import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ISearchResult } from '@cdux/ng-common';


@Component({
  selector: 'cdux-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss']
})
export class SearchResultItemComponent implements OnInit {

  @Input() public resultItem: ISearchResult;
  @Input() public index: number;
  @Output() public resultClicked: EventEmitter<ISearchResult> = new EventEmitter();

  public postTime: Date;

  ngOnInit() {
    this.postTime = this.resultItem.postTime ? new Date(this.resultItem.postTime) : null;
  }
}
