import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CduxFormsModule, CduxModalModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { CduxNgCommonModule } from '@cdux/ng-common';
import { SharedModule as SharedFragmentsModule, DropdownModule, LoadingModule } from '@cdux/ng-fragments';

import { TuxCommonModule } from '../common/common.module';
import { EventTrackingModule } from '../event-tracking/event-tracking.module';
import { TermsModule } from '../terms/terms.module';
import { TransactionModule } from '../transaction/transaction.module';

import { BetSlipModule } from '../bet-slip/bet-slip.module';
import { SsnCollectionModule } from '../ssn-collection/ssn-collection.module';

import { BetsContainerComponent } from './components/bets-container/bets-container.component';
import { MyBetsContainerComponent } from './components/my-bets-container/my-bets-container.component';
import { CompletedBetsContainerComponent } from './components/completed-bets-container/completed-bets-container.component';
import { CompletedBetsComponent } from './components/completed-bets/completed-bets.component';
import { MyBetsByTrackComponent } from './components/my-bets-by-track/my-bets-by-track.component';
import { MyBetsComponent } from './components/my-bets/my-bets.component';
import { QuickPickDropdownComponent } from './components/quick-pick-sidebar/quick-pick-dropdown.component';
import { QuickPickSidebarComponent } from './components/quick-pick-sidebar/quick-pick-sidebar.component';
import { BetSuccessPanelComponent } from './components/bet-success/bet-success-panel.component';

import { CompletedBetsBusinessService } from './services/completed-bets.business.service';
import { MyBetsBusinessService } from './services/my-bets.business.service';
import { BetsCommonService } from './services/bets-common.service'
import { TranslateModule, EventSessionModule, WagerModule } from '@cdux/ng-common';
import { BetSlipContainerComponent } from './components/bet-slip-container/bet-slip-container.component';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';

@NgModule({
    imports: [
        CommonModule,
        CduxFormsModule,
        CduxModalModule,
        CduxMediaModule,
        CduxNgCommonModule,
        DropdownModule,
        FormsModule,
        PortalModule,
        SsnCollectionModule,
        TuxCommonModule,
        SharedFragmentsModule,
        BetSlipModule,
        RouterModule,
        LoadingModule,
        EventTrackingModule,
        TermsModule,
        TranslateModule,
        TransactionModule,
        EventSessionModule,
        WagerModule
    ],
    declarations: [
        BetsContainerComponent,
        CompletedBetsContainerComponent,
        MyBetsContainerComponent,
        BetSlipContainerComponent,
        CompletedBetsComponent,
        MyBetsComponent,
        MyBetsByTrackComponent,
        QuickPickDropdownComponent,
        QuickPickSidebarComponent,
        BetSuccessPanelComponent,
        TodaysBetsContainerComponent
    ],
    exports: [
        BetsContainerComponent,
        CompletedBetsContainerComponent,
        MyBetsContainerComponent,
        BetSlipContainerComponent,
        CompletedBetsComponent,
        MyBetsComponent,
        MyBetsByTrackComponent,
        QuickPickDropdownComponent,
        QuickPickSidebarComponent,
        TodaysBetsContainerComponent
    ],
    providers: [
        CompletedBetsBusinessService,
        MyBetsBusinessService,
        BetsCommonService
    ]
})
export class BetsModule {
}
