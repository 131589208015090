import { combineLatest, Observable } from 'rxjs';
import {
    finalize,
    map,
    takeUntil,
    filter,
    share
} from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    ISelectedEntry,
    ProgramEntry,
    IBetNavObject,
    MultiRaceExoticBetType,
    enumBetModifier,
    WagerState,
    TrackService
} from '@cdux/ng-common';
import { ISelectionUpdate } from '../interfaces/selection-update.interface';
import { IProgramEntriesState } from '../interfaces/program-entries-state.interface';

export interface IEntryUpdates {
    bettingInterests: ISelectedEntry[][];
    updatedEntries: ProgramEntry[][];
    scratches: ISelectionUpdate[];
    allSelected: boolean[];
    wagerState: WagerState
}

export class EntryUpdateHandler extends CduxRxJSBuildingBlock<any, IEntryUpdates> {

    protected _stream: Observable<IEntryUpdates>;

    /** CONTROLS **/
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _wagerStateStream: Observable<WagerState>,
        private _entryUpdates: Observable<IProgramEntriesState>,
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = combineLatest([this._wagerStateStream, this._entryUpdates]).pipe(
            this._syncWagerAndEntries(),
            map(([wagerState, entryUpdates]) => {
                return {
                    bettingInterests: wagerState.bettingInterests,
                    updatedEntries: entryUpdates.programEntries,
                    scratches: this._compileScratchSelectionUpdates(wagerState.bettingInterests, entryUpdates.programEntries, wagerState.betNav),
                    allSelected: this._checkAllSelected(wagerState.bettingInterests, entryUpdates.programEntries, wagerState.betNav),
                    wagerState
                };
            }),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        );
    }

    /** Custom Operators **/
    /* When a track/race changes or switching to or from a pick-n race
     * the wager state change will fire before updated entries have returned from the WS
     * the filter waits until track and race match and the correct number of arrays in the 2D array of entries
     * matches the the race legs
     */
    private _syncWagerAndEntries() {
        return filter(([wagerState, programEntryState]: [WagerState, IProgramEntriesState]) => {
            if (TrackService.isExactTrackObject(wagerState.basicTrack, programEntryState.track)) {
                if (!!wagerState.betNav) {
                    return wagerState.betNav.type.poolType.raceLegs === programEntryState.programEntries.length;
                }
                return true;
            }
            return false;
        });
    }
    /** End Custom Operators **/

    /** CUSTOM METHODS **/
    private _checkAllSelected(allBettingInterests: ISelectedEntry[][], updatedEntries: ProgramEntry[][], betNav: IBetNavObject): boolean[] {
        const areAllSelected: boolean[] = [];
        if (!!allBettingInterests && !!updatedEntries) {
            allBettingInterests.forEach((legSelectedEntries, selectedEntriesLegIndex) => {
                const updatedEntriesLegIndex: number = betNav.type instanceof MultiRaceExoticBetType ? selectedEntriesLegIndex : 0;
                const filteredEntries: ProgramEntry[] = this._getSelectableEntries(updatedEntries[updatedEntriesLegIndex], allBettingInterests, betNav.modifier);
                areAllSelected[selectedEntriesLegIndex] = !!legSelectedEntries && (legSelectedEntries.length === filteredEntries.length);
            });
        }
        return areAllSelected;
    }

    private _compileScratchSelectionUpdates(allBettingInterests: ISelectedEntry[][], updatedEntries: ProgramEntry[][], betNav: IBetNavObject): ISelectionUpdate[] {
        const scratchSelectionUpdates: ISelectionUpdate[] = [];
        if (!!allBettingInterests && !!updatedEntries) {
            allBettingInterests.forEach((legSelectedEntries, selectedEntriesLegIndex) => {
                const updatedEntriesLegIndex: number = betNav.type instanceof MultiRaceExoticBetType ? selectedEntriesLegIndex : 0;
                const legScratchedEntries: ProgramEntry[] = this._extractScratchedEntries(updatedEntries[updatedEntriesLegIndex]);
                const legScratchedSelectedEntries: ISelectedEntry[] = this._compileListOfEntriesInLegThatScratched(legSelectedEntries, legScratchedEntries);
                if (legScratchedSelectedEntries.length > 0) {
                    scratchSelectionUpdates.push({entries: legScratchedSelectedEntries, selected: false, leg: selectedEntriesLegIndex});
                }
            });
        }
        return scratchSelectionUpdates;
    }

    private _compileListOfEntriesInLegThatScratched(legSelectedEntries: ISelectedEntry[], legScratchedEntries: ProgramEntry[]): ISelectedEntry[] {
        const legScratchedSelectedEntries: ISelectedEntry[] = [];
        if (!!legSelectedEntries) {
            legSelectedEntries.forEach((selectedEntry) => {
                const scratchIndex = legScratchedEntries.findIndex((scratchedEntry) => scratchedEntry.ProgramNumber === selectedEntry['ProgramNumber']);
                if (scratchIndex !== -1) {
                    legScratchedSelectedEntries.push(selectedEntry);
                    // Remove the scratched entry from the list to speed up
                    // subsequent findIndex calls and prevent bet interests
                    // from being added to the scratch list multiple times.
                    legScratchedEntries.splice(scratchIndex, 1);
                }
            });
        }
        return legScratchedSelectedEntries;
    }

    private _extractScratchedEntries(programEntries: ProgramEntry[]): ProgramEntry[] {
        return !!programEntries ? programEntries.filter((programEntry) => programEntry.Scratched) : [];
    }

    private _getSelectableEntries(programEntries: ProgramEntry[], allBettingInterests?: ISelectedEntry[][], betModifier?: enumBetModifier): ProgramEntry[] {
        return !!programEntries ? programEntries.filter((programEntry) => {
            if (!programEntry.BettingInterest || programEntry.Scratched) {
                return false;
            }
            return !this._isRunnerSelectedAsKey(programEntry, allBettingInterests, betModifier);
        }) : [];
    }

    /**
     * for key wagers, filter out the selected KEY entry since the KEY cannot be selected as WITH and make sure to check ALL for the WITH column
     * @param {ProgramEntry} entry
     * @param {ISelectedEntry[][]} allBettingInterests
     * @param {enumBetModifier} betModifier
     * @returns {boolean}
     * @private
     */
    private _isRunnerSelectedAsKey(entry: ProgramEntry, allBettingInterests?: ISelectedEntry[][], betModifier?: enumBetModifier): boolean {
        // check if we have a KEY selected for key wagers
        const isKeySelected: boolean = (betModifier === enumBetModifier.KEY && !!(allBettingInterests[0])) ? allBettingInterests[0].length > 0 : false;
        if (isKeySelected) {
            return entry.BettingInterest === allBettingInterests[0][0].BettingInterest;
        }
    }
    /** END CUSTOM METHODS **/
}
