import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { RunnerSearchDataService } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { SearchFieldAbstract } from '../search-field-abstract.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';

@Component({
    selector: 'cdux-standalone-search',
    templateUrl: './standalone-search.component.html',
    styleUrls: ['./standalone-search.component.scss']
})
export class StandaloneSearchComponent extends SearchFieldAbstract {

    @ViewChild('searchField') searchField: ElementRef;

    constructor(
        localRouter: Router,
        localSearchSvc: RunnerSearchDataService,
        localTodaysRacesSvc: TodaysRacesBusinessService,
        localWagerUtilSvc: WageringUtilBusinessService,
        private _sidebarService: SidebarService,
        localEventTrackService: EventTrackingService
    ) {
        super(
            localRouter,
            localSearchSvc,
            localTodaysRacesSvc,
            localWagerUtilSvc,
            localEventTrackService
        );
        this.focusField();
    }

    public closeSearch(forceClose) {
        this._sidebarService.close(true);
    }
}
