import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractMenuItemComponent } from '../abstract-menu-item.component';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { BetSlipBusinessService } from '../../../bet-slip/services/bet-slip.business.service';
import { EventClickType } from '@cdux/ng-common';
import { MenuItemsEnum } from '../../enums/menu-items.enum';
import { Subscription } from 'rxjs';
import { BrazeUtils } from '@cdux/ts-domain-braze';
import * as braze from '@braze/web-sdk';


@Component({
    selector: 'cdux-inbox-menu-item',
    templateUrl: './inbox.component.html'
})
export class InboxComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {
    contentCards: braze.ContentCards;
    private _subscription: Subscription;

    constructor(
                protected _sidebarService: SidebarService,
                protected _betSlipService: BetSlipBusinessService,
                protected _eventTrackingService: EventTrackingService,
                private _brazeUtils: BrazeUtils
                ) {
        super(_sidebarService, _eventTrackingService);
    }

    ngOnInit(): void {
        this._subscription = this._brazeUtils.getContentCards().subscribe(
            (cards) => {
              this.contentCards = cards;
              console.error('Content Cards: ', this.contentCards);
            }
          );
    }

    ngOnDestroy() {
        if (this._subscription) {
          this._subscription.unsubscribe();
        }
      }

    protected clickEvent: EventClickType;
    protected navTarget: MenuItemsEnum;
    protected activate(): void {

    }
    protected deactivate(): void {

    }
}
