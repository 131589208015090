import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export enum EToteStatus {
    TOTE_STATUS_UNKNOWN,
    TOTE_STATUS_DOWN,
    TOTE_STATUS_UP,
}

@Injectable({
    providedIn: 'root',
})
export class ToteStatusService {
    public static readonly RE_TOTE_DOWN = /tote.*down/i;

    private _toteStatus: BehaviorSubject<EToteStatus> = new BehaviorSubject(EToteStatus.TOTE_STATUS_UP);
    public get toteStatus () {
        // return asObservable to avoid access to next(), etc.
        return this._toteStatus.asObservable();
    }

    public static isUp (): OperatorFunction<EToteStatus, boolean> {
        return (source: Observable<EToteStatus>) => {
            return source.pipe(
                map(status => status === EToteStatus.TOTE_STATUS_UP),
            );
        };
    }

    public static isDown (): OperatorFunction<EToteStatus, boolean> {
        return (source: Observable<EToteStatus>) => {
            return source.pipe(
                map(status => status === EToteStatus.TOTE_STATUS_DOWN),
            );
        };
    }

    public updateToteStatus (status: EToteStatus) {
        this._toteStatus.next(status);
    }
}
