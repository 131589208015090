import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { finalize, takeUntil, map, switchMap, startWith, tap, withLatestFrom } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    BasicBetType,
    enumBetModifier,
    ISelectedBetAmount,
    ITrackBasic,
    MultiRaceExoticBetType,
    EntrySelectionUtilBusinessService,
    WagerState,
    ITrack
} from '@cdux/ng-common';

import { BetNavManager } from 'app/shared/betpad/classes/bet-nav-manager.class';
import { RaceNavManager } from 'app/shared/betpad/classes/race-nav-manager.class';
import { EntrySelectionManager } from 'app/shared/program/classes/entry-selection-manager.class';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { WageringNavigationState } from './view-navigation-manager.class';
import { WageringViewEnum } from '../enums/wagering-view.enum';

export class WagerManager extends CduxRxJSBuildingBlock<any, WagerState> {
    protected _stream: Observable<WagerState>;
    private _raceNavEmissions: Subject<ITrackBasic> = new Subject<ITrackBasic>();

    private _source = new BehaviorSubject<string>(null);

    /** CONTROLS **/
    private _raceNavSelections: Subject<ITrackBasic> = new Subject<ITrackBasic>();
    private _raceNavOptions: ReplaySubject<ITrackBasic[]> = new ReplaySubject<ITrackBasic[]>(1);
    private _betTypeOptions: Subject<(BasicBetType[] | MultiRaceExoticBetType[])> = new Subject<BasicBetType[]|MultiRaceExoticBetType[]>();
    private _betTypeSelections: Subject<(BasicBetType | MultiRaceExoticBetType)> = new Subject<BasicBetType|MultiRaceExoticBetType>();
    private _betAmountSelections: Subject<ISelectedBetAmount> = new Subject<ISelectedBetAmount>();
    private _betModifierSelections: Subject<enumBetModifier> = new Subject<enumBetModifier>();
    private _entrySelections: Subject<ISelectionUpdate[]> = new Subject<ISelectionUpdate[]>();
    private _resumeWager: Subject<WagerState> = new Subject<WagerState>();
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavOptions.complete();
        this._raceNavSelections.complete();
        this._betTypeOptions.complete();
        this._betTypeSelections.complete();
        this._betAmountSelections.complete();
        this._betModifierSelections.complete();
        this._entrySelections.complete();
        this._resumeWager.complete();
    }

    public updateRaceNav(track: ITrackBasic) {
        this._raceNavSelections.next(track);
    }

    public selectTrackFromList(trackList: ITrackBasic[] | ITrack[]) {
        this._raceNavOptions.next(trackList);
    }

    public selectBetTypeFromList(betNav: (BasicBetType | MultiRaceExoticBetType)[]) {
        this._betTypeOptions.next(betNav)
    }

    public updateBetType(betType: BasicBetType | MultiRaceExoticBetType) {
        this._betTypeSelections.next(betType);
    }

    public updateBetModifier(betModifier: enumBetModifier) {
        this._betModifierSelections.next(betModifier);
    }

    public updateBetAmount(betAmount: ISelectedBetAmount) {
        this._betAmountSelections.next(betAmount);
    }

    public updateEntries(selection: ISelectionUpdate[]) {
        this._entrySelections.next(selection);
    }

    public resetEntries() {
        this._entrySelections.next(null);
    }

    public updateNavigationState(state: WageringNavigationState) {
        this._source.next(state && state.view && state.section
            ? (state.view + '_' + state.section).toLowerCase()
            : WageringViewEnum.DEFAULT.toLowerCase()
        );
    }

    public resumeWager(wager: WagerState) {
        this._resumeWager.next(wager);
    }

    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    public listenToRaceNavigations(): Observable<ITrackBasic> {
        return this._raceNavEmissions.asObservable();
    }
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = this._resumeWager
            .pipe(
                startWith(null),
                this._handleStateManagers(),
                this._updateAccessorObservables(),
                withLatestFrom(this._source),
                map(([ wagerState, sourceId ]) => (
                    wagerState.sourceId = sourceId,
                    wagerState
                )),
                finalize(() => this.kill()),
                takeUntil(this._kill)
            );
    }

    /** FACTORIES **/
    /**
     * Generates a Race Nav Manager.
     *
     * @param initialValue
     * @private
     */
    private _initializeRaceNavManager(initialValue?: WagerState) {
        return new RaceNavManager(
            this._raceNavOptions,
            this._raceNavSelections,
            (initialValue || {} as WagerState).basicTrack
        );
    }

    /**
     * Generates a Bet Nav Manager.
     *
     * @param initialValue
     * @private
     */
    private _initializeBetNavManager(initialValue?: WagerState) {
        return new BetNavManager(
            this._betTypeOptions,
            this._betTypeSelections,
            this._betAmountSelections,
            this._betModifierSelections,
            (initialValue || {} as WagerState).betNav
        );
    }

    /**
     * Generates an Entry Selection Manager.
     *
     * @param betNavManager
     * @param initialValue
     * @private
     */
    private _initializeEntrySelectionManager(betNavManager: BetNavManager, initialValue?: WagerState) {
        return new EntrySelectionManager(
            new EntrySelectionUtilBusinessService(),
            betNavManager.listen(),
            this._entrySelections,
            initialValue
        );
    }
    /** END FACTORIES **/

    /** CUSTOM OPERATORS **/
    /**
     * This operators handles creating and listening to instances of the sub-managers.
     * This will happen in two tiers:
     * 1. On each newly resumed wager, a new Race Nav Manager is generated and listened to.
     * 2. On each newly selected track or instance of the Race Nav Manager:
     *    a. A Bet Nav Manager is generated
     *    b. An Entry Selection Manager is generated, listening to updates of the Bet Nav Manager
     *
     * The result of all of this is a Wager State.
     *
     * @private
     */
    private _handleStateManagers() {
        return switchMap((wagerToResume: WagerState) => {
            const raceNavManager = this._initializeRaceNavManager(wagerToResume);
            return raceNavManager.listen()
                .pipe(
                    withLatestFrom(of(wagerToResume)),
                    switchMap(([track, wager], callNumber) => {
                        const initialValue = callNumber === 0 ? wager : undefined;
                        const betNavManager = this._initializeBetNavManager(initialValue);
                        const entrySelectionManager = this._initializeEntrySelectionManager(betNavManager, initialValue);
                        return entrySelectionManager.listen()
                            .pipe(
                                withLatestFrom(of(track)),
                                map(([selectionState, raceNav]) => {
                                    return { basicTrack: raceNav, betNav: selectionState.betNav, bettingInterests: selectionState.selectedEntries };
                                }),
                            );
                    }),
                );
        });
    }

    /**
     * This is intended to update any Observables that may be getting used as accessors other than the main stream.
     *
     * @private
     */
    private _updateAccessorObservables() {
        return tap((wagerState: WagerState) => {
            if (wagerState && wagerState.basicTrack) {
                this._raceNavEmissions.next(wagerState.basicTrack);
            }
        });
    }
    /** END CUSTOM OPERATORS **/
}
