import { enumProgramSort } from '../enums/program-sort-columns.enum';

import {
    convertFractionToNumeric,
    ProgramEntry,
    SortingBusinessService,
    ISortMap,
    ISummaryRunnerStats,
} from '@cdux/ng-common';

export class EntriesSorterUtil {

    /**
     *
     * @param sourceList
     * @param {boolean} sortDescending
     * @param {enumProgramSort} sortProperty
     * @param {enumProgramSort} scndSortProperty
     * @returns {any[] | null}
     */
    public static sortEntries(sourceList: ProgramEntry[], sortAscendingOrStatsMap: boolean | { [postPosition: string]: ISummaryRunnerStats }, sortPropertyOrAscending: enumProgramSort | boolean, sortProperty?: enumProgramSort): (any[] | null) {

        let summaryRunnerStatsMap: { [postPosition: string]: ISummaryRunnerStats } | null = null;
        let sortAscending: boolean;
        
        if (typeof sortAscendingOrStatsMap === 'boolean') {
            // Old signature
            sortAscending = sortAscendingOrStatsMap;
            sortProperty = sortPropertyOrAscending as enumProgramSort;
        } else {
            // New signature
            summaryRunnerStatsMap = sortAscendingOrStatsMap;
            sortAscending = sortPropertyOrAscending as boolean;
            sortProperty = sortProperty as enumProgramSort;
        }

        if (!sourceList) {
            return [];
        }

        const columnName: string = sortProperty;
        const sortDefinitions: ISortMap[] = [];

        function createSummaryStatCompareFn(statKey: string) {
            return (a: ProgramEntry, b: ProgramEntry) => {
                const aStats = summaryRunnerStatsMap[a.PostPosition];
                const bStats = summaryRunnerStatsMap[b.PostPosition];
                const aValue = aStats?.[statKey] ?? 0;
                const bValue = bStats?.[statKey] ?? 0;
                return sortAscending ? 
                    SortingBusinessService.compareNumeric(aValue, bValue) :
                    SortingBusinessService.compareNumeric(bValue, aValue);
            };
        }

        if (columnName === enumProgramSort.SORTABLE_PROGRAM_NUMBER) {
            sortDefinitions.push({
                target: enumProgramSort.SORTABLE_PROGRAM_NUMBER,
                ascending: sortAscending
            });
        } else {
            // Since we're not sorting by program number, logic is as follows:
            // 1. Scratched horses go to the bottom.
            // 2. Scratched horses should be sorted by program number (ascending).
                sortDefinitions.push(
                    {
                        // If we're not sorting by program number, push scratches to the bottom.
                        compareFn: (a: ProgramEntry, b: ProgramEntry) => {
                            // If the current entry is scratched, move it lower in the list.
                            if (a.Scratched && !b.Scratched) {
                                return 1;
                            }
                            // If the compared-to entry is scratched, move the current up in the list.
                            if (b.Scratched && !a.Scratched) {
                                return -1;
                            }
                            // Proceed with nested sorts.
                            return 0;
                        }
                    },
                    {
                        // Scratched horses should be sorted by program number (ascending).
                        compareFn: (a: ProgramEntry, b: ProgramEntry) => {
                            if (a.Scratched && b.Scratched) {
                                return SortingBusinessService.compareNumeric(a[enumProgramSort.SORTABLE_PROGRAM_NUMBER], b[enumProgramSort.SORTABLE_PROGRAM_NUMBER]);
                            }
                            // Proceed with nested sorts.
                            return 0;
                        }
                    },
                );

            if (columnName === enumProgramSort.LIVE_ODDS || columnName === enumProgramSort.PROFITLINE_ODDS || columnName === enumProgramSort.MORNINGLINE_ODDS) {
                const order = sortAscending ? 1 : -1;
                sortDefinitions.push(
                    {
                        compareFn: (a: ProgramEntry, b: ProgramEntry) => {
                            // move empty odds to the bottom
                            if (a[columnName] && !b[columnName]) {
                                return -1;
                            } else if (b[columnName] && !a[columnName]) {
                                return 1;
                            }

                            const oddsCompareValue: number = SortingBusinessService.compareNumeric(this._convertTextOddsToNumeric(a[columnName]), this._convertTextOddsToNumeric(b[columnName]));
                            // Compare the numeric odds values. In case of tie, order by sortable program number ascending.
                            if (oddsCompareValue === 0) {
                                return SortingBusinessService.compareNumeric(a[enumProgramSort.SORTABLE_PROGRAM_NUMBER], b[enumProgramSort.SORTABLE_PROGRAM_NUMBER]);
                            } else {
                                return order * oddsCompareValue;
                            }
                        }
                    }
                );
            } else if (columnName === enumProgramSort.PRIOR_RUN_STYLE || columnName === enumProgramSort.SUMMARY_RUN_STYLE) {
                const order = sortAscending ? 1 : -1;
                sortDefinitions.push(
                    {
                        compareFn: (a: ProgramEntry, b: ProgramEntry) => {
                            // move empty or NA(none available) to the bottom,
                            // then sort alphabetically
                            if (a.PriorRunStyle === b.PriorRunStyle) {
                                return 0;
                            } else if (!a.PriorRunStyle) {
                                return 1;
                            } else if (!b.PriorRunStyle) {
                                return -1;
                            } else if (a.PriorRunStyle === 'NA') {
                                return 1;
                            } else if (b.PriorRunStyle === 'NA') {
                                return -1;
                            }

                            return order * SortingBusinessService.compareString(a.PriorRunStyle, b.PriorRunStyle);
                        }
                    },
                    {
                        compareFn: (a: ProgramEntry, b: ProgramEntry) => {
                            // speed points should be displayed in descending order
                            return -1 * SortingBusinessService.compareNumeric(a.SpeedPoints, b.SpeedPoints);
                        }
                    }
                );
            }

            const summaryStatsMap = {
                [enumProgramSort.SUMMARY_MONEY_WON]: 'totalEarnings',
                [enumProgramSort.SUMMARY_TRAINER_WN]: 'trainerWinPercent',
                [enumProgramSort.SUMMARY_JOCKEY_WN]: 'jockeyWinPercent',
                [enumProgramSort.SUMMARY_PRM_PWR]: 'primePower',
                [enumProgramSort.SUMMARY_AVG_CLS]: 'averageClass',
                [enumProgramSort.SUMMARY_SPD_LR]: 'speedLastRace',
                [enumProgramSort.SUMMARY_BACK_SPD]: 'backSpeed',
                [enumProgramSort.SUMMARY_AVG_SPD]: 'avgSpeed',
                [enumProgramSort.SUMMARY_DAYS_OFF]: 'daysOff'
            };
            
            if (summaryStatsMap.hasOwnProperty(columnName) && summaryRunnerStatsMap) {
                sortDefinitions.push({
                    compareFn: createSummaryStatCompareFn(summaryStatsMap[columnName])
                });
            } else {
                // Default to sorting by the given target
                sortDefinitions.push({
                    target: columnName,
                    ascending: sortAscending
                });
            }
        }

        // Perform sorting according to the definitions.
        return SortingBusinessService.sort(sourceList, sortDefinitions);
    }

    private static _convertTextOddsToNumeric(textOdds: string): number {
        let numericOdds: number = null;
        if (!!textOdds) {
            // The odds string may be something other than a stringified number or fraction. "Even" odds
            // means that the numeric odds are 1/1. We still want to properly sort the odds while displaying
            // "Even", so we manually map to 1.
            numericOdds = textOdds.toLowerCase().trim() === 'even' ? 1 : convertFractionToNumeric(textOdds);
        }
        return numericOdds;
    }
}
