<ng-container *ngIf="fpwEnabled">
  <div class="funding-content_available-funds-drawer">
    <div class="funding-content_available-funds__header">
      <div class="header-title">Available to Withdraw</div>
      <div class="header-total-amount" id="availableBalance"> {{ withdrawBalance?.availableBalance | currency:'USD':'symbol-narrow' }} </div>
      <i class="icon--ellipsis--light" (click)="changeAmountDropdown()"></i>
    </div>
    <div class="funding-content_available-funds__body" [class.is-open]="amountDropdown">
      <h3>PENDING</h3>
      <div class="funding-content_available-funds__row">
        <div class="funding-type">EZMoney</div>
        <div class="funding-amount" id="pendingEzmoneyAmount"> {{ withdrawBalance?.pendingEzMoney | currency:'USD':'symbol-narrow' }} </div>
      </div>
      <div class="funding-content_available-funds__row">
        <div class="funding-type">PayPal</div>
        <div class="funding-amount" id="pendingPaypalAmount"> {{ withdrawBalance?.pendingPayPal | currency:'USD':'symbol-narrow' }} </div>
      </div>
      <div class="funding-content_available-funds__row">
        <div class="funding-type">Bonus</div>
        <div class="funding-amount" id="pendingBonusAmount"> {{ withdrawBalance?.pendingBonus | currency:'USD':'symbol-narrow' }} </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!fpwEnabled">
  <div class="amount-withdraw-wrap">
    <div class="amount--current">
      <div class="item-name">Current Balance</div>
      <div class="item-amount" id="accountBalance"> {{ withdrawBalance?.accountBalance | currency:'USD':'symbol-narrow' }} </div>
    </div>
    <div class="amount--pending">
      <div class="item-name">Pending EZMoney <i id="pendingEzmoney" (click)="openPendingBalanceDesc('ezmoney')" class="icon--info"></i></div>
      <div class="item-amount" id="pendingEzmoneyAmount"> -{{ withdrawBalance?.pendingEzMoney | currency:'USD':'symbol-narrow' }} </div>
    </div>
    <div class="amount--pending">
      <div class="item-name">Pending PayPal <i id="pendingPaypal" (click)="openPendingBalanceDesc('paypal')" class="icon--info"></i></div>
      <div class="item-amount" id="pendingPaypalAmount"> -{{ withdrawBalance?.pendingPayPal | currency:'USD':'symbol-narrow' }} </div>
    </div>
    <div class="amount--pending">
      <div class="item-name">Pending Bonus<i id="pendingBonus" (click)="openPendingBalanceDesc('bonus')" class="icon--info"></i></div>
      <div class="item-amount" id="pendingBonusAmount"> -{{ withdrawBalance?.pendingBonus | currency:'USD':'symbol-narrow' }} </div>
    </div>
    <div class="amount--available">
      <div class="item-name">Available to Withdraw:</div>
      <div class="item-amount" id="availableBalance"> {{ withdrawBalance?.availableBalance | currency:'USD':'symbol-narrow' }} </div>
    </div>
  </div>
</ng-container>
