import { AfterViewChecked, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
export abstract class AbstractRaceNavDrawerComponent implements AfterViewChecked {

    /**
     * This is the value emitted to with the content size, communicating whether a
     * sequence of animations has finished.
     *
     * @type {boolean}
     */
    protected _finishedChanges: boolean;

    /**
     * This is used to communicate that the content size of the drawer has changed.
     * Passing true as the parameter communicates that the content is done changing.
     * This will be useful to know when transition animations can be disabled.
     *
     * @type {EventEmitter<boolean>}
     */
    @Output() public contentSizeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * When there's a change to the view, communicate that the content size needs checked.
     */
    public ngAfterViewChecked() {
        this.contentSizeChange.emit(this._finishedChanges);
    }

}
