<section *ngIf="statsObs | async as stats; else noStats" class="stats-classic" [class.is-compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE" [ngSwitch]="statsView">
    <ng-template #statsHeader let-titleMain="titleMain" let-titleSub="titleSub">
        <header class="stats-header">
            <div class="stats-title-wrap">
                <div *ngIf="titleMain" class="stats-title-main">{{ titleMain }}</div>
                <div *ngIf="titleSub" class="stats-title-sub">{{ titleSub }}</div>
            </div>
            <div class="stats-details" *ngIf="stats?.raceTypeStatus as raceTypeStatus">
                <div class="stats-details-row">
                    {{ raceTypeStatus.ageRestriction }}; {{ raceTypeStatus.sexRestriction }}; {{ raceTypeStatus.raceType }}
                </div>
                <div class="stats-details-row">
                    {{ raceTypeStatus.distance }}; {{ raceTypeStatus.surface }}
                </div>
            </div>
        </header>
    </ng-template>


    <ng-container *ngSwitchCase="ViewSectionEnum.STATS_POST_BIAS">
        <ng-container *ngTemplateOutlet="statsHeader; context:{ titleMain: 'Post Position Bias', titleSub: 'Average Win Percentage' }"></ng-container>

        <ng-template #postBiasStatsCard let-trackBiasStats="trackBiasStats" let-trackBiasStatsType="trackBiasStatsType">
            <div class="stats--card is-inactive" *ngIf="trackBiasStats">
                <header class="ts-card-head">
                    <div class="header details">
                        <span class="label uppercase">{{ trackBiasStatsType }}<span class="value">
                            ({{ trackBiasStats.beginDate | date:'MM/dd' }} - {{ trackBiasStats.endDate | date:'MM/dd' }})
                        </span></span>
                        <span class="label right">No. of Races<span class="value highlight">
                            {{ trackBiasStats.countRaces || '0' }}
                        </span></span>
                    </div>
                </header>

                <div class="ts-card-body">
                    <div class="bar-chart-wrap">
                        <div class="data-bar-wrap">
                            <div class="label">Rail</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.postPos1Stats?.percent || 0 | number:'1.0-0' }} dark"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.postPos1Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">1-3</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.postPos2Stats?.percent || 0 | number:'1.0-0' }} med"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.postPos2Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">4-7</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.postPos3Stats?.percent || 0 | number:'1.0-0' }} med-light"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.postPos3Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">8+</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.postPos4Stats?.percent || 0 | number:'1.0-0' }} light"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.postPos4Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="stats-cards-wrapper">
            <ng-container *ngTemplateOutlet="postBiasStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsMeet, trackBiasStatsType: 'Meet' }"></ng-container>
            <ng-container *ngTemplateOutlet="postBiasStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsWeek, trackBiasStatsType: 'Week' }"></ng-container>
        </div>
    </ng-container>


    <ng-container *ngSwitchCase="ViewSectionEnum.STATS_TRACK_BIAS">
        <ng-container *ngTemplateOutlet="statsHeader; context:{ titleMain: 'Track Bias' }"></ng-container>

        <ng-template #trackBiasStatsCard let-trackBiasStats="trackBiasStats" let-trackBiasStatsType="trackBiasStatsType">
            <div class="stats--card is-inactive" *ngIf="trackBiasStats">
                <header class="ts-card-head">
                    <div class="header details">
                        <span class="label uppercase">{{ trackBiasStatsType }}<span class="value">
                            ({{ trackBiasStats.beginDate | date:'MM/dd' }} - {{ trackBiasStats.endDate | date:'MM/dd' }})
                        </span></span>
                        <span class="label right">No. of Races<span class="value highlight">
                            {{ trackBiasStats.countRaces || '0' }}
                        </span></span>
                    </div>
                    <div class="header details align-top">
                        <span class="label small">Winner Average Beaten Length</span>
                        <span class="label right">1st Call <span class="value highlight">{{ trackBiasStats.winnerAvgBeatenLength1 }}</span></span>
                        <span class="label right">2nd Call <span class="value highlight">{{ trackBiasStats.winnerAvgBeatenLength2 }}</span></span>
                    </div>
                </header>

                <div class="ts-card-body">
                    <div class="bar-chart-wrap">
                        <div class="data-bar-wrap">
                            <div class="label">Wire-to-wire</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.wireToWire || 0 | number:'1.0-0' }} dark"></div>
                            </div>
                            <div class="percentage">{{ (trackBiasStats.wireToWire || 0) | number:'1.1-1' }}%</div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">Speed Bias</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.speedBias || 0 | number:'1.0-0' }} med"></div>
                            </div>
                            <div class="percentage">{{ (trackBiasStats.speedBias || 0) | number:'1.1-1' }}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="stats-cards-wrapper">
            <ng-container *ngTemplateOutlet="trackBiasStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsMeet, trackBiasStatsType: 'Meet' }"></ng-container>
            <ng-container *ngTemplateOutlet="trackBiasStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsWeek, trackBiasStatsType: 'Week' }"></ng-container>
        </div>
    </ng-container>


    <ng-container *ngSwitchCase="ViewSectionEnum.STATS_RUN_STYLE">
        <ng-container *ngTemplateOutlet="statsHeader; context:{ titleMain: 'Run Style', titleSub: 'Percentage of Races Won' }"></ng-container>

        <ng-template #runStyleStatsCard let-trackBiasStats="trackBiasStats" let-trackBiasStatsType="trackBiasStatsType">
            <div class="stats--card is-inactive" *ngIf="trackBiasStats">
                <header class="ts-card-head">
                    <div class="header details">
                        <span class="label uppercase">{{ trackBiasStatsType }}<span class="value">
                            ({{ trackBiasStats.beginDate | date:'MM/dd' }} - {{ trackBiasStats.endDate | date:'MM/dd' }})
                        </span></span>
                        <span class="label right">No. of Races<span class="value highlight">
                            {{ trackBiasStats.countRaces || '0' }}
                        </span></span>
                    </div>
                </header>

                <div class="ts-card-body">
                    <div class="bar-chart-wrap">
                        <div class="data-bar-wrap">
                            <div class="label">Early (E)</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.runStyle1Stats?.percent || 0 | number:'1.0-0' }} dark"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.runStyle1Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">Early Presser (E/P)</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.runStyle2Stats?.percent || 0 | number:'1.0-0' }} med"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.runStyle2Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">Presser (P)</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.runStyle3Stats?.percent || 0 | number:'1.0-0' }} med-light"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.runStyle3Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                        <div class="data-bar-wrap">
                            <div class="label">Closer (S)</div>
                            <div class="data-bar">
                                <div class="data fill-percent-{{ trackBiasStats.runStyle4Stats?.percent || 0 | number:'1.0-0' }} light"></div>
                            </div>
                            <div class="percentage">
                                {{ (trackBiasStats.runStyle4Stats?.percent || 0) | number:'1.1-1' }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="stats-cards-wrapper">
            <ng-container *ngTemplateOutlet="runStyleStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsMeet, trackBiasStatsType: 'Meet' }"></ng-container>
            <ng-container *ngTemplateOutlet="runStyleStatsCard; context:{ trackBiasStats: stats?.trackBiasStatsWeek, trackBiasStatsType: 'Week' }"></ng-container>
        </div>
    </ng-container>


    <ng-container *ngSwitchCase="ViewSectionEnum.STATS_SUCCESS_RATES">
        <ng-container *ngIf="stats?.raceTypeStatus; else noStats">
            <ng-container *ngTemplateOutlet="statsHeader; context:{ titleMain: 'Success Rates', titleSub: stats?.raceTypeStatus?.dateType }"></ng-container>

            <div class="stats-cards-wrapper">
                <div class="stats--card is-inactive" *ngIf="stats?.raceTypeStatus as raceTypeStatus">
                    <header class="ts-card-head">
                        <span class="header main uppercase">Favorites</span>
                        <div class="header details">
                            <span class="label race">No. of Races <span class="value highlight">
                                {{ raceTypeStatus.numRaces }}
                            </span></span>
                            <span class="label roi right">$2 ROI <span class="value highlight">
                                {{ raceTypeStatus.favReturnOnInvestment }}
                            </span></span>
                        </div>
                    </header>
                    <div class="ts-card-body">
                        <div class="bar-chart-wrap">
                            <div class="data-bar-wrap">
                                <div class="label">Win Percentage</div>
                                <div class="data-bar">
                                    <div class="data fill-percent-{{ raceTypeStatus.winPercentage || 0 | number:'1.0-0' }} dark"></div>
                                </div>
                                <div class="percentage">
                                    {{ (raceTypeStatus.winPercentage || 0) | number:'1.1-1' }}%
                                </div>
                            </div>
                            <div class="data-bar-wrap">
                                <div class="label">ITM Percentage</div>
                                <div class="data-bar">
                                    <div class="data fill-percent-{{ raceTypeStatus.inTheMoneyPercentage || 0 | number:'1.0-0' }} med"></div>
                                </div>
                                <div class="percentage">
                                    {{ (raceTypeStatus.inTheMoneyPercentage || 0) | number:'1.1-1' }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stats--card is-inactive" *ngIf="stats?.raceTypeStatus as raceTypeStatus">
                    <header class="ts-card-head">
                        <span class="header main uppercase">All Winners</span>
                        <div class="header details">
                            <span class="label race">No. of Races <span class="value highlight">
                                {{ raceTypeStatus.numRaces }}
                            </span></span>
                            <span class="label payoff">$2 Win Payoff <span class="value highlight">
                                {{ raceTypeStatus.medianOddsWin | currency:'USD':'symbol-narrow':'1.2-2' }}
                            </span></span>
                            <span class="label right">Avg. Field Size <span class="value highlight">
                                {{ raceTypeStatus.averageFieldSize }}
                            </span></span>
                        </div>
                    </header>

                    <div class="ts-card-body">
                        <div class="bar-chart-wrap">
                            <div class="data-bar-wrap">
                                <div class="label">1/9 to 9/2 Odds</div>
                                <div class="data-bar">
                                    <div class="data fill-percent-{{ raceTypeStatus.winPercentageShort || 0 | number:'1.0-0' }} dark"></div>
                                </div>
                                <div class="percentage">
                                    {{ (raceTypeStatus.winPercentageShort || 0) | number:'1.1-1' }}%
                                </div>
                            </div>
                            <div class="data-bar-wrap">
                                <div class="label">5/1 to 9/1 Odds</div>
                                <div class="data-bar">
                                    <div class="data fill-percent-{{ raceTypeStatus.winPercentageMid || 0 | number:'1.0-0' }} med"></div>
                                </div>
                                <div class="percentage">
                                    {{ (raceTypeStatus.winPercentageMid || 0) | number:'1.1-1' }}%
                                </div>
                            </div>
                            <div class="data-bar-wrap">
                                <div class="label">10/1 & Up Odds</div>
                                <div class="data-bar">
                                    <div class="data fill-percent-{{ raceTypeStatus.winPercentageLong || 0 | number:'1.0-0' }} med-light"></div>
                                </div>
                                <div class="percentage">
                                    {{ (raceTypeStatus.winPercentageLong || 0) | number:'1.1-1' }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</section>


<ng-template #noStats>
    <div class="has-error light" *ngIf="!isLoading">
        <div class="gbl-message">
            <i class="icon--info"></i>
            <div class="header">Stats unavailable</div>
            <div class="description">Stats are unavailable for this race.</div>
        </div>
    </div>
</ng-template>
