import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountHistoryBetsService, ITransaction, IWager } from '@cdux/ng-fragments';
import { Bet, enumRaceStatus, enumTrackType, ICancelWagerRequest } from '@cdux/ng-common';
import { BetsCommonService } from '../../shared/bets/services/bets-common.service';
import { BetsBusinessService } from '../../shared/bet-slip/services/bets.business.service';


export const AccountHistoryBetsFactory = (betsCommonService: BetsCommonService, betsService: BetsBusinessService): AccountHistoryBetsService => {
    betsCommonService.initializeCommonService().pipe(
        take(1)
    ).subscribe();

    return {
        getRaceStatusChanges(brisCode: string, trackType: enumTrackType, raceNum: number): Observable<enumRaceStatus> {
            return betsCommonService.getRaceStatusChanges(brisCode, trackType, raceNum);
        },
        createBetFromTransaction(transaction: ITransaction): Observable<Bet> {
            return betsCommonService.createBetFromTransaction(transaction);
        },
        saveWager(bet: Bet): Observable<any> {
            return betsService.saveWager(bet);
        },
        cancelWager(wager: IWager | ICancelWagerRequest | ITransaction): Promise<boolean> {
            return betsService.cancelWager(wager);
        },
        copyAndEditBet(bet: Bet) {
            return betsCommonService.copyAndEditBet(bet);
        }
    }
}
