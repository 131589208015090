import { NgModule } from '@angular/core';
import { CompactViewComponent } from './compact-view.component';
import { SharedWageringViewsModule } from '../shared/wagering-views/wagering-views.module';
import { BetPadSharedModule } from '../../shared/betpad/betpad-shared.module';
import { BetSlipModule } from '../../shared/bet-slip/bet-slip.module';
import { BetsModule } from '../../shared/bets/bets.module';
import { ProgramSharedModule } from '../../shared/program/program-shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
    exports: [
        CompactViewComponent,
    ],
    declarations: [
        CompactViewComponent,
    ],
    imports: [
        SharedWageringViewsModule,
        BetPadSharedModule,
        BetSlipModule,
        BetsModule,
        ProgramSharedModule,
        CommonModule
    ]
})
export class CompactModule {}
