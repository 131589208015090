// Import the core angular.
import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';

// This directive will copy the value to clipboard
@Directive({
    selector: '[cduxCopyToClipboard]',
})
export class CduxCopyToClipboardDirective {

    @Input() public set cduxCopyToClipboard(value: string) {
        this._value = value;
    }

    @Output() public cduxCopyToClipboardSuccess: EventEmitter<void> = new EventEmitter();

    private _dom: any = (<any>document);
    private _value: string;

    @HostListener('click') public onClick(): void {
        this.copyToClipboard(this._value);
    }

    // I initialize the clipboard functionality.
    private copyToClipboard(value: string): void {
        if (!!value && value !== null) {
            let textarea = null;

            try {

                // In order to execute the "Copy" command, we actually have to have
                // a "selection" in the currently rendered document. As such, we're
                // going to inject a Textarea element and .select() it in order to
                // force a selection.
                // --
                // NOTE: This Textarea is being rendered off-screen.
                textarea = this._dom.createElement('textarea');
                textarea.style.height = '0px';
                textarea.style.left = '-100px';
                textarea.style.opacity = '0';
                textarea.style.position = 'fixed';
                textarea.style.top = '-100px';
                textarea.style.width = '0px';
                this._dom.body.appendChild(textarea);

                // Set and select the value (creating an active Selection range).
                textarea.value = value;
                textarea.select();

                if (this.isOS()) {
                    const oldContentEditable = textarea.contentEditable,
                        oldReadOnly = textarea.readOnly,
                        range = document.createRange();

                    textarea.contenteditable = true;
                    textarea.readonly = false;
                    range.selectNodeContents(textarea);

                    const s = window.getSelection();
                    s.removeAllRanges();
                    s.addRange(range);

                    textarea.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

                    textarea.contentEditable = oldContentEditable;
                    textarea.readOnly = oldReadOnly;
                }

                this._dom.execCommand('Copy');
                this.cduxCopyToClipboardSuccess.emit();
            } finally {
                // Cleanup - remove the Textarea from the DOM if it was injected.
                if (textarea && textarea.parentNode) {
                    textarea.parentNode.removeChild(textarea);
                }
            }
        }

    }

    private isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }
}
