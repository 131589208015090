import { ComponentRef, Directive, HostListener, Input } from '@angular/core';
import { CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { CduxAbstractSidebarComponent } from '../cdux-sidebar-component.class';
import { ISidebarComponentProperties } from '../interfaces/sidebar-portal-component.interface';


/**
 * Takes a Map() of input properties, and applies them to the CduxAbstractSidebarComponent
 * that is attached to the portal outlet on which this directive is applied.
 *
 * This directive is intended to set inputs at the time that the Component is attached
 * to the portal, and is NOT meant to update properties on an existing attached portal.
 *
 * The directive takes an input (a Map object) that contains the inputs, and
 * listens for the host portal outlet's 'attached' event. When the inputs are set
 * and/or the attached event is fired, the directive will attempt to apply the intputs
 * to the Component attached to the portal.
 *
 * As soon inputs are applied, the local reference to the portal component is deleted.
 * This is done on purpose, for two reasons: 1. It keeps inadvertent updates to inputs
 * from updating an existing attached portal (use standard portal input properties for that),
 * and 2. It makes sure that a reference to a component is not kept after the component
 * is destroyed.
 *
 * USAGE:
 *
 * controller:
 * ----------
 * this.inputs: Map<any, any> = new Map();
 * this.inputs.set('key', 'value');
 *
 * template:
 * --------
 * <ng-template [cdkPortalOutlet]="MySideBarComponent" [cduxSidebarInputs]="inputs"></ng-template>
 */
@Directive({
    selector: '[cduxSidebarProperties]',
    exportAs: 'cduxSidebarProperties'
})
export class CduxSidebarPropertiesDirective {

    // The instance of the component that is attached to the portal.
    // This reference is not maintained. It gets nulled as soon as
    // inputs are applied to the component.
    private _attachedComponent: CduxAbstractSidebarComponent;

    // A Map of inputs that will be applied to the component that
    // is attached to the portal.
    private _portalProperties: ISidebarComponentProperties;

    /**
     * A Map of inputs for the component that gets loaded in the portal outlet.
     * Set this property by using an attribute binding in your template.
     *
     * @param {ISidebarComponentProperties} properties
     */
    @Input('cduxSidebarProperties')
    public set portalProperties(properties: ISidebarComponentProperties) {
        this._portalProperties = properties;
        this.applyInputs(true);
    }

    /**
     * Listener for the PortalOutlet's attached event. when the event is fired, this
     * method captures a reference to the attached component so that we can set it's
     * inputs when they are set.
     *
     * @param {CdkPortalOutletAttachedRef} attachedComponent
     */
    @HostListener('attached', ['$event']) onPortalAttached(attachedComponent: CdkPortalOutletAttachedRef) {
       const componentRef = attachedComponent as ComponentRef<CduxAbstractSidebarComponent>;
       this._attachedComponent = <CduxAbstractSidebarComponent>componentRef.instance;
       this.applyInputs();
    }

    /**
     * Applies the inputs to the attached portal if both exist,
     * and then removes the local reference to the portal component
     * to prevent an orphaned reference later.

     * @param {boolean} forceRemoveComponentReference - force removal of local component reference
     * used by the portalProperties setter to force removal when there are no properties
     */
    private applyInputs(forceRemoveComponentReference: boolean = false) {
        if (this._attachedComponent && this._portalProperties) {
            this._attachedComponent.setProperties(this._portalProperties);
            this._attachedComponent = null;
            this._portalProperties = null;
        } else if (forceRemoveComponentReference) {
            this._attachedComponent = null;
        }
    }
}
