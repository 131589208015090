import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { RunnerDetailsComponent } from "app/shared/program/components/runner-details/runner-details.component";
import { IDetailsOptions } from "app/shared/program/interfaces/details.interface";

@Component({
    selector: 'cdux-favorite-stats-runner',
    templateUrl: '../../../shared/program/components/runner-details/runner-details.component.html',
    styleUrls: ['../../../shared/program/components/runner-details/runner-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteStatsRunnerComponent extends RunnerDetailsComponent implements OnInit {

    @Input() detailOptions: IDetailsOptions;
    @Input() isStable: boolean = false;

    public ngOnInit() {
        this.init(this.detailOptions);
    }
}