<div class="my-account-container" *cduxLoading="'myAccountContainer'; strategy:'overlay'; component:loadingDotsComponent;">
    <div class="my-account-inner" [style.opacity]="isLoaded? 1 : 0" >
        <!-- add 'open' class to email-confo below to animate the confirmation message onto the screen remove it to animate it off -->
        <div class="email-confo" [class.open] = "successMessage.length > 0" [cduxFocus]="successMessage.length > 0" [scrollToTop] = 'false'>
            <p>{{ successMessage }}</p>
        </div>
        <div class="email-fail" [class.open] = "customError.length > 0" [cduxFocus]="customError.length > 0" [scrollToTop]='false'>
            <p *ngIf="!!customError">
                {{ customError }}
            </p>
        </div>
        <div class="account">
            <h3>Account number</h3>
            <div class="info" id="accountNumberValue">{{accountInfo.accountNumber}}</div>
        </div>
        <div *ngIf="!ssoLoginFT" class="name">
            <h3>Username</h3>
            <div class="info" id="usernameValue">{{accountInfo.username}}</div>
        </div>
        <div class="address" [cduxLogClick]="eventClickType.MY_ACCOUNT_SETTINGS_ADDRESS_TOOLTIP">
            <cdux-tooltip class="account-tooltip" [activatedOn]="'click hover'" [position]="'bottom'" tooltipTitle="Change Address">
                <div tooltip-toggle class="icon--info" id="toolTip" *ngIf="!ssoLoginFT"></div>
                <span tooltip-content>
                    To update your address please
                    <ng-container *ngIf="phoneSupportEnabled; else chatLink">
                        call Player Services at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}
                    </ng-container>
                    <ng-template #chatLink>
                      <cdux-chat [linkStyle]="CHAT_LINK_STYLE"></cdux-chat>
                    </ng-template>
                </span>
            </cdux-tooltip>

            <h3>Address</h3>
            <div class="info" id="addressValue">
                {{accountInfo.address1}} <br>
                <div *ngIf="accountInfo.address2">{{accountInfo.address2}} <br></div>
                {{accountInfo.city}}, {{accountInfo.state}} {{accountInfo.zip}}
            </div>
        </div>

        <ng-container *ngIf="ssoLoginFT; else updateInfo">
            <div class="sso-info">
                <h3>Email</h3>
                <div class="info" id="ssoEmail">
                    {{ accountInfo.email }}
                </div>
            </div>

            <div class="sso-info" *ngIf="accountInfo.phone">
                <h3>Cell Phone</h3>
                <div class="info" id="ssoPhone">
                    {{ formatPhone(accountInfo.phone) }}
                </div>
            </div>

            <div class="sso-info" id="ssoNotice">
                If you need to update your {{ siteName }} profile information, please navigate to the {{ siteName }} website. To update your address, email or cell phone tied to your horse racing account, please chat with our player services team.
            </div>
            <div class="sso-info" *ngIf="!isNative">
                <button (click)="launchZeMessenger()" class="sso-chat-button" id="sso-chat-button"><span>Chat Now</span></button>
            </div>
        </ng-container>

        <ng-template #updateInfo>
            <!-- BEGIN FORM -->
            <form action="" [formGroup]="form" *ngIf="!ssoLoginFT">
                <!-- EMAIL -->
                <div class="form-label-hover email-button-container" [class.hide]="editType === manageAccountType.EMAIL" (click)='editAccount(manageAccountType.EMAIL)' formGroupName="emailGroup">
                    <!--[ngClass] adds .has-value to input when there's value-->
                    <input id="email" type="email" class="has-value" formControlName="email" readonly style="cursor: pointer;" >
                    <label class="ts-label" for="email">Email</label>
                    <!-- VALIDATION ICON
                        DIRECTIONS: 1. use 'icon--pencil-circle' as the default feedback icon
                                    2. use class 'icon--valid-check' for positive feedback
                                    3. use class 'icon--remove--amber' for negative feedback
                    -->
                    <i class="icon--pencil-circle" id="emailEdit"></i>
                    <!--<i class="icon&#45;&#45;valid-check hide"></i>-->
                    <!--<i class="icon&#45;&#45;remove&#45;&#45;amber hide"></i>-->
                </div>
                <cdux-email-input *ngIf="editType === manageAccountType.EMAIL" [formGroup]="form.get('emailGroup')" (cancel)="cancel()" (update)="onFormUpdate($event)"></cdux-email-input>

                <div class="form-label-hover phone-button-container"  [class.hide]="editType === manageAccountType.CELL_NUMBER"  (click)='editAccount(manageAccountType.CELL_NUMBER)' formGroupName="phoneGroup">
                    <input id="cell-phone-display" type="tel" class="has-value" formControlName="phone" readonly [cduxTextMask]="phoneMask">
                    <label class="ts-label" for="cell-phone-display">Cell Phone</label>
                    <i class="icon--pencil-circle" id="cellPhoneEdit"></i>
                </div>
                <cdux-phone-input *ngIf="editType === manageAccountType.CELL_NUMBER" [formGroup]="form.get('phoneGroup')" (cancel)="cancel()" (update)="onFormUpdate($event)"></cdux-phone-input>

                <div class="form-label-hover password-button-container" [class.hide]="editType === manageAccountType.PASSWORD" (click)='editAccount(manageAccountType.PASSWORD)'>
                    <!--[ngClass] adds .has-value to input when there's value-->
                    <input id="password-button" type="password" class="password-button has-value" value="password"  readonly style="cursor: pointer;">
                    <label class="ts-label" for="password-button">Password</label>
                    <i class="icon--pencil-circle" id="passwordEdit"></i>
                </div>
                <cdux-password-input *ngIf="editType === manageAccountType.PASSWORD" [formGroup]="form.get('passwordGroup')" (cancel)="cancel()"  (update)="onFormUpdate($event)"></cdux-password-input>
                <ng-container *ngIf="displayPinInput">
                    <div class="form-label-hover pin-button-container" [class.hide]="editType === manageAccountType.PIN" (click)="editAccount(manageAccountType.PIN)" formGroupName="pinGroup">
                        <input id="pin" type="password" class="has-value" formControlName="pin"  readonly style="cursor: pointer;">
                        <!--[ngClass] adds .has-value to input when there's value-->
                        <label class="ts-label" for="pin">Pin</label>
                        <i class="icon--pencil-circle" id="pinEdit"></i>
                    </div>
                    <cdux-updatepin *ngIf="editType === manageAccountType.PIN" [formGroup]="form.get('pinGroup')" (cancel)="cancel()" (update)="onFormUpdate($event)"></cdux-updatepin>
                </ng-container>
                <!--
                        CANCEL / UPDATE BUTTONS
                        DIRECTIONS: use the class 'inactive' for when the save button can't be clicked
                        and use the class 'active' for when it can be clicked
                    -->
                <!--<div class="update-cancel-button inactive"><p>SAVE</p></div>-->
            </form>
        </ng-template>
    </div>
</div>
