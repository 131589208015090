import { ComponentRef, Output, EventEmitter, Directive } from '@angular/core';
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { BetshareSessionData } from '@cdux/ng-fragments';
import { take } from 'rxjs/operators';

import { BetshareOptInResponse, BetShareStatusUpdate } from '../models';
import { ISidebarPortalComponent } from '../../shared/sidebar/interfaces/sidebar-portal-component.interface';

@Directive()
export abstract class CduxAbstractBetShareComponent {

    @Output() public statusUpdate: EventEmitter<BetShareStatusUpdate> = new EventEmitter();

    public betShareSessionData: BetshareSessionData;
    public betShareOptInResponse: BetshareOptInResponse;

    /**
     * @internal
     */
    public updateSessionData(sessionData: BetshareSessionData, handleErrors = true) {
        this.betShareSessionData = Object.assign({}, sessionData);
    }

    /**
     * @internal
     */
    public updateOptinResponse(optInResponse: BetshareOptInResponse, handleErrors = true) {
        this.betShareOptInResponse = Object.assign({}, optInResponse);
    }

    protected _attach(portalComponent: ISidebarPortalComponent, outlet: CdkPortalOutlet) {
        if (outlet && portalComponent) {
            if (outlet.hasAttached) {
                outlet.detach();
            }
            outlet.attached.pipe(
                take(1)
            ).subscribe((ref: ComponentRef<any>) => {
                ref.instance.setProperties(portalComponent.properties);
            });
            outlet.attach(new ComponentPortal(portalComponent.component));
        } else if (outlet && !portalComponent) {
            if (outlet.hasAttached) {
                outlet.detach();
            }
        }
    }
}
