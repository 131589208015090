import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import {
    enumResultsPayoutReason,
    enumTrackType,
    enumTrackTypeBds,
    EventsService,
    FavoriteRunnersService,
    IAdwRace,
    IFavoriteRunnerNew,
    IResultsExoticPool,
    IResultsFinishOrder,
} from '@cdux/ng-common';

import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { EventKeys, ResultsSortUtils } from 'app/shared';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { Results } from '../../models/results.model';
import { ResultsService } from '../../services/results.business.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';

enum display {
    ALL = 'all',
    PAYOUTS = 'payouts',
    FINISH_ORDER = 'finish_order'
}

@Component({
    selector: 'cdux-result-chart',
    templateUrl: './result-chart.component.html',
    styleUrls: ['./result-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultChartComponent implements OnInit, OnDestroy {
    @Input() public showChartButton = true;
    @Input() public onProgram: boolean;
    @Input() public raceDate: string;
    @Input() public brisCode: string;
    @Input() public trackType: enumTrackType;
    @Input() public raceNumber: number;
    @Input() public showHeader = true;
    @Input() public compactView = false;
    @Input() public onClassic = false;
    @Input() public display = display.ALL;

    public TRACK_TYPE_ENUM = enumTrackType;
    public favRunnerIds: number[] = [];
    public displayOptions = display;

    private _destroy: Subject<any> = new Subject<any>();
    private _hasRunInit = false;
    public favoriteLocation = FavoriteLocationEnum;

    @Input()
    public set resultChart(value: Results) {
        this._resultChart = value;
        // TODO: This should probably respond to
        // race navigation instead of relying on
        // the results input. For now I can just
        // set a flag to avoid a race condition.
        if (this._hasRunInit) {
            this._preparePools(value);
        }
        this._changeDetector.detectChanges();
    }
    public get resultChart(): Results {
        return this._resultChart;
    }
    private _resultChart: Results;

    @Output()
    public instantChartAvailable = new EventEmitter<boolean>();
    public hasInstantChart: boolean;

    constructor(
        private _acctBubbleNotificationService: AccountBubbleNotificationService,
        private _changeDetector: ChangeDetectorRef,
        private _eventsService: EventsService,
        private _favoriteRunnerService: FavoriteRunnersService,
        private _resultsService: ResultsService,
        private _wageringUtil: WageringUtilBusinessService,
    ) { }

    ngOnInit() {
        this._updateFavRunnerIds();
        this._hasRunInit = true;
        this._preparePools(this._resultChart);
        this._resultsService.buildBreadcrumbs(this.resultChart.track, this.raceDate); // build initial breadcrumbs on load, rebuild on race nav events
        this._eventsService.on(EventKeys.RACE_NAV_RACE_CHANGE).pipe(
            takeUntil(this._destroy)
        ).subscribe( // TODO: Is this event handler necessary?  Is responding to resultChart sufficient?
            (race: IAdwRace) => {
                this.raceNumber = race.race;
                // this._checkChartAvailability();
                this._resultsService.buildBreadcrumbs(this.resultChart.track, this.raceDate);
                this._changeDetector.detectChanges();
            }
        );

        this._acctBubbleNotificationService.on([FavEventType.FAVORITE_RUNNER_ADD, FavEventType.FAVORITE_RUNNER_REMOVE]).pipe(
            takeUntil(this._destroy)
        ).subscribe(() => {
            this._updateFavRunnerIds();
        });
    }

    ngOnDestroy() {
        this._destroy.next();
    }

    /**
     * trackByProgramNumber
     *
     * @param index {number}
     * @param entry {*}
     * @returns {((string | null))}
     * @memberof ResultChartComponent
     */
    public trackByProgramNumber(index: number, entry: any): (string | null) {
        return entry ? entry.programNumber : null;
    }

    public trackByPoolCode(index: number, pool: IResultsExoticPool): (string | null) {
        return pool ? pool.poolCode : null;
    }

    public getEntryAsFavorite(entry: IResultsFinishOrder): IFavoriteRunnerNew {
        const favObject = {
            runnerId: entry.brisId,
            runnerName: entry.horseName,
            breedType: enumTrackTypeBds.TBRED, // TODO: dynamicify
            whereBred: entry.whereBred,
            yearOfBirth: entry.yob, // TODO: get yob from entries response
        }
        return favObject as IFavoriteRunnerNew;
    }

    private _decodePoolResultReason(pool: IResultsExoticPool): IResultsExoticPool {
        switch (pool && pool.reason1) {
            case enumResultsPayoutReason.CARRYOVER:
                pool.decodedReason = 'Carryover';
                break;
            case enumResultsPayoutReason.REFUNDED:
                pool.decodedReason = 'Refunded';
                break;
            case enumResultsPayoutReason.PARTIAL:
                pool.decodedReason =  (pool.reason2 && pool.reason3 && pool.reason2 !== pool.reason3) ?
                    `(${pool.reason3} of ${pool.reason2})` : '';
        }
        return pool;
    }

    private _preparePools(results: Results): Results {
        // sort pools, and add SaddleCloths
        const pools = ['wpsMatrix', 'winPools', 'placePools', 'showPools', 'finishOrder'];
        pools.map((poolType) => {
            if (poolType in results.raceDetails) {
                if (poolType !== 'finishOrder') { // Finish Order is sorted by finishPosition ASC on the backend
                    // sorts wpsMatrix, winPools, placePools, and showPools by seq
                    results.raceDetails[poolType] = ResultsSortUtils.sortPools(results.raceDetails[poolType]);
                }
                results.raceDetails[poolType].map((pool) => {
                    pool.saddleClothClass = this._wageringUtil.getSaddleClothClass(this.trackType, pool.programNumber);
                    return pool;
                });
            }
        });

        // pre-decode exotic pool reason codes
        if (!!results.raceDetails.exoticPools) {
            results.raceDetails.exoticPools.map((pool) => this._decodePoolResultReason(pool));
        }

        return results;
    }

    private _updateFavRunnerIds() {
        this.favRunnerIds = this._favoriteRunnerService.favoriteIds;
    }

    public checkFavoriteRunner(entryId: number): boolean {
        return this.favRunnerIds.includes(entryId);
    }

}
