import {CduxAbstractSidebarComponent} from '../../../../sidebar/cdux-sidebar-component.class';
import {Component, OnInit} from '@angular/core';
import {
    ISidebarComponentProperties,
    ISidebarHeaderComponent,
    ISidebarHeaderProperties
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';

@Component({
    selector: 'cdux-moneygram-method-header',
    templateUrl: './moneygram-method-header.component.html',
    styleUrls: ['./moneygram-method-header.component.scss']
})
export class FundingMoneyGramMethodHeaderComponent extends CduxAbstractSidebarComponent implements OnInit {

    public static getSidebarComponent(options: ISidebarHeaderProperties = {}): ISidebarHeaderComponent {
        return {
            component: FundingMoneyGramMethodHeaderComponent,
            properties: options
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    ngOnInit() { /* empty */ }
}
