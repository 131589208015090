import { DetectionService, WindowRefService } from '@cdux/ng-common';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SocialMediaService {
    private window: Window;

    constructor(private _windowRef: WindowRefService, private _detectionService: DetectionService) {
        this.window = this._windowRef.nativeWindow;
    }

    public sendEmail(subject: string, body: string): void {
        this.window.location.href = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
    }

    public sendSMS(body: string): void {
        this.window.location.href = this._generateOS() + encodeURIComponent(body);
    }

    public sendTweet(message: string, link: string): void {
        const url = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(message) + '&url=' + encodeURIComponent(link);
        this.window.open(url);
    }

    public sendFacebookPost(link: string) {
        const url = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(link);
        this.window.open(url);
    }


    private _generateOS(): string {
        if (this._detectionService.isAndroidDevice()) {
            // if android, return the proper encoding
            return 'sms:?body=';
        } else {
            // if iOS, return the proper encoding
            return 'sms:&body=';
        }
    }

}
