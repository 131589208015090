<div class="modal-tournament deposit-unavailable">
    <header>
        <div class="full-col"></div>
        <span (click)="closeModal()"><i class="icon--close dark" title="Click to close box or cancel"></i></span>

    </header>
    <div class="contents-wrap">

        <h1 class="h1--standard center-text"><i class="icon--info"></i><br>
            {{ modalConfig.title }}</h1>
        <p class="message">{{ modalConfig.notice }}</p>

        <footer class="taller-margin">
            <button class="button-primary-outline white" (click)="closeModal()">cancel</button>
            <button class="button-primary white" (click)="switchToPrimaryAccount()">Switch Account</button>
        </footer>
    </div>
</div>