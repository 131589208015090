import { PastPerformanceManager } from './classes/pp-manager.class';
import { Location } from '@angular/common';
import { ReplaySubject, Subject, iif, of } from 'rxjs';
import { EcommerceBusinessService } from './../ecommerce/services/ecommerce.business.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
import {
    enumFeatureToggle,
    enumEcommerceCustomerAvailability,
    enumEcommerceCustomerCredibilityStatus,
    enumEcommercePurchaseStatus,
    EcommerceProduct,
    EventClickAttributeType,
    EventClickType,
    FeatureToggleDataService,
    IAvailableProduct,
    IAvailableTrack,
    IAvailableDate,
    IAvailableRace,
    IMenu,
    ITrackBasic,
    JwtRefreshService,
    JwtSessionService,
    TrackService,
    UserEventEnum,
} from '@cdux/ng-common';
import { LoadingDotsComponent, LoadingService, ToastService } from "@cdux/ng-fragments";
import { catchError, first, map, skipWhile, switchMapTo, takeUntil, tap } from 'rxjs/operators';
import { DisplayModeEnum } from 'app/shared/common/enums';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { Router } from '@angular/router';
import { CduxMediaToggleService } from "@cdux/ng-platform/web";

export interface PastPerformancesMenuItem {
    availableTrack: IAvailableTrack;
    availableDate: IAvailableDate;
    availableProduct: IAvailableProduct;
    availableRace: IAvailableRace;
    menuItem: IMenu;
    isPending?: boolean;
    errorMessage?: string;
    showDepositLink?: boolean;
}

@Component({
    selector: 'cdux-past-performances',
    templateUrl: './past-performances.component.html',
    styleUrls: ['./past-performances.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PastPerformancesComponent implements OnInit, OnDestroy {
    public LoadingDotsComponent = LoadingDotsComponent;
    public loadingOverlayName = 'PastPerformancesComponentLoading';

    @Input()
    public set selectedRaceNavigation(track: ITrackBasic) {
        if (track && !TrackService.isExactTrackObject(this.track, track)) {
            if (TrackService.isSameTrack(this.track, track)) {
                // remove this filter if you need to call
                // for single-race products or something
                this.track = track; // update reference
            } else { // get products for the new track
                this.track = track;
                this.availableProducts = undefined;
                this._loadingService.register(this.loadingOverlayName);
                this._pastPerformancesRequestHandler.updateRaceNavigation(track);
            }
        }
    }

    @Input() public displayMode: DisplayModeEnum;

    public track: ITrackBasic;
    public availableProducts: PastPerformancesMenuItem[];

    public raceNavChange: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>();
    public DisplayModeEnum = DisplayModeEnum;
    public handicappingStoreEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.HANDICAPPING);

    private _pastPerformancesRequestHandler: PastPerformanceManager;
    private _destroy: Subject<undefined> = new Subject<undefined>();

    private _allowedCustomerAvailability = [
        enumEcommerceCustomerAvailability.VIEW,
        enumEcommerceCustomerAvailability.FREE_WITH_BET,
        enumEcommerceCustomerAvailability.REFUND_WITH_BET
    ];

    public isPhone: boolean = false;
    public isTrusted: boolean = false;

    constructor (
        _environment: ENVIRONMENT,
        private _location: Location,
        private _ecommerceService: EcommerceBusinessService,
        private _fundingService: FundingService,
        private _featureToggleDataService: FeatureToggleDataService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _eventTrackingService: EventTrackingService,
        private _loadingService: LoadingService,
        private _jwtRefreshService: JwtRefreshService,
        private _sessionService: JwtSessionService,
        private _toastService: ToastService,
        private _router: Router,
        private _mediaService: CduxMediaToggleService,
        private _notificationBubbleService: AccountBubbleNotificationService
    ) {
        this._pastPerformancesRequestHandler = new PastPerformanceManager(_environment, this._ecommerceService, this._sessionService);
    }

    ngOnInit() {
        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
        });

        this.loadPPs();
    }

    ngOnDestroy() {
        this._pastPerformancesRequestHandler.kill();
        this._destroy.next();
        this._destroy.complete();
    }

    private loadPPs() {
        iif(() => !this._sessionService.isLoggedIn(),
            of(null), // not logged in, skip account check
            iif(() => { // verify fusebill account is valid
                    const userInfo = this._sessionService.getUserInfo();
                    return +userInfo.fuseBillid !== +userInfo.account;
                },
                of(null), // fusebill account has been set up
                this._jwtRefreshService.jwtRefresh(true).pipe(
                    skipWhile((res) => { // skip while fusebill account is invalid
                        if (!res.fusebillId || (+res.fusebillId === +res.accountNumber)) {
                            this.displayInfo("Your account is still being configured, please wait...", 30000);
                            return true;
                        } else { // fusebill account has been set up, continue
                            this.displayInfo("Account has been configured, loading products...", 1000);
                            return false;
                        }
                    }),
                    first()
                )
            )
        ).pipe(
            switchMapTo(this._pastPerformancesRequestHandler.listen()),
            map(([availableDocs, menuItems]) => {
                this.availableProducts = [];
                this.isTrusted = [
                    enumEcommerceCustomerCredibilityStatus.TRUSTED.toUpperCase(),
                    enumEcommerceCustomerCredibilityStatus.PREFERRED.toUpperCase()
                ].includes(availableDocs.customerCredibilityStatus.toUpperCase());

                const availableTrack = !!availableDocs && !!availableDocs.availableTracks[0] ? availableDocs.availableTracks[0] : null;
                const availableDate = !!availableTrack && !!availableTrack.availableDates[0] ? availableTrack.availableDates[0] : null;

                if (availableDocs && menuItems && availableTrack && availableDate) {
                    if (menuItems.length > 0 && menuItems[0].children?.length > 0) {
                        menuItems[0].children.forEach((menuItem) => {
                            const itemProductCode = menuItem && menuItem.item && menuItem.item.productCode.toUpperCase();
                            const availableProduct = availableDate.availableProducts.find((availableProduct) =>
                                availableProduct.productCode.toUpperCase() === itemProductCode &&
                                availableProduct.availableRaces.length > 0
                            );
                            availableProduct?.availableRaces.forEach((availableRace) => {
                                if (this._allowedCustomerAvailability.includes(availableRace.customerAvailability)) {
                                    this.availableProducts.push({
                                        availableTrack, availableDate, availableProduct, availableRace, menuItem
                                    });
                                }
                            })
                        });
                    }
                }

                return this.availableProducts;
            }),
            catchError((e) => {
                this.displayError("Unable to load available products.  Contact Player Services for assistance.");
                return of([]);
            }),
            tap(() => {
                this._loadingService.resolve(this.loadingOverlayName);
                this._changeDetectorRef.markForCheck();
            }),
            takeUntil(this._destroy)
        ).subscribe();
    }

    public displayInfo(message: string, duration = 1000) {
        this._toastService.open(message, { duration });
    }

    public displaySuccess(message: string, duration = 3000) {
        this._notificationBubbleService.dispatch({
            type: FavEventType.ECOMMERCE_TRANSACTION_SUCCESS,
            payload: { message, duration }
        });
    }

    public displayError(message: string) {
        this._toastService.cduxWarning(message);
    }

    public goToDeposit() {
        this._fundingService.postDepositRedirectURL = this._router.url;
        this._router.navigate(['/deposit']);
    }

    public download(item: PastPerformancesMenuItem) {
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        } else {
            const ts = Date.now();
            this._eventTrackingService.logClickEvent(EventClickType.PP_DOWNLOAD, [
                { attrId: EventClickAttributeType.PP_DOWNLOAD_PRODUCT_CODE, data: item.menuItem.productCode, timestamp: ts },
                { attrId: EventClickAttributeType.PP_DOWNLOAD_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                { attrId: EventClickAttributeType.PP_DOWNLOAD_TRACK_TYPE, data: TrackService.getTrackType(item.availableTrack.trackType), timestamp: ts },
                { attrId: EventClickAttributeType.PP_DOWNLOAD_RACE_DATE, data: item.availableDate.productDate, timestamp: ts }
            ]);

            this._eventTrackingService.logUserEvent(UserEventEnum.PPDOWNLOAD, {
                trackName: this.track.DisplayName,
                productName: item.menuItem.label,
                price: item.menuItem.item.price,
                paidForPp: false
            });

            const newTabUrl = this._location.prepareExternalUrl([
                'product/download',
                item.menuItem.item.productCode,
                item.availableTrack.trackType,
                this.track.BrisCode,
                item.availableDate.productDate,
                item.availableTrack.dayEvening
            ].join('/'));

            window.open(newTabUrl);
        }
    }

    public makePromiseAndDownload(item: PastPerformancesMenuItem) {
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        } else if (item.isPending) {
            this.displayInfo('Transaction in progress, please wait...', 1000);
        } else {
            item.isPending = true;
            item.errorMessage = null;
            item.showDepositLink = false;
            this._changeDetectorRef.markForCheck();

            const product: EcommerceProduct = {
                brisCode:    this.track.BrisCode,
                trackId:     item.availableTrack.trackCode,
                dayEvening:  item.availableTrack.dayEvening,
                productCode: item.availableProduct.productCode,
                raceDate:    item.availableDate.productDate,
                raceNumber:  item.availableRace.raceNumber,
                trackType:   item.availableTrack.trackType,
                country:     item.availableTrack.country
            };

            const paidUpFront = item.availableRace.customerAvailability === enumEcommerceCustomerAvailability.REFUND_WITH_BET;
            this._ecommerceService.insertPromise(product, paidUpFront).toPromise().then((promise) => {
                if (promise.promiseID) {
                    item.availableRace.customerAvailability = enumEcommerceCustomerAvailability.VIEW;
                    item.availableRace.purchaseStatus = enumEcommercePurchaseStatus.PURCHASED;
                    this._changeDetectorRef.markForCheck();
                    this.displaySuccess('<i class="icon--check-light-circle"></i> Transaction successful');
                    setTimeout(() => this.download(item), 1000);
                } else {
                    throw new Error('Unable to create promise. Contact Player Services for assistance.');
                }
            }).catch((e) => {
                if (e?.data?.status === 402 || e?.data?.message?.includes('NSF Error: true')) {
                    item.errorMessage = 'Insufficient funds.';
                    item.showDepositLink = true;
                } else {
                    item.errorMessage = 'Unexpected error. Please try again later.';
                    item.showDepositLink = false;
                }
            }).finally(() => {
                item.isPending = false;
                this._changeDetectorRef.markForCheck();
            });
        }
    }

    public isProductOwned(item: PastPerformancesMenuItem) {
        return item.availableRace?.customerAvailability === enumEcommerceCustomerAvailability.VIEW;
    }

    public getCustomerPrice(item: PastPerformancesMenuItem) {
        if (item.availableRace.purchaseStatus === enumEcommercePurchaseStatus.PURCHASED) {
            return 0;
        } else if (item.availableProduct.customerPrice !== null) {
            return item.availableProduct.customerPrice;
        } else {
            return item.menuItem.item.price;
        }
    }

    public goToFullHandicapping() {
        window.open(this._ecommerceService.getFullHandicappingURL());
    }
}
