import { Injectable } from '@angular/core';
import { IVideoStreamResponse } from '@cdux/ng-common';
import { IVideoStreamSource } from './video-stream-source.interface';
import { ReplayBusinessService } from '../../../services/replay-business.service';
import { ReplayProviderService } from '../../video-provider.service';

@Injectable()
export class RCNReplayStreamSource implements IVideoStreamSource {
    constructor(private replayBusinessService: ReplayBusinessService, private replayProviderService: ReplayProviderService) {}

    public getStreamData (): Promise<IVideoStreamResponse> {
        return this.replayBusinessService.getReplayStream(
            this.replayProviderService.trackCode,
            this.replayProviderService.trackType,
            this.replayProviderService.raceDate,
            this.replayProviderService.raceNum,
            this.replayProviderService.providerName,
            this.replayProviderService.options
        );
    }
}
