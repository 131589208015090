import { Component } from '@angular/core';
import { enumPoolType, ToteDataService } from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { PoolsSectionComponent } from './pools-section.component';

@Component({
    selector: 'cdux-pools-sidebar',
    templateUrl: 'pools-sidebar.component.html',
    styleUrls: ['./pools-sidebar.component.scss']
})
export class PoolsSidebarComponent extends PoolsSectionComponent {
    constructor (
        _eventTrackingService: EventTrackingService,
        _toteDataService: ToteDataService,
        _viewStateService: ViewStateService
    ) {
        super(_eventTrackingService, _toteDataService, _viewStateService);
        this.section = ViewSectionEnum.POOLS; // not using input for now
    }

    // bypassing the section input/outputs to isolate it from view cache
    public viewPools() {
        super.viewPools();
        this.section = ViewSectionEnum.POOLS;
    }
    public viewProbables() {
        super.viewProbables();
        this.section = ViewSectionEnum.PROBABLES;
    }
    public viewWillPays() {
        super.viewWillPays();
        this.section = ViewSectionEnum.WILL_PAYS;
    }

    public hasOffloadedContent(): boolean {
        return this.section === ViewSectionEnum.POOLS || (
            this.section === ViewSectionEnum.PROBABLES && (
                this.probablesAvailablePoolTypes[enumPoolType.EXACTA] ||
                this.probablesAvailablePoolTypes[enumPoolType.QUINELLA] ||
                this.probablesAvailablePoolTypes[enumPoolType.DAILY_DOUBLE]
            )
        );
    }
}
