import { SharedEventKeys } from '@cdux/ng-common';

export class EventKeys extends SharedEventKeys {
    static PROGRAM_PROBABLES_VISIBILITY = 'program:probables:visibility';
    static PROGRAM_POOLS_VISIBILITY = 'program:pools:visibility';
    static PROGRAM_WILLPAYS_VISIBILITY = 'program:willpays:visibility';
    static PROGRAM_VIDEO_VISIBILITY = 'program:video:visibility';
    static MAIN_SCROLLING = 'main:scrolling';

    static RACE_STATS_CLOSE = 'stats:close';
    static RACE_STATS_OPEN = 'stats:open';

    static HANDICAPPING_TOGGLE = 'handicapping:toggle';

    static RACE_RESULTS_IN = 'race:hasResults';

    static RACE_NAV_TRACK_CHANGE = 'race:nav:trackChange';
    static RACE_NAV_RACE_CHANGE = 'race:nav:raceChange';

    static VIDEO_PRIMARY_OPEN = 'video:primary:open';
    static VIDEO_PRIMARY_CLOSE = 'video:primary:close';
    static VIDEO_PRIMARY_TUNE = 'video:primary:tune';
}
