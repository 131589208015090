export const LANG_PAYPAL_NAME = 'paypal';

export const LANG_PAYPAL_TRANS = {
  'description': 'Deposit quickly and securely using your PayPal account.',
  'tile-icon': '<div class="icon--vendor-logo"><i class="icon--pp"></i></div>',
  'header-icon': '<div class="icon--vendor-logo"><i class="icon--pp one-line white"></i></div>',
  'header-account-info': ' ',
  'deposit-instructions': 'You will be taken to PayPal to login.',
  'pending-balance-title': 'Pending PayPal Deposit',
  'pending-balance-info': 'These funds will be available to withdraw once your PayPal deposit has cleared.',
  'withdraw-account-header': 'PayPal Account:',
  'withdraw-success-msg-amount': '%s has been withdrawn to your PayPal Account:',
  'withdraw-success-msg-delay': 'Please allow a few hours for the funds to be placed into your PayPal account.',
  'deposit-canceled': 'Your PayPal deposit request was canceled.'
};
