import { Component, ChangeDetectorRef, ViewContainerRef, ViewChild, OnInit } from '@angular/core';

import { IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';
import { RCNBaseVideoFeed } from './rcn-base-video-feed.class';
import { RCNVideoStreamSource } from './rcn-stream-sources/rcn-video-stream-source.service';

import { VideoProviderService } from '../video-provider.service';
import { VideoService } from 'app/wagering/video/services/video.service';


@Component({
    selector: 'cdux-rcn-html-video-feed',
    template: `<div #streamContainer><video id='rcnHtmlVideo' [src]='videoSrc' [muted]='muted' autoplay controls playsinline></video></div>`
})
export class RCNHTMLVideoFeedComponent extends RCNBaseVideoFeed implements OnInit {
    @ViewChild('streamContainer', { read: ViewContainerRef })
    protected container: ViewContainerRef;

    public videoSrc: string;
    public muted: boolean;

    constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected streamSource: RCNVideoStreamSource,
        protected videoProviderService: VideoProviderService,
        private videoService: VideoService
    ) {
        super(changeDetectorRef, videoProviderService, streamSource);
        this.muted = this.videoService.getMuted();
    }

    ngOnInit() {
        super.ngOnInit();
        const videoPlayer = document.querySelector('#rcnHtmlVideo');
        if (videoPlayer) {
            videoPlayer.addEventListener('volumechange', (change) => this.videoService.setMuted(change.target['muted']));
        }
    }

    protected handleStreamData (streamData: IVideoStreamResponses) {
        const streamUrl: string = this.getUrlFromCurrentStream();

        if (streamUrl) {
            this.videoSrc = streamUrl;
            this.resize();
        }
    }

    // no-op overrides
    public pause() {}
    public start() {}
    public stop() {}
}
