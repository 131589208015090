import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CduxModalModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from '../common/common.module';

import { DatepickerComponent, DatepickerBarComponent, DatepickerContentComponent } from './components';
import { DatepickerTriggerDirective } from './directives';

@NgModule({
    declarations: [
        DatepickerComponent,
        DatepickerBarComponent,
        DatepickerContentComponent,
        DatepickerTriggerDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        TuxCommonModule,
        CduxModalModule,
    ],
    exports: [
        // Export ModalModule Dependency
        CduxModalModule,
        DatepickerComponent,
        DatepickerBarComponent,
        DatepickerContentComponent,
        DatepickerTriggerDirective,
    ]
})
export class DatepickerModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<DatepickerModule> {
        return {
            ngModule: DatepickerModule,
            providers: [
            ],
        }
    }
}
