import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
    DamInfo,
    DetectionService,
    enumTrackType,
    EventClickType,
    GetRunnerDetailsDataService
} from '@cdux/ng-common';
import { LoadingService } from '@cdux/ng-fragments';

import { CduxRouteUtil } from 'app/shared';
import { BreadcrumbsService } from 'app/shared/breadcrumbs';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { enumDetailsToggleLabel } from './details-toggle.enum';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-dam-details',
    templateUrl: './dam-details.component.html',
    styleUrls: ['./dam-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DamDetailsComponent implements OnInit, OnDestroy, IDetails {

    public showDamSummary: boolean = false;
    public showDamOffspring: boolean = false;
    public showDamRecord: boolean = false;
    private _damInfo: DamInfo;
    public set damInfo(value: DamInfo) {
        this._damInfo = value;
        this.showDamRecord = !!value && !!value.bdsDamSummary && !!value.bdsDamSummary.damRecord
        this.showDamSummary = !!value && !!value.bdsDamSummary;
        this.showDamOffspring = !!value && !!value.bdsDamOffspringStats;
    }
    public get damInfo(): DamInfo {
        return this._damInfo;
    }
    public loaded: boolean = false;
    public offspringStatsToggleLabel: enumDetailsToggleLabel = enumDetailsToggleLabel.MORE;
    public offspringStatDisplayLimit: number = 4;

    private _damInfoSub: Subscription;

    private _destroy: Subject<any> = new Subject<any>();

    public brisCode: string;
    public trackType: enumTrackType;
    public raceNum: number;
    public programNum: string;
    public isInline: boolean = true;
    public isDesktop: boolean = false;
    public isPhone: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbService: BreadcrumbsService,
        private _detectionService: DetectionService,
        private _runnerDetailsService: GetRunnerDetailsDataService,
        private _loadingService: LoadingService,
        private _headerService: HeaderService,
        private _eventService: EventTrackingService,
        private _changeDetector: ChangeDetectorRef,
        private _mediaService: CduxMediaToggleService
    ) {
        // Empty
    }

    public ngOnInit() {
        this.isDesktop = this._detectionService.isDesktop();

        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    this.isInline = false;

                    const params = CduxRouteUtil.extractParams(this._activatedRoute);

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum,
                        damId: params.damId,
                    };

                    this.init(options);
                }
            }
        );
    }

    public ngOnDestroy(): void {
        this._loadingService.resolve('routeLoader', 500, 'void');
        this._headerService.setDefaultHeader();
        if (this._damInfoSub) {
            this._damInfoSub.unsubscribe();
            this._damInfoSub = null;
        }

        this._destroy.next();
        this._destroy.complete();
    }

    public getRowClass(index: number) {
        return index % 2 ? 'zebra-striped-tr-dark' : 'zebra-striped-tr-light';
    }

    public toggleShowAllStats() {
        this._eventService.logClickEvent(EventClickType.PROGRAM_DAM_DETAILS_MORE);
        this.offspringStatDisplayLimit = this.offspringStatDisplayLimit === 4 ? -1 : 4;

        // toggle label
        if (this.offspringStatsToggleLabel === enumDetailsToggleLabel.MORE) {
            this.offspringStatsToggleLabel = enumDetailsToggleLabel.LESS;
        } else if (this.offspringStatsToggleLabel === enumDetailsToggleLabel.LESS) {
            this.offspringStatsToggleLabel = enumDetailsToggleLabel.MORE;
        }
        this._changeDetector.detectChanges();
    }

    public init(options: IDetailsOptions) {
        this.brisCode = options.brisCode;
        this.trackType = options.trackType;
        this.raceNum = options.raceNumber;
        this.programNum = options.programNumber;

        if (options.damId) {
            this._damInfoSub = this._runnerDetailsService.getAncestorDetails(options.damId, 'DAM').subscribe((res: DamInfo) => {
                this._loadingService.resolve('routeLoader', 500, 'void');
                this.loaded = true;
                this.damInfo = res;
                this._breadcrumbService.addCurrentRoute(this.damInfo.bdsDamSummary.horseName);
                this.offspringStatDisplayLimit = 4;
                this._headerService.updateHeader({ staticContent: this.damInfo.bdsDamSummary.horseName });
                this._changeDetector.detectChanges();
            }, (err) => {
                this._loadingService.resolve('routeLoader', 500, 'void');
                this.loaded = true;
                this.damInfo = null;
            });
        }

        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.markForCheck();
        });
    }

}
