import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuxCommonModule } from '../common/common.module';

import { EcommerceBusinessService } from './services/ecommerce.business.service';

@NgModule({
    imports: [
        CommonModule,
        TuxCommonModule,
    ],
})
export class TuxEcommerceModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<TuxEcommerceModule> {
        return {
            ngModule: TuxEcommerceModule,
            providers: [
                EcommerceBusinessService,
            ],
        }
    }
}
