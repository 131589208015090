import { Injectable } from '@angular/core';
import { IEntryMap } from '../interfaces/selected-entry.interface';
import { ILegInfo } from 'app/shared/program/interfaces/leg-info.interface';
import { ProgramEntry } from '@cdux/ng-common';

@Injectable()
export class RunnerBallotEntriesService {
    public mapSelectedEntries(updatedEntries: ProgramEntry[][], legInfo: ILegInfo): IEntryMap[][] {
        const condensedEntries: IEntryMap[][] = [];
        // TODO: Eliminate need to null check updatedEntries array index to prevent
        // console error when betting interest updates and updated entries fire out
        // of sync. Right now, the null checks prevent errors and the condensed entries
        // clean themselves up.
        if (!!updatedEntries && updatedEntries.length > 0) {
            let condensedEntriesLegs: number = 1;
            if (legInfo.multiRace) {
                condensedEntriesLegs = legInfo.legs;
            } else if (legInfo.legsCounter.length > 0) {
                condensedEntriesLegs = legInfo.legsCounter.length;
            }
            for (let i = 0; i < condensedEntriesLegs; i++) {
                // For single race wagers, the one and only list of entries is used to
                // populate each leg of the 2-D condensed entry array.
                const legIndex: number = legInfo.multiRace ? i : 0;
                const programEntries: ProgramEntry[] = !!updatedEntries[legIndex] ? updatedEntries[legIndex] : [];
                if (programEntries.length > 0) {
                    condensedEntries[i] = this._getCondensedEntriesForLeg(programEntries, legInfo.multiRace);
                }
            }
        }
        return condensedEntries;
    }

    private _getCondensedEntriesForLeg(programEntries: ProgramEntry[], padEmptyPositions: boolean): IEntryMap[] {
        const condensedEntries: IEntryMap[] = [];
        programEntries.forEach((currentEntry) => {
            const previousBetInterest: IEntryMap = !!condensedEntries[condensedEntries.length - 1] ? condensedEntries[condensedEntries.length - 1] : null;
            if (previousBetInterest?.bettingInterest && previousBetInterest.bettingInterest === currentEntry.BettingInterest) {
                // A betting interest is only scratched if all corresponding entries are scratched
                previousBetInterest.scratched = previousBetInterest.scratched && currentEntry.Scratched;
            } else {
                if (padEmptyPositions) {
                    condensedEntries[currentEntry.BettingInterest - 1] = {
                        bettingInterest: currentEntry.BettingInterest,
                        programNumber: currentEntry.ProgramNumber,
                        scratched: currentEntry.Scratched
                    };
                } else {
                    // Add the new betting interest to the condensed entries for the leg
                    condensedEntries.push({
                        bettingInterest: currentEntry.BettingInterest,
                        programNumber: currentEntry.ProgramNumber,
                        scratched: currentEntry.Scratched
                    });
                }
            }
        });
        return condensedEntries;
    }

    public generateSaddleClothList(entryMap: IEntryMap[][], multiRace: boolean): number[] {
        let saddleCloth = [];
        if (!!entryMap && entryMap.length > 0) {
            if (multiRace) {
                const lastProgramNumberArray = entryMap.map(leg => {
                    let highest = 0;
                    leg.forEach(entry => {
                        if (!highest || (!!entry && entry.bettingInterest > highest) ) {
                            highest = entry.bettingInterest;
                        }
                    });
                    return highest;
                });
                const maxProgramNumber = Math.max(...lastProgramNumberArray);
                saddleCloth = Array(maxProgramNumber).fill(0).map((_, index) => index + 1);
            } else {
                entryMap[0].forEach(entry => {
                    if (saddleCloth.length === 0 || !entry.bettingInterest || saddleCloth[saddleCloth.length - 1] !== entry.bettingInterest) {
                        saddleCloth.push(entry.bettingInterest || entry.programNumber);
                    }
                });
            }
        }
        return saddleCloth;
    }
}
