import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'cdux-funding-deposit-button',
    template: `
    <div class="cdux-button-wrapper" [ngSwitch]="inboundBet > 0">
      <button #depositButton
        id="depositButton"
        *ngSwitchDefault
        (click)="emitClick($event)"
        [disabled]="disabled">
          Deposit
      </button>
      <button #depositButton
        id="depositBetButton"
        *ngSwitchCase="true"
        (click)="emitClick($event)"
        [disabled]="disabled">
          Deposit & Bet
      </button>
    </div>`
})
export class FundingDepositButtonComponent {

    private _disabled = false;
    @Input() public set disabled(set) {
        this._disabled = set;
        this._changeDectectorRef.detectChanges();
    }
    public get disabled() {
        return this._disabled;
    }
    @Input() public inboundBet = 0;

    @Output() public onClick: EventEmitter<Event> = new EventEmitter();

    public emitClick(event: Event) {
        this.onClick.emit(event);
    }

    constructor(
        private _changeDectectorRef: ChangeDetectorRef
    ) { }

}
