<section class="quick-pick-container" *ngIf="isExpanded || displayMode === DisplayModeEnum.LARGE; else QuickPickSideTab" [ngClass]="'quick-pick-container' + (isExpanded ? '--expanded' : '')">
    <div [ngClass]="'quick-pick__header' + (isExpanded ? '--expanded' : '')" (click)="toggle()">
        <div class="quick-pick__header__title">{{ poolType?.displayName || poolCode }} Quick Pick</div>
        <i class="icon-expand-arrow" [class.is-active]="isExpanded"></i><!-- add is-active on header click-->
    </div>

    <div [ngClass]="'quick-pick__body' + (isExpanded ? '--expanded' : '')">
        <div class="quick-pick__body__content--animated" *ngIf="isWagerableRace && isPoolAvailable; else NoBets">
            <div class="error-message" *ngIf="wagerError">
                {{ wagerError }}
            </div>
            <div class="tool-tip">
                <cdux-tooltip class="quick-pick-tooltip" [activatedOn]="'click'" [position]="'bottom'" tooltipTitle="Quick Pick">
                    <span tooltip-toggle>
                        <i class="icon--info"></i>&nbsp;What is Quick Pick?
                    </span>
                    <span tooltip-content>
                        <h1 class="quick-pick-tooltip-header">Quick Pick</h1>
                        <p class="quick-pick-tooltip-info">
                            You choose the number of runner combinations, decide if you want to include
                            the Morning Line favorite, and let the system randomly generate your ticket.
                            The cost of your Quick Pick is calculated based on the number of different
                            possible runner combinations. If the horses in your Quick Pick finish first,
                            second, and third (in any order), you win!
                        </p>
                    </span>
                </cdux-tooltip>
            </div>

            <div class="quick-pick__wager-options">
                <cdux-quick-pick-dropdown [dropupHeaderText]="'Runner Count'" [list]="runnerCountOptions" [(ngModel)]="selectedRunnerCount"></cdux-quick-pick-dropdown>
                <cdux-quick-pick-dropdown [dropupHeaderText]="'Bet Amount'" [list]="betAmountOptions" [(ngModel)]="selectedBetAmount"></cdux-quick-pick-dropdown>
            </div>

            <div class="quick-pick__includes" [class.disabled]="!hasFavorite || mustIncludeFavorite">
                <div class="checkbox no-margin">
                    <input id="quick-pick__include_ml_favorite" type="checkbox" [disabled]="!hasFavorite || mustIncludeFavorite" [ngModel]="includeFavorite" (ngModelChange)="setIncludeFavorite($event)" />
                    <label for="quick-pick__include_ml_favorite"></label>
                </div>
                <div class="quick-pick__includes__text">
                    Include Morning Line Favorite
                </div>
            </div>

            <div class="quick-pick__runner-selections" title="Click to refresh" (click)="refresh()">
                <div class="quick-pick__runner-list" *ngIf="!isLoading">
                    <div *ngFor="let entry of randomEntries" [ngClass]="getSaddleClothClass(entry)">
                        {{ entry.BettingInterest }}
                    </div>
                </div>
                <i [ngClass]="'icon-refresh' + (isLoading ? '__spinning' : '')"></i>
            </div>

            <div class="quick-pick__submit-wager">
                <div [style.visibility]="!isLoading && wagerAmount > 0 ? 'visible' : 'hidden'">
                    Total Cost: {{ wagerAmount | currency:'USD':'symbol-narrow':getDigitsInfo(wagerAmount) }}
                </div>
                <button class="button-action-outline-white large" [disabled]="!canSubmit()" (click)="submit()">
                    <ng-container *ngIf="!isLoading && !isSubmitting; else loadingDots">
                        Bet
                        <ng-container *ngIf="wagerAmount > 0">
                            &nbsp;{{ wagerAmount | currency:'USD':'symbol-narrow':getDigitsInfo(wagerAmount) }}
                        </ng-container>
                    </ng-container>
                    <ng-template #loadingDots>
                        <div class="loader-block">
                            <div class="loader-dots">
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                                <i class="dots-animated"></i>
                            </div>
                        </div>
                    </ng-template>
                </button>
            </div>
            <div class="quick-pick__terms-and-conditions">
                <div>By placing bet, you agree to the <a cduxTerms="{{ TERM_TYPE }}">Terms and Conditions</a>.</div>
            </div>
        </div>

        <ng-template #NoBets>
            <div class="quick-pick__body__content--animated no-bets">
                <div *ngIf="!isWagerableRace">Race is no longer accepting wagers.</div>
                <div *ngIf="isWagerableRace && !isPoolAvailable">Wager type is not available for this race.</div>
            </div>
        </ng-template>
    </div>
</section>

<ng-template #QuickPickSideTab>
    <div class="quick-pick-side-tab has-cursor-finger" (click)="toggle()">
        <div class="quick-pick-side-tab__dot"></div><span class="quick-pick-side-tab__label">Quick Pick</span><i class="icon-expand-arrow"></i>
    </div>
</ng-template>
