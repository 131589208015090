import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CduxSidebarContentComponent } from '../../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../../../../menu-items/enums/menu-items.enum';
import { EventClickType, IPlayerRewards, IRedeemRewardsResponse, TranslateService } from '@cdux/ng-common';
import { PlayerRewardsBusinessService } from '../../services/rewards.business.service';
import { LoadingDotsComponent, LoadingService } from '@cdux/ng-fragments';
import { take } from 'rxjs/operators';
import { CduxMediaToggleService, CduxModalConfig, ModalService, MultipleOfValidator } from '@cdux/ng-platform/web';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { RewardsSuccessComponent } from '../rewards-redemption-success/rewards-redemption-success.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { coerceToNumber } from '../../../../common/utils';
import { Subscription } from 'rxjs';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

enum rewardsDisplayStatus {
    POINTS,
    ERROR,
    SUCCESS
}

@Component({
    selector: 'cdux-rewards-container',
    templateUrl: './rewards-container.component.html',
    styleUrls: ['./rewards-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RewardsContainerComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {
    private static readonly maskKey = /[0-9]/;

    public playerRewards: IPlayerRewards;
    public loadingDotsComponent = LoadingDotsComponent;
    public redemptionText: string;
    public message: string;
    public inputError: string;
    public submitting: boolean = false;
    public displayState: rewardsDisplayStatus;
    public rewardsDisplayStatus = rewardsDisplayStatus;
    public isMobile: boolean = false;
    public eventClickType = EventClickType;
    public form: UntypedFormGroup;
    private _sub: Subscription[] = [];
    public min: number;
    public max: number;
    public value: number;
    public smallTextClass: boolean;
    public tournamentSelected = false;
    public rewardsMask = {
        mask: [],
        guide: false,
        placeholder: ''
    };
    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */
    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: RewardsContainerComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'rewards-title',
            translateLanguage: 'my-account',
            eventClickType: EventClickType.MY_ACCOUNT_REWARDS_BACK_BUTTON
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(private _playerRewardsBusinessService: PlayerRewardsBusinessService,
                private _loadingService: LoadingService,
                private _translateService: TranslateService,
                private _mediaService: CduxMediaToggleService,
                private _sidebarService: SidebarService,
                private _fb: UntypedFormBuilder,
                private _modalService: ModalService,
                private _tournament: TournamentsSessionService
                ) {
        super();
    }

    ngOnInit() {

        this._sub.push(this._tournament.onTournamentSelection
            .subscribe(
                (res) => {
                    this.tournamentSelected = res ? true : false;
                }
            ));

        this._loadingService.register('rewardsOverlay');
        this._getRewards();
    }

    ngOnDestroy() {
        this._sub.forEach(sub => sub.unsubscribe());
    }

    /**
     * get the rewards points
     * @private
     */
    private _getRewards(): void {
        this._playerRewardsBusinessService.getRewards().pipe(take(1)).subscribe(
            (rewards: IPlayerRewards) => {
                if (rewards) {
                    this._loadingService.resolve('rewardsOverlay', 100, 'success');
                    this.displayState = rewardsDisplayStatus.POINTS;
                    let minRedemption = false;
                    if (this._playerRewardsBusinessService.minRedemption(rewards)) {
                        minRedemption = true;
                        this.redemptionText = this._translateService.translate('rewards-redeem', 'my-account');
                    } else {
                        this.redemptionText = this._translateService.translate('rewards-minimum', 'my-account', false, rewards.pointsRedemptionMinimum);
                    }
                    this.playerRewards = rewards;
                    this._initForm(this.playerRewards, minRedemption);
                }
            },
            (errMessage) => {
                if (!!errMessage) {
                    this.displayState = rewardsDisplayStatus.ERROR;
                    this._loadingService.resolve('rewardsOverlay', 100, 'success');
                    this.message = errMessage;
                }
            }
        );
    }

    private _initForm(rewards: IPlayerRewards, minAmount: boolean): void {
        if (rewards.totalPointsAvailable < rewards.pointsRedemptionMinimum) {
            this.value = 0;
            this.min = rewards.pointsRedemptionMinimum;
            this.max = rewards.pointsRedemptionMaximum;
        } else if (rewards.totalPointsAvailable > rewards.pointsRedemptionMaximum) {
            this.min = rewards.pointsRedemptionMinimum;
            this.max = rewards.pointsRedemptionMaximum;
            this.value = this.max;
        } else {
            this.min = rewards.pointsRedemptionMinimum;
            this.max = this._playerRewardsBusinessService.getRedeemPoints(rewards);
            this.value = this.max;
        }

        // set mask to same number of digits as max
        const maxLength = this.max.toString().length;
        for (let i = 0; i < maxLength; i++) {
            this.rewardsMask.mask.push(RewardsContainerComponent.maskKey);
        }

        this.form = this._fb.group({
            // if less than min disable input and submit button
            amount: new UntypedFormControl({value: this.value, disabled: !minAmount}, [
                Validators.required,
                Validators.min(this.min),
                Validators.max(this.max),
                Validators.pattern('[0-9]+'),
                MultipleOfValidator.multipleOf(rewards.pointsRedemptionMultiple)
            ])
        });
        this.smallTextClass = this._useSmallTextClass(this.value);
        this._sub.push(this.form.statusChanges.subscribe(() => {
            this.value = coerceToNumber(this.form.get('amount').value);
            this.smallTextClass = this._useSmallTextClass(this.value);

            const control = this.form.get('amount');
            if (this.form.invalid) {
                if (control.errors['max']) {
                    this.inputError = this._translateService.translate('rewards-max-error', 'my-account');
                } else if ( control.errors['notMultipleOf']) {
                    this.inputError = this._translateService.translate('rewards-multiple-error', 'my-account', false, rewards.pointsRedemptionMultiple);
                }  else {
                    // show no error for below minimum
                    this.inputError = null;
                }
            } else {
                this.inputError = null;
            }
        }));
    }

    /**
     * redeem the rewards points
     * close the sidebar after showing success message if desktop
     * open success message in modal if mobile
     */
    public redeemRewards() {
        if (this.form.disabled || this.form.invalid || this.submitting) {
            return;
        }
        this.submitting = true;
        const amount = coerceToNumber(this.form.get('amount').value);
        this._playerRewardsBusinessService.redeemRewards(amount).pipe(take(1)).subscribe(
            (redeemRewards: IRedeemRewardsResponse) => {
                this.displayState = rewardsDisplayStatus.SUCCESS;
                this.submitting = false;
                if (redeemRewards) {
                    this.message = this._translateService.translate('rewards-redemption-success', 'my-account', false, redeemRewards.dollarAmountReceieved.toFixed(2));
                    if (this._mediaService.query('phone'))  {
                        this.isMobile = true;
                        const modalConfig: CduxModalConfig = {
                            width: '100%',
                            height: '100%',
                            maxWidth: '100vw',
                            maxHeight: '100vh',
                            padding: '0px',
                            context: {
                                message: this.message,
                                isMobile: this.isMobile
                            }
                        };
                        this._modalService.open(RewardsSuccessComponent, modalConfig);
                    } else {
                        setTimeout(() => {
                            this._sidebarService.close();
                        }, 5000)
                    }
                }
            },
            (errMessage) => {
                if (!!errMessage) {
                    this.submitting = false;
                    this.displayState = rewardsDisplayStatus.ERROR;
                    this.message = errMessage;
                }
            }
        );
    }

    private _useSmallTextClass(val: number): boolean {
        return !!val && val.toString().length >= 6;
    }
}
