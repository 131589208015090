import {Injectable} from '@angular/core';
import {
    Bet,
    BetAmountType,
    Entry,
    enumBetSubtype,
    ILabelValuePair,
    IPoolType,
    ISelectedBetAmount,
    WagerService
} from '@cdux/ng-common';
import {combineLatest, empty, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {BetSlipBusinessService} from './bet-slip.business.service';

export interface BetNavValueChange {
    amount?: ISelectedBetAmount;
    type?: {label: string, value: IPoolType};
    subType?: {label: string, value: enumBetSubtype};
}

export class BetNavDropdownData {
    public selectedBetAmount: ISelectedBetAmount;
    public selectedBetType: ILabelValuePair;
    public selectedBetSubType: {label: string, value: enumBetSubtype};
    public betSubTypeOptions = [];
    public betTypeOptions: Array<ILabelValuePair> = [];
    public betAmountOptions: ISelectedBetAmount[] = [];
}

@Injectable()
export class BetNavBusinessService {

    private _triggerValueChange: Subject<BetNavValueChange> = new Subject();

    constructor(
        private _wagerService: WagerService,
        private _betSlipService: BetSlipBusinessService,
    ) {
        // Empty
    }

    public watchChangeTriggers(): Observable<BetNavValueChange> {
        return this._triggerValueChange.asObservable();
    }

    public triggerValueChange(changes: BetNavValueChange) {
        this._triggerValueChange.next(changes);
    }

    /**
     * Fetches bet nav data.
     *
     * @param {Bet} bet
     * @param {boolean} raceChanged - Used to determine if the bet list should be refreshed.
     * @returns {Observable<BetNavDropdownData> | null}
     */
    public fetchBetNavDropdownData(bet?: Bet, raceChanged: boolean = true): Observable<BetNavDropdownData> | null {

        if (!bet) {
            bet = this._betSlipService.currentBet;
        }

        if (!bet || !bet.track || !bet.race) {
            return empty();
        }

        const amountObs = this._wagerService.getBetAmounts(
            bet.track.BrisCode,
            bet.track.TrackType,
            bet.race.race,
            bet.poolType && bet.poolType.Code ? bet.poolType.Code : undefined,
            raceChanged);

        const typeObs = this._wagerService.getBetTypes(
            bet.track.BrisCode,
            bet.track.TrackType,
            bet.race.race,
            raceChanged);

        const poolsObs = this._wagerService.getPoolTypes();

        const legLimitObs = this._wagerService.getLegLimit();

        return combineLatest([typeObs, amountObs, poolsObs, legLimitObs]).pipe(
            map(([betTypeData, betAmountData, poolsData, legLimitData]) => {
                const config = new BetNavDropdownData();

                // Filter out any Bet Types with more legs than configured legLimit
                config.betTypeOptions = betTypeData.filter(b => +b.value.Legs <= legLimitData);

                const allowedAmounts: ISelectedBetAmount[] = betAmountData.map(amount => {
                    return {value: amount, type: BetAmountType.PROVIDED};
                });
                config.betAmountOptions = allowedAmounts;
                bet.allowedAmounts = allowedAmounts;

                // Set the dropdown values from appropriate bet type information for track/race
                config.selectedBetType = (
                    !!bet.poolType
                    && !!bet.poolType.Code
                    && config.betTypeOptions.find((v) => v.value.Code === bet.poolType.Code)
                )
                    ? config.betTypeOptions.filter(option => option.value.Code === bet.poolType.Code)[0]
                    : config.betTypeOptions[0];

                if (config.selectedBetType) {
                    bet.poolType = config.selectedBetType.value;
                } else {
                    bet.poolType = {} as IPoolType;
                }
                // if bet amount matches betListOption, select option
                // else if custom amount select custom amount, else take first option in bet list
                const filteredBetAmounts = config.betAmountOptions.filter(option => option.value === bet.amount.value);
                if (filteredBetAmounts[0]) {
                    config.selectedBetAmount = filteredBetAmounts[0];
                } else {
                    if (!!bet.amount.value && bet.amount.type === BetAmountType.CUSTOM) {
                        config.selectedBetAmount = bet.amount;
                    } else {
                        config.selectedBetAmount = config.betAmountOptions[0];
                    }
                }
                bet.amount = config.selectedBetAmount;


                config.betSubTypeOptions = this._wagerService.getBetSubTypesForPool(bet.poolType || poolsData[0]);
                config.selectedBetSubType = config.betSubTypeOptions
                    .filter(option => option.value === bet.betSubtype)[0]
                    || config.betSubTypeOptions[0];

                if (config.selectedBetSubType) {
                    bet.betSubtype = config.selectedBetSubType.value;
                } else {
                    bet.betSubtype = enumBetSubtype.STRAIGHT;
                }

                // updating currentBet
                this._betSlipService.currentBet = bet;

                return config;
            })
        );
    }
    /**
     *
     * @param legs - number of legs from the original sub type
     * @param runnersArray -> array of runners
     * @param originSubtype -> bet subtype origin
     * @param destinationSubType -> bet subtype that was clicked
     */
    public processRunners(legs: number, runnersArray: Entry[][], originSubtype: enumBetSubtype, destinationSubType: enumBetSubtype): Entry[][] {
        if (!runnersArray || runnersArray.length === 0) {
            return [];
        }
        // check the destination to see how we should process the runners.
        switch (destinationSubType) {
            case enumBetSubtype.BOX:
                runnersArray = this.processRunnersToBox(runnersArray);
                break;
            case enumBetSubtype.KEY:
                if (this.isSingleOrEmptyRunner(runnersArray[0])) {
                    runnersArray = this.processRunnersToKey(runnersArray, legs, 1, 1);
                } else {
                    // if there are multiple runners in the first leg, clear the whole array.
                    runnersArray = [];
                }
                break;
            case enumBetSubtype.KEYBOX:
                /**
                 * for the key || keybox subtype, check to see if there is only a single runner in the first leg.
                 * if only a single runner, retain the runner in the first leg, and retain all
                 * other selected runners from all other positions in the 2nd position for keyboxes,
                 * or in the nth position for key wagers.
                 */
                if (this.isSingleOrEmptyRunner(runnersArray[0])) {
                    runnersArray = this.processRunnersToKeyBox(runnersArray);
                } else {
                     // if there are multiple runners in the first leg, clear the whole array.
                    runnersArray = [];
                }
                break;
            case enumBetSubtype.STRAIGHT:
                 // with this destination, we want to retain all selected runners into each position.
                if (originSubtype === enumBetSubtype.BOX) {
                    runnersArray = this.processStraightRunners(runnersArray, legs, 0, 1);
                }
                /**
                 * When a user constructs a keybox and changes the modifier
                 * to straight, retain the horses selected in the key leg
                 * and retain the horses in box and populate those horses
                 *  in the other legs of the straight wager.
                 */

                if (originSubtype === enumBetSubtype.KEYBOX) {
                    runnersArray = this.processStraightRunners(runnersArray, legs, 1, 1);
                }

                break;
            default:
                runnersArray = [];
        }
        return this.removeDuplicatesFromRunners(runnersArray);
    }


    /**
     *
     * @param runners the leg that is processed to
     * find whether a single runner is in a particular leg
     * NOTE: we want to also check to see if
     * there are no runners as well, which functions the same as
     * a single runner being processed. so you see no runners returning
     * true for that
     */
    private isSingleOrEmptyRunner(runners: Entry[]): boolean {
        if (this.noRunners(runners)) {
            return true;
        }
        const programNumber = runners[0].ProgramNumberCoupled;
        let flag: boolean = true;
        for (let i = 0; i < runners.length; i++) {
            if (programNumber !== runners[i].ProgramNumberCoupled) {
                flag = false;
            }
        }
        return flag;
    }

    public noRunners(runners: Entry[]): boolean {
        if (!runners || typeof runners === 'undefined' || runners.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    public removeDuplicatesFromRunners(runners: Entry[][]): Entry[][] {
        runners.forEach((runner, i) => {
            runners[i] = runner.filter((item, index, self) =>
                index === self.findIndex((t) => (t.ProgramNumber === item.ProgramNumber && t !== null))
            )
        });
        return runners;
    }

    /**
     *
     * @param runners = array of runners to be modified
     * @param legs  - how many legs are in this specific bet type
     * @param arrayToCopy  = the array index we want to copy
     * @param startingIndex the starting index to start the copy
     */
    public processStraightRunners(runners: Entry[][], legs: number, arrayToCopy: number, startingIndex: number): Entry[][] {
        const nonKeyLegs = legs - 1;
        const legSelection = ~1 << 31 - nonKeyLegs >>> 31 - nonKeyLegs;
        const newRunnersJson = JSON.stringify(runners[arrayToCopy].map(e => {
            if (arrayToCopy === 0) {
                e.leg = legSelection + 1;

            } else {
                e.leg = legSelection

            }
            return e;
        }));
        runners.length = legs;
        for ( let i = startingIndex; i < runners.length; i ++) {
            if (this.noRunners(runners[arrayToCopy])) {
                runners[i] = [];
            } else {
                runners[i] = JSON.parse(newRunnersJson);
            }
        }

        return runners;
    }

    public processRunnersToKey(runners: Entry[][], legs: number, arrayToCopy: number, startingIndex: number): Entry[][] {
        /**
         * we want to add every runner in each
         * position to the first leg, then we iterate
         * through assigning the leg value using the straight
         * runners function, since they function the same
         */
        for (let i = 2; i < legs; i++) {
            if (!this.noRunners(runners[i])) {
                runners[1].push(...runners[i]);
            }
        }
        if (this.noRunners(runners[1])) {
            return [];
        }

        if (!!runners[0][0]) {
            // filters out the key runner in the non-key legs
            runners[1] = runners[1].filter(runner => runner.ProgramNumberCoupled !== runners[0][0].ProgramNumberCoupled);
        }
        return this.processStraightRunners(runners, legs, arrayToCopy, startingIndex);
    }

    /**
     * @param runners - array of runners to be modified
     */
    public processRunnersToBox(runners: Entry[][]): Entry[][] {
        for (let i = 1; i < runners.length; i++) {
            if (!this.noRunners(runners[i])) {
                runners[0].push(...runners[i]);
            }
        }
        runners[0].forEach(r => r.leg = 1);
        return runners.slice(0, 1);
    }

    public processRunnersToKeyBox(runners: Entry[][]) {

        for (let i = 2; i < runners.length; i++) {
            if (!this.noRunners(runners[i])) {
                runners[1].push(...runners[i]);
            }
        }
        if (this.noRunners(runners[1])) {
            return [];
        }

        // Working under the assumption that this function will not run if there's more than one key selected,
        // we set the only runner as selected in only the first leg.
        if (!!runners[0][0]) {
            runners[0][0].leg = 1;
        }
        // Let's go through all the runners in the non-key position and set it as selected in the second leg.
        // If it's selected in both the key and non-key position, set it as selected in both.
        runners[1].forEach(r => {
            const keyMatch = !!runners[0][0] && runners[0][0].ProgramNumber === r.ProgramNumber;
            if (keyMatch) {
                r.leg = 3;
                runners[0][0].leg = 3;
            } else {
                r.leg = 2;
            }
        });
        return runners.slice(0, 2);
    }

    public retainSingleLegRunners(origin: number, destination: number, runners: Entry[][]): Entry[][] {
        return (!!runners && runners.length === 1 && origin === 1 && destination === 1) ? runners : [];
    }
}
