import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CarryoverBannerHandler } from 'app/shared/betpad/classes/carryover-banner-handler.class';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import {
    BasicBetType,
    ITrackBasic,
    MultiRaceExoticBetType,
    TrackService,
    ToteDataService,
    JwtSessionService,
    ConfigurationDataService,
} from '@cdux/ng-common';
import { ICarryoverBanner } from 'app/shared/betpad/interfaces/carryover-banner.interface';
import { take } from 'rxjs/operators';

const CARRYOVER_STORAGE_KEY = 'carryover_banner_dismissed';

@Component({
    selector: 'cdux-carryover-banner',
    templateUrl: './carryover-banner.component.html',
    styleUrls: ['./carryover-banner.component.scss']
})
export class CarryoverBannerComponent implements OnInit, OnDestroy {

    private _carryoverRequestHandler: CarryoverBannerHandler;

    private _raceDate: string;

    private _track: ITrackBasic;
    @Input()
    public set track(t: ITrackBasic) {
        if (t && t.BrisCode && t.TrackType && !TrackService.isSameTrack(t, this._track)) {
            this._track = t;
            this._checkDismissalState();
            this._carryoverRequestHandler.updateRaceNavigation(t);
        }
    }

    public isDismissed: boolean = false;

    public carryover: ICarryoverBanner;

    @Output() public updateRaceNav = new EventEmitter<ITrackBasic>();
    @Output() public updateBetType = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();

    constructor(
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService,
        private _sessionService: JwtSessionService,
        private _changeDetector: ChangeDetectorRef,
        protected configService: ConfigurationDataService,
    ) {
        this._carryoverRequestHandler = new CarryoverBannerHandler(this._todaysRacesService);
    }

    ngOnInit() {

        this._checkDismissalState();

        this._carryoverRequestHandler.listen().subscribe((carryover) => {
            this._changeDetector.markForCheck();
            this.carryover = carryover;
        });

        this._sessionService.addLogoutTask(() => new Promise<void>((resolve) => {
            window.localStorage.removeItem(CARRYOVER_STORAGE_KEY);
            resolve();
        }));

    }

    ngOnDestroy() {
        this._carryoverRequestHandler.kill();
    }

    /**
     * Checks the dismissal state
     *
     * Retrieve the tote date first, then if it's hard dismissed, set it to dismissed
     * Else, set a small timeout to prevent it from popping with out data, then display it
     */
    private _checkDismissalState() {
        this._toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this._raceDate = date;
            if (this._track) {
                if (this._isHardDismissed(this._track)) {
                    this.isDismissed = true;
                } else {
                    setTimeout(() => { this.isDismissed = false; this._changeDetector.markForCheck() }, 1000);
                }
            }
            this._changeDetector.markForCheck();
        });
    }

    /**
     * Generates the key to track to dismissed banner by track code, track type, and tote date
     */
    private _getCurrentStoreValue(track: ITrackBasic): string {
        return track.BrisCode + track.TrackType + this._raceDate;
    }

    /**
     * Check if the carryover banner had been hard dismissed
     * Hard dismissed means that the banner is dismissed for the entire track for the given tote date
     *
     * @param track
     */
    private _isHardDismissed(track: ITrackBasic): boolean {
        return this._getCarryoverDismissedList().indexOf(this._getCurrentStoreValue(track)) >= 0 ? true : false;
    }

    /**
     * Get the list of dismissed carryover banners
     *
     * If the list isn't generated, created one
     */
    private _getCarryoverDismissedList(): string[] {
        const storage = JSON.parse(window.localStorage.getItem(CARRYOVER_STORAGE_KEY));
        if (storage) {
            return storage;
        } else {
            window.localStorage.setItem(CARRYOVER_STORAGE_KEY, JSON.stringify([]));
            return [];
        }
    }

    /**
     * Store the track to be hard dismissed
     */
    private _storeCarryoverDismissedState(track: ITrackBasic) {
        const storage = this._getCarryoverDismissedList();
        storage.push(this._getCurrentStoreValue(track));
        window.localStorage.setItem(CARRYOVER_STORAGE_KEY, JSON.stringify(storage));
    }

    public programNavigate() {
        this.dismiss();
        this.updateRaceNav.emit({ ...this._track, RaceNum: this.carryover.raceNumber });
        this._todaysRacesService.getTodaysRaceBetTypes(
            this._track.BrisCode,
            this._track.TrackType,
            this.carryover.raceNumber
        ).pipe( take(1) ).subscribe(betTypes => {
            const betType = betTypes.find(bt => bt.code === this.carryover.poolType);
            if (betType) { this.updateBetType.emit(betType); }
        });
    }

    public dismiss() {
        this._storeCarryoverDismissedState(this._track);
        this.isDismissed = true;
    }

}
