import { Injectable } from '@angular/core';

// Do not use this for anything outside of Deposit Menu Item or so help me!
// THIS IS TECH DEBT FOR A DERBY FIX!
/**
 * @deprecated
 */
@Injectable({providedIn: 'root'})
export class DepositMenuItemService {
    /**
     * @deprecated
     */
    public initialBalance: number;
}
