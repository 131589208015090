import { Injectable } from '@angular/core';
import {
    AccountDataService,
    enumStatus,
    IAccountInfo,
    IUpdateMyAccountRequest,
} from '@cdux/ng-common';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
    enumManageAccountType,
} from '../enums/manage-account.enum';
import { ManageConstants } from '../constants/manage.constants';

@Injectable({ providedIn: 'root' })
export class ManageBusinessService {

    constructor(
        private _accountService: AccountDataService) {
    }

    public update(type: enumManageAccountType, data: IUpdateMyAccountRequest): Observable<{ info: IAccountInfo, message: string}> {
        return this._accountService.updateAccountInfo(data).pipe(
            map(result => {
                // Check for a valid update
                if (result.message.toLowerCase() === enumStatus.SUCCESS) {
                    return { info: result, message: ManageConstants.successMessages[type]}
                } else {
                    if (result.message) {
                        return { info: null, message: result.message };
                    } else {
                        return of(null);
                    }
                }
            }),
            catchError(err => {
                return of(null);
            })
        );
    }

    public load(): Observable<IAccountInfo> {
        return this._accountService.getAccountInfo().pipe(
            map((accountInfo: IAccountInfo) => {
                return accountInfo;
            }),
            catchError(err => {
                // Return an empty object to avoid crashing the components
                return of(<IAccountInfo>{});
            })
        );
    }
}
