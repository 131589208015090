<div class="bet-slip-submit-all__total-bar" *ngIf="betCount > 0">
    <div class="bet-slip-submit-all__total-bar-left-col">
        <span class="label">Bets:</span><span class="bets" id="bet-slip-total-bets">{{betCount}}</span>
    </div>
    <div class="bet-slip-submit-all__total-bar-right-col">
        <span class="label">Total:</span><span class="total" id="bet-slip-total-amount">{{totalAmount | formatBetAmount}}</span>
    </div>
</div>
<div *ngIf="betCount > 0" id="bet-slip-submit-all-button" class="btn-bet-slip-submit-all" [cduxLogClick]="eventClickType.SUBMIT_ALL_BET" (click)="triggerSubmission()">
    <span *ngIf="!submitAllInProgress; else loadingTemplate">Submit All</span>
    <ng-template #loadingTemplate>
        <div class="loader-block button-loader">
            <div class="loader-dots">
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
            </div>
        </div>
    </ng-template>
</div>
