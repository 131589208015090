import { Component, Input } from '@angular/core';
import { IOddsTrend, IOddsTrendDetails } from '@cdux/ng-common';

@Component({
    selector: 'cdux-odds-trend',
    templateUrl: './odds-trend.component.html',
    styleUrls: ['./odds-trend.component.scss']
})
export class OddsTrendComponent {
    @Input()
    public set oddsTrend(oddsTrend: IOddsTrend) {
        this.oddsTrendArray =
            !oddsTrend ? [] : [
                oddsTrend.odds5,
                oddsTrend.odds4,
                oddsTrend.odds3,
                oddsTrend.odds2,
                oddsTrend.current
            ].filter(
                // include only changes
                (e) => !!(e && e.change)
            );
    }

    public oddsTrendArray: IOddsTrendDetails[];
}
