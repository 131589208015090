import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { EventClickType } from '@cdux/ng-common';
import { Component } from '@angular/core';

@Component({
    selector: 'cdux-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent {
    private _context: any;
    public heading: string;
    public message: string;
    public actionText: string;
    public eventType: EventClickType;

    public set context(context: any) {
        if (!!context) {
            this._context = context;
            this.heading = this._context.heading;
            this.message = this._context.message;
            this.actionText = this._context.actionText;
            this.eventType = this._context.eventType;
        }
    }

    public get context(): any {
        return this._context;
    }

    constructor(public _eventTrackingService: EventTrackingService) {}

    public action(): void {
        if (!!this.eventType) {
            this._eventTrackingService.logClickEvent(this.eventType);
        }
        this._context.action();
    }

    public close(): void {
        this._context.close();
    }
}
