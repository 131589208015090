<section class="funding-user-balance" *ngIf="usersname || balance.valueOf() !== 0">
  <div class="funding-user-balance__user-name" *ngIf="usersname" id="funding-user-balance-name-txt">
    {{ usersname }}
  </div>
  <div class="funding-user-balance__label" *ngIf="balance !== undefined && balance !== null" id="funding-user-balance-balance-lbl">
    balance
  </div>
  <div class="funding-user-balance__user-account-balance" *ngIf="balance !== undefined && balance !== null" id="funding-user-balance-balance-value">
    {{ balance | currency }}
  </div>
</section>
