import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject } from 'rxjs';

import {
    enumFeatureToggle,
    EventClickType,
    EventClickAttributeType,
    ITrack,
    EventsService,
    FeatureToggleDataService,
    TrackService,
    ITrackBasic,
    StringSlugifyPipe,
    FavoriteTracksService,
    SortUpcomingTracksPipe,
    WindowRefService,
    TodaysDisplayTrack,
    FavoriteTrackListPipe,
} from '@cdux/ng-common';
import { ScrollableContentComponent } from '@cdux/ng-fragments';
import { TodaysRacesBusinessService } from '../program/services/todays-races.business.service';
import { EventTrackingService } from '../event-tracking/services/event-tracking.service';
import { EventKeys } from '../common';
import { withLatestFrom, publishReplay, refCount, takeUntil, skip } from 'rxjs/operators';


@Component({
    selector: 'cdux-mtp-bar',
    styleUrls: [ './mtp-bar.component.scss' ],
    templateUrl: './mtp-bar.component.html',
    providers: [ FavoriteTrackListPipe ]
})
export class MtpBarComponent extends ScrollableContentComponent implements OnInit, OnDestroy, AfterViewInit {
    public trackList: ITrack[] = [];
    public favoriteTrackList: ITrack[] = [];

    public showFavorites = false; // default to show all tracks in the up next bar
    public trackListVisibleLength = 0; // set to 0 to allow all tracks

    public isToteBoardEnabled = this.featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.TOTE_BOARD);

    private trackDataSubscription: Subscription;

    private _destroy: Subject<boolean> = new Subject();


    // setup default scrollArrows
    public scrollArrows = {
            displayLeft: false,
            displayRight: true
    };

    @Output()
    private select = new EventEmitter<ITrack>();

    @Input()
    public canClickOutOfToteBoard: boolean;


    @Input()
    public set isToteBoardActive(active: boolean) {
        if (this._isToteBoardActive !== active) {
            this.isToteBoardActiveChange.emit(this._isToteBoardActive = !!active);
        }
    }
    public get isToteBoardActive(): boolean {
        return this._isToteBoardActive;
    }
    private _isToteBoardActive = false;

    @Output()
    public isToteBoardActiveChange = new EventEmitter<boolean>();


    constructor(
        protected _changeDetector: ChangeDetectorRef,
        protected _windowRef: WindowRefService,
        private eventTrackingService: EventTrackingService,
        private featureToggleDataService: FeatureToggleDataService,
        private favoriteTracksListPipe: FavoriteTrackListPipe,
        private router: Router,
        private sortTracksPipe: SortUpcomingTracksPipe,
        private eventsService: EventsService,
        private activatedRoute: ActivatedRoute,
        private todaysRacesService: TodaysRacesBusinessService,
        private stringSlugifyPipe: StringSlugifyPipe,
        private favoriteTracksService: FavoriteTracksService
    ) {
        super(_changeDetector, _windowRef);
    }

    public setFavoriteTabActive(isFavoriteTabActive: boolean): void {
        this.showFavorites = isFavoriteTabActive;
        // as active tab changed we need to reset and set the scrollArrows
        this._setScroll(true);
    }

    public toggleToteBoard(state = !this.isToteBoardActive) {
        this.eventTrackingService.logClickEvent(EventClickType.MTP_BAR_TOTE_BOARD);
        this.isToteBoardActive = state;
    }

    ngOnInit() {
        const todaysRaceObs = this.todaysRacesService.getTodaysTracks(true, true)
        .pipe(
            publishReplay(1),
            refCount()
        )
        this.trackDataSubscription = todaysRaceObs.subscribe(tracks => this.processTrackData(tracks));
        this.favoriteTracksService.getFavoriteTracks()
        .pipe(
            skip(1),
            withLatestFrom(todaysRaceObs),
            takeUntil(this._destroy)
        )
        .subscribe(([favoriteBasicTracks, todayDisplayTracks]) => {
            this.favoriteTrackList = this.todaysRacesService.compileFavoriteTracks(todayDisplayTracks, favoriteBasicTracks).map(todaysDisplayTrack => todaysDisplayTrack.ITrack);
            if (this.trackListVisibleLength > 0) {
                this.favoriteTrackList = this.favoriteTrackList.slice(0, this.trackListVisibleLength);
            }
            this._setScroll();
        })
    }

    ngAfterViewInit() {
        this._changeDetector.detectChanges();
    }

    ngOnDestroy() {
        this.trackDataSubscription.unsubscribe();
        this._destroy.next();
        this._destroy.complete();
    }

    private processTrackData(tracks: TodaysDisplayTrack[]) {
        this.trackList = this.sortTracksPipe.transform(tracks.map(t => t && t.ITrack));
        this.trackList = this.trackList.filter(t => !!t); // remove invalid ITrack data
        this.favoriteTrackList = this.favoriteTracksListPipe.transform(this.trackList);

        if (this.trackListVisibleLength > 0) {
            this.trackList = this.trackList.slice(0, this.trackListVisibleLength);
            this.favoriteTrackList = this.favoriteTrackList.slice(0, this.trackListVisibleLength);
        }
        this._setScroll();
    }

    /**
     * set the scroll bar display right as true only if list contains more than 5 items
     * @param reset
     * @private
     */
    private _setScroll(reset: boolean = false) {
        if (reset) {
            this.scrollArrows.displayLeft = false;
            this.scrollArrows.displayRight = false;
        }
        if ((this.showFavorites && this.favoriteTrackList.length > 5) || (!this.showFavorites && this.trackList.length > 5 )) {
            // we need to verify displayLeft since they might be start scrolling
            if (!this.scrollArrows.displayLeft) {
                this.scrollArrows.displayRight = true;
            }
        } else {
            this.scrollArrows.displayRight = false;
            this.scrollArrows.displayLeft = false;
        }
    }

    public selectTrack(track: ITrack) {
        // only navigate if the brisCode and track type are different
        const currentTrack = {
            BrisCode: this.activatedRoute.snapshot.params['brisCode'],
            TrackType: this.activatedRoute.snapshot.params['trackType']
        } as ITrackBasic;

        if (!TrackService.isSameTrack(track, currentTrack)) {
            this.eventTrackingService.logClickEvent(
                EventClickType.MTP_BAR_TRACK,
                [{
                    attrId: EventClickAttributeType.MTP_BAR_BRIS_CODE,
                    data: track.BrisCode
                }, {
                    attrId: EventClickAttributeType.MTP_BAR_TRACK_TYPE,
                    data: track.TrackType
                }, {
                    attrId: EventClickAttributeType.MTP_BAR_RACE,
                    data: track.RaceNum
                }]
            );

            this.select.emit(track);
            this.eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
            this.programNavigate(this.stringSlugifyPipe.transform(track.UrlDisplayName), track.BrisCode, track.TrackType, track.RaceNum);
        }
    }

    public programNavigate(urlDisplayName: string, brisCode: string, trackType: string, raceNum: number) {
        this.router.navigate(['/program', urlDisplayName, brisCode, trackType, raceNum]);
    }
}
