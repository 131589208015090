import { switchMap, catchError, takeUntil, finalize, startWith } from 'rxjs/operators';
import { ReplaySubject, zip, of, Observable, iif, combineLatest } from 'rxjs';
import { CduxRxJSBuildingBlock, ENVIRONMENT } from '@cdux/ng-core';
import { ITrackBasic, IAvailableDocsResponse, IMenu, JwtSessionService } from '@cdux/ng-common';
import { EcommerceBusinessService } from 'app/shared/ecommerce/services/ecommerce.business.service';

export class PastPerformanceManager extends CduxRxJSBuildingBlock<any, any> {

    protected _stream: Observable<[IAvailableDocsResponse, IMenu[]]>;

    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>();


    constructor (
        private _environment: ENVIRONMENT,
        private _ecommerceService: EcommerceBusinessService,
        private _sessionService: JwtSessionService
    ) {
        super();
        this._init()
    }

    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }

    protected _init() {
        this._stream = combineLatest([
            this._raceNavigationChanges,
            this._sessionService.onAuthenticationChange.pipe(
                startWith(this._sessionService.isLoggedIn())
            )
        ]).pipe(
            switchMap(([track, isLoggedIn]) =>
                this._getAvailableRaceDocs(track, isLoggedIn)
            ),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        )
    }
    private _getAvailableRaceDocs(track: ITrackBasic, auth = false): Observable<[IAvailableDocsResponse, IMenu[]]> {
        const availableDocsObs = zip(
            iif(() => auth,
                this._ecommerceService.getAvailableDocs(track),
                this._ecommerceService.getAvailableDocsLite(track)
            ),
            this._ecommerceService.getMenu('TuxDropDown' + this._environment.affiliateId)
        ).pipe(
            catchError(() => of(<[IAvailableDocsResponse, IMenu[]]> [null, []]))
        );

        return availableDocsObs;
    }
}
