import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { enumTop3Category } from 'app/wagering/shared/angles/top-3-button/top-3-category.enum';
import { enumFeatureToggle, FeatureToggleDataService, WagerState } from '@cdux/ng-common';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { DisplayModeEnum } from 'app/shared';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';

@Component({
    selector: 'cdux-angles-top-pick-bar',
    templateUrl: './angles-top-pick-bar.component.html',
    styleUrls: ['./angles-top-pick-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnglesTopPickBarComponent {

    @Input()
    public displayMode: DisplayModeEnum;
    public DisplayModeEnum = DisplayModeEnum;

    @Input()
    public view: WageringViewEnum;
    public WageringViewEnum = WageringViewEnum;

    @Input()
    public wagerState: WagerState;

    @Input()
    public toteDate: string;

    @Output()
    public onSelection: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    // provides enum to template
    public category: enumTop3Category;
    public top3Category = enumTop3Category;

    public anglesNames = {
        'topPick': {
            'displayName': 'Top Pick',
            'descriptiveText': 'The top generated pick for the race.',
            'displaySide': 'left'
        },
        'bestBet': {
            'displayName': 'Best Bet',
            'descriptiveText': 'A horse that is ranked among today’s most likely winners.',
            'displaySide': 'left'
        },
        'hotJockey': {
            'displayName': 'Hot Jockey',
            'descriptiveText': 'A jockey that has been “hot” in the last seven (7) days.',
            'displaySide': 'left'
        },
        'hotTrainer': {
            'displayName': 'Hot Trainer',
            'descriptiveText': 'A trainer that has been “hot” in the last seven (7) days.',
            'displaySide': 'left'
        },
        'hotTrainerJockeyCombo': {
            'displayName': 'Combo',
            'descriptiveText': 'A trainer and jockey combination which has been “hot” in the last fourteen (14) days.',
            'displaySide': 'right'
        },
        'trainerAngle': {
            'displayName': 'Key Trainer',
            'descriptiveText': 'A horse whose profile fits a profitable and high win percentage.',
            'displaySide': 'right'
        },
        'bestDistance': {
            'displayName': 'Best Distance',
            'descriptiveText': 'A horse which has performed exceptionally well over the same distance.',
            'displaySide': 'right'
        },
        'horseForCourse': {
            'displayName': 'Horse for Course',
            'descriptiveText': 'A horse which has performed exceptionally well over the track and surface.',
            'displaySide': 'right'
        },
        'clocker': {
            'displayName': 'Clocker Special',
            'descriptiveText': 'A horse that has recorded a fast workout in the last seven (7) days.',
            'displaySide': 'left'
        },
        'exitKey': {
            'displayName': 'Exiting Key Race',
            'descriptiveText': 'A horse exiting a race that has produced multiple horses that won their next start.',
            'displaySide': 'right'
        }
    }

    public anglesNamesKeys: string[] = Object.keys(this.anglesNames);

    public isTop3PicksEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.TOP_3_PICKS);

    constructor (private _featureToggleDataService: FeatureToggleDataService) { }
}
