<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>

<div class="jockey-details" *ngIf="detailsObs | async as details" [class.is-inline]="isInline">
    <ng-container *ngIf="availableStats?.length > 0">
        <div class="details-col">
            <h1>{{ this.primaryName() }}</h1>
        </div>
        <div class="detail-info-full">
            <div class="info-container">
                <!-- Key Stats-->
                <div class="table-container">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <td>Key Stats</td>
                                <td class="align-right">str</td>
                                <td class="align-right">w%</td>
                                <td class="align-right">$2 avg</td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let stat of keyStats">
                                <tr *ngIf="details?.summaryStats.hasOwnProperty(stat)">
                                    <td [ngClass]="details?.summaryStats[stat]?.roi >= 2 ? 'green-highlight' : ''"
                                        [innerHTML]="stat | cduxTranslate: 'summary-stats-categories'"></td>
                                    <td class="align-right">{{ details?.summaryStats[stat]?.starts }}</td>
                                    <td class="align-right">{{ details?.summaryStats[stat]?.winPercent }}%</td>
                                    <td class="align-right"
                                        [ngClass]="details?.summaryStats[stat]?.roi >= 2 ? 'green-highlight' : ''">{{
                                        details?.summaryStats[stat]?.roi | number:'1.2-2' }}</td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>

                <!--With Trainer-->
                <div class="table-container">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <td>{{ detailsType }} Combo</td>
                                <td class="align-right">str</td>
                                <td class="align-right">w%</td>
                                <td class="align-right">$2 avg</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stat of availableComboStats">
                                <td [ngClass]="details?.comboStats[stat]?.roi >= 2 ? 'green-highlight' : ''"
                                    [innerHTML]="(stat + '-' + this.detailsType.toLocaleLowerCase()) | cduxTranslate: 'summary-stats-categories'">
                                </td>
                                <td class="align-right">{{ details?.comboStats[stat]?.starts }}</td>
                                <td class="align-right">{{ details?.comboStats[stat]?.winPercent }}%</td>
                                <td class="align-right"
                                    [ngClass]="details?.comboStats[stat]?.roi >= 2 ? 'green-highlight' : ''">{{
                                    details?.comboStats[stat]?.roi | number:'1.2-2' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!--All Stats-->
            <div class="stats-container">
                <div class="table-container">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <thead>
                            <tr>
                                <td>All Stats</td>
                                <td class="align-right">str</td>
                                <td class="align-right">w%</td>
                                <td class="align-right">$2 avg</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let stat of availableStats | slice:0:(showWholeAllStats ? availableStats.length : MIN_ROWS_DISPLAY)">
                                <td [ngClass]="details?.summaryStats[stat]?.roi >= 2 ? 'green-highlight' : ''"
                                    [innerHTML]="stat | cduxTranslate: 'summary-stats-categories'"></td>
                                <td class="align-right">{{ details?.summaryStats[stat]?.starts }}</td>
                                <td class="align-right">{{ details?.summaryStats[stat]?.winPercent }}%</td>
                                <td class="align-right" [ngClass]="details?.summaryStats[stat]?.roi >= 2 ? 'green-highlight' : ''">{{
                                    details?.summaryStats[stat]?.roi | number:'1.2-2' }}</td>
                            </tr>
                            <tr *ngIf="availableStats.length > MIN_ROWS_DISPLAY" (click)="toggleAllStats()">
                                <td><a>SEE {{ allStatsToggleLabel }}</a> </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </ng-container>
</div>

<div class="trainer-details" *ngIf="error" [class.is-inline]="isInline">
    <div class="details-col">
        <h1>{{ this.errorMessage }} </h1>
    </div>
</div>
