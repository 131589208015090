<div [class.funding-options-wrapper]="displayStyle === enumDepositDisplayStyle.SIDEBAR">
    <!-- Add deposit option child components here -->
    <!-- This will be dynamically generated in the future -->
    <ng-template ngFor let-fundingOption [ngForOf]="fundingOptions">
      <cdux-funding-option
        *ngIf="canDisplayFundingOption(fundingOption)"
        [id]="'depositFundingOption-' + fundingOption.fundType"
        [fundingMethodDetails]="fundingOption"
        [displayStyle]="displayStyle"
        [isActive]="fundingOption.fundId === activeFundingMethod"
        [hasError]="fundingOption.locked"
        [canReplace]="fundingOption.canReplace !== false"
        [icon]="getIcon('tile-icon', fundingOption)"
        [amexEnabled]="amexEnabled"
        (select)="selectFundingMethod($event)"
        (replace)="replaceFundingMethod($event)">
      </cdux-funding-option>
    </ng-template>
</div>
