import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, zip } from 'rxjs';
import { catchError, concat, map, switchMap, take } from 'rxjs/operators';

import {
    BasicBetType,
    enumRaceHighlightIconTypes,
    enumRaceStatus,
    enumTodaysTracksSortOrder,
    enumTrackStatus,
    enumTrackType,
    enumTrackTypeBds,
    EventClickType,
    FavoriteRunnersService,
    FavoriteTracksService,
    IAllRaceProgramEntry,
    IEntryDetails,
    IFavoriteTrackDisplay,
    IMtpDisplay,
    ITodaysRace,
    ITodaysTrack,
    ITrackBasic,
    IUpcomingTrack,
    MultiRaceExoticBetType,
    ProgramEntry,
    RaceDetails,
    SortTodaysRacesByMtpPipe,
    StringSlugifyPipe,
    TodaysDisplayTrack,
    ToteDataService,
    TracksDataService,
    TrackService,
} from '@cdux/ng-common';
import { MtpDisplayUtil } from '@cdux/ng-fragments';
import { CduxStorageService } from '@cdux/ng-platform/web';
import { IExpertPickAnalysis } from '@cdux/ng-common/services/data/adw-track/interfaces/expert-pick-analysis.interface';

import { ITodaysRacesTrackLists } from '../interfaces/todays-races-track-lists.interface';
import { enumFilters, TodaysRaceFilters } from '../interfaces/todays-race-filters.interface';
import { TodaysRacesViewEnum } from '../enums/todays-races-view.enum';
import { IFavoriteTrackGroupLists, ITrackGroupLists } from '../interfaces/track-group-lists.interface';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysRacesFilterInfo } from '../models/todays-races-filter-info';

enum TrackSort {
    MTP, NAME
}

@Injectable({
    providedIn: 'root'
})
export class TodaysRacesBusinessService {

    public static readonly TRACK_TYPE_MAP_ADW_TO_BDS = {
        [enumTrackType.TBRED]: enumTrackTypeBds.TBRED,
        [enumTrackType.HARN]: enumTrackTypeBds.HARN,
        [enumTrackType.GREY]: enumTrackTypeBds.GREY,
    };

    public static readonly TRACK_TYPE_MAP_BDS_TO_ADW = {
        [enumTrackTypeBds.TBRED]: enumTrackType.TBRED,
        [enumTrackTypeBds.HARN]: enumTrackType.HARN,
        [enumTrackTypeBds.GREY]: enumTrackType.GREY,
    };

    public filterConfigObs: Observable<TodaysRaceFilters>;

    constructor(
        private tracksDataService: TracksDataService,
        private mtpDisplayUtil: MtpDisplayUtil,
        private favoriteRunnersService: FavoriteRunnersService,
        private favoriteTracksService: FavoriteTracksService,
        private stringSlugify: StringSlugifyPipe,
        private sortMtpPipe: SortTodaysRacesByMtpPipe,
        private eventTrackingService: EventTrackingService,
        private cduxStorageService: CduxStorageService,
        private toteService: ToteDataService,
    ) {
    }
    /**
     * getTodaysActiveTracks
     *
     * Gets track with display info. Returns lists of general and favorite tracks.
     *
     * @param [poll=false] {boolean}
     * @param [includeRaces=false] {boolean}
     * @param [sortOrder=enumTodaysTracksSortOrder.NEXTUP] {enumTodaysTracksSortOrder}
     * @memberof TodaysRacesBusinessService
     * @returns {Observable<ITrackGroupLists>}
     */
    public getTodaysActiveTracks(poll: boolean = false, includeRaces: boolean = true, sortOrder: enumTodaysTracksSortOrder = enumTodaysTracksSortOrder.NEXTUP, view: TodaysRacesViewEnum = TodaysRacesViewEnum.TIME): Observable<ITrackGroupLists> {
        const todaysTracksObs = this.getTodaysTracks(poll, includeRaces, sortOrder);
        const favoriteTracksObs = this.getFavoriteTracks();
        const favoriteStartsObs = this.getFavoriteRunners();
        const currentRaceDateObs = this.getRaceDateChanges();

        // Add call to get toteDate to this so that everything within can stay nice and clean
        return combineLatest([todaysTracksObs, favoriteTracksObs, favoriteStartsObs, currentRaceDateObs])
            .pipe(
                map(([allTracks, favoriteBasicTracks, favoriteStarts, raceDate]) => {

                    // Compile list of featured tracks and update favorites for current race:
                    let featuredTracks: TodaysDisplayTrack[] = [];
                    featuredTracks = this.addFavoritesToAllTracks(this.compileFeaturedTracks(allTracks, view), favoriteStarts, raceDate);

                    // Update allTracks array with favorites for current race:
                    allTracks = this.addFavoritesToAllTracks(allTracks, favoriteStarts, raceDate);

                    const canceledTracks = this.compileCanceledTracks(allTracks, view);

                    return {
                        generalTracks: this.compileGeneralTracks(allTracks, canceledTracks),
                        favoriteTracks: this.compileFavoriteTracks(allTracks, favoriteBasicTracks),
                        featuredTracks: featuredTracks,
                        canceledTracks: canceledTracks
                    };
                })
            );
    }

    /**
     * Get track list data complete with display info. Returns an object
     * containing view list objects. Each list object contains track lists
     * grouped by category (general, favorite, canceled). Lists are also
     * filtered according to the config provided by the filter component.
     *
     * @param [poll=false] {boolean}
     * @param [includeRaces=true] {boolean}
     * @param [sortOrder=null] {enumTodaysTracksSortOrder}
     * @returns {Observable<ITodaysRacesTrackLists>}
     */
    public getTodaysTrackLists(poll: boolean = false, includeRaces: boolean = true): Observable<ITodaysRacesTrackLists> {

        const activeTracksByTime = this.getTodaysActiveTracks(poll, includeRaces, enumTodaysTracksSortOrder.NEXTUP, TodaysRacesViewEnum.TIME);
        const activeTracksByTrack = this.getTodaysActiveTracks(poll, includeRaces, enumTodaysTracksSortOrder.NEXTUP, TodaysRacesViewEnum.TRACK);

        // Combine source observables to construct grouped and sorted track lists
        const unfilteredTrackListsObs = combineLatest([activeTracksByTime, activeTracksByTrack]).pipe(
            map(([tracksByTime, tracksByTrack]) => <ITodaysRacesTrackLists> {
                hasTrackTypes: tracksByTrack.generalTracks.reduce((types, track) => {
                    if (!types.some(t => t === track.type)) {
                        types.push(track.type);
                    }
                    return types;
                }, <enumTrackType[]> []),
                trackViewLists: this.sortTrackLists(tracksByTrack, TrackSort.NAME),
                timeViewLists: this.sortTrackLists(this.filterViewLists(tracksByTime, this.filterCurrentRaces), TrackSort.MTP)
            })
        );

        const filterConfigDBUpdates = this.cduxStorageService.observe(TodaysRacesFilterInfo.DB, TodaysRacesFilterInfo.KEY, TodaysRacesFilterInfo.VERSION).pipe(
            map(storedFilterObj => storedFilterObj.filters || null),
            catchError(error => of(null))
        );

        // The concat operator waits for the first subscription to complete (checkFilterStorage)
        // before beginning the next subscription (filterConfigDBUpdates)
        this.filterConfigObs = this.checkFilterStorage().pipe(take(1), concat(filterConfigDBUpdates));

        // Filter new track lists with existing filter config or filter existing lists with
        // new filter config depending on which source emits
        return combineLatest([unfilteredTrackListsObs, this.filterConfigObs]).pipe(
            map(([unfilteredTrackLists, filterConfig]) => !filterConfig ? unfilteredTrackLists : <ITodaysRacesTrackLists> {
                hasTrackTypes: unfilteredTrackLists.hasTrackTypes,
                trackViewLists: this.filterViewLists(unfilteredTrackLists.trackViewLists, this.filterBreedType(filterConfig)),
                timeViewLists: this.filterViewLists(unfilteredTrackLists.timeViewLists, this.filterBreedType(filterConfig))
            })
        );
    }

    /**
     * Get todays tracks. Returns an array of track objects, each
     * containing general track info as well as an array of races.
     *
     * @param poll
     * @returns {Observable<TodaysDisplayTrack[]>}
     */
    public getTodaysTracks(poll: boolean, includeRaces?: boolean, sortOrder: enumTodaysTracksSortOrder = enumTodaysTracksSortOrder.NEXTUP): Observable<TodaysDisplayTrack[]> {
        return this.tracksDataService.todaysTracks(poll, includeRaces, sortOrder).pipe(
            map(todaysTracksData => this.appendRaceDisplayInfo(todaysTracksData as ITodaysTrack[]))
        );
    }

    public getUpcomingTracks(poll: boolean, beginDate: string, endDate = beginDate): Observable<IUpcomingTrack[]> {
        return this.tracksDataService.upcomingTracks(poll, beginDate, endDate);
    }

    public getTodaysTrack(brisCode: string, trackType: enumTrackType): Observable<TodaysDisplayTrack> {
        return this.tracksDataService.todaysTrack(brisCode, trackType, false).pipe(
            take(1),
            map(todaysTrackData => this.appendRaceDisplayInfo([todaysTrackData])[0])
        );
    }

    public getTodaysTrackPoll(brisCode: string, trackType: enumTrackType): Observable<TodaysDisplayTrack> {
        return this.tracksDataService.todaysTrack(brisCode, trackType, true).pipe(
            map(todaysTrackData => this.appendRaceDisplayInfo([todaysTrackData])[0])
        );
    }

    /**
     * Get the race list for a today's track.
     *
     * @param brisCode
     * @param trackType
     * @param poll
     * @returns
     * @memberof TodaysRacesBusinessService
     */
    public getTodaysRaces(brisCode: string, trackType: enumTrackType, poll: boolean = false): Observable<ITodaysRace[]> {
        return this.tracksDataService.todaysRaces(brisCode, trackType, poll);
    }

    public getTodaysWagerableRaces(brisCode: string, trackType: enumTrackType, poll: boolean = false): Observable<ITodaysRace[]> {
        return this.tracksDataService.todaysRaces(brisCode, trackType, poll).pipe(
            switchMap((todaysRaces) => zip(...todaysRaces.map((todaysRace) =>
                !TrackService.isWagerableRace(todaysRace.status) ? of(null) :
                    this.getTodaysRaceBetTypes(brisCode, trackType, todaysRace.raceNumber, false).pipe(
                        map((betTypes) => betTypes?.length > 0 ? todaysRace : null)
                    )
            ))),
            map((todaysRaces) => todaysRaces.filter(r => !!r))
        );
    }

    /**
     * Get the today's race for a today's track.
     *
     * @param brisCode
     * @param trackType
     * @param raceNum
     * @param poll
     * @returns
     * @memberof TodaysRacesBusinessService
     */
    public getTodaysRace(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<ITodaysRace> {
        return this.tracksDataService.todaysRace(brisCode, trackType, raceNum, poll);
    }

    public getTodaysRaceBetTypes(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<(BasicBetType | MultiRaceExoticBetType)[]> {
        return this.tracksDataService.todaysRaceBetTypes(brisCode, trackType, raceNum, poll);
    }

    public getTodaysRaceEntries(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<ProgramEntry[]> {
        return this.tracksDataService.todaysRaceEntries(brisCode, trackType, raceNum, poll);
    }

    public getTodaysRaceExpertPick(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<IExpertPickAnalysis> {
        return this.tracksDataService.todaysRaceExpertPick(brisCode, trackType, raceNum, poll);
    }

    public getTodaysRaceBrisPicks(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<any> {
        return this.tracksDataService.todaysRaceBrisPicks(brisCode, trackType, raceNum, poll);
    }

    public getTodaysAllRaceEntries(brisCode: string, trackType: enumTrackType, poll: boolean = false): Observable<IAllRaceProgramEntry[]> {
        return this.tracksDataService.todaysAllRaceEntries(brisCode, trackType, poll);
    }

    /**
     * Get a list of favorite tracks to cross-reference with
     * the list of available tracks.
     *
     * @returns {Observable<ITrackBasic[]>}
     */
    public getFavoriteTracks(): Observable<ITrackBasic[]> {
        return this.favoriteTracksService.getFavoriteTracks();
    }

    /**
     * Get a list of favorite runners to cross-reference with
     * the list of available tracks.
     *
     * @returns {Observable<ITrackBasic[]>}
     */
    public getFavoriteRunners(): Observable<IEntryDetails[]> {
        return from(this.favoriteRunnersService.getTodaysEntryDetails());
    }

    public getFavoriteTracksIncludeNotRunning(poll: boolean = false, includeRaces: boolean = true, sortOrder: enumTodaysTracksSortOrder = enumTodaysTracksSortOrder.NEXTUP, view: TodaysRacesViewEnum = TodaysRacesViewEnum.TIME): Observable<IFavoriteTrackGroupLists> {
        const todaysTracksObs = this.getTodaysTracks(poll, includeRaces, sortOrder);
        const favoriteTracksObs = this.favoriteTracksService.getFavoriteTracks();

        return combineLatest([todaysTracksObs, favoriteTracksObs]).pipe(
            map(([allTracks, favoriteBasicTracks]) => {
                const favTodayDisplayTrack = this.compileFavoriteTracks(allTracks, favoriteBasicTracks);
                const favoriteToday: IFavoriteTrackDisplay[] = [];
                favTodayDisplayTrack.forEach((ftdt) => {
                    favoriteToday.push({
                        brisCode: ftdt.brisCode,
                        trackType: ftdt.type,
                        currentRaceIndex: ftdt.currentRaceIndex,
                        currentRaceNumber: ftdt.currentRaceNumber,
                        formattedTrackName: ftdt.formattedTrackName,
                        status: ftdt.status,
                        races: ftdt.races
                    });
                });

                const favoriteFuture: IFavoriteTrackDisplay[] = [];
                const keysAllTrack: Set<string> = new Set();
                allTracks.forEach((t) => {
                    keysAllTrack.add(t.brisCode.toLowerCase() + '-' + t.type.toLowerCase());
                });

                favoriteBasicTracks.forEach((fbt) => {
                    if (!keysAllTrack.has(fbt.BrisCode.toLowerCase() + '-' + fbt.TrackType.toLowerCase())) {
                        favoriteFuture.push({
                            brisCode: fbt.BrisCode,
                            trackType: fbt.TrackType,
                            formattedTrackName: fbt.BrisCode,
                        });
                    }
                });
                return {
                    favoriteTracksToday: favoriteToday,
                    favoriteTracksNotRunning: favoriteFuture
                };
            })
        );
    }

    public logClick(clickType: EventClickType): void {
        this.eventTrackingService.logClickEvent(clickType);
    }

    public compileFavoriteTracks(allTracks: TodaysDisplayTrack[], favoriteBasicTracks: ITrackBasic[]): TodaysDisplayTrack[] {
        return this.sortTracks(allTracks.filter((track) => {
            // Add the track to the favorite list if it matches a favorite
            return favoriteBasicTracks.some((favoriteTrack) => {
                    return TrackService.isSameTrack(favoriteTrack, track.ITrackBasic);
            });
        }), TrackSort.MTP);
    }

    private addFavoritesToAllTracks(allTracks: TodaysDisplayTrack[], favorites: IEntryDetails[], raceDate: string) {
        if (allTracks?.length && favorites?.length) {
            // For Current race update:
            allTracks.forEach((track) => {
                track.favorites = favorites.filter((start) => {
                    return this.startIsCurrentTrackAndRace(start, track, raceDate);
                });

                // update favoritesIcon array in races under track for favorite runners:
                const favRaces: number[] = favorites.filter((start) => {
                    return this.startIsInTrack(start, track, raceDate);
                }).map(y => y.raceNumber);
                if (favRaces && favRaces.length) {
                    track.races.forEach((aRace) => {
                        if (favRaces.includes(aRace.raceNumber)) {
                            // this race having favorites for runner: if RUNNER icon type is not included in the list, then add it
                            if (aRace.hasOwnProperty('raceHighlightIcons')) {
                                aRace.raceHighlightIcons.add(enumRaceHighlightIconTypes.RUNNER);
                            } else {
                                aRace.raceHighlightIcons = new Set([ enumRaceHighlightIconTypes.RUNNER ]);
                            }
                        }
                    });
                }
            });
        }

        return allTracks;
    }

    private startIsCurrentTrackAndRace(start: IEntryDetails, track: TodaysDisplayTrack, raceDate: string) {
        return start.bdsTrackType === TodaysRacesBusinessService.TRACK_TYPE_MAP_ADW_TO_BDS[track.type] &&
               start.raceNumber === track.currentRaceNumber &&
               start.brisCode.toLowerCase() === track.brisCode.toLowerCase() &&
               start.raceDate === raceDate
    }

    private startIsInTrack(start: IEntryDetails, track: TodaysDisplayTrack, raceDate: string) {
        return start.bdsTrackType === TodaysRacesBusinessService.TRACK_TYPE_MAP_ADW_TO_BDS[track.type] &&
               start.brisCode.toLowerCase() === track.brisCode.toLowerCase() &&
               start.raceDate === raceDate
    }

    private compileCanceledTracks(allTracks: TodaysDisplayTrack[], view: TodaysRacesViewEnum): TodaysDisplayTrack[] {
        switch (view) {
            case TodaysRacesViewEnum.TRACK:
                return allTracks.filter((track) => {
                    return track.status === enumTrackStatus.CANCELED;
                });
            case TodaysRacesViewEnum.TIME:
                return allTracks.filter((track => {
                    return track.status === enumTrackStatus.CANCELED || this.isCurrentRaceCanceled(track);
                }));
            default:
                return [];
        }
    }

    private compileGeneralTracks(allTracks: TodaysDisplayTrack[], canceledTracks: TodaysDisplayTrack[]): TodaysDisplayTrack[] {
        return this.sortTracks(allTracks.filter((track) => {
            // Add the track to the general list if it does not match a canceled track
            return canceledTracks.every((canceledTrack) => {
                return !TrackService.isSameTrack(canceledTrack.ITrackBasic, track.ITrackBasic);
            });
        }), TrackSort.MTP);
    }

    private compileFeaturedTracks(allTracks: TodaysDisplayTrack[], view: TodaysRacesViewEnum): TodaysDisplayTrack[] {
        const addTrack = (tracks: TodaysDisplayTrack[], track: ITodaysTrack, props = {}) => {
            // clone track data before applying props to avoid polluting the source
            tracks.push(this.appendRaceDisplayInfo([{ ...track, ...props }]).pop());
        };

        return this.sortTracks(allTracks.reduce((featuredTracks, track) => {
            if (track.featuredTrackLabel) { // feature the entire card
                addTrack(featuredTracks, track, {
                    formattedTrackName: this.formatTrackLabel(track, track.featuredTrackLabel)
                });
            }

            if (!track.featuredRaces || !track.featuredRaces.length) {
                return featuredTracks; // no specific featured races
            }

            switch (view) {
                case TodaysRacesViewEnum.TIME:
                    track.featuredRaces.forEach(race => {
                        addTrack(featuredTracks, track, {
                            formattedTrackName: this.formatTrackLabel(track, race.label),
                            races: track.races.filter(
                                // filter out the non-featured races
                                r => r.raceNumber === race.raceNumber
                            ),
                            currentRaceIndex: 0,
                            currentRaceNumber: race.raceNumber
                        });
                    });
                    break;
                case TodaysRacesViewEnum.TRACK:
                    const collatedRaces = {};
                    track.featuredRaces.forEach(race => {
                        if (!collatedRaces[race.label]) {
                            collatedRaces[race.label] = [];
                        }
                        collatedRaces[race.label].push(race);
                    });
                    Object.keys(collatedRaces).forEach(label => {
                        addTrack(featuredTracks, track, {
                            formattedTrackName: this.formatTrackLabel(track, label),
                            races: collatedRaces[label],
                            currentRaceIndex: collatedRaces[label][0] ? 0 : -1,
                            currentRaceNumber: collatedRaces[label][0]?.raceNumber || 0
                        });
                    });
                    break;
            }

            return featuredTracks;
        }, <TodaysDisplayTrack[]> []), TrackSort.MTP);
    }

    private isCurrentRaceCanceled(track: TodaysDisplayTrack): boolean {
        let currentRace: ITodaysRace = null;
        track.races.forEach((race) => {
            if (race.raceNumber === track.currentRaceNumber) {
                currentRace = race;
            }
        });

        return !!currentRace ? currentRace.status === enumRaceStatus.CANCELED : false;
    }

    /**
     * Generic method for filtering track group lists. Creates a new
     * object containing group lists filtered according to the provided
     * filter function.
     *
     * @param trackLists
     * @param filterFn
     */
    public filterViewLists(trackLists: ITrackGroupLists, filterFn: (track: TodaysDisplayTrack) => boolean): ITrackGroupLists {
        const filteredTrackLists = {} as ITrackGroupLists;
        for (const trackGroup of Object.keys(trackLists)) {
            filteredTrackLists[trackGroup] = trackLists[trackGroup].filter(filterFn);
        }
        return filteredTrackLists;
    }

    // *** Race Filter Functions ***

    /**
     * Method which returns a filter callback which depends on the provided
     * race status list. Rejects races with a status that doesn't match any
     * of the ones provided.
     *
     * @param raceStatusList
     */
    public filterRaceStatus(...raceStatusList: enumRaceStatus[]): (race: ITodaysRace) => boolean {
        return (race: ITodaysRace) => {
            return !!race && !!race.status && raceStatusList.some((activeStatus) => {
                return activeStatus === race.status;
            });
        };
    }

    // *** Track Filter Functions ***

    /**
     * Filter callback which rejects tracks missing a current race
     * number or lacking a race matching that number.
     *
     * @param track
     */
    private filterCurrentRaces(track: TodaysDisplayTrack): boolean {
        // Filter out tracks which do not have a current race number matching a race
        return !!track.currentRaceNumber && track.currentRaceIndex !== -1;
    }

    /**
     * Method which returns a filter callback which depends on the provided
     * track status. Rejects tracks with a status that doesn't match the
     * one provided.
     *
     * @param trackStatus
     */
    public filterTrackStatus(trackStatus: enumTrackStatus): (track: TodaysDisplayTrack) => boolean {
        return (track: TodaysDisplayTrack) => {
            return !!track && !!track.status && track.status === trackStatus;
        };
    }

    /**
     * Method which returns a filter callback which depends
     * on the provided filter config. Rejects track types
     * which do not match an active type.
     *
     * @param filterConfig
     */
    private filterBreedType(filterConfig: TodaysRaceFilters): (track: TodaysDisplayTrack) => boolean {
        const activeTypes = this.getActiveFilters(filterConfig);

        const showAllTypes: boolean = activeTypes.indexOf(enumFilters.ALL) !== -1;

        return (track: TodaysDisplayTrack) => {
            return showAllTypes ? true
                                : activeTypes.some((activeType) => {
                                    return activeType === track.type
                                });
        };
    }

    public getActiveFilters(filterConfig: TodaysRaceFilters): (enumFilters | enumTrackType)[] {
        return Object.keys(filterConfig).filter((filter) => {
            return filterConfig[filter] === true;
        }) as (enumFilters | enumTrackType)[];
    }

    public findTrackInList(brisCode: string, trackType: enumTrackType, trackList: TodaysDisplayTrack[]): TodaysDisplayTrack {
        if (!brisCode || !trackType || !trackList) {
            return null;
        }

        const brisCodeLowerCase: string = brisCode.toLowerCase();
        const trackTypeLowerCase: string = trackType.toLowerCase();
        const todaysTrack = trackList.find((track) => {
            return track.brisCode.toLowerCase() === brisCodeLowerCase && track.type.toLowerCase() === trackTypeLowerCase;
        });

        return !!todaysTrack ? todaysTrack : null;
    }

    /**
     * For each track, format the track name for route navigation,
     * create a new track object, and append display information to
     * each race.
     *
     * @param trackListData
     * @returns {Observable<TodaysDisplayTrack[]>}
     */
    private appendRaceDisplayInfo(trackListData: ITodaysTrack[]): TodaysDisplayTrack[] {
        return trackListData.map((track) => {
            let raceDisplayInfo: IMtpDisplay[] = [];
            raceDisplayInfo = track.races.map((race) => {
                const isCurrentRace = race.raceNumber === track.currentRaceNumber;
                return this.mtpDisplayUtil.deriveMtpDisplay({mtp: race.mtp, raceStatus: race.status, postTimestamp: race.postTime}, isCurrentRace);
            });

            return new TodaysDisplayTrack(track, this.stringSlugify.transform(track.name), raceDisplayInfo);
        });
    }

    /**
     * sortTrackLists sorts a group of trackLists for TIME view
     *
     * Time View sorts tracks chronologically by current race status/MTP/post-time for each section (e.g. Favorites, All Races)
     *
     * @private
     * @param {ITrackGroupLists} trackList
     * @returns {ITrackGroupLists}
     * @memberof TodaysRacesBusinessService
     */
    private sortTrackLists(trackList: ITrackGroupLists, sort = TrackSort.MTP): ITrackGroupLists {
        for (const key of Object.keys(trackList)) {
            this.sortTracks(trackList[key], sort);
        }
        return trackList;
    }

    /**
     * sortTracks sorts an array of TodaysDisplayTrack using cdux-ng's SortTracksByMtp pipe
     *
     * @private
     * @param {TodaysDisplayTrack[]} tracks
     * @returns {TodaysDisplayTrack[]}
     * @memberof TodaysRacesBusinessService
     */
    private sortTracks(tracks: TodaysDisplayTrack[], sort: TrackSort): TodaysDisplayTrack[] {
        return tracks.sort((a: TodaysDisplayTrack, b: TodaysDisplayTrack) => {
            switch (sort) {
                case TrackSort.NAME:
                    return a.formattedTrackName > b.formattedTrackName ? 1 : -1;
                case TrackSort.MTP:
                default:
                    return this.sortMtpPipe.compare(a, b);
            }
        });
    }

    /**
     * Format (or generate) a displayable label for this track.
     *
     * @private
     * @param {TodaysDisplayTrack} track
     * @param {string} label (optional) defaults to track name
     * @returns {string}
     * @memberof TodaysRacesBusinessService
     */
    private formatTrackLabel(track: TodaysDisplayTrack, label?: string): string {
        if (!label) {
            return TrackService.formatTrackName(track.ITrackBasic);
        } else {
            const tti = TrackService.getTrackTypeIndicator(track.ITrackBasic);
            return tti ? label + ' ' + tti : label;
        }
    }

    /**
     * Storage logic
     *
     * Check if a filter object exists in storage.
     */
    private checkFilterStorage(): Observable<TodaysRaceFilters> {
        return from(this.cduxStorageService.fetch({ db: TodaysRacesFilterInfo.DB, _id: TodaysRacesFilterInfo.KEY, version: TodaysRacesFilterInfo.VERSION })
            .then((storedFilters: any) => {
                return storedFilters.filters ? storedFilters.filters : null;
            })
            .catch(() => {
                return null;
            }));
    }

    public storeFilters(filters): void {
        // Returns a promise indicating successful storage of the filter object.
        this.cduxStorageService.store(new TodaysRacesFilterInfo(filters));
    }

    /**
     * get current race date
     */
    public getRaceDateChanges(): Observable<string> {
        return this.toteService.currentRaceDate(true);
    }

    /**
     * Maps a given race to a RaceDetails object
     */
    public mapTodaysRaceToRaceDetails(todaysRace: ITodaysRace, basicTrack: ITrackBasic): Partial<RaceDetails> {
        if (!!todaysRace) {
            return {
                BreedType: !!basicTrack && !!basicTrack.TrackType ? TrackService.getTrackTypeBds(basicTrack.TrackType) : null,
                Gait: todaysRace.gait,
                Grade: todaysRace.grade,
                MaxClaimPrice: !Number.isNaN(+todaysRace.maxClaimPrice) ? +todaysRace.maxClaimPrice : null,
                RaceName: todaysRace.raceName,
                RaceType: todaysRace.raceType,
                RestrictionType: todaysRace.restrictionType
            };
        } else {
            return null;
        }
    }
}
