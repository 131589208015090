import {
    PoolType,
    MultiRaceExoticBetType,
    enumBetModifier,
    IBetNavObject
} from '@cdux/ng-common';
import { ILegInfo } from '../interfaces/leg-info.interface';

export class LegInfoUtil {
    public static assembleLegInfo(betNav: IBetNavObject, isCompact: boolean = false): ILegInfo {
        if (!!betNav  && Object.keys(betNav).length > 0) {
            const legs = betNav.type.poolType.raceLegs;
            const selectionCount = betNav.type.poolType.selectionCount;
            const selectedBetModifier = betNav.modifier;
            if (betNav.type instanceof MultiRaceExoticBetType && legs) {
                return this._setLegsForMultiPick(betNav.type, selectionCount);
            } else if (legs && !!selectedBetModifier) {
                switch (selectedBetModifier) {
                    case enumBetModifier.KEY:
                        if (isCompact) {
                            return { legs, legsCounter: ['Key', 'W/'], multiRace: false };
                        } else {
                            return { legs, legsCounter: ['Key', 'With'], multiRace: false };
                        }
                    case enumBetModifier.KEY_BOX:
                        return { legs, legsCounter: ['Key', 'Box'], multiRace: false };
                    case enumBetModifier.BOX:
                        return { legs, legsCounter: ['Box'], multiRace: false };
                    default:
                        return { legs, legsCounter: this._setLegsForStraight(selectionCount), multiRace: false };
                }
            } else {
                switch (betNav.type.poolType.code) {
                    case PoolType.PLACE.code:
                    case PoolType.SHOW.code:
                    case PoolType.WIN.code:
                        return { legs, legsCounter: [betNav.type.poolType.displayName], multiRace: false };
                    default:
                        return { legs, legsCounter: [betNav.type.poolType.code], multiRace: false };
                }
            }
        }
        return { legs: 0, legsCounter: [], multiRace: false };
    }

    private static _setLegsForMultiPick(betTypeModel: MultiRaceExoticBetType, selectionCount: number): ILegInfo {
        const legsCounter = [];
        for (let i = 0; i < selectionCount; i++) {
            legsCounter.push(`RACE ${betTypeModel.legRaceNumbers[i]}`);
        }
        return { legs: selectionCount, legsCounter, legRaceNumbers: betTypeModel.legRaceNumbers, multiRace: true };
    }

    private static _setLegsForStraight(selectionCount: number): string[] {
        return new Array(selectionCount)
            .fill(1)
            .map((x, i) => {
                let suffix: string;
                switch (i + 1) {
                    case 1:
                        suffix = 'st';
                        break;
                    case 2:
                        suffix = 'nd';
                        break;
                    case 3:
                        suffix = 'rd';
                        break;
                    default:
                        suffix = 'th';
                        break;
                }
                return (i + 1) + suffix;
            });
    }
}
