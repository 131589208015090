import { OnDestroy, HostListener, Output, EventEmitter, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import {
    enumToggleState,
    EventClickType,
    CduxObjectUtil,
} from '@cdux/ng-common';
import { SidebarService } from '../../sidebar/sidebar.service';
import { ISidebarPortalState } from '../../sidebar/interfaces/sidebar-portal-state.interface';
import { MenuItemsEnum } from '../enums/menu-items.enum';

@Directive()
export abstract class AbstractMenuItemComponent implements OnDestroy, OnInit {

    /**
     * The click event to be tracked.
     *
     * @type [EventClickType}
     */
    protected abstract clickEvent: EventClickType;

    /**
     * Used to identify the menu item that should be activated when something is externally opening the nav panel.
     *
     * @type {MenuItemsEnum}
     */
    protected abstract navTarget: MenuItemsEnum;

    /**
     * List of subscriptions in the component.
     *
     * @type {Subscription[]}
     * @private
     */
    protected _subscriptions: Subscription[] = [];

    /**
     * Determines the active state of the menu item.
     * @type {boolean}
     * @private
     */
    private _isActive = false;

    /**
     * Determines if the state of the menu item represents a
     *  success state.
     *
     * @private
     */
    private _isSuccess = false;

    /**
     * Getter for the activation state.
     *
     * @returns {boolean}
     */
    public get isActive() {
        return this._isActive;
    }

    /**
     * Getter for the success state
     *
     * @returns {boolean}
     */
    public get isSuccess() {
        return this._isSuccess;
    }

    /**
     * Communicates the activation state on a toggle event.
     *
     * @type {EventEmitter<enumToggleState>}
     */
    @Output() public toggle: EventEmitter<enumToggleState> = new EventEmitter<enumToggleState>();

    /**
     * Listen for touch events on the button on tablet and phone.
     * @param {Event} $event
     */
    @HostListener('touchstart', ['$event']) public touchStart($event: Event): void {
        this._handleUserInteraction($event);
    }

    /**
     * Listen for clicks on the button.
     *
     * @param {Event} $event
     */
    @HostListener('click', ['$event']) public onClick($event: Event): void {
        this._handleUserInteraction($event);
    }

    private _handleUserInteraction($event: Event) {
        if (!!this.clickEvent) {
            this._eventTrackingService.logClickEvent(this.clickEvent);
        }

        // We have to prevent clicks from propogating up to the sidebar
        //  component listeners that would immediately dismiss any nav-panel
        //  that is loaded through the sidebarService with
        // "disableBackdropClick = false"
        $event.preventDefault();
        $event.stopPropagation();
        if (!this._isActive) {
            this.activate();
            this.toggle.emit(enumToggleState.ON);
        } else {
            this.deactivate();
            this.toggle.emit(enumToggleState.OFF);
        }
    }

    /**
     * Constructor
     *
     * @param {SidebarService} _sidebarService
     * @param {EventTrackingService} _eventTrackingService
     */
    constructor(protected _sidebarService: SidebarService,
                protected _eventTrackingService: EventTrackingService) {}

    /**
     * I need to listen for external changes of the target component so that
     * the menu items can set their active state.
     */
    ngOnInit() {
        if (!!this.navTarget) {
            this._subscriptions.push(
                this._sidebarService.onPortalStateChanged
                    .subscribe((portalState: ISidebarPortalState) => {
                        if (portalState.isOpen
                            && CduxObjectUtil.deepGet(portalState, 'portalComponent.properties.navTarget') === this.navTarget) {
                            this._isActive = true;
                            this._isSuccess = !!CduxObjectUtil.deepGet(portalState, 'portalOptions.isSuccess');
                        } else {
                            this._isActive = false;
                            this._isSuccess = false;
                        }
                    })
            );
        }
    }

    /**
     * Handles garbage collection.
     */
    ngOnDestroy() {
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    /**
     * Actions to perform on activation.
     */
    protected abstract activate(): void;

    /**
     * Actions to perform on deactivation.
     */
    protected abstract deactivate(): void;

}
