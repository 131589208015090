import { ValidationErrors, AbstractControl, Validators } from '@angular/forms';

import { CreditCard } from '../utils/creditcard';

function isEmptyInputValue(value: any): boolean {
  return value == null || value.length === 0;
}

export class FundingValidators {

  public static readonly fundingValidationErrorMessages = {
    badBin: 'We are unable to process this specific credit card. Please try another card or choose another deposit method.',
    badBinSmallGlass: 'We are unable to process this specific credit card. Please try another card or choose another <span class="text-underline has-cursor-finger">deposit method</span>'
  };

  static conditionalRequired(condition: boolean): ValidationErrors {
    return (control: AbstractControl): ValidationErrors | null => {
      return (isEmptyInputValue(control.value) && condition) ? { 'required': true } : null;
    };
  }

  static validateCCNumber(amexEnabled: boolean): ValidationErrors {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;
      }

      let response: any = null;
      const status = CreditCard.getCardType(control.value, amexEnabled);
      if (!status) {
        response = { 'invalidCC': true };
      }
      return response;
    };
  }

  static validateExpDate(): ValidationErrors {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;
      }

      if (Validators.required(control) !== undefined && Validators.required(control) !== null) {
        return { 'expDate': true };
      }

      if (typeof control.value !== 'undefined' && control.value.length >= 5) {
        let [month, year] = control.value.split(/[\s\/]+/, 2);

        if ((year != null ? year.length : void 0) === 2 && /^\d+$/.test(year)) {
          const prefix = new Date().getFullYear().toString().slice(0, 2);
          year = prefix + year;
        }
        month = parseInt(month, 10).toString();
        year = parseInt(year, 10).toString();

        if (/^\d+$/.test(month) && /^\d+$/.test(year) && (month >= 1 && month <= 12)) {
          const expiry = new Date(year, month);
          const currentTime = new Date();
          expiry.setMonth(expiry.getMonth() - 1);
          expiry.setMonth(expiry.getMonth() + 1, 1);

          if (expiry > currentTime) {
            return null;
          }
        }
      }

      return { 'expDate': true };
    };
  }
}
