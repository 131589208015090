<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>
<div class="runner-details greyhound" *ngIf="isLoaded" [cduxQAAutomation]="'expanded-entry-details'">
    <ng-container *ngIf="showDetails; else noDetailsAvailable">
        <div [ngClass]="{'detail-info-mobile': isPhone, 'detail-info-full': !isPhone}">
            <div class="details-col">
                <h1 id="runnerName">{{ data.runnerName }}</h1>
                <div class="breeder-owner">
                    <div class="breeder-info">
                        <div class="label">KENNEL</div>
                        <div class="breeder-spacer"></div>
                        <div class="info" id="kennelName">{{ data.kennelName }}</div>
                    </div>
                </div>
            </div>

            <div class="history-surface-container">
                <div class="history-col">
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td>Record</td>
                                <td class="right-align">Starts</td>
                                <td class="right-align">1ST</td>
                                <td class="right-align">2ND</td>
                                <td class="right-align">3RD</td>
                                <td class="right-align">4TH</td>
                            </tr>

                            <tr *ngIf="data.lifetimeRaceSummary">
                                <td>LIFE</td>
                                <td class="right-align" id="lifeSumStartCount">{{data.lifetimeRaceSummary?.startCount}}</td>
                                <td class="right-align" id="lifeSumWinCount">{{data.lifetimeRaceSummary?.winCount}}</td>
                                <td class="right-align" id="lifeSumPlaceCount">{{data.lifetimeRaceSummary?.placeCount}}</td>
                                <td class="right-align" id="lifeSumShowCount">{{data.lifetimeRaceSummary?.showCount}}</td>
                                <td class="right-align" id="lifeSumFourthCount">{{data.lifetimeRaceSummary?.fourthCount}}</td>
                            </tr>

                            <tr *ngIf="data.trackRaceSummary">
                                <td id="trackSumTrackCode">{{data.trackRaceSummary?.track}}</td>
                                <td class="right-align" id="trackSumStartCount">{{data.trackRaceSummary?.startCount}}</td>
                                <td class="right-align" id="trackSumWinCount">{{data.trackRaceSummary?.winCount}}</td>
                                <td class="right-align" id="trackSumPlaceCount">{{data.trackRaceSummary?.placeCount}}</td>
                                <td class="right-align" id="trackSumShowCount">{{data.trackRaceSummary?.showCount}}</td>
                                <td class="right-align" id="trackSumFourthCount">{{data.trackRaceSummary?.fourthCount}}</td>
                            </tr>
                        <tr>
                            <td class="right-align"></td>
                            <td class="right-align"></td>
                            <td class="right-align"></td>
                            <td class="right-align"></td>
                            <td class="right-align"></td>
                            <div class="performance-details" *ngIf="data.bestTime">
                                <div class="time">Best Time</div>
                                <div class="data" id="bestTime">{{ data.bestTime }}</div>
                            </div>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="details-scroll-x">
            <table width="100%" border="0" cellspacing="0" cellpadding="0" id="tblPastPerformances" class="details-table-9col">
                <tbody>
                    <tr>
                        <td>{{ getRacesText() }}</td>
                        <td>DIST</td>
                        <td>GRADE</td>
                        <td>PP</td>
                        <td>ODDS</td>
                        <td>FIN</td>
                        <td>TIME</td>
                    </tr>
                    <tr *ngFor="let pp of data.pastPerformance | sortObjectsByKeys:'raceDate':true">
                        <td>
                            <p class="date">{{ (pp.raceDate | date: 'MMM d, yy').replace(', ', ', \'') }}</p>
                            <p class="track">{{pp.trackCode}}</p>
                        </td>
                        <td>{{pp.distance || '-'}}</td>
                        <td>{{pp.grade || '-'}}</td>
                        <td>{{pp.postPosition || '-'}}</td>
                        <td>{{pp.odds || '-'}}</td>
                        <td>{{pp.finishPosition || '-'}}</td>
                        <td>{{pp.runnerTime || '-'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="details-scroll-x">
            <table class="details-table-9col" id="tblComments">
                <tbody>
                    <tr class="header">
                        <td>COMMENTS</td>
                        <td>OFF</td>
                        <td>1/8</td>
                        <td>STRETCH</td>
                        <td>FIN</td>
                        <td>MARGIN</td>
                        <td>COMMENTS</td>
                    </tr>
                    <tr *ngFor="let pp of data.pastPerformance | sortObjectsByKeys:'raceDate':true">
                        <td>
                            <p class="date">{{ (pp.raceDate | date: 'MMM d, yy').replace(', ', ', \'') }}</p>
                            <p class="track">{{pp.trackCode}}</p>
                            <!-- <p class="race-type">GRADE 1</p> -->
                        </td>
                        <td>{{pp.offPosition || '-'}}</td>
                        <td>{{pp.eightPosition || '-'}}</td>
                        <td>{{pp.stretchPosition || '-'}}</td>
                        <td>{{pp.finishPosition || '-'}}</td>
                        <td>{{pp.finishMargin || '-'}}</td>
                        <td>{{pp.comments || '-'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>

    <ng-template #noDetailsAvailable>
        <div class="no-details" *ngIf="isLoaded">
            <h1>No details available.</h1>
        </div>
    </ng-template>
</div>
