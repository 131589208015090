import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { FeatureToggleDataService, FUND_ID, JwtSessionService } from '@cdux/ng-common';
import { BetShareSettings } from '@cdux/ng-fragments';

import { AccountHistorySidebarComponent } from 'app/shared/my-account/account-history/components/account-history-sidebar/account-history-sidebar.component';
import { BetsContainerComponent, BetsViewEnum } from 'app/shared/bets/components/bets-container/bets-container.component';
import { BetSlipContainerComponent } from 'app/shared/bets/components/bet-slip-container/bet-slip-container.component';
import { BetSuccessPanelComponent } from 'app/shared/bets/components/bet-success/bet-success-panel.component';
import { CashierComponent } from 'app/shared/my-account/cashier/cashier.component';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FundingDepositOptionsComponent } from 'app/shared/funding/components/deposit-options/deposit-options.component';
import { FundingPaypalCompleteDepositComponent } from 'app/shared/funding/components/methods/paypal/paypal-complete-deposit.component';
import { FundingPaypalService } from 'app/shared/funding/shared/services/paypal.service';
import { FundingSuccessComponent } from 'app/shared/funding/shared/components/funding-success/funding-success.component';
import { FundingWithdrawOptionsComponent } from 'app/shared/funding/components/withdraw-options/withdraw-options.component';
import { ISidebarHeaderComponent, ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { ISidebarPortalOptions } from 'app/shared/sidebar/interfaces/sidebar-portal-state.interface';
import { ManageComponent } from 'app/shared/my-account/manage/components/manage.component';
import { MyAccountContainerComponent } from 'app/shared/my-account/my-account-container/my-account-container.component';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';

import { InterceptorService } from '../interceptor.service';
import { InterceptorEvent, ISidenavRoute, SidenavRoute } from '../models';
import { RewardsContainerComponent } from 'app/shared/my-account/rewards/components/rewards-container/rewards-container.component';

@Injectable()
export class DeeplinkInterceptor {

    // Public Accessor to Set Routes
    public set sidenavRoutes(routes: ISidenavRoute[]) {
        this._sidenavRoutes = routes;
    }
    // Public Accessor to Get Routes
    public get sidenavRoutes(): ISidenavRoute[] {
        return this._sidenavRoutes;
    }

    // Sidenav Routes
    private _sidenavRoutes: ISidenavRoute[] = [
        {
          authGuarded: true,
          path: 'bet-slip',
          component: BetsContainerComponent,
          inputs: {
              currentBetsView: BetsViewEnum.BET_SLIP
          }
        },
        {
            authGuarded: true,
            path: 'saved-bets',
            component: BetSlipContainerComponent,
        },
        {
            authGuarded: true,
            path: 'todays-bets',
            component: TodaysBetsContainerComponent,
        },
        {
            authGuarded: true,
            path: 'active-bets',
            component: BetsContainerComponent,
            inputs: {
                currentBetsView: BetsViewEnum.ACTIVE_BETS
            }
        },
        {
            authGuarded: true,
            path: 'bet-success', // TODO: Consider changing this to 'bet-share-success'
            component: BetSuccessPanelComponent,
            inputs: { betShareDisplayTime: BetShareSettings.BET_SHARE_DISPLAY_TIME, navTarget: MenuItemsEnum.TODAYS_BETS }
        },
        {
            authGuarded: true,
            path: 'ended-bets',
            component: BetsContainerComponent,
            inputs: {
                currentBetsView: BetsViewEnum.ENDED_BETS
            }
        },
        // TODO: while SUFFIX_ACTION_FULL_PAGE is disabled (empty string), this creates a conflict with the above
        /*{
            path: FundingPaypalService.DEEPLINK_ACTION + FullPageFundingConstants.SUFFIX_ACTION_FULL_PAGE,
            authGuarded: true,
            component: FullPageFundingComponent,
        },*/
        {
            path: FundingPaypalService.DEEPLINK_ACTION,
            authGuarded: true,
            component: FundingPaypalCompleteDepositComponent,
            inputs: {
                fundingMethodDetails: {},
            }
        },
        {
            authGuarded: true,
            path: 'greendotBarcodeReceived',
            component: FundingSuccessComponent,
            inputs: {
                methodToSelect: FUND_ID.GREENDOTATR
            }
        },
        {
            authGuarded: true,
            path: 'ezbankSuccess',
            component: FundingSuccessComponent,
            inputs: {
                methodToSelect: FUND_ID.MAZOOMA
            }
        },
        {
            authGuarded: true,
            path: 'rewards',
            component: RewardsContainerComponent
        },
        {
            path: 'my-account',
            component: MyAccountContainerComponent,
            authGuarded: true,
            children: [
                {
                    path: 'my-account-settings',
                    component: ManageComponent,
                    authGuarded: true
                },
                {
                    path: 'account-history',
                    component: AccountHistorySidebarComponent
                },
                {
                    path: 'cashier',
                    component: CashierComponent,
                    children: [
                        {
                            path: 'deposit',
                            component: FundingDepositOptionsComponent,
                        },
                        {
                            path: 'mazooma',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.MAZOOMA
                            },
                        },
                        {
                            path: 'ezmoneydeposit',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.EZMONEY
                            },
                        },
                        {
                            path: 'paypaldeposit',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.PAYPAL
                            },
                        },
                        {
                            path: 'creditcard',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.CREDITCARD
                            },
                        },
                        {
                            path: 'moneygram',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.MONEYGRAM
                            },
                        },
                        {
                            path: 'paynearme',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.PAYNEARME
                            },
                        },
                        {
                            path: 'withdraw',
                            component: FundingWithdrawOptionsComponent,
                        },
                        {
                            path: 'ezmoneywithdrawal',
                            component: FundingWithdrawOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.EZMONEY_W
                            },
                        },
                        {
                            path: 'greendot',
                            component: FundingDepositOptionsComponent,
                            inputs: {
                                methodToSelect: FUND_ID.GREENDOTATR
                            },
                        }
                    ]
                }
            ]
        }
    ];

    private fullPageMethods: any = {
        deposit: {
            operation: 'deposit',
            method: FullPageFundingConstants.ROUTE_PARAM_DUMMY
        },
        mazooma: {
            operation: 'deposit',
            method: 'mazooma',
        },
        ezmoneydeposit: {
            operation: 'deposit',
            method: 'ezmoney',
        },
        paypaldeposit: {
            operation: 'deposit',
            method: 'paypal',
        },
        creditcard: {
            operation: 'deposit',
            method: 'creditcard',
        },
        moneygram: {
            operation: 'deposit',
            method: 'moneygram',
        },
        paynearme: {
            operation: 'deposit',
            method: 'paynearme',
        },
        greendot: {
            operation: 'deposit',
            method: 'greendotatr'
        }
    };

    private _interceptorSubscription: Subscription;
    public ftFullPageDeposit = false;
    public ftFullPageWithdrawal = false;

    constructor(
        private _interceptorService: InterceptorService,
        private _sidebarService: SidebarService,
        private _jwtSessionService: JwtSessionService,
        private _router: Router,
        localFeatureToggleService: FeatureToggleDataService,
    ) {
        this.ftFullPageWithdrawal = localFeatureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
        if (this.ftFullPageWithdrawal) {
            this.fullPageMethods = {
                ...this.fullPageMethods,
                withdraw: {
                    operation: 'withdraw',
                    method: FullPageFundingConstants.ROUTE_PARAM_DUMMY
                },
                ezmoneywithdraw: {
                    operation: 'withdraw',
                    method: 'ezmoney'
                },
                paypalwithdraw: {
                    operation: 'withdraw',
                    method: 'paypal'
                },
                checkwithdraw: {
                    operation: 'withdraw',
                    method: 'check'
                }
            }
        }
    }

    /**
     * Initializes our subscription to watch for the proper interceptor
     * events so that it can respond appropriately and load the proper
     * sidenav routes.
     */
    public register(): void {
        if (!this._interceptorSubscription) {
            this._interceptorSubscription = this._interceptorService.navigationEnd
                .subscribe((event: InterceptorEvent<NavigationEnd>) => {
                    const action = event?.params['action']?.toLowerCase() || null;
                    let inputs;
                    try {
                        inputs = !!event.params['inputs'] ? JSON.parse(event.params['inputs']) : null;
                    } catch {
                        inputs = null;
                    }

                    if (action) {
                        if (this.fullPageMethods[action]) {
                            this._router.navigate(['funding', this.fullPageMethods[action].operation, this.fullPageMethods[action].method]);
                        } else {
                            this._loadSidenavRoute(action, inputs);
                        }
                    }
                });
        }
    }

    public isRoute(action: string): boolean {
        return this._findRoute(this._sidenavRoutes, action).length > 0;
    }

    /**
     * Loads a Component into the Sidenav Based on the Action Query Parameter
     * @param action - Action Query Parameter String
     */
    private _loadSidenavRoute(action: string, inputs: any): boolean {
        const path = this._findRoute(this._sidenavRoutes, action);
        let routeOrParentRouteIsAuthGuarded = null;
        let routeOrParentRouteIsReverseAuthGuarded = null;

        for (let i = 0; i < path.length; i++) {
            const route = new SidenavRoute().fromRoute(path[i]);

            try {
                if (!!inputs) {
                    route.inputs = !!route.inputs ? Object.assign(route.inputs, inputs) : inputs;
                }
                const loggedIn = this._jwtSessionService.isLoggedIn();

                // check authGuarded.  If not available we will set to false or
                // keep the parent routes authGuarded value.  If current route
                // has one, we use it.
                if (route.hasOwnProperty('authGuarded')) {
                    routeOrParentRouteIsAuthGuarded = route.authGuarded;
                } else if (routeOrParentRouteIsAuthGuarded == null) {
                    routeOrParentRouteIsAuthGuarded = false;
                }
                // same as above, using reverseAuthGuarded
                if (route.hasOwnProperty('reverseAuthGuarded')) {
                    routeOrParentRouteIsReverseAuthGuarded = route.reverseAuthGuarded;
                } else if (routeOrParentRouteIsReverseAuthGuarded == null) {
                    routeOrParentRouteIsReverseAuthGuarded = false;
                }

                if (routeOrParentRouteIsAuthGuarded && !loggedIn) {
                    this._routeToLoginWithRedirect(this._router.url);
                    break;
                } else if ((!routeOrParentRouteIsAuthGuarded && !routeOrParentRouteIsReverseAuthGuarded)
                    || (routeOrParentRouteIsAuthGuarded && loggedIn)
                    || (routeOrParentRouteIsReverseAuthGuarded && !loggedIn)) {

                    this._loadRouteInSidebar(route, i === 0);
                } else {
                    break;
                }

            } catch (err) {
                console.error(`Deeplinker routed componnents must be types that inherit CduxAbstractSidebarComponent. Did you forget to implement this in ${route.component.name}?`);
            }
        }
        return path.length > 0;
    }

    private _loadRouteInSidebar(route: ISidenavRoute, isRoot: boolean): void {
        const component: ISidebarPortalComponent = route.component.getSidebarComponent(route.inputs);
        let header: ISidebarHeaderComponent;
        if (route.component.hasOwnProperty('getHeaderComponent')) {
            header = route.component.getHeaderComponent(route.headerInputs);
        }
        const opts: ISidebarPortalOptions = route.navigationRef || {};
        // Each time we process a deeplinked component, we want to
        //  clear the history, but only on the root path in the route
        opts.clearHistory = isRoot;
        this._sidebarService.loadComponent(component, header, opts);
    }

    /**
     * Finds the Associated Route and its Path
     * @param routes - Collection of ISidenavRoute
     * @param pathName - Path to Search for
     */
    private _findRoute(routes: ISidenavRoute[], pathName: string): ISidenavRoute[] {
        let path = [];
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            if (route.path === pathName) {
                path.push(route);
                break;
            } else if (route.children && route.children.length) {
                const childPath = this._findRoute(route.children, pathName);
                if (childPath.length) {
                    childPath.unshift(route);
                    path = childPath;
                    break;
                }
            }
        }
        return path;
    }

    private _routeToLoginWithRedirect(redirectUrl: string) {
        const urlTree = this._router.parseUrl(redirectUrl);
        const queryParams = urlTree.queryParams;
        const urlWithoutParams = redirectUrl.indexOf('?') > -1 ? redirectUrl.split('?')[0] : redirectUrl;
        this._jwtSessionService.redirectLoggedInUserUrl = urlWithoutParams;
        this._jwtSessionService.redirectInputs = queryParams;
        this._router.navigate(['/login']);
    }
}
