import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { EventClickType, FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { SidebarService } from '../../../../sidebar/sidebar.service';

import { FundingChangeOfAddressComponent } from '../../../shared/components/change-address/change-address.component';

import { FundingService } from '../../../shared/services/funding.service';

import { FundingAbstractMethodWithdrawComponent } from '../abstract-method-withdraw.component';
import { FundingCheckService } from '../../../shared/services/check.service';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { SIDEBAR_LOADERS } from '../../../../sidebar/enums/loader.enums';
import { FundingCheckMethodHeaderComponent } from './check-method-header.component';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service';
import {enumWithdrawOptions} from '../abstract-method.component';
import { FullPageFundingConstants } from '../../../full-page-funding/full-page-funding.constants';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-check-method-withdraw',
    templateUrl: './check-method-withdraw.component.html'
})
export class FundingCheckMethodWithdrawComponent extends FundingAbstractMethodWithdrawComponent implements OnInit {

    private _selectedDeliveryMethod = null;
    public enumWithdrawDisplayStyle = enumFundingDisplayStyle;
    public fpwEnabled: boolean = false; // Whether the Full Page withdrawal feature is turned on


    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingCheckMethodWithdrawComponent, options)
    }

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingCheckMethodWithdrawComponent, options)
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingCheckMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventTrackingService: EventTrackingService,
        _translateService: TranslateService,
        public router: Router,
        _headerService: HeaderService,
        protected _sessionService: JwtSessionService,
        _mediaService: CduxMediaToggleService,
        private _checkService: FundingCheckService,
        private _currencyPipe: CurrencyPipe,
        private _changeDetectorRef: ChangeDetectorRef,
        _featureToggleService: FeatureToggleDataService,
    ) {
        super(
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _eventTrackingService,
            _translateService,
            router,
            _headerService,
            _mediaService,
            _sessionService,
            _featureToggleService,
            _changeDetectorRef
        );
        this.operationMethod = enumWithdrawOptions.CHECK;
    }

    public ngOnInit() {
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
        this._initForm([
            {
                name: 'amount',
                default: 0,
                validators: [
                    Validators.min(1),
                    Validators.max(this.getMaxWithdrawAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            },
            {
                name: 'deliveryMethod',
                default: null,
                validators: [
                    Validators.required
                ]
            },
            {
                name: 'signatureRequired',
                default: null,
                validators: []
            },
            {
                name: 'total',
                default: 0,
                validators: [
                    Validators.min(1),
                    Validators.max(this.withdrawBalance ? this.withdrawBalance.availableBalance : 1000)
                ]
            }
        ]);
        this._subscriptions.push(
            this.form.get('amount').valueChanges.subscribe((value) => {
                this._recalculateTotals();
            }),
            this.form.get('deliveryMethod').valueChanges.subscribe((value) => {
                this._selectedDeliveryMethod = this.accountInfo.miscInfo.deliveryMethods.find((v) => v.code === value);
                this._updateSignatureRequiredField();
            })
        );
        this._updateSignatureRequiredField();
    }

    /**
     * Triggers a withdraw in the Business Service
     */
    public onWithdraw( isFullscreen: boolean = false) {
        this.pendingWithdraw = true;
        const amount = this.form.get('amount').value;
        if (!isFullscreen) {
            this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        }
        this.successMessage = `${this._currencyPipe.transform(amount, 'USD', 'symbol-narrow')} has been withdrawn from your account and a check will be mailed to your address.`;
        this._subscriptions.push(
            this._checkService.withdraw(amount, this.form.get('deliveryMethod').value, this.form.get('signatureRequired').value).subscribe((res) => {
                this._handleResponse(
                    res,
                    this.successMessage,
                    amount,
                    isFullscreen
                )
            })
        );
    }

    /**
     * Opens the COA page in the Sidenav
     */
    public openChangeAddress() {
        this._eventTrackingService.logClickEvent(EventClickType.WITHDRAW_TOOLTIP_CHECK_ADDRESS);

        if (this.isSmallGlass) {
            this._sidebarService.loadDropupComponent(FundingChangeOfAddressComponent.getSidebarComponent(), true, 'Change Address');
        } else {
            this._sidebarService.loadComponent(FundingChangeOfAddressComponent.getSidebarComponent());
        }
    }

    public getShippingCost(methodCode: string) {
        if (this.accountInfo && this.accountInfo.miscInfo && this.accountInfo.miscInfo.deliveryMethods) {
            const method = this.accountInfo.miscInfo.deliveryMethods.find((v) => v.code === methodCode);
            if (method) {
                return method.cost;
            }
        }
        return 0;
    }

    private _recalculateTotals() {
        const withdrawAmount = this.form.get('amount').value;
        const shippingCost = this.getShippingCost(this.form.get('deliveryMethod').value);
        this.form.get('total').setValue(withdrawAmount + shippingCost);
    }

    private _updateSignatureRequiredField() {
        const sigControl = this.form.get('signatureRequired');
        if (!this._selectedDeliveryMethod || this._selectedDeliveryMethod.cost === 0) {
            sigControl.setValue(false);
            sigControl.disable();
        } else {
            sigControl.enable();
        }
        this._recalculateTotals();
        this._changeDetectorRef.detectChanges();
    }

}
