import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatWPSPoolPercent'
})
export class FormatWPSPoolPercentPipe implements PipeTransform {

  /**
   * Format a given number to a valid % by coercing to number and removing unneeded characters.
   *
   * 0 -> 0%
   * 0.0 -> 0%
   * 1 -> 1%
   * 99.99 - > 100%
   * 100 -> 100%
   * 101 -> ''
   * 1000 -> ''
   * 1,000 -> ''
   *
   * @param {number|string} value
   * @returns {string}
   */
  public transform(value: number|string): string {

    if (value && typeof value === 'string') {
        value = value.replace(',', '');
    }

    if (Number(value) > 100) {
        return '';
    } else {
        return Number(value).toFixed(0) + '%';
    }

  }

}
