import { ModuleWithProviders, NgModule } from '@angular/core';

import { ActivityService } from './services/activity.service';
import { ConnectivityService } from './services/connectivity.service';
import { NotificationBadgeService } from './badges/notification-badge.service';
import { NotificationComponent } from './notification.component';

@NgModule({
    imports: [],
    declarations: [
        NotificationComponent,
    ],
    exports: [],
    providers: [
        NotificationBadgeService,
    ],
})
export class NotificationModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<NotificationModule> {
        return {
            ngModule: NotificationModule,
            providers: [
                ConnectivityService,
                ActivityService
            ],
        }
    }
}
