import { NgModule, ModuleWithProviders } from '@angular/core';
import { RedirectService } from './redirect.service';
import { TuxCommonModule } from '../common/common.module';

@NgModule({
  imports: [
      TuxCommonModule,
  ],
})
export class RedirectModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<RedirectModule> {
        return {
            ngModule: RedirectModule,
            providers: [
                RedirectService,
            ],
        }
    }
}
