<div class="tile">
    <div class="header-row">
        <div id="fav-tile-jockey-jockey-name-{{ jockeyName }}" class="title-name">
            {{ jockeyName }}
            <div class="title-name__stats">
                <span aria-label="view stats" role="link" class="link" tabindex="0" (click)="openStatsModal()">Stats</span>
            </div>
        </div>
    </div>

    <div class="trainer__track-row first is-jockey">
      <div class="trainer__track-row__track-name" id="fav-tile-jockey-track-name-{{ trackName }}">
        {{ trackName }}
      </div>

      <ng-container *ngFor="let runner of runnerDetails; index as i">
        <div class="trainer__track-row__runner-name is-jockey">
          <span id="fav-tile-jockey-runner-name-{{ runner.runnerName }}">{{ runner.runnerName }}</span>
          <button id="fav-tile-jockey-bet" aria-label="bet {{ runner.runnerName }}" class="smaller" tabindex="0" (click)="bet(runner)">Bet</button>
        </div>
        <div class="trainer__track-row__race-info">
          <span id="fav-tile-jockey-race-number-{{ runner.raceNumber }}">R{{ runner.raceNumber }}</span>
          <span id="fav-tile-jockey-race-type-{{ runner.raceType }}">{{ getRaceTypeText(runner.maxClaimPrice, runner.breedType, runner.raceType, false) }}</span>
        </div>
      </ng-container>

      <div *ngIf="additionalRacesCnt > 0" id="fav-tile-jockey-more" class="more is-jockey">plus {{ additionalRacesCnt }} more</div>
      <div *ngIf="additionalRacesCnt === 0" id="fav-tile-jockey-more" class="more is-jockey"></div>
    </div>

    <!-- Add empty rows -->
    <ng-container *ngIf="!isPhone">
      <ng-container *ngFor="let item of [].constructor(emptyRowCnt)">
        <div class="trainer__track-row empty is-jockey"></div>
      </ng-container>
    </ng-container>

    <div class="trainer__track-row__link">
        <span aria-label="see all runners" role="link" class="link see-more" tabindex="0" (click)="expand()">See All Runners</span>
    </div>

    <div class="notes-row">
        <span class="title-name">Notes</span>
        <span id="fav-tile-jockey-notes" class="notes">{{ jockeyData.comment }}</span>
    </div>

    <div class="action-row">
        <!-- EDIT BUTTON HERE -->
        <div class="action-row_col">
            <span id="fav-tile-jockey-edit-button" aria-label="edit this jockey" role="button" class="action-item" tabindex="0" (click)="edit()">Edit</span>
        </div>
        <span aria-hidden="true" class="text__gray2">|</span>
        <div class="action-row_col">
            <span id="fav-tile-jockey-remove-button" aria-label="remove this jockey" role="button" class="action-item" tabindex="0" (click)="remove()">Remove</span>
        </div>
        <span aria-hidden="true" class="text__gray2">|</span>
        <div class="action-row_col">
            <span id="fav-tile-jockey-see-all-button" aria-label="see all runners from this jockey" role="button" class="action-item" tabindex="0" (click)="expand()">See All</span>
        </div>
    </div>
</div>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #removeModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <h2>
      <span id="favorite-add-modal-description" class="runner">
        Are you sure you want to remove {{ jockeyName }}?
      </span>
    </h2>
    <div class="message">You will no longer receive alerts and all notes will be lost.</div>
    <div class="button-row">
      <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
      <div class="btn-remove" (click)="confirmRemove()">Remove</div>
    </div>
  </div>
</ng-template>

<ng-template #updateModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <h2>
      <span id="favorite-add-modal-description" class="runner">{{ jockeyName }}</span>
    </h2>
    <textarea id="favorite-add-modal-note"
              cols="50" [maxlength]="COUNT_MAX" name="note"
              placeholder="ADD A NOTE..." rows="13"
              [(ngModel)]="note"
    ></textarea>
    <div class="char-count"></div>
    <div class="btn-update" (click)="confirmUpdate()">Update</div>
  </div>
</ng-template>

<ng-template #statsModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1"></div>
      <div class="col2"></div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div id="favorite-jockey-modal-detailoptions" class="scrollable">
      <cdux-favorite-stats-jockey
        [detailOptions]="options"
      ></cdux-favorite-stats-jockey>
    </div>
  </div>
</ng-template>

<ng-template #expandedModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner">
      <div class="col1">
        <h1 class="stable-alerts__trainer__runner__title">{{ jockeyName }}</h1>
      </div>

      <div class="col2">
          <div class="stable-alerts__trainer__runner__notes-title">Notes</div>
          <div id="expanded_view_notes" class="stable-alerts__trainer__runner__notes">{{ jockeyData.comment }}</div>
      </div>

      <div class="col3">
          <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>

    <div class="stable-alerts__trainer__runner__title-bar">
      <span class="col1">Runners</span>
      <span class="col2">Time</span>
      <span class="col3">Race</span>
      <span class="col4">Race Details</span>
      <span class="col5">Track</span>
      <span class="col6">BET</span>
    </div>

    <div class="stable-alerts__trainer__runner__scroll-area">
        <ul id="expanded_view_runner_list" class="stable-alerts__trainer__runner__list">
          <li *ngFor="let runner of todaysRunners; index as i">
            <div class="col1">
                <div id="expanded_view_runner_name_{{i}}" class="runner-name">{{ runner.runnerName }}</div>
                <div id="expanded_view_runner_details_{{i}}" class="runner-details">{{ formatRunnerDetails(runner) }}</div>
            </div>
            <div class="col2">
                <div class="mtp-badge minutes-to-post">
                  <cdux-mtp-badge
                    id="expanded_view_mtp_{{i}}-mtpBadge"
                    [source]="setMtpDisplaySrc(runner)"
                    [showMinutes]="true">
                  </cdux-mtp-badge>
                </div>
            </div>
            <div class="col3">
                <span id="expanded_view_race_number_{{i}}">R{{ runner.raceNumber }}</span>
            </div>
            <div class="col4">
                <div id="expanded_view_race_type_{{i}}" class="race-details-1">
                  {{ getRaceTypeText(runner.maxClaimPrice, runner.breedType, runner.raceType, true) }}
                </div>
                <div id="expanded_view_purse_{{i}}" class="race-details-2">{{ runner.formattedPurse }} | <span *ngIf="runner.ageRestriction">{{runner.ageRestriction | uppercase}} | </span><span *ngIf="runner.sexRestriction">{{runner.sexRestriction}} | </span>{{runner.distance}} | {{runner.surfaceDescription | titlecase}}</div>
            </div>
            <div class="col5">
                <span  id="expanded_view_track_name_{{i}}">{{ runner.trackName }}</span>
            </div>
            <div class="col6">
                <button [class.smaller]="!isPhone" (click)="bet(runner, true)">BET</button>
            </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
