import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Injector,
    OnDestroy,
    OnInit,
    ViewContainerRef,
    ViewChild,
    ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavoritePersonService, FavoriteRunnersService, FeatureToggleDataService, CduxObjectUtil } from '@cdux/ng-common';

import { LoadingDotsComponent, LoadingService, ToastService } from '@cdux/ng-fragments';
import { take } from 'rxjs/operators';
import { FavoritesManagerJockeysComponent } from './manager-jockeys/favorites-manager-jockeys.component';

import { FavoritesManagerRunnersComponent } from './manager-runners/favorites-manager-runners.component';
import { FavoritesManagerTracksComponent } from './manager-tracks/favorites-manager-tracks.component';
import { FavoritesManagerTrainersComponent } from './manager-trainers/favorites-manager-trainers.component';
import { FavoritesManagerSettingsComponent } from './manager-settings/favorites-manager-settings.component';
import { DrawerService, FullDrawerOperations, FullDrawerOriginDirection } from 'app/shared/common/services';
import { FavoritesStableSearchComponent } from './favorites-stable-search/favorites-stable-search.component';
import { FavEventType } from "./favorites-event-interface";
import { AccountBubbleNotificationService } from "app/shared/notification/services/account-bubble-notification.service";

@Component({
    // selector: 'cdux-favorites-manager',
    templateUrl: './favorites-manager.component.html',
    styleUrls: ['./favorites-manager.component.scss'],
})
export class FavoritesManagerComponent implements AfterViewInit, OnDestroy, OnInit {
    public static readonly OVERLAY_NAME = 'favMgrLoadingOverlay';

    /**
     * defines manager tabs (key) and maps them to the component to load (value)
     */
    public static readonly FAVORITE_TYPES: { [key: string]: any } = {
        'Runners': {
            component: FavoritesManagerRunnersComponent,
            componentRef: null,
        }
    };

    @ViewChild('favTiles', { read: ViewContainerRef })
    private _tilesOutlet: ViewContainerRef;

    public readonly tabs = Object.keys(FavoritesManagerComponent.FAVORITE_TYPES);
    public isLoading = true;
    public selectedTabIndex: number;
    public selectedTab: string;
    public favoriteType: string;
    public settingsManagementFT: boolean;
    public stableSearchFT: boolean;
    public stableSearchPersonsFT: boolean;
    public runnerBadge: boolean;
    public trainerBadge: boolean;
    public jockeyBadge: boolean;

    private errorMessages = {
        MAX_NUMBER: 'You have reached the maximum number of %d runners. Please remove some runners before adding new ones.',
        NO_NEW_FAVORITES: 'All runners in this batch already exist in your stable.',
        DEFAULT: 'We are unable to add these favorites to your stable. Please try again later.'
    };
    private errorMessageKeys = {
        MAX_NUMBER: 'maximum number',
        NO_NEW_FAVORITES: 'no new favorites'
    };

    // create readonly accessor aliases for template
    public get LOADING_COMPONENT() {
        return LoadingDotsComponent;
    }
    public get OVERLAY_NAME() {
        return FavoritesManagerComponent.OVERLAY_NAME;
    }

    public constructor(
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _injector: Injector,
        localFeatureToggleService: FeatureToggleDataService,
        private _loadingService: LoadingService,
        private _route: ActivatedRoute,
        private _drawerService: DrawerService,
        private favPerson: FavoritePersonService,
        private _favRunners: FavoriteRunnersService,
        private _changeDetector: ChangeDetectorRef,
        private _toastService: ToastService
    ) {
        this.settingsManagementFT = localFeatureToggleService.isFeatureToggleOn('STBLALRT_STTINGS');
        this.stableSearchFT = localFeatureToggleService.isFeatureToggleOn('STABLE_ALRT_SRCH');
        this.stableSearchPersonsFT = localFeatureToggleService.isFeatureToggleOn('STABLE_SRCH_PRSN');
        if (localFeatureToggleService.isFeatureToggleOn('STABLE_TRACK') && !FavoritesManagerComponent.FAVORITE_TYPES.hasOwnProperty('Tracks')) {
            FavoritesManagerComponent.FAVORITE_TYPES.Tracks = {
                component: FavoritesManagerTracksComponent,
                componentRef: null
            };
            this.tabs.push('Tracks');
        }
        if (localFeatureToggleService.isFeatureToggleOn('STABLE_TRAINER') && !FavoritesManagerComponent.FAVORITE_TYPES.hasOwnProperty('Trainers')) {
            FavoritesManagerComponent.FAVORITE_TYPES.Trainers = {
                component: FavoritesManagerTrainersComponent,
                componentRef: null
            };
            this.tabs.push('Trainers');
        }
        if (localFeatureToggleService.isFeatureToggleOn('STABLE_JOCKEY') && !FavoritesManagerComponent.FAVORITE_TYPES.hasOwnProperty('Jockeys')) {
            FavoritesManagerComponent.FAVORITE_TYPES.Jockeys = {
                component: FavoritesManagerJockeysComponent,
                componentRef: null
            };
            this.tabs.push('Jockeys');
        }
        Object.freeze(FavoritesManagerComponent.FAVORITE_TYPES);
    }

    public ngOnInit() {
        this._route.queryParams.pipe(take(1))
            .toPromise().then((routeParam) => {
                if (routeParam.batchId) {
                    this.addRunnerBatch(routeParam.batchId);
                }
            });

        this._route.params.pipe(take(1))
            .toPromise()
            .then((routeParams) => {

                if (routeParams.favoriteType?.includes('batchId')) {
                    const batchId = routeParams.favoriteType.split('=')[1];
                    this.addRunnerBatch(batchId);
                }

                this.favoriteType = routeParams.favoriteType;
                this.selectTab(this.tabs.findIndex((item: string) => item === this.favoriteType), true);
            });
        this._favRunners.hasRunnersToday().then(hasRunners => {
            this.runnerBadge = hasRunners;
        });
        this.favPerson.hasTrainersToday().then(hasRunners => {
            this.trainerBadge = hasRunners;
        });
        this.favPerson.hasJockeysToday().then(hasRunners => {
            this.jockeyBadge = hasRunners;
        });
    }

    public ngAfterViewInit() {
        /**
         * We can't access the ViewChild _tilesOutlet until AfterViewInit.
         * By that point, we can't also modify the view or we'll get an
         * ExpressionChangedAfterItHasBeenCheckedError. This setTimeout
         * (as well as a delay() pipe, or similar) will kick the can just
         * far enough. (Hello, AngularJS digest hacks.)
         */
        setTimeout(() => {
            if (!this.favoriteType) {
                this.selectTab(0, true);
            }
        });
        setTimeout(() => {
            if (location.hash.indexOf('#settingopen') > -1 || location.href.indexOf('%23settingopen') > -1) {
                this.openSettingManagement();
            }
        }, 1000);
    }

    public ngOnDestroy() {
        /**
         * Clean up all component references, consequently triggering
         * their OnDestroy methods and cleaning up subscriptions.
         */
        Object.values(FavoritesManagerComponent.FAVORITE_TYPES).forEach(componentDef => {
            componentDef.componentRef = null;
        });
    }

    public displayBadge(tab: any) {
        switch (tab) {
            case 'Runners':
                return this.selectedTab === 'Runners' ? false : this.runnerBadge;
            case 'Trainers':
                return this.selectedTab === 'Trainers' ? false : this.trainerBadge;
            case 'Jockeys':
                return this.selectedTab === 'Jockeys' ? false : this.jockeyBadge;
        }
    }
    public selectTab (newIndex: number, override = false) {
        // Default to 0 (runners tab) if newIndex is outside the valid (0 - 3) range
        if (newIndex < 0) {
            newIndex = 0;
        }
        // Hide the previous host view (tab content) without destroying it.
        if (!this.isLoading || override) {
            this._loadingService.register(FavoritesManagerComponent.OVERLAY_NAME).then(() => {
                this.isLoading = true;
                this._tilesOutlet.detach();

                this.selectedTabIndex = newIndex;
                this.selectedTab = this.tabs[this.selectedTabIndex];

                if (this.selectedTabIndex >= 0) {
                    this._generateView();

                    // Display the new host view.
                    const componentRef = FavoritesManagerComponent.FAVORITE_TYPES[this.tabs[this.selectedTabIndex]].componentRef;

                    this._loadingService.resolve(FavoritesManagerComponent.OVERLAY_NAME).then(() => {
                        this.isLoading = false;
                        this._tilesOutlet.insert(componentRef.hostView);
                    });
                }
            });
        }
    }

    public canSearchTab() {
        switch (this.selectedTab) {
            case 'Runners':
                return true;
            case 'Trainers':
                return this.stableSearchPersonsFT;
            case 'Jockeys':
                return this.stableSearchPersonsFT;
            default:
                return false;
        }
    }

    public openSettingManagement() {
        this._drawerService.toggleFullDrawer({
            component: FavoritesManagerSettingsComponent,
            operation: FullDrawerOperations.OPEN,
            originDirection: FullDrawerOriginDirection.RIGHT,
            title: 'Stable Settings'
        });
    }

    public openStableSearch() {
        this._drawerService.toggleFullDrawer({
            component: FavoritesStableSearchComponent,
            operation: FullDrawerOperations.OPEN,
            originDirection: FullDrawerOriginDirection.RIGHT,
            title: 'Search ' + this.selectedTab
        });
    }

    /**
     * creates a host view for the given FAVORITE_TYPES index
     */
    private _generateView(tabIndex = this.selectedTabIndex) {
        const componentDef = FavoritesManagerComponent.FAVORITE_TYPES[this.tabs[tabIndex]];
        if (!componentDef?.componentRef && componentDef) {
            const componentFactory = this._componentFactoryResolver.resolveComponentFactory(componentDef?.component);
            componentDef.componentRef = componentFactory.create(this._injector);
        }
    }

    public addRunnerBatch(batchId: string) {
        this._favRunners.addAll(batchId).then((result) => {
            this.isLoading = false;
            this._accountBubbleNotificationService.delay({
                type: FavEventType.FAVORITE_RUNNER_ADD,
                payload: {
                    message: '<i class="icon--check-light-circle"></i>' + 'Runners Added to <span class="bold-text">stable</span>.',
                    duration: 4000
                }
            }, 6000);
            this._changeDetector.markForCheck();
        }, error => {
            // Get the maximum number of records out of the message:
            const errorsArray: string[] = CduxObjectUtil.deepGet(error, 'data.error.errors');
            const errorMessage: string = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].toLowerCase() : null;
            if (errorMessage.search(this.errorMessageKeys.MAX_NUMBER) !== -1) {
                // Show maximum number error
                const maxRecords: any[] = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].match(/(\d+)/g) : null;
                if (error.data.error.code === 400 && Array.isArray(maxRecords) && maxRecords.length) {
                    // Display the error message
                    this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%d', maxRecords[0]));
                }
            } else if (errorMessage.search(this.errorMessageKeys.NO_NEW_FAVORITES) !== -1) {
                // Show already exists error message
                this._toastService.cduxWarning(this.errorMessages.NO_NEW_FAVORITES);
            } else {
                // Show default error message
                this._toastService.cduxWarning(this.errorMessages.DEFAULT);
            }
        });
    }
}
