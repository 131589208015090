import { NgModule } from '@angular/core';
import { FormatProbablePipe } from './format-probable.pipe';

@NgModule({
    declarations: [
        FormatProbablePipe
    ],
    imports: [],
    providers: [],
    exports: [
        FormatProbablePipe
    ]
})
export class ProbablesPipesSharedModule {}
