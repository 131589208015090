import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { combineLatest, of, ReplaySubject, Subject, Subscription, Observable } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import {
    BasicBetType,
    enumBetModifier,
    enumFeatureToggle,
    enumRaceStatus,
    FeatureToggleDataService,
    IRaceIdentifier,
    ISelectedBetAmount,
    ITrack,
    ITrackBasic,
    MultiRaceExoticBetType,
    TrackService,
    WagerState
} from '@cdux/ng-common';

import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { ViewSectionEnum, ViewSectionProgramGroup, ViewSectionPoolsGroup, ViewSectionResultsGroup, ViewSectionStatsGroup } from 'app/wagering/views/enums/view-section.enum';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { takeWhileInclusive } from 'app/shared/common/operators/';
import { ResultsService } from 'app/shared/results/services/results.business.service';
import { DisplayModeEnum } from 'app/shared/common/enums';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';

const AUTO_ADV_RACE_FT = 'AUTO_ADV_RACE';

@Component({
    selector: 'cdux-wagering-section-container',
    templateUrl: './section-container.component.html',
    styleUrls: ['section-container.component.scss']
})
export class WageringSectionContainerComponent implements OnDestroy {

    public WageringViewEnum = WageringViewEnum;
    public DisplayModeEnum = DisplayModeEnum;
    public ViewSectionEnum = ViewSectionEnum;

    @Input()
    public view: WageringViewEnum;

    @Output()
    public viewChange = new EventEmitter<WageringViewEnum>();

    @Input()
    public section: ViewSectionEnum;

    @Output()
    public sectionChange = new EventEmitter<ViewSectionEnum>();

    @Input()
    public displayMode: DisplayModeEnum;

    private _resultsAvailabilitySub: Subscription;
    public isRaceClosed = false;

    private _raceStatus: enumRaceStatus;
    @Input()
    public set raceStatus(status: enumRaceStatus) {
        if (status) {
            this._raceStatus = status;
            this._raceStatusSubject.next(this._raceStatus);
        }
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }

    private _selectedRaceNav: ITrackBasic;
    @Input()
    public set selectedRaceNav(raceNav: ITrackBasic) {
        if (raceNav) {
            this._selectedRaceNav = raceNav;
            this.track = raceNav;
            this.race = raceNav.RaceNum;
            this._changeDetector.detectChanges();
        }
    }
    public get selectedRaceNav(): ITrackBasic {
        return this._selectedRaceNav;
    }

    private _wagerState: WagerState;
    @Input()
    public set wagerState(wagerState: WagerState) {
        if (wagerState && wagerState.basicTrack) {
            const sameTrack = TrackService.isExactTrackObject(wagerState.basicTrack, this._wagerState ? this._wagerState.basicTrack : null);
            this._wagerState = wagerState;

            if (!sameTrack) {
                this._basicTrackChanged = true;
                this._wagerStateSubject.next(this.wagerState);
            }

            this.track = wagerState.basicTrack;
            this.race = wagerState.basicTrack && wagerState.basicTrack.RaceNum;
            this._changeDetector.detectChanges();
        }
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }

    public race: number;
    public track: ITrackBasic;

    private _basicTrackChanged: boolean = false;

    private _raceStatusSubject: ReplaySubject<enumRaceStatus> = new ReplaySubject<enumRaceStatus>(1);
    private _wagerStateSubject: ReplaySubject<WagerState> = new ReplaySubject<WagerState>(1);

    private _destroy: Subject<any> = new Subject();
    // This is a big delay but sometimes when navigating to a race with a different race status it takes awhile to get
    // the new status. This usually occurs when on the current race and navigating to a previous official race.
    private _resultsLoadingDelay = 1000;

    @Output()
    public onSelectionChange: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    @Output()
    public onResetEntrySelections: EventEmitter<undefined> = new EventEmitter<undefined>();

    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();

    public viewSectionProgramGroup = ViewSectionProgramGroup;
    public viewSectionPoolsGroup = ViewSectionPoolsGroup;
    public viewSectionResultsGroup = ViewSectionResultsGroup;
    public viewSectionStatsGroup = ViewSectionStatsGroup;

    public autoAdvanceRaceFT: boolean = false;
    public showPPs: boolean = false;
    public isStatsEnabled: boolean = false;

    constructor (
        private _changeDetector: ChangeDetectorRef,
        private _featureToggleService: FeatureToggleDataService,
        private _resultsService: ResultsService
    ) {
        this._initializeResults();

        this.autoAdvanceRaceFT = this._featureToggleService.isFeatureToggleOn(AUTO_ADV_RACE_FT);
        this.showPPs = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SHOW_PP_RACENAV);
        this.isStatsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SHOW_STATS);
    }

    ngOnDestroy() {
        if (this._resultsAvailabilitySub) {
            this._resultsAvailabilitySub.unsubscribe();
        }

        this._destroy.next();
        this._destroy.complete();
    }

    /**
     * Check for results availability when the race is no longer wagerable
     * Requies the listed component inputs to be available
     */
    private _initializeResults() {
        combineLatest([
            this._raceStatusSubject.asObservable(),
            this._wagerStateSubject.asObservable()
        ]).pipe(
            debounceTime(this._resultsLoadingDelay),
            switchMap(([raceStatus, wagerState]) => {

                let returnObs: Observable<boolean>;
                const trackChanged = this._basicTrackChanged;
                this._basicTrackChanged = false;

                if (!TrackService.isWagerableRace(raceStatus) && raceStatus !== enumRaceStatus.OFF) {
                    // Checked to see if we should start polling for results
                    if (trackChanged || (!trackChanged && this.wagerState.basicTrack.RaceNum >= (this.wagerState.basicTrack as ITrack).RaceTotal)) {
                        returnObs = this._resultsService.resultsAvailable(
                            <IRaceIdentifier>{
                                brisCode: this.wagerState.basicTrack.BrisCode,
                                trackType: this.wagerState.basicTrack.TrackType,
                                raceNumber: this.wagerState.basicTrack.RaceNum,
                                raceDate: ''
                            }, true
                        ).pipe(
                            // All we care about with this is just whether or not the results service is responding so we can show
                            // the tab in the nav. Once we have received a response for a track/race, we can kill this sub until
                            // a new one is generated.
                            takeWhileInclusive(available => !available, true)
                        )
                    } else {
                        if (this.autoAdvanceRaceFT && this._canAdvanceOnSelectedGroup() &&  !trackChanged && this.wagerState.basicTrack.RaceNum < (this.wagerState.basicTrack as ITrack).RaceTotal) {
                            this.updateRaceNav.emit({
                                ...this.wagerState.basicTrack,
                                RaceNum: this.wagerState.basicTrack.RaceNum + 1
                            });
                            this._changeDetector.detectChanges();
                        }

                        returnObs = of(false);
                    }
                } else {
                    returnObs = of(false);
                }

                return returnObs;
            }),
            takeUntil(this._destroy)
        ).subscribe(
            (available) => {
                // check the response to see if results are available
                this.isRaceClosed = available;
                this._changeDetector.detectChanges();
            }
        );
    }

    private _canAdvanceOnSelectedGroup() {
        // disable advancing if user is on result group
        return ViewStateService.getPrimaryGroupForSection(this.section) !== ViewStateGroupEnum.RESULTS;
    }

    /**
     * Handles triggering of display for the sub navs.
     * @param section
     * @param fromInput
     */
    public selectSection(section: ViewSectionEnum, fromInput: boolean = false) {
        this.section = section;
        this.sectionChange.emit(this.section);
    }

    public isSection(section: ViewSectionEnum | ViewSectionEnum[]): boolean {
        return this.section === section || Array.isArray(section)
            && section.indexOf(this.section) > -1;
    }

    public isWagerableRace(): boolean {
        return TrackService.isWagerableRace(this.raceStatus);
    }
}
