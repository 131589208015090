<header class="reg-login-header">
    <div (cduxTap)="closeClicked()" class="close-btn" *ngIf="!initiatedFromBetShare">
        <div id="fullpage-reg-close-icon" class="icon--login-close"></div>
        <div id="fullpage-reg-close-txt" class="close-txt">Close</div>
    </div>
    <div class="secured-message">
        <div id="fullpage-reg-secured-message-icon" class="icon--badge-encryption"></div>
        <span id="fullpage-reg-secured-message-text">Secured by 2048-bit encryption</span>
    </div>
</header>
<div id="fullpage-reg-offer-claimed-banner" *ngIf="showOfferBanner()" class="offer-claimed-notification">
    You have claimed the {{ validOffer.code }} offer. Continue to sign up to receive your bonus
</div>
<section [ngClass]="{'is-hidden': showIDScan}" class="reg-login-container">

    <div class="content-peripheral has-image" [ngClass]="{'is-hidden': hasErrors || registrationSuccessful }">
        <div class="offer-image">
            <img *ngIf="offerImgObs | async as imgSrc" id="fullpage-reg-offer-image" src="{{ imgSrc | sharedResource }}" (error)="fetchDefaultOfferImage();">
        </div>

        <div class="content-peripheral_footer">
            <div id="fullpage-reg-existing-account-question-text" class="content-peripheral_footer_text">
                Already have an account?
            </div>
            <div>
                <!-- when the login button is clicked, switch the text to the button loader component while user is waiting -->
                <button id="fullpage-reg-login-button-clicked" *ngIf="loginClicked; else loginButton" type="submit" class="sign-in--btn-peripheral">
                    <div class="button-loader primary">
                        <div class="loader-dots">
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                        </div>
                    </div>
                </button>
                <ng-template #loginButton>
                    <button id="fullpage-reg-login-button" (cduxTap)="redirectToLogin()" type="submit" class="sign-in--btn-peripheral">Login</button>
                </ng-template>
            </div>
        </div>
    </div>


    <div class="content-main" [ngClass]="{'is-hidden':hasErrors || registrationSuccessful}">
        <div class="icon--logo-sm-color"></div>
        <div (cduxTap)="closeClicked()" id="fullpage-reg-small-glass-close-icon" *ngIf="isSmallGlass" class="icon--login-close"></div><!-- only displays in phone sizes -->

        <div class="gan-login-item" *ngIf="ftGANRegistration && ganRegistrationURL && currentStep === 0" (click)="openGANWidget()">
          Already have A TwinSpires Sports&nbsp;&amp;&nbsp;Casino&nbsp;Account?
          <span id="reg-widget-button-span">Click Here</span>
        </div>
        <div class="reg-progress">
            <div class="step">
                <div class="step-num" [ngClass]="contactStepClass">
                    1
                </div>
                <div class="step-label" [ngClass]="contactStepClass">
                    contact
                </div>
            </div>
            <div class="connector"></div>
            <div class="step">
                <div class="step-num" [ngClass]="addressStepClass">
                    2
                </div>
                <div class="step-label" [ngClass]="addressStepClass">
                    address
                </div>
            </div>
            <div class="connector"></div>
            <div class="step">
                <div class="step-num" [ngClass]="accountStepClass">
                    3
                </div>
                <div class="step-label" [ngClass]="accountStepClass">
                    account
                </div>
            </div>
        </div>

        <div [innerHTML]="getHelpText()" class="reg-message"></div>

        <div class="reg-form_scroller" #scrollingArea>
            <cdux-form-builder
                #formBuilder
                [affiliateId]="affiliateId"
                [config]="config"
                [enableOffers]="isOffersEnabled"
                [forceFullSSN]="isBetShare"
                [offer]="currentOffer"
                [preRegistration]="preRegistration"
                [showBottomButtons]="showBottomButtons"
                [stateErrorMessages]="stateErrorMessages"
                [step]="currentStep"
                (firstNameEntered)="saveFirstName($event)"
                (offerCodeChanged)="offerCodeChanged()"
                (offerValidated)="offerValidated($event)"
                (registrationComplete)="onRegistrationComplete($event)"
                (registrationSubmitted)="onRegistrationSubmitted()"
                (stepChange)="onStepChange($event)"
                (userEvent)="onUserEvent($event)"
                (showTerms)="resetScroll()">
            </cdux-form-builder>
            <p class="small-text dk-reg-disclaimer" [ngClass]="{'centered-disclaimer-box' : isSmallGlass}" *ngIf="!!affiliateDisclaimer && (currentStep === registrationSteps.CONTACT || currentStep === registrationSteps.ACCOUNT)">{{affiliateDisclaimer}}</p>
            <p *ngIf="showAccessibilityMessage && currentStep === registrationSteps.CONTACT"
               class="small-text"
               [ngStyle]="{'margin' : isSmallGlass ? '20px' : '10px auto'}"
               id="fullpage-reg-accessibility-text">
                For players with visual accessibility
                needs, please <span *ngIf="phoneEnabled else showChat">contact Player Services at
                <a href="{{affiliatePhone}}">{{ uiAffiliatePhone }}</a></span>
                to setup and manage your account including registration,
                deposits, withdrawals and placing wagers.</p>
        </div>
        <ng-template #showChat><cdux-chat></cdux-chat></ng-template>

        <div id="fullpage-reg-ssl-encryption-message" class="security-message">
            We safeguard your personal information with SSL encryption
            so that it cannot be read in transit by a third party.
        </div>
        <div class="login-link-footer">
            Already have an account? <a (cduxTap)="redirectToLogin()">Log In</a>
        </div>
        <div class="reg-footer-logo" *ngIf="showRegLoginFooterTag">
            <div class="icon-logo-tag-sm-color"></div>
        </div>
    </div>

    <ng-container *ngIf="hasErrors">
        <div class="content-error">
            <div class="icon--logo-sm-color"></div>
            <div (cduxTap)="closeClicked()" id="fullpage-reg-small-glass-error-screen-close-icon" *ngIf="isSmallGlass" class="icon--login-close"></div><!-- only displays in phone sizes -->
            <div class="content-error_title">Registration Problem</div>

            <!-- First soft fail -->
            <p *ngIf="showRetryButton; else noRetryButton">{{ 'registration-error-personal-info' | cduxTranslate:''+affiliateId}}</p>

            <!-- Other fail type -->
            <ng-template #noRetryButton>
                <!-- All fail types -->
                <p><span [innerHTML]="translateStringKeyMap[registrationStatus] + '-header' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span></p>
                <p>
                  <ng-container *ngIf="(phoneEnabled || (registrationStatus !== RegistrationStatesEnum.HARD_FAIL && registrationStatus !== RegistrationStatesEnum.HARD_FAIL_ID_SCAN)) else chatHardFailErrorMessage">
                    <span [innerHTML]="translateStringKeyMap[registrationStatus] + '-body' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span>
                    <!-- Hard fail -->
                    <span *ngIf="registrationStatus === RegistrationStatesEnum.HARD_FAIL || registrationStatus === RegistrationStatesEnum.HARD_FAIL_ID_SCAN" class="link-phone">
                        <a href="tel:{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</a>.
                    </span>
                  </ng-container>
                  <ng-template #chatHardFailErrorMessage>
                    Please <cdux-chat></cdux-chat>.
                  </ng-template>
                    <!-- Account exists fail -->
                    <span *ngIf="registrationStatus === RegistrationStatesEnum.ACCOUNT_EXISTS"><a (click)="triggerPasswordReset()">here</a>.</span>
                </p>
                <!-- Second soft fail -->
                <ng-container *ngIf="softFailCount">
                    <p><span [innerHTML]="'registration-required-docs' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span></p>
                    <p>
                      <ng-container *ngIf="phoneEnabled else showSoftFailChatMessage">
                        <span>{{ 'registration-fail-soft-body-call' | cduxTranslate:''+affiliateId}} </span>
                        <span class="link-phone">
                            <a href="tel:{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</a>
                        </span>.
                      </ng-container>
                      <ng-template #showSoftFailChatMessage>
                        Or <cdux-chat></cdux-chat> to verify your identity and complete registration.
                      </ng-template>
                    </p>
                </ng-container>
            </ng-template>

            <div *ngIf="showRetryButton" class="content-error_footer">
                <div class="loginBtnWrapper">
                    <button type="submit" class="reg-next--btn" (cduxTap)="resetErrors()">try again</button>
                </div>
            </div>

            <div *ngIf="!showRetryButton && softFailCount" class="content-error_footer">
                <div class="loginBtnWrapper">
                    <button type="submit" class="reg-next--btn" (cduxTap)="navigateToDocumentUpload()">upload documents</button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="registrationSuccessful">
        <div class="content-success">
            <div class="icon--logo-sm-color"></div>
            <div (cduxTap)="closeClicked()" id="fullpage-reg-small-glass-success-screen-close-icon" *ngIf="isSmallGlass" class="icon--login-close"></div>

            <div class="icon--check-circle-success"></div>

            <div id="fullpage-reg-success-welcome-text" class="content-success_title">Welcome, {{ firstName }}!</div>

            <p>
                <ng-container *ngIf="offerName">You have successfully claimed {{ offerName }}.<br></ng-container>
                Now fund your account to bet the races!
            </p>
        </div>
    </ng-container>

</section>

<ng-container *ngIf="showIDScan">
    <cdux-id-scan
        [uuid]="regResponse?.uuid"
        [closeOnCancel]="true"
        [showCloseButton]="isSmallGlass"
        (idScanComplete)="onIdScanComplete($event)"
        (closeIdScan)="closeClicked()"></cdux-id-scan>
</ng-container>
