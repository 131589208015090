import { Component, Output, HostListener, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { take } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';

import { SsnCollectionService } from './services/ssn-collection.service';
import { SsnCollectionComponentBase } from './ssn-collection-base.component';
import { EventClickType, enumKeyboardEventCodes, enumSsnReason, JwtSessionService } from '@cdux/ng-common';

export enum EnumSsnCollectionEvents {
    SUBMITTING,
    SUCCESS,
    SOFT_FAILURE,
    HARD_FAILURE
}

@Component({
    selector: 'cdux-ssn-collection-bet-share',
    templateUrl: './ssn-collection-bet-share.component.html',
    styleUrls: ['./ssn-collection-bet-share.component.scss']
})
export class SsnCollectionBetShareComponent extends SsnCollectionComponentBase {
    @Output()
    public closeSsn: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public events: EventEmitter<EnumSsnCollectionEvents> = new EventEmitter<EnumSsnCollectionEvents>();

    public eventClickType = EventClickType;
    public submitting: boolean = false;

    constructor(
        _environment: ENVIRONMENT,
        protected sessionService: JwtSessionService,
        protected ssnCollectionService: SsnCollectionService,
        private changeDetector: ChangeDetectorRef
    ) {
        super(
            _environment,
            sessionService,
            ssnCollectionService
        );
    }

    public submitSsn () {
        this.submitting = true;
        this.changeDetector.detectChanges();

        // betshare specific ssn collection must pass in enumSsnReason.BETSHARE
        this.submit(enumSsnReason.BETSHARE)
            .pipe(take(1))
            .subscribe((success) => {
                    this.submitting = success; // If we succeeded, then I want to continue hiding the close button.
                    this.changeDetector.detectChanges();
                    if (success) {
                        this.events.emit(EnumSsnCollectionEvents.SUCCESS);
                    } else if (this.canRetry) {
                        this.events.emit(EnumSsnCollectionEvents.SOFT_FAILURE);
                    } else {
                        this.events.emit(EnumSsnCollectionEvents.HARD_FAILURE);
                    }
                },
                () => {
                    this.submitting = false;
                    this.changeDetector.detectChanges();
                    if (this.canRetry) {
                        this.events.emit(EnumSsnCollectionEvents.SOFT_FAILURE);
                    } else {
                        this.events.emit(EnumSsnCollectionEvents.HARD_FAILURE);
                    }
                });
    }

    public close () {
        this.closeSsn.next();
    }

    /**
     * listen for Escape key to close SSN collection
     *
     * @param {KeyboardEvent} event
     */
    @HostListener('document:keydown', ['$event'])
    handleEscKey (event: KeyboardEvent) {
        if (event.code === enumKeyboardEventCodes.ESCAPE || event.keyCode === 27) {
            this.close();
        }
    }
}
