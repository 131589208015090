<div class="cdi-tour-container carousel">

  <i class="icon--tour-close" (click)="closeTourModal()"></i>

    <!--
      The data service for this component doesn't yet exist so I've
      plopped in a DATA URI array in the business service just to demonstrate
      what multiple images will look like in the carousel. I.e., placeholder stuff.
      -->

    <cdux-carousel
        (onBack)="onBack($event)"
        (onNext)="onNext($event)"
        [showPagination]="true"
        [showTourArrows]="true">
      <ng-template ngFor let-tourImage let-i="index" [ngForOf]="context.data">
        <cdux-carousel-item>
          <img [src]="tourImage | sharedResource">
        </cdux-carousel-item>
      </ng-template>
    </cdux-carousel>

</div>
