import { ENVIRONMENT, ENVIRONMENTS, AFFIDS } from '@cdux/ng-core';

const affId = AFFIDS.TWINSPIRES;
const username = 'my_tux';

export const environment: ENVIRONMENT = {
    environment: ENVIRONMENTS.PRODUCTION,
    production: true,
    affiliateId: affId,
    domain: 'twinspires.com',
    username: username,
    setSecureCookie: true,
    thirdPartyConfig: {
        'Box': {
            'Folder': '7667404974',
            'Token': 'zmdxugp0tzsi6ax51n29u4341f730wej',
            'Token_Simplified_Upload': '4723136851c84f2a84cc010323bcfc08',
        },
        'RightNow': {
            'Subdomain': 'twinspires',
            'Category': 10
        },
        'ZendeskChatURL': 'https://static.zdassets.com/ekr/snippet.js?key=7609eac5-f4d6-4ccb-8467-e516d9689bb0'
    },
    appConfig: {
        servicesBaseUrl: '',
        username: username,
        environmentUsername: username,
        platformId: 'TUX',
        affiliateId: affId,
        clientIp: '0.0.0.0',
        outputType: 'json'
    }
};
