/**
 * Tests the event passed to ensure compliance with a numeric input.
 * @param event - KeyboardEvent to be tested.
 */
export function RestrictNumeric(event: KeyboardEvent, value: any = ''): boolean {
  const regex = new RegExp(`^[0-9]$`);

  if (event.metaKey || event.ctrlKey || event.key === 'Backspace' || event.key === 'Delete') {
    return true;
  }
  if (event.which === 32 || event.key === 'Space' ) {
    return false;
  }
  if (event.which === 0 && (event.key === 'Dead' || event.key === 'Unidentified')) {
    return false;
  }
  const val = value + (event.key ? event.key : String.fromCharCode(event.which));
  return !!regex.test(val);
}

/**
 * Tests the event passed to ensure compliance with a floating point input.
 * @param event - KeyboardEvent to be tested.
 */
export function RestrictFloat(event: KeyboardEvent, value: any = '', specificity: number = 2): boolean {
  const regex = new RegExp(`^[$]?([0-9]+([.]([0-9]{0,${specificity}}))?|[.][0-9]+)$`);

  if (event.metaKey || event.ctrlKey) {
    return true;
  }
  if (event.which === 32 || event.key === 'Space' || event.key === 'Backspace' || event.key === 'Delete') {
    return false;
  }
  if (event.which === 0 && (event.key === 'Dead' || event.key === 'Unidentified')) {
    return false;
  }
  const val = value + (event.key ? event.key : String.fromCharCode(event.which));
  if (value) {
    return !!regex.test(val);
  }
  return !!/[\d\s\.]/.test(val);
}
