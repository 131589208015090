import { NotificationConfig } from '@cdux/ng-platform/web';

export enum FavEventType {
    FAVORITE_RUNNER_ADD = 'FAVORITE_RUNNER_ADD',
    FAVORITE_RUNNER_REMOVE = 'FAVORITE_RUNNER_REMOVE',
    FAVORITE_TRACK_ADD = 'FAVORITE_TRACK_ADD',
    FAVORITE_TRACK_REMOVE = 'FAVORITE_TRACK_REMOVE',
    FAVORITE_RUNNING_TODAY = 'FAVORITE_RUNNING_TODAY',
    ECOMMERCE_TRANSACTION_SUCCESS = 'ECOMMERCE_TRANSACTION_SUCCESS',
    TOURNAMENT_OPT_IN_SUCCESS = 'TOURNAMENT_OPT_IN_SUCCESS'
}

export enum FavEventMessage {
    FAVORITE_RUNNING_TODAY = 'You have starters in your <span class="bold-text">stable</span> today.',
    TOURNAMENT_OPT_IN_SUCCESS = 'OPTED IN TO <span class="bold-text">TOURNAMENT</span>'
}

export interface FavEvent {
    type: FavEventType;
    payload: NotificationConfig;
}
