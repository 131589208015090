import { Bet,
         EventClickType,
         DetectionService,
         FeatureToggleDataService,
         enumFeatureToggle
       } from '@cdux/ng-common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BetShareBusinessService } from '../../services/betshare.business.service';
import { SocialMediaTypes } from '../../../social/enums/social-media-types.enum';


@Component({
    selector: 'cdux-bet-share-success',
    templateUrl: './bet-share-success.component.html',
    styleUrls: ['./bet-share-success.component.scss']
})
export class BetShareSuccessComponent implements OnInit {

    @Input() bet: Bet;
    @Output() closeShareBet = new EventEmitter<undefined>();

    public showSocialMedia: boolean = false;
    public socialMediaType = SocialMediaTypes;
    public onAndroidTablet: boolean;
    public betShareUrl: string = null;
    public triggerCopy: boolean = false;
    // Expose EventClickType so we can use it in the template for click events
    public eventClickType = EventClickType;
    public socialMediaToggle: boolean;


    constructor(
        private _betShareService: BetShareBusinessService,
        private _detectionService: DetectionService,
        private _featureToggleDataService: FeatureToggleDataService
    ) {}

    ngOnInit() {
        this.onAndroidTablet = this._detectionService.isTablet() && this._detectionService.isAndroidDevice();
        this.betShareUrl = this._betShareService.generateLink(this.bet);
        this.socialMediaToggle = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.MY_BETS_SOCIAL_MEDIA);
    }

    public shareBet(type: SocialMediaTypes): void {
        this._betShareService.shareBet(this.bet, type);
        this.close();
    }

    public copyBet(): void {
        this.triggerCopy = true;
        setTimeout(() => {
            this.triggerCopy = false;
            this.close();
        }, 3000);
    }

    public close() {
        this.closeShareBet.emit();
    }

}
