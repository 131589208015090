<div class="tile">
  <div class="runner-row">
    <div id="fav-tile-track-{{ idTrackCode }}-{{ favoriteData?.trackType.toLowerCase() }}" class="runner-name">{{ favoriteData?.formattedTrackName }}</div>
  </div>
  <div class="race-row" *ngIf="isRunningToday">
    <div class="race-row_race-col">
      <span class="title">Race</span>
      R<span id="fav-tile-track-{{ idTrackCode }}-race-number-{{favoriteData.currentRaceNumber}}">{{ favoriteData.currentRaceNumber }}</span>
    </div>
    <div class="race-row_time">
      <span class="title">Time</span>
      <div class="mtp-badge minutes-to-post">
        <cdux-mtp-badge
          id="fav-tile-track-{{ idTrackCode }}-mtp-badge"
          [source]="favoriteData?.races[favoriteData.currentRaceIndex]" [showMinutes]="true"></cdux-mtp-badge>
      </div>
    </div>
  </div>
  <div class="action-row">
    <div class="action-row_col"><span id="fav-tile-track-{{ idTrackCode }}-remove" class="action-item">Remove</span></div>
    <div class="action-row_col" *ngIf="isRunningToday"><span id="fav-tile-track-{{ idTrackCode }}-bet"   class="action-item">Bet</span></div>
  </div>
</div>

<!--<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>TODO uncomment when needed to implement remove notification-->

<!--<ng-template #removeModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <h2>
      <span id="favorite-add-modal-description" class="runner">
        Are you sure you want to remove<br>{{ favoriteData.runnerName }}?
      </span>
    </h2>
    <div class="message">You will no longer receive alerts and all notes will be lost.</div>
    <div class="button-row">
      <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
      <div class="btn-remove" (click)="confirmRemove()">Remove</div>
    </div>

  </div>
</ng-template> TODO: uncomment when need to implement track removal-->
