
<div class="program-summary-tooltip">
    <span>
        What is Summary?
    </span>
    <div class="icon--info" (click)="openSummaryGlossary()"></div>
    <ng-template #programSummaryGlossaryModal>
        <ng-template [cdkPortalOutlet]="programSummaryGlossaryPortal"></ng-template>
    </ng-template>
</div>

<ng-template cdkPortal #programSummaryGlossaryPortal="cdkPortal">
    <div class="program-summary-glossary-container">
        <div class="header-summary-glossary">
            <h2 class="program-summary-glossary-header">Race Summary
                <div class="icon--close" (click)="closeSummaryGlossary()"></div>
            </h2>
        </div>
            <div class="program-summary-glossary-info">
                <ul>
                    <h2>PROFIT LINE/ PL</h2>
                        <p>The estimated fair value winning odds of runner based on computer modeling with the lowest Profit Line odds considered the most likely winner.</p>
                </ul>
                <ul>
                    <h2>MORNING LINE ODDS/ ML</h2>
                    <p>The morning line odds for the horse established by the racetrack.</p>
                </ul>
                <ul>
                    <h2>RUN STYLE PTS/ RUN STYL</h2>
                        <p>The run style of the horse. E = Early, E/P = Early/Presser, P = Presser, S = Sustained or closer.
                        <br>
                        <br>
                        PTS or Early Speed Points. The numbers range from 0-8 and measure the early speed ability of the horse
                        based on its running position and beaten lengths at the first call of recent races. The higher the number, the more early speed a horse has shown in recent races.
                        <br>
                        <br>
                        NOTE: A + (plus sign) indicates that this is a favorable running style for this race. A ++ (double plus sign)
                        indicates that this is the best or most dominant running style for this race.</p>
                </ul>
                <ul>
                    <h2>PRIME POWER/ PRM PWR</h2>
                        <p>A number that measures the quality of each horse's most recent starts by combining dozens of 
                        handicapping factors into one easy-to-use rating. Speed, class, pace, form, weight, and many more factors are combined 
                        by a sophisticated algorithm to form this rating. For more information, see "Prime Power - Using Our Best Rating" elsewhere in the Training to Win section.</p>
                </ul>
                <ul>
                    <h2>DAYS SINCE LAST RACE/ DAYS OFF</h2>
                    <p>One dot after this stat indicates that his last race was the horse's first start off a layoff. Two dots indicate that his last race was the horse's second start off a layoff.</p>
                </ul>
                <ul>
                    <h2>BACK SPEED/ BACK SPD</h2>
                    <p>The best Speed figure earned by each horse within the last year at today's distance/surface category.</p>
                </ul>
                <ul>
                    <h2>SPEED LAST RACE/ SPD LR</h2>
                    <p>The Speed figure earned by each horse in the last start, regardless of distance or surface.</p>
                </ul>
                <ul>
                    <h2>AVERAGE CLASS/ AVG CLS</h2>
                    <p>An average of the Bris Class Rating for the last three starts regardless of distance and surface.</p>
                </ul>
                <ul>
                    <h2>JOCKEY WIN PERCENTAGE/ W% JKY</h2>
                        <p>The percentage of wins per starts that a jockey has had in the last year.</p>
                </ul>
                <ul>
                    <h2>TRAINER WIN PERCENTAGE/ W% TRN</h2>
                        <p>The percentage of wins per starts that a trainer has had in the last year.</p>
                </ul>
                <ul>
                    <h2>TOTAL EARNINGS/ $ WON</h2>
                        <p>Lifetime earnings</p>
                </ul>
        </div>
    </div>
</ng-template>