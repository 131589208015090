import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { map, debounceTime, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumFeatureToggle,
    EventClickType,
    FeatureToggleDataService,
    UserEventResponseOnRegistrationSubmit,
    IBonus,
    IPreRegistrationForm,
    OffersDataService,
    RegistrationResponse,
    RegistrationStatesEnum,
    UserEventEnum,
} from '@cdux/ng-common';
import { FormBuilderComponent } from '@cdux/ng-platform/web';
import { BrazeUtils } from '@cdux/ts-domain-braze';

import { IncomeAccessService } from 'app/analytics/income-access.service';
import { EventTrackingService } from '../event-tracking/services/event-tracking.service';
import { CduxSidebarContentComponent } from '../sidebar/cdux-sidebar-content-component.class';
import { ISidebarComponentProperties } from '../sidebar/interfaces/sidebar-portal-component.interface';
import { RegistrationEvent } from './registration-event.enum';
import { RegistrationExternalRedirect } from './registration-external-redirect.enum';
import { RegistrationUserEventStatusEnum } from './registration-user-event-status.enum';
import { RegistrationNotificationService } from './registration-notification.service';
import * as momentTime from 'moment-timezone';

/* Registration Flow Configuration */
const CONFIG_FLOW_CONFIG_KEY: string = 'registration_flow'
const DEFAULT_FLOW: number = 0;

@Directive()
export abstract class AbstractRegistrationComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    @ViewChild('formBuilder') formBuilder: FormBuilderComponent;
    @ViewChild('scrollingArea') scrollingArea: ElementRef;

    @Input() public isBetShare: boolean = false;
    @Input() public offer: IBonus;
    @Input() public externalRedirect: RegistrationExternalRedirect;
    @Input() public preRegistration: IPreRegistrationForm = null;

    public hasErrors = false;
    public registrationStatus = 0;
    public regResponse: RegistrationResponse;
    public eventClickType = EventClickType;
    public affiliateId = this._environment.affiliateId;
    public initiatedFromBetShare = false;
    public translateStringKeyMap = {
        [RegistrationStatesEnum.SUCCESS]: '',
        [RegistrationStatesEnum.SOFT_FAIL]: 'registration-fail-soft',
        [RegistrationStatesEnum.SOFT_FAIL_ID_SCAN]: 'registration-fail-soft',
        [RegistrationStatesEnum.HARD_FAIL]: 'registration-fail-hard',
        [RegistrationStatesEnum.HARD_FAIL_ID_SCAN]: 'registration-fail-hard',
        [RegistrationStatesEnum.ACCOUNT_EXISTS]: 'registration-account-exists',
        [RegistrationStatesEnum.TECHNICAL_FAIL]: 'registration-fail-technical'
    };

    public validOffer: IBonus;
    public currentOffer: IBonus;

    protected readonly _OFFER_IMAGE_DEFAULT = 'resources/shared/images/Offer_DefaultImage_Registration.jpg';

    public offerImgObs: Observable<string>;
    protected _offerImgSubject: BehaviorSubject<string> = new BehaviorSubject(this._OFFER_IMAGE_DEFAULT);

    /* Registration Config */
    // override _configData file in extending component only if necessary
    protected _configData = require('./fullpage-registration.config.json');
    public configStack: enumConfigurationStacks = enumConfigurationStacks.TUX
    public config: any;

    public isOffersEnabled = false;
    public validateOffers = false;

    public RegistrationStatesEnum = RegistrationStatesEnum;

    protected _destroy: Subject<boolean> = new Subject();

    constructor (
        private _configService: ConfigurationDataService,
        protected _featureToggleService: FeatureToggleDataService,
        private _incomeAccessService: IncomeAccessService,
        @Inject(BrazeUtils) private _brazeUtils: BrazeUtils,
        protected _environment: ENVIRONMENT,
        protected _eventTrackingService: EventTrackingService,
        protected _regNotificationService: RegistrationNotificationService,
        protected _offersDataService: OffersDataService,
        protected _route: ActivatedRoute
    ) {
        super();
        this.isOffersEnabled = this._featureToggleService.isFeatureToggleOn('REGOFFERCODE');
        this.validateOffers = this._featureToggleService.isFeatureToggleOn('CHECK_OFFER_CODE');
    }

    ngOnInit() {
        // check configService for `registration_flow` value
        this._configService
            // override configStack in extending component to get a Stack- or Platform-specific value
            .getConfiguration(this.configStack, CONFIG_FLOW_CONFIG_KEY)
            .pipe(
                map(config =>
                    // map to the retrieved flow value or fallback to the default
                    (!!config && config[CONFIG_FLOW_CONFIG_KEY]) ? +config[CONFIG_FLOW_CONFIG_KEY] : DEFAULT_FLOW
                )
            ).subscribe(
                configFlow => {
                    // get the correct config or fallback to the default if the specified config doesn't exist
                    this.config = this._configData[this._environment.affiliateId].flows[configFlow] || this._configData[this._environment.affiliateId].flows[DEFAULT_FLOW];
                },
                _ => {
                    // if there's an error, make sure we have setup the default config
                    this.config = this._configData[this._environment.affiliateId].flows[DEFAULT_FLOW];
                }
            );

        if (this.isOffersEnabled) {

            // There are three possible ways an offercode may be passed into the registration process:
            // 1. As a route param: /join-now/[offercode]
            // 2. As a query param: /join-now?code=[offercode]
            // 3. As a property of the inputs param: /join-now?inputs={"code":"[offercode]"}
            // To attempt to keep the logic as simple as possible, we a merging all of these possible objects
            // so that we can perform one check for "code" below.

            let mergedInputParams = Object.assign({}, this._route.snapshot.queryParams, this._route.snapshot.params);
            if (mergedInputParams && mergedInputParams.inputs) {
                mergedInputParams = Object.assign(mergedInputParams, JSON.parse(mergedInputParams.inputs));
            }

            mergedInputParams.code = mergedInputParams.code || mergedInputParams.offerCode || mergedInputParams.promoCode;

            if (mergedInputParams.code) {
                if (this.validateOffers) {
                    this._offersDataService.getOfferDetailsCodeUUID(mergedInputParams.code).pipe(
                        take(1),
                        takeUntil(this._destroy)
                    ).subscribe(res => {
                        if (res.bonusDetails?.code === mergedInputParams.code) {
                            this.offerValidated(res.bonusDetails);
                        } else {
                            this.setUnvalidatedOfferCode(mergedInputParams.code);
                        }
                    });
                } else {
                    this.setUnvalidatedOfferCode(mergedInputParams.code);
                }
            }
        }

        /*
         * We can't use the default offer image until we're certain that an
         * offer-specific image doesn't exist. Otherwise, the default image is
         * likely to flash briefly before the offer-specific one. We need to
         * allow time for the offer code to be read, parsed and validated.
         *
         * Though the time value (in milliseconds) given below to debounceTime
         * is just speculation, it works on the much slower dev environment with
         * no cache.
         *
         * With the debounce in place, and the offer evaluation (above) already
         * in progress, the async evaluation of offerImgObs in the template
         * should complete sometime after the image has been determined.
         */
        this.offerImgObs = this._offerImgSubject.pipe(debounceTime(100));
    }

    ngOnDestroy() {
        this._offerImgSubject.complete();
        this._destroy.next();
        this._destroy.complete();
        this._regNotificationService.onRegistrationEvent.next({regEvent: RegistrationEvent.CLOSED});
    }

    public abstract onRegistrationComplete(regResponse: RegistrationResponse);
    public abstract triggerPasswordReset(username: string);
    public abstract navigateToDocumentUpload();
    public abstract onStepChange($event);
    public abstract onRegistrationSubmitted(response?: UserEventResponseOnRegistrationSubmit);

    public setProperties(properties: ISidebarComponentProperties) {}

    // NOTE: Potential abstract
    // Should an extending component require changes to this method, it should be converted
    // to an abstract method and the implementation moved into the extending component(s)
    public onUserEvent(event: EventClickType) {
        this._eventTrackingService.logClickEvent(event);
    }

    public resetScroll() {
        this.scrollingArea.nativeElement.scrollTop = 0;
    }

    private setUnvalidatedOfferCode(code: string) {
        this.currentOffer = {
            agreement: null,
            code: code,
            description: null,
            displayImageUrl: null,
            displayMobileImageUrl: null,
            displayNew: null,
            endTimeMillis: null,
            id: null,
            idFundingList: [],
            imageRetrievalUrl: null,
            imageAssetPath: null,
            minDeposit: null,
            registrationBonus: null,
            shortDescription: null,
            sidebarImageUrl: null,
            startTimeMillis: null,
            type: null,
            waitingAllowed: null,
        };
    }

    protected logUserEvent (userEventEnum: UserEventEnum, userData: any): void {
        const iaData = this._incomeAccessService.retrieveData();
        const data = {
            ...userData,
            btag: iaData.btag,
        };
        this._eventTrackingService.logUserEvent(userEventEnum, data);
    }

    // convenience method for all platforms on user registration submit
    protected logRegistrationSubmitEvent(): void {
        this._eventTrackingService.logUserEvent(UserEventEnum.REGISTRATION_STEP, {
            registrationStep: RegistrationUserEventStatusEnum.SUBMIT
        });
    }

    protected setBrazeDeviceUser(camId: string) {
        if (this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BRAZE)) {
            this._brazeUtils.changeUser(camId);
        }
    }
    /**
     * Offer code has been validated so we can set the valid param and update the
     * registration display.
     * @param offer
     */
    public offerValidated(bonus: IBonus) {
        this.validOffer = this.currentOffer = bonus;

        /*
         * TODO: If we want to revert to the default image after unsetting
         *       validOfferCode, we would call fetchDefaultOfferImage from
         *       offerCodeChanged. However, doing so without clear need could
         *       lead to flashing of the default image if the offer is re-validated.
         */
        if (this.validOffer.sidebarImageUrl) {
            this._offerImgSubject.next(this.validOffer.imageAssetPath + this.validOffer.sidebarImageUrl);
        }
    }

    /**
     * Offer code has changed but has not been validated. Clear valid param until
     * the code can be validated.
     */
    public offerCodeChanged() {
        this.validOffer = null;
        this._offerImgSubject.next(this._OFFER_IMAGE_DEFAULT);
    }

    public buildUserEventObject(userData: any, regResponse: RegistrationResponse) {
        return {
            ...userData,
            cookiesEnabled: !!navigator.cookieEnabled,
            customerCity: regResponse.city,
            customerEmail: regResponse.email,
            customerZip: regResponse.zipCode,
            customerState: regResponse.state,
            firstName: regResponse.firstName,
            lastName: regResponse.lastName,
            userName: regResponse.login ? regResponse.login.user : null,
            birthDate: regResponse.dob,
            phone: regResponse.phone,
            bonusCode: regResponse.promoCode,
            timeZone: momentTime.tz(new Date(), momentTime.tz.guess()).format('z'),
            browserLanguage: navigator.language,
            osVersion:  navigator.appVersion,
        }
    }
}
