<div class="link bet-slip-menu-item"
     [class.is-active]="isActive"
     [class.has-new-total]="highlightBetLabel || isShowingBetAdded"
     [class.success]="isSuccess">
    <div class="bet-slip-menu-number">
        {{ this.formatBetCount() }}
    </div>
    <!-- We force a &nbsp; if we don't have our bet count yet so the
             menu item doesn't jump around between initial render and the
             bet count showing up. -->
    <span *ngIf="unplacedBets === undefined">&nbsp;</span>
    <span *ngIf="unplacedBets >= 0" class="bet-slip-menu-label">SAVED</span>
</div>
