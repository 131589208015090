export const LANG_FUNDTYPES_NAME = 'fundtypes';

export const LANG_FUNDTYPES_TRANS = {
    'PAYPAL': 'PayPal',
    'EZMONEY': 'EZMoney',
    'MONEYGRAM': 'MoneyGram',
    'MAZOOMA': 'Instant Bank Transfer',
    'CREDITCARD': 'Credit Card',
    'CASH': 'Cash',
    'CHECK': 'Check by Mail',
    'MONEYORDER': 'Money Order',
    'WIRETRANSFER': 'Wire Transfer',
    'GREENDOTATR': 'Green Dot',
    'PAYNEARME': 'PayNearMe'
};
