import { Component, ChangeDetectorRef, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';
import { RCNBaseVideoFeed } from './rcn-base-video-feed.class';
import { RCNVideoStreamSource } from './rcn-stream-sources/rcn-video-stream-source.service';

import { VideoProviderService } from '../video-provider.service';


@Component({
    selector: 'cdux-rcn-flash-video-feed',
    template: `<div #streamContainer [innerHTML]='content'></div>`
})
export class RCNFlashVideoFeedComponent extends RCNBaseVideoFeed implements OnInit {
    public static REQUIRES_FLASH = true;

    @ViewChild('streamContainer', { read: ViewContainerRef })
    protected container: ViewContainerRef;

    public content: SafeHtml;

    constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected sanitizer: DomSanitizer,
        protected streamSource: RCNVideoStreamSource,
        protected videoProviderService: VideoProviderService,
    ) {
        super(changeDetectorRef, videoProviderService, streamSource);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    protected handleStreamData (streamData: IVideoStreamResponses) {
        let streamUrl: string = this.getUrlFromCurrentStream();

        if (streamUrl) {
            // force https for stream url requests
            streamUrl = streamUrl.replace('http://', 'https://');
            this.content = 'Flash is not available';
        }
    }

    // no-op overrides
    public pause() {}
    public start() {}
    public stop() {}
}
