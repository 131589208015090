import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumRaceStatus,
    IRaceIdentifier,
    TracksDataService,
} from '@cdux/ng-common';

@Injectable({
    providedIn: 'root'
})
export class NoncurrentRacesConfigService {
    public static readonly CONFIG_KEY_POLL = 'program_poll_noncurrent_races';
    public static readonly STATUS_IS_LOADED = true;
    public static readonly STATUS_NOT_LOADED = false;

    /**
     * An array of IRaceIdentifier objects can't be searched by
     * simple element equality, since the object created to search by
     * won't be the same object reference as any in the array.
     *
     * So, as a shortcut, since our objects are neither deep nor complex,
     * and are restricted to the structure of an interface,
     * store the JSON string of the objects and use simple string comparison.
     */
    private idStrings: string[] = [];

    private triedOnce = false;
    private isLoadedSubject = new AsyncSubject();

    constructor (
        private configurationDataService: ConfigurationDataService,
        private tracksDataService: TracksDataService
    ) {}

    public loadConfig (): AsyncSubject<any> {
        if (!this.triedOnce) {
            this.isLoadedSubject.next(NoncurrentRacesConfigService.STATUS_NOT_LOADED);
            const configKeysToBeLoaded = [
                NoncurrentRacesConfigService.CONFIG_KEY_POLL
            ];
            this.configurationDataService.getConfiguration(enumConfigurationStacks.TUX, configKeysToBeLoaded)
                .subscribe(
                    (data) => {
                        try {
                            const configObj = JSON.parse(data[NoncurrentRacesConfigService.CONFIG_KEY_POLL]) as IRaceIdentifier[];
                            if (!   (!Array.isArray(configObj)
                                || !configObj.length
                                || !configObj[0].hasOwnProperty('brisCode')
                                || !configObj[0].hasOwnProperty('trackType')
                                || !configObj[0].hasOwnProperty('raceNumber'))
                            ) {
                                configObj.forEach((value) => {
                                    this.idStrings.push(JSON.stringify(value));
                                });
                            }

                            this.isLoadedSubject.next(NoncurrentRacesConfigService.STATUS_IS_LOADED);
                            this.isLoadedSubject.complete();
                        } catch (error) {
                            if (!(error instanceof SyntaxError)) {
                                throw error;
                            }
                            console.warn('[NoncurrentRacesConfigService] Invalid configuration object.', data);
                        }
                    },
                    (data) => {
                        this.isLoadedSubject.next(NoncurrentRacesConfigService.STATUS_NOT_LOADED);
                        this.isLoadedSubject.complete();
                        console.error('[NoncurrentRacesConfigService] Error loading configuration.');
                    }
            );
            this.triedOnce = true;
        }
        return this.isLoadedSubject;
    }


    /*
     * WISHLIST: Rather than front-load this service in the app initializer,
     *           it would be nice for this method to be able to wait for
     *           the isLoadedSubject subject.
     */
    // public shouldPollRace (raceId: IRaceIdentifier): Observable<boolean> {
    //     /**
    //      * We have to make sure that the configuration has been loaded,
    //      * so wait for the isLoadedSubject subject, or for a timeout.
    //      */
    //     return new Observable().pipe(
    //         skipUntil(this.isLoadedSubject),
    //         tap((data) => {
    //             console.log('new obs got here');
    //         }),
    //         flatMap((data) => {
    //             return of(this.config.indexOf(raceId) !== -1).pipe(
    //                 take(1)
    //             );
    //         }),
    //         take(1),
    //     );
    // }

    /**
     * determines if given raceId appears in config (list of non-current exceptions)
     *
     * The JSON-parsed array of IRaceIdentifier objects can't be searched by
     * simple element equality, since the objects are not the same. Instead,
     * equality requires us comparing the objects' properties.
     *
     * @param raceId
     */
    public isRaceAnException (raceId: IRaceIdentifier): boolean {
        return this.idStrings.includes(JSON.stringify(raceId));
    }

    /**
     * determines if given raceId should be polled
     *
     * Before looking at the non-current exceptions, this method first looks
     * to see if the given race is the current race for the given track. If
     * so, polling is enabled. If not, it checks the exceptions.
     *
     * @param raceId
     */
    public shouldPollRace (raceId: IRaceIdentifier): Observable<boolean> {
        return this.tracksDataService.todaysTracks().pipe(
            take(1),
            map((todaysTracksData) => {
                const selectedTrack = todaysTracksData.find((value) => value.brisCode.toUpperCase() === raceId.brisCode.toUpperCase());
                if (selectedTrack) {
                    const selectedRace = selectedTrack.races.find((value) => value.raceNumber === raceId.raceNumber);
                    if (selectedRace) {
                        // short-circuit if this is the current race for the track
                        if (selectedRace.currentRace === true && selectedRace.status === enumRaceStatus.OPEN) {
                            return true;
                        }
                    }
                }
                return this.isRaceAnException(raceId);
            })
        );
    }
}
