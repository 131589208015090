import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { EventSessionModule, TranslateModule} from '@cdux/ng-common';
import { SharedModule as SharedFragmentsModule, LoadingModule } from '@cdux/ng-fragments';
import { CduxFormsModule } from '@cdux/ng-platform/web';

import { BetSlipModule } from 'app/shared/bet-slip/bet-slip.module';
import { IdScanModule } from 'app/shared/id-scan/id-scan.module';
import { TuxCommonModule } from 'app/shared/common/common.module';

import { FullpageRegistrationComponent } from './fullpage-registration.component';
import { GanWidgetContainerComponent } from './gan-widget-container.component';
import { RegistrationComponent } from './registration.component';
import { RegistrationNotificationService } from './registration-notification.service';

@NgModule({
    declarations: [
        FullpageRegistrationComponent,
        RegistrationComponent,
        GanWidgetContainerComponent
    ],
    imports: [
        BetSlipModule,
        CduxFormsModule,
        CommonModule,
        EventSessionModule,
        IdScanModule,
        RouterModule,
        TranslateModule,
        TuxCommonModule,
        SharedFragmentsModule,
        LoadingModule
    ]
})
export class RegistrationModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<RegistrationModule> {
        return {
            ngModule: RegistrationModule,
            providers: [
                RegistrationNotificationService
            ],
        }
    }
}
