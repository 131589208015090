<div class="tile">
    <div class="header-row">
        <div id="fav-tile-trainer-trainer-name-{{ trainerName }}" class="title-name">
            {{ trainerName }}
            <div class="title-name__stats">
                <span aria-label="view stats" role="link" class="link" tabindex="0" (click)="openStatsModal()">Stats</span>
            </div>
        </div>
    </div>

    <ng-container *ngFor="let runner of runnerDetails; index as i">
        <div class="trainer__track-row" [class.first]="i===0">
            <div id="fav-tile-trainer-track-name-{{ runner.trackName }}" class="trainer__track-row__track-name">{{ runner.trackName }}
            </div>

            <div class="trainer__track-row__runner-name">
                <span id="fav-tile-trainer-runner-name-{{ runner.runnerName }}">{{ runner.runnerName }}</span>
                <button id="fav-tile-trainer-bet" aria-label="bet" class="smaller" (click)="bet(runner)">BET</button>
            </div>

            <div class="trainer__track-row__race-info">
                <span id="fav-tile-trainer-race-number-{{ runner.raceNumber }}">R{{ runner.raceNumber }}</span>
                <span id="fav-tile-trainer-race-type-{{ runner.raceType }}">{{ getRaceTypeText(runner.maxClaimPrice, runner.breedType, runner.raceType, false) }}</span>
            </div>

            <div *ngIf="runner.additionalRacesCnt > 0" id="fav-tile-trainer-more" class="more">plus {{ runner.additionalRacesCnt }} more</div>
            <div *ngIf="runner.additionalRacesCnt === 0" id="fav-tile-trainer-more" class="more"></div>
        </div>
    </ng-container>

    <!-- If there's only one runner for a trainer, we want a space to make the cards the same size. -->
    <ng-container *ngIf="runnerDetails.length === 1">
        <div class="trainer__track-row empty"></div>
    </ng-container>

    <div class="trainer__track-row__link">
        <span aria-label="see all runners" role="link" class="link see-more" tabindex="0" (click)="expand()">See All Runners</span>
    </div>

    <div class="notes-row">
        <span class="title-name">Notes</span>
        <span id="fav-tile-trainer-notes" class="notes">{{ trainerData.comment }}</span>
    </div>

    <div class="action-row">
        <!-- EDIT BUTTON HERE -->
        <div class="action-row_col">
            <span id="fav-tile-trainer-edit-button" aria-label="edit this trainer" role="button" class="action-item" tabindex="0" (click)="edit()">Edit</span>
        </div>
        <span aria-hidden="true" class="text__gray2">|</span>
        <div class="action-row_col">
            <span id="fav-tile-trainer-remove-button" aria-label="remove this trainer" role="button" class="action-item" tabindex="0" (click)="remove()">Remove</span>
        </div>
        <span aria-hidden="true" class="text__gray2">|</span>
        <div class="action-row_col">
            <span id="fav-tile-trainer-see-all-button" aria-label="see all runners from this trainer" role="button" class="action-item" tabindex="0" (click)="expand()">See All</span>
        </div>
    </div>
</div>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #statsModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1"></div>
      <div class="col2"></div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div class="scrollable">
      <cdux-favorite-stats-trainer
        [detailOptions]="options"
      ></cdux-favorite-stats-trainer>
    </div>
  </div>
</ng-template>

<ng-template #removeModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <h2>
      <span id="favorite-add-modal-description" class="runner">
        Are you sure you want to remove {{ trainerName }}?
      </span>
    </h2>
    <div class="message">You will no longer receive alerts and all notes will be lost.</div>
    <div class="button-row">
      <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
      <div class="btn-remove" (click)="confirmRemove()">Remove</div>
    </div>
  </div>
</ng-template>

<ng-template #updateModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <h2>
      <span id="favorite-add-modal-description" class="runner">{{ trainerName }}</span>
    </h2>
    <textarea id="favorite-add-modal-note"
              cols="50" [maxlength]="COUNT_MAX" name="note"
              placeholder="ADD A NOTE..." rows="13"
              [(ngModel)]="note"
    ></textarea>
    <div class="char-count"></div>
    <div class="btn-update" (click)="confirmUpdate()">Update</div>
  </div>
</ng-template>

<ng-template #expandedModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner">
      <div class="col1">
        <h1 class="stable-alerts__trainer__runner__title">{{ trainerName }}</h1>
      </div>

      <div class="col2">
          <div class="stable-alerts__trainer__runner__notes-title">Notes</div>
          <div id="expanded_view_notes" class="stable-alerts__trainer__runner__notes">{{ trainerData.comment }}</div>
      </div>

      <div class="col3">
          <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>

    <div class="stable-alerts__trainer__runner__title-bar">
      <span class="col1">Runners</span>
      <span class="col2">Time</span>
      <span class="col3">Race</span>
      <span class="col4">Race Details</span>
      <span class="col5">Track</span>
      <span class="col6">Bet</span>
    </div>

    <div class="stable-alerts__trainer__runner__scroll-area">
        <ul id="expanded_view_runner_list" class="stable-alerts__trainer__runner__list">
          <li *ngFor="let runner of todaysRunners; index as i">
            <div class="col1">
                <div id="expanded_view_runner_name_{{i}}" class="runner-name">{{ runner.runnerName }}</div>
                <div id="expanded_view_runner_details_{{i}}" class="runner-details">{{ formatRunnerDetails(runner) }}</div>
            </div>
            <div class="col2">
                <div class="mtp-badge minutes-to-post">
                  <cdux-mtp-badge
                    id="expanded_view_mtp_{{i}}-mtpBadge"
                    [source]="setMtpDisplaySrc(runner)"
                    [showMinutes]="true">
                  </cdux-mtp-badge>
                </div>
            </div>
            <div class="col3">
                <span id="expanded_view_race_number_{{i}}">R{{ runner.raceNumber }}</span>
            </div>
            <div class="col4">
                <div id="expanded_view_race_type_{{i}}" class="race-details-1">
                  {{ getRaceTypeText(runner.maxClaimPrice, runner.breedType, runner.raceType, true) }}
                </div>
                <div id="expanded_view_purse_{{i}}" class="race-details-2">{{ runner.formattedPurse }} | <span *ngIf="runner.ageRestriction">{{runner.ageRestriction | uppercase}} | </span><span *ngIf="runner.sexRestriction">{{runner.sexRestriction}} | </span>{{runner.distance}} | {{runner.surfaceDescription | titlecase}}</div>
            </div>
            <div class="col5">
                <span  id="expanded_view_track_name_{{i}}">{{ runner.trackName }}</span>
            </div>
            <div class="col6">
                <button [class.smaller]="!isPhone" (click)="bet(runner, true)">BET</button>
            </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
