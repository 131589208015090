<div class="ts-radio inline">
  <input type="radio"
    [id]="id"
    [name]="name"
    [checked]="checked" 
    [disabled]="disabled"
    [required]="required"
    (change)="onChange($event)"
    (click)="onClick($event)" />
  <label [for]="id">
      <span>
        <ng-content></ng-content>
      </span>
  </label>
</div>