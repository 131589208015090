import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cdux-funding-transaction-summary',
    templateUrl: './transaction-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FundingTransactionSummaryComponent {

    public readonly DIGIT_FORMAT = '1.2';

    @Input() public transactionName: string;
    @Input() public amount: number;
    @Input() public feeName: string;
    @Input() public feeValue: number;

    public calculateTotal(): number {
        return this.amount + this.feeValue;
    }
}
