import {
    AfterContentInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EventsService } from '@cdux/ng-common';
import { EventKeys } from '../events';

@Directive({
    selector: '[cduxVisible]'
})
export class VisibleDirective implements OnInit, AfterContentInit, OnDestroy {

    /**
     * An external event that will trigger the visibility check.
     */
    @Input() public triggerEvent: string = EventKeys.MAIN_SCROLLING;

    /**
     * Event to emit when the component visibility changes.
     */
    @Input() public visibilityEvent: string;

    /**
     * An number of pixels above or below to go ahead and transition to the visible state.
     * This is useful for getting a head start on polling before reaching a widget
     */
    @Input() public visibilityTopMargin: number = 0;
    @Input() public visibilityBottomMargin: number = 0;

    @Output() public isVisible: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public isHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * These are saved states for us to compare against to determine if we're transitioning states.
     */
    private _isCurrentlyHidden: boolean;
    private _isCurrentlyVisible: boolean;

    /**
     * This is the subscription to the trigger event for visibility detection.
     */
    private _eventSubscription: Subscription;

    /**
     * Determine if a given element is visible.
     *
     * @param element
     * @param topMargin (optional)
     * @param bottomMargin (optional)
     * @returns true if any part of element is visible
     */
    public static isElementVisible(element: ElementRef, topMargin = 0, bottomMargin = 0): boolean {
        return VisibleDirective.getElementVisibility(element, topMargin, bottomMargin) > 0;
    }

    /**
     * Determine the visibility of a given element.  The return value is the calculated percentage
     * of the component (plus/minus margins) which is currently within the viewport.
     *
     * @param element
     * @param topMargin (optional)
     * @param bottomMargin (optional)
     * @returns Percentage of element which is visible
     */
    public static getElementVisibility(element: ElementRef, topMargin = 0, bottomMargin = 0): number {
        // vHeight is the height of the viewport.
        const vHeight: number = (window.innerHeight || document.documentElement.clientHeight);

        /**
         * rect is the smallest rectangle which contains the entire element,
         * with read-only left, top, right, bottom, x, y, width,
         * and height properties describing the overall border-box
         * in pixels. Properties other than width and height are
         * relative to the top-left of the viewport.
         */
        const rect = element.nativeElement.getBoundingClientRect(),
            top = rect.top - topMargin,
            bottom = rect.bottom + bottomMargin,
            // default to 1px height if within viewport
            height = rect.height || (vHeight > top ? 1 : 0),
            visible = height // total potential viewable size of component
                + Math.min(top, 0) // add (negative) amount above viewport
                // add any bottom margin portion contained by the viewport
                + (bottom >= vHeight ? (bottom - vHeight) : bottomMargin),
            visiblePct = visible / height; // visible component percentage

        return visiblePct;
    }

    constructor(private _el: ElementRef, private _eventsService: EventsService) { }

    public ngOnInit() {
        this._eventSubscription = this._eventsService.on(this.triggerEvent).subscribe(() => {
            this._processVisibility();
        });
    }

    public ngAfterContentInit() {
        // We should check to see if the element is visible immediately after the inner content has rendered.
        // It needs to be wrapped in a setTimeout so that things have the chance to subscribe to events before this emits.
        setTimeout(_ => this._processVisibility());
    }

    public ngOnDestroy() {
        if (this._eventSubscription) {
            this._eventSubscription.unsubscribe();
        }
    }

    private _processVisibility() {
        const visibility = VisibleDirective.getElementVisibility(
                this._el,
                this.visibilityTopMargin,
                this.visibilityBottomMargin
            ),
            isVisible = visibility > 0,
            isHidden = visibility < 1;

        if (isVisible !== this._isCurrentlyVisible) {
            this._isCurrentlyVisible = isVisible;
            this.isVisible.emit(isVisible);
            if (this.visibilityEvent) {
                this._eventsService.broadcast(this.visibilityEvent, { isVisible });
            }
        }

        if (isHidden !== this._isCurrentlyHidden) {
            this._isCurrentlyHidden = isHidden;
            this.isHidden.emit(isHidden);
        }
    }
}
