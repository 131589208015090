export const LANG_VALIDATION_NAME = 'validation';

export const LANG_VALIDATION_TRANS = {
  'minlength': 'The value must be at least %s characters.',
  'maxlength': 'The value can not be more than %s characters.',
  'expDate': 'Invalid expiration date.',
  'invalidCC': 'Please enter a valid card number',
  'pattern': 'The input is not valid.',
  'required': 'The input is required.',
  'pwmismatch': 'Passwords do not match.'
};
