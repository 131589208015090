import { Component } from '@angular/core';
import { ModalService } from '@cdux/ng-platform/web';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { EventClickType } from '@cdux/ng-common';

@Component({
  selector: 'cdux-tour-component',
  templateUrl: './tour.component.html'
})
export class TourComponent {

    private _context: any;
    public tourImages: string;

    constructor(private _modalService: ModalService,
                private _eventTrackingService: EventTrackingService) {
    }

    public set context(context: any) {
        this._context = context;
    }

    public get context(): any {
        return this._context;
    }

    public closeTourModal() {
        this._eventTrackingService.logClickEvent(EventClickType.TOUR_CLOSE);
        this._modalService.closeAll();
    }

    /**
     * Emitter callback for when a user clicks the back button.
     * @param {number} location - the new current position of the carousel.
     * IE: Someone is on view 3 and hits back, location will be 2.
     */
    public onBack(location: number) {
        this._eventTrackingService.logClickEvent(EventClickType.TOUR_PREVIOUS_IMAGE);
    }

    /**
     * Emitter callback for when a user clicks the next button.
     * @param {number} location - the new current position of the carousel.
     * IE: Someone is on view 0 and hits next, location will be 1.
     */
    public onNext(location: number) {
        this._eventTrackingService.logClickEvent(EventClickType.TOUR_NEXT_IMAGE);
    }

}
