import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';

import { Bet, enumFeatureToggle, EventClickType, FeatureToggleDataService, IBet, JwtSessionService, RaceDateService } from '@cdux/ng-common';
import { CduxStorageService } from '@cdux/ng-platform/web';

import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { BetSlipComponent } from '../../../bet-slip/components/bet-slip/bet-slip.component';
import { BetSlipBusinessService } from '../../../bet-slip/services/bet-slip.business.service';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import { ISidebarComponentProperties, ISidebarPortalComponent } from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { take } from 'rxjs/operators';

enum MainTabsEnum {
    BET_SLIP,
    MY_BETS
}

enum MyBetsTabsEnum {
    ACTIVE,
    ENDED
}

export enum BetsViewEnum {
    BET_SLIP,
    ACTIVE_BETS,
    ENDED_BETS
}

const NAV_PANEL_PROPS = {
    BETS_VIEW: 'currentBetsView',
    BETS_ID: 'returningBetId',
    BET_SHARE_ID: 'betShareId',
};


/**
 * Height of the header within the nav-panel/sidebar.
 *
 * @type {number}
 */
const BETS_HEADER_HEIGHT = 46;

/**
 * Height of the more content within the nav-panel/sidebar.
 *
 * @type {number}
 */
const BETS_SCROLL_HEIGHT = 50;

/**
 * Height of the footer within the nav-panel/sidebar.
 *
 * @type {number}
 */
const BETS_FOOTER_HEIGHT = 94;

export interface IBetContainerNavPanelProps {
    currentBetsView?: BetsViewEnum;
    returningBetId?: string;
    betShareId?: string;
}

@Component({
    selector: 'cdux-bets-container',
    templateUrl: './bets-container.component.html',
    styleUrls: ['./bets-container.component.scss']
})
export class BetsContainerComponent extends CduxSidebarContentComponent implements OnInit {

    public isBetPad: boolean = false;

    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        this.isBetPad = (value === enumProgramViews.BETPAD);
    }
    public get programView(): enumProgramViews {
        return this._programView;
    }

    /**
     * Gets the bet slip component, when active.
     */
    @ViewChild(BetSlipComponent) private _betSlipComponent: BetSlipComponent;

    /**
     * The indicators for the various views.
     *
     * @type {BetsViewEnum}
     */
    public readonly BETS_VIEW_ENUM = BetsViewEnum;

    /**
     * The indicators for the main tabs.
     *
     * @type {MainTabsEnum}
     */
    public readonly MAIN_TABS_ENUM = MainTabsEnum;

    /**
     * The indicators for the sub-tabs under My Bets.
     *
     * @type {MyBetsTabsEnum}
     */
    public readonly MY_BETS_TABS_ENUM = MyBetsTabsEnum;

    /**
     * The view currently on display.
     *
     * @type {number}
     */
    public currentBetsView: number = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.SAVED_BETS) ? BetsViewEnum.BET_SLIP : BetsViewEnum.ACTIVE_BETS;

    /**
     * The bet ID currently being transferred.
     *
     * @type {string}
     *
     */
    @Input() public returningBetId: string;

    /**
     * The section of my bets to display when the container first loads
     *
     * @type {BetsViewEnum}
     */
    @Input() public initialBetsView: BetsViewEnum;

    /**
     * The betShare bet ID passes from bet total share button.
     *
     * @type {string}
     *
     */
    public betShareId: string;

    /**
     * The currently selected main tab.
     *
     * @type {number}
     */
    public currentMainTab: number = MainTabsEnum.BET_SLIP;

    /**
     * The currently selected sub-tab, which incidentally only exists under the My Bets main tab.
     *
     * @type {number}
     */
    public currentMyBetsTab: number = MyBetsTabsEnum.ACTIVE;

    /**
     * An indicator for the click events bound to the tabs.
     *
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    /**
     * Number of bets in the bet slip.
     *
     * @type {number}
     */
    public betSlipBetCount: number = 0;

    /**
     * Total cost of the bets in the bet slip.
     *
     * @type {string}
     */
    public betSlipTotalAmount: string = '0';

    /**
     * Are the bets reporting as valid?
     *
     * @type {boolean}
     */
    public betSlipValid: boolean = false;

    public showFooter = false;

    public betShareFeatureToggle: boolean = false;
    public savedBetsFeatureToggle: boolean = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.SAVED_BETS);

    /*
     * Are submit all operations processing?
     * @type {boolean}
     */
    public submitAllInProgress: boolean = false;

    /**
     * current toteDare
     * @type {Date}
     */
    private _toteDate: Date = new Date();

    /*****************************
     * Sidebar Content Component Implementation
     *****************************/
    public static getSidebarComponent(options: IBetContainerNavPanelProps = {}): ISidebarPortalComponent {
        const _inputTokens = new Map<any, any>();
        if (options.currentBetsView !== undefined) {
            _inputTokens.set(NAV_PANEL_PROPS.BETS_VIEW, options.currentBetsView);
        }
        if (options.returningBetId !== undefined) {
            _inputTokens.set(NAV_PANEL_PROPS.BETS_ID, options.returningBetId);
        }
        if (options.betShareId !== undefined) {
            _inputTokens.set(NAV_PANEL_PROPS.BET_SHARE_ID, options.betShareId);
        }
        return {
            component: BetsContainerComponent,
            properties: {
                navTarget: MenuItemsEnum.BETS,
                inputs: _inputTokens
            }
        }
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return null;
    }

    public setProperties(props: ISidebarComponentProperties): void {
        if (props && props.inputs) {
            if (props.inputs.has(NAV_PANEL_PROPS.BETS_VIEW)) {
                this.setView(props.inputs.get(NAV_PANEL_PROPS.BETS_VIEW), null);
            }

            if (props.inputs.has(NAV_PANEL_PROPS.BETS_ID)) {
                this.returningBetId = props.inputs.get(NAV_PANEL_PROPS.BETS_ID);
            }

            if (props.inputs.has(NAV_PANEL_PROPS.BET_SHARE_ID)) {
                this.betShareId = props.inputs.get(NAV_PANEL_PROPS.BET_SHARE_ID);
            }
        }
    }
    /*****************************/

    /**
     * Constructor
     *
     * @param {EventTrackingService} eventTrackingService
     * @param {CduxStorageService} _cduxStorageService
     * @param {JwtSessionService} _sessionService
     * @param {BetSlipBusinessService} _betSlipService
     * @param {ChangeDetectorRef} changeDetector
     * @param {RaceDateService} _raceDateService
     */
    constructor(private eventTrackingService: EventTrackingService,
                private _featureToggleDataService: FeatureToggleDataService,
                private _cduxStorageService: CduxStorageService,
                private _sessionService: JwtSessionService,
                private _betSlipService: BetSlipBusinessService,
                private changeDetector: ChangeDetectorRef,
                private _raceDateService: RaceDateService,
    ) {
        super();
    }

    ngOnInit() {
        if (this.initialBetsView in BetsViewEnum) {
            // Display the specified section of my bets
            this.setView(this.initialBetsView)
        }

        this._cduxStorageService.fetch({db: Bet.DB, version: Bet.VERSION}).then((e: IBet[]) => {
            this._raceDateService.getToteDate().pipe(
                take(1)
            ).subscribe((date: Date) => {
                this._toteDate = date;

                const bets = this._processBets(e);
                const todaysBets = this._betSlipService.clearPrevDaysSavedBets(
                    this._cduxStorageService,
                    bets,
                    this._toteDate
                );
                this.betSlipBetCount = todaysBets.length;
            });
        });
    }


    /**
     * Selects the appropriate tab. Can be called apart from user interacting with UI so the event is optional.
     *
     * @param {number} type - This should be a BetsViewEnum.
     * @param {EventClickType} [event] - The type of the click event that we want to log
     */
    public setView(type: number, event?: EventClickType) {
        // If we have an event, it was from a user interaction and should be recorded in the event logger.
        if (event) {
            this.eventTrackingService.logClickEvent(event);
        }

        switch (type) {
            case BetsViewEnum.BET_SLIP:
                this.currentMainTab = this.MAIN_TABS_ENUM.BET_SLIP;
                this.submitAllInProgress = false;
                break;
            case BetsViewEnum.ACTIVE_BETS:
                this.currentMainTab = this.MAIN_TABS_ENUM.MY_BETS;
                this.currentMyBetsTab = this.MY_BETS_TABS_ENUM.ACTIVE;
                this.showFooter = false;
                this.betShareId = null;
                break;
            case BetsViewEnum.ENDED_BETS:
                this.currentMainTab = this.MAIN_TABS_ENUM.MY_BETS;
                this.currentMyBetsTab = this.MY_BETS_TABS_ENUM.ENDED;
                this.showFooter = false;
                this.betShareId = null;
                break;
        }
        this.currentBetsView = type;
        // I need this line so that it will update the UI before recalculating the content size.
        this.changeDetector.detectChanges();
        this.communicateContentSizeChange();
    }

    /**
     * Triggers the submit all function on the bet slip component, if available.
     */
    public triggerSubmitAll() {
        if (this._betSlipComponent) {
            this._betSlipComponent.submitAll();
        } else {
            console.error('Could not find Bet Slip from which to submit bets.');
        }
    }

    public toggleSubmitAllButton(submitAllInProgress: boolean): void {
        this.submitAllInProgress = submitAllInProgress;
    }

    /**
     * When one of the transactions changes in size, we need to recheck the content size.
     */
    public sizeChange() {
        this.communicateContentSizeChange();
    }

    /**
     * Returns the scrolling area to the top.
     */
    public resetScroll() {
        this.scrollingArea.nativeElement.scrollTop = 0;
    }

    /**
     * Response to bet count updates.
     *
     * @param {number} betCount
     */
    public onBetCountUpdate(betCount: number) {
        this.betSlipBetCount = betCount;
    }

    /**
     * Response to total bet amount updates.
     *
     * @param {string} totalAmount
     */
    public onTotalAmountUpdate(totalAmount: string) {
        this.betSlipTotalAmount = totalAmount;
    }

    /**
     * Response to validity updates on the bets. This will make it so that submit all cannot be triggered,
     * in the event of an errored bet.
     *
     * @param {boolean} valid
     */
    public onValidUpdate(valid: boolean) {
        this.betSlipValid = valid;
    }

    public betSlipInitialized() {
        this.showFooter = true;
    }

    public forceChangeDetection() {
        this.changeDetector.detectChanges();
    }

    /**
     * Filters out bets to just what should show in the bet slip.
     *
     * @param {any[]} bets
     * @returns {Bet[]}
     * @private
     */
    private _processBets(bets: any[]): Bet[] {
        const userName = this._sessionService.getUserInfo().username;
        return bets
            .map((e) => Bet.fromBetlike(e))
            .filter((e: Bet) =>
                e.userName === userName
                && e.id !== this._betSlipService.currentBet.id
                && e.showInBetSlip
                && !e.isQuickBet);
    }

    /**
     * Emits a content size change with an override for size of the scrolling area, if available.
     * To make it respond to a particular element's height, set a #scrollingArea element.
     */
    public communicateContentSizeChange() {
            // Unfortunately, this gets called on change detection, presumably because it's
            // bound to a portal. This results in this emitting the client height at inconvenient
            // times, like when it hasn't fully rerendered. So I'm setting it in a timeout
            // to make it wait until it's done. And yes, I've tried many things to get this
            // to work without it. Good luck trying, if you're really determined.
            //  I'm adding Bets Components height from the scrollHeight to account for the bets container component.
            setTimeout(() => {
                // In IE the clientHeight is giving different values so using scrollHeight.
                this.contentSizeChange.emit(this.scrollingArea.nativeElement.scrollHeight + (BETS_HEADER_HEIGHT + BETS_SCROLL_HEIGHT + BETS_FOOTER_HEIGHT));
            });

    }
}
