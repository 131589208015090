export enum enumDetailsType {
    DRIVER = 'DRIVER',
    TRAINER = 'TRAINER',
    TRAINER_HARN = 'TRAINER_HARN',
    JOCKEY = 'JOCKEY',
    RUNNER = 'RUNNER',
    RUNNER_GH = 'RUNNER_GH',
    SIRE = 'SIRE',
    DAM = 'DAM'
}
