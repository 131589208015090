import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { EventClickType } from '@cdux/ng-common';
import { BreadcrumbsService } from './services/breadcrumbs.service';

@Component({
    selector: 'cdux-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

    public eventClickType = EventClickType;
    private _subs: Subscription[] = [];

    constructor (
        public breadcrumbsService: BreadcrumbsService, // needed in template
        private _router: Router,
        private _changeDetector: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this._subs.push(
            this.breadcrumbsService.breadcrumbUpdates.subscribe(
                _ => this._changeDetector.detectChanges()
            )
        );
    }

    ngOnDestroy() {
        this._subs.map(sub => sub.unsubscribe());
    }

    public goToBreadcrumbUrl (url: string) {
        this._router.navigateByUrl(url);
    }
}
