import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ChangeDetectionStrategy
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';

import {
    DetectionService,
    Entry,
    EventClickType,
    FeatureToggleDataService,
    GetTrainerJockeyDetailsDataService,
    IFavoritePerson,
    IJockeyTrainerInfo,
    PersonnelStats,
    StringSlugifyPipe,
    ToteDataService,
    TracksDataService,
    TrainerJockeyDetails,
    TranslateService
} from '@cdux/ng-common';
import { CduxRouteUtil } from 'app/shared';
import { BreadcrumbsService } from 'app/shared/breadcrumbs/services/breadcrumbs.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';


@Component({
    selector: 'cdux-trainer-jockey-details',
    templateUrl: './trainer-jockey-details.component.html',
    styleUrls: ['./trainer-jockey-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainerJockeyDetailsComponent implements OnInit, OnDestroy, IDetails {
    readonly STATS_TYPE_TRAINER = 'TRAINER';
    readonly STATS_TYPE_JOCKEY = 'JOCKEY';
    readonly DISPLAY_TOGGLE_LABEL_MORE = 'MORE';
    readonly DISPLAY_TOGGLE_LABEL_LESS = 'LESS';
    readonly MIN_ROWS_DISPLAY = 5;

    public trainerJockeyDetails: TrainerJockeyDetails; // This holds the model data
    public errorMessage: string;
    public error: boolean = false;
    public showWholeAllStats: boolean = false;
    public allStatsToggleLabel: string;
    public currentStatsReport: string; // This indicates TRAINER or JOCKEY page
    public favoriteData: IFavoritePerson;
    public displayedKeyStats: PersonnelStats[] = [];
    public displayedAllStats: PersonnelStats[] = [];
    public displayedComboStats: PersonnelStats[] = [];
    public trainerId: number;
    public jockeyId: number;
    public trainerName: string = null;
    public jockeyName: string = null;
    public jockeyChange: boolean = false;
    public entry: Entry = null;
    public trackName: string = null;
    public trackBrisCode: string = null;
    public trackType: string = null;
    public trackRaceNumber: number;
    public programNumber: string;
    public isInline: boolean = true;
    public isDesktop: boolean = false;
    public isTrainerStable: boolean = false;
    public isJockeyStable: boolean = false;

    private reportType: string | null;
    public allStatsLen = 0;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbsService: BreadcrumbsService,
        private _detectionService: DetectionService,
        private _eventService: EventTrackingService,
        private _headerService: HeaderService,
        private _stringSlugify: StringSlugifyPipe,
        private _toteDataService: ToteDataService,
        private _trainerJockeyDetailsDataService: GetTrainerJockeyDetailsDataService,
        private _translateService: TranslateService,
        private _tracksDataService: TracksDataService,
        private _changeDetector: ChangeDetectorRef,
        public localFeatureToggleService: FeatureToggleDataService,
    ) {
        // Empty
    }

    public ngOnInit() {
        this.isDesktop = this._detectionService.isDesktop();

        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);
                    this.isInline = false;

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNumber,
                        programNumber: params.programNumber,
                        trackName: params.trackName,
                        trainerId: params.trainerId,
                        jockeyId: params.jockeyId,
                        trainerName: params.trainerName,
                        jockeyName: params.jockeyName,
                        jockeyChange: data.change ? data.change : false
                    }
                    this.init(options);
                }
            }
        );

    }

    public ngOnDestroy() {
        this._headerService.setDefaultHeader();
        this._destroy.next();
        this._destroy.complete();
    }

    public init(options: IDetailsOptions) {
        this.trackName = options.trackName;
        this.trackBrisCode = options.brisCode;
        this.trackType = options.trackType;
        this.trackRaceNumber = options.raceNumber;
        this.trainerId = options.trainerId;
        this.jockeyId = options.jockeyId;
        this.trainerName = options.trainerName;
        this.jockeyName = options.jockeyName;
        this.programNumber = options.programNumber;
        this.jockeyChange = options.jockeyChange;

        this.reportType = this.getReportType();

        this.isTrainerStable = this.localFeatureToggleService.isFeatureToggleOn('STABLE_TRAINER');
        this.isJockeyStable = this.localFeatureToggleService.isFeatureToggleOn('STABLE_JOCKEY');

        if (this.reportType === this.STATS_TYPE_TRAINER) {
            this.currentStatsReport = this.STATS_TYPE_TRAINER;
            this.initialSetup();
            this.getTJDetails( this.STATS_TYPE_TRAINER );
        } else if (this.reportType === this.STATS_TYPE_JOCKEY) {
            this.currentStatsReport = this.STATS_TYPE_JOCKEY;
            this.initialSetup();
            this.getTJDetails( this.STATS_TYPE_JOCKEY );
        } else {
            this.error = true;
            this.errorMessage = this._translateService.translate('bad-request', 'trainer-jockey-details-errors');
        }
    }

    private getReportType() {
        if (this.trainerName && this.trainerId) {
            return this.STATS_TYPE_TRAINER;
        } else if (this.jockeyName && this.jockeyId) {
            return this.STATS_TYPE_JOCKEY;
        } else {
            return null;
        }
    }

    private initializeBreadCrumbs(trainerOrJockeyName: string) {
        // If there are no previous routes (such as when coming from a link) then
        // we pull the track info from url to create breadcrumb back to track details
        if (!this._breadcrumbsService.breadcrumbs.length && this.trackBrisCode && this.trackType) {
            this._tracksDataService.trackList(false).subscribe((tracks) => {
                if (tracks) {
                    tracks.forEach((track) => {
                        if (this.trackBrisCode.toLowerCase() === track.BrisCode.toLowerCase()) {
                            this.trackName = track.DisplayName;
                        }
                    });
                }
                this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb(
                    (this.trackName || 'Program') + ' - Race ' + this.trackRaceNumber, [
                        '/program', this._stringSlugify.transform(this.trackName),
                        this.trackBrisCode, this.trackType, this.trackRaceNumber
                    ].join('/')
                ));
                this._breadcrumbsService.addCurrentRoute(trainerOrJockeyName);
            });
        } else {
            this._breadcrumbsService.addCurrentRoute(trainerOrJockeyName);
        }
    }

    private initialSetup() {
        this.allStatsToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
    }

    private setData(personnelData) {
        this.trainerJockeyDetails = personnelData;
        if (this.reportType === this.STATS_TYPE_TRAINER) {
            const name = this.trainerJockeyDetails.primaryName.split(' ');
            this.favoriteData = {
                personType: 'trainer',
                personId: Number(this.trainerId),
                lastName: name.length === 2 ? name[1].replace(',', '') : name[2].replace(',', ''),
                firstName: name[0],
                middleName: name.length === 2 ? null : name[1]
            }
        } else {
            const name = this.trainerJockeyDetails.primaryName.split(' ');
            this.favoriteData = {
                personType: 'jockey',
                personId: Number(this.jockeyId),
                lastName: name.length === 2 ? name[1].replace(',', '') : name[2].replace(',', ''),
                firstName: name[0],
                middleName: name.length === 2 ? null : name[1]
            }
        }
        this.displayedKeyStats = personnelData.keyStats;
        this.allStatsLen = personnelData.allStats.length;
        this.displayedAllStats = (this.showWholeAllStats) ? personnelData.allStats : this.getShortList(personnelData.allStats, this.MIN_ROWS_DISPLAY);
        this.displayedComboStats = personnelData.comboKeyStats || null;

        this._headerService.updateHeader({ staticContent: this.trainerJockeyDetails.primaryName });
        this.initializeBreadCrumbs(this.trainerJockeyDetails.primaryName);
        this._changeDetector.detectChanges();
    }

    public getTJDetails(statsType: string = this.STATS_TYPE_TRAINER) {
        const isCombo: boolean = !!(this.trainerId && this.jockeyId && this.jockeyId !== -1);
        try {
            if ((statsType === this.STATS_TYPE_TRAINER && this.trainerId > 0) || (statsType === this.STATS_TYPE_JOCKEY && this.jockeyId > 0)) {
                let tjFeed: any = null;
                if (statsType === this.STATS_TYPE_TRAINER) {
                    tjFeed = this.trackBrisCode ? this._toteDataService.currentRaceDate().pipe(switchMap(raceDate =>
                                                      this._trainerJockeyDetailsDataService.getTrainerDetails(this.trainerId, this.trackBrisCode, raceDate)
                                                  ))
                                                : this._trainerJockeyDetailsDataService.getTrainerDetails(this.trainerId);
                } else if (statsType === this.STATS_TYPE_JOCKEY)    {
                    if (this.jockeyChange)  {
                        this.error = true;
                        this.errorMessage = this._translateService.translate('jockey-change', 'trainer-jockey-details-errors');
                        this._changeDetector.detectChanges();
                        this.initializeBreadCrumbs(this.jockeyName);
                        return;
                    }
                    tjFeed = this.trackBrisCode ? this._trainerJockeyDetailsDataService.getJockeyDetails(this.jockeyId, this.trackBrisCode)
                                                : this._trainerJockeyDetailsDataService.getJockeyDetails(this.jockeyId);
                }
                if (isCombo) {
                    const comboFeed = this._toteDataService.currentRaceDate().pipe(switchMap(raceDate =>
                        this._trainerJockeyDetailsDataService.getComboDetails(this.jockeyId, this.trainerId, this.trackBrisCode, raceDate)
                    ));

                    combineLatest([tjFeed, comboFeed]).pipe(
                        map(([sTrainer, sCombo]) => {
                            const tjD: TrainerJockeyDetails = new TrainerJockeyDetails(sTrainer, sCombo as IJockeyTrainerInfo);
                            return tjD;
                            })
                    ).subscribe(
                        (x) => {
                            this.setData(x);
                        },
                        (err2) => {
                            this.error = true;
                            this.errorMessage = this._translateService.translate('ws-response-error', 'trainer-jockey-details-errors');
                            this._changeDetector.detectChanges();
                        }
                    );
                } else {
                    tjFeed.subscribe(
                        (x) => {
                            this.setData(new TrainerJockeyDetails(x));
                        },
                        (err2) => {
                            this.error = true;
                            this.errorMessage = this._translateService.translate('ws-response-error', 'trainer-jockey-details-errors');
                            this._changeDetector.detectChanges();
                        }
                    )
                }
            }  else {
                this.error = true;
                this.errorMessage = this._translateService.translate('ws-response-error', 'trainer-jockey-details-errors');
                this._changeDetector.detectChanges();
                this.initializeBreadCrumbs(this.jockeyName);
                return;
            }
        } catch (err) {
            this.error = true;
            this.errorMessage = this._translateService.translate('bad-request', 'trainer-jockey-details-errors');
            this._changeDetector.detectChanges();
        }
    }

    public toggleAllStats() {
        this._eventService.logClickEvent(
            this.reportType === this.STATS_TYPE_JOCKEY
                ? EventClickType.PROGRAM_JOCKEY_DETAILS_MORE
                : EventClickType.PROGRAM_TRAINER_DETAILS_MORE
        );

        if ( !this.showWholeAllStats ) {
            this.allStatsToggleLabel = this.DISPLAY_TOGGLE_LABEL_LESS;
            this.showWholeAllStats = true;
            this.displayedAllStats = this.trainerJockeyDetails.allStats;
        } else {
            this.showWholeAllStats = false;
            this.allStatsToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
            this.displayedAllStats = this.getShortList(this.trainerJockeyDetails.allStats, this.MIN_ROWS_DISPLAY);
        }

        this._changeDetector.detectChanges();
    }

    public getShortList(longList: any[], maxLimit: number): any[] {
        if (maxLimit > 0 && maxLimit < longList.length) {
            return longList.slice(0, maxLimit);
        } else {
            return longList;
        }
    }

    /**
    * This will capitalize first letter of each word in string
    */
    public properString(input: string): string {
        return (!!input) ? input.split(' ').map(function(wrd) {return wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase(); }).join(' ') : '';
    }
}
