import { PastPerformanceModule } from 'app/shared/past-performances/past-performances.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';

import { LiveTicketComponent } from './live-ticket/live-ticket.component';
import { ClassicViewComponent } from './classic-view/classic-view.component';
import { SharedWageringViewsModule } from 'app/wagering/shared/wagering-views/wagering-views.module';
import { ProgramSharedModule } from 'app/shared/program/program-shared.module';
import { BetPadSharedModule } from 'app/shared/betpad/betpad-shared.module';
import { BetSlipModule } from 'app/shared/bet-slip/bet-slip.module';
import { BetsModule } from 'app/shared/bets/bets.module';

import { CheckioboxModule } from '@cdux/ng-fragments';
import { TuxCommonModule } from 'app/shared/common/common.module';
import { EventSessionModule, RunnerListPipe, WagerModule } from '@cdux/ng-common';

@NgModule({
    imports: [
        ClickOutsideModule,
        CommonModule,
        SharedWageringViewsModule,
        ProgramSharedModule,
        BetsModule,
        BetPadSharedModule,
        BetSlipModule,
        CheckioboxModule,
        TuxCommonModule,
        PastPerformanceModule,
        EventSessionModule,
        WagerModule
    ],
    declarations: [
        ClassicViewComponent,
        LiveTicketComponent
    ],
    exports: [
        ClassicViewComponent
    ],
    providers: [
        RunnerListPipe
    ]
})
export class ClassicModule {}
