import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class MatchingFieldValidator {
    public static createValidator(field: string, confirmField: string, caseSensitive: boolean = false) {
        return (control: AbstractControl) => {
            const fieldControl = control.get(field);
            const confirmControl = control.get(confirmField);
            return !!fieldControl && !!confirmControl && (caseSensitive ? fieldControl.value === confirmControl.value : fieldControl.value.toUpperCase() === confirmControl.value.toUpperCase()) ?
                null : { mismatch: true };
        }
    }
}
