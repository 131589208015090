<main class="tux-framework-container" [class.full-screen-footer]="navPanelOpen">
    <ng-container *ngIf="fullPageDrawerFT">
        <cdux-full-screen-drawer></cdux-full-screen-drawer>
    </ng-container>
    <header id="cdux-header" class="tux-top">
        <cdux-header></cdux-header>
    </header>
    <cdux-tournaments-selection-bar class="cdux-tournaments-selection-bar"></cdux-tournaments-selection-bar>
    <div class="tux-body" id="cdux-body">
	<div class="tux-main-content full-width">
        <router-outlet></router-outlet>
    </div>
    </div>
    <div #headerNavPanel></div>
    <footer id="cdux-footer" class="tux-bottom">
        <div class="footer">
            <cdux-footer [navPanelOpen]="navPanelOpen"></cdux-footer>
        </div>
        <div class="sub-footer">
            <div #footerNavPanel></div>
        </div>
    </footer>
</main>
