import { EventTrackingService } from './../../event-tracking/services/event-tracking.service';
import { Injectable } from '@angular/core';
import { of, interval, Subscription, timer, merge, fromEvent, Observable } from 'rxjs';
import { bufferTime, filter, finalize, flatMap, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CduxMediaToggleService, ModalRef, ModalService } from '@cdux/ng-platform/web';
import { NotificationComponent } from '../notification.component';
import { ConfigurationDataService, enumConfigurationStacks, EventClickType, WindowRefService } from '@cdux/ng-common';
import { ToastRef, ToastService } from '@cdux/ng-fragments';

interface IActivityConfig {
    inactivityTimeout: number;
    timeoutRefresh: number;
}

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    private static readonly defaultTimeout = 180000;
    private static readonly defaultModalTimeout = 60000;
    private static readonly config_keys = ['tux_inacitivity_timeout', 'tux_post_timeout_refresh'];

    private _config;
    private _notificationRef: ModalRef<NotificationComponent> | ToastRef;
    private _isIdle: boolean;
    public eventClickType = EventClickType;

    constructor(private _eventTrackingService: EventTrackingService,
                private _modalService: ModalService,
                private _windowRef: WindowRefService,
                private _configService: ConfigurationDataService,
                private _mediaService: CduxMediaToggleService,
                private _toastService: ToastService) {}

    public startActivityTimer(): Observable<any> {
        if (!this._notificationRef) {
            const configObs = this._getConfig();
            const eventsObs = merge(
                fromEvent(window, 'mousemove'),
                fromEvent(window, 'mouseup'),
                fromEvent(document, 'keyup'),
                fromEvent(document, 'touchend')
            );

            return configObs.pipe(
                take(1),
                flatMap(config => {
                    // buffer user events into an array, check every 5 seconds
                    return eventsObs.pipe(
                        bufferTime(5000),
                        // if empty and not currently monitoring, begin inactivity monitor
                        filter(arr => !arr.length && !this._isIdle && !this._notificationRef),
                        tap(() => this._isIdle = true),
                        // countdown until user event cancels observable, or modal is displayed to user
                        switchMap(() => interval(config.inactivityTimeout).pipe(
                            takeUntil(eventsObs),
                            tap(() => this._openIdleNotification()),
                            // when inner observable completes on user action, reset isIdle, outer observable resumes
                            finalize(() => this._isIdle = false)
                        )));
                })
            );
        }
    }

    private _getConfig(): Observable<IActivityConfig> {
        if (!!this._config) {
            return of(this._config);
        } else {
            return this._configService.getConfiguration(enumConfigurationStacks.TUX, ActivityService.config_keys).pipe(map(config => {
                this._config = {
                    inactivityTimeout: !!config['tux_inacitivity_timeout'] ? (+config['tux_inacitivity_timeout']  * 60 * 1000) : ActivityService.defaultTimeout,
                    timeoutRefresh: !!config['tux_post_timeout_refresh'] ? (+config['tux_post_timeout_refresh']  * 60 * 1000) : ActivityService.defaultModalTimeout
                };
                return this._config;
            }))
        }
    }

    private _startRefreshTimer(): Subscription {
        return timer(this._config.timeoutRefresh).pipe(take(1)).subscribe(() => this._refresh());
    }

    private _openIdleNotification(): void {
        if (!this._notificationRef) {
            if (this._mediaService.query('phone')) {
                this._openToastNotification();
            } else {
                this._openIdleModal();
            }
        }
    }

    private _openIdleModal() {
        const timerSub = this._startRefreshTimer();
        if (!this._notificationRef) {
            this._notificationRef = this._modalService.open(NotificationComponent, {
                disableClose: true,
                context: {
                    heading: 'Are you still there?',
                    message: 'Your session has timed out after ' +  ((this._config.inactivityTimeout / 1000) / 60) + ' minutes of inactivity',
                    actionText: 'Refresh',
                    eventType: this.eventClickType.SESSION_IDLE,
                    close: () => { this._close(timerSub) },
                    action: () => { this._refresh() }
                }
            });
        }
    }

    private _openToastNotification() {
        const timerSub = this._startRefreshTimer();
        this._notificationRef = this._toastService.open('Your session has timed out after ' +  ((this._config.inactivityTimeout / 1000) / 60) + ' minutes of inactivity', {
            panelClass: ['warning', 'warning-action'],
            action: 'Refresh',
            hideCheck: true,
            actionFn: () => {
                this._eventTrackingService.logClickEvent(this.eventClickType.SESSION_IDLE);
                this._refresh();
            }
        });

        this._notificationRef.afterClosed.pipe(take(1)).subscribe(() => {
            this._close(timerSub);
        });
    }

    private _refresh() {
        this._windowRef.nativeWindow.location.reload();
    }

    private _close(timerSub: Subscription) {
        timerSub.unsubscribe();
        this._modalService.closeAll();
        this._notificationRef = null;
    }
}
