import { OnInit, Component, Input } from '@angular/core';
import { IW2GTransaction } from '@cdux/ng-common';


@Component({
    selector: 'cdux-taxable-transaction',
    templateUrl: './taxable-transaction.component.html',
    styleUrls: ['./taxable-transaction.component.scss']
})
export class TaxableTransactionComponent implements OnInit {
    @Input() public transaction: IW2GTransaction;
    @Input() public index: number;

    public wagerType: string;
    public grossWinnings: number;
    public withHoldings: number;
    public netGains: number;

    ngOnInit() {

        this.wagerType = this.transaction.wagerType;
        // Convert currency values to number and run arithmetic
        this.grossWinnings = Number(this.transaction.grossWinningAmount);
        this.withHoldings = Number(this.transaction.federalWithHoldings) + Number(this.transaction.stateTaxWithHeldAmount);
        this.netGains = this.grossWinnings - this.withHoldings;
    }

    public printTransaction(): void {
        const filename = '/w2g_' + this.transaction.winDate;
        window.open('/adw/w2g/forms/' + this.transaction.id + filename);
    }
}
