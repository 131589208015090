import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { iif, Subject, Subscription } from 'rxjs';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    enumFeatureToggle,
    enumTrackType,
    FeatureToggleDataService,
    GetRunnerDetailsDataService,
    IFavoriteRunnerNew,
    RunnerDetails,
    ToteDataService,
    IOddsTrend,
    TranslateService,
} from '@cdux/ng-common';
import { CduxRequestError } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { CduxRouteUtil } from 'app/shared';
import { HeaderService } from 'app/shared/header/services/header.service';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';

@Component({
    selector: 'cdux-summary-details',
    templateUrl: './summary-details.component.html',
    styleUrls: ['./summary-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryDetailsComponent implements OnInit, OnDestroy, IDetails {

    public runnerInfo: RunnerDetails = null;
    public favoriteData: IFavoriteRunnerNew;

    public trackType: enumTrackType;
    public brisCode: string;
    public raceNumber: number;
    public programNumber: string;
    public horseId: number;
    private activeLeg = 0;

    public errorMessage: string;
    public error: boolean = false;
    public isPhone: boolean = false;
    public oddsTrendArray: IOddsTrend;
    public oddsTrendEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ODDS_TREND);

    // expose enum to template
    public ETrackType = enumTrackType;

    private mediaSub: Subscription;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _changeDetector: ChangeDetectorRef,
        private _featureToggleService: FeatureToggleDataService,
        private _headerService: HeaderService,
        private _mediaService: CduxMediaToggleService,
        private _runnerDetailDataService: GetRunnerDetailsDataService,
        private _toteDataService: ToteDataService,
        private _translateService: TranslateService
    ) {
    }

    public ngOnInit() {
        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.

        this.mediaSub = this._mediaService.registerQuery('phone').subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });

        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum,
                        view: params.view,
                        horseId: params.horseId
                    };

                    this.init(options);
                }
            }
        );
    }

    public ngOnDestroy() {
        this._headerService.setDefaultHeader();
        if (this.mediaSub) { this.mediaSub.unsubscribe(); }
        this._destroy.next();
        this._destroy.complete();
    }

    public getRunnerDetail() {
        const raceNum = this.activeLeg ? this.raceNumber + this.activeLeg : this.raceNumber;

        this._toteDataService.currentRaceDate(this._toteDataService.NO_POLLING).pipe(
            switchMap(
                (raceDate: string) => iif(
                    () => this.trackType === enumTrackType.HARN,
                    this._runnerDetailDataService.getRunnerStats(this.brisCode, this.trackType, raceDate, raceNum, this.programNumber),
                    this._runnerDetailDataService.getRunnerDetails(this.horseId, this.brisCode, raceNum, raceDate)
                )
            ),
            first()
        ).subscribe((data: RunnerDetails) => {
            this.runnerInfo = data;
            this._headerService.updateHeader({ staticContent: this.runnerInfo.runnerInfo.horseName });

            this.favoriteData = this.runnerInfo.runnerInfo.generateNewFavorite();

            this._changeDetector.detectChanges();
        }, (error: CduxRequestError) => {
            this.error = true;
            this.errorMessage = this._translateService.translate('ws-response-error', 'runner-details-errors');
            this._changeDetector.detectChanges();
        });
    }

    public init(options: IDetailsOptions) {
        this.horseId = options.horseId;
        this.trackType = options.trackType;
        this.oddsTrendArray = options.oddsTrendArray;

        // Horse ID check to short circuit showing an error message.
        // Currently for TBred we need this value.
        // Once we switch to the new bds stats service (which harness currently uses) we shouldn't need to check for
        // horseId.
        if (this.horseId) {
            this.getRunnerDetail();
        } else if (this.trackType === enumTrackType.HARN) {
            this.getRunnerDetail();
        } else {
            this.error = true;
            this.errorMessage = this._translateService.translate('bad-request', 'runner-details-errors');
        }
    }
}
