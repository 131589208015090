export enum WagerEventTypeEnum {
    ERROR_DISMISSED,
    RACE_NAVIGATION,
    RACE_NAVIGATION_INVALID,
    REPEAT_WAGER_SUBMISSION_ERROR,
    WAGER_SUBMISSION_PROCESSING,
    WAGER_SUBMISSION_SUCCESS,
    WAGER_SUBMISSION_ERROR,
    WAGER_SAVE_PROCESSING,
    WAGER_SAVE_SUCCESS,
    WAGER_SAVE_ERROR,
    WAGERING_DISABLED,
    WAGER_INVALID,
    WAGER_VALID,
    WAGER_NO_VALUE
}
