import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';
import { PlayerGroupsService } from '@cdux/ng-common';

@Injectable()
export class GroupRouteGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _playerGroupService: PlayerGroupsService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let allowNavigation: boolean = false;
        if (!!route.data && !!route.data['groups']) {
            for (let i = 0; i < route.data['groups'].length; i++) {
                if (this._playerGroupService.inPlayerGroup(route.data['groups'][i], false)) {
                    allowNavigation = true;
                    break;
                }
            }
        }
        if (!allowNavigation) {
            this._router.navigate([ DEFAULT_REDIRECT_PATH ]);
        }
        return allowNavigation;
    }
}
