<div class="link todays-bets-menu-item"
     [class.is-active]="isActive"
     [class.success]="isSuccess">
    <div class="todays-bets-menu-number">
      {{counter}}
    </div>
    <!-- We force a &nbsp; if we don't have our bet count yet so the
             menu item doesn't jump around between initial render and the
             bet count showing up. -->
    <span *ngIf="counter === null">&nbsp;</span>
    <span *ngIf="counter !== null" class="todays-bets-menu-label">BETS</span>
</div>
