import { Observable, ReplaySubject, of, merge, Subject, EMPTY } from 'rxjs';
import {
    finalize,
    takeUntil,
    switchMap,
    catchError,
    take,
    distinctUntilChanged,
    startWith,
    filter
} from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { ITodaysRace, ITrackBasic, TrackService, enumTrackType } from '@cdux/ng-common';
import { enumDropdownStates } from '@cdux/ng-fragments';

import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

export class RaceListRequestHandler extends CduxRxJSBuildingBlock<any, ITodaysRace[]> {
    protected _stream: Observable<ITodaysRace[]>;

    /** CONTROLS **/
    private _raceNavChange: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>();
    private _dropdownStateChange: Subject<enumDropdownStates> = new Subject<enumDropdownStates>();
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _todaysRacesService: TodaysRacesBusinessService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavChange.complete();
        this._dropdownStateChange.complete();
    }

    public updateRaceNav(track: ITrackBasic) {
        this._raceNavChange.next(track);
    }

    public updateDropdownState(state: enumDropdownStates) {
        this._dropdownStateChange.next(state);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = this._raceNavChange.pipe(
            distinctUntilChanged((prevTrack, currTrack) => TrackService.isSameTrack(prevTrack, currTrack)),
            this._switchToRaceListStream(),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        );
    }

    /** CUSTOM OPERATORS **/
    private _switchToRaceListStream() {
        return switchMap((basicTrack: ITrackBasic) => {
            return merge(
                // Get the race list initially every time the track changes
                this._getRaceListObs(basicTrack),
                // Race list polling stream which pauses when the dropdown is closed
                this._dropdownOpenStream(basicTrack)
            );
         });
    }
    /** END CUSTOM OPERATORS **/

    /** CUSTOM OBSERVABLES **/
    private _dropdownOpenStream(basicTrack: ITrackBasic): Observable<ITodaysRace[]> {
        return this._filteredDropdownState().pipe(
            switchMap((state) => {
                if (state === enumDropdownStates.OPEN) {
                    return this._getRaceListObs(basicTrack, true);
                } else {
                    return EMPTY;
                }
            })
        );
    }

    private _filteredDropdownState(): Observable<enumDropdownStates> {
        return this._dropdownStateChange.pipe(
            filter((state) => state !== enumDropdownStates.NONE),
            startWith(enumDropdownStates.CLOSED),
            distinctUntilChanged()
        );
    }

    private _getRaceListObs(basicTrack: ITrackBasic, poll: boolean = false): Observable<ITodaysRace[]> {
        const brisCode: string = !!basicTrack && !!basicTrack.BrisCode ? basicTrack.BrisCode : null;
        const trackType: enumTrackType = !!basicTrack && !!basicTrack.TrackType ? basicTrack.TrackType : null;
        const raceListObs: Observable<ITodaysRace[]> = this._todaysRacesService.getTodaysRaces(brisCode, trackType, true).pipe(
            catchError((err) => of([]))
        );
        return poll ? raceListObs : raceListObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/
}
