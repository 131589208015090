<ng-container *ngIf="isLoading; else iframeTemplate">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
</ng-container>

<ng-template #loadingTemplate>
    <div class="funding-container">
        <div class="alt-light-bg funding-content">
            <i class="icon--mazooma"></i>
            <p>Please wait while we redirect you to login with your bank credentials.</p>
            <div class="loader-dots">
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #iframeTemplate>
    <iframe
        *ngIf="iframeUrl"
        #mazoomaFrame
        id="ezbankFrame"
        [src]="iframeUrl"
        class="tux-iframe"
        (load)="monitorIFrameSource()"
    ></iframe>
</ng-template>

