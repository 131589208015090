import {
    BasicBetType,
    enumTrackType,
    ITrackBasic,
    MultiRaceExoticBetType,
    TracksDataService
} from '@cdux/ng-common';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { catchError, finalize, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, ReplaySubject } from 'rxjs';


export class BetTypesRequestHandler extends CduxRxJSBuildingBlock<any, (BasicBetType | MultiRaceExoticBetType)[]> {
    protected _stream: Observable<(BasicBetType | MultiRaceExoticBetType)[]>;
    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(private _tracksDataService: TracksDataService) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public updateBetTypes(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = this._raceNavigationChanges.pipe(
            switchMap((track) => {
                return this._getBetTypes(track);
            }),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        ) as Observable<(BasicBetType | MultiRaceExoticBetType)[]>
    }

    /** CUSTOM OBSERVABLES **/
    private _getBetTypes(basicTrack: ITrackBasic): Observable<(BasicBetType | MultiRaceExoticBetType)[]> {
        const brisCode: string = !!basicTrack && !!basicTrack.BrisCode ? basicTrack.BrisCode : null;
        const trackType: enumTrackType = !!basicTrack && !!basicTrack.TrackType ? basicTrack.TrackType : null;
        const raceNum: number = !!basicTrack && !!basicTrack.RaceNum ? basicTrack.RaceNum : null;

        const betTypesObs: Observable<(BasicBetType | MultiRaceExoticBetType)[]> = this._tracksDataService.todaysRaceBetTypes(brisCode, trackType, raceNum).pipe(
            catchError((err) => of([]))
        );
        return betTypesObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/

    /** CUSTOM OPERATORS **/
    /** END CUSTOM OPERATORS **/
}
