import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@cdux/ng-fragments';

import { EzbankIframeComponent } from './ezbank-iframe.component';

@NgModule({
    imports: [
        CommonModule,
        LoadingModule,
    ],
    declarations: [
        EzbankIframeComponent,
    ],
    providers: [],
    exports: [
        EzbankIframeComponent,
    ],
})
export class EzbankIframeModule { }
