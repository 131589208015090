<div *cduxLoading="'betSlipContainer'; strategy:'overlay'; component:loadingDotsComponent;">
    <section class="bet-slip-container">
        <!--<h2 class="bet-slip-container__title">Bet Slip</h2>-->
        <div [ngSwitch]="showBets && betSlipInitialized">
            <div *ngSwitchCase="false" class="bet-slip-empty">
                <p *ngIf="betSlipInitialized">Your bet slip is empty.</p>
            </div>
            <div *ngSwitchCase="true">
                <div *ngFor="let bet of bets; let $index = index; let $last = last;">
                    <cdux-bet
                        id="bet-slip-bet-{{$index+1}}"
                        class="{{'bet-' + ($index+1)}}"
                        [index]="$index"
                        [clearFundingError]="bet.id === betIdToSubmit"
                        [bet]="bet"
                        [forceSubmitAnimation]="$last && submitSuccess"
                        [forceSSNInput]="$last && requireSSN"
                        [showSSNInput]="betToShowSsnCollection === bet.id"
                        [externallySubmitted]="submitting"
                        [betShareEnabled]="betShareEnabled"
                        [conditionalWageringEnabled]="isConditionalWagerToggleOn"
                        [programView]="programView"
                        (submissionInProgress)="toggleSingleSubmission($event)"
                        (updateTempBetShares)="updateTempBetShares($event)"
                        (displaySsnCollection)="displaySsnCollection($event)"
                        [mtpConfig]="mtpConfig"
                        [programView]="programView"
                        [showSaddleCloths]="showSaddleCloths"
                    ></cdux-bet>
                </div>
            </div>
        </div>
    </section>
</div>
