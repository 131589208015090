import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { CduxObjectUtil, EventClickType } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { SidebarSearchContainerComponent } from 'app/shared/program-search/components/sidebar-search/sidebar-search.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';

@Component({
    selector: 'cdux-small-glass-search-menu-item',
    template: `
        <div class="link search-container">
            <div
                id="search-menu-item-button"
                class="search-menu-item"
                [ngClass]="{'is-active' : isActive}"
                (cduxTap)="toggleSearch()">
                <i class="icon-magnifying-glass-light"></i>
            </div>
        </div>
    `,
    styleUrls: ['./small-glass-search-menu-item.component.scss']
})
export class SmallGlassSearchMenuItemComponent implements OnInit, OnDestroy {

    public isActive: boolean;

    private _destroy: Subject<boolean> = new Subject();

    constructor(
        private _sidebarService: SidebarService,
        private _eventTrackingService: EventTrackingService
    ) {}

    ngOnInit() {
        this._sidebarService.onPortalStateChanged.pipe(
            tap(portalState => this.isActive = portalState.isOpen && CduxObjectUtil.deepGet(portalState, 'portalComponent.properties.navTarget') === MenuItemsEnum.RUNNER_SEARCH),
            takeUntil(this._destroy)
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroy.next(true);
        this._destroy.complete();
    }

    public toggleSearch(): void {
        if ( this.isActive ) {
            this._sidebarService.close(true);
        } else {
            this._sidebarService.loadComponent(SidebarSearchContainerComponent.getSidebarComponent(), null, {
                clearHistory: true
            });
            // Log click event SEARCH_RUNNER_BUBBLE_OPEN
            this._eventTrackingService.logClickEvent(EventClickType.SEARCH_RUNNER_BUBBLE_OPEN);
        }
    }
}
