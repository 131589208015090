import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

const SUPPRESS_PRELOAD = true;
@Injectable({
    providedIn: 'root'
})
export class AppPreloadStrategy extends PreloadingStrategy {
    private _checked: boolean = false;
    private _suppressPreload: boolean = false; // We should default to preloading, unless otherwise specified.
    constructor(private route: ActivatedRoute) {
        super();
    }
    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        if (!this._checked) {
            this._suppressPreload = this._checkForPreloadSuppression(this.route.snapshot);
            this._checked = true;
        }
        if (this._suppressPreload === SUPPRESS_PRELOAD) {
            return of(null);
        }
        return fn();
    }

    private _checkForPreloadSuppression(route: ActivatedRouteSnapshot): boolean {
        if (route.data && route.data.suppressPreload) {
            return SUPPRESS_PRELOAD;
        }
        if (route.firstChild) {
            return this._checkForPreloadSuppression(route.firstChild);
        }
        return !SUPPRESS_PRELOAD;
    }
}
