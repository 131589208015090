import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuxCommonModule } from '../common/common.module';

import { CarouselComponent } from './carousel.component';
import { CarouselItemComponent} from './carousel-item.component';

@NgModule({
    declarations: [
        CarouselComponent,
        CarouselItemComponent,
    ],
    imports: [
        CommonModule,
        TuxCommonModule,
    ],
    exports: [
        CarouselComponent,
        CarouselItemComponent,
    ],
})
export class CarouselModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<CarouselModule> {
        return {
            ngModule: CarouselModule,
            providers: [

            ],
        }
    }
}
