<div class="bets-container">
    <div class="bets-content"
         #scrollingArea
         cduxMoreContent
         (hasMoreContent)="toggleScrollingIndicator($event)">
        <cdux-bet-slip [betIdToSubmit]="returningBetId"
                       [betShareId] = "betShareId"
                       [showSaddleCloths] = "true"
                       (betCount)="onBetCountUpdate($event)"
                       (totalAmount)="onTotalAmountUpdate($event)"
                       (valid)="onValidUpdate($event)"
                       (resetScroll)="resetScroll()"
                       (sizeChange)="sizeChange()"
                       (submitAllInProgress)="toggleSubmitAllButton($event)"
                       (onInitialize)="betSlipInitialized()"
                       (onChangeDetection)="forceChangeDetection()"></cdux-bet-slip>
    </div>

    <cdux-more-content-indicator *cduxMediaToggle="'desk-to-phone'"
                                 [moreContent]="showScrollingIndicator"></cdux-more-content-indicator>
    <footer class="bet-slip-submit-all"
            [class.is-inactive]="betSlipValid"
            *ngIf="showFooter">
        <cdux-bet-slip-footer (submit)="triggerSubmitAll()"
                              [valid]="betSlipValid"
                              [betCount]="betSlipBetCount"
                              [totalAmount]="betSlipTotalAmount"
                              [submitAllInProgress]="submitAllInProgress"></cdux-bet-slip-footer>
    </footer>
</div>
