// TO DO update DOMAIN
import { ENVIRONMENT, ENVIRONMENTS, AFFIDS } from '@cdux/ng-core';

const affId = AFFIDS.DKHORSE;
const username = 'my_tux';

export const environment: ENVIRONMENT = {
    environment: ENVIRONMENTS.PRODUCTION,
    production: true,
    affiliateId: affId,
    domain: 'dkhorse.com',
    username: username,
    setSecureCookie: true,
    accountMenuConfig: {
        hideRecallTodaysBets: true,
        hideRewards: true,
        hideTaxDocuments: false,
        hideDetailedAccountHistory: true,
        hideStableAlert: true
    },
    thirdPartyConfig: {
        'Box': {
            'Folder': '193114260273',
            // this is a placeholder and won't work if simple layout is ever toggled off
            'Token': 'aBcDeFgHiJk',
            'Token_Simplified_Upload': '3f67b3ad358e49f38659ee79396bd6b9',
        },
        'RightNow': {
            'Subdomain': 'NEWAFFILIATE--tst',
            'Category': 10
        },
        'ZendeskChatURL': 'https://static.zdassets.com/ekr/snippet.js?key=8fb81674-fd40-4939-ab6f-29f24adc0023'
    },
    appConfig: {
        servicesBaseUrl: '',
        username: username,
        environmentUsername: username,
        platformId: 'TUX',
        affiliateId: affId,
        clientIp: '0.0.0.0',
        outputType: 'json'
    },
    regLoginFooterTag: true
};
