import { Component, ChangeDetectorRef, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    Menu,
    CduxMenuDataService,
    MenuItemType,
    FeatureToggleDataService,
    JwtSessionService,
    LegacySupportService,
    PlayerGroupsService,
    enumPlayerGroup,
    enumFeatureToggle
} from '@cdux/ng-common';
import { ModalService } from '@cdux/ng-platform/web';
import { ZendeskChatService } from '@cdux/ng-fragments';

import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { TourService } from 'app/shared/tour/services';
import { MyAccountContainerComponent } from 'app/shared/my-account/my-account-container/my-account-container.component';
import { RewardsContainerComponent } from 'app/shared/my-account/rewards/components/rewards-container/rewards-container.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { CduxRouteUtil } from 'app/shared/common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FallBackHeaderMenuItems } from 'app/shared/header/data/fallBackMenuItems'

export enum enumMenuURLToken {
    DOMAIN = 'DOMAIN',
    TS_B64 = 'TS_B64'
}

@Component({
    selector: 'cdux-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy {

    private _subscriptions: Subscription[] = [];

    public menu: Menu[];

    public isLoggedIn: boolean;

    public MenuItemType = MenuItemType;

    /* Consider refactoring _sidenavRoutes in deeplink interceptor into a standalone config to be single source of truth
     * for both header and sidebar if more than only a handful of entries are added to the action map.
     */
    private readonly _actionMap = { 'rewards': [MyAccountContainerComponent, RewardsContainerComponent] }

    constructor(
        private _environment: ENVIRONMENT,
        private _menuService: CduxMenuDataService,
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _legacySupportService: LegacySupportService,
        private _tourService: TourService,
        private _sidebarService: SidebarService,
        private _featureToggleService: FeatureToggleDataService,
        private _eventTrackingService: EventTrackingService,
        private _modalService: ModalService,
        private _sessionService: JwtSessionService,
        private _playerGroupService: PlayerGroupsService,
        private _zendeskChatService: ZendeskChatService) { }

    ngOnInit() {
        this.getMenuFromService();

        this.isLoggedIn = this._sessionService.isLoggedIn();
        this._subscriptions.push(
            this._sessionService.onAuthenticationChange.subscribe((loggedIn) => {
                this.isLoggedIn = loggedIn;
            })
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    /**
     * Opens up Zendesk Messenger messaging window
     */
    private _launchZeMessenger() {
        if (this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ZENDESK_CHAT)
            && this._zendeskChatService.isLoaded()) {
            this._zendeskChatService.setMessengerVisibility(true);
            this._zendeskChatService.openZeMessenger();
        }
    }


    private _processMenu(menu: Menu[]): Menu[] {

        const sortFn = (a: Menu, b: Menu) => {
            return a.order - b.order;
        };

        // sort function for recursively sorting submenu
        const sortMenuTree = (unsortedMenu: Menu[]) => {
            unsortedMenu.sort(sortFn);
            unsortedMenu.forEach((menuItem) => {
                if (menuItem.submenu && menuItem.submenu.length) {
                    // recursively sort the tree
                    sortMenuTree(menuItem.submenu);
                }
            });
            return unsortedMenu;
        }

        // find and filter feature toggle items recursively
        const filterFeatureToggles = (menuItem: Menu) => {
            if (menuItem.submenu && menuItem.submenu.length > 0) {
                // recursively filter the tree
                menuItem.submenu = menuItem.submenu.filter(filterFeatureToggles);
            }
            if (menuItem.toggles) {
                // if the menu item has toggles, make sure they are to be displayed
                return Object.keys(menuItem.toggles).every((toggle) => {
                    return this._featureToggleService.isFeatureToggleOn(toggle) === menuItem.toggles[toggle];
                });
            } else {
                return true;
            }
        }

        // filter feature toggled items, sort and update route to link type.
        return sortMenuTree(menu.filter(filterFeatureToggles).map((m) => this.updateDirectUrlPath(m)));
    }

    public getMenuFromService() {
        this._menuService.getMenu(this._environment.affiliateId, 'TUX').pipe(first()).subscribe((menu: Menu[]) => {
            this.menu = this._processMenu(menu);
        }, err => {
            this.menu = this._processMenu(FallBackHeaderMenuItems);
        });
    }

    public menuItemHover(menuItem, state: boolean) {
        menuItem.submenuOpen = state;
        this._changeDetector.detectChanges();
    }

    public menuItemClick(event: Event, menuItem: Menu) {

        if (menuItem.clickEventType) {
            this._eventTrackingService.logClickEvent(menuItem.clickEventType)
        }

        if (event.type.startsWith('touch') && menuItem.submenu.length >= 1) {
            if (menuItem.submenuOpen) {
                menuItem.submenuOpen = false;
            } else {
                this.closeAllSubmenus();
                menuItem.submenuOpen = true;
            }
            return;
        }

        switch (menuItem.type) {
            case MenuItemType.ACTION:
                this.action(menuItem);
                break;
            case MenuItemType.CHAT:
                this._launchZeMessenger();
                break;
            case MenuItemType.LEGACY:
                this._legacySupportService.setCookies();
                window.location.href = this.replaceTokens(menuItem.route);
                break;
            case MenuItemType.LINK:
                // Only match against the top-most parent route, excluding the application prefix
                const requestedTopLevelRoute = CduxRouteUtil.parseTopLevelMenuRoute(menuItem.route);

                // if the top-level route is in the tree, try navigating to it
                if (CduxRouteUtil.matchesTopLevelRoute(requestedTopLevelRoute, this._router)) {
                    this._router.navigate([menuItem.route.replace(CduxRouteUtil.getBaseHref(), '')]);
                } else {
                    // route isn't in the tree, assume it's leaving
                    // perform a replace if the url contains a variable that needs to be replaced
                    window.location.href = this.replaceTokens(menuItem.route);
                }
                break;
            case MenuItemType.NEWTAB:
                window.open(this.replaceTokens(menuItem.route), '_blank');
                break;
            case MenuItemType.TOUR:
                this._tourService.openTourModal();
                break;
        }

        this._modalService.closeAll();

        this.closeAllSubmenus();

        this._changeDetector.detectChanges();
    }

    public action(menuItem: Menu) {
        if (!!this._actionMap[menuItem.route]) {
            const sidebarView = this._actionMap[menuItem.route];
            for (let i = 0; i < sidebarView.length; i++) {
                this._sidebarService.loadComponent(sidebarView[i].getSidebarComponent(), sidebarView[i].getHeaderComponent(), {
                    /**
                     * we want to clear just in case the rewards button was
                     * selected multiple times
                     */
                    clearHistory: i === 0 ? true : false
                });
            }
        }
    }

    public closeAllSubmenus() {
        // close all submenus
        this.menu.forEach((item) => {
            // submenuOpen is only a runtime property
            item.submenuOpen = false;
        });
    }

    public checkAuthDisplay(menuItem: Menu): boolean {
        if ((menuItem.authRequired === false && this.isLoggedIn === false) || // anon only menu item
            (menuItem.authRequired === true && this.isLoggedIn === true) || // logged in only menu item
            menuItem.authRequired === null) { // menu item is always displayed
            return true;
        } else {
            return false;
        }
    }

    public checkPlayerGroupDisplay(menuItem: Menu): boolean {
        if (menuItem.playerGroupId) {
            return menuItem.playerGroupId.split(',').every((groupId) => {
                return this._playerGroupService.inPlayerGroup(parseInt(groupId, 10) as enumPlayerGroup, false);
            });
        } else {
            return true;
        }
    }

    public updateDirectUrlPath(menuItem: Menu) {
        switch (menuItem.type) {
            case MenuItemType.ACTION:
                menuItem.directUrl = '?action=' + menuItem.route;
                break;
            case MenuItemType.LINK:
                const requestedTopLevelRoute = CduxRouteUtil.parseTopLevelMenuRoute(menuItem.route);
                if (CduxRouteUtil.matchesTopLevelRoute(requestedTopLevelRoute, this._router)) {
                    menuItem.directUrl = menuItem.route.replace(CduxRouteUtil.getBaseHref(), '');
                } else {
                    menuItem.directUrl = this.replaceTokens(menuItem.route);
                }
                break;
            case MenuItemType.CHAT:
                menuItem.directUrl = null;
                break;
            default:
                menuItem.directUrl = this.replaceTokens(menuItem.route);
                break;
        }

        if (menuItem.submenu && menuItem.submenu.length) {
            menuItem.submenu.forEach((mi) => this.updateDirectUrlPath(mi));
        }

        return menuItem;
    }

    public replaceTokens(input: string): string {
        return input.replace(/{([^}]*)}/g, (match, token) => {
            switch (token.toUpperCase()) {
                case enumMenuURLToken.DOMAIN:
                    return location.host;
                case enumMenuURLToken.TS_B64:
                    return btoa(Date.now().toString());
                default: // invalid or empty
                    return '';
            }
        });
    }
}
