<div>
    <form *ngIf="form && !showIframe && !fundingMethodDetails.locked" [formGroup]="form">
        <div class="ts-message error" *ngIf="lockout">
            <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
          <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
        </div>
        <ng-template #chat><cdux-chat></cdux-chat></ng-template>

        <div *ngIf="!lockout">
            <header class="funding-content_header">
                <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
                <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="deposit-close-btn"></i>
                <h1>Bank Transfer</h1>
                <div class="funding-type-icon">
                    <i class="icon--mazooma-large-white"></i>
                </div>
            </header>

            <cdux-funding-input-amount
                id="depositAmount"
                formControlName="amount"
                [inputType]="OPERATION"
                [fundType]="fundingMethodDetails.fundType"
                [min]="minDeposit"
                [max]="maxDeposit"
                [amount]="amount"
                [offerId]="offerId"
                [phoneEnabled]="phoneEnabled"
                [displayStyle]="enumDepositDisplayStyle.FULL_PAGE">
            </cdux-funding-input-amount>

            <div class="ts-message error" *ngIf="!!errorMessage">{{ errorMessage }}</div>
            <div class="ts-message success" *ngIf="successfulDeposit && !errorCode">{{ successMessage }}</div>

            <div class="funding-content_details-block">

                <p>You will login with your bank credentials.</p>

                <div class="total">
                    <span class="label">Total:</span>
                    <span class="amount">{{ form.get('amount').value | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
                </div>

                <div class="loginbank--btn large"
                     (click)="navigateToEzBank()"
                     [class.disabled]="form.invalid || form.get('amount').value <= 0">
                  <ng-container *ngIf="!isFetchingParams; else loadingTemplate">
                    <span>Login to bank</span>
                  </ng-container>
                  <ng-template #loadingTemplate>
                    <div class="gbl-button-loader">
                      <div class="gbl-loader-dots">
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                        <i class="dots-animated"></i>
                      </div>
                    </div>
                  </ng-template>
                </div>
            </div>
        </div>
    </form>

    <cdux-ezbank
        [ngStyle]="{'height': (isSmallGlass) ? '100vh' : '1000px'}"
        *ngIf="showIframe && !redirectToggledOn"
        [depositAmount]="depositAmount"
        [displayStyle]="enumDepositDisplayStyle.FULL_PAGE"
        (depositTransactionComplete)="processEzBankResults()">
    </cdux-ezbank>
</div>
