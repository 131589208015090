import { Pipe, PipeTransform } from '@angular/core';

import { enumTrackType } from '@cdux/ng-common';
import { WageringUtilBusinessService } from '../services/wagering-util.business.service';

/**
 * This pipe transforms a given betting interest and track type into a css class.
 * The term "betting interest" is used to discourage backporting this pipe for
 * use with program numbers. Betting interest is now being passed in backend models
 * to avoid calulating the first field program number or coupled program number.
 *
 */
@Pipe({
    name: 'cduxSaddleClothClass'
})
export class SaddleClothClassPipe implements PipeTransform {
    constructor(private _wageringUtil: WageringUtilBusinessService) {
    }

    transform(bettingInterest: number, trackType: enumTrackType) {
        return this._wageringUtil.getSaddleClothClass(trackType, bettingInterest);
    }
}
