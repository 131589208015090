import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    OnInit
} from '@angular/core';
import {
    BasicBetType,
    enumBetModifier,
    enumFeatureToggle,
    enumRaceStatus,
    EventsService,
    FeatureToggleDataService,
    ISelectedBetAmount,
    ITrack,
    ITrackBasic,
    MultiRaceExoticBetType,
    TrackService,
    WagerState
} from '@cdux/ng-common';
import { DisplayModeEnum } from '../../shared/common/enums/display-mode.enum';
import { ViewSectionEnum } from '../views/enums/view-section.enum';
import { ISelectionUpdate } from '../../shared/program/interfaces/selection-update.interface';
import { ErrorBarError } from '../../shared/bet-slip/interfaces/error-bar-error.interface';
import { QuickPickSidebarComponent } from '../../shared/bets/components/quick-pick-sidebar/quick-pick-sidebar.component';
import { AbstractWageringViewEventComponent } from '../../shared/wager-views/components';
import { WagerEvent, WageringView } from '../../shared/wager-views/interfaces';
import { CdkPortal } from '@angular/cdk/portal';
import { FooterService } from '../../shared/footer/services/footer.service';
import { MobileFooterConfig } from '../../shared/footer/models';
import { WageringViewEnum } from '../views/enums/wagering-view.enum';
import { EventKeys } from 'app/shared';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { enumVideoBarType } from '../video/enums/video-bar-type.enum';
import { TodaysRaceStatus } from '../tv/classes/todaysRaceStatus';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { TvTrackInterface } from '../video/interfaces/tv-track.interface';
import { WagerEventTypeEnum } from 'app/shared/wager-views';

@Component({
    selector: 'cdux-mobile-view',
    templateUrl: './mobile-view.component.html',
    styleUrls: ['./mobile-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileViewComponent extends AbstractWageringViewEventComponent implements WageringView, OnDestroy, OnInit {

    @Output() public sectionChange: EventEmitter<ViewSectionEnum> = new EventEmitter<ViewSectionEnum>();
    @Output() public viewChange: EventEmitter<WageringViewEnum> = new EventEmitter<WageringViewEnum>();
    @Output() public selectTrackFromList: EventEmitter<ITrackBasic[]> = new EventEmitter<ITrackBasic[]>();
    @Output() public selectBetTypeFromList: EventEmitter<BasicBetType[] | MultiRaceExoticBetType[]> = new EventEmitter<BasicBetType[] | MultiRaceExoticBetType[]>();
    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() public updateEntrySelection: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();
    @Output() public resetEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public stashEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public restoreEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public dismissError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public outboundEvents: EventEmitter<WagerEvent> = new EventEmitter<WagerEvent>();

    @Input() public section: ViewSectionEnum;
    @Input() public view: WageringViewEnum;
    private _wagerState: WagerState;
    @Input() public set wager(value: WagerState) {
        if (!TrackService.isSameTrack(value?.basicTrack, this._wagerState?.basicTrack) ||
            value?.basicTrack?.RaceNum !== this._wagerState?.basicTrack?.RaceNum) {
            this.quickPickSidebar?.close();
        }
        this._wagerState = value;
        if (this._determineIfEntriesAreSelected() && this.areEntriesSelected === false) {
            this._setFooter(this.mobileTotalBar);
        }
        this.areEntriesSelected = this._determineIfEntriesAreSelected();
        if (this._wagerState && this._wagerState.basicTrack) {
            this._todaysRaceGetter.updateTrack(this._wagerState.basicTrack);
        }
    }
    public get wager(): WagerState {
        return this._wagerState;
    }
    @Input() public fundingEnabled: boolean = false;
    @Input() public racesRestrictedTo: enumRaceStatus[];
    @Input() public error: ErrorBarError;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.MOBILE;

    @Input() public set raceStatus(raceStatus: enumRaceStatus) {
        this._raceStatus = raceStatus;
        this.isWagerableRace = TrackService.isWagerableRace(raceStatus);
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }
    private _raceStatus: enumRaceStatus;

    @ViewChild('mobileTotalBar', { static: true }) public mobileTotalBar: CdkPortal;

    @ViewChild(QuickPickSidebarComponent)
    public quickPickSidebar: QuickPickSidebarComponent;
    public isQuickPickSidebarEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.QUICK_PICK);

    public isWagerableRace: boolean = false;
    public areEntriesSelected: boolean = false;
    public showVideo: boolean = false;
    private _destroy: Subject<boolean> = new Subject();
    public videoBarType = enumVideoBarType;
    private _todaysRaceGetter;
    public liveRaceNumber: number;
    public selectedITrack: TvTrackInterface;
    public showAfterSubmission: boolean = false;

    constructor(private _footerService: FooterService,
        private _changeDetector: ChangeDetectorRef,
        private _eventsService: EventsService,
        private _featureToggleService: FeatureToggleDataService,
        private _todaysRacesService: TodaysRacesBusinessService) {
        super();
        this._todaysRaceGetter = new TodaysRaceStatus(this._todaysRacesService.getTodaysTracks(true, true))
    }

    ngOnInit() {
        this._eventsService.on(EventKeys.VIDEO_PRIMARY_CLOSE).pipe(takeUntil(this._destroy))
            .subscribe(() => { this.showVideo = false; this._changeDetector.markForCheck() });
        this._eventsService.on(EventKeys.VIDEO_PRIMARY_OPEN).pipe(takeUntil(this._destroy))
            .subscribe(() => { this.showVideo = true; this._changeDetector.markForCheck() });

        this._todaysRaceGetter.listen().subscribe(data => {
            this.liveRaceNumber = data.RaceNum;
            this.selectedITrack = { track: Object.assign({}, data, this.wager.basicTrack) as ITrack, liveRaceNumber: this.liveRaceNumber };
        });

        if (this.section === ViewSectionEnum.RUNNER_BALLOT) {
            this.sectionChange.emit(ViewSectionEnum.PROGRAM_BASIC);
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this._destroy.next();
        this._destroy.complete();
        this._setFooter(null);
        this._todaysRaceGetter.kill();
    }

    public scroll($event) {
        this._eventsService.broadcast(EventKeys.MAIN_SCROLLING, $event);
    }

    public get displayTotalBar(): boolean {
        return ((this.areEntriesSelected || this.showAfterSubmission ) && this.raceStatus === enumRaceStatus.OPEN);
    }

    private _setFooter(footer: CdkPortal) {
        const config = new MobileFooterConfig({
            componentOrTemplateRef: footer,
        });
        this._footerService.updateFooter(config);
        this._changeDetector.markForCheck();
    }

    private _determineIfEntriesAreSelected(): boolean {
        if (this._wagerState && this._wagerState.bettingInterests) {
            return this._wagerState.bettingInterests.reduce((p, c) => p || !!c && c.length > 0, false);
        }
        return false;
    }

    public handleEvent($event: WagerEvent) {
        this.outboundEvents.emit($event);
        switch ($event.type) {
            case WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS:
            case WagerEventTypeEnum.REPEAT_WAGER_SUBMISSION_ERROR:
                this.showAfterSubmission = true;
                break;
            case WagerEventTypeEnum.WAGER_NO_VALUE:
                this.showAfterSubmission = false;
                this._footerService.updateFooter(null);
                break;
            default:
                break;
        }
    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        this.quickPickSidebar?.close();
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        this.quickPickSidebar?.close();
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this.quickPickSidebar?.close();
        this.updateBetAmount.emit(betAmount);
    }

    public onUpdateEntrySelections(selection: ISelectionUpdate[]): void {
        this.quickPickSidebar?.close();
        this.updateEntrySelection.emit(selection);
    }
}
