<cdux-dropdown class="program-nav-track-dropdown" [(ngModel)]="currentTrack" [disabled]="!availability"
    (stateChange)="onDropdownStateChange($event)" (ngModelChange)="setCurrent($event)"
    [allowCloseOnExternalClick]="displayMode === DisplayModeEnum.COMPACT" [dropDownIdentifier]="dropDownIdentifier">
    <div dropdown-header [cduxLogClick]="eventClickType.RACE_NAV_TRACK_DROPDOWN">
        <div class="menu-tab">
            <h2 class="title-label">
                <cdux-track-group id="track_dropdown" *ngIf="currentTrack" [track]="currentTrack">
                    <span class="track-name">{{ currentTrack | formatTrackName }}</span>
                </cdux-track-group>
                <ng-container *ngIf="trackGroups?.generalTracks?.length > 0 && !currentTrack">Select
                    Track</ng-container>
                <ng-container *ngIf="trackGroups?.generalTracks?.length === 0 && !currentTrack">No Tracks
                    Available</ng-container>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>
    <div dropdown-list [class]="getClass()">
        <div class="dropdown-filter-header">
            <ng-container *ngIf="!tournamentSelected">
                <div id="track-list-filter-all" class="filter-option smallest"
                    [cduxLogClick]="eventClickType.TRACK_LIST_TYPE_FILTER_ALL"
                    [ngClass]="{'selected': trackTypeFilter === null}" (click)="filterTrackType(null)">
                    All
                </div>
                <div id="track-list-filter-tbred" class="filter-option smallest" *ngIf="hasTBred"
                    [cduxLogClick]="eventClickType.TRACK_LIST_TYPE_FILTER_TBRED"
                    [ngClass]="{'selected': trackTypeFilter === enumTrackType.TBRED}"
                    (click)="filterTrackType(enumTrackType.TBRED)">
                    Thoroughbred
                </div>
                <div id="track-list-filter-harn" class="filter-option smallest" *ngIf="hasHarn"
                    [cduxLogClick]="eventClickType.TRACK_LIST_TYPE_FILTER_HARN"
                    [ngClass]="{'selected': trackTypeFilter === enumTrackType.HARN}"
                    (click)="filterTrackType(enumTrackType.HARN)">
                    Harness
                </div>
                <div id="track-list-filter-grey" class="filter-option smallest" *ngIf="hasGrey"
                    [cduxLogClick]="eventClickType.TRACK_LIST_TYPE_FILTER_GREY"
                    [ngClass]="{'selected': trackTypeFilter === enumTrackType.GREY}"
                    (click)="filterTrackType(enumTrackType.GREY)">
                    Greyhound
                </div>
            </ng-container>
            <div id="track-list-sort" class="sort-option" [cduxLogClick]="eventClickType.TRACK_LIST_SORT"
                (click)="$event.stopPropagation(); sortTracks()">
                {{ sortStyle === trackSortStyle.MTP ? 'MTP' : 'ABC' }}<div class="sort-option-icon">&nbsp;</div>
            </div>
        </div>


        <div *ngIf="tournamentSelected">
            <div class="header-cancelled">Tournament Eligible</div>
            <div *ngIf="true; then mainTracksView"></div>
        </div>

        <!-- Favorite Tracks -->
        <ng-container *ngIf="sectionDisplay?.favorite && !tournamentSelected">
            <div id="track_dropdown_header_favorite_track" class="header-cancelled"
                *ngIf="displayTrackGroups?.favoriteTracks?.length > 0">Favorites</div>
            <cdux-dropdown-option *ngFor="let track of displayTrackGroups?.favoriteTracks; trackBy:trackById"
                id="track_dropdown_item_favorite_{{track.brisCode}}" [value]="track" (click)="logTrackClick(track)">
                <div class="option-label">
                    <cdux-track-group [track]="track.ITrack">
                        <cdux-mtp-badge mtp-badge *ngIf="sortStyle === trackSortStyle.MTP" [source]="track.ITrack"
                            [showMinutes]="true"></cdux-mtp-badge>
                        <div class="superscript__track-dropdown">
                            <span id="trackname_{{track.brisCode}}">{{ track.formattedTrackName || (track.ITrack |
                                formatTrackName) }}</span>
                        </div>
                        <div class="superscript">
                            <cdux-my-fav [id]="'favorites-runner-badge-'+track.brisCode+'-race-'+track.currentRaceNumber"
                                *ngIf="displayFavRunnerBadge(track)"></cdux-my-fav>
                            <span *ngIf="carryoverMap && carryoverMap.generalTracksMap[track.brisCode]"
                                class="carryover-icon"></span>
                        </div>
                        <div class="subscript">R{{ track.currentRaceNumber }}</div>
                        <cdux-mtp-badge *ngIf="sortStyle === trackSortStyle.ALPHABETICAL" [source]="track.ITrack"
                            [showMinutes]="true"></cdux-mtp-badge>
                    </cdux-track-group>
                </div>
            </cdux-dropdown-option>
        </ng-container>

        <!-- Featured Tracks -->
        <ng-container *ngIf="sectionDisplay?.featured && isFeaturedShown">
            <div id="track_dropdown_header_featured_track" class="header-cancelled"
                *ngIf="displayTrackGroups?.featuredTracks?.length > 0 || tournamentSelected">Featured</div>
            <div *ngIf="!tournamentSelected; else tournamentFeaturedView">
                <cdux-dropdown-option *ngFor="let track of displayTrackGroups?.featuredTracks; trackBy:trackById"
                    id="track_dropdown_item_featured_{{track.brisCode}}" [value]="track"
                    (click)="logFeaturedTrackClick(track)">
                    <div class="option-label">
                        <cdux-track-group [track]="track.ITrack">
                            <cdux-mtp-badge mtp-badge *ngIf="sortStyle === trackSortStyle.MTP" [source]="track.ITrack"
                                [showMinutes]="true"></cdux-mtp-badge>
                            <div class="superscript__track-dropdown">
                                <span id="trackname_{{track.brisCode}}">{{ track.formattedTrackName || (track.ITrack |
                                    formatTrackName) }}</span>
                            </div>
                            <div class="superscript">
                                <cdux-my-fav
                                    [id]="'featured-runner-badge-'+track.brisCode+'-race-'+track.currentRaceNumber"
                                    *ngIf="displayFavRunnerBadge(track)"></cdux-my-fav>
                                <span *ngIf="carryoverMap && carryoverMap.featuredTracksMap[track.brisCode]"
                                    class="carryover-icon"></span>
                            </div>
                            <div class="subscript">R{{ track.currentRaceNumber }}</div>
                            <cdux-mtp-badge *ngIf="sortStyle === trackSortStyle.ALPHABETICAL" [source]="track.ITrack"
                                [showMinutes]="true"></cdux-mtp-badge>
                        </cdux-track-group>
                    </div>
                </cdux-dropdown-option>
            </div>
        </ng-container>

        <!-- Valid Tracks -->
        <ng-container *ngIf="sectionDisplay?.general && !tournamentSelected; ">
            <div id="track_dropdown_header_track" class="header-cancelled">All Races</div>
            <div *ngIf="!tournamentSelected; then mainTracksView"></div>
        </ng-container>

        <!-- Cancelled Tracks -->
        <ng-container *ngIf="sectionDisplay?.canceled">
            <div id="track_dropdown_header_cancelled_track" class="header-cancelled"
                *ngIf="displayTrackGroups?.canceledTracks?.length > 0">Cancelled</div>
            <cdux-dropdown-option *ngFor="let track of displayTrackGroups?.canceledTracks; trackBy:trackById"
                id="track_dropdown_item_cancelled_{{track.brisCode}}" [value]="track" (click)="logTrackClick(track)">
                <div class="option-label">
                    <cdux-track-group [track]="track.ITrack">
                        <cdux-mtp-badge mtp-badge *ngIf="sortStyle === trackSortStyle.MTP" [source]="track.ITrack"
                            [showMinutes]="false"></cdux-mtp-badge>
                        <div class="superscript__track-dropdown">{{ track.formattedTrackName || (track.ITrack |
                            formatTrackName) }}</div>
                        <div class="subscript">All Races</div>
                        <cdux-mtp-badge *ngIf="sortStyle === trackSortStyle.ALPHABETICAL" [source]="track.ITrack"
                            [showMinutes]="false"></cdux-mtp-badge>
                    </cdux-track-group>
                </div>
            </cdux-dropdown-option>
        </ng-container>

    </div>

    <ng-template #tournamentFeaturedView>
        <div class="tournaments-tracklist-status">
            You need to switch to your primary {{siteName}} account to view featured races.
        </div>
    </ng-template>

    <ng-template #mainTracksView>
        <cdux-dropdown-option *ngFor="let track of displayTrackGroups?.generalTracks; trackBy:trackById"
            id="track_dropdown_item_{{track.brisCode}}" [value]="track" (click)="logTrackClick(track)">
            <div class="option-label">
                <cdux-track-group [track]="track.ITrack">
                    <cdux-mtp-badge mtp-badge *ngIf="sortStyle === trackSortStyle.MTP" [source]="track.ITrack"
                        [showMinutes]="true"></cdux-mtp-badge>
                    <div class="superscript__track-dropdown">
                        <span id="trackname_{{track.brisCode}}">{{ track.formattedTrackName || (track.ITrack |
                            formatTrackName) }}</span>
                    </div>
                    <div class="superscript">
                        <cdux-my-fav [id]="'allraces-runner-badge-'+track.brisCode+'-race-'+track.currentRaceNumber"
                            *ngIf="displayFavRunnerBadge(track)"></cdux-my-fav>
                        <span *ngIf="carryoverMap && carryoverMap.generalTracksMap[track.brisCode]"
                            class="carryover-icon"></span>
                    </div>
                    <div class="subscript">R{{ track.currentRaceNumber }}</div>
                    <cdux-mtp-badge *ngIf="sortStyle === trackSortStyle.ALPHABETICAL" [source]="track.ITrack"
                        [showMinutes]="true"></cdux-mtp-badge>
                </cdux-track-group>
            </div>
        </cdux-dropdown-option>
    </ng-template>
</cdux-dropdown>