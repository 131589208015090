<section *ngIf="favoritesToday.length">
  <header>Running Today</header>
  <div class="tile-container">
    <cdux-favorite-tile-track *ngFor="let fav of favoritesToday; trackBy:trackingTrack" [favoriteData]="fav" isRunningToday="true"></cdux-favorite-tile-track>
  </div>
</section>

<section *ngIf="favoritesElse.length">
  <header>Manage Tracks</header>
  <div class="tile-container">
    <cdux-favorite-tile-track *ngFor="let fav of favoritesElse; trackBy:trackingTrack" [favoriteData]="fav"></cdux-favorite-tile-track>
  </div>
</section>

<cdux-favorites-manager-empty id="no-favorite-tracks" *ngIf="favoritesEmpty"></cdux-favorites-manager-empty>
