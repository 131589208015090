import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { EventSessionModule, TranslateModule, WagerModule } from '@cdux/ng-common';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from '../common/common.module';

import { SidebarModule } from '../sidebar/sidebar.module';
import { MenuItemsModule } from '../menu-items/menu-items.module';

import { FooterComponent } from './components/footer.component';
import { PopUpModule } from '../pop-up/pop-up.module';
import { UpNextModule } from '../up-next/up-next.module';

@NgModule({
    imports: [
        RouterModule,
        PortalModule,
        CommonModule,
        CduxFormsModule,
        CduxMediaModule,
        SidebarModule,
        TuxCommonModule,
        EventSessionModule,
        WagerModule,
        PopUpModule,
        TranslateModule,
        MenuItemsModule,
        UpNextModule,
    ],
    declarations: [
        FooterComponent,
    ],
    exports: [
        FooterComponent,
        UpNextModule,
    ],
})
export class FooterModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<FooterModule> {
        return {
            ngModule: FooterModule,
            providers: [
            ],
        }
    }
}
