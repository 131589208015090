<div class="video-player" [class.tv]="onTV">
    <span #videoContainer></span>
    <div class="flash-unavailable-wrapper" *ngIf="!isFlashAvailable">
        <div class="video-player-image"></div>
        <div class="overlay--video-player">
            <i class="icon--triangle--video-player"></i>
            <h3 class="header">
                Playback Requires Flash
                <span><a href="https://get.adobe.com/flashplayer/" target="_blank">Download the current Adobe Flash
                        Player to watch live video.</a></span>
            </h3>
        </div>
    </div>
    <div class="video-unavailable-wrapper" *ngIf="!isVideoAvailable">
        <div class="video-player-image"></div>
        <div class="overlay--video-player center">
            <h3 class="header">
                <span>Video is currently unavailable for this track.</span>
            </h3>
        </div>
    </div>
</div>
