<div class="nav-panel-content" #content>
    <div class="sidebar-loader" *cduxLoading="loaderNamePrefix + 'sidebarLoader'; strategy:'overlay'; element:element;">
        <div class="sidebar-loader" *cduxLoading="loaderNamePrefix + 'sidebarDotLoader'; strategy:'overlay'; component:loadingDotsComponent; element:element;">

            <div id="sidebar-alert-msg" class="nav-panel-error" *ngIf="(headerMessage | async) as msg">
                {{ msg }}
            </div>

            <cdux-sidebar-header [headerContentComponent]="currentSidebarHeaderComponent" *ngIf="isOpen && hideHeader === false"></cdux-sidebar-header>
            <div class="sidebar-content">
                <ng-template [cdkPortalOutlet]="_portal" (attached)="_onAttached($event)"></ng-template>
            </div>
        </div>
    </div>
</div>


