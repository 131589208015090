import {
    enumRaceStatus,
    enumTrackType,
    IBetNavObject,
    ITodaysRace,
    ITrackBasic,
    ProgramEntry,
    MultiRaceExoticBetType,
    TrackService,
    TracksDataService,
    BasicBetType,
    BetTypeUtil
} from '@cdux/ng-common';
import { Observable, of, ReplaySubject, merge, combineLatest } from 'rxjs';
import {
    catchError,
    finalize,
    switchMap,
    take,
    takeUntil,
    map,
    withLatestFrom,
    distinctUntilChanged,
    share,
    debounceTime,
    filter
} from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { RaceDetailsRequestHandler } from 'app/shared/betpad/classes/race-details-request-handler.class';
import { IProgramEntriesState } from '../interfaces/program-entries-state.interface';

export class EntriesManager extends CduxRxJSBuildingBlock<any, IProgramEntriesState> {
    protected _stream: Observable<IProgramEntriesState>;

    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);

    /**
     * Constructor
     */
    constructor(
        private _tracksDataService: TracksDataService,
        private _betNavStream?: Observable<IBetNavObject>
    ) {
        super();
        this._raceDetailsRequestHandler = new RaceDetailsRequestHandler(_tracksDataService);
        this._init();
    }

    /* EXTERNAL CONTROLS */
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
        this._raceDetailsRequestHandler.updateRaceNavigation(track);
    }
    /* END EXTERNAL CONTROLS */

    /**
     * Initializes the stream.
     */
    protected _init() {
        let entriesObs: Observable<IProgramEntriesState>;
        if (!this._betNavStream) {
            // Simple case. A bet nav stream was not provided, so assume
            // that the selected race is the only one ever polled for entries.
            entriesObs = this._raceDetailsRequestHandler.listen().pipe(
                withLatestFrom(this._raceNavigationChanges),
                filter(([raceStatus, raceNav]) => !!(raceNav && raceStatus)),
                distinctUntilChanged((a, b) => TrackService.isExactTrackObject(a[1], b[1]) && a[0].status === b[0].status),
                switchMap(([raceDetails, raceNav]: [ITodaysRace, ITrackBasic]) =>
                    of(raceNav).pipe(this._switchToSingleRaceEntries(raceDetails.status === enumRaceStatus.OPEN))
                )
            );
        } else {
            // A bet nav stream was provided. It is necessary to listen
            // to both race nav and bet nav changes to set the correct
            // combination of track and leg race numbers in case the user
            // switches to multi-race bet types at any point.
            entriesObs = this._getTrackAndRaceListStream(this._raceNavigationChanges, this._raceDetailsRequestHandler.listen(), this._betNavStream).pipe(
                switchMap(([raceNav, raceDetails, races]: [ITrackBasic, ITodaysRace, number[]]) =>
                    of([raceNav, races]).pipe(this._switchToMultiRaceEntries(raceDetails.status === enumRaceStatus.OPEN))
                )
            );
        }

        this._stream = entriesObs.pipe(
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        );
    }

    /* METHODS */
    /**
     * Compares previous and current bet nav objects to determine
     * if a change occured which will require new entries requests
     * to be made.
     *
     * @param prevBetNavObj
     * @param currBetNavObj
     */
    private _betNavChangeRequiresNewEntries(prevBetNavObj: IBetNavObject, currBetNavObj: IBetNavObject): boolean {
        const isPrevBetTypeMultiRace: boolean = prevBetNavObj.type instanceof MultiRaceExoticBetType;
        const isCurrBetTypeMultiRace: boolean = currBetNavObj.type instanceof MultiRaceExoticBetType;
        if (!isPrevBetTypeMultiRace && isCurrBetTypeMultiRace) {
            // Single -> Multi
            return true;
        } else if (isPrevBetTypeMultiRace && !isCurrBetTypeMultiRace) {
            // Multi -> Single
            return true;
        } else if (isPrevBetTypeMultiRace && isCurrBetTypeMultiRace) {
            // Multi -> Multi
            return true;
        } else {
            // Single -> Single
            return false;
        }
    }
    /* END METHODS */

    /* CUSTOM OPERATORS */
    private _switchToSingleRaceEntries(poll: boolean) {
        return switchMap((track: ITrackBasic) => {
            return this._getSingleRaceEntriesObs(track, poll).pipe(
                // Map to 2-D array so that the manager always outputs
                // an array of entries arrays
                map((entries) => {
                    return {programEntries: [entries], track}
                })
            );
        });
    }

    private _switchToMultiRaceEntries(poll: boolean) {
        return switchMap(([basicTrack, legRaceNumbers]: [ITrackBasic, number[]]) => {
            // Using combineLatest with a debounce instead of a zip because
            // the inner observables don't always emit at the same rate.
            // In particular, when switching to a pick-N, the request manager
            // may immediately replay the last entries value for the selected
            // race before emitting the new entries when the new request finishes.
            // A zip emits strictly sequential pairs, so the selected race entries
            // would always be one poll behind the other races.
            return combineLatest(
                legRaceNumbers.map((race) => {
                    // Need to change the race number on each track object
                    const newBasicTrack = { ...basicTrack, RaceNum: race };
                    return this._getSingleRaceEntriesObs(newBasicTrack, poll);
                })
            ).pipe(
                debounceTime(300),
                map(entries => {
                    return {programEntries: entries, track: basicTrack};
                })
            );
        });
    }

    /**
     * Returns a map operator which checks the bet type and
     * returns the correct combination of track and race
     * numbers.
     */
    private _mapBetTypeToTrackAndRaceNumbers() {
        return map(([betType, selectedTrack]: [BasicBetType | MultiRaceExoticBetType, ITrackBasic]) => {
            let legRaceNumbers: number[];
            if (betType instanceof MultiRaceExoticBetType) {
                legRaceNumbers = betType.legRaceNumbers;
            } else {
                legRaceNumbers = [selectedTrack.RaceNum];
            }
            return [selectedTrack, legRaceNumbers] as [ITrackBasic, number[]];
        });
    }
    /* END CUSTOM OPERATORS */

    /* CUSTOM OBSERVABLES */
    /**
     * This returns a stream where each item is a combination of a
     * track and a list of race numbers. It is created from the merge
     * of separate streams originating with either a race nav change
     * or a bet nav change. This is necessary when it is possible to
     * switch between single and multi-race wagers.
     *
     * @param raceNavStream
     * @param betNavStream
     */
    private _getTrackAndRaceListStream(raceNavStream: Observable<ITrackBasic>, raceDetailsStream: Observable<ITodaysRace>, betNavStream: Observable<IBetNavObject>): Observable<[ITrackBasic, ITodaysRace, number[]]> {
        const raceNavDerivedTrackAndRaces = raceNavStream.pipe(
            filter((raceNav) => !!raceNav),
            map((selectedTrack) => {
                return [selectedTrack, [selectedTrack.RaceNum]] as [ITrackBasic, number[]];
            })
        );

        const betNavDerivedTrackAndRaces = betNavStream.pipe(
            filter((betNav) => !!betNav),
            distinctUntilChanged((prevBetNavObj, currBetNavObj) => {
                // This distinct acts as a filter for bet nav changes. Only changes
                // that require new entries requests will continue in the stream.
                return !this._betNavChangeRequiresNewEntries(prevBetNavObj, currBetNavObj);
            }),
            map((betNav) => betNav.type),
            withLatestFrom(raceNavStream),
            this._mapBetTypeToTrackAndRaceNumbers()
        );

        return combineLatest([raceDetailsStream, merge(raceNavDerivedTrackAndRaces, betNavDerivedTrackAndRaces)]).pipe(
            map(([race, [track, races]]) => <[ITrackBasic, ITodaysRace, number[]]>[ track, race, races ]),
            filter(([ track, race, races ]) => !!(track && race && races)),
            // This distinct prevents extra entries calls from being made on init,
            // and when a race nav change triggers a transition from a multi to a
            // single race wager.
            distinctUntilChanged(([prevTrack, prevRaceDetails, prevRaceNumbers], [currTrack, currRaceDetails, currRaceNumbers]) => {
                return TrackService.isSameTrack(prevTrack, currTrack) && (prevRaceDetails.status === currRaceDetails.status) && BetTypeUtil.isSameLegRaceNumbers(prevRaceNumbers, currRaceNumbers);
            })
        );
    }

    /**
     * Method for building a race entries observable.
     *
     * @param basicTrack
     * @param poll
     */
    private _getSingleRaceEntriesObs(basicTrack: ITrackBasic, poll: boolean): Observable<ProgramEntry[]> {
        const brisCode: string = !!basicTrack && !!basicTrack.BrisCode ? basicTrack.BrisCode : null;
        const trackType: enumTrackType = !!basicTrack && !!basicTrack.TrackType ? basicTrack.TrackType : null;
        const raceNum: number = !!basicTrack && !!basicTrack.RaceNum ? basicTrack.RaceNum : null;

        const raceProgramEntriesObs: Observable<ProgramEntry[]> = this._tracksDataService.todaysRaceEntries(brisCode, trackType, raceNum, poll).pipe(
            // Catch any error from the data service and return an empty array. This keeps the
            // subscription to betNav/raceNav changes alive so that subsequent entries requests are made.
            catchError((err) => of([] as ProgramEntry[]))
        );

        return poll ? raceProgramEntriesObs : raceProgramEntriesObs.pipe(take(1));
    }
    /* END CUSTOM OBSERVABLES */
}
