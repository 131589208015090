import { Component, OnDestroy, ChangeDetectorRef, ViewChild, ViewContainerRef, OnInit } from '@angular/core';

import {
    DetectionService,
    IVideoStreamHl2s,
    FeatureToggleDataService,
    enumConfigurationStacks,
    ConfigurationDataService,
} from '@cdux/ng-common';

import { take } from 'rxjs/operators';
import { IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';
import { RCNBaseVideoFeed } from './rcn-base-video-feed.class';
import { RCNVideoStreamSource } from './rcn-stream-sources/rcn-video-stream-source.service';
import { VideoFeedError } from '../video-feed.interface';

import { VideoProviderService } from '../video-provider.service';
import { VideoService } from 'app/wagering/video/services/video.service';


const HL2S_PLAYER_ID = 'rcn-hl2s-params';

// For height and width, use "100%" to keep the parent's size.
// videofit options: "letterbox | fill | crop | original | resize"
@Component({
    selector: 'cdux-rcn-hl2s-video-feed',
    template: `<div id="rcn-hl2s-container" #streamContainer>
  <ins id="${HL2S_PLAYER_ID}" *ngIf="stream"
    [attr.data-referer]="stream.referer"
    [attr.data-hash]="stream.hash"
    [attr.data-timestamp]="stream.timestamp"
    [attr.data-stream]="stream.stream"
    [attr.data-speed]="stream.speed"
    [attr.data-hd]="stream.hd === true ? 1 : 0"
    [attr.data-usr]="stream.user"
    [attr.data-autoplay]="autoPlay === true ? 1 : 0"
    data-width="100%"
    data-height="100%"
    [attr.data-muted]="muted === true ? 1 : 0"
    data-hidecontrols="0"
    data-videofit="letterbox">
  </ins>
</div>`
})
export class RCNHL2SVideoFeedComponent extends RCNBaseVideoFeed implements OnInit, OnDestroy {
    @ViewChild('streamContainer', { read: ViewContainerRef })
    protected container: ViewContainerRef;

    public autoPlay = true;
    public muted: boolean;
    public stream: IVideoStreamHl2s;

    private player_src: string = null;
    private videoTagLoadInterval;

    constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected detectionService: DetectionService,
        protected streamSource: RCNVideoStreamSource,
        protected videoProviderService: VideoProviderService,
        private featureToggleService: FeatureToggleDataService,
        private configurationDataService: ConfigurationDataService,
        private videoService: VideoService
    ) {
        super(changeDetectorRef, videoProviderService, streamSource);

        // Disable autoplay for mobile browsers, which won't allow it
        if (this.detectionService.isMobileDevice()) {
            this.autoPlay = false;
        }

        this.muted = this.videoService.getMuted();
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public ngOnDestroy () {
        if (window.hasOwnProperty('hl2functions') && typeof window['hl2functions'] === 'object') {
            window['hl2functions'].removePlayer(HL2S_PLAYER_ID);
        } else {
            console.warn('hl2functions missing');
        }
        this.stream = null; // redundant?

        if (this.videoTagLoadInterval) {
            window.clearInterval(this.videoTagLoadInterval);
        }
    }

    protected handleStreamData (streamData: IVideoStreamResponses) {
        this.stream = JSON.parse(JSON.stringify(this.videoProviderService.currentStream)) as IVideoStreamHl2s;

        /*
         * Tell the template to process updates so that the
         * ngIf condition on what will become paramsElement is
         * satisfied by the just-assigned this.stream. Without
         * this, paramsElement is likely to be undefined because
         * it hasn't been added to the DOM yet.
         */
        this.changeDetectorRef.detectChanges();

        /*
         * We need a SCRIPT tag to load the JS library from RCN.
         * However, Angular strips SCRIPT tags from templates. The
         * workaround is to dynamically add one to the DOM.
         *
         * TODO: This is unsafe, could be exploited by MITM attack.
         */
        // a false feature toggle will avoid injecting the script and throw an unavailable error


        this._setRCNPlayerURL().then(() => {
            const HL2S_SCRIPT_ID = 'rcn-hl2s-script';
            if (this.featureToggleService.isFeatureToggleOn('HL2S_VIDEO')) {
                if (!document.getElementById(HL2S_SCRIPT_ID)) { // one time
                    const scriptElement = document.createElement('script');
                    scriptElement.id = HL2S_SCRIPT_ID;
                    scriptElement.src = this.player_src;
                    document.head.appendChild(scriptElement);
                }

                if (this.videoTagLoadInterval) {
                    window.clearInterval(this.videoTagLoadInterval);
                }
                let intervalCount = 0;
                this.videoTagLoadInterval = window.setInterval(() => {
                    intervalCount++;
                    const videoPlayer = document.querySelector('#' + HL2S_PLAYER_ID + ' video');
                    if (videoPlayer) {
                        window.clearInterval(this.videoTagLoadInterval);
                        videoPlayer.addEventListener('volumechange', (change) => this.videoService.setMuted(change.target['muted']));
                    } else if (intervalCount >= 120) { // don't leave the timer running indefinitely -- runs for a minute
                        window.clearInterval(this.videoTagLoadInterval);
                    }
                },
                500
                );
            } else {
                this.errorSubject.next(VideoFeedError.VIDEO_UNAVAILABLE)
            }
        });
    }


    private _setRCNPlayerURL() {
        return new Promise<void>((resolve) => {
            if (this.player_src) {
                resolve();
                return;
            }
            this.configurationDataService.getConfiguration(enumConfigurationStacks.TUX, 'rcn_player_lib_url')
            .pipe(
                take(1)
            ).subscribe(
                (url) => {
                    this.player_src = url.rcn_player_lib_url;
                    resolve();
                }
            );
        });
    }

    // no-op overrides
    public pause() {}
    public start() {}
    public stop() {}
}
