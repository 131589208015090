import { enumPoolType } from '@cdux/ng-common';
import { enumPoolsSort } from 'app/wagering/shared/pools/enums/pools-sort-columns.enum';
import { PoolSubView } from 'app/wagering/shared/wagering-views/enums/pool-sub-view.enum';
import { ViewSectionEnum } from '../enums/view-section.enum';
import { enumProgramSort } from 'app/shared/program/enums/program-sort-columns.enum';
import { ISortState } from 'app/shared/program/classes/sort-state-handler.class';

export enum ViewStateGroupEnum {
    PROGRAM = 'programGroup',
    POOLS = 'poolsGroup',
    RESULTS = 'resultsGroup',
    STATS = 'statsGroup'
}

export interface ViewStateStore {
    primary: ViewStateGroupEnum | ViewSectionEnum,
    programGroup: {
        section: ViewSectionEnum,
        sortState: ISortState<enumProgramSort | string>
    },
    poolsGroup: {
        section: ViewSectionEnum,
        [ViewSectionEnum.POOLS]: {
            sortState: ISortState<enumPoolsSort | string>,
            subView: PoolSubView
        },
        [ViewSectionEnum.PROBABLES]: {
            poolType: enumPoolType
        },
        [ViewSectionEnum.WILL_PAYS]: {
            sortState: ISortState<enumProgramSort | enumPoolType | string>
        }
    },
    resultsGroup: {
        section: ViewSectionEnum,
    },
    statsGroup: {
        section: ViewSectionEnum,
    }
}
