import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { PlatformService } from '@cdux/ng-common';
import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';

@Injectable()
export class PlatformGuard implements CanActivate {

    constructor(private _router: Router,
                private _platformService: PlatformService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isNativeApp = this._platformService.isNativeApp();
        if (!isNativeApp) {
            this._router.navigate([ DEFAULT_REDIRECT_PATH ]);
        }
        return isNativeApp;
    }
}
