import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { PoolSubView } from '../../enums/pool-sub-view.enum';
import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { enumPoolType, EventClickAttributeType, EventClickType, ToteDataService } from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';

@Component({
    selector: 'cdux-pools-section',
    templateUrl: 'pools-section.component.html',
    styleUrls: ['./pools-section.component.scss']
})
export class PoolsSectionComponent extends AbstractWageringSectionComponent implements OnInit {

    public currentPoolSubView: PoolSubView = PoolSubView.TOTALS;
    public currentProbablePoolType: enumPoolType = enumPoolType.EXACTA;
    public poolSubView = PoolSubView;
    public enumPoolType = enumPoolType;
    public eventClickType = EventClickType;

    @Output() public updateSection = new EventEmitter<ViewSectionEnum>();

    public probablesAllowedPoolTypes: enumPoolType[] = [
        enumPoolType.EXACTA,
        enumPoolType.QUINELLA,
        enumPoolType.DAILY_DOUBLE
    ];

    public probablesAvailablePoolTypes = {};

    constructor(
        private _eventTrackingService: EventTrackingService,
        protected _toteDataService: ToteDataService,
        protected _viewStateService: ViewStateService
    ) {
        super(_toteDataService, _viewStateService);
    }

    ngOnInit() {
        const sectionCache = this._viewStateService.getViewSectionGroupCache(ViewStateGroupEnum.POOLS);
        if (sectionCache && sectionCache !== this.section) {
            this.updateSection.emit(sectionCache);
        }

        if (this._viewStateService.getPoolsSubView()) {
            this.currentPoolSubView = this._viewStateService.getPoolsSubView();
        }

        if (this._viewStateService.getProbablesPoolType()) {
            this.currentProbablePoolType = this._viewStateService.getProbablesPoolType();
        }
    }

    public updateProbablesAvailablePoolTypes(types: enumPoolType[]) {
        this.probablesAvailablePoolTypes = {};
        if (Array.isArray(types)) {
            types.forEach(type => this.probablesAvailablePoolTypes[type] = true);
        }
        if (types.includes(this._viewStateService.getProbablesPoolType())) {
            this.currentProbablePoolType = this._viewStateService.getProbablesPoolType();
        }
    }

    public resetProbablesPoolType() {
        this.currentProbablePoolType = enumPoolType.EXACTA;
        this.probablesAvailablePoolTypes = {};
    }

    public viewPools() {
        this.logClickEvent(ViewSectionEnum.POOLS);
        this.updateSection.emit(ViewSectionEnum.POOLS);
    }

    public viewProbables() {
        this.probablesAvailablePoolTypes = {};
        this.logClickEvent(ViewSectionEnum.PROBABLES);
        this.updateSection.emit(ViewSectionEnum.PROBABLES);
    }

    public viewWillPays() {
        this.logClickEvent(ViewSectionEnum.WILL_PAYS);
        this.updateSection.emit(ViewSectionEnum.WILL_PAYS);
    }

    public viewPoolTotals() {
        this.currentPoolSubView = this.poolSubView.TOTALS;
        this._viewStateService.setPoolsSubView(PoolSubView.TOTALS);
        this.logClickEvent(this.poolSubView.TOTALS);
    }

    public viewPoolPercentages() {
        this.currentPoolSubView = this.poolSubView.PERCENTAGES;
        this._viewStateService.setPoolsSubView(PoolSubView.PERCENTAGES);
        this.logClickEvent(this.poolSubView.PERCENTAGES);
    }

    public viewExacta() {
        this.currentProbablePoolType = enumPoolType.EXACTA;
        this._viewStateService.setProbablesPoolType(enumPoolType.EXACTA);
        this.logClickEvent(enumPoolType.EXACTA);
    }

    public viewDouble() {
        this.currentProbablePoolType = enumPoolType.DAILY_DOUBLE;
        this._viewStateService.setProbablesPoolType(enumPoolType.DAILY_DOUBLE);
        this.logClickEvent(enumPoolType.DAILY_DOUBLE);
    }

    public viewQuinella() {
        this.currentProbablePoolType = enumPoolType.QUINELLA;
        this._viewStateService.setProbablesPoolType(enumPoolType.QUINELLA);
        this.logClickEvent(enumPoolType.QUINELLA);
    }

    public logClickEvent(eventName: string) {
        const ts = Date.now();

        switch (eventName) {
            case ViewSectionEnum.POOLS:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_POOLS, [
                    { attrId: EventClickAttributeType.POOLS_SUB_MENU_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_SUB_MENU_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_SUB_MENU_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_SUB_MENU_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PROBABLES:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_PROBABLES, [
                    { attrId: EventClickAttributeType.PROBABLES_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROBABLES_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROBABLES_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.PROBABLES_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.WILL_PAYS:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_WILL_PAYS, [
                    { attrId: EventClickAttributeType.WILL_PAYS_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.WILL_PAYS_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.WILL_PAYS_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.WILL_PAYS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case this.poolSubView.TOTALS:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_TOTALS, [
                    { attrId: EventClickAttributeType.POOL_TOTALS_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_TOTALS_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_TOTALS_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_TOTALS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case this.poolSubView.PERCENTAGES:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_PERCENTAGES, [
                    { attrId: EventClickAttributeType.POOL_PERCENTAGES_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_PERCENTAGES_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_PERCENTAGES_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.POOL_PERCENTAGES_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumPoolType.EXACTA:
                this._eventTrackingService.logClickEvent(EventClickType.PROBABLES_NAV_EXACTA, [
                    { attrId: EventClickAttributeType.EXACTA_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.EXACTA_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.EXACTA_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.EXACTA_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumPoolType.DAILY_DOUBLE:
                this._eventTrackingService.logClickEvent(EventClickType.PROBABLES_NAV_DAILY_DOUBLE, [
                    { attrId: EventClickAttributeType.DAILY_DOUBLE_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.DAILY_DOUBLE_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.DAILY_DOUBLE_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.DAILY_DOUBLE_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumPoolType.QUINELLA:
                this._eventTrackingService.logClickEvent(EventClickType.PROBABLES_NAV_QUINELLA, [
                    { attrId: EventClickAttributeType.QUINELLA_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.QUINELLA_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.QUINELLA_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.QUINELLA_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            default:
            // We should never get in here, but if we do, that's OK, we won't log any click events
        }
    }
}
