import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@cdux/ng-platform/web';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { ItournamentsNotAvailableModalConfig } from '../../services/tournaments-not-available-modal.service';


@Component({
    templateUrl: 'tournaments-not-available-modal.component.html',
    styleUrls: ['./tournaments-not-available-modal.component.scss']
})
export class TournamentsNotAvailableModalComponent implements OnInit, OnDestroy {
    public modalConfig: ItournamentsNotAvailableModalConfig;

    constructor(
        private _touramentsSessionService: TournamentsSessionService,
        private _modalService: ModalService,
        private _router: Router
    ) { }

    ngOnInit() {}

    public switchToPrimaryAccount() {
        this._touramentsSessionService.setPrimarySiteMode();
        this._modalService.closeAll();
        if (this.modalConfig.destinationPath) {
            if (this.modalConfig.destinationPath.startsWith('https:')){
                const _window: any = (<any>window);
                _window.location.href = this.modalConfig.destinationPath;
            }
            else {
                this._router.navigateByUrl(this.modalConfig.destinationPath);
            }
        }
    }

    public set context(context: any) {
        this.modalConfig = context.config;
    }

    public closeModal() {
        this._modalService.closeAll();
    }
    ngOnDestroy(): void {}
}