<div class="summary-details" *ngIf="runnerInfo">
    <div [ngClass]="{'detail-info-mobile': isPhone, 'detail-info-full': !isPhone}" class="is-open">
        <div class="details-col">
            <div>
                <cdux-favorite-toggle-runner
                  *ngIf="trackType === ETrackType.TBRED"
                  [favoriteData]="favoriteData"
                ></cdux-favorite-toggle-runner>
                <h1 class="horse-name">{{ runnerInfo.runnerInfo?.horseName }}</h1>
            </div>
            <div *ngIf="oddsTrendEnabled && oddsTrendArray?.odds2" class="odds-trend-container">
                <p>ODDS TREND</p>
                <cdux-odds-trend [oddsTrend]="oddsTrendArray"></cdux-odds-trend>
            </div>
        </div>
    </div>
</div>

<div class="summary-details" *ngIf="error">
    <div class="no-details">
        <h1>{{ this.errorMessage }} </h1>
    </div>
</div>

<ng-template #singleCol let-label="label" let-isLink="isLink">
    <tr >
        <td *ngIf="isLink" (click)="togglePP()"><a>{{ label }}</a></td>
        <td *ngIf="!isLink">{{ label }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
</ng-template>
