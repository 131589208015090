<div *ngIf="displayStyle === enumDepositDisplayStyle.SIDEBAR" class="deposit-form-info">
    <div class="input-block">
        <div class="amount-min" [id]="id + '-min'" [class.text-error]="hasError('min')" *ngIf="displayMin">
          MIN
          <br />
          {{ min | currency:'USD':'symbol-narrow':'1.0-2' }}
        </div>
        <input #inputBlock [id]="id" class="deposit--amount amount-entered" [(ngModel)]="value" cduxCurrency maxlength="16" (focus)="onFocus()" (blur)="onBlur()" autocomplete="off" />
            <ng-container *ngTemplateOutlet="inputErrors"></ng-container>

        <div class="enter-amount-border-box">
            <div class="enter-amount-label">Enter Amount</div>
        </div>

        <div class="amount-max" [id]="id + '-max'" [class.text-error]="hasError('max')" *ngIf="displayMax">
          MAX
          <br />
          {{ max | currency:'USD':'symbol-narrow':'1.0-2' }}
        </div>
    </div>
    <div class="amount-preset-wrapper" *ngIf="suggestions">
        <ng-template ngFor let-suggestion [ngForOf]="suggestions">
            <div class="amount-preset" [id]="id + '-suggestionValue-' + suggestion" [class.selected]="isSuggestionSelected(suggestion)" (click)="onSuggestionClick(suggestion)"> ${{ suggestion }} </div>
        </ng-template>
    </div>
</div>

<div *ngIf="displayStyle === enumDepositDisplayStyle.EXPRESS">
    <div class="amount-preset-wrapper" *ngIf="suggestions">
        <ng-template ngFor let-suggestion [ngForOf]="suggestions">
            <div class="amount-preset" [id]="id + '-suggestionValue-' + suggestion" [class.selected]="isSuggestionSelected(suggestion)" (click)="onSuggestionClick(suggestion)"> ${{ suggestion }} </div>
        </ng-template>
    </div>
    <div class="amount-input-block">
        <input #inputBlock [placeholder]="inputFocused ? '' : '0.00'" [id]="id" class="amount-input" [(ngModel)]="value" cduxCurrency [showSymbol]="false" maxlength="8" (focus)="onFocus()" (blur)="onBlur()" autocomplete="off" />
        <label [for]="id" class="amount-min-max" [id]="id + '-min-max'">
          {{ min | currency:'USD':'symbol-narrow':'1.2' }} min /
          {{ max | currency:'USD':'symbol-narrow':'1.2' }} max
        </label>
        <ng-container *ngTemplateOutlet="inputErrors"></ng-container>
    </div>
</div>

<div *ngIf="displayStyle === enumDepositDisplayStyle.FULL_PAGE">
    <div class="enter-amount-label">Enter Amount</div>
    <div class="funding-content_input-block">
        <input #inputBlock [id]="id" class="deposit--amount amount-entered" [class.error]="hasError('min') || hasError('max')" [(ngModel)]="value" cduxCurrency [showSymbol]="false" maxlength="16" (focus)="onFocus()" (blur)="onBlur()" autocomplete="off" />
        <!--symbol--dollar needs to be **immediately** after the input to trigger the white color on $-->
        <span class="symbol--dollar"></span>

        <div class="amount-min" [id]="id + '-min'" [class.text-error]="hasError('min')" *ngIf="displayMin">Min {{ min | currency:'USD':'symbol-narrow':'1.0-2' }}</div>
        <div class="amount-max" [id]="id + '-max'" [class.text-error]="hasError('max')" *ngIf="displayMax">Max {{ max | currency:'USD':'symbol-narrow':'1.0-2' }}</div>
    </div>
    <ng-container *ngTemplateOutlet="inputErrors"></ng-container>
    <div class="funding-content_amount-preset-wrapper" *ngIf="suggestions">
        <ng-template ngFor let-suggestion [ngForOf]="suggestions">
            <div class="amount-preset" [id]="id + '-suggestionValue-' + suggestion" [class.selected]="isSuggestionSelected(suggestion)" (click)="onSuggestionClick(suggestion)"> ${{ suggestion }} </div>
        </ng-template>
    </div>
</div>

<ng-template #inputErrors>
    <div cduxInputError *ngIf="!this.inputFocused && inputTouched && coerceToNumber(innerValue) < min">
        Minimum {{ operationName }} is {{ min | currency:'USD':'symbol-narrow' }}
    </div>

    <div cduxInputError *ngIf="!this.inputFocused && max > 0 && coerceToNumber(innerValue) > max">
        Maximum {{ operationName }} is {{ max | currency:'USD':'symbol-narrow' }}
    </div>

    <div cduxInputError *ngIf="!this.inputFocused   && max === 0 && coerceToNumber(innerValue) > max">
        Sorry, we are currently unable to accept {{ fundType | cduxTranslate:'fundtypes' }} {{ operationName }}s for your account.
        <br />
      <span *ngIf="phoneEnabled else chat">To contact Player Services, {{ 'customer-service' | cduxTranslate:affId.toString(10) }}</span>.
    </div>
    <ng-template #chat>Please <cdux-chat></cdux-chat> for assistance</ng-template>

    <div cduxInputError *ngIf="!this.inputFocused && !!this.offerId && !!this.amount && !(coerceToNumber(innerValue) < min) && (coerceToNumber(innerValue) < amount)">
        Deposit at least ${{amount}} to claim your bonus!
    </div>
</ng-template>
