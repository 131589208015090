import { Injectable } from '@angular/core';
import { Observable, zip, of, combineLatest } from 'rxjs';
import { map, take, flatMap, catchError } from 'rxjs/operators';

import {
    enumTrackStatus,
    WagerService,
    IPoolType,
    ITrack,
    Bet,
    enumTrackType,
    enumBetSubtype,
    Entry,
    IToteRace,
    EntrySelectionService,
    OldWagerValidationService,
    IWagerValidation,
    IAdwRace,
    BetAmountType,
    enumRaceStatus,
    JwtSessionService,
    ITodaysRace,
} from '@cdux/ng-common';
import { ITransaction, IWager, BetsModifiers } from '@cdux/ng-fragments';

import { ProgramNavBusinessService } from 'app/shared/program/services/program-nav.business.service';
import { ProgramModuleBusinessService  } from 'app/shared/program/services/program-module.business.service';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

@Injectable()
export class BetsCommonService {
    private _poolsTable = {};
    private _trackStatusEnum = enumTrackStatus;

    constructor(
        private _betSlipService: BetSlipBusinessService,
        private _entrySelectionService: EntrySelectionService,
        private _jwtSessionService: JwtSessionService,
        private _oldWagerValidationService: OldWagerValidationService,
        private _programModuleBusinessService: ProgramModuleBusinessService,
        private _programNavService: ProgramNavBusinessService,
        private _wagerService: WagerService,
        private _todaysRacesService: TodaysRacesBusinessService,
    ) {}

    public initializeCommonService(): Observable<{tracks: ITrack[], pools: IPoolType[]}> {
        return zip(
            this._initializePools(),
            this._initializeTracks(),
            (tracks: ITrack[], pools: IPoolType[]) => ({ tracks, pools })
        ).pipe(
            catchError(() => {
                return of(null);
            })
        );
    }

    private _initializePools (): Observable<IPoolType[]> {
        if (Object.keys(this._poolsTable).length > 0) {
            return of(Array.of(this._poolsTable) as IPoolType[]);
        } else {
            return this._wagerService.getPoolTypes().pipe(
                map( (data: IPoolType[]) => {
                    data.forEach((pool: IPoolType) => {
                        this._poolsTable[pool.Code] = pool;
                    });
                    return data;
                })
            );
        }
    }

    private _initializeTracks (): Observable<ITrack[]> {
        if (this._programNavService.tracks && this._programNavService.tracks.length > 0) {
            return of(this._programNavService.tracks);
        } else {
            return this._programNavService.getTracksChanges();
        }
    }

    public getTrack(brisCode: string, trackType: string): ITrack {
        const tracks: ITrack[] = this._programNavService.tracks.filter(track =>
            (track.BrisCode.toUpperCase() === brisCode.toUpperCase() && track.TrackType.toUpperCase() === trackType.toUpperCase()));

        return tracks.length > 0 ? tracks[0] : null;
    }

    /**
     * Will retrieve the raceStatus based on track code and race number
     * @param {string} brisCode
     * @param {string} trackType
     * @param {number} raceNum
     * @returns {string}
     */
    public getRaceStatus(brisCode: string, trackType: string, raceNum: number): string {
        const track = this.getTrack(brisCode, trackType);
        if (!!track) {
            if (raceNum > track.RaceNum) {
                return this._trackStatusEnum.OPEN;
            } else if (raceNum < track.RaceNum) {
                return this._trackStatusEnum.CLOSED;
            } else {
                return track.RaceStatus;
            }
        } else {
            return '';
        }
    }

    /**
     * Will retrieve the raceStatus based on track code and race number
     * @param {string} brisCode
     * @param {enumTrackType} trackType
     * @param {number} raceNum
     * @param {poll} boolean
     * @returns {Observable<enumRaceStatus>}
     */
    public newGetRaceStatus(brisCode: string, trackType: enumTrackType, raceNum: number, poll = false): Observable<enumRaceStatus> {
        return this._todaysRacesService.getTodaysRace(brisCode, trackType, raceNum, poll).pipe(
            take(1),
            map((race: ITodaysRace) => {
                return race.status;
            })
        );
    }

    /**
     * Gets an observable to update status of the given race
     *
     * @param {string} brisCode
     * @param {enumTrackType} trackType
     * @param {string} raceNum
     * @returns {Observable<IAdwRace>}
     */
    public getRaceStatusChanges(brisCode: string, trackType: enumTrackType, raceNum: number): Observable<enumRaceStatus> {
        return this._programNavService.getRaceChanges(
            raceNum,
            brisCode,
            trackType
        ).pipe(
            map((race: IAdwRace) => {
                return race.raceStatus;
            })
        );
    }

    /**
     * will return pool type
     * @param {string} type
     * @returns {string}
     */
    public getPoolType(type: string): string {
        const pool = this._poolsTable[type];
        try {
            return pool.Name || '';
        } catch (e) {
            return '';
        }
    }

    /**
     * create Bet from IWager, validate and add to Bet Slip
     *
     * @param {MyBetsModel} myBet
     * @returns {Observable<boolean>}
     */
    public addToBetSlip(myBet: IWager): Observable<boolean> {
        return this._copyBet(myBet).pipe(
            flatMap(bet => this._validateAndAddBet(bet))
        );
    }

    public copyAndEditWager(myBet: IWager): Promise<Bet> {
        return this._copyBet(myBet).toPromise().then(bet => {
            return this.copyAndEditBet(bet);
        });
    }

    public copyAndEditBet(bet: Bet): Bet{
        bet.id = Bet.generateBetId();
        this._betSlipService.editBet(bet);
        return bet;
    }

    private _copyBet(myBet: IWager): Observable<Bet> {
        const TrackType: enumTrackType = this.getTrackType(myBet.trackType);
        const amountObs: Observable<string[]> = this._wagerService.getBetAmounts(
            myBet.brisCode,
            TrackType,
            parseInt(myBet.raceNum, 10),
            myBet.poolType,
            false);

        const programNavSub: Observable<IAdwRace> = this._programNavService.getRaceChanges(parseInt(myBet.raceNum, 10), myBet.brisCode, myBet.trackType);

        const _track: ITrack = this.getTrack(myBet.brisCode, myBet.trackType);
        const allRacesObs: Observable<IToteRace[]> = this._programModuleBusinessService.getAllRacesObservable(_track);
        const bet = new Bet();
        bet.betSubtype = this.getBetSubType(myBet.runners, myBet.delimiter);
        bet.track = _track;
        bet.poolType = this._poolsTable[myBet.poolType];
        bet.userName = this._jwtSessionService.getUserInfo().username;
        bet.conditional = myBet.condWagerData.conditionalWager;
        bet.conditionalMtp = myBet.condWagerData.conditionalMaxMtp;
        bet.conditionalOdds = myBet.condWagerData.conditionalOdds;
        bet.conditionalProbablePayout = myBet.condWagerData.conditionalProbablePayout;

        return combineLatest([amountObs, allRacesObs, programNavSub]).pipe(
            take(1),
            map(([betAmounts, allRaces, race ]) => {
                const amountIndex = betAmounts.indexOf(myBet.amount.toString());
                bet.amount = {value: myBet.amount.toString(), type: amountIndex !== -1 ? BetAmountType.PROVIDED : BetAmountType.CUSTOM};
                bet.allowedAmounts = betAmounts.map(amount => {
                    return {value: amount, type: BetAmountType.PROVIDED};
                });
                bet.runners = this.getRunners(myBet.runners, myBet.delimiter, parseInt(myBet.raceNum, 10), allRaces, bet.poolType.MultipleRace);
                bet.race = race;
                bet.betCreatedTimestamp = new Date().getTime();
                bet.showInBetSlip = true;
                bet.isQuickBet = false;
                return bet;
            }, (error) => {
                return null;
            })
        );
    }

    /**
     * This will validate the bet and add it to store
     * @param {Bet} bet
     * @returns {Observable<boolean>}
     */
    private _validateAndAddBet(bet: Bet): Observable<boolean> {
        return this._oldWagerValidationService.validate(bet).pipe(
            map((data: IWagerValidation) => {
                if (data.isValid) {
                    const copiedBets: Bet[] = [];
                    copiedBets.push(bet);
                    this._betSlipService.betsToAdd = copiedBets;
                }
                return data.isValid
            }, (error) => {
                return false;
            })
        );
    }

    /**
     * this will return TrackType
     * @param {string} TrackType
     * @returns {enumTrackType}
     */
    public getTrackType(TrackType: string): enumTrackType {
        return TrackType as enumTrackType || enumTrackType.TBRED
    }

    /**
     * Generate an array of legs of entries from the runner list.
     * Follows the documentation at:
     * https://github.cdinteractive.com/twinspires/documentation/blob/production/Development/API/MyBets/api.todays-bets.md#runners-list
     *
     * @param {string} runnersList
     * @param {string} delimiter
     * @param {number} raceNumber
     * @param {IToteRace[]} allRaces
     * @param {boolean} multiRace
     * @returns {Entry[][]}
     */
    // TODO - fix this to handle things like "1-4"
    public getRunners(runnersList: string, delimiter: string, raceNumber: number, allRaces: IToteRace[], multiRace: boolean):  Entry[][] {
        // Runners list split by legs.
        const legs = runnersList.split(',WT,');
        // Index of the race of the first leg.
        const raceIndex = allRaces.findIndex(tempRace => tempRace['RaceNumber'] === raceNumber);
        // Final runners selection.
        const runners = [];
        // Raw entries from the allRaces call.
        let entries = [];

        // Cycle through the legs of program numbers to assemble an array of entries for each leg.
        legs.forEach((leg, legIndex) => {
            // Runners for the current leg.
            const currentLegsRunners = [];
            // Get the individual runner's program numbers.
            const legRunners = leg.split(delimiter);
            if (entries.length === 0 || multiRace) {
                // Get the race corresponding to the leg.
                const race = allRaces[raceIndex + legIndex];
        if (!race) {
            console.warn('Entries not found for this race.');
            return [];
        }
                // Get the entries of the current leg.
                entries = race.Entries
                    .map(e => new Entry(e));
            }

            // Cycle through the selected program numbers of this leg to assemble the appropriate entries.
            legRunners.forEach((runner) => {
                // There can be extra notation in the runners list, so let's just use actual numbers.
                if (isNaN(+runner)) {
                    return;
        }

                // Get the matching runners (accounts for field and coupled entries).
                const matchingRunners = entries.filter(e => +e.ProgramNumberCoupled === +runner);
                if (matchingRunners.length === 0) {
                    console.warn('One or more entries could not be found matching the runner list.');
            }
                // Set the entry as selected.
                matchingRunners.forEach(r => this._entrySelectionService.applySelectionToLeg(true, legIndex, false, r));
                // Add these entries to the leg.
                currentLegsRunners.push(...matchingRunners);
        });
            // Add this leg of runners to the list.
            runners.push(currentLegsRunners);
        });

        return runners;
    }

    /**
     * returns subtype of the wager.
     * @param {string} runnersList
     * @param {string} delimiter
     * @returns {enumBetSubtype}
     */
    public getBetSubType(runnersList: string, delimiter: string): enumBetSubtype  {
        const splitRunnerList = runnersList.split(delimiter);
        // pull modifier if present
        if (splitRunnerList.indexOf(BetsModifiers.KEY_BOX) !== -1
            || splitRunnerList.indexOf(BetsModifiers.POWER_BOX) !== -1
            || splitRunnerList.indexOf(BetsModifiers.BOX)  !== -1
            || splitRunnerList.indexOf(BetsModifiers.KEY)  !== -1) {
            // Add the modifier to the wager type
            switch (splitRunnerList[0]) {
                case BetsModifiers.POWER_BOX:
                    return enumBetSubtype.POWERBOX;
                case BetsModifiers.KEY_BOX:
                    return enumBetSubtype.KEYBOX;
                case BetsModifiers.BOX:
                    return enumBetSubtype.BOX;
                case BetsModifiers.KEY:
                    return enumBetSubtype.KEY;
            }
        }
        return enumBetSubtype.STRAIGHT;
    }

    /**
     * maps an ITransaction object to a Bet one
     *
     * Unfortunately, we have similar-but-not-identical IWager, Bet and
     * ITransaction (for wager transactions), so some of this code will
     * be similar-but-not-identical to that in addToBetSlip.
     *
     * TODO:HIP: eliminate duplicate code (or competing interfaces/models)
     *
     * @param transaction
     */
    /* istanbul ignore next */
    public createBetFromTransaction (transaction: ITransaction): Observable<Bet> {
        const DELIMITER = ',';

        const bet = new Bet();
        bet.betSubtype = this.getBetSubType(transaction.runners, DELIMITER);
        bet.track = this.getTrack(transaction.brisCode, transaction.trackType);
        bet.poolType = this._poolsTable[transaction.betTypeCode];
        bet.userName = this._jwtSessionService.getUserInfo().username;
        bet.conditional = transaction.conditionalWagerData.conditionalWager;
        bet.conditionalMtp = transaction.conditionalWagerData.conditionalMaxMtp;
        bet.conditionalOdds = transaction.conditionalWagerData.conditionalOdds;
        bet.conditionalProbablePayout = transaction.conditionalWagerData.conditionalProbablePayout;

        // TODO:HIP: GetAllRaces call should be replaced with TodaysRaces call
        const allRacesObs: Observable<IToteRace[]> = this._programModuleBusinessService.getAllRacesObservable(bet.track);

        const amountObs: Observable<string[]> = this._wagerService.getBetAmounts(
            transaction.brisCode,
            transaction.trackType,
            transaction.raceNum,
            transaction.betTypeCode,
            false
        );

        const programNavSub: Observable<IAdwRace> = this._programNavService.getRaceChanges(
            transaction.raceNum,
            transaction.brisCode,
            transaction.trackType,
        );

        return combineLatest(
            amountObs,
            allRacesObs,
            programNavSub
        ).pipe(
            take(1),
            map(([ betAmounts, allRaces, race ]) => {
                const amountString = transaction.wagerAmount.toString();

                bet.amount = {
                    value: amountString,
                    type: betAmounts.indexOf(amountString) !== -1 ? BetAmountType.PROVIDED : BetAmountType.CUSTOM
                };
                bet.allowedAmounts = betAmounts.map(amount => {
                    return {
                        value: amount,
                        type: BetAmountType.PROVIDED
                    };
                });

                bet.runners = this.getRunners(
                    transaction.runners,
                    DELIMITER,
                    transaction.raceNum,
                    allRaces,
                    bet.poolType.MultipleRace
                );

                bet.race = race;
                bet.betCreatedTimestamp = new Date().getTime();
                bet.showInBetSlip = true;
                bet.isQuickBet = false;

                return bet;
            })
        );
    }
}
