import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    FeatureToggleDataService,
    FUNDING_OPERATIONS,
    JwtSessionService,
    TranslateService,
} from '@cdux/ng-common';

import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service'
import { FundingPaypalService } from 'app/shared/funding/shared/services/paypal.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';

import { PaypalMethodDepositAbstractComponent } from './paypal-method-deposit-abstract.component';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { Router } from '@angular/router';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-paypal-method-deposit-fullpage',
    styleUrls: ['./paypal-method-deposit-fullpage.component.scss'],
    templateUrl: './paypal-method-deposit-fullpage.component.html',
})
export class FundingPaypalMethodDepositFullpageComponent extends PaypalMethodDepositAbstractComponent implements OnInit {
    // share data structures with template
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public FUNDING_OPERATIONS = FUNDING_OPERATIONS;
    public isSmallGlass = false;

    public static getComponent (options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingPaypalMethodDepositFullpageComponent, options);
    }

    constructor(
        localRouter: Router,
        localCDR: ChangeDetectorRef,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localPaypalService: FundingPaypalService,
        localBetSlipService: BetSlipBusinessService,
        localSidebarService: SidebarService,
        private _mediaToggle: CduxMediaToggleService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localCDR,
            localEventTrackingService,
            localPaypalService,
            localBetSlipService,
            localEnvironment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            _featureToggleService
        );
    }

    ngOnInit() {
        // check Media LG or SG:
        this.isSmallGlass = this._mediaToggle.query('phone');
        super.ngOnInit();
    }
}
