<nav class="wrapper">
    <cdux-betpad-tracklist
        class="track"
        [class.hidden]="displayMode === DisplayModeEnum.MOBILE"
        [tracks]="tracks"
        [selectedTrack]="selectedRaceNavigation"
        (onTrackDropdownStateChange)="onTrackDropdownStateChange($event)"
        (onTrackChange)="onRaceNavigationChange($event)"
        [externalOpenEnabled]="true">
    </cdux-betpad-tracklist>
    <div class="spacer"
         *ngIf="displayMode === DisplayModeEnum.COMPACT">
    </div>
    <div id="race-nav-previous"
        class="navigation-back"
        *ngIf="displayMode !== DisplayModeEnum.MOBILE"
        [class.disabled]="previousRaceNumber === 0"
        (click)="!!previousRaceNumber && previousRace()">
        <div class="navigation-icon"></div>
        <span class="race-number">R{{ previousRaceNumber }}</span>
    </div>
    <cdux-bet-pad-race-list
        class="race"
        cduxDisplayModes
        [displayMode]="displayMode === DisplayModeEnum.MOBILE ? DisplayModeEnum.MOBILE : DisplayModeEnum.LARGE"
        [selectedTrack]="selectedRaceNavigation"
        [races]="races"
        [selectedRace]="selectedRace"
        (valueChanges)="onRaceChange($event)"
        (raceDropdownStateChange)="onRaceDropdownStateChange($event)">
    </cdux-bet-pad-race-list>
    <div id="race-nav-next"
        class="navigation-forward"
        *ngIf="displayMode !== DisplayModeEnum.MOBILE"
        [class.disabled]="nextRaceNumber === 0"
        (click)="!!nextRaceNumber && nextRace()">
        <span class="race-number">R{{ nextRaceNumber }}</span>
        <div class="navigation-icon"></div>
    </div>
</nav>
