import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';

import {
    CduxNgCommonModule,
    EventSessionModule,
    GetRunnerDetailsDataService,
    GetStatsDataService,
    TranslateModule
} from '@cdux/ng-common';
import {CduxFormsModule, CduxQAAutomationModule} from '@cdux/ng-platform/web';
import { CheckioboxModule, SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';

import { SaddleClothClassPipe } from './pipes/saddle-cloth-class.pipe';
import { CduxActivateLegDirective } from './directives/activate-leg.directive';
import { ProgramNavErrorModalComponent } from './components/nav-error-modal/nav-error-modal.component';
import { DefaultSidebarComponent } from './components/default-sidebar/default-sidebar.component';
import { VideoModule } from '../../wagering/video/video.module';
import { BetsModule } from '../bets/bets.module';
import { ProgramComponent } from './components/program/program.component';
import { ProgramEntryComponent } from './components/program-entry/program-entry.component';
import { RankingBarComponent } from './components/ranking-bar/ranking-bar.component';

import { BreadcrumbsModule } from 'app/shared/breadcrumbs/breadcrumbs.module';
import { DamDetailsComponent } from 'app/shared/program/components/ancestor-details/dam-details.component';
import { DriverDetailsComponent } from 'app/shared/program/components/driver-details/driver-details.component';
import { FormatFractionPipe } from 'app/shared/program/pipes/format-fraction.pipe';
import { FormatHorseDetailsNumberPipe } from 'app/shared/program/pipes/format-horse-details-number.pipe';
import { GreyhoundDetailsComponent } from 'app/shared/program/components/runner-details/greyhound-details.component';
import { PopUpModule } from 'app/shared/pop-up/pop-up.module';
import { RunnerDetailsComponent } from 'app/shared/program/components/runner-details/runner-details.component';
import { SummaryDetailsComponent } from './components/summary-details/summary-details.component';
import { SireDetailsComponent } from 'app/shared/program/components/ancestor-details/sire-details.component';
import { SummaryStatsComponent } from 'app/shared/program/components/summary-stats/summary-stats.component';
import { TrainerDetailsComponent } from 'app/shared/program/components/trainer-details/trainer-details.component';
import { TrainerJockeyDetailsComponent } from 'app/shared/program/components/trainer-jockey-details/trainer-jockey-details.component';
import { OddsTrendComponent } from 'app/shared/program/components/odds-trend/odds-trend.component';
import { RunnerDetailsPastPerformancesComponent } from './components/runner-details-past-performances/runner-details-past-performances.component';
import { FavoritesModule } from 'app/account/favorites/favorites.module';
import { ProgramSummaryRacestatsComponent } from './components/program-summary/program-summary-racestats.component';

@NgModule({
    imports: [
        BetsModule,
        BreadcrumbsModule,
        CduxFormsModule,
        CduxNgCommonModule,
        CheckioboxModule,
        CommonModule,
        EventSessionModule,
        PopUpModule,
        PortalModule,
        SharedFragmentsModule,
        TranslateModule,
        VideoModule,
        CduxQAAutomationModule,
        FavoritesModule
    ],
    declarations: [
        SaddleClothClassPipe,
        CduxActivateLegDirective,
        ProgramNavErrorModalComponent,
        DefaultSidebarComponent,
        ProgramComponent,
        ProgramEntryComponent,
        RankingBarComponent,
        OddsTrendComponent,
        RunnerDetailsComponent,
        GreyhoundDetailsComponent,
        DriverDetailsComponent,
        TrainerDetailsComponent,
        SummaryDetailsComponent,
        SummaryStatsComponent,
        TrainerJockeyDetailsComponent,
        SireDetailsComponent,
        DamDetailsComponent,
        FormatHorseDetailsNumberPipe,
        FormatFractionPipe,
        RunnerDetailsPastPerformancesComponent,
        ProgramSummaryRacestatsComponent
    ],
    exports: [
        SaddleClothClassPipe,
        CduxActivateLegDirective,
        ProgramNavErrorModalComponent,
        DefaultSidebarComponent,
        ProgramComponent
    ],
    providers: [
        GetRunnerDetailsDataService,
        GetStatsDataService
    ]
})
export class ProgramSharedModule {
    public static forRoot(): ModuleWithProviders<ProgramSharedModule> {
        return {
            ngModule: ProgramSharedModule,
            providers: [
            ]
        };
    }
}
