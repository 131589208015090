<div class="password-update-container open" [formGroup]="formGroup" >
    <div class="password-toggle">
        <h4>Update Password</h4>
        <!-- <i class="icon--pencil-circle"></i> -->
    </div>
    <div class="form-label-hover current-password-container">
        <!--[ngClass] adds .has-value to input when there's value-->
        <input formControlName="currentPassword"
                type="{{showPassword ? 'text': 'password'}}"
                class="current-password"
                [ngClass]="{'has-value': !!passwordControl.value, 'input-error': showErrorMessage(inputField.password)}"
                id="password"
                (blur)="fieldVisited(inputField.password)">
        <label class="ts-label" for="password">Current Password</label>
        <!-- ng adds .show-the-password class when there's a value in #password,
                    otherwise, span.btn.show-password is hidden -->
        <!-- also add the class 'active' to move the Show to the left when there is an icon located next to it -->
        <span  class="btn show-password show-password-label"
               [ngClass]="{'show-the-password active': !!passwordControl.value}"
               (click)="toggleShowPassword(inputField.password)" id="pw-update-current-pw-show-hide-btn"> {{showPassword ? 'HIDE': 'SHOW'}} </span>
        <i [ngClass]="{'icon--valid-check': showValidIcon(passwordControl), 'icon--remove--amber': showRemoveIcon(passwordControl)}"
            (click)="showRemoveIcon(passwordControl) && resetInput(passwordControl)" id="pw-update-current-pw-valid-icn"></i>
        <div *ngIf="showErrorMessage(inputField.password)" class="ts-validation error" id="pw-update-current-pw-error-txt">Please enter current password</div>
    </div>

    <div class="form-label-hover new-password-container">
        <!--[ngClass] adds .has-value to input when there's value-->
        <input formControlName="newPassword"
                type="{{showNewField ? 'text': 'password'}}"
                class="new-password"
                [ngClass]="{'has-value': !!newControl.value, 'input-error': showErrorMessage(inputField.newField)}"
                (blur)="fieldVisited(inputField.newField)"
                id="new-password">
        <label class="ts-label" for="new-password">New Password</label>
        <!-- ng adds .show-the-password class when there's a value in #password,
                    otherwise, span.btn.sho gw-password is hidden -->
        <!-- also add the class 'active' to move the Show to the left when there is an icon located next to it -->
        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active': !!newControl.value}"
              (click)="toggleShowPassword(inputField.newField)" id="pw-update-new-pw-show-hide-btn"> {{showNewField ? 'HIDE': 'SHOW'}} </span>
        <i [ngClass]="{'icon--valid-check': showValidIcon(newControl), 'icon--remove--amber': showRemoveIcon(newControl)}"
            (click)="showRemoveIcon(newControl) && resetInput(newControl)" id="pw-update-new-pw-valid-icn"></i>
        <div *ngIf="showErrorMessage(inputField.newField)" class="ts-validation error" id="pw-update-new-pw-error-txt">Password does not meet requirements</div>
    </div>

    <div class="form-label-hover confirm-password-container">
        <!--[ngClass] adds .has-value to input when there's value-->
        <input formControlName="confirmPassword"
                type="{{showConfirmField ? 'text': 'password'}}"
                class="confirm-password"
                id="confirm-password"
                (blur)="fieldVisited(inputField.confirmField)"
                [ngClass]="{'has-value' : !!confirmControl.value, 'input-error': showErrorMessage(inputField.confirmField) || showMisMatchError() }">
        <label class="ts-label" for="confirm-password">Confirm Password</label>
        <!-- ng adds .show-the-password class when there's a value in #password,
                    otherwise, span.btn.show-password is hidden -->
        <!-- also add the class 'active' to move the Show to the left when there is an icon located next to it -->
        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active': !!confirmControl.value}"
              (click)="toggleShowPassword(inputField.confirmField)" id="pw-update-confirm-pw-show-hide-btn">{{showConfirmField ? 'HIDE': "SHOW"}}</span>
        <!-- ng adds .show-the-password class when there's a value in #password,
                    otherwise, span.btn.show-password is hidden -->
        <i [ngClass]="{'icon--valid-check': showValidIcon(confirmControl), 'icon--remove--amber': showRemoveIcon(confirmControl)}"
            (click)="showRemoveIcon(confirmControl) && resetInput(confirmControl)" id="pw-update-confirm-pw-valid-icn"></i>
        <div *ngIf="showErrorMessage(inputField.confirmField)" class="ts-validation error" id="pw-update-confirm-pw-error-invalid-txt">Confirm password is required</div>
        <div *ngIf="showMisMatchError()" class="ts-validation error" id="pw-update-confirm-pw-error-mismatch-txt">Passwords do not match</div>
    </div>
    <p id="pw-update-rules-text">{{passwordRulesText}}</p>

    <div class="update-cancel-button-container" [cduxFocus]="true" [scrollToTop] = 'false'>
        <div id="cancelBtn"
            class="update-cancel-button active"
            (click)="onCancel()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_PW_CANCEL"
        >CANCEL</div>
        <div id="updateBtn"
            class="update-cancel-button"
            [ngClass]="formGroup.invalid ? 'inactive': 'active'"
            (click)="formGroup.valid && onUpdate()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_SETTINGS_PASSWORD"
        >UPDATE</div>
    </div>

</div>
