import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
    Output
} from '@angular/core';

import {
    enumTrackType,
    EventClickAttributeType,
    EventClickType,
    ITodaysRace,
    ITrackBasic,
    TrackService,
    JwtSessionService,
    ConfigurationDataService,
} from '@cdux/ng-common';

import { DropdownComponent, enumDropdownStates, AbstractDropupSelectComponent } from '@cdux/ng-fragments';

import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { CduxMediaToggleService, ModalService } from '@cdux/ng-platform/web';
import { NgControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DisplayModeEnum } from '../../../common/enums';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-bet-pad-race-list',
    templateUrl: './bet-pad-race-list.component.html',
    styleUrls: ['./bet-pad-race-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetPadRaceListComponent extends AbstractDropupSelectComponent<ITodaysRace, BetPadRaceListComponent> implements OnInit, OnDestroy {
    public enumTrackType = enumTrackType;

    @ViewChild(DropdownComponent) public dropdown;

    @Input()
    public set races(newRaces: ITodaysRace[]) {
        if (!!newRaces) {
            this.list = newRaces;
            this.isDisabled = newRaces.length === 0;
            this.racesCount = newRaces.length;
            this._changeDetector.detectChanges();
        }
    }
    public get races(): ITodaysRace[] {
        return this.list;
    }

    public DisplayModeEnum = DisplayModeEnum;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;

    @Input()
    public set selectedRace(value: ITodaysRace) {
        this.value = !!value ? value : null;
        this.selectedRaceLabel = !!value ? 'RACE ' + value.raceNumber : '';
        this.raceIndex = this._getIndexOfRaceInRaces(this.selectedRace);
        this._changeDetector.detectChanges();
    }
    public get selectedRace(): ITodaysRace {
        return this.value;
    }

    private _selectedTrack: ITrackBasic;
    @Input()
    public set selectedTrack(value: ITrackBasic) {
        this._selectedTrack = value;
        this.dropupHeaderText = value ? TrackService.formatTrackName(value) : null;
    }
    public get selectedTrack(): ITrackBasic {
        return this._selectedTrack;
    }
    public currentRaceNumber: number;

    @Input() public programView: enumProgramViews;
    @Input() public proxyRaceNavToHeader: boolean = false;

    @Output() public raceDropdownStateChange = new Subject<enumDropdownStates>();

    /**
     * Exposes the event click types to the template.
     */
    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;

    public isDisabled: boolean = false;
    public selectedRaceLabel: string = '';
    public raceIndex: number;
    public racesCount: number;
    public isLoggedIn: boolean = false;
    public tournamentSelected = false;

    /**
     * The text for the dropup header.
     *
     * @type {string}
     */
    public dropupHeaderText: string;

    private _destroy: Subject<undefined> = new Subject<undefined>();

    constructor(
        private _sessionService: JwtSessionService,
        private _eventTrackingService: EventTrackingService,
        private _tournamentsSession: TournamentsSessionService,
        protected configService: ConfigurationDataService,
        _changeDetector: ChangeDetectorRef,
        _mediaQueryService: CduxMediaToggleService,
        _modalService: ModalService,
        @Self() @Optional() _control: NgControl
    ) {
        super(
            _changeDetector,
            BetPadRaceListComponent,
            _mediaQueryService,
            _modalService,
            _control
        );
    }

    ngOnInit() {
        super.ngOnInit();
        this.isLoggedIn = this._sessionService.isLoggedIn();

        this._sessionService.onAuthenticationChange
            .pipe(takeUntil(this._destroy))
            .subscribe(v => this.isLoggedIn = v);

        this._tournamentsSession.onTournamentSelection
            .pipe(takeUntil(this._destroy))
            .subscribe((data) => {
                this.tournamentSelected = data ? true : false;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._destroy.next();
        this._destroy.complete();
    }

    public onDropdownStateChange(state: enumDropdownStates): void {
        if (state === enumDropdownStates.OPEN) {
            // need to set currentRaceNumber on open to trigger focus
            const currentRace = this.races?.find((race) => race.currentRace);
            if (currentRace) {
                this.currentRaceNumber = currentRace.raceNumber;
            }
        } else {
            // need to make it null to trigger focus after setting currentRaceNumber on open
            this.currentRaceNumber = null;
        }
        this.raceDropdownStateChange.next(state);
    }

    public onRaceSelection(race: ITodaysRace): void {
        if (race) {
            this.value = race;
        }
    }

    public previousRace(): void {
        const previousRaceIndex = this._getIndexOfRaceInRaces(this.selectedRace) - 1;
        if (this.selectedRace && previousRaceIndex > -1) {
            const previousRace = this.races[previousRaceIndex];
            this.value = previousRace;
        }
    }

    public nextRace(): void {
        const nextRaceIndex = this._getIndexOfRaceInRaces(this.selectedRace) + 1;
        if (this.selectedRace && nextRaceIndex < this.races.length) {
            const nextRace = this.races[nextRaceIndex];
            this.value = nextRace;
        }
    }

    public openRaceList(): void {
        if (this.dropdown && !this.dropdown.opened) {
            this.dropdown.toggle();
        }
    }

    public trackByRace(index: number, race: ITodaysRace) {
        return race.raceNumber;
    }

    public logClick(event: EventClickType) {
        const time = Date.now();

        switch (event) {
            case EventClickType.RACE_NAV_RACE_SELECTION:
                this._eventTrackingService.logClickEvent(event,
                    [
                        { attrId: EventClickAttributeType.RACE_NAV_RACE_BRIS_CODE, data: this.selectedTrack.BrisCode, timestamp: time },
                        { attrId: EventClickAttributeType.RACE_NAV_RACE_TRACK_TYPE, data: this.selectedTrack.TrackType, timestamp: time },
                        { attrId: EventClickAttributeType.RACE_NAV_RACE_RACE, data: this.selectedRace.raceNumber, timestamp: time },
                        { attrId: EventClickAttributeType.RACE_NAV_RACE_DATE, data: this.selectedRace.raceDate, timestamp: time },
                    ]);
                break;
        }
    }

    public hasCarryover(race: ITodaysRace): boolean {
        return race && race.carryover.length && TrackService.isWagerableRace(race.status);
    }

    private _getIndexOfRaceInRaces(race): number {
        if (race && this.races && this.races.length > 0) {
            return this.races.findIndex(r => r.raceNumber === race.raceNumber);
        }
        return -1;
    }

}
