import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewRef
} from '@angular/core';
import { noop, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { enumToggleState, JwtSessionService } from '@cdux/ng-common';
import { LoadingService, LoadingDotsComponent } from '@cdux/ng-fragments';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { CduxSidebarContentComponent } from '../cdux-sidebar-content-component.class';
import { SIDEBAR_LOADERS } from '../enums/loader.enums';
import { ISidebarLoaderProperties, ISidebarLoaderState } from '../interfaces/sidebar-loader.interface';
import { ISidebarHeaderComponent } from '../interfaces/sidebar-portal-component.interface';
import { ISidebarPortalState } from '../interfaces/sidebar-portal-state.interface';
import { SidebarService } from '../sidebar.service';

/**
 * Height of the header within the nav-panel/sidebar.
 *
 * @type {number}
 */
const HEADER_HEIGHT = 60;

/**
 *  80 pixels for the app header
 * +10 pixels from the bottom of the page
 * + 2 pixels for borders on the nav-panel/sidebar.
 *
 * @type {number}
 */
const RESERVED_VERTICAL_SPACE = 92;

@Component({
    selector: 'cdux-sidebar',
    templateUrl: './cdux-sidebar.component.html',
    styleUrls: [ './cdux-sidebar.component.scss' ],
    animations: [
        trigger('transform', [
            state('open', style({
                transform: 'translate3d(0, 0, 0)',
                visibility: 'visible',
            })),
            state('close', style({
                visibility: 'hidden',
            })),
            transition('close <=> open',
                animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
        ])
    ]
})
export class CduxSidebarComponent implements AfterViewInit, OnInit, OnDestroy {

    @Output() public stateChange: EventEmitter<enumToggleState> = new EventEmitter<enumToggleState>();

    @Input() public loaderNamePrefix: string = '';

    @ViewChild(CdkPortalOutlet) public portalOutlet: CdkPortalOutlet;

    public headerMessage: Observable<string> = of('');

    /**
     * The currently attached Portal.
     *
     * @type {ComponentPortal<any>}
     * @internal
     */
    protected _portal: ComponentPortal<any>;

    private _destroy: Subject<boolean> = new Subject();

    /**
     * This is the component being hosted by the portal.
     *
     * @type {CduxSidebarContentComponent | any}
     */
    private _portalComponent: CduxSidebarContentComponent | any;

    /**
     * The most recent Portal State emitted.
     *
     * @type {ISidebarPortalState}
     */
    private _portalState: ISidebarPortalState;

    /**
     * The subscriptions that get unsubscribed from each time the portal resets.
     *
     * @type {Subscription[]}
     * @private
     */
    private _portalOutputSubscriptions: Subscription[] = [];

    /**
     * Holds the determination of having met the threshold.
     *
     * @type {boolean}
     */
    private _thresholdIsMet: boolean;

    /**
     * This is the threshold turned to a numeric height.
     *
     * @type {number}
     */
    private _maxPixelHeight: number;

    /**
     * Is the sidebar opened?
     *
     * @type {boolean}
     */
    private _isOpen: boolean;

    /**
     * IS the sidebar representing a Success State?
     *
     * @type {boolean}
     */
    private _isSuccess: boolean;

    /**
     * Collection of Subscriptions
     *
     * @type {Subscription[]}
     */
    private _subscriptions: Subscription[] = [];

    /**
     * This component's HTML element.
     *
     * @type {HTMLElement}
     */
    private _htmlElement: HTMLElement;

    /**
     * Was the most recent click inside the sidebar.
     *
     * @type {boolean}
     */
    private _isSidebarClick: boolean;

    /**
     * Does the current component override the sidebar's backdropClickDisabled setting?
     *
     * @type {boolean}
     */
    private _componentDisablesBackdropClick: boolean = null;

    /**
     * Are we showing the header?
     *
     * @type {boolean}
     */
    private _hideHeader:  boolean = false;

    /**
     * Hold a reference to the Border Color of the Panel
     * @type {string}
     */
    private _borderColor: string;

    /**
     * Instance copy of the LOADERS enum.
     *
     * @type {SIDEBAR_LOADERS}
     */
    public readonly LOADERS = SIDEBAR_LOADERS;

    /**
     * The component that has the loading dots.
     *
     * @type {LoadingDotsComponent}
     */
    public loadingDotsComponent = LoadingDotsComponent;
    /**
     * The component to be loaded in the header.
     *
     * @type {ISidebarHeaderComponent}
     */
    public currentSidebarHeaderComponent: ISidebarHeaderComponent;

    /**
     * This is what the resizing should respond to.
     *
     * @type {ElementRef}
     */
    @ViewChild('content')
    public content: ElementRef;

    /**
     * Handles the recalculation of the threshold in the event of a window resize.
     *
     * @param e
     */
    @HostListener('window:resize', ['$event'])
    public windowResize(e) {
        this._updateThreshold();
        this._updateHeightSetting();
    }

    @HostBinding('style.border-color')
    public get borderColor(): string {
        return this._borderColor;
    }

    /**
     * Determines if the header should be hidden.
     *
     * @returns {boolean}
     */
    public get hideHeader(): boolean {
        return this._hideHeader;
    }

    /**
     * Binds the is-down class on the host to the _isOpen property.
     *
     * @returns {boolean}
     */
    @HostBinding('class.is-down')
    public get isOpen(): boolean {
        return this._isOpen;
    }

    /**
     * Binds the success class to the _isSuccess property
     *
     * @type {boolean}
     */
    @HostBinding('class.success')
    public get isSuccess(): boolean {
        return this._isSuccess;
    }

    /**
     * Gets the native element of the host.
     *
     * @returns {HTMLElement}
     */
    get element(): HTMLElement {
        return this._elementRef.nativeElement;
    }

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _elementRef: ElementRef,
        private _loadingService: LoadingService,
        private _mediaService: CduxMediaToggleService,
        private _sessionService: JwtSessionService,
        private _sidebarService: SidebarService,
    ) {
        this.headerMessage = this._sidebarService.headerMessage.asObservable().pipe(
            // prevent multiple next('') from going further
            distinctUntilChanged(),
        );
    }

    ngAfterViewInit() {
        if (this.portalOutlet) {
            this._portalInitialization();
        } else {
            throw new Error('Portal Outlet failed to Initialize!');
        }

        if (this.content) {
            this._updateThreshold();
            this._updateHeightSetting();
        }
    }

    ngOnInit() {
        this._htmlElement = this._elementRef.nativeElement;
    }

    /**
     * Handles initialization. Binds backdrop clicks to close the sidebar, if appropriate. Also sets up
     * some necessary startup and teardown actions.
     */
    private _portalInitialization() {
        const portalChangedSubscription: Subscription = this._sidebarService.onPortalStateChanged.subscribe((portalState: ISidebarPortalState) => {
            this._portalState = portalState;

            // Since this is happening after the view init, I'm wrapping this in a set timeout so that
            // it doesn't flip out from changing this._isOpen immediately after starting up.
            setTimeout(() => {
                this._componentDisablesBackdropClick = (portalState.portalOptions.disableBackdropClick !== undefined && portalState.portalOptions.disableBackdropClick !== null) ? portalState.portalOptions.disableBackdropClick : null;

                if (portalState.isOpen !== this._isOpen) {
                    this._isOpen = portalState.isOpen;
                }

                if (portalState.portalOptions) {
                    this._isSuccess = portalState.portalOptions.isSuccess;
                    this._borderColor = portalState.portalOptions.borderColor;
                }

                this._unsetHeight();

                if (this._isOpen) {
                    // Attach the current content component
                    const currentSidebarComponent = new ComponentPortal(portalState.portalComponent.component);

                    this._detach();
                    this._portal = currentSidebarComponent;
                    // Prepare The Header Component
                    this.currentSidebarHeaderComponent = portalState.headerComponent;
                    this._setHeaderState((portalState.portalOptions.forceShowHeader === true), (portalState.portalOptions.hideHeader === true));
                    this.stateChange.emit(enumToggleState.ON);
                } else {
                    // Clear the content component
                    this._detach();

                    // Clear the header component
                    this.currentSidebarHeaderComponent = null;
                    this._setHeaderState();

                    // If the sidebar has been closed, force close any loaders being displayed
                    this._hideLoader(this.LOADERS.DOT_LOADER);
                    this._hideLoader(this.LOADERS.SPINNING_LOADER);
                    this.stateChange.emit(enumToggleState.OFF);
                }

                // This is necessary to make sure that when going from
                //  a fully extended nav panel to a "short one" that the
                //  contentSize changed gets a chance to adjust.
                //  The easiest test scenario if someone is looking to avoid
                //  this call and see if they fixed it is to:
                //    1. Open deposit
                //    2. Go back to the deposit Options if a specific method came up
                //    3. Click on the Profile nav button to open "My Account"
                //  Without this line, the nav panel will be full height instead of fitting
                //  the my-account component size.
                if (!(this._changeDetector as ViewRef).destroyed) {
                    this._changeDetector.detectChanges();
                }
            });
        });

        this._subscriptions.push(portalChangedSubscription);

        const loaderSubscription: Subscription = this._sidebarService.onOverlayStateChanged.subscribe((newLoaderState: ISidebarLoaderState) => {
           if (newLoaderState.loaderState === enumToggleState.ON) {
               this._showLoader(newLoaderState.loader);
           } else {
               this._hideLoader(newLoaderState.loader, newLoaderState.options, newLoaderState.onLoaderClosed)
           }
        });
        this._subscriptions.push(loaderSubscription);

        const logoutSubscription: Subscription = this._sessionService.onAuthenticationChange.pipe(
            distinctUntilChanged()
        ).subscribe((isLoggedIn) => {
            if (this._sidebarService.isOpen && !isLoggedIn) {
                this._sidebarService.close(true);
            }
        });
        this._subscriptions.push(logoutSubscription);
    }

    /**
     * Detaches the component from change detection. For use when being detached from a component.
     */
    public detachFromUI() {
        this._changeDetector.detach();
    }

    /**
     * Reattaches the component to change detection. For use when being attached to a component.
     */
    public reattachToUI() {
        this._changeDetector.reattach();
    }

    /**
     * Garbage collection.
     */
    ngOnDestroy() {
        // old way
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this._detach();
        this._dispose();

        // This is the way.
        this._destroy.next(true);
        this._destroy.complete();
    }

    /**
     * This will fire when a new component has been attached to the portal.
     *
     * @param {ComponentRef<any>} component
     */
    protected _onAttached(ref: ComponentRef<any>) {
        // Unsubscribe from the previous component's outputs.
        this._portalOutputSubscriptions.map((subscription: Subscription) => subscription.unsubscribe());

        // Set the newly hosted component.
        this._portalComponent = ref.instance;

        // Capture subscriptions to the outputs.
        this._portalOutputSubscriptions.push(
            this._portalComponent
                .contentSizeChange
                .subscribe((contentHeight) => {
                    this._updateThreshold();
                    this._updateHeightSetting(contentHeight);
                })
        );

        ref.instance.setProperties(this._portalState.portalComponent.properties);
        this._sidebarService.onAttached.next(ref);
    }

    private _detach() {
        if (this.portalOutlet && this.portalOutlet.hasAttached) {
            this.portalOutlet.detach();
        }
    }

    private _dispose() {
        if (this.portalOutlet) {
            this.portalOutlet.dispose();
        }
    }

    /**
     * Updates the header's state.
     *
     * @param {boolean} forceShow
     * @param {boolean} hide
     * @private
     */
    private _setHeaderState(forceShow: boolean = false, hide: boolean = false) {
        if (forceShow) {
            this._hideHeader = false;
        } else if (hide) {
            this._hideHeader = true;
        } else {
            const hasHeaderComponent = (!!this.currentSidebarHeaderComponent);
            this._hideHeader = (!this._sidebarService.canGoBack() && !hasHeaderComponent);
        }
    }

    /**
     * Shows the loader.
     *
     * @param {SIDEBAR_LOADERS} loader
     * @private
     */
    private _showLoader(loader: SIDEBAR_LOADERS) {
        this._loadingService.register(this.loaderNamePrefix + loader);
    }

    /**
     * Hides the loader.
     *
     * @param {SIDEBAR_LOADERS} loader
     * @param {ISidebarLoaderProperties} options
     * @param {() => void} onLoaderClosed
     * @private
     */
    private _hideLoader(loader: SIDEBAR_LOADERS, options: ISidebarLoaderProperties = {}, onLoaderClosed: () => void = () => noop()) {
        this._loadingService.resolve(this.loaderNamePrefix + loader, options.delayMs, options.status, options.messages, options.statusOverride, options.options).then(onLoaderClosed);
        if (!(this._changeDetector as ViewRef).destroyed) {
            this._changeDetector.detectChanges();
        }
    }

    /*
     * CLICK EVENT HANDLING
     *
     * Clicking on an element in the sidebar may cause that element to be destroyed (say, to load a new component
     * in the sidebar). When that happens, we can't walk back up the element tree to see where the user clicked.
     * So, we'll watch for mousedown (and touchstart, for touch devices) events, and walk the tree at that point.
     * Then when the click happens, we just have to handle it based on whether the click happened inside or outside
     * of the sidebar.
     */

    @HostListener('document:mousedown', ['$event']) clickStart(event: Event) {
        this._setIsSidebarClick(<HTMLElement>event.srcElement ? <HTMLElement>event.srcElement : <HTMLElement>event.target);
    }

    @HostListener('document:touchstart', ['$event']) touchStart(event: Event) {
        this._setIsSidebarClick(<HTMLElement>event.srcElement ? <HTMLElement>event.srcElement : <HTMLElement>event.target);
        if (!this._mediaService.query('phone')) {
            this._closeSidebarOnOutsideClick(event);
        }
    }

    @HostListener('document:click', ['$event']) clickEnd(event: Event) {
        this._closeSidebarOnOutsideClick(event);
    }

    private _closeSidebarOnOutsideClick(event: Event) {
        const disableClose: boolean = this._sidebarService.backdropClickDisabled || this._componentDisablesBackdropClick;

        if (!event.defaultPrevented && this._isOpen && !this._isSidebarClick && !disableClose) {
            this._sidebarService.close(true);
        }
    }

    /**
     * Checks whether or not the supplied HTMLElement is a descendant of the Sidebar.
     * @param element - HTMLElement to check
     */
    private _setIsSidebarClick(element: HTMLElement) {
        this._isSidebarClick = false;
        while (element = element.parentElement) {
            if (element === this._htmlElement) {
                this._isSidebarClick = true;
                break;
            }
        }
    }

    /**
     * Determines if the content has made the element reach its max height.
     *
     * @param {number} contentHeightOverride
     * @returns {boolean}
     * @private
     */
    private _isThresholdMet(contentHeightOverride) {
        const elHeight = this._htmlElement.clientHeight;
        const contentHeight = (contentHeightOverride || this.content.nativeElement.clientHeight) + this.content.nativeElement.offsetTop;
        // I'm subtracting 60 from the elHeight to account for the header.
        // Subtracting it elsewhere is problematic.
        // The modifiedElHeight represents the inner content that doesn't
        // include the sidebar header.
        const modifiedElHeight = this.currentSidebarHeaderComponent
            ? elHeight - HEADER_HEIGHT
            : elHeight;
        return elHeight >= this._maxPixelHeight && modifiedElHeight <= contentHeight;
    }

    /**
     * Recalculates the threshold and max pixel height.
     *
     * @private
     */
    private _updateThreshold() {
        this._maxPixelHeight = document.body.clientHeight - RESERVED_VERTICAL_SPACE;
    }

    /**
     * Updates the state and set height, if appropriate.
     *
     * @param {number} contentHeight
     * @private
     */
    private _updateHeightSetting(contentHeight ?: number) {
        if (this._thresholdIsMet) {
            this._unsetHeight(); // unset to reevaluate content accurately
        }
        if (this._thresholdIsMet !== this._isThresholdMet(contentHeight)) {
            this._setHeight();
        }
    }

    /**
     * Sets the height of the sidebar to match the threshold.
     *
     * @private
     */
    private _setHeight() {
        // I need to add 2 pixels to the height, since there's a 1 pixel border that gets added
        // when the scroll bars appear. This keeps it from falling below the threshold as soon
        // as the height is applied.
        this._htmlElement.style.height = (this._maxPixelHeight + 2) + 'px';
        this._thresholdIsMet = true;
    }

    /**
     * Returns the set height of the sidebar to automatically adjust to the content.
     *
     * @private
     */
    private _unsetHeight() {
        this._htmlElement.style.height = 'auto';
        this._thresholdIsMet = false;
    }

}
