import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class CduxTitleService {
    private static baseTitle: string;

    constructor (private title: Title) {
        if (!CduxTitleService.baseTitle) {
            CduxTitleService.baseTitle = title.getTitle();
        }
    }

    public getBaseTitle(): string {
        return CduxTitleService.baseTitle;
    }

    public setBaseTitle(title: string) {
        CduxTitleService.baseTitle = title;
        this.restoreTitle();
    }

    public getTitle(): string {
        return this.title.getTitle();
    }

    public setTitle(title: string) {
        this.title.setTitle(title);
    }

    public setTitlePrefix(prefix: string) {
        this.title.setTitle(prefix + ' ' + CduxTitleService.baseTitle);
    }

    public restoreTitle() {
        this.title.setTitle(CduxTitleService.baseTitle);
    }
}
