interface ICard {
  type: string,
  patterns: number[],
  format: RegExp,
  length: number[],
  cvvLength: number[]
}

const cards: ICard[] = [
  {
    type: 'visa',
    patterns: [4],
    format: /(\d{1,4})/g,
    length: [13, 19],
    cvvLength: [3]
  }, {
    type: 'mastercard',
    patterns: [51, 52, 53, 54, 55],
    format: /(\d{1,4})/g,
    length: [16],
    cvvLength: [3]
  }, {
        type: 'amex',
        patterns: [37, 34],
        format: /(\d{1,4})/g,
        length: [15],
        cvvLength: [4]
  }
];

export class CreditCard {

  public static cards = cards;

  public static getCardFromNumber(number: number | string, amexEnabled?: boolean): ICard {
    let response: ICard;

    const num = (number + '').replace(/\D/g, '');

    this.cards.forEach((card) => {
      if (amexEnabled !== undefined && !amexEnabled && card.type === 'amex') {
        return;
      }
      const refs = card.patterns;
      refs.forEach((ref) => {
        const pattern = ref + '';

        if (num.substr(0, pattern.length) === pattern) {
          response = card;
        }
      });
    });

    return response;
  }

  public static getCardType(number: number | string, amexEnabled?: boolean): string {
    if (!number) {
      return null;
    }

    const card = this.getCardFromNumber(number, amexEnabled);

    if (card !== null && typeof card !== 'undefined') {
      return card.type;
    } else {
      return null;
    }
  }

  public static isCardNumber(value: string, event?: KeyboardEvent): boolean {
    if (event) {
      const digit = event.key;

      if (!/^\d+$/.test(digit)) {
        return false;
      }

      value = (value + digit).replace(/\D/g, '');
    } else {
      value = (value).replace(/\D/g, '');
    }

    const card = this.getCardFromNumber(value);
    let result: boolean;

    if (card) {
      result = value.length <= card.length[card.length.length - 1];
    } else {
      result = value.length <= 19;
    }

    return result;
  }
}
