import { Component, ElementRef, OnDestroy, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil, timeout } from 'rxjs/operators';

import {
    ConfigurationDataService,
    enumConfigurationStacks,
    EzbankDataService,
    enumEZBankTransactionStatus,
    IEZBankTransactionStatus,
    JwtSessionService,
    TranslateService,
    UserEventEnum,
} from '@cdux/ng-common';
import { LoadingService, LoadingDotsComponent } from '@cdux/ng-fragments';

import { CduxRouteUtil } from 'app/shared/common/utils/CduxRouteUtil';
import { enumDepositOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { takeWhileInclusive } from 'app/shared/common/operators/take-while-inclusive.operator';
import { EZBankPageviewStatus } from 'app/shared/funding/shared/enums/ezbank-pageview-status.enum';


// how long to continue polling for transactionStatus
const TRANSACTION_STATUS_TIMEOUT = 30000;

// failure code to report EZBank success but Tote Failure
const EZBANK_TOTE_FAILURE_CODE = '-4';

@Component({
    selector: 'cdux-ezbank',
    styleUrls: [ './ezbank-iframe.component.scss' ],
    templateUrl: './ezbank-iframe.component.html'
})
export class EzbankIframeComponent implements OnInit, OnDestroy {

    @Input() depositAmount: number;
    @Input() displayStyle: enumFundingDisplayStyle;

    @Output() depositTransactionComplete = new EventEmitter<undefined>();

    public readonly _OVERLAY_NAME = 'mazoomaLoadingOverlay';

    public iframeUrl: SafeResourceUrl;
    public loadingComponent = LoadingDotsComponent;
    public isLoading = true;

    protected returnUrl: string;
    protected delimiter = '?';
    protected redirectQueryParams = 'action=mazooma';

    private _unknownErrorCode = 'UNK';
    private _orderId: string;
    private _depositAmount: string;
    private _destroy: Subject<boolean> = new Subject();
    private _pendingStatuses = [
        enumEZBankTransactionStatus.PENDING,
        enumEZBankTransactionStatus.PENDINGAUTHORIZE,
        enumEZBankTransactionStatus.PENDINGINITIATE
    ];
    private _transactionPending = false;
    private _redirectURL: string;

    @ViewChild('mazoomaFrame') mazoomaFrameEl: ElementRef;

    constructor (
        protected configService: ConfigurationDataService,
        protected ezbankDataService: EzbankDataService,
        protected jwtSessionService: JwtSessionService,
        protected loadingService: LoadingService,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected _translateService: TranslateService,
        private _fundingService: FundingService,
        private _eventTrackingService: EventTrackingService
    ) {}

    ngOnInit() {
        /*
         * Check for deposit amount. If it's missing, then this component
         * was not accessed from the deposit sidebar. The user must be
         * redirected to the program with the EZ Bank deposit option open
         * in the sidebar. Otherwise, start the Mazooma process in an iframe.
         */
        if (this.depositAmount || (window.history.state && window.history.state.depositAmount)) {

            this._depositAmount = this.depositAmount || window.history.state.depositAmount;

            if (this.displayStyle !== enumFundingDisplayStyle.FULL_PAGE && window.history.state.finalUrl) {
                const finalUrl = window.history.state.finalUrl;
                this.delimiter = finalUrl.indexOf('?') === -1 ? '?' : '&';
                this._redirectURL = finalUrl + this.delimiter;
            }

            this.returnUrl = CduxRouteUtil.getBaseHref(true) + 'assets/ezbank/finalizeMazooma.html';

            const user = this.jwtSessionService.getUserInfo();

            combineLatest([
                this.ezbankDataService.fetchMazoomaParams(this._depositAmount, user.account, this.returnUrl),
                this.configService.getConfiguration(enumConfigurationStacks.TUX, ['mzma_staging_server', 'mzma_merchant_id'])
            ]).pipe(
                take(1)
            ).subscribe(
                (responses) => {
                    // create a JSON object to pass to the iframe via session storage
                    if (responses[0].status === 'success') {
                        const MazoomaOptions = {
                            'details': responses[0].details,
                            'merchantId': responses[1].mzma_merchant_id,
                            'serverUrl': responses[1].mzma_staging_server,
                        };
                        this._orderId = responses[0].orderId || null;
                        sessionStorage.setItem('MazoomaOptions', JSON.stringify(MazoomaOptions));
                        // resolve loader and show the Mazooma iframe
                        this.isLoading = false;
                        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('assets/ezbank/launchMazooma.html');
                        this._transactionPending = true;
                    } else {
                        // fetchMazoomaQueryParam with error: redirect to Mazooma deposit widget with error:
                        this._fundingService.ezBankErrorCode = 'fallback';
                        this.isLoading = false;
                    }
                },
                (reason) => {
                    console.error('EZ Bank deposit calls failed.', reason);
                    this._fundingService.ezBankErrorCode = 'fallback';
                    this.isLoading = false;
                }
            );
        } else {
            // resolve loader and redirect to homepage with ezbank widget open
            this._redirectURL = '/?';
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this._destroy.next(true);
        this._destroy.complete();
    }

    public monitorIFrameSource() {
        try {
            /*
             * A cross-origin error will be thrown when trying to retrieve the
             * href of an iframe on a different domain. If no error is thrown,
             * we then need to check if the URL matches the return URL
             * provided to Mazooma, meaning the process is complete on their end.
             */
            const iframeSource = this.mazoomaFrameEl.nativeElement.contentWindow.location.href;
            if (iframeSource.indexOf(this.returnUrl) !== -1) {

                this.loadingService.register(this._OVERLAY_NAME);

                this.ezbankDataService.transactionStatus(this._orderId)
                    .pipe(
                        // kill this subscription with impunity when:
                        //     a) component is destroyed, OR
                        //     b) after the timeout has passed

                        // Set timeout to throw an error if the transaction is still pending when the timer expires
                        timeout(new Date(Date.now() + TRANSACTION_STATUS_TIMEOUT)),
                        takeUntil(this._destroy),
                        // takeWhileInclusive used here to satisfy AC#6 of US29769:
                        //      a) continue polling while we're receiving "Pending" statuses
                        //      b) emit the final value, which should be a success/fail status
                        takeWhileInclusive(
                            (status: IEZBankTransactionStatus) => {
                                this._transactionPending = this._pendingStatuses.indexOf(status.transactionStatus) >= 0;
                                return this._transactionPending;
                            },
                            true
                        )
                    ).subscribe(
                        (status: IEZBankTransactionStatus) => {

                            if (!this._transactionPending) {
                                // handle SUCCESS/FAILURE responses, (and ignore "Pending" responses)
                                // initialize some common variables
                                let pageviewStatus = EZBankPageviewStatus.FAILURE;

                                if (status.transactionErrorCode !== null) {
                                    if (status.transactionErrorCode === '99') {
                                        // update pageview status to "CANCELLED"
                                        pageviewStatus = EZBankPageviewStatus.CANCELLED
                                    }
                                    // EZBank cancelled. Update funding service to reflect error.
                                    this._fundingService.ezBankErrorCode = status.transactionErrorCode;
                                    this._logFailureEvent(this._fundingService.ezBankErrorCode);
                                } else if (status.transactionStatus === enumEZBankTransactionStatus.TOTEFAILURE) {
                                    // EZBank deposit succeeded, but Tote balance update failed. Update funding service to reflect error.
                                    this._fundingService.ezBankErrorCode = EZBANK_TOTE_FAILURE_CODE;
                                    this._logFailureEvent(this._fundingService.ezBankErrorCode);
                                } else {
                                    // update pageview status to "SUCCESS"
                                    pageviewStatus = EZBankPageviewStatus.SUCCESS;

                                    // update this.redirectURL to show success message
                                    this.redirectQueryParams = 'action=ezbankSuccess';
                                }

                                // redirect to success or ezbank widget and indicate success or failure
                                if (pageviewStatus === EZBankPageviewStatus.SUCCESS) {
                                    this._resolveLoaderOnSuccess(this._depositAmount);
                                } else {
                                    this._resolveLoader();
                                }
                            }
                        },
                        // uh-oh, got an error
                        _ => {
                            // resolve loader, redirect to ezbank widget, and indicate "unknown error"
                            this._fundingService.ezBankErrorCode = this._unknownErrorCode;
                            this._logFailureEvent(this._fundingService.ezBankErrorCode);
                            this._resolveLoader();
                        },
                        // uh-oh, we must have timed out
                        // This ^^ isn't necessarily true. This code executes when the observer gets a complete notifiction (https://angular.io/guide/observables#subscribing)
                        // so this is executing regardless of timeout, failure, or success. The issue was swallowed before but now is a problem because of how we're
                        // passing error codes.
                        () => {
                            // on a successful deposit this code runs twice. Once in the next block and here, so only fire if transaction is pending
                            if (this._transactionPending) {
                                // resolve loader, redirect to ezbank widget, and indicate "unknown error"
                                this._resolveLoader();
                            }
                        }
                    );
            }
        } catch (e) {}
    }

    private _logFailureEvent(errorCode: any) {
        const code = 'ezbank-' + errorCode;
        const deposit_failure_event = {
            'firstTimeDeposit': this._fundingService.isFirstTimeDeposit(),
            'depositMethod':  enumDepositOptions.EZ_BANK,
            'depositAmount': this._depositAmount,
            'failReason': this._translateService.translate(code, 'errorcodes', true),
            'osVersion': this._eventTrackingService.getOsVersion(),
            // balance isn't returned on failed attempts so send most recent value
            'userBalance': this._fundingService.accountBalance,
            'status': 'fail',
            'action': 'complete'
        };
        this._eventTrackingService.logUserEvent(UserEventEnum.DEPOSIT_FAILED, deposit_failure_event);
    }

    private _resolveLoaderOnSuccess(depositAmount: string) {
        this.loadingService.resolve(this._OVERLAY_NAME).then(
            () => {
                if (this.displayStyle === enumFundingDisplayStyle.FULL_PAGE) {
                    this.depositTransactionComplete.emit();
                } else {
                    this.router.navigateByUrl(this._redirectURL + this.redirectQueryParams, {
                        state: {
                            depositAmount
                        }
                    });
                }
            }
        );
    }

    private _resolveLoader() {
        this.loadingService.resolve(this._OVERLAY_NAME).then(
            () => {
                if (this.displayStyle === enumFundingDisplayStyle.FULL_PAGE) {
                    this.depositTransactionComplete.emit();
                } else {
                    this.router.navigateByUrl(this._redirectURL + this.redirectQueryParams);
                }
            }
        );
    }
}
