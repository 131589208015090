import {
    enumFeatureToggle,
    FeatureToggleDataService,
    JwtSessionService,
} from '@cdux/ng-common';
import { TemplateRef, Directive, ViewContainerRef, AfterViewInit } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { Portal, TemplatePortal } from '@angular/cdk/portal';

export enum enumFavoriteToggleState {
    ON = 'Remove from', // when it's favorited, action text should be "remove"
    OFF = 'Add to',
}


@Directive()
export abstract class FavoriteToggleAbstractComponent implements AfterViewInit {
    // @Input using appropriate interface
    public abstract favoriteData;
    // @ViewChild('confirmationModal') from child component
    protected abstract confirmationModal: TemplateRef<unknown>;

    public readonly COUNT_MAX = 4096;
    // expose enum to template
    public readonly TOGGLE_STATE = enumFavoriteToggleState;

    public countCurrent = 0;
    public isEnabled: boolean = false;
    public isToggling: boolean = false;
    public modalContent: Portal<unknown>;
    protected templatePortal: TemplatePortal<any>;
    private _overlayRef: OverlayRef;

    protected _currentState: enumFavoriteToggleState = enumFavoriteToggleState.OFF;
    public get currentState (): enumFavoriteToggleState {
        return this._currentState;
    }

    public get note () {
        return this.favoriteData?.comment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.favoriteData.comment = value;
        }
    }


    protected constructor (
        localFeatureToggleService: FeatureToggleDataService,
        localJwtSessionService: JwtSessionService,
        protected viewContainerRef: ViewContainerRef,
    ) {
        this.isEnabled = localJwtSessionService.isLoggedIn() &&
            localFeatureToggleService.isFeatureToggleOn(enumFeatureToggle.STABLE_ALERTS);
    }

    public ngAfterViewInit() {
        this.templatePortal = new TemplatePortal(
            this.confirmationModal,
            this.viewContainerRef
        );
    }

    public dismiss (): void {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public storeOverlay (overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }


    protected turnOn () {
        this._currentState = enumFavoriteToggleState.ON;
    }

    protected turnOff () {
        this._currentState = enumFavoriteToggleState.OFF;
    }

    public abstract confirm (): void;
    public abstract toggleFavorite (toggleState: enumFavoriteToggleState): void;
}
