import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, GreenDotDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { AbstractGreendotATRDepositComponent } from './abstract-greendot-atr-deposit.component';
import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service';
import { UntypedFormBuilder } from '@angular/forms';
import { FundingService } from '../../../shared/services/funding.service';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { FullPageFundingConstants } from '../../../full-page-funding/full-page-funding.constants';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-greendot-atr-deposit-fullpage',
    templateUrl: './greendot-atr-deposit-fullpage.component.html',
    styleUrls: ['./greendot-atr-deposit-fullpage.component.scss']
})
export class GreendotATRDepositFullPageComponent extends AbstractGreendotATRDepositComponent implements OnInit {

    protected readonly isFullPage: boolean = true;
    public isSmallGlass = false;
    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(GreendotATRDepositFullPageComponent, options);
    }

    constructor(
        localRouter: Router,
        localEventService: EventTrackingService,
        localEnvironment: ENVIRONMENT,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService,
        protected greenDotDataService: GreenDotDataService,
        protected betSlipService: BetSlipBusinessService,
        protected changeDetector: ChangeDetectorRef,
        protected route: ActivatedRoute,
        private _mediaToggle: CduxMediaToggleService,
    ) {
        super(
            localRouter,
            localEventService,
            localEnvironment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            localToggleService
        );
    }

    public ngOnInit () {
        this.isSmallGlass = this._mediaToggle.query('phone');
        // Since GreendotATR navigates away from the app, we copy the redirect URL from
        // memory to session storage so we can access it when we return to the app.
        if (this._fundingService.postDepositRedirectURL) {
            sessionStorage.setItem('postDepositRedirectURL', this._fundingService.postDepositRedirectURL);
        }
        // Store current bet in bet slip and save the ID to session storage for reference
        if (this.betSlipService.currentBet) {
            sessionStorage.setItem('postDepositReturningBetId', this.betSlipService.currentBet.id);
            this.betSlipService.storeBet(this.betSlipService.currentBet);
        }
        if ('greendot' in this.route.snapshot.queryParams && this.route.snapshot.queryParams.greendot === 'submitted') {
            this.displayError = true;
        }
    }

    public navigateToGreenDot() {
        const url = new URL(location.href);
        url.searchParams.delete('action');
        url.searchParams.delete('greendot');
        let backUrl = url.href; // The backUrl without 'action', 'greendot' in params, this is a "Back" button prevention step
        url.searchParams.append('action', FullPageFundingConstants.GREENDOT_FP_DEEPLINK_ACTION);

        this.greenDotDataService.fetchGreenDotToken(url.href)
            .subscribe(
                (result) => {
                    this.requestToken = result.Response.token;
                    this.formUrl = result.Response.actionUrl;
                    this.partnerId = result.Response.partnerId;
                    // force form template values to update before submit
                    this.changeDetector.detectChanges();

                    /*
                     * Before submitting the form and "navigating" to
                     * the Green Dot-hosted pages, modify the history
                     * to update the state of this page. That way, GD's
                     * "back" button will instruct this page to show
                     * an error.
                     */
                    if (backUrl.indexOf('?') === -1) {
                        backUrl += '?greendot=submitted';
                    } else {
                        backUrl += '&greendot=submitted';
                    }
                    window.history.pushState({ greenDotSubmitted: true }, '', backUrl);
                    this.greenDotFormEl.nativeElement.submit();
                },
                (reason) => {
                     console.error('Green Dot ATR token call failed.', reason);

                    /*
                     * We don't have an active Green Dot "tile" in the
                     * deposit options (the removed
                     * FundingGreendotMethodDepositComponent), so there's
                     * no way to return to the deposit list with Green Dot
                     * selected and an error shown. For now, just use this
                     * page to display the error.
                     */
                    this.displayError = true;
                }
            );
    }
}
