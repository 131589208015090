import { Component, OnInit } from '@angular/core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';


@Component({
    selector: 'cdux-ezmoney-modal',
    templateUrl: './ezmoney-modal.component.html',
    styleUrls: ['./ezmoney-modal.component.scss']
})
export class EzmoneyModalComponent implements OnInit {
    public isSmallGlass = false;
    public isReVerify: boolean = false;
    private _context: any;

    public set context(context: any) {
        if (!!context) {
            this._context = context;
            this.isReVerify = this._context.isReVerify;
        }
    }

    constructor(
        private _mediaToggle: CduxMediaToggleService,
    ) { }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
    }

  public closeModal(confirmed?: boolean) {
   this._context.close(confirmed);
  }

  public action(confirmed?: boolean) {
    this._context.action(confirmed);
  }

}
