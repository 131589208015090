import { NgModule } from '@angular/core';
import { FormatWPSPoolPercentPipe } from './format-wps-pool-percent.pipe';
import { FormatWPSPoolPipe } from './format-wps-pool.pipe';

@NgModule({
    declarations: [
        FormatWPSPoolPercentPipe,
        FormatWPSPoolPipe,
    ],
    imports: [],
    providers: [],
    exports: [
        FormatWPSPoolPercentPipe,
        FormatWPSPoolPipe,
    ]
})
export class PoolsPipesSharedModule {}
