<cdux-wagering-section-nav [displayMode]="displayMode" [view]="view" [section]="section"
    [isRaceWagerable]="isWagerableRace()" [selectedRaceNav]="wagerState?.basicTrack" (selection)="selectSection($event)">
</cdux-wagering-section-nav>
<ng-container [ngSwitch]="true">
    <cdux-wagering-section-group *ngSwitchDefault>
        <cdux-program-section #sectionWidget
            [raceStatus]="raceStatus"
            [isRaceClosed]="isRaceClosed"
            [wagerState]="wagerState"
            [section]="section"
            [view]="view"
            [displayMode]="displayMode"
            (updateSection)="selectSection($event)"
            (updateRaceNav)="updateRaceNav.emit($event)"
            (updateBetNav)="updateBetNav.emit($event)"
            (updateBetType)="updateBetType.emit($event)"
            (updateBetAmount)="updateBetAmount.emit($event)"
            (updateBetModifier)="updateBetModifier.emit($event)"
            (onSelectionChange)="onSelectionChange.emit($event)"
            (onResetEntrySelections)="onResetEntrySelections.emit($event)">
        </cdux-program-section>
    </cdux-wagering-section-group>

    <cdux-wagering-section-group *ngSwitchCase="isSection(viewSectionPoolsGroup)">
        <cdux-pools-section #sectionWidget cduxDisplayModes [displayMode]="displayMode" [section]="section" [track]="track" [race]="race" (updateSection)="selectSection($event)">
        </cdux-pools-section>
    </cdux-wagering-section-group>

    <cdux-wagering-section-group *ngSwitchCase="showPPs && isSection(ViewSectionEnum.PAST_PERFORMANCES)">
        <cdux-past-performances #sectionWidget cduxDisplayModes [displayMode]="displayMode" [selectedRaceNavigation]="wagerState?.basicTrack"></cdux-past-performances>
    </cdux-wagering-section-group>

    <cdux-wagering-section-group *ngSwitchCase="isStatsEnabled && isSection(viewSectionStatsGroup)">
        <cdux-stats-section #sectionWidget cduxDisplayModes [displayMode]="displayMode" [section]="section" [track]="track" [race]="race" (updateSection)="selectSection($event)">
        </cdux-stats-section>
    </cdux-wagering-section-group>

    <cdux-wagering-section-group *ngSwitchCase="isSection(viewSectionResultsGroup)">
        <cdux-results-section #sectionWidget cduxDisplayModes 
            [wagerState]="wagerState"
            [raceStatus]="raceStatus"
            [displayMode]="displayMode"
            [section]="section"
            [track]="track"
            [race]="race"
            (updateSection)="selectSection($event)">
        </cdux-results-section>
    </cdux-wagering-section-group>

</ng-container>
