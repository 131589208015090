import { Component } from '@angular/core';
import { CduxAbstractSidebarComponent } from '../cdux-sidebar-component.class';
import { ISidebarComponentProperties, ISidebarHeaderComponent } from '../interfaces/sidebar-portal-component.interface';

@Component({
    selector: 'cdux-sidebar-title-header',
    templateUrl: './cdux-sidebar-title-header.component.html',
    styleUrls: ['./cdux-sidebar-title-header.component.scss']
})
export class CduxSidebarTitleHeaderComponent extends CduxAbstractSidebarComponent {
    public headerTitle: string;

    /*********************************
     * SidebarComponent Implementation
     *********************************/
    public static getSidebarComponent(title: string): ISidebarHeaderComponent {
        const inputTokens: Map<string, any> = new Map<string, any>();
        inputTokens.set('header-title', title);
        const options = {
            inputs: inputTokens
        }
        return {
            component: CduxSidebarTitleHeaderComponent,
            properties: options
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.headerTitle = properties.inputs.get('header-title');
    }
    /*********************************/

}
