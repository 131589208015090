import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { EventSessionModule } from '@cdux/ng-common';
import { CduxMediaModule, CduxModalModule } from '@cdux/ng-platform/web';
import { LoadingModule } from '@cdux/ng-fragments';
import { TuxCommonModule } from '../common/common.module';

import { SidebarService } from './sidebar.service';
import { CduxSidebarComponent } from './components/cdux-sidebar.component';
import { CduxSidebarHeaderComponent } from './components/cdux-sidebar-header.component';
import { CduxSidebarPropertiesDirective } from './directives/cdux-sidebar-properties.directive';
import { CduxSidebarTitleHeaderComponent } from './components/cdux-sidebar-title-header.component';


@NgModule({
    declarations: [ CduxSidebarComponent, CduxSidebarHeaderComponent, CduxSidebarPropertiesDirective, CduxSidebarTitleHeaderComponent],
    imports: [CommonModule, PortalModule, TuxCommonModule, LoadingModule, EventSessionModule, CduxMediaModule, CduxModalModule],
    exports: [ PortalModule, LoadingModule, CduxSidebarComponent, CduxSidebarPropertiesDirective ]
})
export class SidebarModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<SidebarModule> {
        return {
            ngModule: SidebarModule,
            providers: [
                SidebarService,
            ],
        }
    }
}
