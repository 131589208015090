import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { TemplatePortal, ComponentType } from '@angular/cdk/portal';
import { Subscription, combineLatest } from 'rxjs';
import { take, distinctUntilChanged } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    Bet,
    EventClickType,
    EventsService,
    IRaceIdentifier,
    Menu,
    JwtSessionService,
    CduxObjectUtil,
    TranslateService,
} from '@cdux/ng-common';
import { ModalService, CduxModalConfig, ModalRef, TooltipComponent, CduxMediaToggleService } from '@cdux/ng-platform/web';

import { LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';
import { ActiveDrawerEnum } from '../../common/services';

// services
import { BetSlipBusinessService } from '../../bet-slip/services/bet-slip.business.service';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { TourService } from '../../tour/services';
import { IMobileHeaderConfig } from '../models';
import { HeaderService } from '../services/header.service';
import {  EventKeys } from 'app/shared/common';
import { DrawerService } from '../../../shared/common/services/drawer/drawer.service';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { TournamentsNotAvailableModalService } from 'app/shared/tournaments-session/services/tournaments-not-available-modal.service';

@Component({
    selector: 'cdux-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Output()
    public depositClick = new EventEmitter<void>();

    // Tooltip component for Tour Reminder
    @ViewChild('tipTour') private _tipTour: TooltipComponent;
    // Burger Menu Template Portal
    @ViewChild('burgerMenu') burgerMenu: TemplatePortal<any>;

    // Data Store for Phone Flag
    public isPhone = false;

    // Expose EventClickType so we can use it in the template for click events
    public eventClickType = EventClickType;
    // Data Store for Active User Session Flag
    public isLoggedIn: boolean = this._sessionService.isLoggedIn();
    // Data Store for Burger Menu State Flag
    public burgerMenuOpened: boolean = false;
    // Data Store for Center Column Typoe
    public centerColumnDataType: 'Logo' | 'Portal' | 'String' = 'Logo';
    // Data Store for Center Column String Content
    public centerColumnData: string;

    public menu: Menu[] = [];

    // Most Recent Portals to be Attached
    public get portal(): ComponentType<any> | TemplatePortal<any> {
        return this._portal;
    }
    public get portalSub(): ComponentType<any> | TemplatePortal<any> {
        return this._portalSub;
    }

    protected get topLevelRoute(): boolean {
        return this._headerService.topLevelRoute;
    }

    protected showVideoButton: boolean = false;

    protected staticRaceData: IRaceIdentifier;
    // Determines if the mobile button should show as active
    public isVideoOpen: boolean = false;
    public tournamentSelected: boolean = false;

    /**
     * Create a collection of Subscriptions to allow for easy garbage collection
     */
    private _subscriptions: Subscription[] = [];
    // Reference to the Burger Menu Modal
    private _burgerMenuRef: ModalRef<any>;
    // Subscription for Media Service
    private _burgerMenuMediaSub: Subscription;
    // Backing Data Store for Portals
    private _portal: ComponentType<any> | TemplatePortal<any> = null;
    private _portalSub: ComponentType<any> | TemplatePortal<any> = null;
    
    constructor(
        public eventsService: EventsService,
        private _environment: ENVIRONMENT,
        private _sessionService: JwtSessionService,
        private _betSlipService: BetSlipBusinessService,
        private _eventTrackingService: EventTrackingService,
        private _router: Router,
        private _tourService: TourService,
        private _modalService: ModalService,
        private _mediaService: CduxMediaToggleService,
        private _headerService: HeaderService,
        private _drawerService: DrawerService,
        private _tournamentSession: TournamentsSessionService,
        private _tournamentModal: TournamentsNotAvailableModalService,
        private _translateService: TranslateService,
    ) { }

    public ngOnInit() {
        this._subscriptions.push(

            // Watch for tour page reminder tooltip
            this._tourService.onTooltip.subscribe((value: boolean) => {
                if (this._tipTour) {
                    this._tipTour.click();

                    setTimeout(() => {
                        // If the user has not manually closed the tooltip, do it via a timeout
                        if (this._tipTour.show) {
                            this._tipTour.click();
                        }
                    }, 3000);
                }
            }),

            this._sessionService.onAuthenticationChange.subscribe((isLoggedIn) => {
                this.isLoggedIn = isLoggedIn;
            }),
            combineLatest([
                this._headerService.headerChange,
                this._mediaService.registerQuery('phone').pipe(distinctUntilChanged())
            ]).subscribe(([headerConfig]) => {
                this._loadMobileHeaderConfig(headerConfig);
            }),
            this.eventsService.on(EventKeys.VIDEO_PRIMARY_OPEN).subscribe(() => this.isVideoOpen = true), // TODO: combin into one observable
            this.eventsService.on(EventKeys.VIDEO_PRIMARY_CLOSE).subscribe(() => this.isVideoOpen = false), // TODO: improve event service to send back event type
            this._tournamentSession.onTournamentSelection.subscribe((res) => this.tournamentSelected = res ? true : false)
        );
    }

    public ngOnDestroy() {
        // Unsubscribe from all active Subscriptions in the collection
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public goBack() {
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_BACK);
        this.isVideoOpen = false;
        this._headerService.goBack();
    }

    public clearRunners(): void {
        const bet = Bet.fromBetlike(this._betSlipService.currentBet);
        bet.id = null;
        bet.runners = [];
        bet.betCreatedTimestamp = null;
        this._betSlipService.currentBet = bet;
    }

    public login(): void {
        this._sessionService.redirectLoggedInUserUrl = this._router.url;
        this._router.navigate([ LOGIN_REDIRECT_PATH ]);
    }

    /**
     * Toggles the Mobile Burger Menu
     */
    public toggleBurgerMenu(state: boolean) {
        if ((this.burgerMenuOpened && state !== true)
            || state === false) {
            this._burgerMenuRef.close();
        } else {
            const modalConfig = new CduxModalConfig();
            const bodyHeight = document.body.getBoundingClientRect().height - 60;
            modalConfig.maxWidth = '100vw';
            modalConfig.minWidth = '100vw';
            modalConfig.height = bodyHeight + 'px';
            modalConfig.maxHeight = bodyHeight + 'px';
            modalConfig.minHeight = bodyHeight + 'px';
            modalConfig.heightFn = () => {
                return document.body.getBoundingClientRect().height - 60 + 'px'
            };
            modalConfig.padding = '0px';
            modalConfig.hasBackdrop = false;
            modalConfig.position = {
                top: '60px',
                left: '0px',
                right: '0px',
                bottom: '0px'
            };
            this._burgerMenuRef = this._modalService.open(
                this.burgerMenu.templateRef,
                modalConfig
            );
            this.burgerMenuOpened = true;
            // Closes the Burger Menu if the Screen is resized above Phone width
            this._burgerMenuMediaSub = this._mediaService.registerQuery('phone')
                .subscribe((match: boolean) => {
                    this.isPhone = match;
                    if (!match) {
                        this._burgerMenuRef.close();
                    }
                });
            // Immediately reverts the icon
            this._burgerMenuRef.beforeClosed.pipe(
                take(1)
            ).subscribe(() => {
                this.burgerMenuOpened = false;
            });
            // Cleans up when the Burger Menu closes
            this._burgerMenuRef.afterClosed.pipe(
                take(1)
            ).subscribe(() => {
                if (this._burgerMenuMediaSub) {
                    this._burgerMenuMediaSub.unsubscribe();
                    this._burgerMenuMediaSub = null;
                }
                this.burgerMenuOpened = false;
            });
        }
    }

    public toggleVideo() {
        if (this.isVideoOpen) {
            this.eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
            this._drawerService.activeDrawer = ActiveDrawerEnum.NONE;
        } else {
            if (this.staticRaceData) {
                this.eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN,
                    <IRaceIdentifier>{
                        brisCode: this.staticRaceData.brisCode,
                        trackType: this.staticRaceData.trackType,
                        raceNumber: this.staticRaceData.raceNumber,
                        raceDate: this.staticRaceData.raceDate
                    }
                );
            } else {
                this.eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN);
            }
            this._drawerService.activeDrawer = ActiveDrawerEnum.VIDEO;
        }
    }


    public onMobileLogoClicked() {
        this.onLogoClicked();
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_HAMBURGER_LOGO);
        this._modalService.closeAll();
        this.clearRunners();
    }

    public onLogoClicked() {
        const _window: any = (<any>window);
        let hostname: string;
        if (_window.location.host.indexOf('localhost') >= 0) {
            hostname = 'https://dev.' + this._environment.domain + '/';
        } else {
            hostname = 'https://' + _window.location.host + '/';
        }
        if (this.tournamentSelected) {
            this._tournamentModal.showModal({
                title: this._translateService.translate('tournament-modal-redirect-homepage-title', this._environment.affiliateId.toString()),
                destinationPath: hostname,
                notice: this._translateService.translate('tournament-modal-redirect-homepage-subTitle', this._environment.affiliateId.toString()),
            });
            return;
        }
        _window.location.href = hostname;
    }

    public onDepositClicked() {
        this.depositClick.emit();
    }

    /**
     * @internal
     */
    protected _onAttached(ref: ComponentRef<any>) {
        const inputs = CduxObjectUtil.deepGet(this._headerService.config, 'dynamicRef.inputs');

        if (inputs) {
            Object.keys(inputs).forEach((key: string) => {
                if (ref.instance && ref.instance.hasOwnProperty(key)) {
                    ref.instance.key = inputs.key;
                }
            });
        }

        this._headerService._attached(ref);
    }

    /**
     * Loads the config on potential header updates
     * if no config is passed in, then the header defaults
     *
     * @param config
     */
    private _loadMobileHeaderConfig(config: IMobileHeaderConfig | null) {
        // if config is passed in, set the header appropriately
        if (config) {
            if (config.dynamicRef) {
                // if the portal is present set it
                this._portal = config.dynamicRef.componentOrTemplateRef;
                this._portalSub = null;
                this.centerColumnDataType = 'Portal';

                // check for sub-header
                if (config.dynamicRefSub) {
                    this._portalSub = config.dynamicRefSub.componentOrTemplateRef;
                }
            } else {
                // otherwise, clear portal
                this._portal = null;
                this._portalSub = null;
            }
            if (config.showVideoButton) {
                // load the provided option for the video button
                this.showVideoButton = config.showVideoButton;
            } else {
                // if no option is present or false, default to false, close video
                this.showVideoButton = false;
                this.eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
            }
            if (config.staticContent) {
                // if static content option is present, set it
                this.centerColumnData = config.staticContent;
                this.centerColumnDataType = 'String';
            }
            if (!config.dynamicRef && !config.staticContent) {
                this.centerColumnDataType = 'Logo';
            }
            this.staticRaceData = config.staticRaceData;
        } else {
            // if no config is passed in, reset to the default header
            this._headerService.setDefaultHeader();
            this.centerColumnDataType = 'Logo';
        }
    }
}
