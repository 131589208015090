import { EventClickType, WindowRefService } from '@cdux/ng-common';
import { ToastRef, ToastService } from '@cdux/ng-fragments';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { ModalRef, ModalService } from '@cdux/ng-platform/web';
import { NotificationComponent } from '../notification.component';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { CduxRouteUtil } from 'app/shared/common';

@Injectable({
    providedIn: 'root'
})
export class ConnectivityService {

    public eventClickType = EventClickType;

    constructor(private _eventTrackingService: EventTrackingService,
                private _windowRef: WindowRefService,
                private _modalService: ModalService,
                private _toastService: ToastService,
                private _httpClient: HttpClient) {}

    public openConnectivityModal(): ModalRef<NotificationComponent> {
        return this._modalService.open(NotificationComponent, { disableClose: true, context: {
            heading: 'Lost connection',
            message: 'Please refresh to reconnect.',
            actionText: 'Refresh',
            eventType: this.eventClickType.LOST_CONNECTIVITY,
            close: () => { this._modalService.closeAll(); },
            action: () => { this._windowRef.nativeWindow.location.reload() }
        }});
    }

    public openToastNotification(): ToastRef {
        return this._toastService.open('Lost connection.  Please refresh to reconnect.', {
            panelClass: ['warning', 'warning-action'],
            action: 'Refresh',
            hideCheck: true,
            actionFn: () => {
                this._eventTrackingService.logClickEvent(this.eventClickType.LOST_CONNECTIVITY);
                this._windowRef.nativeWindow.location.reload();
            }
        })
    }

    public isOnline(): Observable<boolean> {
        const isOnline = this._windowRef.nativeWindow.navigator.onLine;
        if (isOnline) {
            return this._ping();
        } else {
            return of(false);
        }
    }

    private _ping(): Observable<boolean> {
        const url = CduxRouteUtil.getBaseHref(true) + 'assets/connectivity/checkConnection.gif?cb=' + Math.floor((1 + Math.random()) * 0x10000);
        return this._httpClient.get(url, { responseType: 'blob' }).pipe(map(response => {
            return !!response;
        }), catchError(() => {
            return of(false);
        }));
    }
}
