import { Component, ContentChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'cdux-wagering-section-group',
    template: `
        <div class="wagering-section-group-nav">
            <ng-content select="[section-nav]"></ng-content>
            <ng-container *ngTemplateOutlet="offloadedTemplate"></ng-container>
        </div>

        <div class="wagering-section-group">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./section-group.component.scss']
})
export class WageringSectionGroupComponent {
    @ContentChild('sectionWidget')
    public set sectionWidget(ref: any) {
        this.offloadedTemplate = ref && ref.offloadedTemplate || null;
    }

    public offloadedTemplate: TemplateRef<any>;
}
