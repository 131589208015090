import { AfterViewInit, ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    CARD_DEPOSIT_CODES,
    EventClickType,
    ICreditcardDepositRequest,
    InputOrnamentTypeEnum,
    IOrnamentConfig, ITokenizedCreditcardDepositRequest, TranslateService, FeatureToggleDataService, JwtSessionService
} from '@cdux/ng-common';

import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { enumDepositOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { FundingValidators } from 'app/shared/funding/shared/validators/funding.validators';
import { CreditCard } from 'app/shared/funding/shared/utils/creditcard';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { FundingCreditcardService } from 'app/shared/funding/shared/services/creditcard.service';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FundingAbstractMethodDepositComponent } from 'app/shared/funding/components/methods/abstract-method-deposit.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { HeaderService } from 'app/shared/header/services/header.service';

const visaOrnament: IOrnamentConfig = {type: InputOrnamentTypeEnum.ICON, value: 'icon--visa'};
const mcOrnament: IOrnamentConfig = {type: InputOrnamentTypeEnum.ICON, value: 'icon--mc'};
const amexOrnament: IOrnamentConfig = {type: InputOrnamentTypeEnum.ICON, value: 'icon--amex'};

@Directive()
export class CreditcardMethodDepositAbstractComponent extends FundingAbstractMethodDepositComponent implements OnInit, AfterViewInit {
    protected isFullscreen: boolean;

    // Determine if we want to show the existing card information on the CC form
    public showCurrentCardTemplate: boolean = false;

    // Determine if we have focus on the CVV field so we can properly apply tooltip class(es)
    public isCvvFocused: boolean = false;

    // Error Handling
    public ERROR_CODES = CARD_DEPOSIT_CODES;

    // Classes Applied to Card Number
    public cardClasses: string[] = [];

    // Input Icons
    public icons: IOrnamentConfig[] = [];

    public cvvToolTipContent = 'cvv-tooltip-content';

    public cvvMask = {
        mask: [/[0-9]/, /[0-9/]/, /[0-9/]/],
        placeholder: '_'
    };

    // Input Masks
    public ccMask = {
        mask: [
            /[0-9]/, /[0-9/]/, /[0-9/]/, /[0-9/]/, ' ',
            /[0-9]/, /[0-9/]/, /[0-9/]/, /[0-9/]/, ' ',
            /[0-9]/, /[0-9/]/, /[0-9/]/, /[0-9/]/, ' ',
            /[0-9]/, /[0-9/]/, /[0-9/]/, /[0-9/]/, ' ',
            /[0-9]/, /[0-9/]/, /[0-9/]/
        ],
        guide: false,
        placeholder: '_'
    };

    public ccExpMask = {
        mask: [/[0-1]/, /\d/, '/', /\d/, /\d/],
        placeholder: '_',
        guide: true,
    };

    public cvvPattern = '^[0-9]{3}';

    public showCvvField: boolean = true;
    public badBinError: boolean = false;

    public get disabledState(): boolean {
        return this._disabledState;
    }

    public fundingErrorMessages = FundingValidators.fundingValidationErrorMessages;

    private _disabledState: boolean = true;

    private readonly _cvvMaskConfig = {
        default: [/[0-9]/, /[0-9/]/, /[0-9/]/],
        amex: [/[0-9]/, /[0-9/]/, /[0-9/]/, /[0-9/]/]
    };

    public get bBList(): number[] {
        return this._bBList;
    }
    private _bBList: number[] = [];

    constructor(
        protected _router: Router,
        protected _changeDetectorRef: ChangeDetectorRef,
        protected _eventService: EventTrackingService,
        protected _translateService: TranslateService,
        protected _creditCardService: FundingCreditcardService,
        protected _currencyPipe: CurrencyPipe,
        localSessionService: JwtSessionService,
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _headerService: HeaderService,
        _featureToggleService: FeatureToggleDataService,
    ) {
        super(
            _router,
            _headerService,
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _eventService,
            _translateService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumDepositOptions.CREDIT_CARD;
    }

    public ngOnInit() {
        // This functionality is part of the full page deposit epic and is, therefore, hidden behind it's toggle.
        this.showCurrentCardTemplate = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
        // Update tool tip based on Amex enabled or not
        this.cvvToolTipContent = this.amexEnabled ? 'cvv-tooltip-content-plus-amex' : 'cvv-tooltip-content';
        const fieldRequired = !this.fundingMethodDetails.accountInfo || this.replaceAccountInfo;
        // We get the CC blocked BINs here:
        this._creditCardService.getBBList().pipe(
            take(1)
        ).subscribe((data) => {
            this._bBList = data;
        });
        this._initForm([
            {
                name: 'amount',
                default: this.getInitialDepositAmount(),
                validators: [
                    Validators.min(this.getMinDepositAmount(this.hasVerifiedAccount)),
                    Validators.max(this.getMaxDepositAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            },
            {
                name: 'cardNumber',
                default: '',
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired),
                    FundingValidators.validateCCNumber(this.amexEnabled),
                ]
            },
            {
                name: 'expiration',
                default: '',
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired),
                    FundingValidators.validateExpDate(),
                ]
            },
            {
                name: 'cvv',
                default: '',
                validators: [
                    Validators.required,
                    Validators.maxLength(this.cvvMask.mask.length),
                    Validators.minLength(this.cvvMask.mask.length)
                ]
            },
            {
                name: 'billAddressDiff',
                default: false,
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired),
                ]
            },
            {
                name: 'address',
                default: '',
                validators: [],
            },
            {
                name: 'city',
                default: '',
                validators: [],
            },
            {
                name: 'state',
                default: '',
                validators: [],
            },
            {
                name: 'zipCode',
                default: '',
                validators: [],
            },
        ]);
        this._subscriptions.push(
            this.form.get('cardNumber').valueChanges.subscribe((value) => {
                const cardType = CreditCard.getCardType(value, this.amexEnabled) || 'unknown';
                if (this.cardClasses.indexOf('is-' + cardType) === -1) {
                    CreditCard.cards.map((cd) => {
                        const index = this.cardClasses.indexOf('is-' + cd.type);
                        this.cardClasses.splice(index, 1);
                    });
                    const unknownIndex = this.cardClasses.indexOf('is-unknown');
                    this.cardClasses.splice(unknownIndex, 1);
                    this.cardClasses.push('is-' + cardType);
                }
                const currentMask = this.cvvMask.mask;
                switch (cardType) {
                    case 'visa':
                        this.icons = [visaOrnament];
                        this.cvvMask.mask = this._cvvMaskConfig.default;
                        this.cvvPattern = '^[0-9]{3}';
                        break;
                    case 'mastercard':
                        this.icons = [mcOrnament];
                        this.cvvMask.mask = this._cvvMaskConfig.default;
                        this.cvvPattern = '^[0-9]{3}';
                        break;
                    case 'amex':
                        this.icons = [amexOrnament];
                        this.cvvMask.mask = this._cvvMaskConfig.amex;
                        this.cvvPattern = '^[0-9]{4}';
                        break;
                    default:
                        this.icons = [];
                        this.cvvMask.mask = this._cvvMaskConfig.default;
                        this.cvvPattern = '^[0-9]{3}';
                        break;
                }
                if (currentMask.length !== this.cvvMask.mask.length) {
                    this.resetCvvField(true);
                } else {
                    this._changeDetectorRef.detectChanges();
                }

                const parsedBin = value.replace(' ', '').substring(0, 6);
                this.badBinError = parsedBin.length === 6 && this._bBList.includes(parseInt(parsedBin, 10))
            }),
            this.form.get('billAddressDiff').valueChanges.subscribe((state) => {
                if (state) {
                    this.form.get('address').setValidators([Validators.required]);
                    this.form.get('city').setValidators([Validators.required]);
                    this.form.get('state').setValidators([Validators.required]);
                    this.form.get('zipCode').setValidators([Validators.required]);
                } else {
                    this.form.get('address').clearValidators();
                    this.form.get('address').reset();
                    this.form.get('city').clearValidators();
                    this.form.get('city').reset();
                    this.form.get('state').clearValidators();
                    this.form.get('state').reset();
                    this.form.get('zipCode').clearValidators();
                    this.form.get('zipCode').reset();
                }
                this._changeDetectorRef.detectChanges();
            }),
            this.form.statusChanges.subscribe(() => {
                if (this._disabledState !== (this.form.invalid || this.form.get('amount').value <= 0)) {
                    this._disabledState = (this.form.invalid || this.form.get('amount').value <= 0);
                    this._changeDetectorRef.detectChanges();
                }
            })
        );
    }

    public ngAfterViewInit() {
        const currentMask = this.cvvMask.mask;
        switch (this.fundingMethodDetails.cardTypeID) {
            case 1:
            case 2:
            default:
                this.cvvMask.mask = this._cvvMaskConfig.default;
                this.cvvPattern = '^[0-9]{3}';
                break;
            case 4:
                this.cvvMask.mask = this._cvvMaskConfig.amex;
                this.cvvPattern = '^[0-9]{4}';
                break;
        }
        if (currentMask.length !== this.cvvMask.mask.length) {
            this.resetCvvField();
        }

        // Re-evaluate content size to make sure method header and back arrow are pinned
        // when the component loads.
        this.communicateContentSizeChange();
    }

    public get hasVerifiedAccount(): boolean {
        return !this.replaceAccountInfo && (!!this.fundingMethodDetails.accountInfo || this.fundingMethodDetails['token'] !== undefined);
    }

    public resetCvvField(newCard: boolean = false) {
        const cvvFormField = this.form.get('cvv');
        const touched = cvvFormField.touched;
        cvvFormField.clearValidators();
        cvvFormField.setValue('');
        cvvFormField.setValidators([
            Validators.required,
            Validators.maxLength(this.cvvMask.mask.length),
            Validators.minLength(this.cvvMask.mask.length)
        ]);
        this.showCvvField = !this.showCvvField;
        this._changeDetectorRef.detectChanges();
        this.showCvvField = !this.showCvvField;
        this._changeDetectorRef.detectChanges();
        if (touched) {
            cvvFormField.markAsTouched({onlySelf: true});
        }
        cvvFormField.updateValueAndValidity();
    }

    public toggleCvvFocus(shouldFocus) {
        this.isCvvFocused = shouldFocus;
    }

    public cvvTooltipClick() {
        this._eventService.logClickEvent(EventClickType.DEPOSIT_TOOLTIP_CREDITCARD_CVV);
    }

    /**
     * Triggers a deposit in the Business Service
     */
    public onDeposit() {
        // Re-evaluate content size to make sure the loading spinner and success
        // messaging are centered correctly.
        this.communicateContentSizeChange();
        this.logDepositStartGtmEvent();
        this.pendingDeposit = true;

        if (this.flowAttribute) {
            this._eventService.logClickEvent(EventClickType.DEPOSIT_DEBIT_SUBMIT, this.flowAttribute);
        } else {
            this._eventService.logClickEvent(EventClickType.DEPOSIT_DEBIT_SUBMIT);
        }
        const amount = this.form.get('amount').value;
        let lastFour: string | number;
        let request: Partial<ICreditcardDepositRequest> | ITokenizedCreditcardDepositRequest;

        this._sidebarService.showLoadingOverlay(this.isBetShare ? SIDEBAR_LOADERS.BET_SHARE_SPINNER : SIDEBAR_LOADERS.SPINNING_LOADER);
        if (!this.fundingMethodDetails.accountInfo || this.replaceAccountInfo) {
            const expiration = this._getExpirationParts(this.form.get('expiration').value);
            if (expiration === '') {
                return;
            }

            const cardNumber = this.form.get('cardNumber').value.replace(/ /g, '');
            lastFour = cardNumber.slice(cardNumber.length - 4);

            request = <Partial<ICreditcardDepositRequest>> {
                amount: this.form.get('amount').value,
                cardNumber: cardNumber,
                ccMonth: expiration.month,
                ccYear: expiration.year,
                cvv2: this.form.get('cvv').value,
                billAddressDiff: this.form.get('billAddressDiff').value
            };

            if (this.form.get('billAddressDiff').value) {
                request.address = this.form.get('address').value;
                request.zipCode = this.form.get('zipCode').value;
                request.city = this.form.get('city').value;
                request.state = this.form.get('state').value;
            }
        } else if (this.fundingMethodDetails['token']) {
            lastFour = this.fundingMethodDetails.accountInfo;

            request = <ITokenizedCreditcardDepositRequest> {
                amount: this.form.get('amount').value,
                cardtype: this.fundingMethodDetails.cardTypeID,
                cvv: this.form.get('cvv').value,
                token: this.fundingMethodDetails['token']
            };
        }

        this._fundingService.onFundingEvent.emit({event: 'DEPOSIT'});
        this._changeDetectorRef.detectChanges();
        this.successMessage = `${this._currencyPipe.transform(amount, 'USD', 'symbol-narrow')} has been deposited to your account with Credit Card ending in ${lastFour}.`;
        this._creditCardService.deposit(amount, <ICreditcardDepositRequest | ITokenizedCreditcardDepositRequest>request).pipe(take(1)).subscribe((response) => {
            this._handleCCResponse(
                response,
                this.successMessage,
                amount
            );
        }, () => {
            // catch all for any unexpected errors thrown from backend
            this._handleCCResponse({ response: 'FAILED', status: 'failure'}, this.successMessage, amount);
        });
    }

    private _handleCCResponse(response: any, message: string, amount: number) {
        this.errorCode = null;
        this.errorCodeArgs = null;
        if (response.response !== this.ERROR_CODES.APPROVED) {
            if (response.errorCode === this.ERROR_CODES.DAILY_TRANSACTION_LIMIT || response.errorCode === this.ERROR_CODES.WEEKLY_TRANSACTION_LIMIT) {
                this.errorCodeArgs = this._translateService.translate('customer-service', this.AFFILIATE_KEY, false);
            } else {
                response.errorCode = (response.response) ? response.response : this.ERROR_CODES.DECLINED;
            }
            message = '';
        }
        this._handleResponse(response, message, amount, this.isFullscreen);
    }


    private _getExpirationParts(expirationString: string) {
        const parts = expirationString.match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);

        if (!parts) {
            return '';
        }

        return {
            month: parts[1] || '',
            separator: parts[2] || '',
            year: parts[3] || ''
        }
    }
}
