import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map, filter, shareReplay } from 'rxjs/operators';

import {
    enumRaceStatus,
    enumTrackType,
    ITrack,
    ITrackBasic,
    ITrainerJockeyTrackSummary,
    IToteRace,
    IAdwRace,
    ToteDataService,
    TracksDataService,
    TrainerJockeySummaryDataService,
    AnglesRace
} from '@cdux/ng-common';

@Injectable({
    providedIn: 'root'
})
export class ProgramModuleBusinessService {

    /**
     * All Races Data
     */
    public _allRacesObservables: Observable<IToteRace[]>[] = [];
    private _allRacesData: IToteRace[];
    get allRacesData(): IToteRace[] { return this._allRacesData; }
    set allRacesData(races: IToteRace[]) { this._allRacesData = races; }

    /**
     * IAdwRace List Data
     */
    private _races: IAdwRace[] = [];
    get races(): IAdwRace[] { return this._races; }
    set races(races: IAdwRace[]) { this._races = races; }

    /**
     * Constructor
     */
    constructor(private toteService: ToteDataService,
        private trainerJockeySummaryDataService: TrainerJockeySummaryDataService,
        private tracksDataService: TracksDataService) {}

    /**
     * Generates a shared observable for the all races data service endpoint, to avoid duplicate calls.
     *
     * @param {IAdwRace[]} races
     * @param {ITrack} track
     * @returns {any}
     */
    public combineRaceListAndProgram(races: IAdwRace[], track: ITrack): IAdwRace[] {

        // Clear out the current races to be recombined
        this._races = [];
        const tempRaces: IAdwRace[] = [];

        // Only combine if we have data from the GetAllRaces (Program) call
        if (this._allRacesData && races) {
            // For each race in the Program, look for the race number
            this._allRacesData.forEach((raceProgram: IToteRace) => {
                const existingRace = races.find(raceADW => raceADW.race === raceProgram.RaceNumber && raceADW.track.toLowerCase() === track.AtabCode.toLowerCase());
                if (races.length && !existingRace) {
                    // if the race is not in the ADW Race List, add it with data from the Program Data
                    let postTime: number;
                    /**
                     * raceProgram.PostTime is in format YYYY-MM-DDTHH:mm:ss.sssZ ; This is a simplification of the ISO-8601 calendar date extended format.
                     * Date.parse() is safe to use on this format, but is possibly inconsistent for other date-time string formats. String parsing implementation differs
                     * across browsers.
                     */
                    raceProgram.PostTime ? postTime = Date.parse(raceProgram.PostTime) : postTime = 0;
                    tempRaces.push({ track: track.AtabCode, race: raceProgram.RaceNumber, post: '23:59:59', mtp: 99, postTimestamp: postTime, raceStatus: enumRaceStatus.OPEN, currentRace: false });
                } else {
                    // the race was already in the list, add it to the combined racelist
                    tempRaces.push(existingRace);
                }
            });
            // Set the new list of races
            this._races = tempRaces;
            return this._races;
        } else {
            // if we don't have Program data, just pass the race list back
            return this._races = races;
        }


    }

    /**
     * Returns an observable for a list of races in the format of the Tote Races call.
     * It combines the list of races from the Tote Race call with the BDS program list of races.
     *
     * There is an important reason for this. Tote is not garanteed to have all races that will be racing in a given day.
     * When Tote can not provide the post time, the race is not loaded into our systems until the post time becomes available.
     * If post time is missing for a future race, it typically will not be given a post time from tote till it becomes the current
     * race. The program data provided by BDS will have all of the races for a given day. Any missing races are constructed from
     * the program data into the same format as the tote race call.
     *
     * Please use this service to get a race list instead of any direct call to the tote races call. UNLESS you are certain you truly
     * only need the data TOTE provides.
     *
     * @param {ITrack} track
     * @returns {Observable<IAdwRace[]>}
     */
    public getRaceListCombinedObservable(track: ITrack): Observable<IAdwRace[]> {
        return combineLatest([
            this.getAllRacesObservable(track),                    // BDS data,  returns IToteRace[]
            this.tracksDataService.raceList(track.AtabCode, true) // tote data, returns IAdwRace[]
        ]).pipe(
            map(([racesListBDS, racesListTote]) => {
                this.allRacesData = racesListBDS;
                return this.combineRaceListAndProgram(racesListTote, track);
            })
        );
    }

    /**
     * Generates a shared observable for the all races data service endpoint, to avoid duplicate calls.
     *
     * @param {ITrack} track
     * @returns {any}
     */
    public getAllRacesObservable(track: ITrackBasic): Observable<IToteRace[]> {
        if (!track || !track.BrisCode || !track.TrackType) {
            return of(undefined);
        }

        const key = track.BrisCode + '::' + track.TrackType;

        if (this._allRacesObservables[key]) {
            return this._allRacesObservables[key];
        } else { // Store the observable for sharing.
            const allRacesObs = this.toteService.allRaces(track.BrisCode, track.TrackType).pipe(
                filter((allRaces) => !!allRaces && Array.isArray(allRaces.AllRaces)),
                map(allRaces => allRaces.AllRaces)
            );

            // If we are looking at a Thoroughbred track, we need to lookup jockey and
            //  trainer stats. Otherwise, we merge nothing in.
            const trainerJockeyObs = track.TrackType === enumTrackType.TBRED ?
                this.trainerJockeySummaryDataService.getTrainerJockeyTrackSummary(track.BrisCode, track.TrackType).pipe(
                    // If this call fails for any reason, we don't want to blow up the
                    //  combined observable, so we just log and return as if no stats exist.
                    catchError((err) => {
                        console.warn(`Trainer Jockey Summary Data failed to load for race ${track.BrisCode}. | ${err}`);
                        return of(undefined);
                    })
                ) : of(undefined);

            // If we are looking at a Thoroughbred track, we need to lookup jockey and
            //  trainer stats. Otherwise, we merge nothing in.
            const allRacesAnglesObs = track.TrackType === enumTrackType.TBRED ?
                this.toteService.angles(track.BrisCode) .pipe(
                    map(anglesRaces => anglesRaces.anglesRaces)
                ) : of(undefined);

            // Zip our two calls together so they can be called simultaneously
            this._allRacesObservables[key] = combineLatest([allRacesObs, trainerJockeyObs, allRacesAnglesObs]).pipe(
                map(([allRaces, trainerJockeySummary, anglesRaces]) => this._mergeAllRacesDataSources(allRaces, trainerJockeySummary, anglesRaces)),
                shareReplay(1)
            );
        }

        return this._allRacesObservables[key];
    }

    private _mergeAllRacesDataSources(
        allRaces: Array<IToteRace>,
        trainerJockeySummary: ITrainerJockeyTrackSummary,
        anglesRaces: AnglesRace[]): Array<IToteRace> {

        if (!!allRaces && !!trainerJockeySummary && !!trainerJockeySummary.races) {

            /**
             * We build a map of our data so we can run through it once and quickly
             * query it by race and entry as we append to our race entries.
             *
             * We key each race summary by raceNumber.
             * We key each entry with a key like "[PostPosition]_[TrainerId]_[JockeyId]"
             */
            const trainerJockeyMap = trainerJockeySummary.races.reduce((acc, curr) => {
                const entryMap = curr.starts.reduce((entryAcc, entryCurr) => {
                    entryAcc[`${entryCurr.postPosition}_${entryCurr.trainerId}_${entryCurr.jockeyId}`] = entryCurr;
                    return entryAcc;
                }, {});

                acc['' + curr.raceNumber] = entryMap;
                return acc;
            }, {});

            const anglesMap = anglesRaces.reduce((acc, curr) => {
                const entryMap = curr.anglesMarks.reduce((entryAcc, entryCurr) => {
                    entryAcc['' + entryCurr.programNumber] = entryCurr;
                    return entryAcc;
                }, {});

                acc['' + curr.raceNumber] = entryMap;
                return acc;
            }, {});

            allRaces.forEach((race) => {
                const raceSummary = trainerJockeyMap['' + race.RaceNumber];
                const anglesMarks = anglesMap['' + race.RaceNumber];
                race.Entries.forEach((entry) => {
                    if (raceSummary) {
                        const entrySummary = raceSummary[`${entry.PostPosition}_${entry.TrainerId}_${entry.JockeyId}`];
                        entry.TrainerJockeySummary = entrySummary;
                    }
                    if (anglesMarks) {
                        entry.AnglesMarks = anglesMarks['' + entry.ProgramNumber]
                    }
                });
            });
        }
        return allRaces;
    }
}
