<div class="result-item has-cursor-finger" (cduxTap)="resultClicked.emit(resultItem)">
    <div class="col-1">
        <div [id]="'search-result-item-silk-'+index" class="silk" *ngIf="resultItem.silk">
            <img [src]="resultItem.silk">
        </div>
    </div>
    <div class="col-2">
        <div [id]="'search-result-item-runner-name-'+index" class="runner">{{ resultItem.runnerName }}</div>
        <div [id]="'search-result-item-track-name-'+index" class="track">{{ resultItem.trackName }}</div>
    </div>
    <div class="col-3">
        <div [id]="'search-result-item-race-num-'+index" class="race-num">R{{ resultItem.raceNumber }}</div>
    </div>
    <div class="col-4">
        <div class="mtp-badge mtp-post-time post-time">
            <div [id]="'search-result-item-mtp-'+index" class="mtp-value">{{ postTime | date:'hh:mm' }}</div>
            <div class="mtp-label">{{ postTime | date:'a' }}</div>
        </div>
    </div>
</div>