import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { ENVIRONMENT } from '@cdux/ng-core';
import { EventClickType, FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';

import { FundingAbstractMethodDepositComponent } from '../abstract-method-deposit.component';
import { enumDepositOptions } from '../abstract-method.component';
import { HeaderService } from 'app/shared/header/services/header.service';


@Component({
    selector: 'cdux-funding-ezbank-method-deposit',
    templateUrl: './ezbank-method-deposit.component.html'
})
export abstract class FundingEZBankMethodDepositAbstractComponent extends FundingAbstractMethodDepositComponent implements OnInit, OnDestroy {

    public eventClickType = EventClickType;
    public minDeposit: number;
    public maxDeposit: number;
    public errorMessage: string;

    private _destroy: Subject<boolean> = new Subject();

    constructor(
        router: Router,
        changeDetector: ChangeDetectorRef,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        protected _translateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            router,
            localHeaderService,
            localEnvironment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localEventTrackingService,
            _translateService,
            localSessionService,
            localToggleService,
            changeDetector
        );
        this.operationMethod = enumDepositOptions.EZ_BANK;
    }

    public ngOnInit() {
        this.minDeposit = this.getMinDepositAmount(this.hasVerifiedAccount);
        this.maxDeposit = this.getMaxDepositAmount();
        this._initForm([
            {
                name: 'amount',
                default: this.getInitialDepositAmount(),
                validators: [
                    Validators.min(this.minDeposit),
                    Validators.max(this.maxDeposit),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            }
        ]);
        this._checkErrorCodes();
    }

    public ngOnDestroy() {
        this._destroy.next(true);
        this._destroy.complete();
    }

    public abstract navigateToEzBank();

    public get hasVerifiedAccount(): boolean {
        // return true because EZBank is stateless. This will cause the widget
        // to respect the min deposit amount, rather than the min INITIAL
        // deposit amount.
        return true;
    }

    /**
     * Gets the Maximum Allowed Deposit
     */
    public getMaxDepositAmount() {
        const methodDetails = this.fundingMethodDetails;
        if (this.hasVerifiedAccount) {
            return super.getMaxDepositAmount();
        } else {
            const initialMax = methodDetails.initialMaxDeposit - methodDetails['pendingDepositAmount'];

            return initialMax > 0 ? initialMax : 0;
        }
    }

    protected _checkErrorCodes() {
        if (typeof this._fundingService.ezBankErrorCode !== 'undefined' && this._fundingService.ezBankErrorCode !== null) {
            const errorCode = 'ezbank-' + this._fundingService.ezBankErrorCode;
            this._fundingService.ezBankErrorCode = null;
            this.errorMessage = this._translateService.translate(errorCode, 'errorcodes', true);
        }
    }
}
