<ng-template  cdk-portal #leftIcon="cdkPortal">
    <cdux-favorite-track *ngIf="!tournamentSelected && isLoggedIn" [track]="selectedTrack" [largeStar]="true"></cdux-favorite-track>
</ng-template>

<cdux-dropdown
    class="program-nav-race-dropdown"
    [class.hidden]="displayMode === DisplayModeEnum.MOBILE"
    [class.is-bet-pad]="displayMode === DisplayModeEnum.COMPACT"
    id="race_dropdown"
    [disabled]="isDisabled"
    [disabledLabel]="selectedRaceLabel"
    (stateChange)="onDropdownStateChange($event)"
    [(ngModel)]="selectedRace"
    (ngModelChange)="onRaceSelection($event)"
    [forceState]="onSmallGlass ? smallGlassState : 0"
    [allowCloseOnExternalClick]="displayMode === DisplayModeEnum.COMPACT">

    <div dropdown-header
         [cduxLogClick]="eventClickType.RACE_NAV_RACE_DROPDOWN">
        <div class="menu-tab">
            <h2 class="title-label">
                <span id="race_dropdown_identifier">
                    {{ selectedRaceLabel }}
                </span>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>
    <div dropdown-list>
        <cdux-dropdown-option *ngFor="let race of races; trackBy:trackByRace"
                              [value]="race"
                              (click)="logClick(eventClickType.RACE_NAV_RACE_SELECTION)">
            <div class="option-label" [cduxFocus]="race?.raceNumber === currentRaceNumber">
                <div class="race" id="race_item_identifier">
                    <div class="race-number">
                        <div class="race-number-value">Race {{ race?.raceNumber }}</div>
                        <span class="race-number-details" *ngIf="race['raceDetailsDisplay'] && race['raceDetailsDisplay']['trackType'] === enumTrackType.TBRED">
                            {{ race['raceDetailsDisplay']['surface'] || 'N/A' }} {{ race['raceDetailsDisplay']['distance'] || 'N/A' }}
                        </span>
                    </div>
                    <span class="race-details" *ngIf="race['raceDetailsDisplay']">
                        <div class="detail" *ngIf="race['raceDetailsDisplay']['trackType'] === enumTrackType.TBRED; else NonTBRaceDetails">
                            {{ race['raceDetailsDisplay']['displayRaceName'] || (race['raceDetailsDisplay']['raceType'] | titlecase) }}
                        </div>
                        <ng-template #NonTBRaceDetails>
                            <div class="detail">{{ (race['raceDetailsDisplay']['raceType'] | uppercase) || 'N/A' }}</div>
                            <div class="detail">{{ (race['raceDetailsDisplay']['distance'] | uppercase) || 'N/A' }}</div>
                            <div class="detail">{{ (race['raceDetailsDisplay']['surface'] | uppercase) || 'N/A' }}</div>
                        </ng-template>
                    </span>
                </div>
                <div *ngIf="hasCarryover(race)" class="carryover-icon"></div>
            </div>
            <span id="mtp-badge">
                <cdux-mtp-badge [source]="race" [showMinutes]="true"></cdux-mtp-badge>
            </span>
        </cdux-dropdown-option>
    </div>
</cdux-dropdown>

<ng-container *ngIf="displayMode === DisplayModeEnum.MOBILE">
    <div class="navigation-controls">
        <div class="race-nav-back"
             [class.disabled]="raceIndex === 0"
             (click)="previousRace()"
             [cduxLogClick]="eventClickType.RACE_NAV_PREVIOUS">
            <i class="icon--arrow-rounded-l"></i>
        </div>
        <div class="race-nav-dropdowns" (click)="openRaceList()" [cduxLogClick]="eventClickType.RACE_NAV_RACE_DROPDOWN">
            <h2 class="track-title-label">
                <span id="track_dropup_identifier">
                    <cdux-track-group
                        *ngIf="selectedTrack; else noTrackSelected"
                        class="in-header"
                        [inHeader]="true"
                        [track]="selectedTrack"
                        [disableClick]="true">
                        <span class="track-name">{{ dropupHeaderText }}</span>
                    </cdux-track-group>
                    <ng-template #noTrackSelected>Track</ng-template>
                </span>
            </h2>
            <h2 class="race-title-label">
                <span id="race_dropup_identifier">
                    Race {{ selectedRace?.raceNumber }}
                </span>
            </h2>
        </div>
        <div class="race-nav-forward"
             [class.disabled]="raceIndex >= racesCount - 1"
             (click)="nextRace()"
             [cduxLogClick]="eventClickType.RACE_NAV_NEXT">
            <i class="icon--arrow-rounded-r"></i>
        </div>
    </div>
</ng-container>
