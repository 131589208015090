import { Component, EventEmitter } from '@angular/core';
import { Input, Output } from '@angular/core';
import {
    BasicBetType,
    Bet,
    WagerState,
    enumBetModifier,
    enumBetSubtype,
    enumRaceStatus,
    enumTrackType,
    ISelectedEntry,
    MultiRaceExoticBetType,
    RunnerListConfig,
    RunnerListPipe,
    TrackService,
    PoolType
} from '@cdux/ng-common';
import { BetsModifierDisplayValue } from '@cdux/ng-fragments';

@Component({
    selector: 'cdux-runner-bar',
    templateUrl: './runner-bar.component.html',
    styleUrls: ['./runner-bar.component.scss'],
})
export class RunnerBarComponent {
    @Input() showBetDetails: boolean;
    @Input() keepPicks: boolean;
    @Input() compact: boolean;

    @Input() public set bet(value: Bet) {
        if (value) {
            if (value.amount) {
                this.value = value.amount.value;
            }
            if (value.poolType) {
                this.poolType = PoolType.getPoolTypeByCode(value.poolType.Code);
            }
            switch (value.betSubtype) {
                case enumBetSubtype.BOX:
                    this.subtype = BetsModifierDisplayValue.BOX;
                    break;
                case enumBetSubtype.KEYBOX:
                    this.subtype = BetsModifierDisplayValue.KEY_BOX;
                    break;
                case enumBetSubtype.KEY:
                    this.subtype = BetsModifierDisplayValue.KEY;
                    break;
                case enumBetSubtype.POWERBOX:
                    this.subtype = BetsModifierDisplayValue.POWER_BOX;
                    break;
                default:
                    this.subtype = '';
                    break;
            }
            this._isBetWagerable = !!value.poolType;

            if (value.runners && value.poolType) {
                this.runnerListConfig = {
                    subtype: value.betSubtype || null,
                    selectionCount: +value.poolType.Legs || 1,
                    bettingInterests: value.runners.map<ISelectedEntry[]>((leg) =>
                        leg.map<ISelectedEntry>((entry) => ({
                            ProgramNumber: entry.ProgramNumber,
                            BettingInterest: +entry.ProgramNumberCoupled
                        }))
                    )
                };
                this.runnerListString = this._runnerListPipe.transform(this.runnerListConfig);
            } else {
                this.runnerListConfig = null;
                this.runnerListString = '';
            }
        }
    }

    @Input() public set wagerState(wager: WagerState) {
        if (wager) {
            if (wager.betNav) {
                if (wager.betNav.amount) {
                    this.value = wager.betNav.amount.value;
                }
                if (wager.betNav && wager.betNav.type) {
                    this.betType = wager.betNav.type;
                    this.poolType = wager.betNav.type.poolType;
                }
                switch (wager.betNav.modifier) {
                    case enumBetModifier.BOX:
                        this.subtype = BetsModifierDisplayValue.BOX;
                        break;
                    case enumBetModifier.KEY_BOX:
                        this.subtype = BetsModifierDisplayValue.KEY_BOX;
                        break;
                    case enumBetModifier.KEY:
                        this.subtype = BetsModifierDisplayValue.KEY;
                        break;
                    case enumBetModifier.POWER_BOX:
                        this.subtype = BetsModifierDisplayValue.POWER_BOX;
                        break;
                    default:
                        this.subtype = '';
                        break;
                }
                if (wager.basicTrack) {
                    this.trackType = wager.basicTrack.TrackType;
                }
            }
            this._isWagerStateWagerable = !!wager.betNav;

            if (wager.betNav && wager.betNav.type && wager.betNav.type.poolType) {
                this.runnerListConfig = {
                    subtype: wager.betNav.modifier || null,
                    selectionCount: wager.betNav.type.poolType.selectionCount || 1,
                    bettingInterests: wager.bettingInterests || []
                };
                this.runnerListString = this._runnerListPipe.transform(this.runnerListConfig);
            } else {
                this.runnerListConfig = null;
                this.runnerListString = '';
            }
        }
    }

    @Input() public set raceStatus(raceStatus: enumRaceStatus) {
        this._isStatusWagerable = TrackService.isWagerableRace(raceStatus);
    }
    @Input() public showSaddleCloths = false;

    @Output() onClear: EventEmitter<undefined> = new EventEmitter<undefined>();
    @Output() onKeepPicksToggle: EventEmitter<undefined> = new EventEmitter<undefined>();

    public runnerListString: string;
    public runnerListConfig: RunnerListConfig;

    public value: string;
    public trackType: enumTrackType;
    public poolType: PoolType;
    public betType: BasicBetType | MultiRaceExoticBetType;
    public subtype: string;

    public get show() {
        // When using the WagerState, it requires a raceStatus. The raceStatus isn't used with the Bet input.
        return this._isBetWagerable || (this._isStatusWagerable && this._isWagerStateWagerable);
    }

    private _isStatusWagerable: boolean = false;
    private _isBetWagerable: boolean = false;
    private _isWagerStateWagerable: boolean = false;

    constructor(
        private _runnerListPipe: RunnerListPipe,
    ) { }

    public clear() {
        this.onClear.emit();
    }

    public handleKeepPicksToggle() {
        this.onKeepPicksToggle.emit();
    }
}
