<div class="account-history-bet" [ngClass]="{'odd-row' : index % 2 !== 0 }">
    <div class="account-history-bet__title-bar">
        <div class="title">{{ wagerType }}</div>
        <div class="title right">{{ grossWinnings | currency }}</div>
    </div>
    <div class="account-history-bet__row flex">
        <div class="title right amount-negative">{{ withHoldings | currency }}</div>
    </div>
    <div class="account-history-bet__row flex">
        <div class="serial-number">{{ transaction.ticketNumber }}</div>
        <div class="title right is-verified">{{ netGains | currency }}</div>
    </div>
    <div class="account-history-bet__row flex">
        <div (click)="printTransaction()" class="icon--ellipsis"></div>
    </div>
</div>
