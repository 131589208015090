Multifactor Authentication
 <cdux-tooltip class="multifactor-authentication-tooltip" [activatedOn]="'click hover'" [position]="'bottom'"
               tooltipTitle="Multifactor Authentication">
   <!-- this activeatedOn needs to be 'hover' for desktop and 'click' for mobile -->
   <span tooltip-toggle>
      <i class="icon--info"></i>&nbsp;
    </span>
   <span tooltip-content>
     <p class="multifactor-authentication-tooltip-info">
       MFA adds an extra layer of security to your account by asking for a verification code when you sign in.
     </p>
   </span>
 </cdux-tooltip>

