import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { FeatureToggleDataService } from '@cdux/ng-common';
import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';

/*
READ THIS FIRST
This guard checks feature guards that have a boolean value.  
Without the negateFeatureToggle flag present and set to true, the guard will only redirect to the redirectUrl if the feature toggle is false.
In situations where mutual exclusivity is desired, the negateFeatureToggle flag can be set to true, and the guard will redirect to the redirectUrl if the feature toggle is true.
By default and to preserve existing functionality, if the negateFeatureToggle flag is not present, it is effectively set to false.
*/

@Injectable()
export class FeatureToggleGuard implements CanActivate {
    constructor(private router: Router,
                private _featureToggleService: FeatureToggleDataService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!route.data || !route.data.featureToggle) { // fail
            return Promise.resolve(this.router.parseUrl(DEFAULT_REDIRECT_PATH));
        }
        return this._featureToggleService.watchFeatureToggle(route.data.featureToggle).pipe(
            first(), 
                map(ft =>     
                    this.exclusiveOR(!!ft, !!route.data.negateFeatureToggle)  ||  //if negateFeatureToggle is not present, its value is falsy.
                    this.router.parseUrl(
                        route.data.redirectUrl || // static redirect
                        (
                            route.data.redirectPattern && 
                            route.data.redirectReplacement
                        ) && // dynamic redirect
                        state.url.replace(new RegExp(route.data.redirectPattern), route.data.redirectReplacement) ||
                        DEFAULT_REDIRECT_PATH // default redirect
                    )
                )
        ).toPromise(); 
    }

/* 
exclusiveOR is a boolean XOR function: If either a or b is true, but not both, return true. Otherwise, return false. 
This is how the negateFeatureToggle flag inverts the feature toggle value when set to true.
*/
    exclusiveOR(a: boolean, b: boolean): boolean {
        return ( a || b ) && !( a && b );

    }
}
