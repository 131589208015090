import { CommonModule } from '@angular/common';
import { PastPerformancesComponent } from './past-performances.component';
import { NgModule } from '@angular/core';
import { LoadingModule } from '@cdux/ng-fragments';

@NgModule({
    imports: [
        CommonModule,
        LoadingModule
    ],
    declarations: [
        PastPerformancesComponent
    ],
    exports: [
        PastPerformancesComponent
    ]
})

export class PastPerformanceModule {}


