import { LANG_MY_ACCOUNT_TRANS } from './my-account';

export const LANG_MY_ACCOUNT_6200_NAME = 'my-account-6200';

const LANG_MY_ACCOUNT_6200_EXTENSION = {
    'external-rewards-title': 'Dynasty Rewards',
    'external-rewards-subtitle': 'DraftKings\' annual rewards program',
    'external-responsible-gaming-title': 'Responsible Gaming Center',
    'external-responsible-gaming-subtitle': 'Learn about our RG resources',
};

export const LANG_MY_ACCOUNT_6200_TRANS = Object.assign({}, LANG_MY_ACCOUNT_TRANS, LANG_MY_ACCOUNT_6200_EXTENSION);
