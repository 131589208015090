<ul class="main-menu">
    <ng-container *ngFor="let menuItem of menu">
        <li class="nav-link mouse-menu main-menu-link" *ngIf="checkAuthDisplay(menuItem) && checkPlayerGroupDisplay(menuItem)"
            [class.is-open]="menuItem.submenuOpen" (cduxHover)="menuItemHover(menuItem, $event)">
            <a href="{{ menuItem.directUrl }}" class="main-menu-link" (cduxTap)="menuItemClick($event, menuItem)" (contextmenu)="updateDirectUrlPath(menuItem)" [id]="'menu-item-'+ (menuItem.title | stringSlugify)">
                {{ menuItem.title }}
            </a>
            <ul class="submenu" *ngIf="menuItem.submenu.length">
                <div class="icon--sub-menu-down-arrow"></div>
                <ng-container *ngFor="let submenuItem of menuItem.submenu">
                    <li *ngIf="checkAuthDisplay(submenuItem) && checkPlayerGroupDisplay(submenuItem)">
                        <a href="{{ submenuItem.directUrl }}" class="mouse-menu-item" (cduxTap)="menuItemClick($event, submenuItem)" (contextmenu)="updateDirectUrlPath(menuItem)" [id]=" (menuItem.title | stringSlugify)+ '-sub-menu-item-'+ (submenuItem.title | stringSlugify)">
                            {{ submenuItem.title }}
                        </a>
                    </li>
                </ng-container>
            </ul>
            <cdux-tooltip *ngIf="menuItem.tooltip.tooltip" #tipTour class="tour-remind-tooltip"
                [position]="menuItem.tooltip.position">
                <span tooltip-content>
                    {{ menuItem.tooltip.tooltip }}
                </span>
            </cdux-tooltip>
        </li>
    </ng-container>
</ul>
