import { Component, Input, OnDestroy, HostBinding, HostListener, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Subscription, AsyncSubject, iif, of } from 'rxjs';

import { enumTop3Category } from './top-3-category.enum';
import { AnglesBusinessService } from 'app/shared/program/services/angles.business.service';
import { TopPicks } from 'app/shared/program/interfaces/top-picks.interface';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { skipWhile, take, switchMap, map, catchError, finalize, tap } from 'rxjs/operators';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { isArray } from 'util';
import { WagerState, TrackService, ITrackBasic, WagerService, EventClickType, enumRaceStatus, ILabelValuePair, enumPoolType, EventClickAttributeType } from '@cdux/ng-common';



@Component({
    selector: 'cdux-top-3-button',
    templateUrl: './top-3-button.component.html'
})
export class Top3ButtonComponent implements OnDestroy {
    @Input()
    public category: enumTop3Category = null;

    @Input()
    public toteDate: string;


    private _wagerState: WagerState;
    @Input()
    public set wagerState(state: WagerState) {
        this._wagerState = state;
        if (this._wagerState && this._wagerState.basicTrack && !(TrackService.isSameTrack(this._track, this._wagerState.basicTrack) && this._race === this._wagerState.basicTrack.RaceNum)) {
            this._track = this._wagerState.basicTrack;
            this._race = this._wagerState.basicTrack.RaceNum;

            this._initialize();
        }
    }

    public get wagerState(): WagerState {
        return this._wagerState;
    }

    @Output()
    public select: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    @HostBinding('class.is-disabled') buttonDisabled: boolean = true;

    public picks: TopPicks = {
        topPicks: '',
        betNumbers: []
    };

    private _topPicksSub: Subscription = null;
    private _currentRaceExactaSub: Subscription = null;
    private _raceChangesSub: Subscription = null;
    private _hasExactaSubject;
    private _track: ITrackBasic = null;
    private _race: number = null;

    @HostListener('click')
    public selectCategory() {
        if (!this.buttonDisabled) {
            const selection: ISelectionUpdate[] = [{
                entries: this.picks.bettingInterests,
                selected: true,
                leg: 0
            }];

            this._logClickEvent(this.category);

            this.select.emit(selection);
        }
    }

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _anglesService: AnglesBusinessService,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _eventTrackingService: EventTrackingService,
        private _wagerService: WagerService
    ) {
        /*
         * Begin with this empty value in case someone tries to call
         * selectCategory before hasExactaSubject has been properly set.
         *
         * TODO: Can I just use a BehaviorSubject instead?
         */
        this._hasExactaSubject = new AsyncSubject();
        this._hasExactaSubject.complete();
    }

    ngOnDestroy() {
        if (this._topPicksSub) {
            this._topPicksSub.unsubscribe();
        }
        if (this._raceChangesSub) {
            this._raceChangesSub.unsubscribe();
        }
    }

    private _initialize() {

        if (this._topPicksSub) {
            this._topPicksSub.unsubscribe();
        }
        if (this._currentRaceExactaSub) {
            this._currentRaceExactaSub.unsubscribe();
        }

        this._topPicksSub = this._anglesService.getTopPicks(this.category, this.wagerState.basicTrack.BrisCode, this.wagerState.basicTrack.TrackType, this.wagerState.basicTrack.RaceNum).subscribe(
            (picks: TopPicks) => {
                this.picks = picks;
                this._changeDetectorRef.markForCheck();
            }
        );

        /*
        * This checks the current race to see if exactas are available.
        * It prevents itself from firing more than once (i.e., once for
        * all x instances of the component on the page), until deselect()
        * is called. This Subject completes, so there's no need to
        * unsubscribe.
        */
        this._currentRaceExactaSub = this._currentRaceOffersExacta(this.wagerState)
            .pipe(
                take(1),
                tap((hasExacta) => {
                    this.buttonDisabled = !hasExacta;
                    this._changeDetectorRef.markForCheck();
                    return hasExacta;
                }),
                switchMap(
                    (hasExacta) => iif(
                        () => hasExacta,
                        this._todaysRacesService.getTodaysRace(
                            this.wagerState.basicTrack.BrisCode,
                            this.wagerState.basicTrack.TrackType,
                            this.wagerState.basicTrack.RaceNum,
                            true
                        ),
                        of(null)
                    )
                ),
                skipWhile((race) => race && TrackService.isWagerableRace(race.status)),
                take(1)
            ).subscribe(
                (newRaceInfo) => {
                    this.buttonDisabled = true;
                    this._changeDetectorRef.markForCheck();
                },
                (reason) => { console.warn('Unable to determine if exacta available for race.', reason); }
            );
    }

    private _logClickEvent(category: enumTop3Category) {
        const ts = Date.now();

        switch (category) {
            case enumTop3Category.CLASS:
                this._eventTrackingService.logClickEvent(EventClickType.TIPS_TOP_CLASS, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_CLASS_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_CLASS_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_CLASS_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_CLASS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumTop3Category.PACE:
                this._eventTrackingService.logClickEvent(EventClickType.TIPS_TOP_PACE, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PACE_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PACE_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PACE_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PACE_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumTop3Category.PICKS:
                this._eventTrackingService.logClickEvent(EventClickType.TIPS_TOP_PICKS, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PICKS_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PICKS_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PICKS_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_PICKS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case enumTop3Category.SPEED:
                this._eventTrackingService.logClickEvent(EventClickType.TIPS_TOP_SPEED, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_SPEED_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_SPEED_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_SPEED_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_TOP_SPEED_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
        }
    }

    /**
     * checks to see if current race (specified by ProgramNavBusinessService) offers exacta wager
     *
     * This method prevents itself from firing more than once. Before it
     * will fire again, deselect() must be called.
     */
    private _currentRaceOffersExacta(wagerState: WagerState): AsyncSubject<boolean> {
        /*
        * Because we complete the AsyncSubject when finalizing
        * the observable pipe, we have to create a new one each
        * time this method is evaluated.
        */
        this._hasExactaSubject = new AsyncSubject();

        /*
        * short-circuit if the race is off, closed or official
        *
        * In practice, OFF is the only real problem (DE13216). It could be
        * a while after a race changes its status before the exacta (and
        * wagering, in general) are updated to be unavailable.
        */
        this._todaysRacesService.getTodaysRace(wagerState.basicTrack.BrisCode, wagerState.basicTrack.TrackType, wagerState.basicTrack.RaceNum, true).pipe(
            switchMap(
                race => iif(
                    () => (race.status === enumRaceStatus.OFF || race.status === enumRaceStatus.CLOSED || race.status === enumRaceStatus.OFFICIAL),
                    of(null),
                    this._wagerService.getBetTypes(wagerState.basicTrack.BrisCode, wagerState.basicTrack.TrackType, wagerState.basicTrack.RaceNum)
                )
            ),
            take(1),
            map((betTypes: ILabelValuePair[]) => {
                if (isArray(betTypes) && betTypes.find((element: ILabelValuePair) => element.value.Code === enumPoolType.EXACTA)) {
                    this._hasExactaSubject.next(true);
                } else {
                    this._hasExactaSubject.next(false);
                }
            }),
            catchError((reason, caught) => {
                const errMsg = `Unable to determine if exacta available for ${wagerState.basicTrack.BrisCode.toUpperCase()} (${wagerState.basicTrack.TrackType}) race ${wagerState.basicTrack.RaceNum}.`;
                console.warn(errMsg);
                this._hasExactaSubject.next(false);
                return of(errMsg);
            }),
            finalize(() => {
                this._hasExactaSubject.complete();
            })
        ).subscribe();

        return this._hasExactaSubject;
    }
}
