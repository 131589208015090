import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { EventsService } from '@cdux/ng-common';

import { merge, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventKeys } from 'app/shared';

@Component({
    // TODO:HIP: We have two components with this selector and name. (How?)
    selector: 'cdux-video-sync',
    templateUrl: './video-sync.component.html',
    styleUrls: ['./video-sync.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoSyncComponent implements OnInit, OnDestroy {

    @Input()
    public set isActive(isActive: boolean) {
        if (isActive) {
            this._isActive = isActive;
            this._changeDetectorRef.detectChanges();
        } else {
            this._isActive = false;
            this._changeDetectorRef.detectChanges();
        }
    }
    public get isActive(): boolean {
        return this._isActive;
    }

    private _isActive: boolean;
    private _subscriptions: Subscription[] = [];

    constructor (private _changeDetectorRef: ChangeDetectorRef, private _eventsService: EventsService) { }

    ngOnInit() {
        this._subscriptions.push(
            merge(
                this._eventsService.on(EventKeys.VIDEO_PRIMARY_OPEN)
                    .pipe(tap(() => this._isActive = true)),
                this._eventsService.on(EventKeys.VIDEO_PRIMARY_CLOSE)
                    .pipe(tap(() => this._isActive = false))
            ).subscribe(() => this._changeDetectorRef.detectChanges()
        ));
    }

    ngOnDestroy() {
        this._subscriptions.forEach((subscription) => { subscription.unsubscribe() });
    }
}
