import { LANG_COMMON } from './common';

export const LANG_6200_NAME = '6200';

// 6200 specific extension of the common affiliate dictionary
const LANG_6200_EXTENSION = {
  'customer-service': '',
  'player-services-email': 'player.services@dkhorse.com',
  'registration-cellphone-alert-header': '',
  'registration-cellphone-alert-body': '',
  'registration-ssn-alert-body': 'DK Horse is committed to responsible wagering and complying with state and federal regulations.',
  'registration-player-services-fax': '',
  'registration-player-services-phone': '',
  'registration-account-exists-body': 'You may already have a DK Horse account. You can retrieve your account information and reset your password ',
  'registration-success-header-woffer': 'Welcome to DK Horse',
  'bet-share-registration-success-header-woffer': 'Welcome to DK Horse',
  'primary-phone-number': '',
  'primary-phone-number-vanity': '',
  'primary-phone-hyperlink': '',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': '',
  'twitter-url': '',
  'instagram-url': '',
  'support-display-social-media-icons': 'false',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'DK Horse',
  'moneygram-receiveId': '13000',
  'affiliate-name': 'DK Horse',
  'affiliate-name-llc': 'DK Horse LLC',
  'sso-affiliate-name': 'DraftKings',
  'affiliate-privacy-policy-url': 'https://www.dkhorse.com/privacy-policy',
  'affiliate-disclaimer': 'Please be advised that registering for DK Horse does not register you for any other DraftKings product and your DK Horse account is separate and distinct from any existing DraftKings account you may have.',
  'affiliate-deposit-disclaimer': 'Please note that funds deposited in your DK Horse account will not be available in any other DraftKings accounts.',
  'red-state-message': 'Sorry! DK Horse is not available in your state.',
  'sso-login-completion-message': 'Giddy up! We\'ll have you in on the action in just a moment!',
  'sso-red-state-blocked-header': 'DK HORSE WAGERING UNAVAILABLE',
  'sso-red-state-blocked-text' : 'We appreciate your interest, but horse wagering is not supported in your state at this time. Please check back later for updates.',
  'sso-document_upload_required-header': 'FILE UPLOAD',
  'sso-document_upload_required-text1': 'There was a problem creating your horse wagering account. You can gain access to horse wagering by providing additional information.',
  'sso-document_upload_required-text2': 'If you continue to experience problems accessing horse wagering please contact player services.',
  'sso-document_upload_required-button-text': 'Upload ID',
  'sso-unknown-error-header': 'DK HORSE WAGERING UNAVAILABLE',
  'sso-unknown-error-text': 'Sorry, we are unable to complete your DK Horse request at this time. Please try again later or chat with player services.',
  'sso-locked-account-header': 'DK HORSE WAGERING UNAVAILABLE',
  'sso-locked-account-text': 'Your account is locked and access to DK Horse is restricted. Please contact player services for support.',
  'sso-chat-button-text': 'CHAT NOW',
  'sso-under-age-blocked-header': 'DK HORSE WAGERING UNAVAILABLE',
  'sso-under-age-blocked-text': 'You do not meet your state\'s age requirement to wager on DK Horse.',
};

export const LANG_6200_TRANS = Object.assign({}, LANG_COMMON, LANG_6200_EXTENSION);
