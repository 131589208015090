
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';

import { SsnCollectionService } from './services/ssn-collection.service';
import { SsnCollectionComponentBase } from './ssn-collection-base.component';
import { EventClickType, enumSsnReason, JwtSessionService } from '@cdux/ng-common';

@Component({
    selector: 'cdux-ssn-collection-bet',
    templateUrl: './ssn-collection-bet.component.html',
    styleUrls: ['./ssn-collection-bet.component.scss']
})
export class SSNCollectionBetComponent extends SsnCollectionComponentBase implements OnInit {

    public eventClickType = EventClickType;
    public SSNCollectionForm: UntypedFormGroup;

    constructor(
        _environment: ENVIRONMENT,
        _sessionService: JwtSessionService,
        private _fb: UntypedFormBuilder,
        private _ssnCollectionService: SsnCollectionService
    ) {
        super(
            _environment,
            _sessionService,
            _ssnCollectionService
        );
    }

    public ngOnInit() {
        this.SSNCollectionForm = this._fb.group({
            area: '',
            group: '',
            serial: '****'
        });

        this.SSNCollectionForm.get('serial').setValue(this.getSS4());
    }

    public cancel() {
        this._ssnCollectionService.cancelSsn();
    }

    public onSubmit() {
        this.ssn3 = this.SSNCollectionForm.get('area').value;
        this.ssn2 = this.SSNCollectionForm.get('group').value;
        if (this.ssn3 && this.ssn2) {
            // betshare specific ssn collection must pass in enumSsnReason.BETSHARE
            this.submit(enumSsnReason.BETSHARE || enumSsnReason.CONTEST_REG).subscribe(() => {});
        }
    }

}
