import {Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({name: 'depositCurrency'})
export class DepositCurrencyPipe implements PipeTransform {
    constructor(private _currencyPipe: CurrencyPipe) {}

    transform(value: any, symbolDisplay: boolean = true, digits?: string): string {

        // Convert number strings to numbers
        value = typeof value === 'string' && this.isNumeric(value) ? +value : value;

        if (typeof value === 'number') {
            return this._currencyPipe.transform(value, 'USD', (symbolDisplay) ? 'symbol-narrow' : 'code' , digits);
        } else {
            return value;
        }
    }

    private isNumeric(value: any): boolean {
        return !Number.isNaN(value - parseFloat(value));
    }
}
