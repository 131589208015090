import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { InterceptorEvent } from './models';

@Injectable()
export class InterceptorService {

    // Public Accessor to Set Router
    public set router(router: Router) {
        this._router = router;
        // Instantiates Router Events Subscriptiom
        this._setupRouterSubscriptions(router);
    }
    public get router(): Router {
        return this._router;
    }

    // NavigationEnd Event Emitter
    public navigationEnd: EventEmitter<InterceptorEvent<NavigationEnd>> = new EventEmitter();

    // Reference to Router
    private _router: Router;

    // Events Subscription
    private _routerEventsSubscription: Subscription;

    /**
     * Injects an Instance of Router into the Service
     * @param router - Router to be Injected
     */
    public injectRouter(router: Router) {
        this.router = router;
    }

    /**
     * Creates the Subscription to Router Events
     * @param router - Router to be Subscribed to
     */
    private _setupRouterSubscriptions(router: Router) {
        if (this._routerEventsSubscription) {
            this._routerEventsSubscription.unsubscribe();
        }
        this._routerEventsSubscription = router.events.subscribe((event) => {
            setTimeout(() => {
                if (event instanceof NavigationEnd) {
                    const parameters = this._parsePameters(event.url);
                    this.navigationEnd.emit({
                        event: event,
                        route: event.url.substr(event.url.lastIndexOf('/') + 1).split('?') [0],
                        params: parameters
                    });
                }
            }, 0);
        })
    }

    /**
     * Extracts the Query Parameter String from a URL
     * @param url - URL Containing Query Parameters
     */
    private _extractQueryParameters(url: string) {
        const queryStart = url.indexOf('?');
        if (queryStart === -1) {
            return '';
        }
        return url.slice(queryStart + 1);
    }

    /**
     * Parses out a URL into an Query Parameter Object
     * @param url - URL Containing Query Parameters
     */
    private _parsePameters(url: string): { [key: string]: string } {
        const parameters = {};
        const queryParams = this._extractQueryParameters(url).trim().replace(/^[?#&]/, '');
        queryParams.split('&').forEach((param) => {
            const parts = param.replace(/\+/g, ' ').split('=');
            const key = parts.shift();
            let val = parts.length > 0 ? parts.join('=') : undefined;
            val = val === undefined ? null : decodeURIComponent(val);
            parameters[key] = val;
        });
        return parameters;
    }

}
