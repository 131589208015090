<form class="deposit-form-wrapper ezbank" *ngIf="form" [formGroup]="form" [class.dark]="lockout">

    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>
    <div *ngIf="!lockout">
        <cdux-funding-input-amount id="depositAmount" formControlName="amount"
            [inputType]="OPERATION"
            [fundType]="fundingMethodDetails.fundType"
            [min]="minDeposit"
            [max]="maxDeposit"
            [amount]="amount"
            [offerId]="offerId">
        </cdux-funding-input-amount>

        <div class="ts-message error" *ngIf="!!errorMessage">{{ errorMessage }}</div>

        <div class="ts-body toc">You will login with your bank credentials.</div>

        <cdux-funding-deposit-button
            (click)="navigateToEzBank()"
            [disabled]="form.invalid || form.get('amount').value <= 0"
            [inboundBet]="inboundBet">
        </cdux-funding-deposit-button>
    </div>

</form>
