import { IResultsPool, IResultsAlsoRan } from '@cdux/ng-common';

export class ResultsSortUtils {
    /**
     * sortPools
     *
     * sort pools by `seq` property
     *
     * @static
     * @param {IResultsPool[]} pools
     * @returns {IResultsPool[]}
     * @memberof ResultsSortUtils
     */
    public static sortPools(pools: IResultsPool[]): IResultsPool[] {
        return pools && [ ...pools ].sort((a, b) => a.seq - b.seq);
    }

    /**
     * sortAlsoRans
     *
     * sorts AlsoRans by `finishPosition` property
     *
     * @static
     * @param {IResultsAlsoRan[]} alsoRans
     * @returns {IResultsAlsoRan[]}
     * @memberof ResultsSortUtils
     */
    public static sortAlsoRans(alsoRans: IResultsAlsoRan[]): IResultsAlsoRan[] {
        return alsoRans && [ ...alsoRans ].sort((a, b) => {
            // sort "0" positions after anything else
            if (+a.finishPosition === 0) { return 1; }
            if (+b.finishPosition === 0) { return -1; }
            return a.finishPosition - b.finishPosition
        });
    }
}
