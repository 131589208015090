import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { take } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import { EventClickType, TranslateService, JwtSessionService, Ezmoney, FeatureToggleDataService, enumFeatureToggle, IPublicTokenExchangeRequest } from '@cdux/ng-common';

import { enumDepositOptions } from '../abstract-method.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingAbstractMethodDepositComponent } from '../abstract-method-deposit.component';
import { FundingService } from '../../../shared/services/funding.service';
import { FundingValidators } from '../../../shared/validators/funding.validators';
import { FundingEzmoneyService } from '../../../shared/services/ezmoney.service';
import { IFundingAccountDetails } from '../../../shared/interfaces/funding.interfaces';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from '../../../../sidebar/enums/loader.enums';
import { HeaderService } from 'app/shared/header/services/header.service';


const EZM_VALIDATION_ERROR = 2106; // validation error code for ez money
const EZM_GENERAL_ERROR = 1000;
const EZM_PLAID_UPDATE_REQUIRED = 2205;

@Directive()
export class AbstractEzmoneyMethodDepositComponent extends FundingAbstractMethodDepositComponent implements OnInit {

    protected isFullPage: boolean;

    public eventClickType = EventClickType;

    public successMessage = '';

    public plaidEnabled: boolean;
    public plaidMaskedAcct: string;
    protected plaidSession: IPublicTokenExchangeRequest;

    constructor(
        protected _router: Router,
        protected _eventTrackingService: EventTrackingService,
        protected _cdr: ChangeDetectorRef,
        protected _ezMoneyService: FundingEzmoneyService,
        protected _currencyPipe: CurrencyPipe,
        protected _translateService: TranslateService,
        localEnvironment: ENVIRONMENT,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            _router,
            localHeaderService,
            localEnvironment,
            localFb,
            localFundingService,
            localSidebarService,
            _eventTrackingService,
            _translateService,
            localSessionService,
            localToggleService,
            _cdr
        );
        this.operationMethod = enumDepositOptions.EZ_MONEY;
    }

    public ngOnInit() {
        this.plaidEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PLAID_ENABLED);
        let fieldRequired:boolean;
        if (this.plaidEnabled) {
            fieldRequired = false;
        } else {
            fieldRequired = !this.fundingMethodDetails.accountInfo || this.replaceAccountInfo;
        }
        this._initForm([
            {
                name: 'amount',
                default: this.getInitialDepositAmount(),
                validators: [
                    Validators.min(this.getMinDepositAmount(this.hasVerifiedAccount)),
                    Validators.max(this.getMaxDepositAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            },
            {
                name: 'routingNumber',
                default: '',
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired),
                    Validators.minLength(9),
                    Validators.maxLength(9),
                    Validators.pattern('[0-9]{9}')
                ]
            },
            {
                name: 'accountNumber',
                default: '',
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired),
                    Validators.minLength(4),
                    Validators.maxLength(17),
                    Validators.pattern('[0-9]{4,17}')
                ]
            },
            {
                name: 'accountType',
                default: 'checking',
                validators: [
                    FundingValidators.conditionalRequired(fieldRequired)
                ]
            }
        ]);
    }

    public get hasVerifiedAccount(): boolean {
        return this.fundingMethodDetails['verified'] && !this.replaceAccountInfo;
    }

    /**
     * Triggers a deposit in the Business Service
     */
    public onDeposit() {
        this.logDepositStartGtmEvent();
        this.pendingDeposit = true;

        this.errorCodeArgs = null;
        if (this.flowAttribute) {
            this._eventTrackingService.logClickEvent(this.eventClickType.DEPOSIT_EZMONEY, this.flowAttribute);
        } else {
            this._eventTrackingService.logClickEvent(this.eventClickType.DEPOSIT_EZMONEY);
        }

        const amount = this.form.get('amount').value;
        const accountNumber = this.form.get('accountNumber').value;

        let accountDetails: IFundingAccountDetails = null;
        let accountInfo = this.fundingMethodDetails.accountInfo;
        if (!accountInfo || this.replaceAccountInfo) {
            if (this.plaidEnabled) {
                accountInfo = this.plaidMaskedAcct;
            } else {
                accountInfo = accountNumber.slice(accountNumber.length - 4);
            }
        }

        this._sidebarService.showLoadingOverlay(this.isBetShare ? SIDEBAR_LOADERS.BET_SHARE_SPINNER : SIDEBAR_LOADERS.SPINNING_LOADER);
        if (!this.fundingMethodDetails.accountInfo || this.replaceAccountInfo) {
            accountDetails = this._formatAccountDetails();
        }

        this._fundingService.onFundingEvent.emit({event: 'DEPOSIT'});
        this._cdr.detectChanges();
        this.successMessage = `${this._currencyPipe.transform(amount, 'USD', 'symbol-narrow')} has been deposited to your account with EZMoney account ending in ${accountInfo}.`;
        this._subscriptions.push(
            this._ezMoneyService.deposit(amount, accountDetails).pipe(take(1)).subscribe((res: any) => {
                if (res.errorCode === EZM_VALIDATION_ERROR || res.errorCode ===  EZM_GENERAL_ERROR) {
                    this.errorCodeArgs = this._translateService.translate('customer-service', this.AFFILIATE_KEY, false);
                }

                this._handleResponse(
                    res,
                    this.successMessage,
                    amount,
                    this.isFullPage
                );
            })
        );
    }

    /**
     * Gets the Maximum Allowed Deposit
     */
    public getMaxDepositAmount() {
        return Ezmoney.getMaxDeposit(this.fundingMethodDetails);
    }

    public plaidUpdateRequired(code: number) {
        return this.plaidEnabled && code === EZM_PLAID_UPDATE_REQUIRED;
    }

    protected plaidVerificationRequired(): boolean {
        return  this.plaidEnabled && (!this.fundingMethodDetails.accountInfo || this.replaceAccountInfo)
    }

    private _formatAccountDetails(): IFundingAccountDetails {
        if(this.plaidVerificationRequired()) {
            return {
                plaidSession: this.plaidSession
            }
        } else {
            return {
                accountNumber: this.form.get('accountNumber').value,
                routingNumber: this.form.get('routingNumber').value,
                accountType: this.form.get('accountType').value
            };
        }
    }
}
