import { Injectable, NgZone } from '@angular/core';
import { ModalService, ModalRef } from '@cdux/ng-platform/web';
import { take } from 'rxjs/operators';
import { TournamentsNotAvailableModalComponent } from '../components/tournaments-not-available-modal/tounraments-not-available-modal.component';
 
export interface ItournamentsNotAvailableModalConfig {
    title: string,
    notice: string,
    destinationPath: string | null
}

@Injectable({
    providedIn: 'root'
})
export class TournamentsNotAvailableModalService {

    private _modalRef: ModalRef<TournamentsNotAvailableModalComponent>;

    public get modalRef(): ModalRef<TournamentsNotAvailableModalComponent> {
        return this._modalRef;
    }

    constructor(
        private _zone: NgZone,
        private _modalService: ModalService
    ) { }

    public openModal(modalConfig: ItournamentsNotAvailableModalConfig): Promise<boolean> {
       return new Promise((resolve, reject) => {
           if (!this._modalRef) {
                this._modalRef = this._modalService.open(TournamentsNotAvailableModalComponent, {
                    context:{
                        config: modalConfig
                    },
                    padding: '0px',
                    maxHeight: '100vh',
                    maxWidth: '100vh',
                    overflow: 'hidden',
                    hasBackdrop: true
                });
                this._modalRef.afterClosed.pipe(
                    take(1)
                ).subscribe(() => {
                    this._modalRef = null;
                    resolve(true);
                });
           } else {
               reject(false);
           }
       })
    }

    public showModal(config: ItournamentsNotAvailableModalConfig) {
        this._zone.run(() => {
            this.openModal(config);
        });
    }
}