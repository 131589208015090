<div class="tux-framework__scrim" [ngClass]="{'tux-framework__scrim--fade-in' : drawerOpen}">
</div>
<aside [class]="activeDrawerClasses">
    <header class="drawer--horizontal__header">
        <h1 class="h1--standard--margin-0">{{title}}</h1>
        <!-- in cdux-tux-template, be sure to add onclick="this.blur();" to make sure the icon-button doesn't stay focused after click -->
        <button
            class="icon-button icon--close--standard"
            id="close-right-drawer"
            onclick="this.blur();"
            (click)="closeDrawer()"
        ></button>
    </header>
    <section class="drawer--horizontal__section--content">
        <ng-template #drawerPortal></ng-template>
    </section>
</aside>
