import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    ITrackBasic,
    enumRaceStatus,
    ITodaysRaceCarryover
} from '@cdux/ng-common';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { ICarryoverBanner } from '../interfaces/carryover-banner.interface';

export class CarryoverBannerHandler extends CduxRxJSBuildingBlock<any, ICarryoverBanner> {

    protected _stream: Observable<ICarryoverBanner>;

    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false); // start un-paused
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _todaysRacesService: TodaysRacesBusinessService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }
    /** END EXTERNAL CONTROLS **/

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = this._pause.pipe(
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                switchMap(track => this._getCarryover(track))
            )),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<ICarryoverBanner>;
    }

    /**
     * Retrieves a single carryover for a given track
     */
    private _getCarryover(track: ITrackBasic): Observable<ICarryoverBanner> {
        return this._todaysRacesService.getTodaysRaces(track.BrisCode, track.TrackType, false).pipe(take(1), map(races => {

            let highestCarryover: ITodaysRaceCarryover = null;
            let highestRaceNumber: number = null;
            races.forEach(race => {
                if (race.status !== enumRaceStatus.OPEN) {
                    return;
                }
                race.carryover.forEach(carryover => {
                    if (!highestCarryover || highestCarryover.amount < carryover.amount) {
                        highestCarryover = carryover;
                        highestRaceNumber = race.raceNumber;
                    }
                });
            });

            if (highestCarryover) {
                return {
                    poolType: highestCarryover.poolCode,
                    poolName: highestCarryover.poolName,
                    amount: highestCarryover.amount,
                    raceNumber: highestRaceNumber
                } as ICarryoverBanner;
            }
        }));
    }

}
