<!--adding transform style resolves DE13544, a rendering issue in chrome,
by forcing hardware acceleration which aides in rendering the view
see comments in stack overflow post:
https://stackoverflow.com/questions/42766830/angular-2-chrome-dom-rendering-problems
-->
<div class="my-account-row-wrapper">

    <div class="user-name"> {{ firstName }} </div>

    <!--my account - manage -->
    <div class="my-account-row" (click)="goToMyAccountManage()">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'manage-title' | cduxTranslate: 'my-account' }}
            </div>
            <div class="my-account-row-info-body">
                {{ manageTitle | cduxTranslate: 'my-account' }}
            </div>
        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!--end my account -history row-->

    <!--my account - history -->
    <ng-container *cduxMediaToggle="'desk-to-phone'">
        <div class="my-account-row" *ngIf="accountHistoryToggle"
            [cduxLogClick]="eventClickType.MAIN_NAV_MENU_ACCOUNT_HISTORY" (click)="goToAccountHistory()">

            <div class="my-account-row-info" style="transform: translate(0%, 0%);">
                <div class="my-account-row-info-header">
                    {{ 'history-title' | cduxTranslate: 'my-account' }}
                </div>
                <div class="my-account-row-info-body">
                    {{ 'history-subtitle' | cduxTranslate: 'my-account' }}
                </div>
            </div>

            <div class="my-account-row-arrow-right">
                <i class="icon--arrow-right--white"></i>
            </div>
        </div>
    </ng-container>
    <!--end my account -history row-->

    <div class="my-account-row" *ngIf="(accountHistoryToggle && isPhone) || !accountHistoryToggle"
        [cduxLogClick]="eventClickType.MAIN_NAV_MENU_ACCOUNT_HISTORY" (click)="goToAccountHistorySmallGlass()">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div *ngIf="!accountHistoryToggle" class="my-account-row-info-header">
                {{ 'daily-history-title' | cduxTranslate: 'my-account' }}
            </div>
            <div *ngIf="!accountHistoryToggle" class="my-account-row-info-body">
                {{ 'daily-history-subtitle' | cduxTranslate: 'my-account' }}
            </div>
            <div *ngIf="accountHistoryToggle" class="my-account-row-info-header">
                {{ 'history-title' | cduxTranslate: 'my-account' }}
            </div>
            <div *ngIf="accountHistoryToggle" class="my-account-row-info-body">
                {{ 'history-subtitle' | cduxTranslate: 'my-account' }}
            </div>
        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>

    <ng-container *cduxMediaToggle="'desk-to-phone'">
        <!-- hide on small glass -->
        <!--my account - Recall Today's Bets row-->
        <div *ngIf="!environment.accountMenuConfig?.hideRecallTodaysBets && !accountHistoryToggle"
            class="my-account-row" (click)="goToRTB()">

            <div class="my-account-row-info">
                <div class="my-account-row-info-header">
                    {{ 'rtb-title' | cduxTranslate: 'my-account' }}

                </div>
                <div class="my-account-row-info-body">
                    {{ 'rtb-subtitle' | cduxTranslate: 'my-account' }}
                </div>

            </div>

            <div class="my-account-row-arrow-right">
                <i class="icon--arrow-right--white"></i>
            </div>
        </div>
        <!--end my account Recall Today's Bets row-->
    </ng-container>

    <!--my account - cashier row-->
    <div class="my-account-row" (click)="goToCashier()">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'cashier-title' | cduxTranslate: 'my-account' }}

            </div>
            <div class="my-account-row-info-body">
                {{ 'cashier-subtitle' | cduxTranslate: 'my-account' }}
            </div>

        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!--end my account -cashier row-->

    <!--my account - Rewards row-->
    <div *ngIf="!environment.accountMenuConfig?.hideRewards" class="my-account-row" (click)="goToRewards()"
        [cduxLogClick]="eventClickType.MAIN_NAV_MENU_REWARDS">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'rewards-title' | cduxTranslate: 'my-account' }}

            </div>
            <div class="my-account-row-info-body">
                {{ 'rewards-subtitle' | cduxTranslate: 'my-account' }}
            </div>

        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!--end my account Rewards row-->

    <!--my account - Preferences row-->
    <div class="my-account-row" (click)="goToPreferences()">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'preferences-title' | cduxTranslate: 'my-account' }}
            </div>
            <div class="my-account-row-info-body">
                {{ 'preferences-subtitle' | cduxTranslate: 'my-account' }}
            </div>
        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!--end my account -preferences row-->

    <ng-container *cduxMediaToggle="'desk-to-phone'">
        <!-- hide on small glass -->
        <!--my account - W2G row-->
        <div *ngIf="!environment.accountMenuConfig?.hideTaxDocuments" class="my-account-row" (click)="goToW2G()">

            <div class="my-account-row-info">
                <div class="my-account-row-info-header">
                    {{ 'w2g-title' | cduxTranslate: 'my-account' }}

                </div>
                <div class="my-account-row-info-body">
                    {{ 'w2g-subtitle' | cduxTranslate: 'my-account' }}
                </div>

            </div>

            <div class="my-account-row-arrow-right">
                <i class="icon--arrow-right--white"></i>
            </div>
        </div>
        <!--end my account W2G row-->
    </ng-container>

    <!-- My Account - External Rewards -->
    <div *ngIf="externalRewardsUrl" class="my-account-row" id="my-account-ext-rewards-menu-item" (click)="goToExternalUrl(externalRewardsUrl)">
        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'external-rewards-title' | cduxTranslate: 'my-account' + '-' + AFFILIATE_KEY }}
            </div>
            <div class="my-account-row-info-body">
                {{ 'external-rewards-subtitle' | cduxTranslate: 'my-account' + '-' + AFFILIATE_KEY }}
            </div>
        </div>
        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!-- End My Account - External Rewards -->

    <!-- My Account - External Responsbile Gaming Center -->
    <div *ngIf="externalResponsibleGamingUrl" class="my-account-row" id="my-account-ext-rg-menu-item" (click)="goToExternalUrl(externalResponsibleGamingUrl)">
        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'external-responsible-gaming-title' | cduxTranslate: 'my-account' + '-' + AFFILIATE_KEY }}
            </div>
            <div class="my-account-row-info-body">
                {{ 'external-responsible-gaming-subtitle' | cduxTranslate: 'my-account' + '-' + AFFILIATE_KEY }}
            </div>
        </div>
        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!-- End My Account - External Responsbile Gaming Center -->

    <!-- Manage favorites: Stable Alert (NEW) row -->
    <div *ngIf="isStableAlertsEnabled" class="my-account-row" (click)="goToFavorites()">
      <div class="my-account-row-info" [class.has-notice]="hasNotificationFavorites" style="transform: translate(0%, 0%);">
        <div class="my-account-row-info-header">
          {{ 'stable-alerts-title' | cduxTranslate: 'my-account' }}
        </div>
        <div class="my-account-row-info-body">
          {{ 'stable-alerts-subtitle' | cduxTranslate: 'my-account' }}
        </div>
      </div>

      <div class="my-account-row-arrow-right">
        <i class="icon--arrow-right--white"></i>
      </div>
    </div>
    <!-- End Manage favorites: Stable Alert (NEW) row -->

    <ng-container *cduxMediaToggle="'desk-to-phone'">

        <!--my account - Detailed Account Hisotry row--><!-- TODO Account History HIP remove ACCOUNT_HITORY toggle -->
        <div *ngIf="!environment.accountMenuConfig?.hideDetailedAccountHistory && !accountHistoryToggle"
            class="my-account-row" (click)="goToDAH()">

            <div class="my-account-row-info" style="transform: translate(0%, 0%);">
                <div class="my-account-row-info-header">
                    {{ 'dah-title' | cduxTranslate: 'my-account' }}

                </div>
                <div class="my-account-row-info-body">
                    {{ 'dah-subtitle' | cduxTranslate: 'my-account' }}
                </div>

            </div>

            <div class="my-account-row-arrow-right">
                <i class="icon--arrow-right--white"></i>
            </div>
        </div>
        <!--end my account Detailed Account History row-->
    </ng-container> <!-- end 'desk-to-phone' restricted items -->

    <!--my account - signout row-->
    <div class="my-account-row" (click)="logout()">

        <div class="my-account-row-info" style="transform: translate(0%, 0%);">
            <div class="my-account-row-info-header">
                {{ 'signout-title' | cduxTranslate: 'my-account' }}
            </div>
            <div class="my-account-row-info-body">
                {{ 'signout-subtitle' | cduxTranslate: 'my-account':false:affiliateName }}
            </div>
        </div>

        <div class="my-account-row-arrow-right">
            <i class="icon--arrow-right--white"></i>
        </div>
    </div>
    <!--end my account -signout row-->
</div>
