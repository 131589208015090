<div class="video-player-wrap">
    <div class="tab-wrap" *ngIf="!isLoggedIn">
        <div class="video-player-image"></div>
        <div class="overlay--video-player" (click)="onSignInClicked()">
            <i class="icon--triangle--video-player"></i>
            <h3 class="header sign-in">
                Sign in
                <span>to view Live Streaming</span>
            </h3>
        </div>
    </div>

    <div class="tab-wrap" [class.video-player-logged-in]="isLoggedIn" *ngIf="isLoggedIn && show">
        <cdux-video-tab-bar
            *ngIf="showTabBar && videoBarType === videoBarTypeEnum.TAB"
            class="video-tab-bar"
            [class.standalone]="onStandalone || inlineTabs"
            [onStandalone]="onStandalone"
            [onTV]="onTV"
            [inlineTabs]="inlineTabs"
            [(feedAngle)]="feedAngle"
            [feedAngles]="feedAngles"
            [feedState]="feedState"
            (feedStateChange)="onFeedStateChange($event)"
            [disableLiveReplayToggle]="disableLiveReplayToggle"
            [track]="track"
            [race]="race"
            [liveRaceNumber]="liveRaceNumber"
            [liveVideoAvailable]="liveVideoAvailable">
        </cdux-video-tab-bar>

        <cdux-video-button-bar
            *ngIf="showTabBar && videoBarType === videoBarTypeEnum.BUTTON"
            [track]="track"
            [race]="race"
            [feedState]="feedState"
            [(feedAngle)]="feedAngle"
            [feedAngles]="feedAngles"
            [liveRaceNumber]="liveRaceNumber"
            [disableLiveReplayToggle]="disableLiveReplayToggle"
            [onStandalone]="false"
            [liveVideoAvailable]="liveVideoAvailable"
            (feedStateChange)="onFeedStateChange($event)">
        </cdux-video-button-bar>

        <cdux-video-select
            class="video-select"
            [class.standalone]="onStandalone"
            *ngIf="controls"
            (selected)="onSelect($event)"
            [feedState]="feedState"
            [raceDate]="raceDate"
            [track]="track"
            [race]="race">
        </cdux-video-select>

        <cdux-video-player
            class="video-player"
            [onTV]="onTV"
            [class.standalone]="onStandalone"
            [(feedAngle)]="feedAngle"
            (feedAnglesChange)="onFeedAnglesChange($event)"
            (error)="handleFeedError($event)">
        </cdux-video-player>
    </div>
</div>
