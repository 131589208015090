<div class="complete-bet" [class.todays-bets-container-overrides]="todaysBetsContainer" [ngClass]="{'odd-row' : index % 2 !== 0 }" [cduxLogClick]="bet.isExpanded ? eventClickType.MY_BETS_COLLAPSE : eventClickType.MY_BETS_EXPAND" (click)="toggleExpandedState()">
    <ng-container *ngIf="!todaysBetsContainer || isBetPad">
        <div class="complete-bet__title-bar">
            <h2 class="title">
                <div  class="track-name">{{bet.trackName}}</div>
                <div class="race-number">Race {{bet.raceNum}}</div>
            </h2>
            <h2 class="title right" [ngClass]="wagerTotalClassName" *ngIf="!bet.betShareData.betShare">{{bet.wagerTotal | currency:'USD':'symbol-narrow' | negativeSymbol}}</h2>
        </div>
        <div class="complete-bet__title-bar">
        </div>

        <div class="complete-bet__row">
            <span class="complete-bet__bet-type">{{bet.poolType | formatWager : bet.runners : bet.delimiter : undefined : bet.quickPick }}</span>
            <span class="complete-bet__bet-amount">{{ betAmount | currency:'USD':'symbol-narrow'}}</span>

            <span class="bets">{{bet.runners | formatRunners:bet.delimiter}}</span>
            <i class="icon--betshare--primary" *ngIf="bet.betShareData.betShare"></i>
        </div>

        <div class="complete-bet__row">
            <div class="icon-details-arrow" [ngClass]="{'is-open': bet.isExpanded}"></div><!-- add is-open class when the bet is clicked -->
        </div>
    </ng-container>

    <ng-container *ngIf="todaysBetsContainer && !isBetPad">
        <ng-container *ngIf="bet.betShareData.betShare">
            <div class="bet-share-cost-container">
                <i class="icon--betshare--primary"></i>
            </div>
        </ng-container>

        <div class="complete-bet__title-bar">
            <div class="track-race-container">
                <h2 class="title">{{bet.trackName}}</h2>
                <h2 class="title">Race {{bet.raceNum}}</h2>
            </div>

            <div class="bet-type-and-amount-container">
                <span class="complete-bet__bet-amount">{{ betAmount | currency:'USD':'symbol-narrow'}}</span>
                <span class="complete-bet__bet-type">{{bet.poolType | formatWager : bet.runners : bet.delimiter : undefined : bet.quickPick }}</span>
            </div>
        </div>

        <div class="complete-bet__row has-bets-container">
            <div class="bets-container">
                <cdux-runner-list
                    [runnerList]="bet.runners"
                    [runnerListDelimiter]="bet.delimiter"
                    [collapseBoxedRunners]="false"
                    [poolType]="bet.poolType"
                    [trackType]="bet.trackType"
                ></cdux-runner-list>
            </div>
        </div>

        <div class="complete-bet__row-2" *ngIf="bet.condWagerData.conditionalWager">
            <ng-container  *ngIf="!!bet.condWagerData.conditionalOdds; else exoticTemplate">
                <div class="condition"> MIN ODDS: {{bet.condWagerData.conditionalOdds | odds}} </div>
                <div class="condition"> MAX ODDS: {{bet.condWagerData.conditionalMaxOdds | odds}} </div>
            </ng-container>
            <ng-template #exoticTemplate>
                <div class="condition"> MIN PROBABLE PAYOUT: {{bet.condWagerData.conditionalProbablePayout | currency:'USD':'symbol':'.0'}} </div>
                <div class="condition"> MAX PROBABLE PAYOUT: {{bet.condWagerData.conditionalMaxProbablePayoff | currency:'USD':'symbol':'.0'}}</div>
            </ng-template>
            <div class="condition"> AT MTP: {{bet.condWagerData.conditionalMaxMtp}} </div>
        </div>
    </ng-container>

    <ng-container *ngIf="bet.condWagerData.conditionalWager && !todaysBetsContainer">
        <ng-container  *ngIf="!!bet.condWagerData.conditionalOdds; else exoticTemplate">
            <div class="complete-bet__row">
                <div class="condition"> MIN ODDS: {{bet.condWagerData.conditionalOdds | odds}} </div>
            </div>
            <div class="complete-bet__row">
                <div class="condition"> MAX ODDS: {{bet.condWagerData.conditionalMaxOdds | odds}} </div>
            </div>
        </ng-container>
        <ng-template #exoticTemplate>
            <div class="complete-bet__row">
                <div class="condition"> MIN PROBABLE PAYOUT: {{bet.condWagerData.conditionalProbablePayout | currency:'USD':'symbol':'.0'}} </div>
            </div>
            <div class="complete-bet__row">
                <div class="condition"> MAX PROBABLE PAYOUT: {{bet.condWagerData.conditionalMaxProbablePayoff | currency:'USD':'symbol':'.0'}}</div>
            </div>
        </ng-template>
        <div class="complete-bet__row">
            <div class="condition"> AT MTP: {{bet.condWagerData.conditionalMaxMtp}} </div>
        </div>
    </ng-container>

    <div class="complete-bet__total-row">
        <div class="total">Bet Total:</div>
        <div class="amount-details-container">
            <div class="title right amount" [ngClass]="wagerTotalClassName">
                <div [ngClass]="{'profit': betStatus !== wagerDisplayStatus.REFUNDED}"><span class="trophy" *ngIf= "betStatus !== wagerDisplayStatus.REFUNDED"></span>{{bet.betShareData.betShare ? bet.betShareData.participantCost : bet.wagerTotal | currency:'USD':'symbol-narrow' | negativeSymbol}}</div>
            </div>
            <div class="icon-details-arrow" [ngClass]="{'is-open': bet.isExpanded}"></div><!-- add is-open class when the bet is clicked -->
        </div>
    </div>

    <div class="complete-bet__details is-open is-visible" *ngIf="bet.isExpanded">
        <div class="complete-bet__details-row">
            <div class="complete-bet__details-label">Status</div>
            <div class="complete-bet__details-data status" [ngClass]="{'is-pending-win' : bet.isFrozen, 'is-failed': !!bet.condWagerData?.conditionalFailureReason }">{{betStatus}}</div>
        </div>
        <div *ngIf="bet.wagerTypeId === 'TOURNAMENT'" class="complete-bet__details-row" id="active-my-bets-tournament-name-container-{{index+1}}">
          <div class="my-bet__details-label" id="active-my-bets-tournament-name-header-{{index+1}}">Tournament</div>
          <div class="my-bet__details-data" id="active-my-bets-tournament-name-details-{{index+1}}">
            {{ bet.tournamentName }}
          </div>
        </div>
        <div *ngIf="bet.betShareData.betShare">
            <div class="complete-bet__details-row">
                <div class="complete-bet__details-label">My Shares</div>
                <div class="complete-bet__details-data ">{{ myBetSharesValue }}</div>
            </div>
            <div class="complete-bet__details-row">
                <div class="complete-bet__details-label">Cost of Shares</div>
                <div class="complete-bet__details-data "> 1 / {{ bet.betShareData.pricePerShare | currency:'USD':'symbol-narrow'  }}</div>
            </div>
            <div class="complete-bet__details-row">
                <div class="complete-bet__details-label">My Bet Contribution</div>
                <div class="complete-bet__details-data ">{{ bet.betShareData.participantCost | currency:'USD':'symbol-narrow' }} of {{ (isToday ? bet.wagerAmount : bet.betShareData.betShareCost) | currency:'USD':'symbol-narrow' }}</div>
            </div>
        </div>
        <div class="complete-bet__details-row">
            <div class="complete-bet__details-label">Finish</div>
            <div class="complete-bet__details-data ">{{ finishOrder }}</div>
        </div>
        <ng-container *ngIf="!unsubmittedCondWager">
            <div class="complete-bet__details-row" id="active-my-bets-transaction-id-container-{{index+1}}">
                <div class="complete-bet__details-label" id="active-my-bets-transaction-id-header-{{index+1}}">Transaction ID</div>
                <div class="complete-bet__details-data " id="active-my-bets-transaction-id-details-{{index+1}}">{{bet.serialNumber}}</div>
            </div>
            <div class="complete-bet__details-row" id="active-my-bets-date-container-{{index+1}}">
                <div class="complete-bet__details-label" id="active-my-bets-date-header-{{index+1}}">Date</div>
                <div class="complete-bet__details-data " id="active-my-bets-date-details-{{index+1}}">{{bet.activityDate | date: 'longDate'}}</div>
            </div>
            <div class="complete-bet__details-row" id="active-my-bets-time-container-{{index+1}}">
                <div class="complete-bet__details-label" id="active-my-bets-time-header-{{index+1}}">Time</div>
                <div class="complete-bet__details-data " id="active-my-bets-tournament-time-details-{{index+1}}">{{ bet.activityDate | date: 'mediumTime'}}/{{ bet.activityDate | timezone }}</div>
            </div>
        </ng-container>

        <div class="complete-bet__details-row function-row">
            <div class="complete-bet__function-row-left-col" *ngIf="this.canShowWagerControls">
                <i class="icon-complete-bet program" *ngIf="isProgramAvailable && !isBetPad"
                    [cduxLogClick]="eventClickType.MY_BETS_PROGRAM"
                    (click)="programNavigate(bet)" cduxClickStopPropagation
                    id="ended-program--button-{{index+1}}"></i>
                <i class="icon-my-bet play" *ngIf="isProgramAvailable && isVideoToggledOn && !isBetPad"
                    [cduxLogClick]="eventClickType.MY_BETS_VIDEO"
                    (click)="programNavigate(bet, true)" cduxClickStopPropagation
                    id="ended-play--button-{{index+1}}"></i>
            </div>
            <div class="complete-bet__function-row-right-col">
                <div class="btn-bet-slip-submit" *ngIf="bet.isFrozen && !hasFullSsn" (click)="toggleSsnCollection()" cduxClickStopPropagation>Claim Winnings</div>
            </div>
        </div>
    </div>
</div>
