<!-- NOTE: The dots in the password/pin fields are there for example purposes.
This means that when there is nothing in the fields, there should be NO dots.
The dots appear when there has been data entered into those fields
-->
<div class="pin-update-container open" [formGroup] = "formGroup">
    <div class="pin-toggle">
        <h4>Update PIN</h4>
    </div>
    <p>4 digit number.</p>

    <div class="form-label-hover current-pin-container">
            <input
                id="current-pin"
                class="new-pin"
                [ngClass]="{'has-value' : !!passwordControl.value, 'input-error': showErrorMessage(inputField.password)} "
                formControlName="currentPin"
                [type]="showPassword ? 'text' : 'password'"
                (blur)="fieldVisited(inputField.password)"
                maxlength = "4">
        <label class="ts-label" for="current-pin">Current PIN</label>
        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active' : !!passwordControl.value}"
              (click)="toggleShowPassword(inputField.password)">{{showPassword ? 'Hide' : 'Show'}}</span>

        <i [ngClass]="{'icon--valid-check': showValidIcon(passwordControl), 'icon--remove--amber' : showRemoveIcon(passwordControl)}"
           (click)="showRemoveIcon(passwordControl) && resetInput(passwordControl)"></i>
        <div *ngIf="showErrorMessage(inputField.password)" class="ts-validation error">Please enter a valid PIN.</div>

    </div>

    <div class="form-label-hover new-pin-container">
            <input
                id="new-pin"
                class="new-pin"
                [ngClass]="{'has-value' : !!newControl.value, 'input-error': showErrorMessage(inputField.newField)}"
                formControlName="newPin"
                [type]="showNewField ? 'text' : 'password'"
                (blur)="fieldVisited(inputField.newField)"
                maxlength = "4">
        <label class="ts-label" for="current-pin">New PIN</label>
        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active' : !!newControl.value}"
              (click)="toggleShowPassword(inputField.newField)">{{showNewField ? 'Hide' : 'Show'}}</span>

        <i [ngClass]="{'icon--valid-check': showValidIcon(newControl), 'icon--remove--amber' : showRemoveIcon(newControl)}"
           (click)="showRemoveIcon(newControl) && resetInput(newControl)"></i>
        <div *ngIf="showErrorMessage(inputField.newField) && !showInValidPinError()" class="ts-validation error">Please enter a valid PIN.</div>
        <div *ngIf="showErrorMessage(inputField.newField) && showInValidPinError()" class="ts-validation error">Sorry, {{newControl.value}} is too easy to guess.</div>

    </div>

    <div class="form-label-hover new-confirm-pin-container">
        <input
            id="confirm-pin"
            class="confirm-pin"
            [ngClass]="{'has-value' : !!confirmControl.value, 'input-error': showErrorMessage(inputField.confirmField)}"
            formControlName="confirmPin"
            [type]="showConfirmField ? 'text' : 'password'"
            (blur)="fieldVisited(inputField.confirmField)"
            maxlength = "4">
        <label class="ts-label" for="current-pin">Confirm PIN</label>
        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active' : !!confirmControl.value}"
              (click)="toggleShowPassword(inputField.confirmField)">{{showConfirmField ? 'Hide' : 'Show'}}</span>

        <i [ngClass]="{'icon--valid-check': showValidIcon(confirmControl), 'icon--remove--amber' : showRemoveIcon(confirmControl)}"
           (click)="showRemoveIcon(confirmControl) && resetInput(confirmControl)"></i>
        <div *ngIf="showErrorMessage(inputField.confirmField)" class="ts-validation error">Please enter a valid PIN.</div>
        <div *ngIf="showMisMatchError()" class="ts-validation error">PINs do not match</div>
    </div>

    <div class="update-cancel-button-container" [cduxFocus]="true" [scrollToTop] = 'false'>
        <div id="cancelBtn"
            class="update-cancel-button active"
            (click)="onCancel()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_PIN_CANCEL"
        >CANCEL</div>
        <div id="updateBtn"
            class="update-cancel-button"
            [ngClass]="formGroup.valid ? 'active' : 'inactive'"
            (click)="formGroup.valid && onUpdate()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_SETTINGS_PIN"
        >UPDATE</div>
    </div>
</div>
