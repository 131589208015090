import { Injectable } from '@angular/core';
import { IGanPlayerInfo, IPreRegistrationForm, RegistrationDataService } from '@cdux/ng-common';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root'})
export class GanRegistrationService {
    constructor(private _regService: RegistrationDataService) {}

    public getGanPlayerInfo(token: string): Observable<IGanPlayerInfo> {
        return this._regService.validateGanUser(token)
            .pipe(catchError(() => of(null)));
    }

    public ganResponseToIPreReg(regResponse: IGanPlayerInfo): IPreRegistrationForm {
        return {
            firstname: regResponse.firstName,
            lastname: regResponse.lastName,
            email: regResponse.email,
            address: regResponse.address,
            address2: regResponse.address2,
            zip: regResponse.zipCode,
            city: regResponse.town,
            state: regResponse.state,
            cellphone: this._formatPhoneNumber(regResponse.phone)
        };
    }

    // format the phone number otherwise the text mask directive will register it as an error
    private _formatPhoneNumber(phoneNumber: string) {
        const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + ' - ' + match[3];
        }
        return phoneNumber;
    }
}
