<div  *cduxLoading="'mfaPrefOverlay'; strategy:'overlay'; component:loadingDotsComponent;">
  <div class="my-account-row-wrapper" *ngIf="responseReceived">
    <ng-container *ngIf="mfaEnabled else mfaForm">
      <cdux-mfa-toggle [userMfaPreferences]="userMfaPreferences" (onMfaToggleUpdate)="getUpdatedMfaUserInfo($event)"></cdux-mfa-toggle>
    </ng-container>
    <ng-template #mfaForm>
      <cdux-enable-mfa-form (onMfaFormClose)="getUpdatedMfaUserInfo($event)"></cdux-enable-mfa-form>
    </ng-template>
  </div>
</div>
