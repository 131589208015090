<cdux-track-list
    class="program-nav-track-dropdown"
    [class.is-bet-pad]="programView===ProgramViewsEnum.BETPAD"
    (onTrackDropdownStateChange)="onDropdownStateChange($event)"
    [trackGroups]="tracks"
    [currentTrack]="selectedTrack"
    (onTodaysDisplayTrackChange)="onTrackSelection($event)"
    [availability]="true"
    [sectionDisplay]="trackListSections"
    [displayMode]="displayMode"
    [externalOpenEnabled]="externalOpenEnabled"
></cdux-track-list>
