import { NgModule, ModuleWithProviders } from '@angular/core';
import { ManageComponent } from './components/manage.component';
import { EmailInputComponent } from './components/email/email-input.component';
import { PasswordInputComponent } from './components/password/password-input.component';
import { PhoneInputComponent } from './components/phone/phone-input.component';
import { UpdatePinComponent } from './components/pin/update.pin';
import { TuxCommonModule } from 'app/shared/common/common.module';
import { TranslateModule, EventSessionModule } from '@cdux/ng-common';
import { CduxFormsModule } from '@cdux/ng-platform/web';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@cdux/ng-fragments';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CduxFormsModule,
        CommonModule,
        EventSessionModule,
        LoadingModule,
        ReactiveFormsModule,
        TuxCommonModule,
        TranslateModule
    ],
    declarations: [
        ManageComponent,
        EmailInputComponent,
        PasswordInputComponent,
        PhoneInputComponent,
        UpdatePinComponent
    ],
    exports: [
        ManageComponent,
        EmailInputComponent,
        PasswordInputComponent,
        PhoneInputComponent,
        UpdatePinComponent
    ]
})
export class ManageModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<ManageModule> {
        return {
            ngModule: ManageModule
        }
    }
}
