<div class="cdux-loading-wrapper" *cduxLoading="LOADING_OVERLAY_NAME; strategy:'overlay'; component:loadingDotsComponent;">
    <div class="nav-panel-content">
        <div class="my-account-row-wrapper alt-rows">
            <div *ngIf="yearsList.length > 0">
                <div *ngFor="let year of yearsList" class="my-account-row" (click)="goToYear(year)">
                    <div class="my-account-row-info">
                        <div class="my-account-row-info-header">{{ year }}</div>
                    </div>
                    <div class="my-account-row-arrow-right">
                        <i class="icon--arrow-right--white"></i>
                    </div>
                </div>
            </div>
            <div *ngIf="noYearsToShow">
                <p style="padding:25px;">You had no reportable winnings for the requested period.</p>
            </div>
        </div>
    </div>
</div>
