import { Observable, merge } from 'rxjs';
import { finalize, takeUntil, distinctUntilChanged, map, scan, share, startWith } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    BasicBetType,
    enumBetModifier,
    IBetNavObject,
    ISelectedBetAmount,
    MultiRaceExoticBetType
} from '@cdux/ng-common';

enum enumBetNavChange {
    RESET,
    TYPE ,
    MODIFIER,
    AMOUNT
}

interface BetNavChangeType {
    type: enumBetNavChange,
    value: IBetNavObject[keyof IBetNavObject] | IBetNavObject
}

export class BetNavManager extends CduxRxJSBuildingBlock<any, IBetNavObject> {
    protected _stream: Observable<IBetNavObject>;

    /** CONTROLS **/
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _betTypeOptions: Observable<(BasicBetType[] | MultiRaceExoticBetType[])>,
        private _betTypeSelections: Observable<(BasicBetType | MultiRaceExoticBetType)>,
        private _betAmountSelections: Observable<ISelectedBetAmount>,
        private _betModifierSelections: Observable<enumBetModifier>,
        private _initialValue?: IBetNavObject
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/
    /**
     * Initializes the stream.
     */
    protected _init() {
        // initial bet nav
        const updateBetNav: Observable<IBetNavObject> =  this._betTypeOptions.pipe(
            // Handles cold and hot starts (with an initial value, and not).
            this._handleFreshStart(),
            // This is necessary in addition to the operator above for instances where we're resuming a bet,
            // since we won't have an initial list of options.
            this._handleRestart(),
        );
        // update betnav for evry change in betnav
        const betNavchanges: Observable<BetNavChangeType> = merge(
            updateBetNav.pipe(map((v) => {
                return {type: enumBetNavChange.RESET, value: v};
            })),
            this._betTypeSelections.pipe(map(v => {
                return {type: enumBetNavChange.TYPE, value: v};
            })),
            this._betAmountSelections.pipe(map(v => {
                return {type: enumBetNavChange.AMOUNT, value: v};
            })),
            this._betModifierSelections.pipe(map(v => {
                return {type: enumBetNavChange.MODIFIER, value: v};
            })),
        );
        this._stream = betNavchanges.pipe(
            scan<BetNavChangeType, IBetNavObject>((acc, change) => {
                switch (change.type) {
                    case enumBetNavChange.RESET:
                        return change.value as IBetNavObject;
                    case enumBetNavChange.TYPE:
                        return this._resetBetNavConfigWithType(change.value as (BasicBetType | MultiRaceExoticBetType));
                    case enumBetNavChange.MODIFIER:
                        return {...acc, modifier: change.value as enumBetModifier};
                    case enumBetNavChange.AMOUNT:
                        return {...acc, amount: change.value as ISelectedBetAmount};
                }
            }, null),
            distinctUntilChanged(),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        );
    }

    private _resetBetNavConfigWithType(betType: (BasicBetType | MultiRaceExoticBetType)): IBetNavObject {
        const poolType = !!betType ? betType.poolType : null;
        const betModifiers: enumBetModifier[] = !!poolType ? poolType.betModifiers : null;
        const betAmounts: ISelectedBetAmount[] = !!betType ? betType.betAmounts : null;
        return {
            type: !!betType ? betType : null,
            modifier: !!betModifiers && betModifiers.length > 0 ? betModifiers[0] : null,
            amount: !!betAmounts && betAmounts.length > 0  ? betAmounts[0] : null
        }
    }

    private _resetBetNavWithInitialValue(betTypes: (BasicBetType | MultiRaceExoticBetType)[], betNav: IBetNavObject): IBetNavObject {
        const matchingBetType = betTypes.find(bt => bt.poolType === betNav.type.poolType);
        const matchingPoolType = matchingBetType ? matchingBetType.poolType : null;
        const possibleAmounts = matchingBetType && matchingBetType.betAmounts ? matchingBetType.betAmounts : [];
        const possibleModifiers = matchingPoolType && matchingPoolType.betModifiers ? matchingPoolType.betModifiers : [];
        const matchingAmount = possibleAmounts.find(a => a.value === betNav.amount.value);
        const matchingModifier = possibleModifiers.find(m => m === betNav.modifier);

        return {
            type: matchingBetType || betTypes[0] || null,
            modifier: matchingModifier || possibleModifiers[0] || null,
            amount: matchingAmount || possibleAmounts[0] || null
        };
    }
    /** CUSTOM OPERATORS **/
    /**
     * Handles a fresh start of the Bet Nav Manager (on page start up).
     *
     * @private
     */
    private _handleFreshStart() {
        return map((betNavList: (BasicBetType[] | MultiRaceExoticBetType[]), callNum) => {
            if (betNavList && betNavList.length > 0) {
                // Handle starting with an initial value.
                if (callNum === 0 && this._initialValue) {
                    return this._resetBetNavWithInitialValue(betNavList, this._initialValue);
                }
                // Default to the first bet type available.
                return this._resetBetNavConfigWithType(betNavList[0]);
            }
            return null;
        });
    }

    /**
     * Helps handle reinitialization with a value.
     *
     * @private
     */
    private _handleRestart() {
        return this._initialValue ? startWith(this._initialValue) : src => src;
    }
    /** END CUSTOM OPERATORS **/
}
