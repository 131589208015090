import { Component, Input } from '@angular/core';

@Component({
  selector: 'cdux-my-fav',
  templateUrl: './my-fav.component.html',
  styleUrls: ['./my-fav.component.scss']
})
export class MyFavComponent {
    /**
     * allow toggle attribute (true if present, regardless of value)
     * https://stackoverflow.com/a/42009572/356016
     *
     * @private
     */
    private _showText: boolean = false;
    @Input() set showText (val) {
        this._showText = true; // only called when the attribute exists
    }
    get showText () {
        return this._showText;
    }

    private _isFavorite: boolean = true;
    @Input() set isFavorite (val) {
        this._isFavorite = val;
    }
    get isFavorite () {
        return this._isFavorite;
    }
}
