/* istanbul ignore file */

import { Injectable, ComponentRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { of, ReplaySubject, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import { Menu, CduxMenuDataService } from '@cdux/ng-common';
import { CduxRouteUtil } from 'app/shared/common/utils';
import { FallBackHeaderMenuItems } from 'app/shared/header/data/fallBackMenuItems'
import { IMobileHeaderConfig } from '../models';

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    /**
     * @internal
     */
    private _config: IMobileHeaderConfig = {};

    public get config(): IMobileHeaderConfig {
        return this._config;
    }

    /**
     * when the config is set, update the options in the existing object
     * this will update existing properties from the passed in config
     * as well as add new properties if they were included
     */
    public set config(config: IMobileHeaderConfig) {
        Object.assign(this._config, config);
    }

    public attached: ReplaySubject<ComponentRef<any>> = new ReplaySubject(1);

    /**
     * @internal
     */
    public headerChange: ReplaySubject<IMobileHeaderConfig> = new ReplaySubject(1);
    public openSubMenu: Subject<number> =  new Subject<number>();

    public menu: Menu[] = [];
    public topLevelRoute: boolean = false;
    public reverseNavigation: boolean = false;

    constructor(
        private _environment: ENVIRONMENT,
        private _menuService: CduxMenuDataService,
        private _route: ActivatedRoute,
        private _router: Router
    ) {
        this._menuService.getMenu(this._environment.affiliateId, 'TUX').pipe(
            catchError(() => of(FallBackHeaderMenuItems))
        ).subscribe((menu) => {
            this.menu = menu;
            this.topLevelRoute = this.isTopLevelRoute(this._router.url);
           // this._changeDetector.detectChanges();
        });

        this._router.events.subscribe(routerEvent => {
            if (routerEvent instanceof NavigationEnd) {
                this.topLevelRoute = this.isTopLevelRoute(this._router.url);
                CduxRouteUtil.navigate(this._route, this.topLevelRoute, this.reverseNavigation);
                // On Route changes, update the header config so that the video can be shown or hidden
                const routeData = CduxRouteUtil.getCurrentPathConfig(this._route.snapshot);
                this.updateHeader({ showVideoButton: routeData.showMobileVideoButton });
            }
        });

        this.openSubMenu.subscribe((menuIndex) => {
            this.menu[menuIndex].submenuOpen = true;
        });

        this.setDefaultHeader();
    }

    /**
     * Updates the header config and broadcasts update
     *
     * @param config
     */
    public updateHeader(config: IMobileHeaderConfig) {
        if (config) { // merge in the new config options
            this.config = { ...this.config, ...config };
            this.headerChange.next(this.config);
        }
    }

    /**
     * Set the mobile header config to its default parameters
     */
    public setDefaultHeader() {
        this.updateHeader({
            dynamicRef: null,
            dynamicRefSub: null,
            showVideoButton: false,
            staticRaceData: null,
            staticContent: null
        });
    }

    public goBack() {
        const history = CduxRouteUtil.history;
        this.reverseNavigation = true;

        let path: string;
        if (history && history.length > 1) {
            path = history[history.length - 2].pathFromRoot.params.join('/');
        } else {
            path = CduxRouteUtil.getParentPath(this._route);
        }

        if (path) {
            this._router.navigate([path]).then(() => {
                this.reverseNavigation = false;
            });
        } else if (path = this.findTopLevelRoute(this.menu)) {
            this._router.navigate([path]).then(() => {
                this.reverseNavigation = false;
            });
        }
    }

    public isTopLevelRoute(url: string): boolean {
        for (const menuItem of this.menu) {
            const matches = menuItem.submenu.find((submenuItem) => {
                if (submenuItem.route) {
                    return submenuItem.route.replace(CduxRouteUtil.getBaseHref(), '') === url.replace(/^\//, '');
                } else {
                    return false;
                }
            });
            if (matches) {
                return true;
            }
        }
        return false;
    }

    public findTopLevelRoute(menuItems: Menu[]): string | null {
        const currentPath = CduxRouteUtil.getPathFromRoot(this._route).path.join('/').split('/');
        let flatMenuItems = menuItems.reduce((c, v: any) => {
            const submenu = v.submenu.map((child) => {
                const tmpChild = Object.assign({}, child);
                if (tmpChild.route) {
                    tmpChild.route = tmpChild.route.split('/').filter((route) => !!route);
                }
                return tmpChild
            });
            c = c.concat(...submenu);
            return c;
        }, []);
        for (const slug of currentPath) {
            if (slug) {
                const tmpMatches = flatMenuItems.filter((v) => v.route && v.route.indexOf(slug) > -1);
                if (tmpMatches.length === 1) {
                    flatMenuItems = tmpMatches;
                    break;
                } else if (tmpMatches.length < 1) {
                    break;
                } else {
                    flatMenuItems = tmpMatches;
                }
            }
        }
        if (flatMenuItems[0] && Array.isArray(flatMenuItems[0].route)) {
            return flatMenuItems[0].route.join('/');
        }
        return null;
    }

    /**
     * @internal
     */
    public _attached(ref: ComponentRef<any>) {
        this.attached.next(ref);
    }
}
