export class RaceTypeConstants {

  public static raceTypes: object = {
    AR: {
      ALW: 'ALLOWANCE',
      AOC: 'ALLOWANCE OPTIONAL CLAIMING',
      CAN: 'CANCELLED',
      CLH: 'CLAIMING HANDICAP',
      CLM: 'CLAIMING',
      CST: 'CLAIMING STAKES',
      HCP: 'HANDICAP',
      MAT: 'MATCH RACE',
      MCL: 'MAIDEN CLAIMING',
      MSA: 'MAIDEN STARTER ALLOWANCE',
      MSW: 'MAIDEN SPECIAL WEIGHT',
      OCH: 'OPTIONAL CLAIMING HANDICAP',
      OCL: 'OPTIONAL CLAIMING',
      OCS: 'OPTIONAL CLAIMING STAKES',
      SHP: 'STARTER HANDICAP',
      SOC: 'STARTER OPTIONAL CLAIMING',
      STK: 'STAKES',
      STR: 'STARTER ALLOWANCE',
      TRL: 'TRIAL',
      WCL: 'WAIVER CLAIMING',
      WMC: 'WAIVER MAIDEN CLAIMING'
    },
    MX: {
      ALW: 'ALLOWANCE',
      AOC: 'SPEED INDEX OPTIONAL CLAIMING',
      AWT: 'ALLOWANCE TRIAL',
      CAN: 'CANCELLED',
      CLM: 'CLAIMING',
      DBY: 'DERBY',
      DCN: 'DERBY CONSOLATION',
      DTR: 'DERBY TRIAL',
      EXH: 'EXHIBITION',
      FCN: 'FUTURITY CONSOLATION',
      FTR: 'FUTURITY TRIAL',
      FUT: 'FUTURITY',
      HCP: 'HANDICAP',
      HDS: 'HANDICAP STAKES',
      INV: 'INVITATIONAL',
      MAT: 'MATURITY',
      MCH: 'MATCH',
      MCL: 'MAIDEN CLAIMING',
      MCN: 'MATURITY CONSOLATION',
      MDN: 'MAIDEN',
      MOC: 'MAIDEN OPTIONAL CLAIMING',
      MSA: 'MAIDEN STARTER ALLOWANCE',
      MTR: 'MATURITY TRIAL',
      OCL: 'OPTIONAL CLAIMING',
      SOC: 'STARTER OPTIONAL CLAIMING',
      SPI: 'SPEED INDEX RACE',
      STA: 'STARTER ALLOWANCE',
      STK: 'STAKE',
      WCL: 'WAIVER CLAIMING',
      WMC: 'WAIVER MAIDEN CLAIMING'
    },
    QH: {
      ALW: 'ALLOWANCE',
      AWT: 'ALLOWANCE TRIAL',
      CAN: 'CANCELLED',
      CHM: 'CHAMPIONSHIP',
      CLM: 'CLAIMING',
      CLS: 'CLAIMING STAKES',
      CLT: 'CLAIMING STAKES TRIAL',
      CON: 'CONSOLATION',
      CST: 'CLAIMING STAKES/TRIAL',
      DBY: 'DERBY',
      DCN: 'DERBY CONSOLATION',
      DTR: 'DERBY TRIAL',
      FCN: 'FUTURITY CONSOLATION',
      FNL: 'FINAL',
      FTR: 'FUTURITY TRIAL',
      FUT: 'FUTURITY',
      HCP: 'HANDICAP',
      HDS: 'HANDICAP STAKES',
      IHS: 'INVITATIONAL HANDICAP STAKES',
      INH: 'INVITATIONAL HANDICAP',
      INS: 'INVITATIONAL STAKES',
      INV: 'INVITATIONAL',
      MAT: 'MATURITY',
      MCH: 'MATCH RACE',
      MCL: 'MAIDEN CLAIMING',
      MCN: 'MATURITY CONSOLATION',
      MDN: 'MAIDEN',
      MDT: 'MAIDEN TRIAL',
      MOC: 'MAIDEN OPTIONAL CLAIMING',
      MSA: 'MAIDEN STARTER ALLOWANCE',
      MTR: 'MATURITY TRIAL',
      OCL: 'OPTIONAL CLAIMING',
      SCN: 'STAKES CONSOLATION',
      SHP: 'STARTER HANDICAP',
      SIM: 'SIMULCAST',
      SOC: 'STARTER OPTIONAL CLAIMING',
      SPC: 'SPEED INDEX CONSOLATION',
      SPF: 'SPEED INDEX FINAL',
      SPI: 'SPEED INDEX RACE',
      SPT: 'SPEED INDEX TRIAL',
      STA: 'STARTER ALLOWANCE',
      STK: 'STAKES',
      STR: 'STAKES TRIAL',
      TRL: 'TRIALS',
      UNK: 'UNKNOWN RACE TYPE',
      WCL: 'WAIVER CLAIMING',
      WMC: 'WAIVER MAIDEN CLAIMING',
      ZRC: 'CANCELLED RACE'
    },
    TB: {
      ALW: 'ALLOWANCE',
      AOC: 'ALLOWANCE OPTIONAL CLAIMING',
      CAN: 'CANCELLED',
      CLH: 'CLAIMING HANDICAP',
      CLM: 'CLAIMING',
      CST: 'CLAIMING STAKES',
      HCP: 'HANDICAP',
      HST: 'HANDICAP STAKES',
      MAT: 'MATCH RACE',
      MCL: 'MAIDEN CLAIMING',
      MDN: 'MAIDEN',
      MOC: 'MAIDEN OPTIONAL CLAIMING',
      MSA: 'MAIDEN STARTER ALLOWANCE',
      MST: 'MAIDEN STAKES',
      MSW: 'MAIDEN SPECIAL WEIGHT',
      OCH: 'OPTIONAL CLAIMING HANDICAP',
      OCL: 'OPTIONAL CLAIMING',
      OCS: 'OPTIONAL CLAIMING STAKES',
      SHP: 'STARTER HANDICAP',
      SIM: 'SIMULCAST',
      SOC: 'STARTER OPTIONAL CLAIMING',
      SPW: 'SPECIAL WEIGHT',
      SST: 'STARTER STAKES',
      STK: 'STAKES',
      STR: 'STARTER ALLOWANCE',
      TRL: 'TRIAL',
      UNK: 'UNKNOWN RACE TYPE',
      WCL: 'WAIVER CLAIMING',
      WMC: 'WAIVER MAIDEN CLAIMING'
    }
  };

}
