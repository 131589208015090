import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';

import { ENVIRONMENT, IAppConfig } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    EventClickType,
    enumConfigurationStacks,
    FeatureToggleDataService,
    IBonus,
    IOfferAssignment,
    IPreRegistrationForm,
    NativeEventEnum,
    OffersDataService,
    PlatformService,
    RegistrationResponse,
    RegistrationStatesEnum,
    TranslateService,
} from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { BrazeUtils } from '@cdux/ts-domain-braze';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractRegistrationComponent } from 'app/shared/registration/abstract-registration.component';
import { RegistrationNotificationService } from 'app/shared/registration/registration-notification.service';
import { IncomeAccessService } from 'app/analytics/income-access.service';

export interface IPromotionResponse {
    loggedIn: boolean,
    offerName: string,
    offerAssignment: IOfferAssignment
}

enum RegistrationSteps {
    CONTACT = 0,
    ADDRESS = 1,
    ACCOUNT = 2
}

const CURRENT_STEP_CLASS = 'is-current';
const COMPLETE_STEP_CLASS = 'is-complete';

@Component({
    selector: 'cdux-platform-registration',
    templateUrl: './platform-registration.component.html',
    styleUrls: ['../../shared/registration/fullpage-registration.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PlatformRegistrationComponent extends AbstractRegistrationComponent implements OnInit {

    public applicationConfig: IAppConfig;

    public firstName: string;
    public isSmallGlass: boolean = false;
    public loginClicked: boolean = false;
    public offerImgObs: Observable<string>;
    public offerName: string;
    public registrationSuccessful: boolean = false;
    public showBottomButtons: boolean = true;
    public showRetryButton: boolean = false;
    public softFailCount: number = 0;
    public validOffer: IBonus;
    public affiliateName: string;

    // Create class strings for the progress bar
    public currentStep: number = RegistrationSteps.CONTACT;
    public contactStepClass: string = CURRENT_STEP_CLASS;
    public addressStepClass: string;
    public accountStepClass: string;

    // This overrides the abstract preRegistration input...
    public preRegistration: IPreRegistrationForm = {};

    private _email: string;

    constructor(
        localConfigService: ConfigurationDataService,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFeatureToggleService: FeatureToggleDataService,
        localIncomeAccessService: IncomeAccessService,
        @Inject(BrazeUtils)  localBrazeUtils: BrazeUtils,
        localRegNotificationService: RegistrationNotificationService,
        localOffersDataService: OffersDataService,
        localRoute: ActivatedRoute,
        private _mediaQuery: CduxMediaToggleService,
        private _platformService: PlatformService,
        private _translateService: TranslateService,
    ) {
        super(
            localConfigService,
            localFeatureToggleService,
            localIncomeAccessService,
            localBrazeUtils,
            localEnvironment,
            localEventTrackingService,
            localRegNotificationService,
            localOffersDataService,
            localRoute,
        );

        this.applicationConfig = localEnvironment.appConfig;

        // override configStack with the Platform-specific value
        this.configStack = this._platformService.isAndroid() ? enumConfigurationStacks.ANDROID : enumConfigurationStacks.IOS;

        this.affiliateName = this._translateService.translate('affiliate-name', localEnvironment.affiliateId.toString()) || '';
    }

    ngOnInit() {
        super.ngOnInit();
        this.isSmallGlass = this._mediaQuery.query('phone');
        this._platformService.sendMessage(NativeEventEnum.REG_START);
    }

    /* ===============================================
     * IMPLEMENT AbstractRegistrationComponent Methods
     * =============================================== */

    /**
     * Communicates to native app when registration is submitted by user
     *
     * @memberof PlatformRegistrationComponent
     */
    public onRegistrationSubmitted() {
        // NOTE: PlatformRegistration is no longer logging this directly,
        // in favor of allowing the native app to handle its own logging
        this._platformService.sendMessage(NativeEventEnum.REG_SUBMIT);
    }

    /**
     * Handles Registration Response from FormBuilderComponent
     *
     * @param {RegistrationResponse} regResponse
     * @memberof PlatformRegistrationComponent
     */
    public onRegistrationComplete(regResponse: RegistrationResponse) {
        if (regResponse && regResponse.hasOwnProperty('status')) {
            if (regResponse.status === RegistrationStatesEnum.SUCCESS) {
                // NOTE: PlatformRegistration is no longer logging this directly,
                // in favor of allowing the native app to handle its own logging

                // communicate success event and user data to native app
                this._platformService.sendMessage(NativeEventEnum.SUCCESS, this.buildPlatformUserEventObject(regResponse));
            } else if (regResponse.status === RegistrationStatesEnum.SOFT_FAIL_ID_SCAN) {
                // HANDLE ID SCAN RESPONSES
                // TODO: Pass SOFT FAIL ID SCAN status to Android via PlatformService
                //       Android will need to handle ID Scan at which point (if successful) reg will be completed
                // Only show idScan component on small glass
                // this.idScan = this.isSmallGlass;

                // this._eventTrackingService.logClickEvent(EventClickType.REGISTRATION_PROMPT_SCAN); // TODO: note this logging is likely not needed any more, since we have put this logging in id-scan-container init()
                // If not on small glass, show a retry button but only the first time.
                //      — Though it's not explicitly supported, Platform Registration could conceivably
                //          be loaded on larger form factor devices (like tablets, chromebooks, whatevs),
                //          so leaving the following lines in place to accommodate that possibility
                this.showRetryButton = !this.isSmallGlass && !this.softFailCount;
                this.softFailCount++;
                this.hasErrors = true;
                this.registrationStatus = regResponse.status;
                this.regResponse = regResponse;
            } else {
                if (regResponse.status === RegistrationStatesEnum.SOFT_FAIL) {
                    this.showRetryButton = this.softFailCount === 0;
                    this.softFailCount++;
                }
                this._logOtherFailureType(regResponse);
            }
        } else {
            this._logOtherFailureType(regResponse);
        }
    }

    /**
     * Click handler for "Upload My Documents" to verify registration
     *
     * @memberof RegistrationComponent
     */
    public navigateToDocumentUpload() {
        // translate key for verify Path URL
        const key = 'registration-fail-verify-path';

        // get path
        const path = this._translateService.translate(
            key,
            this._environment.affiliateId.toString()
        );
        // ensure we got a path string from translate
        if (!!path && path !== key) {
            const message = {
                'path': path
            };
            this._platformService.sendMessage(NativeEventEnum.REG_UPLOAD, message);
        }

    }

    /**
     * Click handler for "Forgot Password"
     *
     * @param {string} username
     * @memberof RegistrationComponent
     */
    public triggerPasswordReset(username: string) {
        this._eventTrackingService.logClickEvent(EventClickType.REGISTRATION_RESET_PASSWORD);
        this._platformService.sendMessage(NativeEventEnum.FORGOT_PASSWORD);
    }

    /**
     * Because we have a progress bar that lives outside the formbuilder component
     * we need to respond to step change events emitted by the formbuilder
     * so the progress bar stays in sync.
     * @param $event
     */
    public onStepChange($event) {
        this.resetScroll();
        switch ($event) {
            case RegistrationSteps.CONTACT:
                this.currentStep = $event;
                this.contactStepClass = CURRENT_STEP_CLASS;
                this.addressStepClass = '';
                this.accountStepClass = '';
                break;
            case RegistrationSteps.ADDRESS:
                this.currentStep = $event;
                this.contactStepClass = COMPLETE_STEP_CLASS;
                this.addressStepClass = CURRENT_STEP_CLASS;
                this.accountStepClass = '';
                this._sendLeadCapture();
                // NOTE: PlatformRegistration is no longer logging this directly,
                // in favor of allowing the native app to handle its own logging
                break;
            case RegistrationSteps.ACCOUNT:
                this.currentStep = $event;
                this.contactStepClass = COMPLETE_STEP_CLASS;
                this.addressStepClass = COMPLETE_STEP_CLASS;
                this.accountStepClass = CURRENT_STEP_CLASS;
                this._platformService.sendMessage(NativeEventEnum.ACCOUNT_INFO)
                // NOTE: PlatformRegistration is no longer logging this directly,
                // in favor of allowing the native app to handle its own logging
                break;
            default:
                break;
        }
    }

    // END AbstractRegistrationComponent Method Implementation /

    /**
     * Click handler for "Login" button
     *
     * @memberof RegistrationComponent
     */
    public navigateToLogin() {
        this._platformService.sendMessage(NativeEventEnum.DO_LOGIN);
    }

    /**
     * Click handler for "player services" phone link
     *
     * @memberof PlatformRegistrationComponent
     */
    public triggerSupportCall() {
        this._platformService.sendMessage(NativeEventEnum.CALL_SUPPORT);
    }


    /**
     * Click handler for "terms and conditions" link
     *
     * @memberof PlatformRegistrationComponent
     */
     public navigateToTerms() {
        this._platformService.sendMessage(NativeEventEnum.SHOW_TERMS);
    }

    /**
     * Sends email to native app for lead capture
     */
    private _sendLeadCapture() {
        const message = {
            'email': this._email
        }
        this._platformService.sendMessage(NativeEventEnum.LEAD_CAPTURE, message )
    }

    /**
     * Set error state for a non soft id scan fail.
     *
     * @param regResponse
     */
    private _logOtherFailureType(regResponse: RegistrationResponse): void {
        // NOTE: PlatformRegistration is no longer logging this directly,
        // in favor of allowing the native app to handle its own logging
        this.hasErrors = true;
        this.registrationStatus = regResponse.status;
        if (!this.showRetryButton) {
            // communicate fail event and user data to native app
            this._platformService.sendMessage(NativeEventEnum.REG_FAIL, this.buildPlatformUserEventObject(regResponse));
        }
    }

    /**
     * modify regResponse to send to native app via platform service
     *
     * @param regResponse
     */
    private buildPlatformUserEventObject(regResponse: RegistrationResponse) {
        const platformUserEventObject = {
            'camId': regResponse.camGlobalId || '',
            // new properties to support Tealium events
            'firstName': regResponse.firstName || '',
            'lastName': regResponse.lastName || '',
            'email': regResponse.email || '',
            'state': regResponse.state || '',
            'city': regResponse.city || '',
            'zip': regResponse.zipCode || ''
        }
        if (!!regResponse.login && !!regResponse.login.user && !!regResponse.login.pass) {
            platformUserEventObject['username'] = regResponse.login.user;
            platformUserEventObject['password'] = regResponse.login.pass;
        }
        if (!!regResponse.offer && !!regResponse.offer.code && !!regResponse.offer.type) {
            // if present, also pass along the offer code and type
            platformUserEventObject['offerCode'] = regResponse.offer.code;
            platformUserEventObject['offerType'] = regResponse.offer.type;
        }
        if (regResponse.status !== RegistrationStatesEnum.SUCCESS) {
            platformUserEventObject['failReason'] = regResponse.statusCode
            platformUserEventObject['failType'] = this.translateStringKeyMap[regResponse.status]
        }
        return platformUserEventObject
    }

    public resetErrors(): void {
        this.hasErrors = false;
        this.registrationStatus = 0;
        this.regResponse = {};
        this.currentStep = RegistrationSteps.CONTACT;
    }

    /**
     * Because this component needs to display the name entered into the child
     * component, we need to have a method to save it when the name change event
     * is emitted.
     * @param name
     */
    public saveFirstName(name: string) {
        this.firstName = name;
    }

    /**
     * Pass updated email to Native App for Lead Capture
     * @param email
     * @memberof PlatformRegistrationComponent
     */
    public saveEmailAddress(email: string) {
        this._email = email;
    }

    /**
     * Return the text that displays under the progress bar according to the
     * current step in the registration process.
     */
    public getHelpText(): string {
        switch (this.currentStep) {
            case RegistrationSteps.CONTACT:
                const offerSegment = this.validOffer?.code ? ' to get your bonus' : '';
                return 'Sign up' + offerSegment + '. Only takes a minute to join.';
            case RegistrationSteps.ADDRESS:
                return 'Enter your address exactly as it appears on your driver’s license.';
            case RegistrationSteps.ACCOUNT:
                const firstName = this.firstName ? ', ' + this.firstName : '';
                return 'Almost there' + firstName + '. Let’s finish setting up your ' + this.affiliateName + ' account.';
        }
    }

    /**
     * sets image to default in case of error
     *
     * This does NOT get called in an infinite loop if the default
     * image is also missing.
     */
    public fetchDefaultOfferImage () {
        this._offerImgSubject.next(this._OFFER_IMAGE_DEFAULT);
    }

}
