import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service'
import { FundingService } from '../../../shared/services/funding.service';
import {FundingAbstractMethodDepositComponent} from '../abstract-method-deposit.component';
import { enumDepositOptions } from '../abstract-method.component';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { FundingMoneyGramMethodHeaderComponent } from './moneygram-method-header.component';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-moneygram-method-deposit',
    templateUrl: './moneygram-method-deposit.component.html'
})

export class FundingMoneygramMethodDepositComponent extends FundingAbstractMethodDepositComponent {

    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingMoneygramMethodDepositComponent, options);
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingMoneyGramMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        localRouter: Router,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventTrackingService: EventTrackingService,
        _environment: ENVIRONMENT,
        _translateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localHeaderService,
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _eventTrackingService,
            _translateService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumDepositOptions.MONEYGRAM;
    }

    public get hasVerifiedAccount(): boolean {
        return this.fundingMethodDetails['verified'];
    }
}
