import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppInjectorService } from './app/app-injector.service';
import { AppModule } from './app/app.module';
/* eslint-disable no-restricted-imports */
import { environment } from 'environments/environment';
/* eslint-enable no-restricted-imports */

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
    AppInjectorService.setInjector(moduleRef.injector);
});
