import { Component, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { CduxSidebarContentComponent } from '../../sidebar/cdux-sidebar-content-component.class';
import { ISidebarPortalComponent, ISidebarComponentProperties } from '../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { SidebarService } from '../../sidebar/sidebar.service';
import { of } from 'rxjs';
import { tap, delay, takeUntil } from 'rxjs/operators';

export const SIDEBAR_KEYS = {
    DISPLAY_TIME: 'displayTime',
    MESSAGE: 'message'
};

@Component({
    selector: 'cdux-account-bubble-notification',
    templateUrl: './account-bubble-notification.component.html',
    styleUrls: ['./account-bubble-notification.component.scss']
})
export class AccountBubbleNotificationComponent extends CduxSidebarContentComponent implements AfterViewInit, OnDestroy {

    /**
     * The displayTime property provides the display duration, in milliseconds,
     * for favorite notification panel
     *
     * @type {number} - representing in milliseconds
     * @memberof AccountBubbleNotificationComponent
     */
    @Input()
    public displayTime: number;

    @Input()
    public message: string;

    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**************************************************
     * Get the AccountBubbleNotificationComponent interface for
     * sidebar service to load component
     **************************************************/
    public static getSidebarComponent(options: any): ISidebarPortalComponent {
        const _inputTokens = new Map<any, any>();
        if (options.hasOwnProperty(SIDEBAR_KEYS.DISPLAY_TIME)) {
            _inputTokens.set(SIDEBAR_KEYS.DISPLAY_TIME, options[SIDEBAR_KEYS.DISPLAY_TIME])
        } else {
            _inputTokens.set(SIDEBAR_KEYS.DISPLAY_TIME, 2000); // Default display for 2 seconds
        }

        if (options.hasOwnProperty(SIDEBAR_KEYS.MESSAGE)) {
            _inputTokens.set(SIDEBAR_KEYS.MESSAGE, options[SIDEBAR_KEYS.MESSAGE])
        } else {
            _inputTokens.set(SIDEBAR_KEYS.MESSAGE, 'FAVORITES UPDATED');
        }

        return {
            component: AccountBubbleNotificationComponent,
            properties: {
                inputs: _inputTokens,
                navTarget: options.navTarget || MenuItemsEnum.PROFILE
            }
        }
    }
    public setProperties(properties: ISidebarComponentProperties): void {
        if (properties.inputs) {
            this.displayTime = properties.inputs.get(SIDEBAR_KEYS.DISPLAY_TIME);
            this.message = properties.inputs.get(SIDEBAR_KEYS.MESSAGE);
        }
    }
    /**************************************************/

    constructor(
        private _sidebarService: SidebarService
    ) {
        super();
    }

    ngAfterViewInit() {
        // Close out the success panel once it's been alive for configured
        // amount of time.
        of('').pipe(
            delay(this.displayTime),
            takeUntil(this._destroyed),
            tap(_ => this._sidebarService.close(true))
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroyed.emit(true);
    }
}
