<ng-container [ngSwitch]="displayMode">
    <ng-container *ngSwitchCase="DisplayModeEnum.LARGE">
        <div class="track-race-details" *ngIf="!!raceDetails">
            <div class="row">
                <div id="race-name" class="detail label truncated" *ngIf="raceDetails?.displayRaceName; else RaceTypeLarge"
                    [style.text-overflow]="getCustomTextOverflow(raceDetails?.grade)">
                    {{ raceDetails.displayRaceName | uppercase }}
                </div>
                <ng-template #RaceTypeLarge>
                    <div id="race-type" class="detail label truncated">
                        {{ !!raceType ? (raceType | uppercase) : NOT_AVAILABLE }}
                    </div>
                </ng-template>
            </div>
            <div class="row">
                <div id="purse" class="detail" *ngIf="!isGreyhoundTrack; else greyhoundGradeLabelLarge">
                    <span class="label">Purse:</span> {{ !!raceDetails.purse ? (+raceDetails.purse | formatPurse) : NOT_AVAILABLE }}
                </div>
                <!-- Greyhound tracks show grade instead of purse -->
                <ng-template #greyhoundGradeLabelLarge>
                    <div id="grade" class="detail">
                        <span class="label">Grade:</span> {{ !!raceDetails.grade ? raceDetails.grade : NOT_AVAILABLE }}
                    </div>
                </ng-template>
                <div id="age-restrictions" class="detail" *ngIf="raceDetails?.ageRestrictions">
                    {{ !!raceDetails.ageRestrictions ? (raceDetails.ageRestrictions | uppercase) : NOT_AVAILABLE }}
                </div>
                <div id="sex-restrictions" class="detail" *ngIf="raceDetails?.sexRestrictions">
                    {{ !!raceDetails.sexRestrictions ? (raceDetails.sexRestrictions | uppercase) : NOT_AVAILABLE }}
                </div>
                <div id="distance" class="detail">
                    {{ !!raceDetails.distance ? (raceDetails.distance | uppercase) : NOT_AVAILABLE }}
                </div>
                <div id="surface-conditions" class="detail truncated" *ngIf="!isGreyhoundTrack">
                    <span class="label">{{ surfaceLabel || NOT_AVAILABLE }}<span *ngIf="surfaceConditions">:</span></span> {{ surfaceConditions }}
                </div>
                <div class="icon-container" [class.is-active]="isShowingDescription" *ngIf="raceDetails?.raceConditions">
                    <a id="more-link" (click)="toggleShowDescription()">{{ isShowingDescription ? 'Less' : 'More' }}</a>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="DisplayModeEnum.MOBILE">
        <div class="track-race-details" *ngIf="!!raceDetails" (click)="toggleShowDescription()">
            <div id="mtp" class="mtp">
                <cdux-mtp-badge class="up-next-mtp" [source]="raceDetails" [showMinutes]="true"></cdux-mtp-badge>
            </div>
            <div class="multi-line-details">
                <div id="race-name" class="detail race-name" *ngIf="raceDetails?.displayRaceDescription; else RaceTypeMobile">
                    {{ raceDetails.displayRaceDescription | uppercase }}
                </div>
                <ng-template #RaceTypeMobile>
                    <div id="race-type" class="detail">
                        {{ !!raceType ? (raceType | uppercase) : NOT_AVAILABLE }}
                    </div>
                </ng-template>
                <ng-container *ngIf="raceDetails.purse || raceDetails.grade">
                    <div id="purse" class="detail" *ngIf="!isGreyhoundTrack; else greyhoundGradeLabelMobile">
                        {{ !!raceDetails.purse ? (+raceDetails.purse | formatPurse) : NOT_AVAILABLE }}
                    </div>
                    <!-- Greyhound tracks show grade instead of purse -->
                    <ng-template #greyhoundGradeLabelMobile>
                        <div id="grade" class="detail">
                            {{ !!raceDetails.grade ? 'Grade ' + raceDetails.grade : NOT_AVAILABLE }}
                        </div>
                    </ng-template>
                </ng-container>
                <div id="age-restrictions"
                    class="detail"
                    *ngIf="!!raceDetails.ageRestrictions">
                    {{ raceDetails.ageRestrictions | uppercase }}
                </div>
                <div id="sex-restrictions"
                    class="detail"
                    *ngIf="!!raceDetails.sexRestrictions">
                    {{ raceDetails.sexRestrictions | uppercase }}
                </div>
                <div id="distance"
                    class="detail"
                    *ngIf="!!raceDetails.distance">
                    {{ raceDetails.distance | uppercase }}
                </div>
                <div id="surface-conditions"
                    class="detail"
                    *ngIf="!!surfaceLabel && !isGreyhoundTrack">
                    {{ surfaceLabel | uppercase }}<span *ngIf="surfaceConditions">:</span> {{ surfaceConditions | uppercase }}
                </div>
            </div>
            <div class="arrow-icon">
                <i class="icon--arrow-right--white" (click)="toggleShowDescription()" *ngIf="!isGreyhoundTrack"></i>
            </div>
        </div>
    </ng-container>

</ng-container>

<ng-template #raceDetailsDrawerContent>
    <div class="race-detail-description" *ngIf="!!raceDetails && !!raceDetails.raceConditions">
        <div class="race-conditions">{{ raceDetails.raceConditions }}</div>
        <div class="surface-conditions"><span class="weather" *ngIf="weather">Weather: {{ weather }} </span></div>
        <div class="surface-conditions"><span class="surface" *ngIf="surfaceConditionsFull">{{ surfaceConditionsFull }}</span></div>
    </div>
</ng-template>
