export enum enumProgramSort {
    // Common columns
    PROGRAM = 'ProgramNumber',
    PROGRAM_NUMBER = 'ProgramNumberSort',
    SORTABLE_PROGRAM_NUMBER = 'SortableProgramNumber',
    NAME = 'Name',
    ODDS = 'OddsSort',
    ODDS_RANK = 'OddsRank',
    LIVE_ODDS = 'LiveOdds',
    MORNINGLINE_ODDS = 'MorningLineOdds',
    PROFITLINE_ODDS = 'ProfitLineOdds',
    PROFITLINE_RANK = 'ProfitLineRank',
    PROFITLINE = 'ProfitLineOddsNum',

    // Program Summary Columns
    SUMMARY_DAYS_OFF = 'SummaryDaysOff',
    SUMMARY_RUN_STYLE = 'SummaryRunStyle',
    SUMMARY_AVG_SPD = 'SummaryAverageSpeed',
    SUMMARY_BACK_SPD = 'SummaryBackSpeed',
    SUMMARY_SPD_LR = 'SummarySpeedLastRace',
    SUMMARY_AVG_CLS = 'SummaryAverageClass',
    SUMMARY_PRM_PWR = 'SummaryPrimePower',
    SUMMARY_JOCKEY_WN = 'SummaryJockeyWins',
    SUMMARY_TRAINER_WN = 'SummaryTrainerWins',
    SUMMARY_MONEY_WON = 'SummaryMoneyWon',

    // Class, Pace, Speed Sort Columns
    AVG_CLASS = 'AverageClass',
    AVG_DISTANCE = 'AverageSpeed',
    AVG_SPEED = 'AverageSpeedLast3',
    BEST_SPEED = 'BestSpeedAtDistance',
    BEST_SPEED_HARN = 'BestSpeed',
    BEST_SPEED_RANK = 'bestSpeedRank',
    DAYS_OFF = 'DaysOff',
    EARLY_PACE = 'EarlyPace',
    EARLY_PACE_1 = 'AveragePaceE1',
    EARLY_PACE_2 = 'AveragePaceE2',
    EP1_RANK = 'ep1Rank',
    EP2_RANK = 'ep2Rank',
    LAST_CLASS = 'LastClass',
    LATE_PACE = 'AveragePaceLP',
    LATE_PACE_H = 'LatePace',
    LP_RANK = 'lpRank',
    MID_PACE = 'MidPace',
    PRIME_POWER = 'PrimePower',
    POWER_RATING = 'PowerRating',
    PRIOR_RUN_STYLE = 'PriorRunStyle',
    RUN_STYLE = 'RunStyle',
    SPEED_POINTS = 'SpeedPoints',

    // Pool Sort Columns
    WIN = 'entry.Win',
    PLACE = 'entry.Place',
    SHOW = 'entry.Show',
    WINPCT = 'entry.WinPct',
    PLACEPCT = 'entryPlacePct',
    SHOWPCT = 'entry.ShowPct'
}
