import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CreditcardDataService, ICreditcardDepositRequest, ITokenizedCreditcardDepositRequest, JwtSessionService } from '@cdux/ng-common';

import { LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';
import { IFundingService } from '../interfaces/funding.interfaces';

@Injectable()
export class FundingCreditcardService implements IFundingService {

    constructor(
        private _creditCardService: CreditcardDataService,
        private _sessionService: JwtSessionService,
        private _router: Router,
    ) {
        // Empty
    }

    private validateLogin() {
        if (!this._sessionService.isLoggedIn()) {
            this._router.navigate([ LOGIN_REDIRECT_PATH ]);
        }
    }

    /**
     * Triggers a deposit for the specified Funding Method if it exists or sets it up using the supplied
     * Funding Account Details
     * @param amount - Amount to be deposited
     * @param request - Funding Method Request Parameters
     */
    public deposit(amount: string | number, request: ICreditcardDepositRequest | ITokenizedCreditcardDepositRequest): Observable<any> {
        this.validateLogin();

        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }
        request.amount = amount;

        if ('ccYear' in request && request.ccYear.length < 4) {
            // converting to int and adding 2000 covers 1- and 2-digit years
            request.ccYear = (parseInt(request.ccYear, 10) + 2000).toString(10); // code only good until 2100
        }

        if ('ccMonth' in request && request.ccMonth.length < 2) {
            request.ccMonth = '0' + request.ccMonth;
        }

        return this._creditCardService.deposit(request);
    }

    /**
     * Get a list of CC blocked BINs
     */
    public getBBList(): Observable<any> {
        return this._creditCardService.getBB();
    }
}
