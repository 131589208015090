import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IWithdrawBalance, EventClickType } from '@cdux/ng-common';

import { WithdrawOfferService } from '../../shared/services/withdraw-offer.service';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-funding-withdraw-offer',
    templateUrl: './withdraw-offer.component.html',
    styleUrls: [ './withdraw-offer.component.scss' ]
})
export class FundingWithdrawOfferComponent implements OnInit {
    @Input() public balance: IWithdrawBalance;

    public url: string = null;

    constructor(
        private _eventTrackingService: EventTrackingService,
        private _ngZone: NgZone,
        private _router: Router,
        private _sidebarService: SidebarService,
        private _withdrawOfferService: WithdrawOfferService
    ) {}

    ngOnInit() {
        this._withdrawOfferService.getOfferEligibility(this.balance).subscribe(
            (offerCode) => {
                if (offerCode) {
                    this.url = '/offers/' + offerCode + '/details';
                } else {
                    this.url = null;
                }
            }
        );
    }

    public onClick(): void {
        this._eventTrackingService.logClickEvent(EventClickType.WITHDRAW_OFFER_OTHER);
        this._sidebarService.close(true);
        this._ngZone.run(() => this._router.navigate([this.url]));
    }

}
