<div class="deposit-form-wrapper paypal" [class.extended]="!errorMessage" [class.dark]="lockout">
    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>
    <div class="ts-message error" *ngIf="errorMessage">{{ errorMessage }}</div>

    <p *ngFor="let err of paypalAccountErrors" class="paypal-error-info">
        {{err}}
    </p>

    <div class="try-again-container" *ngIf="!isToteDown">
        <div class="try-again-button" (click)="goToFunding(true)">Try Again</div>
    </div>
</div>
