import { Injectable } from '@angular/core';
import {
    enumTrackType,
    IReplayRequestOptions,
    IVideoStream,
    IVideoStreamResponse,
    ReplayFormats,
    VideoProviderName,
    VideoProviderType,
} from '@cdux/ng-common';


@Injectable()
export class VideoProviderService {
    private _currentStream: IVideoStream;
    private _streamData: IVideoStreamResponse;

    constructor() { this.reset(); }

    public set streamData (streamData: IVideoStreamResponse) {
        this._streamData = streamData;
    }
    public get streamData (): IVideoStreamResponse {
        return this._streamData;
    }

    public set currentStream (currentStream: IVideoStream) {
        this._currentStream = currentStream;
    }
    public get currentStream (): IVideoStream {
        return this._currentStream;
    }

    public get providerName (): VideoProviderName {
        return this._currentStream.provider;
    }

    public get providerType (): VideoProviderType {
        return this._streamData.platform;
    }

    public get trackCode (): string {
        return this._streamData.brisCode;
    }

    public get trackType (): enumTrackType {
        return this._streamData.trackType;
    }


    public reset() {
        this._currentStream = null;
        this._streamData = null;
    }
}

@Injectable()
export class ReplayProviderService {
    public trackCode: string;
    public trackType: enumTrackType;
    public raceNum: number;
    public raceDate: string;
    public providerName: VideoProviderName;
    public providerType: VideoProviderType;
    public options: IReplayRequestOptions;

    public static setFormatFromVideoProviderType(videoProviderType: VideoProviderType): ReplayFormats {
        let format: ReplayFormats;

        switch (videoProviderType) {
            case VideoProviderType.FLASH:
                format = ReplayFormats.FLASH;
                break;
            case VideoProviderType.ANDROID:
                format = ReplayFormats.ANDROID;
                break;
            case VideoProviderType.IPHONE:
                format = ReplayFormats.IPHONE;
                break;
        }

        return format;
    }

    // Maps a VideoProviderType (the platform we are making a request from) to a VideoProviderName (determines what sort of stream response we get)
    public static setProviderNameFromVideoProviderType(videoProviderType: VideoProviderType): VideoProviderName {
        let name: VideoProviderName;

        switch (videoProviderType) {
            case VideoProviderType.FLASH:
                name = VideoProviderName.HTTP_REPLAY;
                break;
            case VideoProviderType.ANDROID:
            case VideoProviderType.IPHONE:
            default:
                name = VideoProviderName.RCN;
        }

        return name;
    }

    public reset() {
        this.trackCode = null;
        this.trackType = null;
        this.raceNum = null;
        this.raceDate = null;
        this.providerName = null;
        this.providerType = null;
        this.options = {};
    }
}
