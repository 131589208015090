import { Component } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import { ModalService } from '@cdux/ng-platform/web';

import {
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { AbstractPaynearmeMethodDepositComponent } from './abstract-paynearme-method-deposit.component';
import { PaynearmeMethodDepositConstants } from './paynearme-method-deposit.constants';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { UntypedFormBuilder } from '@angular/forms';
import { FundingService } from '../../../shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-paynearme-method-deposit-details',
    templateUrl: './paynearme-method-deposit.component.html',
    styleUrls: ['./paynearme-method-deposit.component.scss']
})
export class FundingPaynearmeMethodDepositComponent extends AbstractPaynearmeMethodDepositComponent {

    protected readonly isFullPage: boolean = false;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(hasError: boolean = false, url?: string, fullscreen?: boolean, isBetPad?: boolean): ISidebarPortalComponent {
        const inputTokens: Map<string, any> = new Map();
        inputTokens.set(PaynearmeMethodDepositConstants.HAS_ERROR_TOKEN, hasError);
        if (url) {
            inputTokens.set(PaynearmeMethodDepositConstants.URL_TOKEN, url);
        }
        if (fullscreen !== null && fullscreen !== undefined) {
            inputTokens.set(PaynearmeMethodDepositConstants.FULLSCREEN_TOKEN, fullscreen);
        }
        if (isBetPad !== null && isBetPad !== undefined) {
            inputTokens.set(PaynearmeMethodDepositConstants.IS_BET_PAD_TOKEN, isBetPad);
        }
        return {
            component: FundingPaynearmeMethodDepositComponent,
            properties:  {
                inputs: inputTokens
            }
        };
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        localRouter: Router,
        localEventService: EventTrackingService,
        localEnvironment: ENVIRONMENT,
        localModalService: ModalService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localEventService,
            localEnvironment,
            localModalService,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            _featureToggleService
        );
        this.affiliateId = localEnvironment.affiliateId.toString();
    }

    // Override close(). PayNearMe sidebar component will popup a modal, so this need to be called for close.
    public close() {
        this._modalService.closeAll();
    }
}
