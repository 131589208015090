import { IOffer } from '../interfaces/offer.interface';
import { CduxStorage } from '@cdux/ng-common';

export class Offer implements CduxStorage.DynamicStoreable {
    public static readonly DB = 'offers';
    public static readonly VERSION = 1;
    public static readonly KEY = 'claimOffer';

    public offer: IOffer;

    constructor(offer: IOffer) {
        this.offer = offer;
    }

    /**
     * Tells the data store how to store this model.
     *
     * @returns {CduxStorage.StorageQuery}
     */
    public generateStoreableMetadata(): CduxStorage.StorageQuery {
        return {
            db: Offer.DB,
            _id: Offer.KEY,
            data: this,
            version: Offer.VERSION
        };
    }
}
