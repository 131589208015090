<div id="connection-popup" class="modal-inner-wrap lost-connection">
    <div class="modal-header">
        <h2 class="shout">{{heading}}</h2>
        <!--Close button-->
        <div class="close-btn-wrap" (click)="close()">
            <i class="icon--close dark" title="Click to close box or cancel"></i>
        </div>
    </div>

    <p class="messages is-shown">{{message}}</p>

    <div class="submitBtnWrapper">
        <button id="connection--submit-btn" type="submit" class="submit--btn ghost" (click)="action()">{{actionText}}</button>
    </div>

</div>
