import { ChangeDetectorRef, Component, Optional, Self, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AbstractDropupSelectComponent } from '@cdux/ng-fragments';
import { ModalService, CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-quick-pick-dropdown',
    templateUrl: './quick-pick-dropdown.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickPickDropdownComponent extends AbstractDropupSelectComponent<{label: string, value: string}, QuickPickDropdownComponent> {

    /**
     * Header text for the dropup.
     * @type {string}
     */
    @Input()
    public dropupHeaderText = '';

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetector
     * @param {CduxMediaToggleService} _mediaQueryService
     * @param {ModalService} _modalService
     * @param {NgControl} _control
     */
    constructor(
        _changeDetector: ChangeDetectorRef,
        _mediaQueryService: CduxMediaToggleService,
        _modalService: ModalService,
        @Self() @Optional() _control: NgControl
    ) {
        super(
            _changeDetector,
            QuickPickDropdownComponent,
            _mediaQueryService,
            _modalService,
            _control
        );
    }
}
