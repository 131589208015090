import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TuxCommonModule } from '../common/common.module';

import { ClickOutsideModule } from 'ng-click-outside';

import { EventSessionModule, WagerModule } from '@cdux/ng-common';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';
import { RouterModule } from '@angular/router';
import { RegistrationMenuItemComponent } from './components/registration-menu-item.component';
import { DepositMenuItemComponent } from './components/deposit-menu-item.component';
import { SidebarModule } from '../sidebar/sidebar.module';
import { PopUpModule } from '../pop-up/pop-up.module';
import { ProfileMenuItemComponent } from './components/profile-menu-item.component';
import { DepositCurrencyPipe } from './pipes/deposit-currency.pipe';
import { MenuItemsComponent } from './components/menu-items.component';
import { BetSlipMenuItemComponent } from './components/bet-slip-menu-item.component';
import { TodaysBetsMenuItemComponent } from 'app/shared/menu-items/components/todays-bets-menu-item.component';
import { BetsMenuItemComponent } from 'app/shared/menu-items/components/bets-menu-item.component';
import { SearchMenuItemComponent } from 'app/shared/menu-items/components/search/search-menu-item/search-menu-item.component';
import { SmallGlassSearchMenuItemComponent } from 'app/shared/menu-items/components/search/small-glass-search-menu-item/small-glass-search-menu-item.component';
import { ProgramSearchModule } from '../program-search/program-search.module';
import { InboxComponent } from './components/inbox/inbox.component'

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        CduxFormsModule,
        CduxMediaModule,
        SidebarModule,
        TuxCommonModule,
        EventSessionModule,
        WagerModule,
        PopUpModule,
        CommonModule,
        ClickOutsideModule,
        SharedFragmentsModule,
        FormsModule,
        ProgramSearchModule
    ],
    declarations: [
        DepositCurrencyPipe,
        ProfileMenuItemComponent,
        DepositMenuItemComponent,
        BetsMenuItemComponent,
        BetSlipMenuItemComponent,
        TodaysBetsMenuItemComponent,
        RegistrationMenuItemComponent,
        MenuItemsComponent,
        SearchMenuItemComponent,
        SmallGlassSearchMenuItemComponent,
        InboxComponent
    ],
    exports: [
        ProfileMenuItemComponent,
        DepositMenuItemComponent,
        BetsMenuItemComponent,
        BetSlipMenuItemComponent,
        TodaysBetsMenuItemComponent,
        RegistrationMenuItemComponent,
        MenuItemsComponent
    ],
})
export class MenuItemsModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<MenuItemsModule> {
        return {
            ngModule: MenuItemsModule,
            providers: [
                CurrencyPipe,
            ],
        }
    }
}
