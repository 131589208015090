import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * TODO: notifications probably need a mechanism to track when they've been
 *       cleared and for how long. For the initial use case, this was "race
 *       date", which didn't lend itself to a reusuable solution (we don't
 *       have a time for "race date" and we can't stringify a closure that
 *       could evaluate to a boolean).
 */

@Injectable({ providedIn: 'root' })
export class NotificationBadgeService<T> {
    /**
     * We want to send the whole list of notifications on each change,
     * not just the latest item. To do that, we'll maintain the Set
     * (queue) independently of the Subject.
     */
    protected notificationQueue: Set<T> = new Set();

    private _notifications: BehaviorSubject<Set<T>> = new BehaviorSubject(this.notificationQueue);
    public get notifications (): Observable<Set<T>> {
        return this._notifications.asObservable();
    }

    public add (item: T, expires?): boolean {
        const oldSize = this.notificationQueue.size;
        this.notificationQueue.add(item);
        if (oldSize < this.notificationQueue.size) {
            this._notifications.next(this.notificationQueue);
            return true;
        }
        return false;
    }

    public clear (): void {
        this.notificationQueue.clear();
        this._notifications.next(this.notificationQueue);
    }

    public getSnapshot (): Set<T> {
        return new Set(this.notificationQueue);
    }

    public has (item: T): boolean {
        return this.notificationQueue.has(item);
    }

    /**
     *
     * @param item
     * @param note a valid, future Date.valueOf
     */
    public remove (item: T, note: number = new Date().valueOf()): boolean {
        const rv = this.notificationQueue.delete(item);
        this._notifications.next(this.notificationQueue);
        return rv;
    }

    public get size (): number {
        return this.notificationQueue.size;
    }
}
