import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { enumTrackType, ITodaysRace, ITrackBasic, TracksDataService } from '@cdux/ng-common';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
    catchError,
    debounceTime,
    finalize,
    publishReplay,
    refCount,
    switchMap,
    take,
    takeUntil
} from 'rxjs/operators';

import { enumRaceStatus } from '@cdux/ng-common';
import { takeWhileInclusive } from 'app/shared/common/operators';

export class RaceDetailsRequestHandler extends CduxRxJSBuildingBlock<any, ITodaysRace> {

    protected _stream: Observable<ITodaysRace>;
    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(private _tracksDataService: TracksDataService) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = this._raceNavigationChanges.pipe(
            // There is an issue when we initially load integrated tux, navigate somewhere else (like horse details), then navigate back.
            // In the view-foundation component on the load of legacy tux, the onUpdateRaceNav event is hit 2 times - once from
            // handling the route and then once when the event propagates down through the components and gets sent back up.
            // For whatever reason, if these happen too quickly, the switchMap below does not complete the inner observable.
            // The second load of integrated tux seems to more often than not trigger these events too quickly.
            // Putting in the debounceTime of 0 seems to allow everything a chance to operate accordingly i.e. the inner
            // observable gets completed.
            // This manifested itself by causing the race status to still poll for an incorrect race, resulting in the occasional
            // display of the Betting is Closed message on an open race and the race automatically advancing erroneously.
            debounceTime(0),
            switchMap((track) => {
                return this._getRaceDetails(track, true);
            }),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            publishReplay(1),
            refCount()
        ) as Observable<ITodaysRace>
    }

    /** CUSTOM OBSERVABLES **/
    private _getRaceDetails(basicTrack: ITrackBasic, poll: boolean = false): Observable<ITodaysRace> {
        const brisCode: string = !!basicTrack && !!basicTrack.BrisCode ? basicTrack.BrisCode : null;
        const trackType: enumTrackType = !!basicTrack && !!basicTrack.TrackType ? basicTrack.TrackType : null;
        const raceNum: number = !!basicTrack && !!basicTrack.RaceNum ? basicTrack.RaceNum : null;
        const raceDetailsObs: Observable<ITodaysRace> = this._tracksDataService.todaysRace(brisCode, trackType, raceNum, poll).pipe(
            takeWhileInclusive(this._isNotOfficial, true),
            catchError((err) => of(null))
        );
        return poll ? raceDetailsObs : raceDetailsObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/

    /** CUSTOM OPERATORS **/
    /** END CUSTOM OPERATORS **/

    private _isNotOfficial(raceDetails: ITodaysRace): boolean {
        return !(raceDetails && [
            enumRaceStatus.OFFICIAL,
            enumRaceStatus.CLOSED,
            enumRaceStatus.CANCELED
        ].indexOf(raceDetails.status) > -1);
    }
}
