import { Directive, ElementRef, Input } from '@angular/core';
import { WaypointService } from '../services/waypoint/waypoint.service';

@Directive({
    selector: '[cduxWaypoint]'
})
export class WaypointDirective {

    /**
     * This is the key that will indicate this particular waypoint. Each waypoint should have a unique key.
     * Any component that needs a path to this waypoint will need to get the path from the Waypoint Service,
     * using a matching key.
     *
     * @type {string}
     */
    @Input() public set waypointKey(key: string) {
        if (!!key) {
            this._waypointService.registerWaypoint(this._el, key);
        }
    }

    /**
     * Constructor
     *
     * @param {WaypointService} _waypointService
     * @param {ElementRef} _el
     */
    constructor(private _waypointService: WaypointService,
                private _el: ElementRef) {}

}
