<div class="nav-panel is-down is-max-height">
  <div class="nav-panel-content">
    <ng-template #buttonRowTemplate let-dualButtons="dualButtons" let-isDisabled="isDisabled" let-isLoading="isLoading">
      <div class="button-row"[ngClass]="{'dual-buttons': dualButtons}">
        <button class="button-primary-outline" type="button" *ngIf="dualButtons" [disabled]="submitting" (click)="previousStep()">back</button>
        <button class="button-primary" [disabled]="isDisabled" (click)="formStep === MfaFormStepEnum.STEP_ONE ? nextStep() : submit()">
          <ng-container *ngIf="!isLoading; else loading">
            Next
          </ng-container>
          <ng-template #loading>
            <div class="button-loader light">
              <div class="loader-dots">
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
                <i class="dots-animated"></i>
              </div>
            </div>
          </ng-template>
        </button>
      </div>
    </ng-template>
    <ng-template #errorRowTemplate>
      <div class="error-message" [ngClass]="{'text__error-color': hasErrors, 'text__action-color': showMessage}" *ngIf="hasErrors ||
            showMessage">
        <i class="icon--bang-light amber" *ngIf="!showMessage"></i>&nbsp;&nbsp;
        &nbsp;{{errorMessage || message}}
      </div>
    </ng-template>
    <section class="multifactor-authentication-preferences" [ngSwitch]="formStep">
      <figure class="multifactor-authentication-setup-progress">
        <ng-container *ngFor="let step of stepsConfig; index as index; last as isLast">
          <div [ngClass]="step.stepClass" [attr.aria-label]="step.ariaLabel" class="step">
            <div class="step-num" [ngClass]="{'is-current': formStep === index, 'is-complete': formStep > index}">{{ index + 1 }}</div>
            <div class="step-label" [ngClass]="{ 'is-current': formStep === index }">
              {{ step.stepLabel }}
            </div>
          </div>
          <div class="connector" *ngIf="!isLast"></div>
        </ng-container>
      </figure>
      <form [formGroup]="mfaPhoneForm" (ngSubmit)="submit()">
        <ng-container *ngSwitchCase="MfaFormStepEnum.STEP_ONE">
          <h3 class="h3--standard text__white text--uppercase">Step 1</h3>
          <p>Please enter your phone number. We will use this number to verify your device through Multifactor
            Authentication.</p>
          <ng-container *ngTemplateOutlet="errorRowTemplate"></ng-container>

          <h2 class="h2--standard text__white">SMS Text Message</h2>
          <div class="form-label-hover">
            <input placeholder="xxx-xxx-xxxx" type="tel" formControlName="phone" name="phone" [cduxTextMask]="textMaskConfig">
            <label class="ts-label">Cell Phone</label>
          </div>
          <ng-container *ngTemplateOutlet="buttonRowTemplate; context: { dualButtons: false, isDisabled: mfaPhoneForm.invalid || submitting, isLoading: isLoadingDotsActive }">
          </ng-container>
        </ng-container>
      </form>

      <form [formGroup]="mfaOtpForm" (ngSubmit)="submit()">
        <ng-container *ngSwitchCase="MfaFormStepEnum.STEP_TWO">
          <h3 class="h3--standard text__white text--uppercase">Step 2</h3>
          <p>A verification code will be sent to your phone number. Please enter that code below.</p>
          <ng-container *ngTemplateOutlet="errorRowTemplate"></ng-container>

          <div class="form-label-hover">
            <input formControlName="mfaOtpCode" name="mfaOtpCode" placeholder="enter code" maxlength="6" (blur)="checkInputOnBlur()" (focus)="clearErrors()">
            <!-- can we do an auto format with angular or js to put the dashes in as the user types?  -->
            <label class="ts-label">Verification Code</label>
          </div>
          <ng-container *ngTemplateOutlet="buttonRowTemplate; context: { dualButtons: true, isDisabled: mfaOtpForm.invalid || submitting, isLoading: isLoadingDotsActive }">
          </ng-container>
          <p class="receive-code">It may take a minute to receive your code. Haven’t received it yet?</p>
          <p><a class="resend-code" [ngClass]="{'mfa-disable': showMessage}" (click)="resendOtpCode()">RESEND CODE</a></p>
        </ng-container>
      </form>
      <ng-container *ngSwitchCase="MfaFormStepEnum.STEP_THREE">
        <div class="success">
          <i class="icon--valid-check block-center"></i>
          <p class="text__action-color center-text bold-text">Successfully Activated</p>
        </div>

        <p>Verification codes will now be sent to {{cellPhone}} for logging in.</p>
        <div class="button-row">
          <button class="button-primary" type="button" (click)="closeForm()">done</button>
        </div>
      </ng-container>
    </section>
  </div>
</div>
