import { Injectable } from '@angular/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, withLatestFrom, distinctUntilChanged, mergeMap, take } from 'rxjs/operators';

import {
    Entry,
    enumPoolType,
    enumTrackType,
    ToteDataService,
    WagerService,
    IExoticProbablesDataResponse,
    IPoolsDataResponse,
    IPoolsTotal,
    IPoolType,
    IAdwRace,
    RaceInfoService,
} from '@cdux/ng-common';

import { ProgramListBusinessService } from 'app/shared/program/services/program-list.business.service';

/**
 *
 *
 * @export
 * @class ProbablesBusinessService
 */
@Injectable()
export class ProbablesBusinessService {

    private _poolTypesSub: Subscription;
    public poolTypesMap = {};
    private _probableTypesArray: string[] = [ // Hard coding this array to only get the values we're using as probables
        enumPoolType.EXACTA,
        enumPoolType.QUINELLA,
        enumPoolType.DAILY_DOUBLE
    ];

    constructor(
        private _programListBusinessService: ProgramListBusinessService,
        private _toteDataService: ToteDataService,
        private raceInfoService: RaceInfoService,
        private wagerService: WagerService
    ) {
        this._probableTypesArray.forEach((type: string) => {
            this.poolTypesMap[type] = {};
        });
    }

    /**
     * Set PoolTypes Subscription.
     */
    public setPoolsTypesSub(): void {
        if (this._poolTypesSub) {
            this._poolTypesSub.unsubscribe();
            this._poolTypesSub = null;
        }
        this._poolTypesSub = this.wagerService.getPoolTypes().subscribe((data: IPoolType[]) => {
            data.forEach((poolType: IPoolType) => {
                if (this._probableTypesArray.indexOf(poolType.Code) !== -1) {
                    this.poolTypesMap[poolType.Code] = poolType;
                }
            });
        });
    }

    /**
     * Returns an observable that will combine the latest pool Totals and probables.
     *  This method should be called when the track, race, or pool type are changed.
     *
     * @param {enumPoolType} poolType
     * @param {string} brisCode
     * @param {enumTrackType} trackType
     * @param {IAdwRace} race
     * @returns {Observable<{probablesData: IExoticProbablesDataResponse, entries: Entry[], poolTotals: IPoolsTotal[]}>}
     */
    public getProbablesWithPoolTotalsBundle(
        poolType: enumPoolType,
        brisCode: string,
        trackType: enumTrackType,
        race: IAdwRace
    ): Observable<{ probablesData: IExoticProbablesDataResponse, entries: Entry[], poolTotals: IPoolsTotal[] }> {
        if (poolType && brisCode && trackType && race) {
            const poolTotalsObs = this.getPoolsTotalsSub(brisCode, trackType, race.race).pipe(
                map((poolsTotalData) => poolsTotalData.PoolTotals)
            );

            return this.getProbablesSub(poolType, brisCode, trackType, race).pipe(
                map((probablesWithEntries) => ({ probablesData: probablesWithEntries[0], entries: probablesWithEntries[1] })),
                // Always pair with the latest poolsTotal results.
                withLatestFrom(poolTotalsObs),
                // Only emit downstream when the probables data has changed. This may not be the proper
                //  business rule for the data. If not, we can just drop the distinctUntilChanged operator
                //  Example:
                //      ----A----B----C----D----E-
                //      ------T---U--V--W----X---Y
                //      Output should be:
                //          AT, BT, CV, DW, EX
                distinctUntilChanged((first, second) => first[0].probablesData === second[0].probablesData),
                map(([probables, poolsTotals]) => {
                    return Object.assign(probables, { poolTotals: poolsTotals })
                })
            );
        }
    }

    /**
     * Return pools totals subscription.
     *
     * @param brisCode {string}
     * @param trackType {enumTrackType}
     * @param raceNum {number}
     */
    public getPoolsTotalsSub(
        brisCode: string,
        trackType: enumTrackType,
        raceNum: number
    ): Observable<IPoolsDataResponse> {
        if (brisCode && trackType && raceNum) {
            return this.raceInfoService.isClosed(brisCode, trackType, raceNum).pipe(
                mergeMap((raceIsClosed: boolean) => {
                    return this._toteDataService.exoticPools(
                        brisCode,
                        trackType,
                        raceNum.toString(),
                        !raceIsClosed
                    );
                })
            );
        }
    }

    public getProbables(
        poolType: enumPoolType,
        brisCode: string,
        trackType: enumTrackType,
        race: number
    ): Observable<IExoticProbablesDataResponse> {
        if (poolType && brisCode && trackType && race) {
            return this.raceInfoService.isClosed(brisCode, trackType, race).pipe(
                mergeMap(
                    (raceIsClosed: boolean) => {
                        return this._toteDataService.getProbablesPolling(
                            poolType,
                            brisCode,
                            trackType,
                            race,
                            raceIsClosed ? false : this._toteDataService.USE_POLLING
                        )

                    }
                )
            );
        }
    }

    /**
     * Return probable subscription based on the probable data being requested.
     * This method should be called when the track, race, or pool type are changed.
     *
     * @param poolType {enumPoolType}
     * @param brisCode {string}
     * @param trackType {enumTrackType}
     * @param race {IAdwRace}
     */
    private getProbablesSub(
        poolType: enumPoolType,
        brisCode: string,
        trackType: enumTrackType,
        race: IAdwRace
    ): Observable<[IExoticProbablesDataResponse, Entry[]]> {
        if (poolType && brisCode && trackType && race && ('race' in race)) {
            return this.raceInfoService.isClosed(brisCode, trackType, race.race).pipe(
                mergeMap(
                    (raceIsClosed: boolean) => {
                        // if the race is closed, we only want to take on result
                        // otherwise continue to poll
                        if (raceIsClosed) {
                            return combineLatest([
                                this._toteDataService.getProbables(
                                    poolType,
                                    brisCode,
                                    trackType,
                                    race.race
                                ).pipe(take(1)),
                                this._programListBusinessService.getEntriesChanges(race)
                            ]);
                        } else {
                            return combineLatest(
                                this._toteDataService.getProbables(
                                    poolType,
                                    brisCode,
                                    trackType,
                                    race.race
                                ),
                                this._programListBusinessService.getEntriesChanges(race)
                            );
                        }
                    }
                )
            );
        }
    }
}
