import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventSessionModule, TranslateModule} from '@cdux/ng-common';
import { SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';
import { CduxFormsModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from 'app/shared/common/common.module';
import { RouterModule } from '@angular/router';
import { BetSlipModule } from 'app/shared/bet-slip/bet-slip.module';
import { PlatformRegistrationComponent } from './platform-registration.component';
import { PlatformRegistrationRoutingModule } from './platform-registration.routing.module';

@NgModule({
    declarations: [
        PlatformRegistrationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CduxFormsModule,
        EventSessionModule,
        TranslateModule,
        TuxCommonModule,
        SharedFragmentsModule,
        BetSlipModule,
        PlatformRegistrationRoutingModule
    ],
    exports: [
        PlatformRegistrationRoutingModule
    ]
})
export class PlatformRegistrationModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<PlatformRegistrationModule> {
        return {
            ngModule: PlatformRegistrationModule
        }
    }
}
