import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { TrainerJockeyDetailsComponent } from "app/shared/program/components/trainer-jockey-details/trainer-jockey-details.component";
import { IDetailsOptions } from "app/shared/program/interfaces/details.interface";

@Component({
    selector: 'cdux-favorite-stats-trainer',
    templateUrl: '../../../shared/program/components/trainer-jockey-details/trainer-jockey-details.component.html',
    styleUrls: ['../../../shared/program/components/trainer-jockey-details/trainer-jockey-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteStatsTrainerComponent extends TrainerJockeyDetailsComponent implements OnInit {

    @Input() detailOptions: IDetailsOptions;

    public ngOnInit() {
        this.init(this.detailOptions);
    }
}
