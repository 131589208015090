import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
    enumPoolType,
    IExoticProbablesDataResponse,
    IPoolTotalsModel,
    ITrackBasic,
    TrackService
} from '@cdux/ng-common';
import { ProbablesBusinessService } from 'app/shared/program/services/probables.business.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { DisplayModeEnum } from 'app/shared';

@Component({
    selector: 'cdux-probables-shared',
    templateUrl: './probables-shared.component.html',
    styleUrls: ['./probables-shared.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProbablesSharedComponent {
    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isSameTrack(track, this._track)) {
            this._track = track;
            this._attemptedPoolTypes = [];
            this.resetPoolType.emit(true);
            this._update.next();
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._attemptedPoolTypes = [];
            this.resetPoolType.emit(true);
            this._update.next();
        }
    }
    public get race(): number {
        return this._race;
    }

    @Input()
    public set poolType(poolType: enumPoolType) {
        if (poolType !== this._poolType) {
            this._poolType = poolType;
            this.poolTypeChange.emit(this._poolType);
            this._update.next();
        }
    }
    public get poolType(): enumPoolType {
        return this._poolType
    }

    @Input()
    public allowedPoolTypes: enumPoolType[] = [];

    @Input()
    public displayMode: DisplayModeEnum;
    public DisplayModeEnum = DisplayModeEnum;

    @Output()
    public updateProbableAvailablePoolTypes = new EventEmitter<enumPoolType[]>();

    @Output()
    public poolTypeChange = new EventEmitter<enumPoolType>();

    // This output stinks but in some instances when we request probables with a pool type, we may get a 204 if the
    // probable does not have that type. For instance, track 1 has EX QN and DD. By default, it will load EX. After that loads,
    // switching to QN and DD isn't a problem. However, if we switch to DD and then change track to something that doesn't have DD
    // probables, we get in a funky state. So we need to reset the pool type whenever we change track/race. However, the pools
    // section component is really what handles switching between pools so we need to tell it to reset.
    @Output()
    public resetPoolType = new EventEmitter<any>();

    public probablesObs: Observable<IExoticProbablesDataResponse> = null;

    public currentPoolTotalsModel: IPoolTotalsModel;

    public isLoading: boolean = false;

    private _track: ITrackBasic;
    private _race: number;
    private _poolType: enumPoolType;

    // Attempted requests for probables for the currently selected track/race
    private _attemptedPoolTypes: enumPoolType[] = [];

    private _update = new ReplaySubject<void>(1);
    private _loadingDelay = 200;

    constructor(
        private _probablesBusinessService: ProbablesBusinessService
    ) {
        this.probablesObs = this._update.asObservable()
            .pipe(
                skipWhile(() => !(this._track && this._race && this._poolType && this.allowedPoolTypes.includes(this._poolType))),
                debounceTime(this._loadingDelay),
                tap(() => {
                    this.isLoading = true;
                    this._attemptedPoolTypes.push(this._poolType);
                }),
                switchMap(() => this._probablesBusinessService.getProbables(this.poolType, this._track.BrisCode, this._track.TrackType, this._race)),
                map((probablesData) => {
                    if (probablesData && probablesData.hasOwnProperty('poolType')) {
                        // First, make sure there are available pool types
                        if (!probablesData.availablePoolTypes) {
                            return null;
                        }

                        // Next, make sure that the probable we have requested is one that is available
                        // TODO - is this even a possible state to get in to with the webservice? If yes, should we then
                        // retry with a pool that is available?
                        if (!probablesData.availablePoolTypes.includes(this.poolType)) {
                            return null;
                        }

                        // Send up the available pool types info so the section component can show/hide options appropriately
                        this.updateProbableAvailablePoolTypes.emit(probablesData.availablePoolTypes);

                        // We can go ahead and clear out our attempted pool type requests once we have found a successful one
                        // since we tell the parent component what we now have available
                        this._attemptedPoolTypes = [];

                        // Set the current pool totals model
                        this.currentPoolTotalsModel = probablesData.poolTotalsModels.find(model => model.PoolType === this.poolType);

                        return probablesData;
                    } else {
                        const nextPoolType = this._determineNextPoolType();

                        if (nextPoolType) {
                            this.poolType = nextPoolType;
                        }

                        return null;
                    }
                }),
                catchError(() => of(null)),
                tap(() => this.isLoading = false)
            );
    }

    private _determineNextPoolType(): enumPoolType {
        return this.allowedPoolTypes.find((poolType) => !this._attemptedPoolTypes.includes(poolType))
    }
}
