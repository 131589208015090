import { FundingCheckService } from './check.service';
import { FundingCreditcardService } from './creditcard.service';
import { FundingEzmoneyService } from './ezmoney.service';
import { FundingService } from './funding.service';
import { FundingPaypalService } from './paypal.service';
import { FundingPaynearmeService } from './paynearme.service';
import { WithdrawOfferService } from './withdraw-offer.service';
import { EzbankService } from './ezbank.service';
import { PlaidBusinessService } from './plaid.business.service';

export const FUNDING_SHARED_SERVICES = [
  EzbankService,
  FundingCheckService,
  FundingCreditcardService,
  FundingEzmoneyService,
  FundingService,
  FundingPaypalService,
  FundingPaynearmeService,
  WithdrawOfferService,
  PlaidBusinessService
];
