<ng-container *cduxMediaToggle="'desk-to-phone'">

    <ng-container *ngIf="!tournamentSelected; else TournamentSelected">
        <cdux-mtp-bar *ngIf="isMtpBarEnabled; else NoMtpBar"
            [canClickOutOfToteBoard]="canClickOutOfToteBoard"
            [(isToteBoardActive)]="isToteBoardActive"></cdux-mtp-bar>
        
        <ng-template #NoMtpBar>
            <div class="affid-footer">
                <div class="icon-logo-tag-sm-white"></div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #TournamentSelected>
        <div class="affid-footer">
            <div class="tournaments-footer-logo"></div>
        </div>
    </ng-template>

</ng-container>
<div class="footer-content" *cduxMediaToggle="'phone'">
    <ng-container *ngIf="!navPanelOpen && !!portal">
        <div [cdkPortalOutlet]="portal" (attached)="_onAttached($event)"></div>
    </ng-container>
    <div [style.display]="!navPanelOpen && !!portal ? 'none' : 'block'">
        <cdux-menu-items [navPanelOpen]="navPanelOpen"></cdux-menu-items>
    </div>
</div>
