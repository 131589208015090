import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { Subscription } from 'rxjs';

import { CduxSidebarContentComponent } from '../../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../../../../sidebar/sidebar.service';


const METHOD_TOKEN = 'method-token';
const MOBILE_TOKEN = 'mobile-token';

@Component({
    selector: 'cdux-funding-pending-balance-description',
    templateUrl: './pending-balance-description.component.html',
    styleUrls: [ './pending-balance-description.component.scss' ]
})
export class FundingPendingBalanceDescriptionComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    @Input() public method: string;
    @Input() public mobileOnly: boolean = false;

    private _mediaQuerySub: Subscription;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(method: string, mobileOnly: boolean = false): ISidebarPortalComponent {
        const inputTokens: Map<any, any> = new Map();

        inputTokens.set(METHOD_TOKEN, method);
        inputTokens.set(MOBILE_TOKEN, mobileOnly);

        return {
            component: FundingPendingBalanceDescriptionComponent,
            properties: {
                inputs: inputTokens,
            }
        };
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return null;
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.method = properties.inputs.get(METHOD_TOKEN);
        this.mobileOnly = properties.inputs.get(MOBILE_TOKEN);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        private _mediaService: CduxMediaToggleService,
        private _sidebarService: SidebarService
    ) {
        super();
    }

    public ngOnInit(): void {
        this._mediaQuerySub = this._mediaService.registerQuery('phone').subscribe((val: boolean) => {
            if (val && this.mobileOnly) {
                this._sidebarService.goBack();
            }
        });
    }

    public ngOnDestroy() {
        this._mediaQuerySub.unsubscribe();
    }
}
