import { EventClickType, Menu, MenuItemType } from '@cdux/ng-common';

export const FallBackHeaderMenuItems: Menu[] = [
    {
        'title': 'Bet Now',
        'route': '/bet/todays-races/time',
        'type': MenuItemType.LINK,
        'clickEventType': EventClickType.MAIN_NAV_MENU_TODAYS_RACES,
        'desktopOnly': false,
        'authRequired': null,
        'playerGroupId': null,
        'toggles': {},
        'tooltip': {
            'tooltip': '',
            'position': ''
        },
        'order': 0,
        'submenu': [
            {
                'title': 'Today\'s Races',
                'route': '/bet/todays-races/time',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_TODAYS_RACES,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {},
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 0,
                'submenu': []
            },
            {
                'title': 'Results & Replays',
                'route': '/bet/results',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_RESULTS_REPLAYS,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {},
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 1,
                'submenu': []
            },
            {
                'title': 'Scratches & Changes',
                'route': '/bet/changes',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_CHANGES,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {},
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 2,
                'submenu': []
            },
            {
                'title': 'Carryovers',
                'route': '/bet/carryovers',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_CARRYOVER,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {
                    'SHOW_CARRYOVERS': true
                },
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 4,
                'submenu': []
            },
            {
                'title': 'Calendar',
                'route': '/bet/calendar',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_CALENDAR,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {
                    'SHOWCALENDAR': true
                },
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 5,
                'submenu': []
            }
        ]
    },
    {
        'title': 'Support',
        'route': '/bet/support',
        'type': MenuItemType.LINK,
        'clickEventType': EventClickType.MAIN_NAV_MENU_FAQ,
        'desktopOnly': false,
        'authRequired': null,
        'playerGroupId': null,
        'toggles': {},
        'tooltip': {
            'tooltip': '',
            'position': ''
        },
        'order': 4,
        'submenu': [
            {
                'title': 'Customer Support',
                'route': '/bet/support',
                'type': MenuItemType.LINK,
                'clickEventType': EventClickType.MAIN_NAV_MENU_SUPPORT,
                'desktopOnly': false,
                'authRequired': null,
                'playerGroupId': null,
                'toggles': {},
                'tooltip': {
                    'tooltip': '',
                    'position': ''
                },
                'order': 2,
                'submenu': []
            }
        ]
    }
];
