
<section class="tux-full-page-shell-container">
    <div class="tux-full-page-shell__header">
        <div class="tux-full-page-shell__header-button-left" *ngIf="showCloseButton" (click)="onCloseButtonClick.emit()">
            <i class="icon--close-light"></i>
        </div>
        <div class="tux-full-page-shel__header-title" *ngIf="title">
            {{ title }}
        </div>
    <div class="tux-full-page-shell__header-button-right" *ngIf="showButton && buttonText" (click)="onButtonClick.emit()">
            <div class="variable-button">
                {{ buttonText }}
            </div>
        </div>
    </div>
    <div class="tux-full-page-shell__content">
        <ng-content></ng-content>
    </div>
</section>
