import { EventClickType } from '@cdux/ng-common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CduxAbstractSidebarComponent } from '../cdux-sidebar-component.class';
import { ComponentPortal } from '@angular/cdk/portal';
import { ISidebarHeaderComponent, ISidebarHeaderProperties } from '../interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../sidebar.service';
import { ICduxSidebarHeaderButtonEvent } from '../interfaces/sidebar-header-button-event.interface';

@Component({
  selector: 'cdux-sidebar-header',
  templateUrl: './cdux-sidebar-header.component.html',
  styleUrls: ['./cdux-sidebar-header.component.scss']
})
export class CduxSidebarHeaderComponent {

    private _preventDefaultButtonHandler: boolean = false;

    public eventClickType: EventClickType;

    public headerProperties: ISidebarHeaderProperties;
    public headerComponent: ComponentPortal<CduxAbstractSidebarComponent>;

    public showButton: boolean = true;

    @Input()
    public  set headerContentComponent(header: ISidebarHeaderComponent) {
        if (header) {
            this.headerProperties = header.properties;
            this.headerComponent = new ComponentPortal<CduxAbstractSidebarComponent>(header.component);
        } else {
            this.headerProperties = null;
            this.headerComponent = null;
        }

        if (this.headerProperties) {
            this._preventDefaultButtonHandler = (this.headerProperties.preventDefaultHeaderButtonAction === true);

            if (this.headerProperties.hideHeaderButton === true) {
                this.showButton = false;
            } else {
                this.showButton = this._sidebarService.canGoBack();
            }

            if (this.headerProperties.onHeaderButtonClick) {
                this.onHeaderButtonClicked.subscribe(this.headerProperties.onHeaderButtonClick);
            }

            if (this.headerProperties.eventClickType) {
                this.eventClickType = this.headerProperties.eventClickType;
            }
        } else {
            this._preventDefaultButtonHandler = false;
            this.showButton = true;
        }
    }

    @Output()
    public onHeaderButtonClicked: EventEmitter<ICduxSidebarHeaderButtonEvent> = new EventEmitter<ICduxSidebarHeaderButtonEvent>();

    constructor(private _sidebarService: SidebarService) {
        this.showButton = this._sidebarService.canGoBack();
    }

    public buttonClickHandler() {
        const goBack: boolean = !this._preventDefaultButtonHandler;


        this.onHeaderButtonClicked.emit({defaultActionPrevented: this._preventDefaultButtonHandler});
        this._sidebarService.headerButtonClicked(goBack);
        if (goBack) {
           this._sidebarService.goBack();
        }
    }

}
