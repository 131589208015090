import { Injectable, NgZone } from '@angular/core';
import { ModalService, ModalRef } from '@cdux/ng-platform/web';
import { take } from 'rxjs/operators';
import { ExpertVideoComponent } from '../components/expert-video.component';

@Injectable({
    providedIn: 'root'
})
export class ExpertVideoModalService {

    private _modalRef: ModalRef<ExpertVideoComponent>;

    public get modalRef(): ModalRef<ExpertVideoComponent> {
        return this._modalRef;
    }

    constructor(
        private _zone: NgZone,
        private _modalService: ModalService
    ) { }

    public openVideoModal(url: string, autoplay: boolean, header: string): Promise<boolean> {
       return new Promise((resolve, reject) => {
           if (!this._modalRef) {
                this._modalRef = this._modalService.open(ExpertVideoComponent, {
                    context: {
                        url: url,
                        header: header,
                        autoplay: autoplay
                    },
                    padding: '0px',
                    overflow: 'hidden',
                    hasBackdrop: true
                });
                this._modalRef.afterClosed.pipe(
                    take(1)
                ).subscribe(() => {
                    this._modalRef = null;
                    resolve(true);
                });
           } else {
               reject(false);
           }
       })
    }

    public showVideo(url: string, autoplay: boolean, header: string) {
        this._zone.run(() => {
            this.openVideoModal(url, autoplay, header);
        });
    }
}
