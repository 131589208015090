<div class="bris-picks-button" [class.compact]="isCompact" (click)="openNarrative()">
    <div class="bris-picks-logo"></div>
</div>

<ng-template #raceNarrativeModal>
    <div class="bris-picks-modal">
        <div class="bris-picks-header">
            <div class="icon--close" (click)="closeNarrative()"></div>
            <ng-template [cdkPortalOutlet]="logoPortal"></ng-template>
        </div>
        <div class="bris-picks-body">
            <ng-template [cdkPortalOutlet]="raceNarrativePortal"></ng-template>
        </div>
    </div>
</ng-template>

<ng-template cdkPortal #logoPortal="cdkPortal">
    <cdux-bris-picks-logo></cdux-bris-picks-logo>
</ng-template>

<ng-template cdkPortal #raceNarrativePortal="cdkPortal">
    <cdux-bris-picks-narrative
        (close)="closeNarrative()"
        (select)="selectEntries($event)"
        [brisBetComment]="brisBetComment"
        [brisBetEntries]="brisBetEntries"
        [betType]="betType"
        [wagerState]="wagerState"
        [wagerAmount]="wagerAmount">
    </cdux-bris-picks-narrative>
</ng-template>
