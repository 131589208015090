/**
 * Created by eric.mcdowell on 7/27/17.
 */
import { enumTrackConditions } from '@cdux/ng-common';

const SYNTHETIC = 'SYNTHETIC';

export class FormatSurfaceConditionsUtil {
    /**
     * Surface should be either 'T' or 'D', corresponding to Turf and Dirt; or explicit surface label, e.g. 'Synthetic'.
     * Condition is the condition of ALL of the tracks at a specific location (i.e. 'Current Turf-Heavy, Dirt-Standard')
     * The strategy here is to get the condition of the corresponding track. Meaning if the surface is 'D', we will need
     * to try to get 'Standard' for the condition. If there is no matching condition, set it to N/A.
     * The result should be in the format Surface:Condition.
     *
     * It's also possible to receive a code for the surface condition, this is a two letter code that has a direct mapping.
     *
     * @param {string[]} value
     * @returns {any}
     */
    public static format([surface, conditions, surfaceLabel = null, conditionsLabel = null]: string[]): string {

        if (surfaceLabel && conditionsLabel) {
            return surfaceLabel + ' : ' + conditionsLabel;
        }
        // Get the surface name and condition
        const surfaceName = this.getSurfaceName(surface);
        const surfaceCondition = this.getSurfaceCondition(surface, conditions);

        // Determine the correct separator
        const separator = surfaceName && surfaceCondition ? ' : ' : '';

        return surfaceName + separator + surfaceCondition;
    }

    /**
     * Track conditions string will be something like 'Current Turf-Heavy, Dirt-Standard'
     * This method will attempt to parse it out like { Turf: 'Heavy', Dirt: 'Standard' }
     */
    public static parseTrackCondition(trackCondition: string): {[key: string]: string} {
        const parsed = {};

        // extract hyphenated condition pairs from string
        const matches = trackCondition?.match(/[A-z]+-[A-z0-9_]+/g) || [];

        // separate condition pairs and save as key/value
        matches.forEach((match) => {
            const [surface, condition] = match.split('-');
            if (surface && condition) {
                parsed[surface] = condition;
            }
        });

        return parsed;
    }

    /**
     * Translate a surface code into a surface name.
     */
    public static getSurfaceName(surface: string): string {
        // Format the code properly
        const surfaceCode = surface?.trim().toUpperCase() || '';
        return { 'D': 'Dirt', 'T': 'Turf' }[surfaceCode] || surface || '';
    }

    /**
     * Parse and extract the condition of a specific surface.
     */
    public static getSurfaceCondition(surface: string, trackCondition: string): string {
        if (surface?.trim().toUpperCase() === SYNTHETIC) {
            return ''; // Ignore conditions for Synthetic surface
        }

        let condition: string = '';

        if (trackCondition?.length === 2) {
            // Code was provided, map the code
            condition = enumTrackConditions[trackCondition.toUpperCase()];
        } else if (trackCondition) {
            // Code was not sent, determine if it's the Integrated Scratches Conditions
            // Format : "Current [Surface]-[Condition]"
            if (trackCondition && trackCondition.indexOf('Current') >= 0) {
                const surfaceName = this.getSurfaceName(surface);
                condition = FormatSurfaceConditionsUtil.parseTrackCondition(trackCondition)[surfaceName];
            } else {
                // didn't match any known format, just give it back
                condition = trackCondition
            }
        }

        // If no condition, set not available
        condition = condition || 'N/A';

        return condition;
    }

    /**
     * Parse and format conditions for all surfaces.  Optionally display a specific surface first.
     */
    public static getAllSurfaceConditions(trackCondition: string, surface?: string): string {
        const allConditionsMap = this.parseTrackCondition(trackCondition);
        const allConditionsArray = Object.keys(allConditionsMap).map((key) => {
            return [ key, allConditionsMap[key] ];
        });

        // display a specific surface first if given
        if (surface && allConditionsArray.length > 0) {
            const surfaceIndex = allConditionsArray.findIndex(([key]) => key === surface);

            if (surfaceIndex > 0) { // is not already first
                const swap = allConditionsArray[surfaceIndex];
                allConditionsArray[surfaceIndex] = allConditionsArray[0];
                allConditionsArray[0] = swap;
            }
        }

        return allConditionsArray.map((c) => c.join(': ')).join(', ');
    }
}
