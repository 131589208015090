import { Injectable, ComponentRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { MobileFooterConfig } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FooterService {

    public attached: ReplaySubject<ComponentRef<any>> = new ReplaySubject(1);

    /**
     * @internal
     */
    public _footerChange: ReplaySubject<MobileFooterConfig> = new ReplaySubject(1);

    public updateFooter(config: MobileFooterConfig | null) {
        this._footerChange.next(config);
    }

    /**
     * @internal
     */
    public _attached(ref: ComponentRef<any>) {
        this.attached.next(ref);
    }

}
