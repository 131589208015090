import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CduxModalModule } from '@cdux/ng-platform/web';

import { BrisPicksCommentComponent } from './bris-picks-comment.component';
import { BrisPicksLogoComponent } from './bris-picks-logo.component';
import { BrisPicksNarrativeComponent } from './bris-picks-narrative.component';
import { BrisPicksComponent } from './bris-picks.component';

@NgModule({
    imports: [
        CommonModule,
        CduxModalModule
    ],
    declarations: [
        BrisPicksComponent,
        BrisPicksCommentComponent,
        BrisPicksLogoComponent,
        BrisPicksNarrativeComponent
    ],
    exports: [
        BrisPicksComponent
    ]
})
export class BrisPicksModule {}
