<div class="" *ngIf="!fundingMethodDetails.locked">
  <header class="funding-content_header">
    <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
    <h1>Moneygram</h1>
    <div class="funding-type-icon">
      <i class="icon-nav-moneygram"></i>
    </div>
    <i class="close-button icon--login-close" (cduxTap)="close()" id="deposit-close-btn"></i>
  </header>
  <div class="funding-content_details-block">
    <i class="icon--moneygram"></i>
    <cdux-moneygram-method-details [displayStyle]="enumDepositDisplayStyle.FULL_PAGE">
    </cdux-moneygram-method-details>
  </div>
</div>
