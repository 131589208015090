import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ENVIRONMENTS, ENVIRONMENT } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    EventClickType,
    EventSessionBusinessService,
    EventsService,
    EventTrackingType,
    IEventClickDetails,
    IEventClickDetailsAttribute,
    PlatformService,
    JwtSessionService,
    IEventTracking,
    enumFeatureToggle,
    FeatureToggleDataService,
    UserEventEnum
} from '@cdux/ng-common';
import { Subscription } from 'rxjs';
import { EventKeys } from '../../common/events/event-keys';
import { CduxRouteUtil } from '../../common/utils/CduxRouteUtil';

@Injectable()
export class EventTrackingService extends EventSessionBusinessService implements IEventTracking {
    private subscriptions: Subscription[] = [];
    public isLoggedIn: boolean;

    constructor(
        private environment: ENVIRONMENT,
        private jwtSessionService: JwtSessionService,
        private eventsService: EventsService,
        private route: ActivatedRoute,
        private featureToggleService: FeatureToggleDataService,
        injector: Injector,
        configurationDataService: ConfigurationDataService,
        platformService: PlatformService,
    ) {
        super(injector, configurationDataService, platformService);

        this.isLoggedIn = this.jwtSessionService.isLoggedIn();
        this.subscriptions.push(this.eventsService.on(EventKeys.AUTH_STATUS_CHANGED).subscribe((isLoggedIn) => {
            if (this.isLoggedIn && !isLoggedIn) {
                if (this.environment.environment !== ENVIRONMENTS.LOCAL) {
                    this.deleteSessionId();
                    this.logEvent(EventTrackingType.SESSION_CACHE, this.getDeviceSessionData());
                }
            }
            this.isLoggedIn = isLoggedIn;
        }));
    }

    public getDeviceSessionData() {
        const sessionData = super.getDeviceSessionData();
        // DeviceJS is responsible for getting the affiliate ID in some other repositories, such as TSUI.
        // Given that DeviceJS can't find the affiliate ID in the same way on TUX, we are manually adding it here.
        if (!sessionData.affiliateId || (sessionData.affiliateId && sessionData.affiliateId.trim() === '')) {
            sessionData.affiliateId = this.environment.affiliateId;
        }
        return sessionData
    }

    public logClickEvent(eventClickType: EventClickType, attributes?: IEventClickDetailsAttribute|IEventClickDetailsAttribute[]) {
        const routeParams = CduxRouteUtil.extractParams(this.route);
        const eventData: IEventClickDetails = {
            clickEventId: eventClickType,
            timestamp: Date.now(),
            interfaceId: this.getPlatformInterfaceId() || routeParams.view || ''
        };

        if (attributes) {
            if (!Array.isArray(attributes)) {
                attributes = [ attributes ];
            }
            eventData.attributes = attributes;
        }

        this.logEvent(EventTrackingType.CLICK, eventData);
    }

    public logGtmUserEvent(type: UserEventEnum, data: any = {}) {
        if (this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.GTM)) {
            data.event = type;
            data.platform = this.environment.appConfig &&
                this.environment.appConfig.platformId;
            this.logGoogleTagManagerEvent(data);
        }
    }

    public logUserEvent(type: UserEventEnum, data: any = {}) {
        this.logGtmUserEvent(type, data);
    }

    /**
     * Unsubscribes from any lingering subscriptions.
     */
    public unsubscribeAll() {
        this.subscriptions
            .forEach(sub => sub.unsubscribe());
    }
}
