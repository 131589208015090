import { Pipe, PipeTransform } from '@angular/core';
import { AgeConstants, SexConstants } from '../../constants';

@Pipe({
  name: 'formatAgeSexRestrictions'
})
export class FormatAgeSexRestrictionsPipe implements PipeTransform {
    public transform(value: string, format: 'age'|'sex', full = false): string {
        if (value.length === 3) {
            switch (format) {
                case 'age':
                    return (AgeConstants.AgeMap[value[0]]?.[full ? 1 : 0] || '') +
                        (AgeConstants.AgeMapMods[value[1]]?.[full ? 1 : 0] || '');
                case 'sex':
                    return SexConstants.SexMap[value[2]]?.[full ? 1 : 0] || '';
            }
        }

        return '';
    }
}
