import { enumManageAccountType } from './../../enums/manage-account.enum';
import { Component, OnInit } from '@angular/core';
import { AbstractManageInputComponent } from '../abstract-manage-input.component';
import { ConfigurationDataService, enumConfigurationStacks } from '@cdux/ng-common';
import { Validators } from '@angular/forms';

@Component({
    selector: 'cdux-password-input',
    templateUrl: './password-input.component.html',
    styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent extends AbstractManageInputComponent implements OnInit {
    public passwordRulesText: string;
    constructor(
        protected configService: ConfigurationDataService
    ) {
        super(enumManageAccountType.PASSWORD);
    }

    ngOnInit() {
        this.passwordControl = this.formGroup.get('currentPassword');
        this.confirmControl = this.formGroup.get('confirmPassword');
        this.newControl = this.formGroup.get('newPassword');

        // update newPassword validation regex, and password rules verbiage:
        this.configService.getConfiguration(enumConfigurationStacks.TUX, ['user_pw_regex', 'user_pw_rules_text']
        ).subscribe(
            (config) => {
                if (config.hasOwnProperty('user_pw_rules_text')) {
                    this.passwordRulesText = config['user_pw_rules_text'];
                }
                if (config.hasOwnProperty('user_pw_regex')) {
                    this.newControl.setValidators([
                        Validators.required,
                        Validators.pattern(new RegExp(config['user_pw_regex'])),
                    ]);
                    this.newControl.updateValueAndValidity();
                }
            }
        );
    }
}
