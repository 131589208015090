import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CduxNgCommonModule, EventSessionModule } from '@cdux/ng-common';
import { MtpModule, SharedModule as SharedFragmentsModule, DropdownModule, LoadingModule } from '@cdux/ng-fragments';
import { CduxMediaModule } from '@cdux/ng-platform/web';

import { TuxCommonModule } from '../common/common.module';
import { CarouselModule } from '../carousel/carousel.module';

import { HandicappingComponent } from './handicapping/handicapping.component';
import { RaceDetailsComponent } from './race-details/race-details.component';
import { RaceListComponent } from './race-list/race-list.component';
import { RaceNavComponent } from './race-nav/race-nav.component';
import { RaceStatsComponent } from './race-stats/race-stats.component';
import { TrackListComponent } from './track-list/track-list.component';
import { VideoSyncComponent } from './video-sync/video-sync.component';
import { FavoritesModule } from 'app/account/favorites/favorites.module';

@NgModule({
    declarations: [
        HandicappingComponent,
        RaceDetailsComponent,
        RaceListComponent,
        RaceNavComponent,
        RaceStatsComponent,
        TrackListComponent,
        VideoSyncComponent
    ],
    imports: [
        CarouselModule,
        CduxMediaModule,
        CduxNgCommonModule,
        CommonModule,
        DropdownModule,
        EventSessionModule,
        FavoritesModule,
        FormsModule,
        LoadingModule,
        MtpModule,
        SharedFragmentsModule,
        TuxCommonModule,
    ],
    exports: [
        HandicappingComponent,
        RaceDetailsComponent,
        RaceListComponent,
        RaceNavComponent,
        RaceStatsComponent,
        TrackListComponent
    ]
})
export class RaceNavigationModule { }
