import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { enumTrackType, GetRunnerDetailsDataService, IHandicappingData, ITrackBasic, SortObjectsByKeysPipe, StringSlugifyPipe, ToteDataService, TrackService } from '@cdux/ng-common';
import { CduxRouteUtil } from 'app/shared';
import { BreadcrumbsService } from 'app/shared/breadcrumbs/services/breadcrumbs.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-greyhound-details',
    templateUrl: './greyhound-details.component.html',
    styleUrls: ['./greyhound-details.component.scss'],
    providers: [ SortObjectsByKeysPipe ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GreyhoundDetailsComponent implements OnInit, OnDestroy, IDetails {

    public data = <IHandicappingData>{};
    public isLoaded = false;
    public showDetails: boolean = false;

    public trackType: enumTrackType;
    public brisCode: string;
    public raceNumber: number;
    public programNumber: string;
    public view: WageringViewEnum;
    public isInline: boolean = true;
    public isPhone: boolean = false;
    private activeLeg = 0;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbsService: BreadcrumbsService,
        private _changeDetector: ChangeDetectorRef,
        private _headerService: HeaderService,
        private _mediaService: CduxMediaToggleService,
        private _runnerDetailDataService: GetRunnerDetailsDataService,
        private _stringSlugify: StringSlugifyPipe,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService
    ) { }

    public ngOnInit() {
        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);
                    this.isInline = false;

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum,
                        view: params.view
                    }

                    this.init(options);
                }
            }
        );
    }

    public ngOnDestroy() {
        this._headerService.setDefaultHeader();

        this._destroy.next();
        this._destroy.complete();
    }

    private _initializeBreadCrumbs(runnerName: string) {
        this._breadcrumbsService.addCurrentRoute(runnerName); // set the current/initial breadcrumb
        // generate an appropriate "back" breadcrumb to program if there was not one set previously
        if (this._breadcrumbsService.breadcrumbs.length === 1 && this.brisCode && this.trackType) {
            // we only need data for the current track but TodaysTracks is already being polled
            this._todaysRacesService.getTodaysTracks(true, true).pipe(first()).subscribe(tt => {
                const t = <ITrackBasic> { BrisCode: this.brisCode, TrackType: this.trackType };
                const todaysTrack = tt.find(t2 => TrackService.isSameTrack(t, t2.ITrackBasic));
                if (todaysTrack) {
                    this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb(
                        todaysTrack.name + ' - Race ' + this.raceNumber, [
                            '/program', this._stringSlugify.transform(todaysTrack.name),
                            this.brisCode, this.trackType, this.raceNumber
                        ].join('/')
                    ), true); // truncate and replace any prior initial placeholder breadcrumbs
                    this._breadcrumbsService.addCurrentRoute(runnerName); // current breadcrumb
                    this._changeDetector.detectChanges();
                }
            });
        }
    }

    public init(options: IDetailsOptions) {
        this.brisCode = options.brisCode;
        this.trackType = options.trackType;
        this.raceNumber = +options.raceNumber;
        this.programNumber = options.programNumber;
        this.view = options.view;
        this.activeLeg = options.activeLeg;

        const raceNum = this.activeLeg ? this.raceNumber + this.activeLeg : this.raceNumber;

        this._toteDataService.currentRaceDate(false).pipe(
            switchMap(raceDate => this._runnerDetailDataService.getHandicappingInfo(
                this.brisCode, this.trackType, raceDate, raceNum, +this.programNumber
            )),
            first()
        ).subscribe((data: IHandicappingData) => {
            if (data && data.runnerName) {
              this._breadcrumbsService.addCurrentRoute(data.runnerName);
              this.data = data;
            }
            this.showDetails = !!data.pastPerformance && (data.pastPerformance.length > 0)
            this._headerService.updateHeader({ staticContent: this.data.runnerName });
            this._initializeBreadCrumbs(this.data.runnerName);
            this.isLoaded = true;
            this._changeDetector.detectChanges();
        });

        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });
    }

    public getRacesText() {
        let text;

        if (this.data.pastPerformance && this.data.pastPerformance.length) {
            text = 'LAST ' + this.data.pastPerformance.length + ' RACE' + (this.data.pastPerformance.length > 1 ? 'S' : '');
        } else {
            text = 'NO RACES';
        }

        return text;
    }
}
