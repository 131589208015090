<div class="bris-picks-narrative">
    <div class="heading" *ngIf="wagerState?.basicTrack">
        {{ wagerState.basicTrack.DisplayName }} - Race {{ wagerState.basicTrack.RaceNum }}
    </div>

    <cdux-bris-picks-comment [comment]="brisBetComment"></cdux-bris-picks-comment>

    <button class="bet-button" *ngIf="betType && wagerAmount && brisBetEntries?.length > 0" (click)="select.emit(brisBetEntries); close.emit()">
        Bet {{ wagerAmount | currency:'USD':'symbol-narrow':wagerAmountDigitsInfo }} {{ betType.poolType.displayName }} Box -
        <ng-container *ngFor="let entry of brisBetEntries; let i = index">
            {{ entry.ProgramNumber }} <ng-container *ngIf="brisBetEntries.length - 1 > i">/</ng-container>
        </ng-container>
    </button>

    <div class="close-link" (click)="close.emit()">Close</div>

    <div class="about-bris-picks">
        <div class="about-heading">About brisPICKS</div>
        Race predictions and selections are generated by a highly predictive and
        rigorously tested statistical model that uses more than 50 years of proven,
        proprietary data from the industry-leading Brisnet.com handicapping database.
    </div>
</div>
