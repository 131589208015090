import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CreditcardMethodDepositAbstractComponent } from 'app/shared/funding/components/methods/creditcard/creditcard-method-deposit-abstract.component';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { CARD_TYPES, TranslateService, FeatureToggleDataService, JwtSessionService } from '@cdux/ng-common';
import { FundingCreditcardService } from 'app/shared/funding/shared/services/creditcard.service';
import { CurrencyPipe } from '@angular/common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ENVIRONMENT } from '@cdux/ng-core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-creditcard-method-deposit-fullscreen',
    templateUrl: './creditcard-method-deposit-fullscreen.component.html',
    styleUrls: ['./creditcard-method-deposit-fullscreen.component.scss']
})
export class FundingCreditCardMethodDepositFullscreenComponent extends CreditcardMethodDepositAbstractComponent implements OnInit {

    protected readonly isFullscreen = true;
    public readonly CARD_TYPES = CARD_TYPES;
    public isSmallGlass = false;

    // share enum with template
    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingCreditCardMethodDepositFullscreenComponent, options);
    }

    constructor(
        protected _router: Router,
        protected _translateService: TranslateService,
        protected _creditCardService: FundingCreditcardService,
        protected _currencyPipe: CurrencyPipe,
        protected _eventService: EventTrackingService,
        protected _changeDetectorRef: ChangeDetectorRef,
        _featureToggleService: FeatureToggleDataService,
        environment: ENVIRONMENT,
        fb: UntypedFormBuilder,
        fundingService: FundingService,
        sidebarService: SidebarService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        private _mediaToggle: CduxMediaToggleService) {
        super(
            _router,
            _changeDetectorRef,
            _eventService,
            _translateService,
            _creditCardService,
            _currencyPipe,
            localSessionService,
            environment,
            fb,
            fundingService,
            sidebarService,
            localHeaderService,
            _featureToggleService,
        );
    }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
        super.ngOnInit();
    }

    public onDeposit() {
        if (this.disabledState || this.pendingDeposit) {
            return;
        }
        super.onDeposit();
    }

    public navigateToOptions() {
        this._router.navigate(['funding', 'deposit', 'options']);
    }

    public getIconClass(header: boolean = false): string {
        switch (this.fundingMethodDetails.cardTypeID) {
            case CARD_TYPES.VISA:
                let returnString = 'icon--visa';
                returnString += header ? ' white' : '';
                return returnString;
                break;
            case CARD_TYPES.MC:
                return 'icon--mc';
                break;
            case CARD_TYPES.AMEX:
                if (this.amexEnabled) {
                    return 'icon--amex';
                    break;
                }
                return null;
                break;
            default:
                return null;
        }
    }

    /**
     * When we do the story later, we'll need to pass this created component to the
     * parent so it can replace this instance with the newly generated instance.
     */
    public replaceMethod(): void {
        this.replaceAccountInfo = true;

        // re-validate the amount, credit card number, expiration fields, clear out cvv field
        this.form.controls['amount'].setValidators([
            Validators.min(this.getMinDepositAmount(false)),
            Validators.max(this.getMaxDepositAmount())
        ]);
        // if it is beteShare, reset default amount to min deposit amount
        // (a consolidated max amount including min from deposit option and hard min requirement like from betShare)
        if (this.isBetShare) {
            this.form.controls['amount'].setValue(this.getMinDepositAmount(false));
        }
        this.form.controls['amount'].updateValueAndValidity();
        this.form.controls['cardNumber'].updateValueAndValidity();
        this.form.controls['expiration'].updateValueAndValidity();
        this.resetCvvField(true);
    }
}
