import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ViewableWagerDataHandler } from 'app/shared/betpad/classes/viewable-wager-data-handler.class';
import { ConfigurationDataService, ITrackBasic, TracksDataService } from '@cdux/ng-common';
import { first, map } from 'rxjs/operators';
import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';

@Injectable()
export class ViewableWagerDataGuard implements CanActivate {
    private _viewableWagerDataHandler: ViewableWagerDataHandler;

    constructor(
        private _configurationService: ConfigurationDataService,
        private _router: Router,
        private _tracksDataService: TracksDataService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        this._viewableWagerDataHandler = new ViewableWagerDataHandler(this._tracksDataService, this._configurationService);

        const obs = this._viewableWagerDataHandler.listen().pipe(
            first(),
            map(data => (data && data.runnerStats) || this._router.parseUrl(DEFAULT_REDIRECT_PATH))
        );

        this._viewableWagerDataHandler.updateRaceNavigation({
            BrisCode: route.params.brisCode,
            TrackType: route.params.trackType,
            RaceNum: route.params.raceNum
        } as ITrackBasic);

        return obs.toPromise();
    }
}
