<ng-container *ngIf="displayStyle === enumDepositDisplayStyle.FULL_PAGE; else smallGlassDisplay">
    <h2 class="bigger">To make a MoneyGram deposit you will need these details:</h2>
</ng-container>
<ng-template #smallGlassDisplay>
    <p>To make a MoneyGram deposit you will need these details:</p>
</ng-template>

<ol>
    <li>Your account number: {{ this.account }}</li>
    <li>Company name: {{ 'moneygram-affiliateId' | cduxTranslate:AFFILIATE_KEY }}</li>
    <li>Receive Code: {{ 'moneygram-receiveId' | cduxTranslate:AFFILIATE_KEY }}</li>
</ol>
<p class="p-general">
    Just fill out the Bill Pay form, and give the cashier your cash plus a $3.95 fee.
</p>
<p class="p-general">
    Get a receipt and MoneyGram reference number from the cashier. You will see the added funds in your account balance in a few minutes.
</p>
