import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { Subscription } from 'rxjs';

import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import {CduxSidebarContentComponent} from '../../../../sidebar/cdux-sidebar-content-component.class';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { enumFeatureToggle, FeatureToggleDataService } from '@cdux/ng-common';

const MOBILE_TOKEN = 'mobile-token';

@Component({
  selector: 'cdux-funding-coa',
  templateUrl: './change-address.component.html',
  styleUrls: [ './change-address.component.scss' ]
})
export class FundingChangeOfAddressComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy  {

    @Input() public mobileOnly: boolean = false;

    public readonly affiliateId = this._environment.affiliateId;
    public phoneEnabled: boolean;

    private _mediaQuerySub: Subscription;

    /* IMPLEMENT CduxSidebarContentComponent
       * ===================================== */

    public static getSidebarComponent(mobileOnly: boolean = false): ISidebarPortalComponent {
        const inputTokens: Map<any, any> = new Map();

        inputTokens.set(MOBILE_TOKEN, mobileOnly);

        return {
            component: FundingChangeOfAddressComponent,
            properties: {
                inputs: inputTokens,
            }
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.mobileOnly = properties.inputs.get(MOBILE_TOKEN);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        private _environment: ENVIRONMENT,
        private _mediaService: CduxMediaToggleService,
        private _sidebarService: SidebarService,
        private _featureToggleService: FeatureToggleDataService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.phoneEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this._mediaQuerySub = this._mediaService.registerQuery('phone').subscribe((val: boolean) => {
            if (val && this.mobileOnly) {
                this._sidebarService.goBack();
            }
        });
    }

    public ngOnDestroy() {
        this._mediaQuerySub.unsubscribe();
    }
}
