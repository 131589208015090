import { Directive, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

import { EventClickType, ISearchResult, ITodaysRace, RunnerSearchDataService } from '@cdux/ng-common';
import { RoutingOptions } from '@cdux/ng-fragments';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';

@Directive()
export abstract class SearchFieldAbstract {

    protected abstract searchField: ElementRef;
    public searchResults: Observable<ISearchResult[]>;
    public searchString: string;
    public modelChanged: Subject<string> = new Subject();

    constructor(
        private _router: Router,
        private _searchSvc: RunnerSearchDataService,
        private _todaysRacesSvc: TodaysRacesBusinessService,
        private _wagerUtilSvc: WageringUtilBusinessService,
        private _eventTrackingService: EventTrackingService
    ) {
        this.searchResults = this.modelChanged.pipe(
            // Delay processing by 300ms to prevent rapid fire searches
            debounceTime(300),
            distinctUntilChanged(),
            // Have to set search string within the pipe because programatically updating
            // the search string does not trigger the ngModelChange behavior.
            tap(searchInput => this.searchString = searchInput),
            switchMap((searchStr: string) => {
                // If the length of the string is less than three, we clear out the search results
                // by returning an observalbe of an empty list.
                if (searchStr.length < 3) {
                    return of(null);
                } else {
                    // Log Click Event SEARCH_RUNNER_TEXT_INPUT
                    this.logEvent(EventClickType.SEARCH_RUNNER_TEXT_INPUT);
                    return this._searchSvc.getSearchResults(searchStr);
                }
            })
        );
    }

    /**
     * Set focus on the search field.
     */
    public focusField(): void {
      // Put delay on this to ensure the element is present before we attempt to set focus
      setTimeout(() => {
          this.searchField.nativeElement.focus();
      }, 100);

      // Click Event for SEARCH_RUNNER_BUBBLE_OPEN
      this.logEvent(EventClickType.SEARCH_RUNNER_BUBBLE_OPEN);
  }

    /**
     * Clear the value of the search string. Make sure we push through the
     * subject so that that is cleared as well. Also apply focus back to the field
     * as clicking the clear button removes it.
     */
    public clearSearchString(): void {
        this.modelChanged.next('');
        this.searchField.nativeElement.focus();
    }

    /**
      * Attempt to get the race information for the selected search result. If it's found, send
      * the user to the program or result, based on race status. If'it's not found, navigate to
      * todays races. Close search just in case the user is already on the destination and
      * navigation wouldn't close search.
      * @param $event ISearchResult returned by child component
      */
    public goToProgram($event: ISearchResult): void {
        const adwTrackType = TodaysRacesBusinessService.TRACK_TYPE_MAP_BDS_TO_ADW[$event.trackType];
        this._todaysRacesSvc.getTodaysRace(
            $event.brisCode.toLowerCase(),
            adwTrackType,
            $event.raceNumber
        ).pipe(
            take(1),
        ).subscribe(
            (todaysRace: ITodaysRace) => {
                const routingOption = todaysRace.status === 'Closed' ? RoutingOptions.RESULTS : RoutingOptions.PROGRAM;
                const programUrl = this._wagerUtilSvc.getGoToProgramOrResultUrl(
                    routingOption,
                    $event.brisCode,
                    adwTrackType,
                    $event.raceNumber,
                    $event.trackName.toString().replace('/', '')
                );
                this._router.navigateByUrl(programUrl);
            },
            (e) => {
                this._router.navigateByUrl('/');
            }
        );
        this.clearSearchString();
        this.closeSearch(true);
    }

    public logEvent(clickType: EventClickType) {
        this._eventTrackingService.logClickEvent(clickType);
    }

    protected abstract closeSearch(forceClose): void;
}
