import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PopUpService {

    private window: any = (<any> window);

    public openPopUp(
        path: string,
        name: string,
        height: number = 500,
        width: number = 450,
        resizable: boolean = false,
        scrollbars: boolean = false,
        location: boolean = false
    ): Window {
        return this.window.open(
            path,
            name,
            this.buildPopUpParameters(
                height,
                width,
                resizable,
                scrollbars,
                location
            )
        );
    }

    /**
     * openBetPad
     *
     * This is the logic for navigating to Bet Pad.
     * This limits user to one Bet Pad popup per TS instance.
     *
     * @param  affId {string}
     * @memberof PopUpService
     */
    public openBetPad(affId: string, betPadURL: string) {

        switch (affId) {
            case '2800':
                affId = 'tw';
                break;
            case '4100':
                affId = 'ks';
                break;
            default:
                // unless otherwise specified above, openBetPad will use numeric affId to generate window name
                break;
        }

        // Open/focus a window with the target of <affId>-betpad.
        const win = window.open('', affId + '-betpad', this.buildPopUpParameters(700, 500, false, true, false));
        if (win.location.href === 'about:blank') {
            // If there's no location, then it's a new window. Let's go to Bet Pad.
            win.location.href = betPadURL;
        } else {
            // There's an existing Bet Pad window, let's focus on it.
            win.focus();
        }
    }

    private buildPopUpParameters(
        height: number,
        width: number,
        resizable: boolean = false,
        scrollbars: boolean = false,
        location: boolean = false
    ): string {
        const parameters = [];

        parameters.push('location=' + (location ? 'yes' : 'no'));
        parameters.push('width=' + width);
        parameters.push('height=' + height);

        if (scrollbars) {
            parameters.push('scrollbars=1');
        }

        if (resizable) {
            parameters.push('resizable=1');
        }

        return parameters.join(',');
    }
}
