import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { EventTrackingService } from '../../../../../shared/event-tracking/services/event-tracking.service';
import { EventClickType, EventClickAttributeType, ToteDataService } from '@cdux/ng-common';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';

@Component({
    selector: 'cdux-stats-section',
    template: `
        <ng-template #offloadedTemplate>
            <nav class="content-nav" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
                <ul>
                    <li id="position-bias"
                        [class.is-selected]="section === ViewSectionEnum.STATS_POST_BIAS"
                        (click)="section !== ViewSectionEnum.STATS_POST_BIAS && viewPostPositionBias()">Post Position Bias</li>
                    <li id="track-bias"
                        [class.is-selected]="section === ViewSectionEnum.STATS_TRACK_BIAS"
                        (click)="section !== ViewSectionEnum.STATS_TRACK_BIAS && viewTrackBias()">Track Bias</li>
                    <li id="run-styles"
                        [class.is-selected]="section === ViewSectionEnum.STATS_RUN_STYLE"
                        (click)="section !== ViewSectionEnum.STATS_RUN_STYLE && viewRunStyle()">Run Style</li>
                    <li id="success-rates"
                        [class.is-selected]="section === ViewSectionEnum.STATS_SUCCESS_RATES"
                        (click)="section !== ViewSectionEnum.STATS_SUCCESS_RATES && viewSuccessRates()">Success Rates</li>
                </ul>
            </nav>
        </ng-template>

        <cdux-stats-shared [track]="track" [race]="race" [statsView]="section" [displayMode]="displayMode"></cdux-stats-shared>
    `,
    styleUrls: ['./stats-section.component.scss']
})
export class StatsSectionComponent extends AbstractWageringSectionComponent implements OnInit {

    @Output() public updateSection = new EventEmitter<ViewSectionEnum>();

    public defaultSection = ViewSectionEnum.STATS_POST_BIAS;

    constructor(
        private _eventTrackingService: EventTrackingService,
        protected _toteDataService: ToteDataService,
        protected _viewStateService: ViewStateService
    ) {
        super(_toteDataService, _viewStateService);
    }

    ngOnInit() {

        const sectionCache = this._viewStateService.getViewSectionGroupCache(ViewStateGroupEnum.STATS);
        if (sectionCache) {
            this.section = sectionCache;
            if (this.section !== ViewSectionEnum.STATS) {
                this.updateSection.emit(this.section);
            } else {
                this.updateSection.emit(this.defaultSection);
            }
        } else {
            this.updateSection.emit(this.defaultSection);
        }
    }

    public logClickEvent(statsView: ViewSectionEnum) {
        const ts = Date.now();

        switch (statsView) {
            case ViewSectionEnum.STATS_POST_BIAS:
                this._eventTrackingService.logClickEvent(EventClickType.POST_POSITION_BIAS, [
                    { attrId: EventClickAttributeType.POST_POSITION_BIAS_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.POST_POSITION_BIAS_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.POST_POSITION_BIAS_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.POST_POSITION_BIAS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;

            case ViewSectionEnum.STATS_TRACK_BIAS:
                this._eventTrackingService.logClickEvent(EventClickType.TRACK_BIAS, [
                    { attrId: EventClickAttributeType.TRACK_BIAS_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.TRACK_BIAS_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.TRACK_BIAS_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.TRACK_BIAS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;

            case ViewSectionEnum.STATS_RUN_STYLE:
                this._eventTrackingService.logClickEvent(EventClickType.RUN_STYLE, [
                    { attrId: EventClickAttributeType.RUN_STYLE_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.RUN_STYLE_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.RUN_STYLE_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.RUN_STYLE_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;

            case ViewSectionEnum.STATS_SUCCESS_RATES:
                this._eventTrackingService.logClickEvent(EventClickType.SUCCESS_RATES, [
                    { attrId: EventClickAttributeType.SUCCESS_RATES_BRIS_CODE, data: this.track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.SUCCESS_RATES_TRACK_TYPE, data: this.track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.SUCCESS_RATES_RACE_NUMBER, data: this.race, timestamp: ts },
                    { attrId: EventClickAttributeType.SUCCESS_RATES_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            default:
                // We shouldn't get in here, but if we do, that's OK, we won't log any click event
        }
    }

    public viewPostPositionBias() {
        this.updateSection.emit(ViewSectionEnum.STATS_POST_BIAS);
        this._viewStateService.setViewSectionCache(ViewSectionEnum.STATS_POST_BIAS);
        this.logClickEvent(ViewSectionEnum.STATS_POST_BIAS);
    }

    public viewTrackBias() {
        this.updateSection.emit(ViewSectionEnum.STATS_TRACK_BIAS);
        this._viewStateService.setViewSectionCache(ViewSectionEnum.STATS_TRACK_BIAS);
        this.logClickEvent(ViewSectionEnum.STATS_TRACK_BIAS);
    }

    public viewRunStyle() {
        this.updateSection.emit(ViewSectionEnum.STATS_RUN_STYLE);
        this._viewStateService.setViewSectionCache(ViewSectionEnum.STATS_RUN_STYLE);
        this.logClickEvent(ViewSectionEnum.STATS_RUN_STYLE);
    }

    public viewSuccessRates() {
        this.updateSection.emit(ViewSectionEnum.STATS_SUCCESS_RATES);
        this._viewStateService.setViewSectionCache(ViewSectionEnum.STATS_SUCCESS_RATES);
        this.logClickEvent(ViewSectionEnum.STATS_SUCCESS_RATES);
    }
}
