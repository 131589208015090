<div class="my-account-row-wrapper alt-rows">
   <ng-container *ngIf="!tournamentSelected; else TournamentSelectedView">
        <!--my account - cashier/withdraw row-->
        <div id="cashier-withdraw-link" (click)="loadWithdraw()" class="my-account-row">
            <div class="my-account-row-info">
                <div class="my-account-row-info-header">
                    Withdraw
                </div>
            </div>

            <div class="my-account-row-arrow-right">
                <i class="icon--arrow-right--white"></i>
            </div>
        </div>
        <!--end my account -cashier/withdraw row-->

        <!--my account - cashier/deposit row-->
        <div class="my-account-row">
            <a id="cashier-deposit-link" class="btn-deposit" (click)="loadDeposit()">
                DEPOSIT
            </a>
        </div>
        <!--end my account -cashier/deposit row-->
   </ng-container>

    <ng-template #TournamentSelectedView>
        <div class="tournaments__container account__preferences">
            <main class="tournaments__page-unavailable">
                <div class="center-text"><i class="icon--bang-light amber block-center"></i></div>
                <div class="center-text bold-text">PAGE UNAVAILABLE</div>
                <p class="center-text">To access this page, you must switch to your primary account.</p>
            </main>
        </div>
    </ng-template>
</div>
