<section *ngIf="currentPools || exoticPoolsAvailable; else noPools" class="pools tv-classic">
    <section *ngIf="currentPools">
        <header class="pools-header-bar">
            <!--Add or remove the class is-sorted to the <span> to show that it is the current sorted column-->
            <!--Add or remove the class is-descending for up arrow or down error to the <i> tag-->
            <div class="pools-row__col-num" (click)="sortBy(enumPoolsSort.PROGRAM_NUMBER)"
                [cduxLogClick]="eventClickType.POOLS_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.POOLS_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumPoolsSort.PROGRAM_NUMBER">
                <div class="pools-number-sorter">
                    <span class="is-sortable"
                        [class.is-sorted]="sortState.sortProperty === enumPoolsSort.PROGRAM_NUMBER">#</span>
                </div>
            </div>

            <div class="pools-row__col-odds" (click)="sortBy(enumPoolsSort.ODDS)"
                [cduxLogClick]="eventClickType.POOLS_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.POOLS_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumPoolsSort.ODDS">
                <div class="pool-odds-sorter">
                    <span class="is-sortable"
                        [class.is-sorted]="sortState.sortProperty === enumPoolsSort.ODDS">Odds</span>
                </div>
            </div>

            <div class="pools-row__win" (click)="sortBy(enumPoolsSort.WIN)" [cduxLogClick]="eventClickType.POOLS_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.POOLS_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumPoolsSort.WIN">
                <span class="is-sortable" [class.is-sorted]="sortState.sortProperty === enumPoolsSort.WIN">Win</span>
            </div>

            <div class="pools-row__place" (click)="sortBy(enumPoolsSort.PLACE)"
                [cduxLogClick]="eventClickType.POOLS_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.POOLS_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumPoolsSort.PLACE">
                <span class="is-sortable"
                    [class.is-sorted]="sortState.sortProperty === enumPoolsSort.PLACE">Place</span>
            </div>

            <div class="pools-row__show" (click)="sortBy(enumPoolsSort.SHOW)" [cduxLogClick]="eventClickType.POOLS_SORT"
                [cduxLogClickAttributeType]="eventClickAttributeType.POOLS_SORT_COLUMN"
                [cduxLogClickAttributeData]="enumPoolsSort.SHOW">
                <span class="is-sortable" [class.is-sorted]="sortState.sortProperty === enumPoolsSort.SHOW">Show</span>
            </div>
        </header>

        <header class="pools-header-totals"
            [class.high-amount]="!isLargeView() && isHighAmount([currentPools.totals.totalWin, currentPools.totals.totalPlace, currentPools.totals.totalShow])">

            <div class="pools-row__col-num">Total:</div>

            <div class="pools-row__col-odds">&nbsp;</div>

            <div class="pools-row__win">{{ currentPools.totals.totalWin | currency : 'USD' : 'symbol' : '1.0-0'}}</div>

            <div class="pools-row__place">{{ currentPools.totals.totalPlace | currency : 'USD' : 'symbol' : '1.0-0'}}
            </div>

            <div class="pools-row__show">{{ currentPools.totals.totalShow | currency : 'USD' : 'symbol' : '1.0-0'}}
            </div>

        </header>

        <!--THOROUGHBREDS -->

        <ul class="pools-basic">

            <li *ngFor="let entry of currentPools.entries; trackBy: trackByProgramNumber"
                [ngClass]="{'is-scratched': isScratched(entry)}" class="pools-row">

                <div class="pools-row__col-num">
                    <div [ngClass]="entry.ProgramNumber | cduxSaddleClothClass: track?.TrackType" class="saddle-cloth">
                        {{ entry.ProgramNumber }}</div>
                    <div class="program-post-position"></div>
                </div>

                <div class="pools-row__col-odds">
                    <div [ngClass]="{'is-favorite': entry.Entries.length ? entry.Entries[0].LiveOddsFavorite : false}"
                        class="pools-odds">
                        {{ isScratched(entry) ? 'SCR' : entry.Entries.length ? entry.Entries[0].OddsToDisplay : '-' }}
                    </div>
                </div>

                <div class="pools-row__win pools-row__totals">
                    <div class="pools-row_amount-percent">
                        <div *ngIf="currentView === poolView.TOTALS" [class.high-amount]="isHighAmount([entry.Win])"
                            class="amount" [class.top-3]="entry.isTopWinPool && entry.Win > 0">
                            {{ isScratched(entry) ? 'SCR' : ( entry.Win | currency : 'USD' : 'symbol' : '1.0-0') }}
                        </div>
                        <div *ngIf="currentView === poolView.PERCENTAGES" class="percentage">
                            {{ isScratched(entry) ? 'SCR' : ( entry.WinPct | formatWPSPoolPercent) }}
                        </div>
                    </div>
                </div>

                <div class="pools-row__place pools-row__totals">
                    <div class="pools-row_amount-percent">
                        <div *ngIf="currentView === poolView.TOTALS" [class.high-amount]="isHighAmount([entry.Place])"
                            class="amount" [class.top-3]="entry.isTopPlacePool && entry.Place > 0">
                            {{ isScratched(entry) ? 'SCR' : ( entry.Place | currency : 'USD' : 'symbol' : '1.0-0') }}
                        </div>
                        <div *ngIf="currentView === poolView.PERCENTAGES" class="percentage">
                            {{ isScratched(entry) ? 'SCR' : ( entry.PlacePct | formatWPSPoolPercent) }}
                        </div>
                    </div>
                </div>

                <div class="pools-row__show pools-row__totals">
                    <div class="pools-row_amount-percent">
                        <div *ngIf="currentView === poolView.TOTALS" [class.high-amount]="isHighAmount([entry.Show])"
                            class="amount" [class.top-3]="entry.isTopShowPool && entry.Show > 0">
                            {{ isScratched(entry) ? 'SCR' : ( entry.Show | currency : 'USD' : 'symbol' : '1.0-0') }}
                        </div>
                        <div *ngIf="currentView === poolView.PERCENTAGES" class="percentage">
                            {{ isScratched(entry) ? 'SCR' : ( entry.ShowPct | formatWPSPoolPercent) }}
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </section>
    <cdux-exotic-pools-shared 
        (exoticPoolsAvailable)="updateExoticPoolsAvailable($event)"
        [track]="track"
        [race]="race"
        [displayMode]="displayMode">
    </cdux-exotic-pools-shared>
</section>

<ng-template #noPools>
    <div class="pools-basic has-error light" *ngIf="!isLoading">
        <div class="gbl-message">
            <i class="icon--info"></i>
            <div class="header">Pools unavailable</div>
            <div class="description">Pools are unavailable at this time.<br> Check back later once the pools are open.
            </div>
        </div>
    </div>
</ng-template>