import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable} from 'rxjs';
import { TournamentsSessionService } from '../services/touranments-session.service';
 

@Injectable()
export class TournamentInterceptor implements HttpInterceptor  {
    private cachedUrls = require('./cached-urls.json');
    constructor() {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const cookie = TournamentsSessionService.getSelectedTournament();
        if (cookie === null){
            return next.handle(req);
        }

        let newReq = req.clone({
            headers: req.headers.append('X-Ts-Tournament-Account', cookie.accountId.toString())
            .append('X-TS-tournament-Id', cookie.tournament.idTournament.toString())
            .append('X-TS-enrollment-Id', cookie.enrollment.idTournamentEnrollment.toString())
        });

        const match = this.cachedUrls.find(page => {
            const regex = new RegExp(`${page.path}`, 'gi');
            if (regex.test(newReq.url)) {
                return true;
            } 
            return false;
        });
        if (match !== undefined){
            newReq = newReq.clone({
            params: req.params.append('tournamentId', cookie.tournament.idTournament.toString()),    
            });
        }
        return next.handle(newReq);
    }
}