<section *ngIf="tournamentEnabled">
    <div *ngIf="activeEnrollment && !selectedTournament" class="tournaments__account-message-bar tournament-live">
        <div class="tournaments__account-message-bar__col  text__white"><i class="icon--leaderboard--white-stroke"></i>
            <span class="bold-text">A TOURNAMENT YOU ARE ENTERED IN IS NOW LIVE!</span>
        </div>
        <div class="tournaments__account-message-bar__col"><button
            class="button-secondary-outline smaller" (click)="showSelection()">SWITCH TO TOURNAMENT ACCOUNT</button>
        </div>
    </div>


    <div *ngIf="selectedTournament" class="tournaments__account-message-bar account-active">
        <div class="tournaments__account-message-bar__col  text__white"><i class="icon--leaderboard--white-stroke"></i>
            <div><span class="bold-text">TOURNAMENT ACCOUNT ACTIVE: {{ selectedTournament.tournament.tournament }} ({{ selectedTournament.enrollment.enrollmentName | uppercase }}) - </span>
            <a [routerLink]="['/tournaments',selectedTournament.tournament.idTournament,'details']" class="underline text__white">LEADERBOARD STANDINGS</a></div>
        </div>
        <div class="tournaments__account-message-bar__col"><button
            class="button-secondary-outline smaller" (click)="showSelection()">SWITCH TO PRIMARY ACCOUNT</button>
        </div>
    </div>
</section>
