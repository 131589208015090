import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { InterceptorService } from '../interceptor.service';
import { InterceptorEvent } from '../models';
import { FooterService } from '../../footer/services/footer.service';
import { ToastService } from '@cdux/ng-fragments';
import { BetsContainerComponent, BetsViewEnum } from '../../bets/components/bets-container/bets-container.component';
import { SidebarService } from '../../sidebar/sidebar.service';

export const TOAST_KEYS = {
    MESSAGE: 'message',
    PANEL_CLASS: 'panelClass',
    DURATION: 'duration',
    ACTION_FN: 'actionFn',
    ACTION: 'action'
};

export interface IToastRoute {
    path?: string;
    inputs?: { [key: string]: any };
}


@Injectable()
export class ToastInterceptor {
    // Interceptor Subscription
    private _interceptorSubcription: Subscription;

    public toastRoutes: IToastRoute[] = [
        {
          path: 'bet-success',
          inputs: {
              message: 'Your bet has been placed',
              panelClass: 'action-success',
              duration: '3000',
              action: 'VIEW MY BETS',
              actionFn: () => {
                  this._footerService.updateFooter(null);
                  this._sidebarService.loadComponent(
                      BetsContainerComponent.getSidebarComponent({currentBetsView: BetsViewEnum.ACTIVE_BETS}),
                      BetsContainerComponent.getHeaderComponent(),
                      {clearHistory: true}
                  );
              }
          }
        }
    ];

    constructor(
        private _interceptorService: InterceptorService,
        private _footerService: FooterService,
        private _toast: ToastService,
        private _sidebarService: SidebarService,
    ) {
        // Empty
    }

    /**
     * Initializes our subscription to watch for the proper interceptor
     * events so that it can respond appropriately and load the proper
     * toast panel.
     */
    public register(): void {
        if (!this._interceptorSubcription) {
            this._interceptorSubcription = this._interceptorService.navigationEnd
                .subscribe((event: InterceptorEvent<NavigationEnd>) => {
                    const toast = event.params['notify'] || null;
                    if (toast) {
                        this._loadToast(toast);
                    }
                });
        }
    }

    private _loadToast(pathName: string) {
        const path = this._getPath(pathName);
            if (path) {
                if (path.inputs.hasOwnProperty(TOAST_KEYS.MESSAGE) && path.inputs.hasOwnProperty(TOAST_KEYS.PANEL_CLASS) &&
                    path.inputs.hasOwnProperty(TOAST_KEYS.DURATION) && path.inputs.hasOwnProperty(TOAST_KEYS.ACTION) && path.inputs.hasOwnProperty(TOAST_KEYS.ACTION_FN)) {
                    this._toast.open(path.inputs[TOAST_KEYS.MESSAGE], {
                        panelClass: path.inputs[TOAST_KEYS.PANEL_CLASS],
                        duration: path.inputs[TOAST_KEYS.DURATION],
                        action: path.inputs[TOAST_KEYS.ACTION],
                        actionFn: path.inputs[TOAST_KEYS.ACTION_FN]
                    });
                }
            }
    }

    private _getPath(pathName: string): IToastRoute {
        let path: IToastRoute = null;
        for (let i = 0; i < this.toastRoutes.length; i++) {
            const route = this.toastRoutes[i];
            if (route.path === pathName) {
                path = route;
                break;
            }
        }
        return path;
    }
}
