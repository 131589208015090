<cdux-dropdown class="quick-pick-dropdown" [(ngModel)]="value" [forceState]="onSmallGlass ? smallGlassState : 0">

    <div dropdown-header *ngIf="!suppressHeader">
        <div class="menu-tab">
            <h2 class="title-label">
                <span *ngIf="value">
                    {{ value.label }}
                </span>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>

    <div dropdown-list>
        <cdux-dropdown-option *ngFor="let option of list; trackBy:trackByIndex; let $index = index;" [value]="option">
            <span class="option-label">
                {{ option.label }}
            </span>
        </cdux-dropdown-option>
    </div>

</cdux-dropdown>
