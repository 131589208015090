import { Component, OnInit } from '@angular/core';
import { CduxAbstractSidebarComponent } from '../../../../sidebar/cdux-sidebar-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { CARD_TYPES } from '@cdux/ng-common';


const ACCT_NUMBER_TOKEN = 'acct-number';
const CARD_TYPE_TOKEN = 'card-type';
const AMEX_ENABLED = 'amex-enabled';

@Component({
  selector: 'cdux-creditcard-method-header',
  templateUrl: './creditcard-method-header.component.html',
  styleUrls: ['./creditcard-method-header.component.scss']
})
export class FundingCreditCardMethodHeaderComponent extends CduxAbstractSidebarComponent implements OnInit {

    public accountNumber: string;
    public cardType: CARD_TYPES;
    public amexEnabled: boolean = false;

    public readonly CARD_TYPES = CARD_TYPES;


    public static getSidebarComponent(cardType: CARD_TYPES = null, accountNumber: string = null, amexEnabled: boolean = false): ISidebarPortalComponent {
        const inputTokens: Map<string, any> = new Map<string, any>();

        inputTokens.set(ACCT_NUMBER_TOKEN, accountNumber);
        inputTokens.set(CARD_TYPE_TOKEN, cardType);
        inputTokens.set(AMEX_ENABLED, amexEnabled);

        return {
            component: FundingCreditCardMethodHeaderComponent,
            properties: {
                inputs: inputTokens
            }
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.accountNumber = properties.inputs.get(ACCT_NUMBER_TOKEN);
        this.cardType = properties.inputs.get(CARD_TYPE_TOKEN);
        this.amexEnabled = properties.inputs.get(AMEX_ENABLED);
    }

    ngOnInit() { /* empty */ }
}
