import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { iif, Subject, Subscription } from 'rxjs';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    ConfigurationDataService,
    enumFeatureToggle,
    enumTrackType,
    EventClickType,
    FeatureToggleDataService,
    GetRunnerDetailsDataService,
    IFavoriteRunnerNew,
    ITrackBasic,
    PastPerformance,
    ProgramEntry,
    RunnerDetails,
    StringSlugifyPipe,
    ToteDataService,
    TracksDataService,
    TrackService,
    TranslateService,
    Workout,
} from '@cdux/ng-common';
import { CduxRequestError } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { CduxRouteUtil } from 'app/shared';
import { BreadcrumbsService } from 'app/shared/breadcrumbs/services/breadcrumbs.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { IViewableWagerData } from 'app/shared/betpad/interfaces/viewable-wager-data.interface';
import { ViewableWagerDataHandler } from 'app/shared/betpad/classes/viewable-wager-data-handler.class';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';

@Component({
    selector: 'cdux-runner-details',
    templateUrl: './runner-details.component.html',
    styleUrls: ['./runner-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunnerDetailsComponent implements OnInit, OnDestroy, IDetails {

    readonly DISPLAY_TOGGLE_LABEL_MORE = 'SEE ALL';
    readonly DISPLAY_TOGGLE_LABEL_LESS = 'SEE LESS';
    readonly MIN_ROWS_DISPLAY = 5;

    public eventClickType = EventClickType;
    public trackTypeEnum = enumTrackType;

    public entry: ProgramEntry = null;
    public runnerInfo: RunnerDetails = null;
    public displayedWorkouts: Workout[];
    public favoriteData: IFavoriteRunnerNew;

    public viewableWagerData: IViewableWagerData;

    public trackType: enumTrackType;
    public brisCode: string;
    public raceNumber: number;
    public programNumber: string;
    public horseId: number;
    public view: WageringViewEnum;
    private activeLeg = 0;

    public errorMessage: string;
    public error: boolean = false;
    public showAllPP: boolean = false;
    public showAllWorkout: boolean = false;
    public isPhone: boolean = false;
    public ppToggleLabel: string;
    public workoutToggleLabel: string;
    public replayPP: PastPerformance = null;
    public isInline: boolean = true;
    public detailOptions: IDetailsOptions;

    public entryCommentsEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.ENTRY_COMMENTS);

    // expose enum to template
    public ETrackType = enumTrackType;

    private mediaSub: Subscription;

    private _currentYear = new Date().getFullYear();

    private _viewableWagerDataHandler: ViewableWagerDataHandler;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbsService: BreadcrumbsService,
        private _changeDetector: ChangeDetectorRef,
        private _configurationDataService: ConfigurationDataService,
        private _eventTrackingService: EventTrackingService,
        private _featureToggleDataService: FeatureToggleDataService,
        private _headerService: HeaderService,
        private _mediaService: CduxMediaToggleService,
        private _router: Router,
        private _runnerDetailDataService: GetRunnerDetailsDataService,
        private _stringSlugify: StringSlugifyPipe,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService,
        private _tracksDataService: TracksDataService,
        private _translateService: TranslateService
    ) {
        this._viewableWagerDataHandler = new ViewableWagerDataHandler(this._tracksDataService, this._configurationDataService);
        this._viewableWagerDataHandler.listen().subscribe(
            (data: IViewableWagerData) => {
                if (data) {
                    this.viewableWagerData = data;
                    this._changeDetector.detectChanges();
                }
            }
        )
    }

    public ngOnInit() {
        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);
                    this.isInline = false;

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum,
                        view: params.view,
                        horseId: params.horseId
                    };
                    this.init(options);
                }
            }
        );
    }

    public ngOnDestroy() {
        this._headerService.setDefaultHeader();
        if (this.mediaSub) { this.mediaSub.unsubscribe(); }
        this._viewableWagerDataHandler.kill();

        this._destroy.next();
        this._destroy.complete();
    }

    public getRunnerDetail() {
        const raceNum = this.activeLeg ? this.raceNumber + this.activeLeg : this.raceNumber;

        this._toteDataService.currentRaceDate(this._toteDataService.NO_POLLING).pipe(
            switchMap(
                (raceDate: string) => iif(
                    () => this.trackType === enumTrackType.HARN,
                    this._runnerDetailDataService.getRunnerStats(this.brisCode, this.trackType, raceDate, raceNum, this.programNumber),
                    this._runnerDetailDataService.getRunnerDetails(this.horseId, this.brisCode, raceNum, raceDate)
                )
            ),
            first()
        ).subscribe((data: RunnerDetails) => {
            this.runnerInfo = data;
            this.displayedWorkouts = this.runnerInfo.workouts;
            this.favoriteData = this.runnerInfo.runnerInfo.generateNewFavorite();

            this.initializeBreadCrumbs(this.runnerInfo.runnerInfo.horseName, this.isInline);
            this._headerService.updateHeader({ staticContent: this.runnerInfo.runnerInfo.horseName });

            this._changeDetector.detectChanges();
        }, (error: CduxRequestError) => {
            this.error = true;
            this.errorMessage = this._translateService.translate('ws-response-error', 'runner-details-errors');
            this._changeDetector.detectChanges();
        });
    }

    public togglePP() {
        this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_RUNNER_DETAILS_MORE_PAST_PERFORMANCES);

        if (this.ppToggleLabel === this.DISPLAY_TOGGLE_LABEL_MORE) {
            this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_LESS;
            this.showAllPP = true;
        } else {
            this.showAllPP = false;
            this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
        }
        this._changeDetector.detectChanges();
    }

    public toggleWorkout() {
        this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_RUNNER_DETAILS_MORE_WORKOUTS);
        if (this.workoutToggleLabel === this.DISPLAY_TOGGLE_LABEL_MORE) {
            this.workoutToggleLabel = this.DISPLAY_TOGGLE_LABEL_LESS;
            this.showAllWorkout = true;
            this.displayedWorkouts = this.runnerInfo.workouts;
        } else {
            this.showAllWorkout = false;
            this.workoutToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
            this.displayedWorkouts = this.runnerInfo.workouts.length > this.MIN_ROWS_DISPLAY ? this.runnerInfo.workouts.slice(0, this.MIN_ROWS_DISPLAY - 1) : this.runnerInfo.workouts;
        }
        this._changeDetector.detectChanges();
    }

    private initialSetup() {
        this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
        this.workoutToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;

        this._todaysRacesService.getTodaysRaceEntries(
            this.brisCode, this.trackType, this.raceNumber, this._toteDataService.USE_POLLING
        ).pipe(first(), map(entries => entries.find(entry =>
            entry.EntryId.toString() === this.horseId.toString()
        ))).subscribe(entry => {
            this.entry = entry;
            this._changeDetector.detectChanges();
        });
    }

    private initializeBreadCrumbs(runnerName: string, forceRebuild = false) {
        if (forceRebuild) { this._breadcrumbsService.clearBreadcrumbs(); } // clear all breadcrumbs
        this._breadcrumbsService.addCurrentRoute(runnerName); // set the current/initial breadcrumb
        // generate an appropriate "back" breadcrumb to program if there was not one set previously
        if (this._breadcrumbsService.breadcrumbs.length === 1 && this.brisCode && this.trackType) {
            // we only need data for the current track but TodaysTracks is already being polled
            this._todaysRacesService.getTodaysTracks(true, true).pipe(first()).subscribe(tt => {
                const t = <ITrackBasic> { BrisCode: this.brisCode, TrackType: this.trackType };
                const todaysTrack = tt.find(t2 => TrackService.isSameTrack(t, t2.ITrackBasic));
                if (todaysTrack) {
                    this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb(
                        todaysTrack.name + ' - Race ' + this.raceNumber, [
                            '/program', this._stringSlugify.transform(todaysTrack.name),
                            this.brisCode, this.trackType, this.raceNumber
                        ].join('/')
                    ), true); // truncate and replace any prior initial placeholder breadcrumbs
                    this._breadcrumbsService.addCurrentRoute(runnerName); // current breadcrumb
                    this._changeDetector.detectChanges();
                }
            });
        }
    }

    public seeAncestorDetails(type: 'DAM' | 'SIRE') {
        if (this.isInline) { // multiple runners' details could be expanded
            // reinitialize descendent runner to ensure breadcrumb accuracy
            this.initializeBreadCrumbs(this.runnerInfo.runnerInfo.horseName);
        }

        this._eventTrackingService.logClickEvent(
            type === 'SIRE' ?
            EventClickType.RUNNER_DETAILS_SIRE_DETAILS :
            EventClickType.RUNNER_DETAILS_DAM_DETAILS
        );

        let ancestorDetailsRoute = `/program/${this.view}`;
        if (type === 'DAM') {
            ancestorDetailsRoute += `/dam/${this._stringSlugify.transform(this.runnerInfo.runnerInfo.damName)}/id/${this.runnerInfo.runnerInfo.damId}`;
        } else {
            ancestorDetailsRoute += `/sire/${this._stringSlugify.transform(this.runnerInfo.runnerInfo.sireName)}/id/${this.runnerInfo.runnerInfo.sireId}`;
        }
        this._router.navigateByUrl(ancestorDetailsRoute);
    }

    public hasTrainerDetails(): boolean {
        return this.viewableWagerData && this.viewableWagerData.trainerStats;
    }

    public seeTrainerDetailsFromRunner(name: string, id?: number) {
        this._eventTrackingService.logClickEvent(EventClickType.RUNNER_DETAILS_TRAINER_DETAILS);

        let route: string[] = [];
        if (this.trackType === enumTrackType.HARN) {
            route = [
                '/program',
                this.view,
                'harness',
                this._stringSlugify.transform(name),
                this.brisCode,
                this.trackType,
                this.raceNumber.toString(),
                this.programNumber,
                'trainer'
            ]
        } else {
            route = ['/program/' + this.view + '/trainer', this._stringSlugify.transform(name)];
            if (this.brisCode && this.trackType) {
                route.push(this.brisCode, this.trackType, this.raceNumber.toString(), id.toString());
            } else {
                route.push('id', id.toString());
            }
            if (this.entry && this.entry.JockeyId) {
                route.push(this.entry.JockeyId.toString());
            }
        }

        this._router.navigate(route);
    }

    public hasDriverDetails(): boolean {
        return this.viewableWagerData && this.viewableWagerData.driverStats;
    }

    public seeJockeyDetailsFromRunner(name: string, id?: number) {
        this._eventTrackingService.logClickEvent(EventClickType.RUNNER_DETAILS_JOCKEY_DETAILS);

        let route: string[];
        if (this.trackType === enumTrackType.HARN) {
            route = [
                '/program',
                this.view,
                'harness',
                this._stringSlugify.transform(name),
                this.brisCode,
                this.trackType,
                this.raceNumber.toString(),
                this.programNumber,
                'driver'
            ]
        } else {
            route = ['/program/' + this.view + '/jockey', this._stringSlugify.transform(name)];
            if (this.brisCode && this.trackType) {
                route.push(this.brisCode, this.trackType, this.raceNumber.toString(), id.toString());
            } else {
                route.push('id', id.toString());
            }
            if (this.entry && this.entry.TrainerId) {
                route.push(this.entry.TrainerId.toString());
            }
        }

        this._router.navigate(route);
    }

    public getAgeDisplay() {
        if (this.entry && this._currentYear >= +this.entry.Yob) {
            const age = this._currentYear - +this.entry.Yob;
            return age + (age === 1 ? ' yr' : ' yrs');
        } else {
            return null;
        }
    }

    public init(options: IDetailsOptions, entry?: ProgramEntry) {
        if (entry) {
            this.entry = entry;
        }
        this.detailOptions = options;
        this.view = options.view;
        this.horseId = options.horseId;
        this.trackType = options.trackType;
        this.brisCode = options.brisCode;
        this.raceNumber = options.raceNumber;
        this.programNumber = options.programNumber;
        this.activeLeg = options.activeLeg;
        // Horse ID check to short circuit showing an error message.
        // Currently for TBred we need this value.
        // Once we switch to the new bds stats service (which harness currently uses) we shouldn't need to check for
        // horseId.
        if (this.horseId && this.horseId !== -1) {
            this.initialSetup();
            this.getRunnerDetail();
        } else if (this.trackType === enumTrackType.HARN) {
            this.getRunnerDetail();
        } else {
            this.error = true;
            this.errorMessage = this._translateService.translate('bad-request', 'runner-details-errors');
        }

        this.mediaSub = this._mediaService.registerQuery('phone').subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });
    }
}
