import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { CduxMediaToggleService, enumMediaTarget } from '@cdux/ng-platform/web';
import { EventClickType, EventClickAttributeType, ITrack, ITrackBasic, TrackService } from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

@Component({
    selector: 'cdux-tv-thumbnail-bar',
    templateUrl: './tv-thumbnail-bar.component.html',
    styleUrls: ['./tv-thumbnail-bar.component.scss']
})
export class TvThumbnailBarComponent implements OnInit, OnDestroy {
    private static STORAGE_KEY = 'cdux-tv-track-list';


    @Input()
    public set track(track: ITrackBasic) {
        this.pushTrack(track);
        this._activeTrack = track;
    }

    @Output()
    public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();

    public tracks: ITrack[] = [];

    private _activeTrack: ITrack | ITrackBasic;


    private _tracksHistoryLength = 5;
    private _tracksHistoryLengthMedium = 4;
    private _tracksHistoryLengthSmall = 2;
    public tracksDisplayLength = this._tracksHistoryLength;


    private _tracksDataSubscription: Subscription;
    private _mediaSubscription: Subscription;


    constructor (
        private _changeDetector: ChangeDetectorRef,
        private _eventTrackingService: EventTrackingService,
        private _mediaService: CduxMediaToggleService,
        private _todaysRacesService: TodaysRacesBusinessService
    ) {
        this._restoreTrackList(); // restore before reading inputs
    }

    public ngOnInit() {
        this._mediaSubscription = combineLatest([
            this._mediaService.registerQuery(enumMediaTarget.DESKTOP),
            this._mediaService.registerQuery(enumMediaTarget.TABLET),
            this._mediaService.registerQuery(enumMediaTarget.TABLET_PORTRAIT)
        ]).subscribe(([isDesktop, isTablet, isTabletPortrait]) => {
            this.tracksDisplayLength = isDesktop ? this._tracksHistoryLength :
                isTablet && !isTabletPortrait ? this._tracksHistoryLengthMedium :
                this._tracksHistoryLengthSmall; // de facto tablet portrait or smaller
            // TODO: Not sure why this is necessary, view not updating even when bound
            try { this._changeDetector.markForCheck(); } catch (e) {} // ignore error

            // check if the active track is now out of the current track display set
            if (this._indexOfTrack(this._activeTrack) >= this.tracksDisplayLength) {
                this.pushTrack(this._activeTrack); // move active track to the front
            }
        });
    }

    public ngOnDestroy() {
        if (this._tracksDataSubscription) {
            this._tracksDataSubscription.unsubscribe();
        }

        if (this._mediaSubscription) {
            this._mediaSubscription.unsubscribe();
        }
    }

    public pushTrack(track: ITrackBasic): number {
        if (!track) { return this.tracks.length; }

        const index = this._indexOfTrack(track);
        if (index >= this.tracksDisplayLength) {
            // track in history, but not in view, move it to first index
            this.tracks.unshift(...this.tracks.splice(index, 1));
        } else if (index === -1) { // track not in history, add to front
            if (this.tracks.length >= this._tracksHistoryLength) {
                // track list is at or over capacity, clear end elements
                this.tracks.splice(this._tracksHistoryLength - 1);
            }
            this.tracks.unshift(<ITrack> track); // push track as ITrack
            this._updateTrackData(); // update will fill in ITrack props
        }

        return this.tracks.length;
    }

    public selectRaceNav(track: ITrack) {
        this._activeTrack = track || null;

        this._eventTrackingService.logClickEvent(
            EventClickType.TV_TRACK_THUMBNAIL,
            !this._activeTrack ? [] : [{
                attrId: EventClickAttributeType.TV_THUMBNAIL_BRIS_CODE,
                data: this._activeTrack.BrisCode
            }, {
                attrId: EventClickAttributeType.TV_THUMBNAIL_TRACK_TYPE,
                data: this._activeTrack.TrackType
            }, {
                attrId: EventClickAttributeType.TV_THUMBNAIL_RACE,
                data: this._activeTrack.RaceNum
            }]
        );

        this.updateRaceNav.emit(this._activeTrack);
    }

    public isActiveTrack(track: ITrack) {
        return TrackService.isSameTrack(track, this._activeTrack);
    }


    private _updateTrackData() {
        if (this._tracksDataSubscription) {
            this._tracksDataSubscription.unsubscribe();
        }

        // poll today's tracks web service to keep race number, mtp, and post time current
        this._tracksDataSubscription = this._todaysRacesService.getTodaysTracks(true, true)
            .subscribe(data => {
                for (let i = this.tracks.length; i >= 0; i--) {
                    let found = false;
                    for (const t of data.map(d => d && d.ITrack)) {
                        if (TrackService.isSameTrack(t, this.tracks[i])) {
                            this.tracks[i] = t; // reassign it to trigger change detection
                            found = true;
                            break;
                        }
                    }
                    // remove any invalid track (not running)
                    if (!found) { this.tracks.splice(i, 1); }
                }

                try { this._changeDetector.markForCheck(); } catch (e) {} // ignore error

                this._saveTrackList();
            });
    }

    private _saveTrackList() {
        localStorage.setItem(
            TvThumbnailBarComponent.STORAGE_KEY,
            JSON.stringify(this.tracks)
        );
    }

    private _restoreTrackList() {
        this.tracks = JSON.parse(localStorage.getItem(
            TvThumbnailBarComponent.STORAGE_KEY
        )) || [];

        this._updateTrackData();
    }

    private _indexOfTrack(track: ITrackBasic) {
        for (let i = 0; i < this.tracks.length; i++) {
            if (TrackService.isSameTrack(track, this.tracks[i])) {
                return i;
            }
        }

        return -1;
    }
}
