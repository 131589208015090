import { Observable } from 'rxjs';
import { VideoAngles } from '@cdux/ng-common';

export enum VideoFeedError {
    FLASH_UNAVAILABLE = 'FLASH_UNAVAILABLE',
    VIDEO_UNAVAILABLE = 'VIDEO_UNAVAILABLE',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export interface IVideoFeed {
    onError: Observable<VideoFeedError>;
    start(): void;
    stop(): void;
    pause(): void;
    resize(): void;

    getAngle(): VideoAngles;
    getAngles(): Observable<VideoAngles[]>;
    setAngle(angle: VideoAngles, getStreamData: boolean): void;
}
