// import third-party requirements
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

// import cdux requirements
import {
    IUpdateSsnDataResponse,
    enumSsnReason,
    UpdateSsnDataService,
    JwtSessionService,
} from '@cdux/ng-common';
import { CduxRequestError } from '@cdux/ng-core';

export class SsnCollectionError extends Error {
    public code: string | number;

    constructor(message?: string, code?: string | number) {
        super(message);
    }
}

@Injectable({
    providedIn: 'root'
})
export class SsnCollectionService {

    public static readonly MAX_ATTEMPTS = 2;

    public ssnUpdated: Subject<boolean | null> = new Subject();

    private attempts = 0;

    constructor (
        private sessionService: JwtSessionService,
        private updateSsnDataService: UpdateSsnDataService
    ) {}

    public cancelSsn() {
        this.ssnUpdated.next(null);
    }

    public updateSsn (camId: string, affId: number, ssn5: string, reason: enumSsnReason = enumSsnReason.FROZEN): Observable<any> {
        this.attempts++;

        return this.updateSsnDataService.updateSsn(camId, affId, ssn5, reason).pipe(
            mergeMap((response: IUpdateSsnDataResponse) => {
                if ('updateSucceeded' in response) {
                    if (response.updateSucceeded === true) {
                        this.ssnUpdated.next(true);
                        return of(true);
                    } else {
                        throw new SsnCollectionError(response.responseString, response.responseCode);
                    }
                } else if ('Error' in response) {
                    throw new SsnCollectionError(response.Error.Description, response.ErrorCode.toString());
                }
                this.ssnUpdated.next(false);
                return of(false);
            }),
            catchError((error: CduxRequestError) => {
                /*
                 * We could simply return of(false), but
                 * that would hide the underlying reason from the
                 * calling code. However, since we are re-throwing
                 * an error, the calling code's subscribe must include
                 * an error handler callback.
                 */
                /* istanbul ignore else */
                if ('data' in error) {
                    const responseError = error.data as HttpErrorResponse;

                    /* istanbul ignore else */
                    if (   responseError.status === 500
                        && responseError.error.Error
                        && responseError.error.Error.Description
                    ) {

                        /*
                         * DEV NOTE: To simulate a successful SSN update, you
                         *           may return of(true).
                         */
                        this.ssnUpdated.next(false);
                        throw new SsnCollectionError(responseError.error.Error.Description, responseError.error.ErrorCode);
                    }
                }

                this.ssnUpdated.next(false);
                // if unexpected error, just re-throw
                return throwError(error);
            })
        );
    }

    public canRetry(): boolean {
        return this.attempts < SsnCollectionService.MAX_ATTEMPTS;
    }

    public hasFullSsn(): boolean {
        const userInfo = this.sessionService.getUserInfo();
        return userInfo && +userInfo.cssdLength === 9;
    }
}
