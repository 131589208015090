import {
    AfterViewInit,
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import {
    FavoritePersonService,
    IFavoritePerson,
    TranslateService,
    TFavoriteUUID,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FavoritePersonTypeEnum } from '../enums/favorite-person-type-enums';
import { OverlayRef } from '@angular/cdk/overlay';
import { ToastService } from '@cdux/ng-fragments';
import { FavEventType } from '../favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
import {IDetailsOptions} from "app/shared/program/interfaces/details.interface";
import {WageringViewEnum} from "app/wagering/views/enums/wagering-view.enum";

@Component({
    templateUrl: './favorites-manager-trainers.component.html',
    styleUrls: [ './favorites-manager-trainers.component.scss' ],
})
export class FavoritesManagerTrainersComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('updateModalTemplate') protected updateModalTemplate: TemplateRef<unknown>;
    @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>;
    @ViewChild('statsModalTemplate') protected statsModalTemplate: TemplateRef<unknown>;

    public tempComment: string;
    public countCurrent = 0;
    public readonly COUNT_MAX = 4096;
    public modalContent: Portal<any>;
    public updateTemplatePortal: TemplatePortal<any>;
    public removeTemplatePortal: TemplatePortal<any>;
    public statsTemplatePortal: TemplatePortal<any>;
    private _overlayRef: OverlayRef;
    private _favTrainerSub: Subscription;
    private _terminateObs: Subject<any> = new Subject();

    public todaysRunners: IFavoritePerson[];
    public notTodaysRunners: IFavoritePerson[];
    public noFavorites: boolean = false;
    public options: IDetailsOptions;
    public modalTrainer: IFavoritePerson;

    public trainerName: string;
    private _uuid: TFavoriteUUID;

    private sortDesc = false;

    public constructor(
        private _viewContainerRef: ViewContainerRef,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _favoritePersonService: FavoritePersonService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _eventTrackingService: EventTrackingService,
    ) {}

    ngAfterViewInit() {
        this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        );

        this.updateTemplatePortal = new TemplatePortal(
            this.updateModalTemplate,
            this._viewContainerRef
        );

        this.statsTemplatePortal = new TemplatePortal(
            this.statsModalTemplate,
            this._viewContainerRef
        );
    }

    public ngOnInit() {
        if (this._favTrainerSub) {
            this._favTrainerSub.unsubscribe();
        }

        this._favTrainerSub = this._favoritePersonService.favorites
        .pipe(
            takeUntil(this._terminateObs)
        ).subscribe(
            (favoritesData: IFavoritePerson[]) => {
                this.noFavorites = false;
                const trainersOnlyData = favoritesData.filter(trainer => trainer.personType === FavoritePersonTypeEnum.TRAINER);
                this.todaysRunners = [];
                this.notTodaysRunners = [];

                if (trainersOnlyData.length > 0) {
                    trainersOnlyData.forEach(trainer => {
                        if (trainer?.todaysRunners.length > 0) {
                            this.todaysRunners.push(trainer);
                        }

                        if (trainer?.todaysRunners.length == 0) {
                            this.notTodaysRunners.push(trainer);
                        }
                    });

                    this.todaysRunners.sort((a, b) => {
                        const nameA = a.firstName + ' ' + (a?.middleName ? (a.middleName + ' ' + a.lastName) : a.lastName).toUpperCase(); // ignore upper and lowercase
                        const nameB = b.firstName + ' ' + (b?.middleName ? (b.middleName + ' ' + b.lastName) : b.lastName).toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                    });
                    this.sort(this.notTodaysRunners, true);
                } else {
                    this.noFavorites = true;
                }
            }
        );
    }

    ngOnDestroy() {
        this._terminateObs.next();
        this._terminateObs.complete();
    }

    public confirmRemove() {
        this._favoritePersonService.remove(this._uuid).then((removed) => {
            if (removed) {
                this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);
                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.trainerName + ' removed from <span class="bold-text">stable</span>.',
                        duration: 3000
                    }
                });
            }
            if (!removed) {
              this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public get note() {
        return this.tempComment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.tempComment = value;
        }
    }

    public edit (trainerData: IFavoritePerson) {
        this.modalTrainer = trainerData;
        this.tempComment = trainerData.comment || '';
        this.countCurrent = this.tempComment.length;
        this.modalContent = this.updateTemplatePortal;
    }

    public confirmUpdate(trainerData: IFavoritePerson) {
        // The favorite modal handles validation of the input regarding the
        // limits so we don't need to check them again here.
        // In case the save fails, we don't want to alter the original favorite YET so...

        // 1. Clone the favorite
        const dupeFav = Object.assign({}, trainerData);
        dupeFav.comment = this.tempComment;
        // 2. Call to update the favorite
        this._favoritePersonService.update(dupeFav).then((updated) => {
            if (updated) {
                // 3. If save was successful. Replace original favorite with the new one
                trainerData = dupeFav;
            } else {
                // 4. Show some sort of error message? Seek clarification.
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }

    public remove(trainer: IFavoritePerson) {
        this.trainerName = this.formatName(trainer.firstName, trainer?.middleName, trainer.lastName);
        this._uuid = trainer.uuid;

        this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE_LINK);

        this.modalContent = this.removeTemplatePortal;
    }

    public sort(notTodaysRunners: IFavoritePerson[], reloaded: boolean = false) {
        if (!reloaded) {
            this.sortDesc = !this.sortDesc;
        }

        this.notTodaysRunners.sort((a, b) => {
            const x = a.firstName.concat(a.middleName ? a.middleName : '', a.lastName).toLowerCase();
            const y = b.firstName.concat(b.middleName ? b.middleName : '', b.lastName).toLowerCase();

            if ( !this.sortDesc ) {
                return ( x === y ) ? 0 : (x < y ? -1 : 1);
            } else {
                return ( x === y ) ? 0 : (x < y ? 1 : -1);
            }
        });
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        let favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_FAVORITE_TYPE;
        let favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_LOCATION;

        if (clickType === EventClickType.STABLE_ALERTS_REMOVE) {
            favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_FAVORITE_TYPE;
            favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LOCATION;
        }

        this._eventTrackingService.logClickEvent(
            clickType,
            [
                {
                    attrId: favType,
                    data: FavoriteTypeEnum.TRAINER,
                    timestamp: ts
                },
                {
                    attrId: favLoc,
                    data: FavoriteLocationEnum.STABLE_ALERTS,
                    timestamp: ts
                },
            ]
        );
    }

    public formatName(firstName: string, middleName: string, lastName: string) : string {
        return firstName + ' ' + (middleName ? (middleName + ' ' + lastName) : lastName);
    }

    public formatRecord(record) {
        return record ? record.starts + ' - ' + record.wins + ' - ' + record.places + ' - ' + record.shows : '0 - 0 - 0 - 0';
    }

    public openStatsModal(personId: number, trainerName: string) {
        this.options = {
            brisCode: undefined,
            trackType: undefined,
            raceNumber: undefined,
            programNumber: undefined,
            view: WageringViewEnum.CLASSIC,
            trainerId: personId,
            trainerName: trainerName
        };
        this.modalContent = this.statsTemplatePortal;
    }
}
