import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CduxModalModule, CduxFormsModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from '../common/common.module';
import { CarouselModule } from './../carousel/carousel.module';

import { TourComponent } from './components';
import { TourService } from './services';
import { EventTrackingModule } from '../event-tracking/event-tracking.module';

@NgModule({
  imports: [
    CommonModule,
    TuxCommonModule,
    CduxFormsModule,
    CduxModalModule,
    CarouselModule,
    EventTrackingModule
  ],
  declarations: [
    TourComponent
  ],
  exports: [
    CduxModalModule,
    TourComponent
  ]
})
export class TourModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<TourModule> {
        return {
            ngModule: TourModule,
            providers: [
              TourService
            ],
        }
    }
}
