
<div class="bet-success-container is-in-bet-slip">
    <div class="bet-success-header">
        <i class="icon checkmark"></i>
        <span class="header-text">Your Bet Has Been Placed</span>
    </div>
    <div class="bet-card">
        <div class="bet-details">
            <div class="bet-detail-line"> {{bet.track.DisplayName}} </div>
            <div class="bet-detail-line"> Race {{bet.race.race}} </div>
        </div>
        <div class="bet-type">
            <span class="badge"> {{bet.poolType?.ShortName}} </span>
        </div>
    </div>
    <div *ngIf="!showSocialMedia" class="bet-success-footer">

        <!-- show for non-shared bet
        <div id="bet-success-view-bets-button" class="btn view-bets-button">View My Bets</div>
        -->
        <!-- show for shared bet-->
        <div class="footer-text">Share With Friends</div>

        <!-- show before clicking Later or Share -->
        <div class="btn-share" (click)="close()" [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_SUCCESS_LATER_BUTTON">Later</div>
        <div class="btn-share" (click)="showSocialMedia = !showSocialMedia" [cduxLogClick]="eventClickType.BET_SLIP_BET_SHARE_SUCCESS_SHARE_BUTTON">Share</div>
    </div>
    <div *ngIf="showSocialMedia" class="bet-success-footer">
        <div class="footer-text">Share With Friends</div>
        <i class="icon--email-light"     (click)="shareBet(socialMediaType.EMAIL)" [cduxLogClick]="eventClickType.BET_SLIP_EMAIL_SHARE_BET"></i>
        <i class="icon--messenger-light" (click)="shareBet(socialMediaType.SMS)"*ngIf="!onAndroidTablet" [cduxLogClick]="eventClickType.BET_SLIP_TEXT_SHARE_BET"></i>
        <i class="icon copy-link" >
            <div class="icon--copy-link" (click)="copyBet()" [cduxCopyToClipboard] = "betShareUrl" [style.opacity]="triggerCopy? 0 : 1" [cduxLogClick]="eventClickType.BET_SLIP_COPY_SHARE_BET"></div>
            <div class="icon-alert-wrapper" [ngClass]="{'is-success':triggerCopy }">
                <svg class="icon-alert" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" >
                    <circle class="icon-alert-circle" cx="26" cy="26" r="26"></circle>
                    <path class="icon-alert-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                </svg>
            </div>
        </i>
        <i class="icon--facebook" *ngIf="socialMediaToggle"  (click)="shareBet(socialMediaType.FACEBOOK)" [cduxLogClick]="eventClickType.BET_SLIP_FACEBOOK_SHARE_BET"></i>
        <i class="icon--twitter"  *ngIf="socialMediaToggle" (click)="shareBet(socialMediaType.TWITTER)" [cduxLogClick]="eventClickType.BET_SLIP_TWITTER_SHARE_BET"></i>
    </div>
</div>


