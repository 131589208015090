import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';


import { ENVIRONMENT } from '@cdux/ng-core';
import { EventClickType, FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';

import { FundingEZBankMethodHeaderComponent } from './ezbank-method-header.component';
import { FundingEZBankMethodDepositAbstractComponent } from './ezbank-method-deposit-abstract.component';
import { CduxRouteUtil } from 'app/shared';
import { HeaderService } from 'app/shared/header/services/header.service';


@Component({
    selector: 'cdux-funding-ezbank-method-deposit',
    templateUrl: './ezbank-method-deposit.component.html'
})
export class FundingEZBankMethodDepositComponent extends FundingEZBankMethodDepositAbstractComponent {

    public eventClickType = EventClickType;
    public minDeposit: number;
    public maxDeposit: number;
    public errorMessage: string;
    private readonly _routeCommands = ['/', 'account', 'deposit', 'onlinebanking'];


    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */
    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEZBankMethodDepositComponent, options);
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingEZBankMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }
    /* ===============================
     * END CduxSidebarContentComponent */

    constructor(
        localChangeDetector: ChangeDetectorRef,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        protected _router: Router,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            _router,
            localChangeDetector,
            localEnvironment,
            localEventTrackingService,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            localToggleService
        );
    }

    public navigateToEzBank() {
        // redirect and then return if already on ezbank page to force iframe to reload
     this.logDepositStartGtmEvent();
        if (this._isEzBankRoute()) {
            this._router.navigateByUrl('/', {skipLocationChange: true})
                .then( () => this._navigateToEzBank());
        } else {
            this._navigateToEzBank();
        }
    }

    private _navigateToEzBank() {
        /*
         * We'll pass the current URL into the EZBank process, so that
         * we can return to that route when it is finished. We should
         * remove the "action=ezbank" parameter.
         */
        const currentUrl = this._router.url.split('?');
        const finalUrl = currentUrl[0] + CduxRouteUtil.filterGetParams(currentUrl[1], ['action=mazooma']);

        this._router
            // navigate to new EZBank page URL and pass deposit amount value through router state
            .navigate(
                this._routeCommands,
                { state: {
                    'depositAmount': this.form.get('amount').value,
                    'finalUrl': finalUrl
                }}
            )
            // trigger sidebar to close on successful navigation
            .then(_ => this._sidebarService.close());
    }

    private _isEzBankRoute() {
        const url = this._routeCommands.join('/').replace('//', '/');
        return this._router.url.includes(url);
    }
}
