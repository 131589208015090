<header class="reg-login-header">
    <div class="secured-message">
        <div id="fullpage-reg-secured-message-icon" class="icon--badge-encryption"></div>
        <span id="fullpage-reg-secured-message-text">Secured by 2048-bit encryption</span>
    </div>
</header>
<div id="fullpage-reg-offer-claimed-banner" *ngIf="validOffer && validOffer.code && !registrationSuccessful" class="offer-claimed-notification">
    You have claimed the {{ validOffer.code }} offer. Continue to sign up to receive your bonus
</div>
<section class="reg-login-container platform-registration">

    <div class="content-peripheral has-image" [ngClass]="{'is-hidden': hasErrors || registrationSuccessful }">
        <div class="offer-image">
            <img *ngIf="offerImgObs | async as imgSrc" id="fullpage-reg-offer-image" src="{{ imgSrc | sharedResource }}" (error)="fetchDefaultOfferImage();">
        </div>

        <div class="content-peripheral_footer">
            <div id="fullpage-reg-existing-account-question-text" class="content-peripheral_footer_text">
                Already have an account?
            </div>
            <div>
                <!-- when the login button is clicked, switch the text to the button loader component while user is waiting -->
                <button id="fullpage-reg-login-button-clicked" *ngIf="loginClicked; else loginButton" type="submit" class="sign-in--btn-peripheral">
                    <div class="button-loader primary">
                        <div class="loader-dots">
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                        </div>
                    </div>
                </button>
                <ng-template #loginButton>
                    <button id="fullpage-reg-login-button" (cduxTap)="navigateToLogin()" type="submit" class="sign-in--btn-peripheral">Login</button>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="content-main" #scrollingArea [ngClass]="{'is-hidden':hasErrors || registrationSuccessful}">
        <div class="icon--logo-sm-color"></div>

        <div class="reg-progress">
            <div class="step">
                <div class="step-num" [ngClass]="contactStepClass">
                    1
                </div>
                <div class="step-label" [ngClass]="contactStepClass">
                    contact
                </div>
            </div>
            <div class="connector"></div>
            <div class="step">
                <div class="step-num" [ngClass]="addressStepClass">
                    2
                </div>
                <div class="step-label" [ngClass]="addressStepClass">
                    address
                </div>
            </div>
            <div class="connector"></div>
            <div class="step">
                <div class="step-num" [ngClass]="accountStepClass">
                    3
                </div>
                <div class="step-label" [ngClass]="accountStepClass">
                    account
                </div>
            </div>
        </div>

        <div [innerHTML]="getHelpText()" class="reg-message"></div>

        <div class="reg-form_scroller">
            <cdux-form-builder
                #formBuilder
                [affiliateId]="affiliateId"
                [config]="config"
                [enableOffers]="isOffersEnabled"
                [forceFullSSN]="isBetShare"
                [offer]="currentOffer"
                [platform]="applicationConfig.platformId"
                [delegateTerms]="true"
                [preRegistration]="preRegistration"
                [showBottomButtons]="showBottomButtons"
                [step]="currentStep"
                (emailAddressChanged)="saveEmailAddress($event)"
                (firstNameEntered)="saveFirstName($event)"
                (offerCodeChanged)="offerCodeChanged()"
                (offerValidated)="offerValidated($event)"
                (registrationComplete)="onRegistrationComplete($event)"
                (registrationSubmitted)="onRegistrationSubmitted()"
                (showTerms)="navigateToTerms()"
                (stepChange)="onStepChange($event)"
                (userEvent)="onUserEvent($event)">
            </cdux-form-builder>
        </div>

        <div id="fullpage-reg-ssl-encryption-message" class="security-message">
            We safeguard your personal information with SSL encryption
            so that it cannot be read in transit by a third party.
        </div>

        <div class="login-link-footer">
            Already have an account? <a (cduxTap)="navigateToLogin()">Log In</a>
        </div>
    </div>

    <ng-container *ngIf="hasErrors">
        <div class="content-error">
            <div class="icon--logo-sm-color"></div>
            <div class="content-error_title">Registration Problem</div>

            <!-- First soft fail -->
            <p *ngIf="showRetryButton; else noRetryButton">{{ 'registration-error-personal-info' | cduxTranslate:''+affiliateId}}</p>

            <!-- Other fail type -->
            <ng-template #noRetryButton>
                <!-- All fail types -->
                <p><span [innerHTML]="translateStringKeyMap[registrationStatus] + '-header' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span></p>
                <p>
                    <span [innerHTML]="translateStringKeyMap[registrationStatus] + '-body' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span>
                    <!-- Hard fail -->
                    <span *ngIf="registrationStatus === RegistrationStatesEnum.HARD_FAIL || registrationStatus === RegistrationStatesEnum.HARD_FAIL_ID_SCAN" class="link-phone">
                        <a (click)="triggerSupportCall()">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</a>.
                    </span>
                    <!-- Account exists fail -->
                    <span *ngIf="registrationStatus === RegistrationStatesEnum.ACCOUNT_EXISTS"><a (click)="triggerPasswordReset()">here</a>.</span>
                </p>
                <!-- Second soft fail -->
                <ng-container *ngIf="softFailCount">
                    <p><span [innerHTML]="'registration-required-docs' | cduxTranslate:''+affiliateId | cduxSanitizeHtml"></span></p>
                    <p>
                        <span>{{ 'registration-fail-soft-body-call' | cduxTranslate:''+affiliateId}} </span>
                        <span class="link-phone">
                            <a (click)="triggerSupportCall()">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</a>
                        </span>.
                    </p>
                </ng-container>
            </ng-template>

            <div *ngIf="showRetryButton" class="content-error_footer">
                <div class="loginBtnWrapper">
                    <button type="submit" class="reg-next--btn" (cduxTap)="resetErrors()">try again</button>
                </div>
            </div>

            <div *ngIf="!showRetryButton && softFailCount" class="content-error_footer">
                <div class="loginBtnWrapper">
                    <button type="submit" class="reg-next--btn" (cduxTap)="navigateToDocumentUpload()">upload documents</button>
                </div>
            </div>
        </div>
    </ng-container>

</section>
