<div class="tv-container">
    <div class="tv-video-container">
        <div class="tv-thumbnail-container" *ngIf="!isTabletPortrait">
            <cdux-tv-thumbnail-bar
                [track]="wager?.basicTrack"
                (updateRaceNav)="updateRaceNav.emit($event)">
            </cdux-tv-thumbnail-bar>
        </div>

        <div class="tv-video-player-container">
            <cdux-video
                [controls]="false"
                [show]="true"
                [liveRaceNumber]="liveRaceNumber"
                [trackRace]="selectedITrack"
                [onTV]="true"
                [inlineTabs]="true"
                [disableLiveReplayToggle]="false"
                [videoBarType]="videoBarType.BUTTON">
            </cdux-video>
        </div>
    </div>

    <div class="tv-program-container">
        <cdux-compact-view
            [view]="view"
            [displayMode]="displayMode"
            [section]="section"
            [wager]="wager"
            [raceStatus]="raceStatus"
            [error]="error"
            (sectionChange)="changeSection($event)"
            (updateRaceNav)="updateRaceNav.emit($event)"
            (updateBetNav)="updateBetNav.emit($event)"
            (updateBetType)="updateBetType.emit($event)"
            (updateBetAmount)="updateBetAmount.emit($event)"
            (updateBetModifier)="updateBetModifier.emit($event)"
            (updateEntrySelection)="updateEntrySelection.emit($event)"
            (resetEntrySelections)="resetEntrySelections.emit()"
            (stashEntrySelections)="stashEntrySelections.emit()"
            (restoreEntrySelections)="restoreEntrySelections.emit()"
            (outboundEvents)="outboundEvents.emit($event)"
            (dismissError)="dismissError.emit($event)">
        </cdux-compact-view>
    </div>
</div>
