<div class="sidebar-wrapper dark">
    <div class="ts-message error" *ngIf="!success && canRetry">
        <p>We could not verify the SSN. Please try again or call Player Services
            at <span style="white-space: nowrap;">{{ 'primary-phone-number-vanity' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
    </div>

    <div class="inner-wrap">
        <p class="center" *ngIf="wagerAmount">Your recent wager paid
            <span class="is-verified">{{ wagerAmount | currency:'USD':'symbol-narrow' }}</span></p>

        <p>Due to tax regulations, we need your full
            Social Security Number (SSN) to release the winnings to your
            account.</p>

        <p class="center">Please provide your full SSN:</p>

        <form #thisForm="ngForm" name="ssnc-form" (ngSubmit)="thisForm.form.valid && submitSsn()" onsubmit="return false;">
            <div class="form-wrap-3col ssn">
                <div class="form-label-hover number">
                    <input class="form-control" type="text" name="ssn3" maxlength="3" [ngClass]="{ 'has-value': ssn3 }" [(ngModel)]="ssn3" required>
                    <label>3 digits</label>
                </div>

                <div class="center"><span>-</span></div>

                <div class="form-label-hover number">
                    <input class="form-control" type="text" name="ssn2" maxlength="2" [ngClass]="{ 'has-value': ssn2 }" [(ngModel)]="ssn2" required>
                    <label>2 digits</label>
                </div>

                <div class="center"><span>-</span></div>

                <div class="form-label-hover number is-not-allowed">
                    <input class="form-control" type="text" name="ssn4" maxlength="4" disabled="disabled">
                    <label>{{ ssn4 }}</label>
                </div>
            </div>

            <div class="submitBtnWrapper">
                <button type="submit" class="submit--btn" [disabled]="thisForm.form.pristine || thisForm.form.invalid">Submit</button>
            </div>
        </form>
    </div>
</div>
