<form class="deposit-form-wrapper creditcard" *ngIf="form" [formGroup]="form" [class.dark]="lockout">

    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>
    <div *ngIf="!lockout">
        <cdux-funding-input-amount id="depositAmount" formControlName="amount"
                                   *ngIf="this.errorCode !== ERROR_CODES.EXPIRED"
                                   [inputType]="OPERATION"
                                   [fundType]="fundingMethodDetails.fundType"
                                   [min]="getMinDepositAmount(hasVerifiedAccount)"
                                   [max]="getMaxDepositAmount()"
                                   [amount]="amount"
                                   [offerId]="offerId">
        </cdux-funding-input-amount>

        <div class="ts-message error" *ngIf="errorCode !== null">{{ errorCode | cduxTranslate:'errorcodes':true:errorCodeArgs }}</div>
        <div class="ts-message success" *ngIf="successfulDeposit && !errorCode">{{ successMessage }}</div>

        <div *ngIf="this.errorCode !== ERROR_CODES.EXPIRED">
            <div class="form-account-num-wrapper" *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo">

                <div class="account-info-header">
                    Enter Card Details
                </div>

                <cdux-input class="form-label-hover"
                            minlengtherror="Minimum Credit Card Length is 13-digits."
                            maxlengtherror="Maximum Credit Card Length is 19-digits"
                            requirederror="Card Number is required."
                            label="Card Number"
                            [ngClass]="cardClasses"
                            [classes]="['fs-exclude']"
                            [icons]="icons"
                            (reset)="resetField('cardNumber')">
                    <input id="cardNumber"
                           autocomplete="off"
                           cduxInputField
                           formControlName="cardNumber"
                           required="true"
                           minlength="16"
                           [cduxTextMask]="ccMask"
                           type="text"/>
                </cdux-input>
                <div
                    id="bad-bin-error-panel"
                    class="ts-validation error"
                    *ngIf="badBinError"
                    [innerHTML]="fundingErrorMessages.badBinSmallGlass"
                    (cduxTap)="goToDepositOptions()"></div>

        <div class="account-expire-cvv">
          <cdux-input class="form-label-hover account-expire"
            minlengtherror="Format is mm/yy"
            maxlengtherror="Format is mm/yy"
            requirederror="Expiration Date is required."
            label="Expiration Date"
            [classes]="['fs-exclude']">
            <input id="expiration"
              #expirationInput
              autocomplete="off"
              cduxInputField
              formControlName="expiration"
              placeholder="MM/YY"
              [cduxTextMask]="ccExpMask"
              type="text" />
          </cdux-input>
          <div class="cvv-wrapper" *ngIf="showCvvField">
            <cdux-input [classes]="['fs-exclude']" class="form-label-hover account-cvv" label="CVV" patternerror="Invalid CVV" requirederror="CVV is required.">
                <input id="cvv"
                #cvvInput
                autocomplete="off"
                cduxInputField
                formControlName="cvv"
                [cduxTextMask]="cvvMask"
                pattern="{{ cvvPattern }}"
                type="text"
                (focus)="toggleCvvFocus(true)"
                (blur)="toggleCvvFocus(false)" />
            </cdux-input>
            <span class="input-bumper" [class.focused]="isCvvFocused && (!form.controls.cvv.touched || form.controls.cvv.valid)">
                <cdux-tooltip class="funding-tooltip" [position]="'top'" tooltipTitle="CVV">
                    <span tooltip-toggle (click)="cvvTooltipClick()">
                        <span class="cvv-tooltip-header"></span> <i class="icon--info"></i>
                    </span>
                    <span tooltip-content>{{ cvvToolTipContent | cduxTranslate:'creditcard' }}</span>
                </cdux-tooltip>
            </span>
          </div>
        </div>

                <div class="bill-address-header">
                    Billing Address same as residence?
                </div>

                <div class="bill-address-wrap check-radio">

                    <cdux-radio-group formControlName="billAddressDiff">
                        <cdux-radio-option id="sameBillingRadio" [value]="false">
                            Yes
                        </cdux-radio-option>
                        <cdux-radio-option id="diffBillingRadio" [value]="true">
                            No
                        </cdux-radio-option>
                    </cdux-radio-group>

                    <div class="bill-address-entry is-not-same-residence" *ngIf="form.get('billAddressDiff').value">
                        <cdux-input [classes]="['fs-exclude']" class="form-label-hover account-num" label="Address" requirederror="Address is required.">
                            <input id="address"
                                   cduxInputField
                                   formControlName="address"
                                   maxlength="30"
                                   type="text"/>
                        </cdux-input>
                        <div class="bill-address-citystate">
                            <cdux-input [classes]="['fs-exclude']" class="form-label-hover city" label="City" requirederror="City is required.">
                                <input id="city"
                                       cduxInputField
                                       formControlName="city"
                                       maxlength="25"
                                       type="text"/>
                            </cdux-input>
                            <cdux-input [classes]="['fs-exclude']" class="form-label-hover state" label="State" patternerror="Invalid state" requirederror="State is required.">
                                <input id="state"
                                       cduxInputField
                                       formControlName="state"
                                       minlength="2"
                                       maxlength="2"
                                       pattern="^[a-zA-Z]{2}$"
                                       type="text"/>
                            </cdux-input>
                        </div>
                        <cdux-input [classes]="['fs-exclude']" class="form-label-hover zipcode" label="Zip Code" requirederror="Zip Code is required.">
                            <input id="zipCode"
                                   cduxInputField
                                   formControlName="zipCode"
                                   cduxNumeric
                                   minlength="5"
                                   maxlength="5"
                                   type="text"/>
                        </cdux-input>
                    </div>

                </div>
            </div>

            <div class="form-account-info-wrapper" *ngIf="fundingMethodDetails.accountInfo && !replaceAccountInfo">
                <div class="account-info-header">
                    Enter Card Details
                </div>
                <div class="account-info"></div>
                <div class="account-expire-cvv existing">
                    <div class="cvv-wrapper" *ngIf="showCvvField">
                        <cdux-input class="form-label-hover account-cvv" label="CVV" patternerror="Invalid CVV" requirederror="CVV is required.">
                            <input id="cvv"
                                autocomplete="off"
                                cduxInputField
                                formControlName="cvv"
                                [cduxTextMask]="cvvMask"
                                pattern="{{ cvvPattern }}"
                                type="text"
                                (focus)="toggleCvvFocus(true)"
                                (blur)="toggleCvvFocus(false)"
                                />
                        </cdux-input>
                        <span class="input-bumper" [class.focused]="isCvvFocused && (!form.controls.cvv.touched || form.controls.cvv.valid)">
                            <cdux-tooltip class="funding-tooltip" [position]="'top'" tooltipTitle="CVV">
                                <span tooltip-toggle>
                                    <span class="cvv-tooltip-header"></span> <i class="icon--info"></i>
                                </span>
                                <span tooltip-content>
                                    {{ cvvToolTipContent | cduxTranslate:'creditcard' }}
                                </span>
                            </cdux-tooltip>
                        </span>
                    </div>
                </div>
            </div>

            <cdux-funding-transaction-summary
                transactionName="Deposit"
                [amount]="form.get('amount').value"
                feeName="Credit Card Fee"
                [feeValue]="fundingMethodDetails.flatFee">
            </cdux-funding-transaction-summary>

            <cdux-funding-deposit-button (click)="!pendingDeposit && !badBinError && onDeposit()"
                                         [disabled]="disabledState || pendingDeposit || badBinError"
                                         [inboundBet]="inboundBet">
            </cdux-funding-deposit-button>
        </div>
        <div *ngIf="this.errorCode === ERROR_CODES.EXPIRED" class="cdux-button-wrapper call-to-action">
            <button (click)="addNewCard()">
                ADD NEW CARD
            </button>
        </div>
    </div>
</form>
