import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { ModalService } from '@cdux/ng-platform/web';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { AbstractPaynearmeMethodDepositComponent } from './abstract-paynearme-method-deposit.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingService } from '../../../shared/services/funding.service';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import {
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { PaynearmeMethodDepositConstants } from './paynearme-method-deposit.constants';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-paynearme-method-deposit-fullpage',
    templateUrl: './paynearme-method-deposit-fullpage.component.html',
    styleUrls: ['./paynearme-method-deposit-fullpage.component.scss']
})
export class FundingPaynearmeMethodDepositFullPageComponent extends AbstractPaynearmeMethodDepositComponent implements OnInit {

    protected readonly isFullPage: boolean = true;
    public isLoading: boolean = true;

    public static getComponent(options: IFundingMethodComponentProperties, url: string): ISidebarPortalComponent {
        const retComp = super.createSidebarPortal(FundingPaynearmeMethodDepositFullPageComponent, options);
        if (retComp.properties.inputs.get('method-details').locked) {
            retComp.properties.inputs.set(PaynearmeMethodDepositConstants.IS_DISABLED, retComp.properties.inputs.get('method-details').locked);
        }
        if (url && url.length > 0) {
            retComp.properties.inputs.set(PaynearmeMethodDepositConstants.URL_TOKEN, url);
        } else {
            retComp.properties.inputs.set(PaynearmeMethodDepositConstants.HAS_ERROR_TOKEN, true);
        }
        return retComp;
    }

    constructor(
        localRouter: Router,
        localEventService: EventTrackingService,
        localEnvironment: ENVIRONMENT,
        localModalService: ModalService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localEventService,
            localEnvironment,
            localModalService,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            _featureToggleService
        );
        this.affiliateId = localEnvironment.affiliateId.toString();
    }

    ngOnInit(){
        if(this.hasError){
            const message = this._translateService.translate('paynearme-unavailable', 'errorcodes', false,
                this._translateService.translate('customer-service', this._environment.affiliateId.toString(), false));
            this._fundingService.onFundingEvent.emit({event: 'ERROR', code: 'paynearme-unavailable', messages: [message]});
        }
    }
    /**
     * When iframe resource get loaded, turn off the loading dots
     */
    public handleOnLoad() {
        this.isLoading = false;
    }
}
