import { Component, OnInit } from '@angular/core';
import {
    ISidebarComponentProperties,
    ISidebarHeaderComponent,
    ISidebarHeaderProperties
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { CduxAbstractSidebarComponent } from '../../../../sidebar/cdux-sidebar-component.class';

@Component({
  selector: 'cdux-paynearme-method-header',
  templateUrl: './paynearme-method-header.component.html',
  styleUrls: ['./paynearme-method-header.component.scss']
})
export class FundingPaynearmeMethodHeaderComponent extends CduxAbstractSidebarComponent implements OnInit {

    public static getSidebarComponent(options: ISidebarHeaderProperties = {}): ISidebarHeaderComponent {
        return {
            component: FundingPaynearmeMethodHeaderComponent,
            properties: options
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    ngOnInit() {
  }

}
