<div class="amount-deposit-wrap">
    <div class="amount--deposit">
        <div class="item-name">{{ transactionName }}:</div>
        <div class="item-amount" id="depositAmount"> {{ amount |
            currency:'USD':'symbol-narrow':DIGIT_FORMAT }}
        </div>
    </div>
    <div class="amount--fee">
        <div class="item-name">{{ feeName }}:</div>
        <div class="item-amount" id="txnFee"> {{ feeValue  |
            currency:'USD':'symbol-narrow':DIGIT_FORMAT }}
        </div>
    </div>
    <div class="amount--total">
        <div class="item-name">Total:</div>
        <div class="item-amount" id="total"> {{ calculateTotal() |
            currency:'USD':'symbol-narrow':DIGIT_FORMAT }}
        </div>
    </div>
</div>
