import {Directive, Input, ElementRef} from '@angular/core'

@Directive({
    selector: '[cduxFocus]'
})
export class CduxFocusDirective {
    @Input() public scrollToTop: boolean ;

    @Input()
    set cduxFocus(value: boolean) {
        if (value) {
            if (this.scrollToTop === undefined || this.scrollToTop === null) {
                this.elementRef.nativeElement.scrollIntoView();
            } else {
                // allowing the element to scroll if needed by passing the scrollToTop value as an arg
                this.elementRef.nativeElement.scrollIntoView(this.scrollToTop);
            }
        }
    }

    constructor(private elementRef: ElementRef) {}
}
