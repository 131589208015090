import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
    ViewRef
} from '@angular/core';

import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import { ITrackBasic, ITodaysRace, FavoriteTracksService, enumRaceStatus, TrackService, EventClickType, EventClickAttributeType, ITrack, RaceDetails, enumTodaysTracksSortOrder } from '@cdux/ng-common';
import { enumDropdownStates } from '@cdux/ng-fragments';

import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { TrackListRequestHandler } from '../../classes/track-list-request-handler.class';
import { RaceListRequestHandler } from '../../classes/race-list-request-handler.class';
import { Subject } from 'rxjs';
import { DisplayModeEnum } from '../../../common/enums';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FormatRaceTypeUtil } from 'app/shared/common';

@Component({
    selector: 'cdux-common-race-nav',
    templateUrl: './common-race-nav.component.html',
    styleUrls: ['./common-race-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonRaceNavComponent implements OnInit, OnDestroy {

    /** INPUTS/OUTPUTS **/
    private _selectedRaceNavigation: ITrackBasic;
    @Input()
    public set selectedRaceNavigation(raceNav: ITrackBasic) {
        if (!!raceNav) {
            const prevRaceNav = this._selectedRaceNavigation;
            this._selectedRaceNavigation = raceNav;

            /* only update racelist on track change, to avoid unnecessary WS calls
             * if on the same track, but race changed, only update the selected track
             * this is necessary since a user can select the current track from the track dropdown
             * which will revert the selected track back to the current race
             */
            if (!this.races || !prevRaceNav || !TrackService.isSameTrack(prevRaceNav, this._selectedRaceNavigation)) {
                this._raceListRequestHandler.updateRaceNav(raceNav);
            } else if (this._selectedRaceNavigation.RaceNum !== prevRaceNav.RaceNum) {
                this.selectedRace = this._getSelectedRace(this.races);
                this._updateAdjacentRaceNumbers();
            }
            this._changeDetector.detectChanges();
        }
    }
    public get selectedRaceNavigation() {
        return this._selectedRaceNavigation;
    }

    @Input() public racesRestrictedTo: enumRaceStatus[];

    public DisplayModeEnum = DisplayModeEnum;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;

    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateTrackList: EventEmitter<ITrackBasic[] | ITrack[]> = new EventEmitter<ITrackBasic[] | ITrack[]>();
    /** END INPUTS/OUTPUTS **/

    /** PUBLIC PROPERTIES **/
    public tracks: ITrackGroupLists;
    public races: ITodaysRace[];
    public selectedRace: ITodaysRace;
    public previousRaceNumber: number = 0;
    public nextRaceNumber: number = 0;
    public raceNumberMap: number[]; // allows us to more easily navigate up and down the race list
    public currentRaceIndex: number; // preserve the current race index so we don't have to look it up every time
    /** END PUBLIC PROPERTIES **/

    /** CONTROLS **/
    private _trackListRequestHandler: TrackListRequestHandler;
    private _raceListRequestHandler: RaceListRequestHandler;
    private _destroy: Subject<undefined> = new Subject<undefined>();
    /** END CONTROLS **/

    constructor(
        private _todaysRacesService: TodaysRacesBusinessService,
        private _favoriteTracksService: FavoriteTracksService,
        private _changeDetector: ChangeDetectorRef,
        private _eventTrackingService: EventTrackingService
    ) {
        this._trackListRequestHandler = new TrackListRequestHandler(this._todaysRacesService, this._favoriteTracksService);
        this._raceListRequestHandler = new RaceListRequestHandler(this._todaysRacesService);
    }

    /** LIFECYCLE HOOKS **/
    ngOnInit() {

        this._todaysRacesService.getTodaysActiveTracks(true, true, enumTodaysTracksSortOrder.NEXTUP).pipe(
            this._prioritizeOpenRace(),
            debounceTime(300),
            take(1),
            takeUntil(this._destroy)
        ).subscribe((trackGroupLists) => {
            // Canceled tracks won't appear in general list, but favorites will.
            // Concat so that new list contains all tracks available to user.
            const basicTrackList: ITrack[] = trackGroupLists.generalTracks.concat(trackGroupLists.canceledTracks)
                .map((todaysDisplayTrack) => todaysDisplayTrack.ITrack);
            this.updateTrackList.emit(basicTrackList);
        });

        /** On every track list emit **/
        this._trackListRequestHandler.listen().subscribe((trackGroupLists) => {
            this.tracks = trackGroupLists;
            if (!(<ViewRef>this._changeDetector).destroyed) {
                this._changeDetector.detectChanges();
             }
        });

        /** On every race list emit */
        this._raceListRequestHandler.listen()
            .pipe(this._filterRestrictedRaces())
            .subscribe((todaysRaces: ITodaysRace[]) => {

                // sort the race list, not assuming it's sorted
                this.races = todaysRaces.sort((a, b) => a.raceNumber - b.raceNumber);

                // we create and navigate across the map because we can't assume the race numbers are sequential
                this.raceNumberMap = this.races.map((r) => r.raceNumber);

                // Unfortunately, we need the MTP in the race list, so we need to get a matching ITodaysRace
                // instead of just relying on the ITrackBasic.
                this.selectedRace = this._getSelectedRace(this.races);

                this.races.map(race => {
                    race['raceDetailsDisplay'] = [];
                    race['raceDetailsDisplay']['raceType'] =
                        FormatRaceTypeUtil.format(
                            this._todaysRacesService.mapTodaysRaceToRaceDetails(race, this.selectedRaceNavigation) as RaceDetails,
                            this.selectedRaceNavigation,
                            true
                        );
                    race['raceDetailsDisplay']['displayRaceName'] = this._formatRaceDescription(race.displayRaceName, race.grade);
                    race['raceDetailsDisplay']['distance'] = race.distance;
                    race['raceDetailsDisplay']['surface'] = race.surfaceLabel;
                    race['raceDetailsDisplay']['trackType'] = this.selectedRaceNavigation?.TrackType;
                });

                // Update the adjacent race number, whether there is a next or previous race
                this._updateAdjacentRaceNumbers();
                this._changeDetector.detectChanges();
            });
    }

    ngOnDestroy() {
        // Cleanup controls
        this._destroy.next();
        this._destroy.complete();
        this._trackListRequestHandler.kill();
        this._raceListRequestHandler.kill();
    }
    /** END LIFECYCLE HOOKS **/

    /** EVENT HOOKS **/
    public onRaceChange(raceChange: ITodaysRace) {
        if (raceChange) {
            this.onRaceNavigationChange({ ...this.selectedRaceNavigation, RaceNum: raceChange.raceNumber });
            this._updateAdjacentRaceNumbers();
        }
    }

    public onRaceNavigationChange(value: ITrackBasic): void {
        this.updateRaceNav.emit(value);
    }

    public onTrackDropdownStateChange(state: enumDropdownStates): void {
        this._trackListRequestHandler.updateDropdownState(state);
    }

    public onRaceDropdownStateChange(state: enumDropdownStates): void {
        this._raceListRequestHandler.updateDropdownState(state);
    }
    /** END EVENT HOOKS **/

    private _formatRaceDescription(raceDescription: string, grade?: string ): string {
        raceDescription = raceDescription?.trim() || '';
        if (raceDescription.length > 35) {
            return raceDescription.slice(0, 35).trim() +
                (grade ? ('...-' + grade) : '...');
        } else {
            return raceDescription;
        }
    }

    private _filterRestrictedRaces() {
        if (!this.racesRestrictedTo) {
            return (src) => src;
        }
        return map((races: ITodaysRace[]) => {
            return races.filter(this._todaysRacesService.filterRaceStatus(...this.racesRestrictedTo));
        });
    }

    private _prioritizeOpenRace() {
        return map((trackGroupLists: ITrackGroupLists) => {
            /*
             * BE sort logic puts 'off' races first, if possible put an open race first so initialization logic that
             * grabs track[0] will load an open race if no initial track is specified via URL
             * Note: this is for an internal list of basic track objects and does not affect sort order in dropdown
             */
            const index = trackGroupLists.generalTracks.findIndex(track => track.hasBetTypes && !!track.races[track.currentRaceIndex]
                && TrackService.isWagerableRace(track.races[track.currentRaceIndex].status));
            if (index > 0) {
                trackGroupLists.generalTracks.unshift(trackGroupLists.generalTracks.splice(index, 1)[0]);
            }
            return trackGroupLists;
        });
    }

    private _getSelectedRace(todaysRaces: ITodaysRace[]): ITodaysRace {
        return todaysRaces.find(r => r.raceNumber === this.selectedRaceNavigation.RaceNum) ||
            todaysRaces.find((r, i, a) => TrackService.isWagerableRace(r.status) || i === a.length - 1);
    }

    /**
     * Navigate to the previous race in the list
     */
    public previousRace() {
        if (this.currentRaceIndex > 0) {
            this.onRaceChange(this.races.find(r => r.raceNumber === this.raceNumberMap[this.currentRaceIndex - 1]));
            this.logClick(EventClickType.RACE_NAV_PREVIOUS);
        } else {
            return;
        }
    }

    /**
     * Navigate to the next race in the list
     */
    public nextRace() {
        if (this.currentRaceIndex < this.raceNumberMap.length - 1) {
            this.onRaceChange(this.races.find(r => r.raceNumber === this.raceNumberMap[this.currentRaceIndex + 1]));
            this.logClick(EventClickType.RACE_NAV_NEXT);
        } else {
            return;
        }

    }

    /**
     * If the adjacent race number isn't available set it to zero,
     * otherwise return the actual adjacent race number
     */
    private _updateAdjacentRaceNumbers() {
        // Preserve the current race index based on the current race
        this.currentRaceIndex = this.raceNumberMap.indexOf(this.selectedRaceNavigation.RaceNum);
        this.previousRaceNumber = this.currentRaceIndex > 0 ? this.raceNumberMap[this.currentRaceIndex - 1] : 0;
        this.nextRaceNumber = this.currentRaceIndex < this.raceNumberMap.length - 1 ? this.raceNumberMap[this.currentRaceIndex + 1] : 0;
    }

    public logClick(event: EventClickType) {
        const time = Date.now();
        this._eventTrackingService.logClickEvent(event,
            [
                { attrId: EventClickAttributeType.RACE_NAV_RACE_BRIS_CODE, data: this.selectedRaceNavigation.BrisCode, timestamp: time },
                { attrId: EventClickAttributeType.RACE_NAV_RACE_TRACK_TYPE, data: this.selectedRaceNavigation.TrackType, timestamp: time },
                { attrId: EventClickAttributeType.RACE_NAV_RACE_RACE, data: this.selectedRace.raceNumber, timestamp: time },
                { attrId: EventClickAttributeType.RACE_NAV_RACE_DATE, data: this.selectedRace.raceDate, timestamp: time },
            ]);
    }

}
