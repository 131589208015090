<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>

<div class="sire-dam-details" *ngIf="showDamSummary" [class.is-inline]="isInline">
    <!--Dam Details-->
    <div class="details-col">
        <h1>
            {{ damInfo.bdsDamSummary.horseName }} by {{ damInfo.bdsDamSummary.sireName }}<br>
            <span>
                {{ damInfo.bdsDamSummary.color }},
                {{ damInfo.bdsDamSummary.sexDescription }},
                {{ damInfo.bdsDamSummary.foaling }}
            </span>
        </h1>
        <div class="label-info-group">
            <div class="label-info">
                <div class="label">BREEDER</div>
                <div class="info"><span [innerHTML]="damInfo.bdsDamSummary.breeder | lineBreaker"></span> ({{ damInfo.bdsDamSummary.whereBred }})
                </div>
            </div>
        </div>
        <div class="label-info-group">
            <div class="label-info">
                <div class="label">PERFORMANCE</div>
                <div class="info">{{ damInfo.bdsDamSummary.damRecord.racePerformance }}</div>
            </div>
        </div>
        <div class="label-info-group">
            <div class="label-info">
                <div class="label">1ST CAREER START</div>
                <div class="info">{{ damInfo.bdsDamSummary.damRecord.firstStart }}</div>
            </div>
        </div>
    </div>
    <div class="detail-info-full">
        <div *ngIf="showDamRecord" class="stats-container">
            <table>
                <thead>
                    <tr>
                        <td>{{ isPhone ? "RECORD" : "LIFETIME RACE RECORD" }}</td>
                        <td class="align-right">{{ isPhone ? "STR" : "STARTS" }}</td>
                        <td class="align-right">{{ isPhone ? "WN" : "WIN" }}</td>
                        <td class="align-right">{{ isPhone ? "PL" : "PLACE" }}</td>
                        <td class="align-right">{{ isPhone ? "SH" : "SHOW" }}</td>
                        <td class="align-right">EARNINGS</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>LIFE</td>
                        <td class="align-right lifetimeStarts">{{ damInfo.bdsDamSummary.damRecord.parsedCareerRecord.starts }}</td>
                        <td class="align-right lifetimeWin">{{ damInfo.bdsDamSummary.damRecord.parsedCareerRecord.win }}</td>
                        <td class="align-right lifetimePlace">{{ damInfo.bdsDamSummary.damRecord.parsedCareerRecord.place }}</td>
                        <td class="align-right lifetimeShow">{{ damInfo.bdsDamSummary.damRecord.parsedCareerRecord.show }}</td>
                        <td class="align-right lifetimeEarnings">{{ damInfo.bdsDamSummary.damRecord.parsedCareerRecord.earnings}}</td>
                    </tr>
                    <tr>
                        <td>2YO RECORD</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTwoYoRecord.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTwoYoRecord.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTwoYoRecord.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTwoYoRecord.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTwoYoRecord.earnings }}</td>
                    </tr>
                    <tr>
                        <td>3YO RECORD</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedThreeYoRecord.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedThreeYoRecord.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedThreeYoRecord.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedThreeYoRecord.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedThreeYoRecord.earnings }}</td>
                    </tr>
                    <tr>
                        <td>SPRINTS</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedSprints.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedSprints.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedSprints.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedSprints.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedSprints.earnings }}</td>
                    </tr>
                    <tr>
                        <td>ROUTES</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedRoutes.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedRoutes.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedRoutes.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedRoutes.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedRoutes.earnings }}</td>
                    </tr>
                    <tr>
                        <td>TURF</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTurf.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTurf.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTurf.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTurf.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedTurf.earnings }}</td>
                    </tr>
                    <tr>
                        <td>ALL WEATHER</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedAllWeather.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedAllWeather.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedAllWeather.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedAllWeather.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedAllWeather.earnings }}</td>
                    </tr>
                    <tr>
                        <td>WET TRACKS</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedWetTracks.starts }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedWetTracks.win }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedWetTracks.place }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedWetTracks.show }}</td>
                        <td class="align-right">{{ damInfo.bdsDamSummary.damRecord.parsedWetTracks.earnings }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!--DAM Offspring Summary-->
        <ng-container *ngIf="showDamSummary">
            <div class="summary-header">
                <span>OFFSPRING SUMMARY</span>
            </div>

            <div class="info-container">
                <div class="table-container">
                    <table>
                        <tr>
                            <td>Foals</td>
                            <td class="align-right">{{ damInfo.bdsDamSummary.damOffspringSummary?.foals }}</td>
                        </tr>
                        <tr>
                            <td>Winners/Starters</td>
                            <td class="align-right">{{ [damInfo.bdsDamSummary.damOffspringSummary?.winner,
                                damInfo.bdsDamSummary.damOffspringSummary?.starter] | formatFraction }}</td>
                        </tr>
                        <tr>
                            <td>Stakes Winners</td>
                            <td class="align-right">{{ damInfo.bdsDamSummary.damOffspringSummary?.stakeWinner }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="table-container">
                    <table>
                        <tr>
                            <td>% 2YO Winners</td>
                            <td class="align-right">{{
                                damInfo.bdsDamSummary.damOffspringSummary?.twoYearWinnerPercentage }}</td>
                        </tr>
                        <tr>
                            <td>Turf Winners/Starters</td>
                            <td class="align-right">{{ [damInfo.bdsDamSummary.damOffspringSummary?.turfWinners,
                                damInfo.bdsDamSummary.damOffspringSummary?.turfStarters] | formatFraction }}</td>
                        </tr>
                        <tr>
                            <td>Dirt Winners/Starters</td>
                            <td class="align-right">{{ [damInfo.bdsDamSummary.damOffspringSummary?.dirtWinners,
                                damInfo.bdsDamSummary.damOffspringSummary?.dirtStarters] | formatFraction }}</td>
                        </tr>
                    </table>
                </div>
                <div class="table-container">
                    <table>
                        <tr>
                            <td>AW Winners/Starters</td>
                            <td class="align-right">{{ [damInfo.bdsDamSummary.damOffspringSummary?.awWinners,
                                damInfo.bdsDamSummary.damOffspringSummary?.awStarters] | formatFraction }}</td>
                        </tr>
                        <tr>
                            <td>AWD</td>
                            <td class="align-right">{{ damInfo.bdsDamSummary.damOffspringSummary?.awd }}</td>
                        </tr>
                        <tr>
                            <td>DPI</td>
                            <td class="align-right">{{ damInfo.bdsDamSummary.damOffspringSummary?.dpi }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </ng-container>

        <!--DAM Offspring Stats-->
        <ng-container *ngIf="showDamOffspring">
            <div class="stats-container">
                <table>
                    <thead>
                        <tr>
                            <td>Offspring stats</td>
                            <td class="align-right ">str</td>
                            <td class="align-right ">W%</td>
                            <td class="align-right ">$2 avg</td>
                            <td class="align-right ">wn</td>
                            <td class="align-right ">pl</td>
                            <td class="align-right ">sh</td>
                            <td class="align-right ">starters</td>
                            <td class="align-right ">winners</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-offspringStat let-i="index"
                            [ngForOf]="damInfo.bdsDamOffspringStats | cduxArraySlice:0:offspringStatDisplayLimit">
                            <tr class="qaa-dam-offspring-row">
                                <td class="qaa-dam-offspring-category"
                                    [ngClass]="offspringStat.avg2ret >= 2 ? 'green-highlight' : ''">{{
                                    offspringStat.categoryName }}</td>
                                <td class="align-right qaa-dam-offspring-starts">{{ offspringStat.starts | formatHorseDetailsNumber
                                    }}</td>
                                <td class="align-right qaa-dam-offspring-win-percent">{{ offspringStat.winPercentage }}</td>
                                <td class="align-right qaa-dam-offspring-avg-2-ret"
                                    [ngClass]="offspringStat.avg2ret >= 2 ? 'green-highlight' : ''">{{
                                    offspringStat.avg2ret | formatHorseDetailsNumber:null:'1.2-2' }}</td>
                                <td class="align-right qaa-dam-offspring-wins">{{ offspringStat.wins | formatHorseDetailsNumber }}
                                </td>
                                <td class="align-right qaa-dam-offspring-places">{{ offspringStat.places | formatHorseDetailsNumber
                                    }}</td>
                                <td class="align-right qaa-dam-offspring-shows">{{ offspringStat.shows | formatHorseDetailsNumber }}
                                </td>
                                <td class="align-right qaa-dam-offspring-starters">{{ offspringStat.starters |
                                    formatHorseDetailsNumber }}</td>
                                <td class="align-right qaa-dam-offspring-winners">{{ offspringStat.winners |
                                    formatHorseDetailsNumber }}</td>
                            </tr>
                        </ng-template>
                        <ng-container *ngIf="damInfo.bdsDamOffspringStats.length > offspringStatDisplayLimit">
                            <ng-container *ngTemplateOutlet="viewLessMore"></ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
    <div class="details-col" *ngIf="this.loaded && !damInfo" [class.is-inline]="isInline">
        <h1>No details available.</h1>
    </div>
</div>



<ng-template #viewLessMore>
    <tr>
        <td (click)="toggleShowAllStats()"><a>{{ offspringStatsToggleLabel }}</a></td>
    </tr>
</ng-template>
