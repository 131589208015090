import {
    Bet,
    BetCalculatorBusinessService,
    ConfigurationDataService,
    Entry,
    enumConfigurationStacks,
    IPoolType,
    JwtSessionService
} from '@cdux/ng-common';
import { Injectable } from '@angular/core';
import { WAGER_CONDITIONS } from '../enums/conditional-wagering.enum';
import { IConditionalWagerConfig } from '../interfaces/conditional-wager-config.interface';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ConditionalWageringBusinessService {
    private static readonly MIN_MTP = 0;
    private static readonly MAX_MTP = 15;
    private static readonly ODDS_LIST = [0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 4.5, 5,
        6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 99];
    private static readonly PAYOUT_LIST = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 20, 25,
        30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 125, 150, 175, 200, 250, 300,
        350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500, 1750, 2000, 3000, 4000, 5000, 6000,
        7000, 8000, 9000, 9999];

    private static readonly COND_WAGER_KEYS = ['cond_wager_show_odds', 'cond_wager_show_payout', 'cond_wager_config_timeout'];

    private _condWagerConfig: IConditionalWagerConfig;
    private _configTimeout: number;

    constructor (private _betCalculator: BetCalculatorBusinessService,
                 private _sessionService: JwtSessionService,
                 private _configService: ConfigurationDataService) {
        // clear config values on logout
        this._sessionService.addLogoutTask(() => new Promise<void>((resolve) => {
            this._clearConfigData();
            resolve();
        }), false);
    }

    public getCondWagerConfig(): Observable<IConditionalWagerConfig> {
        const time = new Date().getTime();
        if (!this._condWagerConfig || time > this._configTimeout) {
            return this._configService.getConfiguration(enumConfigurationStacks.TUX,
                ConditionalWageringBusinessService.COND_WAGER_KEYS).pipe(map(config => {
                    if (!!config) {
                        this._condWagerConfig = {
                            showOdds: !!config['cond_wager_show_odds'] ?  config['cond_wager_show_odds'].split(':') : [],
                            showProbPayout: !!config['cond_wager_show_payout'] ? config['cond_wager_show_payout'].split(':') : [],
                            timeout: !!config[ 'cond_wager_config_timeout'] ? +config[ 'cond_wager_config_timeout'] * 1000 : 0
                        };
                        this._configTimeout = time + this._condWagerConfig.timeout;
                    }
                    return this._condWagerConfig;
                }),
                catchError(err => {
                    return of(!!this._condWagerConfig ? this._condWagerConfig : null);
                })
            );
        }
        return of(this._condWagerConfig);
    }

    /**
     * @deprecated this method relies on asynchronous data without being asynchronous, itself
     * TODO: refactor or replace
     */
    public isValidRaceType(name: IPoolType, runners: Entry[][]): boolean {
        let flag = false;
        if (!!name && this._getCondWagerType(name) != null) {
            for (let i = 0; i < runners.length; i++) {
                const firstRunner = !!runners[i][0] ? runners[i][0].ProgramNumberCoupled : null;
                if (!!firstRunner && runners[i].length === runners[i].filter(runner => runner.ProgramNumberCoupled === firstRunner).length) {
                    flag = true;
                } else {
                    return false;
                }
            }
        }
        return flag;
    }

    /**
     * A destructive function to populate some needed fields for conditional wager.
     * Note. after the call, original bet record can be modified for some fields.
     * @param bet
     */
    public initializeConditionalWager(bet: Bet): void {
        if (this.isValidRaceType(bet.poolType, bet.runners)) {
            bet.cost = this._betCalculator.calculate(bet).toString();
            bet.conditional = true;
            bet.conditionalMtp = 0;
            if (this.showOdds(bet.poolType)) {
                bet.conditionalOdds = 10;
                bet.conditionalProbablePayout = null;
            } else {
                bet.conditionalOdds = null;
                bet.conditionalProbablePayout = 50;
            }
        }
    }

    public clearConditionalWager(bet: Bet): void {
        bet.conditional = false;
        bet.cost = null;
        bet.conditionalOdds = null;
        bet.conditionalMtp = null;
        bet.conditionalProbablePayout = null;
    }

    public updateMTP(value: number, increment: boolean): number {
        if (increment) {
            if (value < ConditionalWageringBusinessService.MAX_MTP) {
                return value + 1;
            } else {
                return ConditionalWageringBusinessService.MAX_MTP;
            }
        } else {
            if (value > ConditionalWageringBusinessService.MIN_MTP) {
                return value - 1;
            } else {
                return ConditionalWageringBusinessService.MIN_MTP;
            }
        }
    }

    public updatePayout(value: number, increment: boolean): number {
        return this._updateConditions(value, increment, ConditionalWageringBusinessService.PAYOUT_LIST);
    }

    public updateOdds(fraction: number, increment: boolean): number {
        return this._updateConditions(fraction, increment, ConditionalWageringBusinessService.ODDS_LIST);
    }

    private _updateConditions(value: number, increment: boolean, conditionList: number[]) {
        const index = conditionList.indexOf(value);
        if (increment) {
            if (index !== -1 && index < (conditionList.length - 1)) {
                return conditionList[index + 1];
            }
        } else {
            if (index > 0) {
                return conditionList[index - 1];
            }
        }
        return value;
    }

    public showOdds(poolType: IPoolType): boolean {
        return this._getCondWagerType(poolType) === WAGER_CONDITIONS.ODDS;
    }

    private _getCondWagerType(poolType: IPoolType): WAGER_CONDITIONS {
        if (!!poolType && !!poolType.Code && !!this._condWagerConfig) {
            if (this._condWagerConfig.showOdds.includes( poolType.Code.toUpperCase())) {
                return WAGER_CONDITIONS.ODDS;
            } else if (this._condWagerConfig.showProbPayout.includes( poolType.Code.toUpperCase())) {
                return WAGER_CONDITIONS.PAYOUT;
            }
        }
        return null;
    }

    private _clearConfigData() {
        this._configTimeout = null;
        this._condWagerConfig = null;
    }
}
