import { Component, HostBinding, OnInit } from '@angular/core';
import { isEqual, clone, omit } from 'lodash';
import { DrawerService, FullDrawerOperations } from 'app/shared/common/services';
import { ManageBusinessService } from '../../../shared/my-account/manage/services/manage.business.service';
import { take } from 'rxjs/operators';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import {
    NotificationDataService,
    iNotificationSettings,
    iUpdateNotificationSettingsResponse,
    IAccountInfo,
    iNotificationSettingsOptions,
    iAdvanceNotificationSettings,
    FeatureToggleDataService,
    EventClickType,
} from '@cdux/ng-common';

@Component({
    selector: 'cdux-favorites-manager-settings',
    templateUrl: './favorites-manager-settings.component.html',
    styleUrls: ['./favorites-manager-settings.component.scss'],
})
export class FavoritesManagerSettingsComponent implements OnInit {
    @HostBinding('class') class = 'stable-alerts__drawer-contents';

    public advancedStableAlertsToggle: boolean;
    public changesMade: boolean = false;
    public currentTab: string = 'email'; // Default to EMAIL tab
    public dailySummary: boolean; // control the radio button 'Yes' 'No'
    public emailAddress: string; // Email address displayed on Setting form
    public emailSettings: iNotificationSettings;
    private initialDailySummary: boolean;
    public initialEmailSettings: iNotificationSettings;

    public constructor(
        private _accountService: ManageBusinessService,
        private _notificationSvc: NotificationDataService,
        private _drawerService: DrawerService,
        localFeatureToggleService: FeatureToggleDataService,
        private _eventTrackingService: EventTrackingService,
    ) {
        this.advancedStableAlertsToggle =
            localFeatureToggleService.isFeatureToggleOn('STBL_ADV_TIMING');
    }

    ngOnInit(): void {
        // Get player email from ManageBusinessService
        this._accountService
            .load()
            .pipe(take(1))
            .subscribe((accountInfo: IAccountInfo) => {
                if (!!accountInfo?.email) {
                    this.emailAddress = accountInfo.email;
                }
            });
        // Get current Opted-in/out setting data
        this._notificationSvc
            .getNotificationSettings()
            .then((data: iNotificationSettings) => {
                if (data) {
                    this.emailSettings = data;
                    // set daily summary to true if any of the options are opted in
                    this.dailySummary = this.isNotificationOptionsOptedIn();
                    this.initialDailySummary = this.dailySummary;
                    // true arg indicates that the object should be deep cloned (recursively)
                    this.initialEmailSettings = clone(this.emailSettings, true);
                }
            });
    }

    /**
     * Emit the event to close the drawer
     */
    public closeDrawer(): void {
        this._drawerService.toggleFullDrawer({
            operation: FullDrawerOperations.CLOSE,
        });
    }

    /**
     * Save the object to the backend
     */
    public saveChanges(): void {
        const request: iNotificationSettings = this.emailSettings;
        this._notificationSvc
            .setNotificationSettings(
                request
            )
            .then((data: iUpdateNotificationSettingsResponse) => {
                this._logClickEvents();
                this.closeDrawer();
            });
    }

    /**
     * Set the current tab
     */
    public setCurrentTab(tab: string): void { }

    /**
     * Update the options based on this selection
     * @param event
     */

    public updateDailySummary(): void {
        this.dailySummary = !this.dailySummary;
        if (this.dailySummary) {
            // resets favorites and timings when daily summary is turned from off to on
            this.optIn();
        } else {
            // if daily summary is turned off, opt out of all favorites and timings
            this.onOptOut();
        }
    }

    private optIn(): void {
        if (!this.isNotificationOptionsOptedIn()) {
            this.emailSettings.notificationOptions
                .forEach((option: iNotificationSettingsOptions) => {
                    option.optedIn = true;
                });
        }
        // set morning of to true if no timings are selected
        if (!this.isNotificationSettingsOptedIn())
            this.emailSettings.advanceNotificationSettings.find(
                (setting: iAdvanceNotificationSettings) =>
                    setting.notificationFrequencyId === "0").optedIn = true;
    }

    private onOptOut(): void {
        this.dailySummary = false;
        // mark all favorites options false
        for (const option of this.emailSettings.notificationOptions) {
            option.optedIn = false;
        }
        // mark all timing options to false
        for (const setting of this.emailSettings.advanceNotificationSettings) {
            setting.optedIn = false;
        }
    }

    public onNotificationFavoritesChange(): void {
        // option arg is only present when a favorite checkbox is clicked. If there is no option, then the change came from clicking daily summary.
        if (!this.dailySummary) {
            this.dailySummary = true;
            this.optIn()
        }
        // if no favorites are selected, opt out of daily summary and uncheck favorites and timings.
        if (!this.isNotificationOptionsOptedIn()) this.onOptOut();
    }

    public onNotificationTimingsChange(): void {
        if (!this.dailySummary) {
            this.dailySummary = true;
            this.optIn();
        }
        // if no timings are selected, opt out of daily summary and uncheck timings and favorites.
        if (!this.isNotificationSettingsOptedIn()) this.onOptOut();
    }

    private isNotificationOptionsOptedIn(): boolean {
        return this.emailSettings.notificationOptions.some((option: iNotificationSettingsOptions) => option.optedIn);
    }

    private isNotificationSettingsOptedIn(): boolean {
        return this.emailSettings.advanceNotificationSettings.some((setting: iAdvanceNotificationSettings) => setting.optedIn);
    }

    public onChangesMade(): void {
        this.changesMade = !isEqual(this.emailSettings, this.initialEmailSettings);
    }


    private _logClickEvents(): void {
        const changes = this.getChanges();
        if (this.dailySummary !== this.initialDailySummary) {
            if (this.dailySummary) {
                this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_WHO_IS_RACING_EMAIL_OPT_IN);
            } else {
                this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_WHO_IS_RACING_EMAIL_OPT_OUT);
            }
        }
        if (changes.length !== 0) {
            changes.forEach(element => {
                if (element.optedIn && element.notificationFrequencyId === '0') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_MO_OPT_IN);
                }
                if (!element.optedIn && element.notificationFrequencyId === '0') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_MO_OPT_OUT);
                }
                if (element.optedIn && element.notificationFrequencyId === '24') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_ADV_24_HOUR_OPT_IN);
                }
                if (!element.optedIn && element.notificationFrequencyId === '24') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_ADV_24_HOUR_OPT_OUT);
                }
                if (element.optedIn && element.notificationFrequencyId === '48') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_ADV_48_HOUR_OPT_IN);
                }
                if (!element.optedIn && element.notificationFrequencyId === '48') {
                    this._eventTrackingService.logClickEvent(EventClickType.STABLE_SETTINGS_ADV_48_HOUR_OPT_OUT);
                }
            });
        }
    }

    private getChanges(): any {
        // omit returns the difference between the two objects. We want the changes, so we need to omit the initial object from the current object.
        const changes = omit(this.emailSettings.advanceNotificationSettings, (value, key) => isEqual(value, this.initialEmailSettings.advanceNotificationSettings[key]));
        // omit returns an object. Convert to array for easier looping in _logClickEvents()
        return Object.values(changes);
    }
}

