<section class="video-thumbnails">
    <ng-container *ngFor="let track of tracks; let i = index">
        <div class="video-thumbnail"
            *ngIf="i < tracksDisplayLength"
            (click)="selectRaceNav(track, index)"
            [class.is-active]="isActiveTrack(track)">
            <div class="text-col">
                <div class="track">{{ track.FormattedTrackName || track.DisplayName }}</div>
                <div class="race" *ngIf="track.RaceNum">Race {{ track.RaceNum }}</div>
            </div>
            <cdux-mtp-badge class="up-next-mtp" [source]="track" [showMinutes]="true" class="is-vid-thumb"></cdux-mtp-badge>
        </div>
    </ng-container>
</section>
