import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
    enumTrackType,
    EventClickAttributeType,
    EventClickType,
    FeatureToggleDataService,
    ITodaysRace,
    ITrack,
    ToteDataService,
    TrackService,
    TodaysDisplayTrack,
} from '@cdux/ng-common';
import { enumDropDownIdentifier, enumDropdownStates } from '@cdux/ng-fragments';
import { EventTrackingService } from '../../../shared/event-tracking/services/event-tracking.service';
import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { ITrackListSectionDisplay } from './track-list-section-display.interface';
import { DisplayModeEnum } from 'app/shared/common/enums';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

enum trackSortStyle {
    ALPHABETICAL,
    MTP
}

interface ICarryoverMap {
    generalTracksMap: {
        [brisCode: string]: boolean
    },
    featuredTracksMap: {
        [brisCode: string]: boolean
    }
}

@Component({
    selector: 'cdux-track-list',
    templateUrl: './track-list.component.html',
    styleUrls: ['./track-list.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TrackListComponent implements OnInit, OnDestroy {

    public eventClickType = EventClickType;
    public enumTrackType = enumTrackType;
    public trackSortStyle = trackSortStyle;

    private readonly FEATURE_TOGGLE_FEATURED_TRACKS = 'SHOW_FEATURED';

    // setup tracks from a single list including favorite and cancelled
    private _trackGroups: ITrackGroupLists;
    @Input()
    public set trackGroups(tracks: ITrackGroupLists) {
        this._trackGroups = tracks;

        this.hasTBred = this.hasHarn = this.hasGrey = false;

        this._prepareTracks(true);

        this._buildCarryoverMap();
    }
    public get trackGroups() { return this._trackGroups; }

    /**
     * Since currentTrack is an input, we want to ultimately leave it as an ITrack so we don't have to mess with the things
     * passing data in. However, since we are now setting the value of a given dropdown option to a TodaysDisplayTrack,
     * we will receive one when switching tracks so we need to do some checking.
     *
     * @param track
     */
    private _track: ITrack;
    @Input()
    public set currentTrack(track: ITrack | TodaysDisplayTrack | null) {
        if (track) {
            this._track = track instanceof TodaysDisplayTrack ? track.ITrack : track;
        }
        this._changeDetector.detectChanges();
    }

    /**
     * Ultimately this._track will always be an ITrack, but getters and setters must have the same type.
     */
    public get currentTrack(): ITrack | TodaysDisplayTrack | null {
        return this._track;
    }

    @Input()
    public set availability(availability: boolean) {
        this._availability = availability;
    }
    public get availability(): boolean {
        return this._availability;
    }

    @Input()
    public set sectionDisplay(sections: ITrackListSectionDisplay) {
        this._sectionDisplay = Object.assign(this._sectionDisplay, sections);
    }
    public get sectionDisplay(): ITrackListSectionDisplay {
        return this._sectionDisplay;
    }

    public DisplayModeEnum = DisplayModeEnum;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;
    @Input() public set externalOpenEnabled(enabled: boolean) {
        this.dropDownIdentifier = enabled ? enumDropDownIdentifier.TRACK_LIST_DROPDOWN : null;
    }

    /**
     * We are trying to switch TUX over to using the response from the todays-tracks webservice. Those calls result in a
     * TodaysDisplayTrack (see stuff going on in todays-races.business.service getTodaysTrackLists). This is left in place
     * to support anything still listening for an ITrack but new things should listen to onTodaysDisplayTrackChange.
     *
     * @deprecated
     */
    @Output() onTrackChange = new EventEmitter<ITrack>();

    /**
     * This emits the new track object generated from the todays-tracks webservice call. Use this for the new hawtness.
     */
    @Output() onTodaysDisplayTrackChange = new EventEmitter<TodaysDisplayTrack>();

    @Output() onTrackDropdownStateChange: EventEmitter<enumDropdownStates> = new EventEmitter<enumDropdownStates>();

    private _subscriptions: Subscription[] = [];
    private _availability: boolean;
    private _sectionDisplay: ITrackListSectionDisplay = {
        canceled: true,
        favorite: true,
        featured: true,
        general: true
    };
    public isFeaturedShown: boolean = false;

    public toteDate: string;

    public displayTrackGroups: ITrackGroupLists = null;

    public sortStyle: trackSortStyle = trackSortStyle.MTP;
    public trackTypeFilter: enumTrackType = null;

    public hasTBred: boolean = false;
    public hasHarn: boolean = false;
    public hasGrey: boolean = false;
    public tournamentSelected: boolean = false;

    public dropDownIdentifier: enumDropDownIdentifier;

    public carryoverMap: ICarryoverMap = {
        generalTracksMap: {},
        featuredTracksMap: {}
    };

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _eventsService: EventTrackingService,
        private _tournamentSessionService: TournamentsSessionService,
        private _featureToggleService: FeatureToggleDataService,
        protected _toteDataService: ToteDataService,
    ) { }

    ngOnInit() {
        this._subscriptions.push(
            this._featureToggleService.watchFeatureToggle(this.FEATURE_TOGGLE_FEATURED_TRACKS)
                .subscribe(result => this.isFeaturedShown = result),
            this._toteDataService.currentRaceDate()
                .subscribe(date => this.toteDate = date),
            this._tournamentSessionService.onTournamentSelection.subscribe((data) => {
                this.tournamentSelected = data ? true : false;
                if (data) {
                    this.filterTrackType(null);
                    this.tournamentSelected = true;
                } else {
                    this.tournamentSelected = false;
                }
            })
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    public onDropdownStateChange(state: enumDropdownStates): void {
        this.onTrackDropdownStateChange.emit(state);
    }

    /**
     * Selects a track and closes the track drop down.
     *
     * @param {ITrack} track
     */
    public setCurrent(track: TodaysDisplayTrack) {
        this.onTrackChange.emit(track.ITrack);
        this.onTodaysDisplayTrackChange.emit(track);
    }

    public trackById(index: number, track: TodaysDisplayTrack) {
        return track.brisCode + track.type;
    }

    public logTrackClick(track: TodaysDisplayTrack) {
        const ts = Date.now();

        this._eventsService.logClickEvent(this.eventClickType.RACE_NAV_TRACK_SELECTION, [
            { attrId: EventClickAttributeType.RACE_NAV_TRACK_BRIS_CODE, data: track.brisCode, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_TRACK_TRACK_TYPE, data: track.type, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_TRACK_RACE, data: track.currentRaceNumber, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_TRACK_RACE_DATE, data: this.toteDate, timestamp: ts }
        ]);
    }

    public logFeaturedTrackClick(track: TodaysDisplayTrack) {
        this._eventsService.logClickEvent(this.eventClickType.FEATURED_TRACK_RACE_SELECTION, [
            { attrId: EventClickAttributeType.FEATURED_TRACK_BRIS_CODE, data: track.brisCode },
            { attrId: EventClickAttributeType.FEATURED_TRACK_TRACK_TYPE, data: track.type },
            { attrId: EventClickAttributeType.FEATURED_TRACK_RACE_NUMBER, data: track.currentRaceNumber }
        ]);
    }

    public filterTrackType(type: enumTrackType) {
        this.trackTypeFilter = type;
        this._prepareTracks();
    }

    public sortTracks() {
        this.sortStyle = this.sortStyle === trackSortStyle.MTP ? trackSortStyle.ALPHABETICAL : trackSortStyle.MTP;
        this._prepareTracks();
}

    // This isn't the most efficient way to do things. We could technically not need to refilter if we are just changing the
    // sorting. However, the backend is responsible for sorting the tracks by MTP and I don't want to recreate that logic
    // here just in case we ever decide we want to move OFF to the bottom or something. But the track list isn't really
    // that big so filtering out and sorting isn't going to take much time on each button push or when the service polls
    // and pushes a new list.
    private _prepareTracks(hasNewList: boolean = false) {
        this.displayTrackGroups = { generalTracks: [], favoriteTracks: [], canceledTracks: [], featuredTracks: [] };

        if (this.trackGroups) {
            for (const group in this.trackGroups) {
                if (this.trackGroups.hasOwnProperty(group)) {
                    this.displayTrackGroups[group] = this.trackGroups[group].filter(
                        (track) => {
                            // Piggy-back on the filtering to check our track type availabilities for our superset of
                            // track data. Since we have to do a switch/if..else statements anways, might has well save
                            // running through the tracks again.
                            if (hasNewList) {
                                switch (track.type) {
                                    case enumTrackType.TBRED:
                                        this.hasTBred = true;
                                        break;
                                    case enumTrackType.HARN:
                                        this.hasHarn = true;
                                        break;
                                    case enumTrackType.GREY:
                                        this.hasGrey = true;
                                        break;
                                }
                            }
                            return this.trackTypeFilter === null || track.type === this.trackTypeFilter
                        });
                }
            }

            if (this.sortStyle === trackSortStyle.ALPHABETICAL) {
                for (const group in this.displayTrackGroups) {
                    if (this.displayTrackGroups.hasOwnProperty(group)) {
                        this.displayTrackGroups[group] = this.displayTrackGroups[group].sort((a, b) => a.formattedTrackName.localeCompare(b.formattedTrackName));
                    }
                }
            }
        }
    }

    public getClass() {
        return this.sortStyle === trackSortStyle.MTP ? 'sort-time' : 'sort-alphabetical';
    }

    // Rather than doing all this logic in a function the template calls (worst case scenario is checking all the track's races every digest),
    // build a mapping when we get new track data.
    private _buildCarryoverMap() {
        this.carryoverMap = {
            generalTracksMap: {},
            featuredTracksMap: {}
        };

        // We will need to generate a map for both general and featured tracks. Since featured tracks may have only a single
        // featured race, we can't use the general tracks map which checks all races. This may result in a little extra
        // checking for tracks that have all races featured but it makes checking for carryovers consistent.
        if (this.trackGroups) {
            if (Array.isArray(this.trackGroups.generalTracks)) {
                this.trackGroups.generalTracks.forEach((track) => {
                    this.carryoverMap.generalTracksMap[track.brisCode] = this._checkForCarryover(track.races, track.currentRaceNumber);
                });
            }
            if (Array.isArray(this.trackGroups.featuredTracks)) {
                this.trackGroups.featuredTracks.forEach((track) => {
                    this.carryoverMap.featuredTracksMap[track.brisCode] = this._checkForCarryover(track.races, track.currentRaceNumber);
                });
            }
        }
    }

    private _checkForCarryover(races: ITodaysRace[], currentRaceNumber: number) {
        let hasCarryover = false;

        for (let i = 0; i < races.length; i++) {
            if (races[i].raceNumber >= currentRaceNumber && races[i].carryover && races[i].carryover.length && TrackService.isWagerableRace(races[i].status)) {
                hasCarryover = true;
                break;
            }
        }

        return hasCarryover;
    }

    public displayFavRunnerBadge(track: TodaysDisplayTrack): boolean {
        return track.favorites && track.favorites.length > 0;
    }
}
