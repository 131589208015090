<header class="nav-panel-header" [class.empty]="!headerComponent">
  <div class="nav-panel-header_back-button" *ngIf="showButton" (click)="buttonClickHandler()" [cduxLogClick]="eventClickType">
    <div  class="icon--arrow-left--blue"></div>
  </div>
  <div class="nav-panel-header_title">
    <ng-template *ngIf="headerComponent"
                 [cduxSidebarProperties]="headerProperties"
                 [cdkPortalOutlet]="headerComponent"
                 class="sidenav-header-content"></ng-template>
  </div>
</header>
