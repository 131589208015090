<div class="expert-pick-video-container">
  <div class="header">
    <span>{{ header }}</span>
    <i class="icon--expert-video-close" (click)="closeVideoModal()"></i>
  <div>
  <div  class="video-player-container">
    <!--Vimeo code-->
    <div style="padding:56.25% 0 0 0;position:relative;"><iframe [src]="videoUrl" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
      <script src="https://player.vimeo.com/api/player.js"></script>  
    </div>
  </div>
</div>