import { Injectable } from '@angular/core';
import {
    FeatureToggleDataService,
    IWithdrawBalance,
    JwtSessionService,
    PlayerGroupsService,
} from '@cdux/ng-common';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { FEATURE_TOGGLE_WITHDRAW_OFFER } from '../../../common/constants';

const SESSION_STORAGE_KEY = 'withdraw_offer';

@Injectable()
export class WithdrawOfferService {
    private offerEligibilitySubject: ReplaySubject<string> = new ReplaySubject<string>();

    private offers = [
        {
            id: 'ID3351',
            min: 0,
            max: 25
        },
        {
            id: 'ID3353',
            min: 25.01,
            max: 100
        },
        {
            id: 'ID3355',
            min: 100.01,
            max: 500
        },
        {
            id: 'ID3357',
            min: 500.01,
            max: 5000
        },
        {
            id: 'ID3359',
            min: 5000.01,
            max: null
        }
    ];

    constructor(
        private _featureToggleService: FeatureToggleDataService,
        private _playerGroupsService: PlayerGroupsService,
        private _sessionService: JwtSessionService
    ) {}

    public getOfferEligibility(balance: IWithdrawBalance): Observable<string> {
        this._featureToggleService.watchFeatureToggle(FEATURE_TOGGLE_WITHDRAW_OFFER).pipe(
            first()
        ).subscribe((toggleValue) => {
            let offerCode: string = null;

            if (
                toggleValue
                && sessionStorage.getItem(SESSION_STORAGE_KEY) !== 'true'
                && !this._playerGroupsService.inClaimedWithdrawBonusGroup()
                && balance
                && balance.hasOwnProperty('availableBalance')
            ) {
                offerCode = this._determineOfferFromBalance(balance.availableBalance);
            }

            this.offerEligibilitySubject.next(offerCode);
        });

        return this.offerEligibilitySubject.asObservable();
    }

    public storeWithdrawOffer(code: string): boolean {
        let isWithdrawOffer = false;

        for (const offer of this.offers) {
            if (code === offer.id) {
                isWithdrawOffer = true;
                break;
            }
        }

        if (isWithdrawOffer) {
            sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
            this._sessionService.addLogoutTask(() => new Promise<void>((resolve) => {
                sessionStorage.removeItem(SESSION_STORAGE_KEY);
                resolve();
            }));
        }

        return isWithdrawOffer;
    }

    /**
     * Function to determine which withdraw offer, if any, a user is eligible for. It will check against a list of
     * predefined offer ranges. These ranges should not overlap. The order of the ranges doesn't matter. To represent
     * no maximum, set max: null for that offer. Note that this function won't scale well.
     * If we start adding hundreds of these, it should be revisited if the logic hasn't already been moved to Java.
     *
     * @private
     *
     * @returns {string} On resolve -- a string representing the offer code or null
     */
    private _determineOfferFromBalance(balance: number): string {
        let offerCode = null;

        for (const offer of this.offers) {
            if (balance >= offer.min && (balance <= offer.max) || offer.max === null) {
                offerCode = offer.id;
                break;
            }
        }

        return offerCode;
    }
}
