<form *ngIf="form" [formGroup]="form">
    <div class="express-deposit-input">
        <div class="label">
            <span>Select or enter an amount</span>
        </div>

        <cdux-funding-input-amount
            id="depositAmount"
            formControlName="amount"
            [inputType]="OPERATION"
            [fundType]="fundingMethodDetails.fundType"
            [min]="getMinDepositAmount(hasVerifiedAccount)"
            [max]="getMaxDepositAmount()"
            [suggestions]="[250,100,50]"
            [amount]="amount"
            [offerId]="offerId"
            [phoneEnabled]="phoneEnabled"
            [displayStyle]="enumDepositDisplayStyle.EXPRESS">
        </cdux-funding-input-amount>
    </div>

    <div class="express-deposit-options">
        <div class="label">
            <span>Deposit from</span>
            <span><a (click)="!pendingDeposit && changeClick.emit()">Change</a></span>
        </div>

        <cdux-funding-option
            [fundingMethodDetails]="fundingMethodDetails"
            [displayStyle]="enumDepositDisplayStyle.EXPRESS"
            [hideAccountDetails]="replaceAccountInfo"
            [canReplace]="fundingMethodDetails.canReplace"
            (replace)="!pendingDeposit && replaceClick.emit()">
        </cdux-funding-option>
    </div>

    <div class="express-deposit-details">
        <ng-container *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo">
            <div class="radio-btn-row">
                <cdux-radio-group class="ts-radio inline" formControlName="accountType">
                    <cdux-radio-option id="checkingRadio" value="checking">
                        Checking
                    </cdux-radio-option>
                    <cdux-radio-option id="savingsRadio" value="saving">
                        Savings
                    </cdux-radio-option>
                </cdux-radio-group>
            </div>

            <cdux-input class="form-label-hover"
                        minlengtherror="The number entered should be a 9-digit number."
                        maxlengtherror="The number entered should be a 9-digit number."
                        requirederror="Routing Number is required."
                        label="Routing Number"
                        title="9 Digit Number"
                        helptext="(9 Digits)"
                        [classes]="['account-num', 'fs-exclude']"
                        (reset)="resetField('routingNumber')">
                <input id="routingNumber"
                        autocomplete="off"
                        cduxInputField
                        formControlName="routingNumber"
                        minlength="9"
                        maxlength="9"
                        type="text"
                        [cduxInputComplete]="form.get('routingNumber').value.length === 9"
                        [cduxInputCompleteFocus]="accountNumberInput" />
            </cdux-input>

            <cdux-input class="form-label-hover"
                        minlengtherror="The number entered should be more than 4-digits."
                        maxlengtherror="The number entered should be less than 18-digits."
                        requirederror="Account Number is required."
                        label="Account Number"
                        title="17 Digit Number"
                        helptext="(Up to 17 Digits)"
                        [classes]="['account-num', 'fs-exclude']"
                        (reset)="resetField('accountNumber')">
                <input id="accountNumber"
                        #accountNumberInput
                        autocomplete="off"
                        cduxInputField
                        formControlName="accountNumber"
                        minlength="4"
                        maxlength="17"
                        type="text"
                        [cduxInputComplete]="form.get('accountNumber').value.length === 17" />
            </cdux-input>
        </ng-container>

        <div class="deposit-button"
                (click)="!pendingDeposit && onDeposit()"
                [class.disabled]="form.invalid || form.get('amount').value <= 0 || pendingDeposit">
            <span class="deposit-button-text" *ngIf="!pendingDeposit">
                {{ replaceAccountInfo ? 'Replace' : 'Deposit Now' }}
            </span>
            <div *ngIf="pendingDeposit" class="gbl-button-loader">
                <div class="gbl-loader-dots">
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                </div>
            </div>
        </div>

        <div class="ts-body toc">By clicking Deposit Now, I agree to the <a class="express-terms-link" (click)="termsClick.emit()">Terms & Conditions</a> of EZMoney</div>
    </div>

</form>
