import {
    Component, Input,
} from '@angular/core';

@Component({
    selector: 'cdux-usersname-balance',
    templateUrl: 'usersname-balance.component.html',
    styleUrls: ['usersname-balance.component.scss'],
})
export class UsersnameBalanceComponent {
    @Input()
    public usersname?: string | null; // Username passing in from caller
    @Input()
    public balance?: number | null; // balance passing in from caller

    constructor() {}
}
