import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { enumFeatureToggle, EventClickAttributeType, EventClickType, FeatureToggleDataService, IdScanService, PlatformService } from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import {EventTrackingService} from '../../../event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-id-scan',
    templateUrl: './id-scan-container.component.html',
    styleUrls: ['./id-scan-container.component.scss']
})

export class IdScanContainerComponent implements OnInit {
    @ViewChild('frontImgInput') frontImageInput: ElementRef;
    @ViewChild('backImgInput') backImageInput: ElementRef;

    @Output() idScanComplete = new EventEmitter<boolean>();
    @Output() closeIdScan: EventEmitter<undefined> = new EventEmitter<undefined>();

    @Input() uuid: string;
    @Input() showCloseButton: boolean;
    @Input() closeOnCancel: boolean;

    public activeElement: ElementRef;
    public formGroup: UntypedFormGroup;
    public encodedFrontImage: string;
    public encodedBackImage: string;
    public processing = false;
    public affiliateId = this._environment.affiliateId;
    public step = 0;
    public eventClickType = EventClickType;
    public frontImageFileName: string;
    public backImageFileName: string;
    public frontImageFileSize: string;
    public backImageFileSize: string;
    public showCameraOptions: boolean;
    public smallGlass: boolean;
    public deviceHasCamera: boolean;
    public phoneEnabled: boolean;

    public readonly max_step = 3;
    public errorState: boolean = false;

    private _acceptedFileTypes = ['image/png', 'image/jpeg'];
    private _animationDuration: number;

    constructor(
        private _environment: ENVIRONMENT,
        private _idScanService: IdScanService,
        private _eventTrackingService: EventTrackingService,
        private _mediaQuery: CduxMediaToggleService,
        private _platformService: PlatformService,
        private _featureToggleService: FeatureToggleDataService) {}

    ngOnInit(): void {
        this.phoneEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this.smallGlass = this._mediaQuery.query('phone');
        this.deviceHasCamera = this._platformService.isNativeApp() || this._platformService.isIOS() || this._platformService.isAndroid();
        this.showCameraOptions = this.deviceHasCamera || this.smallGlass;
        this._animationDuration = this.showCameraOptions ? 0 : 3000;
        this.formGroup = new UntypedFormGroup({
            frontImage: new UntypedFormControl(),
            backImage: new UntypedFormControl()
        });
        // log click event REGISTRATION_PROMPT_SCAN(ID 447)
        this._eventTrackingService.logClickEvent(EventClickType.REGISTRATION_PROMPT_SCAN);
    }

    public incrementStep() {
        if (this.encodedFrontImage && this.encodedBackImage) {
            this.step = this.max_step;
        } else if (this.step < this.max_step) {
            this.step++;
        }
    }

    public onFileDropped($event) {
        if ($event.length) {
            if (this.step === 1) {
                this.activeElement = this.frontImageInput;
                this._processImage($event);
            } else {
                this.activeElement = this.backImageInput;
                this._processImage($event);
            }
        }
    }

    public uploadFile() {
        if (this.step === 1) {
            this.takePhoto(this.frontImageInput);
        } else {
            this.takePhoto(this.backImageInput);
        }
    }

    public takePhoto(element: ElementRef) {
        this.activeElement = element;
        element.nativeElement.click();
    }

    public onPhotoChange($event) {
        this.errorState = false;
        this._processImage($event.target.files);
    }

    public cancel() {
        if (this.closeOnCancel) {
            this.closeIdScan.emit();
        } else {
            this.step = 1;
            this.encodedFrontImage = null;
            this.encodedBackImage = null;
            this.activeElement = null;
            this.formGroup.reset();
        }
    }

    public submit() {
        if (this.processing) {
            return;
        }

        if (this.encodedFrontImage && this.encodedBackImage) {
            this.processing = true;
            const request = {
                uuid: this.uuid,
                frontImageBase64: this._idScanService.stripFileReaderEncoding(this.encodedFrontImage),
                backImageBase64: this._idScanService.stripFileReaderEncoding(this.encodedBackImage),
            };

            this._idScanService.submitIdScan(request).subscribe(result => {
                this._logIdScanResult(result);
                this.idScanComplete.emit(result);
                this.processing = false;
            }, () => {
                this._logIdScanResult(false);
                // on error emit false since an unknown error occurred
                this.idScanComplete.emit(false);
                this.processing = false;
            });
        }
    }

    public setStep(newStep: number) {
        this.step = newStep;
    }

    private _formatFileSize(size) {
        return (size / (1024 * 1024)).toFixed(2) + 'MB';
    }

    private _logIdScanResult(success: boolean) {
        this._eventTrackingService.logClickEvent(EventClickType.REGISTRATION_SCAN_PERFORMED,
            { attrId: EventClickAttributeType.REGISTRATION_SCAN_RESULT, data: success, timestamp: Date.now() });
    }

    private _processImage(files: FileList) {
        this.errorState = false;
        if (files.length > 0 && this._acceptedFileTypes.includes(files[0].type)) {
            this.processing = true;
            this._idScanService.imageToBase64(files[0]).pipe(take(1)).subscribe(encodedImage => {
                if (this.activeElement === this.frontImageInput) {
                    this.encodedFrontImage = encodedImage;
                    this.frontImageFileName = files[0].name;
                    this.frontImageFileSize = this._formatFileSize(files[0].size);
                } else if (this.activeElement === this.backImageInput) {
                    this.encodedBackImage = encodedImage;
                    this.backImageFileName = files[0].name;
                    this.backImageFileSize = this._formatFileSize(files[0].size);
                }
                setTimeout(() => {
                    this.incrementStep();
                    this.processing = false;
                }, this._animationDuration)
            }, error => {
                this.processing = false;
            });
        } else {
            this.errorState = true;
        }
    }
}
