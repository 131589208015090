import { TemplatePortal } from "@angular/cdk/portal";
import { Component, Input, ViewChild, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

import { enumFeatureToggle, EventClickType, FeatureToggleDataService } from "@cdux/ng-common";
import { CduxMediaToggleService, enumMediaTarget, ModalRef, ModalService, DropupModalConfig, DropupPrebuiltComponent } from "@cdux/ng-platform/web";
import { EventTrackingService } from "app/shared/event-tracking/services/event-tracking.service";

export enum enumWagerInfoType {
    WAGER_TYPES,
    WAGER_MODIFIERS
}

@Component({
    selector: 'cdux-wager-type-info',
    templateUrl: './wager-type-info.component.html',
    styleUrls: ['./wager-type-info.component.scss']
})
export class WagerTypeInfoComponent { 
    public enumWagerInfoType = enumWagerInfoType;

    @Input()
    public set infoType(infoType: enumWagerInfoType) {
        this._infoType = infoType;

        switch (this.infoType) {
            case enumWagerInfoType.WAGER_TYPES:
                this.headerText = 'WAGER TYPE INFORMATION';
                break;
            case enumWagerInfoType.WAGER_MODIFIERS:
                this.headerText = 'WAGER MODIFIER INFORMATION';
                break;
        }
    }
    public get infoType(): enumWagerInfoType {
        return this._infoType;
    }
    private _infoType: enumWagerInfoType;

    public headerText: string;
    @ViewChild('learnMorePortal')
    private _learnMorePortal: TemplatePortal<any>;
    @ViewChild('learnMoreModal')
    private _learnMoreModal: TemplateRef<any>;
    private _modalRef: ModalRef<any>;

    public isPowerBoxEnabled

    constructor (
        private _mediaService: CduxMediaToggleService,
        private _modalService: ModalService,
        private _eventTrackingService: EventTrackingService,
        private _featureToggleService: FeatureToggleDataService,
    ) { }

    public openLearnMore(): void {
        const closingSubject = new Subject<void>(); // proxy to modal
        this._mediaService.registerQuery(enumMediaTarget.PHONE).pipe(
            distinctUntilChanged(), takeUntil(closingSubject)
        ).subscribe((isPhone) => {
            if (this._modalRef) {
                // close on transition
                this._modalRef.close();
            } else {
                this._modalRef = isPhone ? this._openDropup() : this._openModal();
                this._modalRef.afterClosed.subscribe(() => {
                    closingSubject.next();
                    this._modalRef = null;
                });
            }
        });

        this.logClick(this.infoType);
    }

    public logClick(logClick: enumWagerInfoType): void {
        switch (logClick) {
            case enumWagerInfoType.WAGER_TYPES:
                this._eventTrackingService.logClickEvent(EventClickType.LEARN_MORE_BET_TYPE);
                break;
            case enumWagerInfoType.WAGER_MODIFIERS:
                this._eventTrackingService.logClickEvent(EventClickType.LEARN_MORE_BET_MODIFIER);
                this.isPowerBoxEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.POWER_BOX);
                break;
        }
    }

    private _openModal(): ModalRef<any> {
        const modalRef = this._modalService.open(this._learnMoreModal, {
            hasBackdrop: true,
            overflow: 'hidden',
            padding: '0'
        });

        return modalRef;
    }

    private _openDropup(): ModalRef<any> {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.maxWidth = '100vw';
        modalConfig.maxHeight = '100vh';
        modalConfig.context = {
            close: () => this.closeLearnMore(),
            headerText: this.headerText,
            component: this._learnMorePortal
        };

        const modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        modalRef.componentInstance.close.pipe(
            takeUntil(modalRef.afterClosed)
        ).subscribe(() =>
            modalRef.close()
        );

        return modalRef;
    }

    public closeLearnMore(): void {
        this._modalRef?.close();
    }
}