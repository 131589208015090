import { Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import {
    ConfigurationDataService,
    enumConfigurationStacks,
    FeatureToggleDataService,
    IVideoStreamNeulion,
    VideoAngles,
    JwtSessionService,
} from '@cdux/ng-common';

import { IVideoFeed, VideoFeedError } from '../video-feed.interface';
import { VideoProviderService } from '../video-provider.service';
import { VideoService } from 'app/wagering/video/services/video.service';

export interface INeulionConfig {
    gaa: string;
    htmlPlayerUrl: string;
    locQos: string;
    locResource: string;
    noFlash?: boolean;
    nlHTML5?: boolean;
    nosp: boolean;
    server: string;
    site: string;
}
export interface INeulionPublishPointParams {
    token: string;
}
export interface INeulionLibraryVideoData {
    id: string;
    name: string;
    type: string;
    isLive: boolean;
    isIDExternal: boolean;
    publishPointParams: INeulionPublishPointParams;
}
export interface INeulionLibraryConfig {
    containerId: string;
    site: string;
    server: string;
    locResource: string;
    locQos: string;
    nosp: boolean;
    gaa: string;
    uid: string;
    readyCallback: (id) => void;
    statusCallback: (id, status) => void;
    unsupportedCallback: (id, error) => void;
    noFlash: boolean;
    nlHTML5: boolean;
    useCustomControls: boolean;
    mute?: boolean;
}
export enum INeulionBossConfigOption {
    GAA = 'neulion_gaa',
    HTML_PLAYER_URL = 'neulion_html_player_url',
    LOC_QOS = 'neulion_loc_qos',
    LOC_RESOURCE = 'neulion_loc_resource',
    NL_HTML5 = 'neulion_nl_html5',
    NO_FLASH = 'neulion_no_flash',
    NOSP = 'neulion_nosp',
    SERVER = 'neulion_server',
    SITE = 'neulion_site',
}

@Component({
    selector: 'cdux-neulion-video-feed',
    template: `<div id="nlPlayerContainer"></div>`
})
export class NeulionVideoFeedComponent implements OnInit, IVideoFeed {
    public static CONTAINER_ID = 'nlPlayerContainer';
    public muted: boolean;

    private config: INeulionConfig;
    private window: any = (<any> window);

    private errorSubject: Subject<VideoFeedError> = new Subject<VideoFeedError>();
    public onError: Observable<VideoFeedError> = this.errorSubject.asObservable();

    constructor(
        private videoProviderService: VideoProviderService,
        private sessionService: JwtSessionService,
        private featureToggleService: FeatureToggleDataService,
        private configurationDataService: ConfigurationDataService,
        private videoService: VideoService
    ) {
        this.muted = this.videoService.getMuted();
    }

    ngOnInit() {
        // a false feature toggle will avoid injecting the script and throw an unavailable error
        if (this.featureToggleService.isFeatureToggleOn('NEULION_VIDEO')) {
            const configKeys = [
                INeulionBossConfigOption.HTML_PLAYER_URL,
                INeulionBossConfigOption.GAA,
                INeulionBossConfigOption.LOC_QOS,
                INeulionBossConfigOption.LOC_RESOURCE,
                INeulionBossConfigOption.SERVER,
                INeulionBossConfigOption.SITE,
                INeulionBossConfigOption.NOSP,
                INeulionBossConfigOption.NO_FLASH,
                INeulionBossConfigOption.NL_HTML5
            ];

            this.configurationDataService.getConfiguration(enumConfigurationStacks.TUX, configKeys).subscribe(
                (configs) => {
                    this.config = {
                        htmlPlayerUrl: configs[INeulionBossConfigOption.HTML_PLAYER_URL],
                        gaa: configs[INeulionBossConfigOption.GAA],
                        locQos: configs[INeulionBossConfigOption.LOC_QOS],
                        locResource: configs[INeulionBossConfigOption.LOC_RESOURCE],
                        server: configs[INeulionBossConfigOption.SERVER],
                        site: configs[INeulionBossConfigOption.SITE],
                        nosp: configs[INeulionBossConfigOption.NOSP] === 'true',
                        noFlash: configs[INeulionBossConfigOption.NO_FLASH] === 'true',
                        nlHTML5: configs[INeulionBossConfigOption.NL_HTML5] === 'true'
                    };

                    if (this.isLibraryAvailable()) {
                        this.renderPlayer();
                    } else {
                        if (this.config.htmlPlayerUrl) {
                            this.loadLibrary(this.config.htmlPlayerUrl)
                                .then(() => {
                                    this.renderPlayer();
                                });
                        } else {
                            this.handleError();
                        }
                    }
                },
                () => this.errorSubject.next(VideoFeedError.VIDEO_UNAVAILABLE)
            );
        } else {
            this.errorSubject.next(VideoFeedError.VIDEO_UNAVAILABLE);
        }
    }

    private renderPlayer() {
        const videoData = this.buildRenderVideoData();

        if (videoData && videoData.id && videoData.publishPointParams && videoData.publishPointParams.token) {
            if (this.config.nlHTML5) {
                this.window.nlRenderPlayer(NeulionVideoFeedComponent.CONTAINER_ID, this.buildRenderConfig(), videoData);
            } else {
                this.window.nlRenderPlayer(this.buildRenderConfig(), videoData);
            }
        } else {
            this.handleError();
        }
    }

    /* TODO: This is unsafe, could be exploited by MITM attack. */
    private loadLibrary(url: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            if (this.isLibraryAvailable()) {
                return resolve();
            }

            const el = document.createElement('script');
            el.src = url;
            el.type = 'text/javascript';
            document.getElementsByTagName('head')[0].appendChild(el);

            // TODO: set a timeout theshold
            const interval = setInterval(() => {
                if (this.isLibraryAvailable()) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100);
        });
    }

    private isLibraryAvailable(): boolean {
        return typeof this.window.nlRenderPlayer === 'function';
    }

    private handleError(type: VideoFeedError = VideoFeedError.VIDEO_UNAVAILABLE) {
        this.errorSubject.next(type);
    }

    private buildRenderConfig(): INeulionLibraryConfig {
        const config: INeulionLibraryConfig = {
            containerId: NeulionVideoFeedComponent.CONTAINER_ID,
            site: this.config.site,
            server: this.config.server,
            locResource: this.config.locResource,
            locQos: this.config.locQos,
            nosp: this.config.nosp,
            gaa: this.config.gaa,
            noFlash: this.config.noFlash,
            nlHTML5: this.config.nlHTML5,
            uid: this.sessionService.getUserInfo().camId,
            useCustomControls: true, // TODO - If we ever want to not use the controls provided by NeuLion, then this
                                     // should move to BOSS config. As of now, I see no reason this would be different
                                     // across environments or players.
            // As part of the switch to HTML5, we are using a different nlplayer js file that can support multiple vids
            // on a given page. This multi player library sends the element id of the player as the first param to these
            // callback functions. Unfortunately, that means we will need to check what kind of player we are using to
            // know exactly what the first param is going to be. The easiest thing to check would be this.config.nlHTML5,
            // which will be true for HTML5 and false for Flash.
            readyCallback: (id) => {
                const iframe = document.querySelector('#' + id);
                const iframeContent = iframe['contentDocument'] || iframe['contentWindow'].document;
                const videoElement = iframeContent.querySelector('#' + id + ' video');
                videoElement.addEventListener('volumechange', (change) => {
                    this.videoService.setMuted(change.target['muted']);
                });
            },
            statusCallback: (_id, _status) => {},
            unsupportedCallback: (_id, _error) => {
                // The call to the unsupportedCallback goes outside the Angular change detection process.
                // If you are updating UI based on this function, be sure to force an Angular change detect.
                console.log('NeuLion Video Feed Component: unsupported video');
                // When we were originally only using Flash NeuLion video, we assumed that if the unsupported callback
                // was triggered that flash wasn't enabled. With the addition of HTML5 video, there are 2 situations
                // that can trigger this callback:
                // 1. If the browser doesn't support HTML5
                // 2. If the browser supports HTML5 video but can't play video because of a bad/invalid stream or a valid
                //    stream but encoding/format is unsupported by the HTML5 player
                // For now, if we reach this callback when we are using HTML5, we will just provide the video unavailable
                // error message. In the future, we could do our own checking for browser HTML5 support, and if supported,
                // then we know we have a wonky stream so we can be a little better about error display.
                if (this.config.nlHTML5) {
                    this.handleError(VideoFeedError.VIDEO_UNAVAILABLE);
                } else {
                    this.handleError(VideoFeedError.FLASH_UNAVAILABLE);
                }
            }
        };

        // For whatever reason the NeuLion player doesn't seem to care if the mute flag is true or false -- in both cases
        // if it's present it will mute the player. So only add the property when our mute flag is true.
        // Additionally, the nlmuted storage item will override what we pass in. So if someone mutes the NeuLion player (nlmuted = true)
        // and then loads a different player and unmutes it there, when we load the NeuLion player again we won't add a mute
        // property so the nlmuted storage key will be looked at instead and keep the player muted. So just delete it when we load the NeuLion player.
        localStorage.removeItem('nlmuted');
        if (this.muted) {
            config.mute = this.muted;
        }

        return config;
    }

    private buildRenderVideoData(): INeulionLibraryVideoData {
        return (JSON.parse(JSON.stringify(this.videoProviderService.currentStream)) as IVideoStreamNeulion).video;
    }

    public start() {}
    public stop() {}
    public pause() {}
    public resize() {}
    public getAngle(): VideoAngles { return null; }
    public getAngles(): Observable<VideoAngles[]> { return of([]); }
    public setAngle(angle: VideoAngles): void { return; }
}
