import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LiveVideoFeedFactory } from './live-video-feed.factory';
import { NeulionVideoFeedComponent } from './neulion/neulion-video-feed.component';
import { RCNFlashVideoFeedComponent } from './rcn/rcn-flash-video-feed.component';
import { RCNHL2SVideoFeedComponent } from './rcn/rcn-hl2s-video-feed.component';
import { RCNHTMLReplayFeedComponent } from './rcn/rcn-html-replay-feed.component';
import { RCNHTMLVideoFeedComponent } from './rcn/rcn-html-video-feed.component';
import { RCNReplayStreamSource } from './rcn/rcn-stream-sources/rcn-replay-stream-source.service';
import { RCNVideoStreamSource } from './rcn/rcn-stream-sources/rcn-video-stream-source.service';
import { VideoProviderService, ReplayProviderService } from './video-provider.service';
import { ReplayBusinessService } from '../services/replay-business.service';
import { RCNHTTPReplayFeedComponent } from './rcn/rcn-http-replay-feed.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        NeulionVideoFeedComponent,
        RCNFlashVideoFeedComponent,
        RCNHL2SVideoFeedComponent,
        RCNHTMLReplayFeedComponent,
        RCNHTTPReplayFeedComponent,
        RCNHTMLVideoFeedComponent,
    ],
    providers: [
        LiveVideoFeedFactory,
        RCNReplayStreamSource,
        RCNVideoStreamSource,
        ReplayProviderService,
        VideoProviderService,
        ReplayBusinessService
    ]
})
export class VideoFeedModule { }
