import { Injectable, Directive } from '@angular/core';
import { CduxAbstractSidebarComponent } from './cdux-sidebar-component.class';
import { ISidebarHeaderDefinition } from './interfaces/sidebar-portal-component.interface';

/* istanbul ignore next */
@Directive()
@Injectable()
export abstract class CduxSidebarContentComponent extends CduxAbstractSidebarComponent {

    /**
     * This method takes a list of inputs and returns
     * an ISidebarPortalComponent, suitable for passing to the sidebar service to load
     * the component into the sidebar header.
     *
     * If your sidebar component contains a header, you MUST override this method
     * (typescript does not allow abstract static methods, or else this would be defined as such)
     * and provide your own implementation, so that you can provide
     * a proper ComponentType (the header component) in the returned object.
     *
     * It is HIGHLY recommended that your  override of this method in extended class specifies
     * specific parameters for each input that your component requires (instead of a single, generic ..tokens interator).
     * This will help implementors to know the number and type of inputs that your component requires
     * when implementing it.
     *
     * @returns {ISidebarHeaderDefinition}
     */
    public static getHeaderComponent(...tokens): ISidebarHeaderDefinition {
        return null;
    }
}
