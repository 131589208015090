import { Injectable, EventEmitter, ComponentRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { DropupModalConfig, ModalRef, DropupPrebuiltComponent, CduxMediaToggleService, ModalService } from '@cdux/ng-platform/web';
import {
    TranslateService,
    enumStatus,
    enumToggleState
} from '@cdux/ng-common';

import { ISidebarPortalOptions, ISidebarPortalState } from './interfaces/sidebar-portal-state.interface';
import { CduxSidebarContentComponent } from './cdux-sidebar-content-component.class';
import { ISidebarHeaderComponent, ISidebarPortalComponent, ISidebarTitleHeaderConfig, ISidebarHeaderDefinition } from './interfaces/sidebar-portal-component.interface';
import { HistoryStack } from './sidebar-history.class';
import { SIDEBAR_LOADERS } from './enums/loader.enums';
import { ISidebarLoaderProperties, ISidebarLoaderState } from './interfaces/sidebar-loader.interface';
import { CduxSidebarTitleHeaderComponent } from './components/cdux-sidebar-title-header.component';

@Injectable()
export class SidebarService {
    public static readonly OPT_PRESERVE_ERROR = true;

    // Collection of Portals in history
    private _history: HistoryStack<ISidebarPortalState>;

    // Whether or not the Sidenav is open
    private _open = false;

    // Whether or not you can close the sidebar by clicking off of it on another area of the screen
    // This takes precedence over what a given component specifies when true. If it's false, it falls to the component
    // to decide
    // true = clicking off the sidebar does nothing (sidebar stays open)
    // false = clicking off the sidebar closes it
    private _backdropClickDisabled = false;

    public get backdropClickDisabled() {
        return this._backdropClickDisabled;
    }

    public headerMessage: BehaviorSubject<string> = new BehaviorSubject('');
    public onAttached: Subject<ComponentRef<any>> = new Subject();

    // Emitter for Sidenav to subscribe to for receiving Portal to display
    public onPortalStateChanged: ReplaySubject<ISidebarPortalState> = new ReplaySubject(1);

    // Emits when something calls showOverlay or hideOverlay
    public onOverlayStateChanged: ReplaySubject<ISidebarLoaderState> = new ReplaySubject(1);

    // Emits when the button in the sidebar header is clicked.
    // Allows child or parent components to override click behaviour.
    public onHeaderButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Returns whether or not the Sidenav is open
    get isOpen(): boolean {
        return this._open;
    }

    private static setPortalOptions(options: ISidebarPortalOptions): ISidebarPortalOptions {
        options.clearHistory = (options.clearHistory === true);
        options.disableBackdropClick = (options.disableBackdropClick !== null && options.disableBackdropClick !== undefined) ? options.disableBackdropClick : false;
        options.hideHeader = (options.hideHeader === true);
        options.forceShowHeader = (options.forceShowHeader === true);
        options.preserveError = ('preserveError' in options && options.preserveError === SidebarService.OPT_PRESERVE_ERROR);
        return options;
    }


    constructor(
        private _translateService: TranslateService,
        private _mediaService: CduxMediaToggleService,
        private _modalService: ModalService
    ) {
        this._history = new HistoryStack<ISidebarPortalState>();
        this._open = false;
    }

    public getPortalDefinitions(content: ISidebarPortalComponent, header: ISidebarHeaderDefinition = null, options: ISidebarPortalOptions = {}): ISidebarPortalState {
        options = SidebarService.setPortalOptions(options);

        if (options.clearHistory) {
            this._history.clearItems();
        }

        let builtHeader: ISidebarHeaderComponent = null;

        if (header) {
            if (this._instanceOfISidebarTitleHeaderConfig(header)) {
                let title = header.title

                if (header.translateKey) {
                    if (header.translateParameters) {
                        title = this._translateService.translate(header.translateKey, header.translateLanguage, ...header.translateParameters);
                    } else {
                        title = this._translateService.translate(header.translateKey, header.translateLanguage);
                    }
                }
                builtHeader = CduxSidebarTitleHeaderComponent.getSidebarComponent(title);
                builtHeader.properties.eventClickType = header.eventClickType;
            } else {
                builtHeader = header;
            }
        }

        this._open = true;
        return this._createPortalState(content, builtHeader , options);
    }

    public loadComponent(
        content: ISidebarPortalComponent,
        header: ISidebarHeaderDefinition = null,
        options: ISidebarPortalOptions = {},
    ): Promise<ComponentRef<any>> {
        const portalState = this.getPortalDefinitions(content, header, options);

        this._history.addItem(portalState);
        this.open(options.preserveError);
        return this.onAttached.pipe(
            take(1)
        ).toPromise();
    }

    public loadDropupComponent(content: ISidebarPortalComponent, mobileOnly: boolean = true, title: string = 'Tooltip'): void {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.maxWidth = '100vw';
        modalConfig.maxHeight = '100vh';
        modalConfig.context = {
            headerText: title,
            component: content.component,
            bodyClass: 'dropup-tooltip',
            attachedCallback: (ref: any) => {ref.instance.setProperties(content.properties)}
        };
        const modal: ModalRef<DropupPrebuiltComponent<ISidebarPortalComponent>> = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        // Close the Dropup if the window expands
        let mediaSub: Subscription;
        if (mobileOnly) {
            mediaSub = this._mediaService.registerQuery('phone').subscribe((val: boolean) => {
                if (!val) {
                    modal.close();
                }
            });
        }
        // Unsubscribe from the media query after the Dropup closes
        modal.afterClosed.subscribe(() => {
            if (mediaSub) {
                mediaSub.unsubscribe();
            }
        });
        // Close the Dropup when the close button is clicked
        modal.componentInstance.close.subscribe(() => {
            modal.close();
        });
    }

    public showLoadingOverlay(loaderType: SIDEBAR_LOADERS) {
        const loaderState: ISidebarLoaderState = {
            loader: loaderType,
            loaderState: enumToggleState.ON,
            options: {}
        }

        this.onOverlayStateChanged.next(loaderState);
    }

    public hideLoadingOverlay(loaderType: SIDEBAR_LOADERS, properties: ISidebarLoaderProperties = {}, onOverlayClosed?: () => void) {

        properties.status = (properties.status) ? properties.status : enumStatus.VOID;
        properties.delayMs = (properties.delayMs) ? properties.delayMs : 0;

        const loaderState: ISidebarLoaderState = {
            loader: loaderType,
            loaderState: enumToggleState.OFF,
            options: properties,
            onLoaderClosed: onOverlayClosed
        };

        this.onOverlayStateChanged.next(loaderState);
    }

    public headerButtonClicked(isGoingBack: boolean) {
        this.onHeaderButtonClicked.emit(isGoingBack);
    }

    public goBack() {
        if (this.canGoBack()) {
            const portalState: ISidebarPortalState = this._history.moveToPreviousItem();
            this._addBackFlagToPortalState(portalState, true);
            this._updatePortalState(portalState);
        } else {
            this.close(true);
        }
    }

    /**
     * Used to open the Sidenav if a Portal is loaded into it.
     */
    public open(preserveError = !SidebarService.OPT_PRESERVE_ERROR): void {
        if (!this._history.hasItems()) {
            // A Component must be loaded into the Sidenav before it is Opened
            return;
        }
        if (preserveError !== SidebarService.OPT_PRESERVE_ERROR) {
            this.headerMessage.next('');
        }

        this._open = true;
        this._updatePortalState(this._history.getCurrentItem());
    }

    /**
     * Used to close the Sidenav and optionally reset the PortalHost
     * @param unload - Boolean that dictates whether or not the PortalHost is reset
     */
    public close(unload: boolean = true): void {
        this._open = false;
        this._backdropClickDisabled = false;
        this._updatePortalState( this._createPortalState(null));

        if (unload) {
            this._unloadComponents();
        }
    }

    /**
     * Toggles the state of the Sidenav between Open and Closed
     */
    public toggle(): void {
        (this._open) ? this.close(false) : this.open();
    }

    /**
     * Checks if the passed in component is the same type as the root component
     * in the tree.
     *
     * @param component - Component to be checked for match
     */
    public isRootComponent(component: ComponentType<CduxSidebarContentComponent>): boolean {
        const rootItem: ISidebarPortalState = this._history.getRootItem();
        return (rootItem && rootItem.portalComponent && rootItem.portalComponent.component.name === component.name)
    }

    /**
     * Checks if the passed in component is a parent of the currently loaded component
     *
     * @param {ISidebarPortalState} currentState
     */
    public hasParentComponent(component: ComponentType<CduxSidebarContentComponent>): boolean {
        let item: ISidebarPortalState;
        for (let i = this._history.currentPosition; i >= 0; i--) {
            item = this._history.getItem(i);
            if (item && item.portalComponent && item.portalComponent.component.name === component.name) {
                return true;
            }
        }

        return false;
    }

    public disableBackdropClick() {
        this._backdropClickDisabled = true;
    }

    public enableBackdropClick() {
        this._backdropClickDisabled = false;
    }

    private _instanceOfISidebarTitleHeaderConfig(config: ISidebarHeaderDefinition): config is ISidebarTitleHeaderConfig {
        return 'title' in config || 'translateKey' in config;
    }

    private _updatePortalState(currentState: ISidebarPortalState) {
        // Make sure that the open state is set properly
        currentState.isOpen = this._open;

        this.onPortalStateChanged.next(currentState);
    }

    // Whether or not Navigation can progress backwards
    public canGoBack(): boolean {
        return (!this._history.isAtRootItem());
    }

    // Whether or not Navigation can progress forwards
    public canGoForward(): boolean {
        return (!this._history.isAtLastItem());
    }

    private _createPortalState(portal: ISidebarPortalComponent, header: ISidebarHeaderComponent = null, options: ISidebarPortalOptions = {}): ISidebarPortalState {
        options = SidebarService.setPortalOptions(options);

        const state: ISidebarPortalState = {
            portalComponent: portal,
            headerComponent: header,
            isOpen: this._open,
            portalOptions: options,
        };

        return state;
    }

    private _addBackFlagToPortalState(portalState: ISidebarPortalState, isGoingBack: boolean) {
        this._addBackFlagToComponent(portalState.portalComponent, isGoingBack);
        this._addBackFlagToComponent(portalState.headerComponent, isGoingBack);
    }

    private _addBackFlagToComponent(component: ISidebarPortalComponent | ISidebarHeaderComponent, isGoingBack: boolean) {
        if (component) {
            if (!component.properties) {
               component.properties = {};
            }
            component.properties.isReturningFromChild = isGoingBack;
        }
    }

    /**
     * Resets the Portal Host to the Default State
     */
    private _unloadComponents() {
        this._history.clearItems();
    }
}
