import { OnDestroy, OnInit, Component } from '@angular/core';
import { LoadingService, LoadingDotsComponent, DatepickerService } from '@cdux/ng-fragments';

import { CduxSidebarContentComponent } from '../../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../../../../menu-items/enums/menu-items.enum';
import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { TaxDocumentsBusinessService } from '../../services/tax-documents.business.service';
import { IW2GSummaryDataResponse, IW2GTransactionListResponseContent, IW2GTransaction} from '@cdux/ng-common';

@Component({
    selector: 'cdux-taxable-event-list',
    templateUrl: './taxable-event-list.component.html',
    styleUrls: ['./taxable-event-list.component.scss']
})
export class TaxableEventListComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    public chosenYear: number;
    public LOADING_OVERLAY_NAME: string = 'taxEventListOverlay';
    public summaryResponse: IW2GTransactionListResponseContent;
    public summaryTransactions = [];
    public hasLoaded: boolean = false;
    private _subscriptions: Subscription[] = [];
    public loadingDotsComponent = LoadingDotsComponent;

    public static getSidebarComponent(year: number): ISidebarPortalComponent {
        const inputTokens: Map<string, any> = new Map();
        inputTokens.set('chosen-year', year);
        return {
            component: TaxableEventListComponent,
            properties: {
                inputs: inputTokens,
                navTarget: MenuItemsEnum.PROFILE
            }
        }
    }

    public static getHeaderComponent(year: number): ISidebarTitleHeaderConfig {
        return {
            title: year + ' W-2G'
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {
        // TODO: Do we default to current year or send back to year list if no year is included?
        this.chosenYear = properties.inputs.has('chosen-year') ? properties.inputs.get('chosen-year') : null;
    }

    constructor (
        private _loadingService: LoadingService,
        private _taxDocumentService: TaxDocumentsBusinessService,
        private _dateService: DatepickerService
    ) {
        super();
    }

    ngOnInit() {
        this._loadingService.register(this.LOADING_OVERLAY_NAME);
        this.getEventList();
    }

    ngOnDestroy() {
        if (this._subscriptions && this._subscriptions.length > 1) {
            this._subscriptions.forEach((sub) => sub.unsubscribe());
        }
    }

    private getEventList(): void {
        this._subscriptions.push(this._taxDocumentService.getYearSummary(this.chosenYear).pipe(
            take(1),
            map((r: IW2GSummaryDataResponse) => {
                this.summaryResponse = r.responseContent;
                this.groupTransactions(r.transactionList.transactions);
                this.hasLoaded = true;
                this._loadingService.resolve(this.LOADING_OVERLAY_NAME, 100, 'success');
            })
        ).subscribe());
    }

    private groupTransactions(transactions: IW2GTransaction[]): void {
        const groupedTransactions = {}

        // Break transactions up in to groups by month
        transactions.map((transaction) => {
            const monthNum = new Date(transaction.winDate).getMonth();
            const transactionMonth = this._dateService.getMonthName(monthNum);
            const monthKey = transactionMonth + '--' + monthNum;
            if (groupedTransactions[monthKey]) {
                groupedTransactions[monthKey].push(transaction);
            } else {
                groupedTransactions[monthKey] = [transaction];
            }
        });

        // Put grouped transactions into sortable array for display
        Object.keys(groupedTransactions).forEach((key) => {
            const keyArray = key.split('--');
            this.summaryTransactions.push({
                monthIndex: keyArray[1],
                monthName: keyArray[0],
                transactions: groupedTransactions[key].sort(
                    (a, b) => a.winDate > b.winDate ? 1 : -1
                )
            });
        });

        this.summaryTransactions.sort((a, b) => {
            return a.monthIndex - b.monthIndex
        });
    }

    public printSummary(): void {
        const fileName =  '/w2g_summary_' + this.chosenYear;
        window.open('/adw/w2g/statement/' + this.summaryResponse.summaryToken + fileName);
    }

    public printAll(): void {
        const fileName = '/w2g_all_' + this.chosenYear;
        window.open('/adw/w2g/forms/year/' + this.summaryResponse.summaryToken + fileName);
    }
}
