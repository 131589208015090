import { WagerEvent, WageringViewEvents } from '../interfaces';
import { EventEmitter, Input, OnDestroy, Output, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WagerEventTypeEnum } from '../enums';
import { cduxAccumulateWageringEvents } from '../operators/accumulate-wagering-events.operator';

/**
 * PURPOSE:
 * This Abstract Component is intended to establish a common method of coordinating the state
 * of a wagering view, without having to infer the state or listen for events from random pieces
 * of data. This is NOT supposed to be used to convey data beyond small messages crucial to the
 * UI (i.e. error information). It is primarily intended for turning on/off certain widgets in
 * special circumstances, kicking off animations, and showing error messages.
 *
 * This Abstract Component relies on two things:
 * - The main Wagering View container receiving the `outboundEvents` from its children will
 *      then pass them back down to its children. This ensures that all the events are shared
 *      with the rest of the Wagering View, but aren't being emitted globally.
 * - There is an unbroken chain of parent and children components emitting up
 *      and passing down events.
 */
@Directive()
export class AbstractWageringViewEventComponent implements WageringViewEvents, OnDestroy {
    /**
     * Sends events to the parent component.
     */
    @Output() public outboundEvents: EventEmitter<WagerEvent> = new EventEmitter<WagerEvent>();

    /**
     * Receives events from the parent component.
     * This serves to ensure this.inboundEvents is IMMEDIATELY available for subscription.
     * It will automatically listen to the current inbound event observable.
     *
     * @param inboundEventObservable
     */
    @Input() public set inboundEvents(inboundEventObservable: Observable<WagerEvent>) {
        this._killEvents.next();
        if (inboundEventObservable) {
            inboundEventObservable
                .pipe(
                    takeUntil(this._killEvents)
                )
                .subscribe({
                    next: value => this._inboundEvents.next(value),
                    error: err => this._inboundEvents.error(err),
                    complete: () => this._inboundEvents.complete()
                });
        }
    }
    public get inboundEvents(): Observable<WagerEvent> {
        return this._inboundEvents.asObservable();
    }

    /**
     * Allows viewing the currently accumulated events of the wagering interface.
     *
     * The rules for event accumulation are as follows:
     *  - Any event without an `invalidatedBy` property will exist for only a single emission.
     *  - Any event with a populated `invalidatedBy` property will persist in the accumulated events
     *      until an event occurs which matches one of those listed in the `invalidatedBy` array.
     *
     * Accumulated events progress in the following manner:
     *
     * Initial Accumulated Events = {}
     *
     * Emission 1:
     *                        // Ephemeral, since it lacks an `invalidatedBy` property.
     * Event Emitted        - {type: WAGER_INVALID, message: '...'}
     * Accumulated Events   - {WAGER_INVALID: {type: WAGER_INVALID, message: '...'}}
     *
     *
     * Emission 2:
     *                        // Ephemeral, since it lacks an `invalidatedBy` property.
     * Event Emitted        - {type: RACE_NAVIGATION}
     * Accumulated Events   - {RACE_NAVIGATION: {type: RACE_NAVIGATION}}
     *
     *
     * Emission 3:
     *                        // Persists until an event is seen matching with a type matching one of those listed in `invalidatedBy`
     * Event Emitted        - {type: WAGER_SUBMISSION_PROCESSING, invalidatedBy: [WAGER_SUBMISSION_ERROR, WAGER_SUBMISSION_SUCCESS]}
     * Accumulated Events   - {
     *                           WAGER_SUBMISSION_PROCESSING: {
     *                             type: WAGER_SUBMISSION_PROCESSING,
     *                             invalidatedBy: [WAGER_SUBMISSION_ERROR, WAGER_SUBMISSION_SUCCESS]
     *                           }
     *                        }
     *
     *
     * Emission 4:
     *                        // Ephemeral, since it lacks an `invalidatedBy` property.
     * Event Emitted        - {type: WAGER_INVALID}
     * Accumulated Events   - {
     *                           WAGER_SUBMISSION_PROCESSING: {
     *                             type: WAGER_SUBMISSION_PROCESSING,
     *                             invalidatedBy: [WAGER_SUBMISSION_ERROR, WAGER_SUBMISSION_SUCCESS]
     *                           },
     *                           RACE_NAVIGATION: {type: RACE_NAVIGATION}
     *                        }
     *
     *
     * Emission 5:
     *                        // Ephemeral, since it lacks an `invalidatedBy` property.
     * Event Emitted        - {type: WAGER_SUBMISSION_SUCCESS}
     * Accumulated Events   - {WAGER_SUBMISSION_SUCCESS: {type: WAGER_SUBMISSION_SUCCESS}}
     */
    public get accumulatedEvents(): Observable<Map<WagerEventTypeEnum, WagerEvent>> {
        /**
         * NOTE: The operator applied here is pretty simple but, in the unlikely event that we
         * experience a performance hit from it, it would be worth moving this process to the
         * component containing all of the coordinated widgets. That being said, its location
         * here results in less developer overhead in setting up the events, and the easier
         * something is to use, the more likely it is to be adopted and leveraged.
         */
        return this._inboundEvents.asObservable()
            .pipe(
                cduxAccumulateWageringEvents()
            );
    }

    private _inboundEvents: Subject<WagerEvent> = new Subject<WagerEvent>();
    private _killEvents: Subject<undefined> = new Subject<undefined>();

    /**
     * Does cleanup.
     */
    ngOnDestroy(): void {
        this._inboundEvents.complete();
        this._killEvents.next();
        this._killEvents.complete();
    }
}
