import { Directive, HostListener, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Directive({ selector: '[cduxActivateLeg]' })
export class CduxActivateLegDirective {

    /**
     * Activate on a hover that lasts this number of milliseconds.
     *
     * @type {number}
     */
    @Input() hoverLag = 200;

    @Input() noHover = false;

    @Input() isActive = false;

    @Output()
    public activateLeg: EventEmitter<number> = new EventEmitter<number>();

    private _timer: any;

    @HostListener('mouseover') onMouseOver() {
        if (!this.noHover && !this._timer && !this.isActive) {
            this._timer = setTimeout(() => {
                this._activateLeg();
            }, this.hoverLag);
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = undefined;
        }
    }

    @HostListener('touchend') onTouchEnd() {
        this._activateLeg();
    }

    @HostListener('click') onClick() {
        this._activateLeg();
    }

    constructor(private el: ElementRef ) { }

    private _activateLeg() {
        this.activateLeg.emit(this.el.nativeElement.getAttribute('data-leg-number'));
    }

}
