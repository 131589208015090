import { Component } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    TranslatePipe,
    enumStatus,
    JwtSessionService,
} from '@cdux/ng-common';
import { ILoadingTemplateConfig } from '@cdux/ng-fragments';

import { SsnCollectionComponentBase } from './ssn-collection-base.component';
import { SsnCollectionService } from './services/ssn-collection.service';
import { CduxAbstractSidebarComponent } from '../sidebar/cdux-sidebar-component.class';
import {
    ISidebarComponentProperties, ISidebarHeaderComponent,
    ISidebarPortalComponent
} from '../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from '../sidebar/enums/loader.enums';
import { ISidebarLoaderProperties } from '../sidebar/interfaces/sidebar-loader.interface';

@Component({
    selector: 'cdux-ssn-collection-sidebar-header',
    templateUrl: './ssn-collection-sidebar-header.component.html',
    styleUrls: ['./ssn-collection-sidebar.component.scss']
})
export class SsnCollectionSidebarHeaderComponent extends CduxAbstractSidebarComponent {
    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: SsnCollectionSidebarHeaderComponent
        };
    }
    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */
}


const WAGER_AMOUNT_TOKEN = 'wager-amount-token';


@Component({
    selector: 'cdux-ssn-collection-sidebar',
    templateUrl: './ssn-collection-sidebar.component.html',
    styleUrls: ['./ssn-collection-sidebar.component.scss']
})
export class SsnCollectionSidebarComponent extends SsnCollectionComponentBase {
    private readonly LOADING_OVERLAY_TYPE: SIDEBAR_LOADERS = SIDEBAR_LOADERS.SPINNING_LOADER;

    public wagerAmount: number;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(wagerAmount: number = null): ISidebarPortalComponent {
        const inputTokens: Map<string, any> = new Map();
        inputTokens.set(WAGER_AMOUNT_TOKEN, wagerAmount);

        return {
            component: SsnCollectionSidebarComponent,
            properties: {
                inputs: inputTokens
            }
        };
    }

    public static getHeaderComponent(): ISidebarHeaderComponent {
        return {
            component: SsnCollectionSidebarHeaderComponent
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {

        if (properties.inputs.get(WAGER_AMOUNT_TOKEN) !== null) {
            this.wagerAmount = properties.inputs.get(WAGER_AMOUNT_TOKEN);
        }
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        _environment: ENVIRONMENT,
        private cduxTranslate: TranslatePipe,
        protected sessionService: JwtSessionService,
        protected ssnCollectionService: SsnCollectionService,
        private sidebarService: SidebarService
    ) {
        super(
            _environment,
            sessionService,
            ssnCollectionService
        );
    }

    public submitSsn () {
        this.sidebarService.showLoadingOverlay(this.LOADING_OVERLAY_TYPE);

        const sub = this.submit().subscribe(
            (response) => {
                if (response === true) {
                    this.finishSubmission(enumStatus.SUCCESS);
                    sub.unsubscribe();
                } else {
                    if (this.canRetry) {
                        // dismiss spinner to redisplay form
                        this.sidebarService.hideLoadingOverlay(this.LOADING_OVERLAY_TYPE);
                    } else {
                        this.finishSubmission(enumStatus.FAILED);
                    }
                    sub.unsubscribe();
                }
            },
            (error) => {
                this.finishSubmission(enumStatus.FAILED);
                sub.unsubscribe();
            }
        );
    }

    private finishSubmission(status: enumStatus) {
        const properties: ISidebarLoaderProperties = {
            delayMs: 5000,
            status: status
        };
        if (status === enumStatus.SUCCESS) {
           properties.messages = 'Your SSN has been sent. Once your SSN has been validated, your winnings will be released within 24 hours.'
        } else {
           properties.messages = 'There was a problem validating your information. Please call <span style="white-space: nowrap;">' + this.cduxTranslate.transform('primary-phone-number-vanity', this.AFFILIATE_KEY) + '</span> for assistance.'
           properties.statusOverride = ' ';
           properties.options = { 'button': false } as ILoadingTemplateConfig;
        }

        this.sidebarService.hideLoadingOverlay(this.LOADING_OVERLAY_TYPE, properties, () => {
            // SidebarService.goBack() will automatically go back to the last component or close the sidebar if this is already the root component.
            this.sidebarService.goBack();
        });
    }
}
