<div class="expert-analysis-container" *ngIf="expertPick">
  <div class="expert-picks-header">
      <div class="expert">
          <figure class="image--profile" *ngIf="expertPick.expertImageUrl">
              <img width="25" height="25" [src]="expertPick.expertImageUrl">
              <cdux-tooltip class="quick-pick-tooltip" [activatedOn]="'click hover'" [position]="'bottom'" [tooltipTitle]="'Handicapper Bio'">
                  <span tooltip-toggle *ngIf="expertPick.expertBio">
                      <i class="icon--info"></i>
                  </span>
                  <span tooltip-content>
                      <cdux-expert-pick-bio [expertPick]="expertPick"></cdux-expert-pick-bio>
                  </span>
              </cdux-tooltip>
          </figure>
          <div class="expert-name-link">
              <div class="name">
                  {{ expertPick.header}}
              </div>
              <div class="link" *ngIf="expertPick.videoUrl">
                  <a (click)="showVideo()">Watch Video</a>
              </div>
          </div>
      </div>
      <div id="picks" class="picks" [class.selectable]="isWagerable && canSelectEntries" [class.selected]="isWagerable && isSelected" (click)="selectPicks()">
          <div class="saddle-cloth-wrapper" *ngFor="let pick of expertPickEntries" [title]="pick.titleText">
              <div class="saddle-cloth" [ngClass]="pick.saddleClothClass" [class.is-scratched]="pick.entry.Scratched">
                  {{ pick.entry.ProgramNumber }}
              </div>
              <div class="scratch-indicator" *ngIf="pick.entry.Scratched">SCR</div>
          </div>
      </div>
  </div>
</div>
