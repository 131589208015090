<section class="stable-alert__settings">
  <section class="stable-alerts__settings__content--scroll">
    <h2 class="h2--standard text--uppercase">Email Address</h2>
    <fieldset>
      <label>Current:</label><span class="stable-alert__settings__email-current">{{ emailAddress }}</span>
    </fieldset>
    <div class="stable-alerts__settings__hr" style="margin:0 -40px;"></div>

    <nav class="stable-alert__settings__notifications-nav__tabs">
      <ul class="stable-alert__settings__notifications-nav__tabs__list">
        <li class="stable-alert__settings__notifications-nav__tabs__item" tabindex="0"
          [ngClass]="{'is-selected': currentTab === 'email'}" (click)="setCurrentTab('email')">
          Emails
        </li>

        <!-- <li class="stable-alert__settings__notifications-nav__tabs__item"
                    tabindex="0"
                    [ngClass]="{'is-selected': currentTab === 'notification'}"
                    (click)="setCurrentTab('notification')">
                    Notifications
                </li> -->
      </ul>
    </nav>

    <!-- section for email settings -->
    <section *ngIf="currentTab === 'email'" class="stable-alert__settings__email-settings">
      <h2 class="h2--standard">Who's Racing Email</h2>
      <fieldset>
        <legend id="email--daily-summary--prompt">Do you wish to receive a summary email that lets you know about your
          favorites racing
          each
          day?
        </legend>
        <input class="radio--standard" id="email--daily-summary--yes" name="email--daily-summary" type="radio"
          value="true" [checked]="dailySummary" (change)="updateDailySummary(); onChangesMade()">
        <label for="email--daily-summary--yes">Yes</label>
        <input class="radio--standard" id="email--daily-summary--no" name="email--daily-summary" type="radio"
          value="false" [checked]="!dailySummary" (change)="updateDailySummary(); onChangesMade()">
        <label for="email--daily-summary--no">No</label>
      </fieldset>


      <fieldset>
        <legend id="options-prompt">Which favorites would you like included?</legend>
        <ul>
          <li *ngFor="let option of emailSettings?.notificationOptions; index as i">
            <input class="checkbox--standard" id="{{option.optionDisplay}}" name="{{option.optionDisplay}}"
              type="checkbox" value="{{option.optionDisplay}}" [(ngModel)]="option.optedIn"
              (ngModelChange)="onNotificationFavoritesChange(); onChangesMade()">
            <label for="{{option.optionDisplay}}">{{option.optionDisplay}}</label>
          </li>
        </ul>
      </fieldset>

      <!-- <fieldset>
                <legend>Do you wish to receive carryovers?</legend>
                <input class="radio--standard" id="carryovers--yes" name="carryovers" type="radio" value="yes">
                <label for="carryovers--yes">Yes</label>
                <input class="radio--standard" id="carryovers--no" name="carryovers" type="radio" value="no">
                <label for="carryovers--no">No</label>
                <label for="minimum-amount">Minimum Amount</label><input id="minimum-amount" size="5"
                                                                            type="text"
                                                                            value="$0">
            </fieldset> -->

      <!-- <fieldset>
                <legend>Do you wish to receive stakes races?</legend>
                <ul class="horizontal-layout__inputs">
                    <li>
                        <input class="checkbox--standard" id="all" name="all" type="checkbox"
                                value="all">
                        <label for="all">All</label>
                    </li>
                    <li><input class="checkbox--standard" id="grade-1" name="grade-1" type="checkbox"
                                value="daily-summary">
                        <label for="grade-1">Grade 1</label>
                    </li>
                    <li><input class="checkbox--standard" id="graded" type="checkbox" value="graded">
                        <label for="graded">Graded</label>
                    </li>
                    <li><input class="checkbox--standard" id="listed" type="checkbox" value="listed">
                        <label for="listed">Listed</label></li>
                    <li><input class="checkbox--standard" id="none" type="checkbox" value="none">
                        <label for="listed">None</label>
                    </li>
                </ul>
            </fieldset> -->

      <fieldset *ngIf="advancedStableAlertsToggle === true">
        <legend id="adv-options-prompt">How far in advance would you like to receive your "Who's Racing" email?</legend>
        <ul class="horizontal-layout__inputs">
          <li *ngFor="let setting of emailSettings?.advanceNotificationSettings; index as i">
            <input class="checkbox--standard" id="option-{{setting.notificationFrequencyId}}" name="{{setting.notificationFrequencyId}}"
              type="checkbox" value="{{setting.notificationFrequency}}" [(ngModel)]="setting.optedIn"
              (ngModelChange)="onNotificationTimingsChange(); onChangesMade();">
            <label for="option-{{setting.notificationFrequencyId}}">{{setting.notificationFrequency}}</label>
          </li>
        </ul>

      </fieldset>
      <!-- <h2 class="h2--standard">Racing Recap Email</h2> -->

      <!-- <fieldset>
                <legend>Do you wish to receive a summary email that lets you know how Favorites performed n
                    races and workouts?
                </legend>
                <input class="radio--standard" id="yes" name="yes" type="radio" value="yes">
                <label for="yes">Yes</label>
                <input class="radio--standard" id="no" name="no" type="radio" value="no">
                <label for="no">No</label>
            </fieldset> -->

      <!-- <fieldset>
                <legend>Which favorites would you like included?</legend>
                <ul>
                    <li>
                        <input class="checkbox--standard" id="runners" name="runners" type="checkbox"
                                value="runners">
                        <label for="runners">Runners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="trainers" name="trainers" type="checkbox"
                                value="trainers">
                        <label for="trainers">Trainers</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="jockeys" name="jockeys" type="checkbox"
                                value="jockeys">
                        <label for="jockeys">Jockeys</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="owners" name="owners" type="checkbox"
                                value="owners">
                        <label for="owners">Owners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="tracks" name="tracks" type="checkbox"
                                value="tracks">
                        <label for="tracks">Tracks</label>
                    </li>
                </ul>
            </fieldset> -->

      <!-- <fieldset>
                <legend>How often would you like to receive your "Who's Racing" email?</legend>
                <input class="radio--standard" id="daily" name="daily" type="radio" value="daily">
                <label for="daily">Daily</label>
                <input class="radio--standard" id="weekly" name="48hours" type="radio" value="weekly">
                <label for="weekly">Weekly</label>
                <input class="radio--standard" id="twiceweekly" name="twiceweekly" type="radio"
                        value="twiceweekly">
                <label for="twiceweekly">2x Weekly <span class="nowrap">(Mon &amp; Thu)</span></label>

            </fieldset> -->
    </section>

    <!-- section for notification settings -->
    <!-- <section *ngIf="currentTab === 'notification'" class="stable-alert__settings__notification-settings">
            <h2 class="h2--standard">Push Notifications</h2>
            <fieldset>
                <legend>Do you wish to receive notifications to remind you of favorites that you have racing that day?
                </legend>
                <input class="radio--standard" id="push-notification--yes" name="push-notification"
                        type="radio" value="yes">
                <label for="push-notification--yes">Yes</label>
                <input class="radio--standard" id="push-notification--no" name="push-notification"
                        type="radio" value="no">
                <label for="push-notification--no">No</label>
            </fieldset>


            <fieldset>
                <legend>Which favorites would you like included?</legend>
                <ul>
                    <li>
                        <input class="checkbox--standard" id="runners" name="runners" type="checkbox"
                                value="runners">
                        <label for="runners">Runners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="trainers" name="trainers" type="checkbox"
                                value="trainers">
                        <label for="48hrs">Trainers</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="jockeys" name="jockeys" type="checkbox"
                                value="jockeys">
                        <label for="jockeys">Jockeys</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="owners" name="owners" type="checkbox"
                                value="owneers">
                        <label for="owners">Owners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="tracks" name="tracks" type="checkbox"
                                value="tracks">
                        <label for="tracks">Tracks</label>
                    </li>
                </ul>
            </fieldset>


            <fieldset>
                <legend>Do you wish to receive stakes races?</legend>
                <ul class="horizontal-layout__inputs">
                    <li>
                        <input class="checkbox--standard" id="all" name="all" type="checkbox"
                                value="all">
                        <label for="all">All</label>
                    </li>
                    <li><input class="checkbox--standard" id="grade-1" name="grade-1" type="checkbox"
                                value="daily-summary">
                        <label for="grade-1">Grade 1</label>
                    </li>
                    <li><input class="checkbox--standard" id="graded" type="checkbox" value="graded">
                        <label for="graded">Graded</label>
                    </li>
                    <li><input class="checkbox--standard" id="listed" type="checkbox" value="listed">
                        <label for="listed">Listed</label></li>
                    <li><input class="checkbox--standard" id="none" type="checkbox" value="none">
                        <label for="listed">None</label>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>How far in advance do you want to receive your "Who's Racing" email?</legend>
                <input class="radio--standard" id="morningof" name="morningof" type="radio" value="morningof">
                <label for="morningof">Morning of</label>
                <input class="radio--standard" id="24hours" name="24hours" type="radio" value="24hours">
                <label for="24hours">24 Hours</label>
                <input class="radio--standard" id="48hours" name="48hours" type="radio" value="48hours">
                <label for="48hours">48 Hours</label>
            </fieldset>

            <h2 class="h2--standard">Racing Recap Email</h2>

            <fieldset>
                <legend>Do you wish to receive a summary email that lets you know how Favorites performed n
                    races and workouts?
                </legend>
                <input class="radio--standard" id="yes" name="yes" type="radio" value="yes">
                <label for="yes">Yes</label>
                <input class="radio--standard" id="no" name="no" type="radio" value="no">
                <label for="no">No</label>
            </fieldset>

            <fieldset>
                <legend>Which favorites would you like included?</legend>
                <ul>
                    <li>
                        <input class="checkbox--standard" id="runners" name="runners" type="checkbox"
                                value="runners">
                        <label for="runners">Runners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="trainers" name="trainers" type="checkbox"
                                value="trainers">
                        <label for="trainers">Trainers</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="jockeys" name="jockeys" type="checkbox"
                                value="jockeys">
                        <label for="jockeys">Jockeys</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="owners" name="owners" type="checkbox"
                                value="owners">
                        <label for="owners">Owners</label>
                    </li>
                    <li>
                        <input class="checkbox--standard" id="tracks" name="tracks" type="checkbox"
                                value="tracks">
                        <label for="tracks">Tracks</label>
                    </li>
                </ul>
            </fieldset>

            <fieldset>
                <legend>How often would you like to receive your "Who's Racing" email?</legend>
                <input class="radio--standard" id="daily" name="daily" type="radio" value="daily">
                <label for="daily">Daily</label>
                <input class="radio--standard" id="weekly" name="48hours" type="radio" value="weekly">
                <label for="weekly">Weekly</label>
                <input class="radio--standard" id="twiceweekly" name="twiceweekly" type="radio"
                        value="twiceweekly">
                <label for="twiceweekly">2x Weekly <span class="nowrap">(Mon &amp; Thu)</span></label>

            </fieldset>
        </section> -->


  </section>
  <footer class="stable-alert__settings__footer">
    <button id="saveButton" class="button-primary-outline-white" (click)="saveChanges()"
      [ngClass]="{'inactive': !changesMade}">Save Changes</button>
    <button id="cancelButton" class="button-primary-outline-white" id="cancel-search-to-add"
      (click)="closeDrawer()">Cancel</button>
  </footer>
</section>
