import { ChangeDetectorRef, Component, Input, OnChanges, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import {
    CduxObjectUtil,
    FavoritePersonService,
    FeatureToggleDataService,
    IFavoritePerson,
    JwtSessionService,
    EventClickType,
    EventClickAttributeType
} from "@cdux/ng-common";
import { ToastService } from "@cdux/ng-fragments";
import { AccountBubbleNotificationService } from "app/shared/notification/services/account-bubble-notification.service";
import { FavEventType } from "../favorites-event-interface";
import { enumFavoriteToggleState, FavoriteToggleAbstractComponent } from "./favorite-toggle-abstract.component";
import { FavoritePersonTypeEnum } from '../enums/favorite-person-type-enums';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
@Component({
    selector: 'cdux-favorite-toggle-person',
    templateUrl: './favorite-toggle.component.html',
    styleUrls: [ './favorite-toggle.component.scss' ],
})
export class FavoriteTogglePersonComponent extends FavoriteToggleAbstractComponent implements OnChanges {
    @Input() public favoriteData: IFavoritePerson;
    @Input() public displayFavoritesLabel = false;
    @Input() public rightDisplay = false;
    @ViewChild('confirmationModal') protected confirmationModal: TemplateRef<unknown>;

    public id: number;
    public name: string;

    private errorMessages = {
        MAX_NUMBER: 'You have reached the maximum number of %d %p. Please remove some %p before adding new ones.',
        ALREADY_EXISTS: '%s already exists in your stable.',
        DEFAULT: 'We are unable to add this %p to your stable. Please try again later.'
    };
    private errorMessageKeys = {
        MAX_NUMBER: 'maximum number',
        ALREADY_EXISTS: 'already exists'
    };

    public constructor (
        localFeatureToggleService: FeatureToggleDataService,
        localJwtSessionService: JwtSessionService,
        localViewContainerRef: ViewContainerRef,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _changeDetector: ChangeDetectorRef,
        private _toastService: ToastService,
        public favPerson: FavoritePersonService,
        private _eventTrackingService: EventTrackingService,
    ) {
        super(localFeatureToggleService, localJwtSessionService, localViewContainerRef);
    }

    // wait for @Input
    public ngOnChanges () {
        this._currentState = enumFavoriteToggleState.OFF;
        // retrieve full record (esp. UUID) if it has been favorited
        if (this.favoriteData && 'personId' in this.favoriteData) {
            this.id = this.favoriteData.personId;
            this.name = this.favoriteData.firstName + ' ' + (this.favoriteData.middleName ? this.favoriteData.middleName : '') + ' ' + this.favoriteData.lastName;
            const record = this.favPerson.getByID(this.favoriteData);
            if (record) {
                this.favoriteData = Object.assign({}, record);
                this.countCurrent = this.favoriteData.comment?.length;
                this._currentState = enumFavoriteToggleState.ON;
                this._changeDetector.markForCheck();
                return;
            }
        }
        this._changeDetector.markForCheck();
    }

    public confirm (): void {
        this.favPerson.add(this.favoriteData).then(
            (result: IFavoritePerson) => {
                this._logClickEvent(EventClickType.STABLE_ALERTS_ADD);
                this.favoriteData.personId = result.personId;
                this.turnOn();
                // call AccountBubbleNotificationService.dispatch to emit success message:
                const path = this.favoriteData.personType.toUpperCase() === FavoritePersonTypeEnum.TRAINER ? '/favorites/manager/Trainers' : 'favorites/manager/Jockeys';

                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_ADD,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.favoriteData.firstName + ' ' + (this.favoriteData.middleName ? this.favoriteData.middleName : '') + ' ' + this.favoriteData.lastName + ' added to <span class="bold-text">stable</span>.',
                        path: path,
                        duration: 3000
                    }
                });
                this._changeDetector.markForCheck();
            },
            error  => {
                // Get the maximum number of records out of the message:
                const errorsArray: string[] = CduxObjectUtil.deepGet(error, 'data.error.errors');
                const errorMessage: string = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].toLowerCase() : null;
                // Because we don't want to use custom error codes, many potential errors are returned as 400. This means I
                // need to parse the string and search for specific terms in order to determine which error we have encountered
                if (errorMessage.search(this.errorMessageKeys.MAX_NUMBER) !== -1) {
                    // Show maximum number error
                    const maxRecords: any[] = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].match(/(\d+)/g) : null;
                    if (error.data.error.code === 400 && Array.isArray(maxRecords) && maxRecords.length) {
                        // Display the error message
                        this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%d', maxRecords[0]));
                        this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%p', this.favoriteData.personType + 's'));
                    }
                } else if (errorMessage.search(this.errorMessageKeys.ALREADY_EXISTS) !== -1) {
                    // Show already exists error message
                    this._toastService.cduxWarning(this.errorMessages.ALREADY_EXISTS.replace('%s', this.favoriteData.firstName + ' ' + (this.favoriteData.middleName ? this.favoriteData.middleName : '') + ' ' + this.favoriteData.lastName));
                } else {
                    // Show default error message
                    this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%p', this.favoriteData.personType));
                    this._toastService.cduxWarning(this.errorMessages.DEFAULT.replace('%p', this.favoriteData.personType));
                }
                this._changeDetector.markForCheck();
            }
        ).finally(() => {
            this.dismiss();
        });
    }

    public toggleFavorite () {
        try {
            this._logClickEvent(EventClickType.STABLE_ALERTS_ADD_TO_STABLE);
            if (!this.isToggling) {
                this.isToggling = true;
                if (this._currentState === enumFavoriteToggleState.OFF) {
                    this.modalContent = this.templatePortal;
                } else {
                    this.favPerson.remove(this.favoriteData.personId.toString()).then(
                        success => {
                            if (success) {
                                this.turnOff();
                                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                                this._accountBubbleNotificationService.dispatch({
                                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                                    payload: {
                                        message: '<i class="icon--check-light-circle"></i>' + this.favoriteData.firstName + ' ' + (this.favoriteData.middleName ? this.favoriteData.middleName : '') + ' ' + this.favoriteData.lastName + ' removed from <span class="bold-text">stable</span>.',
                                        duration: 3000
                                    }
                                });
                                this._changeDetector.markForCheck();
                            }
                        },
                        error  => { console.error('Unable to remove from stable.'); }
                    );
                }
            }
        } catch (e) {
            console.error('Toggle failed.', e);
        } finally {
            this.isToggling = false;
        }
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        let favType = EventClickAttributeType.STABLE_ALERTS_ADD_FAVORITE_TYPE;
        let favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_LOCATION;

        if (clickType === EventClickType.STABLE_ALERTS_ADD_TO_STABLE) {
            favType = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_FAVORITE_TYPE;
            favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_LOCATION;
        }

        this._eventTrackingService.logClickEvent(
            clickType,
            [
                {
                    attrId: favType,
                    data: this.favoriteData.personType === 'jockey'
                        ? FavoriteTypeEnum.JOCKEY
                        : FavoriteTypeEnum.TRAINER,
                    timestamp: ts
                },
                {
                    attrId: favLoc,
                    data: FavoriteLocationEnum.PROGRAM,
                    timestamp: ts
                },
            ]
        );
    }
}