import { ChangeDetectorRef, Component} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { TranslateService, CARD_TYPES, FeatureToggleDataService, JwtSessionService } from '@cdux/ng-common';

import { FundingService } from '../../../shared/services/funding.service';
import { CurrencyPipe } from '@angular/common';
import { FundingCreditcardService } from '../../../shared/services/creditcard.service';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { FundingCreditCardMethodHeaderComponent } from './creditcard-method-header.component';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service';
import { CreditcardMethodDepositAbstractComponent } from 'app/shared/funding/components/methods/creditcard/creditcard-method-deposit-abstract.component';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-creditcard-method-deposit',
    templateUrl: './creditcard-method-deposit.component.html',
    styleUrls: ['./creditcard-method-deposit.component.scss']
})
export class FundingCreditCardMethodDepositComponent extends CreditcardMethodDepositAbstractComponent {
    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingCreditCardMethodDepositComponent, options);
    }

    public static getHeaderComponent(cardType: CARD_TYPES = null, accountNumber: string = null, amexEnabled: boolean = false): ISidebarPortalComponent {
        return FundingCreditCardMethodHeaderComponent.getSidebarComponent(cardType, accountNumber, amexEnabled);
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        protected _translateService: TranslateService,
        protected _creditCardService: FundingCreditcardService,
        protected _currencyPipe: CurrencyPipe,
        protected _eventService: EventTrackingService,
        protected _changeDetectorRef: ChangeDetectorRef,
        _featuerToggleService: FeatureToggleDataService,
        _router: Router,
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService) {
        super(
            _router,
            _changeDetectorRef,
            _eventService,
            _translateService,
            _creditCardService,
            _currencyPipe,
            localSessionService,
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            localHeaderService,
            _featuerToggleService,
        );
    }

    /**
     * Add new card when the current card is expired
     */
    public addNewCard() {
        this.errorCode = null;
        this.fundingMethodDetails.accountInfo = null;
        this.form.controls.cvv.setValue('');
        this.form.controls.cvv.markAsUntouched();
        this._changeDetectorRef.detectChanges();
    }


    public goToDepositOptions() {
        this._sidebarService.goBack();
    }



}
