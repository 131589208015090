import {
    Component,
    AfterContentInit,
    OnDestroy,
    QueryList,
    ContentChildren,
    forwardRef,
    Output,
    EventEmitter,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { CarouselItemComponent } from './carousel-item.component';

@Component({
    selector: 'cdux-carousel',
    templateUrl: 'carousel.component.html',
    styleUrls: ['carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements AfterContentInit, OnDestroy {

    // Query List for Carousel Items
    @ContentChildren(forwardRef(() => CarouselItemComponent)) private _itemQuery: QueryList<CarouselItemComponent>;

    /**
     * Gives us a way to hook into carousel onBack changes. Useful for logging events.
     * Emits the currentItem
     * @type {EventEmitter<number>}
     */
    @Output() private onBack = new EventEmitter<number>();

    /**
     * Gives us a way to hook into carousel onNext changes. Useful for logging events.
     * Emits the currentItem
     * @type {EventEmitter<number>}
     */
    @Output() private onNext = new EventEmitter<number>();

    /**
     * If true, show the pagination steppers
     * @type {boolean}
     */
    @Input() public showPagination: boolean = false;

     /**
     * If true, shows the larger white directional arrows
     * @type {boolean}
     */
    @Input() public showTourArrows: boolean = false;

    // Length of the Populated Query List
    public count: number;

    // Current Selected Carousel Item
    public currentItem: number = 0;
    // Populated Query List Items
    public carouselItems: Array<CarouselItemComponent>;
    // Collection of Subscriptions
    private _subscriptions: Subscription[] = [];

    constructor(private _changeDetection: ChangeDetectorRef) {}

    public ngAfterContentInit() {
        // Subscribe to QueryList Changes
        this._itemQuery.changes.subscribe(() => {
            // Parse the Carousel Items on Content Change
            this._parseCarouselItems(this._itemQuery.toArray());
        });
        // Parse the Carousel Items Immediately after Content Initialization
        this._parseCarouselItems(this._itemQuery.toArray());
    }

    public ngOnDestroy() {
        // Unsubscribe from QueryList Changes
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public back() {
        // go back to the previous item in the carousel
        // loop around if we're already on the first item
        this.currentItem = this.currentItem - 1;
        if (this.currentItem < 0) {
            this.currentItem = this.count - 1;
        }

        // determine the previous item index
        const prev = this.currentItem === this.count - 1 ? 0 : this.currentItem + 1;

        // hide the previous, show the next item
        this.carouselItems[prev].hidden = true;
        this.carouselItems[this.currentItem].hidden = false;
        this.onBack.emit(this.currentItem);
    }

    public forward() {
        // go forward to the next item in the carousel
        // loop around if we're already on the last item
        this.currentItem = this.currentItem + 1;
        if (this.currentItem >= this.count) {
            this.currentItem = 0;
        }

        // determine the previous item index
        const prev = this.currentItem === 0 ? this.count - 1 : this.currentItem - 1;

        // hide the previous, show the next item
        this.carouselItems[prev].hidden = true;
        this.carouselItems[this.currentItem].hidden = false;
        this.onNext.emit(this.currentItem);
    }

    public goTo(index: number) {
        this.currentItem = index;

        this.carouselItems.forEach((item) => item.hidden = true);
        this.carouselItems[this.currentItem].hidden = false;
    }

    public trackByIndex(index: number) {
        return index;
    }

    private _parseCarouselItems(carouselItems: CarouselItemComponent[]) {
        // Check to Ensure the Current Selected Carousel Item is Still Valid
        if (!carouselItems[this.currentItem]) {
            // Reset it to 0 if the Selection is Invalid
            this.currentItem = 0;
        }
        // Send Populated QueryList to Local Data Store
        this.carouselItems = carouselItems;
        // Set Non-Selected Carousels Items as Hidden
        // Set Selected Carousel Item as Visible
        this.carouselItems.forEach((v, i) => {
            v.hidden = i !== this.currentItem;
        });
        // Send Populated QueryList Length to Local Data Store
        this.count = this.carouselItems.length;
        this._changeDetection.detectChanges();
    }
}
