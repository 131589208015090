// Library Imports
import { ClickOutsideModule } from 'ng-click-outside';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// CDUX Libraries
import {
    BetCalculatorBusinessService,
    EventSessionModule,
    FormatBetAmountPipe,
    TranslateModule,
    WagerModule,
    OldWagerValidationService, RunnerListPipe
} from '@cdux/ng-common';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { DropdownModule, LoadingModule, SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';

// Custom Modules
import { TuxCommonModule } from '../common/common.module';
import { FundingModule } from '../funding/funding.module';
import { SsnCollectionModule } from '../ssn-collection/ssn-collection.module';
import { TransactionModule } from '../transaction/transaction.module';

// Components
import { BetCustomAmountComponent } from './components/bet-custom-amount/bet-custom-amount.component';
import { BetNavAmountComponent } from './components/bet-nav-amount/bet-nav-amount.component';
import { BetNavSubtypeComponent } from './components/bet-nav-subtype/bet-nav-subtype.component';
import { BetNavTypeComponent } from './components/bet-nav-type/bet-nav-type.component';
import { BetNavComponent } from './components/bet-nav/bet-nav.component';
import { BetShareSuccessComponent } from './components/bet-share-success/bet-share-success.component';
import { BetSlipFooterComponent } from './components/bet-slip/bet-slip-footer.component';
import { BetSlipComponent } from './components/bet-slip/bet-slip.component';
import { BetErrorBarComponent } from './components/bet-total/bet-error-bar.component';
import { BetTotalComponent } from './components/bet-total/bet-total.component';
import { BetComponent } from './components/bet/bet.component';
import { RunnerBarComponent } from './components/runner-bar/runner-bar.component';

// Services
import { BetCustomAmountService } from './services/bet-custom-amount.service';
import { BetNavBusinessService } from './services/bet-nav.business.service';
import { BetSlipErrorsService } from './services/bet-slip-errors.service';
import { BetSlipBusinessService } from './services/bet-slip.business.service';
import { BetsBusinessService } from './services/bets.business.service';
import { BetShareBusinessService } from './services/betshare.business.service';
import { CompactTotalBarComponent } from './components/bet-total/compact/total-bar.component';

@NgModule({
    imports: [
        ClickOutsideModule,
        CommonModule,
        DropdownModule,
        FormsModule,
        FundingModule,
        TranslateModule,
        TuxCommonModule,
        WagerModule,
        EventSessionModule,
        LoadingModule,
        CduxFormsModule,
        CduxMediaModule,
        SsnCollectionModule,
        TransactionModule,
        SharedFragmentsModule
    ],
    declarations: [
        BetComponent,
        BetNavComponent,
        BetNavAmountComponent,
        BetNavTypeComponent,
        BetNavSubtypeComponent,
        BetSlipComponent,
        BetSlipFooterComponent,
        BetErrorBarComponent,
        BetTotalComponent,
        BetShareSuccessComponent,
        BetCustomAmountComponent,
        RunnerBarComponent,
        CompactTotalBarComponent
    ],
    exports: [
        BetComponent,
        BetNavComponent,
        BetNavAmountComponent,
        BetNavSubtypeComponent,
        BetNavTypeComponent,
        BetSlipComponent,
        BetSlipFooterComponent,
        BetTotalComponent,
        BetShareSuccessComponent,
        RunnerBarComponent,
        BetErrorBarComponent,
        CompactTotalBarComponent
    ],
    providers: [
        BetNavBusinessService,
        FormatBetAmountPipe,
        RunnerListPipe,
        OldWagerValidationService,
        BetsBusinessService,
        BetCustomAmountService
    ]
})
export class BetSlipModule {
    public static forRoot(): ModuleWithProviders<BetSlipModule> {
        return {
            ngModule: BetSlipModule,
            providers: [
                BetCalculatorBusinessService,
                BetsBusinessService,
                BetSlipBusinessService,
                BetShareBusinessService,
                BetSlipErrorsService
            ],
        }
    }
}
