import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@cdux/ng-fragments';
import { WageringSectionNavComponent } from './section-nav/section-nav.component';
import { WageringSectionGroupComponent } from './section-group/section-group.component';
import { PoolsSharedComponent } from '../pools/pools-shared.component';
import { PoolsBusinessService } from 'app/shared/program/services/pools.business.service';
import { StatsSharedComponent } from '../stats/stats-shared.component';
import { WillPaysSharedComponent } from '../will-pays/will-pays-shared.component';
import { WageringSectionContainerComponent } from './section-container/section-container.component';
import { BetPadSharedModule } from 'app/shared/betpad/betpad-shared.module';
import { BetSlipModule } from 'app/shared/bet-slip/bet-slip.module';
import { BrisPicksModule } from '../bris-picks/bris-picks.module';
import { TvBetPadComponent } from './sections/tv-betpad/tv-betpad.component';
import { ProgramSharedModule } from 'app/shared/program/program-shared.module';
import { ResultsSharedModule } from 'app/shared/results/results-shared.module';
import { ResultsSectionComponent } from './sections/results-section/results-section.component';
import { PoolsSectionComponent } from './sections/pools-section/pools-section.component';
import { PoolsSidebarComponent } from './sections/pools-section/pools-sidebar.component';
import { PoolsPipesSharedModule } from '../pools/pipes/pools-pipes-shared.module';
import { StatsSectionComponent } from './sections/stats-section/stats-section.component';
import { RunnerBallotEntriesService } from '../runner-ballot/services/runner-ballot-entries.service';
import { RunnerBallotComponent } from '../runner-ballot/runner-ballot.component';
import { RunnerBallotSectionComponent } from './sections/runner-ballot-section/runner-ballot-section.component';
import { CheckioboxModule, SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';
import { ProbablesSharedComponent } from '../probables/probables-shared.component';
import { ProbablesBusinessService } from 'app/shared/program/services/probables.business.service';
import { ProgramSectionComponent } from './sections/program-section/program-section.component';
import { PastPerformanceModule } from 'app/shared/past-performances/past-performances.module';
import { ProbablesPipesSharedModule } from '../probables/pipes/probables-pipes-shared.module';
import { EventSessionModule, TranslateModule } from '@cdux/ng-common';
import { AnglesTopPickBarComponent } from '../angles/angles-top-pick-bar/angles-top-pick-bar.component';
import { CduxFormsModule } from '@cdux/ng-platform/web';
import { Top3ButtonComponent } from '../angles/top-3-button/top-3-button.component';
import { ChangesSharedComponent } from '../changes/changes-shared.component';
import { ChangesSectionComponent } from './sections/changes-section/changes-section.component';
import { TrackChangesService } from 'app/wagering/track-changes/track-changes.services';
import { CarryoverBannerComponent } from '../carryover-banner/carryover-banner.component';
import { TuxCommonModule } from 'app/shared/common/common.module';
import { ExoticPoolsSharedComponent } from 'app/wagering/shared/exotic-pools/exotic-pools-shared.component';
import { ExpertPickAnalysisComponent } from '../angles/expert-pick-analysis/component/expert-pick-analysis.component';
import { ExpertPickBioComponent } from '../angles/expert-pick-analysis/expert-pick-bio/expert-pick-bio.component';
import { ProgramSummaryGlossaryComponent } from 'app/shared/program/components/program-summary/program-summary-glossary.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TuxCommonModule,
        SharedFragmentsModule,
        BetPadSharedModule,
        LoadingModule,
        CheckioboxModule,
        BetSlipModule,
        BrisPicksModule,
        ProgramSharedModule,
        ResultsSharedModule,
        PoolsPipesSharedModule,
        PastPerformanceModule,
        ProbablesPipesSharedModule,
        EventSessionModule,
        CduxFormsModule
    ],
    declarations: [
        PoolsSharedComponent,
        ProbablesSharedComponent,
        StatsSharedComponent,
        ChangesSharedComponent,
        RunnerBallotComponent,
        WillPaysSharedComponent,
        WageringSectionContainerComponent,
        WageringSectionGroupComponent,
        WageringSectionNavComponent,
        TvBetPadComponent,
        ResultsSectionComponent,
        PoolsSectionComponent,
        PoolsSidebarComponent,
        StatsSectionComponent,
        ChangesSectionComponent,
        RunnerBallotSectionComponent,
        ProgramSectionComponent,
        AnglesTopPickBarComponent,
        Top3ButtonComponent,
        ExpertPickAnalysisComponent,
        ExpertPickBioComponent,
        CarryoverBannerComponent,
        ExoticPoolsSharedComponent,
        ProgramSummaryGlossaryComponent
    ],
    exports: [
        PoolsSectionComponent,
        PoolsSidebarComponent,
        PoolsSharedComponent,
        ProbablesSharedComponent,
        StatsSharedComponent,
        ChangesSharedComponent,
        WageringSectionContainerComponent,
        WageringSectionGroupComponent,
        WageringSectionNavComponent,
        Top3ButtonComponent,
        ExoticPoolsSharedComponent
    ],
    providers: [
        PoolsBusinessService,
        ProbablesBusinessService,
        RunnerBallotEntriesService,
        TrackChangesService
    ]
})
export class SharedWageringViewsModule {}
