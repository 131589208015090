import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RunnerDetailsPastPerformancesComponent } from "app/shared/program/components/runner-details-past-performances/runner-details-past-performances.component";

@Component({
    selector: 'cdux-runner-details-past-performances',
    templateUrl: '../../../shared/program/components/runner-details-past-performances/runner-details-past-performances.component.html',
    styleUrls: ['../../../shared/program/components/runner-details-past-performances/runner-details-past-performances.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteStatsPastPerformancesComponent extends RunnerDetailsPastPerformancesComponent {}
