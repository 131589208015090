import { Observable, combineLatest, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, map } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { ITrackBasic, ITrack } from '@cdux/ng-common';

export class TodaysRaceStatus<T = Observable<ITrack>, R = ITrack> extends CduxRxJSBuildingBlock<T, R> {

    protected _stream: Observable<R>;

    /** CONTROLS **/
    private track: ReplaySubject<ITrackBasic> = new ReplaySubject(1);
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _source: Observable<T>
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public updateTrack(track: ITrackBasic) {
        this.track.next(track);
    }
    public kill() {
        super.kill();
        this.track.complete();
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {

        this._stream = combineLatest([this._source, this.track])
            .pipe(
                this.getStatusByTrack(),
                finalize(() => this.kill()),
                takeUntil(this._kill),
                // share()
            ) as Observable<R>;
    }

    /** CUSTOM OPERATORS **/
    private getStatusByTrack() {
        return map(([ todaysTracks, track]) => {
            const brisCode = track.BrisCode || track.brisCode;

            return todaysTracks.find(item => item.brisCode === brisCode)?.ITrack;
        } )
    }
    /** END CUSTOM OPERATORS **/
}
