export class AgeConstants {

    public static AgeMap = {
        A: ['2YO', '2 years old'],
        B: ['3YO', '3 years old'],
        C: ['4YO', '4 years old'],
        D: ['5YO', '5 years old'],
        E: ['3&4YO', '3 & 4 years old'],
        F: ['4&5YO', '4 & 5 years old'],
        G: ['3,4&5YO', '3, 4 & 5 years old'],
        H: ['', '']
    }

    public static AgeMapMods = {
        O: ['', ''],
        U: ['+', ' and up']
    };

}
