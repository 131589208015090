import { Component } from '@angular/core';
import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { ToteDataService } from '@cdux/ng-common';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';

@Component({
    selector: 'cdux-changes-section',
    template: `
        <cdux-changes-shared [track]="wagerState?.basicTrack" [race]="wagerState?.basicTrack?.RaceNum"></cdux-changes-shared>
    `,
    styleUrls: ['./changes-section.component.scss']
})
export class ChangesSectionComponent extends AbstractWageringSectionComponent {

    constructor(
        protected _toteDataService: ToteDataService,
        protected _viewStateService: ViewStateService
    ) {
        super(_toteDataService, _viewStateService);
    }

}
