export const LANG_CREDITCARD_NAME = 'creditcard';

export const LANG_CREDITCARD_TRANS = {
  'description': 'Transfer funds to your account using your Credit Card.',
  'tile-icon': '<div class="icon--vendor-logo"><i class="icon--visa"></i><i class="icon--mc"></i></div>',
  'tile-icon-plus-amex': '<div class="icon--vendor-logo"><i class="icon--visa"></i><i class="icon--mc"></i><i class="icon--amex"></i></div>',
  'tile-icon-visa': '<div class="icon--vendor-logo icon--visa"></div>',
  'tile-icon-mc': '<div class="icon--vendor-logo icon--mc"></div>',
  'tile-icon-amex': '<div class="icon--vendor-logo icon--amex"></div>',
  'header-icon': '<div class="icon--vendor-logo icon--creditcard"><i class="icon--visa white"></i><i class="icon--mc white"></i></div>',
  'header-icon-visa': '<div class="icon--vendor-logo icon--creditcard"><i class="icon--visa white"></i></div>',
  'header-icon-mc': '<div class="icon--vendor-logo icon--creditcard"><i class="icon--mc white"></i></div>',
  'header-icon-amex': '<div class="icon--vendor-logo icon--creditcard"><i class="icon--amex white"></i></div>',
  'header-account-info': '<div class="account-number"><i class="symbol--bullet--four"></i><i class="symbol--bullet--four"></i><i class="symbol--bullet--four"></i> %s </div>',
  'cvv-tooltip-content': 'Please enter the 3-digit card ID number from the back of your card.',
  'cvv-tooltip-content-plus-amex': 'Please enter the 3-digit card ID number from the back of your card. American Express cards have a 4-digit code on the front of the card.'
};
