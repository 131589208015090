import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MtpModule, LoadingModule } from '@cdux/ng-fragments';
import { EventTrackingModule } from 'app/shared/event-tracking/event-tracking.module';
import { VideoModule } from '../video/video.module';

import { TvComponent } from './tv.component';
import { TvThumbnailBarComponent } from './components/tv-thumbnail-bar/tv-thumbnail-bar.component';
import { SharedWageringViewsModule } from '../shared/wagering-views/wagering-views.module';
import { CompactModule } from '../compact/compact.module';

@NgModule({
    imports: [
        CommonModule,
        EventTrackingModule,
        LoadingModule,
        MtpModule,
        VideoModule,
        SharedWageringViewsModule,
        CompactModule
    ],
    declarations: [
        TvComponent,
        TvThumbnailBarComponent
    ],
    exports: [
        TvComponent
    ]
})
export class TvModule { }
