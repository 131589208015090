export class DomUtil {

    public static postRedirect(url: string, params: {[key: string]: string}) {
        if (!document) {
            return;
        }

        const form = document.createElement('form');
        document.body.appendChild(form);
        form.method = 'POST';
        form.action = url;

        Object.keys(params).forEach((key: string) => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            input.value = params[key];
            form.appendChild(input);
        });

        form.submit();
    }

    public static setViewport(width: number | 'device-width', scale: number, scalable: boolean) {
        let metaTag = document.querySelector('meta[name=viewport]');
        if (!metaTag) {
            metaTag = document.createElement('meta') as HTMLMetaElement;
            metaTag.setAttribute('name', 'viewport');
            document.head.appendChild(metaTag);
        }
        metaTag.setAttribute(
            'content',
            `width=${width}, initial-scale=${scale}, user-scalable=${scalable ? 'yes' : 'no'}`
        );
    }

    /* istanbul ignore next: this would be very difficult to mock for unit testing */
    public static async copyStyles(targetWindow: Window): Promise<void> {
        // Remove any previously attached style or link elements
        targetWindow.document.head.querySelectorAll('style, link[rel=stylesheet]')
            .forEach((htmlElement) => htmlElement.parentElement.removeChild(htmlElement));

        // Copy styles from parent window
        document.querySelectorAll('style').forEach((htmlElement: HTMLStyleElement) => {
            targetWindow.document.head.appendChild(htmlElement.cloneNode(true));
        });

        // Copy stylesheet links from parent window (loads asynchronously, using await)
        await Promise.all(Array.from(document.querySelectorAll('link[rel=stylesheet]'))
            .map((htmlElement: HTMLLinkElement) => {
                const styleSheetElement = targetWindow.document.createElement('link');
                styleSheetElement.rel = htmlElement.rel;
                styleSheetElement.type = htmlElement.type;
                styleSheetElement.href = htmlElement.href;
                targetWindow.document.head.appendChild(styleSheetElement);
                return new Promise<void>((resolve, reject) => {
                    styleSheetElement.addEventListener('load',
                        // resolve async to render styles
                        () => setTimeout(() => resolve())
                    );
                    setTimeout(() => reject(), 60000);
                });
            })
        );
    }
}
