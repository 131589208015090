import { BehaviorSubject, Observable, of, ReplaySubject, combineLatest, iif } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    ITrackBasic,
    ToteDataService,
    AnglesDataResponse,
    AnglesRace, enumTrackType, TracksDataService, ITodaysRace
} from '@cdux/ng-common';

export class AnglesRequestHandler extends CduxRxJSBuildingBlock<any, AnglesRace> {

    protected _stream: Observable<AnglesRace>;

    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false); // start un-paused
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _toteDataService: ToteDataService,
        private _tracksDataService: TracksDataService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = this._pause.pipe(
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                switchMap(track => this._getAngles(track, false))
            )),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<AnglesRace>;
    }

    /** CUSTOM OBSERVABLES **/
    private _getAngles(basicTrack: ITrackBasic, poll: boolean = false): Observable<AnglesRace> {
        const brisCode = basicTrack && basicTrack.BrisCode || null;
        const trackType = basicTrack && basicTrack.TrackType || null;
        const raceNum = basicTrack && basicTrack.RaceNum || null;

        const anglesObs = combineLatest([
            this._toteDataService.currentRaceDate(poll),
            this._tracksDataService.todaysRace(brisCode, trackType, raceNum, poll).pipe(take(1))
        ]).pipe(
            // Always send a response for things that use combineLatest
            switchMap(
                ([raceDate, raceDetails]) => iif(
                    () => this._isTBNA(basicTrack, raceDetails),
                    this._toteDataService.topThree(brisCode, raceNum, raceDate),
                    of(null)
                )
            ),
            map((angles: AnglesDataResponse) => {
                return angles.anglesRaces[0];
            }),
            catchError(() => of<AnglesRace>(null))
        );
        return poll ? anglesObs : anglesObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/

    private _isTBNA(basicTrack: ITrackBasic, raceDetails: ITodaysRace): boolean {
        return basicTrack && basicTrack.TrackType === enumTrackType.TBRED && raceDetails && (raceDetails.country.toLowerCase() === 'usa' || raceDetails.country.toLowerCase() === 'can');
    }
}
