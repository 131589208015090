import { Injectable, ElementRef, EventEmitter, Output } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

import { EventKeys } from '../../events/event-keys';
import { EventsService } from '@cdux/ng-common';

export enum ActiveDrawerEnum {
    NONE,
    DESCRIPTION,
    STATS,
    VIDEO,
    HANDICAPPING,
}

export enum FullDrawerOriginDirection {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM',
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

export enum FullDrawerOperations {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE'
}

export interface iFullDrawerConfig {
    component?: any,
    title?: string,
    originDirection?: FullDrawerOriginDirection,
    operation: FullDrawerOperations
}

@Injectable({
    providedIn: 'root'
})
export class DrawerService {

    /**
     * Event emitter for toggling full size drawer
     */
    @Output() toggleFullDrawerEvent = new EventEmitter<iFullDrawerConfig>();

    /**
     * Flag for transition
     */
    public transition: boolean;

    /**
     * Communicates a drawer change.
     *
     * @type {EventEmitter<ActiveDrawerEnum>}
     */
    public drawerChange: EventEmitter<ActiveDrawerEnum> = new EventEmitter<ActiveDrawerEnum>();

    /**
     * The drawer that slides out to show things such as video or the description.
     *
     * @type {ElementRef}
     */
    public drawer: ElementRef;

    /**
     * This is where the content for the drawer goes. It's used for sizing the drawer, necessary for transitions.
     *
     * @type {ElementRef}
     */
    public drawerContent: ElementRef;

    /**
     * Makes the drawer enum available to the template.
     *
     * @type {ActiveDrawerEnum}
     */
    public readonly ACTIVE_DRAWER_ENUM = ActiveDrawerEnum;

    /**
     * Which drawer should be active in the drawer. Using a setter so that transitions get handled.
     *
     * @type {ActiveDrawerEnum.NONE}
     */
    public set activeDrawer(drawer: ActiveDrawerEnum) {
        this._setActiveDrawer(drawer);
    }

    /**
     * Getter for the active drawer.
     *
     * @returns {ActiveDrawerEnum}
     */
    public get activeDrawer() {
        return this._activeDrawer;
    }

    /**
     * The currently active drawer.
     *
     * @type {ActiveDrawerEnum.NONE}
     * @private
     */
    private _activeDrawer: ActiveDrawerEnum = ActiveDrawerEnum.NONE;


    constructor(
        private _eventsService: EventsService,
        private _router: Router
    ) {
        this._router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd && this._activeDrawer !== ActiveDrawerEnum.VIDEO) {
                // when the user leaves the page, close the drawer
                this.toggleDrawer(ActiveDrawerEnum.NONE);
            }
        });
        this._eventsService.on(EventKeys.VIDEO_PRIMARY_OPEN).subscribe(() => {
            // if video isn't already open, ensure it is
            if (this._activeDrawer !== ActiveDrawerEnum.VIDEO) {
                this.toggleDrawer(ActiveDrawerEnum.VIDEO);
            }
        });
        this._eventsService.on(EventKeys.VIDEO_PRIMARY_CLOSE).subscribe(() => {
            // if the video tray is the active drawer, close it
            if (this._activeDrawer === ActiveDrawerEnum.VIDEO) {
                this.toggleDrawer(ActiveDrawerEnum.NONE);
            }
        });
    }

    /**
     * Performs overhead for changing to the appropriate drawer.
     *
     * @param {ActiveDrawerEnum} drawer
     * @param {boolean} availability
     * @param {boolean} isGreyhoundTrack
     * @param {boolean} onProgram
     * @param {any} options Options object that can be used in different ways that depends on the specific drawer that is being activated.
     *
     * Video:
     * options = {ITrack: ITrack, RaceNumber: Number, RaceDate: String }
     */
    public toggleDrawer(drawer: ActiveDrawerEnum, availability?: boolean, isGreyhoundTrack?: boolean, onProgram?: boolean, options?: any) {
        if (availability === undefined) {
            availability = false;
        }
        if (isGreyhoundTrack === undefined) {
            isGreyhoundTrack = false;
        }

        // The transition should only apply on opening and closing of the drawer,
        // there should be no transition when switching between drawers.
        this.transition = this._activeDrawer === ActiveDrawerEnum.NONE || drawer === this._activeDrawer;

        switch (drawer) {
            // Only activate this drawer if tracks and races are available, and it's not greyhound.
            case ActiveDrawerEnum.DESCRIPTION:
                this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
                if (availability && !isGreyhoundTrack) {
                    this._setActiveDrawer(drawer);
                }
                break;
            // Video should not deactivate the video widget, unlike the other widgets.
            case ActiveDrawerEnum.VIDEO:
                this._setActiveDrawer(drawer);
                if (options && options.brisCode && options.trackType && options.raceNumber) {
                    this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN, options);
                } else {
                    this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN);
                }
                break;
            // All non-video widgets should turn off the video.
            case ActiveDrawerEnum.NONE:
                this._setActiveDrawer(ActiveDrawerEnum.NONE);
                this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
                break;
            default:
                this._setActiveDrawer(drawer);
                this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
                break;
        }
    }

    public toggleFullDrawer(config: iFullDrawerConfig) {
        this.toggleFullDrawerEvent.emit(config);
    }

    /**
     * Opens, closes and swaps out drawers appropriately.
     * It will also determine if it's appropriate for the transitions to occur.
     *
     * @param {ActiveDrawerEnum} drawer
     * @private
     */
    private _setActiveDrawer(drawer: ActiveDrawerEnum) {
        this._activeDrawer = drawer;
        this.drawerChange.emit(drawer);
    }
}
