import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EventSessionModule, TranslateModule } from '@cdux/ng-common';
import { LoadingModule } from '@cdux/ng-fragments';

import { TuxCommonModule } from '../common/common.module';

import { SSNCollectionBetComponent } from './ssn-collection-bet.component';
import { SsnCollectionComponent } from './ssn-collection.component';
import { SsnCollectionSidebarComponent, SsnCollectionSidebarHeaderComponent } from './ssn-collection-sidebar.component';
import { SsnCollectionBetShareComponent } from './ssn-collection-bet-share.component';
import { SsnCollectionTournamentsComponent } from './ssn-collection-tournaments.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingModule,
        TranslateModule,
        TuxCommonModule,
        EventSessionModule
    ],
    declarations: [
        SSNCollectionBetComponent,
        SsnCollectionBetShareComponent,
        SsnCollectionComponent,
        SsnCollectionSidebarComponent,
        SsnCollectionSidebarHeaderComponent,
        SsnCollectionTournamentsComponent
    ],
    exports: [
        SSNCollectionBetComponent,
        SsnCollectionBetShareComponent,
        SsnCollectionComponent,
        SsnCollectionTournamentsComponent
    ]
})
export class SsnCollectionModule {
    // For consistency, this method is present on
    // all modules, even those without providers.
    public static forRoot(): ModuleWithProviders<SsnCollectionModule> {
        return {
            ngModule: SsnCollectionModule,
            providers: []
        }
    }
}
