import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'app/shared/breadcrumbs';
import { DetailsAbstract, ITrackBasic, StringSlugifyPipe, TracksDataService, TranslateService } from '@cdux/ng-common';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, first, takeUntil, tap } from 'rxjs/operators';
import { enumDetailsType } from 'app/shared/program/enums/details-types.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-summary-stats',
    templateUrl: './summary-stats.component.html',
    styleUrls: ['./summary-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryStatsComponent implements OnInit, OnDestroy {
    @Input()
    public set detailsObs(obs: Observable<DetailsAbstract>) {
        if (obs) {
            this._detailsObs = this.handleDetailsData(obs)
        }
    }
    public get detailsObs(): Observable<DetailsAbstract> {
        return this._detailsObs;
    }
    private _detailsObs: Observable<DetailsAbstract>;

    @Input()
    public detailsType: enumDetailsType;

    // TODO - is there a better way to handle the display orders? The backend returns a rank but because we turn the stats
    // response into object properties we lose the ability to maintain the sorted order. Does it make sense to create a sort
    // property on the stats classes in the models?
    @Input()
    public displayOrder: any;

    @Input()
    public comboDisplayOrder: any;

    @Input()
    public track: ITrackBasic;

    @Input()
    public isInline: boolean = true;

    readonly DISPLAY_TOGGLE_LABEL_MORE = 'MORE';
    readonly DISPLAY_TOGGLE_LABEL_LESS = 'LESS';
    readonly MIN_ROWS_DISPLAY = 5;

    public runnerName: string;
    public trainerName: string;
    public driverName: string;

    public availableStats: string[] = [];
    public availableComboStats: string[] = [];
    public keyStats: string[] = [
        'last14',
        'last30',
        'last365AllTracks',
    ];

    public showWholeAllStats: boolean = false;
    public allStatsToggleLabel: string = this.DISPLAY_TOGGLE_LABEL_MORE;

    public isPhone: boolean = false;

    public error: boolean = false;
    public errorMessage: string;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _breadcrumbsService: BreadcrumbsService,
        private _changeDetector: ChangeDetectorRef,
        private _mediaService: CduxMediaToggleService,
        private _stringSlugify: StringSlugifyPipe,
        private _tracksDataService: TracksDataService,
        private _translateService: TranslateService
    ) {}

    ngOnInit() {
        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    public toggleAllStats() {
        if ( !this.showWholeAllStats ) {
            this.allStatsToggleLabel = this.DISPLAY_TOGGLE_LABEL_LESS;
            this.showWholeAllStats = true;
        } else {
            this.showWholeAllStats = false;
            this.allStatsToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
        }
    }

    public primaryName(): string {
        return this.detailsType === enumDetailsType.DRIVER
            ? this.driverName
            : this.trainerName;
    }

    public getComboNameFromStatsType(statsType: string): string {
        let name: string;

        switch (statsType) {
            case 'driverTrainer':
                name = this.detailsType === enumDetailsType.DRIVER ? this.trainerName : this.driverName;
                break;
            case 'driverHorse':
            case 'trainerHorse':
                name = this.runnerName;
                break;
        }

        return name;
    }

    private handleDetailsData(detailsObs: Observable<DetailsAbstract>) {
        return detailsObs.pipe(
            first(),
            tap((details: DetailsAbstract) => {
                if (details && details.hasOwnProperty('runnerName')) {
                    this.runnerName = details.runnerName;
                    this.driverName = details.driverName;
                    this.trainerName = details.trainerName;

                    const stats: string[] = [];
                    if (details.hasOwnProperty('summaryStats')) {
                        for (const stat in details.summaryStats) {
                            if (details.summaryStats.hasOwnProperty(stat) && details.summaryStats[stat] !== null) {
                                stats.push(stat);
                            }
                        }
                    }

                    const comboStats: string[] = [];
                    if (details.hasOwnProperty('comboStats')) {
                        for (const stat in details.comboStats) {
                            if (details.comboStats.hasOwnProperty(stat) && details.comboStats[stat] !== null) {
                                comboStats.push(stat);
                            }
                        }
                    }

                    if (stats.length) {
                        this.availableStats = stats.sort((a, b) => this.displayOrder[a] < this.displayOrder[b] ? -1 : this.displayOrder[a] > this.displayOrder[b] ? 1 : 0);
                        this.availableComboStats = comboStats.sort((a, b) => this.comboDisplayOrder[a] < this.comboDisplayOrder[b] ? -1 : this.comboDisplayOrder[a] > this.comboDisplayOrder[b] ? 1 : 0);
                    } else {
                        this.showError('no-data');
                    }
                } else {
                    this.showError('no-data');
                }
            }),
            catchError(
                (error) => {
                    this.showError('no-data');
                    return of(null);
                }
            ),
            finalize(() => this.initializeBreadCrumbs(this.primaryName()))
        )
    }

    private initializeBreadCrumbs(displayName: string) {
        // If there are no previous routes (such as when coming from a link) then
        // we pull the track info from url to create breadcrumb back to track details
        if (!this._breadcrumbsService.breadcrumbs.length && this.track && this.track.BrisCode && this.track.TrackType) {
            this._tracksDataService.trackList(false).subscribe((tracks) => {
                let trackName: string;
                if (tracks) {
                    tracks.forEach((track) => {
                        if (this.track.BrisCode.toLowerCase() === track.BrisCode.toLowerCase()) {
                            trackName = track.DisplayName;
                        }
                    });
                }
                this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb(
                    (trackName || 'Program') + ' - Race ' + this.track.RaceNum, [
                        '/program', this._stringSlugify.transform(trackName),
                        this.track.BrisCode, this.track.TrackType, this.track.RaceNum
                    ].join('/')
                ));
                this._breadcrumbsService.addCurrentRoute(displayName);
            });
        } else {
            this._breadcrumbsService.addCurrentRoute(displayName);
        }
    }

    private showError(errorKey: string) {
        this.error = true;
        this.errorMessage = this._translateService.translate(errorKey, 'summary-stats-errors');
    }
}
