import { Pipe, PipeTransform } from '@angular/core';
import { FormatSurfaceConditionsUtil } from '../../utils';

@Pipe({
  name: 'formatSurface'
})
export class FormatSurfacePipe implements PipeTransform {
    public transform(value: string, conditions?: { [surface: string]: string }, showAll = false): string {
        const surfaceName = FormatSurfaceConditionsUtil.getSurfaceName(value);
        if (!conditions) {
            return surfaceName;
        }

        let output = '';
        if (conditions[surfaceName]) {
            output += surfaceName + ': ' + conditions[surfaceName];
        } else if (!showAll) {
            return surfaceName;
        }

        if (showAll) {
            for (const surfaceLabel in conditions) {
                if (surfaceLabel !== surfaceName) {
                    output += ', ' + surfaceLabel + ': ' + conditions[surfaceLabel];
                }
            }
        }

        return output;
    }
}
