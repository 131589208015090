import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
import {
    BetCalculatorBusinessService,
    IBetResult,
    WagerCalculatorService,
    WagerValidationService,
    enumRaceStatus,
    ToteDataService,
    TranslateService,
    FeatureToggleDataService,
    WagerState,
    TrackService,
    JwtSessionService,
    enumFeatureToggle,
    enumTrackType,
    ITrackBasic,
} from '@cdux/ng-common';
import { BetsBusinessService } from '../../../services/bets.business.service';
import { BetSlipBusinessService } from '../../../services/bet-slip.business.service';
import { BetSlipErrorsService } from '../../../services/bet-slip-errors.service';
import { BET_ERROR_CODES } from '../../../enums/bet-error-codes.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { BetShareBusinessService } from 'app/shared/bet-slip/services/betshare.business.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { AbstractBetTotalComponent } from '../abstract-bet-total.component';
import { Router } from '@angular/router';
import { ConditionalWageringBusinessService } from 'app/shared/bet-slip/services/conditional-wagering.business.service';
import { flatMap, map } from 'rxjs/operators';
import { combineLatest, of, Subscription } from 'rxjs';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-compact-total-bar',
    styleUrls: ['./total-bar.component.scss'],
    templateUrl: './total-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompactTotalBarComponent extends AbstractBetTotalComponent implements OnInit, OnDestroy {

    @Input()
    public raceStatus: enumRaceStatus;
    public shareBetFeatureToggle: boolean;
    public showShareBetButton: boolean;

    private _featureToggleQuerySubscription: Subscription;

    constructor(
        _environment: ENVIRONMENT,
        _wagerCalculator: WagerCalculatorService,
        localBetCalculatorService: BetCalculatorBusinessService,
        _betsService: BetsBusinessService,
        _betSlipErrorService: BetSlipErrorsService,
        _wagerValidationService: WagerValidationService,
        _sessionService: JwtSessionService,
        _changeDetector: ChangeDetectorRef,
        _eventTrackingService: EventTrackingService,
        _betShareService: BetShareBusinessService,
        localConditionalWageringService: ConditionalWageringBusinessService,
        _sidebarService: SidebarService,
        _translateService: TranslateService,
        _featureToggleService: FeatureToggleDataService,
        protected _toteDataService: ToteDataService,
        _betSlipBusinessService: BetSlipBusinessService,
        _router: Router,
        _tournamentSelected: TournamentsSessionService
    ) {
        super(
            _environment,
            _wagerValidationService,
            localBetCalculatorService,
            _betsService,
            _betShareService,
            localConditionalWageringService,
            _sidebarService,
            _betSlipErrorService,
            _wagerCalculator,
            _eventTrackingService,
            _featureToggleService,
            _translateService,
            _sessionService,
            _changeDetector,
            _toteDataService,
            _betSlipBusinessService,
            _router,
            _tournamentSelected
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public deselectAll() {
        if (!!this.submittedWagerState) {
            this.clearSubmittedWager();
        } else {
            this.resetEntrySelections.next();
        }
    }

    public resetTicket(): void {
        this.isSubmittedState = false;
        this.lastTransactionId = null;
        this.submittedWagerState = null;
        this._changeDetector.markForCheck();
    }

    public clearSubmittedWager() {
        this.submittedWagerState = null;
        this.handleValidation(this.wagerState);
    }

    public prepareForShare(): void {
        this.sharing = true;
        this._changeDetector.markForCheck();
    }

    public handleShareSuccess(): void {
        setTimeout(() => {
            this.sharing = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.SHARE_BET_ANIMATION_TIME);
    }

    public handleShareError(): void {
        setTimeout(() => {
            this.sharing = false;
            this._changeDetector.markForCheck();
        }, this.SHARE_BET_ANIMATION_TIME);
    }

    public prepareForConditions() {
        this.conditioning = true;
        this._changeDetector.markForCheck();
    }

    public handleConditionsSuccess() {
        setTimeout(() => {
            this.conditioning = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.CONDITIONAL_BET_ANIMATION_TIME);
    }

    public handleConditionsError() {
        setTimeout(() => {
            this.conditioning = false;
            this._changeDetector.markForCheck();
        }, this.CONDITIONAL_BET_ANIMATION_TIME);
    }

    public prepareForSave(): void {
        this.saving = true;
        this._changeDetector.markForCheck();
    }

    public handleSaveSuccess(): void {
        setTimeout(() => {
            this.saving = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.SAVE_BET_ANIMATION_TIME);
    }

    public handleSaveError(): void {
        setTimeout(() => {
            this.saving = false;
            this._changeDetector.markForCheck();
        }, this.SAVE_BET_ANIMATION_TIME);
    }

    public prepareForSubmit(): void {
        this.submitting = true;
        this._changeDetector.markForCheck();
    }

    public handleSubmitSuccess(result: IBetResult): void {
        this.submitting = false;
        this.reset();
        this.lastTransactionId = !!result ? result.confirmationId : null;
        this.isSubmittedState = true;
        this.submittedWagerState = this.wagerState;
        this._changeDetector.markForCheck();
    }

    public handleSubmitError(error?: string, betId?: string): void {
        this.submitting = false;
        this._convertDesktopWagerErrorsIntoCompact(betId);
        this._changeDetector.markForCheck();
    }

    public handletoggleKeepPicks(): void {
        this.toggleKeepPicks();
    }

    /*
     * overrides abstract class function that determines when to clear a submitted wager and remove the repeat option
     * for compact view, clear when the track or race changes or an entry selection is made
     */
    protected shouldResetSubmittedWager(prevWagerState: WagerState, currentWagerState: WagerState) {
        return this.submittedWagerState &&
            (!TrackService.isExactTrackObject(prevWagerState.basicTrack, currentWagerState.basicTrack) || this.areEntriesSelected(currentWagerState));
    }

    /**
     * Some errors that are used on the larger interfaces are not suitable for a compact size.
     */
    private _convertDesktopWagerErrorsIntoCompact(betId: string) {
        const wagerError = this._betSlipErrorService.getError(betId);
        if (wagerError && wagerError.errorCode === BET_ERROR_CODES.INSUFFICIENT_FUNDS) {
            this._betSlipErrorService.setError(betId, BET_ERROR_CODES.INSUFFICIENT_FUNDS_QUICKBET)
        }
    }
    
    public set wagerState(v: WagerState) {
        super.wagerState = v;
        this._shouldShowShareButton(v?.basicTrack);
    }

    public get wagerState(): WagerState {
        return super.wagerState;
    }
    
    public _shouldShowShareButton(track: ITrackBasic): void {
        const featureToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETSHARE);
        const buttonToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BET_SHARE_TOTAL_BAR);
        const ghToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETSHARE_DISABLE_GH);
        const stateObs = this._betShareService.getBetShareConfig().pipe(map(() => {
            return this._betShareService.unrestrictedState();
        }));
        
        const obsArr = track.TrackType === enumTrackType.GREY ? [featureToggle, buttonToggle, ghToggle]
            : [featureToggle, buttonToggle , of(false)];

            if (this._featureToggleQuerySubscription) {
                this._featureToggleQuerySubscription.unsubscribe();
            }

            this._featureToggleQuerySubscription = combineLatest(obsArr)
            .pipe(flatMap(([betShareFT, shareBetTotalBarFT, disableGH]) => {
                const buttonToggledOn = betShareFT && shareBetTotalBarFT && !disableGH;
                if (buttonToggledOn && this._sessionService.isLoggedIn()) {
                    return stateObs;
                } else {
                    return of(buttonToggledOn);
                }
            })).subscribe(result => {
                this.shareBetFeatureToggle = result;
                this._changeDetector.detectChanges();
                this.showShareBetButton = this.shareBetFeatureToggle;
            });
    }
}
