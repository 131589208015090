import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { RouterModule } from '@angular/router';

import { FundingModule as CduxFundingModule, TranslateModule } from '@cdux/ng-common';
import { SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { LoadingModule } from '@cdux/ng-fragments';

import { TuxCommonModule } from '../common/common.module';
import { RadioModule } from '../radio/radio.module';
import { EzbankIframeModule } from 'app/account/deposit/onlinebanking/ezbank-iframe.module'

import { FullPageFundingComponent } from 'app/shared/funding/full-page-funding/full-page-funding.component';
import { FundingPaypalMethodDepositFullpageComponent } from 'app/shared/funding/components/methods/paypal/paypal-method-deposit-fullpage.component';

import { FUNDING_COMPONENTS } from './components';
import { FUNDING_SHARED_COMPONENTS } from './shared/components';
import { FUNDING_SHARED_DIRECTIVES } from './shared/directives';
import { FUNDING_SHARED_SERVICES } from './shared/services';
import { TermsModule } from 'app/shared/terms/terms.module';
import {LayoutModule} from '../layout/layout.module';

@NgModule({
    imports: [
        CduxFormsModule,
        CduxFundingModule,
        CduxMediaModule,
        CommonModule,
        EzbankIframeModule,
        FormsModule,
        LoadingModule,
        PortalModule,
        RadioModule,
        ReactiveFormsModule,
        RouterModule,
        SharedFragmentsModule,
        TermsModule,
        TranslateModule,
        TuxCommonModule,
        LayoutModule,
    ],
    declarations: [
        ...FUNDING_COMPONENTS,
        ...FUNDING_SHARED_COMPONENTS,
        ...FUNDING_SHARED_DIRECTIVES,
        FullPageFundingComponent,
        FundingPaypalMethodDepositFullpageComponent,
    ],
    exports: [
        EzbankIframeModule,
        PortalModule,
        RadioModule,
        ...FUNDING_COMPONENTS,
        ...FUNDING_SHARED_COMPONENTS,
        ...FUNDING_SHARED_DIRECTIVES,
    ],
})
export class FundingModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<FundingModule> {
        return {
            ngModule: FundingModule,
            providers: [
                ...FUNDING_SHARED_SERVICES,
            ],
        }
    }
}
