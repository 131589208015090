import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CduxMediaModule } from '@cdux/ng-platform/web';
import { DropdownModule, DatepickerLiteModule } from '@cdux/ng-fragments';
import { TuxCommonModule } from '../../shared/common/common.module';
import { EventTrackingModule } from '../../shared/event-tracking/event-tracking.module';
import { PopUpModule } from '../../shared/pop-up/pop-up.module';
import { RaceNavigationModule } from '../../shared/race-navigation/race-navigation.module';

import { VideoCommonModule, EventSessionModule, CduxNgCommonModule } from '@cdux/ng-common';
import { VideoComponent } from './video.component';
import { VideoContainerComponent } from './components/video-container/video-container.component';
import { VideoSyncComponent } from './components/video-sync/video-sync.component';
import { VideoTabBarComponent } from './components/video-bars/video-tab-bar/video-tab-bar.component';
import { VideoButtonBarComponent } from './components/video-bars/video-button-bar/video-button-bar.component';
import { VideoSelectComponent } from './components/video-select/video-select.component';
import { VideoService } from './services/video.service';

import { VideoFeedModule } from './video-feeds/video-feed.module';
import { VideoPlayerComponent } from './components/video-player/video-player.component';

@NgModule({
    imports: [
        CommonModule,
        TuxCommonModule,
        CduxNgCommonModule,
        CduxMediaModule,
        DropdownModule,
        EventTrackingModule,
        FormsModule,
        PopUpModule,
        VideoCommonModule,
        VideoFeedModule,
        EventSessionModule,
        DatepickerLiteModule,
        RaceNavigationModule
    ],
    declarations: [
        VideoComponent,
        VideoContainerComponent,
        VideoSyncComponent,
        VideoTabBarComponent,
        VideoPlayerComponent,
        VideoSelectComponent,
        VideoButtonBarComponent
    ],
    exports: [
        VideoComponent,
        VideoContainerComponent,
        VideoSyncComponent
    ],
})
export class VideoModule {
    public static forRoot(): ModuleWithProviders<VideoModule> {
        return {
            ngModule: VideoModule,
            providers: [VideoService]
        }
    }
}
