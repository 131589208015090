import { Directive, HostListener } from '@angular/core';

import { RestrictNumeric } from '../utils';

@Directive({
  selector: '[cduxNumeric]'
})
export class RestrictNumericDirective {

  @HostListener('keydown', ['$event']) public onKeydown(event: KeyboardEvent) {
    if (!RestrictNumeric(event)) {
      event.preventDefault();
    }
  }

}
