<div *ngIf="!hasError && !isDisabled">
  <header class="funding-content_header">
    <!-- back arrow hidden on desktop -->
    <i class="icon--nav-back-primary" (cduxTap)="navigateToOptions()"></i>
    <i class="close-btn icon--login-close" (cduxTap)="close()" id="deposit-close-btn"></i>
  </header>
  <div>
    <!-- show loading dots/PaynearMe logo here-->
    <ng-container *ngIf="isLoading">
      <h2><i class="icon--pnm"></i></h2>
      <h2 class="loader-dots">
        <i class="dots-animated"></i>
        <i class="dots-animated"></i>
        <i class="dots-animated"></i>
        <i class="dots-animated"></i>
      </h2>
    </ng-container>
    <div [class.is-hidden]="isLoading"><iframe [attr.src]="context.paynearmeFrameSource | cduxSanitizeHtml:'resource'" style="width: 100%; height: 650px; border: none;" (load)="handleOnLoad()"></iframe></div>
  </div>
</div>

