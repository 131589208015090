import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import {
    EventClickType,
    IAvailableResultsRaceDataResponse,
    RaceDateService,
} from '@cdux/ng-common';
import { IWager, WagerDisplayStatus } from '@cdux/ng-fragments';

import { ExpandedDetailsBusinessService } from 'app/shared/transaction/services/expanded-details.business.service';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import * as moment from 'moment';

@Component({
    selector: 'cdux-completed-bets',
    templateUrl: './completed-bets.template.html',
    styleUrls: ['./completed-bets.component.scss']
})
export class CompletedBetsComponent implements OnInit {
    @Output() private expand = new EventEmitter<IWager>();
    @Output() private contract = new EventEmitter<undefined>();

    @Input() bet: IWager;
    @Input() index: number;
    @Input() isToday: boolean;
    @Input() public hasFullSsn: boolean;
    @Input() public isVideoToggledOn: boolean;
    @Input() isFinishOrderToggledOn: boolean;
    @Input() public todaysBetsContainer: boolean = false;

    public wagerDisplayStatus = WagerDisplayStatus;

    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        this.isBetPad = (value === enumProgramViews.BETPAD);
    }
    public get programView(): enumProgramViews {
        return this._programView;
    }

    public readonly DEFAULT_FINISH_ORDER = '-';

    // Expose EventClickType so we can use it in the template for click events
    public eventClickType = EventClickType;
    public isProgramAvailable: boolean = false;
    public wagerTotalClassName: string = null;
    public unsubmittedCondWager = false;

    public myBetSharesValue: string;
    public finishOrder: string = this.DEFAULT_FINISH_ORDER;
    public betStatus: string;
    public betAmount: string | number;

    public isBetPad: boolean = false;

    public toteDate: Date;
    public isAdvWagerOnPayoutDate: boolean = false; // boolean to indicate if current toteDate match the advanced
                                                    // wager's payoutDate. True for an adv wager on the payout date,
                                                    // False for adv wager not on the day or non-advanced wager
    public canShowWagerControls: boolean = true;

    constructor(
        private _expandedDetailsService: ExpandedDetailsBusinessService,
        private _raceDateService: RaceDateService) { }

    public ngOnInit() {
        this._raceDateService.isToteDay(this.bet.eventDate).pipe(
            take(1)
        ).subscribe(isToteDay => this.isProgramAvailable = isToteDay);
        this._raceDateService.getToteDate().pipe(
            take(1)
        ).subscribe(toteDate => {
            this.toteDate = toteDate;
            const payoutMoment = moment(this.bet.payoutRaceDate, 'YYYY-MM-DD');
            this.isAdvWagerOnPayoutDate = this.bet.payoutRaceDate && this.bet.payoutTrackBrisCode
                && this.bet.payoutRaceNumber && moment(this.bet.payoutRaceDate).isSame(toteDate, 'day');
            // Determine if the wager controls can be shown:
            // for normal wager and adv. wager(not future wager), they are able to display the controls,
            // But for future wager, not to display at all.
            this.canShowWagerControls =
                !(     payoutMoment.isSameOrAfter(toteDate, 'day')
                    && payoutMoment.isSameOrAfter(this.bet.activityDate, 'day')
                    && this.bet.eventCode.toUpperCase() === this.bet.payoutTrackAtabCode.toUpperCase()
                ); // eq. to !FutureWager
        });

        this.wagerTotalClassName = this._expandedDetailsService.getWagerTotalClass(this.bet.isFrozen, this.bet.wagerTotal);
        if (this.bet.betShareData.betShare) {
            this.myBetSharesValue = this._expandedDetailsService.getMyShares(+this.bet.betShareData.participantShares, +this.bet.betShareData.betShareNumberOfShares);
        }

        this.betAmount = this.bet.betShareData.betShare ? this.bet.betShareData.participantCost : this.bet.amount;
        this.betStatus = this.bet.isFrozen ? 'Pending Verification' : this.bet.status;
        if (this.bet.condWagerData.conditionalWager) {
            if (!!this.bet.condWagerData.conditionalFailureReason || this.bet.status === WagerDisplayStatus.CANCELLED) {
                this.unsubmittedCondWager = true;
            }
        }
    }

    public toggleExpandedState(): void {
        if (!this.bet.isExpanded) {
            if (this.isFinishOrderToggledOn && !this.bet.finishOrder) {
                this.getFinishOrder();
            }
            this.expand.emit(this.bet);
        } else {
            this.bet.isExpanded = false;
            this.contract.emit();
        }
    }

    public toggleSsnCollection(): void {
        this._expandedDetailsService.toggleSsnCollection(this.bet.wagerTotal);
    }

    public programNavigate(bet: IWager, video?: boolean) {
        if (this.isAdvWagerOnPayoutDate) {
            this._expandedDetailsService.programNavigate(bet.trackName, bet.payoutTrackBrisCode, bet.trackType, bet.payoutRaceNumber, video);
        } else {
            this._expandedDetailsService.programNavigate(bet.trackName, bet.brisCode, bet.trackType, Number.parseInt(bet.raceNum, 10), video);
        }
    }

    /**
     * Get the finish order for the selected bet
     * @param {CompletedBetModel} - Bet to retrieve finish order for
     */
    private getFinishOrder(): void {

        const payoutDate = this.bet.payoutRaceDate ? new Date(this.bet.payoutRaceDate + 'T00:00:00.000') : null;

        this._expandedDetailsService.getResultDetail(
            this.bet.payoutTrackBrisCode || this.bet.brisCode,
            +this.bet.payoutRaceNumber || +this.bet.raceNum,
            payoutDate || this.bet.eventDate,
            this.bet.trackType
        ).pipe(
            take(1)
        ).subscribe((data: IAvailableResultsRaceDataResponse) => {
            if (data?.raceDetails?.finishOrderString && data?.raceDetails.finishOrderString !== '') {
                this.finishOrder = data.raceDetails.finishOrderString;
            } else {
                this.finishOrder = this.DEFAULT_FINISH_ORDER;
            }
        });
    }
}
