<section *ngIf="todaysRunners.length">
    <header aria-label="Running today">Running Today</header>
    <div class="tile-container">
        <cdux-favorites-tile-trainer *ngFor="let trainer of todaysRunners" [trainerData]="trainer"></cdux-favorites-tile-trainer>
    </div>
</section>

<section *ngIf="notTodaysRunners.length">
    <header aria-label="manage stable">
      <div class="header__col1">MANAGE STABLE</div>
      <div class="header__col2" (click)="sort(notTodaysRunners)">&#x2630;&#x2193; {{ !sortDesc ? 'A/Z' : 'Z/A' }}</div>
    </header>
    <header class="header--secondary">
        <div class="row-container__col1" (click)="sort(notTodaysRunners)">Trainer</div>
        <div class="row-container__col2">Record</div>
        <div class="row-container__col3">Notes</div>
        <div class="row-container__col4">Actions</div>
    </header>

    <ng-container *ngFor="let trainer of notTodaysRunners; index as i">
        <div class="row-container" [class.first]="i === 0">
            <div class="row-container__col1">
                <span id="fav-trainer-{{ trainer.firstName + trainer.lastName }}" class="trainer-name">{{
                  formatName(trainer.firstName, trainer?.middleName,trainer.lastName) }}</span>
                <span role="link" class="link" tabindex="0" (click)="openStatsModal(trainer.personId, formatName(trainer.firstName, trainer?.middleName,trainer.lastName))">Stats</span>
            </div>

            <div id="fav-trainer-record" class="row-container__col2">
                <span class="row-container__title-name">Record</span>
                <span class="row-container__content">{{formatRecord(trainer.record)}}</span>
            </div>
            <div id="fav-trainer-notes" class="row-container__col3">
                <span id="trainer-notes" class="notes">{{ trainer.comment }}</span>
            </div>
            <div class="row-container__col4">
                <span id="fav-trainer-edit" aria-label="edit this trainer" class="link" (click)="edit(trainer)">Edit</span>
                <span aria-hidden="true" class="text__gray2">|</span>
                <span id="fav-trainer-remove" aria-label="remove this trainer" class="link" (click)="remove(trainer)">Remove</span>
            </div>
        </div>
    </ng-container>
</section>

<cdux-favorites-manager-empty id="no-favorite-runners" *ngIf="noFavorites"></cdux-favorites-manager-empty>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #removeModalTemplate>
    <div class="favorite-modal">
      <i class="icon--close" (click)="dismissModal()"></i>
      <h2>
        <span id="favorite-remove-modal-description" class="runner">
          Are you sure you want to remove {{ trainerName }}?
        </span>
      </h2>
      <div class="message">You will no longer receive alerts and all notes will be lost.</div>
      <div class="button-row">
        <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
        <div class="btn-remove" (click)="confirmRemove()">Remove</div>
      </div>
    </div>
  </ng-template>

<ng-template #updateModalTemplate>
    <div class="favorite-modal">
      <i class="icon--close" (click)="dismissModal()"></i>
      <h2>
        <span id="favorite-update-modal-description" class="runner">
          {{ formatName(modalTrainer.firstName, modalTrainer?.middleName,modalTrainer.lastName) }}
        </span>
      </h2>
      <textarea id="favorite-add-modal-note"
                cols="50" [maxlength]="COUNT_MAX" name="note"
                placeholder="ADD A NOTE..." rows="13"
                [(ngModel)]="note"
      ></textarea>
      <div class="char-count"></div>
      <div class="btn-update" (click)="confirmUpdate(modalTrainer)">Update</div>
    </div>
</ng-template>

<ng-template #statsModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1"></div>
      <div class="col2"></div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div class="scrollable">
      <cdux-favorite-stats-trainer
        [detailOptions]="options"
      ></cdux-favorite-stats-trainer>
    </div>
  </div>
</ng-template>

