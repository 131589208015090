
import { TemplatePortal } from "@angular/cdk/portal";
import { Component, ViewChild, TemplateRef } from "@angular/core";
import { Subject } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { CduxMediaToggleService, enumMediaTarget, ModalRef, ModalService, DropupModalConfig, DropupPrebuiltComponent } from "@cdux/ng-platform/web";
import { EventTrackingService } from "app/shared/event-tracking/services/event-tracking.service";
import { EventClickType } from "@cdux/ng-common";

@Component({
    selector: 'cdux-program-summary-glossary',
    templateUrl: './program-summary-glossary.component.html',
    styleUrls: ['./program-summary-glossary.component.scss']
})
export class ProgramSummaryGlossaryComponent { 

    public headerText: string;
    @ViewChild('programSummaryGlossaryPortal')
    private _programSummaryGlossaryPortal: TemplatePortal<any>;
    @ViewChild('programSummaryGlossaryModal')
    private _programSummaryGlossaryModal: TemplateRef<any>;
    private _modalRef: ModalRef<any>;


    constructor (
        private _mediaService: CduxMediaToggleService,
        private _modalService: ModalService,
        private _eventTrackingService: EventTrackingService
    ) { }

    public openSummaryGlossary(): void {
        const closingSubject = new Subject<void>(); // proxy to modal
        this._mediaService.registerQuery(enumMediaTarget.PHONE).pipe(
            distinctUntilChanged(), takeUntil(closingSubject)
        ).subscribe((isPhone) => {
            if (this._modalRef) {
                // close on transition
                this._modalRef.close();
            } else {
                this._modalRef = isPhone ? this._openDropup() : this._openModal();
                this._modalRef.afterClosed.subscribe(() => {
                    closingSubject.next();
                    this._modalRef = null;
                });
            }
        });
        this.logClick(EventClickType.PROGRAM_SUMMARY_INFO)
    }

    public logClick(_logClick: EventClickType): void {
        this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_SUMMARY_INFO);
    }

    private _openModal(): ModalRef<any> {
        const modalRef = this._modalService.open(this._programSummaryGlossaryModal, {
            hasBackdrop: true,
            overflow: 'hidden',
            padding: '0'
        });

        return modalRef;
    }

    private _openDropup(): ModalRef<any> {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.maxWidth = '100vw';
        modalConfig.maxHeight = '100vh';
        modalConfig.context = {
            close: () => this.closeSummaryGlossary(),
            headerText: 'Race Summary',
            component: this._programSummaryGlossaryPortal
        };

        const modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        modalRef.componentInstance.close.pipe(
            takeUntil(modalRef.afterClosed)
        ).subscribe(() =>
            modalRef.close()
        );

        return modalRef;
    }

    public closeSummaryGlossary(): void {
        this._modalRef?.close();
    }
}