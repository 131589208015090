import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventClickType, ISearchResult } from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';

@Component({
  selector: 'cdux-search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss']
})
export class SearchResultListComponent {

  @Input() public resultList: ISearchResult[];
  @Input() public searchString: string;

  @Output() resultClicked: EventEmitter<ISearchResult> = new EventEmitter();

  constructor(
    private _sidebarService: SidebarService,
    private _eventTrackingService: EventTrackingService
  ) {}

  public clicked($event): void {
    this.resultClicked.emit($event);
    // Log Click Event SEARCH_RUNNER_RESULT_CLICKED: ID 527
    this._eventTrackingService.logClickEvent(EventClickType.SEARCH_RUNNER_RESULT_CLICKED);
  }

  public cancel() {
    this._sidebarService.close(true);
  }

  /**
   * Create a composite key that we can use to track the list of results
   */
  public resultItemKey(index: number, resultItem: ISearchResult): string {
    return [
      new Date(resultItem.raceDate).getDate(),
      resultItem.raceNumber,
      resultItem.postPosition
    ].join('-');
  }

  // TODO: Add filter functions that will filter the results based on the selected type
  public noResults(): boolean {
    return this.searchString && this.searchString.length > 2 && this.resultList && this.resultList.length === 0;
  }
}
