/**
 * Pipe deprecated, refactored to utility 2.22.19
 */
export class FormatDistanceUtil {
    /**
     * Format the distance into a string for display.
     * NOTE: Passing value as a negative indicates that its absolute distance is an approximation.
     * This is a holdover from the old code.
     *
     * @param {number} value
     * @param {string} unit M = meters, F = furlongs, Y = yards
     * @returns {string}
     */
    public static format(value: number, unit: string) {
        if (!value || !unit) {
            return '';
        }

        let result = '';
        let yards = 0;
        const isApproximation = value < 0; // A negative value indicates an approximation.

        // Get a positive distance, in the event an approximation was indicated via a negative value.
        value = Math.abs(value);

        // Normalize the distance to yards.
        switch (unit.toUpperCase()) {
            case 'M': // Meters
                yards = value * 1.09361;
                break;

            case 'F': // 1/100 Furlongs
                yards = value * 2.2;
                break;

            case 'Y': // Yards
                yards = value;
                break;
        }

        // Discard partial yards to avoid float issues.
        yards = Math.floor(yards);

        if (yards < 880) {
            // If it's less than 0.5 miles, I specify yards.

            result = yards + 'yds';
        } else if (yards < 1760) {
            // If the distance is less than a mile, I'm showing furlongs (1/8th of a mile)
            // with remaining yards or furlong fraction.

            result += Math.floor(yards / 220);
            yards %= 220;

            const map = {
                0: '', // Flat furlong.
                55: ' 1/4', // Quarter of a furlong.
                110: ' 1/2', // Half a furlong.
                165: ' 3/4' // Three fourths of a furlong.
            };

            if (yards in map) {
                // I've got a furlong fraction for a yardage remainder.
                result += map[yards] + 'f';
            } else {
                // I'm showing yardage for a remainder.
                result += 'f ' + yards + 'yds';
            }
        } else {
            // The distance is a mile or more. Show miles and remaining yardage.

            result += Math.floor(yards / 1760); // Miles
            yards %= 1760; // Remaining yards

            const sixteenths = Math.floor(yards / 110); // Sixteenths of a mile
            yards %= 110; // Remaining yards

            if (sixteenths) {
                // This is a stand-in for a recursive function from TSUI. It's more readable and iterative!
                const getGreatestCommonDivisor = (a: number, b: number) => {
                    a = Math.abs(a);
                    b = Math.abs(b);

                    if (b > a) {
                        const temp = a;
                        a = b;
                        b = temp;
                    }

                    do {
                        if (b === 0) {
                            return a;
                        }

                        a %= b;
                        if (a === 0) {
                            return b;
                        }

                        b %= a;
                    } while (a);
                };

                const gcd = getGreatestCommonDivisor(sixteenths, 16);

                // With this, we can turn 2/16 into 1/8.
                result += ' ' + sixteenths / gcd + '/' + 16 / gcd;
            }

            // Now we'll have 3 1/8m
            result += 'm';

            if (yards) {
                // If there's remaining yards, make it 3 1/8m 3yds
                result += ' ' + yards + 'yds';
            }
        }

        // Prepend an asterisk if the distance is approximate.
        return (isApproximation ? '*' : '') + result;
    }
}
