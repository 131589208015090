import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { RunnerSearchDataService } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { SearchFieldAbstract } from 'app/shared/program-search/components/search-field-abstract.component';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';

@Component({
    selector: 'cdux-search-menu-item',
    templateUrl: './search-menu-item.component.html',
    styleUrls: ['./search-menu-item.component.scss']
})
/**
 * This is not extending AbstractMenuItemComponent because it will not load in the
 * sidebar. The abstract requires the sidebar to be defined and passed in to the
 * super() call.
 */
export class SearchMenuItemComponent extends SearchFieldAbstract {

    @ViewChild('searchField') searchField: ElementRef;

    public isSearchOpen: boolean;

    constructor(
        localRouter: Router,
        localSearchSvc: RunnerSearchDataService,
        localTodaysRacesSvc: TodaysRacesBusinessService,
        localWagerUtilSvc: WageringUtilBusinessService,
        localEventTrackingService: EventTrackingService
    ) {
        super(
            localRouter,
            localSearchSvc,
            localTodaysRacesSvc,
            localWagerUtilSvc,
            localEventTrackingService
        );
    }

    /**
     * Set boolean to true, adding the `is-open` class to the input so it will display
     * then set focus on the search field.
     */
    public openSearch(): void {
        this.isSearchOpen = true;
        this.focusField();
    }

    /**
     * Close the search field, clear the results, and clear the search string.
     * @param forceClose boolean
     */
    public closeSearch(): void {
        this.modelChanged.next('');
        this.searchString = '';
        this.isSearchOpen = false;
    }

    /**
     * Return boolean to determine if the results should be shown
     */
    public showResultsPanel(): boolean {
        return (this.searchString && this.searchString.length > 3);
    }
}
