import { BehaviorSubject, concat, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, switchMap, take, takeUntil } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    ITrackBasic,
    ITrainerDriverRaceSummary,
    ToteDataService,
    TrackService,
    TracksDataService,
    TrainerDriverSummaryDataService
} from '@cdux/ng-common';
import { ViewableWagerDataHandler } from 'app/shared/betpad/classes/viewable-wager-data-handler.class';

export class TrainerDriverSummaryRequestHandler extends CduxRxJSBuildingBlock<any, ITrainerDriverRaceSummary> {

    protected _stream: Observable<ITrainerDriverRaceSummary>;

    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false); // start un-paused
    private _viewableWagerDataHandler: ViewableWagerDataHandler = new ViewableWagerDataHandler(this._tracksDataService, this._configurationDataService);
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _configurationDataService: ConfigurationDataService,
        private _tracksDataService: TracksDataService,
        private _toteDataService: ToteDataService,
        private _trainerDriverSummaryDataService: TrainerDriverSummaryDataService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._viewableWagerDataHandler.updateRaceNavigation(track);
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = concat(of(null), this._viewableWagerDataHandler.listen()).pipe(
            distinctUntilChanged((x, y) => x?.trainerStats === y?.trainerStats && x?.driverStats === y?.driverStats),
            switchMap(viewableWagerData => viewableWagerData?.trainerStats || viewableWagerData?.driverStats ?
                this._pause : of(true) // force pause if trainer/driver stats not viewable
            ),
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                distinctUntilChanged((x, y) => TrackService.isExactTrackObject(x, y)),
                switchMap(track => this._getTrainerDriverSummaries(track, true))
            )),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<ITrainerDriverRaceSummary>;
    }

    /** CUSTOM OBSERVABLES **/
    private _getTrainerDriverSummaries(basicTrack: ITrackBasic, poll: boolean = false): Observable<ITrainerDriverRaceSummary> {
        const brisCode = basicTrack && basicTrack.BrisCode || null;
        const trackType = basicTrack && basicTrack.TrackType || null;
        const raceNum = basicTrack && basicTrack.RaceNum || null;
        const raceDetailsObs = this._toteDataService.currentRaceDate(poll).pipe(
            switchMap(raceDate =>
                this._trainerDriverSummaryDataService.getTrainerDriverRaceSummary(brisCode, trackType, raceDate, raceNum)
            ),
            catchError(() => of<ITrainerDriverRaceSummary>(null))
        );
        return poll ? raceDetailsObs : raceDetailsObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/
}
