import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { enumTrackType, IntegratedScratchService, TrackService } from '@cdux/ng-common';

import { RaceData } from './data-classes/race-data';
import { EntryData, IChangesDescription, enumChangeTypes } from './data-classes/entry-data';

@Injectable()
export class TrackChangesService {

    // Format correctly the changes string and label. This business specific logic.
    private static _formatChangesString(entry: EntryData, trackType: enumTrackType, useDescriptiveFormatting: boolean = true): IChangesDescription {
        // at the time IChangesDescription object was added, changes.label was created
        // to allow special visual handling of the label text in TS TUX
        const changes: IChangesDescription = {description: '', label: '', reason: ''};
        const changeType = entry.changeType.toLowerCase();

        switch (changeType) {
            case enumChangeTypes.SCRATCH :
                if (useDescriptiveFormatting) {
                    changes.description += ' is ';
                }
                changes.label = 'SCRATCHED';
                if (entry.description && entry.description.toLowerCase() !== 'tote') {
                    changes.reason = entry.description;
                }
                break;
            case enumChangeTypes.JOCKEY :
                const riderType = TrackService.getRiderTypeForTrackType(trackType) || 'Jockey';
                if (useDescriptiveFormatting) {
                    changes.description += ` - ${riderType} is ${entry.jockeyName}`;
                } else {
                    changes.description += `${riderType} is ${entry.jockeyName}`;
                }
                break;
            case enumChangeTypes.WEIGHT :
                if (useDescriptiveFormatting) {
                    changes.description += ' has weight change by ' + entry.weightDifference + ' lbs';
                } else {
                    changes.description += 'Weight change by ' + entry.weightDifference + ' lbs';
                }
                break;
            case enumChangeTypes.WEIGHT_CORRECTION :
                if (useDescriptiveFormatting) {
                    changes.description += ' weight corrected to ' + entry.weight + ' lbs';
                } else {
                    changes.description += 'Weight corrected to ' + entry.weight + ' lbs';
                }
                break;
            case enumChangeTypes.OTHER :
                changes.description += ' (' + entry.description + ')';
                break;
            case enumChangeTypes.NEW :
                if (useDescriptiveFormatting) {
                    changes.description += ' is a ';
                }
                changes.label = 'NEW ENTRY';
                break;
        }

        return changes;
    }

    // Process the entry changes records. Adding the changes attribute.
    private static _formatEntry(entry: any, trackType: enumTrackType, useDescriptiveFormatting: boolean): EntryData {
        const returnEntry: EntryData = {};

        if (entry && entry['HorseName']) {
            returnEntry.horseName = (entry['HorseName'] !== '[NO NAME]') ? entry['HorseName'] : '';
            returnEntry.changeType = entry['ChangeType'];
            returnEntry.description = entry['Description'];
            returnEntry.jockeyName = entry['JockeyName'];
            returnEntry.newProgramNumber = entry['NewProgramNumber'];
            returnEntry.programNumber = entry['ProgramNumber'];
            returnEntry.weightDifference = entry['WeightDifference'];
            returnEntry.weight = entry['Weight'];
            returnEntry.changes = TrackChangesService._formatChangesString(returnEntry, trackType, useDescriptiveFormatting);
        }
        return returnEntry;
    }

    // Process the race records into a object.  Keeping possible relevant data for componenets.
    private static _formatRace(race: any, useDescriptiveFormatting: boolean): RaceData {
        const returnRace: RaceData = {};

        if (race && race['Race']) {
            returnRace.raceNumber = race['Race'];
            returnRace.brisCode = race['BrisCode'];
            returnRace.trackType = race['Type'];
            returnRace.trackDisplayName = race['DisplayName'];
            returnRace.lastUpdate = race['LastUpdate'];
            returnRace.conditions = race['Conditions'];
            returnRace.entries = [];

            for (const horse of race['EntryChanges']) {
                if (horse['ChangeType'] && horse['ChangeType'] !== 'No Changes') {
                    returnRace.entries.push(TrackChangesService._formatEntry(horse, returnRace.trackType, useDescriptiveFormatting));
                }
            }
        }

        return returnRace;
    }

    // Loop through the races returned from the data service.
    private static _morphData(serviceData: Array<any>, useDescriptiveFormatting: boolean): Array<RaceData> {
        const races: Array<any> = [];

        if (serviceData) {
            for (const object of serviceData) {
                races.push(TrackChangesService._formatRace(object, useDescriptiveFormatting));
            }
        }
        return races;
    }

    /**
    * This business service provides needed business logic for track-changes
    * module. Pass in the data service to get track changes.
    *
    * @param IntegratedScratchService
    */
    constructor(private integratedScratchService: IntegratedScratchService) { }

    /**
     * The method will return the track changes for a given track with the
     * changes string added to the object.
     *
     * @param brisCode
     * @param trackType
     * @returns {Array|null}
     */
    public getTrackChanges(brisCode: string, trackType: enumTrackType, useDescriptiveFormatting: boolean = true): Observable<RaceData[]> {
        return this.integratedScratchService.getIntegratedScratches(brisCode, trackType).pipe(
            map(data => TrackChangesService._morphData(data, useDescriptiveFormatting))
        );
    }
}
