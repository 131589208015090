import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import { ErrorBarError } from '../../interfaces/error-bar-error.interface';

@Component({
    selector: 'cdux-quickbet-error',
    templateUrl: './bet-error-bar.component.html',
    styleUrls: ['./bet-error-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetErrorBarComponent {

    private _dismissAction: () => void;
    private _dismissButtonText = 'OKAY';

    @Input() public set error(errorConfig: ErrorBarError) {
        errorConfig = errorConfig || {} as ErrorBarError;
        this.dismissButtonText = errorConfig.button;
        this._dismissAction = errorConfig.action;
        this.errorMessage = errorConfig.message;
    }

    /**
     * Stop using the Input. Use the error input, instead.
     * @deprecated
     */
    @Input() errorMessage: string;

    /**
     * Stop using the Input. Use the error input, instead.
     * @deprecated
     */
    @Input()
    public set dismissButtonText(text: string) {
        this._dismissButtonText = text || 'OKAY';
    }

    public get dismissButtonText(): string {
        return this._dismissButtonText;
    }

    @Output() errorClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    public onClose(): void {
        this.errorClose.emit(false);
    }
    public onDismiss(): void {
        if (!!this._dismissAction) {
            this._dismissAction();
        }
        this.errorClose.emit(true);
    }
}
