import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { Observable, ReplaySubject, Subject } from 'rxjs/index';
import { finalize, multicast, refCount, scan, startWith, takeUntil } from 'rxjs/operators';

export interface ISortState<T = string> {
    sortProperty: T,
    ascending: boolean
}

export class SortStateHandlerClass<T> extends CduxRxJSBuildingBlock<any, ISortState<T>> {
    protected _stream: Observable<ISortState<T>>;
    private _updateSortProperty: Subject<T> = new Subject<T>();
    private _defaultAscending: { [property: string]: boolean } = {};
    private _forceDirection: boolean = null; // true = Ascending, false = Descending, null = do not force direction

    /**
     * Updates the sort column, will sort by default asc relies on default configuration or optional force direction parameter
     *
     * If forceAscending === true, will force ascending sort
     * If forceAscneidng === false, will force descending sort
     * If forceAscending is not passed, will rely on default or previous sort direction
     *
     * @param sortProperty
     * @param forceAscending
     */
    public updateSortProperty(sortProperty: T, forceAscending?: boolean) {
        // force the sort direction if provided
        if (!!forceAscending) {
            this._forceDirection = forceAscending;
        }
        this._updateSortProperty.next(sortProperty);
    }

    public updateDefaultAscending(sortProperty: T, defaultAscending: boolean) {
        /* istanbul ignore next */
        this._defaultAscending[sortProperty.toString()] = !!defaultAscending;
    }

    /**
     * Constructor
     */
    constructor(private _initialSortState: ISortState<T>) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._updateSortProperty.complete();
    }


    protected _init() {
        this._stream = this._updateSortProperty.pipe(
            scan<T, ISortState<T>>((previousSortState, target) => {
                let ascending: boolean;

                if (!!this._forceDirection) {
                    ascending = this._forceDirection;
                    // reset the force direction so the next update isn't also forced
                    this._forceDirection = null;
                // if the column is the same as the previous, reverse the order
                } else if (previousSortState.sortProperty === target) {
                    ascending = !previousSortState.ascending;
                } else {
                    // else find the default sort direction
                    ascending = this._isDefaultAscending(target);
                }
                return { sortProperty: target, ascending: ascending };
            }, this._initialSortState),
            startWith(this._initialSortState),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            multicast(() => new ReplaySubject(1)),
            refCount()
        ) as Observable<ISortState<T>>;
    }


    /**
     * Return the default sort direction for a configured column
     *
     * @param sortProperty
     */
    private _isDefaultAscending(sortProperty: T): boolean {
        /* istanbul ignore next */
        return !!(sortProperty && this._defaultAscending
            && this._defaultAscending[sortProperty.toString()]);
    }
}
