export const LANG_PROGRAM_TOOLTIPS_NAME = 'program-tooltips';

export const LANG_PROGRAM_TOOLTIPS_INFO = {
    'odds-title': 'Odds',
    'odds-detail': 'Current win odds which may fluctuate until betting pools are closed and tabulated.',

    'odds-trend-title': 'Odds Trend',
    'odds-trend-detail': 'Oldest (left) to newest (right) sequence of recent odds changes.  Depicted with a green arrow when odds increase, red arrow when odds decrease.',

    'ml-adv-title': 'Morning Line (M)',
    'ml-basic-title': 'Morning Line (ML)',
    'ml-detail': 'The estimated winning odds of the runner as set by track handicapper before the start of betting.',

    'pl-title': 'Profit Line (PL)',
    'pl-detail': 'The estimated fair value winning odds of runner based on computer modeling with the lowest Profit Line odds considered the most likely winner.',

    'runner-info-title': 'Runner',
    'runner-info-adv-detail': 'Color, sex, age, where bred state, medication (L for Lasix, *L for 1st-Time-Lasix).',
    'runner-info-basic-detail': 'Name of runner.',
    'runner-info-grey-adv-detail': 'Name of Runner, gender, and age.',

    'med-weight-equip-title': 'Medication (MED) Weight (WT) Equipment (EQP)',
    'med-weight-equip-detail': 'Medication (L for Lasix, *L for 1st-Time-Lasix), Weight assigned to carry, and any Equipment changes (bOn - Blinkers on; bOff - Blinkers off).',
    'med-weight-equip-grey-title': 'Weight',
    'med-weight-equip-grey-detail': 'Weight of runner, if available.',

    'jockey-info-title': 'Jockey',
    'jockey-info-adv-detail': 'Win Percentage - the jockey\'s win percentage for the last 365 days.',
    'jockey-info-basic-detail': 'Jockey\'s name.',
    'jockey-stats-title': 'Jockey Stats',
    'jockey-stats-detail': 'the jockey\'s record for the last 365 days (number of starts, winners, second place finishes, & third place finishes).',

    'driver-info-title': 'Driver',
    'driver-info-adv-detail': 'Win Percentage - the driver\'s win percentage (#starts) for the last 365 days.',
    'driver-info-basic-detail': 'Driver\'s name.',

    'trainer-info-title': 'Trainer',
    'trainer-info-tbred-adv-detail': 'Win Percentage - the trainer\'s win percentage for the last 365 days.',
    'trainer-info-harn-adv-detail': 'Win Percentage - the trainer\'s win percentage (#starts) for the last 365 days.',
    'trainer-info-grey-adv-detail': 'Name of trainer.',
    'trainer-info-basic-detail': 'Trainer\'s name.',
    'trainer-stats-detail': 'Trainer Stats - the trainer\'s record for the last 365 days (number of starts, winners, second place finishes, & third place finishes).',

    'sire-dam-info-title': 'Sire / Dam',
    'sire-detail': 'Sire - the father of the runner.',
    'dam-detail': 'Dam - the mother of the runner.'
};
