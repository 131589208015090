import { Component, Input } from "@angular/core";
import { IExpertPickAnalysis } from "@cdux/ng-common/services/data/adw-track/interfaces/expert-pick-analysis.interface";
@Component({
    selector: 'cdux-expert-pick-bio',
    templateUrl: './expert-pick-bio.component.html',
    styleUrls: ['./expert-pick-bio.component.scss']
})
export class ExpertPickBioComponent {
    @Input()
    public expertPick: IExpertPickAnalysis;
}
