export const LANG_TOURNAMENTS_FAILURE_MODALS_ERRORS_NAME = 'tournaments-failure-modals';

export const LANG_TOURNAMENTS_FAILURE_MODALS_ERRORS = {
    'technicalError': "We have experienced a technical error while attempting to create your tournament account. Please contact player support <a href='%s'>%s</a>",
    'accountIneligible': "Your account is not eligible for participation in %s",
    'notEligibleState': "Players from %s are not eligible to participate in %s",
    'tournamentIsFull': "We're sorry, all seats in %s are filled",
    'maxSeatsReached': "You have reached the maximum number of entries for %s",
    'trackRestricted': "Players from %s are not eligible to participate in %s",
    'enrollmentError': "We're sorry, we are unable to enroll you into this tournament."
};
