<ng-template #content>
  <p class="center-text small-text">Doing so will have you login again to your bank.</p>
  <br>
  <div class="submitBtnWrapper">
    <button class="button-primary-outline-white" (click)="closeModal(false)">Cancel</button>
    <button class="button-primary" type="submit" (click)="action(true)">Replace</button>
    <br>
  </div>
</ng-template>

<ng-template #switchBankModal>
  <div class="modal-inner-wrap custom-modal">
    <div class="modal-header">
      <h2 class="h2--standard text--uppercase">Are you sure you want to<br>replace your bank account?</h2>
      <div class="close-btn-wrap" (click)="closeModal(false)">
        <i class="icon--close dark" title="Click to close box or cancel"></i>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #reVerifyModal>
  <div class="modal-inner-wrap custom-modal">
    <div class="modal-header">
      <h2 class="h2--standard text--uppercase">Thank you for re-verifying your<br>bank account.</h2>
      <div class="close-btn-wrap" (click)="closeModal()">
        <i class="icon--close dark" title="Click to close box or cancel"></i>
      </div>
    </div>
    <p class="center-text small-text">Your initial funding transaction was not processed.</p>
    <p class="center-text bold-text small-text">Please resubmit your Deposit to proceed.</p>
    <br>
    <div class="submitBtnWrapper">
      <button class="button-primary" type="submit" (click)="action()">Okay</button>
      <br>
    </div>
  </div>
</ng-template>

<ng-template #switchBankDropup >
  <div class="modal-inner-wrap custom-modal">
    <br>
    <br>
    <br>
  <div class="modal-header">
    <h2 class="h2--standard text--uppercase">Are you sure you want to<br>replace your bank account?</h2>
  </div>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #reVerifyDropup>
  <div class="modal-inner-wrap custom-modal">
    <br>
    <br>
    <br>
  <div class="modal-header">
    <h2 class="h2--standard text--uppercase">Thank you for re-verifying your<br>bank account.</h2>
  </div>
  <p class="center-text small-text">Your initial funding transaction was not processed.</p>
  <p class="center-text bold-text small-text">Please resubmit your Deposit to proceed.</p>
  <br>
  <div class="submitBtnWrapper">
    <button class="button-primary" type="submit" (click)="action()">Okay</button>
    <br>
  </div>
  </div>
</ng-template>

<ng-template #reVerify>
  <ng-container *ngTemplateOutlet="isSmallGlass ? reVerifyDropup : reVerifyModal"></ng-container>
</ng-template>

<ng-template #switchBank>
  <ng-container *ngTemplateOutlet="isSmallGlass ? switchBankDropup : switchBankModal"></ng-container>
</ng-template>

<ng-container *ngTemplateOutlet="isReVerify ? reVerify : switchBank"></ng-container>

