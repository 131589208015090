<nav class="program-nav compact combined-views has-content-nav">
    <nav class="program-view-tabs">
        <ul>
            <li id="wps-pools"
                [class.is-selected]="section === ViewSectionEnum.POOLS"
                (click)="section !== ViewSectionEnum.POOLS && viewPools()">POOLS</li>
            <li id="probables"
                [class.is-selected]="section === ViewSectionEnum.PROBABLES"
                (click)="section !== ViewSectionEnum.PROBABLES && viewProbables()">PROBABLES</li>
            <li id="will-pays" [class.is-selected]="section === ViewSectionEnum.WILL_PAYS"
                (click)="section !== ViewSectionEnum.WILL_PAYS && viewWillPays()">WILL PAYS</li>
        </ul>
    </nav>
</nav>

<nav *ngIf="hasOffloadedContent()" class="content-nav compact has-offloaded-content">
    <div class="offloaded-content compact">
        <ng-container *ngIf="section === ViewSectionEnum.POOLS">
            <div class="button smallest"
                (click)="currentPoolSubView !== poolSubView.TOTALS && viewPoolTotals()"
                [class.is-active]="currentPoolSubView === poolSubView.TOTALS">Totals</div>
            <div class="button smallest"
                (click)="currentPoolSubView !== poolSubView.PERCENTAGES && viewPoolPercentages()"
                [class.is-active]="currentPoolSubView === poolSubView.PERCENTAGES">Percentages</div>
        </ng-container>
        <ng-container *ngIf="section === ViewSectionEnum.PROBABLES">
            <div class="button smallest"
                *ngIf="probablesAvailablePoolTypes[enumPoolType.EXACTA]"
                (click)="currentProbablePoolType !== enumPoolType.EXACTA && viewExacta()"
                [class.is-active]="currentProbablePoolType === enumPoolType.EXACTA">Exacta</div>
            <div class="button smallest"
                *ngIf="probablesAvailablePoolTypes[enumPoolType.QUINELLA]"
                (click)="currentProbablePoolType !== enumPoolType.QUINELLA && viewQuinella()"
                [class.is-active]="currentProbablePoolType === enumPoolType.QUINELLA">Quinella</div>
            <div class="button smallest"
                *ngIf="probablesAvailablePoolTypes[enumPoolType.DAILY_DOUBLE]"
                (click)="currentProbablePoolType !== enumPoolType.DAILY_DOUBLE && viewDouble()"
                [class.is-active]="currentProbablePoolType === enumPoolType.DAILY_DOUBLE">Double</div>
        </ng-container>
    </div>
</nav>

<section class="pools-sidebar-container" [ngSwitch]="section">
    <cdux-pools-shared
        *ngSwitchCase="ViewSectionEnum.POOLS"
        [track]="track"
        [race]="race"
        [currentView]="currentPoolSubView"
        [displayMode]="displayMode">
    </cdux-pools-shared>
    <cdux-probables-shared
        *ngSwitchCase="ViewSectionEnum.PROBABLES"
        [track]="track"
        [race]="race"
        [displayMode]="displayMode"
        [poolType]="currentProbablePoolType"
        [allowedPoolTypes]="probablesAllowedPoolTypes"
        (updateProbableAvailablePoolTypes)="updateProbablesAvailablePoolTypes($event)"
        (resetPoolType)="resetProbablesPoolType()"
        (poolTypeChange)="currentProbablePoolType = $event">
    </cdux-probables-shared>
    <cdux-will-pays-shared *ngSwitchCase="ViewSectionEnum.WILL_PAYS" [track]="track" [race]="race"></cdux-will-pays-shared>
</section>
