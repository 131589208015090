import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    // TemplateRef,
    // ViewChild,
    // ViewContainerRef
} from '@angular/core';
import { Portal, /*TemplatePortal*/ } from '@angular/cdk/portal';
// import { OverlayRef } from '@angular/cdk/overlay';
// import { Router } from '@angular/router';

// import { /*RoutingOptions,*/ ToastService } from '@cdux/ng-fragments';
import {
    // EventClickType,
    IFavoriteTrackDisplay,
    // ITodaysRace,
    // TranslateService
} from '@cdux/ng-common';
// import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
// import { WageringUtilBusinessService } from '../../../shared/program/services/wagering-util.business.service';

@Component({
    selector: 'cdux-favorite-tile-track',
    templateUrl: './favorites-tile-track.component.html',
    styleUrls: [ './favorites-tile-track.component.scss' ],
})
export class FavoritesTileTrackComponent implements OnInit, AfterViewInit {
    @Input() public favoriteData: Partial<IFavoriteTrackDisplay>;
    @Input() public isRunningToday = false;

    public idTrackCode: string;

    // @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>; // TODO uncomment later when used

    public modalContent: Portal<any>;
    // public removeTemplatePortal: TemplatePortal<any>; // TODO uncomment later when used
    // private _overlayRef: OverlayRef; // TODO uncomment later when needed

    public constructor(
        // private _eventTrackingService: EventTrackingService, // TODO may need later for clickevent
        // private _router: Router, // TODO uncomment later when need
        // private _toastService: ToastService, // TODO uncomment later when needed
        // private _translateService: TranslateService, // TODO uncomment later when needed
        // private _viewContainerRef: ViewContainerRef, // TODO uncomment later when needed
        // private _wageringUtilBusinessService: WageringUtilBusinessService, // TODO uncomment later when needed
    ) {}

    ngOnInit() {
        this.idTrackCode = this.favoriteData && this.favoriteData.brisCode ? this.favoriteData.brisCode.toLowerCase() : null;
    }

    ngAfterViewInit() {
        /*this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        )*/// TODO uncomment when needed to implement removing fav track
    }

    /*public confirmRemove() {
        this._runnerService.remove(this.favoriteData.uuid).then((removed) => {
            // Because the runner service removes the favorite from the cache and the management page
            // is subscribed to that cache, we don't need to do anything in the event of a successful
            // removal. We only need to alert the user to a failed removal.
            if (!removed) {
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }*/// TODO uncomment when needed to implement removing track

    /*public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }*/// TODO uncomment later when needed

    /*public remove () {
        // Log Event Click REMOVE_FAV_RUNNER from Fav Runner Card:
        this._eventTrackingService.logClickEvent(EventClickType.FAV_RUNNER_REMOVE);
        this.modalContent = this.removeTemplatePortal;
    }*/// TODO uncomment later when needed

    /*public bet() {
        // Log Event Click BET_LINK_FAV_RUNNER:
        this._eventTrackingService.logClickEvent(EventClickType.FAV_RUNNER_BET_LINK);
        const trackName = this.favoriteData['track']?.formattedTrackName || '';
        const brisCode = this.favoriteData['brisCode']?.toLowerCase() || null;
        const race = this.favoriteData['raceNumber'];
        const type = this.favoriteData['track']?.type || 'Thoroughbred'; // Default to Thoroughbred

        const routingOpt = this.getRoutingOptionFromFavData();
        const progUrl = this._wageringUtilBusinessService.getGoToProgramOrResultUrl(routingOpt, brisCode, type, race, trackName);
        this._router.navigateByUrl(progUrl);
    }*/// TODO uncomment later when needed to implement BET link

    /**
     * This method will check to see if we should redirect user to Program or Results
     * based on favorite runner's participating track/race and current race on the fav runner related track
     * If the favorite runner's participating race is closed and results official, then should goto results
     * otherwise, goto program.
     */
    /*public getRoutingOptionFromFavData(): RoutingOptions {
        let retRoutingOpt = RoutingOptions.PROGRAM;
        // If the favoriteData is type of IFavoriteRunnerDisplay, then we can compare:
        // raceNumber > track.currentRaceNumber: then should go to program since race is not running
        // raceNumber <= track.currentRaceNumber: then should check the 'status' field on track.races[i] with matching raceNumber:
        // if status == 'Closed' then the race result is available and Bet link should goto result, otherwise goto program
        // In order to make the comparison, the track object need to be on the favoriteData
        const brisCodeOnFav = this.favoriteData['brisCode']?.toLowerCase() || null;
        if (brisCodeOnFav && this.favoriteData['track'] && this.favoriteData['track'].races && this.favoriteData['track'].currentRaceNumber && brisCodeOnFav === this.favoriteData['track'].brisCode) {
            const races = this.favoriteData['track']?.races || null;
            if (races && this.favoriteData['raceNumber'] <= this.favoriteData['track'].currentRaceNumber) {
                const foundRace: ITodaysRace[] = races.filter(arace =>
                    arace.raceNumber === this.favoriteData['raceNumber']
                    && arace.status === 'Closed');
                if (foundRace && foundRace.length) {
                    retRoutingOpt = RoutingOptions.RESULTS;
                }
            }
        }
        return retRoutingOpt;
    }*/// TODO uncomment later when needed to implement BET link
}
