import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[cduxHover]',
})
export class CduxHoverDirective {

    @Output() public cduxHover: EventEmitter<boolean> = new EventEmitter();

    @HostListener('mouseenter', ['$event']) protected _onTouchStart() {
        this.cduxHover.emit(true);
    }

    @HostListener('mouseleave', ['$event']) protected _onMouseDown() {
        this.cduxHover.emit(false);
    }
}
