// https://blogs.msdn.microsoft.com/premier_developer/2018/06/17/angular-how-to-simplify-components-with-typescript-inheritance/

import { Injector } from '@angular/core';

/**
 * This doesn't seem to be 100% reliable. While it continues to work
 * in AbstractProgramComponent, it could not be integrated into
 * RCNBaseVideoFeed. Specifically, ChangeDetectorRef can't be injected
 * using this approach (see also, https://github.com/angular/angular/issues/14656).
 * It also seems to fail to properly inject VideoProviderService.
 *
 * @deprecated
 */
export class AppInjectorService {
    private static injector: Injector;

    static setInjector (injector: Injector) {
        AppInjectorService.injector = injector;
    }

    static getInjector (): Injector {
        return AppInjectorService.injector;
    }
}
