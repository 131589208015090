import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

import { FavEvent, FavEventType } from '../../../account/favorites/favorites-event-interface';
import { AccountBubbleNotificationComponent } from '../../my-account/my-account-container/account-bubble-notification.component';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { SidebarService } from '../../sidebar/sidebar.service';
import { ToastService } from '@cdux/ng-fragments';

@Injectable({
    providedIn: 'root'
})
export class AccountBubbleNotificationService {
    private _eventBroker = new BehaviorSubject<FavEvent>(null);

    public constructor(
        private _toastService: ToastService,
        private _sidebarService: SidebarService,
    ) {}

    /**
     * A reference can be found helpful to demonstrate the purpose of the implementation
     * which implements a event queue that can be used by a component to communicate with other components
     * about a event happens. In our case, when the favorite runner toggle get clicked for adding or removing the runner
     * from favorite, we need this information to be notifiable to other component, such as the profile tab(My Account)
     * where a Notification should display about the favorite runner change.
     * The component call dispatch() to add the notification event to the queue,
     * the receiving component call on() method to subscribe and receive the notification event. Ref:
     * https://medium.com/@Armandotrue/broadcasting-events-in-angular-b85289a4d685
     */

    /**
     * Release a favorite get toggled (either "Added" or "Removed") event matching one of the FavEventType
     * @param {FavEventType} eventTypes
     * @return {Observable}
     */
     public on(eventTypes: FavEventType[]): Observable<FavEvent> {
        return this._eventBroker.asObservable().pipe(
            filter(event => eventTypes.includes(event?.type))
        );
    }

    /**
     * Push a favorite toggled event to the service.
     * @param {FavEvent} event
     */
    public dispatch(event: FavEvent): void {
        this._eventBroker.next(event);
    }

    public delay(event: FavEvent, duration: number): void {
        setTimeout(() => {
            this.dispatch(event)
        }, duration);
    }

    /**
     * Display a Toast message: current default to placed at bottom, default display duration 3000 milliseconds
     * @deprecated This method should not be used. Instead, use NotificationBubbleService.open in @cdux/ng-platform
     * @param {string} eventMessage
     */
    public openNotificationToast(eventMessage: string) {
        this._toastService.open(eventMessage, {
            // id: 'favorite-message',
            panelClass: ['message-container', 'action-info'],
            disableAction: true,
            hideCheck: true,
            duration: 3000, // default duration 3 seconds TODO resume 3 seconds after CSS debugging
            horizontalPosition: 'center',
            verticalPosition: 'bottom'
        });
    }

    /**
     * Display a dropdown notification on sidebar on Profile target tab with default duration 3000 milliseconds
     * @deprecated This method should not be used. Instead, use NotificationBubbleService.open in @cdux/ng-platform
     * @param {string} message
     * @param {number} displayTime in milliseconds
     * @param {MenuItemsEnum} target
     */
    public openNotification(message: string, displayTime?: number, target?: MenuItemsEnum) {
        const options = {
            'displayTime': displayTime || 3000, // default duration 3000 milliseconds
            'message': message,
            'navTarget': target || null
        };
        this._sidebarService.loadComponent(AccountBubbleNotificationComponent.getSidebarComponent(options), null, {
            clearHistory: true,
            disableBackdropClick: true
        });
    }
}
