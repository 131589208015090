import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
    AccountDataService,
    enumTrackType,
    IAvailableResultsRaceDataResponse,
    IGetFundingDetailsRequest,
    IGetFundingDetailsResponse,
    IRaceIdentifier,
    StringSlugifyPipe,
    CduxDateUtil,
} from '@cdux/ng-common';

import { catchError, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SidebarService } from '../../sidebar/sidebar.service';
import { SsnCollectionSidebarComponent } from '../../ssn-collection/ssn-collection-sidebar.component';
import { WagerTotalClass } from '../enums';
import { ResultsService } from 'app/shared/results/services/results.business.service';

@Injectable()
export class ExpandedDetailsBusinessService {

    constructor(
                private accountDataService: AccountDataService,
                private _router: Router,
                private _stringSlugify: StringSlugifyPipe,
                private _sidebarService: SidebarService,
                private _resultsService: ResultsService,
    ) {}

    /**
     * This method is intend to replace getFinishOrder. This new function will check results api for results detail data
     * so that to caller can retrieve finishOrder from a string field in raceDetails object.
     *
     * @param brisCode
     * @param raceNumber
     * @param raceDate
     * @param trackType
     */
    public getResultDetail(brisCode: string, raceNumber: number, raceDate: Date, trackType: enumTrackType): Observable<IAvailableResultsRaceDataResponse> {
        const requestObj: IRaceIdentifier = {
            brisCode: brisCode.toLowerCase(),
            raceNumber: raceNumber,
            raceDate: CduxDateUtil.convertDateToWSFormat(raceDate),
            trackType: trackType
        };
        return this._resultsService.getResultsDetails(requestObj, false).pipe(
            take(1),
            catchError(() => of(null))
        );
    }

    public getFundingDetails(category: string, fundTypeKey: string): Observable<IGetFundingDetailsResponse> {
        const requestObj: IGetFundingDetailsRequest = {
            category: category,
            fundTypeKey: fundTypeKey
        };

        return this.accountDataService.getFundingDetailsData(requestObj);
    }

    public getMyShares(participantShares: number, betShares: number): string {
        return participantShares + ' of ' + betShares + ' (' + ((participantShares / betShares) * 100).toFixed(0) + '%)';
    }

    public programNavigate(trackName: string, brisCode: string, trackType: enumTrackType, raceNum: number, video?: boolean) {
        const navArr = ['/program', this._stringSlugify.transform(trackName), brisCode, trackType, raceNum];
        if (video) { navArr.push('video'); }

        this._router.navigate(navArr);
        this._sidebarService.close(true);
    }

    public toggleSsnCollection(value: number): void {
        this._sidebarService.loadComponent(SsnCollectionSidebarComponent.getSidebarComponent(value), SsnCollectionSidebarComponent.getHeaderComponent());
    }

    public getWagerTotalClass(isFrozen: boolean, wagerTotal: number): string {
        if (isFrozen) {
            return WagerTotalClass.PENDING;
        } else if (wagerTotal < 0) {
            return WagerTotalClass.NEGATIVE_AMOUNT;
        } else if (wagerTotal > 0) {
            return WagerTotalClass.POSITIVE_AMOUNT;
        }
        return null;
    }
}
