import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    BasicBetType,
    enumBetModifier,
    enumFeatureToggle,
    enumRaceStatus,
    FeatureToggleDataService,
    ISelectedBetAmount,
    ITrackBasic,
    MultiRaceExoticBetType,
    TrackService,
    WagerState
} from '@cdux/ng-common';
import { ISelectionUpdate } from '../../shared/program/interfaces/selection-update.interface';
import { ViewSectionEnum } from '../views/enums/view-section.enum';
import { WagerEvent, WageringView } from '../../shared/wager-views/interfaces';
import { ErrorBarError } from '../../shared/bet-slip/interfaces/error-bar-error.interface';
import { DisplayModeEnum } from '../../shared/common/enums/display-mode.enum';
import { AbstractWageringViewEventComponent } from '../../shared/wager-views/components';
import { WageringViewEnum } from '../views/enums/wagering-view.enum';
import { WagerEventTypeEnum } from 'app/shared/wager-views';
import { QuickPickSidebarComponent } from 'app/shared/bets/components/quick-pick-sidebar/quick-pick-sidebar.component';

@Component({
    selector: 'cdux-compact-view',
    templateUrl: 'compact-view.component.html',
    styleUrls: ['./compact-view.component.scss'],
})
export class CompactViewComponent extends AbstractWageringViewEventComponent implements WageringView {

    @Output() public sectionChange: EventEmitter<ViewSectionEnum> = new EventEmitter<ViewSectionEnum>();
    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() public updateEntrySelection: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();
    @Output() public resetEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public stashEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public restoreEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public outboundEvents: EventEmitter<WagerEvent> = new EventEmitter<WagerEvent>();
    @Output() public dismissError: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public set raceStatus(raceStatus: enumRaceStatus) {
        this._raceStatus = raceStatus;
        this.isWagerableRace = TrackService.isWagerableRace(raceStatus);
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }
    private _raceStatus: enumRaceStatus;

    @Input() public view: WageringViewEnum;

    public DisplayModeEnum = DisplayModeEnum;
    public ViewSectionEnum = ViewSectionEnum;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.COMPACT;
    @Input() public section: ViewSectionEnum;

    public isWagerableRace: boolean = false;
    public areEntriesSelected = false;
    private _wagerState: WagerState;
    @Input()
    set wager(v: WagerState) {
        if (!TrackService.isSameTrack(v?.basicTrack, this._wagerState?.basicTrack) ||
            v?.basicTrack?.RaceNum !== this._wagerState?.basicTrack?.RaceNum) {
            this.quickPickSidebar?.close();
        }
        this._wagerState = v;
        this.areEntriesSelected = this._areEntriesSelected();
    }
    get wager(): WagerState {
        return this._wagerState;
    }

    @Input() public fundingEnabled: boolean = false;
    @Input() public racesRestrictedTo: enumRaceStatus[];
    @Input() public error: ErrorBarError;

    @ViewChild(QuickPickSidebarComponent)
    public quickPickSidebar: QuickPickSidebarComponent;
    public isQuickPickSidebarEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.QUICK_PICK);

    public showAfterSubmission: boolean = false;

    public get displayTotalBar(): boolean {
        return this.areEntriesSelected || this.showAfterSubmission;
    }

    constructor (private _featureToggleService: FeatureToggleDataService) {
        super();
    }

    public isSection(section: ViewSectionEnum | ViewSectionEnum[]): boolean {
        return this.section === section || Array.isArray(section)
            && section.indexOf(this.section) > -1;
    }

    // This method has been duplicated from abstract-bet-total.component.ts
    private _areEntriesSelected(): boolean {
        if (this._wagerState && this._wagerState.bettingInterests) {
            return this._wagerState.bettingInterests.reduce((p, c) => p || !!c && c.length > 0, false);
        }
        return false;
    }

    public handleEvent($event: WagerEvent) {
        this.outboundEvents.emit($event);
        if ($event.type === WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS || $event.type === WagerEventTypeEnum.REPEAT_WAGER_SUBMISSION_ERROR) {
            this.showAfterSubmission = true;
        } else {
            this.showAfterSubmission = false;
        }
    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        this.quickPickSidebar?.close();
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        this.quickPickSidebar?.close();
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        this.quickPickSidebar?.close();
        this.updateBetAmount.emit(betAmount);
    }

    public onUpdateEntrySelections(selection: ISelectionUpdate[]): void {
        this.quickPickSidebar?.close();
        this.updateEntrySelection.emit(selection);
    }
}
