import { ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap } from 'rxjs/operators';

import { GetStatsDataService, ITrackBasic, Stats, ToteDataService, TrackService } from '@cdux/ng-common';
import { DisplayModeEnum } from 'app/shared';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';


export enum StatsView {
    POST_BIAS = 'post-bias',
    TRACK_BIAS = 'track-bias',
    RUN_STYLE = 'run-style',
    SUCCESS_RATES = 'success-rates'
}


@Component({
    selector: 'cdux-stats-shared',
    templateUrl: './stats-shared.component.html',
    styleUrls: ['./stats-shared.component.scss']
})
export class StatsSharedComponent {

    public ViewSectionEnum = ViewSectionEnum;

    public isLoading = false;
    private _loadingDelay = 200; // for request debouncing

    @ViewChild('offloadedTemplate')
    public offloadedTemplate: TemplateRef<any>;

    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isSameTrack(track, this._track)) {
            this._track = track;
            this._update.next();
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._update.next();
        }
    }
    public get race(): number {
        return this._race;
    }

    @Input()
    public statsView = ViewSectionEnum.STATS_POST_BIAS;

    @Input() public displayMode: DisplayModeEnum;
    public DisplayModeEnum = DisplayModeEnum;

    public statsObs: Observable<Stats>;

    private _track: ITrackBasic;

    private _race: number;

    private _update = new ReplaySubject<void>(1);


    constructor (
        private _changeDetector: ChangeDetectorRef,
        private _statsDataService: GetStatsDataService,
        private _toteDataService: ToteDataService
    ) {
        this.statsObs = combineLatest([
            this._update.asObservable(),
            this._toteDataService.currentRaceDate(true)
                .pipe(distinctUntilChanged())
        ]).pipe(
            skipWhile(() => !(this._track && this._race)),
            tap(() => this.isLoading = true),
            debounceTime(this._loadingDelay),
            switchMap(([_update, currentRaceDate]) => this._statsDataService.getBrisStats(
                this._track && this._track.BrisCode,
                this._race,
                new Date(currentRaceDate) // getBrisStats does .toISOString().substring(0, 10)
            )),
            map((data: Stats) => {
                this._changeDetector.markForCheck();
                if (data.trackBiasStatsMeet && data.trackBiasStatsWeek) {
                    return data;
                } else {
                    return null;
                }
            }),
            catchError(() => of(null)),
            tap(() => this.isLoading = false)
        );
    }
}
