import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CduxNgCommonModule, EventSessionModule } from '@cdux/ng-common';
import { CduxQAAutomationModule } from '@cdux/ng-platform/web';
import { MtpModule } from '@cdux/ng-fragments';
import { ScrollableTrackComponent } from './components/scrollable-track/scrollable-track.component';
import { TuxCommonModule } from 'app/shared/common/common.module'; // ../../../shared/common/common.module';
import { FavoritesModule } from 'app/account/favorites/favorites.module';

@NgModule({
    imports: [
        CduxNgCommonModule,
        CduxQAAutomationModule,
        CommonModule,
        EventSessionModule,
        FavoritesModule,
        MtpModule,
        RouterModule,
        TuxCommonModule,
    ],
    declarations: [
        ScrollableTrackComponent
    ],
    exports: [
        ScrollableTrackComponent
    ],
})
export class ScrollableContentModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<ScrollableContentModule> {
        return {
            ngModule: ScrollableContentModule
        }
    }
}
