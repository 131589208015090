import { ENVIRONMENT, ENVIRONMENTS, AFFIDS } from '@cdux/ng-core';

const affId = AFFIDS.OAKLAWNANYWHERE;
const username = 'my_tux';

export const environment: ENVIRONMENT = {
    environment: ENVIRONMENTS.PRODUCTION,
    production: true,
    affiliateId: affId,
    domain: 'oaklawnanywhere.com',
    username: username,
    setSecureCookie: true,
    accountMenuConfig: {
        hideRecallTodaysBets: true,
        hideTaxDocuments: false,
        hideDetailedAccountHistory: true,
        hideStableAlert: false
    },
    thirdPartyConfig: {
        'Box': {
            'Folder': '7667404974',
            'Token': 'zmdxugp0tzsi6ax51n29u4341f730wej',
            'Token_Simplified_Upload': '4723136851c84f2a84cc010323bcfc08',
        },
        'RightNow': {
            'Subdomain': 'oaklawnanywhere',
            'Category': 10
        },
        'ZendeskChatURL': 'https://static.zdassets.com/ekr/snippet.js?key=8c23c3ce-c02d-40bf-8769-86be3aaecb14'
    },
    appConfig: {
        servicesBaseUrl: '',
        username: username,
        environmentUsername: username,
        platformId: 'TUX',
        affiliateId: affId,
        clientIp: '0.0.0.0',
        outputType: 'json'
    }
};
