import { Component, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { FundingService } from '../../../shared/services/funding.service';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service'
import { FundingPaypalService } from '../../../shared/services/paypal.service';
import { ISidebarComponentProperties, ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';

import { FundingPaypalMethodHeaderComponent } from './paypal-method-header.component';
import { PaypalMethodDepositAbstractComponent } from './paypal-method-deposit-abstract.component';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-paypal-method-deposit',
    templateUrl: './paypal-method-deposit.component.html'
})
export class FundingPaypalMethodDepositComponent extends PaypalMethodDepositAbstractComponent {

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingPaypalMethodDepositComponent, options);
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingPaypalMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */


    constructor(
        _router: Router,
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventsTrackingService: EventTrackingService,
        _translateService: TranslateService,
        _cdr: ChangeDetectorRef,
        _paypalService: FundingPaypalService,
        _betSlipService: BetSlipBusinessService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            _router,
            _cdr,
            _eventsTrackingService,
            _paypalService,
            _betSlipService,
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _translateService,
            localSessionService,
            localHeaderService,
            _featureToggleService
        );
    }
}
