<section class="cdi-terms-wrapper lightBackground">

  <div class="container cdi-terms" style="padding-top: 1px;">
    <div class="cdi-terms-content">
        <h3>Authorization Agreement</h3>
        <p>By accepting the terms of this Authorization Agreement, I authorize this ADW wagering site (e.g., {{ 'affiliate-name' | cduxTranslate:affiliateId }}, Inc.)
        MERCHANT to initiate Debits and Credits to my bank account at the Depository Financial institution "BANK", as indicated
        by the Transit Routing Number that I have supplied on this form, and BANK to pay such Debit or Credit. This authorization
        is to remain in full force and effect until MERCHANT or BANK has received written notification from me of its termination
        in such time and in such manner as to afford MERCHANT or BANK a reasonable opportunity to act on it.</p>

      <p>I agree that the electronic media record of my transactions held by MERCHANT (
        <a href="mailto:{{ 'player-services-email' | cduxTranslate:affiliateId }}" target="_top">{{ 'player-services-email' | cduxTranslate:affiliateId }}</a>) shall be used as
        the final determination to resolve any dispute I may have. I understand it is my sole responsibility, if applicable,
        to report my financial information to my respective Government, Customs, or Tax jurisdiction. I acknowledge that
        I have read all the information contained in the Legal Notices served by this MERCHANT i.e., ADW wagering company
        (such as {{ 'affiliate-name' | cduxTranslate:affiliateId }}, Inc., etc.) and agree to abide by all the rules, terms, conditions, and agreements therein and
        as amended from time to time.</p>

      <p>Additionally, I authorize MERCHANT to issue payment in accordance with electronic requests for the withdrawal of funds
        from my account and I understand that no withdrawal of funds requests will be processed without a signature on file.</p>

      <p>I additionally agree to the <a href="https://plaid.com/legal/#end-user-privacy-policy" target="_blank">Plaid End User Privacy Policy.</a></p>
    </div>
  </div>

</section>
