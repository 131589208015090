<div class="stable-alerts__main">
  <header aria-label="Stable Alerts Management" class="favorite-management__header--main">
    <h1>STABLE ALERTS</h1>
    <nav class="stable-alerts__nav--secondary">
      <button
        *ngIf="canSearchTab() && stableSearchFT"
        aria-label="search to add to your stable alerts"
        class="icon-button icon--magnifying-glass-plus"
        (click)="openStableSearch()"></button>
      <div 
        class="stable-alerts__nav--secondary__separator"
        *ngIf="stableSearchFT && settingsManagementFT"></div>
      <button
        aria-label="change your stable alert settings"
        class="icon-button icon--gear"
        *ngIf="settingsManagementFT"
        (click)="openSettingManagement()"
      ></button>
  </nav>
  </header>
  <nav aria-label="stable alerts navigation" class="stable-alerts__nav--main">
    <div class="list-container">
      <ul>
        <li *ngFor="let tab of tabs; index as i"
            id="{{tab}}"
            attr.aria-label="{{'View '+ tab }}"
            [class.is-selected]="selectedTabIndex === i"
            role="link"
            (click)="selectTab(i)"
        >{{ tab }} <i *ngIf="displayBadge(tab)" class="notice-badge"></i></li>
      </ul>
    </div>
  </nav>

  <ng-container *ngIf="isLoading">
    <div *cduxLoading="OVERLAY_NAME; strategy:'replace'; component:LOADING_COMPONENT;"></div>
  </ng-container>

  <ng-template #favTiles></ng-template>
</div>


