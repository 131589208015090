import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventClickType } from '@cdux/ng-common';

@Component({
    selector: 'cdux-bet-slip-footer',
    templateUrl: './bet-slip-footer.component.html',
    styleUrls: ['./bet-slip-footer.component.scss']
})
export class BetSlipFooterComponent {

    /**
     * Number of bets in the bet slip.
     *
     * @type {number}
     */
    @Input() public betCount: number = 0;

    /**
     * Total cost of the bets in the bet slip.
     *
     * @type {string}
     */
    @Input() public totalAmount: string = '0';

    /**
     * Are all the bets believed to be valid?
     *
     * @type {boolean}
     */
    @Input() public valid: boolean = false;

    /**
     * Enables/Disables submit all button if submission in progress
     * @type {boolean}
     */
    @Input() public submitAllInProgress: boolean = false;

    /**
     * Emits an event intended to trigger submission of the bet slip.
     *
     * @type {EventEmitter<undefined>}
     */
    @Output() submit: EventEmitter<undefined> = new EventEmitter<undefined>();

    /**
     * Exposes the event click types to the template.
     *
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    /**
     * Used to start the bet slip submission process.
     */
    public triggerSubmission() {
        if (!this.submitAllInProgress) {
            this.submit.emit();
        }
    }

}
