/**
 * This directive prevents click event propagation(bubbling).
 * If both a parent and child element have click events, and you
 * wish to prevent the parent click event from firing when the
 * child is clicked, place this directive on the child element.
 * See https://stackoverflow.com/questions/35274028/stop-event-propagation-in-angular-2
 */
import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[cduxClickStopPropagation]'
})
export class ClickStopPropagationDirective {
    @HostListener('click', ['$event']) public onClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
