import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JwtSessionService } from '@cdux/ng-common';
import {
    SsnCollectionService,
    AccountHistoryModule as FragmentsAccountHistoryModule,
    AccountHistoryService,
    AccountHistoryBetsService,
    ExpandedBetsService
} from '@cdux/ng-fragments';

import { TransactionModule } from 'app/shared/transaction/transaction.module';
import { SsnCollectionService as TuxSSNCollection } from 'app/shared/ssn-collection/services/ssn-collection.service';

import { AccountHistoryContainerComponent } from './account-history-container.component';
import { BetsCommonService } from 'app/shared/bets/services/bets-common.service';
import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { ExpandedDetailsBusinessService } from 'app/shared/transaction/services/expanded-details.business.service';
import { AccountHistoryBusinessService } from 'app/shared/my-account/account-history';
import { AccountHistoryBetsFactory } from './account-history-bets.factory';
import { AccountHistoryWrapperComponent } from './account-history-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        TransactionModule,
        FragmentsAccountHistoryModule,
    ],
    declarations: [
        AccountHistoryContainerComponent,
        AccountHistoryWrapperComponent
    ],
    providers: [
        {
            provide: SsnCollectionService,
            useFactory: (jwtSessionService: JwtSessionService, ssnCollection: TuxSSNCollection) => {
                return {
                    hasFrozenWager(): boolean {
                        return jwtSessionService.getUserInfo().frozenWager;
                    },
                    ssnUpdated: ssnCollection.ssnUpdated,
                    hasFullSsn(): boolean {
                        return ssnCollection.hasFullSsn();
                    }
                };
            },
            deps: [ JwtSessionService, TuxSSNCollection ]
        },
        {
            provide: AccountHistoryBetsService,
            useFactory: AccountHistoryBetsFactory,
            deps: [BetsCommonService, BetsBusinessService]
        },
        {
            provide: ExpandedBetsService,
            useExisting: ExpandedDetailsBusinessService
        },
        {
            provide: AccountHistoryService,
            useClass: AccountHistoryBusinessService
        }
    ],
    exports: [
        TransactionModule,
    ],
})
export class AccountHistoryModule {}
