import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    HostListener,
    ChangeDetectionStrategy
} from '@angular/core';
import { Subscription } from 'rxjs';

import {
    EventClickType,
    EventClickAttributeType,
    FavoriteTracksService,
    IEventClickDetailsAttribute,
    ITrack,
    ITrackBasic,
    TrackService
} from '@cdux/ng-common';

import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';
import { enumTrackViews } from 'app/shared/common/enums/track-views.enum';

@Component({
    selector: 'cdux-favorite-track',
    templateUrl: './favorite-track.component.html',
    styleUrls: ['./favorite-track.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteTrackComponent implements OnDestroy, OnInit {

    private _track: ITrack | ITrackBasic;
    @Input()
    public set track(value: ITrack | ITrackBasic) {
        if (!TrackService.isSameTrack(this._track, value)) {
            this._track = value;
            this.refresh();
        }
    }
    public get track(): ITrack | ITrackBasic {
        return this._track;
    }

    @Input()
    public disableClick: boolean = false;

    @Input() public inHeader: boolean = false;
    @Input() public largeStar: boolean = false;
    @Input() public inView: enumTrackViews;

    public favorites: Subscription;
    public isSelected = false;

    @HostListener('click', ['$event'])
    public toggle(event: MouseEvent) {
        if (this.disableClick) {
            return;
        }

        event.stopPropagation();
        const attribute: IEventClickDetailsAttribute = {
            attrId: null, // filled in based on selected state
            data: this.track.BrisCode + ':' + this.track.TrackType,
            timestamp: new Date().getTime()
        };

        if (!this.isSelected) {
            attribute.attrId = EventClickAttributeType.FAVORITE_TRACK_CODE_ADDED;
            this.eventTrackingService.logClickEvent(EventClickType.FAVORITE_TRACK_ADD, attribute);
            this.favoriteTracksService.addFavoriteTrack(this.track);
        } else {
            attribute.attrId = EventClickAttributeType.FAVORITE_TRACK_CODE_REMOVED;
            this.eventTrackingService.logClickEvent(EventClickType.FAVORITE_TRACK_REMOVE, attribute);
            this.favoriteTracksService.removeFavoriteTrack(this.track);
        }
    }

    constructor(
        private eventTrackingService: EventTrackingService,
        private favoriteTracksService: FavoriteTracksService,
        private changeDetector: ChangeDetectorRef
    ) {}

    private refresh() {
        const _isSelected = this.favoriteTracksService.isFavoriteTrack(this.track);

        // if we aren't changing the value, skip the rest of the process
        if (this.isSelected === _isSelected) {
            return;
        }

        this.isSelected = _isSelected;

        // In cases where the same track was being displayed twice on the page and
        // the user (de-)selected on of the tracks, the other wouldn't immediately
        // update visually. This forces the UI to update if the selected value has changed.
        this.changeDetector.detectChanges();
    }

    ngOnInit() {
        this.favorites = this.favoriteTracksService.getFavoriteTracks()
                             .subscribe(favorites => this.refresh());
    }

    ngOnDestroy() {
        this.favorites.unsubscribe();
    }
}
