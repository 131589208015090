import { Component, OnInit } from '@angular/core';
import { AbstractManageInputComponent } from '../abstract-manage-input.component';
import { enumManageAccountType } from '../../enums/manage-account.enum';

@Component({
    selector: 'cdux-email-input',
    templateUrl: './email-input.component.html',
    styleUrls: ['./email-input.component.scss']
})
export class EmailInputComponent extends AbstractManageInputComponent implements OnInit {

    constructor() {
        super(enumManageAccountType.EMAIL);
    }

    ngOnInit() {
        this.newControl = this.formGroup.get('newEmail');
        this.confirmControl = this.formGroup.get('confirmEmail');
        this.passwordControl = this.formGroup.get('emailPassword');
    }

}
