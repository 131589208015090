import { Component, Input, ViewEncapsulation } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'cdux-bris-picks-comment',
    template: '<div class="bris-picks-comment" [innerHTML]="comment"></div>',
    styleUrls: ['./bris-picks-comment.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BrisPicksCommentComponent {
    @Input()
    comment: SafeHtml;
}
