import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { CduxNgCommonModule, EventSessionModule, TranslateModule, WagerModule } from '@cdux/ng-common';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';
import { TuxCommonModule } from '../common/common.module';
import { TourModule } from './../tour/tour.module';

import { VideoModule } from '../../wagering/video/video.module';

import { SidebarModule } from '../sidebar/sidebar.module';
import { MenuItemsModule } from '../menu-items/menu-items.module';

import { HeaderComponent } from './components/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuResponsiveComponent } from './components/menu-responsive/menu-responsive.component';
import { PopUpModule } from '../pop-up/pop-up.module';
 
@NgModule({
    imports: [
        RouterModule,
        PortalModule,
        CommonModule,
        CduxFormsModule,
        CduxMediaModule,
        SidebarModule,
        TuxCommonModule,
        SharedFragmentsModule,
        EventSessionModule,
        WagerModule,
        TourModule,
        PopUpModule,
        TranslateModule,
        MenuItemsModule,
        VideoModule,
        CduxNgCommonModule,
    ],
    declarations: [
        HeaderComponent,
        MenuComponent,
        MenuResponsiveComponent,
    ],
    exports: [
        HeaderComponent,
        MenuComponent,
        MenuResponsiveComponent
    ],
})
export class HeaderModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<HeaderModule> {
        return {
            ngModule: HeaderModule,
            providers: [
            ],
        }
    }
}
