<div cduxVisible (isVisible)="onVisibilityChange($event)" [visibilityTopMargin]="tuxNavigationBottom">
    <div class="video-player-container" #videoContainer [class.vp-open]="showVideo" [class.reset-overflow]="positionFixed">
        <div class="video-player-holder"
            [class.reserve-space-for-video-tab-bar]="videoComponent?.showTabBar"
            [class.move-bottom]="positionFixed && (!onSmallGlass || !disableFixedPosition) && !(onSmallGlass && hasError)">
            <div class="video-menu-holder">
                <!--In the event we are not on desktop or we haven't moved the video don't bother showing the menu at all-->
                <div *ngIf="isDesktop || positionFixed" class="video-menu">
                    <cdux-pop-up *ngIf="isDesktop && popUpVideoPath" class="popout-btn" [path]="popUpVideoPath" [windowName]="windowName" [popUpWidth]="432" [popUpHeight]="430">
                        <div class="popout-btn" (click)="popUpVideoClick()"></div>
                    </cdux-pop-up>
                    <div class="close-btn"
                         *ngIf="positionFixed"
                         (click)="positionFixed = false"
                         [cduxLogClick]="eventClickType.VIDEO_DISMISS"
                         [cduxLogClickAttributeType]="eventClickAttributeType.SIMULTANEOUS_VIDEO_COUNT"
                         [cduxLogClickAttributeData]=""></div>
                </div>
                <cdux-video *ngIf="showVideo"
                    [controls]=false
                    [show]="showVideo"
                    [track]="track"
                    [race]="race"
                    [raceDate]="raceDate"
                    [liveRaceNumber]="liveRaceNumber"
                    [onStandalone]="false"
                    [disableLiveReplayToggle]="disableLiveReplayToggle"
                    [(feedState)]="feedState"
                    (error)="handleError($event)">
                </cdux-video>
            </div>
        </div>
    </div>
</div>
