import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    ITrackBasic,
    ITrainerJockeyEntrySummary,
    ToteDataService,
    TrainerJockeySummaryDataService
} from '@cdux/ng-common';

export class TrainerJockeySummaryRequestHandler extends CduxRxJSBuildingBlock<any, ITrainerJockeyEntrySummary[]> {

    protected _stream: Observable<ITrainerJockeyEntrySummary[]>;

    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false); // start un-paused
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _toteDataService: ToteDataService,
        private _trainerJockeySummaryDataService: TrainerJockeySummaryDataService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    /**
     * Initializes the stream.
     */
    public _init() {
        this._stream = this._pause.pipe(
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                switchMap(track => this._getTrainerJockeySummaries(track, false))
            )),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<ITrainerJockeyEntrySummary[]>
    }

    /** CUSTOM OBSERVABLES **/
    private _getTrainerJockeySummaries(basicTrack: ITrackBasic, poll: boolean = false): Observable<ITrainerJockeyEntrySummary[]> {
        const brisCode = basicTrack && basicTrack.BrisCode || null;
        const trackType = basicTrack && basicTrack.TrackType || null;
        const raceNum = basicTrack && basicTrack.RaceNum || null;
        const raceDetailsObs = this._toteDataService.currentRaceDate(poll).pipe(
            switchMap(raceDate =>
                this._trainerJockeySummaryDataService.getTrainerJockeyTrackSummary(brisCode, trackType, raceDate, raceNum)
            ),
            map(summary => {
                const race = summary && summary.races &&
                    summary.races.find(r => r.raceNumber === raceNum);
                return race && race.starts;
            }),
            catchError(() => of<ITrainerJockeyEntrySummary[]>(null))
        );
        return poll ? raceDetailsObs : raceDetailsObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/
}
