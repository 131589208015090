import { Component } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { CduxSidebarContentComponent } from '../../../../sidebar/cdux-sidebar-content-component.class';

@Component({
  selector: 'cdux-funding-toc',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: [ './terms-and-conditions.component.scss' ]
})
export class FundingTermsAndConditionsComponent  extends CduxSidebarContentComponent {

    public readonly affiliateId = this._environment.affiliateId;

    /* IMPLEMENT CduxSidebarContentComponent
       * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {

        return {
            component: FundingTermsAndConditionsComponent,
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */

     constructor(
        private _environment: ENVIRONMENT,
     ) {
         super();
     }
}
