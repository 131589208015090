import { Injectable } from '@angular/core';

import { IVideoStreamResponse } from '@cdux/ng-common';

import { IVideoStreamSource } from './video-stream-source.interface';

import { VideoProviderService } from '../../video-provider.service';

@Injectable()
export class RCNVideoStreamSource implements IVideoStreamSource {
    constructor(private videoProviderService: VideoProviderService) {}

    public getStreamData (): Promise<IVideoStreamResponse> {
        /*
         * By this point in the LiveVideoFeedFactory process, we have
         * already called getLiveStreams, in order to determine the provider
         * and which *StreamSource to use (getLivePlayerType).
         *
         * Therefore, it's not necessary to call it again. Instead,
         * use the streamData stored in VideoProviderService.
         */
        return new Promise<IVideoStreamResponse>((resolve) => {
            resolve(this.videoProviderService.streamData);
        });
    }
}
