// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { ENVIRONMENT, AFFIDS } from '@cdux/ng-core';

import { environment as affOaklawnAnywhere } from './1400/environment.1400.prod';
import { environment as affTwinspires } from './2800/environment.2800.prod';
import { environment as affBetAmerica } from './3000/environment.3000.prod';
import { environment as affKeenelandSelect } from './4100/environment.4100.prod';
import { environment as affCapitalOTBBet } from './4200/environment.4200.prod';
import { environment as affBarStool } from './6100/environment.6100.prod';
import { environment as affDKHorse } from './6200/environment.6200.prod';

let env: ENVIRONMENT;
switch ((<any>window).APPAFF) {
    case AFFIDS.OAKLAWNANYWHERE:
        env = affOaklawnAnywhere;
        break;
    case AFFIDS.TWINSPIRES:
        env = affTwinspires;
        break;
    case AFFIDS.BETAMERICA:
        env = affBetAmerica;
        break;
    case AFFIDS.KEENELANDSELECT:
        env = affKeenelandSelect;
        break;
    case AFFIDS.CAPITALOTBET:
        env = affCapitalOTBBet;
        break;
    case AFFIDS.BARSTOOL:
        env = affBarStool;
        break;
    case AFFIDS.DKHORSE:
        env = affDKHorse;
        break;
    default:
        throw Error('No affiliate has been defined.');
}

export const environment: ENVIRONMENT = env;
