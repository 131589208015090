import { NgModule, ModuleWithProviders } from '@angular/core';
import { TuxCommonModule } from '../common/common.module';

import { EventTrackingService } from './services/event-tracking.service';

@NgModule({
    imports: [
        TuxCommonModule,
    ],
})
export class EventTrackingModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<EventTrackingModule> {
        return {
            ngModule: EventTrackingModule,
            providers: [
                EventTrackingService,
            ],
        }
    }
}
