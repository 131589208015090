import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';

import {
    enumTrackType,
    IReplayRequestOptions,
    IReplayStream,
    IReplayStreamResponse,
    IVideoStreamResponse,
    ReplayDataService,
    ReplayFormats,
    VideoProviderName,
    VideoProviderType,
    VideoUtilsService,
} from '@cdux/ng-common';

@Injectable()
export class ReplayBusinessService {
    constructor(
        private _environment: ENVIRONMENT,
        private replayDataService: ReplayDataService
    ) {}

    public getReplayStream(brisCode: string, trackType: enumTrackType, raceDate: string, raceNumber: number, providerName: VideoProviderName, options: IReplayRequestOptions): Promise<IVideoStreamResponse> {
        return this.replayDataService.getReplayStreams(brisCode, trackType, raceDate, raceNumber, providerName, options).pipe(
            first(),
            map((replays: IReplayStreamResponse) => {
                // TODO - Do we still want to filter based on card/race/etc
                if (replays && replays.hasStreams) { // build response
                    const firstCard = Object.keys(replays.streams)[0];
                    return <IVideoStreamResponse>{
                        brisCode,
                        trackType,
                        affiliateId: this._environment.affiliateId,
                        platform: <unknown>options.format as VideoProviderType,
                        hasStreams: !!(replays && replays.hasStreams),
                        streams: <IReplayStream[]>[{
                            raceDate,
                            raceNumber,
                            angles: [].concat(...Object.keys(replays.streams[firstCard]).map(
                                (angle) => {
                                    return replays.streams[firstCard][angle].map(feed => {
                                        if (VideoUtilsService.isIReplayStreamMSRCN(feed)) {
                                            // TODO - do we actually need to still pass in options?
                                            feed.url = VideoUtilsService.addRequiredOptionsToURL(feed.url, [], (options.format === ReplayFormats.ANDROID))
                                        }
                                        return {
                                            angle: angle,
                                            streamData: feed
                                        }
                                    });
                                }
                            ))
                        }]
                    };
                } else {
                    throw new Error('No valid feeds were found.');
                }
            })
        ).toPromise();
    }
}
