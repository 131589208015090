<!-- Mobile Menu -->
<ng-template cdkPortal #burgerMenu="cdkPortal">
    <div class="burger-menu-items">
        <cdux-menu-responsive></cdux-menu-responsive>
    </div>
</ng-template>
<!-- End Mobile Menu -->

<!-- Header -->
<div cduxMediaSwitch>
    <!-- Mobile Header -->
    <div #mainLayout *cduxMediaSwitchCase="'phone'">
        <div class="top-nav-sml-wrapper" >
            <div class="top-nav-sml">
                <div class="col-1">
                    <ng-container *ngIf="topLevelRoute; else showBack">
                        <div class="icon-burger-menu">
                            <!-- <div class="burger-icon-wrapper is-open"> -->
                            <div class="burger-icon-wrapper" [class.is-open]="burgerMenuOpened" (click)="toggleBurgerMenu()">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #showBack>
                        <i class="icon--arrow-left" (click)="goBack()"></i>
                    </ng-template>
                </div>
                <div class="col-2" [ngSwitch]="centerColumnDataType">
                    <!-- insert varying content here-->
                    <div *ngSwitchCase="'Portal'" [cdkPortalOutlet]="portal" (attached)="_onAttached($event)"></div>
                    <div *ngSwitchCase="'String'">
                        {{ centerColumnData }}
                    </div>
                    <!-- default content is logo/badge -->
                    <div *ngSwitchDefault class="logo" routerLink="/" (click)="onMobileLogoClicked()"></div>
                </div>

                <div class="col-3">
                    <cdux-video-sync
                        *ngIf="showVideoButton"
                        (click)="toggleVideo()"
                        id="race_details_video_icon"
                        [cduxLogClick]="eventClickType.VIDEO_PLAY"
                        [isActive]="isVideoOpen">
                    </cdux-video-sync>
                </div>
            </div>
            <div class="top-nav-sml-sub">
                <div *ngIf="portalSub" [cdkPortalOutlet]="portalSub" (attached)="_onAttached($event)"></div>
            </div>
        </div>
    </div>

    <!-- End Mobile Header-->
    
    <!-- Desktop Header -->
    <div #mainLayout *cduxMediaSwitchCase="'desk-to-phone'">
        <div class="top-nav">
            <div class="top-nav__wrapper">
                <div class="ts-logo" (click)="onLogoClicked()"></div>
                <!-- Desktop Menu -->
                <cdux-menu></cdux-menu>
                <!-- End Desktop Menu -->
            </div>
            <cdux-menu-items (depositClick)="onDepositClicked()"></cdux-menu-items>
        </div>
    </div>
    <!-- End Desktop Header -->
</div>
<!-- End Header -->
