export const LANG_EZBANK_NAME = 'mazooma';

export const LANG_EZBANK_TRANS = {
  'description': 'Log in to your bank, and deposit instantly! No fees!',
  'tile-icon': '<div class="icon--vendor-logo"><i class="icon--mazooma"></i></div>',
  'header-icon': '<div class="icon--vendor-logo icon--mazooma white"></div>',
  'header-account-info': '<div class="account-number"><i class="symbol--bullet--six"></i> %s </div>',
  'pending-balance-title': 'Pending Bank Transfer',
  'pending-balance-info': 'It will take 4 business days before we have confirmation of a completed deposit transaction. During this time, you will be able to play with the funds, however, you will not be able to withdraw those funds until they have cleared your bank.',
  'funding-success-header': '<p class="alert-centered">Your deposit is complete.</p>'
};
