<form class="deposit-form-wrapper moneygram" [class.dark]="lockout">
    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>

    <div class="deposit-form-info">
        <div class="text-notes-inner-wrap">
            <cdux-moneygram-method-details [displayStyle]="enumDepositDisplayStyle.SIDEBAR"></cdux-moneygram-method-details>
        </div>
    </div>
</form>
