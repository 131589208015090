<ng-template cdk-portal #datepickerLitePortal="cdkPortal">
    <cdux-datepicker-lite [selectedDate]="selectedDate" [highlightedDates]="availableReplayDates"
        (changeMonth)="onMonthSelect($event)" (changeYear)="onYearSelect($event)" (change)="onDateSelect($event)"
        [startYear]="1994" *ngIf="isDatepickerVisible"></cdux-datepicker-lite>
</ng-template>

<div class="tab-content">
    <div [class.streaming]="!isReplay" [class.replays]="isReplay">

        <div *cduxMediaToggle="'desk-to-phone'" [cdkPortalOutlet]="datepickerLitePortal"></div>
        <ng-container *ngIf="!isReplay; else replaySelect">
            <cdux-track-list *ngIf="!isReplay" class="video track-race-dropdowns video-track-dropdown"
                name="video-track" [trackGroups]="trackGroups" [currentTrack]="track" [availability]="true"
                (onTrackChange)="onTrackSelect($event)"></cdux-track-list>
        </ng-container>
        <ng-template #replaySelect>
            <div class="calendar" [class.is-active]="isDatepickerVisible">
                    <span id="calendar-button" class="icon--calendar" (click)="toggleDatepicker()"
                        [cduxLogClick]="eventClickType.CALENDAR_DATE_FILTER"></span>
            </div>
            <cdux-dropdown class="video track-race-dropdowns video-track-dropdown" name="video-track"
                [(ngModel)]="track">
                <div dropdown-header>
                    <div class="hover-menu">
                        <div class="menu-tab">
                            <cdux-track-group *ngIf="track" [track]="track" id="track_dropdown">
                                <span class="track-name">{{ track.FormattedTrackName }}</span>
                            </cdux-track-group>
                            <ng-container *ngIf="trackList.length > 0 && !track">Select Track</ng-container>
                            <ng-container *ngIf="trackList.length === 0 && !track">No Tracks Available</ng-container>
                        </div>
                        <div class="dropdown-arrow"><i class="icon--arrow-down"></i></div>
                    </div>
                </div>

                <div dropdown-list>
                    <cdux-dropdown-option *ngFor="let track of trackList; trackBy: trackByTrackID" [value]="track"
                        class="hover-menu dropdown-option" (update)="onTrackSelect($event)"
                        [cduxLogClick]="eventClickType.VIDEO_TRACK_SELECTION"
                        id="{{ 'track_dropdown_item' + generateTrackIdTag(track) }}">
                        <div class="track-dropdown__track-and-race">
                            <cdux-track-group [track]="track">
                                <span class="track-name">{{ track.FormattedTrackName }}</span>
                            </cdux-track-group>
                        </div>
                    </cdux-dropdown-option>
                </div>
            </cdux-dropdown>

            <cdux-dropdown class="video track-race-dropdowns video-race-dropdown"
                name="video-race" [(ngModel)]="race" id="race_dropdown">
                <div dropdown-header>
                    <div class="hover-menu">
                        <div class="menu-tab">{{race ? 'Race ' + race : 'Select Race'}}</div>
                        <div class="dropdown-arrow"><i class="icon--arrow-down"></i></div>
                    </div>
                </div>

                <div dropdown-list>
                    <cdux-dropdown-option *ngFor="let race of raceList" [value]="race"
                        class="hover-menu dropdown-option" (update)="onRaceSelect($event)"
                        [cduxLogClick]="eventClickType.VIDEO_RACE_SELECTION">
                        <div class="track-dropdown__track-and-race">
                            <span class="race-num">Race {{ race }}</span>
                        </div>
                    </cdux-dropdown-option>
                </div>
            </cdux-dropdown>
        </ng-template>
    </div>
</div>
