import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({name: 'formatHorseDetailsNumber'})
export class FormatHorseDetailsNumberPipe implements PipeTransform {
    transform(value: any, unit?: string, digits?: string): string {
        if (typeof digits === 'undefined') {
            digits = '1.0-1';
        }

        let ret = '-';

        // Convert number strings to numbers
        value = typeof value === 'string' && this.isNumeric(value) ? +value : value;

        if (typeof value !== 'string') { // Only proceed if we have successfully retrieved a number
            // Have to explicitly check for null or undefined because they erroneously pass a
            // typeof value === 'number' check.
            if (value !== null && value !== undefined) {
                ret = formatNumber(value, 'en-US', digits);

                if (typeof unit === 'string') {
                    ret += unit;
                }
            }
        }

        return ret;
    }

    private isNumeric(value: any): boolean {
        return !Number.isNaN(value - parseFloat(value));
    }
}
