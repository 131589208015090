<div class="video-player-controls" [class.standalone]="onStandalone || inlineTabs" [class.tv]="onTV">

    <div class="video-controls-rows">
        <div class="video-feed row" *ngIf="!disableLiveReplayToggle">
            <span class="header-switch"><br/></span>

            <div class="label-switch" [class.live-only]="liveOnly()" [class.replay-only]="!liveVideoAvailable">

                <div class="switch-box"></div>
                <label *ngIf="liveVideoAvailable" for="replays3" class="label-switch-option live switch-on" [class.is-checked]="(!showReplayButton() || !isReplay()) && !liveOnly()"
                    (click)="toggleFeed(videoFeedStates.LIVE, $event)">
                    <span class="status">live</span>
                    <span *ngIf="!onStandalone && !!liveRaceNumber" class="race">R{{ liveRaceNumber }}</span>
                    <span *ngIf="onStandalone && !isReplay() && track" class="race">R{{ track?.RaceNum }}</span>
                </label>
                <label *ngIf="showReplayButton()" for="replays3" class="label-switch-option replay switch-off" [class.no-click]="!onStandalone" [class.is-checked]="isReplay()"
                    (click)="toggleFeed(videoFeedStates.REPLAY, $event)">
                    <span class="status">replay</span>
                    <span *ngIf="!!race" class="race">R{{ race }}</span>
                </label>
                <input type="checkbox" class="input-switch">

            </div>

        </div>

        <div class="video-angle row" *ngIf="feedAngles?.length > 1">
            <span class="header-switch">Camera angle</span>

            <div class="label-switch">

                <div class="switch-box"></div>
                <div class="label-switch-option switch-on" [class.is-checked]="feedAngle === videoFeedAngles.PAN" (click)="toggleAngle(videoFeedAngles.PAN)">
                    <span class="view">Full View</span>
                </div>
                <div class="label-switch-option switch-off" [class.is-checked]="feedAngle === videoFeedAngles.HEADON" (click)="toggleAngle(videoFeedAngles.HEADON)">
                    <span class="view">Head-on</span>
                </div>
                <input type="checkbox" class="input-switch" name="checkbox2">

            </div>

        </div>

    </div>

</div>
