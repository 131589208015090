<div class="link search-container">
    <div id="search-menu-item-field" class="search-field is-open">
        <i class="icon-magnifying-glass-primary"></i>
        <input
            #searchField
            placeholder="Search Today's Runners"
            type="text"
            name="searchString"
            id="search-menu-item-input"
            [(ngModel)]="searchString"
            (ngModelChange)="modelChanged.next($event)">
        <i [ngClass]="{'is-enabled': !!searchString}" class="icon-clear-search" (cduxTap)="clearSearchString()"></i>
    </div>
    <!-- https://stackoverflow.com/a/51556842/356016 -->
    <ng-container>
        <cdux-search-result-list
        [resultList]="searchResults | async"
        [searchString]="searchString"
        (resultClicked)="goToProgram($event)">
        </cdux-search-result-list>
    </ng-container>
</div>
