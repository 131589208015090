import { ENVIRONMENT, ENVIRONMENTS, AFFIDS } from '@cdux/ng-core';

const affId = AFFIDS.BETAMERICA;
const username = 'my_tux';

export const environment: ENVIRONMENT = {
    environment: ENVIRONMENTS.PRODUCTION,
    production: true,
    affiliateId: affId,
    domain: 'betamerica.com',
    username: username,
    setSecureCookie: true,
    accountMenuConfig: {
        hideRecallTodaysBets: true,
        hideRewards: false,
        hideTaxDocuments: false,
        hideDetailedAccountHistory: true,
        hideStableAlert: true
    },
    thirdPartyConfig: {
        'Box': {
            'Folder': '7667404974',
            'Token': 'zmdxugp0tzsi6ax51n29u4341f730wej',
            'Token_Simplified_Upload': '4723136851c84f2a84cc010323bcfc08',
        },
        'RightNow': {
            'Subdomain': 'betamerica',
            'Category': 10
        },
        'ZendeskChatURL': 'https://static.zdassets.com/ekr/snippet.js?key=99e9d338-8e0e-4d75-9503-97cbc0994b36'
    },
    appConfig: {
        servicesBaseUrl: '',
        username: username,
        environmentUsername: username,
        platformId: 'TUX',
        affiliateId: affId,
        clientIp: '0.0.0.0',
        outputType: 'json'
    }
};
