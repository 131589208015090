import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CduxNgCommonModule, EventSessionModule } from '@cdux/ng-common';
import { SharedModule as SharedFragmentsModule, DatepickerLiteModule } from '@cdux/ng-fragments';
import { CduxMediaModule } from '@cdux/ng-platform/web';

import { TuxCommonModule } from '../common/common.module';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { RaceNavigationModule } from '../race-navigation/race-navigation.module';

import { WageringLayoutModule } from '../../wagering/layout/wagering.layout.module';

import { ResultChartComponent } from './components/result-chart/result-chart.component';
import { ResultDetailComponent } from './components/result-detail/result-detail.component';
import { ResultsPoolBaseTrimPipe } from './pipes/results-pool-base-trim.pipe';
import { ResultsService } from './services/results.business.service';
import { ResultsTrackListComponent } from './components/results-track-list/results-track-list.component';
import { ScrollableContentModule } from '../../wagering/shared/scrollable-track/scrollable-content.module';
import { FavoritesModule } from 'app/account/favorites/favorites.module';


@NgModule({
    imports: [
        BreadcrumbsModule,
        CduxMediaModule,
        CduxNgCommonModule,
        CommonModule,
        DatepickerLiteModule,
        EventSessionModule,
        FavoritesModule,
        RaceNavigationModule,
        ScrollableContentModule,
        SharedFragmentsModule,
        TuxCommonModule,
        WageringLayoutModule,
    ],
    declarations: [
        ResultChartComponent,
        ResultDetailComponent,
        ResultsPoolBaseTrimPipe,
        ResultsTrackListComponent
    ],
    providers: [
        ResultsService
    ],
    exports: [
        ResultChartComponent,
        ResultDetailComponent,
        ResultsTrackListComponent
    ]
})
export class ResultsSharedModule {}
