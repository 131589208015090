<div class="mobile-view" (scroll)="scroll($event)">
    <section class="video-row" [class.open]="showVideo">
        <cdux-video
            *ngIf="showVideo"
            [controls]="false"
            [show]="true"
            [liveRaceNumber]="liveRaceNumber"
            [trackRace]="selectedITrack"
            [onTV]="true"
            [inlineTabs]="true"
            [disableLiveReplayToggle]="false"
            [videoBarType]="videoBarType.TAB">
        </cdux-video>
    </section>
    <section class="program-row">
        <cdux-compact-view
            [view]="view"
            (viewChange)="viewChange.emit($event)"
            [section]="section"
            (sectionChange)="sectionChange.emit($event)"
            [displayMode]="displayMode"
            [wager]="wager"
            [raceStatus]="raceStatus"
            [error]="error"
            (updateRaceNav)="updateRaceNav.emit($event)"
            (updateBetNav)="selectBetTypeFromList.emit($event)"
            (updateBetType)="onUpdateBetType($event)"
            (updateBetAmount)="onUpdateBetAmount($event)"
            (updateBetModifier)="onUpdateBetModifier($event)"
            (updateEntrySelection)="onUpdateEntrySelections($event)"
            (resetEntrySelections)="resetEntrySelections.emit()"
            (stashEntrySelections)="stashEntrySelections.emit()"
            (restoreEntrySelections)="restoreEntrySelections.emit()"
            (outboundEvents)="outboundEvents.emit($event)"
            (dismissError)="dismissError.emit($event)">
        </cdux-compact-view>
    </section>
    <cdux-quick-pick-sidebar *ngIf="isQuickPickSidebarEnabled && isWagerableRace && !displayTotalBar"
        [class.disabled]="!quickPickSidebar?.isWagerableRace || !quickPickSidebar?.isPoolAvailable"
        (outboundEvents)="outboundEvents.emit($event)"
        [displayMode]="displayMode"
        [raceStatus]="raceStatus"
        [wagerState]="wager"
        (opened)="stashEntrySelections.emit()"
        (closed)="restoreEntrySelections.emit()">
    </cdux-quick-pick-sidebar>
    <!--  END Needs swapped with the new section stuff  -->
    <ng-template cdk-portal #mobileTotalBar="cdkPortal">
        <section class="error-row" [style.display]="displayTotalBar && error ? 'flex' : 'none'">
            <cdux-quickbet-error class="compact"
                                [error]="error"
                                (errorClose)="dismissError.emit($event)">
            </cdux-quickbet-error>
        </section>
        <section class="total-bar-row" [style.display]="displayTotalBar && !error ? 'block' : 'none'">
            <cdux-compact-total-bar [wagerState]="wager"
                                    [inboundEvents]="inboundEvents"
                                    [raceStatus]="raceStatus"
                                    (resetEntrySelections)="resetEntrySelections.emit()"
                                    (outboundEvents)="handleEvent($event)">
            </cdux-compact-total-bar>
        </section>
    </ng-template>
</div>
