<section class="classic-wager-container" *ngIf="betTypesAvailable; else technicalDifficulties">
    <div class="runner-container" *ngIf="legInfo?.legsCounter.length > 0">
        <nav class="leg-buttons-wrap">
            <ng-container *ngIf="legInfo?.multiRace">
                <div class="leg-button" [class.is-active]="filteredLeg === legNumber"
                     *ngFor="let legName of legInfo.legsCounter; let legNumber=index; trackBy: trackByIndex"
                     (click)="selectionEnabled && filterLeg(legNumber)">
                    {{legName}}
                </div>
                <div class="leg-button" [class.is-active]="filteredLeg === null" (click)="selectionEnabled && selectAllLeg()">All</div>
            </ng-container>
        </nav>
        <div class="runners-row">
            <div class="label-col">
                &nbsp;
            </div>
            <div class="runner-col">
                <div class="saddle-cloth all" *ngIf="condensedEntries && condensedEntries.length > 0">All</div>
            </div>
            <ng-container *ngFor="let saddleClothNum of saddleCloths; let index=index; trackBy: trackByIndex">
                <div class="runner-col tooltip hover click" [class.show]="visibleTooltip === index" (cduxTap)="toggleTooltipOnClick(index)" (cduxHover)="toggleTooltipOnHover(index, $event)">
                    <div class="saddle-cloth" [ngClass]="saddleClothNum | cduxSaddleClothClass:track?.TrackType" [class.is-scratched]="isScratched(index)">{{saddleClothNum}}</div>
                    <span class="tooltiptext tooltip-bottom">
                        <span class="text leg" *ngFor="let horse of getTooltipInfo(saddleClothNum); let index=index; trackBy: trackByIndex" [class.is-scratched]="horse.scratched">
                            <span class="text label" *ngIf="!!horse.label">{{horse.label}}</span>
                            <span class="text runner">{{horse.name}}</span>
                        </span>
                    </span>
                </div>
            </ng-container>
        </div>
        <ng-container *ngFor="let leg of condensedEntries; let legNumber=index; trackBy: trackByIndex">
            <div class="selection-row" *ngIf="filteredLeg === legNumber || filteredLeg === null">
                <div class="label-col">
                    {{ legInfo?.legsCounter[legNumber] }}
                </div>
                <div class="runner-col">
                    <ng-container *ngIf="!(legNumber === 0 && isKey); else emptyPlaceholder">
                        <cdux-checkiobox
                            id="runner-entry-select-all-leg-{{legNumber + 1}}"
                            [isSelected]="areAllSelected[legNumber]"
                            (update)="selectionEnabled && selectAll($event, legNumber)">
                        </cdux-checkiobox>
                    </ng-container>
                </div>
                <ng-container *ngFor="let entry of leg; trackBy: trackByIndex">
                    <div class="runner-col">
                        <ng-container *ngIf="!!entry; else emptyPlaceholder">
                            <cdux-checkiobox
                                id="runner-entry-select-{{entry.bettingInterest}}-leg-{{legNumber + 1}}"
                                [isSelected]="!!selectedProgramNumbers[legNumber] && selectedProgramNumbers[legNumber][entry.bettingInterest]"
                                [isActive]="!entry.scratched"
                                [class.is-inactive]="getDisabled(entry, legNumber)"
                                (update)="selectionEnabled && selectRunner($event, entry.bettingInterest, legNumber)">
                            </cdux-checkiobox>
                        </ng-container>
                     </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-template #emptyPlaceholder>
            <div class="bet-check-bubble">
                <i class="select-bubble-spacer"></i>
            </div>
        </ng-template>
    </div>
</section>
<ng-template #technicalDifficulties>
    <div class="has-error light" *ngIf="!betTypesAvailable">
        <div class="gbl-message">
            <i class="icon&#45;&#45;info"></i>
            <div class="header uppercase">wagering is currently unavailable for this race</div>
        </div>
    </div>
</ng-template>
