import { ChangeDetectorRef, EventEmitter, Input, Output, ViewRef, Directive } from '@angular/core';
import { VideoFeedStates } from 'app/wagering/video/enums/video-feed-states';
import { enumRaceStatus, EventClickType, IReplayTracks, ITrack, VideoAngles } from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';

@Directive()
export abstract class VideoBarAbstractComponent {
    @Input()
    public set track(track: ITrack | IReplayTracks) {
        this._track = track;
        if (!(this.changeDetector as ViewRef).destroyed) {
            this._detectChanges();
        }
    }
    public get track(): ITrack | IReplayTracks {
        return this._track;
    }
    private _track: ITrack | IReplayTracks;

    @Input()
    public set race(number: number) {
        this._race = number;
        if (!(this.changeDetector as ViewRef).destroyed) {
            this._detectChanges();
        }
    }
    public get race() {
        return this._race;
    }
    private _race: number;

    @Input()
    public set feedState(feedState: VideoFeedStates) {
        if (this._feedState !== feedState) {
            this._feedState = feedState;
        }
    }
    public get feedState(): VideoFeedStates {
        return this._feedState;
    }
    private _feedState = VideoFeedStates.LIVE;

    @Input()
    public set feedAngle(feedAngle: VideoAngles) {
        if (this._feedAngle !== feedAngle) {
            this._feedAngle = feedAngle;
            this.feedAngleChange.emit(this._feedAngle);
        }
    }
    public get feedAngle(): VideoAngles {
        return this._feedAngle;
    }
    private _feedAngle: VideoAngles = null;

    @Input()
    public set feedAngles(feedAngles: VideoAngles[]) {
        this._feedAngles = feedAngles || [];
    }
    public get feedAngles(): VideoAngles[] {
        return this._feedAngles;
    }
    private _feedAngles: VideoAngles[] = [];

    @Input()
    public disableLiveReplayToggle: boolean = false;

    @Input()
    public onStandalone: boolean = false;

    @Input()
    public onTV: boolean = false;

    @Input()
    public liveRaceNumber: number;

    @Input()
    public liveVideoAvailable: boolean = true;

    @Output()
    public feedAngleChange: EventEmitter<VideoAngles> = new EventEmitter();

    @Output()
    public feedStateChange: EventEmitter<VideoFeedStates> = new EventEmitter();

    public videoFeedAngles = VideoAngles;
    public videoFeedStates = VideoFeedStates;

    protected constructor(
        protected changeDetector: ChangeDetectorRef,
        protected eventTrackingService: EventTrackingService
    ) {}

    public _detectChanges() {
        if (!(this.changeDetector as ViewRef).destroyed) {
            this.changeDetector.detectChanges();
        }
    }

    public showReplayButton(): boolean {
        // show the replay button on standalone or when the the selected race is less than the live race
        return this.onStandalone || (this.race < this.liveRaceNumber) || this.isReplay() || !this._isRaceOpen();
    }

    /**
     * Determine whether the current state of video is replay or not
     *
     * @returns {boolean}
     * @memberof VideoTabBarComponent
     */
    public isReplay(): boolean {
        return this.feedState === VideoFeedStates.REPLAY;
    }

    public _isRaceOpen(): boolean {
        // for determining the status, open or off should still show live button, only offer the replay when the race is official
        return this.track && this.track.hasOwnProperty('RaceStatus') && (this.track['RaceStatus'] === enumRaceStatus.OPEN || this.track['RaceStatus'] === enumRaceStatus.OFF);
    }

    /**
     * Emits event when video tab bar element has been clicked
     *
     * @param {VideoFeedStates} videoFeedState - State of element clicked
     * @param {*} [event] - The click event object
     * @memberof VideoTabBarComponent
     */
    public toggleFeed(videoFeedState: VideoFeedStates, event?: any): void {
        if (event) {
            // Only record the event if it is from user interaction.
            // The click is actually occuring on the input radio button, so we need to use the
            // text of the label instead of trying to get the text of the input.
            this.logToggleEvent(videoFeedState);
        }
        this.feedState = videoFeedState;
        this.feedStateChange.emit(this.feedState);
        this._detectChanges();
    }

    public toggleAngle(videoFeedAngle: VideoAngles) {
        // TODO: log event here like toggleFeed does?
        this.feedAngle = videoFeedAngle;
        this._detectChanges();
    }

    /**
     * Abstract the gathering of the data to send to the event logger.
     *
     * @private
     * @param {VideoFeedStates} feedState - The feed state that we are changing to
     * @memberof VideoTabBarComponent
     */
    private logToggleEvent(feedState: VideoFeedStates) {
        const clickEventId: EventClickType = feedState === VideoFeedStates.LIVE ? EventClickType.VIDEO_TABS_STREAMING : EventClickType.VIDEO_TABS_REPLAYS;

        this.eventTrackingService.logClickEvent(clickEventId);
    }
}
