import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import { FormatPursePipe, MtpDisplayUtil, RoutingOptions, ToastService } from '@cdux/ng-fragments';
import {
    enumRaceStatus,
    EventClickAttributeType,
    EventClickType,
    FavoriteRunnersService,
    StringSlugifyPipe,
    IEntryDetails,
    IFavoriteRunner,
    IFavoriteRunnerDisplay,
    IMtpDisplay,
    ITodaysRace,
    TodaysDisplayTrack,
    TrackService,
    TranslateService
} from '@cdux/ng-common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavEventType } from '../favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { IDetailsOptions } from 'app/shared/program/interfaces/details.interface';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { Subscription } from 'rxjs';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { RaceTypeConstants } from 'app/shared/common/constants';
import { TitleCasePipe } from '@angular/common';

@Component({
    selector: 'cdux-favorite-tile-runner',
    templateUrl: './favorites-tile-runner.component.html',
    styleUrls: [ './favorites-tile-runner.component.scss' ],
})
export class FavoritesTileRunnerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public favoriteData: IFavoriteRunner | IFavoriteRunnerDisplay;
    @Input() public isRunningToday = false;

    @ViewChild('updateModalTemplate') protected updateModalTemplate: TemplateRef<unknown>;
    @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>;
    @ViewChild('expandedModalTemplate') protected expandedModalTemplate: TemplateRef<unknown>;
    @ViewChild('replaysModalTemplate') protected replaysModalTemplate: TemplateRef<unknown>;

    public modalContent: Portal<any>;
    public updateTemplatePortal: TemplatePortal<any>;
    public removeTemplatePortal: TemplatePortal<any>;
    public expandedTemplatePortal: TemplatePortal<any>;

    public replaysTemplatePortal: TemplatePortal<any>;
    public readonly COUNT_MAX = 4096;
    public countCurrent = 0;
    public options: IDetailsOptions;

    public tempComment: string;

    private _overlayRef: OverlayRef;

    private tracksSub: Subscription;

    private _todaysTracks: TodaysDisplayTrack[];
    public isPhone: boolean = false;

    private mediaSub: Subscription;

    public constructor(
        private _eventTrackingService: EventTrackingService,
        private _router: Router,
        private _runnerService: FavoriteRunnersService,
        private _toastService: ToastService,
        private _mediaService: CduxMediaToggleService,
        private _changeDetector: ChangeDetectorRef,
        private _translateService: TranslateService,
        private _mtpDisplayUtil: MtpDisplayUtil,
        private _stringSlugify: StringSlugifyPipe,
        private _viewContainerRef: ViewContainerRef,
        private _titleCasePipe: TitleCasePipe,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
    ) {}

    ngOnInit() {
        this.tracksSub = this._todaysRacesService.getTodaysTracks(true, true).subscribe(tracks => {
            this._todaysTracks = tracks;
        });
    }

    ngAfterViewInit() {
        this.mediaSub = this._mediaService.registerQuery('phone').subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });
        this.updateTemplatePortal = new TemplatePortal(
            this.updateModalTemplate,
            this._viewContainerRef
        );
        this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        );
        this.expandedTemplatePortal = new TemplatePortal(
            this.expandedModalTemplate,
            this._viewContainerRef
        );
        this.replaysTemplatePortal = new TemplatePortal(
            this.replaysModalTemplate,
            this._viewContainerRef
        );
    }

    public ngOnDestroy() {
        if (this.tracksSub) {
            this.tracksSub.unsubscribe();
        }
        if (this.mediaSub) { this.mediaSub.unsubscribe(); }
    }

    public get note () {
        return this.tempComment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.tempComment = value;
        }
    }

    public edit () {
        this._logClickEvent(EventClickType.FAV_RUNNER_EDIT);
        this.tempComment = this.favoriteData.comment || '';
        this.countCurrent = this.tempComment.length;
        this.modalContent = this.updateTemplatePortal;
    }

    public confirmUpdate() {
        this._logClickEvent(EventClickType.FAV_RUNNER_EDIT_SAVE);

        // 1. Clone the favorite
        const dupeFav = Object.assign({}, this.favoriteData);
        dupeFav.comment = this.tempComment;
        // 2. Call to update the favorite
        this._runnerService.update(dupeFav).then((updated) => {
            if (updated) {
                // 3. If save was successful. Replace original favorite with the new one
                this.favoriteData = dupeFav;
            } else {
                // 4. Show some sort of error message? Seek clarification.
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });

        this.dismissModal();
    }

    public confirmRemove() {
        this._runnerService.remove(this.favoriteData.uuid).then((removed) => {
            // Because the runner service removes the favorite from the cache and the management page
            // is subscribed to that cache, we don't need to do anything in the event of a successful
            // removal. We only need to alert the user to a failed removal.

                if (removed) {
                    this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);

                    // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                    this._accountBubbleNotificationService.dispatch({
                        type: FavEventType.FAVORITE_RUNNER_REMOVE,
                        payload: {
                            message: '<i class="icon--check-light-circle"></i>' + this.favoriteData.runnerName + ' removed from <span class="bold-text">stable</span>.',
                            duration: 3000
                        }
                    });
                }
            if (!removed) {
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }

    public remove() {
        this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE_LINK);
        this.modalContent = this.removeTemplatePortal;
    }

    public expand() {
        this.modalContent = this.expandedTemplatePortal;
    }

    public replays() {
        if (!this.isPhone) {
            this.options = {
                brisCode: undefined,
                trackType: undefined,
                raceNumber: undefined,
                programNumber: undefined,
                horseId: this.favoriteData.runnerId,
                view: WageringViewEnum.CLASSIC,
            }
            this.modalContent = this.replaysTemplatePortal;
        } else {
            this._router.navigateByUrl([
                '/program',
                WageringViewEnum.CLASSIC,
                'horse',
                this.favoriteData.runnerName,
                'id',
                this.favoriteData.runnerId
            ].join('/'));
        }
    }

    public bet(runner: IEntryDetails, isExpandedView = false) {
        this._logClickEvent(EventClickType.STABLE_ALERTS_BET_LINK);

        this._router.navigate([
            'program',
            this._stringSlugify.transform(runner.trackName),
            runner.brisCode.toLowerCase(),
            TrackService.getTrackType(runner.bdsTrackType) ? TrackService.getTrackType(runner.bdsTrackType) : 'Thoroughbred',
            runner.raceNumber,
        ]);

        if (isExpandedView) {
            this.dismissModal();
        }
    }

    /**
     * This method will check to see if we should redirect user to Program or Results
     * based on favorite runner's participating track/race and current race on the fav runner related track
     * If the favorite runner's participating race is closed and results official, then should goto results
     * otherwise, goto program.
     */
    public getRoutingOptionFromFavData(): RoutingOptions {
        let retRoutingOpt = RoutingOptions.PROGRAM;
        // If the favoriteData is type of IFavoriteRunnerDisplay, then we can compare:
        // raceNumber > track.currentRaceNumber: then should go to program since race is not running
        // raceNumber <= track.currentRaceNumber: then should check the 'status' field on track.races[i] with matching raceNumber:
        // if status == 'Closed' then the race result is available and Bet link should goto result, otherwise goto program
        // In order to make the comparison, the track object need to be on the favoriteData
        const brisCodeOnFav = this.favoriteData['brisCode']?.toLowerCase() || null;
        if (brisCodeOnFav && this.favoriteData['track'] && this.favoriteData['track'].races && this.favoriteData['track'].currentRaceNumber && brisCodeOnFav === this.favoriteData['track'].brisCode) {
            const races = this.favoriteData['track']?.races || null;
            if (races && this.favoriteData['raceNumber'] <= this.favoriteData['track'].currentRaceNumber) {
                const foundRace: ITodaysRace[] = races.filter(arace =>
                        arace.raceNumber === this.favoriteData['raceNumber']
                        && arace.status === 'Closed');
                if (foundRace && foundRace.length) {
                    retRoutingOpt = RoutingOptions.RESULTS;
                }
            }
        }
        return retRoutingOpt;
    }

    public setMtpDisplaySrc(runner: IEntryDetails): IMtpDisplay {
        // Set default values
        let mtp = 0;
        let raceStatus = enumRaceStatus.UNKNOWN;
        let postTime = '00:00:00';
        let displayMinutes = false;

        // Need to match brisCode / bds -> bris track type / race number
        const brisCode = runner.brisCode.toLowerCase();
        const trackType = TrackService.getTrackType(runner.bdsTrackType);
        const raceNumber = runner.raceNumber;

        // Match the bris code and track type
        const myTrack = this._todaysTracks.find(track => track.brisCode.toLowerCase() === brisCode && track.type === trackType);

        if (myTrack) {
            // Match the race numbber
            const myRace = myTrack.races.find(race => race.raceNumber === raceNumber);

            // If race found, set mtp, race status, post time and currentRace (boolean)
            if (myRace) {
                mtp = myRace.mtp;
                raceStatus = myRace.status;
                postTime = myRace.postTime;
                displayMinutes = myRace.currentRace;
            }
        }

        return this._mtpDisplayUtil.deriveMtpDisplay({
            mtp: mtp,
            raceStatus: raceStatus,
            postTimestamp: postTime
        }, displayMinutes);
    }

    public getRaceTypeText(runner: IEntryDetails): string {
        const brisCode = runner.brisCode.toLowerCase();
        const trackType = TrackService.getTrackType(runner.bdsTrackType);
        const raceNumber = runner.raceNumber;
        const myTrack = this._todaysTracks.find(track => track.brisCode.toLowerCase() === brisCode && track.type === trackType);
        const myRace = myTrack.races.find(race => race.raceNumber === raceNumber);

        if (!myRace.raceType) {
            return '';
        }

        // If showFullRaceTypeName = false, name = race type code, else full name
        const name = this._titleCasePipe.transform(RaceTypeConstants.raceTypes['TB'] && RaceTypeConstants.raceTypes['TB'][myRace.raceType] || '');

        // If claiming race
        if (Number(myRace.maxClaimPrice) > 0) {
            const raceTypeComponents = [];

            // Add the max claim price
            const formatPursePipe = new FormatPursePipe();
            raceTypeComponents.push(formatPursePipe.transform(Number(myRace.maxClaimPrice)));

            // Add the fullRaceTypeName or the race type code
            raceTypeComponents.push(name);

            return raceTypeComponents.join(' ');
        } else {
            // If not a claiming race return the race type code or full name
            return name;
        }
    }

    public getRaceDetails(runner: IEntryDetails): string {
        const brisCode = runner.brisCode.toLowerCase();
        const trackType = TrackService.getTrackType(runner.bdsTrackType);
        const raceNumber = runner.raceNumber;
        const myTrack = this._todaysTracks.find(track => track.brisCode.toLowerCase() === brisCode && track.type === trackType);
        const myRace = myTrack.races.find(race => race.raceNumber === raceNumber);

        return myRace.formattedPurse.toUpperCase() + ' | ' + (myRace.ageRestrictions ? myRace.ageRestrictions + ' | ' : '') + (myRace.sexRestrictions ? myRace.sexRestrictions + ' | ' : '') + myRace.distance + ' | ' + myRace.surfaceLabel;
    }

    private _logClickEvent(clickType: EventClickType) {
        switch (clickType) {
            case EventClickType.STABLE_ALERTS_REMOVE:
            case EventClickType.STABLE_ALERTS_REMOVE_LINK:
                const ts = Date.now();

                let favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_FAVORITE_TYPE;
                let favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_LOCATION;

                if (clickType === EventClickType.STABLE_ALERTS_REMOVE) {
                    favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_FAVORITE_TYPE;
                    favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LOCATION;
                }

                this._eventTrackingService.logClickEvent(
                    clickType,
                    [
                        {
                            attrId: favType,
                            data: FavoriteTypeEnum.RUNNER,
                            timestamp: ts
                        },
                        {
                            attrId: favLoc,
                            data: FavoriteLocationEnum.STABLE_ALERTS,
                            timestamp: ts
                        },
                    ]
                );
            break;

            case EventClickType.STABLE_ALERTS_BET_LINK:
                this._eventTrackingService.logClickEvent(
                    EventClickType.STABLE_ALERTS_BET_LINK,
                    [
                        {
                            attrId: EventClickAttributeType.STABLE_ALERTS_BET_LINK_FAVORITE_TYPE,
                            data: FavoriteTypeEnum.RUNNER,
                            timestamp: Date.now()
                        }
                    ]
                );
                break;

            default:
                this._eventTrackingService.logClickEvent(clickType);
        }
    }
}
