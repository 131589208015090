import { LANG_1400_NAME, LANG_1400_TRANS } from './1400';
import { LANG_2800_NAME, LANG_2800_TRANS } from './2800';
import { LANG_3000_NAME, LANG_3000_TRANS } from './3000';
import { LANG_4100_NAME, LANG_4100_TRANS } from './4100';
import { LANG_4200_NAME, LANG_4200_TRANS } from './4200';
import { LANG_6100_NAME, LANG_6100_TRANS } from './6100';
import { LANG_6200_NAME, LANG_6200_TRANS } from './6200';


export const AFFILIATE_LANGUAGE_DICTIONARY = {
  [LANG_1400_NAME]: LANG_1400_TRANS,
  [LANG_2800_NAME]: LANG_2800_TRANS,
  [LANG_3000_NAME]: LANG_3000_TRANS,
  [LANG_4100_NAME]: LANG_4100_TRANS,
  [LANG_4200_NAME]: LANG_4200_TRANS,
  [LANG_6100_NAME]: LANG_6100_TRANS,
  [LANG_6200_NAME]: LANG_6200_TRANS,
};
