import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
    Entry,
    enumPoolType,
    enumTrackType,
    ToteDataService,
    IPoolsDataResponse,
    IAdwRace,
    ProgramEntry,
    IPoolsEntry,
    IPoolsTotal,
    RaceInfoService,
} from '@cdux/ng-common';

import { ProgramListBusinessService } from 'app/shared/program/services/program-list.business.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

const NO_POOLS_DATA_ERROR = 'NO_POOLS_DATA_ERROR';

export interface IPoolsShared {
    track: string;
    trackType: enumTrackType;
    entries: IPoolsSharedEntry[];
    totals: IPoolsSharedTotals
}

export interface IPoolsSharedTotals {
    totalWin: number;
    totalPlace: number;
    totalShow: number;
}

export interface IPoolsSharedEntry {
    ProgramNumber: string;
    Win: number;
    Place: number;
    Show: number;
    WinPct: number;
    PlacePct: number;
    ShowPct: number;
    Entries: ProgramEntry[];
    isTopWinPool?: boolean;
    isTopPlacePool?: boolean;
    isTopShowPool?: boolean;
}

@Injectable()
export class PoolsBusinessService {

    constructor(
        private _toteDataService: ToteDataService,
        private _programListBusinessService: ProgramListBusinessService,
        private raceInfoService: RaceInfoService,
        private _todaysRacesBusinessService: TodaysRacesBusinessService
    ) {}

    /**
     * @deprecated
     */
    public getPoolsSub(
        brisCode: string,
        trackType: enumTrackType,
        race: IAdwRace
    ): Observable<[IPoolsDataResponse, Entry[]]> {
        if (brisCode && trackType && race && ('race' in race)) {
            // poll only when race is open
            return this.raceInfoService.isClosed(brisCode, trackType, race.race).pipe(
                mergeMap(
                    (raceIsClosed: boolean) => combineLatest([
                        this._toteDataService.getWPSPools(
                            brisCode,
                            trackType,
                            race.race,
                            raceIsClosed ? false : this._toteDataService.USE_POLLING
                        ),
                        this._programListBusinessService.getEntriesChanges(race)
                    ])
                )
            );
        } else {
            throw new SyntaxError();
        }
    }

    public getPools(
        brisCode: string,
        trackType: enumTrackType,
        race: number
    ): Observable<IPoolsShared> {
        if (brisCode && trackType && race) {
            return this.raceInfoService.isClosed(brisCode, trackType, race).pipe(
                mergeMap(
                    (raceIsClosed: boolean) => combineLatest(
                        this._toteDataService.getWPSPools(
                            brisCode,
                            trackType,
                            race,
                            raceIsClosed ? false : this._toteDataService.USE_POLLING
                        ),
                        this._todaysRacesBusinessService.getTodaysRaceEntries(brisCode, trackType, race, !raceIsClosed)
                    )
                ),
                map((data) => {
                    const wpsData: IPoolsDataResponse      = data[0];
                    const programEntryData: ProgramEntry[] = data[1];

                    // TODO - check data availability
                    if (!wpsData.hasOwnProperty('PoolTotals') || (wpsData.hasOwnProperty('WPSPools') && wpsData.WPSPools.hasOwnProperty('Entries') && wpsData.WPSPools.Entries.length === 0)) {
                        throwError(new Error(NO_POOLS_DATA_ERROR));
                    } else {
                        return Object.assign(
                            {track: brisCode, trackType: trackType},
                            {totals: this._parsePoolsTotals(wpsData.PoolTotals)},
                            {entries: this._buildPoolsEntries(wpsData.WPSPools.Entries, programEntryData)}
                        ) as IPoolsShared;
                    }
                })
            );
        }
    }

    private _parsePoolsTotals(poolsData: IPoolsTotal[]): IPoolsSharedTotals {
        const pools: IPoolsSharedTotals = {
            totalWin: 0,
            totalPlace: 0,
            totalShow: 0
        };

        for (const poolTotal of poolsData) {
            if (poolTotal.PoolType === enumPoolType.WIN) {
                pools.totalWin = poolTotal.Amount;
            } else if (poolTotal.PoolType === enumPoolType.PLACE) {
                pools.totalPlace = poolTotal.Amount;
            } else if (poolTotal.PoolType === enumPoolType.SHOW) {
                pools.totalShow = poolTotal.Amount;
            }
        }

        return pools;
    }

    private _buildPoolsEntries(poolsEntries: IPoolsEntry[], programEntries: ProgramEntry[]): IPoolsSharedEntry[] {
        const entries: IPoolsSharedEntry[] = [];

        // Add the corresponding program entries to each pools entry to create an IPoolsSharedEntry
        poolsEntries.forEach((poolsEntry: IPoolsEntry) => {
            const matchingProgramEntries: ProgramEntry[] = [];
            for (const programEntry of programEntries) {
                if (programEntry.BettingInterest === parseInt(poolsEntry.ProgramNumber, 10)) {
                    // Only send back a non-scratched entry so in the instances of a coupled entry.
                    // we can still properly show odds when the 1A has scratched but the 1B has not (for instance).
                    if (!programEntry.Scratched) {
                        matchingProgramEntries.push(programEntry);
                        break;
                    }
                }
            }
            entries.push(Object.assign(poolsEntry, { Entries: matchingProgramEntries }));
        });

        // Once we have attached the program entry to the pools entry, figure out the top pools
        this._sortTopPools(entries);

        return entries;
    }

    /**
     * Sort and mark the WPS pools top amounts
     */
    private _sortTopPools(entries: IPoolsSharedEntry[]): void {
        // initialize unique pool amounts
        const winPool = {};
        const placePool = {};
        const showPool = {};

        // Populate the objects with references to each entry, selected by unique WPS amount values.
        entries.forEach((poolEntry: IPoolsSharedEntry) => {
            // If the index doesn't already exist, add it
            if (!(poolEntry.Win in winPool)) {
                winPool[poolEntry.Win] = [];
            }
            if (!(poolEntry.Place in placePool)) {
                placePool[poolEntry.Place] = [];
            }
            if (!(poolEntry.Show in showPool)) {
                showPool[poolEntry.Show] = [];
            }
            // Push the entry onto the array of entries for this unique WPS pool amount
            winPool[poolEntry.Win].push(poolEntry);
            placePool[poolEntry.Place].push(poolEntry);
            showPool[poolEntry.Show].push(poolEntry);
        });

        // Pass the selected entries and the property we want to mark as top pool
        this._markTopPools(winPool, 'isTopWinPool');
        this._markTopPools(placePool, 'isTopPlacePool');
        this._markTopPools(showPool, 'isTopShowPool');
    }

    /**
     * mark the entries that are among the top unique pool amounts
     *
     * @param pool the object that contains the selected entries
     * @param property the given property to toggle
     */
    private _markTopPools (pool: any, property: string) {

        // Sort and store the unique pool values by their keys in descending order
        const sortedPool = Object.keys(pool).sort((n1, n2) => parseFloat(n2) - parseFloat(n1));

        // variable for counting the total number of marked entries
        let markedCount = 0;

        /**
         * Per AC of US17712
         * "3 highest total bet amounts" shall be defined to include ANY runner whose Total Bet Amount in the subject pool is NOT exceeded by the individual Total Bet Amount of more than two other runners.
         * "3 highest total bet amounts" can sometimes comprise of more than 3 runners - due to the chance of multiple runners having the same Total Bet Amount..
         *
         * Logic Translation: If the total number of entries in the top 2 unique pool amounts exceeds 3 entries, do not mark the entries in the 3rd unique pool amount as a top pool amount
         */

        // For each sorted entry, for the top pools, set Top Pool marker on certain conditions
        // slice ensures that we only loop through the top 3 three, it's also possible to have two or less unique amounts
        sortedPool.slice(0, 3).forEach((uniquePoolAmount, uniquePoolAmountIndex) => {
            pool[sortedPool[uniquePoolAmountIndex]].forEach((poolEntry) => {
                // If the total number of entries in the top 2 unique pool amounts exceeds 3,
                // do not mark the entries in the 3rd unique pool amount as a top pool amount
                if ( markedCount > 3 && uniquePoolAmountIndex > 1) {
                    return;
                } else {
                    // marking a top pool
                    poolEntry[property] = true;
                    markedCount++;
                }
            });
        });
    }
}
