import { Injectable } from '@angular/core';
import { enumTrackType, ISelectedEntry, memo, ProgramEntry } from '@cdux/ng-common';
import { RoutingOptions } from '@cdux/ng-fragments';
import { SaddleClothClassPrefix } from '../enums/saddle-cloth-class-prefix.enum';
import { ILegInfo } from 'app/shared/program/interfaces/leg-info.interface';
import { ISelectedProgramNumber } from 'app/shared/betpad/interfaces/selected-program-number.interface';
import { VisibleProgramEntry } from '../models/visible-program-entry.model';

@Injectable({
    providedIn: 'root'
})
export class WageringUtilBusinessService {
    /**
     * Constructs a saddle cloth class name for a given track type
     * and program number.
     *
     * @param {enumTrackType} trackType
     * @param {string | number} programNumber
     * @returns {string}
     */
    @memo((trackType: enumTrackType, programNumber: string | number) => trackType + programNumber)
    public getSaddleClothClass(trackType: enumTrackType, programNumber: string | number): string {
        let prefix: SaddleClothClassPrefix;

        switch (trackType) {
            case enumTrackType.HARN:
                prefix = SaddleClothClassPrefix.HARN;
                break;
            case enumTrackType.GREY:
                prefix = SaddleClothClassPrefix.GREY;
                break;
            case enumTrackType.TBRED:
            default:
                prefix = SaddleClothClassPrefix.TBRED;
                break;
        }

        // This statement will strip out letters from coupled entries ex: '1A' --> '1'
        const suffix = typeof programNumber === 'string' ? programNumber.replace(/\D/g, '') : programNumber;

        return prefix + '-' + suffix;
    }

    public getEntriesForLeg(multiLegRaceEntries: ProgramEntry[][], legInfo: ILegInfo, legIndex: number): ProgramEntry[] {
        let entries: ProgramEntry[] = [];
        if (!!multiLegRaceEntries && multiLegRaceEntries.length > 0 && !!legInfo) {
            if (!legInfo.multiRace) {
                entries = multiLegRaceEntries[0];
            } else if (!!multiLegRaceEntries[legIndex]) {
                entries = multiLegRaceEntries[legIndex];
            }
        }
        return entries;
    }

    public getSelectedProgramNumbers(selectedEntries: ISelectedEntry[][], key: string): ISelectedProgramNumber {
        let updatedProgramNumbers = {};
        if (!!selectedEntries) {
            updatedProgramNumbers = selectedEntries.reduce((p, c, index) => {
                p[index] = !!c && c.reduce((p2, c2) => {
                    p2[c2[key]] = true;
                    return p2;
                }, {});
                return p;
            }, {});
        }
        return updatedProgramNumbers;
    }

    public toSelectedEntry(entry: ProgramEntry): ISelectedEntry {
        return {
            'BettingInterest': entry.BettingInterest,
            'ProgramNumber': entry.ProgramNumber
        }
    }

    /**
     * will return all the entries that are not in focused leg
     * @param entries
     * @param focusedLeg
     */
    public getPaddedEntries(entries: ProgramEntry[][], focusedLeg: number): VisibleProgramEntry[] {
        const paddedProgramEntries: VisibleProgramEntry[] = [];
        entries.forEach((legEntry, index) => {
            if (legEntry) {
                legEntry.forEach((entry) => {
                    if (index !== focusedLeg && entries[focusedLeg].findIndex(visibleEntry => visibleEntry.ProgramNumber === entry.ProgramNumber) === -1 &&
                        paddedProgramEntries.findIndex(remainingEntry => remainingEntry.ProgramNumber === entry.ProgramNumber) === -1) {
                        const visibleProgramEntry: VisibleProgramEntry = new VisibleProgramEntry(entry, true);
                        paddedProgramEntries.push(visibleProgramEntry);
                    }
                })
            }
        });
        return paddedProgramEntries;
    }

    /**
     * A utility function to get program relative URL from brisCode, trackType, raceNumber, trackName
     * @param destType
     * @param brisCode
     * @param trackType
     * @param raceNumber
     * @param trackName
     * @return string
     */
    public getGoToProgramOrResultUrl(destType: RoutingOptions, brisCode?: string, trackType?: 'Thoroughbred'|'Harness'|'Greyhound', race?: number, trackName?: string): string {
        // the program URL format follow this pattern: /program/[track-name-hyphened]/[brisCode]/[trackType]/[race#]
        // Note: the resulting routing path to a program page on site might like /program/classic/track-1/... the 'classic' path after /program/
        // is magically added during routing and does not need to specify here
        const urlParts = ['/program'];
        urlParts.push(trackName ? trackName.trim().replace(/ /g, '-').toLowerCase() : '');
        urlParts.push(brisCode && trackType ? brisCode.toLowerCase() + '/' + trackType : '/');
        urlParts.push(race ? race.toString(10) : '');
        urlParts.push(destType === RoutingOptions.PROGRAM ? 'advanced' : 'payouts');
        return urlParts.join('/');
    }
}
