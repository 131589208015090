import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';
import { RCNBaseVideoFeed } from './rcn-base-video-feed.class';
import { RCNReplayStreamSource } from './rcn-stream-sources/rcn-replay-stream-source.service';

import { VideoProviderService } from '../video-provider.service';
import { VideoFeedError } from '../video-feed.interface';
import { DetectionService, IReplayStream, IReplayStreamHTTPReplay, VideoAngles, ConfigurationDataService, enumConfigurationStacks } from '@cdux/ng-common';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { VideoService } from 'app/wagering/video/services/video.service';

const PLAYER_ID = 'rcn-html5-player';

@Component({
    selector: 'cdux-rcn-http-replay-feed',
    template:
        `<div>
            <ng-container #rcnConfigContainer></ng-container>
        </div>
        <ng-template #rcnConfigTemplate>
            <ins *ngIf="currentPlayerData"
                id="${PLAYER_ID}"
                [attr.data-cust]="currentPlayerData.referer"
                [attr.data-hash]="currentPlayerData.hash"
                [attr.data-timestamp]="currentPlayerData.timestamp"
                [attr.data-stream]="currentPlayerData.stream"
                [attr.data-angle]="currentPlayerData.angle"
                [attr.data-speed]="currentPlayerData.speeds[0]"
                [attr.data-hd]="currentPlayerData.hd"
                [attr.data-autoplay]="autoPlay === true ? 1 : 0"
                [attr.data-usr]="currentPlayerData.user"
                [attr.data-type]="currentPlayerData.rcnType"
                data-videofit="letterbox"
                [attr.data-muted]="muted === true ? 1 : 0"
                data-hidecontrols="0">
            </ins>
        </ng-template>`
})
export class RCNHTTPReplayFeedComponent extends RCNBaseVideoFeed implements OnInit, OnDestroy {
    @ViewChild('rcnConfigContainer', { read: ViewContainerRef })
    protected rcnConfigContainer: ViewContainerRef;

    @ViewChild('rcnConfigTemplate')
    protected rcnConfigTemplate;

    public autoPlay = true;
    public muted: boolean;
    public currentPlayerData: IReplayStreamHTTPReplay;

    private  player_src: string = null;
    private angle: VideoAngles = VideoAngles.PAN;
    private angles = new Subject<VideoAngles[]>();
    private videoTagLoadInterval;

    constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected detectionService: DetectionService,
        protected streamSource: RCNReplayStreamSource,
        protected videoProviderService: VideoProviderService,
        private configurationDataService: ConfigurationDataService,
        private videoService: VideoService
    ) {
        super(changeDetectorRef, videoProviderService, streamSource);

        this.muted = this.videoService.getMuted();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        // Disable autoplay for mobile browsers, which won't allow it
        if (this.detectionService.isMobileDevice()) {
            this.autoPlay = false;
        }
    }

    public ngOnDestroy() {
        this.destroyPlayer();

        if (this.videoTagLoadInterval) {
            window.clearInterval(this.videoTagLoadInterval);
        }
    }

    protected handleStreamData (streamData: IVideoStreamResponses) {

        let playerData: IReplayStreamHTTPReplay = null;
        const angles: VideoAngles[] = [];

        if (typeof streamData === 'string') {
            this.errorSubject.next(VideoFeedError.VIDEO_UNAVAILABLE);
        } else if (streamData && streamData.hasStreams) {
            streamData.streams.forEach((stream: IReplayStream) => {
                if (stream && stream.angles) {
                    stream.angles.forEach(a => {
                        angles.push(a.angle);
                        if (!playerData || this.angle === a.angle) {
                            playerData = a.streamData as IReplayStreamHTTPReplay;
                        }
                    });
                }
            })
        }

        if (playerData) {
            // We can't just update the <ins> values and have the new feed play.
            // We need to destroy the player first. Note that destroyPlayer checks
            // that a player exists.
            this.destroyPlayer();

            // Destroying the player removes the <ins> tag as well as
            // the player, so we need to inject the template again.
            this.rcnConfigContainer.createEmbeddedView(this.rcnConfigTemplate);

            this.currentPlayerData = playerData;
            this.changeDetectorRef.detectChanges();
            this.angles.next(angles);

            this._setRCNReplayeURL().then(() => {
                this.loadLibrary(this.player_src).then(
                    () => {
                        if (this.videoTagLoadInterval) {
                            window.clearInterval(this.videoTagLoadInterval);
                        }
                        let intervalCount = 0;
                        this.videoTagLoadInterval = window.setInterval(() => {
                            intervalCount++;
                            const videoPlayer = document.querySelector('#' + PLAYER_ID + ' video');
                            if (videoPlayer) {
                                window.clearInterval(this.videoTagLoadInterval);
                                videoPlayer.addEventListener('volumechange', (change) => this.videoService.setMuted(change.target['muted']));
                            } else if (intervalCount >= 120) { // don't leave the timer running indefinitely -- runs for a minute
                                window.clearInterval(this.videoTagLoadInterval);
                            }
                        },
                        500
                        );
                    },
                    () => {
                        this.errorSubject.next(VideoFeedError.VIDEO_UNAVAILABLE);
                    }
                );
            });
        }
    }


    private _setRCNReplayeURL() {
        return new Promise<void>((resolve) => {
            if (this.player_src) {
                resolve();
                return;
            }
            this.configurationDataService.getConfiguration(enumConfigurationStacks.TUX, 'rcn_replay_lib_url')
            .pipe(
                take(1)
            ).subscribe(
                (url) => {
                    this.player_src = url.rcn_replay_lib_url;
                    resolve();
                }
            );
        });
    }


    private loadLibrary(url: string) {
        return new Promise<void>((resolve, reject) => {
            if (this.isLibraryAvailable()) {
                return resolve();
            } else {
                const el = document.createElement('script');
                el.async = true;
                el.src = url;
                el.onload = function() {
                    resolve();
                };
                document.getElementsByTagName('head')[0].appendChild(el);
            }
        });
    }

    private isLibraryAvailable(): boolean {
        return <any>window.hasOwnProperty('RCNReplays') && typeof <any>window['RCNReplays'].destroy === 'function';
    }

    private destroyPlayer(): void {
        if (this.isLibraryAvailable() && document.getElementById(PLAYER_ID) && document.getElementById('player_' + PLAYER_ID)) {
            (<any>window['RCNReplays']).destroy(PLAYER_ID);
        }
    }

    public getAngle(): VideoAngles {
        return this.angle;
    }

    public getAngles(): Observable<VideoAngles[]> {
        return this.angles.asObservable();
    }

    public setAngle(angle: VideoAngles, getStreamData: boolean): void {
        this.angle = angle;

        if (getStreamData) {
            this.getStreamData();
        }
    }

    // no-op overrides
    public pause() {}
    public start() {}
    public stop() {}
}
