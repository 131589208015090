export enum enumFullpageMethodToggles {
    MAZOOMA = 'MAZOOMA_FULLPAGE',
    CREDITCARD = 'CC_FULLPAGE',
    MONEYGRAM = 'MNYGRAM_FULLPAGE',
    EZMONEY = 'EZMONEY_FULLPAGE',
    GREENDOTATR = 'GDATR_FULLPAGE',
    PAYPAL = 'PAYPAL_FULLPAGE',
    PAYNEARME = 'PAYNRME_FULLPAGE',
    PAYPAL_W = 'PAYPAL_W_FULLPG',
    EZMONEY_W = 'EZMONEY_W_FULLPG',
    CHECK_W = 'CHECK_W_FULLPAGE',
}
