import { Injectable, NgZone } from '@angular/core';
import { ModalService, ModalRef } from '@cdux/ng-platform/web';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { TourComponent } from '../components/tour.component';
import { TourDataService, EventClickType } from '@cdux/ng-common';
import { EventTrackingService } from '../../../shared/event-tracking/services/event-tracking.service';

@Injectable({
    providedIn: 'root'
})
export class TourService {

    private _modalRef: ModalRef<TourComponent>;

    public tourImageUrls = [
        'resources/shared/images/tour/tour-image-1.jpg',
        'resources/shared/images/tour/tour-image-2.jpg',
        'resources/shared/images/tour/tour-image-3.jpg',
        'resources/shared/images/tour/tour-image-4.jpg',
        'resources/shared/images/tour/tour-image-5.jpg',
        'resources/shared/images/tour/tour-image-6.jpg',
        'resources/shared/images/tour/tour-image-7.jpg'
    ];

    public onTooltipSubject: Subject<boolean> = new Subject<boolean>();
    public onTooltip: Observable<boolean> = this.onTooltipSubject.asObservable();
    public eventClickType = EventClickType;

    public get modalRef(): ModalRef<TourComponent> {
        return this._modalRef;
    }

    constructor(
        private _zone: NgZone,
        private _modalService: ModalService,
        private _tourDataService: TourDataService,
        private _eventTrackingService: EventTrackingService
    ) { }

    /* Until we have a data service created for getting tour images from
    BOSS, I've gone ahead and made a little placeholder method where
    the actual call will go. */
    /**
     * Returns images loaded into Boss tour menu.
     * @returns {Promise}
     */
    public getTourImages(): Promise<any> {
        return new Promise((resolve) => {
            // this._tourDataService.getBossImages().then(
            //     (data) => {
            //         resolve(data);
            //     },

            //     (error) => {
            //         resolve(error);
            //     }
            // );
            resolve(this.tourImageUrls)
        });
    }

    public openTourModal(): Promise<boolean> {
       return new Promise((resolve, reject) => {
           if (!this._modalRef) {
               this.getTourImages()
                   .then((payload) => {
                       this._modalRef = this._modalService.open(TourComponent, {
                           context: {
                               id: 'tour-carousel',
                               data: payload,
                               isSidebar: false,
                           },
                           maxWidth: '100vw',
                           minWidth: '100vw',
                           maxHeight: '100vh',
                           minHeight: '100vh',
                           padding: '0px',
                           hasBackdrop: false
                       });
                       this._modalRef.afterClosed.pipe(
                           take(1)
                       ).subscribe(() => {
                           this._modalRef = null;
                           this.onTooltipSubject.next(true);
                           // Turn on the tooltip
                           resolve(true);
                       });
                   }).catch(() => {
                       reject(false);
                   });
           }
       })
    }

    /**
     * Show tour only if it hasn't yet been seen. Then, set the flag
     * in the DB so it isn't shown to the same user again. The ngZone
     * call was added in response to DE11308 --
     * https://github.cdinteractive.com/twinspires/cdux-tux/pull/2706
     */
    public showTour() {
        this._tourDataService.getTourFlag().subscribe(hasViewed => {
            if (!hasViewed) {
                this._eventTrackingService.logClickEvent(EventClickType.START_TOUR);
                this._zone.run(() => {
                    this.openTourModal();
                    this._tourDataService.setTourFlag().pipe(
                        take(1)
                    ).subscribe();
                });
            }
        })
    }
}


