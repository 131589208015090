import { Pipe, PipeTransform } from '@angular/core';
import { FormatDistanceUtil } from '../../utils';

@Pipe({
  name: 'formatDistance'
})
export class FormatDistancePipe implements PipeTransform {
    public transform(value: number, unit = 'y'): string {
        return FormatDistanceUtil.format(value, unit);
    }
}
