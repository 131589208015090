<ng-container *ngIf="errorCode !== null; else depositMain">
  <div id="greendot-lockout-error-txt" class="ts-message error" *ngIf="lockout">
    <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
    <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
  </div>
  <ng-template #chat><cdux-chat></cdux-chat></ng-template>

  <div class="error-icon" *ngIf="!lockout">
    <i class="icon-exclamation-error-large"></i>
  </div>
  <h2>GreenDot ATR is unavailable</h2>
  <ng-container *ngIf="phoneEnabled else chatMessage">
    <p>Please select another payment method or contact player services at:</p>
    <p>{{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</p>
  </ng-container>
  <ng-template #chatMessage>
    <p>Please select another payment method or <cdux-chat></cdux-chat>.</p>
  </ng-template>


  <div class="try-again--btn" (click)="clearError()">Try Again</div>
</ng-container>

<ng-template #depositMain>
  <ng-container *ngIf="!fundingMethodDetails.locked">
  <header class="funding-content_header">
    <!-- back arrow hidden on desktop -->
    <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
    <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="deposit-close-btn"></i>
    <h1>GREEN DOT</h1>
    <div class="funding-type-icon">
      <i class="icon-nav-greendot"></i>
    </div>
  </header>
  <div class="funding-content_details-block">
    <form #greenDotForm ngNoForm id="greenDotForm" name="greenDotForm" action="{{ formUrl }}" method="post" style="display: none;">
      <input id="greenDotID" type="hidden" name="partnerid" value="{{partnerId}}"/>
      <textarea id="greenDotToken" name="encrypted_data" hidden>{{ requestToken }}</textarea>
    </form>
    <div>
      <p><i class="icon--gdr"></i></p>
      <p class="p-general" *ngIf="!displayError">Please visit Green Dot to generate a Barcode.</p>
      <p class="p-general">This barcode will allow you to deposit cash into your {{ 'affiliate-name' | cduxTranslate:affiliateId.toString(10) }}
        account at a retailer near you.</p>
      <div class="deposit--btn large"
          (click)="navigateToGreenDot()"
      >GO TO GREEN DOT</div>
    </div>
  </div>
  </ng-container>
</ng-template>
