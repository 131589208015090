import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';

import { RadioGroupComponent } from './radio-group.component';

const PREFIX = 'cdux-funding-input-amount';

let NEXT_UNIQUE_ID = 0;

@Component({
  selector: 'cdux-radio-option',
  templateUrl: './radio-option.component.html',
  styleUrls: [ './radio-option.component.scss' ]
})
export class RadioOptionComponent implements OnInit {

  // Name of the Radio Button
  @Input() public name: string;

  // Whether the Radio Button is Checked
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (this._checked !== value) {
      this._checked = value;

      if (value && this.radioGroup && this.radioGroup.value !== this.value) {
        this.radioGroup.selected = this;
      } else if (!value && this.radioGroup && this.radioGroup.value === this.value) {
        this.radioGroup.selected = null;
      }

      this.markForCheck();
    }
  }

  // Value of the Radio Button
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      if (this.radioGroup) {
        if (this.checked) {
          this.radioGroup.selected = this;
        } else {
          this.checked = this.radioGroup.value === value;
        }
      }
    }
  }

  // Whether the Radio Button is Disabled
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
    }
  }

  // Whether the Radio Button is Required
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    if (this._required !== value) {
      this._required = value;
    }
  }

  @Input() set id(value: string) {
    value = PREFIX + value;
    if (this._id !== value) {
      this._id = value;
    }
  }
  get id(): string {
    return this._id;
  }

  public radioGroup: RadioGroupComponent;

  private _id = `${PREFIX}-${NEXT_UNIQUE_ID++}`;
  private _value: any;
  private _checked = false;
  private _disabled = false;
  private _required = false;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    // Empty
  }

  public ngOnInit() {
    if (this.radioGroup) {
      this.checked = this.radioGroup.value === this.value;
      this.name = this.radioGroup.name;
    }
  }

  /**
   * Marks the Radio Group to be Checked by the CDR
   */
  public markForCheck() {
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Handles the Native Input Click Event
   * @param event - Native Click Event
   */
  public onClick(event: Event) {
    // Prevent Default Radio Button Behavior
    event.stopPropagation();
  }

  /**
   * Handles the Native Input Change Event
   * @param event - Native Change Event
   */
  public onChange(event: Event) {
    // Prevent Default Radio Button Behavior
    event.stopPropagation();
    this.checked = true;
  }
}
