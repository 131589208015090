
<section *ngIf="!!wagerState?.betNav?.type" class="compact-total-bar">
    <div class="total-bar-top-row">
        <div class="track-race">
            <span>{{ wagerState.basicTrack.FormattedTrackName | uppercase }}</span>
            <span>R{{ wagerState.basicTrack.RaceNum }}</span>
        </div>
        <cdux-runner-bar class="compact" [compact]="true" [wagerState]="wagerState" [showBetDetails]="entriesAreSelected" [showSaddleCloths]="true"
            [raceStatus]="raceStatus" [keepPicks]="keepPicks" (onClear)="deselectAll()" (onKeepPicksToggle)="handletoggleKeepPicks()">
        </cdux-runner-bar>
    </div>

    <div class="total-bar-bottom-row">
        <ng-template #loadingDots>
            <div class="button-loader green">
                <div class="loader-dots">
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                </div>
            </div>
        </ng-template>
        <ng-container *ngIf="!submittedWagerState; else submittedWagerFunctions">
            <div class="wager-group">
                <div *ngIf="entriesAreSelected" class="bet-total">
                    <span class="label">Total:</span>
                    <span class="total" id="bet-nav-total">{{ total | currency:'USD':'symbol-narrow' }}</span>
                </div>
                <div class="action-wrapper" *ngIf="!tournamentSelected && isBetShareEnabled && allowsBetShare && showShareBetButton">
                    <div id="bet-nav-share" class="action-button" [class.is-inactive]="!isValid"
                        (click)="isValid && !sharing && initiateBetShare()">
                        <span *ngIf="!sharing; else loadingDots">Share</span>
                    </div>
                </div>
                <div class="action-wrapper" *ngIf="!tournamentSelected">
                    <div id="bet-nav-add" class="action-button" [class.is-inactive]="!isValid"
                        *ngIf="isSavedBetsEnabled" (click)="isValid && !saving && saveBet()">
                        <div class="logo-placeholder" #badge></div>
                        <span *ngIf="!saving; else loadingDots">Save</span>
                    </div>
                </div>
                <div class="action-wrapper">
                    <div id="bet-nav-quick-bet" class="action-button btn-quick-bet" [class.is-inactive]="!isValid"
                        (click)="isValid && !submitting && submitQuickBet()">
                        <span *ngIf="!submitting; else loadingDots">Submit</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #submittedWagerFunctions>
            <div class="success-group">
                <div class="action-wrapper">
                    <div
                        id="bet-nav-done"
                        class="action-button"
                        (click)="!submitting && isValid && submitQuickBet(betActionTypes.REPEAT_BET)"
                        (clickOutside)="clearSubmittedWager()">
                        <ng-container *ngIf="!submitting; else loadingDots">Repeat Bet</ng-container>
                    </div>
                </div>
                <div class="action-wrapper">
                    <div id="bet-nav-repeat" class="action-button" (click)="clearSubmittedWager()">
                        <span>Done</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</section>
