export const LANG_WAGER_ERRORS_NAME = 'wager-errors';

/**
 * maps backend strings to Delta Burkes-approved, customer-facing ones
 *
 * To reference the customer-facing strings, use the BET_ERROR_CODES enum
 * defined in bet-error-codes.enum.ts.
 */
export const LANG_WAGER_ERRORS = {
    'ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION': 'Insufficient funds.',
    'ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION BUT NO DEPOSIT REDIRECT': 'Insufficient funds.',
    'QUICKBET ACCOUNT BALANCE IS LESS THAN COST OF TRANSACTION': 'Insufficient funds.',
    'BET AMOUNT IS TOO SMALL': 'You have not met the minimum bet amount. Please modify your bet.',
    'GEOLOCATION REQUIRED': '%s residents are required to enable Location Services to wager.',
    'MULTIPLE BETS FAILED': 'Sorry, your bets cannot be placed',
    'RACE IS CLOSED - NO FURTHER BETS ARE ALLOWED': 'Race is off and no longer accepting bets.',
    'REQUESTED BET AMOUNT IS NOT ALLOWED': 'You have exceeded the allowed bet amount. Please modify your bet.',
    'REQUESTED POOL IS NOT DEFINED IN THE SYSTEM': 'This bet type is not available for this race. Please modify your bet.',
    'REQUESTED PROGRAM IS NOT AVAILABLE': 'Race is no longer available.',
    'Sorry! One of these runners is no longer available. Please modify your picks & resubmit your wager.': 'This bet contains a horse(s) that has scratched. Please modify your bet.',
    'Selected Race is %s minutes or less to Post Time. Cannot place wager.': 'This betshare cannot be submitted. Too close to post time',
    'Expired JWT': 'Your session has expired. Please log in to place a wager.',
    'fallback': 'Sorry, your bet cannot be placed.',
    'Conditional wagering is restricted at this track.': 'Sorry, conditional wagering is not currently available for this track at this time',

    /*
     * This message requires customer-specific content (state name and wagering
     * phone number), and must be overwritten with one built dynamically in code.
     *
     * Note that the style attr is stripped from [innerHtml], so there isn't
     * a direct way to prevent the phone number from wrapping.
     * See https://stackoverflow.com/q/39628007/356016 for workarounds.
     *
     * See buildError in BetSlipErrorsService for an example.
     */
    'Online wagering is restricted for your state.': 'Sorry, %s residents are only able to wager on this track over the phone. Please call %s.',
    'Wagering is restricted for your state.': 'Sorry, %s residents are not allowed to wager on this track.'
};
