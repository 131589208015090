import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
    enumTrackType,
    enumPoolType,
    enumRaceStatus,
    EventClickAttributeType,
    EventClickType,
    ProgramEntry,
    TrackService,
    WagerState,
    JwtSessionService,
    ToteDataService,
    WagerService
} from '@cdux/ng-common';
import { IExpertPickAnalysis } from '@cdux/ng-common/services/data/adw-track/interfaces/expert-pick-analysis.interface';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { ExpertVideoModalService } from '../expert-video/services/expert-video.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';

export class ExpertPickEntry {
    entry: ProgramEntry;
    titleText: string;
    saddleClothClass: string;
}

@Component({
    selector: 'cdux-expert-pick-analysis',
    templateUrl: './expert-pick-analysis.component.html',
    styleUrls: ['./expert-pick-analysis.component.scss']
})
export class ExpertPickAnalysisComponent implements OnInit, OnDestroy {
    private static SUGGESTED_WAGER_AMOUNT = 1; // default base bet amount
    private static MAX_ENTRIES = 3; // maximum entries for box selections

    @Input()
    public view: WageringViewEnum;

    @Input()
    public section: ViewSectionEnum;

    @Input()
    public set wagerState(wagerState) {
        this._wagerStateSubject.next(wagerState);
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }

    @Input()
    public set raceStatus(status: enumRaceStatus) {
        this._raceStatus = status;
        this.isWagerable = TrackService.isWagerableRace(status);
    }
    public get raceStatus(): enumRaceStatus {
        return this._raceStatus;
    }

    @Output()
    public onSelection = new EventEmitter<ISelectionUpdate[]>();

    public expertPick: IExpertPickAnalysis;
    public expertPickEntries: ExpertPickEntry[];
    public wagerAmount: number = ExpertPickAnalysisComponent.SUGGESTED_WAGER_AMOUNT;

    public isWagerable: boolean = false;
    public isSelected: boolean = false;
    public canSelectEntries: boolean = false;

    private _toteDate: string;
    private _destroy = new Subject();
    private _expertPickSubscription: Subscription;

    private _wagerStateSubject = new BehaviorSubject<WagerState>(null);
    private _wagerState: WagerState;
    private _raceStatus: enumRaceStatus;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _todaysRaceService: TodaysRacesBusinessService,
        private _videoService: ExpertVideoModalService,
        private _wagerUtilService: WageringUtilBusinessService,
        private _eventTrackingService: EventTrackingService,
        private _toteDataService: ToteDataService,
        private _wagerService: WagerService
    ) {}

    public ngOnInit() {
        this._toteDataService.currentRaceDate().pipe(
            takeUntil(this._destroy)
        ).subscribe((date: string) => {
            this._toteDate = date;
        });

        this._wagerStateSubject.pipe(
            debounceTime(200),
            takeUntil(this._destroy)
        ).subscribe((wagerState) => {
            if (!TrackService.isSameTrack(wagerState?.basicTrack, this._wagerState?.basicTrack) ||
                wagerState.basicTrack.RaceNum !== this._wagerState.basicTrack.RaceNum) {
                this._wagerState = wagerState;
                this._getExpertPicks();
            } else if (this.isSelected) {
                this._wagerState = wagerState;

                // remove selected state if selected entries have changed from the expert picks
                this.isSelected = wagerState?.bettingInterests?.length > 0 && wagerState.bettingInterests.every((l) =>
                    l?.length === Math.min(
                        this.expertPickEntries?.filter((e) => !e.entry.Scratched).length,
                        ExpertPickAnalysisComponent.MAX_ENTRIES
                    ) &&
                    l.every((e) => this.expertPick?.expertPicks?.includes(e.ProgramNumber))
                );

                this._changeDetector.markForCheck();
            }
        });

        this._wagerService.wagerPlaced.pipe(
            takeUntil(this._destroy)
        ).subscribe((result) => {
            if (result?.success && this.isSelected) {
                this.logClickEvent(EventClickType.EXPERT_PICKS_SUBMIT_WAGER);
            }
        });
    }

    public ngOnDestroy() {
        this._destroy.next();
    }

    public selectPicks() {
        if (this.isSelected) {
            this.isSelected = false;
            this.onSelection.emit([]);
            return;
        } else if (!this.isWagerable || !this.canSelectEntries) {
            return;
        }

        const selection: ISelectionUpdate[] = [{
            entries: this.expertPickEntries
                .filter(e => !e.entry.Scratched)
                .slice(0, ExpertPickAnalysisComponent.MAX_ENTRIES)
                .map(e => this._wagerUtilService.toSelectedEntry(e.entry)),
            selected: true,
            leg: 0
        }];

        this.isSelected = true;
        this.onSelection.emit(selection);
        this.logClickEvent(EventClickType.EXPERT_PICKS_SELECT_ENTRIES);
    }

    private _getExpertPicks() {
        this.isSelected = false;
        this.expertPick = null;
        this.expertPickEntries = [];

        this._expertPickSubscription?.unsubscribe();
        if (!this.wagerState?.basicTrack) {
            return;
        }

        this._expertPickSubscription = combineLatest([
            this._todaysRaceService.getTodaysRaceBetTypes(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            ),
            this._todaysRaceService.getTodaysRaceEntries(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            ),
            this._todaysRaceService.getTodaysRaceExpertPick(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            )
        ]).pipe(
            takeUntil(this._destroy)
        ).subscribe(([betTypes, raceEntries, expertPick]) => {
            if (!expertPick?.expertPicks) {
                this.isSelected = false;
                this.expertPick = null;
                this.expertPickEntries = [];
                return;
            }

            this.expertPick = expertPick;
            this.expertPick.expertPicks = expertPick.expertPicks.filter(p => !!p);
            this.expertPickEntries = expertPick.expertPicks.map((programNumber) =>
                raceEntries.find((e) => e.ProgramNumber === programNumber)
            ).filter((e) => !!e).map((programEntry) => ({
                entry: programEntry,
                titleText: programEntry.ProgramNumber + ': ' + programEntry.Name +
                    (programEntry.Scratched ? ' (scratched)' : ''),
                saddleClothClass: this._wagerUtilService.getSaddleClothClass(
                    expertPick.trackType as enumTrackType,
                    programEntry.BettingInterest
                )
            }));

            // determine if bet type and amount are available and we have enough entries
            const betType = betTypes.find(bt => bt.poolType.code === enumPoolType.EXACTA);
            if (betType) {
                this.canSelectEntries = betType.poolType.selectionCount <= this.expertPickEntries.filter(e => !e.entry.Scratched).length;
                this.wagerAmount = +(betType.betAmounts.find((ba) => +ba.value === ExpertPickAnalysisComponent.SUGGESTED_WAGER_AMOUNT)
                    || betType.betAmounts[0]).value; // fall back to first valid amount if suggested amount not available
            } else {
                this.canSelectEntries = false;
                this.wagerAmount = ExpertPickAnalysisComponent.SUGGESTED_WAGER_AMOUNT;
            }
        }, () => {
            this.expertPick = null
            this.expertPickEntries = [];
        });
    }

    private logClickEvent(event: EventClickType) {
        const ts = Date.now();

        switch (event) {
            case EventClickType.PROGRAM_TIPS_VIDEO:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_TIPS_VIDEO, [
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_VIDEO_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_VIDEO_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_VIDEO_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_VIDEO_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TIPS_VIDEO_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            case EventClickType.EXPERT_PICKS_SELECT_ENTRIES:
                this._eventTrackingService.logClickEvent(EventClickType.EXPERT_PICKS_SELECT_ENTRIES, [
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SELECT_ENTRIES_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SELECT_ENTRIES_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SELECT_ENTRIES_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SELECT_ENTRIES_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SELECT_ENTRIES_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            case EventClickType.EXPERT_PICKS_SUBMIT_WAGER:
                this._eventTrackingService.logClickEvent(EventClickType.EXPERT_PICKS_SUBMIT_WAGER, [
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SUBMIT_WAGER_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SUBMIT_WAGER_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SUBMIT_WAGER_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SUBMIT_WAGER_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.EXPERT_PICKS_SUBMIT_WAGER_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            default:
                this._eventTrackingService.logClickEvent(event);
                break;
        }
    }

    public showVideo() {
        if (this._sessionService.isLoggedIn() === true) {
            this.logClickEvent(EventClickType.PROGRAM_TIPS_VIDEO);
            this._videoService.showVideo(this.expertPick.videoUrl, true, this.expertPick.header);
        } else {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        }
    }
}
