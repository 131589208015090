import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    ITrackBasic,
    TrackService,
    EventClickType,
    EventClickAttributeType,
    SortingBusinessService
} from '@cdux/ng-common';
import { IPoolsShared, IPoolsSharedEntry, PoolsBusinessService } from 'app/shared/program/services/pools.business.service';
import { interval, of, Subject, throwError } from 'rxjs';
import { catchError, debounce, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PoolSubView } from '../wagering-views/enums/pool-sub-view.enum';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ISortState, SortStateHandlerClass } from 'app/shared/program/classes/sort-state-handler.class';
import { enumPoolsSort } from './enums/pools-sort-columns.enum';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { DisplayModeEnum } from 'app/shared';

@Component({
    selector: 'cdux-pools-shared',
    templateUrl: './pools-shared.component.html',
    styleUrls: ['./pools-shared.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolsSharedComponent implements OnInit, OnDestroy {
    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isSameTrack(track, this._track)) {
            this._track = track;
            this._poolsSubThrottle.next();
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._poolsSubThrottle.next();
        }
    }
    public get race(): number {
        return this._race;
    }

    @Input()
    public currentView: PoolSubView = PoolSubView.TOTALS;

    @Input()
    public displayMode: DisplayModeEnum;

    public currentPools: IPoolsShared;
    public poolView = PoolSubView;
    public exoticPoolsAvailable = true;

    public enumPoolsSort = enumPoolsSort;
    public sortState: ISortState<enumPoolsSort>;
    private _sortStateHandler: SortStateHandlerClass<enumPoolsSort>;

    public isLoading: boolean = false;

    private _poolsSubThrottle: Subject<any> = new Subject();

    private _destroy: Subject<any> = new Subject();

    private _track: ITrackBasic = null;
    private _race: number = null;

    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _poolsBusinessService: PoolsBusinessService,
        protected _viewStateService: ViewStateService
    ) {
        this._sortStateHandler = new SortStateHandlerClass<enumPoolsSort>(this._determineInitialSort());
        [
            enumPoolsSort.PROGRAM_NUMBER,
            enumPoolsSort.ODDS
        ].forEach(sort => this._sortStateHandler.updateDefaultAscending(sort, true));
    }

    ngOnInit(): void {

        this._sortStateHandler.listen().pipe(takeUntil(this._destroy)).subscribe((sortState) => {
            this.sortState = sortState;
            this._viewStateService.setSortState(ViewSectionEnum.POOLS, this.sortState);
            this._sortEntries(sortState);
        });

        this._poolsSubThrottle.pipe(
            tap(() => {
                this.isLoading = true;
                this.exoticPoolsAvailable = true;
            }),
            debounce(() => interval(100)),
            switchMap(() => {
                if (this.track && this.race) {
                    return this._poolsBusinessService.getPools(this.track.BrisCode, this.track.TrackType, this.race)
                } else {
                    throwError(new Error('Missing track/race'));
                }
            }),
            catchError(() => of(null)),
            takeUntil(this._destroy),
            withLatestFrom(this._sortStateHandler.listen())
        ).subscribe(
            ([data, sortState]: [IPoolsShared, ISortState<enumPoolsSort>]) => {
                this.currentPools = data;
                if (this.currentPools) {
                    this._sortEntries(sortState);
                }
                this.isLoading = false;
                this._changeDetectorRef.markForCheck();
            }
        );

        this._poolsSubThrottle.next();
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    public trackByProgramNumber(index: number, entry: IPoolsSharedEntry) {
        return entry.ProgramNumber;
    }

    public isScratched(entry: IPoolsSharedEntry): boolean {
        return entry.Win.toString(10) === '-2'
    }

    public sortBy(columnName: enumPoolsSort, forceAscending?: boolean) {
        if (forceAscending) {
            this._sortStateHandler.updateSortProperty(columnName, forceAscending);
        } else {
            this._sortStateHandler.updateSortProperty(columnName)
        }
    }

    private _sortEntries(sortState: ISortState<enumPoolsSort>) {
        let scratched: IPoolsSharedEntry[] = [];
        let newEntries: IPoolsSharedEntry[] = [];
        if (this.currentPools) {
            this.currentPools.entries.map((entry: IPoolsSharedEntry) => {
                this.isScratched(entry) ? scratched.push(entry) : newEntries.push(entry);
            });

            if (sortState.sortProperty === enumPoolsSort.ODDS) {
                newEntries.sort((a, b) => {
                    return this._compareOddsRank(a, b);
                });
            } else {
                newEntries = SortingBusinessService.sort(newEntries, [{target: sortState.sortProperty, ascending: sortState.ascending}]);
            }

            scratched = SortingBusinessService.sort(scratched, [{target: enumPoolsSort.PROGRAM_NUMBER, ascending: sortState.ascending}]);
            this.currentPools.entries = newEntries.concat(scratched);
        }
    }

    private _determineInitialSort(): ISortState<enumPoolsSort> {
        return this.sortState = this._viewStateService.getSortState(ViewSectionEnum.POOLS) as ISortState<enumPoolsSort>;
    }

    private _compareOddsRank(a: IPoolsSharedEntry, b: IPoolsSharedEntry): number {
        let ret: number = 0;

        // I'm not sure how often the situation will arise where wps pools returns entries that we don't have program
        // data for, but it happened while I was testing so here it is.
        // a and b both have entries
        if (a.Entries.length && b.Entries.length) {
            ret = (a.Entries[0].OddsRank < b.Entries[0].OddsRank ? -1 : a.Entries[0].OddsRank > b.Entries[0].OddsRank ? 1 : 0);
        } else if (!a.Entries.length && b.Entries.length) { // a doesn't have entries and b does so rank a lower
            ret = 1;
        } else if (a.Entries.length && !b.Entries.length) { // a has entries and b doesn't so rank b lower
            ret = -1
        }

        return ret * (this.sortState.ascending ? 1 : -1);
    }

    public isHighAmount( arr: number[]): boolean {
        return arr.some(x => x > 9999999);
    }

    public isLargeView(): boolean {
        return this.displayMode === DisplayModeEnum.LARGE ;
    }

    public updateExoticPoolsAvailable(exoticPoolsAvailable: boolean) {
        this.exoticPoolsAvailable = exoticPoolsAvailable;
    }
}
