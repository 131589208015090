import { ComponentType, TemplatePortal } from '@angular/cdk/portal';

export class MobileFooterConfig {

    constructor(
        public dynamicRef: MobileFooterConfigDynamicRef
    ) {
        // Empty
    }

}

export class MobileFooterConfigDynamicRef {

    constructor(
        public componentOrTemplateRef: ComponentType<any> | TemplatePortal<any>,
        public inputs?: { [key: string]: any }
    ) {
        // Empty
    }

}
