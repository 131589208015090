<div class="account-history-bet"
     [cduxLogClick]="transaction.isExpanded ? eventClickType.ACCOUNT_HISTORY_COLLAPSE : eventClickType.ACCOUNT_HISTORY_EXPAND"
     [ngClass]="{'odd-row' : index % 2 !== 0 }"
     (click)="toggleExpandedState()">
    <div *ngIf="isWager">
        <div class="account-history-bet__title-bar">
            <h2 class="title">{{ transaction.title | titlecase }}</h2>
            <h2 *ngIf="transaction.profitLoss" class="title right" [ngClass]="valueClassName">{{ isNumber(transaction.profitLoss) ? (transaction.profitLoss | currency:'USD':'symbol-narrow':'1.2-2') : transaction.profitLoss}}</h2>
            <h2 *ngIf="!transaction.profitLoss" class="title right" [ngClass]="valueClassName">{{transaction.value | currency:'USD':'symbol-narrow' | negativeSymbol}}</h2>
        </div>
        <div class="account-history-bet__title-bar">
            <h2 class="title">{{ transaction.subtitle | titlecase }}</h2>
        </div>
        <ng-container>
            <div class="account-history-bet__row" *ngIf="!transaction.betShareData.betShare; else betshare">
                <span class="account-history-bet__bet-amount">{{transaction.wagerAmount | currency:'USD':'symbol-narrow'}}</span>
                <span class="account-history-bet__bet-type">{{transaction.betType | formatWager:transaction.runners:runnerDelimiter}}</span>
            </div>
        </ng-container>
        <ng-template #betshare>
            <div class="account-history-bet__row flex">
                <span class="account-history-bet__bet-amount">{{transaction.betShareData.participantCost | currency:'USD':'symbol-narrow'}}</span>
                <span class="account-history-bet__bet-type">{{transaction.betType | formatWager:transaction.runners:runnerDelimiter}}</span>
                <i class="icon--betshare--primary" *ngIf="transaction.betShareData.betShare"></i>
            </div>
        </ng-template>
        <div class="account-history-bet__row flex">
            <span class="bets">{{transaction.runners | formatRunners:runnerDelimiter}}</span>
            <div class="icon--ellipsis is-open" [ngClass]="{'is-open': transaction.isExpanded}"></div><!-- add is-open class when the transaction is clicked -->
        </div>
        <div class="account-history-bet__total-row">

        </div>
    </div>
    <!--Funding related content, currently empty-->
    <div *ngIf="!isWager">
        <div class="account-history-bet__title-bar">
            <h2 class="title">{{ transaction.category | titlecase }}</h2>
            <h2 *ngIf="transaction.profitLoss" class="title right" [ngClass]="valueClassName">{{ isNumber(transaction.profitLoss) ? (transaction.profitLoss | currency:'USD':'symbol-narrow':'1.2-2') : transaction.profitLoss}}</h2>
            <h2 *ngIf="!transaction.profitLoss" class="title right" [ngClass]="valueClassName">{{transaction.value | currency:'USD':'symbol-narrow' | negativeSymbol}}</h2>
        </div>
        <div class="account-history-bet__title-bar">
            <h2 *ngIf="isFunding" class="title">{{ transaction.title }}</h2>
            <h2 *ngIf="isAdjustment" class="title">{{ transaction.title | titlecase }}</h2>
        </div>
        <div class="account-history-bet__row">&nbsp;</div>
        <div class="account-history-bet__row flex">&nbsp;
            <div class="icon--ellipsis is-open" [ngClass]="{'is-open': transaction.isExpanded}"></div><!-- add is-open class when the transaction is clicked -->
        </div>
        <div class="account-history-bet__total-row">

        </div>
    </div>
    <div class="account-history-bet__details is-open is-visible" *ngIf="transaction.isExpanded">
        <div class="account-history-bet__details-row" *ngIf="isWager">
            <div class="account-history-bet__details-label">Status</div>
            <div class="account-history-bet__details-data status" [ngClass]="wagerStatusClassName">
                {{transaction.status}}
            </div>
        </div>
        <ng-container *ngIf="isWager && transaction.betShareData.betShare">
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">My Shares</div>
                <div class="account-history-bet__details-data ">{{ ownedBetSharesLabel }}</div>
            </div>
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">Cost of Shares</div>
                <div class="account-history-bet__details-data ">1 / {{ transaction.betShareData.pricePerShare | currency:'USD':'symbol-narrow' }}</div>
            </div>
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">My Bet Contribution</div>
                <div class="account-history-bet__details-data ">{{ transaction.betShareData.participantCost | currency:'USD':'symbol-narrow' }} of {{ (transaction.debitAmount || transaction.betShareData.betShareCost) | currency:'USD':'symbol-narrow' }}</div>
            </div>
        </ng-container>
        <ng-container *ngIf="isWager">
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">Finish</div>
                <div class="account-history-bet__details-data">{{!!transaction.finishOrder ? transaction.finishOrder : DEFAULT_FIELD_SYMBOL}}</div>
            </div>
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">Debit</div>
                <div class="account-history-bet__details-data">{{transaction.debitAmount | currency:'USD':'symbol-narrow':'1.2-2' }}</div>
            </div>
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">Payout</div>
                <div class="account-history-bet__details-data" *ngIf="transaction.isCanceled
                                                                        || transaction.isPending
                                                                        || transaction.status === wagerDisplayStatus.CANCELLED
                                                                        || transaction.isFrozen;
                                                                        else showPayout">
                    {{DEFAULT_FIELD_SYMBOL}}
                </div>
                <ng-template #showPayout>
                    <div class="account-history-bet__details-data">
                        {{ isNumber(transaction.payout) ? (transaction.payout | currency:'USD':'symbol-narrow':'1.2-2') : transaction.payout }}
                    </div>
                </ng-template>
            </div>
            <div class="account-history-bet__details-row">
                <div class="account-history-bet__details-label">Profit/Loss</div>
                <div class="account-history-bet__details-data" *ngIf="transaction.isCanceled
                                                                     || transaction.isPending
                                                                     || transaction.status === wagerDisplayStatus.CANCELLED
                                                                     || transaction.status === wagerDisplayStatus.REFUNDED
                                                                     || transaction.isFrozen;
                                                                     else showPL">
                    {{DEFAULT_FIELD_SYMBOL}}
                </div>
                <ng-template #showPL>
                    <div class="account-history-bet__details-data">
                        {{ isNumber(transaction.profitLoss) ? (transaction.profitLoss | currency:'USD':'symbol-narrow':'1.2-2') : transaction.profitLoss }}
                    </div>
                </ng-template>
            </div>
        </ng-container>
        <div class="account-history-bet__details-row" *ngIf="isFunding && isToday">
            <div class="account-history-bet__details-label">Account ID</div>
            <div class="account-history-bet__details-data">{{!!transaction.accountId ? transaction.accountId : DEFAULT_FIELD_SYMBOL}}</div>
        </div>
        <div class="account-history-bet__details-row" *ngIf="isAdjustment">
            <div class="account-history-bet__details-label">Reason</div>
            <div class="account-history-bet__details-data">{{!!transaction.reason ? transaction.reason : DEFAULT_FIELD_SYMBOL}}</div>
        </div>
        <div class="account-history-bet__details-row" *ngIf="transaction.transactionId">
            <div class="account-history-bet__details-label">Transaction ID</div>
            <div class="account-history-bet__details-data">{{transaction.transactionId}}</div>
        </div>
        <div class="account-history-bet__details-row">
            <div class="account-history-bet__details-label">Date</div>
            <div class="account-history-bet__details-data">{{transaction.transactionDate | date: 'longDate'}}</div>
        </div>
        <div class="account-history-bet__details-row">
            <div class="account-history-bet__details-label">Time</div>
            <div class="account-history-bet__details-data">{{transaction.transactionDate | date: 'mediumTime'}}/{{transaction.transactionDate | timezone}}</div>
        </div>
        <div class="account-history-bet__details-row function-row" *ngIf="isWager">
            <ng-container *ngIf="!cancelToggle; else cancelConfirmMessage">
                <div class="account-history-bet__function-row-left-col">
                    <!-- BUTTON: Cancel -->
                    <i *ngIf="transaction.wagerInteractionToggles?.canCancel" class="icon-account-history-bet delete"
                       (click)="toggleCancel()"
                       cduxClickStopPropagation></i>
                    <!-- BUTTON: Copy -->
                    <i *ngIf="transaction.wagerInteractionToggles?.canCopy" class="icon-account-history-bet copy"
                       (click)="copyWagerTransaction(transaction)"
                       cduxClickStopPropagation></i>
                    <!-- BUTTON: Program/Results -->
                    <i *ngIf="isProgramAvailable || isResultAvailable" class="icon-account-history-bet program"
                       [cduxLogClick]="eventClickType.ACCOUNT_HISTORY_PROGRAM"
                       (click)="resultsNavigate(transaction)"
                       cduxClickStopPropagation></i>
                    <!-- BUTTON: Watch -->
                    <i *ngIf="isProgramAvailable" class="icon-account-history-bet play"
                       (click)="watchWager(transaction)"
                       cduxClickStopPropagation></i>
                </div>
                <!-- BUTTON: Claim Winnings -->
                <div class="account-history-bet__function-row-right-col">
                    <div class="btn-bet-slip-submit" *ngIf="transaction.isFrozen && !hasFullSsn" (click)="toggleSsnCollection()" cduxClickStopPropagation>Claim Winnings</div>
                </div>
            </ng-container>
            <ng-template #cancelConfirmMessage>
                <div class="buttons">
                    <div class="cancel-bet">
                        <div class="prompt">
                            Are you sure you want to cancel this bet?
                        </div>
                        <div class="options">
                            <div class="btn" (click)="toggleCancel()">
                                <div class="dismiss">
                                    No
                                </div>
                            </div>
                            <div class="btn" (click)="cancelWager()">
                                <div class="confirm">
                                    Yes
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>
