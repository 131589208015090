import { Injectable } from '@angular/core';
import { SessionKeys } from '@cdux/ng-common';



@Injectable({
    providedIn: 'root'
})
export class MfaBusinessService {

    constructor() {}

    public formatPhoneNum(phoneNum: string): string {
        const phoneNumDigits = phoneNum.replace(/\D/g, '');
        const phoneNumLength = phoneNumDigits.length;
        let formattedNumber: string;
        for(let i = 0; i < phoneNumLength; i++) {
            if(phoneNumLength > 10){
                formattedNumber = phoneNumDigits.substring(1, 4) + '-' + phoneNumDigits.substring(4, 7) + '-' + phoneNumDigits.substring(7);
            } else {
                formattedNumber = phoneNumDigits.substring(0, 3) + '-' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
            }
        }
        return formattedNumber;
    }

    public removeMfaRmdLocalStorage(username: string) {
        localStorage.removeItem(this._getEncodedKey(username));
    }

    public setMfaRmdLocalStorage(username: string, token: string): void {
        localStorage.setItem(this._getEncodedKey(username), token);
    }

    public getMfaRmdLocalStorage(username: string): string {
        const mfaRmdToken = localStorage.getItem(this._getEncodedKey(username));
        if(mfaRmdToken) {
            return mfaRmdToken;
        } else {
            return '';
        }
    }

    private _getEncodedKey(username: string): string {
        return btoa(username + '-' + SessionKeys.REMEMBER_MFA_DEVICE)
    }
 }
