import { LANG_COMMON } from './common';

export const LANG_4200_NAME = '4200';

// 4200 specific extension of the common affiliate dictionary
const LANG_4200_EXTENSION = {
  'customer-service': '1-800-292-BETS (1-800-292-2387)',
  'player-services-email': 'player.services@CapitalOTBBet.com',
  'registration-cellphone-alert-header': '',
  'registration-cellphone-alert-body': '',
  'registration-ssn-alert-body': '',
  'registration-player-services-fax': '1-859-223-9141',
  'registration-player-services-phone': '1-855-845-5035',
  'registration-account-exists-body': '',
  'registration-success-header-woffer': '',
  'bet-share-registration-success-header-woffer': '',
  'primary-phone-number': '1-800-292-BETS (1-800-292-2387)',
  'primary-phone-number-vanity': '1-800-292-BETS',
  'primary-phone-hyperlink': 'tel:18002922387',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': '',
  'twitter-url': '',
  'instagram-url': '',
  'support-display-social-media-icons': '',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'capitalotbbet.com',
  'moneygram-receiveId': '14244',
  'affiliate-name': 'Capital OTB'
};

export const LANG_4200_TRANS = Object.assign({}, LANG_COMMON, LANG_4200_EXTENSION);
