import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CduxMediaModule } from '@cdux/ng-platform/web';
import { EventSessionModule } from '@cdux/ng-common';

import { TuxCommonModule } from '../common/common.module';
import { BreadcrumbsComponent } from './breadcrumbs.component'
import { BreadcrumbsService } from './services/breadcrumbs.service';

@NgModule({
    declarations: [
        BreadcrumbsComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TuxCommonModule,
        CduxMediaModule,
        EventSessionModule
    ],
    exports: [
        TuxCommonModule,
        BreadcrumbsComponent
    ],
})
export class BreadcrumbsModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<BreadcrumbsModule> {
        return {
            ngModule: BreadcrumbsModule,
            providers: [
                BreadcrumbsService,
            ],
        }
    }
}
