import { Injectable } from '@angular/core';
import { IW2GSummaryDataResponse, IW2GYearList, W2GDataService } from '@cdux/ng-common';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TaxDocumentsBusinessService {

    constructor(
        private _w2gDataService: W2GDataService
    ) {}

    public getYearList(): Observable<IW2GYearList> {
        return this._w2gDataService.getW2GYearsList()
            .pipe(map((yearsList) => {
                return yearsList
            }),
            catchError(err => {
                return throwError(err);
            })
        );
    }

    public getYearSummary(year: number): Observable<IW2GSummaryDataResponse> {
        return this._w2gDataService.getW2GYearSummary(year)
            .pipe(map((summary) => {
                return summary
            }),
            catchError(err => {
                return throwError(err);
            })
        );
    }
}
