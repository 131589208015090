import {
    Component, OnInit
} from '@angular/core';

import {AbstractManageInputComponent} from '../abstract-manage-input.component';
import {enumManageAccountType} from '../../enums/manage-account.enum';

@Component({
    selector: 'cdux-updatepin',
    templateUrl: './update.pin.html',
    styleUrls: ['../manage.component.scss']
})

export class UpdatePinComponent  extends AbstractManageInputComponent implements OnInit {

    constructor() {
        super(enumManageAccountType.PIN);
    }

    ngOnInit() {
        this.newControl = this.formGroup.get('newPin');
        this.confirmControl = this.formGroup.get('confirmPin');
        this.passwordControl = this.formGroup.get('currentPin');
    }

    public showInValidPinError(): boolean {
        return  !!this.newControl.errors && this.newControl.errors.pininvalid;
    }

}
