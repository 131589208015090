<section *ngIf="todaysRunners.length">
    <header aria-label="Running today">Running Today</header>
    <div class="tile-container">
        <cdux-favorites-tile-jockey *ngFor="let jockey of todaysRunners" [jockeyData]="jockey"></cdux-favorites-tile-jockey>
    </div>
</section>

<section *ngIf="notTodaysRunners.length">
    <header aria-label="manage stable">
        <div class="header__col1">MANAGE STABLE</div>
        <div class="header__col2" (click)="sort(notTodaysRunners)">&#x2630;&#x2193; {{ !sortDesc ? 'A/Z' : 'Z/A' }}</div>
    </header>
    <header class="header--secondary">
        <div class="row-container__col1 is-sortable" [class.is-sorted-by]="isSortedBy" (click)="sort(notTodaysRunners)">Jockey</div>
        <div class="row-container__col2">Record</div>
        <div class="row-container__col3">Notes</div>
        <div class="row-container__col4">Actions</div>
    </header>
    <ng-container *ngFor="let jockey of notTodaysRunners; index as i">
        <div class="row-container" [class.first]="i===0">
            <div class="row-container__col1">
                <span id="fav-jockey-{{ jockey.firstName + jockey.lastName }}" class="trainer-name">{{ formatName(jockey.firstName, jockey.middleName, jockey.lastName) }}</span>
                <span role="link" class="link" (click)="showStatsModal(jockey)">Stats</span>
            </div>
            <div id="fav-jockey-record" class="row-container__col2">
                <span class="row-container__title-name">Record</span>
                <span class="row-container__content">{{ formatRecord(jockey.record)}}</span>
            </div>
            <div id="fav-jockey-notes" class="row-container__col3">
                <span id="jockey-notes" class="notes">{{ jockey.comment }}</span>
            </div>
            <div class="row-container__col4">
                <span id="fav-jockey-edit" aria-label="edit this jockey" class="link" (click)="edit(jockey)">Edit</span>
                <span aria-hidden="true" class="text__gray2">|</span>
                <span id="fav-jockey-remove" aria-label="remove this jockey" class="link" (click)="remove(jockey)">Remove</span>
            </div>
        </div>
    </ng-container>
</section>

<cdux-favorites-manager-empty id="no-favorite-runners" *ngIf="noFavorites"></cdux-favorites-manager-empty>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #removeModalTemplate>
    <div class="favorite-modal">
        <i class="icon--close" (click)="dismissModal()"></i>
        <h2>
            <span id="favorite-add-modal-description" class="runner">
                Are you sure you want to remove {{ jockeyName }}?
            </span>
        </h2>
        <div class="message">
            You will no longer receive alerts and all notes will be lost.
        </div>
        <div class="button-row">
            <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
            <div class="btn-remove" (click)="confirmRemove()">Remove</div>
        </div>
    </div>
</ng-template>

<ng-template #updateModalTemplate>
    <div class="favorite-modal">
        <i class="icon--close" (click)="dismissModal()"></i>
        <h2>
            <span id="favorite-update-modal-description" class="runner">
              {{ formatName(modalJockey.firstName, modalJockey.middleName, modalJockey.lastName) }}</span>
        </h2>
        <textarea id="favorite-add-modal-note"
                cols="50" [maxlength]="COUNT_MAX" name="note"
                placeholder="ADD A NOTE..." rows="13"
                [(ngModel)]="note"
        ></textarea>
        <div class="char-count"></div>
        <div class="btn-update" (click)="confirmUpdate(modalJockey)">Update</div>
    </div>
</ng-template>

<ng-template #statsModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1"></div>
      <div class="col2"></div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div id="favorite-jockey-modal-detailoptions" class="scrollable">
      <cdux-favorite-stats-jockey
        [detailOptions]="options"
      ></cdux-favorite-stats-jockey>
    </div>
  </div>
</ng-template>

