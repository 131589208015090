import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RedirectService {
    constructor(private router: Router) {
    }

    navigate(url: string) {
        const redirectTo = url.charAt(0) === '/' ? url : '/' + url;
        this.router.navigate([redirectTo]);
    }
}
