import { Component, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import { ISidebarPortalComponent, ISidebarComponentProperties } from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { of } from 'rxjs';
import { tap, delay, takeUntil } from 'rxjs/operators';

export const SIDEBAR_KEYS = {
    DISPLAY_TIME: 'displayTime',
    BET_SHARE_DISPLAY_TIME: 'betShareDisplayTime',
    MESSAGE: 'message'
};

@Component({
    selector: 'cdux-bet-success-panel',
    templateUrl: './bet-success-panel.component.html',
    styleUrls: ['./bet-success-panel.component.scss']
})
export class BetSuccessPanelComponent extends CduxSidebarContentComponent implements AfterViewInit, OnDestroy {

    /**
     * The displayTime property provides the time in milliseconds
     * that the succees panel component should show.
     *
     * @type {number} - representing milliseconds
     * @memberof BetSuccessPanelComponent
     */
    @Input()
    public displayTime: number;

    @Input()
    public message: string;

    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**************************************************
     * Sidebar (to become: Nav Panel) Implementation
     **************************************************/
    public static getSidebarComponent(options: any): ISidebarPortalComponent {
        const _inputTokens = new Map<any, any>();
        if (options.hasOwnProperty(SIDEBAR_KEYS.DISPLAY_TIME)) {
            _inputTokens.set(SIDEBAR_KEYS.DISPLAY_TIME, options[SIDEBAR_KEYS.DISPLAY_TIME])
        }
        if (options.hasOwnProperty(SIDEBAR_KEYS.BET_SHARE_DISPLAY_TIME)) {
            _inputTokens.set(SIDEBAR_KEYS.BET_SHARE_DISPLAY_TIME, options[SIDEBAR_KEYS.BET_SHARE_DISPLAY_TIME])
        }

        if (options.hasOwnProperty(SIDEBAR_KEYS.MESSAGE)) {
            _inputTokens.set(SIDEBAR_KEYS.MESSAGE, options[SIDEBAR_KEYS.MESSAGE])
        }

        return {
            component: BetSuccessPanelComponent,
            properties: {
                inputs: _inputTokens,
                navTarget: options.navTarget || MenuItemsEnum.BET_SLIP
            }
        }
    }
    public setProperties(properties: ISidebarComponentProperties): void {
        if (properties.inputs) {
            this.displayTime = properties.inputs.get(SIDEBAR_KEYS.BET_SHARE_DISPLAY_TIME) || properties.inputs.get(SIDEBAR_KEYS.DISPLAY_TIME);
            this.message = properties.inputs.get(SIDEBAR_KEYS.MESSAGE);
        }
    }
    /**************************************************/

    constructor(
        private _sidebarService: SidebarService
    ) {
        super();
        /* empty */
    }

    ngAfterViewInit() {
        // Close out the success panel once it's been alive for configured
        // amount of time.
        of('').pipe(
            delay(this.displayTime),
            takeUntil(this._destroyed),
            tap(_ => this._sidebarService.close(true))
        ).subscribe();
    }

    ngOnDestroy() {
        this._destroyed.emit(true);
    }
}
