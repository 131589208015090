import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { Subscription, fromEvent, Subject, Observable, combineLatest } from 'rxjs';

import {
    IAdwTrackOddsMtpPost,
    ITrack,
    IWinOddsEntry,
    ToteDataService,
    TrackService,
    SortUpcomingTracksPipe,
    JwtSessionService,
    TodaysDisplayTrack,
} from '@cdux/ng-common';
import { enumDropDownIdentifier, DropdownService, LoadingService, LoadingDotsComponent } from '@cdux/ng-fragments';

import { TodaysRacesBusinessService } from '../program/services/todays-races.business.service';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cdux-tote-board',
    styleUrls: [ './tote-board.component.scss' ],
    templateUrl: './tote-board.component.html'
})
export class ToteBoardComponent implements OnInit, OnDestroy {
    static OPEN_DELAY_MS = 0;

    public loadingKey = 'toteBoardLoading';
    public loadingDotsComponent = LoadingDotsComponent;

    public isActive = false; // enabled and ready
    public isOpen = false; // toggles the display

    public showFavorites = false;
    public isSelectFavoriteClickable = false;

    public maxRunners = 0;
    public favoriteMaxRunners = 0;
    public loadingComplete: boolean;
    public trackList: ITrack[] = [];

    public favoriteTrackList: ITrack[] = [];
    public trackOdds: {[key: string]: IWinOddsEntry[]} = {};

    private oddsDataSubscription: Subscription;
    private trackDataSubscription: Subscription;
    private documentClickSubscription: Subscription;
    private _destroy: Subject<boolean> = new Subject();

    @Input() public canClickOutOfToteBoard: boolean;

    @Input()
    public set active(value: boolean) {
        if (this.isActive !== value) {
            if (value) {
                this.activate();
            } else {
                this.deactivate();
            }
        }
    }

    @Input()
    public set tracks(value: ITrack[]) {
        // ignore changes while active
        if (this.isActive && this.trackList.length > 0) { return; }
        // ignore unnecessary updates
        if (this.isSameList(value, this.trackList)) { return; }
        // copy array to break reference
        this.trackList = value.slice(0, 5);
    }

    @Input()
    public set favoriteTracks(value: ITrack[]) {
        // ignore unnecessary updates
        if (this.isSameList(value, this.favoriteTrackList) && this.favoriteTrackList.length > 0) { return; }
        // copy array to break reference
        this.favoriteTrackList = value.slice(0, 5);
        // resubscribe if already active
        if (this.isActive) { this.setupDataSubscriptions(); }
    }

    @Output()
    private activeChange = new EventEmitter<boolean>();

    @Output()
    private select = new EventEmitter<ITrack>();

    @Output()
    private isFavoriteTabActive = new EventEmitter<boolean>();

    constructor(
        public sessionService: JwtSessionService,
        private loadingService: LoadingService,
        private sortTracksPipe: SortUpcomingTracksPipe,
        private toteDataService: ToteDataService,
        private todaysRacesService: TodaysRacesBusinessService,
        private dropdownService: DropdownService
    ) { }

    ngOnInit() {
        this.loadingService.register(this.loadingKey);
        this.loadingComplete = false;
        this.sessionService.onAuthenticationChange.pipe(
            startWith(this.sessionService.isLoggedIn()),
            distinctUntilChanged(),
            takeUntil(this._destroy)
        ).subscribe((isLoggedIn: boolean) => {
            this.showFavorites = isLoggedIn;
            this.isFavoriteTabActive.emit(isLoggedIn);
        });
        this.isSelectFavoriteClickable = this.sessionService.isLoggedIn();
    }

    ngOnDestroy() {
        this.deactivate();
        this._destroy.next();
        this._destroy.complete();
    }

    public setShowFavorites(bool: boolean) {
        this.showFavorites = bool;
        this.isFavoriteTabActive.emit(bool);
    }

    public selectTrack(track: ITrack) {
        this.select.emit(track);
    }

    public series(begin: number, end: number) {
        const series = [];
        if (begin <= end) {
            for (let i = begin; i <= end; i++) {
                series.push(i);
            }
        }
        return series;
    }

    public toggleToteBoard(state = !this.isActive) {
        if (state) {
            this.loadingService.register(this.loadingKey);
            this.activate();
        } else {
            this.deactivate(() => this.activeChange.emit(this.isActive));
        }
    }

    public getTrackMTP(track: ITrack) {
        if (TrackService.isTrackOff(track)) {
            return 'OFF';
        } else {
            return track.Mtp;
        }
    }

    public getTrackOddsEntries(track: ITrack) {
        return track && this.trackOdds && this.trackOdds[(track.BrisCode + ':' + track.TrackType).toUpperCase()] || [];
    }

    private activate() {
        if (this.isActive) {
            // clean up prior active subscriptions
            this.deactivate(() => this.activate());
            return;
        }

        this.isActive = true;
        this.setupDataSubscriptions();

        // tote board opening css animation controlled by 'is-open' class
        setTimeout(() => this.isOpen = true, 0); // must set asynchronous
        setTimeout(() => { // delayed to allow css transition to complete
            this.documentClickSubscription = fromEvent(document, 'click').subscribe(
                () => this.canClickOutOfToteBoard || this.deactivate(() => this.activeChange.emit(this.isActive))
            );
        }, ToteBoardComponent.OPEN_DELAY_MS);
    }

    private setupDataSubscriptions() {
        // cleanup the data service subscriptions so that the services can be created with different parameters.
        this.cleanupDataSubscriptions();

        // get odds for current and favorite tracks in separate requests to allow for more aggressive caching
        this.oddsDataSubscription = combineLatest(
            this.getOddsDataSubscription(this.trackList),
            this.getOddsDataSubscription(this.favoriteTrackList)
        ).subscribe(
            ([oddsdata, favoritesdata]) => {
                if (oddsdata) {
                    this.maxRunners = this._processOddsData(oddsdata);
                }
                if (favoritesdata) {
                    this.favoriteMaxRunners = this._processOddsData(favoritesdata);
                }
                this.loadingService.resolve(this.loadingKey);
                this.loadingComplete = true;
            },
            (error) => {
                this.loadingService.resolve(this.loadingKey, 1000, 'error', error.message);
                this.loadingComplete = true;
            }
        )

        // use this service for MTP, Post Time, and Race for consistency with program
        this.trackDataSubscription = this.getTrackDataSubscription();
    }

    private _processOddsData(data: IAdwTrackOddsMtpPost[]): number {
        let maxRunners = 0;

        for (const d of data) {
            if (!d || !d.brisCode || !d.trackType || !d.winOddsEntries) { continue; }
            this.trackOdds[(d.brisCode + ':' + d.trackType).toUpperCase()] = d.winOddsEntries;
            if (d.winOddsEntries.length > maxRunners) { maxRunners = d.winOddsEntries.length; }
        }

        return maxRunners;
    }

    private deactivate(callback?) {
        if (this.documentClickSubscription && !this.documentClickSubscription.closed) {
            this.documentClickSubscription.unsubscribe();
        }

        this.cleanupDataSubscriptions();

        this.trackOdds = {};

        this.isOpen = false; // animated
        // delay for css close animation
        setTimeout(() => {
            if (this.isActive) {
                this.isActive = false;
            }

            if (callback) {
                callback();
            }
        }, ToteBoardComponent.OPEN_DELAY_MS)
    }

    private cleanupDataSubscriptions() {
        // cleaning up the data service subscriptions only
        if (this.oddsDataSubscription && !this.oddsDataSubscription.closed) {
            this.oddsDataSubscription.unsubscribe();
            this.oddsDataSubscription = null;
        }

        if (this.trackDataSubscription && !this.trackDataSubscription.closed) {
            this.trackDataSubscription.unsubscribe();
            this.trackDataSubscription = null;
        }

    }

    private getOddsDataSubscription(trackList: ITrack[]): Observable<IAdwTrackOddsMtpPost[]> {
        return this.toteDataService.currentOddsMtpPost(trackList, true).pipe(takeUntil(this._destroy));
    }

    private getTrackDataSubscription() {
        // Overwritting the PostTime, Current Race Number and MTP from the current track list.
        // This is done to make sure these values are consistent across the application.
        return this.todaysRacesService.getTodaysTracks(true, true).pipe(takeUntil(this._destroy)).subscribe(
            (tracks: TodaysDisplayTrack[]) => {
                for (const t1 of tracks.map(t => t.ITrack)) {
                    for (const t2 of this.trackList) {
                        if (TrackService.isSameTrack(t1, t2)) {
                            t2.PostTime = t1.PostTime;
                            t2.RaceNum = t1.RaceNum;
                            t2.Mtp = t1.Mtp;
                            break;
                        }
                    }

                    for (const t2 of this.favoriteTrackList) {
                        if (TrackService.isSameTrack(t1, t2)) {
                            t2.PostTime = t1.PostTime;
                            t2.RaceNum = t1.RaceNum;
                            t2.Mtp = t1.Mtp;
                            break;
                        }
                    }

                    // sort local track lists again since MTPs have changed
                    this.trackList = this.sortTracksPipe.transform(this.trackList);
                    this.favoriteTrackList = this.sortTracksPipe.transform(this.favoriteTrackList);
                }
            }
        );
    }

    private isSameList(a: ITrack[], b: ITrack[]) {
        const hash = (tracks: ITrack[]) => tracks.map(t => t.BrisCode + ':' + t.TrackType).join('~');
        return a && b && hash(a) === hash(b);
    }

    public openTrackListDropDown() {
        this.dropdownService.openDropDown(enumDropDownIdentifier.TRACK_LIST_DROPDOWN);
    }
}
