import { ChangeDetectorRef, Component, Optional, Self, ChangeDetectionStrategy, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pairwise, takeWhile } from 'rxjs/operators';

import { enumBetModifier, enumFeatureToggle, FeatureToggleDataService } from '@cdux/ng-common';
import { ModalService, CduxMediaToggleService } from '@cdux/ng-platform/web';
import { AbstractDropupSelectComponent, enumDropdownStates } from '@cdux/ng-fragments';

import { enumWagerInfoType } from '../../../../bet-pad/shared/learn-more/wager-type-info.component';

@Component({
    selector: 'cdux-betpad-sub-type',
    templateUrl: './betpad-sub-type.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetPadSubTypeComponent extends AbstractDropupSelectComponent<enumBetModifier, BetPadSubTypeComponent> implements OnInit, OnDestroy {
    public enumWagerInfoType = enumWagerInfoType;

    public learnMoreEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BET_LEARN_MORE);

    /**
     * Flag for showing the "NEW" indicator for a specific modifier (POWER BOX).
     * @type {boolean}
     */
    @HostBinding('class.has-flag')
    public hasFlag: boolean = false;
    private static readonly HAS_SEEN_FLAG_STORAGE_KEY = 'BetPadSubTypeComponent::HasSeenFlag::POWER_BOX';
    private _hasSeenFlag = localStorage.getItem(BetPadSubTypeComponent.HAS_SEEN_FLAG_STORAGE_KEY) === 'true';
    private _flaggedModifier = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.POWER_BOX_ICON) ? enumBetModifier.POWER_BOX : null;
    private _dropdownStateSub: Subscription;

    /**
     * Header text for the dropup.
     * @type {string}
     */
    public dropupHeaderText = 'Bet Subtype';

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetector
     * @param {CduxMediaToggleService} _mediaQueryService
     * @param {ModalService} _modalService
     * @param {NgControl} _control
     */
    constructor(
        _changeDetector: ChangeDetectorRef,
        _mediaQueryService: CduxMediaToggleService,
        _modalService: ModalService,
        @Self() @Optional() _control: NgControl,
        private _featureToggleService: FeatureToggleDataService
    ) {
        super(
            _changeDetector,
            BetPadSubTypeComponent,
            _mediaQueryService,
            _modalService,
            _control
        );
    }

    public ngOnInit() {
        super.ngOnInit();
        this._dropdownStateSub = this._hasSeenFlag || !this._flaggedModifier ? null :
            this.dropdown?.stateChange.pipe(
                distinctUntilChanged(), pairwise(), takeWhile(([fromState])=> {
                    if (!this.hasFlag) {
                        return false; // no need to monitor state
                    } else if (fromState === enumDropdownStates.OPEN) {
                        this._recordFlagSighting(); // clear flag
                        return false; // no need to monitor state
                    }
                    return true;
                })
            ).subscribe();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this._dropdownStateSub?.unsubscribe();
    }

    public isNew(subType: enumBetModifier) {
        if (!this._hasSeenFlag && subType === this._flaggedModifier) {
            this.hasFlag = true;
            return true;
        }
        return false;
    }

    private _recordFlagSighting() {
        localStorage.setItem(BetPadSubTypeComponent.HAS_SEEN_FLAG_STORAGE_KEY, 'true');
        this._hasSeenFlag = true;
        this.hasFlag = false;
    }
}
