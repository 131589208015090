import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CduxTitleService } from 'app/shared/common/services/title/title.service';
import { LayoutHeaderFooterComponent } from 'app/shared/layout/components/layout-header-footer/layout-header-footer.component';

@Injectable()
export class WageringViewsRouteGuard implements CanDeactivate<LayoutHeaderFooterComponent> {
    private _ignoredProgramPaths = [];

    constructor (private _router: Router, private _title: CduxTitleService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | true {
        return true;
    }

    /**
     * Determine if the requested route is compatible with the current view and redirect accordingly.
     *
     * The regex and route manipulation here is dependent on the basic program module routing scheme:
     *     /program/TRACK_NAME_OR_SECTION/[TRACK/TYPE/RACE/[SECTION]]
     *
     * ...and the basic wagering views module routing scheme:
     *     /beta/VIEW/TRACK_NAME_OR_SECTION/[TRACK/TYPE/RACE/[SECTION]]
     *
     * If the TRACK_NAME_OR_SECTION route segment is not in the ignoredProgramPaths array, this method
     * simply replaces the first segment of the requested route with the first 2 segments of the current
     * route and then returns the updated UrlTree back to the router for navigation.
     */
    canDeactivate(component: LayoutHeaderFooterComponent, route: ActivatedRouteSnapshot, current: RouterStateSnapshot, request: RouterStateSnapshot): UrlTree | true {
        // determine secondary route segment for program routes
        const matches = request.url.match('/program/([^/]+)/'),
            path = matches && matches[1]; // get second segment

        // adjust and redirect a supported path back to current route
        if (path && this._ignoredProgramPaths.indexOf(path) === -1) {
            const currentPath = current.url.split('/'),
                requestedPath = request.url.split('/');

            // replace initial path segments with current base path
            requestedPath.splice(0, 2, ...currentPath.slice(0, 3));

            // keep trailing section parameter if we are about to lose it
            if (currentPath.length === 8 && requestedPath.length === 7) {
                requestedPath.push(currentPath[7]);
            }

            // construct a new UrlTree to redirect user navigation
            return this._router.parseUrl(requestedPath.join('/'));
        }

        // restore initial title since we are leaving views module
        this._title.restoreTitle();

        // allow navigation to continue for any unsupported routes
        return true;
    }
}
