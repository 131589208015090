import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TuxCommonModule } from '../../shared/common/common.module';
import { LayoutModule } from '../../shared/layout/layout.module';

import { VideoModule } from '../video/video.module';
import { TermsModule } from '../../shared/terms/terms.module';

import { WageringLayoutComponent } from './wagering.layout.component';

@NgModule({
    declarations: [
        WageringLayoutComponent
    ],
    imports: [
        CommonModule,
        TuxCommonModule,
        LayoutModule,
        VideoModule,
        TermsModule,
    ],
    exports: [
        VideoModule,
        WageringLayoutComponent
    ],
})
export class WageringLayoutModule {
    // Empty
}
