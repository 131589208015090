<ng-container *ngIf="!tournamentSelected; else TournamentSelectedView">
    <div *cduxLoading="'rewardsOverlay'; strategy:'overlay'; component:loadingDotsComponent;">
        <div class="nav-panel-content rewards">
            <ng-container *ngIf="displayState === rewardsDisplayStatus.POINTS" [formGroup]="form">
                <div class="deposit-form-info">
                    <div class="input-block">
                        <div class="amount-min" [class.text-error]="value < min">Min<br />{{min | number}}</div>
                        <input id="rewardsInput" class="deposit--amount amount-entered"
                            [ngClass]="{'small-text' : smallTextClass}" formControlName="amount"
                            [cduxTextMask]="rewardsMask" type="tel">
                        <div class="amount-max" [class.text-error]="value > max">Max<br />{{max | number}}</div>
                    </div>
                </div>
                <div class="rewards-error" *ngIf="form.invalid && inputError">
                    {{inputError}}
                </div>
                <div class="sidebar-content">
                    <div class="sidebar-inner-wrap light">
                        <div class="points">
                            <span class="label">Points:</span>
                            <span id="demo-points" class="value">{{ playerRewards?.totalPointsAvailable }}</span>
                        </div>
                        <div class="rule primary"></div>
                        <div class="cash">
                            <span class="label">Cash value:</span>
                            <span id="demo-cash" class="value">{{ playerRewards?.cashValue |
                                currency:'USD':'symbol-narrow' }}</span>
                        </div>
                    </div>

                    <div class="gbl-button-wrap primary full">
                        <button [class.is-disabled]='form.disabled || form.invalid' class="button"
                            (click)="redeemRewards()" [cduxLogClick]="eventClickType.REWARDS_REDEEM_BUTTON">
                            <span *ngIf="!submitting">{{ redemptionText }}</span>
                            <div *ngIf="submitting" class="button-loader standard-color">
                                <div class="loader-dots">
                                    <i class="dots-animated"></i>
                                    <i class="dots-animated"></i>
                                    <i class="dots-animated"></i>
                                    <i class="dots-animated"></i>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </ng-container>
            <div class="sidebar-content" *ngIf="displayState === rewardsDisplayStatus.ERROR">
                <div class="sidebar-inner-wrap">
                    <div class="instructions">
                        <span class="text">{{message}}</span>
                    </div>
                    <div class="gbl-button-wrap"></div>
                </div>
            </div>
            <cdux-rewards-success [message]="message" [isMobile]="isMobile"
                *ngIf="displayState === rewardsDisplayStatus.SUCCESS && !isMobile"></cdux-rewards-success>
        </div>
    </div>
</ng-container>

<ng-template #TournamentSelectedView>
    <div class="tournaments__container account__preferences">
        <main class="tournaments__page-unavailable">
            <div class="center-text"><i class="icon--bang-light amber block-center"></i></div>
            <div class="center-text bold-text">PAGE UNAVAILABLE</div>
            <p class="center-text">To access this page, you must switch to your primary account.</p>
        </main>
    </div>
</ng-template>
