<div class="runner-list" [class.inline]="inline" [class.has-place-leg]="runnerListElements.length > 1" *ngFor="let leg of runnerListElements; let i = index">
    <span class="place-leg" *ngIf="runnerListElements.length > 1">
        <ng-container *ngIf="raceLegs === 1; else MultiLeg">
            {{ i + 1 }}<span>{{ FormatRank.getSuffix(i + 1) }}</span>
        </ng-container>
        <ng-template #MultiLeg>
            {{ raceNumbers && raceNumbers[i] ? 'R' + raceNumbers[i] : ('L' + (i + 1)) }}
        </ng-template>
    </span>
    <span *ngFor="let e of leg; let i = index" [ngClass]="e.class">
        {{ e.content }}
    </span>
</div>
