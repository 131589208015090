import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    enumTodaysTracksSortOrder,
    IFavoriteTrack,
    IFavoriteTrackDisplay,
} from '@cdux/ng-common';

import { TodaysRacesBusinessService } from '../../../shared/program/services/todays-races.business.service';
import { TodaysRacesViewEnum } from '../../../shared/program/enums/todays-races-view.enum';

@Component({
    templateUrl: './favorites-manager-tracks.component.html',
    styleUrls: [ './favorites-manager-tracks.component.scss' ],
})
export class FavoritesManagerTracksComponent implements OnInit, OnDestroy {

    public favoritesEmpty = false;

    public favoritesToday: Partial<IFavoriteTrackDisplay>[] = [];
    public favoritesElse: Partial<IFavoriteTrackDisplay>[] = [];
    private _destroy: Subject<boolean> = new Subject();

    public constructor (
        private _todayRaceBusinessService: TodaysRacesBusinessService,
    ) {}

    public ngOnInit () {

        /**
         * For stable tracks not running today, we may only have the track code.
         */
        this._todayRaceBusinessService.getFavoriteTracksIncludeNotRunning(true, true, enumTodaysTracksSortOrder.MTP, TodaysRacesViewEnum.TRACK).pipe(
            takeUntil(this._destroy)
        ).subscribe((favAllTracks) => {
            this.favoritesToday = favAllTracks.favoriteTracksToday;
            this.favoritesElse = favAllTracks.favoriteTracksNotRunning;
            this.favoritesEmpty = this.favoritesToday.length === 0 && this.favoritesElse.length === 0;
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    public trackingTrack (fav: IFavoriteTrack ) {
        return '' + fav.brisCode + fav.trackType;
    }
}
