import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import {
    MfaDataService,
    IMfaResponse,
} from '@cdux/ng-common';
import {
    ISidebarHeaderComponent,
    ISidebarPortalComponent,
} from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import { take } from "rxjs/operators";
import { MfaFormHeaderComponent } from "../enable-mfa-form/mfa-form-header";
import { LoadingDotsComponent, LoadingService } from '@cdux/ng-fragments';


@Component({
    selector: 'cdux-mfa-preferences',
    templateUrl: './mfa-preferences.component.html',
    styleUrls: ['./mfa-preferences.component.scss']
})
export class MfaPreferencesComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    public mfaEnabled:boolean = false;
    public loadingDotsComponent = LoadingDotsComponent;
    public responseReceived:boolean = false;
    public userMfaPreferences:IMfaResponse;

    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();

    /*********************************
     * SidbarComponent Implementation
     *********************************/
    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: MfaPreferencesComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            },
        };
    }

    public static getHeaderComponent(): ISidebarHeaderComponent {
        return {
            component: MfaFormHeaderComponent,
            properties: {
                preventDefaultHeaderButtonAction: true,
            }
        }
    }

    public setProperties(): void {
        //    required method from CduxSidebarContentComponent
    }

    constructor(
        private _mfaDataService: MfaDataService,
        private _loadingService: LoadingService,
    ) {
        super();
    }

    ngOnInit() {
        this._getMfaUserInfo();
    }

    ngOnDestroy() {
        this._destroyed.next(true);
    }

    public getUpdatedMfaUserInfo(): void {
        this._getMfaUserInfo();
    }

    private _getMfaUserInfo(): void {
        this._loadingService.register('mfaPrefOverlay');
        this._mfaDataService.getMfaUserInfo().pipe(take(1)).subscribe((response: IMfaResponse) => {
            if (response) {
                this.mfaEnabled = response.mfaEnabled;
                this.userMfaPreferences = response;
                this._loadingService.resolve('mfaPrefOverlay', 100, 'success');
                this.responseReceived = true;
            }
        });
    }

}
