import { NgModule, ModuleWithProviders, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRouteSnapshot, Resolve, Route, RouterModule } from '@angular/router';

import { TermTypes } from '@cdux/ng-common';
import { LoadingService } from '@cdux/ng-fragments';
import { CduxModalModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from '../common/common.module';

import { TermsComponent } from './components';
import { TermsDirective } from './directives';
import { TermsService } from './services';
import { LayoutHeaderFooterComponent } from '../layout/components/layout-header-footer/layout-header-footer.component';

@Injectable()
export class TermsAndConditionsResolver implements Resolve<string> {
    constructor (
        private _loadingService: LoadingService,
        private _termsService: TermsService
    ) {}

    public resolve(route: ActivatedRouteSnapshot): Promise<string> {
        this._loadingService.register('routeLoader');
        const termType = route.data.terms || route.params.terms || TermTypes.TERMS_AND_CONDITIONS;
        return this._termsService.getTermsHtml(termType).finally(
            () => this._loadingService.resolve('routeLoader')
        );
    }
}

export const Routes: Route[] = [{
    path: 'terms',
    component: LayoutHeaderFooterComponent,
    children: [
        {
            path: '',
            component: TermsComponent,
            resolve: { html: TermsAndConditionsResolver },
        }, {
            path: ':terms',
            component: TermsComponent,
        }
    ]
}];

@NgModule({
    imports: [
        RouterModule.forChild(Routes),
        CommonModule,
        TuxCommonModule,
        CduxModalModule,
    ],
    declarations: [
        TermsComponent,
        TermsDirective,
    ],
    exports: [
        RouterModule,
        CduxModalModule,
        TermsComponent,
        TermsDirective,
    ],
})
export class TermsModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<TermsModule> {
        return {
            ngModule: TermsModule,
            providers: [
                TermsService,
                TermsComponent,
                TermsAndConditionsResolver,
            ],
        }
    }
}
