<section class="lightbox-form-wrapper">
    <!--Close button-->
    <div *ngIf="!submitting" class="close-btn" [cduxLogClick]="eventClickType.BETSHARE_SSN_CLOSE" (click)="close()">
        <div class="icon--login-close"></div>
        <div class="close-txt">Close</div>
    </div>

    <div class="lightbox--form">
        <div class="icon--ts-logo-large"></div>

        <div class="lightbox--panel" *ngIf="!submitted || (!success && canRetry)">
            <div class="ts-message error" *ngIf="submitted && !success">
                We could not verify the SSN. Please
                try again or call Player Services at
                <span style="white-space: nowrap;">{{ 'primary-phone-number-vanity' | cduxTranslate:AFFILIATE_KEY }}</span>.
            </div>
            <div class="panel-inner-wrap">
                <h2>WHEN YOU WIN BIG!</h2>
                <p class="center">Due to tax regulations, we must receive every Bet Share participant's full Social Security Number (SSN) to release your winnings.</p>

                <p class="center bold">Please provide your full SSN:</p>

                <form #thisForm="ngForm" name="ssnc-form" (ngSubmit)="thisForm.form.valid && submitSsn()" onsubmit="return false;">
                    <!-- Adding return false on submit to prevent propagation in the event of an Angular break -->
                    <div class="form-wrap-3col ssn">
                        <div class="form-label-hover number">
                            <input class="form-control" type="text" name="ssn3" maxlength="3" [ngClass]="{ 'has-value': ssn3 }" [(ngModel)]="ssn3" required>
                            <label>3 digits</label>
                        </div>

                        <div class="center"><span>-</span></div>

                        <div class="form-label-hover number">
                            <input class="form-control" type="text" name="ssn2" maxlength="2" [ngClass]="{ 'has-value': ssn2 }" [(ngModel)]="ssn2" required>
                            <label>2 digits</label>
                        </div>

                        <div class="center"><span>-</span></div>

                        <div class="form-label-hover number is-not-allowed">
                            <input class="form-control" type="text" name="ssn4" maxlength="4" disabled="disabled">
                            <label>{{ ssn4 }}</label>
                        </div>
                    </div>

                    <div class="submitBtnWrapper">
                        <button type="submit" class="submit--btn" [cduxLogClick]="eventClickType.BETSHARE_SSN_SUBMIT" [disabled]="thisForm.form.pristine || thisForm.form.invalid">Submit</button>
                    </div>

                    <div class="cancelBtnWrapper">
                        <button class="cancel--btn" (click)="close()" [cduxLogClick]="eventClickType.BETSHARE_SSN_CLOSE">Cancel</button>
                      </div>
                </form>
            </div>
        </div>

        <div class="lightbox--panel alert-success" *ngIf="submitted && success">
            <div class="panel-inner-wrap">
                <h2>SSN sent successfully</h2>
                <!-- TODO?: replace with loading.service -->
                <div class="icon-alert-wrapper is-ssn-betshare">
                    <svg class="icon-alert" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                        <circle class="icon-alert-circle" cx="26" cy="26" r="26"></circle>
                        <path class="icon-alert-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                    </svg>
                </div>
                <p class="center">Your Social Security Number has been saved.</p>
            </div>
        </div>

        <div class="lightbox--panel alert-failure" *ngIf="submitted && !success && !canRetry">
            <div class="panel-inner-wrap">
                <h2>Unable to Verify</h2>
                <!-- TODO?: replace with loading.service -->
                <div class="icon-alert-wrapper is-ssn-betshare">
                    <svg class="icon-alert" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                        <circle class="icon-alert-circle" cx="26" cy="26" r="26"></circle>
                    </svg>
                    <div class="icon--bang-light"></div>
                </div>
                <p class="center">There was a problem validating your
                    information. Please call
                    <span style="white-space: nowrap;">{{ 'primary-phone-number' | cduxTranslate:AFFILIATE_KEY }}</span>
                    for assistance.</p>

                <div class="submitBtnWrapper">
                    <button type="submit" class="submit--btn" [cduxLogClick]="eventClickType.BETSHARE_SSN_HARD_FAILURE" (click)="close()">Close</button>
                </div>
            </div>
        </div>
    </div>
</section>
