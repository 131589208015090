<div class="mtp-bar">
    <ng-container *ngIf="!showFavorites">
        <div class="mtp-bar__label-block">
            <span>Up Next</span>
        </div>

        <div [style.visibility]='scrollArrows?.displayLeft ? "visible" : "hidden"' class='scroll-arrow left' (click)='scrollTo(enumArrowDirection.LEFT)'><i
            class='icon--arrow-left--primary'></i></div>


            <div class="mtp-bar__items" #scrollableItems>
                <div class="mtp-bar-item"
                     *ngFor="let track of trackList"
                     (click)="selectTrack(track)">
                    <cdux-mtp-badge class="up-next-mtp" [source]="track" [showMinutes]="true"></cdux-mtp-badge>
                    <div  class="mtp-bar-item__track-race">
                        <cdux-track-group class="in-footer" [track]="track" [showFavorite]="false">
                            <span class="track-name">{{ track | formatTrackName }}</span>
                            <div class="race">Race {{ track.RaceNum }}</div>
                        </cdux-track-group>
                    </div>
                </div>
            </div>

        <div [style.visibility]='scrollArrows?.displayRight ? "visible" : "hidden"' class='scroll-arrow right' (click)='scrollTo(enumArrowDirection.RIGHT)'><i
            class='icon--arrow-right--primary'></i></div>
        <div class="mtp-bar__open-btn-container" *ngIf="isToteBoardEnabled" (click)="toggleToteBoard()">
            <span class="toggle-btn">Show</span>
        </div>
    </ng-container>

    <ng-container *ngIf="showFavorites">
        <div class="mtp-bar__label-block">
            <span>Favorites</span>
        </div>

        <div [style.visibility]='scrollArrows?.displayLeft ? "visible" : "hidden"' class='scroll-arrow left' (click)='scrollTo(enumArrowDirection.LEFT)'><i
            class='icon--arrow-left--primary'></i></div>

        <div class="mtp-bar__items" #scrollableItems>
            <div class="mtp-bar-item"
                    *ngFor="let i = index; let track of favoriteTrackList"
                    (click)="selectTrack(track)">
                <cdux-mtp-badge class="up-next-mtp" [source]="track" [showMinutes]="true"></cdux-mtp-badge>
                <div  class="mtp-bar-item__track-race">
                    <cdux-track-group [track]="track" [showFavorite]="false">
                        <span class="track-name">{{ track | formatTrackName }}</span>
                        <div class="race">Race {{ track.RaceNum }}</div>
                    </cdux-track-group>
                </div>
            </div>
        </div>

        <div [style.visibility]='scrollArrows?.displayRight ? "visible" : "hidden"' class='scroll-arrow right' (click)='scrollTo(enumArrowDirection.RIGHT)'><i
            class='icon--arrow-right--primary'></i></div>
        <div class="mtp-bar__open-btn-container" *ngIf="isToteBoardEnabled" (click)="toggleToteBoard()">
            <span class="toggle-btn">Show</span>
        </div>
    </ng-container>
</div>

<cdux-tote-board *ngIf="isToteBoardEnabled"
    [(active)]="isToteBoardActive"
    [tracks]="trackList"
    [canClickOutOfToteBoard]="canClickOutOfToteBoard"
    [favoriteTracks]="favoriteTrackList"
    (select)="selectTrack($event)"
    (isFavoriteTabActive)="setFavoriteTabActive($event)">
</cdux-tote-board>
