import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentPortal } from '@angular/cdk/portal';
import { iif, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, first, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    enumTrackType,
    EventClickType,
    EventsService,
    GetRunnerDetailsDataService,
    IFavoriteRunnerNew,
    IRaceIdentifier,
    ITrackBasic,
    JwtSessionService,
    PastPerformance,
    ProgramEntry,
    RunnerDetails,
    StringSlugifyPipe,
    ToteDataService,
    TrackService,
    TranslateService,
    UserEventEnum,
    WindowCollectionKey,
    WindowManagerService,
} from '@cdux/ng-common';
import { CduxRequestError } from '@cdux/ng-core';
import { CduxMediaToggleService, enumMediaTarget } from '@cdux/ng-platform/web';

import { LOGIN_REDIRECT_PATH } from 'app/app.routing.constants';
import { CduxRouteUtil, EventKeys } from 'app/shared';
import { BreadcrumbsService } from 'app/shared/breadcrumbs/services/breadcrumbs.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { HeaderService } from 'app/shared/header/services/header.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { VideoContainerComponent } from 'app/wagering/video/components/video-container/video-container.component';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';

@Component({
    selector: 'cdux-runner-details-past-performances',
    templateUrl: './runner-details-past-performances.component.html',
    styleUrls: ['./runner-details-past-performances.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RunnerDetailsPastPerformancesComponent implements OnInit, OnDestroy, IDetails {
    public videoPortal: ComponentPortal<VideoContainerComponent> = new ComponentPortal(VideoContainerComponent);

    readonly DISPLAY_TOGGLE_LABEL_MORE = 'SEE ALL';
    readonly DISPLAY_TOGGLE_LABEL_LESS = 'SEE LESS';
    readonly MIN_ROWS_DISPLAY = 5;

    @Input()
    public set detailOptions(options: IDetailsOptions) {
       this.init(options);
    }

    @Input()
    public set showAllPP(showAll: boolean) {
        if (showAll) {
            this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_LESS;
            this._showAllPP = true;
            this.displayedPastPerformances = this.runnerInfo.pastPerformances;
        } else {
            this._showAllPP = false;
            this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;
            this.displayedPastPerformances = this.runnerInfo.pastPerformances.length > this.MIN_ROWS_DISPLAY ? this.runnerInfo.pastPerformances.slice(0, this.MIN_ROWS_DISPLAY) : this.runnerInfo.pastPerformances;
        }
    }

    public get showAllPP() {
        return this._showAllPP;
    }

    public eventClickType = EventClickType;
    public trackTypeEnum = enumTrackType;

    public entry: ProgramEntry = null;
    public runnerInfo: RunnerDetails = null;
    public displayedPastPerformances: PastPerformance[];
    public favoriteData: IFavoriteRunnerNew;

    public trackType: enumTrackType;
    public brisCode: string;
    public raceNumber: number;
    public programNumber: string;
    public horseId: number;
    public view: WageringViewEnum;
    private activeLeg = 0;

    public errorMessage: string;
    public error: boolean = false;
    public isPhone: boolean = false;
    public ppToggleLabel: string;
    public replayPP: PastPerformance = null;

    // expose enum to template
    public ETrackType = enumTrackType;

    private _showAllPP: boolean = true;

    private _mediaSub: Subscription;
    private _forceMedia: enumMediaTarget;

    private _destroy: Subject<any> = new Subject<any>();

    @ViewChild('videoContainer', { read: ElementRef })
    private videoElement: ElementRef;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _breadcrumbsService: BreadcrumbsService,
        private _changeDetector: ChangeDetectorRef,
        private _eventsService: EventsService,
        private _eventTrackingService: EventTrackingService,
        private _headerService: HeaderService,
        private _mediaService: CduxMediaToggleService,
        private _router: Router,
        private _runnerDetailDataService: GetRunnerDetailsDataService,
        private _sessionService: JwtSessionService,
        private _stringSlugify: StringSlugifyPipe,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService,
        private _translateService: TranslateService
    ) {
    }

    public getPopUpWindowPath(pp: PastPerformance, key = this.getPopUpWindowName()): string {
        return pp && pp.track && 'video/replay/' + [
            pp.date,
            pp.track.BrisCode,
            pp.track.TrackType,
            pp.raceNumber
        ].join('/') + '?key=' + key;
    }

    public getPopUpWindowName(): string {
        return WindowManagerService.generateWindowName(WindowCollectionKey.VIDEO);
    }

    public ngOnInit() {
        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data?.forceMedia) {
                    this._forceMedia = data.forceMedia;
                }
                if (data?.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);
                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum,
                        view: params.view,
                        horseId: params.horseId
                    };

                    this.init(options);
                }
            }
        );
    }

    public ngOnDestroy() {
        this._headerService.setDefaultHeader();

        if (this._mediaSub) {
            this._mediaSub.unsubscribe();
        }

        this._destroy.next();
        this._destroy.complete();
    }

    public getRunnerDetail() {
        const raceNum = this.activeLeg ? this.raceNumber + this.activeLeg : this.raceNumber;

        this._toteDataService.currentRaceDate(this._toteDataService.NO_POLLING).pipe(
            switchMap(
                (raceDate: string) => iif(
                    () => this.trackType === enumTrackType.HARN,
                    this._runnerDetailDataService.getRunnerStats(this.brisCode, this.trackType, raceDate, raceNum, this.programNumber),
                    this._runnerDetailDataService.getRunnerDetails(this.horseId, this.brisCode, raceNum, raceDate)
                )
            ),
            first()
        ).subscribe((data: RunnerDetails) => {
            this.runnerInfo = data;
            if (this.showAllPP) {
                this.displayedPastPerformances = this.runnerInfo.pastPerformances;
            } else {
                this.displayedPastPerformances = this.runnerInfo.pastPerformances.length > this.MIN_ROWS_DISPLAY ? this.runnerInfo.pastPerformances.slice(0, this.MIN_ROWS_DISPLAY) : this.runnerInfo.pastPerformances;
            }
            this._headerService.updateHeader({ staticContent: this.runnerInfo.runnerInfo.horseName });
            this.initializeBreadCrumbs(this.runnerInfo.runnerInfo.horseName);

            this.favoriteData = this.runnerInfo.runnerInfo.generateNewFavorite();

            this._changeDetector.detectChanges();
        }, (error: CduxRequestError) => {
            this.error = true;
            this.errorMessage = this._translateService.translate('ws-response-error', 'runner-details-errors');
            this._changeDetector.detectChanges();
        });
    }

    public startReplay(pp: PastPerformance) {
        if (this.replayPP) {
            this.stopReplay(), setTimeout(() =>
                // must stop first to unload player
                this.startReplay(pp), 0 // do async
            );
            return;
        } else if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate([ LOGIN_REDIRECT_PATH ]);
            return;
        }

        this.replayPP = pp;
        this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN, <IRaceIdentifier> {
            brisCode: this.replayPP.track.BrisCode, trackType: this.replayPP.track.TrackType,
            raceDate: this.replayPP.date, raceNumber: this.replayPP.raceNumber
        });
        this.logReplayViewedEvent(this.replayPP);

        setTimeout(() => { // wait for the video container to be loaded
            if (this.videoElement && this.videoElement.nativeElement) {
                this.videoElement.nativeElement.scrollIntoView();
            }
            this._changeDetector.detectChanges();
        }, 0);
    }

    public onAttachedVideoContainer(ref: ComponentRef<VideoContainerComponent>) {
        ref.instance.disableLiveReplayToggle = true;
    }

    public stopReplay() {
        this.replayPP = null;
        this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
    }

    private initialSetup() {
        this.ppToggleLabel = this.DISPLAY_TOGGLE_LABEL_MORE;

        this._todaysRacesService.getTodaysRaceEntries(
            this.brisCode, this.trackType, this.raceNumber, this._toteDataService.USE_POLLING
        ).pipe(first(), map(entries => entries.find(entry =>
            entry.EntryId.toString() === this.horseId.toString()
        ))).subscribe(entry => {
            this.entry = entry;
            this._changeDetector.detectChanges();
        });
    }

    private initializeBreadCrumbs(runnerName: string) {
        this._breadcrumbsService.addCurrentRoute(runnerName); // set the current/initial breadcrumb
        // generate an appropriate "back" breadcrumb to program if there was not one set previously
        if (this._breadcrumbsService.breadcrumbs.length === 1 && this.brisCode && this.trackType) {
            // we only need data for the current track but TodaysTracks is already being polled
            this._todaysRacesService.getTodaysTracks(true, true).pipe(first()).subscribe(tt => {
                const t = <ITrackBasic> { BrisCode: this.brisCode, TrackType: this.trackType };
                const todaysTrack = tt.find(t2 => TrackService.isSameTrack(t, t2.ITrackBasic));
                if (todaysTrack) {
                    this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb(
                        todaysTrack.name + ' - Race ' + this.raceNumber, [
                            '/program', this._stringSlugify.transform(todaysTrack.name),
                            this.brisCode, this.trackType, this.raceNumber
                        ].join('/')
                    ), true); // truncate and replace any prior initial placeholder breadcrumbs
                    this._breadcrumbsService.addCurrentRoute(runnerName); // current breadcrumb
                    this._changeDetector.detectChanges();
                }
            });
        }
    }

    public getProgramNumberDisplay(pp: PastPerformance) {
        if (this.trackType === this.trackTypeEnum.TBRED) {
            return pp.programNumber + (pp.programNumber !== pp.postPosition.toString(10) ? ' (' + pp.postPosition + ')' : '');
        } else {
            return pp.postPosition;
        }
    }

    public init(options: IDetailsOptions) {
        this.view = options.view;
        this.horseId = options.horseId;
        this.trackType = options.trackType;
        this.brisCode = options.brisCode;
        this.raceNumber = options.raceNumber;
        this.programNumber = options.programNumber;
        this.activeLeg = options.activeLeg;

        // Horse ID check to short circuit showing an error message.
        // Currently for TBred we need this value.
        // Once we switch to the new bds stats service (which harness currently uses) we shouldn't need to check for
        // horseId.
        if (this.horseId) {
            this.initialSetup();
            this.getRunnerDetail();
        } else if (this.trackType === enumTrackType.HARN) {
            this.getRunnerDetail();
        } else {
            this.error = true;
            this.errorMessage = this._translateService.translate('bad-request', 'runner-details-errors');
        }

        this._mediaSub = this._mediaService.registerQuery(enumMediaTarget.PHONE).pipe(
            map((p) => p || this._forceMedia === enumMediaTarget.PHONE),
            distinctUntilChanged()
        ).subscribe((isPhone) => {
            this.isPhone = isPhone;
            this.stopReplay(); // avoid media issues
            this._changeDetector.detectChanges();
        });
    }

    public getRacesText() {
        let text: string;

        if (this.showAllPP && this.displayedPastPerformances?.length) {
            text = 'ALL RACES';
        } else if (this.displayedPastPerformances?.length) {
            text = 'LAST ' + this.displayedPastPerformances.length + ' RACE' + (this.displayedPastPerformances.length > 1 ? 'S' : '');
        } else {
            text = 'NO RACES';
        }

        return text;
    }

    public logReplayViewedEvent(pp: PastPerformance) {
        this._eventTrackingService.logUserEvent(UserEventEnum.REPLAY_VIEWED, {
            raceNumber: pp.raceNumber,
            horseName: this.runnerInfo.runnerInfo.horseName,
            trackName: pp.track.BrisCode,
            replayDate: pp.date
        });
    }
}
