import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { EventClickType, EventClickAttributeType, ITrackBasic, TrackService, enumRaceStatus, BasicBetType, MultiRaceExoticBetType, ISelectedBetAmount, enumBetModifier, WagerState, ITrack, enumTrackStatus } from '@cdux/ng-common';
import { ViewSectionEnum } from '../views/enums/view-section.enum';
import { WageringViewEnum } from '../views/enums/wagering-view.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { Subscription } from 'rxjs';
import { ISelectionUpdate } from '../../shared/program/interfaces/selection-update.interface';
import { ErrorBarError } from '../../shared/bet-slip/interfaces/error-bar-error.interface';
import { AbstractWageringViewEventComponent } from '../../shared/wager-views/components';
import { WagerEvent, WageringView } from 'app/shared/wager-views';
import { TodaysRaceStatus } from './classes/todaysRaceStatus';
import { TvTrackInterface } from 'app/wagering/video/interfaces/tv-track.interface';
import { DisplayModeEnum } from 'app/shared';
import { enumVideoBarType } from 'app/wagering/video/enums/video-bar-type.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-tv',
    templateUrl: './tv.component.html',
    styleUrls: ['./tv.component.scss']
})
export class TvComponent extends AbstractWageringViewEventComponent implements WageringView, OnInit, OnDestroy {

    public displayMode = DisplayModeEnum.COMPACT;

    @Input()
    public wager: WagerState;

    @Input()
    public view: WageringViewEnum;

    @Output()
    public viewChange = new EventEmitter<WageringViewEnum>();


    @Input()
    public section: ViewSectionEnum;

    @Output()
    public sectionChange = new EventEmitter<ViewSectionEnum>();

    @Output() public selectTrackFromList: EventEmitter<ITrackBasic[]> = new EventEmitter<ITrackBasic[]>();

    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateBetNav: EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType[])>();
    @Output() public updateBetType: EventEmitter<BasicBetType | MultiRaceExoticBetType> = new EventEmitter<BasicBetType | MultiRaceExoticBetType>();
    @Output() public updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() public updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() public updateEntrySelection: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();
    @Output() public resetEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public stashEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public restoreEntrySelections: EventEmitter<void> = new EventEmitter<void>();
    @Output() public dismissError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public outboundEvents: EventEmitter<WagerEvent> = new EventEmitter<WagerEvent>();

    @Input() public fundingEnabled: boolean = false;
    @Input() public racesRestrictedTo: enumRaceStatus[];
    @Input() public error: ErrorBarError;


    private _race: number;
    @Input()
    public set race(race: number) {
        this._race = race;
        this.resultsAvailable = false;
    }
    public get race(): number {
        return this._race;
    }
    private _track: ITrackBasic;
    private todaysRaceGetter;
    private _subs: Subscription[] = [];

    @Input()
    public set track(track: ITrackBasic) {
        this.resultsAvailable = false;
        if (!!track) {
            this.todaysRaceGetter.updateTrack(track);
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public raceStatus: enumRaceStatus;

    public liveRaceNumber: number = 1;
    public resultsAvailable = false;
    public selectedITrack: TvTrackInterface;

    public videoBarType = enumVideoBarType;

    public isTabletPortrait = false;

    constructor(private _eventTrackingService: EventTrackingService,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _mediaQueryService: CduxMediaToggleService,
        private _changeDetector: ChangeDetectorRef) {
        super()

        this.todaysRaceGetter = new TodaysRaceStatus (
            this._todaysRacesService.getTodaysTracks(true, true)
        );
    }

    public ngOnInit() {
        this._subs.push(
            this.todaysRaceGetter.listen().subscribe(data => {
                this._track = data;
                this.liveRaceNumber = data.RaceNum;
                this.selectedITrack = {track: Object.assign({}, data, this.wager.basicTrack) as ITrack, liveRaceNumber: this.liveRaceNumber};
            }),
            this._mediaQueryService.registerQuery('tablet-portrait').subscribe((match) => {
                this.isTabletPortrait = match;
                this._changeDetector.markForCheck();
            })
        );

        this._eventTrackingService.logClickEvent(
            EventClickType.INITIAL_TV_LOAD,
            !this.track ? [] : [{
                attrId: EventClickAttributeType.INITIAL_TV_LOAD_BRIS_CODE,
                data: this.track.BrisCode
            }, {
                attrId: EventClickAttributeType.INITIAL_TV_LOAD_TRACK_TYPE,
                data: this.track.TrackType
            }, {
                attrId: EventClickAttributeType.INITIAL_TV_LOAD_RACE,
                data: this.track.RaceNum
            }]
        );

        if (this.section === ViewSectionEnum.RUNNER_BALLOT) {
            this.sectionChange.emit(ViewSectionEnum.PROGRAM_BASIC);
        }

    }

    public ngOnDestroy() {
        this._subs.forEach(sub => sub.unsubscribe());
        delete this._subs;
        this._subs = []
        this.todaysRaceGetter.kill();
    }

    public selectRaceNav(track: ITrackBasic) {
         if (!TrackService.isSameTrack(track, this.track)) {
            this.updateRaceNav.emit(this.track = track);
        }
    }

    public changeSection(section: ViewSectionEnum) {
        this.sectionChange.emit(section);
    }

    public _isTrackOpen(): boolean {
        return this.selectedITrack && this.selectedITrack.hasOwnProperty('Status') && this.selectedITrack['Status'] === enumTrackStatus.OPEN;
    }
}
