/**
 * ViewSectionEnum
 *
 * This is a list of every section in the application not sorted
 * This list is directly tied to the routing structure of the application
 * These are also tied into the sourceId of wagers which are hardcoded into ADW to identify from which interface a wager was submitted
 *
 * If new sections are added, a corresponding sourceId must be inserted. sourceId are not dynamically added when a new one is submitted
 */

export enum ViewSectionEnum {
    RUNNER_BALLOT = 'program',
    PROGRAM_BASIC = 'basic',
    PROGRAM_ADVANCED = 'advanced',
    PROGRAM_SPEED = 'speed',
    PROGRAM_CLASS = 'class',
    PROGRAM_PACE = 'pace',
    PROGRAM_TIPS = 'tips',
    PROGRAM_SUMMARY = 'summary',
    PAST_PERFORMANCES = 'pps',
    STATS = 'stats',
    POOLS = 'pools',
    PROBABLES = 'probables',
    WILL_PAYS = 'will_pays',
    VIDEO = 'video',
    RESULTS = 'results',
    PAYOUTS = 'payouts',
    FINISH_ORDER = 'finish_order',
    CHANGES = 'changes',

    STATS_POST_BIAS = 'post_bias',
    STATS_RUN_STYLE = 'run_style',
    STATS_SUCCESS_RATES = 'success_rates',
    STATS_TRACK_BIAS = 'track_bias',

    WAGER_PAD = 'wager_pad',
}

/**
 * Below are the groupings of each interface, these are used to designate a specific section exists under a specifc heading
 *
 * Not every section is in a group
 */

export const ViewSectionProgramGroup = [
    ViewSectionEnum.RUNNER_BALLOT,
    ViewSectionEnum.PROGRAM_BASIC,
    ViewSectionEnum.PROGRAM_ADVANCED,
    ViewSectionEnum.PROGRAM_SPEED,
    ViewSectionEnum.PROGRAM_PACE,
    ViewSectionEnum.PROGRAM_CLASS,
    ViewSectionEnum.PROGRAM_TIPS,
    ViewSectionEnum.PROGRAM_SUMMARY,
    ViewSectionEnum.CHANGES
]

export const ViewSectionPoolsGroup = [
    ViewSectionEnum.POOLS,
    ViewSectionEnum.PROBABLES,
    ViewSectionEnum.WILL_PAYS
]

export const ViewSectionResultsGroup = [
    ViewSectionEnum.RESULTS,
    ViewSectionEnum.FINISH_ORDER,
    ViewSectionEnum.PAYOUTS
]

export const ViewSectionStatsGroup = [
    ViewSectionEnum.STATS,
    ViewSectionEnum.STATS_POST_BIAS,
    ViewSectionEnum.STATS_RUN_STYLE,
    ViewSectionEnum.STATS_SUCCESS_RATES,
    ViewSectionEnum.STATS_TRACK_BIAS
]
