import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
    ResultsDataService,
    ToteDataService,
    IAvailableResultsDataResponse,
    IAvailableResultsRaceDataResponse,
    IRaceIdentifier,
    IAdwRace,
    enumRaceStatus,
    ITrackBasic,
} from '@cdux/ng-common';

import { BreadcrumbsService } from 'app/shared/breadcrumbs';
import { formatDate } from '@angular/common';


@Injectable()
export class ResultsService {
    static PROGRAM_NUMBER_SCRATCHED = 'SCR';
    static USE_POLLING = true;

    constructor(
        private resultsDataService: ResultsDataService,
        private toteDataService: ToteDataService,
        private _breadcrumbsService: BreadcrumbsService
    ) { }

    /**
     * Retrieve pool results for the specified track for the current race date.
     * Optionally filter by race number, with polling enabled by default.
     *
     * @param track
     * @param raceNumber
     * @param usePolling
     * @returns {Observable<{ WPS: IPoolResultsWPS, Exotics: IPoolResults }>}
     */

    public getResultsByDay(raceDate: Date | string, usePolling?: boolean): Observable<IAvailableResultsDataResponse[]> {
        return this.resultsDataService.getAvailableResults(raceDate, usePolling);
    }

    public getResultsDetails(raceIdentifier: IRaceIdentifier, usePolling?: boolean): Observable<IAvailableResultsRaceDataResponse> {
        return this.resultsDataService.getResultsForRace(raceIdentifier, usePolling);
    }

    public resultsAvailable(raceIdentifier: IRaceIdentifier, usePolling?: boolean): Observable<boolean> {
        // get current race date if not provided
        if (!raceIdentifier.raceDate) {
            return this.toteDataService.currentRaceDate().pipe(
                mergeMap(raceDate => this.getResultsDetails(
                    { ...raceIdentifier, raceDate }, true
                )),
                map((resultsDetails) => !!resultsDetails)
            );
        } else {
            // continue to poll if results are incomplete
            return this.resultsDataService.getResultsForRace(raceIdentifier, usePolling).pipe(
                map(resultsDetails => !!resultsDetails ),
            );
        }
    }

    public generateRaceList(resultsByDay: IAvailableResultsDataResponse): IAdwRace[] {

        if (!resultsByDay) {
            return null;
        }
        const raceList: IAdwRace[] = [];

        // build list of Races for the race dropdown in the Race Nav
        resultsByDay.races.forEach(race => {
            const newRace: IAdwRace = {
                track: resultsByDay.track.brisCode,
                race: race.raceNumber,
                post: '',
                mtp: 0,
                postTimestamp: null,
                raceStatus: enumRaceStatus.OFFICIAL,
                currentRace: false
            };

            raceList.push(newRace);
        });

        return raceList;
    }

    public buildBreadcrumbs(track: ITrackBasic, raceDate: string) {
        this._breadcrumbsService.addBreadcrumb(BreadcrumbsService.generateBreadcrumb('Results', '/results/' + raceDate), true);

        // add current track and race date to result path
        if (track && raceDate) {
            this._breadcrumbsService.addCurrentRoute(track.DisplayName + ' - ' + formatDate(raceDate, 'longDate', 'en-US'));
        }
    }
}
