import { ISummaryRunnerStats, ITrackBasic, RunnerStatsDataService, ToteDataService } from "@cdux/ng-common";
import { CduxRxJSBuildingBlock } from "@cdux/ng-core";
import { BehaviorSubject, Observable, ReplaySubject, of } from "rxjs";
import { catchError, finalize, map, switchMap, take, takeUntil } from "rxjs/operators";

export class RunnerStatsRequestHandler extends CduxRxJSBuildingBlock<any, ISummaryRunnerStats[]> {

    protected _stream: Observable<ISummaryRunnerStats[]>;

    /**CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /** END CONTROLS **/

    constructor(
        private _toteDataService: ToteDataService,
        private _runnerStatsDataService: RunnerStatsDataService,
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    public _init() {
        this._stream = this._pause.pipe(
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                switchMap(track => this.getRunnerStats(track, true))
            )),
            map(stats => stats === null || stats.length === 0 ? null : (stats as ISummaryRunnerStats[])),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<ISummaryRunnerStats[]>;
    }
    
    private getRunnerStats(basicTrack: ITrackBasic, poll: boolean = false): Observable<ISummaryRunnerStats[]> {
        const trackType = basicTrack && basicTrack.TrackType || null;
        const brisCode = basicTrack && basicTrack.BrisCode || null;
        const raceNum = basicTrack && basicTrack.RaceNum || null;
        const raceDetailsObs = this._toteDataService.currentRaceDate(poll).pipe(
            switchMap(raceDate =>
                this._runnerStatsDataService.getRunnerStats(raceDate, brisCode, trackType, raceNum).pipe(
                    catchError(err => of(null))
                )
            ),
        );
        return raceDetailsObs.pipe(take(1));
    }
}