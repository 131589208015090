import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@cdux/ng-platform/web';
import { TermsService } from 'app/shared/terms/services/terms.service';

@Component({
    selector: 'cdux-terms-component',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit, OnDestroy {
    @ViewChild('TermsContainer', { read: ElementRef })
    public set termsContainer(el: ElementRef) {
        this._termsContainer = el;

        if (this.context) {
            this._bindImportedTermsLinks();
        }
    }
    public get termsContainer(): ElementRef {
        return this._termsContainer;
    }
    private _termsContainer: ElementRef;

    private _termsLinkListeners: [ HTMLAnchorElement, () => void ][] = [];

    private _context: any = {};

    public showModalHeader: boolean;


    constructor(
        private _activatedRoute: ActivatedRoute,
        private _domSanitizer: DomSanitizer,
        private _changeDetector: ChangeDetectorRef,
        private _modalService: ModalService,
        private _termsService: TermsService
    ) {}

    public set context(context: any) {
        this._context = context;
        this._context.html = this._domSanitizer.bypassSecurityTrustHtml(context.html);
        this.showModalHeader = 'isSidebar' in context ? !context['isSidebar'] : false;

        if (this._termsContainer) {
            this._termsContainer.nativeElement.scrollTop = 0;
            this._changeDetector.detectChanges();
            this._bindImportedTermsLinks();
        }
    }
    public get context(): any {
        return this._context;
    }

    public ngOnInit() {
        if (this._activatedRoute.snapshot.data.html) { // data pre-loaded from route resolver
            this.context = { ...this.context, html: this._activatedRoute.snapshot.data.html };
        } else if (this._activatedRoute.snapshot.params.terms) {
            this._termsService.getTermsHtml(this._activatedRoute.snapshot.params.terms).then(
                html => this.context = { ...this.context, html }
            );
        }
    }

    public ngOnDestroy(): void {
        this._bindImportedTermsLinks(true);
    }

    public closeModal() {
        this._modalService.closeAll();
    }

    // Bind click events to imported anchor (<a>) elements with 'data-cdux-terms' attributes
    // Example HTML: <a data-cdux-terms="terms">Click here for full terms and conditions</a>
    private _bindImportedTermsLinks(removeOnly = false) {
        if (removeOnly) {
            this._termsLinkListeners.forEach(([link, listener]) => {
                link.removeEventListener('click', listener);
            });
            this._termsLinkListeners = [];
            return;
        } else {
            // unbind previous events first
            this._bindImportedTermsLinks(true);
        }

        if (this.termsContainer) {
            Array.from(
                this.termsContainer.nativeElement.getElementsByTagName('a')
            ).forEach((link: HTMLAnchorElement) => {
                if (!link.dataset.cduxTerms) { return; }

                const listener = () => {
                    this._termsService.getTermsHtml(<any> link.dataset.cduxTerms).then((html) => {
                        this.context = { ...this.context, html };
                    });
                };

                link.addEventListener('click', listener);
                this._termsLinkListeners.push([link, listener]);
            });
        }
    }
}
