import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';

import {
    FavoritePersonService,
    IFavoritePerson,
    StringSlugifyPipe,
    TrackService,
    TranslateService,
    IPersonStartDetail,
    SexConstants,
    IMtpDisplay,
    enumRaceStatus,
    TodaysDisplayTrack,
    enumTrackTypeBds,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';

import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';
import { ToastService, MtpDisplayUtil, FormatPursePipe } from '@cdux/ng-fragments';
import { Router } from '@angular/router';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { RaceTypeConstants } from 'app/shared/common/constants/RaceTypeConstants';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { FavEventType } from '../favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { TitleCasePipe } from '@angular/common';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
import {IDetailsOptions} from "app/shared/program/interfaces/details.interface";
import {WageringViewEnum} from "app/wagering/views/enums/wagering-view.enum";

interface RunnerDetail {
    runnerName: string
    raceType: string
    raceNumber: number
    maxClaimPrice: number
    breedType: string
}

@Component({
    selector: 'cdux-favorites-tile-jockey',
    templateUrl: './favorites-tile-jockey.component.html',
    styleUrls: [ './favorites-tile-jockey.component.scss' ],
})
export class FavoritesTileJockeyComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('updateModalTemplate') protected updateModalTemplate: TemplateRef<unknown>;
    @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>;
    @ViewChild('expandedModalTemplate') protected expandedModalTemplate: TemplateRef<unknown>;
    @ViewChild('statsModalTemplate') protected statsModalTemplate: TemplateRef<unknown>;

    public tempComment: string;
    public countCurrent = 0;
    public readonly COUNT_MAX = 4096;
    public modalContent: Portal<any>;
    public updateTemplatePortal: TemplatePortal<any>;
    public removeTemplatePortal: TemplatePortal<any>;
    public expandedTemplatePortal: TemplatePortal<any>;
    public statsTemplatePortal: TemplatePortal<any>;

    public jockeyName: string;
    private _jockeyData: IFavoritePerson;
    private _overlayRef: OverlayRef;


    private static readonly MAX_RUNNERS_DISPLAY_CNT = 3;

    public runnerDetails: RunnerDetail[] = [];

    public additionalRacesCnt = 0;
    public brisCode = '';
    public bdsTrackType: enumTrackTypeBds;
    public trackName = '';

    public emptyRowCnt = 0;

    public isPhone: boolean = false;
    private _destroy: Subject<any> = new Subject<any>();

    public todaysRunners: IPersonStartDetail[];

    private tracksSub: Subscription;
    private _todaysTracks: TodaysDisplayTrack[];
    public options: IDetailsOptions;

    @Input()
    set jockeyData(jockeyData: IFavoritePerson) {
        this.todaysRunners = [];
        this._jockeyData = jockeyData;
        if (this._jockeyData.todaysRunners.length > 0) {
            this.todaysRunners = this._jockeyData.todaysRunners;
        }

        this.jockeyName = jockeyData.firstName + ' ' + (jockeyData?.middleName ? (jockeyData.middleName + ' ' + jockeyData.lastName) : jockeyData.lastName);

        this._setRunnerDetails();
    }
    get jockeyData() {
        return this._jockeyData;
    }

    public constructor(
        private _viewContainerRef: ViewContainerRef,
        private _favoritePersonService: FavoritePersonService,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _router: Router,
        private _stringSlugify: StringSlugifyPipe,
        private _mediaService: CduxMediaToggleService,
        private _changeDetector: ChangeDetectorRef,
        private _mtpDisplayUtil: MtpDisplayUtil,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _titleCasePipe: TitleCasePipe,
        private _eventTrackingService: EventTrackingService,
    ) {}

    ngOnInit() {
        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });

        this.tracksSub = this._todaysRacesService.getTodaysTracks(true, true).subscribe(tracks => {
            this._todaysTracks = tracks;
        });
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();

        if (this.tracksSub) {
            this.tracksSub.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        );

        this.updateTemplatePortal = new TemplatePortal(
            this.updateModalTemplate,
            this._viewContainerRef
        );

        this.expandedTemplatePortal = new TemplatePortal(
            this.expandedModalTemplate,
            this._viewContainerRef
        );

        this.statsTemplatePortal = new TemplatePortal(
            this.statsModalTemplate,
            this._viewContainerRef
        );
    }

    private _setRunnerDetails() {
        this.runnerDetails = [];

        this.brisCode = this._jockeyData.todaysRunners[0].brisCode;
        this.bdsTrackType = this._jockeyData.todaysRunners[0].bdsTrackType;
        this.trackName = this._jockeyData.todaysRunners[0].trackName;

        // Get all runners associated to 1st track (brisCode)
        const runners = this._jockeyData.todaysRunners.filter((d) => d.brisCode === this.brisCode);

        for (let i = 0; i < FavoritesTileJockeyComponent.MAX_RUNNERS_DISPLAY_CNT; i++) {
            if (runners[i]) {
                this.runnerDetails.push({
                    runnerName: runners[i].runnerName,
                    raceType: runners[i].raceType,
                    raceNumber: runners[i].raceNumber,
                    maxClaimPrice: runners[i].maxClaimPrice,
                    breedType: runners[i].breedType
                });
            } else {
                break;
            }
        }

        this.additionalRacesCnt = runners.length - this.runnerDetails.length;
        this.emptyRowCnt = FavoritesTileJockeyComponent.MAX_RUNNERS_DISPLAY_CNT - this.runnerDetails.length;
    }

    public bet(runner: IPersonStartDetail, isExpandedView = false) {
        this._logClickEvent(EventClickType.STABLE_ALERTS_BET_LINK);

        this._router.navigate([
            'program',
            isExpandedView ? this._stringSlugify.transform(runner.trackName) : this._stringSlugify.transform(this.trackName),
            isExpandedView ? runner.brisCode.toLowerCase() : this.brisCode,
            isExpandedView ? TrackService.getTrackType(runner.bdsTrackType) : TrackService.getTrackType(this.bdsTrackType),
            runner.raceNumber,
        ]);

        if (isExpandedView) {
            this.dismissModal();
        }
    }

    public confirmRemove() {
        this._favoritePersonService.remove(this.jockeyData.uuid).then((removed) => {
            if (removed) {
                this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);
                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.jockeyName + ' removed from <span class="bold-text">stable</span>.',
                        duration: 3000
                    }
                });
            }
            if (!removed) {
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public get note() {
        return this.tempComment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.tempComment = value;
        }
    }

    public edit() {
        this.tempComment = this.jockeyData.comment || '';
        this.countCurrent = this.tempComment.length;
        this.modalContent = this.updateTemplatePortal;
    }

    public confirmUpdate() {
        // The favorite modal handles validation of the input regarding the
        // limits so we don't need to check them again here.
        // In case the save fails, we don't want to alter the original favorite YET so...

        // 1. Clone the favorite
        const dupeFav = Object.assign({}, this.jockeyData);
        dupeFav.comment = this.tempComment;
        // 2. Call to update the favorite
        this._favoritePersonService.update(dupeFav).then((updated) => {
            if (updated) {
                // 3. If save was successful. Replace original favorite with the new one
                this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);
                this.jockeyData = dupeFav;
            } else {
                // 4. Show some sort of error message? Seek clarification.
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }

    public remove() {
        this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE_LINK);
        this.modalContent = this.removeTemplatePortal;
    }

    public expand() {
        this.modalContent = this.expandedTemplatePortal;
    }

    public getRaceTypeText(maxClaimPrice: number, breedType: string, raceType: string, showFullRaceTypeName: boolean): string {
        if (!breedType || !raceType) {
            return '';
        }

        // If showFullRaceTypeName = false, name = race type code, else full name
        const name = !showFullRaceTypeName
            ? raceType
            : this._titleCasePipe.transform(RaceTypeConstants.raceTypes[breedType] && RaceTypeConstants.raceTypes[breedType][raceType] || '');

        // If claiming race
        if (maxClaimPrice > 0) {
            const raceTypeComponents = [];

            // Add the max claim price
            const formatPursePipe = new FormatPursePipe();
            raceTypeComponents.push(formatPursePipe.transform(maxClaimPrice));

            // Add the fullRaceTypeName or the race type code
            raceTypeComponents.push(name);

            return raceTypeComponents.join(' ');
        } else {
            // If not a claiming race return the race type code or full name
            return name;
        }
    }

    private _decodeSex(sex: string): string {
        for (const key of Object.keys(SexConstants.sexMap)) {
            if (key === sex) {
                return SexConstants.sexMap[key][1];
            }
        }

        return '';
    }

    private _formatAge(age: number): string {
        return age + (age === 1 ? ' YR' : ' YRS');
    }

    public formatRunnerDetails(runner: IPersonStartDetail): string {
        // Color, Sex, Age yrs
        return [
            runner.colorDescription || runner.color,
            this._decodeSex(runner.sex),
            this._formatAge(runner.age)
        ].join(', ');
    }

    public setMtpDisplaySrc(runner: IPersonStartDetail): IMtpDisplay {
        // Set default values
        let mtp = 0;
        let raceStatus = enumRaceStatus.UNKNOWN;
        let postTime = '00:00:00';
        let displayMinutes = false;

        // Need to match brisCode / bds -> bris track type / race number
        const brisCode = runner.brisCode.toLowerCase();
        const trackType = TrackService.getTrackType(runner.bdsTrackType);
        const raceNumber = runner.raceNumber;

        // Match the bris code and track type
        const myTrack = this._todaysTracks.find(track => track.brisCode.toLowerCase() === brisCode && track.type === trackType);
        if (myTrack) {
            // Match the race number
            const myRace = myTrack.races.find(race => race.raceNumber === raceNumber);

            // If race found, set mtp, race status, post time and currentRace (boolean)
            if (myRace) {
                mtp = myRace.mtp;
                raceStatus = myRace.status;
                postTime = myRace.postTime;
                displayMinutes = myRace.currentRace;
            }
        }

        return this._mtpDisplayUtil.deriveMtpDisplay({
            mtp: mtp,
            raceStatus: raceStatus,
            postTimestamp: postTime
        }, displayMinutes);
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        switch(clickType) {
            case EventClickType.STABLE_ALERTS_BET_LINK:
                // Log bet button click
                this._eventTrackingService.logClickEvent(
                    EventClickType.STABLE_ALERTS_BET_LINK,
                    [
                        {
                            attrId: EventClickAttributeType.STABLE_ALERTS_BET_LINK_FAVORITE_TYPE,
                            data: FavoriteTypeEnum.JOCKEY,
                            timestamp: ts
                        }
                    ]
                );
                break;

            case EventClickType.STABLE_ALERTS_REMOVE:
            case EventClickType.STABLE_ALERTS_REMOVE_LINK:
                let favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_FAVORITE_TYPE;
                let favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_LOCATION;

                if (clickType === EventClickType.STABLE_ALERTS_REMOVE) {
                    favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_FAVORITE_TYPE;
                    favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LOCATION;
                }

                this._eventTrackingService.logClickEvent(
                    clickType,
                    [
                        {
                            attrId: favType,
                            data: FavoriteTypeEnum.JOCKEY,
                            timestamp: ts
                        },
                        {
                            attrId: favLoc,
                            data: FavoriteLocationEnum.STABLE_ALERTS,
                            timestamp: ts
                        },
                    ]
                );
        }
    }

    public openStatsModal() {
        this.options = {
            brisCode: undefined,
            trackType: undefined,
            raceNumber: undefined,
            programNumber: undefined,
            view: WageringViewEnum.CLASSIC,
            jockeyId: this.jockeyData.personId,
            jockeyName: this.jockeyName
        };
        this.modalContent = this.statsTemplatePortal;
    }
}
