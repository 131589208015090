import { ChangeDetectorRef, Directive, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';

import { TranslateService, JwtSessionService, FeatureToggleDataService } from '@cdux/ng-common';

import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { enumDepositOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingAbstractMethodDepositComponent } from 'app/shared/funding/components/methods/abstract-method-deposit.component';
import { FundingPaypalService } from 'app/shared/funding/shared/services/paypal.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { Router } from '@angular/router';
import { HeaderService } from 'app/shared/header/services/header.service';

@Directive()
export class PaypalMethodDepositAbstractComponent extends FundingAbstractMethodDepositComponent implements OnInit {
    public depositing = false;

    constructor(
        localRouter: Router,
        protected _cdr: ChangeDetectorRef,
        protected _eventService: EventTrackingService,
        protected _paypalService: FundingPaypalService,
        protected _betSlipService: BetSlipBusinessService,
        localEnvironment: ENVIRONMENT,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localHeaderService,
            localEnvironment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            _eventService,
            localTranslateService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumDepositOptions.PAYPAL;
    }

    public ngOnInit() {
        this._initForm([
            {
                name: 'amount',
                default: this.getInitialDepositAmount(),
                validators: [
                    Validators.min(this.getMinDepositAmount(this.hasVerifiedAccount)),
                    Validators.max(this.getMaxDepositAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            },
        ]);
    }

    // START FundingAbstractMethodDepositComponent IMPLEMENTATION

    /**
     * PayPal accounts are never 'additionally verified' outside of the
     * associated email address. So the {@link IFundingOption.verified}
     * property will never be set on this funding method.
     */
    public get hasVerifiedAccount() {
        return !!this.fundingMethodDetails.accountInfo &&
            this.fundingMethodDetails.accountInfo.trim() !== '';
    }

    // END FundingAbstractMethodDepositComponent IMPLEMENTATION

    public onDeposit() {
        this.logDepositStartGtmEvent();
        // Because we actually leave the application for a PayPal deposit, we need to take
        // our post deposit URL and current bet from memory and stick it in session storage.
        if (this._fundingService.postDepositRedirectURL) {
            sessionStorage.setItem('postDepositRedirectURL', this._fundingService.postDepositRedirectURL);
        }
        // Store current bet in bet slip and save the ID to session storage for reference
        if (this._betSlipService.currentBet) {
            sessionStorage.setItem('postDepositReturningBetId', this._betSlipService.currentBet.id);
            this._betSlipService.storeBet(this._betSlipService.currentBet);
        }
        if (!this.depositing && ((this.form.dirty && this.form.valid) || this.form.pristine)) {
            this.depositing = true;
            try {
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                }

                // We initiate the deposit here, but will return to the
                // FundingPaypalCompleteDepositComponent when paypal redirects.

                this._cdr.detectChanges();

                this._paypalService.initiateDeposit(
                    this.form.get('amount').value,
                    location.href,
                    this.fundingMethodDetails.accountInfo,
                    this.inboundBet
                ).subscribe(
                    (redirectUrls) => {
                        if (redirectUrls.errorCode) {
                            this._handleResponse({ status: 'failed', message: redirectUrls.errorMessage }, '');
                        } else {
                            window.location.href = redirectUrls.webRedirectionUrl;
                        }
                    },
                    (err) => this._handleResponse(err, '')
                );
            } finally {
                this.depositing = false;
            }
        // } else {
        //     console.log('form invalid', this.form.controls);
        //     Object.keys(this.form.controls).forEach(key => {
        //         const control = this.form.get(key);
        //         if (control.invalid && 'required' in control.errors) {
        //             console.log(key, control.value, control.errors);
        //         }
        //     });
        }
    }

    public getMaxDepositAmount() {
        if (this.hasVerifiedAccount) {
            return this.fundingMethodDetails.maxDeposit;
        } else {
            return this.fundingMethodDetails.initialMaxDeposit;
        }
    }
}
