import { Injectable } from '@angular/core';
import { ViewSectionEnum, ViewSectionProgramGroup, ViewSectionPoolsGroup, ViewSectionResultsGroup, ViewSectionStatsGroup } from '../enums/view-section.enum';
import { ISortState } from 'app/shared/program/classes/sort-state-handler.class';
import { PoolSubView } from 'app/wagering/shared/wagering-views/enums/pool-sub-view.enum';
import { enumPoolType, JwtSessionService } from '@cdux/ng-common';
import { ViewStateStore, ViewStateGroupEnum } from '../interfaces/view-state-store';
import { enumProgramSort } from 'app/shared/program/enums/program-sort-columns.enum';
import { enumPoolsSort } from 'app/wagering/shared/pools/enums/pools-sort-columns.enum';
import { WageringViewEnum } from '../enums/wagering-view.enum';

@Injectable({
    providedIn: 'root'
})
export class ViewStateService {

    public static VIEW_STATE_STORAGE_KEY = 'viewState';

    public static DEFAULT_VIEW_STATE: ViewStateStore = {
        primary: ViewStateGroupEnum.PROGRAM,
        programGroup: {
            section: ViewSectionEnum.PROGRAM_ADVANCED,
            sortState: {
                sortProperty: enumProgramSort.SORTABLE_PROGRAM_NUMBER,
                ascending: true
            }
        },
        poolsGroup: {
            section: ViewSectionEnum.POOLS,
            [ViewSectionEnum.POOLS]: {
                sortState: {
                    sortProperty: enumPoolsSort.PROGRAM_NUMBER,
                    ascending: true
                },
                subView: PoolSubView.TOTALS
            },
            [ViewSectionEnum.PROBABLES]: {
                poolType: enumPoolType.EXACTA
            },
            [ViewSectionEnum.WILL_PAYS]: {
                sortState: {
                    sortProperty: enumProgramSort.SORTABLE_PROGRAM_NUMBER,
                    ascending: true
                }
            }
        },
        resultsGroup: {
            section: ViewSectionEnum.PAYOUTS
        },
        statsGroup: {
            section: ViewSectionEnum.STATS_POST_BIAS
        }
    };

    /**
     * Gets primary Group for a given section
     * @param section
     */
    public static getPrimaryGroupForSection(section: ViewSectionEnum): ViewStateGroupEnum {
        if (ViewSectionProgramGroup.includes(section)) {
            return  ViewStateGroupEnum.PROGRAM;
        } else if (ViewSectionPoolsGroup.includes(section)) {
            return  ViewStateGroupEnum.POOLS;
        } else if (ViewSectionResultsGroup.includes(section)) {
            return ViewStateGroupEnum.RESULTS;
        } else if (ViewSectionStatsGroup.includes(section)) {
            return ViewStateGroupEnum.STATS;
        } else {
            return null;
        }
    }

    constructor(private _sessionService: JwtSessionService) {

        // Given a url, check if it contains any of the wagering views
        const containsView = (url: string): boolean => {
            return Object.keys(WageringViewEnum)
                .map(v => '/' + WageringViewEnum[v] + '/') // creates array of views with surrounding '/'
                .some((view) => url.includes(view)); // if the url includes any of the views
        }

        this._sessionService.onAuthenticationChange.subscribe((auth) => {
            // if the user is logging in and they're coming from one of the wageringviews
            if (auth && containsView(this._sessionService.redirectLoggedInUserUrl)) {
                // set their view state from the anon view state so they're not transported somewhere else
                this._setViewState(this.getViewState(ViewStateService.VIEW_STATE_STORAGE_KEY));
                // if the user isn't coming from one of the wagering views,
                // we just leave their view state alone
            } else if (auth === false) {
                // when the user logs out, reset the anonymous view so the next anon user gets a fresh state
                this.resetViewState();
            }
        });
    }

    /**
     * Generates the key used to store the view state
     * Will appeand the username if the user name is logged in
     */
    private _getViewStateKey(username: string = ''): string {
        if (this._sessionService.isLoggedIn()) {
            username = '-' + this._sessionService.getUserInfo().username;
        }
        return ViewStateService.VIEW_STATE_STORAGE_KEY + username;
    }

    /**
     * Returns the cached view state
     */
    public getViewState(key: string = ''): ViewStateStore {
        if (window.localStorage.getItem(key ? key : this._getViewStateKey())) {
            try {
                return JSON.parse(window.localStorage.getItem(key ? key : this._getViewStateKey())) as ViewStateStore;
            } catch {
                console.warn('Unable to read view state, reseting view state to default');
                this.resetViewState(key);
                return ViewStateService.DEFAULT_VIEW_STATE;
            }
        } else {
            this.resetViewState(key);
            return ViewStateService.DEFAULT_VIEW_STATE;
        }
    }

    /**
     * Sets the view state in local storage
     *
     * @param viewState
     */
    private _setViewState(viewState: ViewStateStore, key: string = ''): void {
        try {
            window.localStorage.setItem(key ? key : this._getViewStateKey(), JSON.stringify(viewState));
        } catch {
            console.warn('Unable to store view state, reseting view state to default');
            this.resetViewState(key);
        }
    }

    /**
     * Reset the view state to the hardcoded default
     */
    public resetViewState(key: string = '') {
        window.localStorage.setItem(key ? key : this._getViewStateKey(), JSON.stringify(ViewStateService.DEFAULT_VIEW_STATE));
    }

    /**
     * Sets the section cache given a specific section
     * If it's in a group, sets the appropriate group cache
     *
     * @param section
     */
    public setViewSectionCache(section: ViewSectionEnum): void {
        const viewState = this.getViewState();
        if (ViewSectionProgramGroup.includes(section as ViewSectionEnum)) {
            viewState.primary = ViewStateGroupEnum.PROGRAM;
            viewState[ViewStateGroupEnum.PROGRAM].section = section;
        } else if (ViewSectionPoolsGroup.includes(section as ViewSectionEnum)) {
            viewState.primary = ViewStateGroupEnum.POOLS;
            viewState[ViewStateGroupEnum.POOLS].section = section;
        } else if (ViewSectionResultsGroup.includes(section as ViewSectionEnum)) {
            viewState.primary = ViewStateGroupEnum.RESULTS;
            viewState[ViewStateGroupEnum.RESULTS].section = section;
        } else if (ViewSectionStatsGroup.includes(section as ViewSectionEnum)) {
            viewState.primary = ViewStateGroupEnum.STATS;
            viewState[ViewStateGroupEnum.STATS].section = section;
        } else {
            viewState.primary = section as any;
        }
        this._setViewState(viewState);
    }

    /**
     * Gets the cached section based on the stored key
     */
    public getViewSectionCache(): ViewSectionEnum {
        const viewState = this.getViewState();
        switch (viewState.primary) {
            case ViewStateGroupEnum.PROGRAM:
                return viewState[ViewStateGroupEnum.PROGRAM].section;
            case ViewStateGroupEnum.POOLS:
                return viewState[ViewStateGroupEnum.POOLS].section;
            case ViewStateGroupEnum.RESULTS:
                return viewState[ViewStateGroupEnum.RESULTS].section;
            case ViewStateGroupEnum.STATS:
                return viewState[ViewStateGroupEnum.STATS].section;
            default:
                return viewState.primary as any;

        }
    }

    /**
     * Gets the cache for a specific section group
     *
     * @param sectionGroup
     */
    public getViewSectionGroupCache(sectionGroup: ViewStateGroupEnum): ViewSectionEnum {
        const viewState = this.getViewState();
        switch (sectionGroup) {
            case ViewStateGroupEnum.PROGRAM:
                return viewState[ViewStateGroupEnum.PROGRAM].section;
            case ViewStateGroupEnum.POOLS:
                return viewState[ViewStateGroupEnum.POOLS].section;
            case ViewStateGroupEnum.RESULTS:
                return viewState[ViewStateGroupEnum.RESULTS].section;
            case ViewStateGroupEnum.STATS:
                return viewState[ViewStateGroupEnum.STATS].section;
        }
    }

    /**
     * Sets the sort state for a given group or view
     * @param section
     * @param sortState
     */
    public setSortState(section: ViewStateGroupEnum | ViewSectionEnum, sortState: ISortState) {
        const viewState = this.getViewState();
        switch (section) {
            case ViewStateGroupEnum.PROGRAM:
                viewState[ViewStateGroupEnum.PROGRAM].sortState = sortState;
                break;
            case ViewSectionEnum.POOLS:
                viewState[ViewStateGroupEnum.POOLS].pools.sortState = sortState;
                break;
            case ViewSectionEnum.WILL_PAYS:
                viewState[ViewStateGroupEnum.POOLS].will_pays.sortState = sortState;
                break;
        }
        this._setViewState(viewState);
    }

    /**
     * Gets the sort state for a given group or view
     * @param section
     */
    public getSortState(section: ViewStateGroupEnum | ViewSectionEnum): ISortState {
        const viewState = this.getViewState();
        switch (section) {
            case ViewStateGroupEnum.PROGRAM:
                return viewState[ViewStateGroupEnum.PROGRAM].sortState;
            case ViewSectionEnum.POOLS:
                return viewState[ViewStateGroupEnum.POOLS].pools.sortState;
            case ViewSectionEnum.WILL_PAYS:
                return viewState[ViewStateGroupEnum.POOLS].will_pays.sortState;
        }
    }

    /**
     * Sets the cached Pools Sub View
     * @param view
     */
    public setPoolsSubView(view: PoolSubView) {
        const viewState = this.getViewState();
        viewState[ViewStateGroupEnum.POOLS].pools.subView = view;
        this._setViewState(viewState);
    }

    /**
     * Gets the cached Pools Sub View
     */
    public getPoolsSubView(): PoolSubView {
        return this.getViewState()[ViewStateGroupEnum.POOLS].pools.subView as PoolSubView;
    }

    /**
     * Sets the cached Probables pool type
     * @param pool
     */
    public setProbablesPoolType(pool: enumPoolType) {
        const viewState = this.getViewState();
        viewState[ViewStateGroupEnum.POOLS].probables.poolType = pool;
        this._setViewState(viewState);
    }

    /**
     * Gets the cached Probables pool type
     */
    public getProbablesPoolType(): enumPoolType {
        return this.getViewState()[ViewStateGroupEnum.POOLS].probables.poolType;
    }

}
