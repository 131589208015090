import { Bet, Entry, IAdwRace, ITrack, IPoolType } from '@cdux/ng-common';

import { CduxArrayUtil } from './CduxArrayUtil';

export class QuickBetLinkUtil {

    public static buildQuickBetModel(
        quickBetData: any,
        quickBetTrackData: any
    ): Bet {
        /**
         * Data Parsing
         */
        // Instantiate a new Bet Model for Quick Bet
        const quickBet = new Bet();

        /**
         * Track Data
         */

        // Create new ITrack Object
        const quickBetTrack: ITrack = {} as ITrack;
        // Map Route Params into ITrack Object
        quickBetTrack.BrisCode = quickBetTrackData.brisCode;
        quickBetTrack.TrackType = quickBetTrackData.trackType;
        // Inject ITrack into Quick Bet Model
        quickBet.track = quickBetTrack;

        /**
         * IAdwRace Data
         */

        // Create new IAdwRace Object
        const quickBetRace: IAdwRace = {} as IAdwRace;
        // Map Route Params into IAdwRace Object
        quickBetRace.race = parseInt(quickBetTrackData.raceNumber, 10);
        // Inject IAdwRace into Quick Bet Model
        quickBet.race = quickBetRace;

        /**
         * Pool Data
         */

        // Create new IPoolType Object
        const quickBetPool: IPoolType = {} as IPoolType;
        // Map Query Parameters into IPoolType Object
        quickBetPool.Code = quickBetData.betType;
        // Inject IPoolType into Quick Bet Model
        quickBet.poolType = quickBetPool;

        /**
         * Runner Data
         */

        // Create new Runners List
        const quickBetRunners: Entry[][] = [];
        // Iterate Over Query Parameter Runner Keys (Should be alpha-numeric representing the leg)
        Object.keys(quickBetData.entries).forEach((leg: string) => {
            quickBetRunners.push(
                // Iterate over the Runners for each Leg
                quickBetData.entries[leg].map((programNumber) => {
                    // Create new Entry Object
                    const entry: Entry = {} as Entry;
                    // Map Entry Data from Query Parameter
                    entry.ProgramNumber = '' + programNumber;
                    entry.ProgramNumberCoupled = '' + programNumber;
                    entry.leg = parseInt(leg, 10) - 1;
                    return entry;
                })
            );
        });
        // Inject Runners List into Quick Bet Model
        quickBet.runners = quickBetRunners;

        /**
         * Bet Data
         */

        // Inject Bet Model Metadata into Quick Bet Model
        quickBet.allowedAmounts = [];
        quickBet.betSubtype = quickBetData.betModifier;
        quickBet.amount.value = '' + quickBetData.betAmount;
        quickBet.betCreatedTimestamp = Date.now();

        return quickBet;
    }

    /**
     * Populates a Quick Bet Model Runners List with Program Data
     * @param bet - A Quick Bet
     * @param entries - Program Entries
     * @param raceData - IAdwRace Data
     * @param trackData - Track Data
     * @param betData - Bet Data
     */
    public static populateQuickBetModel(
        bet: Bet,
        entries: Entry[],
        raceData: IAdwRace,
        trackData: ITrack,
        betData: any
    ): Bet {
        let populatedBet: Bet = Object.assign({}, bet);
        // Ensure we are starting with all entries having a leg of 0
        entries = entries.map((entry: Entry) => {
            // Copy each object in the array to avoid polluting the reference
            const tmpEntry = new Entry(entry);
            // Set the leg to 0, since we are about to map the legs properly
            tmpEntry.leg = 0;
            return tmpEntry;
        });

        // Ensure that we have Entries and a Bet with a Runners list
        if (entries && bet && bet.runners) {
            const betCoupledRunners = [];

            // Match up the Quick Bet Pool Type with the Associated Pool Type
            let betPoolType = betData.betTypeOptions.filter((betType: any) => {
                return betType.value.Code === bet.poolType.Code;
            });
            if (betPoolType instanceof Array && betPoolType.length) {
                betPoolType = betPoolType[0].value;
            }

            const multipleRace = betPoolType.MultipleRace;

            // Iterate over each Leg of the Runners List
            bet.runners.forEach((leg: Entry[], index) => {
                // Flatten the returned Array
                const betCoupledRunnerLeg = [].concat(
                    // Iterate over the Entries in each Leg
                    ...leg.map((runner: Entry) => {
                        // Match the Leg Entries to the Program Entries
                        return entries.filter((entry: Entry) => {
                            // Check for ProgramNumberCoupled to include Field and Coupled Entries
                            // Exclude scratched entries
                            // Entries are only for the first leg. If it's a multi race wager, we can't use the isScratched
                            // flag to determine subsequent scratches
                            return (!entry.isScratched || (multipleRace && index !== 0)) && (entry.ProgramNumberCoupled === runner.ProgramNumberCoupled);
                        }).map((entry: Entry) => {
                            /**
                             * Here we set the leg of the entry in the bet runners list, the leg is
                             * a bitwise integer where each bit represents a different leg. We are
                             * storing reference to the entry object in the context of each of the
                             * legs so that we can continually compount bits on top of each other
                             * to ensure that entries are properly selected for each leg.
                             *
                             * For instance, if a runner is present in both leg 1 and 2, we first
                             * will store the runner in the first leg with a leg of 1, updating the
                             * reference. When we encounter the same runner in leg two, we store the
                             * runner in the second leg, and compount the second bit, so the leg is
                             * now 3 for both entries.
                             */
                            entry.leg |= (1 << runner.leg);
                            return entry;
                        });
                    })
                );
                // Push the Flattened Array of Program Entries into the Runners List
                betCoupledRunners.push(betCoupledRunnerLeg);
            });

            // Populate Program data into the Quick Bet
            populatedBet = Bet.fromBetlike(bet);
            populatedBet.allowedAmounts = betData.betAmountOptions;
            populatedBet.poolType = betPoolType;
            populatedBet.race = raceData;
            populatedBet.track = trackData;
            populatedBet.runners = betCoupledRunners;
            // Find the closest allowed bet amount, in case the specified amount is invalid
            const amounts: string[] = populatedBet.allowedAmounts.map(amount => {
                return amount.value
            });
            populatedBet.amount.value = '' + CduxArrayUtil.closest(amounts, populatedBet.amount.value);
        }

        return populatedBet;
    }

}
