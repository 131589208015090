import { Observable, merge, of, Subject, EMPTY } from 'rxjs';
import {
    finalize,
    share,
    takeUntil,
    take,
    refCount,
    skip,
    map,
    withLatestFrom,
    catchError,
    distinctUntilChanged,
    startWith,
    switchMap,
    filter,
    publishReplay,
} from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { FavoriteTracksService, ITrackBasic, TodaysDisplayTrack } from '@cdux/ng-common';
import { enumDropdownStates } from '@cdux/ng-fragments';

import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

export class TrackListRequestHandler extends CduxRxJSBuildingBlock<any, ITrackGroupLists> {
    protected _stream: Observable<ITrackGroupLists>;

    /** CONTROLS **/
    private _dropdownStateChange: Subject<enumDropdownStates> = new Subject<enumDropdownStates>();
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _todaysRacesService: TodaysRacesBusinessService,
        private _favoriteTracksService: FavoriteTracksService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._dropdownStateChange.complete();
    }

    public updateDropdownState(state: enumDropdownStates) {
        this._dropdownStateChange.next(state);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        // Get the track list initially to avoid a delay waiting for it to
        // be populated on open
        const initialTrackListsObs = this._getTrackListObs();
        const initialAndOpenStreamObs = merge(initialTrackListsObs, this._dropdownOpenStream()).pipe(
            // Multicast this obs to share it between the main stream and the closed state
            // stream. Otherwise a late subscriber may miss the first emit.
            publishReplay(1),
            refCount()
        );

        this._stream = merge(initialAndOpenStreamObs, this._dropdownClosedStream(initialAndOpenStreamObs)).pipe(
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        );
    }

    /** CUSTOM OBSERVABLES **/
    private _dropdownOpenStream(): Observable<ITrackGroupLists> {
        return this._filteredDropdownState().pipe(
            switchMap((state) => {
                if (state === enumDropdownStates.OPEN) {
                    return this._getTrackListObs(true);
                } else {
                    return EMPTY;
                }
            })
        );
    }

    /**
     * Listen to favorite track changes while the track dropdown is closed. This is so
     * the favorite tracks in the dropdown are kept up to date if the user favorites or
     * unfavorites a track somewhere else while in a closed state.
     */
    private _dropdownClosedStream(trackListObs: Observable<ITrackGroupLists>): Observable<ITrackGroupLists> {
        return this._filteredDropdownState().pipe(
            switchMap((state) => {
                if (state === enumDropdownStates.CLOSED) {
                    return this._favoriteTracksService.getFavoriteTracks().pipe(
                        // Skip the first list of favorites because it's accounted for in the
                        // most recently polled list of tracks.
                        skip(1),
                        withLatestFrom(trackListObs)
                    );
                } else {
                    return EMPTY;
                }
            }),
            map(([favoriteBasicTracks, trackGroupLists]) => {
                return this._updateFavoriteTracksList(favoriteBasicTracks, trackGroupLists);
            })
        );
    }

    /**
     * Listen to dictinct dropdown state changes from CLOSED to OPEN or
     * vice versa.
     */
    private _filteredDropdownState(): Observable<enumDropdownStates> {
        return this._dropdownStateChange.pipe(
            filter((state) => state !== enumDropdownStates.NONE),
            startWith(enumDropdownStates.CLOSED),
            distinctUntilChanged()
        );
    }

    private _getTrackListObs(poll: boolean = false): Observable<ITrackGroupLists> {
        const trackListObs = this._todaysRacesService.getTodaysActiveTracks(true).pipe(
            catchError((e) => {
                return of({} as ITrackGroupLists);
            })
        );
        return poll ? trackListObs : trackListObs.pipe(take(1));
    }
    /** END CUSTOM OBSERVABLES **/

    /** METHODS **/

    /**
     * Recompile a list of today's favorite tracks given a new list of favorite
     * basic tracks, and a track group list object.
     *
     * @param favoriteBasicTracks
     * @param trackGroupLists
     */
    private _updateFavoriteTracksList(favoriteBasicTracks: ITrackBasic[], trackGroupLists: ITrackGroupLists): ITrackGroupLists {
        if (!!favoriteBasicTracks && !!trackGroupLists && !!trackGroupLists.generalTracks && !!trackGroupLists.canceledTracks) {
            const allTracks: TodaysDisplayTrack[] = trackGroupLists.generalTracks.concat(trackGroupLists.canceledTracks);
            const favoriteTracks: TodaysDisplayTrack[] = this._todaysRacesService.compileFavoriteTracks(allTracks, favoriteBasicTracks);
            trackGroupLists.favoriteTracks = favoriteTracks;
        }
        return trackGroupLists;
    }
    /** END METHODS **/
}
