import {
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { of, Subscription } from 'rxjs';

import { ENVIRONMENT } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { NotificationBadgeService } from 'app/shared/notification/badges/notification-badge.service';
import { EAccountNotificationBadge } from 'app/shared/notification/badges/account-notification-badge.enum';

import { AccountHistorySidebarComponent } from '../account-history/components';
import { CashierComponent } from '../cashier/cashier.component';
import { PreferencesComponent } from '../preferences/preferences.component';
import { ManageComponent } from '../manage/components/manage.component';
import { TaxableYearListComponent } from '../tax-documents/components/taxable-year-list/taxable-year-list.component';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumFeatureToggle,
    EventClickType,
    FeatureToggleDataService,
    JwtSessionService,
    LegacySupportService,
    TranslateService,
    WindowRefService,
} from '@cdux/ng-common';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { CduxSidebarContentComponent } from '../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
} from '../../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../../sidebar/sidebar.service';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { RewardsContainerComponent } from '../rewards/components/rewards-container/rewards-container.component';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { catchError, take } from 'rxjs/operators';


@Component({
    selector: 'cdux-my-account-container',
    templateUrl: './my-account-container.component.html',
    styleUrls: ['./my-account-container.component.scss']
})

export class MyAccountContainerComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {
    public readonly AFFILIATE_KEY = this._environment.affiliateId.toString();
    private readonly EXT_REWARDS_URL_KEY = 'external_rewards_url';
    private readonly EXT_RESPONSIBLE_GAMING_URL_KEY = 'external_responsible_gaming_url';

    public fullPageWithdrawFT: boolean = false;
    public accountHistoryToggle: boolean = false;
    public affiliateName: string;
    public environment = this._environment;
    public firstName: string;
    public hasNotificationFavorites = false;
    public isPhone = true;
    public manageTitle: string;
    public showOldStable: boolean = false;
    public externalRewardsUrl: string;
    public externalResponsibleGamingUrl: string;

    public isStableAlertsEnabled: boolean;

    private _window: Window;
    private _rewardsFeatureToggle: boolean;
    private _subs: Subscription[] = [];
    private _w2gSidebarFeatureToggle: boolean;

    /**
     * Exposes the event click types to the template.
     *
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    /* IMPLEMENT CduxSidebarContentComponent
    * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: MyAccountContainerComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        };
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return null;
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        public elementRef: ElementRef,
        private _environment: ENVIRONMENT,
        private _eventTrackingService: EventTrackingService,
        private _featureToggleService: FeatureToggleDataService,
        private _legacySupportService: LegacySupportService,
        private _mediaQuery: CduxMediaToggleService,
        private _notificationBadgeService: NotificationBadgeService<EAccountNotificationBadge>,
        private _router: Router,
        private _sidebarService: SidebarService,
        private _translateService: TranslateService,
        private sessionService: JwtSessionService,
        private _configurationService: ConfigurationDataService,
        private _windowRefService: WindowRefService,
    ) { super(); }

    ngOnInit() {
        this._window = this._windowRefService.nativeWindow;
        this.firstName = this.sessionService.getUserInfo().firstName;
        this.affiliateName = this._translateService.translate('affiliate-name', this._environment.affiliateId.toString(), false);
        this.hasNotificationFavorites = this._notificationBadgeService.has(EAccountNotificationBadge.FAVORITE_RUNNER_TODAY);
        this.manageTitle = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.HIDE_PIN) ? 'manage-subtitle-no-pin' : 'manage-subtitle';

        this._subs.push(
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.REWARDS)
                .subscribe(ft => this._rewardsFeatureToggle = ft),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.TUX_W2G_SIDEBAR)
                .subscribe(ft => this._w2gSidebarFeatureToggle = ft),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.ACCT_HISTORY)
                .subscribe(ft => this.accountHistoryToggle = ft),
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.STABLE_ALERTS)
                .subscribe(ft => this.isStableAlertsEnabled = ft),
            this._featureToggleService.watchFeatureToggle(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT)
                .subscribe(ft => this.fullPageWithdrawFT = ft),
            this._mediaQuery.registerQuery('phone')
                .subscribe(isPhone => this.isPhone = isPhone),
            this._configurationService.getConfiguration(enumConfigurationStacks.TUX, [this.EXT_REWARDS_URL_KEY, this.EXT_RESPONSIBLE_GAMING_URL_KEY])
                .pipe(
                    catchError(() => {
                        return of({});
                    }),
                    take(1)
                )
                .subscribe({
                    next: (configObj) => {
                        this.externalRewardsUrl = configObj[this.EXT_REWARDS_URL_KEY] ? configObj[this.EXT_REWARDS_URL_KEY] : null;
                        this.externalResponsibleGamingUrl = configObj[this.EXT_RESPONSIBLE_GAMING_URL_KEY] ? configObj[this.EXT_RESPONSIBLE_GAMING_URL_KEY] : null;
                    }
                })
        );
    }

    ngOnDestroy() {
        this._subs.forEach((sub) => {
            sub.unsubscribe();
        });
    }

    // Stop propagation of click events that are within the account menu
    @HostListener('click', ['$event', '$event.target']) _isLocalClick(event: Event) {
        event.stopPropagation();
    }

    // Stop propagation of touch events that are within the account menu
    @HostListener('touchend', ['$event', '$event.target']) _isLocalTouch(event: Event) {
        event.stopPropagation();
    }

    // todo: need to load proper component once available
    public goToMyAccountManage(): void {
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_MY_ACCOUNT);
        this._sidebarService.loadComponent(ManageComponent.getSidebarComponent(), ManageComponent.getHeaderComponent());
    }

    public goToAccountHistory(): void {
        this._sidebarService.close();
        this._router.navigate(['/account/history']);
    }

    public goToAccountHistorySmallGlass(): void {
        if (!this.accountHistoryToggle) {
            this.goToDailyAccountHistory();
        } else {
            this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_ACCOUNT_HISTORY);
            this._sidebarService.close(); // USER-10533 fix to set redirect to account/history instead of sidebar loadComponent
            this._router.navigate(['account', 'history']);
            // this._sidebarService.loadComponent(AccountHistoryWrapperComponent.getSidebarComponent(), AccountHistoryWrapperComponent.getHeaderComponent());
        }
    }

    public goToDailyAccountHistory(): void {
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_ACCOUNT_HISTORY);
        this._sidebarService.loadComponent(AccountHistorySidebarComponent.getSidebarComponent(), AccountHistorySidebarComponent.getHeaderComponent());
    }

    public goToCashier(): void {
        if (this.fullPageWithdrawFT) {
            this._sidebarService.close();
            this._router.navigate(['funding', 'deposit', 'options']);
        } else {
            this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_CASHIER);
            this._sidebarService.loadComponent(CashierComponent.getSidebarComponent(), CashierComponent.getHeaderComponent());
        }
    }

    public goToFavorites(): void {
        // badge clearing handled by Fav Mgr's OnInit
        // this._notificationBadgeService.remove(EAccountNotificationBadge.FAVORITE_RUNNER_TODAY);
        // Log Event Click MY_FAV_LINK:
        this._eventTrackingService.logClickEvent(EventClickType.MY_ACCOUNT_MY_FAV_LINK);
        this._sidebarService.close();
        this._router.navigate(['/favorites/manager']);
    }

    public goToPreferences(): void {
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_LOGIN_SETTINGS);
        this._sidebarService.loadComponent(PreferencesComponent.getSidebarComponent(), PreferencesComponent.getHeaderComponent());
    }

    public goToRewards(): void {
        if (this._rewardsFeatureToggle) {
            this._sidebarService.loadComponent(RewardsContainerComponent.getSidebarComponent(), RewardsContainerComponent.getHeaderComponent());
        }
    }

    public goToW2G(): void {
        if (this._w2gSidebarFeatureToggle) {
            this._sidebarService.loadComponent(TaxableYearListComponent.getSidebarComponent(), TaxableYearListComponent.getHeaderComponent());
        } else {
            window.location.href = '/w2g';
        }
    }

    public goToDAH(): void {
        window.location.href = '/account/history';
    }

    public goToRTB(): void {
        this._legacySupportService.setCookies();
        this._router.navigate(['account', 'history']);
    }

    public goToExternalUrl(url: string): void {
        if (url) {
            // Purpose: Open a new browser tab or window depending on the user's browser settings.

            // Security concern: When launching a window with a _blank target, by default the new
            // page retains partial access to the parent window via an "opener" property. This allows
            // scripts running in the new window to set window.opener.location.href which is strange
            // because you will encounter a DOMException trying to read that value cross-origin. Modern
            // browsers mitigate this by defaulting anchor tags to use rel="noopener" for _blank targets,
            // but that doesn't apply for programmatically opened windows. The solution is to use the
            // noopener window feature with _blank targets. As an added measure, we can also null out the
            // opener on the created window. One downside is that this precludes recycling windows by
            // leveraging a named target.
            const newWindow = this._window.open(url, '_blank', 'noopener');
            if (newWindow) newWindow.opener = null;
        }
    }

    public logout(): void {
        this._eventTrackingService.logClickEvent(EventClickType.MAIN_NAV_MENU_SIGN_OUT);
        this.sessionService.logout().subscribe(() => {
            this._router.navigate(['/']);
        });
    }
}
