import { Component, ChangeDetectorRef, ViewContainerRef, ViewChild, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IReplayStream, IReplayStreamMSRCN, VideoAngles } from '@cdux/ng-common';

import { IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';
import { RCNBaseVideoFeed } from './rcn-base-video-feed.class';
import { RCNReplayStreamSource } from './rcn-stream-sources/rcn-replay-stream-source.service';

import { VideoProviderService } from '../video-provider.service';
import { VideoService } from 'app/wagering/video/services/video.service';


@Component({
    selector: 'cdux-rcn-html-replay-feed',
    template: `<div #streamContainer><video id='rcnHtmlReplayVideo' [src]='videoSrc' [muted]='muted' autoplay controls playsinline></video></div>`
})
export class RCNHTMLReplayFeedComponent extends RCNBaseVideoFeed implements OnInit {
    @ViewChild('streamContainer', { read: ViewContainerRef })
    protected container: ViewContainerRef;

    public videoSrc: string;
    public muted: boolean;

    private angle: VideoAngles = VideoAngles.PAN;
    private angles = new Subject<VideoAngles[]>();

    constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected streamSource: RCNReplayStreamSource,
        protected videoProviderService: VideoProviderService,
        private videoService: VideoService
    ) {
        super(changeDetectorRef, videoProviderService, streamSource);
        this.muted = this.videoService.getMuted();
    }

    ngOnInit() {
        super.ngOnInit();
        const videoPlayer = document.querySelector('#rcnHtmlReplayVideo');
        if (videoPlayer) {
            videoPlayer.addEventListener('volumechange', (change) => this.videoService.setMuted(change.target['muted']));
        }
    }

    /**
     * handles data provided by streamSource.getStreamData()
     *
     * TODO: Refactor replay endpoint to have similar return structure as live so getUrlFromCurrentStream can be used for both.
     *
     * @param streamData
     */
    protected handleStreamData (streamData: IVideoStreamResponses) {
        let streamUrl = null;
        const angles: VideoAngles[] = [];

        if (typeof streamData === 'string') {
            streamUrl = streamData;
        } else if (streamData && streamData.hasStreams) {
            streamData.streams.forEach((stream: IReplayStream) => {
                if (stream && stream.angles) {
                    stream.angles.forEach(a => {
                        angles.push(a.angle); // catalog all available feed angles
                        if (!streamUrl || this.angle === a.angle) { // find stream
                            streamUrl = (<IReplayStreamMSRCN>a.streamData).url;
                        }
                    });
                }
            });
        }

        if (streamUrl) {
            // force https for stream url requests
            streamUrl = streamUrl.replace('http://', 'https://');
            this.videoSrc = streamUrl;
            this.angles.next(angles);
            this.resize();
        }
    }

    public getAngle(): VideoAngles {
        return this.angle;
    }

    public getAngles(): Observable<VideoAngles[]> {
        return this.angles.asObservable();
    }

    public setAngle(angle: VideoAngles, getStreamData: boolean): void {
        this.angle = angle;

        if (getStreamData) {
            this.getStreamData();
        }
    }

    // no-op overrides
    public pause() {}
    public start() {}
    public stop() {}
}
