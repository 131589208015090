import { ChangeDetectorRef, Component, Input, OnChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import {
    CduxObjectUtil,
    EventClickType,
    EventClickAttributeType,
    FavoriteRunnersService,
    FeatureToggleDataService,
    IFavoriteRunner,
    JwtSessionService,
} from '@cdux/ng-common';

import { ToastService } from '@cdux/ng-fragments';

import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';

import { enumFavoriteToggleState, FavoriteToggleAbstractComponent } from './favorite-toggle-abstract.component';
import { FavEventType } from '../favorites-event-interface';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';


@Component({
    selector: 'cdux-favorite-toggle-runner',
    templateUrl: './favorite-toggle.component.html',
    styleUrls: [ './favorite-toggle.component.scss' ],
})
export class FavoriteToggleRunnerComponent extends FavoriteToggleAbstractComponent implements OnChanges {
    @Input() set favoriteData(favoriteData: IFavoriteRunner) {
        if (!this.favoriteData || this.favoriteData !== favoriteData) {
            this._data = favoriteData;
        }
    }
    get favoriteData() {
        return this._data;
    }
    @Input() public displayFavoritesLabel = false;
    @Input() public rightDisplay = false;
    @Input() public location = FavoriteLocationEnum.PROGRAM;
    @Input() public replays = false;
    @ViewChild('confirmationModal') protected confirmationModal: TemplateRef<unknown>;

    public id: number;
    private _data: IFavoriteRunner;
    public name: string;

    public get replayNote () {
        return this._replayNote;
    }
    public set replayNote (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.favoriteData.comment = value;
            this._replayNote = value;
        }
    }

    private _replayNote;

    private errorMessages = {
        MAX_NUMBER: 'You have reached the maximum number of %d runners. Please remove some runners before adding new ones.',
        ALREADY_EXISTS: '%s already exists in your stable.',
        DEFAULT: 'We are unable to add this runner to your stable. Please try again later.'
    };
    private errorMessageKeys = {
        MAX_NUMBER: 'maximum number',
        ALREADY_EXISTS: 'already exists'
    };

    public constructor (
        localFeatureToggleService: FeatureToggleDataService,
        localJwtSessionService: JwtSessionService,
        localViewContainerRef: ViewContainerRef,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _changeDetector: ChangeDetectorRef,
        private _eventTrackingService: EventTrackingService,
        private _toastService: ToastService,
        public favRunners: FavoriteRunnersService,


    ) {
        super(localFeatureToggleService, localJwtSessionService, localViewContainerRef);
    }

    // wait for @Input
    public ngOnChanges () {
        // retrieve full record (esp. UUID) if it has been favorited
        if (this.favoriteData && 'runnerId' in this.favoriteData) {
            this.id = this.favoriteData.runnerId;
            this.name = this.favoriteData.runnerName;
            const record = this.favRunners.getByID(this.favoriteData);
            if (record) {
                this._data = Object.assign({}, record);
                this.countCurrent = this.favoriteData.comment?.length;
                this._currentState = enumFavoriteToggleState.ON;
                this._changeDetector.markForCheck();
                return;
            }
        }
        this._currentState = enumFavoriteToggleState.OFF;
        this._changeDetector.markForCheck();
    }

    public confirm (): void {
        if (this.replays && this.replayNote?.length > 0) {
            this.favoriteData.comment = this.replayNote;
        }
        this.favRunners.add(this.favoriteData).then(
            (result: IFavoriteRunner) => {
                this._logClickEvent(EventClickType.STABLE_ALERTS_ADD);
                this.favoriteData.uuid = result.uuid;
                this.turnOn();
                // call AccountBubbleNotificationService.dispatch to emit success message:
                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_ADD,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.favoriteData.runnerName + ' added to <span class="bold-text">stable</span>.',
                        path: '/favorites/manager/Runners',
                        duration: 3000
                    }
                });
                this._changeDetector.markForCheck();
            },
            error  => {
                // Get the maximum number of records out of the message:
                const errorsArray: string[] = CduxObjectUtil.deepGet(error, 'data.error.errors');
                const errorMessage: string = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].toLowerCase() : null;
                // Because we don't want to use custom error codes, many potential errors are returned as 400. This means I
                // need to parse the string and search for specific terms in order to determine which error we have encountered
                if (errorMessage.search(this.errorMessageKeys.MAX_NUMBER) !== -1) {
                    // Show maximum number error
                    const maxRecords: any[] = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].match(/(\d+)/g) : null;
                    if (error.data.error.code === 400 && Array.isArray(maxRecords) && maxRecords.length) {
                        // Display the error message
                        this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%d', maxRecords[0]));
                    }
                } else if (errorMessage.search(this.errorMessageKeys.ALREADY_EXISTS) !== -1) {
                    // Show already exists error message
                    this._toastService.cduxWarning(this.errorMessages.ALREADY_EXISTS.replace('%s', this.favoriteData.runnerName));
                } else {
                    // Show default error message
                    this._toastService.cduxWarning(this.errorMessages.DEFAULT);
                }
                this._changeDetector.markForCheck();
            }
        ).finally(() => {
            this.dismiss();
        });
    }

    public toggleFavorite() {
        try {
            if (!this.isToggling) {
                this.isToggling = true;
                if (this._currentState === enumFavoriteToggleState.OFF) {
                    this._logClickEvent(EventClickType.STABLE_ALERTS_ADD_TO_STABLE);
                    this.modalContent = this.templatePortal;
                } else {
                    this._logClickEvent(EventClickType.PROGRAM_RUNNER_DETAILS_REMOVE_FAV_RUNNER);
                    this.favRunners.remove(this.favoriteData.uuid).then(
                        success => {
                            if (success) {
                                this.turnOff();
                                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                                this._accountBubbleNotificationService.dispatch({
                                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                                    payload: {
                                        message: '<i class="icon--check-light-circle"></i>' + this.favoriteData.runnerName + ' removed from <span class="bold-text">stable</span>.',
                                        duration: 3000
                                    }
                                });
                                this._changeDetector.markForCheck();
                            }
                        },
                        error  => { console.error('Unable to remove from stable.'); }
                    );
                }
            }
        } catch (e) {
            console.error('Toggle failed.', e);
        } finally {
            this.isToggling = false;
        }
    }

    private _logClickEvent(clickType: EventClickType) {
        if (clickType === EventClickType.PROGRAM_RUNNER_DETAILS_REMOVE_FAV_RUNNER) {
            this._eventTrackingService.logClickEvent(clickType);
        } else {
            const ts = Date.now();

            let favType = EventClickAttributeType.STABLE_ALERTS_ADD_FAVORITE_TYPE;
            let favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_LOCATION;

            if (clickType === EventClickType.STABLE_ALERTS_ADD_TO_STABLE) {
                favType = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_FAVORITE_TYPE;
                favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_LOCATION;
            }

            this._eventTrackingService.logClickEvent(
                clickType,
                [
                    {
                        attrId: favType,
                        data: FavoriteTypeEnum.RUNNER,
                        timestamp: ts
                    },
                    {
                        attrId: favLoc,
                        data: this.location,
                        timestamp: ts
                    },
                ]
            );
        }
    }
}
