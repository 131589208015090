import { Injectable } from '@angular/core';
import {
    enumRaceStatus,
    enumTrackStatus,
    enumTrackType,
    ITrack,
    IAdwRace,
    ToteDataService,
    TracksDataService,
} from '@cdux/ng-common';
import { Observable, ReplaySubject, combineLatest, of, throwError } from 'rxjs';
import { tap, map, finalize, publishReplay, refCount, distinctUntilKeyChanged, concatMap, catchError, withLatestFrom, take } from 'rxjs/operators';

import { ProgramModuleBusinessService } from './program-module.business.service';

@Injectable({
    providedIn: 'root'
})
export class ProgramNavBusinessService {

    /**
     * Races (PLURAL)
     */
    private _racesObservables: Observable<IAdwRace[]>[] = [];

    /**
     * Tracks (PLURAL)
     */
    private _tracksObservable: Observable<ITrack[]>;
    private _tracks: ITrack[] = [];
    get tracks(): ITrack[] { return this._tracks; }

    /**
     * @deprecated
     */
    public tracksChanges: ReplaySubject<ITrack[]> = new ReplaySubject(1);

    /**
     * Available
     */
    private _availabilityObservables: Observable<boolean>[] = [];

    /**
     * IAdwRace
     */
    private _raceObservables: Observable<IAdwRace>[] = [];
    private _raceDateObservable: Observable<string>;

    /**
     * @deprecated
     */
    public raceChanges: ReplaySubject<IAdwRace> = new ReplaySubject(1);

    /**
     * Track
     */
    private _trackObservables: Observable<ITrack>[] = [];

    public currentTrack: ITrack = null;
    public currentRace: IAdwRace = null;

    /**
     * Normalizes the BrisCode, so that we can compare apples to apples.
     *
     * @param {string} brisCode
     * @returns {string}
     */
    public static normalizeBrisCode(brisCode: string) {
        return brisCode.toUpperCase();
    }

    constructor(
        private _toteService: ToteDataService,
        private _tracksService: TracksDataService,
        private _programModuleService: ProgramModuleBusinessService) {}

    /**
     * Initialize everything necessary for the program nav.
     *
     * @param {string} [trackCode]
     * @param {enumTrackType} [trackType]
     * @param {number} [raceNumber]
     */
    public resolve(trackCode?: string, trackType?: enumTrackType, raceNumber?: number): Observable<any> {

        // Make it wait until the second emission if availability changes is true, otherwise finish on the first.
        return combineLatest([
            this.getTrackChanges(trackCode, trackType),
            this.getRaceChanges(raceNumber, trackCode, trackType),
            this.getAvailabilityChanges(trackCode, trackType),
            this.getRaceDateChanges()
        ]).pipe(
            take(1),
            catchError((err, obs) => of([undefined, undefined, false]))
        )
    }

    /**
     * Returns an observable of the current track.
     *
     * @param {string} [trackCode]
     * @param {string} [trackType]
     * @returns {Observable<ITrack>}
     */
    public getTrackChanges(trackCode?: string, trackType?: enumTrackType): Observable<ITrack> {
        const key = trackCode || 'default';
        if (this._trackObservables[key]) {
            return this._trackObservables[key];
        }

        this._trackObservables[key] = this.getTracksChanges().pipe(
            map((tracks: ITrack[]) => {
                let track: ITrack;

                // If the track list has not been initialized, get the set initial track, if available.
                if (trackCode) {
                    const brisCode = ProgramNavBusinessService.normalizeBrisCode(trackCode);
                    const filteredTracks = tracks.filter((tempTrack) => {
                        const tracksMatch = ProgramNavBusinessService.normalizeBrisCode(tempTrack.BrisCode) === brisCode;

                        if (!trackType) {
                            return tracksMatch;
                        }

                        return tracksMatch && tempTrack.TrackType === trackType;
                    });

                    if (filteredTracks.length > 0) {
                        track = filteredTracks[0];
                    }
                }

                if (!track) {
                    // How to handle not finding the track.
                    const filteredTracks = tracks
                        .filter((t) =>
                            t.Status === enumTrackStatus.OPEN &&
                            t.RaceStatus === enumRaceStatus.OPEN &&
                            t.WageringAvailable);

                    // Stick with the earliest lowest MTP, thus maintaining alphabetical order.
                    // If there aren't any open tracks, default to the first track.
                    track = filteredTracks.reduce((previous, current) => current.Mtp < previous.Mtp ? current : previous, filteredTracks[0] || tracks[0]);
                }

                return track;
            }),
            finalize(() => {
                this._trackObservables[key] = null;
            }),
            publishReplay(1),
            refCount()
        );

        return this._trackObservables[key];
    }

    /**
     * Returns an observable of track list updates.
     */
    public getTracksChanges(): Observable<ITrack[]> {
        if (this._tracksObservable) {
            return this._tracksObservable;
        }

        this._tracksObservable = this._tracksService.trackList(true).pipe(
            map((tracks: ITrack[]) => {
                if (tracks.length === 0) {
                    throw new Error('Track list unavailable.');
                }
                return tracks;
            }),
            tap((tracks: any) => {
                this._tracks = tracks;
                this.tracksChanges.next(tracks);
            }),
            finalize(() => {
                this._tracksObservable = null;
            }),
            publishReplay(1),
            refCount()
        );

        return this._tracksObservable;
    }

    /**
     * Returns an observable of the current race.
     *
     * @param {number} [raceNumber]
     * @param {string} [trackCode]
     * @param {enumTrackType} [trackType]
     * @returns {Observable<IAdwRace>}
     */
    public getRaceChanges(raceNumber?: number, trackCode?: string, trackType?: enumTrackType): Observable<IAdwRace> {
        const key = `${raceNumber || 'default'}-${trackCode || 'default'}`;

        if (this._raceObservables[key]) {
            return this._raceObservables[key];
        }

        this._raceObservables[key] = this.getRacesChanges(trackCode, trackType).pipe(
            withLatestFrom(this.getTrackChanges(trackCode, trackType)),
            map(([races, track]) => {
                const targetRaceNumber = raceNumber || +track.RaceNum;
                const filteredRaces = races.filter((tempRace) => {
                    return +tempRace.race === +targetRaceNumber;
                });

                let race: IAdwRace;
                if (filteredRaces.length > 0) {
                    race = filteredRaces[0];
                } else {
                    // How to handle not finding the race.
                    return null;
                }

                // Only publish the race change if it's associated with the current track
                if (race.track === track.AtabCode) {
                    this.raceChanges.next(race);
                }
                return race;
            }),
            finalize(() => {
                delete this._raceObservables[key];
            }),
            publishReplay(1),
            refCount()
        );

        return this._raceObservables[key];
    }

    public getRaceDateChanges() {
        if (this._raceDateObservable) { return this._raceDateObservable; }
        return this._raceDateObservable = this._toteService.currentRaceDate(true);
    }

    /**
     * Returns an observable of races.
     *
     * @param {string} [trackCode]
     * @param {enumTrackType} [trackType]
     * @returns {Observable<IAdwRace[]>}
     */
    public getRacesChanges(trackCode?: string, trackType?: enumTrackType): Observable<IAdwRace[]> {
        const key = trackCode || 'default';

        if (this._racesObservables[key]) {
            return this._racesObservables[key];
        }

        this._racesObservables[key] = this.getTrackChanges(trackCode, trackType).pipe(
            distinctUntilKeyChanged('BrisCode'),
            concatMap((track: ITrack) => {
                return this._programModuleService.getRaceListCombinedObservable(track).pipe(
                    map((races: IAdwRace[]) => {
                        if (races.length === 0) {
                            throw new Error('Race list unavailable.');
                        }
                        return races;
                    })
                );
            }),
            catchError(err => {
                console.error('Availability Check threw error.', {
                    errorInfo: err,
                    trackCode,
                    trackType
                });
                return throwError(err);
            }),
            finalize(() => {
                delete this._racesObservables[key];
            }),
            publishReplay(1),
            refCount()
        );


        return this._racesObservables[key];
    }

    /**
     * This returns an observable to check for availability.
     *
     * @param {string} [trackCode]
     * @param {string} [trackType]
     * @returns {Observable<boolean>}
     */
    public getAvailabilityChanges(trackCode?: string, trackType?: enumTrackType): Observable<boolean> {
        const key = trackCode || 'default';

        if (this._availabilityObservables[key]) {
            return this._availabilityObservables[key];
        }

        const tracksChanges = this.getTracksChanges();
        const racesChanges = this.getRacesChanges(trackCode, trackType);

        // Determine availability on the results of tracks alone until the race list gets populated.
        this._availabilityObservables[key] = combineLatest(
            tracksChanges,
            racesChanges
        ).pipe(
            map(() => true),
            catchError((err) => of(false))
        );

        return this._availabilityObservables[key];
    }
}
