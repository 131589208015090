export class ManageConstants {
    // Keep the array in sync with manage-account.enum.ts
    // i.e. Email = successMessages[0], Cell = successMessages[1]
    static readonly successMessages = [
        'We sent you an email to confirm this update',
        'We have updated your cell phone',
        'Your password has been updated.',
        'Your PIN has been updated.'];

    static readonly inValidPin = [
        '1234',
        '0000'
    ];
}
