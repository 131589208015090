import { Component, ComponentRef, OnDestroy } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { BetshareStatusUpdateType } from '@cdux/ng-fragments';

import { BetShareStatusUpdate } from '../../models';
import { CduxAbstractBetShareComponent } from '../abstract.bet-share.component';
import {
    EnumSsnCollectionEvents,
    SsnCollectionBetShareComponent
} from '../../../shared/ssn-collection/ssn-collection-bet-share.component';

@Component({
    selector: 'cdux-bet-share-ssn',
    templateUrl: './bet-share-ssn.component.html',
    styleUrls: ['./bet-share-ssn.component.scss']
})
export class BetShareSSNComponent extends CduxAbstractBetShareComponent implements OnDestroy {

    public portal: ComponentPortal<SsnCollectionBetShareComponent> = new ComponentPortal(SsnCollectionBetShareComponent);

    private _subs: Subscription[] = [];

    public ngOnDestroy() {
        this._subs.forEach(s => s.unsubscribe());
        this._subs.splice(0, this._subs.length);
    }

    public onAttached(ref: ComponentRef<SsnCollectionBetShareComponent>) {
        this._subs.forEach(s => s.unsubscribe());
        this._subs.splice(0, this._subs.length);

        this._subs.push(
            ref.instance.closeSsn.subscribe(() => {
                this.statusUpdate.next(new BetShareStatusUpdate(BetshareStatusUpdateType.OPT_OUT));
            }),
            ref.instance.events.subscribe((e: EnumSsnCollectionEvents) => {
                switch (e) {
                    case EnumSsnCollectionEvents.SUCCESS:
                        this.statusUpdate.next(new BetShareStatusUpdate(BetshareStatusUpdateType.SSN_UPDATE_SUCCESS));
                        break;
                }
            })
        );
    }

}
