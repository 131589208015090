import { LANG_WAGER_ERRORS, LANG_WAGER_ERRORS_NAME } from './errors';
import { LANG_WAGER_NAME, LANG_WAGER } from './wager';
import { LANG_BET_SHARE_TOOLTIPS_INFO, LANG_BET_SHARE_TOOLTIPS_NAME } from './bet-share/bet-share-tooltip';
import { LANG_BET_SHARE, LANG_BET_SHARE_NAME } from './bet-share/bet-share';
import { LANG_BET_CUSTOM_AMOUNT, LANG_BET_CUSTOM_AMOUNT_NAME } from './bet-custom-amount/bet-custom-amount-error';
import { LANG_CONDITIONAL_WAGER, LANG_CONDITIONAL_WAGER_NAME } from './conditional-wager/conditional-wager';

export const WAGER_LANGUAGE_DICTIONARY = {
    [LANG_CONDITIONAL_WAGER_NAME]: LANG_CONDITIONAL_WAGER,
    [LANG_WAGER_ERRORS_NAME]: LANG_WAGER_ERRORS,
    [LANG_WAGER_NAME]: LANG_WAGER,
    [LANG_BET_SHARE_TOOLTIPS_NAME]: LANG_BET_SHARE_TOOLTIPS_INFO,
    [LANG_BET_SHARE_NAME]: LANG_BET_SHARE,
    [LANG_BET_CUSTOM_AMOUNT_NAME]: LANG_BET_CUSTOM_AMOUNT
};
