<div class="nav-panel-content">
  <div class="my-account-row dark">
    <div class="my-account-row-info">
      <div class="my-account-row-info-header">Multifactor Authentication</div>
    </div>
    <label class="label-switch">
      <input type="checkbox" [checked]="isMfaEnabled" [disabled]="submitting" (click)="updateUserMfa(!isMfaEnabled)"/>
      <div class="checkbox"></div>
    </label>
  </div>
  <section class="multifactor-authentication-preferences">
    <p>Disabling will require verifying your information again to re-enable MFA.</p>
    <h2 class="h2--standard text__white">SMS Text Message</h2>
    <div class="saved-number-container">
      <div class="bold-text">Cell Phone</div>
      <div class="saved-number-row">
        <span>{{ formattedPhoneNum }}</span>
        <a class="bold-text text--brighten" *ngIf="!submitting" (click)="updateUserMfa(true)">UPDATE</a>
      </div>
    </div>
  </section>
</div>
