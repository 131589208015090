import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { DetectionService } from '@cdux/ng-common';

let redirected: boolean = false;

@Injectable()
export class DeviceRouteGuard implements CanActivate {

    constructor(private _router: Router,
                private _location: Location,
                private _detectionService: DetectionService
    ) {
        // empty
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (redirected) {
            return true;
        }

        // default redirectURL to null
        let redirectURL = null;

        // get the actual path that's being loaded...
        const slug = this._location.path();

        // check if the path we're loading matches the path our Guard is actually applied on...
        const matchedPath = ((slug === null ? '' : slug) === route.routeConfig.path)

        // get device-specific URL path, if one is defined
        if (route.data && route.data.deviceURLs && route.data.deviceURLs[this._detectionService.getDeviceType()]) {
            redirectURL = route.data.deviceURLs[this._detectionService.getDeviceType()];
        }

        // Navigate to our device-specific redirectURL if we have one
        // AND the path fragment matches this guard instance's configured path
        if (redirectURL && matchedPath && (redirectURL !== state.url)) {
            this._router.navigate([redirectURL], {replaceUrl: true});
            redirected = true;
            return false;
        }

        return true;
    }
}
