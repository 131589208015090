import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { EventSessionModule, TranslateModule, WagerModule, CduxNgCommonModule } from '@cdux/ng-common';
import { MtpModule, SharedModule as SharedFragmentsModule, DropdownModule } from '@cdux/ng-fragments';
import { CheckioboxModule } from '@cdux/ng-fragments';
import { TuxCommonModule } from 'app/shared/common/common.module';
import { RaceNavigationModule } from '../../shared/race-navigation/race-navigation.module';
import { ProgramSharedModule } from '../program/program-shared.module';
import { BetSlipModule } from '../bet-slip/bet-slip.module';

import { CommonRaceNavComponent } from './components/common-race-nav/common-race-nav.component';
import { CommonRaceDetailsComponent } from './components/common-race-details/common-race-details.component';
import { BetPadRaceNavComponent } from './components/betpad-race-nav/betpad-race-nav.component';
import { BetPadRaceListComponent } from './components/betpad-race-list/bet-pad-race-list.component';
import { BetPadTrackListComponent } from './components/betpad-track-list/betpad-track-list.component';
import { BetPadBetTypeComponent } from './components/betpad-bet-type/betpad-bet-type.component';
import { BetPadSubTypeComponent } from './components/betpad-sub-type/betpad-sub-type.component';
import { BetPadProgramComponent } from './components/betpad-program/betpad-program.component';
import { BetPadBetNavComponent } from './components/betpad-bet-nav/bet-pad-bet-nav.component';
import { BetPadLegBarComponent } from './components/betpad-leg-bar/bet-pad-leg-bar.component';
import { BetPadEntryComponent } from './components/betpad-entry/bet-pad-entry.component';
import { CommonBetNavComponent } from './components/common-bet-nav/common-bet-nav.component';
import { CommonMtpBadgeComponent } from './components/common-mtp-badge/common-mtp-badge.component';
import { WagerTypeInfoComponent } from '../../bet-pad/shared/learn-more/wager-type-info.component';

@NgModule({
    imports: [
        TuxCommonModule,
        SharedFragmentsModule,
        CommonModule,
        DropdownModule,
        EventSessionModule,
        FormsModule,
        MtpModule,
        RaceNavigationModule,
        CheckioboxModule,
        ProgramSharedModule,
        BetSlipModule,
        CduxNgCommonModule,
        BetSlipModule,
        TranslateModule,
        WagerModule
    ],
    declarations: [
        CommonMtpBadgeComponent,
        CommonRaceNavComponent,
        CommonBetNavComponent,
        CommonRaceDetailsComponent,
        BetPadRaceNavComponent,
        BetPadRaceListComponent,
        BetPadTrackListComponent,
        BetPadBetTypeComponent,
        BetPadSubTypeComponent,
        BetPadProgramComponent,
        BetPadBetNavComponent,
        BetPadLegBarComponent,
        BetPadEntryComponent,
        WagerTypeInfoComponent
    ],
    exports: [
        CommonRaceNavComponent,
        CommonBetNavComponent,
        CommonRaceDetailsComponent,
        CommonMtpBadgeComponent,
        BetPadRaceNavComponent,
        BetPadRaceListComponent,
        BetPadTrackListComponent,
        BetPadBetTypeComponent,
        BetPadSubTypeComponent,
        BetPadProgramComponent,
        BetPadBetNavComponent,
        BetPadLegBarComponent,
        BetPadEntryComponent
    ]
})
export class BetPadSharedModule {
    // TODO: Planning to rename this module to RaceNavModule. This
    // will be the module to house all race nav related business logic
    // and components.
}
