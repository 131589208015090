import { Component } from '@angular/core';
import { CduxSidebarContentComponent } from 'app/shared/sidebar/cdux-sidebar-content-component.class';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';


@Component({
    selector: 'cdux-sidebar-search-container',
    template: '<cdux-standalone-search></cdux-standalone-search>',
    styleUrls: []
})
export class SidebarSearchContainerComponent extends CduxSidebarContentComponent {

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: SidebarSearchContainerComponent,
            properties: {
                navTarget: MenuItemsEnum.RUNNER_SEARCH
            }
        }
    }

    public setProperties() {}
}
