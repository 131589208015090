import { Injectable } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    IPlayerRewards,
    IRedeemRewardsResponse,
    PlayerRewardsDataService,
    TranslateService
} from '@cdux/ng-common';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlayerRewardsBusinessService {
    private readonly _affiliateKey: string;
    private readonly _pinMismatchErrorCode = 422;

    constructor(environment: ENVIRONMENT,
                private _playerRewardsDataService: PlayerRewardsDataService,
                private _translateService: TranslateService) {
        this._affiliateKey = environment.affiliateId.toString();
    }

    public getRewards(): Observable<IPlayerRewards> {
        return this._playerRewardsDataService.getPlayerRewards()
             .pipe(map((playerRewards: IPlayerRewards) => {
                         return playerRewards;
                   }),
                   catchError(err => {
                       let errMessage;
                       if (!!err.data && err.data.status === this._pinMismatchErrorCode) {
                           const phoneNum = this._translateService.translate('primary-phone-number', this._affiliateKey);
                           errMessage = this._translateService.translate('rewards-pin-mismatch', 'my-account', false, [phoneNum]);
                       }
                       return throwError(errMessage);
                   })
             );
    }

    /**
     * return if minimum points available to redeem
     */
    public minRedemption(rewards: IPlayerRewards): boolean {
        return +rewards.totalPointsAvailable >= +rewards.pointsRedemptionMinimum;
    }

    /**
     * return points redeemed or error from data service
     * @param rewards
     */
    public redeemRewards(amount: number): Observable<IRedeemRewardsResponse> {
        return this._playerRewardsDataService.redeemPlayerRewards(amount)
            .pipe(catchError(err => {
                    const phoneNum = this._translateService.translate('primary-phone-number', this._affiliateKey);
                    const errMessage = this._translateService.translate('rewards-redemption-error', 'my-account', false, [phoneNum]);
                    return throwError(errMessage);
                })
            );
    }

    /**
     * return formatted points to redeem
     * points must only be multiples of pointsRedemptionMultiple
     * if points greater than pointsRedemptionMaximum then returns pointsRedemptionMaximum
     * @param rewards
     */
    public getRedeemPoints(rewards: IPlayerRewards): number {
        let pointsToRedeem: number;
        const remainder = +rewards.totalPointsAvailable % +rewards.pointsRedemptionMultiple;
        if (remainder !== 0) {
            pointsToRedeem = +rewards.totalPointsAvailable - +remainder;
        } else {
            pointsToRedeem = +rewards.totalPointsAvailable;
        }

        return pointsToRedeem > +rewards.pointsRedemptionMaximum ? +rewards.pointsRedemptionMaximum : pointsToRedeem;
    }
}
