import { ITrackBasic, IResultsDetails } from '@cdux/ng-common';

/**
 * This class exists for the purpose of converting
 * the results signature to the appropriate model. It is used in
 * the race navigation and the results chart display.
 *
 */
export class Results {

    public track: ITrackBasic;
    public raceDetails: IResultsDetails;


    constructor(resultsResponse: any) { // use any class, signature doesn't match NG class
        this.track = {
            BrisCode: resultsResponse.track.brisCode,
            DisplayName: resultsResponse.track.displayName,
            TrackType: resultsResponse.track.trackType
        };
        // This mapping existing to allow the new results endpoint to be compatible with older interfaces
        // Sepcifically, getAllRaces uses Pascal-cased parameters (first letter is capitalized),
        // this model is intended to maintain compatibility with older php calls.
        this.raceDetails = {
            AboutDistanceIndicator: resultsResponse.raceDetails.aboutDistanceIndicator,
            AgeRestriction: resultsResponse.raceDetails.ageRestriction,
            ageRestrictionCode: resultsResponse.raceDetails.ageRestrictionCode,
            alsoRans: resultsResponse.raceDetails.alsoRans,
            BreedType: resultsResponse.raceDetails.breedType,
            Country: resultsResponse.raceDetails.country,
            dataStatus: resultsResponse.raceDetails.dataStatus,
            Distance: resultsResponse.raceDetails.distance,
            DistanceUnit: resultsResponse.raceDetails.distanceUnit,
            exoticPools: resultsResponse.raceDetails.exoticPools,
            finishOrder: resultsResponse.raceDetails.finishOrder,
            Gait: resultsResponse.raceDetails.gait,
            Grade: resultsResponse.raceDetails.grade,
            MaxClaimPrice: resultsResponse.raceDetails.maxClaimPrice,
            placePools: resultsResponse.raceDetails.placePools,
            PostTime: resultsResponse.raceDetails.postTime,
            Purse: resultsResponse.raceDetails.purse,
            RaceClassification: resultsResponse.raceDetails.raceClassification,
            RaceConditions: resultsResponse.raceDetails.raceConditions,
            raceDate: resultsResponse.raceDetails.raceDate,
            RaceName: resultsResponse.raceDetails.raceName,
            RaceNumber: resultsResponse.raceDetails.raceNumber,
            RaceType: resultsResponse.raceDetails.raceType,
            RestrictionType: resultsResponse.raceDetails.restrictionType,
            SexRestriction: resultsResponse.raceDetails.sexRestriction,
            sexRestrictionCode: resultsResponse.raceDetails.sexRestrictionCode,
            showPools: resultsResponse.raceDetails.showPools,
            Surface: resultsResponse.raceDetails.surface,
            SurfaceLabel: resultsResponse.raceDetails.surfaceLabel,
            TrackConditionLabel: resultsResponse.raceDetails.trackConditionLabel,
            trackCondition: resultsResponse.raceDetails.trackCondition,
            trackConditionCode: resultsResponse.raceDetails.trackConditionCode,
            winPools: resultsResponse.raceDetails.winPools,
            wpsMatrix: resultsResponse.raceDetails.wpsMatrix
        }
    }
}
