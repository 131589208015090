<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>

<div class="jockey-details" *ngIf="trainerJockeyDetails" [class.is-inline]="isInline">
    <div class="details-col">
        <h1>{{ trainerJockeyDetails.primaryName }}</h1>
        <div class="label-info-group" *ngIf="trainerJockeyDetails.primaryDurationEnd">
            <div class="label-info">
                <div class="info">
                    {{trainerJockeyDetails.primaryDurationStart}}-{{trainerJockeyDetails.primaryDurationEnd}}
                </div>
            </div>
        </div>
        <div class="label-info-group" *ngIf="!trainerJockeyDetails.primaryDurationEnd">
            <div class="label-info">
                <div class="info">
                    {{trainerJockeyDetails.primaryDurationStart}}
                </div>
            </div>
        </div>
        <cdux-favorite-toggle-person
            *ngIf="(currentStatsReport === STATS_TYPE_TRAINER && isTrainerStable) || (currentStatsReport === STATS_TYPE_JOCKEY && isJockeyStable)"
            [favoriteData]="favoriteData"
            displayFavoritesLabel="true"
            rightDisplay="true"
        ></cdux-favorite-toggle-person>
    </div>
    <div class="detail-info-full">
        <div class="info-container">
            <div class="table-container">
                <table border="0" cellspacing="0" cellpadding="0" *ngIf="trainerJockeyDetails.keyStats?.length > 0">
                    <thead>
                        <tr>
                            <td>key stats</td>
                            <td class="align-right">str</td>
                            <td class="align-right">w%</td>
                            <td class="align-right">$2 avg</td>
                            <td class="align-right">wn</td>
                            <td class="align-right">pl</td>
                            <td class="align-right">sh</td>
                            <td class="align-right">earnings</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-kStats [ngForOf]="displayedKeyStats">
                            <tr>
                                <td [ngClass]="kStats.roi >= 2 ? 'green-highlight' : ''">{{ kStats.category }}</td>
                                <td class="align-right">{{ kStats.starts }}</td>
                                <td class="align-right">{{ kStats.winPercentage }}</td>
                                <td class="align-right" [ngClass]="kStats.roi >= 2 ? 'green-highlight' : ''">{{
                                    kStats.roi | number:'1.2-2' }}</td>
                                <td class="align-right">{{ kStats.win }}</td>
                                <td class="align-right">{{ kStats.place }}</td>
                                <td class="align-right">{{ kStats.show }}</td>
                                <td class="align-right" *cduxMediaToggle="'desk-to-phone'">{{ kStats.earningsLongFormat
                                    }}</td>
                                <td class="align-right" *cduxMediaToggle="'phone'">{{ kStats.earningsShortFormat }}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>

            <!-- WITH TRAINER/JOCKEY -->
            <div class="table-container" *ngIf="trainerJockeyDetails.comboKeyStats?.length > 0">
                <table border="0" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <td>with {{ currentStatsReport === STATS_TYPE_TRAINER ? STATS_TYPE_JOCKEY :
                                STATS_TYPE_TRAINER }}</td>
                            <td class="align-right">str</td>
                            <td class="align-right">w%</td>
                            <td class="align-right">$2 avg</td>
                            <td class="align-right">wn</td>
                            <td class="align-right">pl</td>
                            <td class="align-right">sh</td>
                            <td class="align-right">earnings</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-cmbStats [ngForOf]="displayedComboStats">
                            <tr>
                                <td [ngClass]="cmbStats.roi >= 2 ? 'green-highlight' : ''">{{ cmbStats.category }}</td>
                                <td class="align-right">{{ cmbStats.starts }}</td>
                                <td class="align-right">{{ cmbStats.winPercentage }}</td>
                                <td class="align-right" [ngClass]="cmbStats.roi >= 2 ? 'green-highlight' : ''">{{
                                    cmbStats.roi | number:'1.2-2'}}</td>
                                <td class="align-right">{{ cmbStats.win }}</td>
                                <td class="align-right">{{ cmbStats.place }}</td>
                                <td class="align-right">{{ cmbStats.show }}</td>
                                <td class="align-right" *cduxMediaToggle="'desk-to-phone'">{{
                                    cmbStats.earningsLongFormat }}</td>
                                <td class="align-right" *cduxMediaToggle="'phone'">{{ cmbStats.earningsShortFormat }}
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- ALL STATS -->
        <div class="stats-container">
            <div class="table-container">
                <table border="0" cellspacing="0" cellpadding="0" *ngIf="allStatsLen > 0">
                    <thead>
                        <tr>
                            <td>all stats</td>
                            <td class="align-right">str</td>
                            <td class="align-right">w%</td>
                            <td class="align-right">$2 avg</td>
                            <td class="align-right">wn</td>
                            <td class="align-right">pl</td>
                            <td class="align-right">sh</td>
                            <td class="align-right">earnings</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-template ngFor let-aStats [ngForOf]="displayedAllStats">
                            <tr>
                                <td [ngClass]="aStats.roi >= 2 ? 'green-highlight' : ''">{{ aStats.category }}</td>
                                <td class="align-right">{{ aStats.starts }}</td>
                                <td class="align-right">{{ aStats.winPercentage }}</td>
                                <td class="align-right" [ngClass]="aStats.roi >= 2 ? 'green-highlight' : ''">{{ aStats.roi | number:'1.2-2'
                                    }}
                                </td>
                                <td class="align-right">{{ aStats.win }}</td>
                                <td class="align-right">{{ aStats.place }}</td>
                                <td class="align-right">{{ aStats.show }}</td>
                                <td class="align-right" *cduxMediaToggle="'desk-to-phone'">{{ aStats.earningsLongFormat
                                    }}
                                </td>
                                <td class="align-right" *cduxMediaToggle="'phone'">{{ aStats.earningsShortFormat }}</td>
                            </tr>
                        </ng-template>
                        <tr *ngIf="allStatsLen > 5" (click)="toggleAllStats()">
                            <td><a>SEE {{ allStatsToggleLabel }}</a> </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- TODO: I don't see anything in the template for this -->
<div class="jockey-details" *ngIf="error" [class.is-inline]="isInline">
    <div class="details-col">
        <h1>{{ this.errorMessage }} </h1>
    </div>
</div>
