<cdux-dropdown class="bet-dropdown-selector subtype bet-subtype-dropdown"
               name="bet-subtype"
               [(ngModel)]="value"
               [forceState]="onSmallGlass ? smallGlassState : 0">

    <div dropdown-header *ngIf="!suppressHeader">
        <div class="menu-tab">
            <h2 class="title-label">
                    <span id="bet-nav-selected-subtype"
                          *ngIf="value">
                        {{ value }}
                    </span>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>

    <div dropdown-list>
        <cdux-dropdown-option *ngFor="let subType of list; trackBy:trackByIndex; let $index = index;"
                              [value]="subType">
                <span class="option-label" id="bet-nav-subtype-option-{{$index + 1}}">
                    {{ subType }} <span class="flag" *ngIf="isNew(subType)">NEW</span>
                </span>
        </cdux-dropdown-option>

        <cdux-wager-type-info *ngIf="learnMoreEnabled" [infoType]="enumWagerInfoType.WAGER_MODIFIERS"></cdux-wager-type-info>
    </div>

</cdux-dropdown>
