import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { SidebarService } from "../../../sidebar/sidebar.service";
import { finalize, take, takeUntil } from "rxjs/operators";
import { DeviceTypeEnum, IMfaResponse, JwtSessionService, MfaDataService, MfaResponseEnum } from '@cdux/ng-common';
import { MfaBusinessService } from '../services/mfa.business.service';
import { ToastService } from '@cdux/ng-fragments';
import { MfaErrorMessageEnum } from 'app/security/login/enums/mfa-error-message.enum';

@Component({
    selector: 'cdux-mfa-toggle',
    templateUrl: './mfa-toggle.component.html',
    styleUrls: ['./mfa-toggle.component.scss']
})
export class MfaToggleComponent implements OnInit, OnDestroy  {

    @Input() public userMfaPreferences: IMfaResponse;
    @Output() onMfaToggleUpdate = new EventEmitter();
    public deviceType: string;
    public deviceVal: string;
    public formattedPhoneNum: string;
    public isMfaEnabled: boolean;
    public submitting: boolean = false;
    public deviceTypeEnum = DeviceTypeEnum;
    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _sidebarService: SidebarService,
        private _mfaDataService: MfaDataService,
        private _mfaBusinessService: MfaBusinessService,
        private _toastService: ToastService,
        private _sessionService: JwtSessionService
    ) {}

    ngOnInit() {
        this._sidebarService.onHeaderButtonClicked.pipe(
            takeUntil(this._destroyed)
        ).subscribe((event) => {
            this._sidebarService.goBack();
        });

        if(this.userMfaPreferences && this.userMfaPreferences.devices.length > 0) {
            this.deviceType = this.userMfaPreferences.devices[0].deviceType;
            this.deviceVal = this.userMfaPreferences.devices[0].device;

            if(this.userMfaPreferences.devices[0].deviceType === this.deviceTypeEnum.SMS) {
                this.formattedPhoneNum = this._mfaBusinessService.formatPhoneNum(this.userMfaPreferences.devices[0].device)
            }

            this.isMfaEnabled = this.userMfaPreferences.mfaEnabled;
        }
    }

    ngOnDestroy() {
        this._destroyed.next(true);
        this._destroyed.complete();
    }

    public updateUserMfa(shouldEmitt: boolean) {
        this.submitting = true;
        this._mfaDataService.submitMfaUserInfo(false, this.deviceVal, this.deviceType, 0).pipe(
            take(1),
            finalize(() => {
                this.submitting = false;
            })
        ).subscribe((response: IMfaResponse) => {
            if(response.status === MfaResponseEnum.SUCCESS){
                this.isMfaEnabled = false;
                this._mfaBusinessService.removeMfaRmdLocalStorage(this._sessionService.getUserInfo().username);
                if(shouldEmitt){
                    this.onMfaToggleUpdate.emit();
                }
            } else {
                this._toastService.cduxWarning(MfaErrorMessageEnum.TOGGLE_ERROR);
            }
        });
    }

}
