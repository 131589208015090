import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'formatProbable'
})
export class FormatProbablePipe implements PipeTransform {

    /**
     * Format the given dollar amount for display.
     *
     * >= 10000, round down to nearest .1K, e.g. 10.1K for 10100
     * <10, display 2 decimal, round down to nearest 0.01
     * >=10 and <10000, display whole number, round down to nearest 1, formatted with commas.
     *
     * @param {number|string} value
     * @returns {string}
     */
    public transform(value: number): string {

        const decimalPipe = new DecimalPipe('en-US');

        if (value < 10) {
            return '$' + (Math.floor(value * 100) / 100).toFixed(2);
        }

        if (value < 10000) {
            return '$' + decimalPipe.transform(Math.floor(value * 100) / 100, '1.0-0');
        }

        if (value >= 10000) {
            return '$' + (Math.floor(value / 100) / 10).toFixed(1) + 'K';
        }

        return '$' + Math.floor(value).toFixed(0);
    }

}
