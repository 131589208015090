<div id="{{ 'track-changes-' + track?.BrisCode.toUpperCase() }}" class="program-scratches-changes-container">
    <div class="program-scratches-changes-header">
        <div class="track-info">{{ trackConditions }}</div>
        <div class="last-update">LAST UPDATED: {{ lastUpdate | date :'mediumTime'}}</div>
    </div>
    <ng-container *ngTemplateOutlet="changeRow; context:{ change: currentRace }"></ng-container>
    <div class="all-races-header" (click)="toggleAllRaces()">
        <div class="all-races-title">ALL RACES</div>
        <div class="view-close-btn smallest" [class.active]="showAllRaces">{{ showAllRaces ? 'HIDE' : 'VIEW ALL' }}</div>
    </div>
    <ng-container *ngIf="showAllRaces">
        <ng-container *ngFor="let change of changesList">
            <div class="race-number-row">
                RACE {{ change?.raceNumber }}
            </div>
            <ng-container *ngTemplateOutlet="changeRow; context:{ change: change }"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template let-change="change" #changeRow>
    <div id="{{ 'track-changes-race-' + change?.raceNumber + '-entry-' + entry?.programNumber }}" class="content-row" *ngFor="let entry of change?.entries">
        <div class="runner-info">
            <div class="saddle-cloth" [ngClass]="getSaddleClothTrackClass(track.TrackType, entry.programNumber)">
                {{ entry.programNumber }}</div>
            <div class="horse-name">{{ entry?.horseName }}</div>
        </div>
        <div class="scratch-reason">
            <span>{{ entry?.changes?.label }}</span>
            <ng-container *ngIf="entry?.changes?.reason"><br><span>Reason: {{ entry?.changes?.reason }}</span>
            </ng-container>
            <ng-container *ngIf="entry?.changes?.description"><span>{{ entry?.changes?.description }}</span>
            </ng-container>
        </div>
    </div>
    <div class="content-row" *ngIf="change?.entries.length === 0">
        <div class="runner-info">
            &nbsp;
        </div>
        <div class="scratch-reason">
            NONE
        </div>
    </div>
</ng-template>
