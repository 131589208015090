import { NgModule } from '@angular/core';
import { SharedWageringViewsModule } from '../shared/wagering-views/wagering-views.module';
import { CompactModule } from '../compact/compact.module';
import { MobileViewComponent } from './mobile-view.component';
import { BetSlipModule } from '../../shared/bet-slip/bet-slip.module';
import { BetsModule } from '../../shared/bets/bets.module';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { BetPadSharedModule } from '../../shared/betpad/betpad-shared.module';
import { ProgramSharedModule } from '../../shared/program/program-shared.module';
import { VideoModule } from '../video/video.module';

@NgModule({
    exports: [
        MobileViewComponent,
    ],
    declarations: [
        MobileViewComponent,
    ],
    imports: [
        SharedWageringViewsModule,
        CompactModule,
        BetSlipModule,
        BetsModule,
        PortalModule,
        CommonModule,
        BetPadSharedModule,
        ProgramSharedModule,
        VideoModule
    ]
})
export class MobileViewModule {}
