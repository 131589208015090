export enum enumProgramViews {
    TIPS = 'tips',
    BASIC = 'basic',
    CLASS = 'class',
    PACE  = 'pace',
    SUMMARY = 'summary',
    SPEED = 'speed',
    STATS = 'stats',
    VIDEO = 'video',
    BETPAD = 'betpad',
    TVBETPAD = 'tvbetpad'
}
