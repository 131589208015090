<div
  [id]="'fave-toggle-'+id"
  *ngIf="isEnabled && favoriteData && currentState === TOGGLE_STATE.OFF"
  class="favorite-toggle"
  [class.is-favorite]="currentState === TOGGLE_STATE.ON"
  [class.right-display]="rightDisplay"
  (click)="toggleFavorite()">
  <cdux-my-fav
    [id]="'icon-fav-badge-'+id"
    [isFavorite]="currentState === TOGGLE_STATE.ON">
  </cdux-my-fav>
  <span [id]="'fave-toggle-text-'+id" *ngIf="displayFavoritesLabel">{{ currentState }} Stable</span>
</div>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismiss()"
  (modalInitialized)="storeOverlay($event)">
</cdux-simple-modal>

<!-- TODO: make this a component reusable in favorite-toggle-*.component.ts -->
<ng-template #confirmationModal>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismiss()"></i>
    <!-- TODO: don't use "runner", keep type-agnostic -->
    <h2>Add <span id="favorite-add-modal-description" class="runner">{{ name }}</span> to stable</h2>
    <ng-container *ngIf="replays">
      <textarea id="favorite-add-modal-note"
            cols="50" [maxlength]="COUNT_MAX" name="replayNote"
            placeholder="ADD A NOTE..." rows="13"
            [(ngModel)]="replayNote"
      ></textarea>
    </ng-container>
    <ng-container *ngIf="!replays">
      <textarea id="favorite-add-modal-note"
            cols="50" [maxlength]="COUNT_MAX" name="note"
            placeholder="ADD A NOTE..." rows="13"
            [(ngModel)]="note"
      ></textarea>
    </ng-container>

    <div class="char-count"></div>
    <div class="btn-add" (click)="confirm()">Add</div>
  </div>
</ng-template>
