import { Component, OnInit } from '@angular/core';
import { CduxAbstractSidebarComponent } from '../../../../sidebar/cdux-sidebar-component.class';
import {
    ISidebarHeaderComponent,
    ISidebarHeaderProperties
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';

@Component({
  selector: 'cdux-ezbank-method-header',
  templateUrl: './ezbank-method-header.component.html',
  styleUrls: ['./ezbank-method-header.component.scss']
})
export class FundingEZBankMethodHeaderComponent extends CduxAbstractSidebarComponent implements OnInit {

    public static getSidebarComponent(options: ISidebarHeaderProperties = {}): ISidebarHeaderComponent {
        return {
            component: FundingEZBankMethodHeaderComponent,
            properties: options
        };
    }

    public setProperties() { /* empty */ }

    ngOnInit() { /* empty */ }
}
