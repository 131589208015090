import { Directive, HostBinding, Input } from '@angular/core';
import { DisplayModeEnum } from '../enums';

@Directive({
    selector: '[cduxDisplayModes]',
})
export class CduxDisplayModesDirective {
    private _displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;
    @Input() public set displayMode(v: DisplayModeEnum) {
        this.useLargeMode = v === DisplayModeEnum.LARGE;
        this.useCompactMode = v === DisplayModeEnum.COMPACT;
        this.useMobileMode = v === DisplayModeEnum.MOBILE;
        this._displayMode = v;
    }
    public get displayMode(): DisplayModeEnum {
        return this._displayMode;
    }
    @HostBinding('class.large') public useLargeMode: boolean = true;
    @HostBinding('class.compact') public useCompactMode: boolean = false;
    @HostBinding('class.mobile') public useMobileMode: boolean = false;
}
