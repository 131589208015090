import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Bet, ISelectedBetAmount } from '@cdux/ng-common';
import { CduxStorageService } from '@cdux/ng-platform/web';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class BetSlipBusinessService {

    /**
     * @deprecated
     */
    public onBetChange: Subject<Bet> = new Subject<Bet>();
    /**
     * @deprecated
     * This should eventually be replaced by a private property.
     */
    public onBetChangeReplay: ReplaySubject<Bet> = new ReplaySubject<Bet>(1);
    /**
     * @deprecated
     */
    public onBetReset: Subject<boolean> = new Subject<boolean>();

    private _currentBet: Bet = new Bet();
    private _betsToAdd: Bet[] = [];

    // Getters
    /**
     * @deprecated
     */
    get currentBet() {
        return this._currentBet;
    }

    public set betsToAdd(bet: Bet[]) {
        this._betsToAdd = bet;
    }

    public get betsToAdd(): Bet[] {
        return this._betsToAdd;
    }

    // Setters
    /**
     * @deprecated
     * @param value
     */
    set currentBet(value) {
        this._currentBet = value;
        this.onBetChange.next(this._currentBet);
        this.onBetChangeReplay.next(this._currentBet);
    }

    constructor (private _storageService: CduxStorageService) { }

    public resetBet(): void {
        const bet = Bet.fromBetlike(this._currentBet);
        bet.id = null;
        bet.runners = [];
        bet.betCreatedTimestamp = null;
        bet.betShare = false;
        bet.shares = null;
        bet.cost = null;
        this.currentBet = bet;
        this.onBetReset.next(true);
    }

    public editBet(bet: Bet) {
        this.currentBet = bet;
    }

    public async storeBet(bet: Bet): Promise<any> {
        return this._storageService.store(bet);
    }

    public async resumeStoredBet(betId: string): Promise<Bet> {
        const query = { db: Bet.DB, version: Bet.VERSION, _id: betId };
        return this._storageService.fetch(query).then((bet: any) => {
            if (bet && bet._id && bet._id === betId) {
                this.editBet(Bet.fromBetlike(bet));
                this._storageService.destroy(query);
                return bet;
            } else {
                return null;
            }
        });
    }

    public watchBetsToEdit(): Observable<Bet> {
        return this.onBetChangeReplay
            .pipe(
                // Since being able to edit the bet from anywhere was abused last time, I'm adding this here
                // in order distinguish when a new bet is actually being edited, instead of just taking whatever
                // anyone manipulates the current bet to be.
                distinctUntilKeyChanged('id'),
            );
    }

    /**
     * Determine whether the bets should be shrink.
     *
     * @returns {boolean}
     */
    public shouldShrink(bet: Bet): boolean {
        const copyBet = this._betsToAdd.find((copiedbet) => copiedbet.id  === bet.id);
        if (!!copyBet) {
            this._betsToAdd.splice(this._betsToAdd.indexOf(copyBet), 1)
            return true;
        }
        return false;

    }

    public findSelectedAmount(allowedAmounts: ISelectedBetAmount[], amount: ISelectedBetAmount): number {
        if (!!allowedAmounts && !!amount) {
            return allowedAmounts.findIndex((item) => {
                if (!!item) {
                    return item.type === amount.type && item.value === amount.value;
                }
                return false
            });
        }
        return -1;
    }

    /**
     * Remove saved bets that was from yesterday or beyond
     *
     * @param CduxStorageService cduxStorageService
     * @param {any[]} bets
     * @param Date date
     * @returns {Bet[]}
     * @private
     */
    public clearPrevDaysSavedBets(cduxStorageService: CduxStorageService, bets: Bet[], date: Date): Bet[] {
        const evalDateMoment = moment(date, 'YYYY-MM-DD');
        const todaySavedBets: Bet[] = [];
        bets.forEach(value => {
            if (moment(value.betCreatedTimestamp).isBefore(evalDateMoment, 'day')) {
                cduxStorageService.destroy(value);
            } else {
                todaySavedBets.push(value);
            }
        });
        return todaySavedBets;
    }
}
