import { Injectable } from '@angular/core';
import {
    BasicBetType,
    enumTrackType,
    MultiRaceExoticBetType,
    TracksDataService,
    enumBetModifier,
    enumBetSubtype,
    IBetNavObject,
    Bet,
    Entry,
    ISelectedEntry
} from '@cdux/ng-common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BetPadBetNavBusinessService {

    // TODO: remove this helper method
    public static mapModifierToBetSubtype(betModifier: enumBetModifier): enumBetSubtype {
        switch (betModifier) {
            case enumBetModifier.STRAIGHT:
                return enumBetSubtype.STRAIGHT;
            case enumBetModifier.BOX:
                return enumBetSubtype.BOX;
            case enumBetModifier.KEY:
                return enumBetSubtype.KEY;
            case enumBetModifier.KEY_BOX:
                return enumBetSubtype.KEYBOX;
            case enumBetModifier.POWER_BOX:
                return enumBetSubtype.POWERBOX;
            case enumBetModifier.WHEEL:
                return enumBetSubtype.WHEEL;
            default:
                return enumBetSubtype.STRAIGHT;
        }
    }

    // TODO: remove this helper method
    public static mapBetSubtypeToModifier(betModifierKey: enumBetSubtype): enumBetModifier {
        switch (betModifierKey) {
            case enumBetSubtype.STRAIGHT:
                return enumBetModifier.STRAIGHT;
            case enumBetSubtype.BOX:
                return enumBetModifier.BOX;
            case enumBetSubtype.KEYBOX:
                return enumBetModifier.KEY_BOX;
            case enumBetSubtype.POWERBOX:
                return enumBetModifier.POWER_BOX;
            case enumBetSubtype.KEY:
                return enumBetModifier.KEY;
            case enumBetSubtype.WHEEL:
                return enumBetModifier.WHEEL;
        }
    }

    public static  mapBetNav(bet: Bet, betNav: IBetNavObject): Bet {
        if (!!betNav.type) {
            bet.poolType = {
                Code: betNav.type.code,
                Name: betNav.type.poolType.displayName,
                Legs: betNav.type.poolType.selectionCount.toString(),
                PriceMultiplier: betNav.type.poolType.priceMultiplier.toString(),
                MultipleRace: betNav.type.poolType.raceLegs > 1,
                QuinellaPricing: null,
                ShortName: betNav.type.code,
                DisplayName: betNav.type.poolType.displayName
            };
            bet.betSubtype = BetPadBetNavBusinessService.mapModifierToBetSubtype(betNav.modifier);
            bet.amount = betNav.amount;
            bet.allowedAmounts = betNav.type.betAmounts;
            bet.raceNumbers = (<MultiRaceExoticBetType> betNav.type).legRaceNumbers ||
                (bet.race ? [ bet.race.race ] : null);
        }
        return bet;
    }

    /**
     * Make sure that we have empty arrays for all legs so the bet validation and runner list behave correctly.
     * Taking inspiration from BetPadProgramComponent::_updateEntrySelections()
     *
     * DE13507 https://rally1.rallydev.com/#/44248417630d/detail/defect/315320837020
     * DE13520 https://rally1.rallydev.com/#/44248417630d/detail/defect/315631938484
     *
     * @param selectedEntries
     * @param betNav
     */
    public static padISelectedEntryArrays(selectedEntries: ISelectedEntry[][], betNav: IBetNavObject): ISelectedEntry[][] {
        // Copy the entries to avoid mutating the wager state betting interests
        const currentEntries = !!selectedEntries ? JSON.parse(JSON.stringify(selectedEntries)) : [];

        let legCount: number = betNav.type.poolType.selectionCount;
        const modifier: enumBetModifier = !!betNav && !!betNav.modifier ? betNav.modifier : null;
        switch (modifier) {
            case enumBetModifier.KEY_BOX:
                legCount = 2;
                break;
            case enumBetModifier.BOX:
                legCount = 1;
                break;
        }

        // Pad the 2-D entries array with empty arrays for each leg. It's possible that a leg
        // entry array is null/empty, so a having a legCount equal to the number of entries arrays
        // won't tell you for certain that there aren't entries to pad.
        for (let i = 0; i < legCount; i++) {
            if (!Array.isArray(currentEntries[i])) {
                // Just because currentEntries[i] is undefined, that doesn't necessarily mean you have
                // tried to use an index out of bounds. So, instead of pushing([]), just assign the
                // position to an empty array.
                currentEntries[i] = [];
            }
        }

        if (modifier === enumBetModifier.KEY) {
            // Copy WITH entry selections (2nd leg, i.e. index 1) to all remaining leg arrays
            for (let l = currentEntries.length; l > 2; l--) {
                currentEntries[l - 1] = currentEntries[1];
            }
        }

        return currentEntries;
    }

    public static mapISelectedEntryToEntry(bet: Bet, selectedEntries: ISelectedEntry[][]): Bet {
        if (!!selectedEntries) {
            // reset the currentBet's runners
            bet.runners = [];
            selectedEntries.forEach((leg, index) => {
                if (!!leg) {
                    if (!bet.runners[index]) {
                        // initialize this leg if necessary
                        bet.runners[index] = [];
                    }
                    leg.forEach((entry) => {
                        if (!!entry) {
                            // shoehorn our selectedEntry into the bet's runners list
                            bet.runners[index].push(new Entry(
                                {
                                    ProgramNumber: entry.ProgramNumber,
                                    ProgramNumberCoupled: entry.BettingInterest.toString(),
                                    '_firstFieldProgram': entry.BettingInterest.toString(),
                                    FirstFieldProgramNumber: entry.BettingInterest.toString()
                                }
                                )
                            );
                        }
                    })
                }
            });
        }
        return bet;
    }

    constructor(
        private _tracksDataService: TracksDataService
    ) {
        // Empty
    }

    /**
     * Retrieves bet type models for a given today's race.
     * @param {string} brisCode
     * @param {enumTrackType} trackType
     * @param {number} raceNum
     * @param {boolean} poll
     * @returns {Observable<(BasicBetType | MultiRaceExoticBetType)[]>}
     */
    public getTodaysRaceBetTypes(brisCode: string, trackType: enumTrackType, raceNum: number, poll: boolean = false): Observable<(BasicBetType | MultiRaceExoticBetType)[]> {
        return this._tracksDataService.todaysRaceBetTypes(brisCode, trackType, raceNum, poll);
    }

}
