import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take, distinctUntilChanged, takeUntil, tap, first } from 'rxjs/operators';

import { CduxRequestError } from '@cdux/ng-core';
import { EventClickType, IAccountBalance, JwtSessionService } from '@cdux/ng-common';

import { ToteStatusService } from 'app/shared/tote-status/tote-status.service';

import { FundingService } from '../../funding/shared/services/funding.service';
import { FundingDepositOptionsComponent } from '../../funding/components/deposit-options/deposit-options.component';
import { EventTrackingService } from '../../event-tracking/services/event-tracking.service';
import { AbstractMenuItemComponent } from './abstract-menu-item.component';
import { SidebarService } from '../../sidebar/sidebar.service';
import { ISidebarPortalComponent, ISidebarHeaderDefinition } from '../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../enums/menu-items.enum';
import { DepositMenuItemService } from '../deposit-menu-item.service';

@Component({
    selector: 'cdux-deposit-menu-item',
    templateUrl: './deposit-menu-item.component.html',
    styleUrls: ['./deposit-menu-item.component.scss']
})
export class DepositMenuItemComponent extends AbstractMenuItemComponent implements OnInit, OnDestroy {
    /**
     * Used for determining when an external call is made to open the funding in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget = MenuItemsEnum.FUNDING;

    /**
     * The event to be communicated.
     *
     * @type {EventClickType}
     */
    protected clickEvent = EventClickType.MAIN_NAV_MENU_DEPOSIT;

    /**
     * The balance to be listed.
     */
    public balance = 0;

    private _destroy: Subject<undefined> = new Subject<undefined>();

    constructor(
        localEventTrackingService: EventTrackingService,
        protected _sidebarService: SidebarService,
        private _depositMenuItemService: DepositMenuItemService,
        private _fundingService: FundingService,
        private _sessionService: JwtSessionService,
        private _toteStatusService: ToteStatusService,
    ) {
        super(_sidebarService, localEventTrackingService);
    }

    ngOnInit() {
        super.ngOnInit();
        this._subscriptions.push(
            this._fundingService.balanceUpdated.subscribe(val => {
                // Keep the stored balance up to date
                this._depositMenuItemService.initialBalance = val;
                this.balance = val;
            })
        );

        /*
         DE16008 & DE15991 & DE15849
         There is currently a defect where we are unloading and reloading the entire layout when navigating
         to and from the Program. This results in this reinitializing and calling a new balance update.
         By defering to a service, we're negating the reloading of this component. This is a Derby fix.
         It is also tech debt.

         The real fix is to stop the reloading of the layout, and also no longer need to make this initial balance call.
         */
        if (typeof this._depositMenuItemService.initialBalance === 'undefined') {
            this._fundingService.balanceUpdated
                .pipe(
                    take(1),
                    // Just in case this subject doesn't fire, e.g. Tote is down, clean up this subscription on destroy
                    // to prevent a memory leak
                    takeUntil(this._destroy)
                )
                .subscribe(val => this._depositMenuItemService.initialBalance = val);

            this._updateBalance();
        } else {
            this.balance = this._depositMenuItemService.initialBalance;
        }

        this._sessionService.onAuthenticationChange.pipe(
            distinctUntilChanged(),
            takeUntil(this._destroy)
        ).subscribe((isLoggedIn) => {
            // If the user is logged out for any reason, reset the initial balance so
            // that it doesn't show for a different user that logs in, and so that
            // it doesn't persist after jwt expiration for the same user.
            if (!isLoggedIn) {
                this._depositMenuItemService.initialBalance = undefined;
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this._sidebarService.headerMessage.next('');
        this._destroy.next();
        this._destroy.complete();
    }

    /**
     * Actions to perform on activation.
     */
    public activate(): void {
        const depositOptionsComponent: ISidebarPortalComponent = FundingDepositOptionsComponent.getSidebarComponent();
        const depositOptionsHeader: ISidebarHeaderDefinition = FundingDepositOptionsComponent.getHeaderComponent();
        this._sidebarService.loadComponent(depositOptionsComponent, depositOptionsHeader, {
            clearHistory: true
        });
        this._updateBalance();
    }

    /**
     * Actions to perform on deactivation.
     */
    public deactivate(): void {
        this._sidebarService.close(true);
        this._sidebarService.headerMessage.next('');
    }

    /**
     * Updates the balance, using the funding service.
     */
    private _updateBalance() {
        this._fundingService.updateAccountBalance().pipe(
            takeUntil(this._destroy),
        ).subscribe(
            (balanceData: IAccountBalance) => {
                this.balance = balanceData.Balance;
                this._sidebarService.headerMessage.next('');
            },
            (err: CduxRequestError) => {
                this.balance = 0;

                this._toteStatusService.toteStatus.pipe(
                    takeUntil(this._destroy),
                    first(),
                    ToteStatusService.isDown(),
                    tap(isDown => {
                        if (isDown) {
                            this._sidebarService.headerMessage.next(
                                'Balance requests are temporarily unavailable until our nightly maintenance is complete. We apologize for the inconvenience.'
                            );
                        } else {
                            this._sidebarService.headerMessage.next(
                                'Balance requests are temporarily unavailable. Please try again later.'
                            );
                        }
                    }),
                ).subscribe()

            },
        );
    }
}
