import { Injectable, ElementRef } from '@angular/core';
import { IPath, ICoordinates } from '../../interfaces/path.interfaces';

@Injectable()
export class WaypointService {

    /**
     * This is a list of waypoints that are bound to a specific key.
     *
     * @type {Map<any, any>}
     * @private
     */
    private _waypoints: Map<string, HTMLElement> = new Map();

    /**
     * Registers a waypoint so that it can be the delivery point for an animation.
     *
     * @param {ElementRef} el
     * @param {string} key
     */
    public registerWaypoint(el: ElementRef, key: string) {
        if (el) {
            this._waypoints.set(key, el.nativeElement);
        }
    }

    /**
     * Derives an absolute path from the origin element to the destination element.
     *
     * @param {ElementRef} el
     * @param {string} key
     * @returns {IPath}
     */
    public getPath(el: ElementRef, key: string): IPath {
        const waypoint = this._waypoints.get(key);

        if (!!waypoint && el) {
            const origin = this._findAbsolutePosition(el.nativeElement);
            const destination = this._findAbsolutePosition(waypoint);
            return {
                origin,
                destination
            };

        } else {
            console.warn('Could not find a waypoint.');
        }
    }

    /**
     * Magic out the absolute position of an element.
     *
     * @param {HTMLElement} el
     * @returns {ICoordinates}
     * @private
     */
    private _findAbsolutePosition(el: HTMLElement): ICoordinates {
        return {
            x: el.getBoundingClientRect().left ,
            y: el.getBoundingClientRect().top
        };
    }
}
