import { ISummaryRaceStats, ITrackBasic, RaceStatsDataService, ToteDataService } from "@cdux/ng-common";
import { CduxRxJSBuildingBlock } from "@cdux/ng-core";
import { BehaviorSubject, Observable, ReplaySubject, of } from "rxjs";
import { catchError, finalize, switchMap, take, takeUntil } from "rxjs/operators";

export class RaceStatsRequestHandler extends CduxRxJSBuildingBlock<any, ISummaryRaceStats> {
    
    protected _stream: Observable<ISummaryRaceStats>;

    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _pause: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private _toteDataService: ToteDataService,
        private _raceStatsDataService: RaceStatsDataService,
    ) {
        super();
        this._init();
    }

    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public pause() {
        this._pause.next(true);
    }

    public resume() {
        this._pause.next(false);
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }

    public _init() {
        this._stream = this._pause.pipe(
            switchMap(paused => paused ? of(null) : this._raceNavigationChanges.pipe(
                switchMap(track => this.getRaceStats(track, true).pipe(
                    catchError(error => {
                        return of(null);
                    })
                ))
            )),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ) as Observable<ISummaryRaceStats>;
    }

    private getRaceStats(basicTrack: ITrackBasic, poll: boolean = false): Observable<ISummaryRaceStats> {
        const trackType = basicTrack && basicTrack.TrackType || null;
        const brisCode = basicTrack && basicTrack.BrisCode || null;
        const raceNum = basicTrack && basicTrack.RaceNum || null;
        const raceDetailsObs = this._toteDataService.currentRaceDate(poll).pipe(
            switchMap(raceDate =>
                this._raceStatsDataService.getRaceStats( raceDate, brisCode, trackType, raceNum)
                ),
            );
        return raceDetailsObs.pipe(take(1));
    }
}