import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { tap, take } from 'rxjs/operators';

import {
    EventClickType,
    FeatureToggleDataService,
    FUNDING_OPERATIONS,
    FUND_ID,
    ICheckAccountInfo,
    IFundingOption,
    IWithdrawBalance,
    TranslateService,
} from '@cdux/ng-common';

import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { FundingCheckService } from 'app/shared/funding/shared/services/check.service';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { FullPageFundingConstants } from '../../full-page-funding/full-page-funding.constants';
import { IFundingComponentProperties } from '../methods/abstract-method.component';
import { FundingCheckMethodWithdrawComponent } from 'app/shared/funding/components/methods/check/check-method-withdraw.component';
import { FundingCreditCardMethodDepositFullscreenComponent } from 'app/shared/funding/components/methods/creditcard/creditcard-method-deposit-fullscreen.component';
import { FundingEZBankMethodFullPageFundingComponent } from 'app/shared/funding/components/methods/ezbank/ezbank-method-deposit-fullpage.component';
import { FundingEzmoneyMethodDepositFullPageComponent } from 'app/shared/funding/components/methods/ezmoney/ezmoney-method-deposit-fullpage.component';
import { FundingEzmoneyMethodWithdrawComponent } from 'app/shared/funding/components/methods/ezmoney/ezmoney-method-withdraw.component';
import { GreendotATRDepositFullPageComponent } from 'app/shared/funding/components/methods/greendot/greendot-atr-deposit-fullpage.component';
import { FundingMoneygramMethodDepositFullpageComponent } from 'app/shared/funding/components/methods/moneygram/moneygram-method-deposit-fullpage.component';
import { FundingPaynearmeMethodDepositFullPageComponent } from 'app/shared/funding/components/methods/paynearme/paynearme-method-deposit-fullpage.component';
import { FundingPaypalMethodDepositFullpageComponent } from 'app/shared/funding/components/methods/paypal/paypal-method-deposit-fullpage.component';
import { FundingPaypalMethodWithdrawComponent } from 'app/shared/funding/components/methods/paypal/paypal-method-withdraw.component';

export const FULL_PAGE_COMPONENTS_MAP = {
    [FUND_ID.PAYPAL_W] : FundingPaypalMethodWithdrawComponent,
    [FUND_ID.EZMONEY_W] : FundingEzmoneyMethodWithdrawComponent,
    [FUND_ID.CHECK_W] : FundingCheckMethodWithdrawComponent,
    [FUND_ID.EZMONEY] : FundingEzmoneyMethodDepositFullPageComponent,
    [FUND_ID.CREDITCARD] : FundingCreditCardMethodDepositFullscreenComponent,
    [FUND_ID.PAYPAL] : FundingPaypalMethodDepositFullpageComponent,
    [FUND_ID.MONEYGRAM] : FundingMoneygramMethodDepositFullpageComponent,
    [FUND_ID.PAYNEARME] : FundingPaynearmeMethodDepositFullPageComponent,
    [FUND_ID.GREENDOTATR] : GreendotATRDepositFullPageComponent,
    [FUND_ID.MAZOOMA] : FundingEZBankMethodFullPageFundingComponent,
}

export const FUNDING_CLICKEVENTS_MAP = {
    [FUND_ID.PAYPAL_W] : EventClickType.PAYPAL_WITHDRAW_TILE,
    [FUND_ID.EZMONEY_W] : EventClickType.EZ_MONEY_WITHDRAW_TILE,
    [FUND_ID.CHECK_W] : EventClickType.CHECK_WITHDRAW_TILE,
    [FUND_ID.EZMONEY] : EventClickType.EZ_MONEY_DEPOSIT_TILE,
    [FUND_ID.CREDITCARD] : EventClickType.CREDIT_CARD_DEPOSIT_TILE,
    [FUND_ID.PAYPAL] : EventClickType.PAYPAL_DEPOSIT_TILE,
    [FUND_ID.MONEYGRAM] : EventClickType.MONEYGRAM_DEPOSIT_TILE,
    [FUND_ID.PAYNEARME] : EventClickType.PAYNEARME_DEPOSIT_TILE,
    [FUND_ID.GREENDOTATR] : EventClickType.GREENDOT_DEPOSIT_TILE,
    [FUND_ID.MAZOOMA] : EventClickType.EZ_BANK_DEPOSIT_TILE,
}

@Component({
    selector: 'cdux-funding-options',
    templateUrl: './funding-options.component.html',
    styleUrls: ['./funding-options.component.scss']
})
export class FundingOptionsComponent implements OnInit {

    @Input()
    public displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.FULL_PAGE;

    @Input()
    public selectedMethodId: FUND_ID;

    @Input()
    public enabledIDs: FUND_ID[] = null;

    @Input()
    public isSmallGlass = false;

    private _selectedOperation;
    @Input() set selectedOperation(operation) {
        this._selectedOperation = operation;
        if (this._selectedOperation === FUNDING_OPERATIONS.WITHDRAW) {
            combineLatest([
                this._checkService.getAccountInfo(),
                this._fundingService.getWithdrawBalance(),
                this._fundingService.getWithdrawalOptOutList(),
            ]).pipe(
                take(1),
                tap(([acctInfo, withdrawBalance, optOutList]) => {
                    this.withdrawBalance = withdrawBalance;
                    this.accountInfo = acctInfo;
                    this.withdrawalOptOutOfferNames = optOutList;
                })
            ).subscribe(() => this.initialize());
        } else {
            this.initialize();
        }
    }

    get selectedOperation() {
        return this._selectedOperation
    }

    @Output()
    public selectedMethod: EventEmitter<ISidebarPortalComponent> = new EventEmitter<ISidebarPortalComponent>();

    @Output()
    public methodsLoaded: EventEmitter<IFundingOption[]> = new EventEmitter<IFundingOption[]>();

    @Output()
    public noMethod: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Make public for template consumption
    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    /**
   *   Current Active Funding Method
   *   Getter to pass value from service to template
   */
    public get activeFundingMethod(): FUND_ID {
        return (this.isSmallGlass) ? null : this._fundingService.activeFundingMethod;
    }

    /**
     *   Last Funding Amount
     *   Getter to pass value from service to template
     */
    public get lastFundingAmount(): number {
        return this._fundingService.lastFundingAmount;
    }

    /**
     *   Collection of Funding Options
     *   Getter to pass value from service to template
     */
    public fundingOptions: IFundingOption[] = [];

    // Whether we are returning from a Funding Option
    public return = false;

    // Withdraw balance including pending amounts
    public withdrawBalance: IWithdrawBalance;

    // Check info necessary for check withdraw display
    public accountInfo: ICheckAccountInfo;

    // Opt Out List
    public withdrawalOptOutOfferNames: string[] = null;

    private amexEnabled: boolean = false;

    private readonly _FEATURE_FLAG_AMEX: string = 'AMEX';

    constructor(
        private _sidebarService: SidebarService,
        private _fundingService: FundingService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _featureToggleService: FeatureToggleDataService,
        private _eventTrackingService: EventTrackingService,
        private _checkService: FundingCheckService,
        private _translateService: TranslateService,
        protected router: Router,
    ) {}

    public ngOnInit(): void {
        this.initialize();
    }

    public initialize(): void {
        this._fundingService.setEnabledFundingMethods(this.displayStyle);
        this.amexEnabled = this._featureToggleService.isFeatureToggleOn(this._FEATURE_FLAG_AMEX);

        if (!this.return) {
            this._fundingService.requestFundingMethodData(this._selectedOperation, this.enabledIDs, this.amexEnabled).pipe(
                take(1)
            ).subscribe((res: IFundingOption[]) => {
                this.fundingOptions = this._fundingService.fundingOptions;
                let method: IFundingOption;
                this.methodsLoaded.emit(res);
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                if (this.selectedMethodId) {
                    // The parent component has passed back a method ID to be set as active. This could be because the user deep
                    // linked to a specific method. If the method is in the list of methods we load it. Otherwise, on small glass,
                    // we show options. On large glass we load the first method in the list.
                    if (Array.isArray(res) && res.length) {
                        method = res.find(m => m.fundId === this.selectedMethodId)
                        if (!method) {
                            if (this.isSmallGlass) {
                                this.router.navigate([this._selectedOperation, FullPageFundingConstants.ROUTE_PARAM_DUMMY]);
                            } else {
                                method = res[0];
                            }
                        }
                    }
                    this.selectFundingMethod(method);
                } else if (
                    (this._selectedOperation === FUNDING_OPERATIONS.DEPOSIT && this._fundingService.activeFundingMethod) ||
                    (this._selectedOperation === FUNDING_OPERATIONS.WITHDRAW && this._fundingService.activeWithdrawalMethod)
                ) {
                    // We have a method chosen by default. This could be because of the previous method used to deposit or because
                    // the sidebar component had a method selected as a property.
                    const selectedFundId = this._selectedOperation === FUNDING_OPERATIONS.DEPOSIT ? this._fundingService.activeFundingMethod : this._fundingService.activeWithdrawalMethod;
                    method = res.find((v) => v.fundId === selectedFundId);

                    if (!method && !this.isSmallGlass && this.displayStyle !== enumFundingDisplayStyle.SIDEBAR && Array.isArray(res) && res.length) {
                        // We have no default method. Load the first in the list by default.
                        method = res[0];
                    } else if (method.locked && this.isSmallGlass) {
                        // We have a default method but it is locked. We clear the method on small glass
                        // so that we don't load a disabled method by default.
                        method = null;
                    }
                } else if (res.length === 1 || !this.isSmallGlass) {
                    // There is no previous method or method to select. Likely a new user.
                    // On large glass we choose the first method in the list as the default method.
                    method = res[0];
                }

                if (method && !method.accountInfo && this._selectedOperation === FUNDING_OPERATIONS.WITHDRAW) {
                    method = res.find((v) => v.fundId === FullPageFundingConstants.DEFAULT_WITHDRAWAL_METHOD);
                }

                if (method) {
                    this.selectFundingMethod(method);
                    if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) { return; }
                } else {
                    this.noMethod.emit(true);
                }

                /**
                 * this 'if' conditional checking is to prevent a JS console error to occur
                 * on BetPad when the underlying user has not made any deposit and click on
                 * the Deposit Tab. The error is like:
                 * Uncaught Error: ViewDestroyedError: Attempt to use a destroyed view: detectChanges
                 *                   at viewDestroyedError (core.js:20354)
                 *                   at Object.debugUpdateDirectives [as updateDirectives] (core.js:23810)
                 *                   ...
                 *                   at SafeSubscriber._next (deposit-options.component.ts:297)
                 * The error was pre-existing before fix on DE13587 and DE13586.
                 */
                // if (!this._changeDetectorRef['destroyed']) {
                //     this._changeDetectorRef.detectChanges();
                // }
            });
        }
        this._changeDetectorRef.detectChanges();
    }

    public setFundingComponentProperties(method: IFundingOption, replaceAccountInfo: boolean): IFundingComponentProperties {
        return {
            fundingMethodDetails: method,
            lastFundingAmount: this.lastFundingAmount,
            amexEnabled: this.amexEnabled,
            replaceAccountInfo: replaceAccountInfo,
            withdrawBalance: this.withdrawBalance,
            accountInfo: this.accountInfo
        }
    }

    public selectFundingMethod(method: IFundingOption, replaceAccountInfo: boolean = false) {
        const compProps = this.setFundingComponentProperties(method, replaceAccountInfo);
        this._eventTrackingService.logClickEvent(FUNDING_CLICKEVENTS_MAP[method.fundId]);
        this.selectedMethod.emit(FULL_PAGE_COMPONENTS_MAP[method.fundId].getComponent(compProps))
    }

    /**
     * Checks to see if we have added translations for this funding option
     */
     public canDisplayFundingOption(method: IFundingOption) {
        return this._translateService.hasLanguage(method.fundType);
    }

    /**
     * Retrieves the Specific Tile Icon to use for the Funding Method
     */
     public getIcon(key: string, method: IFundingOption) {
        if (method.fundId === FUND_ID.CREDITCARD) {
            if (method.accountInfo) {
                switch (method[ 'cardTypeID' ]) {
                    case 1:
                        return this._translateService.translate(`${key}-visa`, method.fundType);
                    case 2:
                        return this._translateService.translate(`${key}-mc`, method.fundType);
                    case 4:
                        return this._translateService.translate(`${key}-amex`, method.fundType);
                }
            } else if (this.amexEnabled) {
                key = 'tile-icon-plus-amex';
            }
        }
        return this._translateService.translate(key, method.fundType);
    }
}
