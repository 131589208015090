<div id="bet-total-error-bar" class="bet-total-error-bar" [class.is-showing] = "errorMessage">
    <div id="bet-total-error-left-close" class="btn close-button" (click)="onClose()">
        <i class="icon-bet-total-error__clear"></i>
    </div>
    <div id="bet-total-error-message" class="message-text"> {{ errorMessage }} </div>
    <div id="bet-total-error-dismiss-button" class="btn dismiss-button" (click)="onDismiss()">
        <span id="bet-total-error-dismiss-text"> {{ dismissButtonText }} </span>
    </div>
</div>




