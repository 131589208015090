import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { CduxMediaToggleService, ModalService } from '@cdux/ng-platform/web';
import { BasicBetType, enumFeatureToggle, FeatureToggleDataService, MultiRaceExoticBetType } from '@cdux/ng-common';
import { AbstractDropupSelectComponent } from '@cdux/ng-fragments';

import { enumWagerInfoType } from '../../../../bet-pad/shared/learn-more/wager-type-info.component';

@Component({
    selector: 'cdux-betpad-bet-type',
    templateUrl: './betpad-bet-type.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetPadBetTypeComponent extends AbstractDropupSelectComponent<(BasicBetType | MultiRaceExoticBetType), BetPadBetTypeComponent> {
    public enumWagerInfoType = enumWagerInfoType;

    public learnMoreEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BET_LEARN_MORE);

    /**
     * Header text for the dropup.
     * @type {string}
     */
    public dropupHeaderText = 'Bet Type';

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetector
     * @param {CduxMediaToggleService} _mediaQueryService
     * @param {ModalService} _modalService
     * @param {NgControl} _control
     */
    constructor(
        _changeDetector: ChangeDetectorRef,
        _mediaQueryService: CduxMediaToggleService,
        _modalService: ModalService,
        @Self() @Optional() _control: NgControl,
        private _featureToggleService: FeatureToggleDataService
    ) {
        super(
            _changeDetector,
            BetPadBetTypeComponent,
            _mediaQueryService,
            _modalService,
            _control
        );
    }

}
