import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
    OnDestroy,
    Input,
    Output,
    TemplateRef,
    EventEmitter,
    ViewChild,
    AfterViewInit
} from '@angular/core';
import {
    ITodaysRace,
    ITrackBasic,
    TracksDataService,
    enumTrackType,
    RaceDetails,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';

import { RaceDetailsRequestHandler } from '../../classes/race-details-request-handler.class';
import { FormatRaceTypeUtil } from 'app/shared/common/utils/FormatRaceTypeUtil';
import { DisplayModeEnum } from '../../../common/enums';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

@Component({
    selector: 'cdux-common-race-details',
    templateUrl: './common-race-details.component.html',
    styleUrls: ['./common-race-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonRaceDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

    /** INPUTS/OUTPUTS **/
    public DisplayModeEnum = DisplayModeEnum;
    @Input() public displayMode: DisplayModeEnum = DisplayModeEnum.LARGE;

    private _selectedRaceNav: ITrackBasic;

    @Input()
    public set selectedRaceNav(trackBasic: ITrackBasic) {
        this._selectedRaceNav = trackBasic;
        this._raceDetailsRequestHandler.updateRaceNavigation(trackBasic);
        this.isGreyhoundTrack = !!trackBasic && trackBasic.TrackType === enumTrackType.GREY;
        this._changeDetector.markForCheck();

    }

    public get selectedRaceNav(): ITrackBasic {
        return this._selectedRaceNav;
    }

    @Output() public updateRaceDetailsDrawer: EventEmitter<TemplateRef<any>> = new EventEmitter<TemplateRef<any>>();
    @Output() public toggleRaceDescription: EventEmitter<boolean> = new EventEmitter<boolean>();
    /** END INPUTS/OUTPUTS **/

    /** PUBLIC PROPERTIES **/
    public raceDetails: ITodaysRace;
    public isShowingDescription: boolean = false;
    public trackConditions: {[key: string]: string} = {};
    public surfaceLabel: string = '';
    public surfaceConditions: string;
    public surfaceConditionsFull: string;
    public weather: string;
    public isGreyhoundTrack: boolean;
    public raceType: string;
    public SYNTHETIC: string = 'Synthetic';
    public isNorthAmericanTrack: boolean;
    public readonly NOT_AVAILABLE: string = 'N/A';
    @ViewChild('raceDetailsDrawerContent') raceDetailsDrawerContent: TemplateRef<any>;
    /** END PUBLIC PROPERTIES **/

    /** CONTROLS **/
    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    /** END CONTROLS **/

    constructor(
        private _tracksDataService: TracksDataService,
        private _changeDetector: ChangeDetectorRef,
        private _eventTrackingService: EventTrackingService,
        private _mediaToggleService: CduxMediaToggleService,
        private _todaysRacesService: TodaysRacesBusinessService
    ) {
        this._raceDetailsRequestHandler =  new RaceDetailsRequestHandler(this._tracksDataService);
    }

    /** LIFECYCLE HOOKS **/
    ngOnInit() {
        combineLatest([
            this._raceDetailsRequestHandler.listen(),
            this._mediaToggleService.registerQuery('phone').pipe(distinctUntilChanged())
        ]).subscribe(([raceDetails, isSmallGlass]) => {
            // Hide the race description drawer if there aren't race conditions to display
            if (this.isShowingDescription && (!raceDetails || !raceDetails.raceConditions)) {
                this.toggleShowDescription();
            }

            this.raceDetails = raceDetails;
            this.isNorthAmericanTrack = (raceDetails?.country?.toLowerCase() === 'usa' || raceDetails?.country?.toLowerCase() === 'can');
            this.weather = raceDetails?.weather;
            this.surfaceLabel = raceDetails?.surfaceLabel;
            this.surfaceConditions = raceDetails?.surfaceCondition;
            this.surfaceConditionsFull = raceDetails?.surfaceConditionMap ?
                Object.keys(raceDetails.surfaceConditionMap)
                    .sort((a) => a === raceDetails.surfaceLabel ? -1 : 1)
                    .map((key) => key + ': ' + raceDetails.surfaceConditionMap[key])
                    .join(', ') : this.surfaceConditions;
            if (this.surfaceLabel === this.SYNTHETIC && this.isNorthAmericanTrack)  {
                this.surfaceConditionsFull = this.surfaceLabel;
            } else
            if (this.surfaceLabel === this.SYNTHETIC) {
                this.surfaceConditionsFull = this.surfaceLabel + ': ' + this.surfaceConditions;
            }
            if (this.surfaceLabel === this.SYNTHETIC || (this.surfaceConditions?.length > 8 && isSmallGlass)) {
                this.surfaceConditions = ''; // hide on small glass or synthetic surface
            }
            const details: Partial<RaceDetails> = this._todaysRacesService.mapTodaysRaceToRaceDetails(raceDetails, this.selectedRaceNav);
            // use long form race type on desktop/tablet, use race type code on mobile
            this.raceType = FormatRaceTypeUtil.format(details as RaceDetails, this.selectedRaceNav, !isSmallGlass);

            this._changeDetector.markForCheck();
        });
    }

    ngAfterViewInit() {
        // Must wait until after view init for view child to be set
        this.updateRaceDetailsDrawer.emit(this.raceDetailsDrawerContent);
    }

    ngOnDestroy() {
        this._raceDetailsRequestHandler.kill();
    }
    /** END LIFECYCLE HOOKS **/

    public logClickEvent() {
        const ts = Date.now();

        this._eventTrackingService.logClickEvent(EventClickType.RACE_NAV_DRAWER_OPENING, [
            { attrId: EventClickAttributeType.RACE_NAV_DRAWER_OPENING_BRIS_CODE, data: this._selectedRaceNav.BrisCode, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_DRAWER_OPENING_TRACK_TYPE, data: this._selectedRaceNav.TrackType, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_DRAWER_OPENING_RACE_NUMBER, data: this.raceDetails.raceNumber, timestamp: ts },
            { attrId: EventClickAttributeType.RACE_NAV_DRAWER_OPENING_RACE_DATE, data: this.raceDetails.raceDate, timestamp: ts }
        ]);
    }

    /** EVENT HOOKS **/
    public toggleShowDescription() {
        if (!!this.raceDetails && !!this.raceDetails.raceConditions) {
            this.isShowingDescription = !this.isShowingDescription;
            this.toggleRaceDescription.emit(this.isShowingDescription);
            this.logClickEvent();
        }
    }
    /** END EVENT HOOKS**/

    public getCustomTextOverflow(extra: string) {
        return '"...' + (extra ? '-' + extra : '') + '"';
    }
}
