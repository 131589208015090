import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ITrackBasic, TrackService, EventClickType, EventClickAttributeType, enumTrackType } from '@cdux/ng-common';

import { TrackChangesService } from 'app/wagering/track-changes/track-changes.services';
import { RaceData } from 'app/wagering/track-changes/data-classes/race-data';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { throwError, interval, of, Subject } from 'rxjs';
import { switchMap, debounce, catchError, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'cdux-changes-shared',
    templateUrl: './changes-shared.component.html',
    styleUrls: ['./changes-shared.component.scss']
})
export class ChangesSharedComponent implements OnInit, OnDestroy {
    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isSameTrack(track, this._track)) {
            this._track = track;
            this._scratchesSubThrottle.next();
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._scratchesSubThrottle.next();
        }
    }
    public get race(): number {
        return this._race;
    }

    private _track: ITrackBasic = null;
    private _race: number = null;

    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;

    private _scratchesSubThrottle: Subject<any> = new Subject();
    private _destroy: Subject<any> = new Subject();

    public showAllRaces = true;

    public changesList: RaceData[] = [];

    public lastUpdate: string;
    public trackConditions: string;
    public currentRace: RaceData;

    constructor(
        private _trackChangesService: TrackChangesService,
        private _wageringUtil: WageringUtilBusinessService,
        private _changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this._scratchesSubThrottle.pipe(
            debounce(() => interval(100)),
            switchMap(() => {
                if (this.track && this.race) {
                    return this._trackChangesService.getTrackChanges(this.track.BrisCode, this.track.TrackType, false)
                } else {
                    throwError(new Error('Missing track/race'));
                }
            }),
            catchError(() => of(null)),
            takeUntil(this._destroy)
        ).subscribe((data: RaceData[]) => {
            this.changesList = data || [];
            if (this.changesList.length > 0) {
                this.lastUpdate = this.changesList[0].lastUpdate ? this.changesList[0].lastUpdate : '';
                this.trackConditions = this.changesList[0].conditions ? this.changesList[0].conditions : 'N/A';
            }
            if (this.changesList.length) {
                this.currentRace = this.changesList.find(race => race.raceNumber === this.race.toString());
            }
            this._changeDetector.markForCheck();
        });

        this._scratchesSubThrottle.next();
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    public toggleAllRaces() {
        this.showAllRaces = !this.showAllRaces;
    }

    public getSaddleClothTrackClass(trackType: enumTrackType, programNumber: string): string {
        return this._wageringUtil.getSaddleClothClass(trackType, programNumber);
    }
}
