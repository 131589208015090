import { Injectable } from '@angular/core';
import { IOfferAssignment } from '@cdux/ng-common';
import { Subject } from 'rxjs';
import { RegistrationEvent } from './registration-event.enum';

@Injectable()
export class RegistrationNotificationService {

    public onRegistrationEvent: Subject<{regEvent: RegistrationEvent, offerAssignment?: IOfferAssignment}> = new Subject();

}
