import { Component, Input, OnInit } from '@angular/core';
import { enumFeatureToggle, EventClickType, FeatureToggleDataService, NativeEventEnum, PlatformService, TranslateService } from '@cdux/ng-common';
import { ZendeskChatService } from '@cdux/ng-fragments';

@Component({
    selector: 'cdux-chat',
    templateUrl: './chat.component.html',
    styleUrls:['./chat.component.scss']
})
export class ChatComponent implements OnInit {
    @Input()
    public errorCode: string;

    @Input()
    public language: string

    @Input()
    public linkStyle: string;

    private _zendeskEnabled: boolean;
    public eventClickType = EventClickType;
    public errorMessageParts: string[];

    constructor(private _featureToggleService: FeatureToggleDataService,
                private _platformService: PlatformService,
                private _translateService: TranslateService,
                private _zendeskChatService: ZendeskChatService) {
    }

    ngOnInit() {
        this._zendeskEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ZENDESK_CHAT);
        if(!!this.errorCode) {
            this.errorMessageParts = this._translateService.translate(this.errorCode, !!this.language ? this.language : 'errorcodes', false).split('%s')
        }
    }

    public launchZeMessenger() {
        if(this._platformService.isNativeApp()) {
            this._platformService.sendMessage(NativeEventEnum.CHAT_NOW);
        } else {
            this._launchZeMessenger();
        }
    }

    private _launchZeMessenger() {
        if (this._zendeskEnabled && this._zendeskChatService.isLoaded()) {
            this._zendeskChatService.openZeMessenger();
        }
    }
}
