import { Observable, combineLatest } from 'rxjs';
import { finalize, takeUntil, take, map } from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { ITrackBasic, TrackService, ITrack } from '@cdux/ng-common';

export class InitialRaceNavSelector extends CduxRxJSBuildingBlock<any, ITrackBasic> {
    protected _stream: Observable<ITrackBasic>;

    /** CONTROLS **/
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _source: Observable<ITrackBasic[] | ITrack[]>,
        private _initialTrack: Observable<ITrackBasic>
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = combineLatest([this._source, this._initialTrack]).pipe(
            take(1),
            map(([trackList, initialTrack]) => {
                if (!!initialTrack && trackList.length > 0) {
                    const matchingTrack: ITrackBasic = TrackService.findTrackInList(initialTrack.BrisCode, initialTrack.TrackType, trackList);
                    if (!!matchingTrack) {
                        // The bris code and track type were valid. Use the initial race number if provided
                        // and attempt to complete initialization.
                        return {
                            ...initialTrack,
                            RaceNum: !Number.isNaN(initialTrack.RaceNum) ? initialTrack.RaceNum : matchingTrack.RaceNum,
                            DisplayName: matchingTrack.DisplayName
                        };
                    } else {
                        // An invalid initial track was provided, default to first in the list.
                        return trackList[0];
                    }
                } else {
                    // No initial track provided, default to first in the list.
                    if (!!trackList[0]) {
                        return trackList[0];
                    } else {
                        throw new Error('The track list was empty or the default track was undefined');
                    }
                }
            }),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            // share()
        );
    }

    /** CUSTOM OPERATORS **/
    /** END CUSTOM OPERATORS **/
}
