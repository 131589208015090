import { Injectable } from '@angular/core';
import { enumConfigurationStacks, TermsAndConditionsDataService, TermTypes } from '@cdux/ng-common';

@Injectable()
export class TermsService {
    /**
     * returns a promise for the data specified depending on what legal
     * document you want to dynamically return
     * @param {TermType} termTypes
     * @returns {Promise}
     */

    constructor(private _termsAndConditionsDataService: TermsAndConditionsDataService) { }

    public getTermsHtml(termTypes: TermTypes): Promise<string> {
        return new Promise((resolve) => {
            this._termsAndConditionsDataService.getTermsAndConditions(termTypes, enumConfigurationStacks.TUX).then(
                (data) => {
                    resolve(data);
                },
                // For now just resolve the error. The error will contain TermPolicy.ERROR so it should be ok to just
                // shove that text into the html.
                (error) => {
                    resolve(error);
                }
            );
        });
    }
}
