import { AbstractControl } from '@angular/forms';
import { ManageConstants } from '../constants/manage.constants';


export function ValidatePin(control: AbstractControl) {

    if (!!control.value &&  ManageConstants.inValidPin.find(value => value === control.value)) {
        return { pininvalid: true };
    }
    return null;
}
