<form class="deposit-form-wrapper ezmoney" *ngIf="form" [formGroup]="form" [class.dark]="lockout">

    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>
    <div *ngIf="!lockout">
        <cdux-funding-input-amount id="depositAmount" formControlName="amount" [inputType]="OPERATION"
            [fundType]="fundingMethodDetails.fundType" [min]="getMinDepositAmount(hasVerifiedAccount)"
            [max]="getMaxDepositAmount()" [amount]="amount" [offerId]="offerId">
        </cdux-funding-input-amount>

        <div class="ts-message error" *ngIf="errorCode !== null">
            {{ errorCode | cduxTranslate:'errorcodes':true:errorCodeArgs}}</div>
        <div class="ts-message success" *ngIf="successfulDeposit && !errorCode">{{ successMessage }}</div>

        <div class="form-account-num-wrapper" *ngIf="!fundingMethodDetails.accountInfo || replaceAccountInfo">

            <div class="account-info-header">
                Enter Bank Details
            </div>

            <div class="ezmoney">
                <cdux-radio-group class="account-type-wrap" formControlName="accountType" class="inline">
                    <cdux-radio-option id="checkingRadio" value="checking">
                        Checking
                    </cdux-radio-option>
                    <cdux-radio-option id="savingsRadio" value="saving">
                        Savings
                    </cdux-radio-option>
                </cdux-radio-group>
            </div>


            <cdux-input class="form-label-hover routing-number"
                minlengtherror="The number entered should be a 9-digit number."
                maxlengtherror="The number entered should be a 9-digit number."
                requirederror="Routing Number is required." label="Routing Number" title="9 Digit Number"
                helptext="(9 Digits)" (reset)="resetField('routingNumber')">
                <input id="routingNumber" autocomplete="off" cduxInputField formControlName="routingNumber"
                    minlength="9" maxlength="9" type="text"
                    [cduxInputComplete]="form.get('routingNumber').value.length === 9"
                    [cduxInputCompleteFocus]="accountNumberInput" />
            </cdux-input>

            <cdux-input class="form-label-hover routing-number"
                minlengtherror="The number entered should be more than 4-digits."
                maxlengtherror="The number entered should be less than 18-digits."
                requirederror="Account Number is required." label="Account Number" title="17 Digit Number"
                helptext="(Up to 17 Digits)" (reset)="resetField('accountNumber')">
                <input id="accountNumber" #accountNumberInput autocomplete="off" cduxInputField
                    formControlName="accountNumber" minlength="4" maxlength="17" type="text"
                    [cduxInputComplete]="form.get('accountNumber').value.length === 17" />
            </cdux-input>
        </div>

        <div class="ts-body toc">By submitting below, I agree to the <a id="termsAndConditions"
                (click)="openTermsAndConditions()" style="cursor: pointer;">Terms & Conditions.</a></div>

        <cdux-funding-deposit-button (click)="!pendingDeposit && onDeposit()"
            [disabled]="form.invalid || form.get('amount').value <= 0 || pendingDeposit" [inboundBet]="inboundBet">
        </cdux-funding-deposit-button>
    </div>

</form>
