import { Component, Input } from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
// todo: HIP item, make this component handle all paypal errors on fullpage deposit
@Component(({
    selector: 'cdux-paypal-error',
    templateUrl:'./paypal-error-fullpage.component.html'
}))
export class PaypalErrorFullpageComponent {
    @Input()
    public paypalAccount: string;

    @Input()
    public phoneEnabled: boolean;

    public affId: string;

    constructor(environment: ENVIRONMENT) {
        this.affId = environment.affiliateId.toString();
    }
}
