import { Component, OnInit } from '@angular/core';
import { CduxAbstractSidebarComponent } from '../../../../sidebar/cdux-sidebar-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';


const ACCT_NUMBER_TOKEN = 'acct-number';

@Component({
  selector: 'cdux-ezmoney-method-header',
  templateUrl: './ezmoney-method-header.component.html',
  styleUrls: ['./ezmoney-method-header.component.scss']
})
export class FundingEzMoneyMethodHeaderComponent extends CduxAbstractSidebarComponent implements OnInit {

    public accountNumber: string;


    public static getSidebarComponent(accountNumber: string = null): ISidebarPortalComponent {
        const inputTokens: Map<string, any> = new Map<string, any>();

        inputTokens.set(ACCT_NUMBER_TOKEN, accountNumber);

        return {
            component: FundingEzMoneyMethodHeaderComponent,
            properties: {
                inputs: inputTokens
            }
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.accountNumber = properties.inputs.get(ACCT_NUMBER_TOKEN);
    }

    ngOnInit() { /* empty */ }
}
