import { Component, ChangeDetectorRef, EventEmitter, OnInit, Output } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractEzmoneyMethodDepositComponent } from 'app/shared/funding/components/methods/ezmoney/abstract-ezmoney-method-deposit.component';
import { FundingEzmoneyService } from 'app/shared/funding/shared/services/ezmoney.service';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-ezmoney-method-deposit-express',
    templateUrl: './ezmoney-method-deposit-express.component.html',
    styleUrls: ['./ezmoney-method-deposit-express.component.scss']
})
export class FundingEzmoneyMethodDepositExpressComponent extends AbstractEzmoneyMethodDepositComponent implements OnInit {

    @Output()
    public changeClick = new EventEmitter<void>();

    @Output()
    public replaceClick = new EventEmitter<void>();

    @Output()
    public termsClick = new EventEmitter<void>();

    protected readonly isFullPage: boolean = true;
    // public eventClickType = EventClickType;
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public successMessage = '';
    public playerName: string = '';
    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodDepositExpressComponent, options);
    }

    constructor(
        localRouter: Router,
        localEnvironment: ENVIRONMENT,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localEventTrackingService: EventTrackingService,
        localCdr: ChangeDetectorRef,
        localEzMoneyService: FundingEzmoneyService,
        localCurrencyPipe: CurrencyPipe,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localEventTrackingService,
            localCdr,
            localEzMoneyService,
            localCurrencyPipe,
            localTranslateService,
            localEnvironment,
            localFb,
            localFundingService,
            localSidebarService,
            localSessionService,
            localHeaderService,
            localToggleService
        );
    }

    ngOnInit() {
        super.ngOnInit();
    }

    public getInitialDepositAmount(): number {
        return 0;
    }

    public onDeposit() {
        if (this.form.invalid || this.form.get('amount').value <= 0) {
            return;
        }
        this.error.emit(false);
        super.onDeposit();
    }

}
