import { NgModule } from '@angular/core';
import { IdScanContainerComponent } from './components/container/id-scan-container.component';
import { EventSessionModule, TranslateModule } from '@cdux/ng-common';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropDirective } from '../drag-and-drop/draganddrop.directive';
import {TuxCommonModule} from '../common/common.module';

@NgModule({
    imports: [
        TuxCommonModule,
        EventSessionModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        DragAndDropDirective,
        IdScanContainerComponent
    ],
    exports: [
        IdScanContainerComponent
    ]
})
export class IdScanModule {}
