import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, map, skipWhile, switchMap, tap } from 'rxjs/operators';

import {
    enumPoolType,
    ITrackBasic,
    IWillPaysPoolEntry,
    ProgramEntry,
    ToteDataService,
    TrackService,
    IWillPaysPool,
    WagerDataService,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';

import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { ISortState, SortStateHandlerClass } from 'app/shared/program/classes/sort-state-handler.class';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { enumProgramSort } from 'app/shared/program/enums/program-sort-columns.enum';

interface WillPaysComponentEntryData {
    ProgramEntry: ProgramEntry;
    PoolEntries: { [poolId: string]: IWillPaysPoolEntry }
}

interface WillPaysComponentData {
    Pools: IWillPaysPool[];
    Entries: WillPaysComponentEntryData[]
}


@Component({
    selector: 'cdux-will-pays-shared',
    templateUrl: './will-pays-shared.component.html',
    styleUrls: ['./will-pays-shared.component.scss']
})
export class WillPaysSharedComponent implements OnInit {
    public isLoading: boolean = false;

    private _loadingDelay = 200; // for request debouncing


    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isSameTrack(track, this._track)) {
            this._track = track;
            this._update.next();
        }
    }

    public get track(): ITrackBasic {
        return this._track;
    }

    @Input()
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._update.next();
        }
    }

    public get race(): number {
        return this._race;
    }

    public willPaysObs: Observable<WillPaysComponentData>;
    public poolTypesMap: { [key: string]: string } = {};

    private _track: ITrackBasic;
    private _race: number;
    private _update = new ReplaySubject<void>(1);

    public enumProgramSort = enumProgramSort;
    public sortState: ISortState<enumProgramSort | string>;
    private _sortStateHandler: SortStateHandlerClass<enumProgramSort | string>;

    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;


    constructor (
        private _changeDetector: ChangeDetectorRef,
        private _currencyPipe: CurrencyPipe,
        private _toteDataService: ToteDataService,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _wagerDataService: WagerDataService,
        private _viewStateService: ViewStateService
    ) {
        this.willPaysObs = this._buildWillPaysDataObs();
        this._sortStateHandler = new SortStateHandlerClass<enumProgramSort | string>(this._viewStateService.getSortState(ViewSectionEnum.WILL_PAYS) as any);
    }

    ngOnInit() {
        this._sortStateHandler.listen().subscribe((sortState) => {
            this.sortState = sortState;
            this._viewStateService.setSortState(ViewSectionEnum.WILL_PAYS, sortState);
        });

        [
            enumProgramSort.SORTABLE_PROGRAM_NUMBER,
            enumProgramSort.ODDS_RANK
        ].forEach(sort => this._sortStateHandler.updateDefaultAscending(sort, true));
    }

    public formatPoolType(type: enumPoolType): string {
        return this.poolTypesMap[type] || null;
    }

    public formatBaseAmount(value: number): string {
        return this._currencyPipe.transform(value, 'USD', 'symbol-narrow', '1.' + (value % 1 ? '2-2' : '0-0'));
    }

    public formatQualifier(value: string): string {
        return value.replace(/,/g, ', ').replace(/\//g, ' / ').replace(/\*/g, '#');
    }

    public formatEntryPoolValue(entry: IWillPaysPoolEntry): string {
        let value: string;

        if (!entry || !entry.Value || typeof entry.Value === 'string' && ['SC', 'NM'].indexOf(entry.Value.trim().toUpperCase()) !== -1) {
            value = '-'; // runner was scratched or data is invalid ("SC" means "scratched", "NM" means "no money")
        } else {
            value = this._currencyPipe.transform(entry.Value, 'USD', 'symbol-narrow', '1.2-2');
            if (entry.Reason1 && entry.Reason2 > entry.Reason3) { // check for a partial payout reason
                value += ' (' + entry.Reason3 + ' ' + entry.Reason1.toLowerCase() + ' ' + entry.Reason2 + ')';
            }
        }

        return value;
    }

    public getPoolId(pool: IWillPaysPool) {
        return pool.Pooltype + ':' + pool.Qualifier;
    }

    public setSortKey(key: string) {
        this._sortStateHandler.updateSortProperty(key);
    }

    public isSortedBy(key: string): boolean {
        return key === this.sortState.sortProperty;
    }

    public isSortedAscending(): boolean {
        return this.sortState.ascending;
    }


    private _buildWillPaysDataObs(): Observable<WillPaysComponentData> {
        return this._update.pipe(
            skipWhile(() => !(this._track && this._race)),
            tap(() => this.isLoading = true),
            debounceTime(this._loadingDelay),
            switchMap(() => combineLatest([
                this._todaysRacesService.getTodaysRaceEntries(
                    this._track.BrisCode,
                    this._track.TrackType,
                    this._race,
                    true
                ),
                this._toteDataService.willPays(
                    this._track.BrisCode,
                    this._track.TrackType,
                    this._race,
                    true
                ),
                this._wagerDataService.poolTypes(),
                this._sortStateHandler.listen()
            ])),
            map(([ entryData, willPaysData, poolTypes ]) => {
                this._changeDetector.markForCheck();

                this.poolTypesMap = poolTypes.reduce((poolTypesMap, poolType) => {
                    poolTypesMap[poolType.Code] = poolType.Name;
                    return poolTypesMap;
                }, {});

                // if sort column is not a pool type or not program number or odds, reset it
                if (this.sortState.sortProperty !== enumProgramSort.SORTABLE_PROGRAM_NUMBER &&
                    this.sortState.sortProperty !== enumProgramSort.ODDS_RANK &&
                    !(willPaysData && willPaysData.Pools && willPaysData.Pools.find(pool =>
                        this.sortState.sortProperty === this.getPoolId(pool)
                    ))
                ) {
                    this._sortStateHandler.updateSortProperty(enumProgramSort.SORTABLE_PROGRAM_NUMBER, true);
                }

                if (entryData && willPaysData && willPaysData.Pools && willPaysData.Pools.length) {
                    return this._buildComponentData(willPaysData.Pools, entryData);
                } else {
                    return null;
                }
            }),
            catchError(() => of(null)),
            tap(() => this.isLoading = false)
        );
    }

    private _buildComponentData(poolData: IWillPaysPool[], programData: ProgramEntry[]): WillPaysComponentData {
        return {
            Pools: poolData,
            Entries: this._sortEntries(programData.map(entry => {
                const bin = entry.BettingInterest.toString();
                return {
                    ProgramEntry: entry,
                    PoolEntries: poolData.reduce((values, pool) => {
                        values[this.getPoolId(pool)] = pool.Entries.find(
                            e => e.ProgramNumber.toString() === bin
                        );
                        return values;
                    }, {})
                };
            }, []))
        };
    }

    private _sortEntries(entries: WillPaysComponentEntryData[]) {
        return entries.sort((a, b) => {
            let sort = 0;

            // always prefer the non-scatched entries
            if (a.ProgramEntry.Scratched) { ++sort; }
            if (b.ProgramEntry.Scratched) { --sort; }
            if (sort !== 0) { return sort; } // exits early

            if (this.sortState.sortProperty in a.ProgramEntry) {
                sort = a.ProgramEntry[this.sortState.sortProperty] >
                    b.ProgramEntry[this.sortState.sortProperty] ?
                    1 : -1;
            } else if (this.sortState.sortProperty in a.PoolEntries) {
                sort = +a.PoolEntries[this.sortState.sortProperty].Value >
                    +b.PoolEntries[this.sortState.sortProperty].Value ?
                    1 : -1;
            }

            return this.sortState.ascending ? sort : sort * -1;
        });
    }
}
