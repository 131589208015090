import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { BasicBetType, ISelectedEntry, MultiRaceExoticBetType, ProgramEntry, WagerState } from "@cdux/ng-common";

@Component({
    selector: 'cdux-bris-picks-narrative',
    templateUrl: './bris-picks-narrative.component.html',
    styleUrls: ['./bris-picks-narrative.component.scss']
})
export class BrisPicksNarrativeComponent {
    @Output()
    public close = new EventEmitter<void>();

    @Output()
    public select = new EventEmitter<ISelectedEntry[]>();

    @Input()
    public wagerState: WagerState;

    @Input()
    public brisBetComment: SafeHtml = '';

    @Input()
    public brisBetEntries: ProgramEntry[] = [];

    @Input()
    public betType: (BasicBetType | MultiRaceExoticBetType);

    @Input()
    public set wagerAmount(wagerAmount: number) {
        this._wagerAmount = +wagerAmount;
        this.wagerAmountDigitsInfo = this.wagerAmount % 1 ? '1.2-2' : '1.0-0'; // hide cents for even amount
    }
    public get wagerAmount(): number {
        return this._wagerAmount;
    }
    private _wagerAmount: number;
    public wagerAmountDigitsInfo: string = '1.0-0';
}
