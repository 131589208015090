import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnInit,
    OnDestroy,
    ElementRef
} from '@angular/core';
import { ITodaysRace, ITrackBasic, TracksDataService } from '@cdux/ng-common';
import { RaceDetailsRequestHandler } from '../../classes/race-details-request-handler.class';

@Component({
    selector: 'cdux-common-mtp-badge',
    templateUrl: './common-mtp-badge.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonMtpBadgeComponent implements OnInit, OnDestroy {
    /** INPUTS/OUTPUTS **/
    @Input()
    public set selectedRaceNav(trackBasic: ITrackBasic) {
        this._raceDetailsRequestHandler.updateRaceNavigation(trackBasic);
    }
    /** END INPUTS/OUTPUTS **/

    /** PRIVATE PROPERTIES **/
    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    /** END PRIVATE PROPERTIES **/

    /** PUBLIC PROPERTIES **/
    public raceDetails: ITodaysRace;
    public classes: string;
    /** END PUBLIC PROPERTIES **/

    /** CONTROLS **/
    /** END CONTROLS **/
    constructor(private _tracksDataService: TracksDataService,
                private _el: ElementRef,
                private _changeDetector: ChangeDetectorRef) {
        this._raceDetailsRequestHandler =  new RaceDetailsRequestHandler(this._tracksDataService);
    }

    /** LIFECYCLE HOOKS **/
    ngOnInit() {
        this._raceDetailsRequestHandler.listen()
            .subscribe((raceDetails) => {
                this.raceDetails = raceDetails;
                this._changeDetector.detectChanges();
            });
        this.classes = this._el.nativeElement.className;
    }

    ngOnDestroy() {
        this._raceDetailsRequestHandler.kill();
    }
    /** END LIFECYCLE HOOKS **/

    /** EVENT HOOKS **/
    /** END EVENT HOOKS **/
}
