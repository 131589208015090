import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
import { TermTypes } from '@cdux/ng-common';
import { ModalService, ModalRef } from '@cdux/ng-platform/web';

import { TermsComponent } from '../components';
import { TermsService } from '../services';

@Directive({
    selector: '[cduxTerms]',
})
export class TermsDirective {

    @Input() public cduxTerms: TermTypes;
    @Input() public cduxTermsElement: ElementRef;

    private _modalRef: ModalRef<TermsComponent>;

    constructor(
        private _environment: ENVIRONMENT,
        private _modalService: ModalService,
        private _termsService: TermsService,
    ) {
        // Empty
    }

    private _openModalLogin() {
        this._termsService.getTermsHtml(this.cduxTerms).then((html) => {
            this._modalRef = this._modalService.open(TermsComponent, {
                id: 'terms-and-conditions',
                context: {
                    html: html,
                    isSidebar: false
                },
                /* height applies an align-item style of flex-start to cdk-global-overlay-wrapper
                 * as shown in the global-position-strategy.ts file in angular's github page
                 */
                height: '100%',
                padding: '0px',
                overflow: 'hidden',
                maxWidth: '100vw',
                minWidth: '100vw',
                maxHeight: '100vh',
                minHeight: '100vh'
            });
        });
    }

    private _openModalSidebar() {
        this._termsService.getTermsHtml(this.cduxTerms).then((html) => {
            this._modalRef = this._modalService.open(TermsComponent, {
                id: 'terms-and-conditions',
                context: {
                    html: html,
                    isSidebar: true
                },
                hasBackdrop: false,
                width: '375px',
                maxWidth: '375px',
                maxHeight: 'calc(100vh - 80px - 45px)',
                position: {
                    right: this._getDrawerWidth(),
                    top: '80px',
                    bottom: '45px',
                },
                padding: '0px',
            });
        });
    }

    // TODO:Find a better way to get the drawer width
    private _getDrawerWidth(): string {
        const body = window.document.body;
        const framework: HTMLElement = <HTMLElement>body.getElementsByClassName('tux-framework-container')[0];
        const vw = body.getBoundingClientRect().width;
        const mw = framework.getBoundingClientRect().width;
        const dw = vw > mw ? ((vw - mw) / 2) : 0;
        return dw + 'px';
    }


    @HostListener('click', ['event']) public onClick() {
        /*
         * TODO: Implement a real solution
         *       A looming CCPA deadline of July 1 didn't produce a story
         *       until June 30, so this is fast and dirty. 😒
         */
        if (this.cduxTerms === TermTypes.PRIVACY_POLICY) {
            window.open('/assets/policy-docs/' + this._environment.affiliateId + '/privacy.pdf', '_blank');
            event.stopImmediatePropagation();
            return false;
        } else if (this.cduxTermsElement) {
            this._openModalSidebar();
        } else {
            this._openModalLogin();
        }
    }

    @HostListener('window:resize') public onResize() {
        if (this._modalRef) {
            this._modalRef.updatePosition({
                right: this._getDrawerWidth(),
                top: '80px',
                bottom: '45px',
            });
        }
    }

}
