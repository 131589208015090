import { Directive, OnDestroy, ChangeDetectorRef, ViewContainerRef, ViewRef, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';

import { IVideoStreamUrl, VideoAngles } from '@cdux/ng-common';

import { IVideoStreamSource, IVideoStreamResponses } from './rcn-stream-sources/video-stream-source.interface';

import { VideoFeedError, IVideoFeed } from '../video-feed.interface';
import { VideoProviderService } from '../video-provider.service';


@Directive()
export abstract class RCNBaseVideoFeed implements IVideoFeed, OnInit, OnDestroy {
    protected container: ViewContainerRef;

    protected errorSubject: Subject<VideoFeedError> = new Subject<VideoFeedError>();
    public onError: Observable<VideoFeedError> = this.errorSubject.asObservable();

    protected constructor (
        protected changeDetectorRef: ChangeDetectorRef,
        protected videoProviderService: VideoProviderService,
        protected streamSource: IVideoStreamSource,
    ) {}

    ngOnInit() {
        // Moved from the constructor. With the implementation of the video-bar-button component, we
        // want the ability to select a video angle other than the default. This means that in the video
        // player component where we create the dynamic video feed component we will need to set the angle.
        // Since the constructor for the feeds will already have run, we want to wait until ngOnInit so we
        // can respect the angle value that's provided.
        this.getStreamData();
    }

    ngOnDestroy() {
        this.changeDetectorRef.detach();
    }

    protected handleError(type: VideoFeedError = VideoFeedError.VIDEO_UNAVAILABLE) {
        this.errorSubject.next(type);
    }

    protected getUrlFromCurrentStream (): string {
        // This method only works for IVideoStreamUrl (extension of IVideoStream).
        const currentStream = JSON.parse(JSON.stringify(this.videoProviderService.currentStream)) as IVideoStreamUrl;

        if (currentStream.url && typeof currentStream.url === 'string') {
            return currentStream.url;
        }

        return null;
    }

    protected getStreamData() {
        this.streamSource.getStreamData().then(
            (streamData: IVideoStreamResponses) => {
                try {
                    // TODO: streamData is never used, videoProviderService.currentStream used instead
                    this.handleStreamData(streamData);
                } catch (e) {
                    this.handleError();
                }
            }, (error) => {
                this.handleError();
            }
        );
    }

    public resize() {
        if (!(this.changeDetectorRef as ViewRef).destroyed) {
            this.changeDetectorRef.detectChanges();
        }
    }

    /**
     * processes data retrieved from VideoDataService (web service)
     *
     * The actual data service call is abstract and defined by the
     * streamSource provided to the constructor. For example, it
     * could be the response from getLiveStreams (IVideoStreamResponse)
     * or a replay.
     *
     * @param data
     */
    protected abstract handleStreamData (streamData: IVideoStreamResponses);

    public abstract start();
    public abstract stop();
    public abstract pause();
    public getAngle(): VideoAngles { return null; }
    public getAngles(): Observable<VideoAngles[]> { return of([]); }
    public setAngle(angle: VideoAngles, getStreamData: boolean): void { return; }
}
