import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    EventClickType,
    IAvailableResultsRaceDataResponse,
    IGetFundingDetailsResponse,
    StringSlugifyPipe,
    ToteDataService,
    TranslateService
} from '@cdux/ng-common';
import { ToastService, TransactionCategory, ITransaction, WagerDisplayStatus, } from '@cdux/ng-fragments';

import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { BetsCommonService } from 'app/shared/bets/services/bets-common.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import {
    WagerStatusClass,
    WagerTotalClass,
} from 'app/shared/transaction';
import { ExpandedDetailsBusinessService } from 'app/shared/transaction/services/expanded-details.business.service';

@Component({
    selector: 'cdux-transaction-component',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.scss']
})
export class CduxTransactionComponent implements OnInit, OnDestroy {
    static WAGER_CANCELLATION_ERROR_MESSAGE = 'We were unable to cancel your bet.\n' + 'If you have any questions, please call ';

    @Input() public transaction: ITransaction;
    @Input() public hasFullSsn: boolean;
    @Input() public index: number;
    // @Input() public toteDate: string;
    @Input() public isToday: boolean;
    @Input() public isFinishOrderToggledOn: boolean;
    @Output() public expand = new EventEmitter<ITransaction>();
    @Output() public contract = new EventEmitter<undefined>();
    @Output() public wagerCanceled: EventEmitter<ITransaction> = new EventEmitter<ITransaction>();

    public toteDate: string;
    public isAdjustment: boolean;
    public isFunding: boolean;
    public isProgramAvailable: boolean;
    public isResultAvailable: boolean;
    public isWager: boolean;
    public ownedBetSharesLabel: string;
    public runnerDelimiter: string;
    public valueClassName: string;
    public wagerStatusClassName: string;
    public cancelToggle: boolean = false;

    /**
     * Exposes the event click types to the template.
     * @type {EventClickType}
     */
    public eventClickType = EventClickType;

    public readonly DEFAULT_FIELD_SYMBOL = '-';
    public readonly wagerDisplayStatus = WagerDisplayStatus;

    private _destroy: Subject<boolean> = new Subject();

    constructor(
        private _environment: ENVIRONMENT,
        private _betsCommonService: BetsCommonService,
        private _betsService: BetsBusinessService,
        private _changeDetector: ChangeDetectorRef,
        private _expandedDetailsService: ExpandedDetailsBusinessService,
        private _router: Router,
        private _sidebarService: SidebarService,
        private _stringSlugify: StringSlugifyPipe,
        private _toastService: ToastService,
        private _toteDataService: ToteDataService,
        private _translateService: TranslateService
    ) {
        this._betsCommonService.initializeCommonService().pipe(
            take(1)
        ).subscribe();
    }

    ngOnInit() {
        /*
        information on today's bets and past bets come from different
        sources and use a different delimiter to properly format runners list
         */
        this.runnerDelimiter = this.isToday ? ',' : ' ';

        this._setTransactionTypeToggles();

        // Set the program icon toggle and style classes
        if (this.isWager) {
            const wager = <ITransaction> this.transaction;
            this._toteDataService.currentRaceDate().pipe(take(1)).subscribe(toteRaceDate => {
                this.toteDate = toteRaceDate;
                this.isProgramAvailable = wager.eventDate === toteRaceDate;
                this.isResultAvailable = wager.eventDate < toteRaceDate;
            });
        } else {
            this.isProgramAvailable = this.isResultAvailable = false;
        }

        this.wagerStatusClassName = this.isWager ? this._getWagerStatusClassName() : null;

        if (this.transaction.profitLoss) {
            const valueForClassCalc = parseFloat(this.transaction.profitLoss);
            // per US33088, account history should only change this class for positive values
            //      and frozen wagers should not be treated differently...
            this.valueClassName = (valueForClassCalc > 0) ? this._expandedDetailsService.getWagerTotalClass(false, valueForClassCalc) : null;
            // Keep this until F2869 is resolved
            // const pl = this.transaction.profitLoss ? parseFloat(this.transaction.profitLoss) : 0.0;
            // this.valueClassName = this.expandedDetailsService.getWagerTotalClass(this.transaction.isFrozen, pl);
        }

    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    public copyWagerTransaction (transaction: ITransaction) {
        this._betsCommonService.createBetFromTransaction(transaction)
            .pipe(takeUntil(this._destroy))
            .subscribe(
                (bet) => {
                    this._betsService.saveWager(bet)
                        .pipe(takeUntil(this._destroy))
                        .subscribe(
                            (_) => {
                                this._changeDetector.detectChanges();
                            },
                            error => {
                                this._changeDetector.detectChanges();
                            }
                        );
                }
            );
    }

    public resultsNavigate(transaction: ITransaction) {
        if (this.isProgramAvailable) {
            this._router.navigate(this._getProgramRoute(transaction));
        } else if (this.isResultAvailable) {
            this._router.navigate(['/results', this._stringSlugify.transform(transaction.title), transaction.eventDate, transaction.brisCode, transaction.trackType, transaction.raceNum]);
        }

        this._sidebarService.close(true);
    }

    public isNumber(value: any): boolean {
        return typeof value === 'number';
    }

    public toggleExpandedState(): void {
        if (!this.transaction.isExpanded) {
            this.expand.emit(this.transaction);
            this._getTransactionDetails(this.transaction);
            this._changeDetector.markForCheck();
        } else {
            this.transaction.isExpanded = false;
            this.contract.emit();
        }
    }

    public toggleSsnCollection(): void {
        this._expandedDetailsService.toggleSsnCollection(this.transaction.value);
    }

    public toggleCancel() {
        this.cancelToggle = !this.cancelToggle;
    }

    public cancelWager() {
        this._betsService.cancelWager(this.transaction)
            .then(success => {
                this.toggleCancel();
                if (success) {
                    this.transaction.isCanceled = true;
                    this.wagerCanceled.emit(this.transaction);
                } else {
                    this._toastService.open(
                        CduxTransactionComponent.WAGER_CANCELLATION_ERROR_MESSAGE +
                            this._translateService.translate(
                                'primary-phone-number',
                                this._environment.affiliateId.toString()
                            ),
                        { duration: 0, panelClass: 'warning' }
                    );
                }
            });
    }

    public watchWager (transaction: ITransaction) {
        const route = this._getProgramRoute(transaction);
        route.push('video');
        this._router.navigate(route).then(
            _ => this._sidebarService.close(true)
        );
    }

    private _setTransactionTypeToggles(): void {
        this.isWager = false;
        this.isFunding = false;
        this.isAdjustment = false;
        switch (this.transaction.category as TransactionCategory) {
            case TransactionCategory.WAGER:
                this.isWager = true;
                break;
            case TransactionCategory.DEPOSIT:
            case TransactionCategory.WITHDRAWAL:
                this.isFunding = true;
                break;
            default:
                this.isAdjustment = true;
                break;
        }
    }

    private _getWagerStatusClassName(): string {
        if (this.transaction.isFrozen) {
            return WagerTotalClass.PENDING;
        } else {
            switch (this.transaction.status) {
                case WagerDisplayStatus.ACCEPTED:
                    return WagerStatusClass.IS_ACCEPTED;
                case WagerDisplayStatus.PENDING:
                    return WagerStatusClass.IS_PENDING;
                case WagerDisplayStatus.OPEN:
                    return WagerStatusClass.IS_PROCESSING;
                default:
                    return null;
            }
        }
    }

    /**
     * Make necessary WS calls to get transaction details
     * @param {CduxTransactionModel}
     */
    private _getTransactionDetails(transaction: ITransaction) {
        switch (transaction.category) {
            case TransactionCategory.WAGER:
                if (this.isFinishOrderToggledOn && (!transaction.finishOrder)) {
                    this._getFinishOrder(transaction);
                }
                if (transaction.betShareData.betShare && !this.ownedBetSharesLabel) {
                    this.ownedBetSharesLabel = this._expandedDetailsService.getMyShares(+transaction.betShareData.participantShares, +transaction.betShareData.betShareNumberOfShares);
                }
                break;
            case TransactionCategory.DEPOSIT:
            case TransactionCategory.WITHDRAWAL:
                if (!transaction.accountId) {
                    this._getAccountId(transaction);
                }
                break;
            default:
                if (!transaction.reason) {
                    this._getAdjustmentReason(transaction);
                }
                break;
        }
    }

    /**
     * Get the finish order for the selected transaction
     * @param {WagerTransactionModel} - Wager (transaction) to retrieve finish order for
     */
    private _getFinishOrder(wager: ITransaction): void {
        const payoutDate = wager.payoutRaceDate ? new Date(wager.payoutRaceDate) : null;
        this._expandedDetailsService.getResultDetail(
            wager.payoutTrackBrisCode || wager.brisCode,
            wager.payoutRaceNumber || wager.raceNum,
            payoutDate || wager.transactionDate,
            wager.trackType
        ).pipe(
            take(1)
        ).subscribe((data: IAvailableResultsRaceDataResponse) => {
            if (data?.raceDetails?.finishOrderString && data.raceDetails.finishOrderString !== '') {
                wager.finishOrder = data.raceDetails.finishOrderString;
            } else {
                wager.finishOrder = this.DEFAULT_FIELD_SYMBOL;
            }
        });
    }

    /**
     * Get the accountId, the last four digits of the account number (e.g. credit card #, EZ money #, etc.)
     * @param {FundingTransactionModel} - fundItem (transaction) to retrieve accountId for
     */
    private _getAccountId(fundItem: ITransaction): void {
        this._expandedDetailsService.getFundingDetails(fundItem.category, fundItem.fundTypeKey).pipe(
            take(1)
        ).subscribe((data: IGetFundingDetailsResponse) => {
            if (!!data.cardNumber) {
                fundItem.accountId = data.cardNumber;
            } else {
                fundItem.accountId = this.DEFAULT_FIELD_SYMBOL;
            }
        });
    }

    private _getAdjustmentReason(adjItem: ITransaction): void {
        this._expandedDetailsService.getFundingDetails(adjItem.category, adjItem.fundTypeKey).pipe(
            take(1)
        ).subscribe((data: IGetFundingDetailsResponse) => {
            if (!!data.reasonName) {
                adjItem.reason = data.reasonName;
            } else {
                adjItem.reason = this.DEFAULT_FIELD_SYMBOL;
            }
        });
    }

    private _getProgramRoute (transaction): string[] {
        return ['/', 'program', this._stringSlugify.transform(transaction.title), transaction.brisCode, transaction.trackType, transaction.raceNum];
    }
}
