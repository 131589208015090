import { Directive, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { ModalService } from '@cdux/ng-platform/web';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { FundingAbstractMethodDepositComponent } from '../abstract-method-deposit.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingService } from '../../../shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { enumDepositOptions } from '../abstract-method.component';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { FundingPaynearmeMethodHeaderComponent } from './paynearme-method-header.component';
import { PaynearmeMethodDepositConstants } from './paynearme-method-deposit.constants';
import { HeaderService } from 'app/shared/header/services/header.service';

@Directive()
export abstract class AbstractPaynearmeMethodDepositComponent extends FundingAbstractMethodDepositComponent {

    protected abstract isFullPage: boolean; // This is a flag used for FTD fullpage component

    @Input() public context: { paynearmeFrameSource: string };

    public affiliateId: string;
    public hasError: boolean;
    public isDisabled: boolean = false;
    public fullscreen: boolean = false;
    public isBetPad: boolean = false;

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingPaynearmeMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.hasError = (properties.inputs.has(PaynearmeMethodDepositConstants.HAS_ERROR_TOKEN) && properties.inputs.get(PaynearmeMethodDepositConstants.HAS_ERROR_TOKEN) === true);
        this.isDisabled = (properties.inputs.has(PaynearmeMethodDepositConstants.IS_DISABLED) && properties.inputs.get(PaynearmeMethodDepositConstants.IS_DISABLED) === true);
        if (properties.inputs.has(PaynearmeMethodDepositConstants.URL_TOKEN)) {
            this.context = {
                paynearmeFrameSource: properties.inputs.get(PaynearmeMethodDepositConstants.URL_TOKEN)
            }
        }
        if (properties.inputs.has(PaynearmeMethodDepositConstants.FULLSCREEN_TOKEN)) {
            this.fullscreen = properties.inputs.get(PaynearmeMethodDepositConstants.FULLSCREEN_TOKEN);
        }
        if (properties.inputs.has(PaynearmeMethodDepositConstants.IS_BET_PAD_TOKEN)) {
            this.isBetPad = properties.inputs.get(PaynearmeMethodDepositConstants.IS_BET_PAD_TOKEN);
        }
    }

    constructor(
        localRouter: Router,
        localEventService: EventTrackingService,
        localEnvironment: ENVIRONMENT,
        protected _modalService: ModalService,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localHeaderService,
            localEnvironment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localEventService,
            localTranslateService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumDepositOptions.PAYNEARME;
        this.affiliateId = localEnvironment.affiliateId.toString();
    }

    public get hasVerifiedAccount(): boolean {
        return !!this.fundingMethodDetails.accountInfo &&
            this.fundingMethodDetails.accountInfo.trim() !== '';
    }
}
