import { Injectable } from '@angular/core';
import { Observable, Subscription, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EventsService, PaynearmeDataService, IPaynearmeResponse, JwtSessionService } from '@cdux/ng-common';
import { EventKeys } from '../../../common/events/event-keys';

@Injectable()
export class FundingPaynearmeService {

    /**
     *
     * The paynearme URL for a given user is only ever created once.
     * Thus, once we have it on the front end, there's no need
     * to keep making backend calls to get it.
     *
     * This stores the Url returned from the paynearme data service
     * so that we only have to call the data service once.
     */
    private _paynearmeUrl: string = null;
    private subscriptions: Subscription[] = [];

    constructor(
        private _paynearmeDataService: PaynearmeDataService,
        private _jwtSessionService: JwtSessionService,
        private _eventService: EventsService
    ) {
        this.subscriptions.push(this._eventService.on(EventKeys.AUTH_STATUS_CHANGED).subscribe(() => {
            this._paynearmeUrl = null;
        }));
    }

    public getPaynearmeUrl(): Observable<string> {
        const user = this._jwtSessionService.getUserInfo();
        // If the user is not set, or if the user is set AND we have already set a URL,
        // then we don't need to call the data service
        if (!user || (!!user && this._paynearmeUrl)) {
            // if the user is not set, then we have no url to return.
            if (!user) {
                this._paynearmeUrl = null;
            }

            return of(this._paynearmeUrl);
        } else {
            // Otherwise, call the service to get the url.
            return this._paynearmeDataService.createOrder(+user.account).pipe(
                map((response: IPaynearmeResponse) => {
                    this._paynearmeUrl = response.orderTrackingURL;
                    return this._paynearmeUrl;
                }),
                catchError((err) => {
                    // Always clear the url if we encounter an error
                    this._paynearmeUrl = null;
                    return throwError(err);
                })
            );
        }
    }
}
