<div class="nav-panel message is-active">
  <div class="nav-panel-content message is-active">
    <div id="favorite-message-container" class="message-container">
      <div class="message-header">
        <i id="message-header-icon" class="icon checkmark"></i>
        <span id="message-header-text" class="header-text">{{ message }}</span>
      </div>
    </div>
  </div>
</div>
