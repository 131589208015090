import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { PopUpService } from './pop-up.service';

@Component({
  selector: 'cdux-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopUpComponent {

    @Input() public path: string;
    @Input() public windowName: string;
    @Input() public popUpHeight: number;
    @Input() public popUpWidth: number;
    @Input() public resizable: boolean = false;
    @Input() public scrollbars: boolean = false;

    constructor( private _popUpService: PopUpService ) {
        // empty
    }

    public openPopUp() {
        this._popUpService.openPopUp(
            this.path,
            this.windowName,
            this.popUpHeight,
            this.popUpWidth,
            this.resizable,
            this.scrollbars
        );
    }
}
