<section class="probables-container" *ngIf="probablesObs | async as probables; else noProbables" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">

    <div class="probables-pool-total-header">
        <div class="pool-total">POOL TOTAL: {{ currentPoolTotalsModel.Amount | currency:'USD':'symbol-narrow':'1.0'}}</div>
        <div class="pool-base-bet">BASE BET: {{ currentPoolTotalsModel.Base | currency:'USD':'symbol-narrow':'1.0' }}</div>
    </div>

    <div class="probables-table-container">
        <div class="probables-table">
            <div class="probables-table-header">
                <div class="probables-header-row row">
                    <div class="saddle-cloth-container">
                        <div class="label--two-over-one">
                            <span class="one">1</span>
                            <span class="slash">\</span>
                            <span class="two">2</span>
                        </div>
                    </div>
                    <div class="saddle-cloth-container" *ngFor="let runner of probables.Exotics.RunList[0].Combos">
                        <div [ngClass]="runner.With | cduxSaddleClothClass: track?.TrackType" class="saddle-cloth">{{ runner.With }}</div>
                    </div>
                </div>
            </div>
            <div class="probables-table-body">
                <div class="probables-row row" *ngFor="let runner of probables.Exotics.RunList">
                    <div class="saddle-cloth-container">
                        <div [ngClass]="runner.ProgramNumber | cduxSaddleClothClass: track?.TrackType" class="saddle-cloth">{{ runner.ProgramNumber }}</div>
                    </div>
                    <div class="amount" *ngFor="let combo of runner.Combos">
                        {{ combo.Amount === '-1.00' ? '&nbsp;' : combo.Amount === '-2.00' ? '-' : combo.Amount | formatProbable }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #noProbables>
    <div class="has-error light" *ngIf="!isLoading">
        <div class="gbl-message">
            <i class="icon--info"></i>
            <div class="header">Probables unavailable</div>
            <div class="description">Probables are unavailable at this time.<br> Check back later.</div>
        </div>
    </div>
</ng-template>
