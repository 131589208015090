<div class="track-group-container" [class.track-list]="inTrackList" [ngClass]="inView">
    <cdux-favorite-track *ngIf="isFavoriteEnabled"
                         class="favorite-track"
                         [inHeader]="inHeader"
                         [inView]="inView"
                         [largeStar]="largeStar"
                         [track]="track"
                         [disableClick]="disableClick"></cdux-favorite-track>
    <ng-content select="[mtp-badge]"></ng-content>
    <div class="track-race-info" [class.favorites-disabled]="!isFavoriteEnabled" [ngClass]="inView">
        <ng-content></ng-content>
    </div>
</div>
