import { Observable } from 'rxjs';
import { IPublicTokenExchangeRequest } from '@cdux/ng-common';

export interface IFundingService {
  deposit?(amount: string | number, ...depositArgs): Observable<any>;
  getAccountInfo?(): Observable<any>;
  withdraw?(amount: string | number, ...withdrawArgs): Observable<any>;
  removeAccountInfo?(): Observable<any>;
}

export interface IFundingAccountDetails {
  //  old peristAccount way
  routingNumber?: string;
  accountNumber?: string;
  accountType?: string;
  // new plaid way
  plaidSession?: IPublicTokenExchangeRequest
}

export interface IEnabledMethods {
    deposit: number[],
    withdraw: number[]
}

export interface IFundingEvent {
    // TODO: fix uses of IFundingEvent to use enumFundingEvent exclusively
    event: 'DEPOSIT' | 'ERROR' | 'SUCCESS' | enumFundingEvent;
    messages?: string[];
    code?: string;
    amount?: number;
}

export enum enumFundingEvent {
    DEPOSIT = 'DEPOSIT',
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
}
