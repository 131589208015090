<nav *ngIf="view !== WageringViewEnum.MOBILE" class="tux-nav-secondary">
    <ng-container *ngTemplateOutlet="raceNav"></ng-container>
    <ng-container *ngTemplateOutlet="raceDetails"></ng-container>
    <div class="nav-tux-views" (click)="isSelectingView = !isSelectingView">
        <ng-container *ngIf="view === WageringViewEnum.VIDEO; else NotVideo">
            <span *ngIf="isPopoutVideoEnabled" class="popout-icon nav-item smaller" (click)="openVideoPopout()">
                <ng-container *cduxMediaToggle="enumMediaTarget.DESKTOP_TO_TABLET">Pop Out</ng-container>
            </span>
            <span class="is-active nav-item smaller" (click)="updateView(WageringViewEnum.CLASSIC)">Video</span>
        </ng-container>
        <ng-template #NotVideo>
            <span *ngIf="isTuxTvEnabled" class="nav-item smaller" (click)="updateView(WageringViewEnum.VIDEO)">Video</span>
        </ng-template>
    </div>
</nav>

<div class="tux-nav-secondary-drawer" [class.is-open]="isDrawerOpen">
    <ng-container *ngTemplateOutlet="drawerContent"></ng-container>
</div>

<div *ngIf="view === WageringViewEnum.MOBILE">
    <ng-template cdk-portal #mobileRaceHeader="cdkPortal">
        <div class="race-nav-sml">
            <div *ngTemplateOutlet="raceNav"></div>
        </div>
    </ng-template>

    <ng-template cdk-portal #mobileRaceSubHeader="cdkPortal">
        <div *ngTemplateOutlet="raceDetails"></div>
    </ng-template>

    <ng-template cdk-portal #mobileRaceDetails="cdkPortal">
        <div class="race-details-sml" *ngTemplateOutlet="drawerContent"></div>
    </ng-template>
</div>

<ng-template #raceNav>
    <cdux-common-race-nav cduxDisplayModes
                          [displayMode]="view === WageringViewEnum.MOBILE ? DisplayModeEnum.MOBILE : DisplayModeEnum.LARGE"
                          [selectedRaceNavigation]="selectedRaceNav"
                          (updateRaceNav)="onRaceNavChange($event)"
                          (updateTrackList)="onTrackListChange($event)">
    </cdux-common-race-nav>
    <cdux-common-mtp-badge *ngIf="view !== WageringViewEnum.MOBILE"
                           [selectedRaceNav]="selectedRaceNav">
    </cdux-common-mtp-badge>
</ng-template>
<ng-template #raceDetails>
    <cdux-common-race-details cduxDisplayModes
                              [displayMode]="view === WageringViewEnum.MOBILE ? DisplayModeEnum.MOBILE : DisplayModeEnum.LARGE"
                              [selectedRaceNav]="selectedRaceNav"
                              (updateRaceDetailsDrawer)="onDrawerContentChange($event)"
                              (toggleRaceDescription)="view === WageringViewEnum.MOBILE ? openMobileRaceDetails() : toggleDrawer($event)">
    </cdux-common-race-details>
</ng-template>
