import { LANG_RUNNER_DETAILS_ERRORS, LANG_RUNNER_DETAILS_ERRORS_NAME } from './runner-details/errors';
import { LANG_TRAINER_JOCKEY_DETAILS_ERRORS, LANG_TRAINER_JOCKEY_DETAILS_ERRORS_NAME } from './trainer-jockey-details/errors';
import { LANG_PROGRAM_TOOLTIPS_NAME, LANG_PROGRAM_TOOLTIPS_INFO } from './tooltips/tooltips';
import { LANG_TODAYS_RACES_INFO, LANG_TODAYS_RACES_NAME } from './todays-races/todays-races';
import { LANG_SUMMARY_STATS_CATEGORIES, LANG_SUMMARY_STATS_CATEGORIES_NAME } from 'languages/program/summary-stats/categories';
import { LANG_SUMMARY_STATS_ERRORS, LANG_SUMMARY_STATS_ERRORS_NAME } from 'languages/program/summary-stats/errors';

export const PROGRAM_LANGUAGE_DICTIONARY = {
    [LANG_RUNNER_DETAILS_ERRORS_NAME]: LANG_RUNNER_DETAILS_ERRORS,
    [LANG_TRAINER_JOCKEY_DETAILS_ERRORS_NAME]: LANG_TRAINER_JOCKEY_DETAILS_ERRORS,
    [LANG_PROGRAM_TOOLTIPS_NAME]: LANG_PROGRAM_TOOLTIPS_INFO,
    [LANG_TODAYS_RACES_NAME]: LANG_TODAYS_RACES_INFO,
    [LANG_SUMMARY_STATS_CATEGORIES_NAME]: LANG_SUMMARY_STATS_CATEGORIES,
    [LANG_SUMMARY_STATS_ERRORS_NAME]: LANG_SUMMARY_STATS_ERRORS,
    [LANG_TRAINER_JOCKEY_DETAILS_ERRORS_NAME]: LANG_TRAINER_JOCKEY_DETAILS_ERRORS
};
