import { Observable, concat, of } from 'rxjs';
import {
    finalize,
    takeUntil,
    distinctUntilChanged,
    share,
    startWith,
    map,
    withLatestFrom
} from 'rxjs/operators';

import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { ITrackBasic, TrackService, ITrack } from '@cdux/ng-common';

import { InitialRaceNavSelector } from './initial-race-nav-selector.class';

export class RaceNavManager extends CduxRxJSBuildingBlock<any, ITrackBasic> {
    protected _stream: Observable<ITrackBasic | ITrack>;
    private _startup: Observable<ITrackBasic>;

    /** CONTROLS **/
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _trackOptions: Observable<ITrackBasic[] | ITrack[]>,
        private _trackSelections: Observable<ITrackBasic>,
        initialValue?: ITrackBasic
    ) {
        super();
        /** CONTROLS INIT **/
        const initialTrack = initialValue ? this._trackSelections.pipe(startWith(initialValue)) : this._trackSelections;
        this._startup = initialValue ? of(initialValue) : new InitialRaceNavSelector(this._trackOptions, initialTrack).listen();
        /** END CONTROLS INIT **/
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        // Concat so that initialization completes before reacting strictly to track
        // and race changes.
        this._stream = concat(this._startup, this._trackSelections).pipe(
            distinctUntilChanged((prev, curr) => TrackService.isExactTrackObject(prev, curr)),
            // startWith null to avoid a race condition on initialization
            withLatestFrom(this._trackOptions.pipe(startWith(null))),
            map(([track, trackList]) => {
                if (!!trackList) {
                    const matchingTrack: ITrackBasic = TrackService.findTrackInList(track.BrisCode, track.TrackType, trackList);
                    if (!!matchingTrack) {
                        // The bris code and track type were valid.
                        // Use the DisplayName from trackList track if updated track DisplayName is different.
                        // This will actually changed a slugified display name when taken from the url
                        if (track.DisplayName.toLocaleLowerCase() !== matchingTrack.DisplayName.toLocaleLowerCase()) {
                            track.DisplayName = matchingTrack.DisplayName;
                        }

                        // The trackList is going to have ITracks but the track that comes in may be an ITrackBasic.
                        // We need the AllowsConditionalWagering property that exists on the ITrack to be passed down to
                        // other components as the wagerState. This approach means RaceNum may be out of sync with the
                        // other race related data on the track (like MTP).
                        track = { ...matchingTrack, ...track, RaceNum: track.RaceNum || matchingTrack.RaceNum };
                    }
                }
                return track;
            }),
            finalize(() => this.kill()),
            takeUntil(this._kill),
            share()
        );
    }

    /** CUSTOM OPERATORS **/
    /** END CUSTOM OPERATORS **/
}
