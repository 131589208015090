import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { Subscription } from 'rxjs';
import { VideoBarAbstractComponent } from 'app/wagering/video/components/video-bars/video-bar-abstract.component';

@Component({
    selector: 'cdux-video-tab-bar',
    templateUrl: './video-tab-bar.component.html',
    styleUrls: ['video-tab-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoTabBarComponent extends VideoBarAbstractComponent implements OnDestroy {
    @Input()
    public inlineTabs: boolean = false;

    private _subscriptions: Subscription[] = [];

    constructor(
        protected changeDetector: ChangeDetectorRef,
        protected eventTrackingService: EventTrackingService
    ) {
        super(changeDetector, eventTrackingService);
    }

    ngOnDestroy() {
        this._subscriptions.forEach((sub) => sub.unsubscribe());
        this._subscriptions = [];
    }

    public liveOnly(): boolean {
        // set the liveOnly state when not on standalone and when the live race is the same as the selected race and live race number is present
        return !this.onStandalone && (this._isFirstRace() || this._isFutureRace()) && this._isRaceOpen();
    }

    private _isFutureRace(): boolean {
        return !!this.liveRaceNumber && this.race >= this.liveRaceNumber;
    }

    private _isFirstRace(): boolean {
        return !!this.liveRaceNumber && this.liveRaceNumber === 1;
    }
}
