import { ProgramEntry } from '@cdux/ng-common';

export class VisibleProgramEntry extends ProgramEntry {
    public Padded: boolean;
    constructor(entry: ProgramEntry, padded: boolean) {
        super({
            alsoEligible: entry.AlsoEligible,
            apprentice: entry.Apprentice,
            apprenticeType: entry.ApprenticeType,
            apprenticeWeight: entry.ApprenticeWeight,
            averageClass: entry.AverageClass,
            averagePaceE1: entry.AveragePaceE1,
            averagePaceE2: entry.AveragePaceE2,
            averagePaceLP: entry.AveragePaceLP,
            averageSpeed: entry.AverageSpeed,
            averageSpeedLast3: entry.AverageSpeedLast3,
            bestSpeed: entry.BestSpeed,
            bestSpeedAtDistance: entry.BestSpeedAtDistance,
            bettingInterest: entry.BettingInterest,
            blinkers: entry.Blinkers,
            color: entry.Color,
            colorDescription: entry.ColorDescription,
            commentsNegative: entry.CommentsNegative,
            commentsPositive: entry.CommentsPositive,
            damName: entry.DamName,
            damId: entry.DamId,
            daysOff: entry.DaysOff,
            earlyPace: entry.EarlyPace,
            formattedMedication: entry.FormattedMedication,
            silkStatus: entry.SilkStatus,
            silkUriPath: entry.SilkUriPath,
            equipment: entry.Equipment,
            entryId: entry.EntryId,
            horseClaimingPrice: entry.HorseClaimingPrice,
            expertPickRank: entry.ExpertPickRank,
            jockeyId: entry.JockeyId,
            jockeyName: entry.JockeyName,
            jockeyFirstName: entry.JockeyFirstName,
            jockeyMiddleName: entry.JockeyMiddleName,
            jockeyLastName: entry.JockeyLastName,
            jockeyChange: entry.JockeyChange,
            lastClass: entry.LastClass,
            latePace: entry.LatePace,
            liveOdds: entry.LiveOdds,
            liveOddsFavorite: entry.LiveOddsFavorite,
            mainTrackOnly: entry.MainTrackOnly,
            medication: entry.Medication,
            midPace: entry.MidPace,
            morningLineOdds: entry.MorningLineOdds,
            morningLineFavorite: entry.MorningLineFavorite,
            name: entry.Name,
            oddsRank: entry.OddsRank,
            oddsTrend: entry.OddsTrend,
            overweightAmount: entry.OverweightAmount,
            ownerName: entry.OwnerName,
            postPosition: entry.PostPosition,
            powerRating: entry.PowerRating,
            primePower: entry.PrimePower,
            priorRunStyle: entry.PriorRunStyle,
            profitlineOdds: entry.ProfitLineOdds,
            programNumber: entry.ProgramNumber,
            scratched: entry.Scratched,
            sex: entry.Sex,
            sireName: entry.SireName,
            sireId: entry.SireId,
            sortableProgramNumber: entry.SortableProgramNumber,
            speedPoints: entry.SpeedPoints,
            trainerName: entry.TrainerName,
            trainerFirstName: entry.TrainerFirstName,
            trainerMiddleName: entry.TrainerMiddleName,
            trainerLastName: entry.TrainerLastName,
            trainerId: entry.TrainerId,
            weight: entry.Weight,
            whelpDate: entry.WhelpDate,
            whereBred: entry.WhereBred,
            yob: entry.Yob,
        });
        this.Padded = padded;
    }
}
