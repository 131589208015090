import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EventSessionModule, TranslateModule } from '@cdux/ng-common';
import {
    AccountHistoryFilterModule,
    SharedModule as SharedFragmentsModule,
    LoadingModule,
    DatepickerLiteModule
} from '@cdux/ng-fragments';
import { CduxFormsModule, CduxMediaModule } from '@cdux/ng-platform/web';
import { TuxCommonModule } from '../common/common.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { SsnCollectionModule } from '../ssn-collection/ssn-collection.module';
import { TransactionModule } from '../transaction/transaction.module';
import {
    AccountHistoryBusinessService,
    AccountHistorySidebarComponent,
    CduxTransactionComponent
} from './account-history';
import { CashierComponent } from './cashier/cashier.component';
import { AccountBubbleNotificationComponent } from './my-account-container/account-bubble-notification.component';
import { ManageModule } from './manage/index';
import { MyAccountContainerComponent } from './my-account-container/my-account-container.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { RewardsContainerComponent } from './rewards/components/rewards-container/rewards-container.component';
import { RewardsSuccessComponent } from './rewards/components/rewards-redemption-success/rewards-redemption-success.component';
import { TaxableEventListComponent } from './tax-documents/components/taxable-event-list/taxable-event-list.component';
import { TaxableTransactionComponent } from './tax-documents/components/taxable-transaction/taxable-transaction.component';
import { TaxableYearListComponent } from './tax-documents/components/taxable-year-list/taxable-year-list.component';
import { MfaPreferencesComponent } from "./multi-factor-authentication/mfa-preferences/mfa-preferences.component";
import { EnableMfaFormComponent } from "./multi-factor-authentication/enable-mfa-form/enable-mfa-form";
import { MfaFormHeaderComponent } from "./multi-factor-authentication/enable-mfa-form/mfa-form-header";
import { MfaToggleComponent } from "./multi-factor-authentication/mfa-toggle/mfa-toggle.component";

@NgModule({
  imports: [
    AccountHistoryFilterModule,
    CduxFormsModule,
    CduxMediaModule,
    CommonModule,
    DatepickerLiteModule,
    DatepickerModule,
    EventSessionModule,
    LoadingModule,
    ManageModule,
    ReactiveFormsModule,
    RouterModule,
    SsnCollectionModule,
    TransactionModule,
    TranslateModule,
    TuxCommonModule,
    SharedFragmentsModule,
  ],
    declarations: [
    AccountBubbleNotificationComponent,
    CduxTransactionComponent,
    MyAccountContainerComponent,
    AccountHistorySidebarComponent,
    CashierComponent,
    PreferencesComponent,
    RewardsContainerComponent,
    RewardsSuccessComponent,
    TaxableYearListComponent,
    TaxableEventListComponent,
    TaxableTransactionComponent,
    MfaPreferencesComponent,
    EnableMfaFormComponent,
    MfaFormHeaderComponent,
    MfaToggleComponent,
    ],
  exports: [
    AccountBubbleNotificationComponent,
    DatepickerModule,
    MyAccountContainerComponent,
    TransactionModule,
  ]
})
export class MyAccountModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<MyAccountModule> {
        return {
            ngModule: MyAccountModule,
            providers: [
                AccountHistoryBusinessService,
            ],
        }
    }
}
