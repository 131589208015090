import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { enumDepositOptions } from '../abstract-method.component';
import { FundingAbstractMethodDepositComponent } from '../abstract-method-deposit.component';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-moneygram-method-fullpage-deposit',
    templateUrl: './moneygram-method-deposit-fullpage.component.html',
    styleUrls: ['./moneygram-method-deposit-fullpage.component.scss']
})

export class FundingMoneygramMethodDepositFullpageComponent extends FundingAbstractMethodDepositComponent {

    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    public static getComponent(options: IFundingMethodComponentProperties): any {
        return super.createSidebarPortal(FundingMoneygramMethodDepositFullpageComponent, options);
    }

    constructor(
        localRouter: Router,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        _featureToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localHeaderService,
            localEnvironment,
            localFb,
            localFundingService,
            localSidebarService,
            localEventTrackingService,
            localTranslateService,
            localSessionService,
            _featureToggleService
        );
        this.operationMethod = enumDepositOptions.MONEYGRAM;
    }

    public get hasVerifiedAccount(): boolean {
        return this.fundingMethodDetails['verified'];
    }
}
