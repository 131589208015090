import { Component, Input, OnInit } from '@angular/core';
import { LoadingService, LoadingDotsComponent} from '@cdux/ng-fragments';

import { CduxSidebarContentComponent } from 'app/shared/sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { Router } from '@angular/router';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { BetsViewEnum } from 'app/shared/bets/components/bets-container/bets-container.component';
import { BetSlipContainerComponent } from 'app/shared/bets/components/bet-slip-container/bet-slip-container.component';

@Component({
    selector: 'cdux-todays-bets-container',
    templateUrl: './todays-bets-container.component.html',
    styleUrls: ['./todays-bets-container.component.scss']
})
export class TodaysBetsContainerComponent extends CduxSidebarContentComponent implements OnInit {
    @Input()
    public programView: enumProgramViews;

    public loadingDotsComponent = LoadingDotsComponent;

    public myBetsLoaded: boolean = false;
    public completedBetsLoaded: boolean = false;

    private _loaded: boolean = false;

    /*****************************
     * Sidebar Content Component Implementation
     *****************************/
    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: TodaysBetsContainerComponent,
            properties: {
                navTarget: MenuItemsEnum.TODAYS_BETS
            }
        }
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return null;
    }

    public setProperties(properties: ISidebarComponentProperties): void {}
    /*****************************/

    constructor(
        private _loadingService: LoadingService,
        private _router: Router,
        private _sidebarService: SidebarService
    ) {
        super();
    }

    ngOnInit() {
        this._loadingService.register('todaysBetsContainer');
    }

    public checkAllLoaded() {
        if (this.myBetsLoaded && this.completedBetsLoaded && !this._loaded) {
            this._loaded = true;
            this._loadingService.resolve('todaysBetsContainer', -1);
        }
    }

    public goToAccountHistory(phone ?: boolean) {
        if (phone) {
            this._router.navigate(['account', 'history']);
            this._sidebarService.close();
            // TODO temp comment out this._sidebarService.loadComponent(AccountHistoryWrapperComponent.getSidebarComponent(), AccountHistoryWrapperComponent.getHeaderComponent());
        } else {
            this._router.navigate(['account', 'history']);
            this._sidebarService.close(true);
        }

    }

    public openMenuItem(item: BetsViewEnum) {
        switch (item) {
            case BetsViewEnum.BET_SLIP:
                this._sidebarService.loadComponent(
                    BetSlipContainerComponent.getSidebarComponent(),
                    null,
                    { clearHistory: true }
                );
                break;
            default:
        }
    }
}
