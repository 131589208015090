import { Component, OnInit, Input } from '@angular/core';
import { ENVIRONMENT, IUserInfo } from '@cdux/ng-core';
import { JwtSessionService } from '@cdux/ng-common';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';

@Component({
    selector: 'cdux-moneygram-method-details',
    templateUrl: './moneygram-method-deposit-details.component.html'
})

export class FundingMoneygramMethodDepositDetailsComponent implements OnInit {

    @Input()
    public displayStyle: enumFundingDisplayStyle;

    public AFFILIATE_KEY: string;
    public account: string;
    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    constructor(
        protected _environment: ENVIRONMENT,
        protected _sessionService: JwtSessionService
    ) {}

    public ngOnInit() {
        this.AFFILIATE_KEY = this._environment.affiliateId.toString();
        const user: IUserInfo = this._sessionService.getUserInfo();
        if (user) {
            this.account = user.account;
        }
    }
}
