import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { PopUpModule } from 'app/shared/pop-up/pop-up.module';

import { enumFeatureToggle } from '@cdux/ng-common';
import { LoadingModule, MtpModule } from '@cdux/ng-fragments';
import { CduxFormsModule, CduxSimpleModalModule } from '@cdux/ng-platform/web';

import { AuthGuard, FeatureToggleGuard } from 'app/shared/common/guards';
import { LayoutHeaderFooterComponent } from 'app/shared/layout/components/layout-header-footer/layout-header-footer.component';
import { DEFAULT_REDIRECT_PATH } from 'app/app.routing.constants';

import { FavoritesManagerComponent } from './favorites-manager.component';
import { FavoritesManagerEmptyComponent } from './favorites-manager-empty.component';
import { FavoritesManagerRunnersComponent } from './manager-runners/favorites-manager-runners.component';
import { FavoritesManagerTracksComponent } from './manager-tracks/favorites-manager-tracks.component';
import { FavoritesTileRunnerComponent } from './manager-runners/favorites-tile-runner.component';
import { FavoritesTileTrackComponent } from './manager-tracks/favorites-tile-track.component';
import { FavoriteToggleRunnerComponent } from './toggles/favorite-toggle-runner.component';
import { MyFavComponent } from './my-fav.component';
import { FavoritesTileTrainerComponent } from './manager-trainers/favorites-tile-trainer.component';
import { FavoritesManagerTrainersComponent } from './manager-trainers/favorites-manager-trainers.component';
import { FavoriteTogglePersonComponent } from './toggles/favorite-toggle-person.component';
import { FavoritesManagerJockeysComponent } from './manager-jockeys/favorites-manager-jockeys.component';
import { FavoritesTileJockeyComponent } from './manager-jockeys/favorites-tile-jockey.component';
import { FullScreenDrawerModule } from 'app/shared/full-screen-drawer/full-screen-drawer.module';
import { FavoritesStableSearchComponent } from './favorites-stable-search/favorites-stable-search.component';
import { FavoritesManagerSettingsComponent } from './manager-settings/favorites-manager-settings.component';
import { FavoriteStatsRunnerComponent } from './manager-runners/favorites-stats-runner.component';
import { FavoriteStatsPastPerformancesComponent } from './manager-runners/favorites-stats-past-performances.component';
import { FavoriteStatsTrainerComponent } from './manager-trainers/favorites-stats-trainer.component';
import {FavoritesStatsJockeyComponent} from "./manager-jockeys/favorites-stats-jockey.component";
import { TournamentGuardChild } from 'app/shared/common/guards/tournaments/tournament-guards';

const routes: Route[] = [{
    path: 'favorites',
    canActivate: [ AuthGuard, FeatureToggleGuard ],
    canActivateChild: [ AuthGuard, TournamentGuardChild ],
    component: LayoutHeaderFooterComponent,
    data: {
        featureToggle: enumFeatureToggle.STABLE_ALERTS,
        redirectUrl: DEFAULT_REDIRECT_PATH
    },
    children: [
        {
            path: 'manager',
            component: FavoritesManagerComponent,
        },
        {
            path: '',
            pathMatch: 'prefix',
            redirectTo: 'manager',
        },
        {
            path: 'manager/:favoriteType',
            component: FavoritesManagerComponent,
        }
    ]
}];

@NgModule({
    declarations: [
        FavoritesManagerComponent,
        FavoritesManagerEmptyComponent,
        FavoritesManagerRunnersComponent,
        FavoritesManagerTracksComponent,
        FavoritesManagerTrainersComponent,
        FavoritesManagerJockeysComponent,
        FavoritesStableSearchComponent,
        FavoritesManagerSettingsComponent,
        FavoritesTileRunnerComponent,
        FavoritesTileTrackComponent,
        FavoritesTileTrainerComponent,
        FavoritesTileJockeyComponent,
        FavoriteToggleRunnerComponent,
        FavoriteTogglePersonComponent,
        MyFavComponent,
        FavoriteStatsRunnerComponent,
        FavoritesStatsJockeyComponent,
        FavoriteStatsTrainerComponent,
        FavoriteStatsPastPerformancesComponent,
    ],
    imports: [
        CduxSimpleModalModule,
        CommonModule,
        FormsModule,
        PortalModule,
        PopUpModule,
        CduxFormsModule,
        LoadingModule,
        FullScreenDrawerModule,
        MtpModule,
        RouterModule.forChild(routes),
    ],
    exports: [
        FavoritesManagerComponent,
        FavoriteToggleRunnerComponent,
        FavoriteTogglePersonComponent,
        MyFavComponent,
    ]
})
export class FavoritesModule {}
