import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    ReplaySubject,
    throwError
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    publishReplay,
    refCount,
    switchMap,
    tap
} from 'rxjs/operators';

import {
    Bet,
    CdiDataService,
    Entry,
    EntrySelectionService,
    enumTrackType,
    IAdwRace,
    IOddsMtpPost,
    IPoolType,
    IProfitLineOdds,
    IRaceIdentifier,
    IToteRace,
    ITrack,
    ProgramDataService,
    Scratch,
    ToteDataService,
    RaceConstants,
    CduxObjectUtil,
    SortingBusinessService,
} from '@cdux/ng-common';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { CduxArrayUtil } from 'app/shared/common/utils';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { enumProgramSort } from 'app/shared/program/enums/program-sort-columns.enum';
import { MtpInfo } from 'app/shared/program/interfaces/mtp-info.interface';
import { NoncurrentRacesConfigService } from 'app/shared/program/services/noncurrent-races-config.service';
import { ProgramModuleBusinessService } from './program-module.business.service';
import { ProgramNavBusinessService } from './program-nav.business.service';
import { isNull } from 'util';

@Injectable({
    providedIn: 'root'
})
export class ProgramListBusinessService {

    /**
     * Observables that are shared in order to avoid duplication of calls.
     *
     * @type {any[]}
     * @private
     */
    private _entriesObservables: Observable<Entry[]>[] = [];
    private _paddedEntriesObservables: Observable<Entry[]>[] = [];
    private _raceDetailsObservables: Observable<IToteRace>[] = [];

    private _isMultiPick: boolean;
    get isMultiPick(): boolean { return this._isMultiPick; }
    set isMultiPick(flag: boolean) { this._isMultiPick = flag; }

    private _isMultiColumn: boolean;
    get isMultiColumn(): boolean { return this._isMultiColumn; }
    set isMultiColumn(flag: boolean) { this._isMultiColumn = flag; }

    private _isAdvanced: boolean;
    get isAdvanced(): boolean { return this._isAdvanced; }
    set isAdvanced(flag: boolean) { this._isAdvanced = flag; }

    private _activeLeg: number;
    get activeLeg(): number { return this._activeLeg; }
    set activeLeg(leg: number) { this._activeLeg = leg; this._activeLegChanges.next(leg); }
    private _activeLegChanges: BehaviorSubject<number> = new BehaviorSubject(1);
    public readonly activeLegChanges: Observable<number> = this._activeLegChanges.asObservable();

    private _currentProgramView: enumProgramViews = enumProgramViews.BASIC;
    get currentProgramView(): enumProgramViews { return this._currentProgramView; }
    set currentProgramView(view: enumProgramViews) { this._currentProgramView = view; this._currentProgramViewChanges.next(view); }
    private _currentProgramViewChanges: BehaviorSubject<enumProgramViews> = new BehaviorSubject(null);
    public readonly currentProgramViewChanges: Observable<enumProgramViews> = this._currentProgramViewChanges.asObservable();

    /**
     * Entries
     */
    private _entries: Entry[];
    get entries(): Entry[] { return this._entries; }

    /**
     * Selected Entries
     */
    private _selectedEntries: Entry[];
    get selectedEntries(): Entry[] { return this._selectedEntries; }

    /**
     * Padded Entries
     */
    private _paddedEntries: Entry[];
    get paddedEntries(): Entry[] { return this._paddedEntries; }

    /**
     * Scratches
     */
    private _scratches: Scratch;
    get scratches(): Scratch { return this._scratches; }
    private _scratchesChanges: ReplaySubject<Scratch> = new ReplaySubject(1);
    public readonly scratchesChanges: Observable<Scratch> = this._scratchesChanges.asObservable();

    /**
     * Race Details
     */
    private _raceDetails: IToteRace | null;
    get raceDetails(): IToteRace { return this._raceDetails; }

    /**
     * MTP Information
     */
    private _mtpInfo: MtpInfo;
    get mtpInfo(): MtpInfo { return this._mtpInfo; }
    private _mtpInfoChanges: ReplaySubject<MtpInfo> = new ReplaySubject(1);
    public readonly mtpInfoChanges: Observable<MtpInfo> = this._mtpInfoChanges.asObservable();
    private _hasLiveOdds: ReplaySubject<boolean> = new ReplaySubject(1);
    public readonly hasLiveOdds: Observable<boolean> = this._hasLiveOdds.asObservable();

    /**
     * Constructor
     *
     * @param {CdiDataService} cdiService
     * @param {ToteDataService} toteService
     * @param {ProgramDataService} programService
     * @param {BetSlipBusinessService} betSlipService
     * @param {ProgramModuleBusinessService} programModuleService
     * @param {ProgramNavBusinessService} programNavService
     * @param {SortingBusinessService} sortingService
     */
    constructor(
        private betSlipService: BetSlipBusinessService,
        private cdiService: CdiDataService,
        private noncurrentRacesConfigService: NoncurrentRacesConfigService,
        private programModuleService: ProgramModuleBusinessService,
        private programNavService: ProgramNavBusinessService,
        private programService: ProgramDataService,
        private toteService: ToteDataService,
        private entrySelectionService: EntrySelectionService,
        private wageringUtil: WageringUtilBusinessService
    ) {}

    /**
     * Get the necessary initial values.
     */
    public resolve(track: ITrack, race: IAdwRace): Observable<any> {
        // If we need to hold initialize for certain observables, add them here.
        // Needs to return Entries and RaceDetails
        return combineLatest([
            this.getPaddedEntriesChanges(race, race),
            this.getRaceDetailsChanges(track, race),
            this.programModuleService.getAllRacesObservable(track)
        ]);
    }

    /**
     * Compares the other legs of a multi-race wager and makes sure all program numbers exist in the current leg.
     *
     * @param {IAdwRace} targetRace
     * @param {IAdwRace} currentRace
     * @returns {Observable<Entry[]>}
     */
    public getPaddedEntriesChanges(currentRace: IAdwRace, targetRace: IAdwRace) {

        if (!targetRace) {
            return throwError('Unable to retrieve padded entries: No Target Race');
        }

        if (!currentRace) {
            return throwError('Unable to retrieve padded entries: No Current Race');
        }

        const track = this.programNavService.tracks
            .filter(t => t.AtabCode.toUpperCase() === targetRace.track.toUpperCase())[0];

        if (!track) {
            return throwError('Unable to retrieve padded entries: No Track from Track List');
        }

        // I only want to use polling on the current targetRace of a track.
        const poll = +track.RaceNum === +targetRace.race;

        const key = `${targetRace.track}-${currentRace.race}-${targetRace.race}-${poll ? 1 : 0}`;

        // Store the observable for sharing.
        if (this._paddedEntriesObservables[key]) {
            return this._paddedEntriesObservables[key];
        }

        // Get the currently selected pool type.
        const poolTypeChanges = this.betSlipService.onBetChangeReplay.pipe(
            filter(b =>
                !!b.poolType
                && !!b.race
                && b.race.race === currentRace.race
                && b.race.track === currentRace.track),
            distinctUntilChanged((before, after) => {
                const bPoolType = before.poolType || {Code: 'WN'} as IPoolType;
                return bPoolType.Code === after.poolType.Code
            }),
            map((bet): IPoolType => {
                return bet.poolType
            })
        );

        const entriesChanges = poolTypeChanges.pipe(
            map(poolType => poolType.MultipleRace),
            distinctUntilChanged(),
            switchMap((multipleRace: boolean) =>
                multipleRace
                    ? this.getEntriesChanges(targetRace)
                    : this.getEntriesChanges(currentRace))
        );

        // Watch entries, pool type, and race changes.
        this._paddedEntriesObservables[key] = combineLatest(
                entriesChanges,
                poolTypeChanges,
                this.programModuleService.getAllRacesObservable(track),
                of(currentRace)
            ).pipe(
                filter(([entries, poolType, allRaces, race]) => !!allRaces && !!poolType),
                map(([entries, poolType, allRaces, race]) => {
                    // If it's not a multi-race wager and only has one leg, then no padding is necessary.
                    if (!poolType.MultipleRace && +poolType.Legs === 1) {
                        this._paddedEntries = entries;
                        return entries;
                        // make sure we short-circuit this subscription, so field entries aren't duplicated for multi-leg, single-race wagers
                    }

                    // Find the index of the current race in the allRaces array.
                    const raceIndex = allRaces.findIndex((r: any) => r.RaceNumber === race.race);
                    const racesProgramNumbers = [];
                    if (raceIndex > -1) {
                        for (let i = 0; i < +poolType.Legs; i++) {
                            // If it's spread across multiple races, get the race information from the corresponding leg.
                            // If it's only got multiple legs, but not races, reuse the same race's information.
                            const tempRace: any = poolType.MultipleRace ? allRaces[raceIndex + i] : allRaces[raceIndex];
                            if (tempRace) {
                                const programNumbers = tempRace.Entries
                                    .map(entry => entry.ProgramNumber);
                                // Get the entries' program numbers.
                                racesProgramNumbers.push(programNumbers);
                            }
                        }
                    }

                    // Get all of the distinct program numbers.
                    const ghostEntries = [];
                    const programNumbersSet = CduxArrayUtil.toSet(...racesProgramNumbers);

                    programNumbersSet
                        .forEach(num => {
                            const entryIndex = entries.findIndex(e => e.ProgramNumber === num);

                            if (entryIndex === -1) {
                                // Make a ghost / padding entry to hold the place for any entry that doesn't
                                // actually exist in this leg.
                                // It pulls from the runners on the bet because this service only keeps the
                                // selected entries for the current leg, whereas the bet slip service keeps
                                // a record of the selected entries from all legs.
                                const tempEntry = new Entry({ ProgramNumber: num });
                                this.setSelectedEntries(tempEntry, this.betSlipService.currentBet.runners, +race.race);
                                ghostEntries.push(tempEntry);
                            }
                        });

                    const paddedEntries = [...entries];
                    paddedEntries.push(...ghostEntries);
                    paddedEntries
                        .forEach(entry => {
                            this.setEntryExistence(entry, racesProgramNumbers);
                        });
                    // Set the padded entries.
                    this._paddedEntries = paddedEntries;
                    return paddedEntries;
                }),
                finalize(() => {
                    delete this._paddedEntriesObservables[key];
                }),
                publishReplay(1),
                refCount()
            );

        return this._paddedEntriesObservables[key];
    }

    /**
     * Gets an observable of the race details.
     *
     * @returns {Observable<IToteRace>}
     */
    public getRaceDetailsChanges(track: ITrack, race: IAdwRace): Observable<IToteRace> {
        if (!track) {
            return throwError('Unable to retrieve race details: No Track.');
        }

        if (!race) {
            return throwError('Unable to retrieve race details: No Race.');
        }

        const key = `${track.BrisCode}-${race.race}`;

        // Store the observable for sharing.
        if (this._raceDetailsObservables[key]) {
            return this._raceDetailsObservables[key];
        }

        // Return the appropriate race details.
        this._raceDetailsObservables[key] = this.programModuleService.getAllRacesObservable(track).pipe(
            tap(allRacesData => this.programModuleService.allRacesData = allRacesData),
            map(allRacesData => {
                for (const row of allRacesData) {
                    if (row.hasOwnProperty('RaceNumber') && row['RaceNumber'] === race.race) {
                        this._raceDetails = row;
                        return this._raceDetails;
                    }
                }
            }),
            finalize(() => {
                delete this._raceDetailsObservables[key];
            }),
            publishReplay(1),
            refCount()
        );

        return this._raceDetailsObservables[key];
    }

    /**
     * Gets an observable of entry changes.
     *
     * @param {IAdwRace} race
     * @returns {Observable<Entry[]>}
     */
    public getEntriesChanges(race: IAdwRace): Observable<Entry[]> {
        if (!race) {
            return throwError('Unable to retrieve entries changes: No Race');
        }

        const track = this.programNavService.tracks
            .filter(t => t.AtabCode.toUpperCase() === race.track.toUpperCase())[0];

        // I only want to use polling on the current race of a track. Also, not for the will pays component.
        const poll = (+track.RaceNum === +race.race) || this.noncurrentRacesConfigService.isRaceAnException({
            'brisCode':   track.BrisCode,
            'trackType':  track.TrackType,
            'raceNumber': +race.race
        } as IRaceIdentifier);

        const key = `${race.track}-${race.race}-${poll ? 1 : 0}`;

        // Store the observable for sharing.
        if (this._entriesObservables[key]) {
            return this._entriesObservables[key];
        }

        // Set up default data sources.
        const allRacesSource = this.programModuleService.getAllRacesObservable(track);
        // Casting as Observable<Scratch> here because integrated scratches returns
        // a single Scratch object when the race number is specified
        const integratedScratchesSource = this.cdiService.integratedScratches(track.BrisCode, track.TrackType, +race.race, poll) as Observable<Scratch>;
        const oddsMTPPostSource = this.toteService.oddsMtpPost(track.BrisCode, track.TrackType, '' + race.race, poll);
        const profitLineSource = track.TrackType === enumTrackType.TBRED ?
            this.programService.profitline(track.BrisCode, track.TrackType, '' + race.race, poll) :
            of([] as IProfitLineOdds[]);
        const betChangeSource = this.betSlipService.onBetChangeReplay;
        // Return an observable of entries changes.
        this._entriesObservables[key] = combineLatest(
            allRacesSource,
            integratedScratchesSource,
            oddsMTPPostSource,
            profitLineSource,
            betChangeSource
        ).pipe(
            map(([allRaces, integratedScratches, oddsMTP, profitLine, bet]) => {
                return [
                    this.processCombinedData(
                        track.TrackType,
                        race,
                        allRaces,
                        integratedScratches,
                        oddsMTP,
                        profitLine
                    ),
                    bet
                ] as [Entry[], Bet];
            }),
            map(([entries, bet]) => this.updateSelectedEntries(entries, bet.runners, +race.race)),
            finalize(() => {
                delete this._entriesObservables[key];
            }),
            publishReplay(1),
            refCount()
        );

        return this._entriesObservables[key];
    }

    /**
     * Processes the all of the combined data to build the entries.
     *
     * @param {enumTrackType} trackType
     * @param {IAdwRace} selectedRace
     * @param {IToteRace[]} allRaces
     * @param {Scratch} integratedScratches
     * @param {IOddsMtpPost} oddsMTP
     * @param {IProfitLineOdds[]} profitLine
     * @returns {Entry[]}
     */
    private processCombinedData(
        trackType: enumTrackType,
        selectedRace: IAdwRace,
        allRaces: IToteRace[],
        integratedScratches: Scratch,
        oddsMTP: IOddsMtpPost,
        profitLine: IProfitLineOdds[]
    ): Entry[] {
        const integrated = integratedScratches || {} as Scratch;
        const odds = oddsMTP;
        const profit = profitLine;

        this.programModuleService.allRacesData = allRaces;
        this._scratches = integrated;
        this._scratchesChanges.next(integrated);
        if (odds && odds.MtpInfo) {
            this._mtpInfo = odds.MtpInfo;
            this._mtpInfoChanges.next(odds.MtpInfo);
        }

        return this.setCurrentEntries(trackType, selectedRace, allRaces, integrated, odds, profit);
    }

    /**
     * Set all of the current entries.
     *
     * @param {enumTrackType} trackType
     * @param {IAdwRace} selectedRace
     * @param {IToteRace[]} allRaces
     * @param {Scratch} integrated
     * @param {IOddsMtpPost} odds
     * @param {IProfitLineOdds[]} profit
     * @returns {Entry[]}
     */
    private setCurrentEntries(
        trackType: enumTrackType,
        selectedRace: IAdwRace,
        allRaces: IToteRace[],
        integrated: Scratch,
        odds: IOddsMtpPost,
        profit: IProfitLineOdds[]
    ): Entry[] {
        const race = allRaces.filter((tempRace => tempRace['RaceNumber'] === selectedRace.race))[0];
        if (!race) {
            console.warn('Entries not found for this race.');
            return [];
        }
        const entriesData = [];
        const firstFieldProgram: string = this.findFirstField(race['Entries']);
        const selectedEntries = this.betSlipService.currentBet.runners;

        let lowestOdds: number = Number.MAX_VALUE;
        // Sort the odds.
        const sortFn = function order(a, b) {
            return a < b ? -1 : (a > b ? 1 : 0);
        };

        let showLiveOdds = false;
        let lowestLiveOdds: number;
        /* get lowestLiveOdds and set showLiveOdds
            if our lowest WinOdds value is 99,
            then we assume we are not recieving live odds */
        if (!!odds.WinOdds && !!odds.WinOdds.Entries && odds.WinOdds.Entries.length > 0) {
            lowestLiveOdds = odds.WinOdds.Entries
                // map entries to numeric value of NumOdds
                .map(entry => +entry['NumOdds'])
                // filter out scratches (odds = -1) and NaNs
                .filter(entry => ((entry > 0) && (entry <= 99)))
                // sort and return the first/lowest
                .sort(sortFn)[0];
            if (lowestLiveOdds !== 99) {
                showLiveOdds = true;
                lowestOdds = lowestLiveOdds;
            }
            this._hasLiveOdds.next(showLiveOdds);
        }
        if (!showLiveOdds) {
            // Get the lowest morning line odds.
            lowestOdds = race['Entries']
            // filtering the entries for odds greater than 0 as odds on scratch horses are getting as -1
                .map(entry => {
                    if (entry.MorningLineOdds) {
                        if (entry.MorningLineOdds.indexOf('/') > 0) {
                            const sections: string[] = entry.MorningLineOdds.split('/');
                            return parseFloat(sections[0]) / parseFloat(sections[1]);
                        } else {
                            return parseFloat(entry.MorningLineOdds);
                        }
                    } else {
                        return 0;
                    }
                })
                .filter(morningLineOdds => morningLineOdds > 0)
                .sort(sortFn)[0];
        }

        let isFutureRace: boolean;
        if (odds.MtpInfo) {
            isFutureRace = odds.MtpInfo.Mtp === '99';
        }

        // Generate each entry.
        for (const entryObj of race['Entries']) {
            const entry: Entry = new Entry(entryObj);
            entry.FirstFieldProgramNumber = firstFieldProgram;
            entry.SaddleClothClass = this.getSaddleClothClass(trackType, entry);
            entry.isFutureRace = isFutureRace;

            if (entry.TrainerName) {
                entry.TrainerName = this.formatTrainerName(entry.TrainerName);
            }

            if (!!entry.JockeyName) {
                /**
                 * If jockey name in response is one of the formats:
                 *      1. 'lastName, firstName',
                 *      2. 'lastName, firstName M.I.'
                 * then reorder the name by splitting on the comma.
                 * Otherwise don't modify the name.
                 */
                if (entry.JockeyName.indexOf(',') > 0) {
                    const jockeyParts = entry.JockeyName.split(',');
                    entry.JockeyName = jockeyParts[1].trim() + ' ' + jockeyParts[0].trim();
                }
            }

            // Set current scratches.
            this.setCurrentScratches(entry, integrated, odds);

            // Set unscratched entry's odds from Live Odds
            if (odds.WinOdds && !entry.isScratched) {
                this.setCurrentEntriesOdds(entry, odds.WinOdds.Entries);
            }

            // Set selected entries.
            this.setSelectedEntries(entry, selectedEntries, +race.RaceNumber);

            // Set up profit line odds.
            if (profit && profit.length > 0) {
                this.setProfitLineOdds(entry, profit);
            }
            entriesData.push(entry);
        }

        // updates ranking for speed/pace/class
        this._updateRankingFields(entriesData);

        // sets the displayed odds for each entry
        this.setCurrentEntriesOddsDisplay(entriesData, lowestOdds, showLiveOdds);

        this._entries = entriesData;

        return entriesData;
    }

    /**
     * Set the selected entries.
     *
     * @param entry
     * @param selectedEntries
     * @param race
     */
    private setSelectedEntries(entry, selectedEntries, race: number) {
        const bet = Bet.fromBetlike(this.betSlipService.currentBet);
        let runnerScratched: boolean = false;
        // We always want to loop through all the legs of the selected entries so we can call
        // entrySelectionService.applySelectionToLeg. However, if we are coming in from a quick bet that is multi race
        // we don't currently check each subsequent leg for scratches. We only currently have entries/scratch data for
        // the first leg.
        selectedEntries.forEach((legOfEntries, i) => {
            const entryAmongSelected = legOfEntries.filter(selectedEntry => entry.ProgramNumber === selectedEntry.ProgramNumber);

            if (entryAmongSelected.length > 0) {
                if (entry.isScratched) {
                    // if multi race then delete the runner if only scratched by race
                    const multipleRace = this.betSlipService.currentBet.poolType.MultipleRace;
                    // If we are coming in from a quick bet, programNavService.currentRace may still be null. If
                    // that's the case, we can assume that the race being passed in is the race that we will be
                    // initially loading in the program
                    const currentRace = isNull(this.programNavService.currentRace) ? race : +this.programNavService.currentRace.race;
                    const currentLegIndex = race - (currentRace);
                    /**
                     * The following conditional is kinda sucky, but necessary until we can remove the side-effect
                     * of updating the bet while getting ANY race's entries. What really needs to be done is that the
                     * entriesChanges function should have no interest in the state of the bet, nor setting it.
                     * The fact that it's setting it means that when another component calls for entries, it will
                     * end up impacting the selected entries of whatever the program-list is set to.
                     */
                    if ((multipleRace && currentLegIndex === i) || (!multipleRace && race === currentRace)) {
                          runnerScratched = true;
                          const index = bet.runners[i].findIndex(e => e.ProgramNumber === entry.ProgramNumber);
                          bet.runners[i].splice(index, 1);
                    }
                }

                // If the runner is determined to be a scratch, it gets removed from the bet above so we also
                // want to remove it from the current leg
                this.entrySelectionService.applySelectionToLeg(!runnerScratched, i, false, entry);
            }
        });
        if (runnerScratched) {
            this.betSlipService.currentBet = bet;
        }
    }

    /**
     * Determines which legs of a wager this entry exists on.
     * To explain: if the value of multiPickRacePresence is 10101 (21),
     * it would indicate that an entry does exist in legs 5, 3, and 1.
     *
     * Note: (1 << 2) would equal 00100. `|= 00100` says to set the 3rd bit.
     * Since the array index is zero-based it works naturally for letting the
     * first leg map to the first (zeroeth) bit.
     *
     * @param entry
     * @param programNumbersPerLeg
     */
    private setEntryExistence(entry, programNumbersPerLeg) {
        programNumbersPerLeg
            .forEach((legEntriesNumbers, index) => {
                if (legEntriesNumbers.indexOf(entry.ProgramNumber) > -1) {
                    EntrySelectionService.setEntryPresenceByIndex(true, index, entry);
                }
            });
    }

    /**
     * Set the current entry odds.
     *
     * @param {Entry} entry
     * @param {Object[]} entryOddsArray
     */
    private setCurrentEntriesOdds(entry: Entry, entryOddsArray: object[]) {
        entryOddsArray
            .filter(entryOdds => entryOdds['TextOdds'] !== RaceConstants.TEXT_ODDS_SCRATCH)
            .forEach(entryOdds => {
                if (entryOdds['ProgramNumber'] === entry.ProgramNumberCoupled) {
                    entry.TextOdds = entryOdds['TextOdds'];
                    entry.NumOdds = parseFloat(entryOdds['NumOdds']);
                } else {
                    // Watch for field entries too
                    if (entry.isFieldEntry) {
                        if (parseFloat(entry.ProgramNumberCoupled) >= parseFloat(entry.FirstFieldProgramNumber)) {
                            entry.TextOdds = entryOdds['TextOdds'];
                            entry.NumOdds = parseFloat(entryOdds['NumOdds']);
                        }
                    }
                }
            });
    }

    /**
     * Set the odds display
     * Makes a determination about live odds based on all the entries
     */
    private setCurrentEntriesOddsDisplay(entries: Entry[], lowestOdds: number, showLiveOdds: boolean) {
        // Set odds and determine if the entry is the favorite.
        entries.map((entry) => {
            if (entry.isScratched) {
                // Show that we're scratched
                entry['oddsDisplay'] = RaceConstants.TEXT_ODDS_SCRATCH;
                entry.isFavorite = false;
            } else {
                // Assume MorningLine
                entry['oddsDisplay'] = entry.MorningLineOddsFormatted;
                let entryOdds = entry.MorningLineOddsNum;
                if (showLiveOdds) {
                    // we have Live Odds, so show them instead
                    entryOdds = entry.NumOdds;
                    entry['oddsDisplay'] = entry.TextOdds;
                }
                // set favorite based on entryOdds
                entry.isFavorite = (entryOdds === lowestOdds) && (entryOdds !== 99);
            }
            return entry;
        });
    }

    /**
     * Set current scratches.
     *
     * @param {Entry} entry
     * @param {Scratch} scratch
     * @param {IOddsMtpPost} oddsMTP
     */
    private setCurrentScratches(entry: Entry, scratch: Scratch, oddsMTP: IOddsMtpPost) {
        // Check win odds data. Matching on the coupled program number because the program number on a win odds entry is actually a betting interest,
        // not the actual program number. If tote (win odds) scratches an entry, e.g. 1, then that means any coupled/field runners are also scratched.
        // So in that case the 1A and 1X would also be scratched.
        const entryOdds = CduxObjectUtil.deepGet(oddsMTP, 'WinOdds.Entries') ? oddsMTP.WinOdds.Entries.find(odds => odds.ProgramNumber === entry.ProgramNumberCoupled) : null;
        const isOddsScratched = entryOdds && entryOdds.TextOdds ? entryOdds.TextOdds.toUpperCase() === RaceConstants.TEXT_ODDS_SCRATCH : false;

        // Check integrated scratch data.
        let isScratchChange: boolean = false;
        if (!!scratch && !!scratch.EntryChanges) {
            const entryChanges = scratch.EntryChanges.filter(entryChange => entryChange.ProgramNumber.toString().toUpperCase() === entry.ProgramNumber.toUpperCase());

            entryChanges.forEach((change) => {
                if (typeof change.ChangeType === 'string') {
                    switch (change.ChangeType.toUpperCase()) {
                        case RaceConstants.ENTRY_CHANGE_TYPES.SCRATCH:
                                isScratchChange = true;
                            break;
                        case RaceConstants.ENTRY_CHANGE_TYPES.JOCKEY:
                                entry.JockeyName = change.JockeyName;
                            break;
                    }
                }
            });
        }

        if (isScratchChange || isOddsScratched) {
            entry.TextOdds = RaceConstants.TEXT_ODDS_SCRATCH;
            entry.NumOdds = Number.NaN;
            entry.isScratched = true;
        }
    }

    /**
     * Set profit line odds.
     *
     * @param {Entry} entry
     * @param {IProfitLineOdds[]} entryPlUpdateArray
     */
    private setProfitLineOdds(entry: Entry, entryPlUpdateArray: IProfitLineOdds[]) {
        const foundEntryUpdate = entryPlUpdateArray.filter(PLUpdate => PLUpdate.horseId === entry.HorseId)[0];
        if (foundEntryUpdate) {
            entry.ProfitLineOdds = foundEntryUpdate.plFairValueOdds;
        }
    }

    /**
     * Examine the Entries array to see if Field Entries exists.
     * If yes, return the first program number
     * NOTE. The entries array may not be sorted properly. i.e. 1, 1A, 10, 10F, 2, ...
     */
    private findFirstField(entries: object[]): string {
        const fieldNums: number[] = [];

        for (const entryObj of entries) {
            const entry: Entry = new Entry(entryObj);
            if (entry.ProgramNumber.toUpperCase().indexOf('F') >= 0) {
                fieldNums.push(parseFloat(entry.ProgramNumberCoupled));
            }
        }

        // No field entries found
        if (fieldNums.length === 0) {
            return '';
        }

        // Return the first field entry.
        const fieldNumsSorted: number[] = fieldNums.sort();
        return fieldNumsSorted[0].toString();
    }

    /**
     * Updates the current entries to reflect a list of selected entries.
     *
     * @param {Entry[]} entries
     * @param {Entry[][]} selectedEntries
     * @param race
     */
    private updateSelectedEntries(entries: Entry[], selectedEntries: Entry[][] = [], race: number): Entry[] {
        const flattenedEntries = selectedEntries.reduce((p, c) => p.concat(c), []);
        entries.forEach((entry) => {
            if (!flattenedEntries.length) {
                EntrySelectionService.clearEntrySelection(entry);
            } else {
                const entryAmongSelected = flattenedEntries.filter(selectedEntry => entry.ProgramNumber === selectedEntry.ProgramNumber);
                if (entryAmongSelected.length > 0) {
                    this.setSelectedEntries(entry, selectedEntries, race);
                } else {
                    EntrySelectionService.clearEntrySelection(entry);
                }
            }
        });
        return entries;
    }

    /**
     * @param {Entry[]} entries
     * @param {string} valueFieldName
     * @param {string} rankFieldName
     * @returns {Entry[]}
     * @private
     */
    private _updateRankingField(entries: Entry[], valueFieldName: string, rankFieldName: string) {
        let rank = 1;
        const sortedEntries = SortingBusinessService.sort(entries, [
            {
                target: valueFieldName,
                ascending: false
            },
            {
                target: enumProgramSort.PROGRAM_NUMBER
            }
        ]);

        for (let i = 0; i < sortedEntries.length; i++) {
            if (sortedEntries[i].isScratched === true) {
                sortedEntries[i][rankFieldName] = -2;
            } else {
                sortedEntries[i][rankFieldName] = rank++;
            }
        }
    }

    /**
     * @param {Entry[]} entries
     * @returns {Entry[]}
     * @private
     */
    private _updateRankingFields(entries: Entry[]) {
        this._updateRankingField(entries, 'AveragePaceE1', 'ep1Rank');
        this._updateRankingField(entries, 'AveragePaceE2', 'ep2Rank');
        this._updateRankingField(entries, 'AveragePaceLp', 'lpRank');
        this._updateRankingField(entries, 'AverageSpeedLast3', 'avgSpeedRank');
        this._updateRankingField(entries, 'AverageSpeed', 'avgDistanceRank');
        this._updateRankingField(entries, 'BestSpeedDistance', 'bestSpeedRank');
        this._updateRankingField(entries, 'PrimePower', 'primePowerRank');
        this._updateRankingField(entries, 'AverageClass', 'avgClassRank');
        this._updateRankingField(entries, 'LastClass', 'lastClassRank');
    }

    /**
     * Formats the trainer name properly.
     * @param trainerName
     */
    private formatTrainerName(trainerName: string): string {
        if (trainerName === '') {
            return '';
        }

        // Trainer names normal come in as [Last Name, First Name] if the parts of the trainer name are received from the track sepparately.
        // Other sources do not provide a parsed trainer name. For example greyhound tracks provide the trainer name as a single string.
        const splitOnComma = trainerName.split(', ');
        if (splitOnComma.length > 1) {
            return splitOnComma[1] + ' ' + splitOnComma[0];
        } else {
            return splitOnComma[0]
        }
    }

    /**
     * Get the saddle cloth class.
     *
     * @param {enumTrackType} trackType
     * @param {Entry} entry
     * @returns {string}
     */
    private getSaddleClothClass(trackType: enumTrackType, entry: Entry): string {
        // Looks for any horses with an 'F' in their program number.
        const saddleClassProgramNumber: string = entry.isFieldEntry ? entry.FirstFieldProgramNumber : entry.ProgramNumberCoupled;

        return this.wageringUtil.getSaddleClothClass(trackType, saddleClassProgramNumber);
    }
}
