import { RouterModule, Route } from '@angular/router';
import { NgModule } from '@angular/core';
import { PlatformGuard } from 'app/shared/common/guards/platform/platform.guard';
import { PlatformRegistrationComponent } from './platform-registration.component';

const Routes: Route[] = [
    {
        path: '',
        component: PlatformRegistrationComponent,
        canActivate: [ PlatformGuard ],
        data: {
            suppressPreload: true
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(Routes),
    ],
    exports: [
        RouterModule,
    ],
})
export class PlatformRegistrationRoutingModule {
    // Empty
}
