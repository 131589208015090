
export class HistoryStack<T> {

    private _stack: T[] = [];
    private _currentPosition: number = -1;

    public get currentPosition(): number {
        return this._currentPosition;
    }

    public get size(): number {
        return this._stack.length;
    }

    public hasItems(): boolean {
        return (this._stack.length > 0);
    }

    public isAtRootItem(): boolean {
        return (this._currentPosition <= 0);
    }

    public isAtLastItem(): boolean {
        return (this._currentPosition === this._stack.length - 1);
    }


    public addItem(item: T) {
        if (this.hasItems()) {
            this._stack = this._stack.slice(0, this._currentPosition + 1);
        }

        const stackLength = this._stack.push(item);
        this._currentPosition = stackLength - 1;
    }

    public getItem(index: number): T {
        let item = null;
        if (this.hasItems() && this._stack.length > index) {
            item = this._stack[index];
        }

        return item;
    }

    public getCurrentItem(): T {
        return this.getItem(this._currentPosition);
    }

    public getRootItem(): T {
        return this.getItem(0);
    }

    public moveToPreviousItem(): T {
        let currentItem = null;

        if (this.hasItems() && !this.isAtRootItem()) {
            this._currentPosition--;
            currentItem = this.getCurrentItem();
        }

        return currentItem;
    }

    public moveToNextItem(): T {
        let currentItem = null;

        if (this.hasItems() && !this.isAtLastItem()) {
            this._currentPosition++;
            currentItem = this.getCurrentItem();
        }

        return currentItem;
    }


    public clearItems() {
        this._stack = [];
        this._currentPosition = -1;
    }

}
