<ol *ngIf="showPagination" class="carousel-indicators">
    <li *ngFor="let item of carouselItems; let i = index; trackBy:trackByIndex" (click)="goTo(i)" [ngClass]="{'active': i === currentItem}"></li>
</ol>
<div class="carousel-items"><ng-content></ng-content></div>
<div *ngIf="count > 1" class="carousel-controls">
        <i *ngIf="showTourArrows" class="icon--arrow-left" (click)="back()"></i>
        <a *ngIf="!showTourArrows" class="carousel-back" (click)="back()"></a>
        <i *ngIf="showTourArrows" class="icon--arrow-right" (click)="forward()"></i>
        <a *ngIf="!showTourArrows" class="carousel-forward" (click)="forward()"></a>
</div>
