import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ENVIRONMENT, IUserInfo } from '@cdux/ng-core';

import {
    AvailableDocsDataService,
    DownloadIDDataService,
    EcommerceDataService,
    enumTrackPeriod,
    FeatureToggleDataService,
    IAvailableDocsLiteParameters,
    IAvailableDocsResponse,
    IMenu,
    IMenuItem,
    EcommerceCustomer,
    EcommerceProduct,
    EcommercePromiseResponse,
    ITrackBasic,
    MenuDataService,
    ToteDataService,
    TrackService,
    JwtSessionService,
    enumFeatureToggle,
} from '@cdux/ng-common';

const RE_ISO8601_YYYY_MM_DD = /^\d{4}-\d{2}-\d{2}$/;

@Injectable()
export class EcommerceBusinessService {

    constructor(
        private environment: ENVIRONMENT,
        private availableDocsDataService: AvailableDocsDataService,
        private downloadIDDataService: DownloadIDDataService,
        private ecommerceDataService: EcommerceDataService,
        private featureToggleService: FeatureToggleDataService,
        private location: Location,
        private sessionService: JwtSessionService,
        private menuDataService: MenuDataService,
        private toteDataService: ToteDataService,
        @Inject(DOCUMENT) private document: Document
    ) { }


    /**
     * Locate the specified item data in the array of menu item data.
     *
     * @param {string} id
     * @param {IMenuItem[]} menuItems
     * @returns {IMenuItem}
     */
    private findMenuItem(id: string, menuItems: IMenuItem[]): IMenuItem {
        for (const el of menuItems) {
            if (el && el.productCode && el.productCode.toUpperCase() === id.toUpperCase()) {
                return el;
            }
        }

        return null;
    }

    /**
     * Merge the given IMenuStructure and IMenuItem arrays into a combined IMenu array.
     *
     * @param {IMenuStructure[]} menuStructure
     * @param {IMenuItem[]} menuItems
     * @returns {IMenu[]}
     */
    private mergeMenuItems(menuStructure: IMenu[], menuItems: IMenuItem[]): IMenu[] {
        const menu = <IMenu[]> menuStructure;

        for (const el of menu) {
            if (Array.isArray(el.children)) {
                el.children = this.mergeMenuItems(el.children, menuItems);
            }

            if (el.productCode) {
                // replace product code reference with dictionary data
                el.item = this.findMenuItem(el.productCode, menuItems);
                delete el.productCode; // unnecessary, duplicated data
            }
        }

        return menu;
    }

    /**
     * Retrieve available documents for the given parameters.
     * All parameters are optional, but in practice, either
     * track or productCode (or both) should be supplied.
     * Dates will default to the current tote race date.
     *
     * Note: This "lite" request does not verify ownership of documents,
     *       and so does not require the user to be logged in.
     *
     * @param {ITrack} track (optional)
     * @param {string} productCode (optional)
     * @param {string} beginDate (optional)
     * @param {string} endDate (optional)
     * @returns {Observable<IAvailableDocsResponse>}
     */
    public getAvailableDocsLite(track?: ITrackBasic, productCode?: string, beginDate?: string, endDate?: string, raceNumber?: number): Observable<IAvailableDocsResponse> {
        if (!beginDate) {
            return this.toteDataService.currentRaceDate().pipe(
                switchMap((d) => this.getAvailableDocsLite(track, productCode, d, endDate))
            );
        }

        const params = <IAvailableDocsLiteParameters> {
            dayEvening: enumTrackPeriod.DAY,
            beginDate: beginDate,
            endDate: endDate || beginDate
        };

        if (track) {
            params.brisCode = track.BrisCode;
            params.trackType = TrackService.getTrackTypeBds(track.TrackType);
        }

        if (productCode) {
            params.productCode = productCode;
        }

        if (raceNumber) {
            params.raceNumber = raceNumber;
            params.includeSingleRace = true;
        }

        return this.availableDocsDataService.getAvailableDocsLite(params);
    }

    /**
     * Retrieve available documents for the given parameters.
     * All parameters are optional, but in practice, either
     * track or productCode (or both) should be supplied.
     * Dates will default to the current tote race date.
     *
     * @param {ITrack} track (optional)
     * @param {string} productCode (optional)
     * @param {string} beginDate (optional)
     * @param {string} endDate (optional)
     * @returns {Observable<IAvailableDocsResponse>}
     */
    public getAvailableDocs(track?: ITrackBasic, productCode?: string, beginDate?: string, endDate?: string, raceNumber?: number): Observable<IAvailableDocsResponse> {
        if (!beginDate) {
            return this.toteDataService.currentRaceDate().pipe(
                switchMap((d) => this.getAvailableDocs(track, productCode, d, endDate))
            );
        }

        const params = <IAvailableDocsLiteParameters> {
            dayEvening: enumTrackPeriod.DAY,
            beginDate: beginDate,
            endDate: endDate || beginDate
        };

        if (track) {
            params.brisCode = track.BrisCode;
            params.trackType = TrackService.getTrackTypeBds(track.TrackType);
        }

        if (productCode) {
            params.productCode = productCode;
        }

        if (raceNumber) {
            params.raceNumber = raceNumber;
            params.includeSingleRace = true;
        }

        return this.availableDocsDataService.getAvailableDocs(params);
    }

    /**
     * Retrieve the specified ecommerce menu data.
     *
     * @param {string} id
     * @returns {Observable<IMenu[]>}
     */
    public getMenu(id: string): Observable<IMenu[]> {
        return this.menuDataService.getMenu(id).pipe(
            map((data) => this.mergeMenuItems(data.menuStructure, data.menuItems))
        );
    }

    /**
     * Retrieve the current ecommerce customer data.
     *
     */
    public getCustomer(): Observable<EcommerceCustomer> {
        return this.ecommerceDataService.getCustomer();
    }

    /**
     * Create a promise for the given product.
     * 
     */
    public insertPromise(product: EcommerceProduct, paidUpFront = false): Observable<EcommercePromiseResponse> {
        return this.ecommerceDataService.insertPromise(product, paidUpFront);
    }

    /**
     * Generate a secure download URL for the given product.
     *
     * @param {IProduct} product
     * @returns {Observable<string>}
     */
    public getDownloadURL(product: EcommerceProduct): Observable<string> {
        // we assume the route to this is guarded with AuthGuard
        const userInfo = this.sessionService.getUserInfo();

        // verify format of date is YYYY-MM-DD
        if (!product.raceDate.match(RE_ISO8601_YYYY_MM_DD)) {
            throw new Error('Invalid raceDate format.');
        }

        if (this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.ECOMM_PREGEN)) {
            return this.ecommerceDataService.getDownloadURL(product)
                .pipe(map(downloadInfo => {
                    this._setCookie(downloadInfo.cookie);
                    return this._formatURL(downloadInfo.url);
                }))
        } else {
            // todo: remove _getDownloadID function once old ecom endpoint is retired
            return this._getDownloadID(userInfo, product);
        }
    }

    private _getDownloadID(userInfo: IUserInfo, product: EcommerceProduct): Observable<string> {
        return this.downloadIDDataService.getDownloadID(userInfo.account).pipe(
            map((data) => {
                // window.location.origin has what we need, but isn't widely supported.
                let productUrl = window.location.protocol + '//' + window.location.host + '/api/ecomm/productdownload';

                // standard WS params
                productUrl += '?username='    + this.environment.username;
                productUrl += '&ip=0.0.0.0';
                productUrl += '&affid='       + this.environment.affiliateId;
                productUrl += '&output=json';
                productUrl += '&account='     + userInfo.account;

                // params for Ecommerce/PreGenProduct
                productUrl += '&affiliateId=' + this.environment.affiliateId;
                productUrl += '&customerId='  + userInfo.account;
                productUrl += '&productCode=' + product.productCode;
                productUrl += '&brisCode='    + product.brisCode;
                productUrl += '&dayEvening='  + product.dayEvening;
                productUrl += '&raceDate='    + product.raceDate;
                productUrl += '&trackType='   + product.trackType;
                productUrl += '&raceNumber='  + product.raceNumber;
                productUrl += '&authKey='     + data.DownloadID;

                return productUrl;
            })
        );
    }

    public getFullHandicappingURL(): string {
        return this.featureToggleService.isFeatureToggleOn('HANDICAPPING')
            ? this.location.prepareExternalUrl('/handicapping')
            : '/handicapping';
    }

    private _setCookie(token: string): void {
        this.document.cookie = token + ';path=/;';
    }

    private _formatURL(url: string): string {
        return !!url ? url.replace(/^.+.com\//g, '/') : url;
    }
}
