import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TuxCommonModule } from '../common/common.module';

import { MtpModule, LoadingModule } from '@cdux/ng-fragments';
import { MtpBarComponent } from './mtp-bar.component';
import { ToteBoardComponent } from './tote-board.component';
import { CduxNgCommonModule } from '@cdux/ng-common';
import { ScrollableContentModule } from '../../wagering/shared/scrollable-track/scrollable-content.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TuxCommonModule,
        CduxNgCommonModule,
        LoadingModule,
        MtpModule,
        ScrollableContentModule,
    ],
    declarations: [
        MtpBarComponent,
        ToteBoardComponent,
    ],
    exports: [
        LoadingModule,
        MtpBarComponent,
        ToteBoardComponent,
    ]
})
export class UpNextModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<UpNextModule> {
        return {
            ngModule: UpNextModule,
            providers: []
        }
    }
}
