import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe, Location } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';

import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service'
import { FundingService } from '../../../shared/services/funding.service';
import { FundingPaypalService } from '../../../shared/services/paypal.service';
import { of } from 'rxjs';
import { map, catchError, take, takeUntil } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    IPaypalCompleteDepositStatus,
    IPaypalDepositStatus,
    enumStatus,
    FeatureToggleDataService
} from '@cdux/ng-common';
import { FundingAbstractMethodComponent, enumDepositOptions } from '../abstract-method.component';
import { FUNDING_OPERATIONS, TranslateService, FUND_ID, IFundingOption } from '@cdux/ng-common';
import { FundingDepositOptionsComponent } from '../../deposit-options/deposit-options.component';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarHeaderProperties
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { FundingPaypalMethodHeaderComponent } from './paypal-method-header.component';
import { SIDEBAR_LOADERS } from '../../../../sidebar/enums/loader.enums';

@Component({
    selector: 'cdux-funding-paypal-complete-deposit',
    styleUrls: ['./paypal-complete-deposit.component.scss'],
    templateUrl: './paypal-complete-deposit.component.html'
})
export class FundingPaypalCompleteDepositComponent extends FundingAbstractMethodComponent implements OnInit, OnDestroy {

    public errorMessage: string;
    public paypalAccountErrors: Array<string>;
    public paypalFundType: string = FUND_ID[FUND_ID.PAYPAL];
    public isToteDown: boolean = false;

    private readonly _TOTE_DOWN_CODE = 1008;
    private readonly _WRONG_PAYPAL_ACCOUNT_CODE = 2131;
    private readonly _PAYPAL_ACCOUNT_IN_USE_CODE = 2133;
    private readonly _ERROR_CODE_LANGUAGE_KEY = 'errorcodes';

    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();


    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingPaypalCompleteDepositComponent, {});
    }

    public static getHeaderComponent(options: ISidebarHeaderProperties = {}): ISidebarPortalComponent {
        // We should Always Prevent the Header from doing the normal
        //  back button thing. We're a special case and we know it.
        options.preventDefaultHeaderButtonAction = true;
        return FundingPaypalMethodHeaderComponent.getSidebarComponent(options);
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventTrackingService: EventTrackingService,
        _featureToggleService: FeatureToggleDataService,
        private _activatedRoute: ActivatedRoute,
        private _location: Location,
        private _paypalService: FundingPaypalService,
        private _currencyPipe: CurrencyPipe,
        protected _translateService: TranslateService) {
        super(
            _environment,
            _sidebarService,
            _fb,
            _fundingService,
            _eventTrackingService,
            _translateService,
            _featureToggleService
        );
        this.operation = FUNDING_OPERATIONS.DEPOSIT;
        this.operationMethod = enumDepositOptions.PAYPAL;
    }

    public ngOnInit() {


        this._activatedRoute.queryParams.pipe(
            take(1)
        ).subscribe((params: Params) => {
            const token: string = (params.hasOwnProperty('token')) ? params.token : null;
            // Make sure we preserve the target Bet that was active prior to
            //   redirection to PayPal
            if (params.hasOwnProperty(this._paypalService.BET_ID_PARAM)) {
                this.inboundBet = params[this._paypalService.BET_ID_PARAM];
            }

            if (!this._paypalService.hasTransactionCompleted(token) && params.hasOwnProperty(FundingPaypalService.PAYPAL_TRANSACTION_PARAM)) {
                switch (params[FundingPaypalService.PAYPAL_TRANSACTION_PARAM]) {
                    case enumStatus.SUCCESS:
                        this.completeDeposit(token);
                        break;
                    case enumStatus.CANCELLED:
                        this.cancelDeposit(token);
                        break;
                }
            }
        });

        this._sidebarService.onHeaderButtonClicked.pipe(
            takeUntil(this._destroyed)
        ).subscribe(() => {
            this.goToFunding(false);
        });
    }

    ngOnDestroy() {
        this._destroyed.next(true);
    }

    public goToFunding(shouldAutoSelect: boolean = true) {

        const inputs = {
            shouldAutoSelectMethod: shouldAutoSelect
        };
        if (shouldAutoSelect) {
            inputs['methodToSelect'] = FUND_ID.PAYPAL
        }

        this._sidebarService.loadComponent(FundingDepositOptionsComponent.getSidebarComponent(inputs), FundingDepositOptionsComponent.getHeaderComponent(), {
            clearHistory: true
        });
    }

    private _prepareErrorMessage(): string {
        if (this.errorCode === undefined || this.errorCode === null) {
            return '';
        }

        this.errorMessage = this._translateService.translate('' + this.errorCode, this._ERROR_CODE_LANGUAGE_KEY, true);

        const affilId = '' + this._environment.affiliateId;
        switch (+this.errorCode) {
            case this._WRONG_PAYPAL_ACCOUNT_CODE:
                this.paypalAccountErrors = [
                    this._translateService.translate(
                        this.errorCode + '-details',
                        this._ERROR_CODE_LANGUAGE_KEY,
                        true,
                        this.fundingMethodDetails.accountInfo
                    ),
                    this._translateService.translate(
                        'player-services',
                        this._ERROR_CODE_LANGUAGE_KEY,
                        false,
                        this._translateService.translate('player-services-email', affilId, false),
                        this._translateService.translate('customer-service', affilId, false)
                    )
                ];
                break;
            // In this case: the user has no payPal deposit record, so the paypal accountInfo is null
            case this._PAYPAL_ACCOUNT_IN_USE_CODE:
                this.paypalAccountErrors = [
                    this._translateService.translate(
                        this.errorCode + '-details',
                        this._ERROR_CODE_LANGUAGE_KEY,
                        true
                    ),
                    this._translateService.translate(
                        'player-services',
                        this._ERROR_CODE_LANGUAGE_KEY,
                        false,
                        this._translateService.translate('player-services-email', affilId, false),
                        this._translateService.translate('customer-service', affilId, false)
                    )
                ];
                break;
            default:
                break;
        }
    }

    /**
     * This clears out the queryString from the current navigation
     *  state and replaces the top of the route stack.
     *
     *  We do this to prevent users from refreshing/navigating
     *  BACK to trigger additional calls
     */
    private _clearQueryState() {
        const path = this._location.path(true).split('?')[0];
        this._location.replaceState(path, '');
    }

    private completeDeposit(token: string) {
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        this._subscriptions.push(
            this._paypalService.completeDeposit(token)
                .subscribe(
                    (res: IPaypalCompleteDepositStatus) => {
                        if (res.errorCode) {
                            // Handle Soft Errors
                            this._handleException(res);
                        } else {
                            this._clearQueryState();
                            this._handleResponse(
                                res,
                                `${this._currencyPipe.transform(res.amount, 'USD', 'symbol-narrow')} has been deposited to your account with PayPal.`,
                                res.amount
                            );
                        }
                    },
                    (err) => this._handleException(err)
                )

        );
    }

    private cancelDeposit(token: string) {
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        this._paypalService.cancelDeposit(token)
            .subscribe(
                (res: IPaypalDepositStatus) => {
                    this._clearQueryState();
                    if (!res.errorCode) {
                        this.errorMessage = this._translateService.translate('deposit-canceled', FUND_ID[FUND_ID.PAYPAL]);
                        this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
                    } else {
                        this._handleResponse(res, '');
                    }
                },
                (err) => this._handleException(err)
            );
    }

    private _handleException(error) {

        if (+error.errorCode === this._WRONG_PAYPAL_ACCOUNT_CODE) {
            // Wait Until funding details are complete so we can
            //  provide the proper error messaging.
            this._fundingService.requestFundingMethodData(FUNDING_OPERATIONS.DEPOSIT, [FUND_ID.PAYPAL]).pipe(
                map((options) => options.filter((o) => o.fundId === FUND_ID.PAYPAL)),
                map((options) => options && options.length > 0 ? options[0] : undefined),
                catchError((err) => of({}))
            ).subscribe((details: IFundingOption) => {
                this.fundingMethodDetails = details;
                this._clearQueryState();
                this._handleResponse(error, '');
                this._prepareErrorMessage();
            });
        } else {
            if (error.errorCode === this._TOTE_DOWN_CODE) {
                this.isToteDown = true;
                error.status = 'tote-down';
            }
            this._clearQueryState();
            this._handleResponse(error, '');
            this._prepareErrorMessage();
        }
    }
}
