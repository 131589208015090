<div class="search-results-panel is-open">
    <!-- No results and no search string -->
    <ng-container *ngIf="!noResults(); else noResultsMessage">
        <div class="scrolling-list">
            <ng-container *ngIf="resultList && resultList.length">
                <ng-container #resultListTemplate *ngFor="let result of resultList; let i = index; trackBy: resultItemKey">
                    <cdux-search-result-item
                        [resultItem]="result"
                        [index]="i"
                        (resultClicked)="clicked($event)">
                    </cdux-search-result-item>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #noResultsMessage>
    <div id="no-results-container" class="no-results">
        <div class="tile-container has-error">
            <div class="gbl-message">
                <i class="icon--info"></i>
                <div id="no-results-header" class="header">No Results</div>
                <div id="no-results-message" class="description">No results found for your search</div>
            </div>
        </div>
    </div>
</ng-template>
