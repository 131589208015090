import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewRef
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import {
    DetectionService,
    EventClickAttributeType,
    EventClickType,
    EventsService,
    IRaceIdentifier,
    ITrackBasic,
    StringSlugifyPipe,
    ToteDataService,
    JwtSessionService,
    WindowManagerService,
    WindowCollectionKey,
} from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { ProgramNavBusinessService } from 'app/shared/program/services/program-nav.business.service';
import { EventKeys } from '../../../../shared/common/events';
import {
    ActiveDrawerEnum,
} from '../../../../shared/common/services';
import { AbstractRaceNavDrawerComponent } from '../../../../shared/race-navigation/race-nav/abstract-race-nav-drawer.component';
import { VideoFeedStates } from '../../enums/video-feed-states';
import { VideoComponent } from '../../video.component';
import { VideoService } from '../../services/video.service';

@Component({
    selector: 'cdux-video-container',
    templateUrl: './video-container.component.html',
    styleUrls: ['./video-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoContainerComponent extends AbstractRaceNavDrawerComponent implements AfterViewInit, OnInit, OnDestroy {

    /**
     * Current track.
     *
     * @type {ITrackBasic}
     */
    @Input()
    public get track(): ITrackBasic {
        return this._track;
    }
    public set track(track) {
        this._track = track;
        this.popUpVideoPath = this.popUpVideoPathLink();
        this._detectChanges();
    }

    /**
     * Current Race.
     *
     * @type {number}
     */
    @Input()
    public get race(): number {
        return this._race;
    }
    public set race(race) {
        this._race = race;
        this.popUpVideoPath = this.popUpVideoPathLink();
        this._detectChanges();
    }

    /**
     * Current Race Number.
     *
     * @type {number}
     */
    @Input()
    public liveRaceNumber: number;

    /**
     * Current Race Date
     * Used for replay videos
     *
     * @type {string}
     */
    @Input()
    public get raceDate(): string {
        return this._raceDate;
    }
    public set raceDate(raceDate) {
        this._raceDate = raceDate;
        this.popUpVideoPath = this.popUpVideoPathLink();
        this._detectChanges();
    }

    /**
     * Forces the video to be housed in its native location. (Only affects mobile size)
     *
     * @type {boolean}
     */
    @Input()
    public disableFixedPosition = false;

    /**
     * Determines whether the video should be shown.
     *
     * @type {boolean}
     */
    @Input()
    public showVideo: boolean = false;

    /**
     * Is used to determine whether the video should be displayed at the bottom of the screen.
     *
     * @type {boolean}
     */
    public positionFixed: boolean = false;

    @Input()
    public get feedState(): VideoFeedStates {
        return this._feedState;
    }
    public set feedState(feedState) {
        this._feedState = feedState;
        this.popUpVideoPath = this.popUpVideoPathLink();
        this._detectChanges();
    }

    public hasError: boolean = false;

    /**
     * Enums for use in the template.
     *
     * @type {EventKeys}
     * @type {EventClickType}
     * @type {EventClickAttributeType}
     */
    public eventKeys = EventKeys;
    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;

    public popUpVideoPath: string;
    public tuxNavigationBottom: number;

    private _track: ITrackBasic = null;
    private _race: number;
    private _raceDate: string = null;
    private _feedState: VideoFeedStates;
    private _destroy: Subject<boolean> = new Subject();

    /**
     * Window name.
     *
     * @type {string}
     */
    public windowName: string = null;

    /**
     * Is the current platform desktop?
     *
     * @type {boolean}
     */
    public isDesktop: boolean = false;

    /**
     * References screen size.
     *
     * @type {boolean}
     */
    public onSmallGlass: boolean = false;

    @Input()
    public disableLiveReplayToggle: boolean = false;

    /**
     * Communicates a drawer change.
     *
     * @type {EventEmitter<ActiveDrawerEnum>}
     */
    @Output() public drawerChange: EventEmitter<ActiveDrawerEnum> = new EventEmitter<ActiveDrawerEnum>();

    private _toteRaceDate: string;

    @ViewChild(VideoComponent)
    public videoComponent: VideoComponent;

    @ViewChild('videoContainer') private _videoContainerElement: ElementRef;

    /**
     * Constructor
     *
     * @param {CduxMediaToggleService} _mediaService
     * @param {ChangeDetectorRe} _changeDetector
     * @param {ElementRef} _templateRef
     * @param {EventsService} _eventsService
     * @param {JwtSessionService} _sessionService
     * @param {ProgramNavBusinessService} _programNavService
     * @param {Router} _router
     * @param {StringSlugifyPipe} _stringSlugify
     * @param {ToteDataService} _toteDataService
     * @param {WindowManagerService} _windowManager
     */
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _detectionService: DetectionService,
        private _eventsService: EventsService,
        private _mediaService: CduxMediaToggleService,
        private _programNavService: ProgramNavBusinessService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _stringSlugify: StringSlugifyPipe,
        private _toteDataService: ToteDataService,
        private _windowManager: WindowManagerService,
        protected videoService: VideoService,
    ) {
        super();
    }

    /**
     * Startup overhead.
     */
    ngOnInit() {
        if (!this.feedState) {
            this._setFeedState();
        }

        this._eventsService.on<IRaceIdentifier>(EventKeys.VIDEO_PRIMARY_OPEN)
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe((r) => {
                if (r && r.brisCode && r.trackType && r.raceNumber) {
                    this.openVideoPlayer(
                        { BrisCode: r.brisCode, TrackType: r.trackType },
                        r.raceNumber,
                        r.raceDate || this._toteRaceDate
                    );
                } else {
                    this.openVideoPlayer();
                }
            }),
        this._eventsService.on(EventKeys.VIDEO_PRIMARY_CLOSE)
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe(() => this.closeVideoPlayer()),
        this._toteDataService.currentRaceDate(true)
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe((date: string) => {
            this._toteRaceDate = date;
            if (!this.raceDate) {
                this.raceDate = date;
            }
            this._detectChanges();
        })

        this.isDesktop = this._detectionService.isDesktop();

        this._sessionService.addLogoutTask(() => new Promise<void>((resolve) => {
            this._windowManager.close(WindowCollectionKey.VIDEO);
            resolve();
        }), false);

        this.videoService.registerVideoContainer(this._videoContainerElement);
    }

    /**
     * Check video visibility after view inits fully
     */
    ngAfterViewInit(): void {
        this._mediaService.registerQuery('phone')
            .pipe(
                distinctUntilChanged(),
                takeUntil(this._destroy)
            )
            .subscribe((onSmallGlass) => {
                this.onSmallGlass = onSmallGlass;
                this._detectChanges();
                setTimeout(() => this.tuxNavigationBottom = document.getElementById('cdux-header')?.getBoundingClientRect().bottom || 0, 1000)
            });
    }

    /**
     * Garbage collection.
     */
    ngOnDestroy() {
        this._changeDetector.detach();
        this._destroy.next();
        this._destroy.complete();
    }

    private _getVideoUrl(): string {
        // Gets the current track and race if either are missing in order to build the URL
        if (!this.track || !this.race) {
            this._getCurrentTrackAndRace();
        }

        if (this._router.url.indexOf('/program') >= 0) {
            return ['/program', this._stringSlugify.transform(this.track.DisplayName), this.track.BrisCode, this.track.TrackType, this.race, 'video'].join('/');
        } else {
            return this._router.url + '/video';
        }
    }

    /**
     * This update the member track and race
     */
    private _getCurrentTrackAndRace() {
        if (this._programNavService.currentTrack && this._programNavService.currentRace) {
            this.track = this._programNavService.currentTrack;
            this.liveRaceNumber = this._programNavService.currentTrack.RaceNum;
            this.race = this._programNavService.currentRace.race;
        }

        if (!this.feedState) {
            this._setFeedState();
        }
    }

    private _setLiveTrackRaceNumber() {
        if (this._programNavService.currentTrack) {
            this.liveRaceNumber = this._programNavService.currentTrack.RaceNum;
        }
    }

    private _setFeedState() {
        if (!this.liveRaceNumber) {
            this._setLiveTrackRaceNumber();
        }

        // TODO: Investigate why this happens to remove this check
        // sometimes, liveRaceNumber is set initially to race 1 instead of the actual live race number
        if (this.liveRaceNumber < this.race && this._programNavService.currentTrack) {
            this.liveRaceNumber = this._programNavService.currentTrack.RaceNum;
        }

        if (this._isPastRace() || (!this.liveRaceNumber && this.raceDate)) {
            this.feedState = VideoFeedStates.REPLAY;
        } else {
            this.feedState = VideoFeedStates.LIVE;
        }

    }

    private _isPastRace(): boolean {
        return (!!this.liveRaceNumber && this.liveRaceNumber > this.race) || this._isPastDate();
    }

    private _isPastDate(): boolean {
        return this.raceDate !== this._toteRaceDate && !!this.raceDate;
    }

    public onFeedStateChange(event: VideoFeedStates) {
        this.feedState = event;
        this._detectChanges();
    }

    public onVisibilityChange(isVisible: boolean) {
        this.positionFixed = !this.onSmallGlass && !this.hasError && this.showVideo && !isVisible;
        this._detectChanges();
    }

    /**
     * How to handle the popup video being clicked.
     */
    public popUpVideoClick() {
        this.windowName = WindowManagerService.generateWindowName(WindowCollectionKey.VIDEO);
        this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_CLOSE);
        this.positionFixed = false;
        this.popUpVideoPath = this.popUpVideoPathLink();
        this._detectChanges();
    }

    /**
     * Gets the video path.
     *
     * @returns {string}
     */
    public popUpVideoPathLink(): string {
        if (this.track && this.race) {
            if (this.feedState === VideoFeedStates.LIVE) {
                // if the selected race matches to the current race, assume we're requesting a live video
                return 'video/live/' + this.track.BrisCode + '/' + this.track.TrackType + '?key=' + this.windowName;
            } else {
                // assume it's not the current race and use the member date to generate the url
                // if the container is given a date, it will use that, otherwise it will use the current tote date
                if (!this.raceDate) {
                    return 'video/replay/' + this._toteRaceDate + '/' + this.track.BrisCode + '/' + this.track.TrackType + '/' + this.race + '?key=' + this.windowName;
                }
                return 'video/replay/' + this.raceDate + '/' + this.track.BrisCode + '/' + this.track.TrackType + '/' + this.race + '?key=' + this.windowName;
            }
        } else {
            return '';
        }
    }

    /**
     * Opens the video player
     *
     * In the event track and race aren't pulled in, retrieve them from the program nav service
     * @param track
     * @param race
     */
    public openVideoPlayer(track?: ITrackBasic, race?: number, raceDate?: string): void {
        // Sets the member variable if passed in
        this.track = track || null;
        this.race = race || null;
        this.raceDate = raceDate || null;

        // Initially check for login
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._getVideoUrl();
            this._router.navigate(['/login']);
        } else {
            if (!track || !race) {
                // If args are not passed in, grab the current track and race
                this._getCurrentTrackAndRace();
            }
            this._setFeedState();
            this.showVideo = true;

            // trigger here rather than in track setter, so it only gets triggered once...
            this._detectChanges();
        }
    }

    public closeVideoPlayer(): void {
        if (this.showVideo) {
            this.showVideo = false;
            this._detectChanges();
        }
    }

    public handleError($event) {
        if ($event) {
            this.hasError = true;
            this._detectChanges();
        }
    }

    private _detectChanges() {
        if (!(this._changeDetector as ViewRef).destroyed) {
            this._changeDetector.detectChanges();
        }
    }
}
