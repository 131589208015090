import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, FUND_ID, FUNDING_OPERATIONS, TranslateService } from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import {
    enumDepositOptions,
    FundingAbstractMethodComponent
} from 'app/shared/funding/components/methods/abstract-method.component';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { FundingService } from '../../../shared/services/funding.service';
import { IFundingDepositOptionsComponentProperties } from '../../interfaces/funding-sidebar-component.interfaces';

const METHOD_TO_SELECT_TOKEN = 'methodToSelect';

@Component({
    selector: 'cdux-funding-success',
    templateUrl: './funding-success.component.html',
    styleUrls: ['./funding-success.component.scss']
})
export class FundingSuccessComponent extends FundingAbstractMethodComponent implements OnInit, OnDestroy {

    // Method Selector Override. This will override the "shouldAutoSelectMethod" flag if both are set
    public methodToSelect: FUND_ID;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingDepositOptionsComponentProperties = {}): ISidebarPortalComponent {
        const inputTokens: Map<any, any> = new Map();

        if (options.methodToSelect) {
            inputTokens.set(METHOD_TO_SELECT_TOKEN, options.methodToSelect);
        }

        return {
            component: FundingSuccessComponent,
            properties: {
                inputs: inputTokens
            }
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        if (properties.inputs.has(METHOD_TO_SELECT_TOKEN)) {
            this.methodToSelect = properties.inputs.get(METHOD_TO_SELECT_TOKEN);
            this._setFundingOperationInfo(this.methodToSelect);
        }

    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventTrackingService: EventTrackingService,
        _featureToggleService: FeatureToggleDataService,
        private _location: Location,
        protected _translateService: TranslateService
    ) {
        super(
            _environment,
            _sidebarService,
            _fb,
            _fundingService,
            _eventTrackingService,
            _translateService,
            _featureToggleService
        );
    }

    public ngOnInit() {
        // Must retrieve the depositAmount from the history.state before clearing the navigation state to send event to marketing
        const depositAmount = window.history.state?.depositAmount;
        this._clearQueryState();

        let messages = ['funding-success-header'];
        messages = messages.map(key => this._translateService.translate(key, FUND_ID[this.methodToSelect], false));
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        setTimeout(() => this._handleResponse({ status: 'success' }, messages, depositAmount))
    }

    /**
     * This clears out the queryString from the current navigation
     *  state and replaces the top of the route stack.
     *
     *  We do this to prevent users from refreshing/navigating
     *  BACK to trigger additional calls
     */
    private _clearQueryState() {
        const path = this._location.path(true).split('?')[0];
        this._location.replaceState(path, '');
    }

    private _setFundingOperationInfo(fundId: FUND_ID): void {
        switch (fundId) {
            case FUND_ID.MAZOOMA:
                this.operation = FUNDING_OPERATIONS.DEPOSIT;
                this.operationMethod = enumDepositOptions.EZ_BANK;
                break;
            default:
                break;
        }
    }

}
