import { WageringEventAccumulator } from '../classes/wagering-event-accumulator.class';
import { WagerEvent } from '../interfaces/wager-event.interface';
import { Observable, Subject } from 'rxjs';

export function cduxAccumulateWageringEvents() {
    return (source: Subject<WagerEvent> | Observable<WagerEvent>) => {
        const accumulator = new WageringEventAccumulator(source);
        return accumulator.listen();
    }
}
