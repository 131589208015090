import { BetshareSessionDataErrors, BetshareStatusUpdateType } from '@cdux/ng-fragments';

export class BetshareOptInResponse {
    public status: 'SUCCESS' | 'FAILURE';
    public errors: BetshareSessionDataErrors = new BetshareSessionDataErrors();
    public availableShares: number;
}

export class BetShareStatusUpdate {
    constructor(public type: BetshareStatusUpdateType,
                public data: Map<string, any> = new Map()) {}
}
