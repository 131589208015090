/**
 * Collection of Array Utilities
 *
 * TODO - Move to NG
 */

import { coerceToNumber } from './coercion';

export class CduxArrayUtil {

    /**
     * Creates a set out of a collection of Arrays
     * @param args - Arrays to create a set out of
     */
    public static toSet(...args) {
        const map = {};
        const all = [].concat(...args);
        all.forEach((element) => {
            map[element.toString()] = true;
        });

        return Object.keys(map);
    }

    /**
     * Groups an array of objects by the given property.
     * Returns a grouped object keyed by grouping property values.
     * @param list Object array to group
     * @param prop Object property to group by
     */
    public static groupBy(list, prop) {
        return list.reduce((groups, item) => {
            const key = item[prop];
            groups[key] = groups[key] || [];
            groups[key].push(item);
            return groups;
        }, {});
    }

    /**
     * Finds the closest number in an array of numbers or strings
     * @param arr - Collection of Values
     * @param target - Target Value
     */
    public static closest(arr: number[] | string[], target: number | string): number {
        let curr = arr[0];
        let diff = Math.abs(coerceToNumber(target) - coerceToNumber(curr));
        for (let i = 0; i < arr.length; i++) {
            const newDiff = Math.abs(coerceToNumber(target) - coerceToNumber(arr[i]));
            if (newDiff < diff) {
                diff = newDiff;
                curr = coerceToNumber(arr[i]);
            }
        }
        return coerceToNumber(curr);
    }
}
