<ng-template ngFor let-fundingOption [ngForOf]="fundingOptions">
    <cdux-funding-option
      *ngIf="canDisplayFundingOption(fundingOption)"
      [id]="'fundingOption-' + fundingOption.fundType"
      [fundingMethodDetails]="fundingOption"
      [displayStyle]="displayStyle"
      [isActive]="fundingOption.fundId === activeFundingMethod"
      [hasError]="fundingOption.locked"
      [canReplace]="fundingOption.canReplace !== false"
      [icon]="getIcon('tile-icon', fundingOption)"
      [amexEnabled]="amexEnabled"
      [fundingOperation]="selectedOperation"
      (select)="selectFundingMethod($event)"
      (replace)="replaceFundingMethod($event)">
    </cdux-funding-option>
  </ng-template>