<section class="bet-share-join-container">
    <section class="bet-share-join__bet-area" *ngIf="errorSeverity !== enumErrorSeverity.CRITICAL">
        <h1>{{ betShareSessionData?.details?.captainFirstName + ' ' + betShareSessionData.details?.captainLastName?.substring(0,1) }}. has invited you to join a&nbsp;bet!</h1>
        <div class="bet">
            <div class="bet-slip__title-bar">
                <h2 class="title">
                    <div class="track-name">{{ betShareSessionData?.details?.trackName  }}</div>
                    <div class="race-number">Race {{ betShareSessionData?.details?.race }}</div>
                </h2>
                <!--<h2 class="title right"></h2>-->
                <span class="bet-slip__bet-type">{{ betShareSessionData?.details?.poolTypeName ? (betShareSessionData?.details?.poolTypeName | formatWager:betShareSessionData?.details?.runners:listDelimiter:betShareSessionData?.details?.poolTypeId) : '' }}</span>
            </div>
            <div class="bet-slip__row">
                <span class="bet-slip__row-bet-amount">{{ betShareSessionData?.details?.amount?.toString() ? (betShareSessionData?.details?.amount?.toString() | formatBetAmount) : '' }}</span>
                <span class="bet-slip__row-bets">{{ betShareSessionData?.details?.runners ? (betShareSessionData?.details?.runners | formatRunners:listDelimiter) : '' }}</span>
            </div>
            <div class="bet-slip__total-row">
                <span class="total">Bet Total:</span>
                <span class="amount">{{ betShareSessionData?.details?.betShareCost.toString() ? (betShareSessionData?.details?.betShareCost.toString() | formatBetAmount) : '' }}</span>
            </div>
        </div>
        <h2 *ngIf="errorSeverity === enumErrorSeverity.SOFT || errorSeverity === enumErrorSeverity.NONE">Join in on the bet by choosing your share(s).</h2>
    </section>
    <section class="bet-share-join__share-control-area" *ngIf="errorSeverity !== enumErrorSeverity.HARD && errorSeverity !== enumErrorSeverity.CRITICAL">
        <div class="available-shares-msg" *ngIf="errorSeverity === enumErrorSeverity.SOFT && errorType === enumErrorTypes.SHARES">
            ONLY {{ betShareSessionData?.details?.availableShares === 1 ? '1 SHARE' : betShareSessionData?.details?.availableShares + ' SHARES'}} AVAILABLE
        </div>
        <div class="share-control-row">
            <div class="bet-amount-controls">
                <i class="btn-decrement light" (click)="updateShares(0)" [cduxLogClick]="eventClickType.BETSHARE_INVITATION_DECREASE_SHARE"></i>
                <div class="shares"><span class="fraction-of">{{ betShareSessionData?.shares }}</span>of {{ betShareSessionData?.details?.availableShares }}</div>
                <i class="btn-increment light" (click)="updateShares(1)" [cduxLogClick]="eventClickType.BETSHARE_INVITATION_INCREASE_SHARE"></i>
            </div>
        </div>
        <div class="share-cost-row">
            <span class="share-cost__col-left">Share Cost:</span>
            <span class="share-cost__col-right">{{ betShareSessionData?.details?.pricePerShare?.toString() ? (betShareSessionData?.details?.pricePerShare?.toString() | formatBetAmount) : '' }}</span>
        </div>
        <div class="total-row">
            <span class="share-cost__col-left">Total:</span>
            <span class="share-cost__col-right">{{ joinBetShareTotal ? (joinBetShareTotal | formatBetAmount) : '' }}</span>
        </div>
    </section>
    <section class="bet-share-join__button-control-area">
        <div id="normal" *ngIf="errorSeverity === enumErrorSeverity.NONE || errorSeverity === enumErrorSeverity.SOFT">
            <div class="cancellation-msg">PURCHASED BET SHARES CANNOT BE CANCELLED.</div>
            <div class="button-control-col-left">
                <div (click)="optOut()" class="btn-nothanks" [cduxLogClick]="eventClickType.BETSHARE_INVITATION_NO_THANKS">No Thanks</div>
            </div>
            <div class="button-control-col-right">
                <div class="btn-joinbet" (click)="optIn()" [cduxLogClick]="eventClickType.BETSHARE_INVITATION_JOIN_BETS">
                    <span *ngIf="!optInLoading">Join Bet</span>
                    <div *ngIf="optInLoading" class="button-loader standard-color">
                        <div class="loader-dots">
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                            <i class="dots-animated"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="errors" *ngIf="errorSeverity === enumErrorSeverity.HARD || errorSeverity === enumErrorSeverity.CRITICAL">
            <!-- error states -->
            <div class="error-message">
                <div>We are sorry! </div>
                <ng-container *ngIf="errorSeverity === enumErrorSeverity.HARD || errorSeverity === enumErrorSeverity.CRITICAL" [ngSwitch]="errorType">
                    <div *ngSwitchCase="enumErrorTypes.RACE_STATUS">This Bet Share is no longer available.</div>
                    <div *ngSwitchCase="enumErrorTypes.STATUS">This Bet Share is no longer available.</div>
                    <div *ngSwitchCase="enumErrorTypes.SHARES">All shares in this Bet Share have been claimed.</div>
                    <div *ngSwitchCase="enumErrorTypes.RESTRICTED">Sorry this Bet Share is not available.</div>
                    <div *ngSwitchCase="enumErrorTypes.INVALID">This Bet Share ID is invalid.</div>
                    <div *ngSwitchDefault>{{ this.betShareSessionData?.details?.message ? this.betShareSessionData?.details?.message : 'This Bet Share is no longer available.' }}</div>
                </ng-container>
            </div>
            <div class="button-control-col-center">
                <div (click)="optOut()" class="btn-okay">OKAY</div>
            </div>
            <!-- end error states -->
        </div>
        <div class="button-control-link-row" *ngIf="errorSeverity !== enumErrorSeverity.CRITICAL"><a (click)="goToHowItWorks()" [cduxLogClick]="eventClickType.BETSHARE_INVITATION_HOW_IT_WORKS" >How it works</a></div>
    </section>
    <section class="bet-share-join__how-it-works-area" *ngIf="errorSeverity !== enumErrorSeverity.CRITICAL" [cduxFocus]="focus" [scrollToTop]="true">
        <h2>Buy a share of the bet</h2>
        <p>Your friend has built a bet and wants to split the cost. A bet is either split into equal shares or custom
            amounts. You can buy as many shares as you like, as long as they are still available.
        </p>
        <h2>SHARE WITH YOUR FRIENDS</h2>
        <p>Continue to share this bet with your friends while open shares are still available. Share with your friends via email, text and/or social media.</p>
        <h2>PAYOUT</h2>
        <p>
            Once a ticket is cashed, you’ll get the the corresponding portion of your contribution. If there are ten shares, and you buy one, you’ll get one tenth of the winnings.
        </p>
        <h2>A NOTE ABOUT TAXES</h2>
        <p>
            If the ticket pays 300 times the full wager, or more than $5,000, we will withhold 24% of the winnings for federal income tax.
        </p>
    </section>
    <section class="bet-share-join__terms-area" *ngIf="errorSeverity !== enumErrorSeverity.CRITICAL">
            <h2 class="header-terms">Terms and Conditions</h2>
            <p>
                The terms and conditions set forth below apply to the account holder’s participation in Bet Share. Except as set forth below, the general terms and conditions of TwinSpires.com <a href="https://www.twinspires.com/terms">(https://www.twinspires.com/terms)</a> apply to the activities of Bet Share and are incorporated herein. For the avoidance of doubt, in the event that the terms and provisions set forth below conflict with the general terms and provisions of TwinSpires.com, the terms set forth below shall prevail as it relates to the activity and Wagers placed through Bet Share. If any term or condition set forth below is deemed invalid, illegal or unenforceable in any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or condition below nor invalidate or render unenforceable such term or condition in any other jurisdiction. Contributions to BetShare wagers are not considered when evaluating handle toward VIP status. BetShare wagers do not accrue TSC Elite rewards points, and will not count toward any other individual offer except where expressly indicated.
            </p>
        
            <h3 class="terms-section">DEFINITIONS USED IN THESE BET SHARE TERMS AND CONDITIONS</h3>
        
            <p>In these Bet Share Terms and Conditions, where we use capitalized words they will have the meanings set out below:</p><br>

            <p class="definitions-terms">Captain:</p>
            <p style="margin-bottom:45px">A single player in charge of creating a wager and then allowing
                    others in the TwinSpires community to contribute funds to that wager.</p>

            <p class="definitions-terms">My Bet Contribution:</p>
            <p style="margin-bottom:45px">Players dollar amount purchases of the total bet cost.</p>

            <p class="definitions-terms">My Shares:</p>
            <p style="margin-bottom:45px">Number of shares held by player</p>

            <p class="definitions-terms">Open Shares:</p>
            <p style="margin-bottom:45px">Number of shares currently available.</p>

            <p class="definitions-terms">Participant:</p>
            <p style="margin-bottom:45px">A player who is able to contribute to a Bet Share by purchasing shares created by the captain.</p>

            <p class="definitions-terms">Reserve Shares:</p>
            <p style="margin-bottom:45px">Number of shares a captain reserves for himself for a specific Bet Share wager.</p>

            <p class="definitions-terms">Split Shares:</p>
            <p style="margin-bottom:45px">Number of shares a captain creates for a specific Bet Share wager.</p>

        
            <p>Bet Share is an online social pari-mutuel wagering service to bring TwinSpires’ players together to facilitate a fun and engaging social group for wagering. </p>
        
            <p>IN ORDER TO FACILITATE THE SOCIAL NATURE OF THE WAGER, ONLY THE CAPTAIN MAY CANCEL THE WAGER. NEVERTHELESS, ALL WAGERS PLACED BY THE CAPTAINS AND PARTICIPANTS UNDER BET SHARE ARE THE INDIVIDUAL WAGERS OF SUCH CAPTAIN OR PARTICIPANT AND THE ACTIVITIES HEREUNDER DO NOT CREATE SEPARATE POOLS. THE WAGERS PLACED HEREUNDER AND THE OPEN SHARES MADE AVAILABLE BY THE CAPTAIN ARE NOT SALES OR OFFERS TO SELL SECURITIES, BUT ARE MERELY WAGERS PLACED BY THE CAPTAIN AND PARTICIPANTS ON THE OUTCOME OF A HORSE RACE, WITHOUT ANY GUARANTEE OF A RETURN.</p>
        
            <h3 class="terms-section">CAPTAINS</h3>
        
            <p>
                A Captain will establish the Wager and share the Wagers with others in the TwinSpires’ community who may want to participate. The Captain sets the overall size of the Wager, the number of shares created under the Wager (Split Shares), and the number of shares the Captain wants to hold for their own account (Reserve Shares).
            </p>
        
            <p>Once a Captain places the Wager and shares the Wager with the community, only the Captain may cancel the Wager.</p>
        
            <h3 class="terms-section">PARTICIPANTS</h3>
        
            <p>A Participant will have received a notice from a Captain that the Captain has placed a Wager and wishes to accept Participants into the Wager. The Participant will review the Wager and receive the number of Split Shares created by the Captain, and the number of shares currently available to purchase (Open Shares). </p>
        
            <p>A Participant will purchase the number of desired Open Shares and will then become a Participant in the Wager. The Participant’s purchase is deemed a Wager by the Participant, however only a Captain may cancel the Wager. The Participant’s purchase amount will be visible to the Participant under the “My Bet Contribution” of the Bet Share site.</p>
        
            <h3 class="terms-section">WINNINGS</h3>
        
            <p>A winning Wager will be paid out to the Captain and each Participant in relation to their individual contributions.</p>
        
            <h3 class="terms-section">TAX REPORTING AND WITHHOLDING</h3>
        
            <p>In keeping with all Federal Tax Laws set forth by the Internal Revenue Service (IRS), any Wager placed by a Captain that, in the aggregate (i.e. including all Shares held by the Captain and all Participants), results in winnings over $5,000.00 or 300 times the aggregate amount of such Wager, is subject to reporting and withholding twenty-four percent (24%) (or such then current applicable rate required by law) for each individual participating in the Wager. An individualized Form W2-G will be sent to the Captain and each Participant summarizing the individual activity of the recipient.</p>
        
        </section>
</section>


