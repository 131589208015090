export const LANG_SUMMARY_STATS_CATEGORIES_NAME = 'summary-stats-categories';

export const LANG_SUMMARY_STATS_CATEGORIES = {
    'last14': 'Last 14 Days',
    'last30': 'Last 30 Days',
    'last365AllTracks': 'Last 365 Days',
    'favorite': 'Favorite',
    'pacers': 'Pacers',
    'trotters': 'Trotters',
    'oddsGE5to1': 'Odds &ge; 5',
    'oddsLT5to1': 'Odds &lt; 5',
    'shortLayoff': 'Short Layoff',
    'longLayoff': 'Long Layoff',
    'upInClass': 'Up In Class',
    'downInClass': 'Down In Class',
    'PostStatsALL_FAST_TRACKS': 'Post Stats (All Fast Tracks)',
    'PostStatsAT_TRACK': 'Post Stats (At Track)',
    'driverTrainer-trainer': 'Trainer with Driver',
    'driverTrainer-driver': 'Driver with Trainer',
    'driverHorse-driver': 'Driver with Horse',
    'trainerHorse-trainer': 'Trainer with Horse'

};
