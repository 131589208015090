import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    ViewChild
} from '@angular/core';
import { VideoBarAbstractComponent } from 'app/wagering/video/components/video-bars/video-bar-abstract.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { VideoAngles } from '@cdux/ng-common';
import { VideoFeedStates } from 'app/wagering/video/enums/video-feed-states';

@Component({
    selector: 'cdux-video-button-bar',
    templateUrl: './video-button-bar.component.html',
    styleUrls: ['video-button-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoButtonBarComponent extends VideoBarAbstractComponent {
    public showingAngles: boolean = false;

    private _hideAnglesTimeout = null;
    private _openedFromTouch = false;

    @ViewChild('replayButton') private _replayButton: ElementRef;
    @ViewChild('replayToolTip') private _replayToolTip: ElementRef;

    @HostListener('document:click', ['$event'])
    externalClick(event) {
        if (this._replayButton && this._replayToolTip) {
            const isReplayButton = this._replayButton.nativeElement.contains(event.target);
            const isReplayToolTip = this._replayToolTip.nativeElement.contains(event.target);

            // Always hide the angles if we click something other than the replay button or the replay tool tip
            if (!(isReplayButton || isReplayToolTip)) {
                this.hideAngles();
            }
        }
    }

    constructor(
        protected changeDetector: ChangeDetectorRef,
        protected eventTrackingService: EventTrackingService,
    ) {
        super(changeDetector, eventTrackingService);
    }

    // Override the showReplayButton from the abstract to use the presence of
    // feed angles since we are making a call to see what's available before
    // attempting to play a replay
    public showReplayButton(): boolean {
        return !!this.feedAngles.length;
    }

    public showAngles() {
        if (this._hideAnglesTimeout) {
            clearTimeout(this._hideAnglesTimeout);
        }
        this.showingAngles = true;
    }

    public hideAngles(delay: boolean = false) {
        if (delay) {
            this._hideAnglesTimeout = setTimeout(
                () => {
                    this.showingAngles = false;
                    this._detectChanges();
                }, 100);
        } else {
            this.showingAngles = false;
        }
    }

    public hasAngle(angle: VideoAngles): boolean {
        return this.feedAngles.includes(angle);
    }

    public selectAngle(angle: VideoAngles) {
        if (this._openedFromTouch) {
            return;
        }

        this.toggleAngle(angle);
        this.toggleFeed(VideoFeedStates.REPLAY);
    }

    public toggleShowingAngles() {
        this.showingAngles = !this.showingAngles;
    }

    public toggleReplay(feedState: VideoFeedStates, $event?: any) {
        if (this._openedFromTouch) {
            return;
        }

        // If a timeout exists, we opened the angle selector via a mouseover.
        // In this event, we do not need to toggle the angle selector.
        if (this._hideAnglesTimeout) {
            clearTimeout(this._hideAnglesTimeout);
        } else {
            // We have clicked replay, and do not have a mouseover event, so we can toggle the angle selector
            this.toggleShowingAngles();
        }

        this.toggleFeed(feedState, $event);
    }

    public toggleFeed(feedState: VideoFeedStates, $event?: any) {
        if (this.feedState !== feedState) {
            super.toggleFeed(feedState, $event);
        }
    }

    public touchedReplayButton() {
        this._openedFromTouch = true;
        this.toggleShowingAngles();
    }

    public touchedFeedAngle(angle: VideoAngles) {
        this._openedFromTouch = true;
        this.toggleShowingAngles();
        this.toggleAngle(angle);
        this.toggleFeed(VideoFeedStates.REPLAY);
    }
}
