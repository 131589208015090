import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { IPoolsDataResponse, ITrackBasic, RaceInfoService, ToteDataService } from '@cdux/ng-common';
import { Observable, ReplaySubject } from 'rxjs/index';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { takeWhileInclusive } from 'app/shared/common/operators';

export class ExoticPoolsRequestHandler extends CduxRxJSBuildingBlock<any, IPoolsDataResponse> {
    protected _stream: Observable<IPoolsDataResponse>;

    /** CONTROLS **/
    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    /** END CONTROLS **/

    constructor(
        private _raceInfoService: RaceInfoService,
        private _toteDataService: ToteDataService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._raceNavigationChanges.complete();
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceNavigationChanges.next(track);
    }
    /** END EXTERNAL CONTROLS **/

    protected _init() {
        this._stream = this._raceNavigationChanges.pipe(
            switchMap(track => this._getExoticPools(track)),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        );
    }

    private _getExoticPools(track: ITrackBasic): Observable<IPoolsDataResponse> {
        let pools = of(null);

        if (track.BrisCode && track.TrackType && track.RaceNum) {
            pools = this._raceInfoService.isClosed(track.BrisCode, track.TrackType, track.RaceNum).pipe(
                switchMap((raceIsClosed: boolean) =>
                    this._toteDataService.getExoticPools(
                        track.BrisCode,
                        track.TrackType,
                        track.RaceNum.toString(),
                        !raceIsClosed)
                    .pipe(
                        takeUntil(this._kill),
                        // Only take one if the race is closed
                        takeWhileInclusive(() => !raceIsClosed, true)
                    )
                )
            );
        }

        return pools;
    }
}
