import { Injectable } from '@angular/core';
import { TranslateService } from '@cdux/ng-common';


@Injectable()
export class BetCustomAmountService {

    constructor(private _translateService: TranslateService) {
    }

    public hasError(baseAmount: number, maxAmount: number, betMultiple: number, betAmount: number): string {
        if (baseAmount && betAmount < baseAmount) {
            return this._translateService.translate('message-min-bet-not-met', 'bet-custom-amount', false, baseAmount.toString());
        }
        if (maxAmount && betAmount > maxAmount) {
            return this._translateService.translate('message-max-bet-exceeded', 'bet-custom-amount', false, maxAmount.toString());
        }
        if (betMultiple && !this.isMultiple(betAmount, betMultiple)) {
            return this._translateService.translate('message-multiple-bet-not-meet', 'bet-custom-amount', false, betMultiple.toString());
        } else {
            return null
        }
    }

    private isMultiple(betAmount: number, betMultiple: number): boolean {
        // finding the decimal places
        const decimals = betMultiple.toString().split('.')[1];
        let decimalMultiplier: number = 1;
        if (decimals) {
            // assign the multiplier value to finding the multiples for bet amount with decimal
            decimalMultiplier = Math.pow(10, decimals.length);
        }
        return (((betAmount * decimalMultiplier) % (betMultiple * decimalMultiplier)) === 0);
    }

}
