export const LANG_BET_SHARE_NAME = 'bet-share';

export const LANG_BET_SHARE = {
    'subject': 'Join my bet at TwinSpires!',
    'url-segment': '/betshare/',
    'message-captain': '%s invites you to join a bet at TwinSpires! %s placed a %s bet at %s today, and has %s shares available here: ',
    'message-participant': '%s invites you to join a bet at TwinSpires! %s shared a %s bet at %s today, and has %s shares available here: ',
    'twitter-message-captain': 'Join my bet at @TwinSpires! #BetShare I placed a %s bet at %s today, and have %s shares available here: ',
    'twitter-message-participant': 'Join my bet at @TwinSpires! #BetShare I shared a %s bet at %s today, and have %s shares available here: ',
    'share-your-bet-default': '%s/share-your-bet?id=[BSID]',
    'share-bet-title-small': 'Share',
    'share-bet-title': 'Share Bet'
};
