import {
    AfterViewInit, ChangeDetectorRef,
    Component, ComponentRef,
    OnDestroy,
    OnInit, Renderer2,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { enumToggleState, FeatureToggleDataService } from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { CduxSidebarComponent } from '../../../sidebar/components/cdux-sidebar.component';

@Component({
    selector: 'cdux-header-footer-layout',
    templateUrl: './layout-header-footer.component.html',
    styleUrls: [ './layout-header-footer.component.scss' ],
    encapsulation: ViewEncapsulation.None,
})
export class LayoutHeaderFooterComponent implements OnInit, AfterViewInit, OnDestroy {

    /**
     * A property determining if we should implement the full page drawer
     *
     * @type {boolean}
     */
    public fullPageDrawerFT: boolean = false;

    /**
     * A property determining if the nav-panel is open.
     *
     * @type {boolean}
     */
    public navPanelOpen: boolean = false;

    /**
     * Is there more content in the sidebar?
     */
    public moreContent: boolean;

    /**
     * This is a reference to the cdux-sidebar component, which can be moved on screen resizing.
     *
     * @type {ComponentRef<CduxSidebarComponent>}
     */
    private _navPanel: ComponentRef<CduxSidebarComponent>;

    /**
     * Marker for the subfooter nav-panel placement.
     *
     * @type {ViewContainerRef}
     */
    @ViewChild('footerNavPanel', {static: true, read: ViewContainerRef}) protected _footer: ViewContainerRef;

    /**
     * Marker for the header nav-panel placement.
     *
     * @type {ViewContainerRef}
     */
    @ViewChild('headerNavPanel', {static: true, read: ViewContainerRef}) protected _header: ViewContainerRef;

    /**
     * Indicator for whether the nav-panel is placed for viewing in the header.
     *
     * @private
     * @type {boolean}
     */
    private _navPanelInDesktopMode = true;

    /**
     * Emits on destruction of the component.
     *
     * @type {Subject<any>}
     */
    private _onDestroy: Subject<any> = new Subject();

    constructor(
        private _mediaToggle: CduxMediaToggleService,
        private _renderer: Renderer2,
        private _changeDetector: ChangeDetectorRef,
        private _featureToggleSvc: FeatureToggleDataService,
    ) {}

    ngOnInit() {
        // Constructing the nav-panel programmatically so that we have access to the ViewRef.
        let marker;
        // Determine the marker that the nav-panel should be placed after.
        if (this._mediaToggle.query('phone')) {
            this._navPanelInDesktopMode = false;
            marker = this._footer;
        } else {
            this._navPanelInDesktopMode = true;
            marker = this._header;
        }
        this.fullPageDrawerFT = this._featureToggleSvc.isFeatureToggleOn('FULL_PAGE_DRAWER');
        this._navPanel = marker.createComponent(CduxSidebarComponent);
        // Add the nav-panel class.
        this._renderer.addClass(this._navPanel.instance.element, 'nav-panel');
        // Listen to state changes.
        this._navPanel.instance.stateChange
            .pipe(
                takeUntil(this._onDestroy)
            )
            .subscribe(state => this.onNavPanelStateChange(state));
        }

        ngAfterViewInit() {
        this._mediaToggle
            .registerQuery('phone')
            .pipe(
                takeUntil(this._onDestroy)
            )
            .subscribe(onPhone => {
                if (onPhone && this._navPanelInDesktopMode) {
                    this._renderer.setProperty(this._navPanel.instance.element, 'id', 'cdux-subfooter');
                    this._renderer.appendChild(this._footer.element.nativeElement, this._navPanel.instance.element);
                    this._navPanelInDesktopMode = false;
                } else if (!onPhone && !this._navPanelInDesktopMode) {
                    this._renderer.setProperty(this._navPanel.instance.element, 'id', 'cdux-sidebar');
                    this._renderer.appendChild(this._header.element.nativeElement, this._navPanel.instance.element);
                    this._navPanelInDesktopMode = true;
                }
                setTimeout(_ => {
                    this._changeDetector.detectChanges()
                });
            });
    }

    ngOnDestroy() {
        this._onDestroy.next(undefined);
    }

    /**
     * Used to update the more content property.
     *
     * @param value
     */
    public updateMoreContent(value) {
        this.moreContent = value;
    }

    /**
     * Responds to changes in the state of the nav-panel.
     *
     * @param {enumToggleState} navPanelState
     */
    public onNavPanelStateChange(navPanelState: enumToggleState) {
        this.navPanelOpen = navPanelState === enumToggleState.ON;
        this._changeDetector.detectChanges();
    }
}
