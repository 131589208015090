import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AnglesMarks,
    AnglesRace,
    BetTypeUtil,
    ConfigurationDataService,
    DetectionService,
    EntrySelectionUtilBusinessService,
    enumBetModifier,
    enumFeatureToggle,
    enumTrackType,
    EventClickAttributeType,
    EventClickType,
    FavoriteRunnersService,
    FeatureToggleDataService,
    IBetNavObject,
    ISelectedEntry,
    ITodaysRace,
    ITrackBasic,
    ITrainerDriverRaceSummary,
    ITrainerDriverEntrySummary,
    ITrainerJockeyEntrySummary,
    JwtSessionService,
    MultiRaceExoticBetType,
    ProgramEntry,
    ToteDataService,
    TracksDataService,
    TrackService,
    TrainerDriverSummaryDataService,
    TrainerJockeySummaryDataService,
    WagerState,
    FavoritePersonService,
    PreferencesService,
    ISummaryRunnerStats,
    RunnerStatsDataService,
    ISummaryRaceStats,
    RaceStatsDataService
} from '@cdux/ng-common';
import { FormatRank, ISelection } from '@cdux/ng-fragments';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    take,
    takeUntil,
    withLatestFrom
} from 'rxjs/operators';
import {
    combineLatest,
    Observable,
    ReplaySubject,
    Subject
} from 'rxjs';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { enumProgramSort } from 'app/shared/program/enums/program-sort-columns.enum';
import { ISortState, SortStateHandlerClass } from 'app/shared/program/classes/sort-state-handler.class';
import { EntriesSorterUtil } from 'app/shared/program/utils/EntriesSorterUtil';
import { RaceDetailsRequestHandler } from 'app/shared/betpad/classes/race-details-request-handler.class';
import { TrainerJockeySummaryRequestHandler } from 'app/shared/betpad/classes/trainer-jockey-summary-request-handler.class';
import { IEntryUpdates } from 'app/shared/program/classes/entry-update-handler.class';
import { TrainerDriverSummaryRequestHandler } from '../../classes/trainer-driver-handler.class';
import { ILegInfo } from 'app/shared/program/interfaces/leg-info.interface';
import { ISelectedProgramNumber } from 'app/shared/betpad/interfaces/selected-program-number.interface';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { VisibleProgramEntry } from '../../models/visible-program-entry.model';
import { AnglesRequestHandler } from 'app/shared/betpad/classes/angles-request-handler.class';
import { LegInfoUtil } from 'app/shared/program/utils';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';
import { DisplayModeEnum } from 'app/shared/common/enums/display-mode.enum';
import { IViewableWagerData } from 'app/shared/betpad/interfaces/viewable-wager-data.interface';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { AccountBubbleNotificationService } from '../../../notification/services/account-bubble-notification.service';
import { FavEventType } from 'app/account/favorites/favorites-event-interface';
import { PreferencesComponent } from 'app/shared/my-account/preferences/preferences.component';
import { ProgramSummaryRacestatsComponent } from '../program-summary/program-summary-racestats.component';
import { RunnerStatsRequestHandler } from '../../classes/runner-stats-handler.class';
import { RaceStatsRequestHandler } from '../../classes/race-stats-handler.class';
import { ProgramEntryComponent } from '../program-entry/program-entry.component';

export interface Rankings {
    [field: string]: {
        rank: number,
        ordinal: string,
        percentage: number
    }
}

export interface RankingsMap {
    [postPosition: string]: Rankings
}

@Component({
    selector: 'cdux-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramComponent implements OnInit, OnDestroy {
    private readonly CHECKIO_CLASS = 'checkio-';
    private readonly LEG_CLASS = 'legs-';
    private _formatRank = new FormatRank();
    private _allEntriesUpdate: ProgramEntry[][] = [];
    private _sortStateHandler: SortStateHandlerClass<enumProgramSort>;
    private _trainerDriverSummaryRequestHandler: TrainerDriverSummaryRequestHandler;
    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    private _trainerJockeySummaryRequestHandler: TrainerJockeySummaryRequestHandler;
    private _anglesRequestHandler: AnglesRequestHandler;
    private _runnerStatsRequestHandler: RunnerStatsRequestHandler;
    private _raceStatsRequestHandler: RaceStatsRequestHandler;
    private _killSubscription: Subject<any> = new Subject<any>();
    private _selectedBettingInterests: ISelectedEntry[][];
    private _focusedLegNumber: number;
    private _focusedLegChanged: ReplaySubject<number> = new ReplaySubject(1);
    public get focusedLegNumber(): number {
        return this._focusedLegNumber;
    }

    public set focusedLegNumber(value: number) {
        if (this._focusedLegNumber !== value) {
            this._focusedLegNumber = value;
            this._focusedLegChanged.next(value);
        }
    }
    public visibleEntries: VisibleProgramEntry[] = [];
    public rankingsMap: RankingsMap = {};
    public trainerJockeySummaryMap: { [postPosition: string]: ITrainerJockeyEntrySummary } = {};
    public trainerDriverSummaryMap: { [programNumber: string]: ITrainerDriverEntrySummary } = {};
    public summaryRunnerStatsMap: { [postPosition: string]: ISummaryRunnerStats } = {};
    public anglesMap: { [postPosition: string]: AnglesMarks } = {};
    public track: ITrackBasic;
    public focusedTrack: ITrackBasic;
    public isTbredTrack: boolean;
    public isGreyTrack: boolean;
    public isHarnessTrack: boolean;
    public riderTypeHeading: string;
    public isNorthAmericanTrack: boolean;
    public isJockeySilksEnabled: boolean;
    public areSilksAvailable: boolean;
    public isProfitlineOddsEnabled: boolean;
    public summaryRunnerStats: ISummaryRunnerStats[];
    public summaryRaceStats: ISummaryRaceStats;

    public get isBasicClass(): boolean {
        return this.section === ViewSectionEnum.PROGRAM_BASIC;
    }

    public get isSummaryClass(): boolean {
        return this.section === ViewSectionEnum.PROGRAM_SUMMARY;
    }

    @ViewChild(ProgramSummaryRacestatsComponent, {read: ProgramSummaryRacestatsComponent, static: false})
    public programSummaryRacestatsComponent: ProgramSummaryRacestatsComponent;
    
    @ViewChild(ProgramEntryComponent, {read: ProgramEntryComponent, static: false})
    public programEntryComponent: ProgramEntryComponent;

    public legInfo: ILegInfo;
    public selectedProgramNumbers: ISelectedProgramNumber = {};
    public checkioClass: string;
    public sectionClass: string;
    public legClass: string;
    public areAllSelected: boolean[] = [];
    public isKey: boolean = false;
    public isWagerableRace: boolean;
    public selectionEnabled: boolean;
    public isMobileDevice: boolean;

    public isCompact = false;
    public DisplayModeEnum = DisplayModeEnum;
    private _displayMode;

    public enumProgramSort = enumProgramSort;
    public sortState: ISortState<enumProgramSort>;

    private _toteDate: string;
    public favRunnerIds: number[] = [];
    public favPersonIds: number[] = [];

    // specify alternate initial sort directions
    private _defaultSortList = [
        enumProgramSort.SORTABLE_PROGRAM_NUMBER,
        enumProgramSort.ODDS_RANK,
        enumProgramSort.MORNINGLINE_ODDS,
        enumProgramSort.PROFITLINE_ODDS,
        enumProgramSort.PRIOR_RUN_STYLE,
        enumProgramSort.EARLY_PACE,
        enumProgramSort.MID_PACE,
        enumProgramSort.LATE_PACE_H,
        enumProgramSort.SUMMARY_RUN_STYLE
    ];

    public eventClickType = EventClickType;
    public eventClickAttributeType = EventClickAttributeType;

    // Subjects
    private _wager: ReplaySubject<WagerState> = new ReplaySubject<WagerState>();
    private _wagerState: WagerState;

    @Input()
    public set wager(wager: WagerState) {
        if (wager) {
            if (!TrackService.isExactTrackObject(this.track, wager.basicTrack)) {
                // change entries, clear selections for new track or race
                this.track = wager.basicTrack;
                this.isTbredTrack = this.track && this.track.TrackType === enumTrackType.TBRED;
                this.isGreyTrack = this.track && this.track.TrackType === enumTrackType.GREY;
                this.isHarnessTrack = this.track && this.track.TrackType === enumTrackType.HARN;
                this.riderTypeHeading = TrackService.getRiderType(this.track);
                this._raceDetailsRequestHandler.updateRaceNavigation(wager.basicTrack);

                if (this.section === ViewSectionEnum.PROGRAM_ADVANCED) {
                    if (this.isHarnessTrack)    {
                        this._trainerDriverSummaryRequestHandler.resume();
                        this._trainerJockeySummaryRequestHandler.pause();
                    } else {
                        this._trainerJockeySummaryRequestHandler.resume();
                        this._trainerDriverSummaryRequestHandler.pause();
                    }
                }
            }
            this.isKey = !!wager.betNav ? (wager.betNav.modifier === enumBetModifier.KEY) : false;
            this._wager.next(wager);
            this._wagerState = wager;
        }
    }
    public get wager(): WagerState {
        return this._wagerState;
    }

    @Input()
    public entries: Observable<IEntryUpdates>;

    private _section: ViewSectionEnum;
    @Input()
    public set section(section: ViewSectionEnum) {
        this._section = section;
        this._generateSectionClass();

        if (section === ViewSectionEnum.PROGRAM_ADVANCED) {
            if (this.isHarnessTrack)    {
                this._trainerDriverSummaryRequestHandler.resume();
                this._trainerJockeySummaryRequestHandler.pause();
            } else {
                this._trainerJockeySummaryRequestHandler.resume();
                this._trainerDriverSummaryRequestHandler.pause();
            }
        } else {
            this._trainerDriverSummaryRequestHandler.pause();
            this._trainerJockeySummaryRequestHandler.pause();
        }

        if (section === ViewSectionEnum.PROGRAM_TIPS) {
            this._anglesRequestHandler.resume();
        } else {
            this._anglesRequestHandler.pause();
        }

        if (section === ViewSectionEnum.PROGRAM_SUMMARY) {
            this._runnerStatsRequestHandler.resume();
            this._raceStatsRequestHandler.resume();
        } else {
            this._runnerStatsRequestHandler.pause();
            this._raceStatsRequestHandler.pause();
        }
    }
    public get section(): ViewSectionEnum {
        return this._section;
    }

    @Input()
    public set displayMode(displayMode: DisplayModeEnum) {
        this.isCompact = displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE;
        this._displayMode = displayMode;
    }
    public get displayMode(): DisplayModeEnum {
        return this._displayMode;
    }

    @Input()
    public viewableWagerData: IViewableWagerData;

    @Input()
    public view: WageringViewEnum;

    @Output()
    onSelectionChange: EventEmitter<ISelectionUpdate[]> = new EventEmitter<ISelectionUpdate[]>();

    public showFavorite: boolean; // a flag to indicate whether or not showing the favor badge depending on feature toggle and login state
    public showTrainerFavorite: boolean;
    public showJockeyFavorite: boolean;
    public isExpertPicksHidden: boolean;

    constructor(
        configurationDataService: ConfigurationDataService,
        toteDataService: ToteDataService,
        tracksDataService: TracksDataService,
        trainerJockeySummaryDataService: TrainerJockeySummaryDataService,
        trainerDriverSummaryDataService: TrainerDriverSummaryDataService,
        runnerStatsDataService: RunnerStatsDataService,
        raceStatsDataService: RaceStatsDataService,
        private _acctBubbleNotificationService: AccountBubbleNotificationService,
        private _changeDetector: ChangeDetectorRef,
        private _detectionService: DetectionService,
        private _entrySelectionService: EntrySelectionUtilBusinessService,
        private _eventService: EventTrackingService,
        private _favoriteRunnerService: FavoriteRunnersService,
        private _favoritePersonService: FavoritePersonService,
        private _featureToggleService: FeatureToggleDataService,
        private _preferencesService: PreferencesService,
        private _sessionService: JwtSessionService,
        private _viewStateService: ViewStateService,
        private _wageringUtilService: WageringUtilBusinessService,
    ) {
        this._raceDetailsRequestHandler = new RaceDetailsRequestHandler(tracksDataService);
        this._trainerJockeySummaryRequestHandler = new TrainerJockeySummaryRequestHandler(toteDataService, trainerJockeySummaryDataService);
        this._trainerDriverSummaryRequestHandler = new TrainerDriverSummaryRequestHandler(configurationDataService, tracksDataService, toteDataService, trainerDriverSummaryDataService);
        this._anglesRequestHandler = new AnglesRequestHandler(toteDataService, tracksDataService);
        this._runnerStatsRequestHandler = new RunnerStatsRequestHandler(toteDataService, runnerStatsDataService);
        this._raceStatsRequestHandler = new RaceStatsRequestHandler(toteDataService, raceStatsDataService);
        this._sortStateHandler = new SortStateHandlerClass<enumProgramSort>(this._determineInitialSort());

        this._defaultSortList.forEach(property =>
            this._sortStateHandler.updateDefaultAscending(property, true)
        );

        toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this._toteDate = date;
        });

        this.isJockeySilksEnabled = this._featureToggleService.isFeatureToggleOn('JOCKEY_SILKS');
        this.isProfitlineOddsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PROFITLINE_ODDS);
        this.showJockeyFavorite = this._featureToggleService.isFeatureToggleOn('STABLE_JOCKEY');
        this.showTrainerFavorite = this._featureToggleService.isFeatureToggleOn('STABLE_TRAINER');
    }

    ngOnInit() {
        this.focusedLegNumber = 0;

        const entriesObs = this.entries.pipe(
            this._getVisibleEntries()
        );

        entriesObs.pipe(
            this._sortEntries(),
            withLatestFrom(this._sortStateHandler.listen()),
            takeUntil(this._killSubscription)
        ).subscribe((
            [[_allEntriesUpdate, sortedEntries, summaryRunnerStatsSort], sortState]:
                [[IEntryUpdates, VisibleProgramEntry[], ISummaryRunnerStats[]], ISortState<enumProgramSort>]
        ) => {
            this.sortState = sortState;
            this._viewStateService.setSortState(ViewStateGroupEnum.PROGRAM, this.sortState);
            this.visibleEntries = sortedEntries;
            this._changeDetector.detectChanges();
            this.programEntryComponent.summaryRunnerSort;
            this.summaryRunnerStats
        });

        entriesObs.pipe(
            withLatestFrom(this._focusedLegChanged),
            takeUntil(this._killSubscription)
        ).subscribe((
            [[allEntriesUpdate, visibleEntries], focusedLegNumber]:
                [[IEntryUpdates, VisibleProgramEntry[]], number]
        ) => {
            this._allEntriesUpdate = allEntriesUpdate.updatedEntries;
            this._selectedBettingInterests = allEntriesUpdate.bettingInterests;
            this.legInfo = LegInfoUtil.assembleLegInfo(allEntriesUpdate.wagerState.betNav, this.isCompact);
            this.checkioClass = this.CHECKIO_CLASS + (this.legInfo && this.legInfo.legsCounter ? this.legInfo.legsCounter.length.toString() : '0');
            this.legClass = this.LEG_CLASS + (this.legInfo && this.legInfo.legsCounter ? this.legInfo.legsCounter.length.toString() : '0');
            this.selectedProgramNumbers = this._wageringUtilService.getSelectedProgramNumbers(allEntriesUpdate.bettingInterests, 'ProgramNumber');
            this.areAllSelected = allEntriesUpdate.allSelected;
            if (allEntriesUpdate.scratches.length > 0) {
                this.onSelectionChange.emit(allEntriesUpdate.scratches);
            }

            this._generateSectionClass();

            // rebuild a relative rankings map for updated visible entries
            this.rankingsMap = this._generateRankingsMap(visibleEntries, [
                { column: enumProgramSort.EARLY_PACE_1, rankAsc: false },
                { column: enumProgramSort.EARLY_PACE_2, rankAsc: false },
                { column: enumProgramSort.LATE_PACE, rankAsc: false },
                { column: enumProgramSort.AVG_SPEED, rankAsc: false },
                { column: enumProgramSort.AVG_DISTANCE, rankAsc: false },
                { column: enumProgramSort.BEST_SPEED, rankAsc: false },
                { column: enumProgramSort.PRIME_POWER, rankAsc: false },
                { column: enumProgramSort.LAST_CLASS, rankAsc: false },
                { column: enumProgramSort.AVG_CLASS, rankAsc: false },
                { column: enumProgramSort.POWER_RATING, rankAsc: false },
                { column: enumProgramSort.BEST_SPEED_HARN, rankAsc: false },
                { column: enumProgramSort.EARLY_PACE, rankAsc: true },
                { column: enumProgramSort.MID_PACE, rankAsc: true },
                { column: enumProgramSort.LATE_PACE_H, rankAsc: true },
                { column: enumProgramSort.SUMMARY_DAYS_OFF, rankAsc: false },
                { column: enumProgramSort.SUMMARY_RUN_STYLE, rankAsc: false },
                { column: enumProgramSort.SUMMARY_AVG_SPD, rankAsc: false },
                { column: enumProgramSort.SUMMARY_BACK_SPD, rankAsc: false },
                { column: enumProgramSort.SUMMARY_SPD_LR, rankAsc: false },
                { column: enumProgramSort.SUMMARY_AVG_CLS, rankAsc: false },
                { column: enumProgramSort.SUMMARY_PRM_PWR, rankAsc: false },
                { column: enumProgramSort.SUMMARY_JOCKEY_WN, rankAsc: false },
                { column: enumProgramSort.SUMMARY_TRAINER_WN, rankAsc: false },
                { column: enumProgramSort.SUMMARY_MONEY_WON, rankAsc: false }
            ]);

            // fetch additional visible entry data for focused leg of wager
            const focusedTrack = (!this.legInfo || !this.legInfo.multiRace)
                ? allEntriesUpdate.wagerState.basicTrack // single race leg
                : {
                    ...allEntriesUpdate.wagerState.basicTrack, // multi leg
                    RaceNum: +this.legInfo.legRaceNumbers[focusedLegNumber]
                };

            // update focused track and remove existing track-specific data
            if (!TrackService.isExactTrackObject(this.focusedTrack, this.focusedTrack = focusedTrack)) {
                this.trainerJockeySummaryMap = {}, this.trainerDriverSummaryMap = {}, this.anglesMap = {}; this.summaryRunnerStatsMap;

                if (focusedTrack.TrackType === enumTrackType.HARN) {
                    this._trainerDriverSummaryRequestHandler.updateRaceNavigation(focusedTrack);
                } else {
                    this._trainerJockeySummaryRequestHandler.updateRaceNavigation(focusedTrack);
                }

                this._anglesRequestHandler.updateRaceNavigation(focusedTrack);
                this._runnerStatsRequestHandler.updateRaceNavigation(focusedTrack);
                this._raceStatsRequestHandler.updateRaceNavigation(focusedTrack);
            }

            this._changeDetector.detectChanges();
        });

        combineLatest([
            this._raceDetailsRequestHandler.listen(),
            this._trainerJockeySummaryRequestHandler.listen(),
            this._trainerDriverSummaryRequestHandler.listen(),
            this._anglesRequestHandler.listen(),
            this._runnerStatsRequestHandler.listen(),
            this._raceStatsRequestHandler.listen(),
        ]).pipe(
            debounceTime(200),
            takeUntil(this._killSubscription)
        ).subscribe(([raceDetails, trainerJockeySummaries, trainerDriverSummaries, angles, summaryRunnerStats, summaryRaceStats]: [ITodaysRace, ITrainerJockeyEntrySummary[], ITrainerDriverRaceSummary, AnglesRace, ISummaryRunnerStats[], ISummaryRaceStats]) => {
            this.isNorthAmericanTrack = raceDetails && raceDetails.country && (raceDetails.country.toLowerCase() === 'usa' || raceDetails.country.toLowerCase() === 'can');
            this.isWagerableRace = this._isWagerableRace(raceDetails);
            this.areSilksAvailable = !!(raceDetails && raceDetails.hasSilks);

            if (trainerJockeySummaries) {
                trainerJockeySummaries.reduce(
                    // index by post position
                    (d, e) => (d[e.postPosition] = e, d),
                    this.trainerJockeySummaryMap = {}
                );
            }

            if (trainerDriverSummaries) {
                this.trainerDriverSummaryMap = trainerDriverSummaries.runnerStats
            }

            if (summaryRunnerStats) {
                summaryRunnerStats.forEach(stats => {
                    this.summaryRunnerStatsMap[stats.postPosition] = stats;
                });
            } else {
                this.summaryRunnerStatsMap = {};
            }

            if (summaryRaceStats) {
                this.summaryRaceStats = summaryRaceStats;
            }

            if (angles) {
                angles.anglesMarks.reduce(
                    // index by post position
                    (d, e) => (d[e.programNumber] = e, d),
                    this.anglesMap = {}
                );
            }

            this._changeDetector.detectChanges();
        });

        this._getBetNavStream().pipe(
            distinctUntilChanged((prev, curr) => {
                return BetTypeUtil.isSameWagerType(prev.type, curr.type);
            }),
            withLatestFrom(this._sortStateHandler.listen()),
            takeUntil(this._killSubscription)
        ).subscribe(([val, sortState]: [IBetNavObject, ISortState<enumProgramSort>]) => {
            this.focusedLegNumber = 0;
            // if the wager type is a multi-race wager, reset the sort to the default
            if (val.type instanceof MultiRaceExoticBetType && this.isNotSortedByProgramNumber(sortState)) {
                this._resetDefaultSortDirections();
            } else {
                // if the wager isn't a multi-race wager, make sure the focused leg is always the first
                this.focusedLegNumber = 0;
            }
            this._changeDetector.detectChanges();
        });

        const entriesStateObs = entriesObs.pipe(
            map(([allEntriesUpdate, sortedEntries]) => {
                return allEntriesUpdate;
            })) as Observable<IEntryUpdates>;
        this._disableUserInteractionSub(entriesStateObs);

        this.isMobileDevice = this._detectionService.isMobileDevice();

        this.showFavorite = this._sessionService.isLoggedIn();
        if (this.showFavorite) {
            this._updateFavRunnerIds();
        }

        if (this.showJockeyFavorite || this.showTrainerFavorite) {
            this._updateFavPersonIds();
        }
        this._sessionService.onAuthenticationChange.pipe(
            takeUntil(this._killSubscription)
        ).subscribe((loggedIn) => {
            this.showFavorite = loggedIn;
            if (this.showFavorite) {
                this._updateFavRunnerIds();
            }
            if (this.showJockeyFavorite || this.showTrainerFavorite) {
                this._updateFavPersonIds();
            }
        });

        this._acctBubbleNotificationService.on([
                FavEventType.FAVORITE_RUNNER_ADD,
                FavEventType.FAVORITE_RUNNER_REMOVE])
            .pipe(
                takeUntil(this._killSubscription)
            ).subscribe(() => {
                this._updateFavRunnerIds();
                if (this.showJockeyFavorite || this.showTrainerFavorite) {
                    this._updateFavPersonIds();
                }
            }
        );

        this._preferencesService.watchFlag(PreferencesComponent.HIDE_EXPERT_PICKS_KEY, false).pipe(
            takeUntil(this._killSubscription)
        ).subscribe((value) => {
            this.isExpertPicksHidden = !!value;
            this._changeDetector.markForCheck();
        });
    }

    ngOnDestroy() {
        this._killSubscription.next();
        this._killSubscription.complete();
        this._raceDetailsRequestHandler.kill();
        this._sortStateHandler.kill();
        this._wager.complete();
    }

    /* when a track/race changes or wager type changes, there is period when the view
     * and wager state are out of sync. Temporarily disable user interaction unti the
     * the new entries or reformatted selections have been received and the view has re-rendered
     */
    private _disableUserInteractionSub(entriesObs: Observable<IEntryUpdates>) {
        combineLatest([this._wager, entriesObs])
            .pipe(takeUntil(this._killSubscription))
            .subscribe(([wagerState, allEntriesUpdate]) => {
                const emittedWagerState = allEntriesUpdate.wagerState;
                this.selectionEnabled = TrackService.isExactTrackObject(wagerState.basicTrack, emittedWagerState.basicTrack)
                    && (!!wagerState.betNav && !!emittedWagerState.betNav)
                    && this._sameBetNav(wagerState.betNav, emittedWagerState.betNav);
            });
    }

    private isNotSortedByProgramNumber(sortState: ISortState<enumProgramSort>): boolean {
        return sortState.sortProperty !== enumProgramSort.SORTABLE_PROGRAM_NUMBER || (sortState.sortProperty === enumProgramSort.SORTABLE_PROGRAM_NUMBER && !sortState.ascending)
    }

    public sortBy(columnName: enumProgramSort, forceAscending?: boolean): void {
        if (!!forceAscending) {
            this._sortStateHandler.updateSortProperty(columnName, forceAscending);
        } else {
            this._sortStateHandler.updateSortProperty(columnName);
        }
    }

    private _determineInitialSort(): ISortState<enumProgramSort> {
        return this.sortState = this._viewStateService.getSortState(ViewStateGroupEnum.PROGRAM) as ISortState<enumProgramSort>;
    }

    private _resetDefaultSortDirections() {
        this._defaultSortList.forEach(property =>
            this._sortStateHandler.updateDefaultAscending(property, true)
        );
    }

    private _sortEntries() {
        return (src: Observable<[IEntryUpdates, VisibleProgramEntry[]]>) => combineLatest(
            src,
            this._sortStateHandler.listen()
        ).pipe(
            map(([[entries, visibleEntries], sortState]: [[IEntryUpdates, VisibleProgramEntry[]], ISortState<enumProgramSort>]) => {    
                const sortedEntries = EntriesSorterUtil.sortEntries(
                    visibleEntries as ProgramEntry[], 
                    this.summaryRunnerStatsMap, 
                    sortState.ascending, 
                    sortState.sortProperty
                );
                return [entries, sortedEntries];
            })
        );
    }

    /**
     * Generate a RankingsMap for the given entries across the given fields.
     * Ties will be ranked identically. This method currently only works for numeric data.
     */
    private _generateRankingsMap(entries: VisibleProgramEntry[], fields: { column: enumProgramSort, rankAsc: boolean }[]): RankingsMap {
        const rankingsRange: { [field: string]: { max: number, min: number } } = {};

        // generate sorted array for each of the field values
        const values: { [field: string]: number[] } = {};
        fields.forEach(field => {
            values[field.column] = [];

            entries.forEach(entry => {
                if (!entry.BettingInterest || entry.Scratched) { return; } // scratched
                if (!entry[field.column]) { return; } // empty value
                let index = 0; // start at 0, find next index
                while (entry[field.column] < values[field.column][index]) {
                    index++; // value will be equal or higher
                }
                values[field.column].splice(index, 0, +entry[field.column]);
            });

            rankingsRange[field.column] = {
                max: values[field.column].length ? values[field.column][0] : 0,
                min: values[field.column].length ? Math.max(values[field.column][values[field.column].length - 1] - 10, 0) : 0
            };

            // if the column should be ranked from lowest to highest, reverse it (doesn't affect bar display)
            if (field.rankAsc) {
                values[field.column].reverse();
            }

        });

        // generate a map of relative field ranking per entry
        return entries.reduce<RankingsMap>((rmap, entry) => {
            rmap[entry.PostPosition] = fields.reduce<Rankings>((ranks, field) => {
                const rank = entry.Scratched ? 0 : values[field.column].indexOf(+entry[field.column]) + 1;
                ranks[field.column] = {
                    rank,
                    ordinal: rank ? this._formatRank.transform(rank) : '',
                    percentage: rank ? Math.round((+entry[field.column] - rankingsRange[field.column].min)
                        / (rankingsRange[field.column].max - rankingsRange[field.column].min) * 100) : 0
                };
                return ranks;
            }, {});
            return rmap;
        }, {});
    }

    private _generateSectionClass() {
        const classString = [];
        switch (this.section) {
            case ViewSectionEnum.PROGRAM_BASIC:
                classString.push('is-basic');
                break;
            case ViewSectionEnum.PROGRAM_ADVANCED:
                classString.push('is-advanced');
                break;
            case ViewSectionEnum.PROGRAM_SPEED:
                classString.push('is-speed');
                break;
            case ViewSectionEnum.PROGRAM_PACE:
                classString.push('is-pace');
                break;
            case ViewSectionEnum.PROGRAM_SUMMARY:
                classString.push('is-summary');
                break;
            case ViewSectionEnum.PROGRAM_CLASS:
                classString.push('is-class');
                break;
            case ViewSectionEnum.PROGRAM_TIPS:
                classString.push('is-angles');
                break;
            default:
                classString.push('is-basic');
                break;
        }
        classString.push(this.checkioClass);
        this.sectionClass = classString.join(' ');
    }

    private _getBetNavStream(): Observable<IBetNavObject> {
        return this._wager.pipe(
            map((wagerState) => wagerState.betNav),
            filter(betNav => !!betNav),
            // Adding share() will cause this stream to no longer
            // replay to late subscribers.
        );
    }

    private _getVisibleEntries() {
        return (src: Observable<IEntryUpdates>) => combineLatest([
            src,
            this._focusedLegChanged
        ]).pipe(
            map(([entries, focusedRaceNumber]: [IEntryUpdates, number]) => {
                let visibleEntries: (VisibleProgramEntry | (VisibleProgramEntry & ISummaryRunnerStats))[] = (
                    entries.updatedEntries[focusedRaceNumber] ||
                    // focused race number may no longer be valid
                    entries.updatedEntries[0] || // first entries
                    [] // no entries were found, use an empty set
                ).map(entry => new VisibleProgramEntry(entry, false));
                if (entries.wagerState.betNav && entries.wagerState.betNav.type instanceof MultiRaceExoticBetType) {
                    visibleEntries = visibleEntries.concat(this._wageringUtilService.getPaddedEntries(entries.updatedEntries, focusedRaceNumber));
                }
                return [entries, visibleEntries]
            })
        );
    }

    public selectRunner(data: ISelection, bettingInterest: number, leg: number) {
        const entries = this._wageringUtilService.getEntriesForLeg(this._allEntriesUpdate, this.legInfo, leg)
            .filter(entry => entry.BettingInterest && entry.BettingInterest === bettingInterest && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));
        if (entries.length > 0) {
            this.onSelectionChange.emit([{ entries: entries, selected: data.isSelected, leg: leg }]);
        }
    }

    public selectAll(data: ISelection, leg: number) {
        const entries = this._wageringUtilService.getEntriesForLeg(this._allEntriesUpdate, this.legInfo, leg)
            .filter(entry => entry.BettingInterest && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));
        if (entries.length > 0) {
            this.onSelectionChange.emit([{ entries: entries, selected: data.isSelected, leg: leg }]);
        }
        if (this.areAllSelected[leg]) {
            this._eventService.logClickEvent(
                EventClickType.SELECT_ALL_RUNNERS,
                [
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_BRIS_CODE, data: this.wager.basicTrack.BrisCode },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_TRACK_TYPE, data: this.wager.basicTrack.TrackType },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_RACE_NUMBER, data: this.wager.basicTrack.RaceNum },
                    { attrId: EventClickAttributeType.SELECT_ALL_RUNNERS_RACE_DATE, data: this._toteDate }
                ]
            )
        }
    }

    public swapLegSelections(fromLeg: number, toLeg: number) {
        // Grab the betting interest numbers from our currently selected to compare with
        const currentLegSelections = this._wageringUtilService.getSelectedProgramNumbers(this._selectedBettingInterests, 'BettingInterest');

        // Determine the new entries from the leg we are coming from and the leg we are going to
        const newFromLegEntries = this._wageringUtilService.getEntriesForLeg(this._allEntriesUpdate, this.legInfo, fromLeg)
            .filter(entry => currentLegSelections && currentLegSelections[toLeg] && currentLegSelections[toLeg][entry.BettingInterest] && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));
        const newToLegEntries = this._wageringUtilService.getEntriesForLeg(this._allEntriesUpdate, this.legInfo, toLeg)
            .filter(entry => currentLegSelections && currentLegSelections[fromLeg] && currentLegSelections[fromLeg][entry.BettingInterest] && !entry.Scratched)
            .map(entry => this._wageringUtilService.toSelectedEntry(entry));

        if (newFromLegEntries.length || newToLegEntries.length) {
            // Emit our new selections along with removing the old selections for each leg
            // DE15407 -- make sure that the removal of current betting interests happens before selecting the new ones or else
            // if legs have the same number selected it will get wiped out
            this.onSelectionChange.emit([
                { entries: this._selectedBettingInterests[fromLeg], selected: false, leg: fromLeg },
                { entries: this._selectedBettingInterests[toLeg], selected: false, leg: toLeg },
                { entries: newFromLegEntries, selected: true, leg: fromLeg },
                { entries: newToLegEntries, selected: true, leg: toLeg },
            ]);
        }
    }

    public getDisabled(entry: VisibleProgramEntry, leg: number): boolean {
        if (!entry.BettingInterest || entry.Scratched) {
            return true;
        }
        if (this.isKey && leg === 1) {
            return this._entrySelectionService.isBettingInterestSelected(this._selectedBettingInterests, 0, entry.BettingInterest);
        }
        return false;
    }

    public trackByLegCounter(index: number, legCounter: string) {
        return legCounter;
    }

    public trackByProgramNumber(index: number, entry: ProgramEntry) {
        return entry.ProgramNumber;
    }
    public onActivateProgramLeg(legNumber: number) {
        // need to change entries to display for multi race by changing the focused leg number. focused leg number is always 0 for single race
        if (this.legInfo.multiRace) {
            this.focusedLegNumber = legNumber;
            this._changeDetector.detectChanges();
        }
    }

    public getLegEntry(programNumber: string, legNumber: number): ProgramEntry {
        return this._allEntriesUpdate[legNumber] ? this._allEntriesUpdate[legNumber].find((entry) => entry.ProgramNumber === programNumber) : null;
    }

    private _isWagerableRace(raceDetails: ITodaysRace): boolean {
        return TrackService.isWagerableRace(raceDetails ? raceDetails.status : null)
    }

    private _sameBetNav(betNav1: IBetNavObject, betNav2: IBetNavObject) {
        return BetTypeUtil.isSameWagerType(betNav1.type, betNav2.type) && betNav1.modifier === betNav2.modifier;
    }

    private _updateFavRunnerIds() {
        this.favRunnerIds = this._favoriteRunnerService.favoriteIds;
    }

    private _updateFavPersonIds() {
        this.favPersonIds = this._favoritePersonService.favoriteIds;
    }

    public checkFavoriteRunner(entryId: number): boolean {
        return this.showFavorite ? this.favRunnerIds.includes(entryId) : false;
    }

    public checkFavoritePerson(entryId: number, personType: string): boolean {
        return this.showFavorite ? this.favPersonIds.includes(entryId) && (personType === 'trainer' ? this.showTrainerFavorite : this.showJockeyFavorite) : false;
    }
}
