<div class="sidebar-content rewards" [class.is-mobile]="isMobile">
    <div class="sidebar-inner-wrap">

        <!-- this is markup taken from alert-success component -->
        <div class="loading-container alert-success">

            <!--This view contains icon-alert icons and success message-->
            <!--start icon-alert-->
            <div class="icon-alert-wrapper">
                <svg class="icon-alert" xmlns="http://www.w3.org/2000/svg" width="52"
                     height="52" viewBox="0 0 52 52">
                    <circle class="icon-alert-circle" cx="26" cy="26" r="26"></circle>
                    <path class="icon-alert-check" fill="none"
                          d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                </svg>
            </div>

            <!--end icon-alert -->

            <!-- start success message -->
            <div class="alert-success-message-block">
                <h2 class="alert-successHeader">

                    Success!
                </h2>
                <div class="alert-successText">
                    {{message}}
                </div>

                <div class="gbl-button-wrap bottom" *ngIf="isMobile" (click)="closeModal()" [cduxLogClick]="eventClickType.REWARDS_REDEEM_SUCCESS_OKAY_BUTTON">
                    <button id="demo-redeem-btn-footer2" class="gbl-button-ghost light">Okay</button>
                </div>

            </div>
            <!-- end success message -->

        </div>

    </div>

</div>

