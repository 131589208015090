<header class="reg-login-header" *ngIf="!betShare">
  <div class="close-btn" (click)="close()">
    <div class="icon--login-close"></div>
    <div class="close-txt">Close</div>
  </div>
  <div class="secured-message">
    <div class="icon--badge-encryption"></div>
    Secured by 2048-bit encryption
  </div>
</header>

<!-- Betshare wrapper around the full page template -->
<cdux-shell-layout *ngIf="betShare; else notBetShare" [showCloseButton]="betShare" (onCloseButtonClick)="close()" title="BET SHARE" [showButton]="betShare" buttonText="Edit Shares" (onButtonClick)="close()">
  <ng-container *ngTemplateOutlet="fullPageTemplate"></ng-container>
</cdux-shell-layout>

<!-- Full page without betshare wrapper -->
<ng-template #notBetShare>
  <ng-container *ngTemplateOutlet="fullPageTemplate"></ng-container>
</ng-template>

<!-- Full page template -->
<ng-template #fullPageTemplate>
  <section class="funding-container" [class.responsive-toggle-nav]="isSmallGlass && !!selectedFundId">
    <ng-container *ngTemplateOutlet="loading"></ng-container>
    <ng-container *ngTemplateOutlet="successTemplate"></ng-container>
    <ng-container *ngIf="!successfulDeposit">
      <ng-container *ngTemplateOutlet="depositOptionsTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="depositComponentTemplate"></ng-container>
    </ng-container>
  </section>
</ng-template>

<!-- Deposit component container template -->
<ng-template #depositComponentTemplate>
  <div class="funding-content" [class.alt-light-bg]="useLightBackground()" [class.hidden]="isLoading">
    <ng-container *ngTemplateOutlet="disabled"></ng-container>

    <ng-container *ngTemplateOutlet="error"></ng-container>

    <ng-template #depositMethod></ng-template>
  </div>
</ng-template>

<!-- Fudning options template -->
<ng-template #depositOptionsTemplate>
  <nav class="funding-nav" [class.hidden]="isLoading" [class.bet-share-margin]="betShare">
    <i *ngIf="!betShare" class="icon--login-close" (cduxTap)="close()"></i>

    <cdux-usersname-balance *ngIf="fpdEnabled"
      [usersname]="usersname"
      [balance]="balance">
    </cdux-usersname-balance>

    <nav *ngIf="fpwEnabled" class="funding-nav__tabs">
      <ul class="funding-nav__tabs__list">
          <li class="funding-nav__tabs__item"
            [class.is-selected]="selectedOperation === enumFundingOperations.DEPOSIT"
            (cduxTap)="goToFunding('deposit')">Deposit</li>
          <li class="funding-nav__tabs__item"
            [class.is-selected]="selectedOperation === enumFundingOperations.WITHDRAW"
            (cduxTap)="goToFunding('withdraw')">Withdraw</li>
      </ul>
    </nav>
    <h1 *ngIf="!fpwEnabled">Deposit</h1>

    <div *ngIf="balanceError">
      <i class="icon--error-inline"></i><h2 class="text__error-color" id="balance-call-error-txt">{{ balanceError }}</h2>
    </div>

    <ng-container *ngIf="selectedOperation === enumFundingOperations.DEPOSIT; else withdrawOptionsText">
      <div *ngIf="!betShareCost; else showBetShareCost"><p *ngIf="!isSmallGlass">Fund your account by selecting a deposit method.</p></div>
      <ng-template #showBetShareCost>
        <i class="icon--error-inline"></i><h2 class="text__error-color" id="fpd-betshare-min-atleast-txt">Please deposit at least ${{betShareCost}} to join this Bet Share.</h2>
      </ng-template>
    </ng-container>

    <ng-template #withdrawOptionsText>
      <p *ngIf="!isSmallGlass">Select your withdraw method from the options below.</p>
    </ng-template>

    <ul>
      <cdux-funding-deposit-options
        [displayStyle]="enumDepositDisplayStyle.FULL_PAGE"
        [selectedMethodId]="selectedFundId"
        [enabledIDs]="defaultEnabledIds"
        [isSmallGlass]="isSmallGlass"
        (selectedMethod)="selectComponent($event)"
        (noMethod)="displayOptions($event)"
        (methodsLoaded)="methodsLoaded($event)"
        *ngIf="!fpwEnabled; else mergedOptionTemplate">
      </cdux-funding-deposit-options>
    </ul>

    <div *ngIf="isFirstTimeDeposit && selectedOperation === enumFundingOperations.DEPOSIT" class="deposit-later" (click)="depositLater()">Deposit Later</div>
  </nav>
</ng-template>

<!-- Successful deposit template -->
<ng-template #successTemplate>
  <div *ngIf="successfulDeposit && !isLoading" class="content-success">
    <div class="icon--logo-sm-color"></div>
    <div class="icon--login-close" (cduxTap)="close()"></div><!-- only displays in phone sizes -->
    <div class="success-ani">
      <!--// progress animation from https://codepen.io/xgad/post/svg-radial-progress-meters//-->
      <svg class="progress" width="120" height="120" viewBox="0 0 120 120">
        <circle class="base" cx="60" cy="60" r="54" fill="none" />
        <circle class="progress__value" cx="60" cy="60" r="54" fill="none" />
      </svg>
      <svg class="check" width="34" height="26" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.965 1.125c-1.613-1.614-4.394-1.474-6.178.31L11.905 15.317l-4.602-4.602c-1.784-1.784-4.565-1.924-6.178-.31-1.614 1.613-1.474 4.394.31 6.178l7.048 7.048a4.814 4.814 0 003.265 1.43c.042.003.084 0 .126 0 .064 0 .129.003.192 0 1.154-.03 2.34-.509 3.261-1.43L31.655 7.303c1.785-1.784 1.924-4.565.31-6.178" fill-rule="evenodd"/>
      </svg>
    </div>
    <article class="success-message">
      <h2>Success!</h2>
      <ng-container *ngIf="successMessage">
        <p *ngFor='let message of successMessage'>{{message}}</p>
      </ng-container>
      <p *ngIf="balance && selectedOperation === enumFundingOperations.DEPOSIT">Your balance is {{ balance | currency:'USD' }}</p>
      <div class="cdux-button-wrapper call-to-action" *ngIf="selectedOperation === enumFundingOperations.WITHDRAW">
        <button (click)="onDoneClick()" id="done--btn">
          Done
        </button>
      </div>

    </article>
  </div>
</ng-template>

<!-- Loading template -->
<ng-template #loading>
  <div *ngIf="isLoading" class="content-success">
    <div class="icon--logo-sm-color"></div>
    <div class="loading-message">
      {{ loadingMessage }}
    </div>
    <!-- show loading dots here-->
    <div *cduxLoading="this._OVERLAY_NAME; strategy:'replace'; component:loadingComponent;"></div>
  </div>
</ng-template>

<!-- Error template -->
<ng-template #error>
  <ng-container *ngIf="hasError">
    <div class="error-message">
      <div class="error-icon">
        <i class="icon-exclamation-error-large"></i>
      </div>
      <ng-container *ngIf="!showAlternateChatMessage else chatMessage">
        <ng-container  *ngIf="+errorCode !== WRONG_PAYPAL_ACCOUNT_CODE else paypalAccountError">
          <p [innerHTML]="errorMessage" id="full-page-error--txt"></p>
        </ng-container>
        <ng-template #paypalAccountError><cdux-paypal-error [phoneEnabled]="phoneEnabled" [paypalAccount]="paypalAccount"></cdux-paypal-error></ng-template>
      </ng-container>
      <ng-template #chatMessage>
        <p><cdux-chat [errorCode]="errorCode + '-chat'"></cdux-chat></p>
      </ng-template>

      <div *ngIf="selectedFundId === FUND_ID.CREDITCARD && errorCode === CC_ERROR_CODES.EXPIRED" class="cdux-button-wrapper call-to-action">
        <button (click)="addNewCard()" id="add-new-card--btn">
            ADD NEW CARD
        </button>
      </div>

      <div *ngIf="!(selectedFundId === FUND_ID.CREDITCARD && errorCode === CC_ERROR_CODES.EXPIRED) && !hasToteError" (click)="clearErrors()" class="try-again--btn" id="try-again--btn">{{errorActionText}}</div>
    </div>
  </ng-container>
</ng-template>

<!-- Disabled template -->
<ng-template #disabled>
  <ng-container *ngIf="isDisabled">
    <div class="error-message">
      <div class="error-icon">
        <i class="icon-exclamation-error-large"></i>
      </div>
      <p id="disabled-error-msg">{{ depositMethodLabel | cduxTranslate: 'fundtypes' }} activation is temporarily disabled.  Please <span *ngIf="phoneEnabled else disabledMethodChatMessage">contact Player Services at {{ 'customer-service' | cduxTranslate:_environment.affiliateId }}</span>.</p>
    </div>
    <ng-template #disabledMethodChatMessage><cdux-chat></cdux-chat></ng-template>
    <div *ngIf="isSmallGlass" (click)="goToOptions()" class="try-again--btn" id="go-to-options-button">Choose A Different Method</div>
  </ng-container>
</ng-template>

<!-- Combined Funding Tempalte -->
<ng-template #mergedOptionTemplate>
  <cdux-funding-options
    [displayStyle]="enumDepositDisplayStyle.FULL_PAGE"
    [selectedMethodId]="selectedFundId"
    [enabledIDs]="defaultEnabledIds"
    [isSmallGlass]="isSmallGlass"
    [selectedOperation]="selectedOperation"
    (selectedMethod)="selectComponent($event)"
    (noMethod)="displayOptions($event)"
    (methodsLoaded)="methodsLoaded($event)">
  </cdux-funding-options>
</ng-template>
