import { ChangeDetectorRef, Directive, Optional } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    FeatureToggleDataService,
    FUNDING_OPERATIONS,
    JwtSessionService,
    TranslateService,
    UserEventEnum
} from '@cdux/ng-common';

import { HeaderService } from 'app/shared/header/services/header.service';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { FundingService } from '../../shared/services/funding.service';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service'
import { FundingAbstractMethodComponent } from './abstract-method.component';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { Router } from '@angular/router';

@Directive()
export abstract class FundingAbstractMethodDepositComponent extends FundingAbstractMethodComponent {

    public abstract get hasVerifiedAccount(): boolean;

    protected displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR;
    protected methodChosen = false;

    public depositDisclaimer: string;

    constructor(
        public router: Router,
        private _headerService: HeaderService,
        _environment: ENVIRONMENT,
        _fb: UntypedFormBuilder,
        _fundingService: FundingService,
        _sidebarService: SidebarService,
        _eventTrackingService: EventTrackingService,
        _translateService: TranslateService,
        protected _sessionService: JwtSessionService,
        _featureToggleService: FeatureToggleDataService,
        @Optional() _cdr?: ChangeDetectorRef,
    ) {
        super(
            _environment,
            _sidebarService,
            _fb,
            _fundingService,
            _eventTrackingService,
            _translateService,
            _featureToggleService,
            _cdr
        );
        this.operation = FUNDING_OPERATIONS.DEPOSIT;
        this.depositDisclaimer = !!this._translateService.translationExists('affiliate-deposit-disclaimer', this._environment.affiliateId.toString()) ?
            this._translateService.translate('affiliate-deposit-disclaimer', this._environment.affiliateId.toString(), false) : null;
    }

    protected logDepositStartGtmEvent() {
        const gtm_deposit_start_event = {
            'action': 'start',
            'firstTimeDeposit': this._fundingService.isFirstTimeDeposit(),
            'depositMethod': this.operationMethod,
            'failReason': null,
            'status': 'success'
        };
        this._eventTrackingService.logGtmUserEvent(UserEventEnum.DEPOSIT, gtm_deposit_start_event);
    }

    public getInitialDepositAmount(): number {
        const minDeposit = this.getMinDepositAmount(this.hasVerifiedAccount);
        if (this.amount) {
            if (this.amount >= minDeposit) {
                return this.amount;
            } else {
                return minDeposit;
            }
        } else if (this.lastFundingAmount) {
            return this.lastFundingAmount;
        } else if (this._fundingService.isFirstTimeDeposit()) {
            return null;
        } else {
            return minDeposit;
        }
    }

    public getMinDepositAmount(hasAccount: boolean) {
        if (hasAccount) {
            return Math.max(this.fundingMethodDetails.minTransactionAmount, this.minimumAmount || 0);
        } else {
            return Math.max(this.fundingMethodDetails.initialMinDeposit, this.minimumAmount || 0);
        }
    }

    public getMaxDepositAmount() {
        return this.fundingMethodDetails.maxDeposit;
    }

    public navCancelMethod() {
        if (this.displayStyle === enumFundingDisplayStyle.FULL_PAGE) {
            this.methodChosen = false;
        }
    }
    public navigateToOptions() {
        this.router.navigate(['funding', 'deposit', 'options']);
    }

    public close () {
        let redirectUrl = '/';
        if (this._sessionService.redirectLoggedInUserUrl
            && this._sessionService.redirectLoggedInUserUrl.split('/')[1] !== this.router.url.split('/')[1]) {  // Check to ensure the previous location wasn't the deposit page
            redirectUrl = this._sessionService.redirectLoggedInUserUrl;
        } else if (this._fundingService.postDepositRedirectURL) {
            redirectUrl = this._fundingService.postDepositRedirectURL;
        }
        // Tell the header service that this is reverse navigation.
        this._headerService.reverseNavigation = true;

        this.router.navigate(
            redirectUrl.replace(/\?.*/, '').split('/'),
            {
                queryParams: redirectUrl.replace(/[^?]*\??/, '').split('&').reduce((params, kv) => {
                    const [left, right] = kv.split('=');
                    params[left] = right;
                    return params;
                }, {}),
                state: {
                    returningBetId: this.inboundBet
                }
            }
        );
    }
}
