import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';

import { SearchResultItemComponent } from './components/search-result-item/search-result-item.component';
import { SearchResultListComponent } from './components/search-result-list/search-result-list.component';
import { SidebarSearchContainerComponent } from './components/sidebar-search/sidebar-search.component';
import { StandaloneSearchComponent } from './components/standalone-search/standalone-search.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedFragmentsModule
    ],
    declarations: [
        SearchResultItemComponent,
        SearchResultListComponent,
        SidebarSearchContainerComponent,
        StandaloneSearchComponent
    ],
    exports: [
        SearchResultItemComponent,
        SearchResultListComponent
    ]
})
export class ProgramSearchModule {
    public static forRoot(): ModuleWithProviders<ProgramSearchModule> {
        return { ngModule: ProgramSearchModule }
    }
}
