import { ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { IRaceIdentifier } from '@cdux/ng-common';

/**
 * Interface for the configuration of the mobile header
 * all properties are optional and no set by default
 * the header service will set the defaults and maintain the config
 */
export interface IMobileHeaderConfig {
    dynamicRef?: MobileHeaderConfigDynamicRef,
    dynamicRefSub?: MobileHeaderConfigDynamicRef,
    showVideoButton?: boolean,
    staticContent?: string,
    staticRaceData?: IRaceIdentifier
}

export class MobileHeaderConfigDynamicRef {

    constructor(
        public componentOrTemplateRef: ComponentType<any> | TemplatePortal<any>,
        public inputs?: { [key: string]: any }
    ) {
        // Empty
    }

}
