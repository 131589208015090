import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'cdux-shell-layout',
    templateUrl: './layout-shell.component.html',
    styleUrls: [ './layout-shell.component.scss' ]
})
export class LayoutShellComponent {

    @Input() public showCloseButton: boolean = true;
    @Input() public title: string;
    @Input() public showButton: boolean = true;
    @Input() public buttonText: string;
    @Output() public onCloseButtonClick: EventEmitter<void> = new EventEmitter();
    @Output() public onButtonClick: EventEmitter<void> = new EventEmitter();

}
