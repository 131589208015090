import { ComponentFactory, ComponentFactoryResolver, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import {
    DetectionService,
    enumTrackType,
    IVideoStream,
    IVideoStreamResponse,
    VideoDataService,
    VideoProviderName,
    VideoProviderType,
} from '@cdux/ng-common';

import { IVideoFeed, VideoFeedError } from './video-feed.interface';
import { NeulionVideoFeedComponent } from './neulion/neulion-video-feed.component';
import { RCNFlashVideoFeedComponent } from './rcn/rcn-flash-video-feed.component';
import { RCNHL2SVideoFeedComponent } from './rcn/rcn-hl2s-video-feed.component';
import { RCNHTMLReplayFeedComponent } from './rcn/rcn-html-replay-feed.component';
import { RCNHTMLVideoFeedComponent } from './rcn/rcn-html-video-feed.component';
import { ReplayProviderService, VideoProviderService } from './video-provider.service';
import { RCNHTTPReplayFeedComponent } from './rcn/rcn-http-replay-feed.component';

@Injectable()
export class LiveVideoFeedFactory {
    /**
     * map of supported technologies (VideoProviderName) by platform (VideoProviderType)
     *
     * Values are declared in the constructor, because TypeScript won't let
     * us use enums in object-literal colon form.
     */
    private static SUPPORTED_PROVIDERS: {[platform: string]: VideoProviderName[]} = {};

    private _providerIndex: number;
    private _providerCount: number;
    private currentGetVideoFeedComponentArgs: any;
    private currentVideoStreamData: IVideoStreamResponse;
    private videoFeedSubject = new Subject<ComponentFactory<IVideoFeed> | VideoFeedError>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private detectionService: DetectionService,
        private videoDataService: VideoDataService,
        private videoProviderService: VideoProviderService,
        private replayProviderService: ReplayProviderService,
    ) {
        this.initializeStoreValues();

        // define supported platforms
        // TODO: move this to a configuration file
        LiveVideoFeedFactory.SUPPORTED_PROVIDERS[VideoProviderType.FLASH] = [
            VideoProviderName.HL2S,
            VideoProviderName.NEULION,
            VideoProviderName.RCN,
        ];
        LiveVideoFeedFactory.SUPPORTED_PROVIDERS[VideoProviderType.ANDROID] = [
            VideoProviderName.HL2S,
            VideoProviderName.NEULION,
            VideoProviderName.RCN,
        ];
        LiveVideoFeedFactory.SUPPORTED_PROVIDERS[VideoProviderType.IPHONE] = [
            VideoProviderName.HL2S,
            VideoProviderName.NEULION,
            VideoProviderName.RCN,
        ];
    }

    /**
     * Public exposure of the current provider index
     *
     * @readonly
     * @memberof LiveVideoFeedFactory
     */
    public get providerIndex() {
        return this._providerIndex;
    }

    /**
     * Public exposure of the number of providers for the current track schedule
     *
     * @readonly
     * @memberof LiveVideoFeedFactory
     */
    public get providerCount() {
        return this._providerCount;
    }

    /**
     * Sets the store items to their initial values
     *
     * @private
     * @memberof LiveVideoFeedFactory
     */
    private initializeStoreValues() {
        this._providerIndex = 0;
        this._providerCount = 0;
        this.currentGetVideoFeedComponentArgs = {
            trackCode: null,
            trackType: null,
            raceNum: null,
        };
        this.currentVideoStreamData = undefined;
    }

    /**
     * Determines which provider type should be requested based on the device type
     */
    private detectProviderPlatform(): VideoProviderType {
        if (this.detectionService.isMobileDevice()) {
            if (this.detectionService.isAppleDevice()) {
                return VideoProviderType.IPHONE;
            } else {
                return VideoProviderType.ANDROID;
            }
        } else {
            return VideoProviderType.FLASH;
        }

        console.warn('Unable to determine provider platform.', DetectionService.getData());
        return null;
    }

    /**
     * checks whether the current track's stream data has video providers
     *
     * @private
     * @returns boolean
     * @memberof LiveVideoFeedFactory
     */
    private currentVideoStreamHasProviders() {
        return this.currentVideoStreamData && this.currentVideoStreamData.hasStreams === true && this.currentVideoStreamData.streams && this.currentVideoStreamData.streams.length > 0;
    }

    /**
     * indicates whether the given track matches the one for which we have stream data
     *
     * @param trackCode
     * @param trackType
     */
    private isCurrentTrack(trackCode: string, trackType: enumTrackType): boolean {
        return this.currentVideoStreamData &&
               this.currentVideoStreamData.brisCode &&
               this.currentVideoStreamData.trackType &&
               this.currentVideoStreamData.brisCode.toUpperCase() === trackCode.toUpperCase() &&
               this.currentVideoStreamData.trackType === trackType;
    }

    /**
     * determines type of player for current stream
     *
     * This method is called after VideoProviderService has
     * been reset and updated to a new stream. That means all
     * downstream instantiations of the *VideoFeedComponent
     * will have access to the current stream data.
     * (current as of 2019-02-06)
     *
     * While it makes sense to put this in the VideoProviderService,
     * doing will introduce a circular dependency for any *FeedComponent
     * listed that must also use VideoProviderService.
     */
    private getLivePlayerType (): IVideoFeed {
        /*
         * We can't forgo this otherwise-unnecessary variable. It somehow
         * coerces TypeScript into (rightfully) accepting the *VideoFeedComponent
         * as an IVideoFeed. Without it, we get an TS2739 error.
         */
        let componentType;

        switch (this.videoProviderService.providerName) {
            case VideoProviderName.RCN:
                if (this.videoProviderService.providerType === VideoProviderType.FLASH) {
                    componentType = RCNFlashVideoFeedComponent;
                } else {
                    componentType = RCNHTMLVideoFeedComponent;
                }
                break;

            case VideoProviderName.HL2S:
                componentType = RCNHL2SVideoFeedComponent;
                break;

            default:
                componentType = NeulionVideoFeedComponent;
                break;
        }

        return componentType;
    }

    /**
     * returns stream provider data at providerIndex for the given track
     *
     * @param trackCode
     * @param trackType
     */
    private getCurrentProvider(trackCode: string, trackType: enumTrackType): Promise<IVideoStream> {
        return new Promise<IVideoStream>((resolve, reject) => {
            /*
             * if we're still working with the current track, there's no need
             * to re-request the track's video schedule data
             */
            if (this.isCurrentTrack(trackCode, trackType)) {
                if (this.currentVideoStreamHasProviders()) {
                    resolve(this.currentVideoStreamData.streams[this.providerIndex]);
                } else {
                    reject();
                }
            } else {
                const providerPlatform = this.detectProviderPlatform();

                // new track, go get its stream data
                this.videoDataService.getLiveStreams(
                    trackCode,
                    trackType,
                    providerPlatform,
                    LiveVideoFeedFactory.SUPPORTED_PROVIDERS[providerPlatform]
                ).then(
                    (streamData: IVideoStreamResponse) => {
                        this.currentVideoStreamData = streamData;
                        if (this.currentVideoStreamHasProviders()) {
                            this._providerCount = this.currentVideoStreamData.streams.length;
                        }

                        if (this.currentVideoStreamHasProviders()) {
                            resolve(this.currentVideoStreamData.streams[this.providerIndex]);
                        } else {
                            reject();
                        }
                    }
                );
            }
        });
    }

    /**
     * Determines the player type for replay feeds
     */
    private getReplayPlayerType(): IVideoFeed {
        let componentType;

        if (this.replayProviderService.providerType === VideoProviderType.FLASH) {
            componentType = RCNHTTPReplayFeedComponent;
        } else {
            componentType = RCNHTMLReplayFeedComponent;
        }

        return componentType;
    }

    /**
     * Determines whether the user's device/browser can play Flash video
     */
    private isFlashAvailable(): boolean {
        // IE 11 (and maybe older IEs?) throws an exception if the namedItem is not found rather than returning null
        try {
            return window.navigator.plugins.namedItem('Shockwave Flash') !== null;
        } catch (e) {
            return false;
        }
    }

    /**
     * attempts failover, letting failure bubble up
     *
     * @private
     * @param {VideoFeedError} type
     * @memberof LiveVideoFeedFactory
     */
    private handleVideoFeedError(type: VideoFeedError) {
        if (!this.failover()) {
            this.videoFeedSubject.next(type);
        }
    }

    /**
     * Puts all the data in place for playing a replay video
     */
    private buildReplayVideoFeedComponent(trackCode: string, trackType: enumTrackType, raceNum: number, raceDate: string) {
        const providerType = this.detectProviderPlatform();

        // this sets up provider data for a shareable service that the various video feed components read their data from
        this.replayProviderService.reset();
        this.replayProviderService.trackCode = trackCode;
        this.replayProviderService.trackType = trackType;
        this.replayProviderService.raceNum = raceNum;
        this.replayProviderService.raceDate = raceDate;
        this.replayProviderService.providerType = providerType;
        this.replayProviderService.providerName = ReplayProviderService.setProviderNameFromVideoProviderType(providerType);
        this.replayProviderService.options.format = ReplayProviderService.setFormatFromVideoProviderType(providerType);

        const playerType = this.getReplayPlayerType();
        this.getComponentFactory(playerType).then(
            (componentFactory) => {
                this.videoFeedSubject.next(componentFactory);
            },
            (error: VideoFeedError) => { this.handleVideoFeedError(error); }
        );
    }

    /**
     * Puts all the data in place for playing a live stream
     */
    private buildLiveVideoFeedComponent(trackCode: string, trackType: enumTrackType) {
        this.getCurrentProvider(trackCode, trackType).then( // sets this.currentVideoStreamData
            (currentProvider: IVideoStream) => {
                // this sets up currentProvider data for a shareable service that the various video feed components read their data from
                this.videoProviderService.reset();
                this.videoProviderService.streamData    = this.currentVideoStreamData;
                this.videoProviderService.currentStream = currentProvider;

                this.getComponentFactory(this.getLivePlayerType()).then(
                    (componentFactory) => {
                        this.videoFeedSubject.next(componentFactory);
                    },
                    (error: VideoFeedError) => { this.handleVideoFeedError(error); }
                );
            },
            () => { this.handleVideoFeedError(VideoFeedError.VIDEO_UNAVAILABLE); }
        )
    }

    /**
     * Given a feed type, this function determines if we can show it and
     * performs some Angular work in preparing the component for the page
     */
    private getComponentFactory(component): Promise<ComponentFactory<IVideoFeed>> {
        return new Promise<ComponentFactory<IVideoFeed>>((resolve, reject) => {
            if (!component) {
                reject(VideoFeedError.VIDEO_UNAVAILABLE);
            } else {
                // error if we need Flash but don't have it
                if (component.REQUIRES_FLASH && !this.isFlashAvailable()) {
                    reject(VideoFeedError.FLASH_UNAVAILABLE);
                }

                resolve(this.componentFactoryResolver.resolveComponentFactory(component));
            }
        });
    }

    /**
     * Kickstarts the building of the appropriate type of video feed
     */
    private buildVideoFeedComponent(trackCode: string, trackType: enumTrackType, raceNum?: number, raceDate?: string) {
        if (raceNum && raceDate) {
            this.buildReplayVideoFeedComponent(trackCode, trackType, raceNum, raceDate);
        } else {
            this.buildLiveVideoFeedComponent(trackCode, trackType);
        }
    }

    /**
     * Begins the process of putting together the feed component for displaying on the page
     *
     * @param trackCode
     * @param trackType
     * @param raceNum
     * @returns Observable<ComponentFactory<IVideoFeed>> An observable stream that will get the component to render; will update when attempting the fail-over
     */
    public getVideoFeedComponent(trackCode: string, trackType: enumTrackType, raceNum?: number, raceDate?: string): Observable<ComponentFactory<IVideoFeed> | VideoFeedError> {
        this.initializeStoreValues();

        // save the data this function was called with so that we can replay these args during failover
        this.currentGetVideoFeedComponentArgs = {
            trackCode: trackCode,
            trackType: trackType,
            raceNum: raceNum,
            raceDate: raceDate
        };

        this.buildVideoFeedComponent(trackCode, trackType, raceNum, raceDate);

        return this.videoFeedSubject.asObservable();
    }

    /**
     * Attempts to retrieve a stream for the next available provider
     *
     * @returns boolean
     */
    public failover() {
        if (this.hasMoreProviders()) {
            this._providerIndex++;
            this.buildVideoFeedComponent(this.currentGetVideoFeedComponentArgs.trackCode, this.currentGetVideoFeedComponentArgs.trackType, this.currentGetVideoFeedComponentArgs.raceNum, this.currentGetVideoFeedComponentArgs.raceDate);

            return true;
        } else {
            return false;
        }
    }

    /**
     * Determines whether the current provider is the final provider
     *
     * @returns boolean
     * @memberof LiveVideoFeedFactory
     */
    public hasMoreProviders() {
        return this.providerIndex < this.providerCount - 1;
    }
}
