import { CurrencyPipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import {
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
import {
    EventClickType,
    FeatureToggleDataService,
    JwtSessionService,
    TranslateService
} from '@cdux/ng-common';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractEzmoneyMethodDepositComponent } from './abstract-ezmoney-method-deposit.component';
import { FundingService } from '../../../shared/services/funding.service';
import { FundingEzMoneyMethodHeaderComponent } from './ezmoney-method-header.component';
import { FundingEzmoneyService } from '../../../shared/services/ezmoney.service';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { HeaderService } from 'app/shared/header/services/header.service';

@Component({
    selector: 'cdux-funding-ezmoney-method-deposit',
    templateUrl: './ezmoney-method-deposit.component.html',
    styleUrls: ['./ezmoney-method-deposit.component.scss']
})
export class FundingEzmoneyMethodDepositComponent extends AbstractEzmoneyMethodDepositComponent {

    public eventClickType = EventClickType;

    public successMessage = '';

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodDepositComponent, options);
    }

    public static getHeaderComponent(accountNumber: string = null): ISidebarPortalComponent {
        return FundingEzMoneyMethodHeaderComponent.getSidebarComponent(accountNumber);
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        localRouter: Router,
        localEnvironment: ENVIRONMENT,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localEventTrackingService: EventTrackingService,
        localCdr: ChangeDetectorRef,
        localEzMoneyService: FundingEzmoneyService,
        localCurrencyPipe: CurrencyPipe,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            localRouter,
            localEventTrackingService,
            localCdr,
            localEzMoneyService,
            localCurrencyPipe,
            localTranslateService,
            localEnvironment,
            localFb,
            localFundingService,
            localSidebarService,
            localSessionService,
            localHeaderService,
            localToggleService
        );
    }

}
