import { Component, ViewEncapsulation } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';
import { Menu } from '@cdux/ng-common';

@Component({
    selector: 'cdux-menu-responsive',
    templateUrl: './menu-responsive.component.html',
    styleUrls: ['./menu-responsive.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuResponsiveComponent extends MenuComponent {

    public responsiveSubmenuToggle(event: Event, menuItem: Menu) {
        if (menuItem.submenu.length === 0) {
            // navigate if there aren't submenu items
            this.menuItemClick(event, menuItem);
        } else if (menuItem.submenuOpen) {
            menuItem.submenuOpen = false;
        } else {
            this.closeAllSubmenus();
            menuItem.submenuOpen = true;
        }
    }

}
