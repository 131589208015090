import { Injectable, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';

import { combineLatest, Observable, Subscription, BehaviorSubject } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

import {
    enumTrackType,
    AnglesPick,
    AnglesRace,
    IOddsMtpPost,
    IRaceIdentifier,
    ToteDataService,
    ProgramEntry,
    ISelectedEntry,
    TracksDataService,
    ITrackBasic,
    RaceConstants,
} from '@cdux/ng-common';

import { enumTop3Category } from 'app/wagering/shared/angles/top-3-button/top-3-category.enum';
import { TopPicks } from '../interfaces/top-picks.interface';
import { NoncurrentRacesConfigService } from './noncurrent-races-config.service';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { AnglesRequestHandler } from 'app/shared/betpad/classes/angles-request-handler.class';

@Injectable({
    providedIn: 'root'
})
export class AnglesBusinessService implements OnDestroy {

    private emptyPicks = {topPicks: '', betNumbers: []};

    private _classPicksSub: BehaviorSubject<TopPicks> = new BehaviorSubject(this.emptyPicks);
    private _pacePicksSub:  BehaviorSubject<TopPicks> = new BehaviorSubject(this.emptyPicks);
    private _topPicksSub:   BehaviorSubject<TopPicks> = new BehaviorSubject(this.emptyPicks);
    private _speedPicksSub: BehaviorSubject<TopPicks> = new BehaviorSubject(this.emptyPicks);

    private _anglesSubscription: Subscription;
    private _anglesRequestHandler: AnglesRequestHandler;

    private _selectedBrisCode: string;
    private _selectedRaceNumber: number;
    private _selectedRaceDate: string;

    constructor(
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService,
        private _tracksDataService: TracksDataService,
        protected noncurrentRacesConfigService: NoncurrentRacesConfigService,
    ) {
        this._anglesRequestHandler = new AnglesRequestHandler(this._toteDataService, this._tracksDataService);
    }

    ngOnDestroy() {
        if (this._anglesSubscription) {
            this._anglesSubscription.unsubscribe();
        }
    }

    public getTopPicks(category: enumTop3Category, track: string, trackType: enumTrackType, race: number, raceDate?: string): Observable<TopPicks> {
        if (typeof raceDate === 'undefined') {
            raceDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
        }

        if (!this._isSelectedTrackRaceDate(track, race, raceDate) || !this._anglesSubscription) {
            this._setupAnglesSubscription(track, trackType, race, raceDate);
        }

        switch (category) {
            case enumTop3Category.CLASS:
                return this._classPicksSub.asObservable();
            case enumTop3Category.PACE:
                return this._pacePicksSub.asObservable();
            case enumTop3Category.PICKS:
                return this._topPicksSub.asObservable();
            case enumTop3Category.SPEED:
                return this._speedPicksSub.asObservable();
            default:
                return null;
        }
    }

    private _setupAnglesSubscription(track: string, trackType: enumTrackType, race: number, raceDate: string) {
        if (this._anglesSubscription) {
            this._anglesSubscription.unsubscribe();
        }

        this._selectedBrisCode   = track;
        this._selectedRaceNumber = race;
        this._selectedRaceDate   = raceDate;

        // only poll odds for current race or exceptions
        this._anglesSubscription = this.noncurrentRacesConfigService.shouldPollRace({
            'brisCode':   track,
            'trackType':  trackType,
            'raceNumber': race
        } as IRaceIdentifier).pipe(
            first(),
            switchMap((shouldPoll) => {
                return combineLatest([
                    this._anglesRequestHandler.listen(),
                    this._todaysRacesService.getTodaysRaceEntries(track, trackType, race, true),
                    this._toteDataService.oddsMtpPost(track, trackType, race.toString(), shouldPoll)
                ]).pipe(
                    filter(([anglesData, programEntries, oddsMtpPost]: [AnglesRace, ProgramEntry[], IOddsMtpPost], index) => {
                        // TODO - should this filter still happen?
                        // Only emit when all observables provide data for our currently selected race.
                        // scratches.Race is a number in the interface.
                        // However, it's getting sent back as a string from the webservice so do some nonsense to actually make it a number
                        // Also, this is weird, but to preserve the error handling in the subscribe when there is a malformed angles
                        // response, we only want to fail on the anglesData race check if there is a race number where we expect
                        // it and it does not match our currently selected race.
                        return (
                            (!(anglesData && anglesData.hasOwnProperty('raceNumber') && anglesData.raceNumber !== this._selectedRaceNumber))
                            // && parseInt(scratches.Race.toString(), 10) === this._selectedRaceNumber
                            && parseInt(oddsMtpPost.MtpInfo.RaceNum, 10) === this._selectedRaceNumber
                        );
                    })
                )
            }))
            .subscribe(
                ([anglesData, entries, oddsMtpPost]: [AnglesRace, ProgramEntry[], IOddsMtpPost]) => {
                    if (this._isValidAnglesResponse(race, anglesData)) {
                        // Gather up our scratches and flatten it to any array of program entry numbers that have scratched.
                        let scratchedEntries: string[] = [];
                        if (Array.isArray(entries)) {
                            scratchedEntries = entries
                                .filter(entry => entry.Scratched)
                                .map(entry => entry.ProgramNumber);
                        }

                        // Double-check for scratches in the odds
                        if (oddsMtpPost && oddsMtpPost.WinOdds && oddsMtpPost.WinOdds.Entries.length) {
                            const oddsScratchedEntries = oddsMtpPost.WinOdds.Entries.filter(odds => odds.TextOdds.toUpperCase() === RaceConstants.TEXT_ODDS_SCRATCH);

                            // add to scratchedEntries if not already in list
                            oddsScratchedEntries.forEach((value) => {
                                // can't use includes() because of IE
                                if (scratchedEntries.indexOf(value.ProgramNumber) === -1) {
                                    scratchedEntries.push(value.ProgramNumber);
                                }
                            });
                        }

                        this._classPicksSub.next(this._generateTopPicks(anglesData.topClassPicks, scratchedEntries));
                        this._pacePicksSub .next(this._generateTopPicks(anglesData.topPacePicks,  scratchedEntries));
                        this._topPicksSub  .next(this._generateTopPicks(anglesData.topThreePicks, scratchedEntries));
                        this._speedPicksSub.next(this._generateTopPicks(anglesData.topSpeedPicks, scratchedEntries));
                    } else {
                        this._classPicksSub.next(this.emptyPicks);
                        this._pacePicksSub.next(this.emptyPicks);
                        this._topPicksSub.next(this.emptyPicks);
                        this._speedPicksSub.next(this.emptyPicks);
                    }
                },
                (error) => {
                    throw new Error(error);
                }
            );

        this._anglesRequestHandler.updateRaceNavigation({ BrisCode: track, TrackType: trackType, RaceNum: race } as ITrackBasic);
    }

    private _isValidAnglesResponse(requestedRace: number, anglesData: AnglesRace): boolean {
        return anglesData && anglesData.raceNumber &&
            requestedRace === anglesData.raceNumber &&
            Array.isArray(anglesData.topClassPicks) &&
            Array.isArray(anglesData.topPacePicks) &&
            Array.isArray(anglesData.topSpeedPicks) &&
            Array.isArray(anglesData.topThreePicks);
    }

    private _generateTopPicks(anglesPicks: AnglesPick[], scratches: string[]) {
        const bets: number[] = [];

        let top3String = '';
        // TODO -- Do we care if we don't have three horses?
        let count = 0;
        for (const pick of anglesPicks) {
            pick.programNumber = pick.programNumber.trim();
            // If the horse hasn't scratched and the horse is not a coupled entry of a horse we have already added
            if (scratches.indexOf(pick.programNumber) === -1 && bets.indexOf(pick.betProgramNumber) === -1) {
                top3String += pick.programNumber;
                bets.push(pick.betProgramNumber);
                count++;
                if (count < 3) {
                    top3String += '-';
                } else {
                    break;
                }
            }
        }

        // Not the most efficient to loop through them again but after figuring out the top 3 picks find all entries with the same betting interest number
        const bettingInterests: ISelectedEntry[] = anglesPicks.filter(pick => bets.includes(pick.betProgramNumber)).map(pick => ({BettingInterest: pick.betProgramNumber, ProgramNumber: pick.programNumber}));

        return {topPicks: top3String, betNumbers: bets, bettingInterests: bettingInterests} as TopPicks;
    }

    private _isSelectedTrackRaceDate(brisCode: string, raceNumber: number, raceDate: string): boolean {
        return (this._selectedBrisCode === brisCode && this._selectedRaceNumber === raceNumber && this._selectedRaceDate === raceDate);
    }
}
