<div class="bet-share-deposit">
    <div class="bsd-message" *ngIf="canGoBack">
        Please deposit at least {{ amountNeeded ? (amountNeeded | formatBetAmount) : '' }} to join this Bet&nbsp;Share
    </div>
    <header class="bsd-header">
        <div class="bsd-header_back-button" *ngIf="canGoBack" (click)="backButtonHandler()">
            <div  class="icon--arrow-left--blue"></div>
        </div>
        <div class="bsd-header_title">
            <ng-template #header="cdkPortalHost" cdkPortalHost></ng-template>
        </div>
    </header>

    <ng-template #body="cdkPortalHost" cdkPortalHost></ng-template>
</div>
