import { AnimationBuilder, AnimationPlayer, style, animate } from '@angular/animations';
import { ViewChild, Component, Input } from '@angular/core';

@Component({
    selector: 'cdux-ranking-bar',
    styleUrls: ['./ranking-bar.component.scss'],
    template: `
        <div class="percentage-bar-bkg">
            <div class="percentage-bar-fill" [class.top-3]="isHighlighted" #rankingBar></div>
        </div>
    `
})
export class RankingBarComponent {
    @ViewChild('rankingBar', { static: true })
    public rankingBar;

    public fillAnimation: AnimationPlayer;

    @Input() public isHighlighted = false;

    private _percentage = 0;
    @Input()
    public set percentage(percent: number) {
        this._percentage = percent;

        if (this.fillAnimation) {
            this.fillAnimation.destroy();
        }

        const factory = this._builder.build([
            style({ width: '0%' }),
            animate('1500ms ease-out', style({ width: (percent) + '%' }))
        ]);

        this.fillAnimation = factory.create(this.rankingBar.nativeElement, {});
        this.fillAnimation.play();
    }
    public get percentage() {
        return this._percentage;
    }

    constructor(private _builder: AnimationBuilder) { }

}
