import { CduxStorage } from '@cdux/ng-common';

import { TodaysRaceFilters } from '../interfaces/todays-race-filters.interface';

export class TodaysRacesFilterInfo  implements CduxStorage.DynamicStoreable {
    public static readonly DB = 'todaysRacesFilterInfo';
    public static readonly VERSION = 1;
    public static readonly KEY = 'todaysRacesFilterInfo';

    public filters: TodaysRaceFilters;

    constructor(filters: TodaysRaceFilters) {
        this.filters = filters;
    }

    /**
     * Tells the data store how to store this model.
     *
     * @returns {CduxStorage.StorageQuery}
     */
    public generateStoreableMetadata(): CduxStorage.StorageQuery {
        return {
            db: TodaysRacesFilterInfo.DB,
            _id: TodaysRacesFilterInfo.KEY,
            data: this,
            version: TodaysRacesFilterInfo.VERSION
        };
    }
}
