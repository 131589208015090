import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import {
    Bet,
    BetCalculatorBusinessService,
    ConfigurationDataService,
    enumConfigurationStacks,
    IBetShare,
    TranslateService,
    JwtSessionService,
    WindowRefService,
} from '@cdux/ng-common';

import { IWager, IBetShareConfig } from '@cdux/ng-fragments';

import { IBetShareTotals } from '../interfaces/bet-share-totals';
import { SocialMediaService } from '../../social/services/social-media.service';
import { SocialMediaTypes } from '../../social/enums/social-media-types.enum';

@Injectable({providedIn: 'root'})
export class BetShareBusinessService {
    private static readonly BETSHARE_CONFIG = {
        keys: ['betshare_blocked_states', 'betshare_min_shares', 'betshare_max_shares', 'betshare_config_timeout', 'share_your_bet', 'betshare_mtp']
    };

    private _betShareConfig: IBetShareConfig;
    private _configTimeout: number;
    private readonly _minShareValue: number = 0.01;

    constructor(private _configService: ConfigurationDataService,
                private _sessionService: JwtSessionService,
                private _translateService: TranslateService,
                private _socialMediaService: SocialMediaService,
                private _windowService: WindowRefService,
                private _location: Location,
                private _betCalculatorService: BetCalculatorBusinessService,
    ) {
        // Empty
    }

    public getBetShareConfig(): Observable<IBetShareConfig> {
        const time = new Date().getTime();
        if (!this._betShareConfig || time > this._configTimeout) {
            return this._configService.getConfiguration(
                enumConfigurationStacks.TUX,
                BetShareBusinessService.BETSHARE_CONFIG.keys
            ).pipe(
                map(config => {
                    if (!!config) {
                        this._betShareConfig = {
                            restrictedStates: !!config['betshare_blocked_states'] ? config['betshare_blocked_states'].split(',') : null,
                            minShare: !!config['betshare_min_shares'] ? +config['betshare_min_shares'] : 0,
                            maxShare: !!config['betshare_max_shares'] ? +config['betshare_max_shares'] : 0,
                            // betshare_config_timeout stored in seconds
                            timeout:  !!config['betshare_config_timeout']  ? +config['betshare_config_timeout'] * 1000 : 0,
                            redirectUrl:  !!config['share_your_bet'] ? config['share_your_bet'] : this._translateService.translate('share-your-bet-default', 'bet-share', false, window.location.origin),
                            minutesToPostRestriction: !!config['betshare_mtp'] ? +config['betshare_mtp'] : 0
                        };
                        this._configTimeout = time + this._betShareConfig.timeout;
                    }
                    return this._betShareConfig;
                }),
                catchError(err => {
                    return of(!!this._betShareConfig ? this._betShareConfig : null);
                })
            );
        }
        return of(this._betShareConfig);
    }

    public getDefaultBetShare(): IBetShare {
        if (!!this._betShareConfig) {
            return {
                totalShares: this._betShareConfig.minShare,
                reserveShares: 1,
            }
        }
        return null;
    }

    /**
     * A destructive function to populate some needed fields for betShare wager.
     * Note. after the call, original bet record can be modified for some fields.
     * @param bet
     */
    public initializeBetShare(bet: Bet): void {
        if (this._betShareConfig) {
            bet.betShare = true;
            bet.betShareId = bet.id;
            bet.shares = this.getDefaultBetShare();
            bet.cost = this._betCalculatorService.calculate(bet).toString();
        }
    }

    public resetBetShare(bet: Bet): void {
        bet.betShare = false;
        bet.cost = null;
        bet.shares = null;
    }

    public validateTotalShares(bet: Bet, shares: number, betTotal: number): boolean {
        const shareValue: number = Math.trunc((betTotal / shares) * 100) / 100; // finding the share value by truncating the all the decimals after two decimal places.
        if (bet.betShare && !!bet.shares && !!this._betShareConfig && shareValue >= this._minShareValue) {
            return shares >= this._betShareConfig.minShare && shares <= this._betShareConfig.maxShare
                && shares >= bet.shares.reserveShares;
        }
        return false;
    }

    public validateReserveShares(bet: Bet, shares: number): boolean {
        if (bet.betShare && !!bet.shares) {
            return shares >= 1 && shares <= bet.shares.totalShares;
        }
        return false;
    }

    public calculateBetShareTotals(totalShares: number, reserveShares: number, betTotal: number): IBetShareTotals {
        const shareValue: number = this._getValuePerShare(betTotal, totalShares); // finding the share value by truncating the all the decimals after two decimal places.
        const adjustmentTotal: number = betTotal -  totalShares * shareValue;       // finding the adjustment amount as we truncate share value to add the value to captain
        const availableShares: number = totalShares - reserveShares;     // finding the available shares
        const reservedTotal: string = this.formatBetAmount((reserveShares * shareValue + adjustmentTotal).toFixed(2));
        const availableTotal: string = this.formatBetAmount((availableShares * shareValue).toFixed(2));
        const shareValueFormated = this.formatBetAmount(shareValue.toFixed(2));
        const betShareTotals: IBetShareTotals = {
            totalShareValue: totalShares + ' x ' + shareValueFormated + ' = ',
            availableValue: availableShares + ' x ' + shareValueFormated + ' = ' + availableTotal,
            reservedValue: reserveShares + ' x ' + shareValueFormated + ' = ' + reservedTotal,
        };
        return betShareTotals;
    }

    private _getValuePerShare(total: number, totalShares: number): number {
        return Math.trunc((total / totalShares) * 100) / 100;
    }

    private formatBetAmount(amount: string | number): string {
        amount = amount.toString();

        const response = amount
            // Get rid of any additional trailing zeroes.
            .replace(/(\.\d{2})(\d+?)$/, '$1')
            // Add a zero if there's only one decimal place.
            .replace(/(\.\d)$/, '$10')
            // If it's an even dollar amount, don't show the decimal.
            .replace('.00', '');

        return '$' + (response.length ? response : '0');
    }

    public shareBet(bet: Bet| IWager, type: SocialMediaTypes): void {
        if (this._sessionService.isLoggedIn()) {
            switch (type) {
                case SocialMediaTypes.EMAIL:
                    this._socialMediaService.sendEmail(this._translateService.translate( 'subject', 'bet-share'), this._generateBetShareMessage(bet, type));
                    break;
                case SocialMediaTypes.TWITTER:
                    this._socialMediaService.sendTweet(this._generateBetShareMessage(bet, type), this.generateRedirectLink(bet));
                    break;
                case SocialMediaTypes.SMS:
                     this._socialMediaService.sendSMS(this._generateBetShareMessage(bet, type));
                    break;
                case SocialMediaTypes.FACEBOOK:
                    this._socialMediaService.sendFacebookPost(this.generateRedirectLink(bet));
                    break;
            }
        }
    }

    private _generateBetShareMessage(bet: Bet | IWager, type: SocialMediaTypes): string {
           const userInfo = this._sessionService.getUserInfo();
           const fullName = userInfo.firstName + ' ' + userInfo.lastName.charAt(0) + '.';
           const betCost = bet instanceof Bet ? bet.cost : bet.wagerAmount;
           const betShares = bet instanceof Bet ? bet.shares.totalShares : bet.betShareData.betShareNumberOfShares;
           const displayName = bet instanceof Bet ? bet.track.DisplayName : bet.trackName;
           const isCaptain = bet instanceof Bet ? true : bet.betShareData.betShareCaptain;
           const formattedBetCost = this.formatBetAmount(betCost);
           const shareValue = this.formatBetAmount(this._getValuePerShare(+betCost, +betShares).toFixed(2));

           let message, key;
           switch (type) {
               case SocialMediaTypes.TWITTER:
                   key = isCaptain ? 'twitter-message-captain' : 'twitter-message-participant';
                   message = this._translateService.translate(key, 'bet-share', false, formattedBetCost, displayName, shareValue);
                   break;
               default:
                   key = isCaptain ? 'message-captain' : 'message-participant';
                   message = this._translateService.translate(key, 'bet-share', false,
                       fullName,  userInfo.firstName, formattedBetCost, displayName, shareValue) + this.generateLink(bet);
           }
           return message;
    }

    public generateLink(bet: Bet | IWager): string {
        // reference origin to set correct environment across dev through prod
        const betShareId = bet instanceof Bet ? bet.betShareId : bet.betShareData.betShareId;
        const url = this._location.prepareExternalUrl(this._translateService.translate( 'url-segment', 'bet-share') + betShareId);
        return this._windowService.nativeWindow.location.origin + url;
    }

    public generateRedirectLink(bet: Bet | IWager): string {
        const betShareId = bet instanceof Bet ? bet.betShareId : bet.betShareData.betShareId;
        const url = this._betShareConfig.redirectUrl.replace('[BSID]',  betShareId);
        return url;
    }

    public unrestrictedState(): boolean {
        return !!this._betShareConfig && !!this._betShareConfig.restrictedStates && !this._betShareConfig.restrictedStates
            .find(state => state === this._sessionService.getUserInfo().state);
    }

    /**
     * returns bet share percentage of the percentage
     * @param participantShares
     * @param totalShares
     */
    public betSharePercentage(participantShares: number, totalShares: number): number {
         return parseFloat(((participantShares / totalShares) * 100).toFixed(4));
    }
}
