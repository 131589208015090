import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnDestroy
} from '@angular/core';
import { ViewSectionEnum, ViewSectionProgramGroup, ViewSectionPoolsGroup, ViewSectionResultsGroup, ViewSectionStatsGroup } from 'app/wagering/views/enums/view-section.enum';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ITrackBasic,
    DetectionService,
    enumFeatureToggle,
    EventClickType,
    EventClickAttributeType,
    FeatureToggleDataService,
    ToteDataService,
    JwtSessionService,
    WindowManagerService,
    WindowCollectionKey,
} from '@cdux/ng-common';
import { PopUpService } from 'app/shared/pop-up/pop-up.service';
import { Location } from '@angular/common';
import { Router, Params } from '@angular/router';
import { DisplayModeEnum } from 'app/shared';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';

@Component({
    selector: 'cdux-wagering-section-nav',
    templateUrl: './section-nav.component.html',
    styleUrls: ['./section-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WageringSectionNavComponent implements OnInit, OnDestroy {
    /** ENUMS FOR TEMPLATE **/
    public viewSectionEnum = ViewSectionEnum;
    public viewStateGroupEnum = ViewStateGroupEnum;
    public wageringViewEnum = WageringViewEnum;
    /** END ENUMS FOR TEMPLATE **/

    public hasContentNav = false;

    @Input()
    public isRaceWagerable = false;

    @Input() public displayMode: DisplayModeEnum;
    public DisplayModeEnum = DisplayModeEnum;

    @Input()
    public view: WageringViewEnum;

    private _section: ViewSectionEnum;
    @Input() public set section(value: ViewSectionEnum) {
        this._section = value;
        this._updateContentNav();
        this._changeDetector.detectChanges();
    }
    public get section(): ViewSectionEnum {
        return this._section;
    }

    @Input() public selectedRaceNav: ITrackBasic;

    @Output() public selection: EventEmitter<ViewSectionEnum> = new EventEmitter<ViewSectionEnum>();

    public toteDate: string;

    private _destroy: Subject<any> = new Subject();

    private _affiliateId: string;

    public eventClickType = EventClickType;

    public isModeEnabled: boolean;
    public displayBetPad: boolean;
    public isStatsEnabled: boolean;
    public showPPs = false;

    public viewSectionProgramGroup = ViewSectionProgramGroup;
    public viewSectionPoolsGroup = ViewSectionPoolsGroup;
    public viewSectionResultsGroup = ViewSectionResultsGroup;
    public viewSectionStatsGroup = ViewSectionStatsGroup;

    constructor(
        private _environment: ENVIRONMENT,
        private _changeDetector: ChangeDetectorRef,
        private _detectionService: DetectionService,
        private _eventTrackingService: EventTrackingService,
        private _featureToggleService: FeatureToggleDataService,
        private _location: Location,
        private _popUpService: PopUpService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _toteDataService: ToteDataService,
        private _viewStateService: ViewStateService
    ) { }

    ngOnInit() {
        this.showPPs = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SHOW_PP_RACENAV);
        this.isStatsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SHOW_STATS);

        this._toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this.toteDate = date;
        });

        this.isModeEnabled = this.displayMode === DisplayModeEnum.LARGE;
        this.displayBetPad =
            // is device enabled
            this._detectionService.isDesktop() &&

            // is mode enabled
            this.isModeEnabled &&

            // is feature toggle enabled
            this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BETPAD)
            ;

        this._affiliateId = this._environment.affiliateId.toString();
        this._updateContentNav();
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    private _getBetPadURL(): string {
        // Prepare url without query params
        const externalUrl: string = this._location.prepareExternalUrl('/betpad');

        let params: Params;
        if (this.selectedRaceNav && this.selectedRaceNav.BrisCode && this.selectedRaceNav.TrackType && this.selectedRaceNav.RaceNum && this.selectedRaceNav.DisplayName) {
            params = {
                brisCode: this.selectedRaceNav.BrisCode,
                trackType: this.selectedRaceNav.TrackType,
                raceNumber: this.selectedRaceNav.RaceNum,
                displayName: this.selectedRaceNav.DisplayName
            };
        }

        // Append query params to external URL if necessary
        return !!params ? this._router.createUrlTree([externalUrl], { queryParams: params }).toString() : externalUrl;
    }

    public openBetPad(): void {
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        } else {
            this._popUpService.openBetPad(this._affiliateId, this._getBetPadURL());
        }
    }

    public openVideo(): void {
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        } else {
            this.logClickEvent(this.viewSectionEnum.VIDEO);
            if (this.selectedRaceNav && this.selectedRaceNav.BrisCode && this.selectedRaceNav.RaceNum && this.selectedRaceNav.TrackType) {
                const windowName = WindowManagerService.generateWindowName(WindowCollectionKey.VIDEO);
                const url = 'video/live/' + this.selectedRaceNav.BrisCode + '/' + this.selectedRaceNav.TrackType + '?key=' + windowName;
                this._popUpService.openPopUp(url, windowName);
            }
        }
    }

    /**
     * Handles triggering of display for the sub navs.
     * @param section
     * @param fromInput
     */
    public selectSection(section: ViewSectionEnum | ViewStateGroupEnum) {
        switch (section) {
            case ViewStateGroupEnum.PROGRAM:
            case ViewStateGroupEnum.POOLS:
            case ViewStateGroupEnum.STATS:
            case ViewStateGroupEnum.RESULTS:
                this.section = this._viewStateService.getViewSectionGroupCache(section);
                this.selection.emit(this.section);
                break;
            default:
                this.section = section;
                this.selection.emit(this.section);
                break;
        }
        this.logClickEvent(section);
        this._updateContentNav();
        this._changeDetector.detectChanges();
    }

    public isSection(section: ViewSectionEnum | ViewSectionEnum[]): boolean {
        return this.section === section || Array.isArray(section)
            && section.indexOf(this.section) > -1;
    }

    private _updateContentNav() {
        this.hasContentNav = false;
        if (ViewSectionProgramGroup.includes(this.section)
            || ViewSectionPoolsGroup.includes(this.section)
            || ViewSectionStatsGroup.includes(this.section)
            || ViewSectionResultsGroup.includes(this.section)) {
            this.hasContentNav = true;
            // TODO: Remove when scratches enabled for TV
            if ((this.displayMode === DisplayModeEnum.COMPACT || this.displayMode === DisplayModeEnum.MOBILE) && this.section === ViewSectionEnum.CHANGES) {
                this.hasContentNav = false;
            }
        }
        this._changeDetector.detectChanges();
    }

    public logClickEvent(section: ViewSectionEnum | ViewStateGroupEnum) {
        const ts = Date.now();

        switch (section) {
            case ViewStateGroupEnum.RESULTS:
                this._eventTrackingService.logClickEvent(EventClickType.RESULTS_RACE_SELECTION, [
                    { attrId: EventClickAttributeType.RESULTS_SELECT_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.RESULTS_SELECT_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.RESULTS_SELECT_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.RESULTS_SELECT_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            // Since we have moved 'Classic' to under the program tab and renamed it 'Linear', clicking 'Program' should
            // still trigger the PROGRAM_MENU event click whether or not we are going to basic or linear
            case ViewStateGroupEnum.PROGRAM:
                this._eventTrackingService.logClickEvent(EventClickType.PROGRAM_MENU, [
                    { attrId: EventClickAttributeType.PROGRAM_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_RACE_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.PROGRAM_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            case ViewStateGroupEnum.POOLS:
                this._eventTrackingService.logClickEvent(EventClickType.POOLS_MENU, [
                    { attrId: EventClickAttributeType.POOLS_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_RACE_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.POOLS_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.PAST_PERFORMANCES:
                this._eventTrackingService.logClickEvent(EventClickType.PPS_MENU, [
                    { attrId: EventClickAttributeType.PPS_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PPS_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PPS_RACE_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.PPS_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            case ViewStateGroupEnum.STATS:
                this._eventTrackingService.logClickEvent(EventClickType.STATS_MENU, [
                    { attrId: EventClickAttributeType.STATS_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.STATS_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.STATS_RACE_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.STATS_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            case ViewSectionEnum.VIDEO:
                this._eventTrackingService.logClickEvent(EventClickType.VIDEO_MENU, [
                    { attrId: EventClickAttributeType.VIDEO_BRIS_CODE, data: this.selectedRaceNav.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.VIDEO_TRACK_TYPE, data: this.selectedRaceNav.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.VIDEO_RACE_DATE, data: this.toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.VIDEO_RACE_NUMBER, data: this.selectedRaceNav.RaceNum, timestamp: ts }
                ]);
                break;
            default:
            // We should never get here, but if we do, then log nothing
        }
    }
}
