import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { Subject, ReplaySubject } from 'rxjs';
import {
    ITrackBasic,
    BasicBetType,
    MultiRaceExoticBetType,
    ISelectedBetAmount,
    enumBetModifier,
    TracksDataService,
    IBetNavObject,
    TrackService
} from '@cdux/ng-common';

import { RaceDetailsRequestHandler } from '../../classes/race-details-request-handler.class';
import { BetTypesRequestHandler } from '../../classes/bet-types-request-handler.class';
import { takeUntil, withLatestFrom } from 'rxjs/operators';


@Component({
    selector: 'cdux-common-bet-nav',
    templateUrl: './common-bet-nav.component.html',
    styleUrls: ['../betpad-bet-nav/bet-pad-bet-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CommonBetNavComponent implements OnInit, OnDestroy {

    @Output() updateBetType: EventEmitter<(BasicBetType | MultiRaceExoticBetType)> = new EventEmitter<(BasicBetType | MultiRaceExoticBetType)>();
    @Output() updateBetModifier: EventEmitter<enumBetModifier> = new EventEmitter<enumBetModifier>();
    @Output() updateBetAmount: EventEmitter<ISelectedBetAmount> = new EventEmitter<ISelectedBetAmount>();
    @Output() availableBetTypes: EventEmitter<(BasicBetType[]| MultiRaceExoticBetType[])> = new EventEmitter<(BasicBetType[] | MultiRaceExoticBetType [])>();


    @Input()
    public set selectedRaceNav(raceNav: ITrackBasic) {
        if (!!raceNav) {
            this._raceDetailsRequestHandler.updateRaceNavigation(raceNav);
            this._betTypesRequestHandler.updateBetTypes(raceNav);
            this.selectedTrackRace = raceNav;
        }
    }

    @Input()
    public set selectedBetNav(betNavObject: IBetNavObject) {
        this._betNavChange.next(betNavObject);
        if (!!betNavObject) {
            this.betAmountList = betNavObject.type.betAmounts;
            this.betModifierList = betNavObject.type.betModifiers;
            this.selectedBetAmount = betNavObject.amount;
            this.selectedBetModifier = betNavObject.modifier;
            this.selectedPoolType = betNavObject.type;
            this._changeDetector.detectChanges();
        }
    }

    public selectedTrackRace: ITrackBasic;
    public selectedPoolType: (BasicBetType | MultiRaceExoticBetType);
    public selectedBetModifier: enumBetModifier;
    public selectedBetAmount: ISelectedBetAmount;
    public poolTypes: (BasicBetType | MultiRaceExoticBetType)[];
    public betModifierList: enumBetModifier[];
    public betAmountList: ISelectedBetAmount[];
    public isWagerable: boolean;

    private _poolTypesAvailable: boolean;
    private _isWagerableRace: boolean;
    private _destroy: Subject<boolean> = new Subject();
    private _betNavChange: ReplaySubject<IBetNavObject> = new ReplaySubject(1);
    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;
    private _betTypesRequestHandler: BetTypesRequestHandler;


    constructor(
        private _trackDataService: TracksDataService,
        private _changeDetector: ChangeDetectorRef,
    ) {
        this._raceDetailsRequestHandler =  new RaceDetailsRequestHandler(this._trackDataService);
        this._betTypesRequestHandler = new BetTypesRequestHandler(this._trackDataService);
    }

    /** LIFECYCLE HOOKS **/
    ngOnInit() {
        this._betTypesRequestHandler.listen().pipe(withLatestFrom(this._betNavChange))
            .subscribe(([betTypes, betNavObject]) => {
                // convert this track/race's betTypes into the poolTypes needed by dropdowns
                this.poolTypes = betTypes;
                this._poolTypesAvailable = !!this.poolTypes && this.poolTypes.length > 0;
                if (!betNavObject) {
                    // only emit on cold start, prior to an initial selected wager
                    this.availableBetTypes.emit(betTypes);
                }
                this._setIsWagerable();
                this._changeDetector.detectChanges();
            });

        this._raceDetailsRequestHandler.listen().pipe(takeUntil(this._destroy))
           .subscribe((raceDetails) => {
                if (!!raceDetails && !!raceDetails.status) {
                    this._isWagerableRace = TrackService.isWagerableRace(raceDetails.status);
                    if (!this._isWagerableRace) {
                        this.availableBetTypes.emit();
                    }
                } else {
                    this._isWagerableRace = false;
                    this.availableBetTypes.emit();
                }
                this._setIsWagerable();
                this._changeDetector.detectChanges();
        });
    }

    ngOnDestroy() {
        // Cleanup controls
        this._destroy.next();
        this._destroy.complete();
        this._betNavChange.complete();
        this._raceDetailsRequestHandler.kill();
        this._betTypesRequestHandler.kill();
    }

    private _setIsWagerable() {
        this.isWagerable = this._poolTypesAvailable && this._isWagerableRace;
    }

    public onUpdateBetType(betType: (BasicBetType | MultiRaceExoticBetType)) {
        if (!betType) {
            return;
        }
        this.updateBetType.emit(betType);
    }

    public onUpdateBetModifier(betModifier: enumBetModifier) {
        if (!betModifier) {
            return;
        }
        this.updateBetModifier.emit(betModifier);
    }

    public onUpdateBetAmount(betAmount: ISelectedBetAmount) {
        if (!betAmount) {
            return;
        }
        this.updateBetAmount.emit(betAmount);
    }
}
