import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@cdux/ng-common';
import { TuxCommonModule } from '../common/common.module';

import { RadioGroupComponent } from './radio-group.component';
import { RadioOptionComponent } from './radio-option.component';

@NgModule({
  declarations: [
    RadioGroupComponent,
    RadioOptionComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    TuxCommonModule,
  ],
  exports: [
    RadioGroupComponent,
    RadioOptionComponent,
  ],
})
export class RadioModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<RadioModule> {
        return {
            ngModule: RadioModule,
            providers: [

            ],
        }
    }
}
