<a class="learn-more" (click)="openLearnMore()">Learn More</a>

<ng-template #learnMoreModal>
    <div class="header-info">
        <h2 class="wager-info-header">{{ headerText }}
            <div class="icon--close" (click)="closeLearnMore()"></div>
        </h2>
        <ng-template [cdkPortalOutlet]="learnMorePortal"></ng-template>
    </div>
</ng-template>

<ng-template cdkPortal #learnMorePortal="cdkPortal" [ngSwitch]="infoType">
    <div class="wager-type-info-container" *ngSwitchCase="enumWagerInfoType.WAGER_TYPES">
        <ul>
            <h2>WIN</h2>
                <p>A WIN bet is a winner if a selected runner finishes FIRST.</p>
        </ul>
        <ul>
            <h2>PLACE</h2>
                <p>A PLACE bet is a winner if a selected runner finishes FIRST or SECOND.</p>
        </ul>
        <ul>
            <h2>SHOW</h2>
                <p>A SHOW bet is a winner if a selected runner finishes FIRST, SECOND, or THIRD.</p>
        </ul>
        <ul>
            <h2>DOUBLE</h2>
                <p>A DOUBLE bet wins if a selected runner from each race finishes FIRST in each of the two designated races.</p>
        </ul>
        <ul>
            <h2>EXACTA</h2>
                <p>An EXACTA bet wins if your selected runners include the TOP2 finishers (1st & 2nd) - in EXACT order.</p>
                <p>Example - A “3/6” Exacta combination only wins if #3 wins & #6 runs 2nd.</p>
        </ul>
        <ul>
            <h2>TRIFECTA</h2>
                <p>A TRIFECTA bet wins if a selected runner combination includes the TOP3 finishers (1st, 2nd, & 3rd) - in EXACT order.</p>
        </ul>
        <ul>
            <h2>OMNI/SWINGER</h2>
                <p>An OMNI/SWINGER bet wins if a selected runner combination includes two of the TOP3 finishers - in ANY order</p>
                <p>(They can finish 1st and 2nd, 2nd and 3rd or 1st and 3rd.)</p>
        </ul>
        <ul>
            <h2>QUINELLA</h2>
                <p>A QUINELLA bet wins if a selected runner combination includes the TOP 2 finishers (1st & 2nd) - in ANY order.</p>
        </ul>
        <ul>
            <h2>TRIO</h2>
                <p>A TRIO bet (offered for French racing) wins if a selected runner combination includes the TOP 3 finishers</p>
                <p> (1st, 2nd, & 3rd) - in ANY order.</p>
        </ul>
        <ul>
            <h2>SUPERFECTA</h2>
                <p>A SUPERFECTA bet wins if a selected runner combination includes the TOP 4 finishers (1st, 2nd, 3rd, & 4th) - in EXACT order.</p>
        </ul>
        <ul>
            <h2>PICK-#</h2>
                <p>A PICK-# bet wins if a selected runner of each race finishes FIRST in each of the designated races.</p>
        </ul>
        <ul>
            <h2>WIN/PLACE/SHOW</h2>
                <p>Selected runner(s) must finish FIRST, SECOND, and/or THIRD.</p>
        </ul>
        <ul>
            <h2>WIN/PLACE</h2>
                <p>Selected runner(s) must finish FIRST and/or SECOND.</p>
        </ul>
        <ul>
            <h2>WIN/SHOW</h2>
                <p>Selected runner(s) must finish FIRST and/or THIRD.</p>
        </ul>
        <ul>
            <h2>PLACE/SHOW</h2>
                <p>Selected runner(s) must finish SECOND and/or THIRD.</p>
        </ul>
        <ul>
            <h2>SUPER HIGH-FIVE</h2>
                <p>A SUPER HI-5 bet wins if a selected runner combination includes the TOP 5 finishers (1st, 2nd, 3rd, 4th, & 5th) - in EXACT order.</p>
        </ul>
    </div>
    <div *ngSwitchCase="enumWagerInfoType.WAGER_MODIFIERS" class="wager-modifier-container">
            <ul>
                <h2>STRAIGHT</h2>
                    <p>A STRAIGHT bet wins if your selected runners finish in the EXACT positions specified on your ticket.
                    <br> Example- $1 Trifecta 5/7/6 covers one combination. #5 must finish first, #7 must finish 2nd, and #6 must finish third.</p>
            </ul>
            <ul>
                <h2>KEY BOX</h2>
                    <p>A KEY BOX bet wins if a selected ‘Key’ runner finishes in any position of the bet (e.g. 1st, 2nd, or 3rd position for Trifecta) and a ‘Non-key’ runner finishes
                    in each remaining position of the bet.<br> Example- $1 Trifecta Key Box 5/6,7 covers 6 finish order combinations ($6 total): 5/6/7, 5/7/6, 7/5/6, 7/6/5, 6/5/7, or 6/7/5.</p>
            </ul>
            <ul>
                <h2>BOX</h2>
                    <p>A BOX bet wins if your selected runners contain all top finishers for the bet (top2 finishers for Exacta, top 3 for Trifecta, top 4 for Superfecta, etc.) - in any order.
                    <br> Example- $1 Trifecta Box 5,6,7 covers six finish order combinations ($6 total): 5/7/6, 5/6/7, 7/5/6, 7/6/5, 6/5/7, or 6/7/5.</p>
            </ul>
            <ul>
                <h2>WHEEL</h2>
                    <p>A WHEEL bet wins if your selected runners finish in the EXACT positions specified on your ticket.<br> Example- $1 Trifecta 5/3,7/all only wins if #5 finishes first,
                    the #3 or #7 finishes 2nd, and any (all) of the horses can finish third.</p>
            </ul>
            <ul>
                <h2>KEY</h2>
                    <p>A KEY bet wins if your first selected KEY runner finishes FIRST and your other selected NON-KEY runners finish in the remaining underneath positions of the bet.
                    <br>Example- $1 Trifecta Key 5/6,7 covers two combinations ($2 total). And wins with finish orders of either 5/7/6 or 5/6/7.</p>
            </ul>
            <ul *ngIf="isPowerBoxEnabled">
                <h2>POWER BOX</h2>
                    <p>A POWER BOX wager wins if one runner from each of your runner position selections (e.g. "1" with "2,3" with "4,5,6") finish in any position of the bet
                    (e.g. top 2 positions for Exacta, top 3 for Trifecta, top4 for Superfecta, etc).<br> Example- $1 Trifecta Power Box "1 with 2,3 with 4,5,6" covers all (36) 
                    possible combinations where: (#1) -and- (#2or#3) -and- (#4,#5,or#6) MUST finish among the top3 finishers - in any order.</p>
            </ul>
    </div>
</ng-template>
