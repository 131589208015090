import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { WageringViewEnum } from '../enums/wagering-view.enum';
import {
    EventsService,
    FeatureToggleDataService,
    JwtSessionService,
    PlayerGroupsService,
    enumFeatureToggle,
    enumPlayerGroup,
} from '@cdux/ng-common';
import { EventKeys } from 'app/shared/common/events';

export class RestrictedViewError extends Error {
    public view: WageringViewEnum;

    constructor(message: string, view: WageringViewEnum) {
        super(message);
        this.view = view;
    }
}

/**
 * Is responsible for controlling the currently selected view. It handles
 * - Restrictions based on user group.
 * - Switching to the mobile view when entering into a mobile screen size.
 */
export class ViewSelectionManager extends CduxRxJSBuildingBlock<WageringViewEnum, WageringViewEnum> {

    protected _stream: Observable<WageringViewEnum>;

    /** CONTROLS **/
    private _selectView: Subject<WageringViewEnum> = new Subject<WageringViewEnum>();
    /** END CONTROLS **/


    /**
     * An array of views that require an authenticated user.
     */
    private _authenticatedViewMap = [ WageringViewEnum.VIDEO ];

    /**
     * A map that matches up which Player Groups are allowed to access which Views.
     */
    private _restrictedViewMap = this._buildRestrictedViewMap();

    /**
     * Constructor
     *
     * @param _mediaQueryService
     * @param _playerGroupsService
     */
    constructor(
        private _featureToggleDataService: FeatureToggleDataService,
        private _mediaQueryService: CduxMediaToggleService,
        private _playerGroupsService: PlayerGroupsService,
        private _sessionService: JwtSessionService,
        private _eventsService: EventsService
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /**
     * Does cleanup.
     */
    public kill() {
        super.kill();
        this._selectView.complete();
    }

    /**
     * Used to indicate a selection being made to the View.
     *
     * @param view
     */
    public selectView(view: WageringViewEnum) {
        this._selectView.next(view);
    }
    /** END EXTERNAL CONTROLS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = this._selectView
            .pipe(
                distinctUntilChanged(),
                this._handleMobileScreenSize(),
                this._handleViewRestrictions(),
                finalize(() => this.kill()),
                takeUntil(this._kill),
                // share()
            ) as Observable<WageringViewEnum>;
    }

    /**
     * One-shot method to work around the inability to specify object literal keys with enums.
     */
    private _buildRestrictedViewMap(): { [WageringViewEnum: string]: enumPlayerGroup[] } {
        return {}; // assign these entries individually by enum value
    }

    /** CUSTOM OPERATORS **/
    /**
     * Applies restrictions to the available views. Results in a thrown error, if not allowed.
     */
    private _handleViewRestrictions() {
        return (source: Observable<WageringViewEnum>) => source.pipe(tap(selectedView => {
            if (this._authenticatedViewMap.includes(selectedView) && !this._sessionService.isLoggedIn()) {
                throw new RestrictedViewError('Authenticated wagering view was selected.', selectedView);
            } else if (selectedView === WageringViewEnum.VIDEO && !this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.TUX_TV)) {
                throw new RestrictedViewError('Disabled wagering view was selected.', selectedView);
            } else if (this._restrictedViewMap[selectedView] && !this._restrictedViewMap[selectedView].some(
                group => this._playerGroupsService.inPlayerGroup(group, false)
            )) {
                throw new RestrictedViewError('Restricted wagering view was selected.', selectedView);
            }
        }));
    }

    /**
     * Switches to a mobile view in the event that a device's screen size drops to our small glass size.
     */
    private _handleMobileScreenSize() {
        return (source: Observable<WageringViewEnum>) =>
            combineLatest([
                    source,
                    this._mediaQueryService.registerQuery('phone')
                ])
                .pipe(
                    map(([selectedView, onMobileSize]) => {
                        if (onMobileSize && selectedView === WageringViewEnum.VIDEO) {
                            // TODO: Implement better initial drawer open option. May be @input
                            setTimeout(() => this._eventsService.broadcast(EventKeys.VIDEO_PRIMARY_OPEN), 2000)
                        }
                        return onMobileSize ? WageringViewEnum.MOBILE : selectedView;
                    })
                );
    }
    /** END CUSTOM OPERATORS **/
}
