
<div class="link search-container" (clickOutside)="closeSearch()">
  <div id="search-menu-item-button" class="search-menu-item" (cduxTap)="openSearch()">
      <i class="icon-magnifying-glass-light"></i>
  </div>
  <div id="search-menu-item-field" [ngClass]="{'is-open': isSearchOpen}" class="search-field">
      <i class="icon-magnifying-glass-primary"></i>
      <input
          #searchField
          placeholder="Search Today's Runners"
          type="text"
          name="searchString"
          id="search-menu-item-input"
          [(ngModel)]="searchString"
          (ngModelChange)="modelChanged.next($event)">
      <i [ngClass]="{'is-enabled': !!searchString}" class="icon-clear-search" (cduxTap)="clearSearchString()"></i>
  </div>
  <!-- https://stackoverflow.com/a/51556842/356016 -->
  <ng-container *ngIf="isSearchOpen && searchResults | async as resultsList">
    <cdux-search-result-list
      *ngIf="resultsList || showResultsPanel()"
      [resultList]="resultsList"
      [searchString]="searchString"
      (resultClicked)="goToProgram($event)">
    </cdux-search-result-list>
  </ng-container>
</div>
