import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuxCommonModule } from '../common/common.module';

import { PopUpComponent } from './pop-up.component';

@NgModule({
    declarations: [
        PopUpComponent,
    ],
    imports: [
        CommonModule,
        TuxCommonModule,
    ],
    exports: [
        PopUpComponent,
    ],
})
export class PopUpModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<PopUpModule> {
        return {
            ngModule: PopUpModule,
            providers: [

            ],
        }
    }
}
