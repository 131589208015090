<section class="will-pays" *ngIf="willPaysObs | async as willPays; else noWillPays">
    <div class="will-pays-col-1">
        <div class="will-pays-header-bar">
            <div class="will-pays-number-sorter">
                <span class="is-sortable" [class.is-sorted]="isSortedBy(enumProgramSort.SORTABLE_PROGRAM_NUMBER)" (click)="setSortKey(enumProgramSort.SORTABLE_PROGRAM_NUMBER)" [cduxLogClick]="eventClickType.WILLPAYS_SORT" [cduxLogClickAttributeType]="eventClickAttributeType.WILLPAYS_SORT_COLUMN" [cduxLogClickAttributeData]="enumProgramSort.SORTABLE_PROGRAM_NUMBER">#</span>
            </div>
        </div>

        <div class="will-pays-header-bar-dark winner-label">WNR</div>

        <ng-container *ngFor="let entry of willPays.Entries">
            <div class="will-pays-runners-row" [class.is-scratched]="entry.ProgramEntry.Scratched">
                <div [ngClass]="entry.ProgramEntry.ProgramNumber | cduxSaddleClothClass: track?.TrackType" class="saddle-cloth">{{ entry.ProgramEntry.ProgramNumber }}</div>
            </div>
        </ng-container>
    </div>

    <div class="will-pays-col-9">
        <div class="will-pays-col-9-inner">
            <table class="will-pays-content">
                <tbody>
                    <tr>
                        <td class="will-pays-header-bar">
                            <div>
                                <span class="is-sortable" [class.is-sorted]="isSortedBy(enumProgramSort.ODDS_RANK)" (click)="setSortKey(enumProgramSort.ODDS_RANK)"  [cduxLogClick]="eventClickType.WILLPAYS_SORT" [cduxLogClickAttributeType]="eventClickAttributeType.WILLPAYS_SORT_COLUMN" [cduxLogClickAttributeData]="enumProgramSort.ODDS_RANK">ODDS</span>
                            </div>
                        </td>

                        <td class="will-pays-header-bar" *ngFor="let pool of willPays.Pools">
                            <div class="will-pays-bet-type">
                                <span class="is-sortable" [class.is-sorted]="isSortedBy(getPoolId(pool))" (click)="setSortKey(getPoolId(pool))" [cduxLogClick]="eventClickType.WILLPAYS_SORT" [cduxLogClickAttributeType]="eventClickAttributeType.WILLPAYS_SORT_COLUMN" [cduxLogClickAttributeData]="pool.Pooltype">
                                    {{ formatBaseAmount(pool.BaseAmount) }} {{ formatPoolType(pool.Pooltype) }}
                                </span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="will-pays-header-bar-dark"></td>

                        <td class="will-pays-header-bar-dark" *ngFor="let pool of willPays.Pools">{{ formatQualifier(pool.Qualifier) }}</td>
                    </tr>

                    <tr class="runner-payout" *ngFor="let entry of willPays.Entries">
                        <td [class.is-favorite]="entry.ProgramEntry.IsFavorite">{{ entry.ProgramEntry.getOddsToDisplay() }}</td>

                        <td *ngFor="let pool of willPays.Pools">{{ formatEntryPoolValue(entry.PoolEntries[getPoolId(pool)]) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>

<ng-template #noWillPays>
    <div class="has-error light" *ngIf="!isLoading">
        <div class="gbl-message">
            <i class="icon--info"></i>
            <div class="header">Will Pays unavailable</div>
            <div class="description">Will Pays are unavailable for this race.</div>
        </div>
    </div>
</ng-template>
