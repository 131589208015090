<div class="stable-alerts__search-to-add" *ngIf="!addFavoriteDisplay">
    <div class="search-to-add-field">
        <!-- is-open applied when search-menu-item is clicked or tapped -->
        <i class="icon-magnifying-glass-primary"></i>
        <input autofocus
            placeholder="{{ searchPlaceholder }}"
            type="text"
            name="searchString"
            id="favorite-stable-alert-search"
            [ngModel]="searchString"
            (ngModelChange)="modelChanged.next($event)">
        <i [ngClass]="{'is-enabled': !!searchString}" class="icon-clear-search" (click)="clearSearchString()"></i>
    </div>

    <ul id="search-to-add-results__list" class="search-to-add-results__list stable-alerts__search-to-add__content--scroll" [ngClass]="{'is-open':!isLoading}">
        <ng-container *ngIf="!isLoading && results.length > 0; else noResultsMessage">
            <ng-container *ngFor="let result of results; index as i">
                <li id="search-to-add-results__item{{i+1}}" class="search-to-add-results__item" tabindex="0"
                    (click)="addFavorite(result)" *ngIf="!result.isCurrentFavorite">
                    <!-- Add favorite -->
                    <div
                        [id]="'fave-toggle-'+id"
                        class="search-to-add-results__item__col1" >
                        <figure class="search-to-add-results__horseshoe-icon">
                            <figcaption>add</figcaption>
                            <div class="favorite-badge not-selected">
                                <i class="icon--horseshoe-light"></i>
                            </div>
                        </figure>
                    </div>

                    <!-- Track / Race Info -->
                    <div id="search-to-add-results__item{{i+1}}__col2" class="search-to-add-results__item__col2">
                        <div class="col-1" *ngIf="result.silkUrl.length > 0">
                            <div id="search-to-add-silk" class="silk"><img src="{{ result.silkUrl }}"></div>
                        </div>

                        <div id="search-to-add-runner-name{{i+1}}" class="search-to-add-results__item__result-title">
                        {{ result.name }}<span *ngIf="result.yearOfBirth"> ({{ result.yearOfBirth}})</span>
                        </div>

                        <div id="search-to-add-race-info{{i+1}}" class="search-to-add-results__item__race">
                            <span id="search-to-add-race-number{{i}}" class="search-to-add-results__item__race__number">
                                {{ result.raceNumber ? 'R' + result.raceNumber : ''}}
                            </span>
                            <span id="search-to-add-track-name{{i+1}}" class="search-to-add-results__item__race__info">
                                {{ result.trackName }}
                            </span>
                        </div>
                    </div>
                </li>
                <li id="search-to-add-results__item{{i+1}}" class="search-to-add-results__item" tabindex="0"
                    *ngIf="result.isCurrentFavorite">
                    <!-- already added favorite -->

                    <div
                        [id]="'fave-toggle-'+id"
                        class="search-to-add-results__item__col1">
                        <figure class="search-to-add-results__horseshoe-icon">
                            <div class="favorite-badge selected">
                                <i class="icon--horseshoe-light"></i>
                            </div>
                        </figure>
                    </div>

                    <!-- Track / Race Info -->
                    <div id="search-to-add-results__item{{i+1}}__col2" class="search-to-add-results__item__col2">
                        <div class="col-1" *ngIf="result.silkUrl.length > 0">
                            <div id="search-to-add-silk" class="silk"><img src="{{ result.silkUrl }}"></div>
                        </div>

                        <div id="search-to-add-runner-name{{i+1}}" class="search-to-add-results__item__result-title">
                          {{ result.name }}<span *ngIf="result.yearOfBirth"> ({{ result.yearOfBirth}})</span>
                        </div>

                        <div id="search-to-add-race-info{{i+1}}" class="search-to-add-results__item__race">
                            <span id="search-to-add-race-number{{i}}" class="search-to-add-results__item__race__number">
                                {{ result.raceNumber ? 'R' + result.raceNumber : ''}}
                            </span>
                            <span id="search-to-add-track-name{{i+1}}" class="search-to-add-results__item__race__info">
                                {{ result.trackName }}
                            </span>
                        </div>
                    </div>
                </li>
                <div *ngIf="results.length - 1 !== i" id="search-to-add-results__hr{{i+1}}" class="search-to-add-results__hr"></div>
            </ng-container>
        </ng-container>

        <ng-template #noResultsMessage>
            <!-- No results found -->
            <div id="no-results-container" class="no-results">
                <div class="tile-container has-error">
                    <div class="gbl-message">
                        <i class="icon--info"></i>
                        <div id="no-results-header" class="header">No Results</div>
                        <div id="no-results-message" class="description">No results found for your search</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ul>

    <footer class="stable-alerts__search-to-add__footer">
        <button class="full-width button-primary-outline-white"
            id="cancel-search-to-add"
            onclick="this.blur();"
            (click)="closeDrawer()">Cancel
        </button>
    </footer>
</div>

<section *ngIf="addFavoriteDisplay" class="stable-alerts__search-to-add__edit-notes">
    <div class="stable-alerts__search-to-add__edit-notes__content--scroll">
        <h2 class="h2--standard center-text">Add <span *ngIf="searchType==='runner'">{{ favoriteAdding.runnerName | titlecase }}</span> <span *ngIf="searchType!=='runner'">{{ favoriteAdding.firstName + ' ' + favoriteAdding.middleName + ' ' + favoriteAdding.lastName }}</span> to Stable</h2>
        <textarea id="favorite-add-modal-note"
                    cols="50" [maxlength]="COUNT_MAX" name="note"
                    placeholder="ADD A NOTE..." rows="40"
                    [(ngModel)]="note"
        ></textarea>
    </div>
    <footer class="stable-alerts__search-to-add__edit-notes__footer">
        <button class="button-primary-outline-white"
            id="cancel-search-to-add"
            onclick="this.blur();"
            (click)="cancelAdd()">Cancel
        </button>
        <button class="button-primary"
            id="save-search-to-add"
            onclick="this.blur();"
            (click)="confirm()">Add
        </button>
    </footer>
</section>
