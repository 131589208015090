import {CduxAbstractSidebarComponent} from '../../../../sidebar/cdux-sidebar-component.class';
import {Component, OnInit} from '@angular/core';
import {
    ISidebarComponentProperties,
    ISidebarHeaderComponent,
    ISidebarHeaderProperties
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';

@Component({
    selector: 'cdux-check-method-header',
    templateUrl: './check-method-header.component.html',
    styleUrls: ['./check-method-header.component.scss']
})
export class FundingCheckMethodHeaderComponent extends  CduxAbstractSidebarComponent implements OnInit {

    public static getSidebarComponent(options: ISidebarHeaderProperties = {}): ISidebarHeaderComponent {
        return {
            component: FundingCheckMethodHeaderComponent,
            properties: options
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    ngOnInit() { /* empty */ }
}
