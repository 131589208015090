import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
    enumFeatureToggle,
    EventClickType,
    EventClickAttributeType,
    FeatureToggleDataService,
    ITrack,
    ToteDataService,
    ITrackBasic,
    JwtSessionService,
    WindowCollectionKey,
    WindowManagerService,
    IEventClickDetailsAttribute
} from '@cdux/ng-common';
import { DropupModalConfig, DropupPrebuiltComponent, enumMediaTarget, ModalService } from '@cdux/ng-platform/web';
import { WageringViewEnum } from './enums/wagering-view.enum';
import { TemplatePortal } from '@angular/cdk/portal';
import { HeaderService } from '../../shared/header/services/header.service';
import { combineLatest, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { IMobileHeaderConfig, MobileHeaderConfigDynamicRef } from '../../shared/header/models';
import { DisplayModeEnum } from '../../shared/common/enums/display-mode.enum';
import { ActiveDrawerEnum, DrawerService } from '../../shared/common/services/drawer/drawer.service';
import { EventTrackingService } from '../../shared/event-tracking/services/event-tracking.service';
import { PopUpService } from '../../shared/pop-up/pop-up.service';


@Component({
    selector: 'cdux-view-navigation',
    templateUrl: './view-navigation.component.html',
    styleUrls: ['./view-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewNavigationComponent implements OnInit, OnDestroy {
    /** TEMPLATE ENUMS **/
    public DisplayModeEnum = DisplayModeEnum;
    public enumMediaTarget = enumMediaTarget;
    /** END TEMPLATE ENUMS **/

    /** INPUTS/OUTPUTS **/
    private _view: WageringViewEnum;
    @Input()
    public set view(value: WageringViewEnum) {
        this._view = value;
        this._wageringViewStream.next(value);
    }
    public get view(): WageringViewEnum {
        return this._view;
    }

    private _selectedRaceNav: ITrackBasic;
    @Input()
    public set selectedRaceNav(raceNav: ITrackBasic) {
        this._selectedRaceNav = raceNav;
        this._changeDetector.detectChanges();
    }
    public get selectedRaceNav(): ITrackBasic {
        return this._selectedRaceNav;
    }

    @Output() public viewChange = new EventEmitter<WageringViewEnum>();
    @Output() public updateRaceNav: EventEmitter<ITrackBasic> = new EventEmitter<ITrackBasic>();
    @Output() public updateTrackList: EventEmitter<ITrackBasic[]> = new EventEmitter<ITrackBasic[]>();
    /** END INPUTS/OUTPUTS **/

    private _mobileRaceHeaderStream: Subject<TemplatePortal<any>> = new Subject<TemplatePortal<any>>();
    @ViewChild('mobileRaceHeader') public set mobileRaceHeader(value: TemplatePortal<any>) {
        this._mobileRaceHeaderStream.next(value);
    }

    private _mobileRaceSubHeaderStream: Subject<TemplatePortal<any>> = new Subject<TemplatePortal<any>>();
    @ViewChild('mobileRaceSubHeader') public set mobileRaceSubHeader(value: TemplatePortal<any>) {
        this._mobileRaceSubHeaderStream.next(value);
    }

    @ViewChild('mobileRaceDetails') private _mobileRaceDetails: TemplatePortal<any>;

    /** PUBLIC PROPERTIES **/
    public WageringViewEnum = WageringViewEnum;
    public isSelectingView = false; // displays view selection menu
    public drawerContent: TemplateRef<any>;
    public isDrawerOpen: boolean = false;
    public isPopoutVideoEnabled: boolean = this._featureToggleService.isFeatureToggleOn('POPOUT_VIDEO');
    public isTuxTvEnabled: boolean = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.TUX_TV);
    /** END PUBLIC PROPERTIES **/

    private _wageringViewStream: Subject<WageringViewEnum> = new Subject<WageringViewEnum>();
    private _destroy: Subject<undefined> = new Subject<undefined>();
    private _toteRaceDate: string;


    constructor(
        private _drawerService: DrawerService,
        private _featureToggleService: FeatureToggleDataService,
        private _headerService: HeaderService,
        private _modalService: ModalService,
        private _changeDetector: ChangeDetectorRef,
        private eventService: EventTrackingService,
        private _popUpService: PopUpService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _toteDataService: ToteDataService,
        private _windowManager: WindowManagerService
    ) {}

    ngOnInit() {
        combineLatest([
            this._wageringViewStream,
            this._mobileRaceHeaderStream,
            this._mobileRaceSubHeaderStream
        ])
            .pipe(
                finalize(() => this._headerService.setDefaultHeader()),
                takeUntil(this._destroy)
            )
            .subscribe(([view, header, subheader]) => {
                if (view === WageringViewEnum.MOBILE && header) {
                    const headerConfig: IMobileHeaderConfig = {
                        showVideoButton: true,
                        dynamicRef: new MobileHeaderConfigDynamicRef(header)
                    };

                    if (subheader) {
                        headerConfig.dynamicRefSub = new MobileHeaderConfigDynamicRef(subheader);
                    }

                    this._headerService.updateHeader(headerConfig);
                } else {
                    this._headerService.setDefaultHeader();
                }
            });



        this._toteDataService.currentRaceDate(true)
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe((date: string) => {
                this._toteRaceDate = date;
            })

    }

    ngOnDestroy() {
        this._wageringViewStream.complete();
        this._mobileRaceHeaderStream.complete();
        this._destroy.next();
        this._destroy.complete();
    }

    /** EVENT HOOKS **/
    public updateView(view: WageringViewEnum) {
        this._logClickEvent(view);
        this.viewChange.emit(view);
    }

    public onRaceNavChange(raceNav: ITrackBasic) {
        this.updateRaceNav.emit(raceNav);
    }

    public onTrackListChange(trackList: ITrackBasic[] | ITrack[]) {
        this.updateTrackList.emit(trackList);
    }

    public onDrawerContentChange(drawerContent: TemplateRef<any>) {
        this.drawerContent = drawerContent;
    }

    public toggleDrawer(openDrawer: boolean) {
        this.isDrawerOpen = openDrawer;
    }
    /** END EVENT HOOKS **/

    public openMobileRaceDetails() {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.maxWidth = '100vw';
        modalConfig.maxHeight = '100vh';
        modalConfig.context = {
            headerText: 'Race Details',
            component: this._mobileRaceDetails
        };
        this._drawerService.toggleDrawer(ActiveDrawerEnum.DESCRIPTION);

        const raceDescriptionModal = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        raceDescriptionModal.componentInstance.close.pipe(
            take(1)
        ).subscribe(() => {
            raceDescriptionModal.close();
            this._drawerService.toggleDrawer(ActiveDrawerEnum.NONE);
        });
    }

    public openVideoPopout(): void {
        if (!this._sessionService.isLoggedIn()) {
            this._sessionService.redirectLoggedInUserUrl = this._router.url;
            this._router.navigate(['/login']);
        } else {
            this.eventService.logClickEvent(EventClickType.VIDEO_POPOUT, [{
                attrId: EventClickAttributeType.SIMULTANEOUS_VIDEO_COUNT,
                data: this._windowManager.getCollectionCount(WindowCollectionKey.VIDEO)
            }]);
            if (this.selectedRaceNav && this.selectedRaceNav.BrisCode && this.selectedRaceNav.RaceNum && this.selectedRaceNav.TrackType) {
                const windowName = WindowManagerService.generateWindowName(WindowCollectionKey.VIDEO);
                const url = 'video/live/' + this.selectedRaceNav.BrisCode + '/' + this.selectedRaceNav.TrackType + '?key=' + windowName;
                this._popUpService.openPopUp(url, windowName);
            }
        }
    }

    private _logClickEvent(view: WageringViewEnum) {
        const videoState = view === WageringViewEnum.VIDEO ? 1 : 0;
        const attributes: IEventClickDetailsAttribute[] = [
            {
                attrId: EventClickAttributeType.TV_TOGGLE_STATE,
                data: videoState,
                timestamp: new Date().getTime()
            },
            {
                attrId: EventClickAttributeType.TV_TOGGLE_BRIS_CODE,
                data: this._selectedRaceNav.BrisCode,
                timestamp: new Date().getTime()
            },
            {
                attrId: EventClickAttributeType.TV_TOGGLE_TRACK_TYPE,
                data: this._selectedRaceNav.TrackType,
                timestamp: new Date().getTime()
            },
            {
                attrId:  EventClickAttributeType.TV_TOGGLE_RACE,
                data: this._selectedRaceNav.RaceNum,
                timestamp: new Date().getTime()
            },
            {
                attrId: EventClickAttributeType.TV_TOGGLE_RACE_DATE,
                data: this._toteRaceDate,
                timestamp: new Date().getTime()
            }
        ];
        this.eventService.logClickEvent(EventClickType.TV_TOGGLE, attributes);

    }
}
