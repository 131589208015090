import { Component, HostListener, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { enumKeyboardEventCodes, IMfaResponse, MfaDataService, DeviceTypeEnum, MfaResponseEnum } from '@cdux/ng-common';
import { MfaErrorMessageEnum } from '../../../../security/login/enums/mfa-error-message.enum';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { MfaFormStepEnum } from '../enums/mfa-form-step.enum';
import { IMfaStepsConfig } from '../interfaces/mfa-steps-config.interface';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { MfaBusinessService } from '../services/mfa.business.service';
import { MfaMessageEnum } from 'app/security/login/enums/mfa-message.enum';


@Component({
    selector: 'cdux-enable-mfa-form',
    templateUrl: './enable-mfa-form.html',
    styleUrls: ['./enable-mfa-form.scss']
})
export class EnableMfaFormComponent implements OnInit, OnDestroy {

    public mfaPhoneForm: FormGroup;
    public mfaOtpForm: FormGroup;
    public formStep: MfaFormStepEnum = MfaFormStepEnum.STEP_ONE;
    public errorMessage: string;
    public hasErrors: boolean = false;
    public cellPhone: string;
    public submitting: boolean = false;
    public mfaAuthorizationId: string;
    public isLoadingDotsActive: boolean = false;
    public deviceType = DeviceTypeEnum;
    public readonly textMaskConfig = {
        mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        placeholderChar: 'x',
    }
    public MfaFormStepEnum = MfaFormStepEnum;
    public mfaResponseEnum = MfaResponseEnum;
    public message: string;
    public showMessage: boolean = false;

    @Output() onMfaFormClose = new EventEmitter();

    private readonly MAX_ATTEMPTS = 2;
    private _paringErrorCount = 0;
    private textMaskRegEx = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
    private _destroyed: EventEmitter<boolean> = new EventEmitter<boolean>();


    constructor(
        private _formBuilder: FormBuilder,
        private _mfaDataService: MfaDataService,
        private _sidebarService: SidebarService,
        private _mfaBusinessService: MfaBusinessService,
    ) { }

    ngOnInit() {
        this.createPhoneForm();
        this.createOtpForm();

        this._sidebarService.onHeaderButtonClicked.pipe(
            takeUntil(this._destroyed)
        ).subscribe((event) => {
            switch (this.formStep) {
                case MfaFormStepEnum.STEP_ONE:
                    this._sidebarService.goBack();
                    break;
                case MfaFormStepEnum.STEP_TWO:
                    this.previousStep();
                    break;
                case MfaFormStepEnum.STEP_THREE:
                    this.closeForm();
                    break;
                default:
                    break;
            }
        });
    }

    ngOnDestroy() {
        this._destroyed.next(true);
    }


    public createPhoneForm() {
        this.mfaPhoneForm = this._formBuilder.group({
            phone: new FormControl('', [Validators.required, Validators.pattern(this.textMaskRegEx)]),
        });
    }

    public createOtpForm() {
        const otpPattern = /^[0-9]{6}$/;
        this.mfaOtpForm = this._formBuilder.group({
            mfaOtpCode: new FormControl('', [Validators.required, Validators.pattern(otpPattern)]),
        });
    }

    @HostListener('document:keydown', ['$event']) protected handleEnter(event: KeyboardEvent) {
        if (event.code === enumKeyboardEventCodes.ENTER || event.keyCode === 13) {
            if (this.formStep === MfaFormStepEnum.STEP_ONE) {
                this.nextStep();
            } else if (this.formStep === MfaFormStepEnum.STEP_TWO) {
                this.submit();
            }
        }
    }

    public stepsConfig: IMfaStepsConfig[] = [
        {
            stepClass: 'is-current is-complete',
            ariaLabel: 'You are on step one of three, phone number or email address',
            stepLabel: 'method',
        },
        {
            stepClass: 'is-current is-complete',
            ariaLabel: 'Next step of three, otp code',
            stepLabel: 'confirm',
        },
        {
            stepClass: 'is-current is-complete',
            ariaLabel: 'Final step of three, complete',
            stepLabel: 'complete',
        }
    ];

    public nextStep(): void {
        if (this.formStep < this.stepsConfig.length - 1) {
            this.clearErrors();
            if (this.formStep === MfaFormStepEnum.STEP_ONE) {
                this.requestOtp();
            }
        }
    }

    public previousStep(): void {
        if (this.formStep > 0) {
            this._resetForms();
            this.clearErrors();
            this.formStep--;
        }
    }

    public requestOtp(): void {
        if (this.mfaPhoneForm.valid && !this.submitting) {
            this.cellPhone = this.mfaPhoneForm.get('phone')?.value;
            this.isLoadingDotsActive = true;
            this._mfaDataService.submitMfaUserInfo(true, this.cellPhone, this.deviceType.SMS, 0).pipe(
                take(1),
                finalize(() => {
                    this.isLoadingDotsActive = false;
                })
            ).subscribe((response: IMfaResponse) => {
                if (response.status === 'ACTIVATION_REQUIRED') {
                    this.mfaAuthorizationId = response.mfaAuthorizationId;
                    this.formStep = MfaFormStepEnum.STEP_TWO;
                    this._paringErrorCount = 0;
                } else {
                    this._handlePairingError();
                }
            }, (error) => {
                if (error) {
                    this._handlePairingError();
                }
            });
        }
    }

    public resendOtpCode() {
      if(!this.showMessage) {
          this.clearErrors();
          if (!this.submitting && this.mfaAuthorizationId) {
              this.submitting = true;
              this.showMessage = true;
              this.message = MfaMessageEnum.CHECK_DEVICE;
              this._mfaDataService.resendPairCode(this.mfaAuthorizationId)
                  .pipe(
                      take(1),
                      finalize(() => {
                          this.submitting = false;
                          setTimeout(() => {
                              this.message = "";
                              this.showMessage = false;
                          }, 15000)
                      })
                  )
                  .subscribe((response: IMfaResponse) => {
                      if (response.status === 'ACTIVATION_REQUIRED') {
                          this.mfaAuthorizationId = response.mfaAuthorizationId;
                      } else {
                          this._handleError(MfaErrorMessageEnum.GENERAL_ERROR);
                          this.showMessage = false;
                          this.message = "";
                      }
                  }, _ => {
                      this._handleError(MfaErrorMessageEnum.GENERAL_ERROR);
                      this.showMessage = false;
                      this.message = "";
                  });
          }
      }
    }

    public submit(): void {
        if (this.mfaOtpForm.valid && !this.submitting) {
            const otpInputVal = this.mfaOtpForm.get('mfaOtpCode')?.value;
            this.submitting = true;
            this.isLoadingDotsActive = true;
            this._mfaDataService.enableMfaForUser(this.mfaAuthorizationId, otpInputVal).pipe(
                take(1),
                finalize(() => {
                    this.submitting = false;
                    this.isLoadingDotsActive = false;
                })
            ).subscribe((response: IMfaResponse) => {
                if (response.status === this.mfaResponseEnum.SUCCESS) {
                    this.cellPhone = this._mfaBusinessService.formatPhoneNum(this.cellPhone);
                    this.formStep = MfaFormStepEnum.STEP_THREE;
                } else {
                    const errorStatus = response.status === this.mfaResponseEnum.WRONG_OTP ? MfaErrorMessageEnum.INVALID_CODE : MfaErrorMessageEnum.GENERAL_ERROR
                    this._handleError(errorStatus);
                    this.formStep = MfaFormStepEnum.STEP_TWO;
                }
            }, (error) => {
                if (error) {
                    this._handleError(MfaErrorMessageEnum.GENERAL_ERROR);
                    this.formStep = MfaFormStepEnum.STEP_TWO;
                }
            });
        }
    }

    public closeForm(): void {
        this.onMfaFormClose.emit();
    }

    public clearErrors() {
        this.errorMessage = "";
        this.hasErrors = false;
    }

    public checkInputOnBlur() {
        const inputValue = this.mfaOtpForm.get('mfaOtpCode').value;
        if (inputValue.length > 0 && inputValue.length < 6) {
            this._handleError(MfaErrorMessageEnum.INVALID_CODE);
        } else if (inputValue.length === 0) {
            this._handleError(MfaErrorMessageEnum.MISSING_CODE);
        } else {
            this.clearErrors();
        }
    }

    private _handlePairingError() {
        this._handleError(this._paringErrorCount < this.MAX_ATTEMPTS
            ? MfaErrorMessageEnum.PAIRING_ERROR : MfaErrorMessageEnum.PAIRING_ERROR_MAX_TRIES);
        this._paringErrorCount++;
    }

    private _handleError(errorMessage: string) {
        this.errorMessage = errorMessage;
        this.hasErrors = true;
    }

    private _resetForms() {
        this.mfaPhoneForm.reset();
        this.mfaOtpForm.reset();
    }
}
