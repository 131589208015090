import { Observable } from 'rxjs';
import { finalize, scan, takeUntil } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { WagerEventTypeEnum } from '../enums/wager-event-type.enum';
import { WagerEvent } from '../interfaces/wager-event.interface';

export class WageringEventAccumulator extends CduxRxJSBuildingBlock<WagerEvent, Map<WagerEventTypeEnum, WagerEvent>> {

    protected _stream: Observable<Map<WagerEventTypeEnum, WagerEvent>>;

    /** CONTROLS **/

    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _source: Observable<WagerEvent>
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = this._source
            .pipe(
                this._accumulateEvents(),
                finalize(() => this.kill()),
                takeUntil(this._kill),
                // share()
            ) as Observable<Map<WagerEventTypeEnum, WagerEvent>>;
    }

    /** CUSTOM OPERATORS **/
    private _accumulateEvents() {
        return scan((acc, event: WagerEvent) => {
            const latestEventMap = new Map<WagerEventTypeEnum, WagerEvent>();
            if (acc) {
                // Only keep the events that can, but haven't been, invalidated.
                acc.forEach((v, k) => {
                    if (v.invalidatedBy && v.invalidatedBy.indexOf(event.type) === -1) {
                        latestEventMap.set(k, v);
                    }
                });
            }
            latestEventMap.set(event.type, event);
            return latestEventMap;
        }, null);
    }
    /** END CUSTOM OPERATORS **/
}
