import { Directive, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';

import { AFFIDS, ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { FundingAbstractMethodDepositComponent } from '../abstract-method-deposit.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FundingService } from '../../../shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { HeaderService } from 'app/shared/header/services/header.service';

@Directive()
export abstract class AbstractGreendotATRDepositComponent extends FundingAbstractMethodDepositComponent implements OnInit {

    protected abstract isFullPage: boolean;

    @ViewChild('greenDotForm') greenDotFormEl;

    public affiliateId: AFFIDS = this.environment.affiliateId;
    public displayError: boolean = false;
    public formUrl: string;
    public requestToken: string;
    public returnUrl: string;
    public partnerId: string;

    protected constructor(
        protected _router: Router,
        localEventService: EventTrackingService,
        protected environment: ENVIRONMENT,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService
    ) {
        super(
            _router,
            localHeaderService,
            environment,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localEventService,
            localTranslateService,
            localSessionService,
            localToggleService
        );
    }

    public abstract ngOnInit ();

    public get hasVerifiedAccount(): boolean {
        return !!this.fundingMethodDetails.accountInfo &&
            this.fundingMethodDetails.accountInfo.trim() !== '';
    }
}
