import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { ViewSectionEnum } from '../enums/view-section.enum';
import { WageringViewEnum } from '../enums/wagering-view.enum';
import { ITrackBasic, StringSlugifyPipe } from '@cdux/ng-common';

enum RouteSegments {
    VIEW,
    SECTION,
    TRACK,
    RACE
}

/**
 * This is in charge of composing a list of route segments that are used to compose the
 * URL that matches the state of the wagering interface.
 *
 * The current route does not necessarily match the currently displayed View. For example,
 * if you use a deeplink to VIDEO on your mobile sized browser, it will display the MOBILE View
 * without changing the route, thus ensuring that when the screen is expanded the user will see
 * the VIDEO View.
 */
export class RouteSyncManager extends CduxRxJSBuildingBlock<string, string[]> {

    protected _stream: Observable<string[]>;

    /** CONTROLS **/
    private _viewChanges: Subject<string> = new Subject<string>();
    private _sectionChanges: Subject<string> = new Subject<string>();
    private _trackChanges: Subject<ITrackBasic> = new Subject<ITrackBasic>();
    private _raceChanges: Subject<string> = new Subject<string>();
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
    ) {
        super();
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public updateView(view: WageringViewEnum) {
        this._viewChanges.next(view);
    }
    public updateSection(section: ViewSectionEnum) {
        this._sectionChanges.next(section);
    }
    public updateTrack(track: ITrackBasic) {
        this._trackChanges.next(track);
    }
    public updateRace(race: string) {
        this._raceChanges.next(race);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = combineLatest([
                this._viewChanges,
                this._sectionChanges,
                this._trackChanges,
                this._raceChanges
            ])
            .pipe(
                this._excludeMidChangeEmissions(),
                this._composeRouteSegments(),
                finalize(() => this.kill()),
                takeUntil(this._kill),
            );
    }

    /** CUSTOM OPERATORS **/
    /**
     * Checks for any lacking route segments, thus avoiding making a malformed URL.
     *
     * @private
     */
    private _excludeMidChangeEmissions() {
        return filter((segments: any[]) => segments.reduce((p: boolean, c: any) => p && !!c, true));
    }

    /**
     * Takes the current state of the wagering interface and turns it into a list of route segments
     * that can be used to update the url so that it matches the interface's state.
     *
     * @private
     */
    private _composeRouteSegments() {
        return map((segments: any[]) => {
            const track = segments[RouteSegments.TRACK] as ITrackBasic;
            const slugifier = new StringSlugifyPipe();
            return [
                segments[RouteSegments.VIEW],
                slugifier.transform(track.DisplayName),
                track.BrisCode,
                track.TrackType,
                segments[RouteSegments.RACE],
                segments[RouteSegments.SECTION],
            ]
        });
    }
    /** END CUSTOM OPERATORS **/
}
