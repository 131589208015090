export const LANG_MY_ACCOUNT_NAME = 'my-account';

export const LANG_MY_ACCOUNT_TRANS = {
    'cashier-subtitle': 'Manage your account balance',
    'cashier-title': 'My Funds',
    'dah-subtitle': 'Searchable record of your activity',
    'dah-title': 'Detailed Account History',
    'daily-history-subtitle': 'Wagers and transactions by day',
    'daily-history-title': 'Daily Account History',
    'history-subtitle': 'All wagers and transactions',
    'history-title': 'Account History',
    'manage-subtitle': 'Manage your phone number, email, password and PIN',
    'manage-subtitle-no-pin': 'Manage your phone number, email and password.',
    'manage-title': 'My Account',
    'preferences-subtitle': 'Manage your site preferences',
    'preferences-title': 'Preferences',
    'rewards-max-error': 'You do not have enough points in your account.',
    'rewards-minimum': 'Min %s',
    'rewards-multiple-error': 'You must redeem points in multiples of %s.',
    'rewards-pin-mismatch': 'TSC Elite points are currently unable to be redeemed on your account. Please contact Player Services at %s to update your account settings.',
    'rewards-redeem': 'Redeem Points',
    'rewards-redemption-error': 'We are experiencing technical difficulties. Please try again later or call Player Services at %s',
    'rewards-redemption-success': 'You have redeemed $%s. These funds will be added to your account balance',
    'rewards-subtitle': 'Redeem your points for cash',
    'rewards-title': 'Rewards',
    'rtb-subtitle': '',
    'rtb-title': 'Recall Today\'s Bets',
    'signout-subtitle': 'Sign out of your %s account',
    'signout-title': 'Sign Out',
    'stable-alerts-subtitle': 'Manage your stable of thoroughbred favorites',
    'stable-alerts-title': 'Stable Alerts',
    'w2g-subtitle': 'Download your W-2G reports',
    'w2g-title': 'Tax Documents',

    'error-password-reuse-search-str': 'Error. Cannot reuse the current or any of the previous passwords.',
    'error-password-reuse-display': 'New password cannot be a previously-used password.',
    'error-password-invalid-search-str': 'Error. Password does not meet requirements',
    'error-password-invalid-display': 'Password does not meet requirements.',
    'error-reset-password-invalid-search-str': 'Error. Invalid password.',
    'error-generic': 'We were unable to update your account. Please try again or contact Player Services at %s.',
    'error-generic-chat': 'We were unable to update your account. Please try again or %s'
};
