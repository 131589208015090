import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CduxMediaModule } from '@cdux/ng-platform/web';

import { TuxCommonModule } from '../common/common.module';
import { HeaderModule } from '../header/header.module';

import { LayoutHeaderFooterComponent } from './components/layout-header-footer/layout-header-footer.component';
import { LayoutMainComponent } from './components/layout-main/layout-main.component';
import { LayoutMainSidebarComponent } from './components/layout-main-sidebar/layout-main-sidebar.component';
import { LayoutShellComponent } from './components/layout-shell/layout-shell.component';

import { LayoutMainSidebarWrapperDirective } from './directives/layout-main-sidebar.directives';

import { SidebarModule } from '../sidebar/sidebar.module';
import { EventSessionModule } from '@cdux/ng-common';
import { MenuItemsModule } from '../menu-items/menu-items.module';
import { FooterModule } from '../footer/footer.module';
import { LayoutHeaderComponent } from './components/layout-header/layout-header.component';
import { FullScreenDrawerModule } from '../full-screen-drawer/full-screen-drawer.module';
import { TournamentsSessionnModule } from '../tournaments-session/touranments-session.module';

@NgModule({
    declarations: [
        LayoutHeaderComponent,
        LayoutHeaderFooterComponent,
        LayoutMainComponent,
        LayoutMainSidebarComponent,
        LayoutShellComponent,
        LayoutMainSidebarWrapperDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        CduxMediaModule,
        TuxCommonModule,
        SidebarModule,
        EventSessionModule,
        HeaderModule,
        FooterModule,
        MenuItemsModule,
        FullScreenDrawerModule,
        TournamentsSessionnModule
    ],
    exports: [
        SidebarModule,
        HeaderModule,
        LayoutHeaderComponent,
        LayoutHeaderFooterComponent,
        LayoutMainComponent,
        LayoutMainSidebarComponent,
        LayoutShellComponent,
        LayoutMainSidebarWrapperDirective,
    ]
})
export class LayoutModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<LayoutModule> {
        return {
            ngModule: LayoutModule,
            providers: [

            ],
        }
    }
}
