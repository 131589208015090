<nav class="program-nav combined-views" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE"
    [class.has-content-nav]="hasContentNav">
    <nav class="program-view-tabs">
        <ul>
            <li id="program" [class.has-content-nav]="isSection(viewSectionProgramGroup)"
                (click)="!isSection(viewSectionProgramGroup) && selectSection(viewStateGroupEnum.PROGRAM)">PROGRAM</li>
            <li id="pools" [class.has-content-nav]="isSection(viewSectionPoolsGroup)"
                (click)="!isSection(viewSectionPoolsGroup) && selectSection(viewStateGroupEnum.POOLS)">POOLS</li>
            <li *ngIf="showPPs" id="past-performances" [class.is-selected]="isSection(viewSectionEnum.PAST_PERFORMANCES)"
                (click)="!isSection(viewSectionEnum.PAST_PERFORMANCES) && selectSection(viewSectionEnum.PAST_PERFORMANCES)">PPs</li>
            <li *ngIf="isStatsEnabled" id="stats" [class.has-content-nav]="isSection(viewSectionStatsGroup)"
                (click)="!isSection(viewSectionStatsGroup) && selectSection(viewStateGroupEnum.STATS)">STATS</li>
            <li id="results" [class.has-content-nav]="isSection(viewSectionResultsGroup)"
                (click)="!isSection(viewSectionResultsGroup) && selectSection(viewStateGroupEnum.RESULTS)">RESULTS</li>
            <li id="video" *ngIf="isModeEnabled" (click)="openVideo()" class="popout-icon">VIDEO</li>
            <li id="betpad" *ngIf="displayBetPad && isRaceWagerable" (click)="openBetPad()" class="popout-icon" [cduxLogClick]="eventClickType.BET_PAD_BUTTON">BET PAD</li>
        </ul>
    </nav>
</nav>
