<div class="funding-inner-wrap custom-amount"  [class.has-messages] = "hasError && !inputFocused">
    <div class="modal-header" *cduxMediaToggle="'desk-to-phone'">
        <h2>Enter Amount</h2>
        <!--Close button-->
        <div class="close-btn-wrap" (click)="close()">
            <i class="icon--close dark" title="Click to close box or cancel" [cduxLogClick]="eventClickType.BET_CUSTOM_AMOUNT_CLOSE"></i>
        </div>
    </div>

    <p class="center messages" *ngIf="!inputFocused">{{ errorMessage }}</p>

    <div class="form-wrap">
        <div class="form-label">
            <label class="prefix">
                $
            </label>
            <input #customAmount type="number" id="bet_custom_amount_text" class="deposit--amount amount-entered" [(ngModel)]="value" (focus)="onFocus()" (blur)="onBlur()" />
            <!--onchange="hasValue(this)"-->
        </div>
    </div>

    <div class="submitBtnWrapper">
        <button  type="submit" class="submit--btn" id = "bet_custom_amount_submit" [class.is-disabled] = "hasError || innerValue === '' " (click)="updateBetAmount()">Okay</button>
    </div>

</div>
