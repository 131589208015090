import { LANG_MY_ACCOUNT_NAME, LANG_MY_ACCOUNT_TRANS } from './my-account';
import { LANG_MY_ACCOUNT_6200_NAME, LANG_MY_ACCOUNT_6200_TRANS } from './my-account-6200';
import { LANG_MY_ACCOUNT_PREFERENCES_NAME, LANG_MY_ACCOUNT_PREFERENCES_TRANS } from './preferences';

export const MY_ACCOUNT_LANGUAGE_DICTIONARY = {
    [LANG_MY_ACCOUNT_NAME]: LANG_MY_ACCOUNT_TRANS,
    [LANG_MY_ACCOUNT_6200_NAME]: LANG_MY_ACCOUNT_6200_TRANS,
};

export const MY_ACCOUNT_PREFERENCES_LANGUAGE_DICTIONARY = {
    [LANG_MY_ACCOUNT_PREFERENCES_NAME]: LANG_MY_ACCOUNT_PREFERENCES_TRANS
};
