import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { RestrictFloat } from '../../../common/utils';

@Directive({
    selector: '[formControlName][cduxCurrency],[ngModel][cduxCurrency]'
})
export class FundingCurrencyDirective {
    @Input()
    public showSymbol: boolean = true;

  constructor( private _control: NgControl ) {
    // Empty
  }

  @HostListener('keypress', ['$event']) public onKeypress(event: KeyboardEvent) {
    if (event.which === 8) {
      // Empty
    } else if (!RestrictFloat(event, this._control.value, 2)) {
      event.preventDefault();
    }
  }
  @HostListener('change') public onChange() {
    this._reformatCurrency();
  }
  @HostListener('focus') public onFocus() {
    this._reformatCurrency();
  }
  @HostListener('blur') public onBlur() {
    this._reformatCurrency(true);
  }

  private _reformatCurrency(local: boolean = false) {
    const value = this._control.value;
    if (!value) {
      return;
    }
    const strValue = value.replace(/\$/, '');
    const floatValue = parseFloat(strValue) ? parseFloat(strValue) : 0;
    if (local) {
      this._formatLocalCurrency(floatValue);
    } else {
      this._formatCurrency(floatValue);
    }
  }

  private _formatCurrency(value: number) {
    let writeValue: string = value.toString();
    if (!this._isInt(value)) {
      writeValue = value.toFixed(2);
    }

    this._control.valueAccessor.writeValue((this.showSymbol ? '$' : '') + writeValue);
  }

  private _formatLocalCurrency(value: number) {
    let writeValue: string = value.toLocaleString('en-us', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
    if (!this._isInt(value)) {
      writeValue = value.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    this._control.valueAccessor.writeValue((this.showSymbol ? '$' : '') + writeValue);
  }

  private _isInt(number: number) {
      return number % 1 === 0;
  }

}
