import { Injectable, OnDestroy } from '@angular/core';
import { JwtSessionService, TournamentsDataService, ToteDataService, IMyEnrollmentsResponse, IMyTournaments, IMyEnrollments, ITournamentAccountInfo  } from '@cdux/ng-common';
import { Cookie } from 'ng2-cookies';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { TournamentsBroadcastChannel } from './tournaments-account-broadcast.service';
import { TournamentsRedirectService } from './tournaments-redirect.service';


export interface ISelectedTournament {
    raceDate: string;
    accountId: number;
    tournament: IMyTournaments;
    enrollment: IMyEnrollments;
}

@Injectable({providedIn: 'root'})
export class TournamentsSessionService implements OnDestroy {
    private subscriptions: Subscription[] = [];
    private raceDate: string = new Date().toISOString().substring(0, 10);
    private startCheckingActiveTournament: Subject<boolean> = new Subject();
    private enableDistinctUntilCheck = true;

    public onTournamentSelection: Subject<ISelectedTournament> = new BehaviorSubject(null);
    public userEnrollmentObs: Observable<IMyEnrollmentsResponse>;

    public static readonly TOURNAMENT_COOKIE = 'TOURNAMENT_ACCOUNT';
    public static readonly CHECK_ACTIVE_TOURNAMNETS = 'checkActiveTournaments'; //Sets to true when login response returns active tournaments flag
    constructor (
        private _sessionService: JwtSessionService,
        private _dataService: TournamentsDataService,
        private _toteDataService: ToteDataService,
        private _broadcast: TournamentsBroadcastChannel,
        private _tournamentsRedirectService: TournamentsRedirectService,
    ) {
        this.userEnrollmentObs =
            combineLatest([
                this._sessionService.onAuthenticationChange.pipe(startWith(false)),
                this._toteDataService.currentRaceDate(true),
                this.startCheckingActiveTournament.pipe(startWith(this.isCheckActiveTournamentsEnabled())),
            ]).pipe(
                tap((res) => {
                    this.raceDate = res[1];
                    this._validateTournamentCookie();
                    this.emitCurrentTournamentState();
                }),
                distinctUntilChanged((prev, curr) => {
                    return this.enableDistinctUntilCheck && (JSON.stringify(prev) === JSON.stringify(curr));
                }),
                tap((_) => {
                    this.enableDistinctUntilCheck = true;
                }),
                switchMap(([_, raceDate]) => {
                    return this.isCheckActiveTournamentsEnabled() && this._sessionService.isLoggedIn() ? this._dataService.getEnrollmentsAuthenticated(raceDate).pipe(catchError(err => of(null))) : of(null)
                })
            ).pipe(shareReplay(1));

        this.subscriptions = [
            // if a user logout, delete the active tournament cookie and check status
            this._sessionService.onAuthenticationChange.subscribe((status) => {
                if (status === false) {
                    this.disableCheckingActiveTournaments();
                    this.setPrimarySiteMode(); //this will delete tournament cookie
                }
            }),

            this._broadcast.stateChangeBroadcastListener
                .pipe(
                    tap((_) => {
                        this.emitCurrentTournamentState();
                    }),
                    filter((tournament) => tournament !== null)
                )
                .subscribe((tournament: ISelectedTournament) => {
                    this._tournamentsRedirectService.goToTournamentProgramPage(tournament?.tournament?.idTournament, true);
                })
        ];
     }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this._broadcast.closeBroadcast();
    }

    public setTournamentMode(enrollment: IMyEnrollments, tournament: IMyTournaments, raceDate: string): Observable<boolean> {
        return this._dataService.getTournamentAccountInfo(enrollment?.idTournamentEnrollment)
            .pipe(
                take(1),
                catchError(err => of(false)),
                tap((accountInfo: ITournamentAccountInfo) => {
                    if (accountInfo && "accountId" in accountInfo) {
                        const cookieValue: ISelectedTournament = { accountId: accountInfo.accountId, tournament, enrollment, raceDate };
                        this.setSelectedTournament(cookieValue);
                        this._tournamentsRedirectService.goToTournamentProgramPage(tournament?.idTournament);
                    }
                }),
            map ((accountInfo: ITournamentAccountInfo) => accountInfo && "accountId" in accountInfo));
    }

    public setPrimarySiteMode(){
        Cookie.delete(TournamentsSessionService.TOURNAMENT_COOKIE, '/', null);
        this.emitCurrentTournamentState();
        this._broadcast.sendMessage(null);
        return TournamentsSessionService.getSelectedTournament();
    }

    public static getSelectedTournament(): ISelectedTournament | null {
        if (!Cookie.check(TournamentsSessionService.TOURNAMENT_COOKIE)){
            return null;
        }
        return JSON.parse(Cookie.get(TournamentsSessionService.TOURNAMENT_COOKIE)) as ISelectedTournament;
    }

    public getSelectedTournament(): ISelectedTournament | null {
        return TournamentsSessionService.getSelectedTournament();
    }

    // This function accomplishes two things.
    // 1. It sets a flag in session storage to check active tournament in case tux is reloaded
    // 2. It triggers a flag to check active tournament
    public enableCheckingActiveTournaments() {
        localStorage.setItem(TournamentsSessionService.CHECK_ACTIVE_TOURNAMNETS, 'true');
        this.startCheckingActiveTournament.next(true);
    }

    // 3. Forces userEnrollmentObs to check the BE by disabling distinctUntilChanges (Usesful when we want to check the BE upon enrollment)
    public triggerActiveTournamentsCheck() {
        this.enableDistinctUntilCheck = false;
        this.enableCheckingActiveTournaments();
    }

    public disableCheckingActiveTournaments() {
        localStorage.removeItem(TournamentsSessionService.CHECK_ACTIVE_TOURNAMNETS);
        this.startCheckingActiveTournament.next(false);
    }

    public isCheckActiveTournamentsEnabled(): boolean {
        const item = localStorage.getItem(TournamentsSessionService.CHECK_ACTIVE_TOURNAMNETS);
        return item && item === 'true' ? true : false;
    }

    public returnToPreviosScreen() {
        this._tournamentsRedirectService.returnToPreviosScreen();
    }

    public goToTodaysTrackPage() {
        this._tournamentsRedirectService.goToTodaysTrackPage();
    }

    public isTournamentSelected(): boolean {
        return this.getSelectedTournament() !== null;
    }

    public setSelectedTournament(tournament: ISelectedTournament | null): void {
        if (tournament) {
            Cookie.set(TournamentsSessionService.TOURNAMENT_COOKIE, JSON.stringify(tournament), null, '/', null, false);
        } else {
            Cookie.delete(TournamentsSessionService.TOURNAMENT_COOKIE, '/', null);
        }
        this.onTournamentSelection.next(tournament);
        this._broadcast.sendMessage(tournament);
    }

    public isTournamentSelectedObservable(): Observable<boolean> {
        return this.onTournamentSelection.pipe(
            map(tournament => tournament !== null)
        );
    }

    public emitCurrentTournamentState() {
        if (this._sessionService.isLoggedIn()) {
            this.onTournamentSelection.next(TournamentsSessionService.getSelectedTournament());
        } else {
            this.onTournamentSelection.next(null);
        }
    }

    private _validateTournamentCookie() {
        const currentCookie = this.getSelectedTournament();
        if (currentCookie !== null && currentCookie.raceDate !== this.raceDate) {
            Cookie.delete(TournamentsSessionService.TOURNAMENT_COOKIE, '/', null);
        }
    }
}
