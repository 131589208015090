<!-- TODO: couldn't get "error | async" to work -->
<ng-container *ngIf="errorCode !== null; else depositMain">
  <div id="paypal-lockout-error-txt" class="ts-message error" *ngIf="lockout">
      <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
    <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
  </div>
  <ng-template #chat><cdux-chat></cdux-chat></ng-template>

  <div class="error-icon" *ngIf="!lockout">
    <i class="icon-exclamation-error-large"></i>
  </div>
  <h2>PayPal is unavailable</h2>
  <ng-container *ngIf="phoneEnabled else paypalUnavailableChat">
    <p>Please select another payment method or contact player services at:</p>
    <p>{{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</p>
  </ng-container>
  <ng-template #paypalUnavailableChat>
    <p>Please select another payment method or <cdux-chat></cdux-chat>.</p>
  </ng-template>

  <div class="try-again--btn" (click)="clearError()">Try Again</div>
</ng-container>

<ng-template #depositMain>
  <ng-container *ngIf="!fundingMethodDetails.locked">
    <header class="funding-content_header">
      <!-- back arrow hidden on desktop -->
      <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
      <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="deposit-close-btn"></i>
      <h1>PayPal</h1>
      <div class="funding-type-icon">
        <i class="icon-paypal"></i>
      </div>
    </header>
    <form [formGroup]="form">
      <div class="funding-content_input-block">

        <cdux-funding-input-amount
          id="depositAmount"
          formControlName="amount"
          [inputType]="OPERATION"
          [fundType]="fundingMethodDetails.fundType"
          [min]="getMinDepositAmount(hasVerifiedAccount)"
          [max]="getMaxDepositAmount()"
          [amount]="amount"
          [offerId]="offerId"
          [phoneEnabled]="phoneEnabled"
          [displayStyle]="enumDepositDisplayStyle.FULL_PAGE">
        </cdux-funding-input-amount>

      </div>

      <div class="funding-content_details-block">
        <!--
        THE ELEMENTS IN THE 'DETAILS-BLOCK' ARE VARIABLE AMONGST
        DEPOSIT METHODS. EVERYTHING ELSE ABOUT THE HTML IS THE SAME
        -->
        <p *ngIf="!fundingMethodDetails.accountInfo">You will be taken to PayPal to login.</p>
        <p *ngIf="fundingMethodDetails.accountInfo">PayPal Account:</p>

        <p class="email">{{ fundingMethodDetails.accountInfo }}</p>

        <div class="amount--deposit">
          <span class="label">Deposit:</span>
          <span class="amount">{{ form.get('amount').value | currency:'USD':'symbol-narrow':'1.2' }}</span>
        </div>
        <div class="amount--fee">
          <span class="label">PayPal Fee:</span>
          <span class="amount">{{ fundingMethodDetails.flatFee | currency:'USD':'symbol-narrow':'1.2' }}</span>
        </div>
        <div class="total">
          <span class="label">Total:</span>
          <span class="amount">{{ form.get('amount').value + fundingMethodDetails.flatFee | currency:'USD':'symbol-narrow':'1.2' }}</span>
        </div>

        <div class="paypal--btn large" (click)="onDeposit()" [class.disabled]="form.invalid || depositing"><i class="icon-paypal-btn"></i></div>
        <div class="withdraw--tandc" *ngIf="!!depositDisclaimer">{{depositDisclaimer}}</div>
      </div>
    </form>
  </ng-container>
</ng-template>
