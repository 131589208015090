import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { startWith, first } from 'rxjs/operators';

import { coerceToNumber } from '../../../../common/utils';

import { CurrencyPipe } from '@angular/common';
import { ENVIRONMENT } from '@cdux/ng-core';
import { FUNDING_OPERATIONS } from '@cdux/ng-common';
import { InputErrorDirective } from '@cdux/ng-platform/web';
import { FundingService } from '../../services/funding.service';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';

const noop = () => {
};

const PREFIX = 'cdux-funding-input-amount';
const NUMBER_OF_SUGGESTIONS_SIDEBAR = 4;
const NUMBER_OF_SUGGESTIONS_FULLPAGE = 5;

let NEXT_UNIQUE_ID = 0;

@Component({
    selector: 'cdux-funding-input-amount',
    templateUrl: './input-amount.component.html',
    styleUrls: ['./input-amount.component.scss']
})
export class FundingInputAmountComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

    public affId = this._environment.affiliateId;

    public operationName: string;

    private _min: number;
    private _max: number;

    // Minimum
    public get min(): number {
        return this._min;
    }

    @Input()
    public set min(value: number) {
        if (!this.max || value <= this.max) {
            this._min = value;
        } else {
            this._min = 0;
            this._max = 0;
        }
    }

    @Input() public displayMin = true;


    // Maximum
    public get max(): number {
        return this._max;
    }

    @Input()
    public set max(value: number) {
        if (!this.min || value >= this.min) {
            this._max = value;
        } else {
            this._min = 0;
            this._max = 0;
        }
    }

    // Collection of suggestions to be displayed
    @Input() public suggestions: number[];

    @Input() public amount: number;

    @Input() public offerId: number;

    @Input() public displayMax = true;

    @Input() public inputType: FUNDING_OPERATIONS;

    @Input() public fundType: string;

    @Input()
    public displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR;

    @Input()
    set phoneEnabled(val: boolean) {
        this._phoneEnabled = val;
    }

    get phoneEnabled(): boolean {
        return this._phoneEnabled;
    }

    @Input()
    set id(value: string) {
        value = PREFIX + value;
        if (this._id !== value) {
            this._id = value;
        }
    }

    get id(): string {
        return this._id;
    }

    set value(value: string) {
        const floatValue = parseFloat(value.replace(/\$/, ''));
        const newValue = floatValue ? floatValue : 0;
        if (newValue !== this._innerValue) {
            this._innerValue = newValue;
            this._onChangeCallback(this._innerValue);
        }
        this._changeDetectorRef.detectChanges();
    }

    get value(): string {
        return (this.displayStyle === enumFundingDisplayStyle.SIDEBAR ? '$' + this.innerValue : this.innerValue);
    }

    get innerValue(): string {
        return (this._innerValue && this._innerValue.toString) ? this._innerValue.toString() : '';
    }

    // Determines which error messages appear for the input
    public coerceToNumber = coerceToNumber;

    // Is the input currently focused?
    public inputFocused = false;

    // Has the input been focused yet?
    public inputTouched = false;

    public enumDepositDisplayStyle = enumFundingDisplayStyle;

    // Focusable HTML Element
    @ViewChild('inputBlock') private _inputBlock: ElementRef;

    // Collection of Error messages
    @ViewChildren(InputErrorDirective) private _errorChildren: QueryList<InputErrorDirective>;

    // Collection of Subscriptions to be cleaned
    private _subscriptions: Subscription[] = [];

    // The value held by the component
    private _innerValue: string | number = 0;

    // The ID of the Component
    private _id = `${PREFIX}-${NEXT_UNIQUE_ID++}`;

    // phone support defaults to true
    private _phoneEnabled: boolean = true;

    // ControlValueAccessor Callback Functions
    private _onTouchedCallback: () => void = noop;
    private _onChangeCallback: (_: any) => void = noop;
    private _destroy: Subject<boolean> = new Subject();

    constructor(
        private _environment: ENVIRONMENT,
        private _fundingService: FundingService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _control: NgControl,
        private _currencyPipe: CurrencyPipe
    ) {
        if (_control) {
            _control.valueAccessor = this;
        }
    }

    public ngOnInit() {
        switch (this.inputType) {
            case FUNDING_OPERATIONS.DEPOSIT:
                this.operationName = FUNDING_OPERATIONS.DEPOSIT;
                const floatValue = parseFloat(this.value.replace(/\$/, '')) || 0;
                if (!this.suggestions) {
                    const numberOfSuggestions = this.displayStyle === enumFundingDisplayStyle.FULL_PAGE ? NUMBER_OF_SUGGESTIONS_FULLPAGE : NUMBER_OF_SUGGESTIONS_SIDEBAR;
                    this._fundingService.getSuggestions(floatValue, numberOfSuggestions).pipe(
                        first()
                    ).subscribe(
                        (suggestions: number[]) => this.suggestions = suggestions
                    );
                }
                break;
            case FUNDING_OPERATIONS.WITHDRAW:
                // We can't use FUNDING_OPERATIONS.WITHDRAW because the value is 'withdraw' and
                // the operationName needs the noun, not the verb.
                this.operationName = 'withdrawal';
                break;
            default:
                this.operationName = 'value';
                break;
        }
    }

    public ngAfterViewInit() {
        this.focus()
        this._subscriptions.push(
            this._errorChildren.changes.pipe(
                startWith(null)
            ).subscribe(() => {
                this._changeDetectorRef.detectChanges();
            })
        );
    }

    public ngOnDestroy() {
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this._destroy.next();
        this._destroy.complete();
    }

    /**
    * Updates the value when a suggestion is clicked
    * @param suggestion - Suggestion to be checked against
    */
    public onSuggestionClick(suggestion: number) {
        this.value = suggestion.toString();
    }

    /**
    * Returns whether a suggestion is active
    * @param suggestion - Suggestion to be checked against
    */
    public isSuggestionSelected(suggestion: number) {
        return (suggestion.toString() === this.innerValue);
    }

    public hasError(error: string) {
        if (!this._control) {
            return false;
        }
        return this._control.hasError(error);
    }

    /**
     * Method for Focusing the Input Element
     */
    public focus() {
        if (this._inputBlock) {
            this._inputBlock.nativeElement.focus();
        }
    }


    public onFocus() {
        this.inputFocused = true;
        this.inputTouched = true;
    }

    /**
     * Implemented as part of ControlValueAccessor
     */
    public onBlur(): void {
        this.inputFocused = false;
        this._onTouchedCallback();
        this._changeDetectorRef.detectChanges();
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param value - Value to be set
     */
    public writeValue(value: any) {
        if (value !== this._innerValue) {
            const floatValue = parseFloat(value) ? parseFloat(value) : 0;
            if (floatValue % 1 === 0) {
                this._innerValue = value;
            } else {
                this._innerValue = this._currencyPipe.transform(floatValue, 'USD', 'symbol-narrow').replace(/\$/, '');
            }
        }
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param fn - Function to be called after change
     */
    public registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param fn - Function to be called after touch
     */
    public registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }
}
