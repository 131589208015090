import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import { IViewableWagerData } from 'app/shared/betpad/interfaces/viewable-wager-data.interface';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumTrackType,
    ITodaysRace,
    ITrackBasic,
    TracksDataService
} from '@cdux/ng-common';
import { RaceDetailsRequestHandler } from 'app/shared/betpad/classes/race-details-request-handler.class';

const HARNESS_DATA_CONFIG_KEY = 'HARNESS_DATA';

export class ViewableWagerDataHandler extends CduxRxJSBuildingBlock<any, IViewableWagerData> {
    protected _stream: Observable<IViewableWagerData>;

    // TODO - this will only cache the results for this instance of the handler
    private _configCache = {};

    private _raceNavigationChanges: ReplaySubject<ITrackBasic> = new ReplaySubject<ITrackBasic>(1);
    private _raceDetailsRequestHandler: RaceDetailsRequestHandler;

    constructor(
        tracksDataService: TracksDataService,
        private _configurationService: ConfigurationDataService
    ) {
        super();

        this._raceDetailsRequestHandler = new RaceDetailsRequestHandler(tracksDataService);

        this._init();
    }

    public kill() {
        super.kill();
        this._raceDetailsRequestHandler.kill();
        this._raceNavigationChanges.complete();
    }

    protected _init() {
        this._stream = combineLatest([
            this._raceNavigationChanges,
            this._raceDetailsRequestHandler.listen(),
            this._getConfigObs()
        ]).pipe(
            map(([track, raceDetails, config]) => {
                const viewableWagerData = {
                    angles: false,
                    class: false,
                    pace: false,
                    speed: false,
                    runnerStats: false,
                    trainerStats: false,
                    driverStats: false,
                    summary: false,
                };

                if (config) {
                    switch (track.TrackType) {
                        case enumTrackType.TBRED:
                            if (this._isRaceNA(raceDetails)) {
                                Object.keys(viewableWagerData).forEach((v) => viewableWagerData[v] = true);
                            }
                            break;
                        case enumTrackType.HARN:
                            if (this._isRaceNA(raceDetails)) {
                                viewableWagerData.class = true;
                                viewableWagerData.speed = true;
                                viewableWagerData.pace = true;
                                viewableWagerData.runnerStats = true;
                                viewableWagerData.trainerStats = true;
                                viewableWagerData.driverStats = true;
                                viewableWagerData.summary = false;
                            }
                            break;
                        default:
                    }
                }

                return viewableWagerData;

            }),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        );
    }

    private _getConfigObs(): Observable<boolean> {
        return this._raceNavigationChanges.pipe(
            switchMap((track) => {
                let configObs: Observable<any>;
                switch (track.TrackType) {
                    case enumTrackType.HARN:
                        if (this._configCache.hasOwnProperty(HARNESS_DATA_CONFIG_KEY)) {
                            configObs = of(this._configCache[HARNESS_DATA_CONFIG_KEY])
                        } else {
                            configObs = this._configurationService.getConfiguration(enumConfigurationStacks.TUX, HARNESS_DATA_CONFIG_KEY).pipe(
                                map((value) => {
                                    return this._configCache[HARNESS_DATA_CONFIG_KEY] = value && value.hasOwnProperty(HARNESS_DATA_CONFIG_KEY) && typeof value[HARNESS_DATA_CONFIG_KEY] === 'string' && value[HARNESS_DATA_CONFIG_KEY].toLowerCase() === 'true';
                                })
                            )
                        }
                        break;
                    default:
                        configObs = of(true);
                }
                return configObs;
            })
        )

    }

    private _isRaceNA(raceDetails: ITodaysRace): boolean {
        return raceDetails && (raceDetails.country.toLowerCase() === 'usa' || raceDetails.country.toLowerCase() === 'can');
    }

    public updateRaceNavigation(track: ITrackBasic) {
        this._raceDetailsRequestHandler.updateRaceNavigation(track);
        this._raceNavigationChanges.next(track);

    }
}
