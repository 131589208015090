import { Injectable } from '@angular/core';
import {
  CheckDataService,
  ICheckAccountInfo,
  JwtSessionService,
} from '@cdux/ng-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFundingService } from '../interfaces/funding.interfaces';
import { Router } from '@angular/router';

@Injectable()
export class FundingCheckService implements IFundingService {

  private _accountInfo: any;

  constructor(
    private _checkDataService:  CheckDataService,
    private _sessionService: JwtSessionService,
    private _router: Router
  ) {
    // Empty
  }

    private validateLogin() {
        if (!this._sessionService.isLoggedIn()) {
            this._router.navigateByUrl('/login');
        }
    }

  public getAccountInfo(): Observable<ICheckAccountInfo> {
    this.validateLogin();
    return this._checkDataService.requestAccountInfo().pipe(
        map((accountInfo: ICheckAccountInfo) => {
            this._accountInfo = accountInfo;
            return accountInfo;
        })
    );
  }

  /**
   * Withdraws the Specified Amount From the Linked EZMoney Account
   * @param amount - Amount to be Withdrawn
   */
  public withdraw(amount: number | string, deliverymethodcode: string, signature: boolean): Observable<any> {
    this.validateLogin();
    if (typeof amount === 'string') {
      amount = parseFloat(amount);
    }
    return this._checkDataService.withdraw({
      amount,
      address1: this._accountInfo.address.address1,
      address2: this._accountInfo.address.address2,
      phone: this._accountInfo.address.phoneNumber,
      city: this._accountInfo.address.city,
      state: this._accountInfo.address.stateCode,
      zip: this._accountInfo.address.zipCode,
      deliverymethodcode,
      signature
    });
  }
}
