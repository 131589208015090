<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>

<div class="sire-dam-details" *ngIf="showSummary" [class.is-inline]="isInline">
    <!--Sire Details-->
    <div class="details-col">
        <h1>
            {{ sireInfo.sireSummary.horseName }} by {{ sireInfo.sireSummary.sireName }}<br>
            <span>
                {{ sireInfo.sireSummary.color }},
                {{ sireInfo.sireSummary.sexDescription }},
                {{ sireInfo.sireSummary.foaling }}
            </span>
        </h1>
        <div class="label-info-group">
            <div class="label-info">
                <div class="label">BREEDER</div>
                <div class="info">{{ sireInfo.sireSummary.breeder }} ({{ sireInfo.sireSummary.whereBred }})</div>
            </div>
        </div>
    </div>
    <div class="detail-info-full">
        <div *ngIf="showSireRecord" class="stats-container">
          <table>
            <thead>
                    <tr>
                        <td>{{ isPhone ? "RECORD" : "LIFETIME RACE RECORD" }}</td>
                        <td class="align-right">{{ isPhone ? "STR" : "STARTS" }}</td>
                        <td class="align-right">{{ isPhone ? "WN" : "WIN" }}</td>
                        <td class="align-right">{{ isPhone ? "PL" : "PLACE" }}</td>
                        <td class="align-right">{{ isPhone ? "SH" : "SHOW" }}</td>
                        <td class="align-right">Earnings</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>LIFE</td>
                        <td class="align-right lifetimeStart">{{ sireInfo.sireSummary.parsedRaceRecord.starts }}</td>
                        <td class="align-right lifetimeWin">{{ sireInfo.sireSummary.parsedRaceRecord.win }}</td>
                        <td class="align-right lifetimePlace">{{ sireInfo.sireSummary.parsedRaceRecord.place }}</td>
                        <td class="align-right lifetimeShow">{{ sireInfo.sireSummary.parsedRaceRecord.show }}</td>
                        <td class="align-right lifetimeEarnings">{{ sireInfo.sireSummary.parsedRaceRecord.earnings }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="summary-header">
            <span>OFFSPRING SUMMARY</span>
        </div>

        <div class="info-container">
            <div class="table-container">
                <table>
                    <tr>
                        <td>Crops</td>
                        <td class="align-right">{{ sireInfo.sireSummary.summaryCrops | formatHorseDetailsNumber }}</td>
                    </tr>
                    <tr>
                        <td>Foals</td>
                        <td class="align-right">{{ sireInfo.sireSummary.summaryFoals | formatHorseDetailsNumber }}</td>
                    </tr>
                    <tr>
                        <td>Starters</td>
                        <td class="align-right">{{ sireInfo.sireSummary.starters }}</td>
                    </tr>
                    <tr>
                        <td>Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.winners }}</td>
                    </tr>
                    <tr>
                        <td>2YO Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.twoYoWinners }}</td>
                    </tr>
                </table>
            </div>
            <div class="table-container">
                <table>
                    <tr>
                        <td>1st Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.firstWinners }}</td>
                    </tr>
                    <tr>
                        <td>Mud Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.mudWins }}</td>
                    </tr>
                    <tr>
                        <td>Turf Wins</td>
                        <td class="align-right">{{ sireInfo.sireSummary.turfWins }}</td>
                    </tr>
                    <tr>
                        <td>1st Turf</td>
                        <td class="align-right">{{ sireInfo.sireSummary.firstTurf }}</td>
                    </tr>
                    <tr>
                        <td>Turf Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.summaryTurfWinrs | formatHorseDetailsNumber }}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="table-container">
                <table>
                    <tr>
                        <td>AW Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.summaryAwWinrs | formatHorseDetailsNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>Dirt Winners</td>
                        <td class="align-right">{{ sireInfo.sireSummary.summaryDirtWinrs | formatHorseDetailsNumber }}
                        </td>
                    </tr>
                    <tr>
                        <td>SW</td>
                        <td class="align-right">{{ sireInfo.sireSummary.stakesWins }}</td>
                    </tr>
                    <tr>
                        <td>AVG Win Dist</td>
                        <td class="align-right">{{ sireInfo.sireSummary.avgWinDist | formatHorseDetailsNumber }}</td>
                    </tr>
                    <tr>
                        <td>Turf SP</td>
                        <td class="align-right">{{ sireInfo.sireSummary.spi | formatHorseDetailsNumber }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div *ngIf="showOffspring" class="stats-container">
            <table>
                <thead>
                    <tr>
                        <td>Offspring Stats</td>
                        <td class="align-right">STR</td>
                        <td class="align-right">W%</td>
                        <td class="align-right">$2 AVG</td>
                        <td class="align-right">WN</td>
                        <td class="align-right">PL</td>
                        <td class="align-right">SH</td>
                        <td class="align-right">Earnings</td>
                    </tr>
                </thead>
                <tbody>
                    <ng-template ngFor let-offspringStat let-i="index"
                        [ngForOf]="sireInfo.offspringStats | cduxArraySlice:0:offspringStatDisplayLimit">
                        <tr [class]="getRowClass(i) + ' qaa-sire-offspring-row'">
                            <td class="qaa-sire-offspring-category"
                                [ngClass]="offspringStat.avg2ret >= 2 ? 'green-highlight' : ''">{{
                                offspringStat.categoryName }}</td>
                            <td class="align-right qaa-sire-offspring-starts">{{ offspringStat.starts | formatHorseDetailsNumber }}
                            </td>
                            <td class="align-right qaa-sire-offspring-win-percent">{{ offspringStat.winPercentage }}</td>
                            <td class="align-right qaa-sire-offspring-avg-2-ret"
                                [ngClass]="offspringStat.avg2ret >= 2 ? 'green-highlight' : ''">{{ offspringStat.avg2ret
                                | formatHorseDetailsNumber:null:'1.2-2' }}</td>
                            <td class="align-right qaa-sire-offspring-wins">{{ offspringStat.wins }}</td>
                            <td class="align-right qaa-sire-offspring-places">{{ offspringStat.places }}</td>
                            <td class="align-right qaa-sire-offspring-shows">{{ offspringStat.shows }}</td>
                            <td *cduxMediaToggle="'desk-to-phone'" class="align-right qaa-sire-offspring-earnings">{{
                                offspringStat.earningsLongFormat }}</td>
                            <td *cduxMediaToggle="'phone'" class="align-right qaa-sire-offspring-earnings">{{
                                offspringStat.earningsShortFormat }}</td>
                        </tr>
                    </ng-template>
                    <ng-container *ngIf="sireInfo.offspringStats.length > offspringStatDisplayLimit">
                        <ng-container *ngTemplateOutlet="viewLessMore"></ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>

    <div class="details-col" *ngIf="this.loaded && !sireInfo" [class.is-inline]="isInline">
        <h1>No details available.</h1>
    </div>
</div>

<ng-template #viewLessMore>
    <tr>
        <td (click)="toggleShowAllStats()"><a>{{ offspringStatsToggleLabel }}</a></td>
    </tr>
</ng-template>
