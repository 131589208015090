import { Injectable } from '@angular/core';
import { EzbankDataService, TranslateService, UserEventEnum, IEZBankTransactionStatus, IEzbankMazoomaParams } from '@cdux/ng-common';
import { Observable } from 'rxjs';
import { FundingService } from './funding.service';
import { enumDepositOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';

@Injectable()
export class EzbankService {
    // failure code to report EZBank success but Tote Failure
    public static readonly EZBANK_TOTE_FAILURE_CODE = '-4';
    public static readonly unknownErrorCode = 'UNK';

    constructor(
        private _ezbankDataService: EzbankDataService,
        private _eventTrackingService: EventTrackingService,
        private _fundingService: FundingService,
        private _translateService: TranslateService
    ) {}

    public fetchMazoomaParams(depositAmount: string, acccountNum: string, returnUrl: string): Observable<IEzbankMazoomaParams> {
        return this._ezbankDataService.fetchMazoomaParams(depositAmount, acccountNum, returnUrl);
    }

    public getTransactionStatus(orderId: string): Observable<IEZBankTransactionStatus> {
        return this._ezbankDataService.transactionStatus(orderId);
    }

    public storeDepositAmount(orderId: string, depositAmount: number): void {
        if (!!orderId) {
            sessionStorage.setItem(orderId, depositAmount.toString());
        }
    }

    public getStoredDepositAmount(orderId): string {
       return sessionStorage.getItem(orderId);
    }

    public logFailureEvent(errorCode: any, depositAmount: string) {
        const code = 'ezbank-' + errorCode;
        const deposit_failure_event = {
            'firstTimeDeposit': this._fundingService.isFirstTimeDeposit(),
            'depositMethod':  enumDepositOptions.EZ_BANK,
            'depositAmount': depositAmount,
            'failReason': this._translateService.translate(code, 'errorcodes', true),
            'osVersion': this._eventTrackingService.getOsVersion(),
            // balance isn't returned on failed attempts so send most recent value
            'userBalance': this._fundingService.accountBalance,
            'status': 'fail',
            'action': 'complete'
        };
        this._eventTrackingService.logUserEvent(UserEventEnum.DEPOSIT_FAILED, deposit_failure_event);
    }
}
