<div class="keep-picks-option">
        <p>KEEP <br>
        PICKS</p>

        <div class="checkbox remember-me"  [ngClass]="{'is-checked':keepPicks}">
            <input id="rememberPsCompact" type="checkbox" name="check" [checked]="keepPicks" (click)="handleKeepPicksToggle()">
            <label for="rememberPsCompact"></label>
        </div>
</div>

<div class="wrapper" *ngIf="show">
    <div class="bet">
        <div class="bet-details">
            <div *ngIf="showBetDetails && (runnerListConfig || runnerListString)" class="bet-info-expanded">
                <span class="bet-amount" id="bet-amount">{{value | formatBetAmount}}</span>
                <span class="bet-type" id="bet-type">{{poolType?.code}}</span>
                <span class="bet-modifier" id="bet-modifier">{{subtype}}</span>
            </div>
        </div>
        <div class="bet-entries">
            <ng-container *ngIf="runnerListConfig || runnerListString; else selectRunners">
                <ng-container *ngIf="showSaddleCloths; else NoSaddleCloths">
                    <span id="bet-nav-combo-list" class="horses-bet runner-list">
                        <cdux-runner-list class="bets"
                            [inline]="!!compact"
                            [runnerList]="runnerListConfig"
                            [collapseBoxedRunners]="false"
                            [trackType]="trackType"
                            [betType]="betType"
                        ></cdux-runner-list>
                    </span>
                </ng-container>
                <ng-template #NoSaddleCloths>
                    <span id="bet-nav-combo-list" class="horses-bet runner-list bets">{{ runnerListString }}</span>
                </ng-template>
            </ng-container>
            <ng-template #selectRunners>
                <i class="select-label">Select Runners</i>
            </ng-template>
        </div>
    </div>
    <i *ngIf="runnerListConfig || runnerListString" class="icon-bet-total-bar__clear" id="bet-nav-clear-all" (click)="clear()"></i>
</div>
