<div class="bets-container" [class.is-bet-pad]="isBetPad">
    <!--Header-->
    <header class="bets-header">
        <nav class="bets-tabs" *ngIf="savedBetsFeatureToggle">
            <ul>
                <li [class.is-selected]="currentMainTab === MAIN_TABS_ENUM.BET_SLIP"
                    (click)="setView(BETS_VIEW_ENUM.BET_SLIP, eventClickType.BET_SLIP_TABS_BET_SLIP)">
                    Bet Slip
                    <span class="bets-count">{{ betSlipBetCount }}</span>
                </li>
                <li [class.is-selected]="currentMainTab === MAIN_TABS_ENUM.MY_BETS"
                    (click)="setView(BETS_VIEW_ENUM.ACTIVE_BETS, eventClickType.BET_SLIP_TABS_MY_BETS)">My Bets</li>
            </ul>
        </nav>
        <nav class="my-bets-tabs" *ngIf="currentMainTab === MAIN_TABS_ENUM.MY_BETS">
            <ul>
                <li [class.is-selected]="currentMyBetsTab === MY_BETS_TABS_ENUM.ACTIVE"
                    (click)="setView(BETS_VIEW_ENUM.ACTIVE_BETS, eventClickType.BET_SLIP_TABS_MY_BETS)">Active</li>
                <li [class.is-selected]="currentMyBetsTab === MY_BETS_TABS_ENUM.ENDED"
                    (click)="setView(BETS_VIEW_ENUM.ENDED_BETS, eventClickType.BET_SLIP_TABS_COMPLETED)">Ended</li>
            </ul>
        </nav>
    </header>

    <div class="bets-content"
         #scrollingArea
         [ngSwitch]="currentBetsView"
         cduxMoreContent
         (hasMoreContent)="toggleScrollingIndicator($event)">
        <cdux-bet-slip *ngSwitchCase="BETS_VIEW_ENUM.BET_SLIP"
                       [programView]="programView"
                       [betIdToSubmit]="returningBetId"
                       [betShareId] = "betShareId"
                       [programView] = "programView"
                       (betCount)="onBetCountUpdate($event)"
                       (totalAmount)="onTotalAmountUpdate($event)"
                       (valid)="onValidUpdate($event)"
                       (resetScroll)="resetScroll($event)"
                       (sizeChange)="sizeChange($event)"
                       (submitAllInProgress)="toggleSubmitAllButton($event)"
                       (onInitialize)="betSlipInitialized()"
                       (onChangeDetection)="forceChangeDetection()"></cdux-bet-slip>

        <cdux-my-bets-container *ngSwitchCase="BETS_VIEW_ENUM.ACTIVE_BETS"
                                (contract)="sizeChange($event)"
                                (openTab) = "setView($event)"
                                [programView]="programView"></cdux-my-bets-container>

        <cdux-completed-bets-container *ngSwitchCase="BETS_VIEW_ENUM.ENDED_BETS"
                                       (contract)="sizeChange($event)"
                                       [programView]="programView"></cdux-completed-bets-container>
    </div>

    <cdux-more-content-indicator *cduxMediaToggle="'desk-to-phone'"
                                 [moreContent]="showScrollingIndicator"></cdux-more-content-indicator>
    <footer class="bet-slip-submit-all"
            [class.is-inactive]="betSlipValid"
            *ngIf="currentBetsView === BETS_VIEW_ENUM.BET_SLIP && showFooter">
        <cdux-bet-slip-footer (submit)="triggerSubmitAll()"
                              [valid]="betSlipValid"
                              [betCount]="betSlipBetCount"
                              [totalAmount]="betSlipTotalAmount"
                              [submitAllInProgress]="submitAllInProgress"></cdux-bet-slip-footer>
    </footer>
</div>
