import { ActivatedRoute, ActivatedRouteSnapshot, UrlSegment, ParamMap, convertToParamMap, Router } from '@angular/router';

import { CduxObjectUtil } from '@cdux/ng-common';
import { CduxRoute } from '../interfaces';

interface IPathFromRoot {
    params: string[];
    path: string[];
}

interface IPathHistory {
    pathFromRoot: IPathFromRoot;
    id: number;
}

let NEXT_UNIQUE_ID = 0;

export class CduxRouteUtil {
    public static set history(history: IPathHistory[]) {
        this._history = history;
        sessionStorage.setItem(this.HISTORY_STORAGE_KEY, JSON.stringify(history));
    }

    public static get history(): IPathHistory[] {
        if (!this._history) {
            // load history from session storage or create a new one
            this._history = JSON.parse(sessionStorage.getItem(this.HISTORY_STORAGE_KEY) || '[]') || [];
        }
        return this._history;
    }

    private static HISTORY_STORAGE_KEY = 'cduxRouteHistory';

    private static _history: IPathHistory[];

    private static _routeTree: CduxRoute[];

    /**
     * returns modified href defined in the base element, or full URL containing the base
     */
    public static getBaseHref(fullURI?: boolean): string {

        let baseHref = '';

        if (fullURI) {
            // returns the entire base with domain, i.e. 'https://www.examples.com/base/'
            baseHref = document.querySelector('base').href;
        } else {
            // return just the value in the base, i.e. '/base/'
            baseHref = document.querySelector('base').getAttribute('href');

            // Allows Feature/Defect based branches to work with routing keeping the player
            // in feature environment.
            if (/^(\/Feature\/([A-Za-z0-9\-_]+))(\/[a-z]+\/)/.test(baseHref)) {
                baseHref = baseHref.replace(/^(\/Feature\/([A-Za-z0-9\-_]+))(\/[a-z]+\/)/, '$3');
            }
        }

        // If we're on localhost, return the default `/bet/`
        if (baseHref === '/' && /^local(\.|host)/.test(location.host)) {
            return '/bet/';
        } else {
            return baseHref;
        }
    }

    /**
     * Matches the provided string against all the known top-level routes
     *
     * Builds out an array of the top level routes and then matches against them
     * @param route
     * @param router
     */
    public static matchesTopLevelRoute(route: string, router: Router): boolean {
        return router.config.some((routeConfig) => {
            if (routeConfig.path === '') {
                // if the path isn't defined, just check the immediate children as if they were top-level
                return !!routeConfig.children && routeConfig.children.some((childRoute) => {
                    return childRoute.path === route;
                });
            } else {
                return routeConfig.path === route;
            }
        });
    }

    /**
     * Parses the top level url route of a given route string
     * @param route
     */
    public static parseTopLevelMenuRoute(route: string): string {

        const baseHref = CduxRouteUtil.getBaseHref();

        // remove the href prefix if present
        if (route.startsWith(baseHref)) {
            route = route.replace(baseHref, '');
        }
        // remove first instance of "/"
        route = route.replace(/^\//, '');
        // if there's another /, remove everything after it
        if (route.indexOf('/') >= 0) {
            route = route.substring(0, route.indexOf('/'));
        }
        return route;
    }

    /**
     * Returns the nearest parentPath for the current ActivatedRoute
     *
     * @param activatedRoute - Current ActivatedRoute which holds the Router Config
     */
    public static getParentPath(activatedRoute: ActivatedRoute): string | null {
        // Grab the IPathFromRoot for Parameter Encoding
        const pathFromRoot = this.getPathFromRoot(activatedRoute);
        // Retrieve the Route Tree from the Router Config or Memory
        if (!this._routeTree) {
            this.getRouteTree(activatedRoute);
        }
        const routeTree = this._routeTree;
        // Traverse the Route Tree to find the Path of the Current Route
        const routeInTree = this._findInTree([...pathFromRoot.path], routeTree);
        // Reverse the Path of the Current Route to find the nearest Parent Route
        const parentRoute = this._findParentRoute(routeInTree, routeTree);
        // If no Parent Route was found, return
        if (!parentRoute) {
            return null;
        }
        // Convert the Route into a Consumable Path
        const parentPath = this.getPathFromRoute(parentRoute);
        // Encode and Parameters into the Parent Path
        const encodedPath = this._encodeRouteParameters(pathFromRoot, parentPath);
        // Return the Encoded Parent Path
        return encodedPath;
    }

    /**
     * Facade call to Build a Path from a Route, filters out Blank Paths
     *
     * @param route - Single Dimensional Route to Traverse for Path
     */
    public static getPathFromRoute(route: CduxRoute[]) {
        return this._buildPathFromRoute(route).filter((path) => !!path);
    }

    /**
     * Facade call to Build a Path from the Current Route
     *
     * @param activatedRoute - Currently Active Route
     */
    public static getPathFromRoot(activatedRoute: ActivatedRoute) {
        return this._buildActivateRouteUrl(activatedRoute.snapshot);
    }

    /**
     * Facade call to Build the Router Tree
     *
     * @param activatedRoute - Currently Active Route
     */
    public static getRouteTree(activatedRoute: ActivatedRoute) {
        const routeTree = this._buildRouteTree(
            activatedRoute.snapshot.routeConfig &&
            activatedRoute.snapshot.routeConfig.children || []
        );
        // Stores the Router Tree for Quick Consumption Later
        this._routeTree = routeTree;
        return routeTree;
    }

    /**
     * Flattens all of the parameters for a nested route
     *
     * @param activatedRoute - Currently Active Route
     */
    public static extractParams(activatedRoute: ActivatedRoute): { [key: string]: any } {
        const parameters = {};

        let activeSnapshot: ActivatedRouteSnapshot = activatedRoute.snapshot;
        while (activeSnapshot) {
            Object.assign(parameters, activeSnapshot.params);
            if (activeSnapshot.children && activeSnapshot.children.length) {
                activeSnapshot = activeSnapshot.children[0];
            } else {
                activeSnapshot = null;
            }
        }

        return parameters;
    }

    /**
     * Flattens all of the parameters for a nested route and converts it to a ParamMap
     *
     * @param activatedRoute - Currently Active Route
     */
    public static extractParamMap(activatedRoute: ActivatedRoute): ParamMap {
        return convertToParamMap(this.extractParams(activatedRoute));
    }

    /**
     * Stores a NavigationEnd Event Path in Logical History
     *
     * @param activatedRoute - ActivatedRoute after Navigation to Read From
     * @param clearHistory - Whether Existing History Should be Cleared
     */
    public static navigate(activatedRoute: ActivatedRoute, clearHistory: boolean = false, reverse: boolean = false): IPathHistory[] {
        const history = !clearHistory && this.history || [];

        if (reverse && !clearHistory) {
            history.splice(history.length - 1, 1);
        } else {
            // Transform ActivatedRoute into IPathFromRoot
            const pathFromRoot = this.getPathFromRoot(activatedRoute);
            // Don't push Routes which only have changed Parameters onto the History
            if (history.length > 0) {
                if ((pathFromRoot.path.join('/') === history[history.length - 1].pathFromRoot.path.join('/'))) {
                    history[history.length - 1].pathFromRoot = pathFromRoot;
                } else {
                    history.push({
                        pathFromRoot,
                        id: NEXT_UNIQUE_ID++
                    });
                }
            } else {
                history.push({
                    pathFromRoot,
                    id: NEXT_UNIQUE_ID++
                });
            }
        }

        return this.history = history;
    }

    /**
     * Traverses the Route Tree to get the aggregated config data for the current path
     *
     * @param activatedRouteSnapshot
     */
    public static getCurrentPathConfig(activatedRouteSnapshot: ActivatedRouteSnapshot) {

        // Preserve the current route for manipulation
        let currentRoute: ActivatedRouteSnapshot = activatedRouteSnapshot;

        // Traverse tree to find current leaf by accessing the first child
        while (currentRoute && currentRoute.firstChild && currentRoute.firstChild.data) {
            // Inherit data from parent route
            currentRoute.firstChild.data = {
                ...currentRoute.data,
                ...currentRoute.firstChild.data
            };
            currentRoute = currentRoute.firstChild;
        }

        return currentRoute.data || {};
    }

    /**
     * filters out any number of GET params from the param string
     *
     * If a full key=value is provided to keysToRemove, the key is only removed
     * if the value also matches. Otherwise, a keys-only match is performed.
     *
     * This method will strip a leading '?', if present, in the params string.
     * If the return value is not empty, it will include the leading '?'.
     *
     * While this method doesn't relate directly to routing, this class still
     * seemed the best of the existing locations. Feel free to move it if a
     * more appropriate utility library is created.
     *
     * @param params GET parameter string
     * @param keysToRemove array of keys to remove (optionally, with their values)
     */
    public static filterGetParams (params: string, keysToRemove: string[]): string {
        if (params === undefined || params === '') {
            return '';
        }

        // drop leading ?
        if (params.substring(0, 1) === '?') {
            params = params.substring(1);
        }

        /*
         * First, separate keysToRemove into full k=v matches and
         * keys-only searches.
         */
        const keysOnly: string[] = [];
        const pairs = keysToRemove.filter(
            (element2) => {
                if (element2.indexOf('=') !== -1) {
                    return true;
                } else {
                    keysOnly.push(element2);
                    return false;
                }
            }
        );

        const getParams = params.split('&').filter(
            (element) => {
                const key = element.split('=')[0];
                // a not-found result means keep the key=value in the returned array
                return (pairs.indexOf(element) === -1) && (keysOnly.indexOf(key) === -1);
            }
        );

        if (getParams.length > 0) {
            return '?' + getParams.join('&');
        }

        return '';
    }


    /**
     * Retrieves the Parent Route for a Route located in the Router Tree
     *
     * @param pathInTree - Route to Search For
     * @param tree - Tree to Search In
     */
    private static _findParentRoute(pathInTree: CduxRoute[], tree: CduxRoute[]): CduxRoute[] {
        const parentPath = this._findParentPath(pathInTree);
        if (!parentPath) {
            return null;
        }
        return this._findInTree(parentPath, tree);
    }

    /**
     * Reverse Traverses a Single Dimensional Route to find the Nearest Parent Path
     *
     * @param pathInTree - Route to Traverse
     */
    private static _findParentPath(pathInTree: CduxRoute[]): string[] {
        // Traverses Only the First Index in Each Child Array
        const currentRoute = pathInTree[0];
        let parentPath;
        if (currentRoute.children && currentRoute.children.length > 0) {
            // Recursive Call if Route has Children
            parentPath = this._findParentPath(currentRoute.children);
        }
        // If there is not a Parent Path set on a Child Route
        if (!parentPath && !!currentRoute.parentPath) {
            // Store the Parent Path
            parentPath = [...currentRoute.parentPath];
        }
        // Return the Parent Path
        return parentPath;
    }

    /**
     * Traverses a Tree to find a Path associated with a Route
     *
     * @param path - Path to Search For
     * @param tree - Tree to Search In
     */
    private static _findInTree(path: string[], tree: CduxRoute[]): CduxRoute[] {
        let segment: string;
        let branch: CduxRoute;
        // Check to Ensure we have a Path Here
        if (path) {
            segment = path[0];
        }
        // Check to Ensure the Path Given results in a Proper Segment
        if (segment && !CduxObjectUtil.isEmpty(segment)) {
            // Attempt to Find and Clone the Segment's Branch in the Tree
            branch = CduxObjectUtil.clone(tree.find((b) => b.path === segment));
        }
        // If we did not receive a Segment, or received a bad Segment
        if (!branch || CduxObjectUtil.isEmpty(branch)) {
            // Search for a Default Branch
            branch = CduxObjectUtil.clone(tree.find((b) => b.path === ''));
        } else {
            // Otherwise, remove the Path from the Collection
            path.splice(0, 1);
        }
        // Unwrap our Branch if it is in an Array
        if (Array.isArray(branch)) {
            branch = branch[0];
        }
        // If we have a Valid Branch, our Branch has Children, and we still have Paths to Traverse
        if (branch && !CduxObjectUtil.isEmpty(branch) && branch.children && path.length) {
            // Recursively Traverse our Branch's Children
            branch.children = this._findInTree(path, branch.children);
        } else if (branch && !CduxObjectUtil.isEmpty(branch) && branch.children && !path.length) {
            // Otherwise, if we have a Valid Branch, our Branch has Children, but we our out of Traversal Steps
            // Look for a Default Path
            if (branch.children.find((v) => v.path === '')) {
                // Recursively Traverse our Branch's Children
                branch.children = this._findInTree(path, branch.children);
            } else {
                // Remove unassociated Children from our Branch
                delete branch.children;
            }
        }
        // Return the Tree associated with the Path
        return [branch];
    }

    /**
     * Generates the Entire Router Tree from the Root Routes
     *
     * @param rootRoutes - Top Level Routes which collectively contain all Child Routes
     */
    private static _buildRouteTree(rootRoutes: CduxRoute[]): CduxRoute[] {
        // Collection to Clone Routes Into
        const tmpRoutes: CduxRoute[] = [];
        // Iterate through our Root Routes
        rootRoutes.forEach((route: CduxRoute) => {
            // Cloned Route
            const tmpRoute: CduxRoute = CduxObjectUtil.clone(route);
            // If our Route has Children in its Config
            if (tmpRoute['_loadedConfig'] && tmpRoute['_loadedConfig'].routes && tmpRoute['_loadedConfig'].routes.length > 0) {
                // Expose the Children
                tmpRoute.children = tmpRoute['_loadedConfig'].routes;
            }
            // If our Route has Exposed Children
            if (tmpRoute.children && tmpRoute.children.length > 0) {
                // Recursively build the Tree of our Route's Children
                tmpRoute.children = this._buildRouteTree(tmpRoute.children);
            }
            tmpRoutes.push(tmpRoute);
        });
        let redirect;
        let redirectTo;
        // If a Route has a redirectTo and we can Locate the Target of that redirectTo
        if (
            (redirect = tmpRoutes.find((v) => !!v.redirectTo))
            && (redirectTo = tmpRoutes.find((v) => v.path === redirect.redirectTo))
        ) {
            // Duplicate the Children onto the redirectTo Route
            redirect.children = redirectTo.children;
        }
        // Return Tree
        return tmpRoutes;
    }

    /**
     * Extracts a Path from the current Activated Route
     *
     * @param activatedRouteSnapshot - Snapshot of the Currently Active Route
     * @param route - Placeholder for Accumulator
     */
    private static _buildActivateRouteUrl(activatedRouteSnapshot: ActivatedRouteSnapshot, route: any = {}): IPathFromRoot {
        // If our Accumulator does not have a Path Collection
        if (!route.path) {
            // Create a blank Path Collection on our Accumulator
            route.path = [];
        }
        // If our Accumulator does not have a Params Collection
        if (!route.params) {
            // Create a blank Params Collection on our Accumulator
            route.params = [];
        }
        // If our Currently Active Route has a Path stored on its Config
        if (activatedRouteSnapshot.routeConfig && activatedRouteSnapshot.routeConfig.path) {
            // Push the Path onto the Accumulator's Collection
            route.path.push(activatedRouteSnapshot.routeConfig.path);
        }
        // If our Currently Active Route has a Url stored on its Config
        if (activatedRouteSnapshot.url && activatedRouteSnapshot.url.length) {
            // Push the Rendered Url onto the Accumulator's Collection
            route.params.push(
                activatedRouteSnapshot.url.reduce((a: string[], c: UrlSegment) => {
                    a.push(c.path);
                    return a;
                }, []).join('/')
            );
        }
        // If our  Currently Active Route has Children
        if (activatedRouteSnapshot.children && activatedRouteSnapshot.children.length > 0) {
            // Recursively Extract the Paths of the Children
            route = this._buildActivateRouteUrl(activatedRouteSnapshot.children[0], route);
        }
        return route;
    }

    /**
     * Traverse a Single Dimensional Route and Returns the Path Associated
     *
     * @param route - Route to Traverse
     */
    private static _buildPathFromRoute(route: CduxRoute[]): string[] {
        let path = [];
        // If our Route uses a Redirect Path, push it onto the Path, otherwise, push the Route's Path.
        path.push(route[0].redirectTo ? route[0].redirectTo : route[0].path);
        // If our Route has Children
        if (route[0].children && route[0].children.length > 0) {
            // Recursively Traverse the Children, Concatenating the Returns
            path = path.concat(this._buildPathFromRoute(route[0].children));
        }
        // Return the complete Path
        return path;
    }

    /**
     * Encodes a Route with any Matching Parameters from an IPathFromRoot where Applicable
     *
     * @param pathFromRoot - Source of Encoding Mapping and Parameters
     * @param path - Path to Encode
     */
    private static _encodeRouteParameters(pathFromRoot: IPathFromRoot, path: string[]): string {
        // Iterate over the Slugs in the Path
        return path.map((slug: string) => {
            // Locate a Matching Path in the IPathFromRoot
            const matchedSlugIndex = pathFromRoot.path.findIndex((v) => v === slug);
            // If we have a Matching Path
            if (matchedSlugIndex >= 0) {
                // Overwrite the Path with the Params
                return pathFromRoot.params[matchedSlugIndex];
            }
            // Otherwise return the Path
            return slug;
        }).join('/');
    }
}
