<!--
        To display toteboard follow these steps:
        1. Add is-present class to toteboard-container-slider.
            This adds the element that houses the toteboard and allows
            for the slide up animation.
        2. Add is-open class to tote-board-container. This actually animates the slide up.
        To hide toteboard reverse the steps.
        Example code for inserting toteboard into the tux framework:
            <main class="tux-framework-container">
                <header class="tux-top">
                </header>
                <section class="tux-body">
                    <div class="tux-main-content">
                    </div>
                   <aside class="tux-sidebar">
                    </aside>
                </section>
                    <aside class="tux-sidebar">
                    </aside>
                <footer class="tux-bottom">
                </footer>
                <section class="toteboard-container-slider">
                </section>
            </main>
-->
<section class="toteboard-container-slider" [class.is-present]="isActive">
    <div class="toteboard-container is-classic" [class.is-open]="isOpen" *ngIf="showFavorites ? favoriteTrackList : trackList as tracks">
        <nav class="toteboard-container__nav-bar">
            <span class="toteboard-container__label" (click)="setShowFavorites(true)" cduxClickStopPropagation [ngClass]="{'active': showFavorites}">Favorites</span>
            <span class="toteboard-container__label" (click)="setShowFavorites(false)" cduxClickStopPropagation [ngClass]="{'active': !showFavorites}">Up Next</span>
            <span class="toteboard-container__btn" (click)="toggleToteBoard(false)">Hide</span>
        </nav>
        <ng-container *ngIf="!loadingComplete || tracks.length > 0">
            <div class="toteboard" *cduxLoading="loadingKey; strategy:'replace'; component:loadingDotsComponent;">
                <ng-container *ngIf="tracks.length > 0">
                        <div class="toteboard__col-static">
                            <table>
                                <thead class="toteboard__header">
                                    <tr class="row">
                                        <th class="col left">Track</th>
                                        <th class="col">Race</th>
                                        <th class="col">Post time</th>
                                        <th class="col">MTP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="row" *ngFor="let track of tracks">
                                        <td class="col left">
                                            <a (click)="selectTrack(track)">
                                                <cdux-track-group [track]="track">
                                                    <span class="track-name">{{ track | formatTrackName }}</span>
                                                </cdux-track-group>
                                            </a>
                                        </td>
                                        <td class="col">{{ track.RaceNum }}</td>
                                        <td class="col">{{ track.PostTime | date:'shortTime' }}</td>
                                        <td class="col">{{ track.Mtp }}</td>
                                    </tr>
                                    <tr class="row" *ngFor="let placeholder of series(tracks.length + 1, 5)">
                                        <td class="col left">--</td>
                                        <td class="col">--</td>
                                        <td class="col">--</td>
                                        <td class="col">--</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="toteboard__col-scroll">
                            <table *ngIf="showFavorites ? favoriteMaxRunners : (maxRunners ? maxRunners : 24) as numRunners">
                                <thead class="toteboard__header">
                                    <tr class="row">
                                        <th class="col" *ngFor="let i of series(1, numRunners)">{{ i }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="row" *ngFor="let track of tracks">
                                        <ng-container *ngIf="getTrackOddsEntries(track) as entries">
                                            <td class="col" *ngFor="let entry of entries">{{ entry.TextOdds }}</td>
                                            <td class="col" *ngFor="let entry of series(entries.length + 1, numRunners)">--</td>
                                        </ng-container>
                                    </tr>
                                    <tr class="row" *ngFor="let placeholder of series(tracks.length + 1, 5)">
                                        <td class="col" *ngFor="let entry of series(1, numRunners)">--</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>
        </ng-container>
        <ng-container *ngIf="showFavorites && loadingComplete">
            <div class="toteboard no-data" *ngIf="sessionService.isLoggedIn() && tracks.length < 1">
                <p><span cduxClickStopPropagation class="messaging" [class.active]="isSelectFavoriteClickable" (click)="isSelectFavoriteClickable && openTrackListDropDown()">
                    Add up to 5 tracks (from the Tracks dropdown) by clicking the Star <i class="icon--favorite-light"></i>
                    icons.</span></p>
            </div>

            <div class="toteboard no-data" *ngIf="!sessionService.isLoggedIn()">
                <p>You need to be signed in to view your favorites</p>
            </div>
        </ng-container>
    </div>
</section>
