<cdux-dropdown class="bet-dropdown-selector type bet-type-dropdown"
               name="bet-type"
               [(ngModel)]="value"
               [forceState]="onSmallGlass ? smallGlassState : 0">

    <div dropdown-header *ngIf="!suppressHeader">
        <div class="menu-tab">
            <h2 class="title-label">
                    <span id="bet-nav-selected-type"
                          *ngIf="value">
                        {{ value.poolType.displayName }}
                    </span>
            </h2>
        </div>
        <div class="dropdown-arrow">
            <i class="icon--arrow-down"></i>
        </div>
    </div>

    <div dropdown-list>
        <cdux-dropdown-option *ngFor="let type of list; trackBy:trackByIndex; let $index = index;"
                              [value]="type">
                <span class="option-label" id="bet-nav-type-option-{{$index + 1}}">
                    {{ type.poolType.displayName }}
                </span>
        </cdux-dropdown-option>

        <cdux-wager-type-info *ngIf="learnMoreEnabled" [infoType]="enumWagerInfoType.WAGER_TYPES"></cdux-wager-type-info>
    </div>
</cdux-dropdown>
