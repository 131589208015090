export enum enumChangeTypes {
    NO_CHANGE = 'no changes',
    SCRATCH = 'scratch',
    JOCKEY = 'jockey change',
    WEIGHT = 'weight change',
    WEIGHT_CORRECTION = 'weight correction',
    NEW = 'new entry',
    OTHER = 'other',
    CANCEL = 'cancel'
}
export interface IChangesDescription {
    description: string;
    label: string;
    reason?: string;
}
export class EntryData {
    public horseName?: string;
    public programNumber?: string;
    public jockeyName?: string;
    public newProgramNumber?: string;
    public changeType?: string;
    public weight?: string;
    public weightDifference?: string;
    public description?: string;
    public changes?: IChangesDescription;
}
