<!--video player controls for live/replay videos and angle views-->
<div *ngIf="!disableLiveReplayToggle" class="video-button-container">

    <!--Live Button-->
    <div *ngIf="liveVideoAvailable" class="video-feed live">
        <div class="button-container">
            <div class="live-button" [class.active]="feedState === videoFeedStates.LIVE" (click)="toggleFeed(videoFeedStates.LIVE, $event)">
                <div class="button-icon"></div>
            </div>
            <div class="status">live</div>
            <span *ngIf="liveRaceNumber" class="race">R{{ liveRaceNumber }}</span>
        </div>
    </div>

    <!--Replay Button -->
    <div *ngIf="feedAngles.length" [class.click]="showingAngles" class="video-feed replay tooltip show" (mouseenter)="showAngles()" (mouseleave)="hideAngles(true)">
        <div class="button-container">
            <div #replayButton class="replay-button" [class.active]="feedState === videoFeedStates.REPLAY" (touchend)="touchedReplayButton()" (click)="toggleReplay(videoFeedStates.REPLAY, $event)">
                <div class="button-icon"></div>
            </div>
            <div class="status">replay</div>
            <div *ngIf="race" class="race">R{{ race }}</div>
        </div>

        <!--Angle Selector-->
        <div #replayToolTip class="replay-tooltip tooltiptext tooltip-right">
            <div class="tooltip-header">
                Camera Angle:
            </div>
            <div class="tooltip-options">
                <div *ngIf="hasAngle(videoFeedAngles.PAN)" class="option full-view" [class.active]="feedAngle === videoFeedAngles.PAN" (touchend)="touchedFeedAngle(videoFeedAngles.PAN)" (click)="selectAngle(videoFeedAngles.PAN)">
                    Full View
                </div>
                <div *ngIf="hasAngle(videoFeedAngles.HEADON)" class="option head-on" [class.active]="feedAngle === videoFeedAngles.HEADON" (touchend)="touchedFeedAngle(videoFeedAngles.HEADON)" (click)="selectAngle(videoFeedAngles.HEADON)">
                    Head-on
                </div>
            </div>
        </div>
    </div>

</div>
