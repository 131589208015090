import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ITrack, ITrackBasic, JwtSessionService } from '@cdux/ng-common';

import { enumTrackViews } from 'app/shared/common/enums/track-views.enum';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-track-group',
    templateUrl: './track-group.component.html',
    styleUrls: ['./track-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackGroupComponent implements OnInit, OnDestroy {
    @Input()
    public track: ITrack|ITrackBasic;

    @Input()
    public disableClick: boolean = false;

    @Input() public inHeader: boolean = false;
    @Input() public largeStar: boolean = false;
    @Input() public inTrackList: boolean = false;
    @Input() public inView: enumTrackViews;

    public isFavoriteEnabled: boolean;

    @Input()
    public showFavorite = true;

    public tournamentSelected = false;
    private _authenticationSubscription: Subscription;
    private _tournamentSubscription: Subscription;

    constructor(
        private sessionService: JwtSessionService,
        private changeDetector: ChangeDetectorRef,
        private tournamentSession: TournamentsSessionService
    ) {}

    ngOnInit() {
        this._tournamentSubscription = this.tournamentSession.onTournamentSelection.subscribe((data) => {
            this.tournamentSelected = data ? true : false;
        });

        this.isFavoriteEnabled = !this.tournamentSelected && this.showFavorite && this.sessionService.isLoggedIn();

        this._authenticationSubscription = this.sessionService.onAuthenticationChange.subscribe((loggedIn) => {
            this.isFavoriteEnabled = this.showFavorite && loggedIn;
            this.changeDetector.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this._authenticationSubscription) {
            this._authenticationSubscription.unsubscribe();
        }
        if (this._tournamentSubscription) {
            this._tournamentSubscription.unsubscribe();
        }
    }
}
