import {  NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TournamentsSelectionBarComponent } from './components/tournaments-selection-bar/tournaments-selection-bar.component';
import { TournamentsSelectionComponent } from './components/tournaments-selection/tournaments-selection.component';
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
  declarations: [
    TournamentsSelectionBarComponent,
    TournamentsSelectionComponent
  ],
  exports: [
    TournamentsSelectionBarComponent,
    TournamentsSelectionComponent
  ]
})

export class TournamentsSessionnModule { }


