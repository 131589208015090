import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'formatFraction'})
export class FormatFractionPipe implements PipeTransform {
    constructor() {}

    transform(fractionArray: any): string {
        let numerator = fractionArray[0];
        const denominator = fractionArray[1];
        if ( !denominator ) {
            return '-';
        }

        if ( !numerator ) {
            numerator = 0;
        }

        return numerator + '/' + denominator;
    }
}
