<form class="bet-share-ssn-container" [formGroup]="SSNCollectionForm" (ngSubmit)="onSubmit()">
    <div class="ts-message error" *ngIf="submitted && !success">
        We could not verify the SSN. Please
        try again or call Player Services at
        <span style="white-space: nowrap;">{{ 'primary-phone-number-vanity' | cduxTranslate:AFFILIATE_KEY }}</span>.
    </div>

    <div class="bet-share-ssn-header">When you Win  Big!</div>
    <p>Due to tax regulations, we must receive every Bet Share participant's full Social Security Number (SSN) to release your winnings.</p>
    <div class="bet-share-ssn-input">

        <div class="form-wrap-3col ssn">
            <div class="form-label-hover number">
                <!--input needs the class 'has-value' below if value is detected - prevents label from overlapping the value-->
                <input class="form-control" type="text" name="ssn3" maxlength="3" formControlName="area" [class.has-value]="SSNCollectionForm.get('area').value.length > 0">
                <label>
                    3 digits
                </label>
            </div>

            <div class="center"><span>-</span></div>

            <div class="form-label-hover number">
                <!--input needs the class 'has-value' below if value is detected - prevents label from overlapping the value-->
                <input class="form-control" type="text" name="ssn2" maxlength="2" formControlName="group" [class.has-value]="SSNCollectionForm.get('group').value.length > 0">
                <label>
                    2 digits
                </label>
            </div>

            <div class="center"><span>-</span></div>

            <div class="form-label-hover number is-not-allowed">
                <input class="form-control" type="text" name="ssn4" maxlength="4" disabled="disabled">
                <label>
                    {{ SSNCollectionForm.get('serial').value }}
                </label>
            </div>
        </div>
    </div>
    <div class="bet-share-ssn-buttons">
        <button type="button" class="btn-cancel" [cduxLogClick]="eventClickType.BET_SLIP_SSN_CANCEL" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn-submit" [cduxLogClick]="eventClickType.BET_SLIP_SSN_SUBMIT" [class.is-loading]="submitting" [disabled]="!SSNCollectionForm.valid">
            <span *ngIf="!submitting">Submit</span>
            <div *ngIf="submitting" class="button-loader standard-color">
                <div class="loader-dots">
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                </div>
            </div>
        </button>
    </div>
</form>
