import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, of, throwError } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';
import { FundingEZBankMethodDepositAbstractComponent } from './ezbank-method-deposit-abstract.component';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    FeatureToggleDataService,
    JwtSessionService,
    TranslateService
} from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { HeaderService } from 'app/shared/header/services/header.service';
import { CduxRouteUtil } from 'app/shared/common/utils/CduxRouteUtil';
import { EzbankService } from 'app/shared/funding/shared/services/ezbank.service';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';


@Component({
    selector: 'cdux-funding-ezbank-method-deposit-fullpage',
    templateUrl: './ezbank-method-deposit-fullpage.component.html',
    styleUrls: ['./ezbank-method-deposit-fullpage.component.scss'],
})
export class FundingEZBankMethodFullPageFundingComponent extends FundingEZBankMethodDepositAbstractComponent implements OnInit {

    // share enum with template
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public depositAmount: number;
    public showIframe: boolean = false;
    public isSmallGlass: boolean;
    public redirectToggledOn: boolean;
    public isFetchingParams: boolean;

    private _default_route = 'funding/deposit/mazooma?action=mazoomaComplete';

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEZBankMethodFullPageFundingComponent, options);
    }

    constructor(
        localRouter: Router,
        localEnvironment: ENVIRONMENT,
        localFormBuilder: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localEventTrackingService: EventTrackingService,
        public changeDetector: ChangeDetectorRef,
        private _mediaToggle: CduxMediaToggleService,
        localTranslateService: TranslateService,
        private localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        private _currencyPipe: CurrencyPipe,
        _featureToggleService: FeatureToggleDataService,
        private _configService: ConfigurationDataService,
        private _ezBankService: EzbankService,
        private _betSlipService: BetSlipBusinessService
    ) {
        super(
            localRouter,
            changeDetector,
            localEnvironment,
            localEventTrackingService,
            localFormBuilder,
            localFundingService,
            localSidebarService,
            localTranslateService,
            localSessionService,
            localHeaderService,
            _featureToggleService
        );
    }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
        this.redirectToggledOn = this._featureToggleService.isFeatureToggleOn('MAZOOMA_REDIRECT');
        super.ngOnInit();
    }

    /**
     * navigateToEzBank
     */
    public navigateToEzBank() {
        // Verify input is valid
        if (this.form.invalid || this.form.get('amount').value <= 0) {
            return;
        }
        this.isFetchingParams = true;
        // Get amount from form input
        this.depositAmount = this.form.get('amount').value;
        // Swap from ezbank deposit form to iframe component
        if (this.redirectToggledOn) {
            this._redirectToMazooma();
        } else {
            this.showIframe = true;
        }
    }

    public processEzBankResults() {
        // Show success or error state based on result from mazooma
        // Call parent method to set error message if there is one
        this._checkErrorCodes();
        // If there's no error message, that means the deposit was successful
        this.successfulDeposit = !this.errorMessage;
        this._fundingService.updateAccountBalance().pipe(
            take(1),
            finalize(() => {
                this._fundingService.onFundingEvent.emit({
                    event: this.errorMessage ? 'ERROR' : 'SUCCESS',
                    messages: [this.errorMessage || `${this._currencyPipe.transform(this.depositAmount, 'USD', 'symbol-narrow')} has been deposited to your account with Instant Bank Transfer.`],
                    amount: this.depositAmount
                });
                this.showIframe = false;
            })
        ).subscribe();
    }

    private _redirectToMazooma() {
        const user = this.localSessionService.getUserInfo();

        this._configService.getConfiguration(enumConfigurationStacks.TUX, ['mzma_staging_server', 'mzma_merchant_id', 'mazooma_return_url'])
            .pipe(
                switchMap(config => {
                    if (!!config) {
                        const returnUrl = config['mazooma_return_url'] ? CduxRouteUtil.getBaseHref(true) + config['mazooma_return_url'] : this._default_route;
                        return combineLatest([
                            this._ezBankService.fetchMazoomaParams(this.depositAmount.toString(), user.account, returnUrl),
                            of(config)
                        ]);
                    } else {
                        return throwError('No Mazooma Query Params');
                    }
                }),
                take(1)
            )
        .subscribe(
            (responses) => {
                // create a JSON object to pass to the iframe via session storage
                if (responses[0].status === 'success') {
                    this._storeTransactionItems(responses[0].orderId);

                    /* We must redirect to Mazooma using a POST
                     * so constructing a form to attach a body to send.
                     * Then attaching to document and redirecting using submit.
                     */
                    const params = {
                        'details': responses[0].details,
                        'merchant_id':  responses[1].mzma_merchant_id,
                    };
                    const form = document.createElement('form');
                    form.method = 'POST';
                    form.action = responses[1].mzma_staging_server;

                    for (const i in params) {
                        if (params.hasOwnProperty(i)) {
                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = i;
                            input.value = params[i];
                            form.appendChild(input);
                        }
                    }

                    document.body.appendChild(form);
                    form.submit();
                } else {
                    // fetchMazoomaQueryParam with error: redirect to Mazooma deposit widget with error:
                    this._fundingService.ezBankErrorCode = 'fallback';
                    this.processEzBankResults();
                    this.isFetchingParams = false;
                }
            },
            (reason) => {
                console.error('EZ Bank deposit calls failed.', reason);
                this._fundingService.ezBankErrorCode = 'fallback';
                this.processEzBankResults();
                this.isFetchingParams = false;
            }
        );
    }

    private _storeTransactionItems(orderId: string) {
        this._ezBankService.storeDepositAmount(orderId, this.depositAmount);
        if (this._fundingService.postDepositRedirectURL) {
            sessionStorage.setItem('postDepositRedirectURL', this._fundingService.postDepositRedirectURL);
        }
        // Store current bet in bet slip and save the ID to session storage for reference
        if (this._betSlipService.currentBet) {
            sessionStorage.setItem('postDepositReturningBetId', this._betSlipService.currentBet.id);
            this._betSlipService.storeBet(this._betSlipService.currentBet);
        }
    }
}
