import { Component, Input, OnInit } from '@angular/core';
import { IWithdrawBalance, FUND_ID, EventClickType, FeatureToggleDataService } from '@cdux/ng-common';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

import { FundingPendingBalanceDescriptionComponent } from './pending-balance-description.component';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { EventTrackingService } from '../../../../event-tracking/services/event-tracking.service';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';

@Component({
    selector: 'cdux-funding-pending-balances',
    templateUrl: './pending-balances.component.html'
})
export class FundingPendingBalancesComponent implements OnInit {

    @Input() public withdrawBalance: IWithdrawBalance | null;

    public FundingMethodTypes = FUND_ID;
    public amountDropdown: boolean = false;
    public fpwEnabled: boolean = false; // Whether the Full Page withdrawal feature is turned on

    constructor(
        private _mediaService: CduxMediaToggleService,
        private _sidebarService: SidebarService,
        private _eventService: EventTrackingService,
        private _featureToggleService: FeatureToggleDataService,
    ) {
        // Empty
    }

    ngOnInit() {
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
    }

    /**
     * Opens the pending balance page in the Sidenav
     */
    public openPendingBalanceDesc(type: string) {
        switch (type) {
            case 'ezmoney':
                this._eventService.logClickEvent(EventClickType.WITHDRAW_TOOLTIP_EZMONEY_PENDING)
                break;
            case 'paypal':
                this._eventService.logClickEvent(EventClickType.WITHDRAW_TOOLTIP_PAYPAL_PENDING);
                break;
            case 'bonus':
                this._eventService.logClickEvent(EventClickType.WITHDRAW_TOOLTIP_BONUS_PENDING);
                break;
        }

        if (this._mediaService.query('phone')) {
            const portalComponent = FundingPendingBalanceDescriptionComponent.getSidebarComponent(type, false);
            this._sidebarService.loadDropupComponent(portalComponent, true, 'Pending Balances');
        } else {
            const portalComponent = FundingPendingBalanceDescriptionComponent.getSidebarComponent(type, true);
            this._sidebarService.loadComponent(portalComponent);
        }
    }

    public changeAmountDropdown() {
        this.amountDropdown = !this.amountDropdown;
    }

}
