import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import {
    ITrack,
    ITrackBasic,
    IRaceIdentifier,
    enumEcommerceCustomerAvailability,
    enumResultsRaceStatus,
    enumRaceStatus,
    enumTrackPeriod,
    IAvailableResultsRaceDataResponse,
    ToteDataService,
    TrackService,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';
import { AuthGuard } from 'app/shared/common/guards';
import { EcommerceBusinessService } from 'app/shared/ecommerce/services/ecommerce.business.service';
import { ResultsService } from 'app/shared/results/services/results.business.service';
import { Results } from 'app/shared/results/models/results.model';
import { takeWhileInclusive } from 'app/shared/common/operators';
import { empty, never, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, switchMap, take, filter, first, debounceTime } from 'rxjs/operators';
import { AbstractWageringSectionComponent } from '../abstract-section.component';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { ViewStateGroupEnum } from 'app/wagering/views/interfaces/view-state-store';

enum View {
    PAYOUTS = 'payouts',
    FINISH_ORDER = 'finish_order'
}

const INSTANT_CHART_PRODUCT_CODE = 'INC';

@Component({
    selector: 'cdux-results-section',
    template: `
        <ng-template #offloadedTemplate>
            <nav class="content-nav" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
                <ul>
                    <li id="payouts" [class.is-selected]="section === ViewSectionEnum.PAYOUTS" (click)="section !== ViewSectionEnum.PAYOUTS && viewPayouts()">Payouts</li>
                    <li id="also-rans" *ngIf="resultChart?.raceDetails?.finishOrder.length > 0" [class.is-selected]="section === ViewSectionEnum.FINISH_ORDER" (click)="section !== ViewSectionEnum.FINISH_ORDER && viewFinishOrder()">Finish Order</li>
                </ul>
                <div *ngIf="isInstantChartAvailable" class="offloaded-content" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
                    <div class="button smallest" (click)="downloadInstantChart()">View Chart</div>
                </div>
            </nav>
        </ng-template>

        <cdux-result-chart
            *ngIf="resultChart"
            [onClassic]="true"
            [compactView]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE"
            [display]="section"
            [showHeader]="false"
            [resultChart]="resultChart"
            [raceDate]="toteDate"
            [brisCode]="track.BrisCode"
            [trackType]="track.TrackType"
            [raceNumber]="race">
        </cdux-result-chart>
        <div class="has-error light">
            <div class="gbl-message" *ngIf="!resultChart && !loadingResult">
                <i class="icon&#45;&#45;info"></i>
                <div class="header uppercase">Results are not presently available</div>
            </div>
        </div>
    `,
    styleUrls: ['./results-section.component.scss']
})
export class ResultsSectionComponent extends AbstractWageringSectionComponent implements OnInit, OnDestroy {

    @Input()
    public set track(track: ITrackBasic) {
        delete this.resultChart;
        this._track = track;
        this._changeDetectorRef.detectChanges();
        if (this._track && this._track.RaceNum) {
            this._initializeResults();
        }
    }

    public get track(): ITrackBasic {
        return this._track;
    }

    private _raceStatus: enumRaceStatus;
    @Input()
    public set raceStatus(status: enumRaceStatus) {
        if (status) {
            this._raceStatus = status;
            this._raceStatusSubject.next(this._raceStatus);
        }
    }


    public isInstantChartAvailable: boolean = false;
    public resultChart: Results;
    public resultsSub: Subscription;
    public loadingResult: boolean  = true;

    private _raceStatusSubject: ReplaySubject<enumRaceStatus> = new ReplaySubject<enumRaceStatus>(1);
    private _pollingResults: boolean = false;

    @Output() public updateSection = new EventEmitter<ViewSectionEnum>();

    public defaultSection = ViewSectionEnum.PAYOUTS

    constructor(
        private _authGuard: AuthGuard,
        private _location: Location,
        private _resultsService: ResultsService,
        private _router: Router,
        protected _toteDataService: ToteDataService,
        private _ecommerceService: EcommerceBusinessService,
        private _eventTrackingService: EventTrackingService,
        private _changeDetectorRef: ChangeDetectorRef,
        protected _viewStateService: ViewStateService) {
            super(_toteDataService, _viewStateService);
        }

    ngOnInit() {
        this._initializeResults();
        const sectionCache = this._viewStateService.getViewSectionGroupCache(ViewStateGroupEnum.RESULTS);
        if (sectionCache) {
            this.section = sectionCache;
            if (this.section !== ViewSectionEnum.RESULTS) {
                this.updateSection.emit(this.section);
            } else {
                this.updateSection.emit(this.defaultSection);
            }
        } else {
            this.updateSection.emit(this.defaultSection);
        }
    }

    ngOnDestroy() {
        if (this.resultsSub) {
            this.resultsSub.unsubscribe();
        }
    }

    private _initializeResults() {
        this.loadingResult = true;
        if (this.race) {
            // we have to get the tote date immediately
            this._toteDataService.currentRaceDate().pipe(take(1)).subscribe(date => {
                // it's okay to set this, even if abstract isn't set yet
                this.toteDate = date;
                this._checkChartAvailability();
                if (this.resultsSub) {
                    this.resultsSub.unsubscribe();
                    delete this.resultsSub;
                    delete this.resultChart;
                    this._pollingResults = false;
                }
                this.resultsSub = this._initalizeResultsDetails();
            });
        }
    }
    private _initalizeResultsDetails(): Subscription {
            return this._raceStatusSubject.asObservable().pipe(
            debounceTime(1000),
            switchMap((raceStatus) => {
                let returnObs: Observable <IAvailableResultsRaceDataResponse> = never();
                if (!TrackService.isWagerableRace(raceStatus) && raceStatus !== enumRaceStatus.OFF && !this._pollingResults) {
                        this._pollingResults = true;
                        returnObs = this._resultsService.getResultsDetails(
                            <IRaceIdentifier>{
                                brisCode: this.track.BrisCode,
                                trackType: this.track.TrackType,
                                raceNumber: this.track.RaceNum,
                                raceDate: this.toteDate
                            }, true
                        ).pipe(
                            filter((result) => {
                                if (result) { return true; }
                                this.loadingResult = false;
                                this._changeDetectorRef.detectChanges();
                                return false;
                            }),
                            takeWhileInclusive(results =>
                                // unsubscribes when results are complete
                                !(results && results.raceDetails && results.raceDetails.dataStatus === enumResultsRaceStatus.COMPLETE),
                                true
                            ),
                            catchError((x) => {
                                this.loadingResult = false;
                                this._changeDetectorRef.detectChanges();
                                return empty();
                            })
                        )
                } else {
                    this.loadingResult = false;
                }
                if (this.loadingResult) {
                    this.section = ViewSectionEnum.PAYOUTS;
                    this.updateSection.emit(this.section);
                }
                this._changeDetectorRef.detectChanges();
                return returnObs;
            })
        )
        .subscribe((resultChart: IAvailableResultsRaceDataResponse) => {
            this.resultChart = new Results(resultChart);
            this.loadingResult = false;
            this._changeDetectorRef.detectChanges();
        });
    }

    private _checkChartAvailability() {
        this.isInstantChartAvailable = false;

        this._ecommerceService.getAvailableDocsLite(
            <ITrack> this.track,
            INSTANT_CHART_PRODUCT_CODE,
            this.toteDate,
            this.toteDate,
            this.track.RaceNum
        ).pipe(
            first(),
            catchError(() => empty())
        ).subscribe(data => {
            try {
                // a specific document was requested, there should only be one element at each level if it is available
                this.isInstantChartAvailable = data.availableTracks[0].availableDates[0].availableProducts[0].availableRaces[0]
                    .customerAvailability === enumEcommerceCustomerAvailability.VIEW
            } catch (e) {
                this.isInstantChartAvailable = false;
            }
        });
    }

    public downloadInstantChart() {
        this._eventTrackingService.logClickEvent(EventClickType.RESULTS_CHART);

        /*
        * If the user isn't logged in, don't open the new tab.
        * Instead, send him to login as if by AuthGuard, so
        * that he returns here.
        */
        if (!this._authGuard.checkLogin('/' + this._router.routerState.snapshot.url)) {
            return;
        }

        const newTabUrl = this._location.prepareExternalUrl([
            'product/download',
            INSTANT_CHART_PRODUCT_CODE,
            TrackService.getTrackTypeBds(this.track.TrackType),
            this.track.BrisCode,
            this.toteDate,
            enumTrackPeriod.DAY,
            this.track.RaceNum
        ].join('/'));

        window.open(newTabUrl);
    }

    public logClickEvent(view: View) {
        const ts = Date.now();
        switch (view) {
            case View.PAYOUTS:
                this._eventTrackingService.logClickEvent(EventClickType.RESULTS_PAYOUTS, [
                    { attrId: EventClickAttributeType.PAYOUTS_BRIS_CODE, data: this._track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.PAYOUTS_TRACK_TYPE, data: this._track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.PAYOUTS_RACE_NUMBER, data: this._track.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.PAYOUTS_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            case View.FINISH_ORDER:
                this._eventTrackingService.logClickEvent(EventClickType.RESULTS_FINISH_ORDER, [
                    { attrId: EventClickAttributeType.FINISH_ORDER_BRIS_CODE, data: this._track.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.FINISH_ORDER_TRACK_TYPE, data: this._track.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.FINISH_ORDER_RACE_NUMBER, data: this._track.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.FINISH_ORDER_RACE_DATE, data: this.toteDate, timestamp: ts }
                ]);
                break;
            default:
            // We shouldn't get in here, but if we do, that's OK, we won't log any click event
        }
    }

    public viewPayouts() {
        this.section = ViewSectionEnum.PAYOUTS;
        this.updateSection.emit(this.section);
        this.logClickEvent(View.PAYOUTS);
    }

    public viewFinishOrder() {
        this.section = ViewSectionEnum.FINISH_ORDER;
        this.updateSection.emit(this.section);
        this.logClickEvent(View.FINISH_ORDER);
    }
}
