import {
    AfterViewInit,
    Component,
    OnInit,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import {
    FavoritePersonService,
    IFavoritePerson,
    TranslateService,
    TFavoriteUUID,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FavoritePersonTypeEnum } from '../enums/favorite-person-type-enums';
import { OverlayRef } from '@angular/cdk/overlay';
import { ToastService } from '@cdux/ng-fragments';
import { FavEventType } from '../favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
import {IDetailsOptions} from "app/shared/program/interfaces/details.interface";
import {WageringViewEnum} from "app/wagering/views/enums/wagering-view.enum";

@Component({
    templateUrl: './favorites-manager-jockeys.component.html',
    styleUrls: [ './favorites-manager-jockeys.component.scss' ],
})
export class FavoritesManagerJockeysComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('updateModalTemplate') protected updateModalTemplate: TemplateRef<unknown>;
    @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>;
    @ViewChild('statsModalTemplate') protected statsModalTemplate: TemplateRef<unknown>;

    public tempComment: string;
    public countCurrent = 0;
    public readonly COUNT_MAX = 4096;
    public modalContent: Portal<any>;
    public updateTemplatePortal: TemplatePortal<any>;
    public removeTemplatePortal: TemplatePortal<any>;
    public statsTemplatePortal: TemplatePortal<any>;

    private _overlayRef: OverlayRef;
    private _favJockeySub: Subscription;
    private _terminateObs: Subject<any> = new Subject();

    public todaysRunners: IFavoritePerson[];
    public notTodaysRunners: IFavoritePerson[];
    public noFavorites: boolean = false;
    public modalJockey: IFavoritePerson;
    public options: IDetailsOptions;


    public jockeyName: string;
    private _uuid: TFavoriteUUID;

    private sortDesc = false;
    public isSortedBy = false;

    public constructor(
        private _viewContainerRef: ViewContainerRef,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _favoritePersonService: FavoritePersonService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _eventTrackingService: EventTrackingService,
    ) {}

    ngAfterViewInit() {
        this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        );

        this.updateTemplatePortal = new TemplatePortal(
            this.updateModalTemplate,
            this._viewContainerRef
        );

        this.statsTemplatePortal = new TemplatePortal(
            this.statsModalTemplate,
            this._viewContainerRef
        );
    }

    public ngOnInit() {
        if (this._favJockeySub) {
            this._favJockeySub.unsubscribe();
        }

        this._favJockeySub = this._favoritePersonService.favorites
        .pipe(
            takeUntil(this._terminateObs)
        ).subscribe(
            (favoritesData: IFavoritePerson[]) => {
                const jockeysOnlyData = favoritesData.filter(jockey => jockey.personType === FavoritePersonTypeEnum.JOCKEY);
                this.noFavorites = false;

                this.todaysRunners = [];
                this.notTodaysRunners = [];

                if (jockeysOnlyData.length > 0) {
                    jockeysOnlyData.forEach(jockey => {
                        if (jockey?.todaysRunners.length > 0) {
                            this.todaysRunners.push(jockey);
                        }

                        if (jockey?.todaysRunners.length == 0) {
                            this.notTodaysRunners.push(jockey);
                        }
                    });

                    this.todaysRunners.sort((a, b) => {
                        const nameA = this.formatName(a.firstName, a.middleName, a.lastName, true);
                        const nameB = this.formatName(b.firstName, b.middleName, b.lastName, true);

                        if (nameA < nameB) { return -1; }
                        if (nameA > nameB) { return 1; }

                        return 0; // names must be equal
                    });

                    this.sort(this.notTodaysRunners, true);
                } else {
                    this.noFavorites = true;
                }
            }
        );
    }

    ngOnDestroy() {
        this._terminateObs.next();
        this._terminateObs.complete();
    }

    public confirmRemove() {
        this._favoritePersonService.remove(this._uuid).then((removed) => {
            if (removed) {
                this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);
                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.jockeyName + ' removed from <span class="bold-text">stable</span>.',
                        duration: 3000
                    }
                });
            }
            if (!removed) {
              this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public get note() {
        return this.tempComment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.tempComment = value;
        }
    }

    public edit (jockeyData: IFavoritePerson) {
        this.modalJockey = jockeyData;
        this.tempComment = jockeyData.comment || '';
        this.countCurrent = this.tempComment.length;
        this.modalContent = this.updateTemplatePortal;
    }

    public confirmUpdate(jockeyData: IFavoritePerson) {
        // The favorite modal handles validation of the input regarding the
        // limits so we don't need to check them again here.
        // In case the save fails, we don't want to alter the original favorite YET so...

        // 1. Clone the favorite
        const dupeFav = Object.assign({}, jockeyData);
        dupeFav.comment = this.tempComment;
        // 2. Call to update the favorite
        this._favoritePersonService.update(dupeFav).then((updated) => {
            if (updated) {
                // 3. If save was successful. Replace original favorite with the new one
                jockeyData = dupeFav;
            } else {
                // 4. Show some sort of error message? Seek clarification.
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }
        });
        this.dismissModal();
    }

    public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }

    public remove(jockey: IFavoritePerson) {
        this.jockeyName = this.formatName(jockey.firstName, jockey.middleName, jockey.lastName);
        this._uuid = jockey.uuid;

        this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE_LINK);

        this.modalContent = this.removeTemplatePortal;
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        let favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_FAVORITE_TYPE;
        let favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_LOCATION;

        if (clickType === EventClickType.STABLE_ALERTS_REMOVE) {
            favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_FAVORITE_TYPE;
            favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LOCATION;
        }

        this._eventTrackingService.logClickEvent(
            clickType,
            [
                {
                    attrId: favType,
                    data: FavoriteTypeEnum.JOCKEY,
                    timestamp: ts
                },
                {
                    attrId: favLoc,
                    data: FavoriteLocationEnum.STABLE_ALERTS,
                    timestamp: ts
                },
            ]
        );
    }

    public showStatsModal(jockey: IFavoritePerson) {
        this.options = {
            brisCode: undefined,
            trackType: undefined,
            raceNumber: undefined,
            programNumber: undefined,
            view: WageringViewEnum.CLASSIC,
            jockeyId: jockey.personId,
            jockeyName: this.formatName(jockey.firstName, jockey.middleName, jockey.lastName)
        };
        this.modalContent = this.statsTemplatePortal;
    }

    public formatName(first: string, middle: string | null, last: string, toUpper = false): string {
        let rv = '';

        if (middle) {
            rv = [first.trim(), middle.trim(), last.trim()].join(' ');
        } else {
            rv = [first.trim(), last.trim()].join(' ');
        }

        if (toUpper) { rv = rv.toUpperCase(); }

        return rv;
    }

    public formatRecord(record) {
        return record ? record.starts + ' - ' + record.wins + ' - ' + record.places + ' - ' + record.shows : '0 - 0 - 0 - 0';
    }

    public sort(notTodaysRunners: IFavoritePerson[], reloaded: boolean = false) {
        if (!reloaded) {
            this.sortDesc = !this.sortDesc;
        }
        this.isSortedBy = true;

        this.notTodaysRunners.sort((a, b) => {
            const x = this.formatName(a.firstName, a.middleName, a.lastName, true);
            const y = this.formatName(b.firstName, b.middleName, b.lastName, true);

            if ( !this.sortDesc ) {
                return ( x === y ) ? 0 : (x < y ? -1 : 1);
            } else {
                return ( x === y ) ? 0 : (x < y ? 1 : -1);
            }
        });
    }
}
