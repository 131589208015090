<div class="foundation-container">
    <div class="foundation-navigation-container">
        <cdux-view-navigation
            [view]="selectedView"
            [selectedRaceNav]="wagerState?.basicTrack"
            (viewChange)="selectView($event)"
            (updateRaceNav)="onUpdateRaceNav($event)"
            (updateTrackList)="selectTrackFromList($event)">
        </cdux-view-navigation>
    </div>

    <div class="foundation-view-container">
        <div class="view {{ selectedView }}">
            <ng-container [ngSwitch]="selectedView">
                <ng-container *ngSwitchCase="WageringViewEnum.DEFAULT">
                    This is the DEFAULT ({{ selectedView }}) view.
                </ng-container>
                <ng-container *ngSwitchCase="WageringViewEnum.VIDEO">
                    <cdux-tv
                        [view]="selectedView"
                        [section]="selectedSection"
                        [wager]="wagerState"
                        [error]="error"
                        [track]="selectedTrack"
                        [race]="selectedRace"
                        [section]="selectedSection"
                        [raceStatus]="selectedRaceStatus"
                        (viewChange)="selectView($event)"
                        (sectionChange)="onSectionChange($event)"
                        (selectTrackFromList)="selectTrackFromList($event)"
                        (updateRaceNav)="onUpdateRaceNav($event)"
                        (updateBetNav)="onSelectBetTypeFromList($event)"
                        (updateBetType)="onUpdateBetType($event)"
                        (updateBetAmount)="onUpdateBetAmount($event)"
                        (updateBetModifier)="onUpdateBetModifier($event)"
                        (updateEntrySelection)="onUpdateEntrySelection($event)"
                        (resetEntrySelections)="onResetEntrySelections()"
                        (stashEntrySelections)="onStashEntrySelections()"
                        (restoreEntrySelections)="onRestoreEntrySelections()"
                        (dismissError)="onDismissError($event)"
                        [inboundEvents]="eventStream"
                        (outboundEvents)="onRegisterEvent($event)">
                    </cdux-tv>
                </ng-container>
                <ng-container *ngSwitchCase="WageringViewEnum.CLASSIC">
                    <ng-container *ngIf="!isTabletPortrait">
                        <cdux-classic-view
                            [view]="selectedView"
                            (viewChange)="selectView($event)"
                            [section]="selectedSection"
                            (sectionChange)="onSectionChange($event)"
                            [raceStatus]="selectedRaceStatus"
                            [wager]="wagerState"
                            (updateRaceNav)="onUpdateRaceNav($event)"
                            (updateBetNav)="onSelectBetTypeFromList($event)"
                            (updateBetType)="onUpdateBetType($event)"
                            (updateBetAmount)="onUpdateBetAmount($event)"
                            (updateBetModifier)="onUpdateBetModifier($event)"
                            (updateEntrySelection)="onUpdateEntrySelection($event)"
                            (resetEntrySelections)="onResetEntrySelections()"
                            (stashEntrySelections)="onStashEntrySelections()"
                            (restoreEntrySelections)="onRestoreEntrySelections()"
                            (outboundEvents)="onRegisterEvent($event)">
                        </cdux-classic-view>
                    </ng-container>
                    <ng-container *ngIf="isTabletPortrait">
                        <cdux-compact-view
                            [view]="selectedView"
                            [displayMode]="'compact'"
                            [section]="selectedSection"
                            [wager]="wagerState"
                            [raceStatus]="selectedRaceStatus"
                            [error]="error"
                            (sectionChange)="onSectionChange($event)"
                            (updateRaceNav)="onUpdateRaceNav($event)"
                            (updateBetNav)="onSelectBetTypeFromList($event)"
                            (updateBetType)="onUpdateBetType($event)"
                            (updateBetAmount)="onUpdateBetAmount($event)"
                            (updateBetModifier)="onUpdateBetModifier($event)"
                            (updateEntrySelection)="onUpdateEntrySelection($event)"
                            (resetEntrySelections)="onResetEntrySelections()"
                            (stashEntrySelections)="onStashEntrySelections()"
                            (restoreEntrySelections)="onRestoreEntrySelections()"
                            (outboundEvents)="onRegisterEvent($event)"
                            (dismissError)="onDismissError($event)">
                        </cdux-compact-view>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="WageringViewEnum.MOBILE">
                    <cdux-mobile-view
                        [view]="selectedView"
                        (viewChange)="selectView($event)"
                        [section]="selectedSection"
                        (sectionChange)="onSectionChange($event)"
                        [raceStatus]="selectedRaceStatus"
                        [wager]="wagerState"
                        [error]="error"
                        (selectBetTypeFromList)="onSelectBetTypeFromList($event)"
                        (updateRaceNav)="onUpdateRaceNav($event)"
                        (updateBetType)="onUpdateBetType($event)"
                        (updateBetAmount)="onUpdateBetAmount($event)"
                        (updateBetModifier)="onUpdateBetModifier($event)"
                        (updateEntrySelection)="onUpdateEntrySelection($event)"
                        (resetEntrySelections)="onResetEntrySelections()"
                        (stashEntrySelections)="onStashEntrySelections()"
                        (restoreEntrySelections)="onRestoreEntrySelections()"
                        (dismissError)="onDismissError($event)"
                        [inboundEvents]="eventStream"
                        (outboundEvents)="onRegisterEvent($event)"></cdux-mobile-view>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
