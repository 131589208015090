import { combineLatest, Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';

import { RouteSyncManager } from './route-sync-manager.class';
import { ViewSelectionManager } from './view-selection-manager.class';
import { ViewSectionEnum } from '../enums/view-section.enum';
import { WageringViewEnum } from '../enums/wagering-view.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { ViewStateService } from '../services/view-state.service';
import {
    EventsService,
    FeatureToggleDataService,
    ITrackBasic,
    JwtSessionService,
    PlayerGroupsService,
} from '@cdux/ng-common';

export interface WageringNavigationState {
    route: string[]; // Route segments relative to parent route definition.
    view: WageringViewEnum;
    section: ViewSectionEnum;
}

/**
 * There are ultimately 3 things that this class coordinates:
 * - The current route.
 *      * The current route does not necessarily match the currently displayed View. For example,
 *          if you use a deeplink to VIDEO on your mobile sized browser, it will display the MOBILE View
 *          without changing the route, thus ensuring that when the screen is expanded the user will see
 *          the VIDEO View.
 *
 * - The currently visible/selected View.
 *      * The visible View is not always the selected. For example, you could currently have the VIDEO
 *          View selected, but because your browser has a mobile screen size, the MOBILE View is currently displayed.
 *      * Not all Views are selectable. The MOBILE is not selected, but is automatically shown when on
 *          a mobile sized screen.
 *
 * - The currently selected section.
 *      * This does not currently have any special functionality. It is kept here so that Routes, Views, and Section
 *          management are all handled in the same place.
 */
export class ViewNavigationManager extends CduxRxJSBuildingBlock<any, WageringNavigationState> {

    protected _stream: Observable<WageringNavigationState>;

    /** CONTROLS **/
    /**
     * Is responsible for building URLs that match the UI's current state.
     * This is decoupled from the selected View, since there should be no
     * route that directs the user to the mobile View. The displaying of the
     * mobile View is something that's managed (in the ViewSelectionManager)
     * and not selected.
     */
    private _routeSyncManager: RouteSyncManager = new RouteSyncManager();

    /**
     * Is responsible for controlling the currently selected view. It handles
     * - Restrictions based on user group.
     * - Switching to the mobile view when entering into a mobile screen size.
     */
    private _viewSelectionManager: ViewSelectionManager;

    /**
     * Performs no special functionality. Kept here so that Routes, Views, and Section
     * management are all handled in the same place.
     */
    private _selectSection: Subject<ViewSectionEnum> = new Subject<ViewSectionEnum>();
    /** END CONTROLS **/

    /**
     * Constructor
     */
    constructor(
        private _featureToggleDataService: FeatureToggleDataService,
        private _mediaQueryService: CduxMediaToggleService,
        private _playerGroupsService: PlayerGroupsService,
        private _sessionService: JwtSessionService,
        private _viewStateService: ViewStateService,
        private _eventsService: EventsService
    ) {
        super();
        this._viewSelectionManager = new ViewSelectionManager(
            this._featureToggleDataService,
            this._mediaQueryService,
            this._playerGroupsService,
            this._sessionService,
            this._eventsService
        );
        this._init();
    }

    /** EXTERNAL CONTROLS **/
    /**
     * Do cleanup.
     */
    public kill() {
        super.kill();
        this._routeSyncManager.kill();
        this._viewSelectionManager.kill();
        this._selectSection.complete();
    }

    /**
     * Causes: Interface & Route Changes
     *
     * Updates the current view. Should not allow the selection of Mobile, since this is determined by
     * screen size.
     *
     * @param view
     */
    public updateView(view: WageringViewEnum) {
        if (view === WageringViewEnum.MOBILE) {
            return; // Presumably, the user shouldn't be able to select Mobile. It should be chosen by screen size.
        }
        // The route should be based on the selected view, not the managed view.
        this._routeSyncManager.updateView(view);
        this._viewSelectionManager.selectView(view);
    }

    /**
     * Causes: Interface & Route Changes
     *
     * Triggers an update to the currently viewed section.
     *
     * @param section
     */
    public updateSection(section: ViewSectionEnum) {
        this._routeSyncManager.updateSection(section);
        this._selectSection.next(section);
        this._viewStateService.setViewSectionCache(section);
    }

    /**
     * Causes: Route Changes
     *
     * Gives updated information on the track for the route.
     *
     * @param track
     */
    public updateTrack(track: ITrackBasic) {
        this._routeSyncManager.updateTrack(track);
    }

    /**
     * Causes: Route Changes
     *
     * Gives updated information on the race for the route.
     *
     * @param race
     */
    public updateRace(race: string) {
        this._routeSyncManager.updateRace(race);
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = combineLatest([
                this._routeSyncManager.listen(),
                this._viewSelectionManager.listen(),
                this._selectSection
            ])
            .pipe(
                map(([route, view, section]) => {
                    return {
                        route,
                        view,
                        section
                    };
                }),
                // Need to add error handling for each submanager.
                finalize(() => this.kill()),
                takeUntil(this._kill),
                // share()
            );
    }

    /** CUSTOM OPERATORS **/
    /** END CUSTOM OPERATORS **/
}
