import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
    selector: '[cduxDragAndDrop]'
})
export class DragAndDropDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() fileDropped = new EventEmitter<any>();

    // Listen for dragover event so we can apply fileOver class
    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = true;
    }

    // Listen for dragleave event so we can remove fileOver class
    @HostListener('dragleave', ['$event']) public onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.fileOver = false;
    }

    // Listen for drop event so we can emit dropped files
    // as an event to the parent component
    @HostListener('drop', ['$event']) public ondrop(event) {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.fileDropped.emit(files);
        }
    }
}
