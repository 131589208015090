<div class="todays-bets-container">
    <div class="todays-bets-container-header">
        <span class="todays-bets-container-header-title">Today's Bets</span>
        <div class="todays-bets-container-header-btn-view-all smallest">
            <ng-container *cduxMediaToggle="'desk-to-phone'">
                <span (click)="goToAccountHistory()">View All</span>
            </ng-container>
            <ng-container *cduxMediaToggle="'phone'">
                <span (click)="goToAccountHistory(true)">View All</span>
            </ng-container>
        </div>
    </div>
    <div *cduxLoading="'todaysBetsContainer'; strategy:'overlay'; component:loadingDotsComponent;" class="todays-bets-container-bets">
        <cdux-my-bets-container
            [todaysBetsContainer]="true"
            [programView]="programView"
            (initialized)="myBetsLoaded = true; checkAllLoaded();"
            (openTab)="openMenuItem($event)">
        </cdux-my-bets-container>

        <cdux-completed-bets-container
            [todaysBetsContainer]="true"
            [programView]="programView"
            (initialized)="completedBetsLoaded = true; checkAllLoaded();">
        </cdux-completed-bets-container>
    </div>
</div>
