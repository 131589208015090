<div class="my-account-row-wrapper">
    <div *ngIf="!ssoLoginFT" class="my-account-row">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">{{ 'remember-username-title' | cduxTranslate: 'my-account-preferences' }}</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="rememberUserNameChecked" (change)="toggleRememberUserName()"/>
            <div class="checkbox"></div>
        </label>
    </div>
    <div class="my-account-row" *ngIf="supportsDarkMode">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">Use System Theme</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="systemThemeEnabled" (change)="toggleSystemTheme()"/>
            <div class="checkbox"></div>
        </label>
    </div>
    <div class="my-account-row" *ngIf="!systemThemeEnabled && supportsDarkMode">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">Force Dark Mode</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="darkModeEnabled" (change)="toggleDarkMode()"/>
            <div class="checkbox"></div>
        </label>
    </div>
    <div class="my-account-row" *ngIf="expertPicksEnabled">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">Show Expert Picks</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="!expertPicksHidden" (change)="toggleExpertPicks()"/>
            <div class="checkbox"></div>
        </label>
    </div>
    <div class="my-account-row" *ngIf="quickPickEnabled && quickPickAllowed">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">Show Quick Picks</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="!quickPickHidden" (change)="toggleQuickPick()"/>
            <div class="checkbox"></div>
        </label>
    </div>
    <div class="my-account-row" *ngIf="brisPicksEnabled">
        <div class="my-account-row-info">
            <div class="my-account-row-info-header">Show brisPICKS</div>
        </div>
        <label class="label-switch">
            <input type="checkbox" [checked]="!brisPicksHidden" (change)="toggleBrisPicks()"/>
            <div class="checkbox"></div>
        </label>
    </div>
  <div class="my-account-row" *ngIf="mfaEnabled" (click)="goToMfaSettings()">
    <div class="my-account-row-info">
      <div class="my-account-row-info-header">Multifactor Authentication</div>
    </div>
    <div class="my-account-row-arrow-right">
      <i class="icon--arrow-right--white"></i>
    </div>
  </div>
