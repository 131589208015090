import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';

import {
    BetShareDataService,
    DetectionService,
    enumFeatureToggle,
    enumRaceStatus,
    EventClickType,
    FeatureToggleDataService,
    IBetShareDetails,
    RaceDateService,
} from '@cdux/ng-common';
import { LoadingService, LoadingDotsComponent, ToastService, IWager, WagerDisplayStatus, ZendeskChatService } from '@cdux/ng-fragments';

import { BetsViewEnum } from 'app/shared/bets/components/bets-container/bets-container.component';
import { BetShareBusinessService } from 'app/shared/bet-slip/services/betshare.business.service';
import { SocialMediaTypes } from 'app/shared/social/enums/social-media-types.enum';
import { BetsCommonService } from 'app/shared/bets/services/bets-common.service';
import { ExpandedDetailsBusinessService } from 'app/shared/transaction/services';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { Subject, Subscription } from 'rxjs/index';
import { WagerStatusClass } from 'app/shared/transaction/enums';
import * as moment from 'moment';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';


@Component({
    selector: 'cdux-mybets',
    templateUrl: './my-bets.component.html',
    styleUrls: ['./my-bets.component.scss']
})
export class MyBetsComponent implements OnInit, OnDestroy {
    public raceStatusEnum = enumRaceStatus;
    public loadingDotsComponent = LoadingDotsComponent;

    @Output() private expand = new EventEmitter<IWager>();
    @Output() private contract = new EventEmitter<undefined>();
    @Output() private cancel = new EventEmitter<MyBetsComponent>();
    @Output() private openTab = new EventEmitter<BetsViewEnum>();

    @Input() public bet: IWager;
    @Input() public loadingId;
    @Input() index: number;
    @Input() public isVideoToggledOn;
    @Input() public isSaveToggledOn;
    @Input() public isCopyToggledOn;
    @Input() public todaysBetsContainer: boolean = false;

    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        this.isBetPad = (value === enumProgramViews.BETPAD);
    }
    public get programView() {
        return this._programView;
    }

    public toteDate: Date;
    public showCancelFailed: boolean;
    public isCanceling = false;
    public isCopyStarted: boolean = false;
    public isCopySuccess: boolean = false;
    public isProgramAvailable: boolean = false;
    public ownedBetSharesLabel: string;
    public betShareProgressPercentage: number;
    public affiliateId;
    public showSocialMediaIcons: boolean = false;
    public onAndroidTablet: boolean = false;
    public betShareUrl: string = '';
    public socialMediaType = SocialMediaTypes;
    public triggerCopy: boolean = false;
    public socialMediaToggle: boolean;
    public betTotalLabel: string;
    public isBetPad: boolean = false;
    public wagerStatusClass: string;
    public isAdvWagerOnPayoutDate: boolean = false; // boolean to indicate if current toteDate match the advanced
                                                    // wager's payoutDate. True for an adv wager on the payout date,
                                                    // False for adv wager not on the payout day or non-advanced wager
    public canShowWagerControls: boolean = true;
    public zendeskEnabled: boolean;
    public phoneSupportEnabled: boolean;

    // Expose EventClickType so we can use it in the template for click events
    public eventClickType = EventClickType;
    private _destroy: Subject<boolean> = new Subject();
    private _betPadRaceStatusSub: Subscription = null;

    constructor(
        _environment: ENVIRONMENT,
        private _loadingService: LoadingService,
        private betsCommonService: BetsCommonService,
        private raceDateService: RaceDateService,
        private betShareDataService: BetShareDataService,
        private _betShareService: BetShareBusinessService,
        private _detectionService: DetectionService,
        private _toastService: ToastService,
        private _featureToggleDataService: FeatureToggleDataService,
        private _expandedDetailsService: ExpandedDetailsBusinessService,
        private _zendeskChatService: ZendeskChatService,
        private _eventService: EventTrackingService
    ) {
        this.affiliateId = _environment.affiliateId.toString();
    }

    public ngOnInit() {
        this.onAndroidTablet = this._detectionService.isTablet() && this._detectionService.isAndroidDevice();
        this.betShareUrl = this._betShareService.generateLink(this.bet);
        this.raceDateService.isToteDayOrNextDay(this.bet.eventDate).pipe(
            take(1)
        ).subscribe(isToteDay => this.isProgramAvailable = isToteDay);
        this.raceDateService.getToteDate().pipe(
            take(1)
        ).subscribe(toteDate => {
            this.toteDate = toteDate;
            const payoutMoment = moment(this.bet.payoutRaceDate, 'YYYY-MM-DD');
            this.isAdvWagerOnPayoutDate = this.bet.payoutRaceDate && this.bet.payoutTrackBrisCode
                && this.bet.payoutRaceNumber && payoutMoment.isSame(toteDate, 'day');
            // Determine if the wager controls can be shown:
            // for normal wager and adv. wager(not future wager), they are able to display the controls,
            // But for future wager, we only display the controls on the day the wager placed, not afterwards.
            this.canShowWagerControls = true;
             /*   !(     payoutMoment.isSameOrAfter(toteDate, 'day')
                    && payoutMoment.isSameOrAfter(this.bet.activityDate, 'day')
                    && this.bet.eventCode.toUpperCase() === this.bet.payoutTrackAtabCode.toUpperCase() // DE16324 temp fix
                ); // eq. !FutureWager*/
        });
        if (this.bet.betShareData.availableAmount) {
            // What percentage of bet share has not been claimed?
            // Claimed percentage = available amount / total amount * 100
            // Remaining percentage = 100 - claimed percentage
            this.betShareProgressPercentage = 100 - Math.round(+this.bet.betShareData.availableAmount / this.bet.amount * 100);
        }
        this.socialMediaToggle = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.MY_BETS_SOCIAL_MEDIA);
        this.phoneSupportEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this.zendeskEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.ZENDESK_CHAT);
        this.betTotalLabel = (this.bet.condWagerData.conditionalWager ? 'Conditional ' : '' ) + 'Bet Total:';
        this.wagerStatusClass = this.getWagerStatusClass(this.bet.status);
    }

    ngOnDestroy() {
        // trigger subscription self-destruct
        this._destroy.next();
        this._destroy.complete();
    }

    public toggleExpandedState(): void {
        if (!this.bet.isExpanded) {
            // for Adv. wager or future wager, since the payout track code could be different from the track code
            // used for placing the wager, so we need to use the payout track's code/race/date to check race status
            // on the payout date
            let raceStatusTrack;
            let raceStatusRaceNum;
            if (this.isAdvWagerOnPayoutDate) {
                raceStatusTrack = this.bet.payoutTrackBrisCode;
                raceStatusRaceNum = this.bet.payoutRaceNumber;
            } else {
                raceStatusTrack = this.bet.brisCode;
                raceStatusRaceNum = Number(this.bet.raceNum);
            }

            if (this.bet.betShareData.betShare) {
                // For bet shares, I want to show a loader while retrieving data.
                this.showLoader();

                this.betsCommonService.newGetRaceStatus(raceStatusTrack, this.bet.trackType, raceStatusRaceNum).subscribe(raceStatus => this.bet.raceStatus = raceStatus);

                this.betShareDataService.getBetShareDetails(this.bet.betShareData.betShareId, !this.bet.betShareData.betShareCaptain)
                    .pipe(take(1))
                    .subscribe((response: IBetShareDetails) => {
                        this.bet.betShareData.betShareInfo = response;
                        if (this.bet.betShareData.betShareInfo
                            && this.bet.betShareData.betShareInfo.participantNumberOfShares
                            && this.bet.betShareData.betShareInfo.betShareNumberOfShares) {
                            // What percentage of the shares does this user own?
                            // Owned Shares / Total Shares * 100
                            // display up to four decimal places
                            const ownedBetSharePercentage = this._betShareService.betSharePercentage(+this.bet.betShareData.betShareInfo.participantNumberOfShares, +this.bet.betShareData.betShareInfo.betShareNumberOfShares);
                            // Assembling the owned bet shares label
                            // {user's shares} of {total shares} (user's percentage of shares%)
                            this.ownedBetSharesLabel = `${this.bet.betShareData.betShareInfo.participantNumberOfShares} of ${this.bet.betShareData.betShareInfo.betShareNumberOfShares}`
                                + ` (${ownedBetSharePercentage}%)`;
                        }
                        this.expand.emit(this.bet);
                        this.resolveLoader(0);
                    });
            } else {
                this.expand.emit(this.bet);
                if (this.isBetPad) {
                    this.betsCommonService.newGetRaceStatus(raceStatusTrack, this.bet.trackType, raceStatusRaceNum, true)
                        .pipe(takeUntil(this._destroy))
                        .subscribe(raceStatus => this.bet.raceStatus = raceStatus);
                } else {
                    this.betsCommonService.newGetRaceStatus(raceStatusTrack, this.bet.trackType, raceStatusRaceNum)
                        .subscribe(raceStatus => this.bet.raceStatus = raceStatus);
                }
            }
        } else {
            this.bet.isExpanded = false;

            // Cleanup the sub when the expanded details are closed
            if (this._betPadRaceStatusSub) {
                this._betPadRaceStatusSub.unsubscribe();
            }

            this.contract.emit();
        }
    }

    public launchZeMessenger($event: MouseEvent) {
        $event.stopPropagation();
        if (this.zendeskEnabled && !this._zendeskChatService.isScriptLoading()) {
            this.logEvent(EventClickType.SUPPORT_CHAT_REQUEST);
            this._zendeskChatService.setMessengerVisibility(true);
            this._zendeskChatService.openZeMessenger();
        }
    }

    public logEvent(event: EventClickType) {
        this._eventService.logClickEvent(event);
    }

    public toggleDelete(): void {
        this.bet.isDeleted = !this.bet.isDeleted;
        this.bet.isExpanded = !this.bet.isExpanded;
    }

    public showCancelFailure(): void {
        this.showCancelFailed = true;
        this.bet.isDeleted = false;
    }

    public showLoader(): Promise<boolean> {
        return this._loadingService.register(this.loadingId);
    }

    public resolveLoader(timeout: number): Promise<boolean> {
        return this._loadingService.resolve(this.loadingId, timeout, 'success');
    }

    public cancelWager(): void {
        this.isCanceling = true;
        this.bet.isDeleted = !this.bet.isDeleted;
        this._loadingService.register(this.loadingId);
        this.cancel.emit(this);
    }

    public programNavigate(bet: IWager, video?: boolean) {
        if (this.isAdvWagerOnPayoutDate) {
            this._expandedDetailsService.programNavigate(bet.trackName, bet.payoutTrackBrisCode, bet.trackType, bet.payoutRaceNumber, video);
        } else {
            this._expandedDetailsService.programNavigate(bet.trackName, bet.brisCode, bet.trackType, Number.parseInt(bet.raceNum, 10), video);
        }
    }

    public copyBet() {
        if (this.isSaveToggledOn) {
            this.addToBetSlip();
        } else {
            this.betsCommonService.copyAndEditWager(this.bet).then((bet) => {
                this.programNavigate(this.bet);
            });
        }
    }

    public addToBetSlip() {
        this.isCopyStarted = true;
        this.betsCommonService.addToBetSlip(this.bet).subscribe((isCopied) => {
            if (isCopied) {
                this.handleSuccess();
            } else {
                this.isCopyStarted = false;
            }
        })
    }

    public toggleSocialMedia() {
        this.showSocialMediaIcons = !this.showSocialMediaIcons;
    }

    public onLinkCopy() {
        this._toastService.open('Link Successfully Copied!', {
            duration: 2500
        });
    }

    public share(type: SocialMediaTypes) {
        this._betShareService.shareBet(this.bet, type);
    }

    public goToBetSlip(): void {
        setTimeout(() => {
            this.openTab.emit(BetsViewEnum.BET_SLIP);
        }, 800);
    }

    private handleSuccess() {
        this.isCopySuccess = true;
        setTimeout(() => {
            this.isCopySuccess = false;
            this.isCopyStarted = false;
            this.bet.isExpanded = false;
            this.goToBetSlip();
        }, 2000);
    }

    private getWagerStatusClass(status: string): string {
        switch (status) {
            case WagerDisplayStatus.ACCEPTED:
            case WagerDisplayStatus.OPEN:
                return WagerStatusClass.IS_ACCEPTED;
            case WagerDisplayStatus.PENDING:
                return WagerStatusClass.IS_PENDING;
            default:
                return null;
        }
    }
}
