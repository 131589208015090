<div class="deposit-form-info paynearme" [class.is-bet-pad]="isBetPad" *ngIf="!hasError">
    <div class="deposit-main-header" *ngIf="!fullscreen">
        <div class="close-btn" (click)="close()">
            <i class="icon--login-close"></i>
            <span class="close-txt">Close</span>
        </div>
    </div>
    <div class="funding-inner-wrap" [class.fullscreen]="fullscreen">
        <iframe [attr.src]="context.paynearmeFrameSource | cduxSanitizeHtml:'resource'" style="width: 100%; height: 100%; border: none;"></iframe>
    </div>
</div>

<div *ngIf="hasError">
    <div class="deposit-form-wrapper paynearme dark">
        <div class="ts-message error">
            <p>PayNearMe is currently unavailable</p>
            <p>Please select another payment method or contact player services at:</p>
            <p>{{ 'customer-service' | cduxTranslate:affiliateId }}</p>
        </div>
    </div>
</div>
