<div class="entry-container is-results" [class.is-classic]="onClassic">
<ng-container *ngIf="display === displayOptions.ALL || display === displayOptions.PAYOUTS">

    <ng-container *ngIf="resultChart?.raceDetails?.wpsMatrix.length > 0">
        <div class="entry-header is-results" [class.is-compact]="compactView">

            <div class="entry_col_num">
                #
            </div>

            <div class="entry_col_runner">
                Runner
            </div>

            <div class="entry_col_win">
                Win
            </div>

            <div class="entry_col_place">
                Place
            </div>

            <div class="entry_col_show">
                Show
            </div>

        </div>
        <ng-container *ngFor="let entry of resultChart?.raceDetails?.wpsMatrix; trackBy: trackByProgramNumber">
            <div class="entry is-results" [class.is-compact]="compactView">

                <div class="entry_col_num">
                    <div class="saddle-cloth {{ entry.saddleClothClass }}">{{ entry.programNumber }}</div>
                    <div class="program-post-position">PP {{ entry.postPosition }}</div>
                </div>

                <div class="entry_col_runner">
                    <span class="main-detail">{{ entry.horseName }}</span>
                    <span class="sub-detail" *ngIf="entry.jockeyLastName && (entry.jockeyFirstName + ' ' + entry.jockeyMiddleName + ' ' + entry.jockeyLastName).trim() as name">{{ name }}</span>
                    <span class="sub-detail" *ngIf="entry.trainerLastName && (entry.trainerFirstName + ' ' + entry.trainerMiddleName + ' ' + entry.trainerLastName).trim() as name">{{ name }}</span>
                </div>

                <div class="entry_col_win">
                    {{ entry.winPayout ? (entry.winPayout | currency:'USD') : '-' }}
                </div>

                <div class="entry_col_place">
                    {{ entry.placePayout ? (entry.placePayout | currency:'USD') : '-' }}
                </div>

                <div class="entry_col_show">
                    {{ entry.showPayout ? (entry.showPayout | currency:'USD') : '-' }}
                </div>

            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="resultChart?.raceDetails?.exoticPools.length > 0">
        <div class="entry-header is-results pools" [class.is-compact]="compactView">

            <div class="entry_col_pool-type">
                Pool Type
            </div>

            <div class="entry_col_runner-list">
                Runners
            </div>

            <div class="entry_col_payout">
                Payout
            </div>

        </div>
        <ng-container *ngFor="let pool of resultChart?.raceDetails?.exoticPools; trackBy: trackByPoolCode">
            <div class="entry is-results pools" [class.is-compact]="compactView">

                <div class="entry_col_pool-type">
                    {{ pool.base | currency:'USD' | trimPoolBase }} {{ pool.poolName ? pool.poolName : pool.poolCode }}
                </div>

                <div class="entry_col_runner-list">
                    <div class="main-detail" [innerHTML]="pool.result | lineBreaker:'/'"></div>
                    <div class="sub-detail" *ngIf="pool.decodedReason">{{ pool.decodedReason }}</div>
                </div>

                <div class="entry_col_payout">
                    {{ pool.value | currency:'USD' }}
                </div>

            </div>
        </ng-container>
    </ng-container>

</ng-container>
<ng-container *ngIf="(display === displayOptions.ALL || display === displayOptions.FINISH_ORDER) && resultChart?.raceDetails?.finishOrder.length > 0">
    <div class="entry-header is-results finish-order" [class.is-compact]="compactView">

        <div class="entry_col_num">
            #
        </div>

        <div class="entry_col_runner">
            Finish Order
        </div>

    </div>
    <ng-container *ngFor="let entry of resultChart?.raceDetails?.finishOrder; trackBy: trackByProgramNumber">
        <div class="entry is-results finish-order" *ngIf="entry.finishPosition > 0" [class.is-compact]="compactView">

            <div class="entry_col_num">
                <div class="saddle-cloth {{ entry.saddleClothClass }}">{{ entry.programNumber }}</div>
                <div class="program-post-position">PP {{ entry.postPosition }}</div>
            </div>

            <div class="entry_col_runner">
                <cdux-my-fav [id]="'icon-fav-badge-'+entry.brisId" showText *ngIf="checkFavoriteRunner(entry.brisId)"></cdux-my-fav>
                <span class="main-detail">{{ entry.horseName }}</span>
                <span class="sub-detail" *ngIf="entry.jockeyLastName && (entry.jockeyFirstName + ' ' + entry.jockeyMiddleName + ' ' + entry.jockeyLastName).trim() as name">{{ name }}</span>
                <span class="sub-detail" *ngIf="entry.trainerLastName && (entry.trainerFirstName + ' ' + entry.trainerMiddleName + ' ' + entry.trainerLastName).trim() as name">{{ name }}</span>
            </div>

            <div class="entry_col_add-to-stable">
                <cdux-favorite-toggle-runner
                    *ngIf="trackType === TRACK_TYPE_ENUM.TBRED"
                    [favoriteData]="getEntryAsFavorite(entry)"
                    displayFavoritesLabel="true"
                    rightDisplay="false"
                    replays="true"
                    [location]="favoriteLocation.RESULTS_REPLAYS">
                </cdux-favorite-toggle-runner>
            </div>

        </div>
    </ng-container>
</ng-container>
</div>
