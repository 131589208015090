import { ISidebarComponentProperties, ISidebarPortalComponent } from './interfaces/sidebar-portal-component.interface';
import { ElementRef, EventEmitter, Input, Output, ViewChild, Directive } from '@angular/core';

@Directive()
export abstract class CduxAbstractSidebarComponent {

    /**
     * This should get the content that's actually being scrolled.
     *
     * @type {ElementRef}
     */
    @ViewChild('scrollingArea') public scrollingArea: ElementRef;

    /**
     * Can be emitted to manually trigger a reevaluation of the parent's height.
     * The passed number is an optional size of the content if it needs to override
     * the parent's evaluation of the content height.
     *
     * @type {EventEmitter<number|undefined>}
     */
    @Output() public contentSizeChange: EventEmitter<number|undefined> = new EventEmitter();

    /**
     * Should the more content indicator be displayed?
     *
     * @type {boolean}
     */
    @Input() public showScrollingIndicator: boolean = false;

    /**
     * Flag that determines if the component is being loaded by the user going "back" from a child component,
     * using the sidebar back button.
     * @type {boolean}
     */
    public isReturningFromChild: boolean = false;

    /**
     * This method takes an arbitrary list of tokens and returns
     * an ISidebarPortalComponent, suitable for passing to the sidebar service to load
     * the component.
     *
     * THIS METHOD MUST BE OVERRIDDEN IN EXTENDED CLASSES.
     *
     * Typescript does not allow abstract static methods, or else this would be defined as such.
     * Instead, if you do not override this method in extended classes and then try to call it,
     * it will throw an error.
     *
     * It is highly recommended that your  override of this method in extended class specifies
     * specific parameters for each input that your component requires.
     * This will help implementors to know the number and type of inputs that your component requires
     * when implementing it.
     *
     * @param {...any} tokens
     *
     * @returns {ISidebarPortalComponent}
     */
    public static getSidebarComponent(...tokens): ISidebarPortalComponent {
        throw new Error('Cannot call the abstract getSidebarComponent() method of CduxAbstractSidebarComponent. The component that is extending CduxAbstractSidebarComponent should either override this abstract method, or provide an overloaded version with parameters that should be called instead.');
    }

    /**
     * Updates whether the more content indicator should be shown.
     *
     * @param $event
     */
    public toggleScrollingIndicator($event) {
        this.showScrollingIndicator = $event;
    }

    /**
     * Emits a content size change with an override for size of the scrolling area, if available.
     * To make it respond to a particular element's height, set a #scrollingArea element.
     */
    public communicateContentSizeChange() {
        if (this.scrollingArea) {
            // Unfortunately, this gets called on change detection, presumably because it's
            // bound to a portal. This results in this emitting the client height at inconvenient
            // times, like when it hasn't fully rerendered. So I'm setting it in a timeout
            // to make it wait until it's done. And yes, I've tried many things to get this
            // to work without it. Good luck trying, if you're really determined.
            setTimeout(() => {
                this.contentSizeChange.emit(this.scrollingArea.nativeElement.clientHeight);
            });
        } else {
            setTimeout(() => {
                this.contentSizeChange.emit();
            });
        }
    }

    /**
     * Override this method in extended component to set properties that are passed in.
     * The root sidebar component will call this method when your component is initialized
     * and pass in any input properties that were supplied.
     *
     * @param {Map<any, any>} inputs
     * @param {Map<any, any>} outputs
     */
    public abstract setProperties(properties: ISidebarComponentProperties): void;
}
