import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { switchMap, take, map, skip, scan } from 'rxjs/operators';
import { LoadingDotsComponent, LoadingService } from '@cdux/ng-fragments';
import { Observable, Subject, Subscription } from 'rxjs';
import { IGanPlayerInfo, IPreRegistrationForm } from '@cdux/ng-common';
import { GanRegistrationService } from './gan-registration.service';
import { ENVIRONMENT } from '@cdux/ng-core';

@Component({
    selector: 'cdux-gan-widget-container',
    templateUrl: './gan-widget-container.component.html',
    styleUrls: ['./gan-widget-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GanWidgetContainerComponent implements OnInit, OnDestroy {

    public loadingDotsComponent = LoadingDotsComponent;
    public hideGanModal: boolean = true;
    public noDisplayModal: boolean = false;
    public showError: boolean;
    public affiliateId: number;

    private _elements = [];
    private _registrationURL: string;
    private _ganTimeout: number;
    private _filePath = '/churchill/default/{fileType}/eaw.min.{fileType}';
    private _context: { registrationURL: string, close: () => void };

    private _ganResponse: Subject<string> = new Subject<string>();
    private _onScriptLoad: Subject<boolean> = new Subject<boolean>();

    public onScriptLoadSub: Subscription;


    public get ganResponse(): Observable<IPreRegistrationForm> {
        return this._ganResponse.asObservable().pipe(
            switchMap(token => this.getRegInfo(token)
                .pipe(map(response => !!response ? this._ganService.ganResponseToIPreReg(response) : null))
            ));
    }

    @HostListener('window:message', ['$event.data'])
    onMessage(data: string) {
        try {
            const parsedData = JSON.parse(data);
            if (!!parsedData.token) {
                this._ganResponse.next(parsedData.token);
            }
        } catch (e) {}
    }

    public set context(context: { registrationURL: string, ganTimeout: number, close: () => void }) {
        if (!!context?.registrationURL) {
            this._context = context;
            this._registrationURL = context.registrationURL;
            this._ganTimeout = context.ganTimeout;
        }
    }

    constructor (
        private _loadingService: LoadingService,
        private _ganService: GanRegistrationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _environment: ENVIRONMENT
    ) {
        this.affiliateId = this._environment.affiliateId;
    }

    ngOnInit() {
        /**
         *  Force the animation to run if the modal is re-opened
         */
        this._changeDetectorRef.detectChanges();
        this._loadingService.register('ganWidgetContainer');
        this.onScriptLoadSub = this._onScriptLoad.pipe(
            scan((acc, curr) => acc && curr, true),
            /**
             * Currently we are loading two files, so we want to skip the first and fire the
             * subscription after the second value from the second file comes through.
             * If we decided to add more files, the "skip" number would be
             * n (n being the total number of files being loaded) minus 1;
             */
            skip(1),
            take(1)
        ).subscribe(scriptsLoaded => {
            if (scriptsLoaded) {
                this._loadingService.resolve('ganWidgetContainer').then(() => this.hideGanModal = false);
            } else {
                this._loadingService.resolve('ganWidgetContainer', this._ganTimeout).then(() => {
                    this.showError = true;
                    this.noDisplayModal = true;
                });
            }
        });
        this._loadStyle(this._registrationURL + this._filePath.replace(/{fileType}/g, 'css'));
        this._loadScript(`var EXTERNAL_AUTH_HOST="` + this._registrationURL +  `";`)
        this._loadScript(null, this._registrationURL + this._filePath.replace(/{fileType}/g, 'js'));
    }

    ngOnDestroy() {
        for (const script of this._elements) {
            document.head.removeChild(script);
        }
        this.onScriptLoadSub.unsubscribe();
    }

    private _loadScript(innerText?: string, src?: string) {
        const script = document.createElement('script');
        if (!!innerText) {
            script.innerText = innerText;
        }

        if (!!src) {
            script.src = src;
            script.onload = (data) => {
                this._onScriptLoad.next(true);
            };

            script.onerror = (error) => {
                this._onScriptLoad.next(false)
            }
        }
        this._loadElement(script);
    }

    private _loadStyle(src: string) {
        const style = document.createElement('link');
        style.href = src;
        style.type = 'text/css';
        style.rel = 'stylesheet';

        style.onload = (data) => {
            this._onScriptLoad.next(true);
        }

        style.onerror = (error) => {
            this._onScriptLoad.next(false);
        }

        this._loadElement(style);
    }

    private _loadElement(el: HTMLElement) {
        document.head.appendChild(el);
        this._elements.push(el);
    }

    public getRegInfo(token: string): Observable<IGanPlayerInfo> {
        return this._ganService.getGanPlayerInfo(token);
    }

    public closeWidget() {
        this._context?.close();
    }
}
