/**
 * Coerces a Value to a Number
 * @param value - Value to be Coerced
 * @param fallbackValue - Fallback Return Value
 */
export function coerceToNumber(value: any, fallbackValue = 0): number {
  return isNaN(parseFloat(value as any)) || isNaN(Number(value)) ? fallbackValue : Number(value);
}

/**
 * Coerces a Value to a Boolean
 * @param value - Value to be Coerced
 */
export function coerceToBoolean(value: any): boolean {
  return value !== null && `${value}` !== 'false';
}

/**
 * Coerces a Value to a String
 * @param value - Value to be Coerced
 */
export function coerceToString(value: any): string {
  return '' + value;
}
