import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewRef
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, EMPTY, from, iif, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    enumTrackType,
    EventsService,
    IReplayTracks,
    ITrack,
    ToteDataService,
    TrackService,
    VideoAngles,
    JwtSessionService,
} from '@cdux/ng-common';
import { EventKeys } from '../../shared/common/events';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoFeedStates } from './enums/video-feed-states';
import { LiveVideoFeedFactory } from './video-feeds/live-video-feed.factory';
import { IVideoFeed, VideoFeedError } from './video-feeds/video-feed.interface';
import { VideoService } from './services/video.service';
import { TvTrackInterface } from 'app/wagering/video/interfaces/tv-track.interface';
import { enumVideoBarType } from 'app/wagering/video/enums/video-bar-type.enum';
import { takeWhileInclusive } from 'app/shared/common/operators/take-while-inclusive.operator';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';

@Component({
    selector: 'cdux-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent implements OnInit, OnDestroy {
    /**
     * Option for whether to include video selection controls:
     *  Streaming/Replays tabs
     *  Track/IAdwRace selects
     *  View Program/Race buttons
     */
    @Input()
    public controls: boolean = true;

    @Input()
    public show: boolean = false;

    @Input()
    public set track(value: ITrack | IReplayTracks) {
        if (value && !(TrackService.isSameTrack(this._track, value) && value.RaceNum === this.race)) {
            this._track = value;
            this._feedResetThrottle.next();
            this._detectChanges();
        }
    }
    public get track(): ITrack | IReplayTracks {
        return this._track;
    }
    private _track: ITrack | IReplayTracks;

    @Input()
    public set race(value: number) {
        if (value !== this._race) {
            this._race = value;
            this._feedResetThrottle.next();
            this._detectChanges();
        }
    }
    public get race(): number {
        return this._race;
    }
    private _race: number;

    @Input()
    public set trackRace(value: TvTrackInterface) {
        if (value && !(TrackService.isSameTrack(this._track, value.track) && value.track.RaceNum === this.race)) {
            this._track = value.track;
            this._race = value.track.RaceNum;
            this._trackRace = value;
            this.liveRaceNumber = value.liveRaceNumber;

            // When using the track/race inputs, the feedState gets passed in via the input. With trackRace, we need
            // some special logic to determine the feedState. Ideally it would be nice to keep this in the feedResetThrottle
            // sub so that the input setter is a light as possible, but there was a brief visual issue with the video
            // tab bar options updating properly when waiting for the throttle debounce.
            if (((!this.liveRaceNumber || this.race < this.liveRaceNumber) && this.isReplay())) {
                this.feedState = VideoFeedStates.REPLAY;
            } else if ((!!this.liveRaceNumber && this.race >= this.liveRaceNumber) || !this.isReplay()) {
                // if the user is navigating to a live race or future race but they already have the live feed playing
                // just set the feed state, don't worry about trying to reset the video
                this.feedState = VideoFeedStates.LIVE;
            }

            this._feedResetThrottle.next();
        }
    }
    public get trackRace() {
        return this._trackRace;
    }
    private _trackRace;

    @Input()
    public set feedState(feedState: VideoFeedStates) {
        this._feedState = feedState;
    }
    public get feedState() {
        return this._feedState;
    }
    private _feedState;

    @Input()
    public raceDate: string;

    @Input()
    public liveRaceNumber: number;

    @Input()
    public onStandalone: boolean = false;

    @Input()
    public onTV: boolean = false;

    @Input()
    public inlineTabs: boolean = false;

    @Input()
    public videoBarType: enumVideoBarType = enumVideoBarType.TAB;

    @Input()
    public resetOnInit: boolean = true;

    @Input()
    public disableLiveReplayToggle: boolean = false;

    /**
     * This pushes video errors up to the container.
     */
    @Output()
    public error = new EventEmitter<VideoFeedError>();

    @Output()
    public selected = new EventEmitter<{
        track: ITrack | IReplayTracks,
        race?: number,
        raceDate?: string
    }>();

    @Output()
    public feedStateChange = new EventEmitter<VideoFeedStates>();

    @Output()
    public feedAnglesChange = new EventEmitter<VideoAngles[]>();

    @ViewChild(VideoPlayerComponent)
    public set videoPlayer(ref: VideoPlayerComponent) {
        if (this.show) {
            this._videoPlayer = ref;
            this._videoPlayerAvailable.next(true);
            this._detectChanges();
        } else {
            this._videoPlayerAvailable.next(false);
        }
    }
    public get videoPlayer() {
        return this._videoPlayer;
    }
    private _videoPlayer: VideoPlayerComponent;

    public get showTabBar(): boolean {
        return !this.disableLiveReplayToggle ||
            this.feedAngles && this.feedAngles.length > 1;
    }

    public isLoggedIn: boolean = false;

    public feedAngle: VideoAngles = null;
    public feedAngles: VideoAngles[] = [];

    public liveVideoAvailable: boolean = true;

    public videoBarTypeEnum = enumVideoBarType;

    private _authSub: Subscription;
    private _feedFactorySub: Subscription;
    private _liveVideoEndSub: Subscription;
    private _feedAnglesSub: Subscription;

    private _feedResetThrottle: Subject<any> = new Subject();
    private _destroy: Subject<any> = new Subject();

    private _liveVideoThreshold: number = null;

    private _videoPlayerAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // Current status of the selected track's live video
    private _hasEnded: boolean;

    // This kind of stinks but we need to know when the track has changed when deciding whether to reset the feed in
    // the throttle. We don't want to reset the feed if they haven't switched tracks and are staying on live video.
    // We can't send a value when track or trackRace change via _feedResetThrottle.next() because the events queue
    // up, so in the case of legacy TUX which uses track and race as separate inputs, depending on the order the inputs
    // change, we may next TRUE then FALSE. This makes the throttle look like the track has not changed even though it has.
    // Doing this also allows for not needing to switch the feed if we queue track B then queue track A
    // again (probably not going to happen from normal user interaction but who knows).
    private _currentlyShownTrack: ITrack | IReplayTracks;
    private _currentlyShownRace: number;

    // We also have need to maintain what feed state we are currently on for comparisons done in the feedResetThrottle sub
    private _currentlyShownFeed: VideoFeedStates;

    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _eventsService: EventsService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _toteDataService: ToteDataService,
        private _videoFeedFactory: LiveVideoFeedFactory,
        private _videoService: VideoService,
        private _todaysRacesBusinessService: TodaysRacesBusinessService
    ) {}

    ngOnInit() {
        this._authSub = this._sessionService.onAuthenticationChange
            .subscribe((auth) => {
                this.isLoggedIn = auth;
                this._detectChanges();
            });
        this.isLoggedIn = this._sessionService.isLoggedIn();
        this._detectChanges();
        this._eventsService.on(EventKeys.RACE_NAV_RACE_CHANGE).subscribe((race) => {
            // when the race changes but it's still a replay, show the requested replay
            if ((!this.liveRaceNumber || race.race < this.liveRaceNumber) && this.isReplay()) {
                this.onSelect({ track: this.track, race: race.race, raceDate: this.raceDate });
            } else if ((!!this.liveRaceNumber && race.race >= this.liveRaceNumber) || !this.isReplay()) {
                // if the user is navigating to a live race or future race but they already have the live feed playing
                // just set the feed state, don't worry about trying to reset the video
                this.feedState = VideoFeedStates.LIVE;
            }
        });

        const liveVideoStartThreshold = this._videoService.getLiveVideoThreshold().then((threshold) => this._liveVideoThreshold = threshold, () => this._liveVideoThreshold = null);

        this._feedResetThrottle.pipe(
            filter(() => {
                let ret = true;
                if (!this.track) {
                    ret = false;
                } else {
                    if (!this.race) {
                        ret = this.onStandalone && this.feedState === VideoFeedStates.LIVE;
                    }
                }
                // Combine our above special situations with checking if anything we actually care about has changed
                return ret && !(TrackService.isSameTrack(this.track, this._currentlyShownTrack) && this.race === this._currentlyShownRace && this.feedState === this._currentlyShownFeed);
            }),
            debounceTime(200),
            switchMap(() => {
                // TODO - should this be done in the track and trackRace setters to get a head start?
                const trackChanged = !TrackService.isSameTrack(this.track, this._currentlyShownTrack);
                const raceChanged = this.race !== this._currentlyShownRace;

                let liveVideoEndObs;

                if (trackChanged) {
                    // Always get rid of the sub when the track has changed.
                    // _updateLiveVideoEndedSub also does this, but we don't always call that function.
                    if (this._liveVideoEndSub) {
                        this._liveVideoEndSub.unsubscribe();
                    }

                    // If we are not showing the live/replay toggle or we are on standalone and we are wanting to view a REPLAY then bypass the request for the live video end.
                    // TODO - will this cause problems if we hit the if and then a subsequent feedReset request wants the same
                    // track but disableLiveReplayToggle is now false?
                    if ((this.onStandalone || this.disableLiveReplayToggle) && this.feedState === VideoFeedStates.REPLAY) {
                        liveVideoEndObs = of(false);
                    } else {
                        liveVideoEndObs = this._updateLiveVideoEndedSub(this.track.BrisCode, this.track.TrackType)
                    }
                } else {
                    liveVideoEndObs = of(this._hasEnded);
                }

                // Go ahead and reset these so we aren't potentially trying to show an angle a race doesn't have
                if (trackChanged || raceChanged) {
                    this.feedAngle = null;
                    this.feedAngles = [];
                }

                let feedAnglesObs: Observable<VideoAngles[]>;
                if (this.preGrabFeedAngles() && (trackChanged || raceChanged)) {
                    feedAnglesObs = this._updateFeedAnglesSub();
                } else {
                    feedAnglesObs = of(null);
                }

                return combineLatest([
                    of(trackChanged).pipe(take(1)),
                    // The update of the video start threshold takes place in the then of the promise in ngOnInit.
                    // We include it here so we can make sure we wait for the request to return before resetting the video
                    // feed but the subscription doesn't use this value at all.
                    from(liveVideoStartThreshold).pipe(take(1)),
                    liveVideoEndObs.pipe(take(1)), // Just take the initial value
                    // A null value from this observable means to ignore the check for available angles if attempting to watch replay
                    feedAnglesObs.pipe(take(1)), // Just take the initial value
                    this._videoPlayerAvailable.asObservable().pipe(take(1)) // TODO - what if the video player is false?
                ])
            }),
            takeUntil(this._destroy),
        ).subscribe(
            ([trackChanged, liveStartThreshold, liveVideoEnded, feedAngles, playerAvailable]) => {
                // Once we have all of our data, we need to determine whether or not we should update the feed.
                // We want to skip setting a new feed if the user has not changed track and is going from LIVE -> LIVE
                // and if they are on standalone, going from LIVE -> REPLAY, and don't have a race set (this means they will keep
                // viewing live until they select a race)
                const updateFeed =
                    !(!trackChanged && (this._currentlyShownFeed === VideoFeedStates.LIVE && this.feedState === VideoFeedStates.LIVE))
                    && !(this.onStandalone && (this._currentlyShownFeed === VideoFeedStates.LIVE && this.feedState === VideoFeedStates.REPLAY && !this.race));

                if (updateFeed) {
                    // Determine if live video is available and force REPLAY if it is not
                    // If we are on standalone we don't want to hide the live video toggle as that's their only obvious way back to live
                    if (this.onStandalone) {
                        this.liveVideoAvailable = true;
                    } else {
                        this.liveVideoAvailable = !liveVideoEnded;
                    }

                    // If the live video has ended, force replay
                    this.feedState = this.liveVideoAvailable ? this.feedState : VideoFeedStates.REPLAY;

                    // After the check against live video, we know what final state we would like to update to.
                    // When the feedAngles obs returns something other than null, we will need to check against that
                    // value to make sure we have replays available if we are trying to switch to them.
                    if (feedAngles !== null && !feedAngles.length && this.feedState === VideoFeedStates.REPLAY) {
                        if (!this.liveVideoAvailable) {
                            this.videoPlayer.handleFeedError(VideoFeedError.VIDEO_UNAVAILABLE);
                            return;
                        } else {
                            this.feedState = VideoFeedStates.LIVE;
                        }
                    }

                    // For the things that need it emit a feedstate change. For example, the video container will need
                    // to know about the change in the event we are forcing replay so the pop out button navigates
                    // to the correct starting state.
                    if (this.feedState !== this._currentlyShownFeed) {
                        this.feedStateChange.emit(this.feedState);
                    }

                    // Update our new current feed values
                    this._currentlyShownTrack = this.track;
                    this._currentlyShownFeed = this.feedState;

                    // Finally, request the new video feed
                    // If we are on standalone, the live video has ended, and LIVE is currently selected, throw up
                    // an error message
                    if (this.onStandalone && liveVideoEnded && this.feedState === VideoFeedStates.LIVE) {
                        this.videoPlayer.handleFeedError(VideoFeedError.VIDEO_UNAVAILABLE);
                    } else {
                        this.resetVideoFeed(this.race);
                    }

                } else {
                    // TODO - can we get here?
                    // Make sure this flag gets reset in the event that we don't want to check liveVideo.
                    this.liveVideoAvailable = true;
                }

                // Always update the race as the race may change but the feed may stay the same (i.e. LIVE -> LIVE)
                this._currentlyShownRace = this.race;

                // Since we reset feedAngle and feedAngles in the switchMap we need angular to update the view
                this._detectChanges();
            }
        );

        this._feedResetThrottle.next();
    }

    ngOnDestroy() {
        if (this._feedFactorySub) { this._feedFactorySub.unsubscribe(); }
        if (this._authSub) { this._authSub.unsubscribe(); }
        this._destroy.next();
        this._destroy.complete();
    }

    public onSignInClicked() {
        this._sessionService.redirectLoggedInUserUrl = this._router.url.split('?', 1)[0];
        this._sessionService.redirectInputs = this._router.parseUrl(this._router.url).queryParams;
        this._router.navigate(['/login']);
    }

    public onFeedStateChange(event: VideoFeedStates) {
        this.feedState = event;
        this.feedStateChange.emit(event);

        if (!this.onStandalone) {
            if (this.isReplay()) {
                this.onSelect({ track: this.track, race: this.race, raceDate: this.raceDate });
            } else {
                this.onSelect({ track: this.track, race: this.liveRaceNumber, raceDate: this.raceDate });
            }
        }

        this._detectChanges();
    }

    public onFeedAnglesChange(angles: VideoAngles[]) {
        // TODO - decide if ignoring the video player emission is correct
        if (!this.preGrabFeedAngles()) {
            this.feedAngles = angles;
        }

        this.feedAnglesChange.emit(angles);
    }

    public onSelect($event: { track: ITrack | IReplayTracks, race: number, raceDate: string }) {
        this._track = $event.track;
        // Only update the race when we are on standalone. If we update in other instances, there is an issue with the
        // replay button disappearing when we use the tab bar to switch from a replay back to live since we update
        // the race to the live race, when we are still viewing a replay.
        if (this.onStandalone) {
            this.race = $event.race;
        }
        this.raceDate = $event.raceDate;
        this._feedResetThrottle.next();
        this._detectChanges();
    }

    public handleFeedError(error: VideoFeedError) {
        this.error.emit(error);
    }

    private _detectChanges() {
        if (!(this._changeDetector as ViewRef).destroyed) {
            this._changeDetector.detectChanges();
        }
    }

    private isReplay(): boolean {
        return this.feedState === VideoFeedStates.REPLAY;
    }

    private resetVideoFeed(race?: number) {
        if (!race) {
            race = this.race;
        }

        if (!this.track || !this.track.BrisCode || !this.track.TrackType) {
            if (this.videoPlayer) {
                this.videoPlayer.handleFeedError(VideoFeedError.VIDEO_UNAVAILABLE);
            }
            return;
        }

        let feedFactoryObs: Observable<ComponentFactory<IVideoFeed> | VideoFeedError>;
        if (this.isReplay()) {
            if (!this.raceDate) {
                feedFactoryObs = this._toteDataService.currentRaceDate().pipe(
                    take(1),
                    switchMap(
                        (raceDate) => {
                            this.raceDate = raceDate;
                            this.selected.emit({ track: this.track, race: race, raceDate: raceDate });
                            return this._videoFeedFactory.getVideoFeedComponent(this.track.BrisCode, this.track.TrackType, race, raceDate);
                        }
                    )
                );
            } else {
                feedFactoryObs = this._videoFeedFactory.getVideoFeedComponent(this.track.BrisCode, this.track.TrackType, race, this.raceDate);
                this.selected.emit({ track: this.track, race: race, raceDate: this.raceDate });
            }
        } else {
            if (this._liveVideoThreshold !== null) {
                const trackAsTrack: ITrack = this.track as ITrack;

                if (trackAsTrack.RaceNum <= 1 && trackAsTrack.Mtp > this._liveVideoThreshold) {
                    this.videoPlayer.handleFeedError(VideoFeedError.VIDEO_UNAVAILABLE);
                    return;
                }
            }

            feedFactoryObs = this._videoFeedFactory.getVideoFeedComponent(this.track.BrisCode, this.track.TrackType);
            this.selected.emit({ track: this.track });
        }

        if (this._feedFactorySub) { this._feedFactorySub.unsubscribe(); }
        this._feedFactorySub = feedFactoryObs.pipe(
            map(
                (feed: ComponentFactory<IVideoFeed> | VideoFeedError) => {
                    if (typeof feed === 'string') {
                        throw new Error(feed);
                    } else {
                        return feed;
                    }
                }
            ),
            catchError((e: VideoFeedError) => {
                this.handleFeedError(e);
                if (this.videoPlayer) {
                    this.videoPlayer.handleFeedError(e);
                }
                return EMPTY;
            })
        ).subscribe(f => {
            this.videoPlayer.feedFactory = f;
            this._detectChanges();
        });
    }

    private _updateLiveVideoEndedSub(brisCode: string, trackType: enumTrackType) {
        if (this._liveVideoEndSub) {
            this._liveVideoEndSub.unsubscribe();
        }

        const liveVideoEndObs = this._videoService.hasLiveVideoEnded(brisCode, trackType);

        this._liveVideoEndSub = liveVideoEndObs.pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (hasEnded) => {
                this._hasEnded = hasEnded;
                if (this.feedState !== VideoFeedStates.LIVE) {
                    this.liveVideoAvailable = !this._hasEnded;
                    this._detectChanges();
                }
            }
        );

        return liveVideoEndObs;
    }

    private _updateFeedAnglesSub(): Observable<VideoAngles[]> {
        if (this._feedAnglesSub) {
            this._feedAnglesSub.unsubscribe();
        }

        // We want to check against the race status to be smarter about when to actually make the call to get angles
        const obs: Observable<VideoAngles[]> = this._todaysRacesBusinessService.getTodaysRace(this.track.BrisCode, this.track.TrackType, this.race, true).pipe(
            // Keep taking items until the race isn't wagerable
            takeWhileInclusive((race) => TrackService.isWagerableRace(race.status), true),
            // If the race is still wagerable, return an empty array.
            // Otherwise grab the angles.
            switchMap(
                (race) => iif(
                    () => TrackService.isWagerableRace(race.status),
                    of([]),
                    this._getFeedAnglesObs()
                )
            ),
            takeUntil(this._destroy)
        );

        this._feedAnglesSub = obs.subscribe(
            (feedAngles: VideoAngles[]) => {
                this.feedAngles = feedAngles;
                this._detectChanges();
            }
        );

        return obs;
    }

    private _getFeedAnglesObs(): Observable<VideoAngles[]> {
        let feedAnglesObs;
        if (!this.raceDate) {
            feedAnglesObs = this._toteDataService.currentRaceDate().pipe(
                take(1),
                switchMap((raceDate) => this._videoService.feedAnglesObs(this.track.BrisCode, this.track.TrackType, this.race, raceDate))
            );
        } else {
            feedAnglesObs = this._videoService.feedAnglesObs(this.track.BrisCode, this.track.TrackType, this.race, this.raceDate)
        }
        return feedAnglesObs.pipe(
            // Take until we have both angles available
            takeWhileInclusive((angles: VideoAngles[]) => !(angles.includes(VideoAngles.PAN) && angles.includes(VideoAngles.HEADON)), true),
        )
    }

    private preGrabFeedAngles(): boolean {
        return this.videoBarType === this.videoBarTypeEnum.BUTTON;
    }
}
