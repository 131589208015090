import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    enumPoolType,
    IPoolsTotal,
    ITrackBasic, PoolType,
    RaceInfoService,
    ToteDataService,
    TrackService
} from '@cdux/ng-common';
import { ExoticPoolsRequestHandler } from 'app/shared/betpad/classes/exotic-pools-request-handler.class';
import { debounce, filter, takeUntil, tap } from 'rxjs/operators';
import { interval, Subject } from 'rxjs/index';
import { DisplayModeEnum } from 'app/shared';

@Component({
    selector: 'cdux-exotic-pools-shared',
    templateUrl: './exotic-pools-shared.component.html',
    styleUrls: ['./exotic-pools-shared.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExoticPoolsSharedComponent implements OnInit, OnDestroy {
    @Input()
    public set track(track: ITrackBasic) {
        if (!TrackService.isExactTrackObject(this._track, track)) {
            this._track = track;
            this._race = track.RaceNum; // If we already have a race number go ahead and set it
            this._updatePools.next();
        }
    }
    public get track(): ITrackBasic {
        return this._track;
    }
    private _track: ITrackBasic;
    @Output() exoticPoolsAvailable = new EventEmitter<boolean>();

    @Input()
    // Note that race might be set when the track is updated if the RaceNum is present on the ITrackBasic
    public set race(race: number) {
        if (race !== this._race) {
            this._race = race;
            this._updatePools.next();
        }
    }
    public get race(): number {
        return this._race;
    }
    private _race: number;

    @Input()
    public set displayMode(displayMode: DisplayModeEnum) {
        this.isCompact = displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE;
        this._displayMode = displayMode;
    }
    public get displayMode(): DisplayModeEnum {
        return this._displayMode;
    }
    private _displayMode: DisplayModeEnum;

    public pools: IPoolsTotal[];
    public isCompact: boolean;

    public EnumPoolType = enumPoolType;

    private _exoticPoolsRequestHandler: ExoticPoolsRequestHandler;
    private _updatePools: Subject<any> = new Subject();
    private _destroy: Subject<any> = new Subject();

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _raceInfoService: RaceInfoService,
        private _toteDataService: ToteDataService
    ) {
        this._exoticPoolsRequestHandler = new ExoticPoolsRequestHandler(this._raceInfoService, this._toteDataService)
    }

    ngOnInit() {
       this._exoticPoolsRequestHandler.listen().pipe(
       ).subscribe(
           (data) => {
                this.pools = [];

                if (data && data.hasOwnProperty('PoolTotals') && Array.isArray(data.PoolTotals)) {
                    data.PoolTotals.forEach((pool) => {
                        if (pool.Amount > 0) {
                            this.pools.push(pool);
                        }
                    });
                }
                this.exoticPoolsAvailable.emit(this.pools.length > 0);
                this._changeDetectorRef.markForCheck();
           }
       );

       this._updatePools.pipe(
           filter(() => !!(this.track.BrisCode && this.track.TrackType && this.race)),
           debounce(() => interval(100)),
           tap(() => this._exoticPoolsRequestHandler.updateRaceNavigation({BrisCode: this.track.BrisCode, TrackType: this.track.TrackType, RaceNum: this.race} as ITrackBasic)),
           takeUntil(this._destroy)
       ).subscribe();

        this._updatePools.next();
    }

    ngOnDestroy() {
        this._exoticPoolsRequestHandler.kill();
        this._destroy.next();
        this._destroy.complete();
    }

    public getPoolString(pool: IPoolsTotal) {
        let poolString = '$';

        const poolBase = typeof pool.Base === 'string' ? parseInt(pool.Base, 10) : pool.Base;
        poolString += poolBase < 1 ? poolBase.toPrecision(2) : poolBase;

        const poolModel = PoolType.getPoolTypeByCode(pool.PoolType);
        poolString += ' ' + (poolModel ? poolModel.displayName : pool.PoolType);

        return poolString;
    }
}
