import { OnInit, Directive } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { IUserInfo, ENVIRONMENT } from '@cdux/ng-core';

import { SsnCollectionService, SsnCollectionError } from './services/ssn-collection.service';
import { CduxSidebarContentComponent } from '../sidebar/cdux-sidebar-content-component.class';
import { ISidebarComponentProperties } from '../sidebar/interfaces/sidebar-portal-component.interface';

import { enumSsnReason, JwtSessionService } from '@cdux/ng-common';

const RE_SS4_FIRST_DIGIT = /^(\d)...$/;

@Directive()
export abstract class SsnCollectionComponentBase extends CduxSidebarContentComponent implements OnInit {
    /**
     * We need the affiliate ID to determine which affiliate "translation"
     * to use in the template for the customer support number.
     *
     * @type {string}
     */
    public readonly AFFILIATE_KEY = this._environment.affiliateId.toString();

    protected userSession: IUserInfo;

    public ssn3 = '';
    public ssn2 = '';
    public ssn4: string;

    public submitting = false;
    public submitted = false;
    public success   = false;
    public canRetry  = false;

    public setProperties(properties: ISidebarComponentProperties) {}

    constructor(
        private _environment: ENVIRONMENT,
        protected sessionService: JwtSessionService,
        protected ssnCollectionService: SsnCollectionService
    ) { super(); }

    ngOnInit() {
        this.getSS4();
    }

    public getSS4(): string {
        this.userSession = this.sessionService.getUserInfo();
        if (this.userSession.cssd) {
            this.ssn4 = this.userSession.cssd.replace(RE_SS4_FIRST_DIGIT, '$1***');
        }
        return this.ssn4;
    }

    public submit (reason: enumSsnReason = enumSsnReason.FROZEN): Observable<boolean> {
        this.submitting = true;
        return this.ssnCollectionService.updateSsn(this.userSession.camId, this._environment.affiliateId, this.ssn3 + this.ssn2, reason).pipe(
            mergeMap((response) => {
                this.submitting = false;
                if (typeof response === 'boolean') {
                    this.canRetry  = this.ssnCollectionService.canRetry();
                    this.success   = !!response;
                    this.submitted = true;
                    return of(true);
                } else {
                    this.canRetry  = this.ssnCollectionService.canRetry();
                    this.success   = false;
                    this.submitted = true;
                    return of(false);
                }
            }),
            catchError((error: SsnCollectionError) => {
                this.submitting = false;
                this.canRetry  = this.ssnCollectionService.canRetry();
                this.success   = false;
                this.submitted = true;
                return of(false);
            })
        );
    }
}
