<div class="expert-pick-bio-container">
    <div>
        <div *cduxMediaToggle="'phone'">
            <h1 *cduxMediaToggle="'desk-to-phone'">Handicapper Bio</h1>
            <figure class="image--profile" *ngIf="expertPick.expertImageUrl">
                <img width="65" height="65" [src]="expertPick.expertImageUrl">
            </figure>
        </div>
            <h2 class="expert-pick-bio-header">
                {{ expertPick.header }}
            </h2>
            <p class="expert-pick-bio-content">
                {{ expertPick.expertBio }}
            </p>
    </div>
</div>