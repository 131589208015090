import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CduxMediaModule } from '@cdux/ng-platform/web';
import { BetPadSharedModule } from 'app/shared/betpad/betpad-shared.module';
import { LayoutModule } from 'app/shared/layout/layout.module';

import { TvModule } from '../tv/tv.module';
import { ClassicModule } from '../classic/classic.module';
import { SharedWageringViewsModule } from '../shared/wagering-views/wagering-views.module';
import { WageringViewsRoutingModule } from './views.routing.module';
import { ViewFoundationComponent } from './view-foundation.component';
import { ViewNavigationComponent } from './view-navigation.component';
import { CompactModule } from '../compact/compact.module';
import { MobileViewModule } from '../mobile/mobile-view.module';
import { TuxCommonModule } from '../../shared/common/common.module';
import { ViewStateService } from './services/view-state.service';

@NgModule({
    imports: [
        BetPadSharedModule,
        CduxMediaModule,
        CommonModule,
        LayoutModule,
        TvModule,
        ClassicModule,
        CompactModule,
        SharedWageringViewsModule,
        WageringViewsRoutingModule,
        MobileViewModule,
        TuxCommonModule
    ],
    exports: [
        WageringViewsRoutingModule
    ],
    declarations: [
        ViewFoundationComponent,
        ViewNavigationComponent
    ],
    providers: [
        ViewStateService
    ]
})
export class WageringViewsModule {
    public static forRoot(): ModuleWithProviders<WageringViewsModule> {
        return {
            ngModule: WageringViewsModule,
            providers: [
            ]
        }
    }
}
