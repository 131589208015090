import { Location } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';

import { ENVIRONMENT, IAuthenticationResult } from '@cdux/ng-core';
import {
    Bet,
    ConfigurationDataService,
    enumConfigurationStacks,
    enumFeatureToggle,
    EventClickType,
    FeatureToggleDataService,
    IOfferAssignment,
    IPreRegistrationForm,
    JwtSessionService,
    OffersDataService,
    OfferType,
    RegistrationResponse,
    RegistrationStatesEnum,
    TranslateService,
    UserEventEnum,
    ValidationService,
} from '@cdux/ng-common';
import { CduxMediaToggleService, CduxStorageService, ModalService } from '@cdux/ng-platform/web';
import { BrazeUtils } from '@cdux/ts-domain-braze';

import { Offer } from 'app/offers/classes/offer';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractRegistrationComponent } from './abstract-registration.component';
import { RegistrationEvent } from './registration-event.enum';
import { RegistrationExternalRedirect } from './registration-external-redirect.enum';
import { RegistrationUserEventStatusEnum } from './registration-user-event-status.enum';
import { RegistrationNotificationService } from './registration-notification.service';
import { GanWidgetContainerComponent } from './gan-widget-container.component';
import { ModalRef } from '@cdux/ng-platform/web';
import { ToastService } from '@cdux/ng-fragments';
import { IncomeAccessService } from 'app/analytics/income-access.service';
import { BetShareService } from '../../bet-share/services/bet-share.service';

export interface IPromotionResponse {
    loggedIn: boolean,
    offerName: string,
    offerAssignment: IOfferAssignment
}

enum RegistrationSteps {
    CONTACT = 0,
    ADDRESS = 1,
    ACCOUNT = 2
}

const CURRENT_STEP_CLASS = 'is-current';
const COMPLETE_STEP_CLASS = 'is-complete';

@Component({
    selector: 'cdux-fullpage-registration',
    templateUrl: 'fullpage-registration.component.html',
    styleUrls: ['fullpage-registration.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FullpageRegistrationComponent extends AbstractRegistrationComponent implements OnInit {

    public registrationSteps = RegistrationSteps;
    public firstName: string;
    public ftFullPageDeposit = false;
    public ftGANRegistration = false;
    public showIDScan: boolean = false;
    public isSmallGlass: boolean = false;
    public loginClicked: boolean = false;
    public offerName: string;
    public registrationSuccessful: boolean = false;
    public showBottomButtons: boolean = true;
    public showRetryButton: boolean = false;
    public softFailCount: number = 0;
    public ganRegistrationURL: string;
    public ganErrorTimeout: number;
    public affiliateName: string;
    public affiliatePhone: string;
    public uiAffiliatePhone: string;
    public affiliateDisclaimer: string;
    public stateErrorMessages;
    public phoneEnabled: boolean;
    public showRegLoginFooterTag: boolean = this._environment.regLoginFooterTag ? this._environment.regLoginFooterTag : false ;
    public showAccessibilityMessage: boolean;

    // Create class strings for the progress bar
    public currentStep: number = RegistrationSteps.CONTACT;
    public contactStepClass: string = CURRENT_STEP_CLASS;
    public addressStepClass: string;
    public accountStepClass: string;

    // This overrides the abstract preRegistration input...
    // TODO: pass the offercode to formBuilder via preRegistration object
    public preRegistration: IPreRegistrationForm = {};

    private _isFirstPage: boolean = true;
    private _modalRef: ModalRef<GanWidgetContainerComponent>;

    private readonly affDisclaimerKey = 'affiliate-disclaimer';
    private _betShareService: BetShareService;

    
    constructor(
        localConfigService: ConfigurationDataService,
        localEnvironment: ENVIRONMENT,
        localEventTrackingService: EventTrackingService,
        localFeatureToggleService: FeatureToggleDataService,
        localIncomeAccessService: IncomeAccessService,
        @Inject(BrazeUtils)  localBrazeUtils: BrazeUtils,
        localRegNotificationService: RegistrationNotificationService,
        localOffersDataService: OffersDataService,
        localRoute: ActivatedRoute,
        private _betSlipService: BetSlipBusinessService,
        private _location: Location,
        private _mediaQuery: CduxMediaToggleService,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _storageService: CduxStorageService,
        protected _translateService: TranslateService,
        private _modalService: ModalService,
        private _toastService: ToastService,
        private readonly injector: Injector
    ) {
        super(
            localConfigService,
            localFeatureToggleService,
            localIncomeAccessService,
            localBrazeUtils,
            localEnvironment,
            localEventTrackingService,
            localRegNotificationService,
            localOffersDataService,
            localRoute,
        );
        this._betShareService = this.injector.get(BetShareService);
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._destroy),
        ).subscribe(
            (e: NavigationEnd) => {
                if (e.hasOwnProperty('id')) {
                    this._isFirstPage = (e.id <= 1);
                }
            }
        );
        this.phoneEnabled = localFeatureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this.ftFullPageDeposit = localFeatureToggleService.isFeatureToggleOn('FULL_PG_DEPOSIT');
        this.ftGANRegistration = localFeatureToggleService.isFeatureToggleOn(enumFeatureToggle.GAN_REGISTRATION);

        if (this.ftGANRegistration) {
            localConfigService.getConfiguration(enumConfigurationStacks.TUX, ['GAN_SUBDOMAIN', 'GAN_ERROR_TIMEOUT'])
                .pipe(
                    take(1),
                    catchError(err => of(null))
                ).subscribe(config => {
                    this.ganRegistrationURL = !!config && !!config['GAN_SUBDOMAIN'] ? config['GAN_SUBDOMAIN'] : null;
                    this.ganErrorTimeout = !!config && !!config['GAN_ERROR_TIMEOUT'] ? +config['GAN_ERROR_TIMEOUT'] : 0;
                });
        }

        this.affiliateName = this._translateService.translate('affiliate-name', localEnvironment.affiliateId.toString()) || '';
        this.affiliatePhone = this._translateService.translate('primary-phone-hyperlink', localEnvironment.affiliateId.toString());
        this.uiAffiliatePhone = this._translateService.translate('primary-phone-number', localEnvironment.affiliateId.toString());
        // show if entry exists in affiliate lang file
        if(this._translateService.translationExists(this.affDisclaimerKey, localEnvironment.affiliateId.toString())) {
            this.affiliateDisclaimer = this._translateService.translate(this.affDisclaimerKey, localEnvironment.affiliateId.toString())
        }
        this.stateErrorMessages = {...ValidationService.stateErrorMessages};
        if(this._translateService.translationExists('red-state-message', localEnvironment.affiliateId.toString())) {
            this.stateErrorMessages.prohibited = this._translateService.translate('red-state-message', localEnvironment.affiliateId.toString());
        }
        this.showAccessibilityMessage = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ACCESSIBILITY_MESSAGE);
    }

    ngOnInit() {
        this.isSmallGlass = this._mediaQuery.query('phone');
        super.ngOnInit();
        this.logUserEvent(UserEventEnum.REGISTRATION_STEP, { registrationStep: RegistrationUserEventStatusEnum.START});
        this.checkRouteForBetShare();
    }

    checkRouteForBetShare() {
        const currentRoute = this._router.url;
        if (currentRoute.includes('/betshare')) {
            this.initiatedFromBetShare = true;
        }
    }

    public onRegistrationSubmitted() {
        this.logRegistrationSubmitEvent();
    }

    public onRegistrationComplete(regResponse: RegistrationResponse) {
        if (regResponse && regResponse.hasOwnProperty('status')) {
            if (regResponse.status === RegistrationStatesEnum.SUCCESS) {
                // User is successfully registered. Happy days ensue.
                this._regNotificationService.onRegistrationEvent.next({regEvent: RegistrationEvent.REGISTRATION_COMPLETE});
                this._login(regResponse);
            } else if (regResponse.status === RegistrationStatesEnum.SOFT_FAIL_ID_SCAN) {
                this.showIDScan = true;
                // If not on small glass, show a retry button but only the first time.
                this.showRetryButton = !this.isSmallGlass && !this.softFailCount;
                this.softFailCount++;
                this.hasErrors = true;
                this.registrationStatus = regResponse.status;
                this.regResponse = regResponse;
                this._logOtherFailureType(regResponse);
            } else {
                if (regResponse.status === RegistrationStatesEnum.SOFT_FAIL) {
                    this.showRetryButton = this.softFailCount === 0;
                    this.softFailCount++;
                }
                this._logOtherFailureType(regResponse);
            }
        } else {
            this._logOtherFailureType(regResponse);
        }
    }

    public triggerPasswordReset(username: string) {
        this._eventTrackingService.logClickEvent(EventClickType.REGISTRATION_RESET_PASSWORD);
        this._redirectToPath('forgot-password-path');
    }

    public navigateToDocumentUpload() {
        this._redirectToPath('registration-fail-verify-path');
    }

    /**
     * Because we have a progress bar that lives outside the formbuilder component
     * we need to respond to step change events emitted by the formbuilder
     * so the progress bar stays in sync.
     * @param $event
     */
    public onStepChange($event) {
        this.resetScroll();
        switch ($event) {
            case RegistrationSteps.CONTACT:
                this.currentStep = $event;
                this.contactStepClass = CURRENT_STEP_CLASS;
                this.addressStepClass = '';
                this.accountStepClass = '';
                break;
            case RegistrationSteps.ADDRESS:
                this.currentStep = $event;
                this.contactStepClass = COMPLETE_STEP_CLASS;
                this.addressStepClass = CURRENT_STEP_CLASS;
                this.accountStepClass = '';
                this.logUserEvent(UserEventEnum.REGISTRATION_STEP, {registrationStep: RegistrationUserEventStatusEnum.STEP_1})
                break;
            case RegistrationSteps.ACCOUNT:
                this.currentStep = $event;
                this.addressStepClass = COMPLETE_STEP_CLASS;
                this.accountStepClass = CURRENT_STEP_CLASS;
                this.logUserEvent(UserEventEnum.REGISTRATION_STEP, {registrationStep: RegistrationUserEventStatusEnum.STEP_2})

                break;
            default:
                break;
        }
    }

    public redirectToLogin(): void {
        this.loginClicked = true;
        this._router.navigate(['/login'], { replaceUrl: true });
    }

    /**
     * Close the registration component but either
     * a) going back if they came from within TUX or
     * b) sending them to the home page of TUX if they came from outside.
     */
    public closeClicked() {
        if (!this._isFirstPage) {
            this._location.back();
        } else {
            this._router.navigate(['/']);
        }
    }

    public resetErrors(): void {
        this.hasErrors = false;
        this.registrationStatus = 0;
        this.regResponse = {};
        this.currentStep = RegistrationSteps.CONTACT;
    }

    /**
     * Because this component needs to display the name entered into the child
     * component, we need to have a method to save it when the name change event
     * is emitted.
     * @param name
     */
    public saveFirstName(name: string) {
        this.firstName = name;
    }

    /**
     * Return the text that displays under the progress bar according to the
     * current step in the registration process.
     */
    public getHelpText(): string {
        switch (this.currentStep) {
            case RegistrationSteps.CONTACT:
                const offerSegment = this.validOffer?.code ? ' to get your bonus' : '';
                return 'Sign up' + offerSegment + '. Only takes a minute to join.';
                break;
            case RegistrationSteps.ADDRESS:
                return 'Enter your address exactly as it appears on your driver’s license.';
                break;
            case RegistrationSteps.ACCOUNT:
                const firstName = this.firstName ? ', ' + this.firstName : '';
                return 'Almost there' + firstName + '. Let’s finish setting up your ' + this.affiliateName + ' account.';
                break;
        }
    }

    /**
     * sets image to default in case of error
     *
     * This does NOT get called in an infinite loop if the default
     * image is also missing.
     */
    public fetchDefaultOfferImage () {
        this._offerImgSubject.next(this._OFFER_IMAGE_DEFAULT);
    }

    // TODO: move to full-page deposit for redirect
    /**
     * Determine action intended by post reg click
     * @param goToDeposit Boolean, whether or not to direct player to deposit widget
     */
    postSuccessfulRegClick(goToDeposit: boolean) {
        let redirectUrl = this._sessionService.redirectLoggedInUserUrl || '/';
        // Clear variable after using it.
        this._sessionService.redirectLoggedInUserUrl = null;
        // Check redirectUrl to determine if it is the offers details page
        if (redirectUrl === '/offers/details') {
            // We cleared any saved offer so, to redirect to the details screen,
            // we need to link directly to the offer details
            redirectUrl = '/offers/' + this.validOffer.code + '/details';
        }
        const params = this._sessionService.redirectInputs || {};
        this._sessionService.redirectInputs = null;

        // If params has an input parameter, remove it. It was for the registration
        // component and we've used it.
        delete params.inputs;

        if (goToDeposit) {
            Object.assign(params, {action: 'deposit'});
        }
        this._router.navigate([redirectUrl], {queryParams: params});
    }

    /**
     * Event handler for onIdScanComplete
     * @param success
     */
    public onIdScanComplete(success: boolean) {
        this.showIDScan = false;
        if (success) {
            this.regResponse.status = RegistrationStatesEnum.SUCCESS;
            this.hasErrors = false;
            this.onRegistrationComplete(this.regResponse);
        } else {
            this.onRegistrationComplete({status: RegistrationStatesEnum.SOFT_FAIL});
        }
    }

    /**
     * Determine if offer banner should be shown.
     */
    public showOfferBanner() {
        return this.validOffer && this.validOffer.code && !this.registrationSuccessful && !this.hasErrors;
    }

    /**
     * Set error state for a non soft id scan fail.
     * @param regResponse
     */
    private _logOtherFailureType(regResponse: RegistrationResponse): void {
        this.hasErrors = true;
        this.registrationStatus = regResponse.status;
        this.logUserEvent(UserEventEnum.REGISTRATION_COMPLETE, this.buildUserEventObject({
            status: RegistrationUserEventStatusEnum.FAIL,
            failReason: this.RegistrationStatesEnum[regResponse.status],
            failType: this.translateStringKeyMap[regResponse.status],
            eventName: UserEventEnum.REGISTRATION_FAIL
        },
            regResponse));
        this._regNotificationService.onRegistrationEvent.next({regEvent: RegistrationEvent.REGISTRATION_ERROR});
    }

    private _login(regResponse: RegistrationResponse): any {
        const userData = {
            status: RegistrationUserEventStatusEnum.SUCCESS,
            failReason: '',
            failType: '',
        }
        const handleFailures = () => {
            // The registration completed but couldn't log the user in so we won't
            // get a camId but should still record the successful registration.
            this.logUserEvent(UserEventEnum.REGISTRATION_COMPLETE, this.buildUserEventObject(userData, regResponse));


            const newBet = Bet.fromBetlike(this._betSlipService.currentBet);
            newBet.runners = [];
            newBet.id = '';
            this._betSlipService.currentBet = newBet;
            this._router.navigate(['/login']);
        };

        // Submit login
        this._sessionService.login(regResponse.login.user, regResponse.login.pass)
            .pipe(
                catchError( () => of(false) ),
                concatMap((result: IAuthenticationResult) => {
                    if (result.loggedIn && regResponse.offer) {
                        return this._handlePromotion(regResponse, result.loggedIn);
                    }
                    return of({loggedIn: result.loggedIn, offerName: undefined, offerAssignment: undefined} as IPromotionResponse);
                })
            )
            .subscribe(
                (promotionResponse: IPromotionResponse) => {
                    if (promotionResponse.loggedIn) {
                        // Wait until after login to record the event in tealium so that the camId
                        // is recorded in the datalayer.
                        this.logUserEvent(UserEventEnum.REGISTRATION_COMPLETE, this.buildUserEventObject(userData, regResponse));
                        this.setBrazeDeviceUser(regResponse.camGlobalId);
                        this._regNotificationService.onRegistrationEvent.next({regEvent: RegistrationEvent.REGISTRATION_SUCCESS, offerAssignment: promotionResponse.offerAssignment});

                        // If we are redirecting to an external url we don't need to bother with anything else
                        if (!this.isBetShare && this.externalRedirect && RegistrationExternalRedirect[this.externalRedirect]) {
                            window.location.href = RegistrationExternalRedirect[this.externalRedirect];
                        }

                        if (this.initiatedFromBetShare) {
                            this._betShareService.goToDeposit()
                            return;
                         }

                        if (!this.isBetShare) {
                            this.registrationSuccessful = true;

                            // Until we are able to make the full page deposit component responsive, we're going to
                            // continue to send small glass users to the original deposit widget as it is already
                            // displayed in full screen. To make the behavior more consistent we are removing the
                            // option to skip deposit and sending them directly to the deposit widget after a successful
                            // registration.
                            //
                            // TODO: remove feature toggle
                            setTimeout(() => {
                                if (this.ftFullPageDeposit) {
                                    this._router.navigate([ 'deposit' ]);
                                } else {
                                    this.postSuccessfulRegClick(true)
                                }
                            }, 3000);
                        }
                    } else {
                        handleFailures();
                    }
                },
                handleFailures
            );
    }

    private _redirectToPath(key: string): void {
        // get & check path
        let path = this._translateService.translate(
            key,
            this.affiliateId.toString()
        );
        path = (path !== key) ? path : null;

        if (path) {
            this._router.navigate([ path ]);
        }
    }

    private _handlePromotion(regResponse: RegistrationResponse, loggedIn: boolean): Observable<IPromotionResponse> {

        // TODO: Use the offers business service method to claim the offer. This will not only claim
        // the offer but also add it to the active offers.

        return this._offersDataService.assign(regResponse.offer.code, regResponse.offer.type).pipe(
            map((assignResponse: IOfferAssignment): IPromotionResponse => {
                const repsonseObject: IPromotionResponse = {
                    loggedIn: loggedIn,
                    offerAssignment: undefined,
                    offerName: undefined
                };
                this._regNotificationService.onRegistrationEvent.next({regEvent: RegistrationEvent.OFFER_ASSIGNMENT,
                    offerAssignment: assignResponse});
                if (assignResponse.OptIn.errors.length < 1 || regResponse.offer.type !== null) {

                    // send return with correct offer display name
                    this.offerName = repsonseObject.offerName = (regResponse.offer.type === OfferType.BONUS) ? assignResponse.OptIn.bonusDisplayName : assignResponse.OptIn.promoDisplayName;
                    repsonseObject.offerAssignment = assignResponse;

                    // Before we proceed, we need to clear any saved offers from local storage.
                    // If we don't, the offers detail resolver will attempt to claim the offer again.
                    this._storageService.destroy({db: Offer.DB, _id: Offer.KEY})
                }

                return repsonseObject;
            }),
            catchError(() => of({loggedIn: loggedIn, offerName: undefined, offerAssignment: undefined} as IPromotionResponse))
        );
    }

    public openGANWidget(): void {
        if (!this._modalRef) {
            this._modalRef = this._modalService.open(GanWidgetContainerComponent, {
                context: {
                    registrationURL: this.ganRegistrationURL,
                    ganTimeout: this.ganErrorTimeout,
                    close: () => {
                        this._modalService.closeAll();
                    }
                },
                width: '500px'
            });
            this._modalRef.componentInstance.ganResponse
                .pipe(
                    takeUntil(this._modalRef.afterClosed
                        .pipe(tap(() => this._modalRef = null))
                    )
                ).subscribe(regResponse => {
                    if (!!regResponse) {
                        this.preRegistration = regResponse;
                    } else {
                        this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
                    }
                    this._modalService.closeAll();
                });
        }
    }
}
