import { ENVIRONMENT, ENVIRONMENTS, AFFIDS } from '@cdux/ng-core';

const affId = AFFIDS.BARSTOOL;
const username = 'my_tux';

export const environment: ENVIRONMENT = {
    environment: ENVIRONMENTS.PRODUCTION,
    production: true,
    affiliateId: affId,
    domain: 'charliedeltaindia.com',
    username: username,
    setSecureCookie: true,
    accountMenuConfig: {
        hideRecallTodaysBets: true,
        hideRewards: true,
        hideTaxDocuments: false,
        hideDetailedAccountHistory: true,
        hideStableAlert: true
    },
    thirdPartyConfig: {
        'Box': {
            'Folder': '1234567890',
            'Token': 'aBcDeFgHiJk',
            'Token_Simplified_Upload': '1234567abcdefg',
        },
        'RightNow': {
            'Subdomain': 'NEWAFFILIATE--tst',
            'Category': 10
        },
        'ZendeskChatURL': 'https://static.zdassets.com/ekr/snippet.js?key=1234567-abcdefg'
    },
    appConfig: {
        servicesBaseUrl: '',
        username: username,
        environmentUsername: username,
        platformId: 'TUX',
        affiliateId: affId,
        clientIp: '0.0.0.0',
        outputType: 'json'
    }
};
