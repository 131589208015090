import { Component, OnInit, OnDestroy, AfterViewInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    FavoriteRunnersService,
    IFavoriteRunner,
    IFavoriteRunnerDisplay,
    TFavoriteUUID,
    TranslateService,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';

import { EAccountNotificationBadge } from 'app/shared/notification/badges/account-notification-badge.enum';
import { NotificationBadgeService } from 'app/shared/notification/badges/notification-badge.service';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { ToastService } from '@cdux/ng-fragments';
import { OverlayRef } from '@angular/cdk/overlay';
import { FavEventType } from '../favorites-event-interface';
import { AccountBubbleNotificationService } from 'app/shared/notification/services/account-bubble-notification.service';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { IDetailsOptions } from 'app/shared/program/interfaces/details.interface';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { FavoriteLastSortByEnums } from "app/account/favorites/enums/favorite-last-sort-by-enums";

@Component({
    // selector: 'cdux-favorites-manager-runners',
    templateUrl: './favorites-manager-runners.component.html',
    styleUrls: [ './favorites-manager-runners.component.scss' ],
})
export class FavoritesManagerRunnersComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('updateModalTemplate') protected updateModalTemplate: TemplateRef<unknown>;
    @ViewChild('removeModalTemplate') protected removeModalTemplate: TemplateRef<unknown>;
    @ViewChild('replaysModalTemplate') protected replaysModalTemplate: TemplateRef<unknown>;


    public modalContent: Portal<any>;
    public updateTemplatePortal: TemplatePortal<any>;
    public removeTemplatePortal: TemplatePortal<any>;
    public replaysTemplatePortal: TemplatePortal<any>;
    public readonly COUNT_MAX = 4096;
    public countCurrent = 0;
    public options: IDetailsOptions;
    public isAlphabetical: boolean = true;
    public isChronological: boolean = false;
    public lastSortBy: string = FavoriteLastSortByEnums.RUNNER;     // default behavior

    private _overlayRef: OverlayRef;
    private _uuid: TFavoriteUUID;

    public modalRunner: IFavoriteRunner;

    public tempComment: string;
    public static KEY_LAST_SEEN_TOTE_DATE = 'lastToteDate_';

    private _favRunnerSub: Subscription;

    /**
     * holds display of "no favorites" until empty list loaded
     *
     * This could be unnecessary after loading dots are implemented (USER-4257).
     */
    public favoritesEmpty = true;

    public favoritesToday: Partial<IFavoriteRunnerDisplay>[] = [];
    public favoritesElse: IFavoriteRunner[] = [];
    private _destroy: Subject<boolean> = new Subject();

    public isPhone: boolean = false;

    private mediaSub: Subscription;

    public constructor (
        private _favRunners: FavoriteRunnersService,
        private _notificationBadgeService: NotificationBadgeService<EAccountNotificationBadge>,
        private _viewContainerRef: ViewContainerRef,
        private _toastService: ToastService,
        private _router: Router,
        private _translateService: TranslateService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _eventTrackingService: EventTrackingService,
    ) {}

    // TODO: move toteDate, destroy to abstract

    public ngOnInit () {
        // clear notification badge
        this._notificationBadgeService.remove(EAccountNotificationBadge.FAVORITE_RUNNER_TODAY);
        if (this._favRunnerSub) {
            this._favRunnerSub.unsubscribe();
        }

        this._favRunnerSub = this._favRunners.favorites
        .pipe(
            takeUntil(this._destroy)
        ).subscribe(
            (favoritesData: IFavoriteRunner[]) => {
                this.favoritesToday = [];
                this.favoritesElse = [];
                this.favoritesEmpty = true;
                if (favoritesData.length > 0){
                    this.favoritesEmpty = false;

                    favoritesData.forEach(runner => {
                        if (runner?.todaysEntryDetails.length > 0) {
                            this.favoritesToday.push(runner);
                        }

                        if (runner?.todaysEntryDetails.length == 0) {
                            this.favoritesElse.push(runner);
                        }
                    });

                    this.resortLists();
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.mediaSub) { this.mediaSub.unsubscribe(); }
        this._destroy.next();
        this._destroy.complete();
    }

    ngAfterViewInit() {
        this.updateTemplatePortal = new TemplatePortal(
            this.updateModalTemplate,
            this._viewContainerRef
        );
        this.removeTemplatePortal = new TemplatePortal(
            this.removeModalTemplate,
            this._viewContainerRef
        );
        this.replaysTemplatePortal = new TemplatePortal(
            this.replaysModalTemplate,
            this._viewContainerRef
        )
    }

    public get note () {
        return this.tempComment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.tempComment = value;
        }
    }

    public edit (runner: IFavoriteRunner) {
        this.modalRunner = runner;
        this.tempComment = runner.comment || '';
        this.countCurrent = this.tempComment.length;
        this.modalContent = this.updateTemplatePortal;
    }

    public confirmUpdate() {
        // The favorite modal handles validation of the input regarding the
        // limits so we don't need to check them again here.
        // In case the save fails, we don't want to alter the original favorite YET so...

        // 1. Clone the favorite
        const dupeFav = Object.assign({}, this.modalRunner);
        dupeFav.comment = this.tempComment;
        // 2. Call to update the favorite
        this._favRunners.update(dupeFav).then((updated) => {
            if (updated) {
                // 3. If save was successful. Replace original favorite with the new one
                this.modalRunner = dupeFav;
            } else {
                // 4. Show some sort of error message? Seek clarification.
                this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }

            this.dismissModal();
            this.resortLists();
        });
    }

    public dismissModal() {
        this.modalContent = undefined;
        this._overlayRef.detach();
    }

    public assignOverlay(overlayRef: OverlayRef) {
        this._overlayRef = overlayRef;
    }

    public remove(runner: IFavoriteRunner) {
        this.modalRunner = runner;
        this._uuid = runner.uuid;

        this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE_LINK);

        this.modalContent = this.removeTemplatePortal;
    }

    public replays(runner: IFavoriteRunner) {
        this.modalRunner = runner;
        if (!this.isPhone) {
            this.options = {
                brisCode: undefined,
                trackType: undefined,
                raceNumber: undefined,
                programNumber: undefined,
                horseId: this.modalRunner.runnerId,
                view: WageringViewEnum.CLASSIC,
            }
            this.modalContent = this.replaysTemplatePortal;
        } else {
            this._router.navigateByUrl([
                '/program',
                WageringViewEnum.CLASSIC,
                'horse',
                this.modalRunner.runnerName,
                'id',
                this.modalRunner.runnerId
            ].join('/'));
        }
    }

    public confirmRemove() {
        this._favRunners.remove(this._uuid).then((removed) => {
            if (removed) {
                this._logClickEvent(EventClickType.STABLE_ALERTS_REMOVE);
                // call AccountBubbleNotificationService.dispatch to send event about favor runner removed
                this._accountBubbleNotificationService.dispatch({
                    type: FavEventType.FAVORITE_RUNNER_REMOVE,
                    payload: {
                        message: '<i class="icon--check-light-circle"></i>' + this.modalRunner.runnerName + ' removed from <span class="bold-text">stable</span>.',
                        duration: 3000
                    }
                });
            }
            if (!removed) {
              this._toastService.cduxWarning(this._translateService.translate('fallback', 'service-errors'));
            }

            this.resortLists();
        });
        this.dismissModal();
    }

    public trackRunner (fav: IFavoriteRunner) {
        return fav[FavoriteRunnersService.UUID_FIELD];
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        switch (clickType) {
            case EventClickType.STABLE_ALERTS_REMOVE:
            case EventClickType.STABLE_ALERTS_REMOVE_LINK:
                let favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_FAVORITE_TYPE;
                let favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LINK_LOCATION;

                if (clickType === EventClickType.STABLE_ALERTS_REMOVE) {
                    favType = EventClickAttributeType.STABLE_ALERTS_REMOVE_FAVORITE_TYPE;
                    favLoc = EventClickAttributeType.STABLE_ALERTS_REMOVE_LOCATION;
                }

                this._eventTrackingService.logClickEvent(
                    clickType,
                    [
                        {
                            attrId: favType,
                            data: FavoriteTypeEnum.RUNNER,
                            timestamp: ts
                        },
                        {
                            attrId: favLoc,
                            data: FavoriteLocationEnum.STABLE_ALERTS,
                            timestamp: ts
                        },
                    ]
                );
                break;

            default:
                this._eventTrackingService.logClickEvent(clickType);
        }
    }

    public formatLastActiveDate(dt: string): string {
        return isNaN(Date.parse(dt)) ? dt : formatDate(dt, 'MM/dd/yyyy', 'en-US');
    }

    private resortLists() {
        // re-sort Manage Stable list according to lastSortBy method.
        if (this.lastSortBy === FavoriteLastSortByEnums.RUNNER) {
            this.sortRunner(!this.isAlphabetical);
        } else {
            this.sortActivityDate(!this.isChronological);
        }
    }

    public sortRunner(alphabetical: boolean) {

        this.isAlphabetical = !alphabetical;
        this.lastSortBy = FavoriteLastSortByEnums.RUNNER;

        this.favoritesElse.sort((a, b) => {
            const nameA = a.runnerName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.runnerName.toUpperCase(); // ignore upper and lowercase

            if (this.isAlphabetical) {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            } else {
                if (nameA >= nameB) {
                    return -1;
                }
                if (nameA <= nameB) {
                    return 1;
                }
            }

            // names must be equal
            return 0;
        });
    }

    public sortActivityDate(isChronological: boolean) {

        this.isChronological = !isChronological;
        this.lastSortBy = FavoriteLastSortByEnums.ACTIVITY_DATE;

        const noneArray = [];
        const threeYearArray = [];
        const dateArray = [];

        this.favoritesElse.map((favorite)=>{
            if (favorite.latestActivityDate.toUpperCase() === 'NONE') {
                noneArray.push(favorite);
            } else if (favorite.latestActivityDate.toUpperCase() === '3 YEARS+') {
                threeYearArray.push(favorite);
            } else {
                dateArray.push(favorite);
            }
        })

        dateArray.sort((a, b) => {
            const dateA = a.latestActivityDate;
            const dateB = b.latestActivityDate;

            if (this.isChronological) {
                if (dateA >= dateB) {
                    return -1;
                }
                if (dateA <= dateB) {
                    return 1;
                }
            } else {
                if (dateA < dateB) {
                    return -1;
                }
                if (dateA > dateB) {
                    return 1;
                }
            }
            return 0;
        });

        if (this.isChronological) {
            this.favoritesElse = noneArray.concat(dateArray, threeYearArray);
        } else {
            this.favoritesElse = threeYearArray.concat(dateArray, noneArray);
        }
    }
}
