import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Md5 } from 'ts-md5/dist/md5';
import { ReplaySubject } from 'rxjs';

type Md5Hash = string|Int32Array; // as returned from Md5.hashAsciiStr()

interface IBreadcrumb {
    id: Md5Hash;
    label: string;
    url: string;
}

@Injectable()
export class BreadcrumbsService {

    public static readonly TRUNCATE_BREADCRUMBS = true;

    public breadcrumbUpdates: ReplaySubject<boolean> = new ReplaySubject();

    /**
     * stack of breadcrumb objects
     */
    private _breadcrumbs: IBreadcrumb[] = [];
    public get breadcrumbs () {
        return this._breadcrumbs.slice();
    }

    public static generateBreadcrumb (label: string, url: string, id?: string): IBreadcrumb {
        let idHash;
        if (typeof id === 'undefined') {
            idHash = Md5.hashAsciiStr(url);
        } else if (typeof id === 'string') {
            idHash = Md5.hashAsciiStr(id);
        } else {
            throw new TypeError('Invalid breadcrumb id.');
        }

        return {
            label: label,
            url: url,
            id: idHash
        } as IBreadcrumb;
    }

    constructor (
        private _location: Location
    ) {}

    /**
     * shortcut for adding breadcrumb for current URL
     *
     * (This should really be called addCurrentUrl, since it has nothing
     * to do with routes.)
     *
     * @param breadcrumbLabel
     * @param truncateBreadcrumbs
     */
    public addCurrentRoute (breadcrumbLabel: string, truncateBreadcrumbs = false) {
        this.addBreadcrumb(
            BreadcrumbsService.generateBreadcrumb(breadcrumbLabel, this._location.path()),
            truncateBreadcrumbs
        );
    }

    public addBreadcrumb (breadcrumb: IBreadcrumb, truncateBreadcrumbs = false) {
        if (truncateBreadcrumbs === BreadcrumbsService.TRUNCATE_BREADCRUMBS) {
            this._breadcrumbs.splice(0);
        } else {
            const index = this.searchRouteInBreadcrumbs(breadcrumb.id);
            if (index > -1) {
                this._breadcrumbs.splice(index);
            }
        }

        /*
         * To prevent any errors from assigning by reference, make a copy
         * of the given breadcrumb to push onto the stack.
         */
        this._breadcrumbs.push(Object.assign({}, breadcrumb) as IBreadcrumb);
        this.breadcrumbUpdates.next(true);
    }

    public clearBreadcrumbs () {
        this._breadcrumbs.splice(0);
        this.breadcrumbUpdates.next(true);
    }

    private searchRouteInBreadcrumbs(searchKey: Md5Hash): number {
        return this._breadcrumbs.findIndex(x => x.id === searchKey);
    }
}
