import { Component, Input, OnInit } from '@angular/core';
import { AbstractManageInputComponent } from '../abstract-manage-input.component';
import { InputOrnamentTypeEnum, ValidationService } from '@cdux/ng-common';
import { enumManageAccountType } from '../../enums/manage-account.enum';

@Component({
    selector: 'cdux-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent extends AbstractManageInputComponent implements OnInit {

    @Input() public phoneErrorMessages = ValidationService.cellphoneErrorMessages;
    public readonly phoneMask = ValidationService.cellphoneMask;

    constructor() {
        super(enumManageAccountType.CELL_NUMBER);
    }

    ngOnInit() {
        this.newControl = this.formGroup.controls['phone'];
    }

    public getIcon(): any {
        const icons = [];

        const className = this.isPhoneValid() ? 'icon--valid-check' : 'icon--remove--amber';
        icons.push({type: InputOrnamentTypeEnum.ICON, value: className});

        return icons;
    }

    public isPhoneValid(): boolean {
        if (!this.newControl.dirty) { return true; }   // Assume the default value is valid
        return this.newControl.valid;
    }

}
