    <div class="cdi-terms-container" [ngClass]="{'sidebar': context.isSidebar}">
        <header class="cdi-terms-main-header" *ngIf="showModalHeader">
            <div class="icon--arrow-left" (click)="closeModal()"></div>
            <div class="branding icon--logo"></div>
        </header>
        <div class="cdi-terms-body">
            <div class="cdi-terms-wrapper sitewide">
                <div class="terms-conditions-header-row" (click)="closeModal()" *ngIf="context.isSidebar">
                        <div class="back-button-container" >
                            <div class="back-button">
                                <div class="back-button__icon">
                                </div>
                                <div class="back-button__text">
                                    Back
                                </div>
                            </div>
                        </div>
                </div>
                <div class="cdi-terms" #TermsContainer>
                   <div class="cdi-terms-content" [innerHTML]="context.html"></div>
                </div>
            </div>
        </div>
    </div>
