import { AFFILIATE_LANGUAGE_DICTIONARY } from './affiliate';
import { FUNDING_LANGUAGE_DICTIONARY } from './funding';
import { INPUT_LANGUAGE_DICTIONARY } from './input';
import { WAGER_LANGUAGE_DICTIONARY } from './wager';
import { SERVICE_LANGUAGE_DICTIONARY } from './services';
import { PROGRAM_LANGUAGE_DICTIONARY } from './program';
import { MY_ACCOUNT_LANGUAGE_DICTIONARY, MY_ACCOUNT_PREFERENCES_LANGUAGE_DICTIONARY } from './my-account';
import { TOURNAMENTS_LANGUAGE_DICTIONARY } from './tournaments';

export const LANGUAGE_DICTIONARY = {
  ...AFFILIATE_LANGUAGE_DICTIONARY,
  ...FUNDING_LANGUAGE_DICTIONARY,
  ...INPUT_LANGUAGE_DICTIONARY,
  ...WAGER_LANGUAGE_DICTIONARY,
  ...SERVICE_LANGUAGE_DICTIONARY,
  ...PROGRAM_LANGUAGE_DICTIONARY,
  ...MY_ACCOUNT_LANGUAGE_DICTIONARY,
  ...MY_ACCOUNT_PREFERENCES_LANGUAGE_DICTIONARY,
  ...TOURNAMENTS_LANGUAGE_DICTIONARY
};
