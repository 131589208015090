import { Component, ChangeDetectorRef, OnInit, OnDestroy, Input } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalService, DropupModalConfig, DropupPrebuiltComponent, ModalRef } from '@cdux/ng-platform/web';

import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, IPlaidLinkOnExitMetadata, IPlaidLinkOnSuccessMetadata, JwtSessionService, TranslateService, ILinkTokenResponse, FundingStatusEnum, IPLaidError } from '@cdux/ng-common';

import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { uiDisplayEnum, buttonTextEnum } from "app/shared/funding/shared/enums/funding-ui.enum";
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractEzmoneyMethodDepositComponent } from './abstract-ezmoney-method-deposit.component';
import { FundingEzmoneyService } from '../../../shared/services/ezmoney.service';
import { FundingService } from '../../../shared/services/funding.service';
import { IFundingMethodComponentProperties } from '../../../shared/interfaces/funding-sidebar-component.interfaces';
import { ISidebarComponentProperties, ISidebarPortalComponent } from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { HeaderService } from 'app/shared/header/services/header.service';
import { PlaidBusinessService } from 'app/shared/funding/shared/services/plaid.business.service';
import { of, Subscription } from 'rxjs';
import { switchMap, take, catchError, takeUntil } from 'rxjs/operators';
import { EzmoneyModalComponent } from './ezmoney-modal.component';
import { ToastService } from '@cdux/ng-fragments'
import { IFundingEvent } from 'app/shared/funding/shared/interfaces/funding.interfaces';

@Component({
    selector: 'cdux-funding-ezmoney-method-deposit-fullpage',
    templateUrl: './ezmoney-method-deposit-fullpage.component.html',
    styleUrls: ['./ezmoney-method-deposit-fullpage.component.scss']
})
export class FundingEzmoneyMethodDepositFullPageComponent extends AbstractEzmoneyMethodDepositComponent implements OnInit, OnDestroy {

    protected readonly isFullPage: boolean = true;
    public uiDisplayEnum = uiDisplayEnum;
    public buttonTextEnum = buttonTextEnum;
    public isSmallGlass = false;
    // public eventClickType = EventClickType;
    public enumDepositDisplayStyle = enumFundingDisplayStyle;
    public successMessage = '';
    public playerName: string = '';
    public uiDisplay: uiDisplayEnum;
    public buttonText: buttonTextEnum;

    private _modalRef: ModalRef<EzmoneyModalComponent>;

    @Input()
    public previousFundingEvent: IFundingEvent;

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        const component = super.createSidebarPortal(FundingEzmoneyMethodDepositFullPageComponent, options);
        if(options.previousFundingEvent) {
            component.properties.inputs.set('previousFundingEvent', options.previousFundingEvent);
        }
        return component;
    }
    private _replaceFundAccountSub: Subscription;

    public setProperties(properties: ISidebarComponentProperties) {
        if(properties.inputs.has('previousFundingEvent')) {
            this.previousFundingEvent = properties.inputs.get('previousFundingEvent')
        }
        super.setProperties(properties);
    }
    public static readonly GENERIC_ERROR_MESSAGE : string = "If you would like to deposit funds, please select a deposit method.";

    constructor(
        localRouter: Router,
        localEnvironment: ENVIRONMENT,
        localFb: UntypedFormBuilder,
        localFundingService: FundingService,
        localSidebarService: SidebarService,
        localEventTrackingService: EventTrackingService,
        localCdr: ChangeDetectorRef,
        localEzMoneyService: FundingEzmoneyService,
        localCurrencyPipe: CurrencyPipe,
        localTranslateService: TranslateService,
        localSessionService: JwtSessionService,
        localHeaderService: HeaderService,
        localToggleService: FeatureToggleDataService,
        private _mediaToggle: CduxMediaToggleService,
        private _plaidBusinessService: PlaidBusinessService,
        private _modalService: ModalService,
        private _toastService: ToastService
    ) {
        super(
            localRouter,
            localEventTrackingService,
            localCdr,
            localEzMoneyService,
            localCurrencyPipe,
            localTranslateService,
            localEnvironment,
            localFb,
            localFundingService,
            localSidebarService,
            localSessionService,
            localHeaderService,
            localToggleService,
        );
    }

    ngOnInit() {
        this.isSmallGlass = this._mediaToggle.query('phone');
        super.ngOnInit();
       this._replaceFundAccountSub = this._fundingService.onReplaceFundingAccount.subscribe(() => {
           this.replaceMethod();
       });
        if (this.plaidEnabled) {
            if (this.fundingMethodDetails.accountInfo) {
                this.uiDisplay = uiDisplayEnum.EXISTING_CARD;
                this.buttonText = this.inboundBet ? buttonTextEnum.DEPOSIT_AND_BET : buttonTextEnum.DEPOSIT;
                if (this.replaceAccountInfo) {
                    this._openModal();
                }
            } else {
                this.uiDisplay = uiDisplayEnum.PLAID;
                this.buttonText = buttonTextEnum.LOGIN_TO_BANK;
            }
        } else {
            if (this.fundingMethodDetails.accountInfo) {
                this.uiDisplay = this.replaceAccountInfo ? uiDisplayEnum.ACCOUNT_INFO_OR_REPLACE : uiDisplayEnum.EXISTING_CARD;
            } else {
                this.uiDisplay = uiDisplayEnum.ACCOUNT_INFO_OR_REPLACE;
            }
            this.buttonText = this.inboundBet ? buttonTextEnum.DEPOSIT_AND_BET : buttonTextEnum.DEPOSIT;
        }

        if(!!this.previousFundingEvent && this.plaidUpdateRequired(+this.previousFundingEvent?.code)) {
            this._openPlaid(true);
        }
    }

    ngOnDestroy() {
        this._plaidBusinessService.closePlaidWidget();
        this._replaceFundAccountSub.unsubscribe();
    }

    public onDeposit() {
        if (this.form.invalid || this.form.get('amount').value <= 0) {
            return;
        }
        if(this.plaidVerificationRequired()) {
            this._openPlaid()
        } else {
            super.onDeposit();
        }
    }

    public replaceMethod(): void {
        this.replaceAccountInfo = true;

        if (this.plaidEnabled) {
            this._openModal();
        } else {
            this.uiDisplay = uiDisplayEnum.ACCOUNT_INFO_OR_REPLACE;
            this.buttonText = this.inboundBet ? buttonTextEnum.DEPOSIT_AND_BET : buttonTextEnum.DEPOSIT;
        }

        // re-validate the amount field since new account will change the required min/max
        this.form.controls['amount'].setValidators([
           // Validators.required,
            Validators.min(this.getMinDepositAmount(false)),
            Validators.max(this.getMaxDepositAmount())
        ]);
        // If this is BetShare, also set default amount to min deposit amount
        // - a consolidated amount from min deposit option and hard required min (like BetShare)
        if (this.isBetShare) {
            this.form.controls['amount'].setValue(this.getMinDepositAmount(false));
        }
        this.form.controls['amount'].updateValueAndValidity();

        // update routingNumber and accountNumber validators to add 'required' since when user having existing account this is not required at beginning
        if (!this.plaidEnabled) {
            const newRoutingValidators = [ Validators.required, Validators.minLength(9), Validators.maxLength(9) ]; // Add required
            this.form.controls.routingNumber.setValidators(newRoutingValidators);
            const newAccountValidators = [ Validators.required, Validators.minLength(4), Validators.maxLength(17) ]; // Add required
            this.form.controls.accountNumber.setValidators(newAccountValidators);
            this.form.controls['routingNumber'].updateValueAndValidity();
            this.form.controls['accountNumber'].updateValueAndValidity();
        }
    }

    public closeModal(confirmed?: boolean) {
        if (this.plaidEnabled && confirmed) {
            this.uiDisplay = uiDisplayEnum.PLAID;
            this.buttonText = buttonTextEnum.LOGIN_TO_BANK;
            this.replaceAccountInfo = true;
        } else {
            this.uiDisplay = uiDisplayEnum.EXISTING_CARD;
            this.buttonText = this.inboundBet ? buttonTextEnum.DEPOSIT_AND_BET : buttonTextEnum.DEPOSIT;
        }
        this._modalService.closeAll();
    }

    private _openModal(updateMode: boolean = false) {
        if (!this._modalRef) {
            const modalConfig = {
                overflow: 'hidden',
                hasBackdrop: true,
                context: this._getContext(updateMode)
            };

            if (this.isSmallGlass) {
                this._modalRef = this._openDropup(updateMode);
            } else {
                this._modalRef = this._modalService.open(EzmoneyModalComponent, modalConfig);
            }
            this._modalRef.afterClosed.pipe(
                take(1)
            ).subscribe(() => {
                this._modalRef = null;
            });
        }
    }

    private _openDropup(updateMode: boolean = false): ModalRef<any> {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.context = this._getDropupContext(updateMode);

        const modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        modalRef.componentInstance.close.pipe(
            takeUntil(modalRef.afterClosed)
        ).subscribe(() => modalRef.close());

        return modalRef;
    }

    private _getContext(updateMode: boolean) {
        return {
            isReVerify: updateMode,
            close: (confirmed: boolean) => this.closeModal(confirmed),
            action: (confirmed: boolean) => this.closeModal(confirmed),
        }
    }

    private _getDropupContext(updateMode: boolean) {
        return {
            close: () => this.closeModal,
            component: EzmoneyModalComponent,
            attachedCallback: (ref) => {
                ref.instance.context = {
                    isReVerify: updateMode,
                    close: (confirmed:boolean) => this.closeModal(confirmed),
                    action: (confirmed: boolean) => this.closeModal(confirmed)
                }
            }
        }
    }

    private _openPlaid(updateMode: boolean = false) {
        const linkTokenObs = updateMode ? this._plaidBusinessService.getUpdateModeToken() : this._plaidBusinessService.getLinkToken();
        this._plaidBusinessService.initializePlaid()
            .pipe(
                switchMap((scriptLoaded) => scriptLoaded ? linkTokenObs : of(null)),
                catchError((err) =>  of(null)),
                take(1)
            )
            .subscribe((res: ILinkTokenResponse) => {
                if(res?.linkToken) {
                    const config = {
                        token: res.linkToken,
                        onSuccess: updateMode ? () => this._openModal(updateMode) : this._handlePlaidSuccess(),
                        onLoad: () => {},
                        onExit: this._handlePlaidExit(updateMode),
                        onEvent: (eventName, metadata) => {},
                    }
                    this._plaidBusinessService.openPlaidWidget(config);
                } else if(res?.status === FundingStatusEnum.FAILED) {
                    this._handleResponse(
                        res,
                        "",
                        this.form.get('amount').value,
                        this.isFullPage
                    );
                } else {
                    this._toastService.cduxWarning(FundingEzmoneyMethodDepositFullPageComponent.GENERIC_ERROR_MESSAGE);
                }
            });
    }

    private _handlePlaidSuccess(): (public_token: string, metadata: IPlaidLinkOnSuccessMetadata) => void {
        const self = this;
        return (public_token: string, metadata: IPlaidLinkOnSuccessMetadata) => {
            if (metadata.accounts.length > 0) {
                this.plaidMaskedAcct = metadata.accounts[0].mask;
            }

            self.plaidSession = this._plaidBusinessService.createPlaidSession(public_token, metadata);

            super.onDeposit();
        }
    }

    private _handlePlaidError(err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) {
        const errAndMetadata = this._plaidBusinessService.createErrAndMetadata(err, metadata);
        this._plaidBusinessService.logPlaidError(errAndMetadata).pipe(take(1)).subscribe((resp   ) => {
        })
    }

    private _handlePlaidExit(updateMode: boolean): (err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) => void {
        const self = this;
        return (err: IPLaidError, metadata: IPlaidLinkOnExitMetadata) => {
            if(updateMode) {
                if (!!err) {
                    self._handlePlaidError(err, metadata);
                }
                self._handleResponse({status: FundingStatusEnum.FAILED, errorCode: 'plaid-fallback'}, "", this.form.get('amount').value, this.isFullPage);
            } else {
                if (!!err) {
                    self._handlePlaidError(err, metadata);
                    self._toastService.cduxWarning(FundingEzmoneyMethodDepositFullPageComponent.GENERIC_ERROR_MESSAGE);
                }
            }
        }
    }
}
