export class FullPageFundingConstants {
    public static readonly DEFAULT_ERROR_MESSAGE = '<h2>Unable to Deposit</h2><p>There was an error in processing your request.</p><p>Please try again or use a different deposit method.</p>';
    public static readonly RE_PATH_DEPOSIT = /^(\/Feature\/.+)?(\/bet)?\/funding\/deposit\/?/;
    public static readonly ROUTE_PARAM_DUMMY = 'options';

    public static readonly FULL_PAGE_DEPOSIT_FT = 'FULL_PG_DEPOSIT2';

    public static readonly FULL_PAGE_WITHDRAWAL_FT = 'FULL_PG_WITHDRAW';

    public static readonly BALANCE_RETRIEVAL_ERROR = 'Balance requests are temporarily unavailable. Please try again later.';

    public static readonly TOTE_DOWN_ERROR = 'Deposit successful! Current balance is unavailable.';

    public static readonly WRONG_PAYPAL_ACCOUNT_ERROR = `<p>Transaction cancelled due to an invalid linked PayPal account.</p><p>An account cannot be linked to multiple PayPal IDs. Please try again using PayPal ID: paypalAccount</p><p>If you have any questions contact player services at customer-service.</p>`;

    // TEMP PATCH FOR USER-1412:
    // In order to complete PayPal deposits, we'll use the existing
    // sidebar component until a full-page solution can be implemented.
    // At that point, define a suffix (e.g., "FP").
    public static readonly SUFFIX_ACTION_FULL_PAGE = 'FP'; // USER-6588: Changed from '' to FP
    public static readonly GREENDOT_FP_DEEPLINK_ACTION = 'greendotBarcodeReceivedFP';

    public static readonly MAZOOMA_DEEPLINK_ACTION = 'mazoomaComplete';

    // betShare id local storage key:
    public static readonly FPD_DONE_BSHARE_BCK = 'fpd-done-bshare-bck';
    // share saved before call full page deposit
    public static readonly SHARE_SAVED_BEFORE_FPD = 'share-saved-before-fpd';

    // default withdrawal operation to check withdrawal
    public static readonly DEFAULT_WITHDRAWAL_METHOD = 16;
}
