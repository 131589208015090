<section class="id-upload-container">
    <div class="content-id-upload">
        <div class="icon--logo-sm-color"></div>
        <span id="idscan-close-button" *ngIf="showCloseButton" (click)="closeIdScan.emit()"><i class="icon--close dark"></i></span>
        <div class="content-inner-centered">
            <ng-container [ngSwitch]="step">
                <!-- Initial Page -->
                <ng-container *ngSwitchCase="0">
                    <div id="idscan-camera-icon" *ngIf="showCameraOptions" class="icon-camera-container">
                        <i class="icon--camera--light"></i>
                    </div>
                    <div class="content-id-upload_title">
                        <span *ngIf="!showCameraOptions">File Upload</span>
                        <span *ngIf="showCameraOptions">Take a photo of your ID</span>
                    </div>

                    <p>There was a problem creating your account. You
                        can complete your registration by submitting a
                        photo of your ID.
                    </p>
                    <p>
                        <span *ngIf="showCameraOptions">Take</span>
                        <span *ngIf="!showCameraOptions">Upload</span>
                        a photo of the front and back of your ID.
                    </p>
                    <div id="idscan-upload-button" (click)="incrementStep()" class="file-upload--btn large">Upload ID</div>
                    <p>
                      <ng-container *ngIf="phoneEnabled else chatMessage">
                        Or call Player Services to verify your identity and
                        complete registration at
                        <span id="idscan-support-phone-link" *ngIf="showCameraOptions" class="link-phone">
                            <a href="tel:{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</a>
                        </span>
                        <span id="idscan-support-phone-number" *ngIf="!showCameraOptions">{{ 'registration-player-services-phone' | cduxTranslate:''+affiliateId}}</span>.
                      </ng-container>
                      <ng-template #chatMessage>
                        Or <cdux-chat></cdux-chat> to verify your identity and
                        complete registration.
                      </ng-template>

                    </p>
                </ng-container>
                <!-- Step 1: Upload Front -->
                <ng-container *ngSwitchCase="1">
                    <ng-container *ngIf="showCameraOptions; else largeGlass">
                        <div id="idscan-id-front-image" class="id-card-example front">
                            <i class="icon--id-front-gray"></i>
                        </div>
                        <h1>Front of photo id checklist:</h1>
                        <ul>
                            <li>Place your ID on a dark, flat surface</li>
                            <li>Take the picture directly above, not at an angle</li>
                            <li>Turn off the flash to reduce glare</li>
                        </ul>
                        <div class="cdux-button-wrapper primary">
                            <button
                                id="idscan-take-front-photo-button"
                                (click)="takePhoto(frontImageInput)"
                                [cduxLogClick]="eventClickType.TAKE_PHOTO_FRONT"
                                [disabled]="processing">
                                    <span *ngIf="!processing; else loadingDots">Take photo of front</span>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- Step 2: Upload Back -->
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngIf="showCameraOptions; else largeGlass">
                        <div id="idcan-id-back-image" class="id-card-example back">
                            <i class="icon--id-back-gray"></i>
                        </div>
                        <h1>Back of photo id checklist:</h1>
                        <ul>
                            <li>Place your ID on a dark, flat surface</li>
                            <li>Take the picture directly above, not at an angle</li>
                            <li>Turn off the flash to reduce glare</li>
                        </ul>
                        <div class="cdux-button-wrapper primary">
                            <button
                                id="idscan-take-back-photo-button"
                                (click)="takePhoto(backImageInput)"
                                [cduxLogClick]="eventClickType.TAKE_PHOTO_BACK"
                                [disabled]="processing">
                                <span *ngIf="!processing; else loadingDots">Take photo of back</span>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- Step 3: Review -->
                <ng-container *ngSwitchCase="3">
                <!-- Step 3: Review -->
                    <div class="content-id-upload_title">Review</div>
                    <ng-container *ngIf="!showCameraOptions; else showCameraOptionsReview">
                        <div id="idscan-front-image-review" class="content-id-upload_file-review">
                            <div class="file-name-size">
                                <div id="idscan-front-image-name" class="file-name">{{frontImageFileName}}</div>
                                <div id="idscan-front-image-size" class="file-size">{{frontImageFileSize}}</div>
                            </div>
                            <div id="idscan-front-image-edit-button" (click)="setStep(1)" class="edit">Edit</div>
                        </div>
                        <div id="idscan-back-image-review" class="content-id-upload_file-review">
                            <div class="file-name-size">
                                <div id="idscan-back-image-name" class="file-name">{{backImageFileName}}</div>
                                <div id="idscan-back-image-size" class="file-size">{{backImageFileSize}}</div>
                            </div>
                            <div id="idscan-back-image-edit-button" (click)="setStep(2)" class="edit">Edit</div>
                        </div>
                    </ng-container>
                    <ng-template #showCameraOptionsReview>
                        <ul class="photo-thumbs">
                            <li id="idscan-front-image-review" (click)="takePhoto(frontImageInput)">
                                <div class="label">
                                    <span class="main-label">ID Photo</span>
                                    <span class="sub-label">Front</span>
                                </div>
                                <div id="idscan-front-image-thumbnail" class="thumbnail-container">
                                    <img [src]="encodedFrontImage">
                                </div>
                                <div class="arrow">
                                    <i class="icon--arrow-right--white"></i>
                                </div>
                            </li>
                            <li id="idscan-back-image-review" (click)="takePhoto(backImageInput)">
                                <div class="label">
                                    <span class="main-label">ID Photo</span>
                                    <span class="sub-label">Back</span>
                                </div>
                                <div id="idscan-back-image-thumbnail" class="thumbnail-container">
                                    <img [src]="encodedBackImage">
                                </div>
                                <div class="arrow">
                                    <i class="icon--arrow-right--white"></i>
                                </div>
                            </li>
                        </ul>
                    </ng-template>

                    <button
                        id="idscan-submit-button"
                        (click)="submit()"
                        class="submit-id--btn large"
                        [disabled]="processing">
                        <span *ngIf="!processing; else loadingDots">Submit ID</span>
                    </button>
                    <button id="idscan-cancel-button" (click)="cancel()" class="submit-id--btn large">
                        Cancel
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #loadingDots>
    <div class="gbl-button-loader">
        <div class="gbl-loader-dots">
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
            <i class="dots-animated"></i>
        </div>
    </div>
</ng-template>

<ng-template #largeGlass>
    <div class="content-id-upload_title">Upload <span *ngIf="step === 1">Front</span><span *ngIf="step === 2">Back</span> of ID
        <div class="id-upload-step-label">Step {{step}} of 2</div>
    </div>

    <ng-container *ngIf="!errorState; else errorStateTemplate">
        <div id="idscan-drag-and-drop-target" class="drag-and-drop-target" cduxDragAndDrop (fileDropped)="onFileDropped($event)">
            <div id="idscan-target-circle" *ngIf="!processing; else imageLoadingTemplate" class="target-circle">
                <i class="icon-upload-secondary"></i>
            </div>

            <p class="large-text">Drag and drop or</p>
            <div id="idscan-choose-file-button" class="choose-file--btn large"
                [disabled]="processing"
                (click)="uploadFile()">
                Choose File
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #errorStateTemplate>
    <div id="idscan-drag-and-drop-target" class="drag-and-drop-target">
        <div id="idscan-error-circle" *ngIf="!processing; else imageLoadingTemplate" class="target-circle is-error" cduxDragAndDrop (fileDropped)="onFileDropped($event)">
            <i class="icon-exclamation-error-large"></i>
        </div>
        <p class="large-text">Drag and drop or</p>
            <div class="choose-file--btn large"
                [disabled]="processing"
                (click)="uploadFile()">Choose File</div>
        <p class="error-text">File type not accepted. Try again.</p>
    </div>
</ng-template>

<ng-template #imageLoadingTemplate>
    <div id="idscan-success-animation" class="success-ani">
    <!--// progress animation from https://codepen.io/xgad/post/svg-radial-progress-meters//-->
        <svg class="progress" width="120" height="120" viewBox="0 0 120 120">
            <circle class="base" cx="60" cy="60" r="54" fill="none" />
            <circle class="progress__value" cx="60" cy="60" r="54" fill="none" />
        </svg>
        <svg class="check" width="34" height="26" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.965 1.125c-1.613-1.614-4.394-1.474-6.178.31L11.905 15.317l-4.602-4.602c-1.784-1.784-4.565-1.924-6.178-.31-1.614 1.613-1.474 4.394.31 6.178l7.048 7.048a4.814 4.814 0 003.265 1.43c.042.003.084 0 .126 0 .064 0 .129.003.192 0 1.154-.03 2.34-.509 3.261-1.43L31.655 7.303c1.785-1.784 1.924-4.565.31-6.178" fill-rule="evenodd"/>
        </svg>
    </div>
</ng-template>

<form id="idscan-form" [formGroup]="formGroup" style="display: none;">
    <input #frontImgInput (change)="onPhotoChange($event)"
           formControlName="frontImage"
           type="file"
           accept="image/png,image/jpeg"
           capture="environment"
           id="frontImage" />
    <input #backImgInput (change)="onPhotoChange($event)"
           formControlName="backImage"
           type="file"
           accept="image/png,image/jpeg"
           capture="environment"
           id="backImage" />
</form>
