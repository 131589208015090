import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EventClickType, FeatureToggleDataService, enumFeatureToggle } from '@cdux/ng-common';
import { TournamentsSessionService } from '../../services/touranments-session.service';
import { Router } from '@angular/router';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TournamentSelectionViewEnum } from '../../tournament-session-view.enum';

@Component({
    selector: 'cdux-tournaments-selection-bar',
    templateUrl: 'tournaments-selection-bar.component.html',
    styleUrls: ['./tournaments-selection-bar.component.scss']

})
export class TournamentsSelectionBarComponent implements OnInit, OnDestroy {
    public errorMessage: string;
    public isPhone: boolean;
    public activeEnrollment: boolean = false;
    public selectedTournament: any;
    public tournamentEnabled: boolean;
    private _killSubscription: Subject<any> = new Subject<any>();

    @Output()
    activeTournamentBarShowing = new EventEmitter();

    @Input() public tournamentSelectionView: TournamentSelectionViewEnum;

    constructor(
        private _tournamentSessionService: TournamentsSessionService,
        private _featureToggleService: FeatureToggleDataService,
        private _router: Router,
        private _eventTrackingService: EventTrackingService

    ) { }

    ngOnInit() {
        this.activeTournamentBarShowing.emit(false);
        this.tournamentEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SHOW_TOURNAMENTS)

        this._tournamentSessionService.userEnrollmentObs.pipe(
            takeUntil(this._killSubscription)
        ).subscribe((response) => {
           this.activeEnrollment = response ? true : false;
           this.activeTournamentBarShowing.emit(this.activeEnrollment && this.tournamentEnabled);
        });

        this._tournamentSessionService.onTournamentSelection.pipe(
            takeUntil(this._killSubscription),
            distinctUntilChanged()
        )
        .subscribe((response) => {
            this.selectedTournament = response;
        })
    }

    public showSelection() {
        switch (this.tournamentSelectionView) {
            case TournamentSelectionViewEnum.VIDEO:
                this._router.navigate(['/tournaments-selection','view',this.tournamentSelectionView]);
                break;
            case TournamentSelectionViewEnum.BETPAD:
                this._router.navigate(['/tournaments-selection','view','betpad']);
                break;
            default:
                this._router.navigate(['/tournaments-selection'], {replaceUrl: true});
        }
        this.logClick(EventClickType.TOURNAMENTS_SWITCH_ACCOUNT);
    }

    public logClick(_logClick: EventClickType): void {
        this._eventTrackingService.logClickEvent(EventClickType.TOURNAMENTS_SWITCH_ACCOUNT);
    }

    ngOnDestroy() {
        this._killSubscription.next();
        this._killSubscription.complete();
    }
}
