<cdux-breadcrumbs *ngIf="!isInline"></cdux-breadcrumbs>
<div class="runner-details" *ngIf="runnerInfo" [cduxQAAutomation]="'expanded-entry-details'">
    <div [ngClass]="{'detail-info-mobile': isPhone, 'detail-info-full': !isPhone}" class="is-open" [class.is-stable]="isStable">
        <div class="details-col" [class.is-stable]="isStable">
            <div>
                <h1 class="horse-name">{{ runnerInfo.runnerInfo?.horseName }}</h1>
                <div class="ancestor-details" *ngIf="runnerInfo.runnerInfo?.sireName && runnerInfo.runnerInfo?.damName">
                    <span>
                        <ng-container *ngIf="runnerInfo.runnerInfo?.sireId && runnerInfo.runnerInfo?.damId; else NoAncestorDetails">
                            <a class="sire-details" (click)="seeAncestorDetails('SIRE')">{{ runnerInfo.runnerInfo?.sireName }}</a> -
                            <a class="dam-details" (click)="seeAncestorDetails('DAM')">{{ runnerInfo.runnerInfo?.damName }}</a>
                        </ng-container>
                        <ng-template #NoAncestorDetails>
                            <span class="sire-details">{{ runnerInfo.runnerInfo?.sireName }}</span> -
                            <span class="dam-details">{{ runnerInfo.runnerInfo?.damName }}</span>
                        </ng-template>
                    </span>
                </div>
                <div class="color-sex-age" *ngIf="runnerInfo.runnerInfo?.colorDescription && runnerInfo.runnerInfo?.sexDescription && runnerInfo.runnerInfo?.age">
                    <span>
                        <span class="entry-color-description csa-details">{{ runnerInfo.runnerInfo?.colorDescription }}</span>,
                        <span class="entry-sex csa-details">{{ runnerInfo.runnerInfo?.sexDescription }}</span>,
                        <span class="entry-age-display csa-details">{{ runnerInfo.runnerInfo?.age }}</span>{{ entry?.Blinkers ? ',' : '' }}
                        <span *ngIf="entry?.Blinkers">{{ entry?.Blinkers }}</span>
                    </span>
                </div>
            </div>
            <div class="breeder-owner">
                <div class="breeder-info">
                    <div class="label">BREEDER</div>
                    <div class="breeder-spacer"></div>
                    <div class="info">{{ runnerInfo.runnerInfo?.breederName }} {{ trackType !== trackTypeEnum.HARN ? '(' + runnerInfo.runnerInfo?.whereBred + ')' : '' }}</div>
                </div>
                <div class="owner-info">
                    <div class="label">OWNER</div>
                    <div class="owner-spacer"></div>
                    <div class="info">{{ runnerInfo.runnerInfo?.ownerName }}</div>
                </div>
            </div>
            <cdux-favorite-toggle-runner
              *ngIf="trackType === ETrackType.TBRED"
              [favoriteData]="favoriteData"
              displayFavoritesLabel="true"
              rightDisplay="true"
            ></cdux-favorite-toggle-runner>
        </div>

        <div class="comments-container" *ngIf="entryCommentsEnabled && (entry?.CommentsPositive?.length || entry?.CommentsNegative?.length)">
            <h1 class="title">Comments</h1>
            <div class="comments-col">
                <div class="positive-col">
                    <h2 class="title">Positive</h2>
                    <ul class="comments">
                        <li class="comment" *ngFor="let comment of entry?.CommentsPositive">
                            <div class="positive-comment-icon"></div> {{ comment }}
                        </li>
                    </ul>
                </div>
                <div class="negative-col">
                    <h2 class="title">Negative</h2>
                    <ul class="comments">
                        <li class="comment" *ngFor="let comment of entry?.CommentsNegative">
                            <div class="negative-comment-icon"></div> {{ comment }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="history-surface-container">
            <div class="history-col">
                <table class="entry-record-table" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-stable]="isStable">Record</td>
                        <td class="right-align" [class.is-mobile]="isPhone">str</td>
                        <td class="right-align" [class.is-mobile]="isPhone">wn</td>
                        <td class="right-align" [class.is-mobile]="isPhone">pl</td>
                        <td class="right-align" [class.is-mobile]="isPhone">sh</td>
                        <td class="right-align" [class.is-mobile]="isPhone">Earnings</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-stable]="isStable">Life</td>
                        <td class="right-align record-lifetime-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.lifetimeStats?.starts }} </td>
                        <td class="right-align record-lifetime-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.lifetimeStats?.win }}</td>
                        <td class="right-align record-lifetime-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.lifetimeStats?.place }}</td>
                        <td class="right-align record-lifetime-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.lifetimeStats?.show }}</td>
                        <td class="right-align record-lifetime-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.lifetimeStats?.earningsShort }}</td>
                        <td class="right-align record-lifetime-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.lifetimeStats?.earningsLong}}</td>
                    </tr>
                    <tr *ngIf="runnerInfo.lifetimeStats?.currentYearStats" [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.currentYear }}</td>
                        <td class="right-align record-curYear-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.currentYearStats?.starts }} </td>
                        <td class="right-align record-curYear-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.currentYearStats?.win }}</td>
                        <td class="right-align record-curYear-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.currentYearStats?.place }}</td>
                        <td class="right-align record-curYear-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.currentYearStats?.show }}</td>
                        <td class="right-align record-curYear-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.currentYearStats?.earningsShort}}</td>
                        <td class="right-align record-curYear-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.currentYearStats?.earningsLong}}</td>
                    </tr>
                    <tr *ngIf="runnerInfo.lifetimeStats?.previousYearStats" [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.previousYear }}</td>
                        <td class="right-align record-prevYear-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.previousYearStats?.starts }} </td>
                        <td class="right-align record-prevYear-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.previousYearStats?.win }}</td>
                        <td class="right-align record-prevYear-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.previousYearStats?.place }}</td>
                        <td class="right-align record-prevYear-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.previousYearStats?.show }}</td>
                        <td class="right-align record-prevYear-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.previousYearStats?.earningsShort}}</td>
                        <td class="right-align record-prevYear-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.previousYearStats?.earningsLong}}</td>
                    </tr>
                    <tr *ngIf="brisCode && trackType !== trackTypeEnum.HARN && runnerInfo.lifetimeStats?.trackStats" [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">{{ brisCode | uppercase }}</td>
                        <td class="right-align record-harness-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.trackStats?.starts }} </td>
                        <td class="right-align record-harness-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.trackStats?.win }}</td>
                        <td class="right-align record-harness-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.trackStats?.place }}</td>
                        <td class="right-align record-harness-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.trackStats?.show }}</td>
                        <td class="right-align record-harness-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.trackStats?.earningsShort}}</td>
                        <td class="right-align record-harness-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.trackStats?.earningsLong}}</td>
                    </tr>

                    </tbody>
                </table>
            </div>

            <div *ngIf="trackType !== trackTypeEnum.HARN" class="surface-col">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">{{ isPhone ? 'Record' : '' }}</td>
                        <td class="right-align" [class.is-mobile]="isPhone">str</td>
                        <td class="right-align" [class.is-mobile]="isPhone">wn</td>
                        <td class="right-align" [class.is-mobile]="isPhone">pl</td>
                        <td class="right-align" [class.is-mobile]="isPhone">sh</td>
                        <td class="right-align" [class.is-mobile]="isPhone">Earnings</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">Dirt</td>
                        <td class="right-align record-dirt-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.dirtStats?.starts }} </td>
                        <td class="right-align record-dirt-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.dirtStats?.win }}</td>
                        <td class="right-align record-dirt-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.dirtStats?.place }}</td>
                        <td class="right-align record-dirt-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.dirtStats?.show }}</td>
                        <td class="right-align record-dirt-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.dirtStats?.earningsShort }}</td>
                        <td class="right-align record-dirt-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.dirtStats?.earningsLong }}</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">Turf</td>
                        <td class="right-align record-turf-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.turfStats?.starts }} </td>
                        <td class="right-align record-turf-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.turfStats?.win }}</td>
                        <td class="right-align record-turf-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.turfStats?.place }}</td>
                        <td class="right-align record-turf-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.turfStats?.show }}</td>
                        <td class="right-align record-turf-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.turfStats.earningsShort}}</td>
                        <td class="right-align record-turf-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.turfStats?.earningsLong }}</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td>AW</td>
                        <td class="right-align record-aw-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.awStats?.starts }} </td>
                        <td class="right-align record-aw-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.awStats?.win }}</td>
                        <td class="right-align record-aw-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.awStats?.place }}</td>
                        <td class="right-align record-aw-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.awStats?.show }}</td>
                        <td class="right-align record-aw-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.awStats?.earningsShort }}</td>
                        <td class="right-align record-aw-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.awStats?.earningsLong }}</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">OFF</td>
                        <td class="right-align record-off-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.offStats?.starts }} </td>
                        <td class="right-align record-off-win"[class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.offStats?.win }}</td>
                        <td class="right-align record-off-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.offStats?.place }}</td>
                        <td class="right-align record-off-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.offStats?.show }}</td>
                        <td class="right-align record-off-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.offStats?.earningsShort }}</td>
                        <td class="right-align record-off-earnings-long" [class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.offStats?.earningsLong }}</td>
                    </tr>
                    <tr [class.is-mobile]="isPhone">
                        <td [class.is-mobile]="isPhone">Dist</td>
                        <td class="right-align record-dist-starts" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.distStats?.starts }} </td>
                        <td class="right-align record-dist-win" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.distStats?.win }}</td>
                        <td class="right-align record-dist-place" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.distStats?.place }}</td>
                        <td class="right-align record-dist-show" [class.is-mobile]="isPhone">{{ runnerInfo.lifetimeStats?.distStats?.show }}</td>
                        <td class="right-align record-dist-earnings-short" [class.is-mobile]="isPhone" *cduxMediaToggle="'phone'">{{ runnerInfo.lifetimeStats?.distStats?.earningsShort }}</td>
                        <td class="right-align record-dist-earnings-long"[class.is-mobile]="isPhone" *cduxMediaToggle="'desk-to-phone'">{{ runnerInfo.lifetimeStats?.distStats?.earningsLong }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <cdux-runner-details-past-performances *ngIf="trackType === trackTypeEnum.TBRED || !trackType" [detailOptions]="detailOptions" [showAllPP]="showAllPP" ></cdux-runner-details-past-performances>

    <ng-container *ngIf="(runnerInfo.pastPerformances?.length > MIN_ROWS_DISPLAY || runnerInfo.workouts?.length > 0) && (!showAllPP || (showAllPP && !runnerInfo.workouts?.length))">
        <table cellspacing="0" cellpadding="0" class="details-table-9col">
            <tbody>
                <tr [hidden]="true"></tr>
                <ng-container *ngTemplateOutlet="singleCol; context: { label: ppToggleLabel, isLink: true }"></ng-container>
            </tbody>
        </table>
    </ng-container>

    <div *ngIf="runnerInfo.workouts?.length && showAllPP" class="details-scroll-x">
        <table cellspacing="0" cellpadding="0" class="details-table-9col">
            <tbody>
            <tr>
                <td [class.is-mobile]="isPhone">WORKOUTS</td>
                <td [class.is-mobile]="isPhone"></td>
                <td [class.is-mobile]="isPhone">DIST</td>
                <td [class.is-mobile]="isPhone"></td>
                <td [class.is-mobile]="isPhone">SURF-COND</td>
                <td [class.is-mobile]="isPhone"></td>
                <td [class.is-mobile]="isPhone">TIME</td>
                <td [class.is-mobile]="isPhone"></td>
                <td [class.is-mobile]="isPhone">RANK</td>
            </tr>

            <ng-template ngFor let-workout [ngForOf]="displayedWorkouts">
                <tr>
                    <td [class.is-mobile]="isPhone">
                        <p class="date">{{ (workout.date | date: 'MMM d, yy').replace(', ', ', \'') }}</p>
                        <p class="track">{{ workout.track.DisplayName }}</p>
                    </td>
                    <td [class.is-mobile]="isPhone"></td>
                    <td [class.is-mobile]="isPhone">{{ workout.distance }}</td>
                    <td [class.is-mobile]="isPhone"></td>
                    <td [class.is-mobile]="isPhone">{{ workout.surface }}-{{ workout.conditions }}</td>
                    <td [class.is-mobile]="isPhone"></td>
                    <td [class.is-mobile]="isPhone">{{ workout.time }}</td>
                    <td [class.is-mobile]="isPhone"></td>
                    <td [class.is-mobile]="isPhone"> {{ workout.workoutRankNumerator }} of {{ workout.workoutRankDenominator }}</td>
                </tr>
            </ng-template>
            <ng-container *ngTemplateOutlet="singleCol; context: { label: ppToggleLabel, isLink: true }"></ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="runner-details" *ngIf="error">
    <div class="no-details">
        <h1>{{ this.errorMessage }} </h1>
    </div>
</div>

<ng-template #singleCol let-label="label" let-isLink="isLink">
    <tr >
        <td *ngIf="isLink" (click)="togglePP()"><a>{{ label }}</a></td>
        <td *ngIf="!isLink">{{ label }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr>
</ng-template>
