<div class="print-buttons">
    <div class="print" (click)="printSummary()">PRINT SUMMARY</div>
    <div class="print" (click)="printAll()">PRINT ALL</div>
</div>
<div class="scrolling-area">
    <div class="cdux-loading-wrapper" *cduxLoading="LOADING_OVERLAY_NAME; strategy:'overlay'; component:loadingDotsComponent;">
        <div *ngIf="hasLoaded && summaryTransactions.length > 0">
            <div class="nav-panel-content">
                <div class="account-history-container">
                    <div *ngFor="let transactionGroup of summaryTransactions" class="account-history-bet-list-for-date">
                        <div class="account-history-date-bar">
                            {{ transactionGroup.monthName |  titlecase }}
                        </div>
                        <div >
                        <cdux-taxable-transaction 
                                *ngFor="let transaction of transactionGroup.transactions; let i = index;"
                                [transaction]="transaction"
                                [index]="i"></cdux-taxable-transaction>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
