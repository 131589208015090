<section *ngIf="favoritesToday.length">
  <header>Running Today</header>
  <div class="tile-container">
    <cdux-favorite-tile-runner *ngFor="let fav of favoritesToday; trackBy:trackRunner" [favoriteData]="fav" isRunningToday="true"></cdux-favorite-tile-runner>
  </div>
</section>

<section *ngIf="favoritesElse.length">
  <header aria-label="manage stable">
    <div class="header__col1">MANAGE STABLE</div>
    <div class="header__col2" (click)="sortRunner(isAlphabetical)">&#x2630;&#x2193; {{ isAlphabetical ? 'A/Z' : 'Z/A' }}</div>
  </header>
  <header class="header--secondary">
    <div class="row-container__col1 underline" (click)="sortRunner(isAlphabetical)">Runner</div>
    <div class="row-container__col2 underline" (click)="sortActivityDate(isChronological)">Last Active</div>
    <div class="row-container__col3">Notes</div>
    <div class="row-container__col4">Actions</div>
  </header>
  <ng-container *ngFor="let runner of favoritesElse; index as i">
      <div class="row-container" [class.first]="i===0">
          <div class="row-container__col1">
              <span id="fav-runner-{{ runner.runnerName }}" class="trainer-name">{{ runner.runnerName }}</span>
              <div aria-label="view stats" class="link" role="link" tabindex="0" (click)="replays(runner)">PPs & Replays</div>
          </div>
          <div class="row-container__col2">
            <span id="fav-runner-lastestactivity-{{ i }}">{{ formatLastActiveDate(runner.latestActivityDate) }}</span>
          </div>
          <div id="fav-trainer-notes" class="row-container__col3">
              <span class="notes">{{ runner.comment }}</span>
          </div>
          <div class="row-container__col4">
              <span id="fav-runner-edit" aria-label="edit this runner" class="link" (click)="edit(runner)">Edit</span>
              <span aria-hidden="true" class="text__gray2">|</span>
              <span id="fav-runner-remove" aria-label="remove this runner" class="link" (click)="remove(runner)">Remove</span>
          </div>
      </div>
  </ng-container>
</section>

<cdux-favorites-manager-empty id="no-favorite-runners" *ngIf="favoritesEmpty"></cdux-favorites-manager-empty>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #replaysModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1">
      </div>
      <div class="col2">
      </div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div class="scrollable">
      <cdux-favorite-stats-runner
        [detailOptions]="options"
        isStable="true"
      ></cdux-favorite-stats-runner>
    </div>
  </div>
</ng-template>

<!-- TODO: make this a component reusable in favorite-toggle-*.component.ts -->
<ng-template #updateModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <!-- TODO: don't use "runner", keep type-agnostic -->
    <h2>
      <span id="favorite-update-modal-description" class="runner">{{ modalRunner.runnerName }}</span>
    </h2>
    <textarea id="favorite-update-modal-note"
              cols="50" [maxlength]="COUNT_MAX" name="note"
              placeholder="ADD A NOTE..." rows="13"
              [(ngModel)]="note"
    ></textarea>
    <div class="char-count"></div>
    <div class="btn-update" (click)="confirmUpdate()">Update</div>
  </div>
</ng-template>

<ng-template #removeModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <!-- TODO: don't use "runner", keep type-agnostic -->
    <h2>
      <span id="favorite-remove-modal-description" class="runner">
        Are you sure you want to remove<br>{{ modalRunner.runnerName }}?
      </span>
    </h2>
    <div class="message">You will no longer receive alerts and all notes will be lost.</div>
    <div class="button-row">
      <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
      <div class="btn-remove" (click)="confirmRemove()">Remove</div>
    </div>

  </div>
</ng-template>
