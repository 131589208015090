import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ModalService } from '@cdux/ng-platform/web';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { EventClickType } from '@cdux/ng-common';

@Component({
    selector: 'cdux-rewards-success',
    templateUrl: './rewards-redemption-success.component.html',
    encapsulation: ViewEncapsulation.None
})

export class RewardsSuccessComponent  {
    @Input() public message: string;
    @Input() public isMobile: boolean;

    private _context: any;

    public set context(context: any) {
        if (!!context) {
            this._context = context;
            this.message = this._context.message;
            this.isMobile = this._context.isMobile;
        }
    }

    public get context(): any {
        return this._context;
    }

    public eventClickType = EventClickType;

    constructor(private _modalService: ModalService,
                private _sidebarService: SidebarService) {

    }

    /**
     * open MyAccount Container and close the modal
     */
    public closeModal() {
        this._sidebarService.goBack();
        this._modalService.closeAll();
    }
}
