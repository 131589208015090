import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { AsyncSubject } from 'rxjs';

import { ENVIRONMENT } from '@cdux/ng-core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
} from '@cdux/ng-common';


export interface IIncomeAccessData {
    affid: string;
    btag: string;
    location?: string;
}

@Injectable({ providedIn: 'root' })
export class IncomeAccessService {
    private static readonly COOKIE_IA_DATA = 'IA_BTAG';

    constructor (
        private environment: ENVIRONMENT,
        protected configService: ConfigurationDataService,
    ) {}

    public storeData (data: IIncomeAccessData): AsyncSubject<boolean> {
        const isRecorded: AsyncSubject<boolean> = new AsyncSubject();

        this.configService.getConfiguration(enumConfigurationStacks.TUX, ['ia_referral_window_minutes'])
            .subscribe(
            (response) => {
                /*
                 * Why "minutes", you may ask? Even though our production use
                 * case will most likely be exclusively "days", "minutes" allows
                 * for easier QA.
                 */
                let minutes = 43200; // fall back to 30 days
                if ('ia_referral_window_minutes' in response) {
                    const configVal = parseInt(response['ia_referral_window_minutes'], 10);
                    if (!Number.isNaN(configVal)) {
                        minutes = configVal;
                    }
                }

                /*
                 * As best as we can tell, the /adw/registration/prospect/[!UUID!]
                 * and /webapi/Registration/register endpoints are expecting
                 * the IA parameters in the IA_BTAG cookie instead of as
                 * parameters to the call. The value of the cookie
                 * is "<affid>:<btag>".
                 */
                Cookie.set(
                    IncomeAccessService.COOKIE_IA_DATA,
                    `${data.affid}:${data.btag}`,
                    minutes / 60 / 24,
                    '/',
                    this.environment.domain
                );

                isRecorded.next(true);
                isRecorded.complete();
            },
            (reason) => {
                console.error('Unable to retrieve IA referral window value.', reason);
                throw new Error('Unable to retrieve IA referral window value.');
            }
        );

        return isRecorded;
    }

    public retrieveData (): IIncomeAccessData {
        const iaCookie: string = Cookie.get(IncomeAccessService.COOKIE_IA_DATA);

        const rv: IIncomeAccessData = {
            affid: null,
            btag: null
        };

        if (iaCookie.length) {
            const iaTags = iaCookie.split(':');
            if (iaTags.length === 2) {
                rv.affid = iaTags[0];
                rv.btag  = iaTags[1];
            }
        }

        return rv;
    }

    public clearData () {
        Cookie.delete(IncomeAccessService.COOKIE_IA_DATA);
    }
}
