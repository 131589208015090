import { LANG_COMMON } from './common';

export const LANG_3000_NAME = '3000';

// 2800 specific extension of the common affiliate dictionary
const LANG_3000_EXTENSION = {
  'customer-service': '1-866-607-7929',
  'player-services-email': 'help@BetAmerica.com',
  'registration-ssn-alert-body': 'BetAmerica is committed to responsible wagering and complying with state and federal regulations.',
  'registration-player-services-fax': '1-859-223-9441',
  'registration-player-services-phone': '1-855-845-5035',
  'registration-account-exists-body': 'You may already have a BetAmerica account. You can retrieve your account information and reset your password ',
  'registration-success-header-woffer': 'Welcome to BetAmerica',
  'registration-cellphone-alert-header': '',
  'registration-cellphone-alert-body': '',
  'bet-share-registration-success-header-woffer': 'Welcome to BetAmerica',
  'primary-phone-number': '1-866-607-7929',
  'primary-phone-number-vanity': '1-866-607-7929',
  'primary-phone-hyperlink': 'tel:18666077929',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': 'https://www.facebook.com/BetAmerica',
  'twitter-url': 'https://twitter.com/BetAmerica',
  'instagram-url': '',
  'support-display-social-media-icons': 'true',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'BetAmerica.com',
  'moneygram-receiveId' : '12996',
  'affiliate-name': 'BetAmerica',
  'betpad-unavailable-title': 'SORRY',
  'betpad-signedout-title': 'SIGNED OUT',
  'betpad-signin-button': 'SIGN IN',
  'betpad-unavailable-button': 'RETURN',
  'betpad-invalid-url-copy': 'The page you requested could not be found.',
  'betpad-unavailable-copy': 'You must be signed in to use Bet Pad.'
};

export const LANG_3000_TRANS = Object.assign({}, LANG_COMMON, LANG_3000_EXTENSION);
