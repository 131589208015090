<form class="deposit-form-wrapper paypal" *ngIf="form" [formGroup]="form" [class.dark]="lockout">
    <div class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
        <p>Please contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}.</p>
    </div>

    <cdux-funding-input-amount id="depositAmount" formControlName="amount"
                                [inputType]="OPERATION"
                                [fundType]="fundingMethodDetails.fundType"
                                [min]="getMinDepositAmount(hasVerifiedAccount)"
                                [max]="getMaxDepositAmount()"
                                [displayMax]="true"
                               [amount]="amount"
                               [offerId]="offerId">
    </cdux-funding-input-amount>

    <div class="ts-message error" *ngIf="errorCode !== null">{{ '' + errorCode | cduxTranslate:'errorcodes':true }}</div>

    <div class="form-account-info-wrapper">
        <div class="account-info" *ngIf="!fundingMethodDetails.accountInfo;else userAccountInfo">
            {{ 'deposit-instructions' | cduxTranslate:fundingMethodDetails.fundType }}
        </div>
        <ng-template #userAccountInfo>
            <div class="account-header">
                {{ 'withdraw-account-header' | cduxTranslate:fundingMethodDetails.fundCode }}
            </div>
            <div class="account-info">
                {{ fundingMethodDetails.accountInfo }}
            </div>
        </ng-template>
    </div>

    <cdux-funding-transaction-summary
        transactionName="Deposit"
        [amount]="form.get('amount').value"
        feeName="PayPal Fee"
        [feeValue]="fundingMethodDetails.flatFee">
    </cdux-funding-transaction-summary>

    <cdux-funding-deposit-button (click)="onDeposit()"
      [disabled]="form.invalid || form.get('amount').value <= 0"
      [inboundBet]="inboundBet">
    </cdux-funding-deposit-button>
</form>
