import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FullScreenDrawerComponent } from "./components/full-screen-drawer.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FullScreenDrawerComponent
    ],
    exports: [
        FullScreenDrawerComponent
    ],
})
export class FullScreenDrawerModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<FullScreenDrawerModule> {
        return {
            ngModule: FullScreenDrawerModule,
            providers: [
            ],
        }
    }
}