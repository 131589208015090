export enum TransactionTypeDisplayName {
    WAGER = 'Bet',
    DEPOSIT = 'Deposit',
    WITHDRAWAL = 'Withdraw',
    POS_ADJ = 'Positive Adjustment',
    NEG_ADJ = 'Negative Adjustment',
    TRANSFER_IN = 'Transfer In',
    TRANSFER_OUT = 'Transfer Out'
}

/* represents recurring css classes
 * applied to return amount of a wager
 */
export enum WagerTotalClass {
    PENDING = 'is-pending-win',
    NEGATIVE_AMOUNT = 'amount-negative',
    POSITIVE_AMOUNT = 'amount-positive'
}

/* represents recurring css classes
 * applied to a wager's status
 */
export enum WagerStatusClass {
    IS_ACCEPTED = 'is-accepted',
    IS_PENDING =  'is-pending',
    IS_PROCESSING = 'is-processing'
}
