<ng-container *ngIf="fpwEnabled">
  <header class="funding-content_header">
    <i class="icon--nav-back-white" (cduxTap)="navigateToOptions()"></i>
    <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="withdraw-close-btn"></i>
    <h1>Check By Mail</h1>
    <div class="funding-type-icon">
      <i class="icon-checkbymail"></i>
    </div>
  </header>
  <form [formGroup]="form" *ngIf="form">

      <cdux-funding-input-amount
        id="depositAmount"
        formControlName="amount"
        [inputType]="OPERATION"
        [fundType]="fundingMethodDetails.fundType"
        [min]="0"
        [displayMin]="false"
        [max]="getMaxWithdrawAmount()"
        [displayMax]="false"
        [phoneEnabled]="phoneEnabled"
        [displayStyle]="enumWithdrawDisplayStyle.FULL_PAGE">
      </cdux-funding-input-amount>

      <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>

      <div class="funding-content_details-block" *ngIf="accountInfo">
        <div class="funding-content_details__delivery-options">
          <h2>Delivery Methods:</h2>
          <cdux-radio-group name="delivery-option" formControlName="deliveryMethod">
            <ng-template ngFor let-method [ngForOf]="accountInfo.miscInfo.deliveryMethods">
              <cdux-radio-option [id]="'methodRadio-' + method.code" [value]="method.code">
                <b class="shout"> {{ method.cost ? '$' + method.cost : 'FREE' }} </b>
                <span style="text-transform: none;"> {{ method.code === 'FCM' ? 'USPS First Class Mail (Delivery 14 Business Days)' : method.displayName }} </span>
              </cdux-radio-option>
            </ng-template>
          </cdux-radio-group>
        </div>
        <div class="funding-content_details__delivery-address">
          <h2>Mail Check To:</h2>
          <div class="funding-content_details__delivery-address__address">
            <span class="address-line"> {{ accountInfo.address.address1 }}<ng-container *ngIf="!accountInfo.address.address2">,</ng-container></span>
            <span *ngIf="accountInfo.address.address2" class="address-line"> {{ accountInfo.address.address2 }}, </span>
            <span class="address-line"> {{ accountInfo.address.city }}, {{ accountInfo.address.stateLabel }} {{ accountInfo.address.zipCode }}</span>
          </div>
          <p>If you need to update your address, please <span *ngIf="phoneEnabled else chat">contact Player Services at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
          <p>Withdrawal requests made after 2 pm ET will be processed the next business day. Delivery times not guaranteed by carrier.</p>
        </div>
        <ng-template #chat></ng-template>

        <div class="ts-message error" *ngIf="errorCode !== null">{{ errorCode | cduxTranslate:'errorcodes':true }}</div>
        <div class="ts-message success" *ngIf="successfulWithdraw">{{ successMessage }}</div>

        <div class="total">
          <span class="label">Total:</span>
          <span class="amount">{{form.get('amount').value + getShippingCost(form.get('deliveryMethod').value) | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
        </div>

        <div class="funding-content_details-block__footer">
          <button class="withdraw--btn large" id="withdrawButton" (click)="!pendingWithdraw && onWithdraw(true)" [disabled]="form.invalid || form.get('amount').value <= 0 || pendingWithdraw">Withdraw</button>
          <div class="withdraw--tandc"> By submitting above, I agree to the <a cduxTerms="terms">Terms and Conditions</a></div>
        </div>
      </div>

    </form>
</ng-container>
<ng-container *ngIf="!fpwEnabled">
  <form class="withdraw-form-wrapper mail-check" [formGroup]="form" *ngIf="form">

    <cdux-funding-input-amount id="amountToWithdraw" formControlName="amount"
      [inputType]="OPERATION"
      [fundType]="fundingMethodDetails.fundType"
      [min]="0"
      [displayMin]="false"
      [max]="getMaxWithdrawAmount()"
      [phoneEnabled]="phoneEnabled"
      [displayMax]="false">
    </cdux-funding-input-amount>

    <cdux-funding-pending-balances [withdrawBalance]="withdrawBalance"></cdux-funding-pending-balances>
    <div class="delivery-options-wrap" *ngIf="accountInfo">
      <div class="delivery-mail-header">
        Delivery Methods:
      </div>

      <cdux-radio-group name="delivery-option" formControlName="deliveryMethod">
        <ng-template ngFor let-method [ngForOf]="accountInfo.miscInfo.deliveryMethods">
          <cdux-radio-option [id]="'methodRadio-' + method.code" [value]="method.code">
            <b class="shout"> {{ method.cost ? '$' + method.cost : 'FREE' }} </b>
            <span> {{ method.displayName }} </span>
          </cdux-radio-option>
        </ng-template>
      </cdux-radio-group>

      <div class="delivery-mail-header">
        Mail Check To: <i class="icon--info" id="changeAddress" (click)="openChangeAddress()"></i>
      </div>
      <div class="delivery-mail-address">
        <span> {{ accountInfo.address.address1 }} </span>
        <span *ngIf="accountInfo.address.address2"> {{ accountInfo.address.address2 }} </span>
        <span> {{ accountInfo.address.city }}, {{ accountInfo.address.stateLabel }} {{ accountInfo.address.zipCode }}</span>
      </div>

      <div class="ts-checkbox" [hidden]="form.get('signatureRequired').disabled">
        <input formControlName="signatureRequired" id="sig-required" type="checkbox">
        <label for="sig-required"><b>Signature Required</b></label>
      </div>
    </div>

    <div class="ts-message error" *ngIf="errorCode !== null">{{ errorCode | cduxTranslate:'errorcodes':true }}</div>
    <div class="ts-message success" *ngIf="successfulWithdraw">{{ successMessage }}</div>

    <cdux-funding-transaction-summary
        transactionName="Withdrawal"
        [amount]="form.get('amount').value"
        feeName="Shipping Cost"
        [feeValue]="getShippingCost(form.get('deliveryMethod').value)">
    </cdux-funding-transaction-summary>

    <div class="cdux-button-wrapper">
      <button #withdrawButton id="withdrawButton" (click)="!pendingWithdraw && onWithdraw()" [disabled]="form.invalid || form.get('amount').value <= 0 || pendingWithdraw">
        Withdraw
      </button>
    </div>

  </form>
</ng-container>

