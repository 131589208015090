import { Injectable } from '@angular/core';

import { ENVIRONMENT } from '@cdux/ng-core';
import { StatesService, TranslateService, JwtSessionService } from '@cdux/ng-common';

import { BET_ERROR_CODES } from '../enums/bet-error-codes.enum';
import { BET_SHARE_ERROR_CODES } from '../enums/bet-share-error-codes.enum';
import { IBetError } from '../interfaces/bet-error.interface';

const TRANSLATE_LANG = 'wager-errors';

/**
 * service to manage error state of bets in the bet slip.
 */
@Injectable()
export class BetSlipErrorsService {
    public static stateNamesMap;

    /**
     * The list of bets that are in an error state.
     * @type {Map<string, IBetError>} - key is the id of the bet that has an error
     * @private
     */
    private _betErrors: Map<string, IBetError> = new Map();

    constructor(
        private environment: ENVIRONMENT,
        private sessionService: JwtSessionService,
        private translateService: TranslateService
    ) {
        BetSlipErrorsService.stateNamesMap = StatesService.byAbbreviation();
    }

    /**
     * Determines if there are any current errors.
     *
     * @returns {boolean}
     */
    public hasErrors() {
        return this._betErrors.size > 0;
    }

    /**
     * set an error for a given bet
     *
     * @param {string} betId
     * @param {BetError} errorCode
     */
    public setError(betId: string, errorCode: string, isBetShare?: boolean): IBetError {
        if (isBetShare) { errorCode = this.mapErrorCode(errorCode); }
        this._betErrors.set(betId, this.buildError(errorCode));
        return this.getError(betId);
    }

    /**
     * get the error for a given bet
     *
     * @param {string} betId
     * @returns {BetError}
     */
    public getError(betId: string): IBetError {
        if (this._betErrors.has(betId)) {
            return this._betErrors.get(betId);
        }

        return null;
    }

    /**
     * remove the error for a given bet
     *
     * @param {string} betId
     */
    public removeError(betId: string) {
        /* istanbul ignore else */
        if (this._betErrors.has(betId)) {
            this._betErrors.delete(betId);
        }
    }

    /**
     * remove all bet errors
     */
    public removeAll() {
        this._betErrors.clear();
    }

    /**
     * creates an IBetError from a given error code
     *
     * @param {string} errorCode
     * @returns {IBetError}
     */
    public buildError(errorCode: string): IBetError {
        let outputErrorString = this.translateService.translate(errorCode, TRANSLATE_LANG);

        switch (errorCode) {
            case BET_ERROR_CODES.BS_SSN:
                return {errorCode: errorCode, errorString: BET_ERROR_CODES.BS_SSN, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false};
            case BET_ERROR_CODES.INSUFFICIENT_FUNDS:
            case BET_ERROR_CODES.INSUFFICIENT_FUNDS_NODEPOSIT:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false};
            case BET_ERROR_CODES.MAX_EXCEEDED:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: true, isPermanent: false, disableSubmit: true};
            case BET_ERROR_CODES.MIN_NOT_MET:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: true, isPermanent: false, disableSubmit: true};
            case BET_ERROR_CODES.POOL_CLOSED:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: true, disableEdit: false, disableCopy: true, isPermanent: false, disableSubmit: false};
            case BET_ERROR_CODES.PROGRAM_NOT_AVAILABLE:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: true, disableEdit: true, disableCopy: true, isPermanent: true, disableSubmit: true};
            case BET_ERROR_CODES.RACE_CLOSED:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: true, disableEdit: true, disableCopy: true, isPermanent: true, disableSubmit: true};
            case BET_ERROR_CODES.SCRATCH:
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: true, isPermanent: true, disableSubmit: true};
            case BET_ERROR_CODES.RESTRICT_PHONE_ONLY:
                outputErrorString = this.translateService.translate(
                    errorCode,
                    TRANSLATE_LANG,
                    TranslateService.OPT_FALLBACK_DISABLE,
                    BetSlipErrorsService.stateNamesMap[this.sessionService.getUserInfo().state],
                    this.translateService.translate('primary-phone-number-vanity', this.environment.affiliateId.toString())
                );
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: true, disableEdit: true, disableCopy: true, isPermanent: true, disableSubmit: true};
            case BET_ERROR_CODES.RESTRICT_TRACK:
                outputErrorString = this.translateService.translate(
                    errorCode,
                    TRANSLATE_LANG,
                    TranslateService.OPT_FALLBACK_DISABLE,
                    BetSlipErrorsService.stateNamesMap[this.sessionService.getUserInfo().state]
                );
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: true, disableEdit: true, disableCopy: true, isPermanent: true, disableSubmit: true};
            case BET_ERROR_CODES.GEOLOCATION_REQUIRED:
                outputErrorString = this.translateService.translate(
                    errorCode,
                    TRANSLATE_LANG,
                    TranslateService.OPT_FALLBACK_DISABLE,
                    BetSlipErrorsService.stateNamesMap[this.sessionService.getUserInfo().state]
                );
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false};
            case BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION:
                return { errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false };
            case BET_ERROR_CODES.CONDITIONAL_RESTRICTED:
                return { errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false };
            case BET_ERROR_CODES.CONDITIONAL_UNAVAILABLE:
                return { errorCode: errorCode, errorString: BET_ERROR_CODES.CONDITIONAL_UNAVAILABLE, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false };
            case BET_ERROR_CODES.EXPIRED_JWT:
                return { errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false };
            default:
                if (errorCode !== BET_ERROR_CODES.UNKNOWN_ERROR) {
                    console.log('unhandled wager error code: ' + errorCode);
                }
                return {errorCode: errorCode, errorString: outputErrorString, disableAmount: false, disableEdit: false, disableCopy: false, isPermanent: false, disableSubmit: false};
        }
    }

    /**
     * Betshare messages will differ from standard bets.
     * Match the error codes based on a partial match in the message property
     *
     * @param errorCode
     */
    private mapErrorCode(errorCode: string): string {
        errorCode = errorCode.toUpperCase();
        let mappedErrorCode: string = BET_ERROR_CODES.UNKNOWN_ERROR;

        Object.keys(BET_SHARE_ERROR_CODES).forEach(key => {
            const value: string = BET_SHARE_ERROR_CODES[key];
            if (errorCode.indexOf(value) >= 0) {
                // Assume the BET_SHARE_ERROR_CODES and the BET_ERROR_CODES enumerators have matching items
                mappedErrorCode = BET_ERROR_CODES[key];
                return;
            }
        });

        return mappedErrorCode;
    }
}
