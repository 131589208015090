import { NgModule } from '@angular/core';
import { Route, RouterModule, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { LayoutModule } from 'app/shared/layout/layout.module';
import { LayoutHeaderFooterComponent } from 'app/shared/layout/components/layout-header-footer/layout-header-footer.component';
import { WageringViewEnum } from './enums/wagering-view.enum';
import { WageringViewsRouteGuard } from './views.guard';
import { ViewFoundationComponent } from './view-foundation.component';
import { RunnerDetailsComponent } from 'app/shared/program/components/runner-details/runner-details.component';
import { ViewableWagerDataGuard } from 'app/shared/common/guards';
import { GreyhoundDetailsComponent } from 'app/shared/program/components/runner-details/greyhound-details.component';
import { DriverDetailsComponent } from 'app/shared/program/components/driver-details/driver-details.component';
import { TrainerDetailsComponent } from 'app/shared/program/components/trainer-details/trainer-details.component';
import { TrainerJockeyDetailsComponent } from 'app/shared/program/components/trainer-jockey-details/trainer-jockey-details.component';
import { SireDetailsComponent } from 'app/shared/program/components/ancestor-details/sire-details.component';
import { DamDetailsComponent } from 'app/shared/program/components/ancestor-details/dam-details.component';
import { ITrack, StringSlugifyPipe } from '@cdux/ng-common';

const routeParamKeys = ['view', 'trackName', 'track', 'type', 'race', 'section'];
let lastUsedView: WageringViewEnum = WageringViewEnum.CLASSIC; // default view

const isValidWageringView = (view: WageringViewEnum) => {
    for (const v in WageringViewEnum) {
        if (view === WageringViewEnum[v]) {
            return true;
        }
    }

    return false;
};

export function viewRouteMatcher(url: UrlSegment[], group: UrlSegmentGroup, route: Route) {
    // general pattern is :view/:trackName/:track/:type/:race/:section
    const normalized = [ ...url ], posParams = {};

    // use default view if empty
    if (normalized.length < 1 || !isValidWageringView(<WageringViewEnum> normalized[0].path)) {
        normalized.unshift(new UrlSegment(lastUsedView, {}));
    } else { // store the requested view for future reference
        lastUsedView = <WageringViewEnum> normalized[0].path;
    }

    // nullify omitted track params
    while (normalized.length < routeParamKeys.length) {
        normalized.push(null);
    }

    // validate and collect route parameters
    routeParamKeys.forEach((key, index) => {
        if (normalized[index]) { posParams[key] = normalized[index]; }
    });

    return { consumed: url, posParams };
}

export const Routes: Route[] = [{
    path: '',
    component: LayoutHeaderFooterComponent,
    children: [
        {
            path: ':view/horse/:horseName',
            children: [
                {
                    path: 'id/:horseId',
                    component: RunnerDetailsComponent,
                    data: {
                        init: true
                    }
                },
                {
                    path: ':brisCode/:trackType/:raceNum/:horseId',
                    component: RunnerDetailsComponent,
                    data: {
                        init: true
                    }
                }
            ]

        },
        {
            path: ':view/harness',
            children: [
                {
                    path: ':horseName/:brisCode/:trackType/:raceNum/:programNum',
                    component: RunnerDetailsComponent,
                    canActivate: [ ViewableWagerDataGuard ],
                    data: {
                        init: true
                    }
                },
                {
                    path: ':trainerName/:brisCode/:trackType/:raceNum/:programNum/trainer',
                    component: TrainerDetailsComponent,
                    canActivate: [ ViewableWagerDataGuard ],
                    data: {
                        init: true,
                        showMobileVideoButton: false
                    }
                },
                {
                    path: ':trainerName/:brisCode/:trackType/:raceNum/:programNum/driver',
                    component: DriverDetailsComponent,
                    canActivate: [ ViewableWagerDataGuard ],
                    data: {
                        init: true,
                        showMobileVideoButton: false
                    }
                }
            ]
        },
        {
            path: ':view/greyhound/:dogName/:brisCode/:trackType/:raceNum/:programNum',
            component: GreyhoundDetailsComponent,
            data: {
                init: true,
                showMobileVideoButton: false
            }
        },
        {
            path: ':view/jockey/:jockeyName',
            data: {
                showMobileVideoButton: false
            },
            children: [
                {
                    path: 'id/:jockeyId',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true
                    },
                    children: [
                        {
                            path: ':trainerId',
                            component: TrainerJockeyDetailsComponent,
                            data: {
                                init: true
                            }
                        }
                    ]
                },
                {
                    path: ':brisCode/:trackType/:raceNumber/:jockeyId/change',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true,
                        change: true
                    },
                },
                {
                    path: ':brisCode/:trackType/:raceNumber/:jockeyId/:trainerId/change',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true,
                        change: true
                    },
                },
                {
                    path: ':brisCode/:trackType/:raceNumber/:jockeyId',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true
                    },
                    children: [
                        {
                            path: ':trainerId',
                            component: TrainerJockeyDetailsComponent,
                            data: {
                                init: true
                            }
                        }
                    ]
                }
            ]
        },
        {
            path: ':view/trainer/:trainerName',
            data: {
                showMobileVideoButton: false
            },
            children: [
                {
                    path: 'id/:trainerId',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true
                    },
                    children: [
                        {
                            path: ':jockeyId',
                            component: TrainerJockeyDetailsComponent,
                            data: {
                                init: true
                            }
                        }
                    ]
                },
                {
                    path: ':brisCode/:trackType/:raceNumber/:trainerId',
                    component: TrainerJockeyDetailsComponent,
                    data: {
                        init: true
                    },
                    children: [
                        {
                            path: ':jockeyId',
                            component: TrainerJockeyDetailsComponent,
                            data: {
                                init: true
                            }
                        }
                    ]
                }
            ]
        },
        {
            path: ':view/sire/:sireName/id/:sireId',
            component: SireDetailsComponent,
            data: {
                init: true,
                showMobileVideoButton: false
            }
        },
        {
            path: ':view/dam/:damName/id/:damId',
            component: DamDetailsComponent,
            data: {
                init: true,
                showMobileVideoButton: false
            }
        },
        { component: ViewFoundationComponent, matcher: viewRouteMatcher }
    ],
    canActivate: [ WageringViewsRouteGuard ],
    canDeactivate: [ WageringViewsRouteGuard ]
}];

@NgModule({
    imports: [
        RouterModule.forChild(Routes),
        LayoutModule.forRoot()
    ],
    providers: [
        WageringViewsRouteGuard
    ],
    exports: [
        RouterModule
    ]
})
export class WageringViewsRoutingModule {
    public static getVideoPath(track: ITrack, race?: number) {
        if (!track) {
            return 'program/' + WageringViewEnum.VIDEO;
        }

        const stringSlugify = new StringSlugifyPipe();
        return 'program/' + WageringViewEnum.VIDEO +
            '/' + stringSlugify.transform(track.DisplayName || 'track') +
            '/' + track.BrisCode.toLowerCase() +
            '/' + track.TrackType +
            '/' + (race || track.RaceNum || '');
    }
}
