import { LANG_COMMON } from './common';

export const LANG_2800_NAME = '2800';

// 2800 specific extension of the common affiliate dictionary
const LANG_2800_EXTENSION = {
  'customer-service': '1-877-SPIRES1 (1-877-774-7371)',
  'player-services-email': 'player.services@twinspires.com',
  'registration-cellphone-alert-header': 'TwinSpires Alerts:',
  'registration-cellphone-alert-body': '4 msgs/mo. Msg & data rates apply. Text HELP to 70820 for help or email player.services@twinspires.com, STOP to 70820 to end.',
  'registration-ssn-alert-body': 'Twinspires is committed to responsible wagering and complying with state and federal regulations.',
  'registration-player-services-fax': '1-859-223-9441',
  'registration-player-services-phone': '1-855-845-5035',
  'registration-account-exists-body': 'You may already have a TwinSpires account. You can retrieve your account information and reset your password ',
  'registration-success-header-woffer': 'Welcome to TwinSpires',
  'bet-share-registration-success-header-woffer': 'Welcome to TwinSpires',
  'primary-phone-number': '1-877-774-7371',
  'primary-phone-number-vanity': '1-877-SPIRES1',
  'primary-phone-hyperlink': 'tel:18777747371',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': 'http://www.facebook.com/twinspires',
  'twitter-url': 'http://www.twitter.com/twinspires',
  'instagram-url': 'http://www.instagram.com/twinspires',
  'support-display-social-media-icons': 'true',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'TwinSpires.com',
  'moneygram-receiveId' : '12908',
  'affiliate-name': 'TwinSpires',
  'betpad-unavailable-title': 'SORRY',
  'betpad-signedout-title': 'SIGNED OUT',
  'betpad-signin-button': 'SIGN IN',
  'betpad-unavailable-button': 'RETURN',
  'betpad-invalid-url-copy': 'The page you requested could not be found.',
  'betpad-unavailable-copy': 'You must be signed in to use Bet Pad.',
  'tournament-modal-redirect-homepage-title': 'Homepage Unavailable',
  'tournament-modal-redirect-homepage-subTitle': 'You are logged into your tournament account. To access the Twinspires homepage, you must be in your primary account.',
  'tournament-modal-redirect-register-title': 'REGISTRATION UNAVAILABLE',
  'tournament-modal-redirect-register-subTitle': 'You are logged into your tournament account. To register for a tournament, you must be in your primary account.',
};

export const LANG_2800_TRANS = Object.assign({}, LANG_COMMON, LANG_2800_EXTENSION);
