    <div *cduxLoading="loadingOverlayName; strategy:'replace'; component:LoadingDotsComponent" class="pp-classic-wrapper" [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
        <ng-container *ngIf="availableProducts">
            <div class="message-wrap">
                <span class="ts-message" *ngIf="availableProducts.length > 0; else pastPerformancesUnavailable">
                    Complimentary Past Performances are FREE for players who complete a wager on this track.
                </span>
                <ng-template #pastPerformancesUnavailable>
                    <span class="ts-message">
                        We don't currently have free handicapping information for this track.
                    </span>
                </ng-template>
            </div>
            <div *ngIf="availableProducts.length > 0" class="info-wrap">
                <i class="icon--info"></i>
                <span class="info-text">
                    <ng-container *ngIf="isTrusted; else Untrusted">
                        By downloading a product, you acknowledge that your account balance will be deducted the listed price of the product if you do not complete a wager for this track on this specific race date.
                    </ng-container>
                    <ng-template #Untrusted>
                        By downloading a product, you agree to purchase the product with your wagering account funds understanding that you will be refunded the listed price of the product after completing a wager on this track on this specific race date. Refunds will typically appear within 24 hours after the final race is completed.
                    </ng-template>
                </span>
            </div>
            <div class="pp-cards-wrapper">
                <ng-container *ngFor="let ap of availableProducts">
                    <div class="pp--card" *ngFor="let ar of ap.availableProduct.availableRaces">
                        <div class="ts-card-head">{{ap.menuItem.label}}</div>
                        <div class="ts-card-head" *ngIf="!isProductOwned(ap)">
                            {{getCustomerPrice(ap) | currency:'USD':'symbol-narrow':'1.2' }}
                        </div>
                        <div class="ts-card-body">{{ap.menuItem.item.description}}</div>
                        <div class="ts-card-error" *ngIf="ap.errorMessage || ap.showDepositLink">
                            {{ap.errorMessage}} <a *ngIf="ap.showDepositLink" (click)="goToDeposit()">Deposit Now</a>
                        </div>
                        <ng-container *ngIf="isProductOwned(ap); else NotYetOwned">
                            <div class="btn-wrap" (click)="download(ap)" id="downloadPPs">
                                <button class="btn--primary" [disabled]="ap.isPending">View</button>
                            </div>
                        </ng-container>
                        <ng-template #NotYetOwned>
                            <div class="btn-wrap" (click)="makePromiseAndDownload(ap)">
                                <button class="btn--primary" [disabled]="ap.isPending">Download</button>
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
                <div class="pp--card" *ngIf="handicappingStoreEnabled && !isPhone">
                    <div class="ts-card-head uppercase">all handicapping</div>
                    <div class="ts-card-body">
                        View all handicapping products
                    </div>
                    <div class="btn-wrap" (click)="goToFullHandicapping()" id="shopAllPPs">
                        <button class="btn--primary">Shop all</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
