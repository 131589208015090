import { LANG_COMMON } from './common';

export const LANG_6100_NAME = '6100';

// 6100 specific extension of the common affiliate dictionary
const LANG_6100_EXTENSION = {
  'customer-service': '1-866-607-7929',
  'player-services-email': 'help@CharlieDeltaIndia.com',
  'registration-cellphone-alert-header': '',
  'registration-cellphone-alert-body': '',
  'registration-ssn-alert-body': 'CharlieDeltaIndia is committed to responsible wagering and complying with state and federal regulations.',
  'registration-player-services-fax': '1-866-607-7929',
  'registration-player-services-phone': '1-866-607-7929',
  'registration-account-exists-body': 'You may already have a CharlieDeltaIndia account. You can retrieve your account information and reset your password ',
  'registration-success-header-woffer': 'Welcome to CharlieDeltaIndia',
  'bet-share-registration-success-header-woffer': 'Welcome to CharlieDeltaIndia',
  'primary-phone-number': '1-866-607-7929',
  'primary-phone-number-vanity': '1-866-607-7929',
  'primary-phone-hyperlink': 'tel:18666077929',
  'secondary-phone-number-2': '',
  'secondary-phone-number-vanity': '',
  'facebook-url': 'http://www.facebook.com/CharlieDeltaIndia#!/CharlieDeltaIndia',
  'twitter-url': 'https://twitter.com/CharlieDeltaIndia',
  'instagram-url': '',
  'support-display-social-media-icons': 'true',
  'support-display-feedback-link': 'true',
  'support-display-faq-link': 'true',
  'support-display-chat-link': 'true',
  'moneygram-affiliateId': 'CharlieDeltaIndia.com',
  'moneygram-receiveId': '12345',
  'affiliate-name': 'CharlieDeltaIndia'
};

export const LANG_6100_TRANS = Object.assign({}, LANG_COMMON, LANG_6100_EXTENSION);
