import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';

import { EventClickType, ITrackBasic, TodaysDisplayTrack } from '@cdux/ng-common';
import { enumDropdownStates } from '@cdux/ng-fragments';

import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { ITrackListSectionDisplay } from 'app/shared/race-navigation/track-list/track-list-section-display.interface';
import { DisplayModeEnum } from 'app/shared/common/enums';

@Component({
    selector: 'cdux-betpad-tracklist',
    templateUrl: './betpad-track-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetPadTrackListComponent {
    /* Inputs/Outputs */

    // setup tracks from a provided list
    private _tracks: ITrackGroupLists;
    @Input()
    public set tracks(newTracks: ITrackGroupLists | null) {
        if (!newTracks) {
            return;
        }
        this._tracks = newTracks;
        this._changeDetector.detectChanges();
    }
    public get tracks(): ITrackGroupLists {
        return this._tracks;
    }

    @Input()
    public selectedTrack: ITrackBasic;

    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        switch (value) {
            case enumProgramViews.BETPAD:
                this.showRace = false;
                this.trackListSections = {canceled: false};
                this._changeDetector.detectChanges();
                break;
        }
    }
    public get programView(): enumProgramViews {
        return this._programView;
    }

    @Input() displayMode: DisplayModeEnum;
    @Input() public externalOpenEnabled: boolean;

    @Output() onTrackChange = new EventEmitter<ITrackBasic>();
    @Output() onTrackDropdownStateChange: EventEmitter<enumDropdownStates> = new EventEmitter<enumDropdownStates>();

    /* Public properties */
    public showRace: boolean = true;
    public ProgramViewsEnum = enumProgramViews;
    public eventClickType = EventClickType;
    public trackListSections: ITrackListSectionDisplay;

    constructor(private _changeDetector: ChangeDetectorRef) {}

    public onDropdownStateChange(state: enumDropdownStates): void {
        this.onTrackDropdownStateChange.emit(state);
    }

    /**
     * Selects a track and closes the track drop down.
     *
     * @param {ITrackBasic} track
     */
    public onTrackSelection(track: TodaysDisplayTrack) {
        this.onTrackChange.emit(track.ITrackBasic);
    }

    public trackByTrackId(index: number, track: ITrackBasic) {
        return track.BrisCode + track.TrackType;
    }
}
