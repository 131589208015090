import { Component, Input, OnInit } from '@angular/core';
import { BasicBetType, Bet, enumBetModifier, enumBetSubtype, enumTrackType, ISelectedEntry, MultiRaceExoticBetType, RunnerListConfig, PoolType } from '@cdux/ng-common';
import { BetsModifiers, FormatRank, FormatRunnersPipe } from '@cdux/ng-fragments';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';


interface RunnerListElement {
    class: string;
    content: string;
}


@Component({
    selector: 'cdux-runner-list',
    templateUrl: './runner-list.component.html',
    styleUrls: ['./runner-list.component.scss']
})
export class RunnerListComponent implements OnInit {
    public runnerListElements: RunnerListElement[][];

    @Input() // probably should not use both bet and runnerList inputs
    public set bet(bet: Bet) {
        if (bet) {
            this.trackType = bet.track && bet.track.TrackType || null;
            this.raceLegs = +(bet.poolType && bet.poolType.MultipleRace && bet.poolType.Legs) || 1,
            this.runnerList = bet.runners && {
                subtype: bet.betSubtype || null,
                selectionCount: +(bet.poolType && bet.poolType.Legs) || 1,
                bettingInterests: bet.runners.map<ISelectedEntry[]>(
                    (leg) => leg.map<ISelectedEntry>((entry) => ({
                        ProgramNumber: entry.ProgramNumber,
                        BettingInterest: +entry.ProgramNumberCoupled
                    }))
                )
            } || null;
            this.raceNumbers = bet.raceNumbers;
            this.poolType = bet.poolType.Code;
        } else {
            this.trackType = null;
            this.raceLegs = 1;
            this.runnerList = null;
        }
    }
    public get bet(): Bet {
        return this.bet;
    }

    @Input() // must set track type, race number, and race legs manually
    public set runnerList(runnerList: RunnerListConfig | string) {
        this._runnerList = runnerList;
        this.runnerListElements = this._generateRunnerList();
    }
    public get runnerList(): RunnerListConfig | string {
        return this._runnerList;
    }
    private _runnerList: RunnerListConfig | string = null;

    @Input()
    public set runnerListDelimiter(delimiter: string) {
        this._runnerListDelimiter = delimiter;
        this.runnerListElements = this._generateRunnerList();
    }
    public get runnerListDelimiter(): string {
        return this._runnerListDelimiter;
    }
    private _runnerListDelimiter: string = null;

    @Input()
    public set trackType(trackType: enumTrackType) {
        this._trackType = trackType;
        this.runnerListElements = this._generateRunnerList();
    }
    public get trackType(): enumTrackType {
        return this._trackType;
    }
    private _trackType: enumTrackType = enumTrackType.TBRED;

    @Input()
    public set betType(betType: BasicBetType | MultiRaceExoticBetType) {
        this._betType = betType;
        if (betType?.poolType) {
            this.poolType = betType.poolType;
        }
        if ((<MultiRaceExoticBetType> betType)?.legRaceNumbers) {
            this.raceNumbers = (<MultiRaceExoticBetType> betType).legRaceNumbers;
        } else {
            this.raceNumbers = [];
        }
    }
    public get betType(): (BasicBetType | MultiRaceExoticBetType) {
        return this._betType;
    }
    private _betType: BasicBetType | MultiRaceExoticBetType = null;

    @Input() // can set from bet type
    public set poolType(poolType: PoolType | string) {
        if (typeof poolType === 'string') {
            poolType = PoolType.getPoolTypeByCode(poolType);
        }
        this.raceLegs = poolType && poolType.raceLegs || 1;
    }

    @Input() // can set from pool type
    public raceLegs = 1;

    @Input() // can set from bet type
    public raceNumbers: number[] = [];

    @Input()
    public inline: boolean = false;

    @Input()
    public collapseBoxedRunners: boolean = true;

    private _hasInit: boolean = false;

    public FormatRank = FormatRank;


    constructor (private _formatRunnersPipe: FormatRunnersPipe, private _wageringUtil: WageringUtilBusinessService) { }


    public ngOnInit(): void {
        this._hasInit = true;
        this.runnerListElements = this._generateRunnerList();
    }

    private _generateRunnerList(): RunnerListElement[][] {
        let runnerListElements: RunnerListElement[][];
        if (!this._hasInit || !this._runnerList || !this._trackType) {
            runnerListElements = [];
        } else if (typeof this._runnerList === 'string') {
            runnerListElements = this._generateRunnerListFromString(this._runnerList, this._runnerListDelimiter || ',');
        } else {
            runnerListElements = this._generateRunnerListFromConfig(this._runnerList);
        }

        // pad the runner list with with unspecified legs
        while (runnerListElements.length < this.raceLegs) {
            runnerListElements.push([]);
        }

        return runnerListElements;
    }

    private _generateRunnerListFromString(runnerList: string, delimiter): RunnerListElement[][] {
        const runnerListArray = runnerList?.split(delimiter) || [];
        if (!this.collapseBoxedRunners && runnerListArray[0] === BetsModifiers.BOX) {
            runnerList = runnerListArray.slice(1).join(delimiter); // skip format
        } else {
            runnerList = this._formatRunnersPipe.transform(runnerList, delimiter);
        }

        // Runner list from Tote may lead with a letter:
        // T = Taxed         F = Federal       S = State
        // I = ID Required   R = Tax Receipt   Q = Quick
        // B = Box           W = Wheel         L = Leading
        // K = Key Wheel     X = Key Box       P = Power Box
        return runnerList.split(/\//).map<RunnerListElement[]>((list: string) => list
            .replace(/\s/g, '') // remove any white space chars
            .replace(/^\D+/, '') // remove any leading non-digits
            .split(/\b/) // tokenize on word boundaries (comma/dash)
            .filter(token => token !== delimiter) // omit delimeters
            .map<RunnerListElement>(token => (
                /^W?[0-9]{1,2}[A-Z]?/.test(token) // check token for a program number
                    ? this._generateRunnerListElement(token, +token.replace(/\D/, ''))
                    : this._generateSeparatorElement(token)
            ))
        );
    }

    private _generateRunnerListFromConfig(config: RunnerListConfig): RunnerListElement[][] {
        const runnerListElements = config.bettingInterests.map<RunnerListElement[]>((legEntries) => {
            if (!this.collapseBoxedRunners && (config.subtype === enumBetModifier.BOX || config.subtype === enumBetSubtype.BOX)) {
                return legEntries.map(e => this._generateRunnerListElement(e.ProgramNumber, e.BettingInterest));
            }
            return this._collapseRanges(legEntries);
        });

        if (config.bettingInterests.length && (config.subtype === enumBetModifier.KEY || config.subtype === enumBetSubtype.KEY)) {
            // shallow copy the WITH selections (index 1) to following legs (KEY is index 0)
            for (let i = 2; i < config.selectionCount; i++) {
                runnerListElements[i] = runnerListElements[1].slice();
            }
        }

        return runnerListElements;
    }

    private _generateRunnerListElement(programNumber: string, bettingInterest: number): RunnerListElement {
        return {
            content: programNumber.replace(/F$/, ''),
            class: 'saddle-cloth ' + this._wageringUtil.getSaddleClothClass(
                this._trackType, parseInt(bettingInterest.toString(), 10)
            )
        };
    }

    private _generateSeparatorElement(content: string) {
        return { content, class: 'seperator' };
    }

    private _collapseRanges(entries: ISelectedEntry[]): RunnerListElement[] {
        let range: ISelectedEntry[] = []; // temporary storage for reduce
        return entries.slice().sort(this._sortEntries) // clone then sort
            .reduce<RunnerListElement[]>((list, entry, i) => { // process
                if (range.length < 1 || +range[range.length - 1].ProgramNumber === +entry.ProgramNumber - 1) {
                    range.push(entry); // entry in current range
                } else { // entry does not fit the current range
                    this._pushRangeToList(range, list); // store
                    range = [ entry ]; // create new entry range
                }

                if ((i + 1) >= entries.length && range.length > 0) {
                    this._pushRangeToList(range, list); // push remainder
                }

                return list;
            }, [])
    }

    private _sortEntries(a: ISelectedEntry, b: ISelectedEntry): number {
        return (a.BettingInterest - b.BettingInterest) || (
            a.ProgramNumber < b.ProgramNumber ? -1 :
            a.ProgramNumber > b.ProgramNumber ? 1 :
            0
        );
    }

    private _pushRangeToList(range: ISelectedEntry[], list: RunnerListElement[]) {
        if (range.length >= 1) { // push first entry from range
            list.push(this._generateRunnerListElement(range[0].ProgramNumber, range[0].BettingInterest));
        }
        if (range.length >= 2) { // push final entry from range
            // use a range indicator character when more than 2
            if (range.length > 2) {
                list.push(this._generateSeparatorElement('-'));
            }
            list.push(this._generateRunnerListElement(
                range[range.length - 1].ProgramNumber,
                range[range.length - 1].BettingInterest
            ));
        }
    }
}
