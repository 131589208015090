import { Component } from '@angular/core';
import { CduxAbstractSidebarComponent } from "../../../sidebar/cdux-sidebar-component.class";
import { ISidebarComponentProperties } from "../../../sidebar/interfaces/sidebar-portal-component.interface";

@Component({
    selector: 'cdux-mfa-form-header',
    templateUrl: './mfa-form-header.html',
    styleUrls: ['./mfa-form-header.scss']
})
export class MfaFormHeaderComponent extends CduxAbstractSidebarComponent {
    public setProperties(properties: ISidebarComponentProperties): void {
        // not used but has to be implemented
    }
}
