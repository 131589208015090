import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';

import { BetPadView } from 'app/shared/program/enums/bet-pad-view.enum';

type CanActivateResponseType =
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree

const PATH_BET_PAD_ROOT = '/' + BetPadView.ROOT_PATH;

/**
 * BetPad appears to LayoutHeaderFooterComponent simply as TUX on a small
 * glass device. As a consequence, components using LHFC indifferently will
 * show the header with navigation elements specific to TUX. The back button
 * uses the manual history of CduxRouteUtil.history and the home button goes
 * to TUX's "home" page.
 *
 * Where applied, this guard will determine if the user is in BetPad (based
 * on Router.url) attempting to load a route not in BetPad (based on the
 * state.url). If so, it interrupts navigation, prepends "/betpad" to the
 * original URL and navigates to it, instead, passing along any state data.
 *
 * This means that any route guarded by BetPadWindowGuard must have a
 * corresponding "/betpad/{route}" to avoid unpredictable behavior or errors.
 */
@Injectable()
export class BetPadWindowGuard implements CanActivate {
    constructor (
        private _router: Router,
    ) {}

    public canActivate (nextRoute: ActivatedRouteSnapshot, nextState: RouterStateSnapshot): CanActivateResponseType {
        if (this._router.url.indexOf(PATH_BET_PAD_ROOT) === 0 && nextState.url.indexOf(PATH_BET_PAD_ROOT) !== 0) {
            // console.log('%cBetPadWindowGuard detected BetPad', 'border: 3px double aquamarine; color: aquamarine; padding: 1px 6px; line-height: 2em; font-weight: bold;');
            this._router.navigateByUrl(PATH_BET_PAD_ROOT + nextState.url, {
                state: this._router.getCurrentNavigation().extras.state,
            });
            return false;
        }
        return true;
    }
}
