import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidebarComponent } from '../../../../sidebar-component';

@Component({
    selector: 'cdux-default-sidebar',
    templateUrl: './default-sidebar.component.html',
    styleUrls: ['./default-sidebar.component.scss']
})
export class DefaultSidebarComponent extends SidebarComponent implements OnInit, OnDestroy {

    ngOnInit() {
        console.log('Main Sidebar (re)loaded');
    }

    ngOnDestroy() {
        console.log('Main Sidebar destroyed');
    }
}
