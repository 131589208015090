<div [class.compact]="displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE">
    <div class="angles-top-pick-bar-container" *ngIf="displayMode === DisplayModeEnum.LARGE || isTop3PicksEnabled">
        <div *ngIf="displayMode === DisplayModeEnum.LARGE" class="what-is program-angles-tooltip hover click">
            <span>
                What are Angles?
                <div class="icon--info"></div>
            </span>
            <div class="program-angles-tooltip-info tooltip-content">
                <h2 *cduxMediaToggle="'phone'; switch: false">ANGLES</h2>
                <div [ngClass]="(displayMode === DisplayModeEnum.COMPACT || displayMode === DisplayModeEnum.MOBILE) ? 'angles-info' : 'angles-columns'">
                    <!-- Left Column -->
                    <div>
                        <span *ngFor="let angleNameKey of anglesNamesKeys">
                            <span *ngIf="anglesNames[angleNameKey].displaySide === 'left'">
                                <h3>{{anglesNames[angleNameKey].displayName | uppercase}}</h3>
                                <ul>
                                    <li>{{anglesNames[angleNameKey].descriptiveText}}</li>
                                </ul>
                            </span>
                        </span>
                    </div>
                    <!-- Right Column -->
                    <div>
                        <span *ngFor="let angleNameKey of anglesNamesKeys">
                            <span *ngIf="anglesNames[angleNameKey].displaySide === 'right'">
                                <h3>{{anglesNames[angleNameKey].displayName | uppercase}}</h3>
                                <ul>
                                    <li>{{anglesNames[angleNameKey].descriptiveText}}</li>
                                </ul>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-picks-container" *ngIf="isTop3PicksEnabled">
            <cdux-top-3-button class="top-box" [category]="top3Category.PICKS"
                (select)="onSelection.emit($event); category = top3Category.PICKS" [wagerState]="wagerState"
                [toteDate]="toteDate"></cdux-top-3-button>
            <cdux-top-3-button class="top-box" [category]="top3Category.SPEED"
                (select)="onSelection.emit($event); category = top3Category.SPEED" [wagerState]="wagerState"
                [toteDate]="toteDate"></cdux-top-3-button>
            <cdux-top-3-button class="top-box" [category]="top3Category.CLASS"
                (select)="onSelection.emit($event); category = top3Category.CLASS" [wagerState]="wagerState"
                [toteDate]="toteDate"></cdux-top-3-button>
            <cdux-top-3-button class="top-box" [category]="top3Category.PACE"
                (select)="onSelection.emit($event); category = top3Category.PACE" [wagerState]="wagerState"
                [toteDate]="toteDate"></cdux-top-3-button>
        </div>
    </div>
</div>
