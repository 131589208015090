<section class="cdi-terms-wrapper lightBackground">

    <div class="container cdi-terms" style="padding-top: 1px;">
      <div class="cdi-terms-content">
        <h2>Address</h2>
        <p>If your address is incorrect, you will need to <span *ngIf="phoneEnabled else chat">contact Player Services at {{ 'customer-service' | cduxTranslate:affiliateId }}</span> in order to update your address.</p>
      </div>
      <ng-template #chat>
        <cdux-chat></cdux-chat>
      </ng-template>
    </div>

  </section>
