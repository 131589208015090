import { ElementRef, Injectable } from '@angular/core';

import { combineLatest, iif, Observable, of, timer } from 'rxjs';
import { catchError, map, startWith, switchMap, take } from 'rxjs/operators';

import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumTodaysTracksSortOrder,
    enumTrackStatus,
    enumTrackType,
    FeatureToggleDataService,
    IRaceIdentifier,
    IReplayTrackResponse,
    IReplayTracks,
    ITodaysRace,
    ITodaysTrack,
    ITrack,
    ITrackBasic,
    ReplayDataService,
    TrackService,
    VideoAngles
} from '@cdux/ng-common';

import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { TodaysRacesViewEnum } from 'app/shared/program/enums/todays-races-view.enum';
import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { takeWhileInclusive } from 'app/shared/common/operators';

const LIVE_VIDEO_END_CONFIG_KEY = 'video_end_time_threshold';
const MUTED_STORAGE_KEY = 'video_muted';

export interface ILiveVideoEndedResult {
    hasEnded: boolean;
    timeRemaining: number;
}

@Injectable()
export class VideoService {
    private _isVideoThresholdFeatureToggleOn = null;
    private _liveVideoThresholdValue = null;
    private _liveVideoEndThresholdValue = null;
    private _videoContainer: ElementRef;

    public static isLiveUrl(url: string) {
        if (url.indexOf('/live') >= 0) {
            return true;
        } else if (url.indexOf('/replay') >= 0) {
            return false;
        } else {
            return null;
        }
    }

    public static findTrackInList(brisCode: string, trackType: enumTrackType, list: Array<ITrack>): ITrack | IReplayTracks {
        return list.find((track) => TrackService.isSameTrack(track, {BrisCode: brisCode, TrackType: trackType}))
    }

    constructor(
        private _replayDataService: ReplayDataService,
        private _featureToggleService: FeatureToggleDataService,
        private _configurationService: ConfigurationDataService,
        private _todaysRacesService: TodaysRacesBusinessService
    ) { }

    /**
     * returns MTP at which live video becomes available
     */
    public getLiveVideoThreshold(): Promise<number> {
        if (this._isVideoThresholdFeatureToggleOn === null) {
            this._isVideoThresholdFeatureToggleOn = this._featureToggleService.isFeatureToggleOn('VIDEO_THRESHOLD');
        }

        return new Promise<number>((resolve, reject) => {
            if (!this._isVideoThresholdFeatureToggleOn) {
                reject();
            } else {
                if (this._liveVideoThresholdValue !== null) {
                    resolve(this._liveVideoThresholdValue);
                } else {
                    const key = 'video_time_threshold';
                    this._configurationService.getConfiguration(enumConfigurationStacks.TUX, key)
                        .pipe(take(1))
                        .subscribe(t => {
                            let value;

                            try {
                                value = parseInt(t[key], 10) || 30; // default to 30 if we get an invalid config value
                            } catch (e) {
                                value = 30; // default to 30 if we get an invalid config value
                            }

                            this._liveVideoThresholdValue = value;
                            resolve(this._liveVideoThresholdValue);
                        });
                }

            }
        });
    }

    public hasLiveVideoEnded(brisCode: string, trackType: enumTrackType): Observable<boolean> {
        return this._todaysRacesService.getTodaysTrackPoll(brisCode, trackType).pipe(
            takeWhileInclusive(track => track.status === enumTrackStatus.OPEN, true),
            switchMap((track) => {
                if (track.status === enumTrackStatus.OPEN) {
                    return of(false);
                } else {
                    // Keep a local cache of the live video end time
                    let configObs;
                    if (this._liveVideoEndThresholdValue !== null) {
                        configObs = of(this._liveVideoEndThresholdValue);
                    } else {
                        configObs = this._configurationService.getConfiguration(enumConfigurationStacks.TUX, LIVE_VIDEO_END_CONFIG_KEY);
                    }

                    return combineLatest([
                        configObs.pipe(take(1)),
                        // Calling this with poll=false results in another request attempt by the browser which we don't really need
                        this._todaysRacesService.getTodaysRaces(brisCode, trackType, true).pipe(take(1))
                    ]).pipe(
                        map((data) => {
                            if (Array.isArray(data) && data[0] && data[0][LIVE_VIDEO_END_CONFIG_KEY] !== null && Array.isArray(data[1]) && data[1].length) {
                                // If we haven't set a video end threshold do it now
                                if (this._liveVideoEndThresholdValue === null) {
                                    const parsedEndTime = parseInt(data[0][LIVE_VIDEO_END_CONFIG_KEY], 10);
                                    this._liveVideoEndThresholdValue = isNaN(parsedEndTime) ? 60 : parsedEndTime
                                }

                                const lastRace: ITodaysRace = data[1].slice(-1)[0];

                                if (lastRace && lastRace.postTimeStamp) {
                                    const now = Date.now();
                                    const postTimeWithThreshold = lastRace.postTimeStamp + (this._liveVideoEndThresholdValue * 60000);

                                    return { hasEnded: postTimeWithThreshold <= now, timeRemaining: postTimeWithThreshold - now };
                                } else {
                                    // If for whatever reason we don't have a lastRace or postTimeStamp let the people watch
                                    return { hasEnded: false, timeRemaining: null };
                                }
                            } else {
                                // If we don't get correct data let the people watch
                                return { hasEnded: false, timeRemaining: null };
                            }
                        }),
                        switchMap(
                            (data: ILiveVideoEndedResult) => iif (
                                () => (data.hasEnded || (!data.hasEnded && data.timeRemaining === null)),
                                of(data.hasEnded),
                                timer(data.timeRemaining).pipe(
                                    map(value => true), // All we care about is that the timer has emitted
                                    startWith(false)
                                )
                            )
                        ),
                        catchError(() => of(false))
                    );
                }
            }),
            catchError(() => of(false))
        )
    }

    /**
     * New Replay Tracks Service Endpoint
     * @param raceDate
     */
    public getReplayTracksObservable(raceDate: string): Observable<IReplayTrackResponse[]> {
        return this._replayDataService.getTracksReplayStatus(raceDate);
    }

    public getReplayStatusObservable(track: ITrack, raceDate: string, raceNumber: number): Observable<boolean> {
        return this._replayDataService.getRaceReplayStatus(<IRaceIdentifier>{
            brisCode: track.BrisCode, trackType: track.TrackType, raceDate, raceNumber
        });
    }

    public getReplayDates(track: ITrackBasic, fromDate: string, toDate?: string) {
        if (arguments.length === 3) {
            return this._replayDataService.getReplayDates(track, fromDate, toDate);
        } else {
            return this._replayDataService.getReplayDates(track, fromDate);
        }
    }

    public getLiveTrackListObservable(): Observable<ITrackGroupLists> {
        return this._todaysRacesService.getTodaysActiveTracks(true, true, enumTodaysTracksSortOrder.NEXTUP, TodaysRacesViewEnum.TIME);
    }

    public getLiveTrackObservable(trackCode: string, trackType: enumTrackType): Observable<ITodaysTrack> {
        return this.getLiveTrackListObservable().pipe(
            map((trackGroups) => {
                const foundTrack = trackGroups.generalTracks.find((track) => {
                    return track.brisCode === trackCode && track.type === trackType;
                });
                if (foundTrack) {
                    return foundTrack;
                }
            })
        )
    }

    public getReplayTracksListObservable(raceDate: string, trackDetails?: ITrackBasic): Observable<IReplayTracks[]> {
        if (!trackDetails) {
            trackDetails = <ITrackBasic>{
                BrisCode: '',
                DisplayName: '',
                TrackType: enumTrackType.TBRED
            };
        }
        return this._replayDataService.getTracksReplayStatus(raceDate, trackDetails).pipe(
            map((replayTracksResponse: IReplayTrackResponse[]) => {
                // takes the response from the dataservice and maps it to a useable tpye
                const replayTracks: IReplayTracks[] = [];
                replayTracksResponse.forEach((replayTrack: IReplayTrackResponse) => {
                    const tempReplayTrack: IReplayTracks = <IReplayTracks>{};
                    if (replayTrack.hasOwnProperty('races')) {
                        tempReplayTrack.races = replayTrack.races;
                    }
                    tempReplayTrack.BrisCode = replayTrack.trackId;
                    tempReplayTrack.TrackType = replayTrack.trackType as enumTrackType;
                    tempReplayTrack.DisplayName = replayTrack.trackName;
                    replayTracks.push(tempReplayTrack);
                });
                return replayTracks;
            }));
    }

    /**
     * Will only return a single track object when requested
     *
     * Wraps the list requests and simply unpacks it to return a single track
     * @param raceDate
     * @param trackDetails
     */
    public getReplaySingleTrackObservable(raceDate: string, trackDetails: ITrackBasic): Observable<IReplayTracks> {
        return this.getReplayTracksListObservable(raceDate, trackDetails).pipe(
            map((replayTracks: IReplayTracks[]) => {
                // find the requested track in the list
                const replayTrack = replayTracks.find((item) => item.BrisCode.toLowerCase() === trackDetails.BrisCode.toLowerCase() && item.TrackType.toLowerCase() === trackDetails.TrackType.toLowerCase());
                if (replayTrack) {
                    return replayTrack;
                }
            })
        );
    }

    public registerVideoContainer (el: ElementRef) {
        this._videoContainer = el;
    }

    /**
     * returns height of registered video container
     *
     * This method can be used to adjust offset of elements below the
     * video container. When closed or not present, the value is 0, so
     * this can also be a simple boolean for whether the video container
     * is toggled open.
     */
    public getVideoContainerHeight (): number {
        if (this._videoContainer && this._videoContainer.nativeElement) {
            return this._videoContainer.nativeElement.offsetHeight;
        }
        return 0;
    }

    public feedAnglesObs(brisCode: string, trackType: enumTrackType, race: number, raceDate: string): Observable<VideoAngles[]> {
        return this._replayDataService.getReplayAngles(brisCode, trackType, race, raceDate);
    }

    public getMuted(): boolean {
        return localStorage.getItem(MUTED_STORAGE_KEY) === 'true';
    }

    public setMuted(muted: boolean) {
        localStorage.setItem(MUTED_STORAGE_KEY, (!!muted).toString());
    }
}
