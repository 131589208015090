import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TemplatePortal } from '@angular/cdk/portal';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { BasicBetType, enumPoolType, enumRaceStatus, EventClickAttributeType, EventClickType, MultiRaceExoticBetType, ProgramEntry, ToteDataService, TrackService, WagerService, WagerState } from '@cdux/ng-common';
import { CduxMediaToggleService, DropupModalConfig, DropupPrebuiltComponent, enumMediaTarget, ModalRef, ModalService } from '@cdux/ng-platform/web';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { ISelectionUpdate } from 'app/shared/program/interfaces/selection-update.interface';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { WageringUtilBusinessService } from 'app/shared/program/services/wagering-util.business.service';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';


@Component({
    selector: 'cdux-bris-picks',
    templateUrl: './bris-picks.component.html',
    styleUrls: ['./bris-picks.component.scss']
})
export class BrisPicksComponent implements OnInit, OnDestroy {
    private static SUGGESTED_WAGER_AMOUNT = 1; // default base bet amount


    @Output()
    public onSelection = new EventEmitter<ISelectionUpdate[]>();


    @Input()
    public view: WageringViewEnum;

    @Input()
    public section: ViewSectionEnum;

    @Input()
    public raceStatus: enumRaceStatus;

    @Input()
    public isCompact: boolean = false;


    @Input()
    public set wagerState(wagerState: WagerState) {
        this._wagerStateSubject.next(wagerState);
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }
    private _wagerState: WagerState;
    private _wagerStateSubject = new BehaviorSubject<WagerState>(null);


    public brisBetComment: SafeHtml = '';
    public brisBetEntries: ProgramEntry[] = [];

    public betType: (BasicBetType | MultiRaceExoticBetType);
    public wagerAmount: number = BrisPicksComponent.SUGGESTED_WAGER_AMOUNT;

    public isSelected: boolean = false;
    public isWagerable: boolean = false;

    private _toteDate: string;
    private _destroy = new Subject<void>();
    private _raceSubscription: Subscription;


    @ViewChild('logoPortal')
    private _logoPortal: TemplatePortal<any>;
    @ViewChild('raceNarrativePortal')
    private _raceNarrativePortal: TemplatePortal<any>;
    @ViewChild('raceNarrativeModal')
    private _raceNarrativeModal: TemplateRef<any>;
    private _modalRef: ModalRef<any>;


    constructor (
        private _domSanitizer: DomSanitizer,
        private _eventTrackingService: EventTrackingService,
        private _mediaService: CduxMediaToggleService,
        private _modalService: ModalService,
        private _todaysRacesService: TodaysRacesBusinessService,
        private _toteDataService: ToteDataService,
        private _wagerService: WagerService,
        private _wagerUtilService: WageringUtilBusinessService
    ) { }

    public ngOnInit() {
        this._toteDataService.currentRaceDate().pipe(
            takeUntil(this._destroy)
        ).subscribe((date: string) => {
            this._toteDate = date;
        });

        this._wagerStateSubject.pipe(
            debounceTime(200),
            takeUntil(this._destroy)
        ).subscribe((wagerState) => {
            if (!TrackService.isSameTrack(this.wagerState?.basicTrack, wagerState?.basicTrack) ||
                this.wagerState?.basicTrack?.RaceNum !== wagerState?.basicTrack?.RaceNum) {
                this._wagerState = wagerState || null;
                this._getBrisPicks();
            } else if (this.isSelected) {
                this.isSelected = wagerState?.bettingInterests?.length > 0 && wagerState.bettingInterests.every((leg) =>
                    leg?.length === this.brisBetEntries.length && leg.every((entry) =>
                        this.brisBetEntries.find(e => e.ProgramNumber === entry.ProgramNumber)
                    )
                );
            }
        });

        this._wagerService.wagerPlaced.pipe(
            takeUntil(this._destroy)
        ).subscribe((result) => {
            if (result?.success && this.isSelected) {
                this.logClickEvent(EventClickType.BRIS_PICKS_SUBMIT_WAGER);
            }
        });
    }

    public ngOnDestroy(): void {
        this.closeNarrative();
        this._destroy.next();
    }


    public openNarrative(): void {
        if (this._mediaService.query(enumMediaTarget.PHONE)) {
            this.openDropup();
        } else {
            this._modalRef = this._modalService.open(
                this._raceNarrativeModal, {
                    hasBackdrop: true,
                    overflow: 'hidden',
                    padding: '0'
                }
            );
        }

        this.logClickEvent(EventClickType.BRIS_PICKS_BUTTON);
    }

    public openDropup(): void {
        const modalConfig = new DropupModalConfig();
        modalConfig.width = '100%';
        modalConfig.height = '100%';
        modalConfig.maxWidth = '100vw';
        modalConfig.maxHeight = '100vh';
        modalConfig.context = {
            close: () => this.closeNarrative(),
            headerText: 'BRIS Tips',
            headerComponent: this._logoPortal,
            component: this._raceNarrativePortal
        };
        this._modalRef = this._modalService.open(DropupPrebuiltComponent, modalConfig);
        this._modalRef.componentInstance.close.pipe(
            takeUntil(this._modalRef.afterClosed)
        ).subscribe(() => this._modalRef.close());
    }

    public closeNarrative(): void {
        this._modalRef?.close();
    }

    public selectEntries(): void {
        const selection: ISelectionUpdate[] = [{
            entries: this.brisBetEntries.map((e) =>
                this._wagerUtilService.toSelectedEntry(e)
            ),
            selected: true,
            leg: 0
        }];

        this.isSelected = true;
        this.onSelection.emit(selection);
        this.logClickEvent(EventClickType.BRIS_PICKS_SELECT_ENTRIES);
    }

    public logClickEvent(event: EventClickType) {
        const ts = Date.now();

        switch (event) {
            case EventClickType.BRIS_PICKS_BUTTON:
                this._eventTrackingService.logClickEvent(event, [
                    { attrId: EventClickAttributeType.BRIS_PICKS_BUTTON_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_BUTTON_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_BUTTON_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_BUTTON_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_BUTTON_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            case EventClickType.BRIS_PICKS_SELECT_ENTRIES:
                this._eventTrackingService.logClickEvent(event, [
                    { attrId: EventClickAttributeType.BRIS_PICKS_SELECT_ENTRIES_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SELECT_ENTRIES_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SELECT_ENTRIES_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SELECT_ENTRIES_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SELECT_ENTRIES_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            case EventClickType.BRIS_PICKS_SUBMIT_WAGER:
                this._eventTrackingService.logClickEvent(event, [
                    { attrId: EventClickAttributeType.BRIS_PICKS_SUBMIT_WAGER_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SUBMIT_WAGER_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SUBMIT_WAGER_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SUBMIT_WAGER_RACE_DATE, data: this._toteDate, timestamp: ts },
                    { attrId: EventClickAttributeType.BRIS_PICKS_SUBMIT_WAGER_TAB, data: this.section, timestamp: ts }
                ]);
                break;
            default:
                this._eventTrackingService.logClickEvent(event);
                break;
        }
    }

    private _getBrisPicks() {
        this.isSelected = false;
        this.brisBetComment = '';
        this.brisBetEntries = [];

        this._raceSubscription?.unsubscribe();
        if (!this.wagerState?.basicTrack) {
            return;
        }

        this._raceSubscription = combineLatest([
            this._todaysRacesService.getTodaysRaceBrisPicks(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            ),
            this._todaysRacesService.getTodaysRaceEntries(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            ),
            this._todaysRacesService.getTodaysRaceBetTypes(
                this.wagerState.basicTrack.BrisCode,
                this.wagerState.basicTrack.TrackType,
                this.wagerState.basicTrack.RaceNum,
                true
            )
        ]).pipe(
            takeUntil(this._destroy)
        ).subscribe(([brisPick, entries, betTypes]) => {
            // map valid program numbers to their program entry data
            this.brisBetEntries = !brisPick?.picks ? [] : // empty array if not set
                brisPick.picks.split(',').reduce((acc: ProgramEntry[], programNumber: string) => {
                    const entry = entries.find(e => !!e && e.ProgramNumber === programNumber);
                    if (entry) { acc.push(entry); }
                    return acc;
                }, []);

            let comment = '';
            let fullComment = brisPick.raceComment;
    
            this.brisBetEntries.forEach((pick, index) => {
                switch (index) {
                    case 0:
                        comment =  brisPick.introComment + ' ' + brisPick.topPickComment + '.';
                        break
                    case 1:
                        comment = brisPick.secondPickComment + '.';
                        break;
                    case 2:
                        comment = brisPick.thirdPickComment + '.';
                        break;
                }
                fullComment = fullComment + this._formatEntryName(comment, pick);
            });
            this.brisBetComment = this._domSanitizer.bypassSecurityTrustHtml(fullComment);

            // remove scratched entries after formatting comment
            this.brisBetEntries = this.brisBetEntries.filter(e => !e.Scratched);

            // determine if race is still open and wagerable
            this.isWagerable = TrackService.isWagerableRace(this.raceStatus);

            // determine if bet type and amount are available and we have enough entries
            this.betType = betTypes.find(bt => bt.poolType.code === enumPoolType.EXACTA &&
                bt.poolType.selectionCount <= this.brisBetEntries.length);
            this.wagerAmount = BrisPicksComponent.SUGGESTED_WAGER_AMOUNT;
            if (this.betType) {
                this.wagerAmount = +(this.betType.betAmounts.find((ba) => +ba.value === this.wagerAmount)
                    || this.betType.betAmounts[0]).value; // default to first amount if suggested not available
            }
        }, () => {
            this.brisBetComment = '';
            this.brisBetEntries = [];
        });
    }

   private _formatEntryName(comment: string, e: ProgramEntry): string {
        const entry = '#' + e.ProgramNumber + ' ' + e.Name.toUpperCase() + '</strong> ';
        return ' <strong' + (e.Scratched ? ' class="scratched">' : '>') + entry.replace(/\s/g,'&nbsp;') + comment;
    }
}
