<div class="label">Bets</div>
<div class="link bets-menu-item"
     [class.is-active]="isActive"
     [class.has-new-total]="highlightBetLabel || isShowingBetAdded"
     [class.success]="isSuccess"
     cduxWaypoint
     [waypointKey]="BETS_MENU_ITEM_WAYPOINT_KEY">
    <div *cduxMediaToggle="'phone'">
        {{ !isActive ? unplacedBets : '' }}
        <i class="menu-item-icon bets"></i>
    </div>
    <div *cduxMediaToggle="'desk-to-phone'">
        {{ unplacedBets }}
    </div>
    <!-- We force a &nbsp; if we don't have our bet count yet so the
             menu item doesn't jump around between initial render and the
             bet count showing up. -->
    <span *ngIf="unplacedBets === undefined">&nbsp;</span>
</div>
