import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import {
    Bet,
    BetAmountType,
    enumBetSubtype,
    EventClickType,
    IBetListRace,
    IBetType,
    IBetTypeConstraint,
    ISelectedBetAmount,
    WagerService
} from '@cdux/ng-common';
import { BetSlipBusinessService } from '../../services/bet-slip.business.service';
import { ModalService } from '@cdux/ng-platform/web';
import { Subject } from 'rxjs';
import { BetCustomAmountService } from '../../services/bet-custom-amount.service';
import { map, take } from 'rxjs/operators';
import { RestrictFloat } from '../../../common/utils';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-bet-custom-amount',
    templateUrl: './bet-custom-amount.component.html',
})
export class BetCustomAmountComponent implements OnInit {
    // Custom Bet Amount
    public betCustomAmount: Subject<ISelectedBetAmount> = new Subject<ISelectedBetAmount>();

    // The value held by the component
    private _innerValue: string;

    public hasError: boolean = false;

    // Bet base amount
    public betBaseAmount: number;

    // Bet maximum amount
    public betMaxAmount: number;

    // Bet multiple value
    public betMultiple: number;

    // error message to be shown accordingly
    public errorMessage: string;

    public eventClickType = EventClickType;

    // Is the input currently focused?
    public inputFocused = true;

    // Focusable HTML Element
    @ViewChild('customAmount') private _inputBlock: ElementRef;


    // Adding ng value
    set value(value: string) {
        const floatValue = parseFloat(value);
        const newValue = floatValue ? floatValue : '';
        if (newValue === '' || newValue.toString() !== this._innerValue) {
            this._innerValue = newValue.toString();
            this.hasErrors();
        }
    }


    get value(): string {
        return  this.innerValue;
    }

    get innerValue(): string {
        return this._innerValue;
    }

    constructor (private _WagerService: WagerService,
                 private _betSlipService: BetSlipBusinessService,
                 private _modalService: ModalService,
                 private _betCustomAmountService: BetCustomAmountService,
                 private _eventTrackingService: EventTrackingService) {
    }

    @HostListener('keypress', ['$event']) public onKeypress(event: KeyboardEvent) {
        if (event.which === 8) {
            // Empty
        } else if (!RestrictFloat(event, this.innerValue, 2)) {
            event.preventDefault();
        }
    }

    ngOnInit() {
        // Need to focus on load
        this.focus();
        // need to update the bet base amount and bet max amount according to bet list call
        this.updateBetMaxValues();
    }

    /**
     * Method for Focusing the Input Element
     */
    public focus() {
        if (this._inputBlock) {
            this._inputBlock.nativeElement.focus();
        }
        if (!this._innerValue) {
            this._innerValue = '';
        }
    }


    public onFocus() {
        this.inputFocused = true;
    }

    /**
     * Implemented as part of ControlValueAccessor
     */
    public onBlur(): void {
        this.inputFocused = false;
    }

    /**
     * need to verify the value entered
     */

    private hasErrors() {
        this.hasError = false;
        this.errorMessage = '';
        const customAmountError: string =  this._betCustomAmountService.hasError(this.betBaseAmount, this.betMaxAmount, this.betMultiple, (this.innerValue !== '') ? parseFloat(this._innerValue) : 0);
        if (customAmountError) {
            this.hasError = true;
            this.errorMessage = customAmountError;
        }
    }

    /**
     * update the bet base amount and bet max amount according to bet list call
     */
    private updateBetMaxValues() {
        const bet = Bet.fromBetlike(this._betSlipService.currentBet);
        const betCode: string = bet.poolType && bet.poolType.Code ? bet.poolType.Code : 'WN';
        const subType: enumBetSubtype = bet.betSubtype;

        this._WagerService.getBetList(bet.track.BrisCode,
            bet.track.TrackType,
            bet.track.RaceNum,
            false).pipe(
            // Get the bet types available for the specific race.
            map((betList: IBetListRace) => {
                return betList.BetTypes;
            }),
            // Get the bet type specified by the bet code.
            map((betTypes: IBetType[]) => {
                return betTypes.filter((betType) => {
                    return WagerService.normalizeBetCode(betType.BetCode) === betCode;
                })[0];
            }),
            // Get the bet amount list, if available. Otherwise, use the default.
            map((betType: IBetType) => {
                if (betType) {
                    const amountInfo = this.getBetInfo(betType, subType);
                    this.betBaseAmount = +betType.BaseAmount;
                    this.betMaxAmount =  +amountInfo.Max;
                    this.betMultiple =   +amountInfo.Multiple;
                }
            }),
            take(1)
        ).subscribe();

    }

    private getBetInfo(betType: IBetType, subType: enumBetSubtype): IBetTypeConstraint {
        switch (subType) {
            case enumBetSubtype.BOX:
                return betType.Extended.Box;
            case enumBetSubtype.KEYBOX:
            case enumBetSubtype.KEY:
            case enumBetSubtype.POWERBOX:
                return betType.Extended.Wheel;
            // straight and wheel use the straight constraint, I know, it's weird
            default:
                return betType.Extended.Straight;
        }
    }

    /**
     * Need to update the bet new custom bet amount
     */
    public updateBetAmount() {
        if (this.innerValue !== '' && !this.hasError) {
            this.betCustomAmount.next({value: this.innerValue, type: BetAmountType.CUSTOM});
            this._modalService.closeAll();
            this._eventTrackingService.logClickEvent(
                this.eventClickType.BET_CUSTOM_AMOUNT_OKAY
            )
        }
    }

    /**
     * need to close the modal
     */
    public close() {
        this._modalService.closeAll();
    }


}
