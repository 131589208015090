import { Component } from '@angular/core';
import { ModalService } from '@cdux/ng-platform/web';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'cdux-expert-video',
  templateUrl: './expert-video.component.html',
  styleUrls: ['./expert-video.component.scss']

})
export class ExpertVideoComponent {

    private _context: any;
    public videoUrl: SafeResourceUrl;
    public header: string;

    constructor(
        private _modalService: ModalService,
        protected sanitizer: DomSanitizer) {
    }

    public set context(context: any) {
        this._context = context;
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(context.url + '?autoplay=' + context.autoplay);
        this.header = context.header;
    }

    public get context(): any {
        return this._context;
    }

    public closeVideoModal() {
        this._modalService.closeAll();
    }
}
