import { LANG_FUNDTYPES_NAME, LANG_FUNDTYPES_TRANS } from './fundtypes';
import { LANG_CREDITCARD_NAME, LANG_CREDITCARD_TRANS } from './creditcard';
import { LANG_EZMONEY_NAME, LANG_EZMONEY_TRANS } from './ezmoney';
import { LANG_CHECK_NAME, LANG_CHECK_TRANS } from './check';
import { LANG_ERRORCODES_NAME, LANG_ERRORCODES_TRANS } from './error';
import { LANG_PAYPAL_NAME, LANG_PAYPAL_TRANS } from './paypal';
import { LANG_MONEYGRAM_NAME, LANG_MONEYGRAM_TRANS } from './moneygram';
import { LANG_BONUS_NAME, LANG_BONUS_TRANS } from './bonus';
import { LANG_PAYNEARME_NAME, LANG_PAYNEARME_TRANS } from './paynearme';
import { LANG_GREENDOT_NAME, LANG_GREENDOT_TRANS } from './greendot';
import { LANG_EZBANK_NAME, LANG_EZBANK_TRANS} from './ezbank';

export const FUNDING_LANGUAGE_DICTIONARY = {
    [LANG_FUNDTYPES_NAME]: LANG_FUNDTYPES_TRANS,
    [LANG_CREDITCARD_NAME]: LANG_CREDITCARD_TRANS,
    [LANG_EZMONEY_NAME]: LANG_EZMONEY_TRANS,
    [LANG_CHECK_NAME]: LANG_CHECK_TRANS,
    [LANG_ERRORCODES_NAME]: LANG_ERRORCODES_TRANS,
    [LANG_PAYPAL_NAME]: LANG_PAYPAL_TRANS,
    [LANG_MONEYGRAM_NAME]: LANG_MONEYGRAM_TRANS,
    [LANG_BONUS_NAME]: LANG_BONUS_TRANS,
    [LANG_PAYNEARME_NAME]: LANG_PAYNEARME_TRANS,
    [LANG_GREENDOT_NAME]: LANG_GREENDOT_TRANS,
    [LANG_EZBANK_NAME]: LANG_EZBANK_TRANS
};
