import { OnDestroy, OnInit, Component } from '@angular/core';
import { CduxSidebarContentComponent } from '../../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../../../sidebar/interfaces/sidebar-portal-component.interface';
import { MenuItemsEnum } from '../../../../menu-items/enums/menu-items.enum';
import { SidebarService } from '../../../../sidebar/sidebar.service';
import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { LoadingDotsComponent, LoadingService } from '@cdux/ng-fragments';
import { TaxDocumentsBusinessService } from '../../services/tax-documents.business.service';
import { IW2GYearList } from '@cdux/ng-common';
import { TaxableEventListComponent } from '../taxable-event-list/taxable-event-list.component';

@Component({
    selector: 'cdux-taxable-year-list',
    templateUrl: './taxable-year-list.component.html',
    styleUrls: ['./taxable-year-list.component.scss']
})
export class TaxableYearListComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {

    public LOADING_OVERLAY_NAME: string = 'taxDocumentsOverlay';
    public loadingDotsComponent = LoadingDotsComponent;
    public yearsList: number[] = [];
    public subscriptions: Subscription[] = [];
    public noYearsToShow: boolean = false;

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: TaxableYearListComponent,
            properties: {
                navTarget: MenuItemsEnum.PROFILE
            }
        }
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'w2g-title',
            translateLanguage: 'my-account'
        }
    }

    public setProperties(properties: ISidebarComponentProperties) {
    }

    constructor(
        private _taxDocumentsService: TaxDocumentsBusinessService,
        private _loadingService: LoadingService,
        private _sidebarService: SidebarService
    ) {
        super();
    }

    ngOnInit() {
        this._loadingService.register(this.LOADING_OVERLAY_NAME);
        this._getTaxableYearsList();
    }

    ngOnDestroy() {
        if (this.subscriptions && this.subscriptions.length > 1) {
            this.subscriptions.forEach((sub) => sub.unsubscribe());
        }
    }

    private _getTaxableYearsList(): void {
        this.subscriptions.push(this._taxDocumentsService.getYearList().pipe(
            take(1),
            map((r: IW2GYearList) => {
                if (r.years && r.years.length > 0) {
                    this.yearsList = r.years;
                } else {
                    this.noYearsToShow = true;
                }
                this._loadingService.resolve(this.LOADING_OVERLAY_NAME, 100, 'success');
            })
        ).subscribe());
    }

    public goToYear(year: number): void {
        this._sidebarService.loadComponent(
            TaxableEventListComponent.getSidebarComponent(year),
            TaxableEventListComponent.getHeaderComponent(year)
        );
    }
}
