<form *ngIf="form && !fundingMethodDetails.locked" [formGroup]="form">
    <div id="ezmoney-lockout" class="ts-message error" *ngIf="lockout">
        <p>We were unable to process your deposit. Your account may be setup incorrectly.</p>
      <p>Please <span *ngIf="phoneEnabled else chat">contact customer service at {{ 'customer-service' | cduxTranslate:AFFILIATE_KEY }}</span>.</p>
    </div>
  <ng-template #chat><cdux-chat></cdux-chat></ng-template>

    <div *ngIf="!lockout && !errorCode && !successfulDeposit">
            <header class="funding-content_header">
                <!-- back arrow hidden on desktop -->
                <i class="icon--nav-back-white" [class.header]="isSmallGlass && isBetShare" (cduxTap)="navigateToOptions()"></i>
                <i *ngIf="isSmallGlass" class="icon--close-light" (cduxTap)="close()" id="deposit-close-btn"></i>
                <h1>EZMoney</h1>
                <div class="funding-type-icon">
                    <i class="icon-ezmoney"></i>
                </div>
            </header>
            <div class="funding-content_input-block">
            <cdux-funding-input-amount
                id="depositAmount"
                formControlName="amount"
                [inputType]="OPERATION"
                [fundType]="fundingMethodDetails.fundType"
                [min]="getMinDepositAmount(hasVerifiedAccount)"
                [max]="getMaxDepositAmount()"
                [amount]="amount"
                [offerId]="offerId"
                [phoneEnabled]="phoneEnabled"
                [displayStyle]="enumDepositDisplayStyle.FULL_PAGE">
            </cdux-funding-input-amount>
            </div>

            <div class="funding-content_details-block" [ngSwitch]="uiDisplay">
            <ng-container *ngSwitchCase="uiDisplayEnum.ACCOUNT_INFO_OR_REPLACE">

                <h2>Enter Bank Details</h2>

                <div class="radio-btn-row">
                    <cdux-radio-group class="ts-radio inline" formControlName="accountType">
                        <cdux-radio-option id="checkingRadio" value="checking">
                            Checking
                        </cdux-radio-option>
                        <cdux-radio-option id="savingsRadio" value="saving">
                            Savings
                        </cdux-radio-option>
                    </cdux-radio-group>
                </div>

                <cdux-input class="form-label-hover"
                            minlengtherror="The number entered should be a 9-digit number."
                            maxlengtherror="The number entered should be a 9-digit number."
                            requirederror="Routing Number is required."
                            label="Routing Number"
                            title="9 Digit Number"
                            helptext="(9 Digits)"
                            [classes]="['account-num', 'fs-exclude']"
                            (reset)="resetField('routingNumber')">
                    <input id="routingNumber"
                           autocomplete="off"
                           cduxInputField
                           formControlName="routingNumber"
                           minlength="9"
                           maxlength="9"
                           type="text"
                           [cduxInputComplete]="form.get('routingNumber').value.length === 9"
                           [cduxInputCompleteFocus]="accountNumberInput" />
                </cdux-input>

                <cdux-input class="form-label-hover"
                            minlengtherror="The number entered should be more than 4-digits."
                            maxlengtherror="The number entered should be less than 18-digits."
                            requirederror="Account Number is required."
                            label="Account Number"
                            title="17 Digit Number"
                            helptext="(Up to 17 Digits)"
                            [classes]="['account-num', 'fs-exclude']"
                            (reset)="resetField('accountNumber')">
                    <input id="accountNumber"
                           #accountNumberInput
                           autocomplete="off"
                           cduxInputField
                           formControlName="accountNumber"
                           minlength="4"
                           maxlength="17"
                           type="text"
                           [cduxInputComplete]="form.get('accountNumber').value.length === 17" />
                </cdux-input>
            </ng-container>

            <ng-container *ngSwitchCase="uiDisplayEnum.EXISTING_CARD">
                <h2>Bank Details</h2>

                <div class="account-num form-label-hover">
                    <div class="funding-field-label">
                        Account ending in
                    </div>
                    <div class="funding-cc-number">
                        <i class="symbol--bullet__13"></i>
                        <span>{{ fundingMethodDetails.accountInfo }}</span>
                    </div>
                    <i (click)="replaceMethod()" class="icon-replace__secondary"></i>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="uiDisplayEnum.PLAID">
                <p>YOU WILL LOGIN WITH YOUR BANK CREDENTIALS</p>
            </ng-container>

              <div class="total">
                <span class="label">Total:</span>
                <span class="amount">{{form.get('amount').value | currency:'USD':'symbol-narrow':'1.2-2'}}</span>
              </div>

            <div class="funding-content_details-block__footer">
              <div class="deposit--btn large"
                   (click)="!pendingDeposit && onDeposit()"
                   [class.disabled]="form.invalid || form.get('amount').value <= 0 || pendingDeposit">
                <span *ngIf="!pendingDeposit"> {{buttonText}} </span>
                <div *ngIf="pendingDeposit" class="gbl-button-loader">
                  <div class="gbl-loader-dots">
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                    <i class="dots-animated"></i>
                  </div>
                </div>
              </div>
              <div class="withdraw--tandc" *ngIf="!!depositDisclaimer">{{depositDisclaimer}}</div>
              <div class="withdraw--tandc">By submitting, I agree to the <a cduxTerms="fund-terms" id="termsAndConditions" class="text__secondary-color has-cursor-finger">Terms & Conditions.</a></div>
            </div>

            </div>
    </div>

</form>
