import { EventTrackingService } from './../../../shared/event-tracking/services/event-tracking.service';
import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    EventClickAttributeType,
    EventClickType,
    FeatureToggleDataService,
    FUND_ID,
    JwtSessionService,
} from '@cdux/ng-common';
import { BetShareUtilService, BetshareStatusUpdateType } from '@cdux/ng-fragments';

import { SidebarService } from '../../../shared/sidebar/sidebar.service';
import { ISidebarPortalState } from '../../../shared/sidebar/interfaces/sidebar-portal-state.interface';

import { CduxAbstractBetShareComponent } from '../abstract.bet-share.component';
import { FundingService } from '../../../shared/funding/shared/services/funding.service';
import { BetShareStatusUpdate } from '../../models';
import { IFundingEvent } from '../../../shared/funding/shared/interfaces/funding.interfaces';
import { FundingDepositOptionsComponent } from 'app/shared/funding/components/deposit-options';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';

@Component({
    selector: 'cdux-bet-share-deposit',
    templateUrl: './bet-share-deposit.component.html',
    styleUrls: ['./bet-share-deposit.component.scss']
})
export class BetShareDepositComponent extends CduxAbstractBetShareComponent implements AfterViewInit, OnDestroy {

    @ViewChild('header') public headerPortalOutlet: CdkPortalOutlet;
    @ViewChild('body') public bodyPortalOutlet: CdkPortalOutlet;

    public canGoBack: boolean;
    public amountNeeded: string;
    private _destroy: Subject<void> = new Subject();
    public eventClickType = EventClickType;
    public ftFullPageDeposit = false;

    constructor(
        private _zone: NgZone,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _sessionService: JwtSessionService,
        private _fundingService: FundingService,
        private _sidebarService: SidebarService,
        private _eventTrackingService: EventTrackingService,
        private _betShareUtil: BetShareUtilService,
        _localFeatureToggleService: FeatureToggleDataService
    ) {
        super();
        this.ftFullPageDeposit = _localFeatureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
    }

    public ngAfterViewInit() {
        // Listen for new Deposit Components
        this._sidebarService.onPortalStateChanged.pipe(
            takeUntil(this._destroy)
        ).subscribe((portalState: ISidebarPortalState) => {
            this._attach(portalState.headerComponent, this.headerPortalOutlet);
            this._attach(portalState.portalComponent, this.bodyPortalOutlet);
            this.canGoBack = this._sidebarService.canGoBack();
            this._changeDetectorRef.detectChanges();
        });

        // Listen for funding events.
        this._fundingService.onFundingEvent
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe((e: IFundingEvent) => {
                switch (e.event) {
                    case 'DEPOSIT':
                        this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.LOADING));
                        break;
                    case 'ERROR':
                        this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.LOADED));
                        break;
                    case 'SUCCESS':
                        const statusUpdate = new BetShareStatusUpdate(BetshareStatusUpdateType.FUNDING_SUCCESS);
                        statusUpdate.data.set('messages', e.messages);
                        this.statusUpdate.emit(statusUpdate);
                        break;
                    default:
                        console.log('A funding event emitted that isn\'t watched for by Bet Share');
                }
            });
        const minAmount = this.getRequiredDepositAmount();
        if (this._sessionService.isLoggedIn()) {
            // Load the Deposit Component
            if (this.ftFullPageDeposit) {
                this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0];
                this._router.navigate(['/', 'deposit'], { queryParams: { 'betId': this._betShareUtil.getCostOfShares(this.betShareSessionData).toString(), 'betShareCost': minAmount } });
            } else {
                this._sidebarService.loadComponent(
                    FundingDepositOptionsComponent.getSidebarComponent({
                        enabledIds: [FUND_ID.EZMONEY, FUND_ID.CREDITCARD],
                        isBetShare: true,
                        flowAttribute: {
                            attrId: EventClickAttributeType.BETSHARE_FLOW,
                            timestamp: new Date().getTime(),
                            data: 0
                        },
                        betId: this._betShareUtil.getCostOfShares(this.betShareSessionData).toString(),
                        amount: minAmount,
                        minimumAmount: minAmount
                    }),
                    FundingDepositOptionsComponent.getHeaderComponent(),
                    {
                        clearHistory: true
                    });
            }
        } else {
            this._zone.run(() => {
                this._router.navigate(['/login']);
                this._changeDetectorRef.detectChanges();
            });
        }

        this.amountNeeded = minAmount.toString();
    }

    public ngOnDestroy() {
        this._destroy.next();
        this._sidebarService.close();
    }

    public backButtonHandler() {
        this._eventTrackingService.logClickEvent(this.eventClickType.DEPOSIT_BACK);
        this._sidebarService.goBack();
    }

    /**
     * Gets the amount that is lacking from the user's balance to join the bet share.
     * Note: Checking for negative return values shouldn't be necessary since the deposit flow won't
     * occur, unless the user is lacking money.
     *
     * @returns {number}
     */
    private getRequiredDepositAmount(): number {
        if (this.betShareSessionData) {
            return +(this._betShareUtil.getCostOfShares(this.betShareSessionData) - this.betShareSessionData.userBalance).toFixed(2);
        }
        return 0;
    }
}
