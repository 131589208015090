import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { CduxRouteUtil } from 'app/shared/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ComponentRef, Input } from '@angular/core';
import { TemplatePortal, ComponentType } from '@angular/cdk/portal';
import { Subscription, Subject } from 'rxjs';

import {
    enumFeatureToggle,
    FeatureToggleDataService,
    JwtSessionService,
    CduxObjectUtil,
} from '@cdux/ng-common';
import { MobileFooterConfig } from '../models';
import { FooterService } from '../services/footer.service';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
    public static TOTE_BOARD_STATE_STORAGE_KEY = 'cduxToteBoardActive';

    /**
     * Flag to determine if the nav panel is open.
     *
     * @type {boolean} navPanelOpen
     */
    @Input() public navPanelOpen: boolean;

    /**
     * Most Recent Portal to be Attached.
     */
    public get portal(): ComponentType<any> | TemplatePortal<any> {
        return this._portal;
    }

    /**
     * Current Wagering View
     */
    private _view: WageringViewEnum;

    /**
     * Create a collection of Subscriptions to allow for easy garbage collection
     */
    private _subscriptions: Subscription[] = [];

    /**
     * Backing Data Store for Portal
     *
     * @type {ComponentType<any>|TemplatePortal<any>}
     */
    private _portal: ComponentType<any> | TemplatePortal<any> = null;

    /**
     * Backing Date Store for Mobile Footer Config.
     *
     * @type {null|MobileFooterConfig}
     */
    private _mobileFooterConfig: MobileFooterConfig = null;

    public set isToteBoardActive(active: boolean) {
        this._isToteBoardActive = !!active;

        // Tote board is forced closed on VIDEO view
        // so we do not update the stored preference
        if (this._view !== WageringViewEnum.VIDEO) {
            localStorage.setItem(
                FooterComponent.TOTE_BOARD_STATE_STORAGE_KEY,
                JSON.stringify(this._isToteBoardActive)
            );
        }
    }
    public get isToteBoardActive(): boolean {
        return this._isToteBoardActive;
    }
    private _isToteBoardActive: boolean = false;

    public canClickOutOfToteBoard: boolean = false;
    public isMtpBarEnabled = this._featureToggleDataService.isFeatureToggleOn(enumFeatureToggle.MTP_BAR);
    public tournamentSelected = false;

    private _kill: Subject<undefined> = new Subject<undefined>();

    /**
     * Constructor.
     *
     * @param {JwtSessionService} sessionService
     * @param {FooterService} _footerService
     * @parama router
     * @paraam route
     */
    constructor(
        public sessionService: JwtSessionService,
        private _changeDetector: ChangeDetectorRef,
        private _featureToggleDataService: FeatureToggleDataService,
        private _footerService: FooterService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _tournamentsSession: TournamentsSessionService
    ) { }

    public ngOnInit() {
        this._router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            startWith(null),
            map(() => {
                return CduxRouteUtil.extractParamMap(this._route).get('view');
            }),
            distinctUntilChanged(),
            takeUntil(this._kill)
        )
        .subscribe((view: WageringViewEnum) => {
            this._view = view;

            this.canClickOutOfToteBoard = view === WageringViewEnum.CLASSIC;

            if (view === WageringViewEnum.VIDEO) {
                this.isToteBoardActive = false; // close on navigation to VIDEO
            } else { // recall the previous state preference from local storage
                const pref = localStorage.getItem(FooterComponent.TOTE_BOARD_STATE_STORAGE_KEY);
                this.isToteBoardActive = !(pref && !JSON.parse(pref)); // default true (active)
            }

            this._changeDetector.detectChanges();
        });

        this._tournamentsSession.onTournamentSelection.pipe(takeUntil(this._kill)).subscribe((data) => {
            this.tournamentSelected = data ? true : false;
        });

        this._subscriptions.push(
            this._footerService._footerChange.subscribe((footerConfig: MobileFooterConfig) => {
                this._loadMobileFooterConfig(footerConfig);
            })
        );
    }

    public ngOnDestroy() {
        this._kill.next();
        this._kill.complete();
        // Unsubscribe from all active Subscriptions in the collection
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Code to be run when a new component is attached to the portal.
     *
     * @param {ComponentRef<any>} ref
     */
    public _onAttached(ref: ComponentRef<any>) {
        const inputs = CduxObjectUtil.deepGet(this._mobileFooterConfig, 'dynamicRef.inputs');

        if (inputs) {
            Object.keys(inputs).forEach((key: string) => {
                if (ref.instance && ref.instance.hasOwnProperty(key)) {
                    ref.instance.key = inputs.key;
                }
            });
        }

        this._footerService._attached(ref);
    }

    /**
     * Loads a new footer into the portal.
     *
     * @param {MobileFooterConfig | null} config
     * @private
     */
    private _loadMobileFooterConfig(config: MobileFooterConfig | null) {
        if (config === null) {
            this._mobileFooterConfig = null;
            // bc we're directly manipulating the DOM here, setTimeout hard forces an angular view check
            // normal change detection won't work here because it's happening outside of the update cycle
            setTimeout(() => { this._portal = null }, 0);
        } else {
            this._mobileFooterConfig = config;
            this._portal = config.dynamicRef.componentOrTemplateRef;
        }
    }

}
