import { EventClickType } from '@cdux/ng-common';
import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { enumManageAccountType } from '../enums/manage-account.enum';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { InputFields } from '../enums/input-fields.enum';

@Directive()
export abstract class AbstractManageInputComponent {
    public inputField = InputFields;

    public newFieldVisited: boolean;
    public confirmFieldVisited: boolean;
    public passwordVisited: boolean;

    public showNewField: boolean;
    public showConfirmField: boolean;
    public showPassword: boolean;

    public newControl: AbstractControl;
    public confirmControl: AbstractControl;
    public passwordControl: AbstractControl;

    @Output() cancel = new EventEmitter<enumManageAccountType>();
    @Output() update = new EventEmitter<enumManageAccountType>();

    @Input() formGroup: UntypedFormGroup;

    public eventClickType = EventClickType;

    public editType: enumManageAccountType;

    constructor(editType: enumManageAccountType) {
        this.editType = editType;
    }

    public onCancel(): void {
        this.cancel.emit(this.editType);
    }

    public onUpdate() {
        this.update.emit(this.editType);
    }

    public fieldVisited(field: InputFields) {
        switch (field) {
            case InputFields.newField:
                this.newFieldVisited = true;
                break;
            case InputFields.confirmField:
                this.confirmFieldVisited = true;
                break;
            case InputFields.password:
                this.passwordVisited = true;
                break;
        }
    }

    public showValidIcon(control: AbstractControl): boolean {
        if (control === this.confirmControl) {
            return !!control.value && !control.errors && (!this.formGroup.errors || !!this.formGroup.errors && !this.formGroup.errors.mismatch);
        } else {
            return !!control.value && !control.errors;
        }
    }

    public showRemoveIcon(control: AbstractControl): boolean {
        if (control === this.confirmControl) {
            return !!control.value && (!!control.errors || (!!this.formGroup.errors && this.formGroup.errors.mismatch));
        } else {
            return !!control.value && !!control.errors;
        }
    }

    public showErrorMessage(field: InputFields): boolean {
        switch (field) {
            case InputFields.newField:
                return this.newFieldVisited && !!this.newControl.errors;
            case InputFields.confirmField:
                return this.confirmFieldVisited && !!this.confirmControl.errors;
            case InputFields.password:
                return this.passwordVisited && !!this.passwordControl.errors;
        }
    }

    public toggleShowPassword(field: InputFields): void {
        switch (field) {
            case InputFields.password:
                this.showPassword = !this.showPassword;
                break;
            case InputFields.confirmField:
                this.showConfirmField = !this.showConfirmField;
                break;
            case InputFields.newField:
                this.showNewField = !this.showNewField;
                break;
        }
    }

    public resetInput(control: AbstractControl): void {
        control.reset();
    }

    public showMisMatchError(): boolean {
        return this.confirmFieldVisited && !this.confirmControl.errors && (!!this.formGroup.errors && this.formGroup.errors.mismatch);
    }
}
