import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { EventClickType, JwtSessionService } from '@cdux/ng-common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    BetShareUtilService,
    BetshareSessionDataErrors,
    BetshareSessionData,
    BetshareSessionErrorType,
    BetshareStatusUpdateType
} from '@cdux/ng-fragments';

import { CduxAbstractBetShareComponent } from '../abstract.bet-share.component';
import {
    BetshareOptInResponse,
    BetShareStatusUpdate
} from '../../models';

/**
 * Severity for determining elements to show in the template.
 */
enum enumErrorSeverity {
    NONE,
    SOFT, // This is an error type for a bet share that can still be joined.
    HARD, // This is an error type for a bet share that can no longer be joined.
    CRITICAL, // This is an error type for when the bet share can't even be found.
}

@Component({
    selector: 'cdux-bet-share-details',
    templateUrl: './bet-share-details.component.html',
    styleUrls: ['./bet-share-details.component.scss']
})
export class BetShareDetailsComponent extends CduxAbstractBetShareComponent implements OnInit {

    public readonly listDelimiter: string = ',';

    /**
     * Bet Share error types enum, for use in template.
     *
     * @type {BetshareSessionErrorType}
     */
    public enumErrorTypes = BetshareSessionErrorType;

    /**
     * Bet Share error severities, for use in template.
     *
     * @type {enumErrorSeverity}
     */
    public enumErrorSeverity = enumErrorSeverity;

    /**
     * The current error severity.
     *
     * @type {enumErrorSeverity}
     */
    public errorSeverity: enumErrorSeverity;

    /**
     * The object containing the bet share's current errors.
     *
     * @type {BetshareSessionDataErrors}
     */
    public errors: BetshareSessionDataErrors;

    /**
     * The current bet share error type. It should correspond to the first one in the array of errors.
     *
     * @type {BetshareSessionErrorType}
     */
    public errorType: BetshareSessionErrorType;

    /**
     * For a logged in user, what is their geographical state? Used in errors.
     *
     * @type {string}
     */
    public userState: string;

    public focus: boolean;

    public joinBetShareTotal: string;

    public eventClickType = EventClickType;

    /**
     * Use this to show / hide the opt in button loading indicator.
     *
     * @type {boolean} [false]
     */
    public optInLoading: boolean = false;

    constructor(private _sessionService: JwtSessionService,
                private _eventTrackingService: EventTrackingService,
                private _betShareUtil: BetShareUtilService,
                private _changeDetectorRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        if (this._sessionService.isLoggedIn()) {
            this.userState = this._sessionService.getUserInfo().state;
        }
    }

    public updateSessionData(sessionData: BetshareSessionData) {
        super.updateSessionData(sessionData);
        this.handleSessionErrors(sessionData.errors);
        this.joinBetShareTotal = this._betShareUtil.getCostOfShares(this.betShareSessionData).toFixed(2);
        this.optInLoading = false; // A response update occurs on opt in failure.
    }

    public updateOptinResponse(optInResponse: BetshareOptInResponse) {
        super.updateOptinResponse(optInResponse);
        this.handleSessionErrors(optInResponse.errors);
        this.optInLoading = false; // A response update occurs on opt in success.
    }

    public optIn() {
        this.optInLoading = true;
        this._changeDetectorRef.detectChanges();
        this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.OPT_IN));
    }

    public optOut() {
        if (this.errorSeverity === enumErrorSeverity.HARD || this.errorSeverity === enumErrorSeverity.CRITICAL) {
            switch (this.errorType) {


                case this.enumErrorTypes.SHARES: {
                    this._eventTrackingService.logClickEvent(this.eventClickType.BETSHARE_ALL_SHARES_CLAIMED);
                    break;
                }

                case this.enumErrorTypes.RESTRICTED: {
                    this._eventTrackingService.logClickEvent(this.eventClickType.BETSHARE_STATE_RESTRICTION);
                    break;
                }

                case this.enumErrorTypes.INVALID: {
                    this._eventTrackingService.logClickEvent(this.eventClickType.BETSHARE_INVALID_ID);
                    break;
                }

                default: {
                    this._eventTrackingService.logClickEvent(this.eventClickType.BETSHARE_INVITATION_NOT_AVAILABLE)
                    break;
                }


            }
        }
        this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.OPT_OUT));
    }

    public updateShares(amount: number) {
        if (amount > 0) {
            this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.INCREASE_SHARES));
        } else {
            this.statusUpdate.emit(new BetShareStatusUpdate(BetshareStatusUpdateType.DECREASE_SHARES));
        }
        this.joinBetShareTotal = this._betShareUtil.getCostOfShares(this.betShareSessionData).toFixed(2);
    }

    /**
     * Sets the errors object.
     * Sets an error severity (see enumErrorSeverity for more info).
     *
     * @param errors
     */
    private handleSessionErrors(errors: BetshareSessionDataErrors) {
        this.errors = errors;
        if (!errors.hasErrors()) {
            // There are no errors.
            this.errorSeverity = enumErrorSeverity.NONE;
        } else {
            const errorsArray = errors.toArray();
            this.errorType = errors.get(BetshareSessionErrorType.STATUS) ? BetshareSessionErrorType.STATUS : errorsArray[0];

            const severityMap = new Map();

            // Critical Errors
            // If it's an unknown or invalid error type, then don't even try to show a bet share.
            severityMap.set(BetshareSessionErrorType.INVALID, enumErrorSeverity.CRITICAL);
            severityMap.set(BetshareSessionErrorType.UNKNOWN, enumErrorSeverity.CRITICAL);

            // Hard Errors
            // If the user hard failed SSN Collection
            severityMap.set(BetshareSessionErrorType.SSN_FAILED, enumErrorSeverity.HARD);

            // Soft Errors
            // If the error is regarding shares and some are still available, then the user can still join.
            severityMap.set(BetshareSessionErrorType.SHARES, (this.betShareSessionData.details.availableShares > 0) ? enumErrorSeverity.SOFT : enumErrorSeverity.HARD);
            // If the error is regarding SSN, and they didn't hard fail, they just need to provide their SSN to continue.
            severityMap.set(BetshareSessionErrorType.SSN_REQUIRED, enumErrorSeverity.SOFT);

            // Default Severity
            // Otherwise it's a hard failure.
            const defaultSeverity = enumErrorSeverity.HARD;

            let severity: enumErrorSeverity = enumErrorSeverity.SOFT;
            errorsArray.forEach((v) => {
                if (severityMap.get(v)) {
                    // If there's a severity mapping for the error and it's greater
                    // than the current error severity, escalate it.
                    if (severityMap.get(v) > severity) {
                        severity = severityMap.get(v);
                    } else {
                        // DO NOTHING!
                        // I want to keep the current error severity.
                    }
                } else if (defaultSeverity > severity) {
                    // I don't have a mapped error severity, so I'm going to default to a hard failure.
                    severity = defaultSeverity;
                }
            });

            this.errorSeverity = severity;
        }
    }

    /**
     * scrolls the how it works section to top
     */
    public goToHowItWorks() {
        // making this true will trigger the focus to how it works
        this.focus = true;
        setTimeout(() => {
            // making this false will allow to trigger the focus to how it works by making it true again
            this.focus = false;
        }, 500);
    }

}
