<div class="email-update-container open" [formGroup]="formGroup">
    <div class="email-toggle">
        <h4>Update Email</h4>
    </div>
    <div class="form-label-hover current-password-container">
        <!--[ngClass] adds .has-value to input when there's value-->
        <input id="new-email"
               autocomplete="user-email"
               type="email"
               class="new-email"
               [ngClass]="{'has-value' : !!newControl.value, 'input-error': showErrorMessage(inputField.newField)}"
               formControlName="newEmail"
               (blur)="fieldVisited(inputField.newField)" >
        <label class="ts-label" for="new-email">New Email</label>
        <!-- ng adds .show-the-password class when there's a value in #password,
        otherwise, span.btn.show-password is hidden -->
        <!-- also add the class 'active' to move the Show to the left when there is an icon located next to it -->
        <!--<span class="btn show-the-password show-password show-password-label">Show</span>-->
        <!-- VALIDATION ICON
         DIRECTIONS: 1. use 'icon--pencil-circle' as the default feedback icon
                     2. use class 'icon--valid-check' for positive feedback
                     3. use class 'icon--remove--amber' for negative feedback
         -->
        <i [ngClass]="{'icon--valid-check': showValidIcon(newControl), 'icon--remove--amber' : showRemoveIcon(newControl)}"
           (click)="showRemoveIcon(newControl) && resetInput(newControl)"></i>
        <div *ngIf="showErrorMessage(inputField.newField)" class="ts-validation error">Please enter a valid email address.</div>
    </div>

    <div class="form-label-hover new-password-container">
        <input id="confirm-email"
               autocomplete="user-email"
               type="email"
               class="new-password"
               [ngClass]="{'has-value' : !!confirmControl.value, 'input-error': showErrorMessage(inputField.confirmField) || showMisMatchError() }"
               formControlName="confirmEmail"
               (blur)="fieldVisited(inputField.confirmField)">
        <label class="ts-label" for="confirm-email">Confirm Email</label>

        <i [ngClass]="{'icon--valid-check': showValidIcon(confirmControl), 'icon--remove--amber' : showRemoveIcon(confirmControl)}"
            (click)="showRemoveIcon(confirmControl) && resetInput(confirmControl)"></i>
        <div *ngIf="showErrorMessage(inputField.confirmField)" class="ts-validation error">Please enter a valid email address.</div>
        <div *ngIf="showMisMatchError()" class="ts-validation error">Emails do not match</div>
    </div>

    <div class="form-label-hover confirm-password-container">
        <input id="confirm-password-email"
               autocomplete="new-password"
               type="{{showPassword ? 'text' : 'password'}}"
               class="confirm-password-email"
               [ngClass]="{'has-value' : !!passwordControl.value, 'input-error': showErrorMessage(inputField.password)}"
               formControlName="emailPassword"
               (blur)="fieldVisited(inputField.password)">
        <label class="ts-label" for="confirm-password-email">Password</label>

        <span class="btn show-password show-password-label"
              [ngClass]="{'show-the-password active' : !!passwordControl.value}"
              (click)="toggleShowPassword(inputField.password)" id="email-update-pw-show-hide-btn">{{showPassword ? 'Hide' : 'Show'}}</span>

        <i [ngClass]="{'hide' : !passwordControl.value, 'icon--valid-check': showValidIcon(passwordControl), 'icon--remove--amber' : showRemoveIcon(passwordControl)}"
           (click)="showRemoveIcon(passwordControl) && resetInput(passwordControl)" id="email-update-pw-valid-icn"></i>
        <div *ngIf="showErrorMessage(inputField.password)" class="ts-validation error" id="email-update-pw-error-txt">Password does not meet requirements</div>
    </div>
    <div class="update-cancel-button-container">
        <div id="cancelBtn"
            class="update-cancel-button active"
            (click)="onCancel()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_EMAIL_CANCEL"
        >CANCEL</div>
        <div id="updateBtn"
            class="update-cancel-button"
            [ngClass]="formGroup.valid ? 'active' : 'inactive'"
            (click)="formGroup.valid && onUpdate()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_SETTINGS_EMAIL"
        >UPDATE</div>
    </div>
</div>
