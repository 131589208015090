/**
 * Pipe deprecated, refactored to utility 2.22.19
 */
import { RaceDetails, enumTrackType, IToteRace, ITrackBasic } from '@cdux/ng-common';
import { FormatPursePipe } from '@cdux/ng-fragments';
import { RaceTypeConstants } from '../constants/RaceTypeConstants';

export class FormatRaceTypeUtil {

    /**
     * Format a race type string using the given criteria.
     *
     * 1. If harness, gait type only: Pace or Trot
     * 2. Abbreviated Claiming Price (if applicable)
     *    a. $25k (5 digits or more are rounded to nearest thousand)
     * 3. Race Type
     *      * 3-character abbreviation for mobile (TSM is defined)
     *      * Race Name-Grade (if a stakes race) (RaceType == STK)
     *      * Otherwise, use full Race Type Name, e.g.:
     *          a. Maiden Special
     *          b. Maiden Claiming
     *          c. Allowance
     *          d. Claiming
     *          e. KyDerby-G1
     *
     * @param raceDetails - Race Data object for the current track and race.
     * @param currentTrack
     * @param showFull - If true, it should return full type string
     * @returns {string}
     */

    public static format(raceDetails: RaceDetails | IToteRace, currentTrack: ITrackBasic, showFull?: boolean): string {

        if (!raceDetails) {
            return '';
        }

        if (currentTrack && currentTrack.TrackType === enumTrackType.GREY) {
            return 'Greyhound';
        }

        // Use the gait type (Pace/Trot) for harness races
        if (currentTrack && currentTrack.TrackType === enumTrackType.HARN) {
            return raceDetails.Gait || '';
        }

        const raceTypeComponents = [];

        if (raceDetails.MaxClaimPrice) {

            // Add the max claim price to the resulting label.
            const formatPursePipe = new FormatPursePipe();
            const maxClaimPriceLabel = formatPursePipe.transform(raceDetails.MaxClaimPrice);
            raceTypeComponents.push(maxClaimPriceLabel);

        }

        if (raceDetails.RaceType) {

            let name: string;

            // Derived from TSUI. Previously a nested ternary.
            if (!showFull) {
                // Show the abbreviated race type.
                name = raceDetails.RaceType;
            } else {

                // if (raceDetails.RaceType === 'STK') {

                //     // Append grade
                //     name = raceDetails.RaceType + (raceDetails.Grade ? ' ' + raceDetails.Grade : '');

                // } else {

                name = this.decodeRaceType(raceDetails.BreedType, raceDetails.RaceType) || raceDetails.RaceType;
                name = name + (raceDetails.Grade ? ' ' + raceDetails.Grade : '');

                // }

            }

            if (name) {
                raceTypeComponents.push(name);
            }

        }

        return raceTypeComponents.join(' ');

    }

    /**
     * Decode a track and race type into the full name of that race type.
     *
     * @param {string} breedType
     * @param {string} raceType
     * @returns {string}
     */
    private static decodeRaceType(breedType: string, raceType: string): string {

        if (!breedType || !raceType) {
            return '';
        }

        return RaceTypeConstants.raceTypes[breedType] &&
            RaceTypeConstants.raceTypes[breedType][raceType] || '';

    }
}
