import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TuxCommonModule } from '../common/common.module';
import { FundingModule } from '../funding/funding.module';
import { MyAccountModule } from '../my-account/my-account.module';

import { InterceptorService } from './interceptor.service';

import {
    DeeplinkInterceptor,
    ToastInterceptor
} from './interceptors/index';

@NgModule({
    imports: [
        CommonModule,
        TuxCommonModule,
        FundingModule,
        MyAccountModule,
    ],
    exports: [
        FundingModule,
        MyAccountModule,
    ]
})
export class InterceptorModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<InterceptorModule> {
        return {
            ngModule: InterceptorModule,
            providers: [
                InterceptorService,
                DeeplinkInterceptor,
                ToastInterceptor
            ],
        }
    }
}
