import { ViewChild, TemplateRef, Input, OnInit, OnDestroy, Directive } from '@angular/core';
import { formatDate } from '@angular/common';
import { ViewSectionEnum } from 'app/wagering/views/enums/view-section.enum';
import { ITrackBasic, WagerState, ToteDataService } from '@cdux/ng-common';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DisplayModeEnum } from 'app/shared';
import { WageringViewEnum } from 'app/wagering/views/enums/wagering-view.enum';
import { ViewStateService } from 'app/wagering/views/services/view-state.service';

@Directive()
export abstract class AbstractWageringSectionComponent implements OnInit, OnDestroy {

    public WageringViewEnum = WageringViewEnum;
    public DisplayModeEnum = DisplayModeEnum;
    public ViewSectionEnum = ViewSectionEnum;

    @ViewChild('offloadedTemplate', { static: true })
    public offloadedTemplate: TemplateRef<any>;

    @Input()
    public displayMode: DisplayModeEnum;

    @Input()
    public view: WageringViewEnum;

    @Input()
    public section: ViewSectionEnum;

    @Input()
    public set track(track: ITrackBasic) {
        this._track = track;
    }
    public get track(): ITrackBasic {
        return this._track;
    }
    protected _track: ITrackBasic;

    @Input()
    public race: number;

    @Input()
    public set wagerState(wagerState: WagerState) {
        this._wagerState = wagerState;
    }
    public get wagerState(): WagerState {
        return this._wagerState;
    }
    protected _wagerState;

    private _subs: Subscription[] = [];
    public toteDate: string = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

    constructor(
        protected _toteDataService: ToteDataService,
        protected _viewStateService: ViewStateService
    ) {}

    ngOnInit(): void {
        this._toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this.toteDate = date;
        });
    }

    ngOnDestroy(): void {
        this._subs.forEach((sub) => {
            sub.unsubscribe();
        })
    }
}
