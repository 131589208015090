import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    enumFeatureToggle,
    EventClickType,
    JwtSessionService,
    PreferencesService,
    SessionKeys,
} from '@cdux/ng-common';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../sidebar/interfaces/sidebar-portal-component.interface';
import { CduxSidebarContentComponent } from '../../sidebar/cdux-sidebar-content-component.class';
import { MenuItemsEnum } from '../../menu-items/enums/menu-items.enum';
import { Subject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { SidebarService } from '../../sidebar/sidebar.service';
import { FeatureToggleDataService } from '@cdux/ng-common';
import { ModalService } from '@cdux/ng-platform/web';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { MfaPreferencesComponent } from "../multi-factor-authentication/mfa-preferences/mfa-preferences.component";


const REMEMBER_USERNAME_TOKEN = 'rememberUserNameChecked';

@Component({
    selector: 'cdux-preferences',
    templateUrl: './preferences.component.html',
    styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends CduxSidebarContentComponent implements OnInit, OnDestroy {
    // TODO: Convert these to enums?
    public static HIDE_EXPERT_PICKS_KEY = 'PreferencesComponent.HideExpertPicks';
    public static DISABLE_QUICK_PICK_KEY = 'PreferencesComponent.DisableQuickPick';
    public static QUICK_PICK_BANNED_STATES = 'quick_pick_banned_states'; // TODO: move this?
    public static HIDE_BRIS_PICKS_KEY = 'PreferencesComponent.HideBrisPicks';
    public static USE_SYSTEM_THEME_KEY = 'PreferencesComponent.UseSystemTheme';
    public static FORCE_DARK_MODE_KEY = 'PreferencesComponent.ForceDarkMode';

    public legacyModalNotice: boolean;
    public shouldShowLegacyInterfaceLinks: boolean;
    public rememberUserNameChecked: boolean = false;
    public systemThemeEnabled = true;
    public darkModeEnabled = false;
    public supportsDarkMode = true;

    public expertPicksEnabled = false;
    public expertPicksHidden = false;

    public quickPickEnabled = false;
    public quickPickAllowed = false;
    public quickPickHidden = false;
    public mfaEnabled = false;

    public brisPicksEnabled = false;
    public brisPicksHidden = false;

    public ssoLoginFT = false;

    private _onDestroy: Subject<any> = new Subject();

    /*********************************
     * SidbarComponent Implementation
     *********************************/
    public static getSidebarComponent(options?: { rememberUserNameChecked: boolean }): ISidebarPortalComponent {
        const inputs = new Map<any, any>();

        if (options && options.hasOwnProperty(REMEMBER_USERNAME_TOKEN)) {
            inputs[REMEMBER_USERNAME_TOKEN] = options.rememberUserNameChecked;
        }

        return {
            component: PreferencesComponent,
            properties: {
                inputs,
                navTarget: MenuItemsEnum.PROFILE
            },
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            translateKey: 'preferences-title',
            translateLanguage: 'my-account'
        };
    }

    public setProperties(properties: ISidebarComponentProperties): void {
        if (properties && properties.inputs && properties.inputs.has(REMEMBER_USERNAME_TOKEN)) {
            this.rememberUserNameChecked = properties.inputs[REMEMBER_USERNAME_TOKEN];
        }
    }

    /*********************************/

    constructor(private sessionService: JwtSessionService,
        private _configurationService: ConfigurationDataService,
        private _eventTrackingService: EventTrackingService,
        private _preferencesService: PreferencesService,
        private _sidebarService: SidebarService,
        private _featureToggleService: FeatureToggleDataService,
        private _modalService: ModalService
        ) {
        super();
    }

    ngOnInit() {
        this.rememberUserNameChecked = !!localStorage.getItem(SessionKeys.REMEMBER_USERNAME);

        this.supportsDarkMode = this._featureToggleService.isFeatureToggleOn('DARK_MODE') && window.matchMedia('(prefers-color-scheme)').media !== 'not all';
        this.darkModeEnabled = this.supportsDarkMode && this._preferencesService.getFlag(PreferencesComponent.FORCE_DARK_MODE_KEY);
        this.systemThemeEnabled = this._preferencesService.getFlag(PreferencesComponent.USE_SYSTEM_THEME_KEY);

        this.expertPicksEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.EXPERT_PICKS);
        this.expertPicksHidden = this._preferencesService.getFlag(PreferencesComponent.HIDE_EXPERT_PICKS_KEY);

        this.quickPickEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.QUICK_PICK);
        this.quickPickAllowed = false; // set from boss config call, based on user state restrictions, etc
        this.quickPickHidden = this._preferencesService.getFlag(PreferencesComponent.DISABLE_QUICK_PICK_KEY);

        this.brisPicksEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BRIS_PICKS);
        this.brisPicksHidden = this._preferencesService.getFlag(PreferencesComponent.HIDE_BRIS_PICKS_KEY);

        this.mfaEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.MFA_ENABLED);

        this.ssoLoginFT = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ENABLE_SSO_LOGIN);

        this._configurationService.getConfiguration(enumConfigurationStacks.TUX, PreferencesComponent.QUICK_PICK_BANNED_STATES).pipe(
            map((config) => {
                const bannedStates = config?.[PreferencesComponent.QUICK_PICK_BANNED_STATES]?.split(',').map(s => s.trim().toUpperCase());
                const state = this.sessionService.getUserInfo()?.state?.trim().toUpperCase() || null;
                return !!state && !!bannedStates && !bannedStates.includes(state) // state and config required
            }),
            take(1)
        ).subscribe((quickPickAllowed) =>
            this.quickPickAllowed = quickPickAllowed
        );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /*
     * deletes or adds the username to local storage based on the state of the checkbox
     */
    toggleRememberUserName(): void {
        if (!this.rememberUserNameChecked) {
            localStorage.setItem(SessionKeys.REMEMBER_USERNAME, this.sessionService.getUserInfo().username);
        } else {
            localStorage.removeItem(SessionKeys.REMEMBER_USERNAME);
        }
        this.rememberUserNameChecked = !this.rememberUserNameChecked;
    }

    toggleExpertPicks(): void {
        if (this.expertPicksHidden) {
            // return to default (not hidden) state
            this._preferencesService.clearFlag(PreferencesComponent.HIDE_EXPERT_PICKS_KEY);
            this._eventTrackingService.logClickEvent(EventClickType.EXPERT_PICKS_SHOW);
        } else {
            this._preferencesService.setFlag(PreferencesComponent.HIDE_EXPERT_PICKS_KEY, true);
            this._eventTrackingService.logClickEvent(EventClickType.EXPERT_PICKS_HIDE);
        }
        this.expertPicksHidden = !this.expertPicksHidden;
    }

    toggleQuickPick(): void {
        if (this.quickPickHidden) {
            // return to default (not hidden) state
            this._preferencesService.clearFlag(PreferencesComponent.DISABLE_QUICK_PICK_KEY);
            this._eventTrackingService.logClickEvent(EventClickType.ENABLE_QUICK_PICK);
        } else {
            this._preferencesService.setFlag(PreferencesComponent.DISABLE_QUICK_PICK_KEY, true);
            this._eventTrackingService.logClickEvent(EventClickType.DISABLE_QUICK_PICK);
        }
        this.quickPickHidden = !this.quickPickHidden;
    }

    toggleBrisPicks(): void {
        if (this.brisPicksHidden) {
            // return to default (not hidden) state
            this._preferencesService.clearFlag(PreferencesComponent.HIDE_BRIS_PICKS_KEY);
            this._eventTrackingService.logClickEvent(EventClickType.BRIS_PICKS_SHOW);
        } else {
            this._preferencesService.setFlag(PreferencesComponent.HIDE_BRIS_PICKS_KEY, true);
            this._eventTrackingService.logClickEvent(EventClickType.BRIS_PICKS_HIDE);
        }
        this.brisPicksHidden = !this.brisPicksHidden;
    }

    public hideSideBar() {
        this._sidebarService.close();
    }

    public toggleSystemTheme() {
        this.systemThemeEnabled = !this.systemThemeEnabled;
        if (this.systemThemeEnabled) {
            this._eventTrackingService.logClickEvent(EventClickType.USE_SYSTEM_THEME_ON);
            this._preferencesService.setFlag(PreferencesComponent.USE_SYSTEM_THEME_KEY, true);
        } else {
            this._eventTrackingService.logClickEvent(EventClickType.USE_SYSTEM_THEME_OFF);
            this._preferencesService.setFlag(PreferencesComponent.USE_SYSTEM_THEME_KEY, false);
        }
    }

    public toggleDarkMode() {
        this.darkModeEnabled = !this.darkModeEnabled && this.supportsDarkMode;
        if (this.darkModeEnabled) {
            this._eventTrackingService.logClickEvent(EventClickType.DARK_MODE_ON);
            this._preferencesService.setFlag(PreferencesComponent.FORCE_DARK_MODE_KEY, true);
        } else {
            this._eventTrackingService.logClickEvent(EventClickType.DARK_MODE_OFF);
            this._preferencesService.clearFlag(PreferencesComponent.FORCE_DARK_MODE_KEY);
        }
    }

    public goToMfaSettings(): void {
            if(this.mfaEnabled) {
                this._sidebarService.loadComponent(MfaPreferencesComponent.getSidebarComponent(), MfaPreferencesComponent.getHeaderComponent());
            }
    }

    public closeNotice() {
        this._modalService.closeAll();
    }
}
