import { AfterContentInit, Component, OnDestroy, forwardRef, ContentChildren, QueryList, Input, ChangeDetectorRef, Self, Optional } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

import { RadioOptionComponent } from './radio-option.component';

const noop = () => {
};

@Component({
    selector: 'cdux-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements AfterContentInit, OnDestroy, ControlValueAccessor {

    // Child Radio Buttons
    @ContentChildren(forwardRef(() => RadioOptionComponent)) private _radios: QueryList<RadioOptionComponent>;

    // Name of the Radio Group
    @Input()
    get name(): string {
        return this._name;
    }
    set name(value: string) {
        if (this._name !== value) {
            this._name = value;
            this._updateRadioButtonNames();
        }
    }

    // Value of the Radio Group
    @Input()
    get value(): any {
        return this._value;
    }
    set value(value: any) {
        if (this._value !== value) {
            this._value = value;
            this._updateSelectedFromValue();
            this._checkSelected();
            this._onChangeCallback(value);
        }
    }

    // Currently Selected Radio Group Option
    @Input()
    get selected(): RadioOptionComponent {
        return this._selected;
    }
    set selected(value: RadioOptionComponent) {
        if (this._selected !== value) {
            this._selected = value;
            this.value = value ? value.value : null;
            this._checkSelected();
            this._updateSelected();
        }
    }

    private _value: any = null;
    private _name: string;
    private _selected: RadioOptionComponent | null = null;
    private _subscriptions: Subscription[] = [];

    // Implemented as part of Control Value Accessor
    private _onTouchedCallback: () => void = noop;
    private _onChangeCallback: (_: any) => void = noop;

    constructor( @Self() @Optional() private _control: NgControl, private _changeDetectorRef: ChangeDetectorRef) {
        if (this._control) {
            this._control.valueAccessor = this;
        }
    }

    public ngAfterContentInit() {
        this._updateRadioButtonGroup();
        this._updateSelectedFromValue();
        this._checkSelected();
        this._subscriptions.push(
            this._radios.changes.subscribe(() => {
                this._updateRadioButtonGroup();
                this._updateSelectedFromValue();
                this._checkSelected();
            })
        );
    }

    public ngOnDestroy() {
        this._subscriptions.map((subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Implemented as part of ControlValueAccessor
     */
    public onBlur(): void {
        this._onTouchedCallback();
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param value - Value to be set
     */
    public writeValue(value: any) {
        this.value = value;
        this._changeDetectorRef.markForCheck();
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param fn - Function to be called after change
     */
    public registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    /**
     * Implemented as part of ControlValueAccessor
     * @param fn - Function to be called after touch
     */
    public registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

    /**
     * Checkes the Currently Selected Radio Group Option
     */
    private _checkSelected() {
        if (this._selected && !this._selected.checked) {
            this._selected.checked = true;
        }
    }

    /**
     * Updates the Radio Button Names
     */
    private _updateRadioButtonNames() {
        if (this._radios) {
            this._radios.map((radio) => {
                radio.name = this.name;
            });
        }
    }

    /**
     * Sets the radioGroup of the Radio Group Options
     */
    private _updateRadioButtonGroup() {
        if (this._radios) {
            this._radios.map((radio) => {
                radio.radioGroup = this;
            });
        }
    }

    /**
     * Updates which Radio Group Options are Checked
     */
    private _updateSelected() {
        if (this._radios && this.selected) {
            this._radios.map((radio) => {
                radio.checked = radio === this.selected;
            });
        }
    }

    /**
     * Programmatically Selects a Radio Group Option based on Value
     */
    private _updateSelectedFromValue() {
        const isAlreadySelected = this._selected !== null && this._selected.value === this._value;

        if (this._radios && !isAlreadySelected) {
            this._selected = null;
            this._radios.map((radio) => {
                radio.checked = this.value === radio.value;
                if (radio.checked) {
                    this._selected = radio;
                }
            });
        }
    }
}
