import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'cdux-carousel-item',
    templateUrl: 'carousel-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselItemComponent {

    private _hidden: boolean = true;
    @Input()
    public set hidden(value: boolean) {
        this._hidden = value;
        this._changeDetector.detectChanges();
    }
    public get hidden(): boolean {
        return this._hidden;
    }

    constructor(private _changeDetector: ChangeDetectorRef) { }

}
