<ng-container *ngFor="let menuItem of menu">
    <div *ngIf="checkAuthDisplay(menuItem) && !menuItem.desktopOnly" class="burger-menu-item" [class.submenu]="menuItem.submenu.length > 0"
        [class.is-open]="menuItem.submenuOpen">
        <div class="burger-menu-item-wrapper" (cduxTap)="responsiveSubmenuToggle($event, menuItem)">
            <div class="burger-menu-item-title">
                {{ menuItem.title }}
            </div>
            <div *ngIf="menuItem.submenu.length > 0" class="burger-menu-item-toggle">
                <div class="burger-menu-item-toggle-icon"></div>
            </div>
        </div>
        <div class="burger-menu-item-submenu">
            <div class="burger-menu-item-submenu-items">
                <ng-container *ngFor="let submenuItem of menuItem.submenu">
                    <div *ngIf="checkAuthDisplay(submenuItem) && !submenuItem.desktopOnly" class="burger-menu-item-submenu-item">
                        <div class="burger-menu-item-submenu-item-title" (cduxTap)="menuItemClick($event, submenuItem)">
                            {{ submenuItem.title }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>
