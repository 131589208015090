import { enumTrackType } from '@cdux/ng-common';

export enum enumFilters {
    ALL = 'allFilter',
}

export interface TodaysRaceFilters {
    [enumFilters.ALL]: boolean;
    [enumTrackType.GREY]: boolean;
    [enumTrackType.HARN]: boolean;
    [enumTrackType.TBRED]: boolean;
}
