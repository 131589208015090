
export interface ISidenavRoute {
    path?: string;
    component?: any;
    children?: ISidenavRoute[];
    authGuarded?: boolean;
    reverseAuthGuarded?: boolean;
    inputs?: { [key: string]: any };
    headerInputs?: { [key: string]: any };
    navigationRef?: { [key: string]: any };
}

export class SidenavRoute implements ISidenavRoute {
    constructor(
        public path: string = '',
        public component: any = null,
        public children: ISidenavRoute[] = [],
        public authGuarded: boolean = false,
        public reverseAuthGuarded: boolean = false,
        public inputs: { [key: string]: any } = {},
        public headerInputs: { [key: string]: any } = {},
        public navigationRef: { [key: string ]: any } = {},
    ) {
        // Empty
    }

    public fromRoute(route: ISidenavRoute): SidenavRoute {
        this.path = route.path || '';
        this.component = route.component || null;
        this.children = route.children || [];
        this.authGuarded = route.authGuarded || false;
        this.reverseAuthGuarded = route.reverseAuthGuarded || false;
        this.inputs = route.inputs || {};
        this.headerInputs = route.headerInputs || {};
        this.navigationRef = route.navigationRef || {};
        return this;
    }
}
