import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
    DetailsAbstract,
    enumTrackType,
    GetRunnerDetailsDataService,
    ToteDataService,
    TrainerDetails,
} from '@cdux/ng-common';
import { CduxRouteUtil } from 'app/shared/common/utils';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { enumDetailsType } from 'app/shared/program/enums/details-types.enum';
import { IDetails, IDetailsOptions } from 'app/shared/program/interfaces/details.interface';

@Component({
    selector: 'cdux-trainer-details',
    templateUrl: './trainer-details.component.html',
    styleUrls: ['./trainer-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrainerDetailsComponent implements OnInit, OnDestroy, IDetails {
    public detailsType = enumDetailsType.TRAINER;

    public detailsObs: Observable<DetailsAbstract>;

    public displayOrder = {
        last14: 1,
        last30: 2,
        last365AllTracks: 3,
        favorite: 4,
        pacers: 5,
        trotters: 6,
        oddsGE5to1: 7,
        oddsLT5to1: 8,
        shortLayoff: 9,
        longLayoff: 10,
        upInClass: 11,
        downInClass: 12
    };

    public comboDisplayOrder = {
        trainerHorse: 1,
        driverTrainer: 2
    }

    public brisCode: string;
    public trackType: enumTrackType;
    public raceNum: number;
    public programNum: string;
    public isInline: boolean = true;
    private activeLeg = 0;

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _runnerDetailsDataService: GetRunnerDetailsDataService,
        private _toteDataService: ToteDataService,
    ) {}

    ngOnInit() {
        // If we are showing details inline on the program then the program entry component manually calls init() after
        // component injection.
        this._activatedRoute.data.pipe(
            take(1),
            takeUntil(this._destroy)
        ).subscribe(
            (data) => {
                if (data && data.init) {
                    const params = CduxRouteUtil.extractParams(this._activatedRoute);
                    this.isInline = false;

                    const options: IDetailsOptions = {
                        brisCode: params.brisCode,
                        trackType: params.trackType,
                        raceNumber: params.raceNum,
                        programNumber: params.programNum
                    };

                    this.init(options);
                }
            }
        );
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();
    }

    public getTrainerDetails(): Observable<TrainerDetails> {
        const raceNum = this.activeLeg ? this.raceNum + this.activeLeg : this.raceNum;

        return this._toteDataService.currentRaceDate(false).pipe(
            switchMap((raceDate: string) => this._runnerDetailsDataService.getTrainerStats(this.brisCode, this.trackType, raceDate, raceNum, this.programNum)),
        );
    }

    public init(options: IDetailsOptions) {
        this.brisCode = options.brisCode;
        this.trackType = options.trackType;
        this.raceNum = options.raceNumber;
        this.programNum = options.programNumber;
        this.activeLeg = options.activeLeg;

        this.detailsObs = this.getTrainerDetails();
    }
}
