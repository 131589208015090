import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({providedIn: 'root'})
export class TournamentsBroadcastChannel {
    public stateChangeBroadcastListener: Subject<any> = new Subject();
    private readonly TOURNAMENT_CHANNEL_NAME = 'TOURNAMENTS_BROADCAST_CHANNEL';
    private _bc: BroadcastChannel;
 
    constructor (){
        this._bc = new BroadcastChannel(this.TOURNAMENT_CHANNEL_NAME);
        this._bc.onmessage = (msg) => {
            this.stateChangeBroadcastListener.next(msg.data);
        };
    }
    public sendMessage(action: any) {
        this._bc.postMessage(action);
        return true;
    }

    public getChannelName(): string {
        return this.TOURNAMENT_CHANNEL_NAME;
    }

    public closeBroadcast(): boolean {
        this._bc.close();
        return true;
    }
}