import { NgModule, ModuleWithProviders } from '@angular/core';
import { TuxCommonModule } from '../common/common.module';

import { ExpandedDetailsBusinessService } from './services/';
import { FormatTransactionTypePipe } from './pipes';

@NgModule({
    imports: [
        TuxCommonModule,
    ],
    declarations: [
        FormatTransactionTypePipe
    ],
    exports: [
        FormatTransactionTypePipe
    ]
})
export class TransactionModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<TransactionModule> {
        return {
            ngModule: TransactionModule,
            providers: [
                ExpandedDetailsBusinessService
            ],
        }
    }
}
