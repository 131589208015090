import { Input, OnDestroy, Optional, ChangeDetectorRef, Directive, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
    FUNDING_OPERATIONS,
    FUND_ID,
    IFundingOption,
    enumStatus,
    IEventClickDetailsAttribute,
    TranslateService,
    UserEventEnum,
    IWithdrawBalance,
    ICheckAccountInfo,
    FeatureToggleDataService,
    enumFeatureToggle
} from '@cdux/ng-common';
import { Subscription } from 'rxjs';

import { ENVIRONMENT } from '@cdux/ng-core';

import { SidebarService } from '../../../sidebar/sidebar.service';
import { EventTrackingService } from '../../../event-tracking/services/event-tracking.service'
import { FundingService } from '../../shared/services/funding.service';
import { FundingTermsAndConditionsComponent } from '../../shared/components/terms-and-conditions/terms-and-conditions.component';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { ComponentType } from '@angular/cdk/portal';
import {
    IFundingMethodComponentProperties
} from '../../shared/interfaces/funding-sidebar-component.interfaces';
import { SIDEBAR_LOADERS } from '../../../sidebar/enums/loader.enums';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import {enumFundingEvent, IFundingEvent} from '../../shared/interfaces/funding.interfaces';

export interface IFundingFormField {
    name: string;
    default: any;
    validators: any[];
}

export enum enumSidebarPortalComponentProperties {
    AMEX_ENABLED = 'amex-enabled',
    AMOUNT_TOKEN = 'amount',
    INBOUND_BET_TOKEN = 'inbound-bet',
    IS_BET_PAD_TOKEN = 'is-bet-pad',
    IS_BET_SHARE_TOKEN = 'is-bet-share',
    LAST_AMOUNT_TOKEN = 'last-amount',
    METHOD_DETAILS_TOKEN = 'method-details',
    MINIMUM_AMOUNT_TOKEN = 'minimum-amount',
    OFFER_ID_TOKEN = 'offer-id',
    REPLACE_ACCOUNT_INFO = 'replace-account-info',
}

// DEPRECATED - use enumSidebarPortalComponentProperties
const METHOD_DETAILS_TOKEN = 'method-details';
const LAST_AMOUNT_TOKEN = 'last-amount';
const IS_BET_SHARE_TOKEN = 'is-bet-share';
const INBOUND_BET_TOKEN = 'inbound-bet';
const AMOUNT_TOKEN = 'amount';
const MINIMUM_AMOUNT_TOKEN = 'minimum-amount';
const OFFER_ID_TOKEN = 'offer-id';
const AMEX_ENABLED = 'amex-enabled';
const IS_BET_PAD_TOKEN = 'is-bet-pad';
const REPLACE_ACCOUNT_INFO = 'replace-account-info';

export interface IFundingComponentProperties {
    fundingMethodDetails?: IFundingOption;
    lastFundingAmount?: number;
    isBetShare?: boolean;
    flowAttribute?: IEventClickDetailsAttribute,
    betId?: string;
    amount?: number;
    minimumAmount?: number;
    offerId?: number;
    amexEnabled?: boolean;
    isBetPad?: boolean;
    replaceAccountInfo?: boolean;
    withdrawBalance?: IWithdrawBalance;
    accountInfo?: ICheckAccountInfo
}

export enum enumDepositOptions {
    EZ_BANK = 'ez_bank',
    CREDIT_CARD = 'credit_card',
    EZ_MONEY = 'ez_money',
    GREEN_DOT = 'green_dot',
    MONEYGRAM = 'moneygram',
    PAYNEARME = 'paynearme',
    PAYPAL = 'paypal',
}

export enum enumWithdrawOptions {
    EZ_MONEY = 'ez_money',
    CHECK = 'check',
    PAYPAL = 'paypal'
}

@Directive()
export abstract class FundingAbstractMethodComponent extends CduxSidebarContentComponent implements OnDestroy {

    // Selected Funding Method
    @Input() public fundingMethodDetails: IFundingOption | null;
    // Last Funding Amount
    @Input() public lastFundingAmount: number;
    // Indicator for whether we're in a bet share context.
    @Input() public isBetShare: boolean = false;
    // Indicator for whether we're in a bet pad.
    @Input() public isBetPad: boolean = false;
    // Inbound bet amount
    @Input() public inboundBet: string;
    // Minimum amount to suggest the user deposits
    @Input() public amount: number;
    // Forced minimum amount to deposit.
    @Input() public minimumAmount: number;
    // Opt in deposit offerId
    @Input() public offerId: number;
    // for event tracking / flow of app
    @Input() public flowAttribute: IEventClickDetailsAttribute;
    // for indentifying if Amex is enabled
    @Input() public amexEnabled: boolean;
    // treats deposit method same as first time deposit if true
    @Input() replaceAccountInfo: boolean;

    @Output()
    public error: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Is the current method a withdraw or deposit
    // It's protected so only direct children can set it.
    protected operation: FUNDING_OPERATIONS;

    // Allow all children and grandchildren to read the operation.
    public get OPERATION(): FUNDING_OPERATIONS {
        return this.operation;
    }

    protected operationMethod: enumWithdrawOptions | enumDepositOptions;

    // AffiliateID key to use for translate service
    public readonly AFFILIATE_KEY = this._environment.affiliateId.toString();

    // Reactive Form Group
    public form: UntypedFormGroup;
    // Lockout Flag
    public lockout = false;
    // flag if no phone support to be used in error and UI messaging
    public phoneEnabled;
    // Fields to be used to Intialize Form
    public formFields: IFundingFormField[] = [];
    // Error Message
    public errorCode: string = null;
    public errorCodeArgs: string = null;

    public pendingDeposit = false;
    public successfulDeposit = false;

    public pendingWithdraw = false;
    public successfulWithdraw = false;

    public successMessage = '';

    // Collection of Subscriptions to be Cleaned
    protected _subscriptions: Subscription[] = [];

    protected readonly _VALID_AMOUNT_PATTERN = '[+-]?([0-9]*[.])?[0-9]+';

    /* IMPLEMENT CduxSidebarContentComponent
     * =====================================
     */

    protected static createSidebarPortal(component: ComponentType<CduxSidebarContentComponent>, options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        const inputTokens: Map<any, any> = new Map();

        if (options.fundingMethodDetails) {
            inputTokens.set(enumSidebarPortalComponentProperties.METHOD_DETAILS_TOKEN, options.fundingMethodDetails);
        }

        if (options.lastFundingAmount) {
            inputTokens.set(enumSidebarPortalComponentProperties.LAST_AMOUNT_TOKEN, options.lastFundingAmount);
        }

        if (options.isBetShare) {
            inputTokens.set(enumSidebarPortalComponentProperties.IS_BET_SHARE_TOKEN, options.isBetShare);
        }

        if (options.betId) {
            inputTokens.set(enumSidebarPortalComponentProperties.INBOUND_BET_TOKEN, options.betId)
        }

        if (options.amount) {
            inputTokens.set(enumSidebarPortalComponentProperties.AMOUNT_TOKEN, options.amount)
        }

        if (options.minimumAmount) {
            inputTokens.set(enumSidebarPortalComponentProperties.MINIMUM_AMOUNT_TOKEN, options.minimumAmount)
        }

        if (options.offerId) {
            inputTokens.set(enumSidebarPortalComponentProperties.OFFER_ID_TOKEN, options.offerId)
        }

        if (options.amexEnabled !== undefined) {
            inputTokens.set(enumSidebarPortalComponentProperties.AMEX_ENABLED, options.amexEnabled)
        }

        if (options.isBetPad) {
            inputTokens.set(enumSidebarPortalComponentProperties.IS_BET_PAD_TOKEN, options.isBetPad);
        }

        if (options.replaceAccountInfo) {
           inputTokens.set(enumSidebarPortalComponentProperties.REPLACE_ACCOUNT_INFO, options.replaceAccountInfo);
        }

        return {
            component: component,
            properties: {
                inputs: inputTokens,
                navTarget: MenuItemsEnum.FUNDING
            }
        };
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return null;
    }

    public setProperties(properties: ISidebarComponentProperties) {
        if (properties && properties.inputs) {
            if (properties.inputs.has(METHOD_DETAILS_TOKEN)) {
                this.fundingMethodDetails = properties.inputs.get(METHOD_DETAILS_TOKEN);
            }

            if (properties.inputs.has(LAST_AMOUNT_TOKEN)) {
                this.lastFundingAmount = properties.inputs.get(LAST_AMOUNT_TOKEN);
            }
            if (properties.inputs.has(IS_BET_SHARE_TOKEN)) {
                this.isBetShare = properties.inputs.get(IS_BET_SHARE_TOKEN);
            }
            if (properties.inputs.has(IS_BET_PAD_TOKEN)) {
                this.isBetPad = properties.inputs.get(IS_BET_PAD_TOKEN);
            }

            if (properties.inputs.has(INBOUND_BET_TOKEN)) {
                this.inboundBet = properties.inputs.get(INBOUND_BET_TOKEN);
            }

            if (properties.inputs.has(AMOUNT_TOKEN)) {
                this.amount = properties.inputs.get(AMOUNT_TOKEN);
            }

            if (properties.inputs.has(MINIMUM_AMOUNT_TOKEN)) {
                this.minimumAmount = properties.inputs.get(MINIMUM_AMOUNT_TOKEN);
                // The suggested amount should not be below the minimum amount.
                if (this.amount && this.minimumAmount > this.amount) {
                    this.amount = this.minimumAmount;
                }
            }

            if (properties.inputs.has(OFFER_ID_TOKEN)) {
                this.offerId = properties.inputs.get(OFFER_ID_TOKEN);
            }

            if (properties.inputs.has(AMEX_ENABLED)) {
                this.amexEnabled = properties.inputs.get(AMEX_ENABLED);
            }

            if (properties.inputs.has(REPLACE_ACCOUNT_INFO)) {
                this.replaceAccountInfo = properties.inputs.get(REPLACE_ACCOUNT_INFO);
            }
        }
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    constructor(
        protected _environment: ENVIRONMENT,
        protected _sidebarService: SidebarService,
        protected _fb: UntypedFormBuilder,
        protected _fundingService: FundingService,
        protected _eventTrackingService: EventTrackingService,
        protected _translateService: TranslateService,
        protected _featureToggleService: FeatureToggleDataService,
        @Optional() protected _cdr?: ChangeDetectorRef
    ) {
        super();
        this.phoneEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this.lockout = (!!this.fundingMethodDetails && this.fundingMethodDetails.locked);
        // default to deposit
        this.operation = FUNDING_OPERATIONS.DEPOSIT;
    }

    public ngOnDestroy() {
        this._subscriptions.map((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    /**
     * Resets a Single Field in the FormGroup
     * @param formGroupName - Name of Field to be Reset
     */
    public resetField(formGroupName: string) {
        const formGroup = this.form.get(formGroupName);
        if (formGroup) {
            formGroup.reset('');
            formGroup.markAsPristine();
            formGroup.markAsUntouched();
        }
    }

    /**
     * Handles the Response from the FundingService
     * @param response - Response from the FundingService Call
     * @param message - Message to be displayed on Success
     * @param amount - amount deposited/withdrawn
     * @param isFullscreen
     */
    protected _handleResponse(response: any, message: string | Array<string>, amount?: number, isFullscreen: boolean = false) {
        if (response && response.status === 'success') {
            const messages = Array.isArray(message) ? message.map((m) => `${m}`) : [`${message}`];
            this._fundingService.onFundingEvent.emit({event: 'SUCCESS', messages, amount: amount});
            this.successfulDeposit = true;
            this.successfulWithdraw = true;

            // We get the updated balance from the transaction response. As a default, lets
            // set it to the current balance, in case there's something wrong.
            let balance: number;
            if (response.messageArgs) {
                const messageArgs = JSON.parse(response.messageArgs);
                balance = messageArgs?.balanceAmount;
            }


            if (!isFullscreen) {
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER, {
                        delayMs: 5000,
                        messages: messages,
                        status: enumStatus.SUCCESS
                    },
                    () => {
                        this._fundingService.closeFundingMethod(this.inboundBet, this.offerId, amount, balance, this.isBetPad, this.operation, this.operationMethod, this.isBetShare);
                });
            } else {
                this._fundingService.closeFundingMethod(this.inboundBet, this.offerId, amount, balance, this.isBetPad, this.operation, this.operationMethod, this.isBetShare);
            }

            this.error.emit(false);
        } else {
            this.pendingDeposit = false;
            this.pendingWithdraw = false;
            this.successfulDeposit = false;
            this.successfulWithdraw = false;

            /* todo: html message for pending on full page and sidebar widget is different. Once the sidebar deposit widget
             * is officially retired, remove sidebar message, full-screen suffix, and just pass object on emit without fundingEvent variable set in two spots
             */
            let fundingEvent: IFundingEvent;
            if (response.status === enumFundingEvent.PENDING.toLowerCase()) {
                this.errorCode = this.operationMethod + '-' + response.status;
                fundingEvent = { event: 'ERROR', messages: [this._translateService.translate(this.errorCode + '-full-screen', 'errorcodes')]};
            } else {
                if (response && response.errorCode === 1000) {
                    this.lockout = true;
                }

                // Special EZM usecase when locked out.
                // Originally handled in the ezmoney deposit component in sidebar, but moved here for full page and
                // express to leverage.
                // This may be ok to apply to all funding methods and not just EZMoney and just change errorCode 1000 in the language file to be the locked messaging,
                // but in an effort to limit scope it's just for EZMoney right now.
                if (this.lockout && this.fundingMethodDetails && this.fundingMethodDetails.fundId === FUND_ID.EZMONEY) {
                    response.errorCode = 'locked';
                }

                if (response && response.errorCode === 1008) {
                    response.errorCode = this.OPERATION + '-' + response.errorCode;
                }

                this.errorCode = response ? response.errorCode : 'fallback';

                /**
                 * Send event to marketing for all Deposit failure
                 */
                 if (this.operation === FUNDING_OPERATIONS.DEPOSIT && !!this.operationMethod) {
                    const deposit_failure_event = {
                        'firstTimeDeposit': this._fundingService.isFirstTimeDeposit(),
                        'depositMethod': this.operationMethod,
                        'depositAmount': amount,
                        'failReason': this._translateService.translate(this.errorCode, 'errorcodes'),
                        'osVersion': this._eventTrackingService.getOsVersion(),
                        // balance isn't returned on failed attempts so send most recent value
                        'userBalance': this._fundingService.accountBalance,
                        'status': 'fail',
                        'action': 'complete'
                    };
                    this._eventTrackingService.logUserEvent(UserEventEnum.DEPOSIT_FAILED, deposit_failure_event);
                }
                fundingEvent = {event: 'ERROR', code: this.errorCode, messages: [this._translateService.translate(this.errorCode, 'errorcodes', true, this.errorCodeArgs)], amount: amount};
            }

            this._fundingService.onFundingEvent.emit(fundingEvent);
            if (!this.isBetShare) {
                this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
            }

            this.error.emit(true);
        }
    }

    /**
     * Initializes the Reactive Form Group
     */
    protected _initForm(fields: IFundingFormField[]): void {
        this.form = this._fb.group({});
        fields.map((field) => {
            const control = this._fb.control(field.default, field.validators);
            this.form.addControl(field.name, control);
        });
        if (this._cdr) {
            this._subscriptions.push(
                this.form.valueChanges.subscribe(() => {
                    this._cdr.detectChanges();
                }),
                this.form.statusChanges.subscribe(() => {
                    this._cdr.detectChanges();
                })
            );
        }
    }

    /**
     * Opens the TOC page in the Sidenav
     */
    public openTermsAndConditions() {
        this._sidebarService.loadComponent(FundingTermsAndConditionsComponent.getSidebarComponent());
    }

    public clearError() {
        this.errorCode = null;
        this.errorCodeArgs = null;
        this.error.emit(false);
    }

}
