import { BehaviorSubject, Observable } from 'rxjs';
import {
    finalize,
    map,
    takeUntil,
    scan,
    withLatestFrom
} from 'rxjs/operators';
import { CduxRxJSBuildingBlock } from '@cdux/ng-core';
import {
    EntrySelectionUtilBusinessService,
    ISelectedEntry,
    IBetNavObject,
    BetTypeUtil,
    WagerState
} from '@cdux/ng-common';
import { ISelectionUpdate } from '../interfaces/selection-update.interface';

export interface ISelectionState {
    selectedEntries: ISelectedEntry[][];
    betNav: IBetNavObject;
}

export class EntrySelectionManager extends CduxRxJSBuildingBlock<any, ISelectionState> {
    /* CONTROLS */
    private _selectionState: BehaviorSubject<ISelectionState>;
    /* END CONTROLS */

    /**
     * Constructor
     */
    constructor(
        private _entrySelectionService: EntrySelectionUtilBusinessService,
        private _betNavStream: Observable<IBetNavObject>,
        private _entrySelections: Observable<ISelectionUpdate[]>,
        private _initialValue?: WagerState
    ) {
        super();

        if (this._initialValue) {
            this._selectionState = new BehaviorSubject<ISelectionState>({selectedEntries: this._initialValue.bettingInterests, betNav: this._initialValue.betNav});
        } else {
            this._selectionState = new BehaviorSubject<ISelectionState>({selectedEntries: [], betNav: null});
        }

        this._init();
    }

    /** EXTERNAL CONTROLS **/
    public kill() {
        super.kill();
        this._selectionState.complete();
    }
    /** END EXTERNAL CONTROLS **/

    /** ACCESSORS **/
    /** END ACCESSORS **/

    /**
     * Initializes the stream.
     */
    protected _init() {
        this._stream = this._selectionState
            .pipe(
                finalize(() => this.kill())
            );
        this._updateRunnersOnSelectionChange();
        this._updateRunnersOnBetNavChange();
    }

    /* CUSTOM OBSERVABLES */
    private _updateRunnersOnSelectionChange(): void {
        this._entrySelections.pipe(
            this._accumulateSelectedEntries(),
            finalize(() => this.kill()),
            takeUntil(this._kill)
        ).subscribe(selection => this._selectionState.next(selection));
    }

    private _updateRunnersOnBetNavChange(): void {
        this._betNavStream.pipe(
            withLatestFrom(this._stream),
            scan<[IBetNavObject, ISelectionState], ISelectionState>((acc, [betNav, selectionState]) => {
                acc.selectedEntries = selectionState.selectedEntries;
                if (!!acc.betNav && selectionState.selectedEntries.length !== 0 && this._entrySelectionService.areEntriesSelected(selectionState.selectedEntries)) {
                    if (!betNav) {
                        acc.selectedEntries = [];
                    } else if (!BetTypeUtil.isSameWagerType(betNav.type, acc.betNav.type)) {
                        acc.selectedEntries = this._entrySelectionService.retainSingleLegRunners(acc.betNav.type.poolType.selectionCount, betNav.type.poolType.selectionCount, acc.selectedEntries);
                    } else if (!BetTypeUtil.isSameBetModifier(betNav.modifier, acc.betNav.modifier) && !!acc.betNav.modifier) {
                        acc.selectedEntries = this._entrySelectionService.retainEntrySelections(acc.selectedEntries, acc.betNav.modifier, betNav.modifier, betNav.type.poolType.selectionCount);
                    }
                }
                acc.betNav = betNav;
                return acc;
            }, {selectedEntries: [], betNav: null}),
            takeUntil(this._kill)).subscribe(entries => this._selectionState.next(entries));
    }
    /* END CUSTOM OBSERVABLES */

    /* CUSTOM METHODS */
    private _updateLegEntries(currentEntries: ISelectedEntry[][], selection: ISelectionUpdate, betNav: IBetNavObject): ISelectedEntry[][] {
        if (!!selection) {
            return this._entrySelectionService.setSelectedAndFinalize(currentEntries, selection.selected, selection.leg, selection.entries, betNav);
        } else {
            return [];
        }
    }

    private _accumulateSelectedEntries() {
        return (src: Observable<ISelectionUpdate[]>): Observable<ISelectionState> => src.pipe(
            withLatestFrom(this._betNavStream, this._selectionState),
            map(([selectionUpdates, betNav, acc]) => {
                if (!!selectionUpdates && selectionUpdates.length > 0) {
                    selectionUpdates.forEach((update) => {
                        acc = {selectedEntries: this._updateLegEntries(acc.selectedEntries, update, betNav), betNav};
                    });
                } else {
                    // Reset the selected entries
                    acc = {selectedEntries: [], betNav};
                }
                return acc;
            })
        );
    }
    /* END CUSTOM METHODS */
}
