import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { CduxMediaToggleService } from "@cdux/ng-platform/web";
import { DrawerService, FullDrawerOperations } from "app/shared/common/services";
import {
    CduxObjectUtil,
    FavoriteRunnersService,
    FavoritesDataService,
    FavoriteSearch,
    FavoriteSearchType,
    IFavoriteRunner,
    FavoriteSearchPerson,
    FavoriteSearchRunner,
    IFavoritePerson,
    FavoritePersonService,
    EventClickType,
    EventClickAttributeType
} from '@cdux/ng-common';
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs/operators";
import { of, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FavEventType } from "../favorites-event-interface";
import { AccountBubbleNotificationService } from "app/shared/notification/services/account-bubble-notification.service";
import { ToastService } from "@cdux/ng-fragments";
import { FavoriteLocationEnum } from 'app/account/favorites/enums/favorite-location-enums';
import { FavoriteTypeEnum } from 'app/account/favorites/enums/favorite-type-enums';

import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-favorites-stable-search',
    templateUrl: './favorites-stable-search.component.html',
    styleUrls: [ './favorites-stable-search.component.scss' ],
})
export class FavoritesStableSearchComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'stable-alerts__drawer-contents';

    public get note () {
        return this.favoriteAdding?.comment;
    }
    public set note (value: string) {
        const count = value.length;
        if (count <= this.COUNT_MAX) {
            this.countCurrent = count;
            this.favoriteAdding.comment = value;
        }
    }

    public countCurrent = 0;
    public readonly COUNT_MAX = 4096;
    public runners: any[];
    public results: any[];
    public isLoading = true;
    public searchType = FavoriteSearchType.RUNNER;
    public searchString = '';
    public addFavoriteDisplay = false;
    public adding: boolean = false;
    public favoriteAdding: any;

    private _searchType: string;
    public modelChanged: Subject<string> = new Subject();
    private _searchResultsSub: Subscription;
    private _favoriteType: FavoriteTypeEnum;

    @Input()
    set selectedTab(selectedTab: string) {
        if (selectedTab !== this._searchType) {
            this._searchType = selectedTab;
        }

        this._initVariables();
    }
    get selectedTab() {
        return this._searchType;
    }

    public searchPlaceholder = 'Search Runners';
    public isPhone: boolean = false;

    private errorMessages = {
        MAX_NUMBER: 'You have reached the maximum number of %d runners. Please remove some runners before adding new ones.',
        ALREADY_EXISTS: '%s already exists in your stable.',
        DEFAULT: 'We are unable to add this favorite to your stable. Please try again later.'
    };
    private errorMessageKeys = {
        MAX_NUMBER: 'maximum number',
        ALREADY_EXISTS: 'already exists'
    };

    private _destroy: Subject<any> = new Subject<any>();

    constructor(
        private _drawerService: DrawerService,
        private _favoritesDataService: FavoritesDataService,
        private _mediaService: CduxMediaToggleService,
        private _changeDetector: ChangeDetectorRef,
        public favRunners: FavoriteRunnersService,
        private _accountBubbleNotificationService: AccountBubbleNotificationService,
        private _toastService: ToastService,
        public favPerson: FavoritePersonService,
        private _eventTrackingService: EventTrackingService,
    ) {
        this._searchResultsSub = this.modelChanged.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(searchInput => this.searchString = searchInput),
            switchMap((searchInput: string) => {
                this.results = [];
                this.isLoading = true;

                /* Need to prevent '%' from either being typed
                 * in or included in a string that's pasted into
                 * the input box.  This code below will also  remove
                 * ALL occurrences of a % character
                 */
                searchInput = searchInput.replace(/%/g,'');
                this.modelChanged.next(searchInput);

                return (searchInput.length > 2)
                    ? this._favoritesDataService.favoriteSearch(this.searchType, searchInput)
                    : of({
                        runners: [],
                        jockeys: [],
                        trainers: []
                    });
            })
        ).subscribe(
            (data: FavoriteSearch) => {
                this.results = [];

                switch(this.searchType) {
                    case FavoriteSearchType.TRAINER:
                        this._favoriteType = FavoriteTypeEnum.TRAINER;
                        this._setTrainersResults(data.trainers);
                        break;

                    case FavoriteSearchType.JOCKEY:
                        this._favoriteType = FavoriteTypeEnum.JOCKEY;
                        this._setJockeysResults(data.jockeys);
                        break;

                    case FavoriteSearchType.RUNNER:
                        this._favoriteType = FavoriteTypeEnum.RUNNER;
                        this._setRunnersResults(data.runners);
                        break;
                }

                /* To prevent the "No Results Found" page from being
                 * displayed incorrectly we only want to set isLoading
                 * to false when the search string length is 3+ characters
                 */
                if (this.searchString.length > 2) {
                    this.isLoading = false;
                }
            },
            () => {
                this.results = [];
                this.isLoading = false;
            }
        );
    }

    private _initVariables() {
        switch (this.selectedTab) {
            case 'Runners':
                this.searchPlaceholder = 'Search Runners';
                this.searchType = FavoriteSearchType.RUNNER;
                break;
            case 'Trainers':
                this.searchPlaceholder = this.isPhone
                    ? 'Search Trainers: Last Name, First'
                    : 'Search Trainers (Last Name, First, MI)';
                this.searchType = FavoriteSearchType.TRAINER;
                break;
            case 'Jockeys':
                this.searchPlaceholder = this.isPhone
                    ? 'Search Jockeys: Last Name, First'
                    : 'Search Jockeys (Last Name, First, MI)';
                this.searchType = FavoriteSearchType.JOCKEY;
                break;
            default:
                this.searchPlaceholder = '';
                break;
        }
    }

    ngOnInit() {
        this._mediaService.registerQuery('phone').pipe(
            takeUntil(this._destroy)
        ).subscribe(isPhone => {
            this.isPhone = isPhone;
            this._changeDetector.detectChanges();
        });

        this._initVariables();
    }

    ngOnDestroy() {
        this._destroy.next();
        this._destroy.complete();

        if (this._searchResultsSub) {
            this._searchResultsSub.unsubscribe();
        }
    }

    /**
     * Emit the event to close the drawer
     */
    public closeDrawer() {
        this._drawerService.toggleFullDrawer({
            operation: FullDrawerOperations.CLOSE
        });
    }

    private _formatName(last: string, first: string, middle: string): string {
        if (middle.trim().length > 0) {
            return [last.trim(), first.trim(), middle.trim()].join(', ');
        } else {
            return [last.trim(), first.trim()].join(', ');
        }
    }

    private _setPersonResults(person: FavoriteSearchPerson[]) {
        if (person?.length > 0) {
            for (let i = 0; i < person.length; i++) {
                this.results.push({
                    name: this._formatName(person[i].lastName, person[i].firstName, person[i].middleName),
                    trackName: '',
                    raceNumber: '',
                    silkUrl:  '',
                    isCurrentFavorite: person[i]?.isCurrentFavorite || false,
                    personType: this.searchType || '',
                    personId: person[i].personId || '',
                    lastName: person[i].lastName || '',
                    firstName: person[i].firstName || '',
                    middleName: person[i].middleName || ''
                });
            }
        }
    }

    public createFavoriteData(favorite: any) {
        switch (this.searchType) {
            case 'runner':
                const favRunnerObject = {
                    runnerId: favorite.runnerId,
                    runnerName:favorite.name,
                    breedType: favorite.breedType,
                    whereBred: favorite.whereBred,
                    yearOfBirth: favorite.yearOfBirth,
                }
                return favRunnerObject as IFavoriteRunner;
            case 'trainer':
            case 'jockey':
                const favPersonObject = {
                    personType: favorite.personType,
                    personId: favorite.personId,
                    lastName: favorite.lastName,
                    firstName: favorite.firstName,
                    middleName: favorite.middleName
                }
                return favPersonObject as IFavoritePerson;
        }
    }

    public addFavorite(favorite: any) {
        this._logClickEvent(EventClickType.STABLE_ALERTS_ADD_TO_STABLE);
        this.addFavoriteDisplay = true;
        this.favoriteAdding = this.createFavoriteData(favorite);
    }

    public cancelAdd() {
        this.addFavoriteDisplay = false;
        this.favoriteAdding = null;
    }

    public confirm (): void {
        if (this.adding === false) {
            switch (this.searchType) {
                case 'runner':
                    this.adding = true;
                    this.favRunners.add(this.favoriteAdding).then(
                        (result: IFavoriteRunner) => {
                            this._logClickEvent(EventClickType.STABLE_ALERTS_ADD);
                            this.favoriteAdding.uuid = result.uuid;
                            // call AccountBubbleNotificationService.dispatch to emit success message:
                            this._accountBubbleNotificationService.dispatch({
                                type: FavEventType.FAVORITE_RUNNER_ADD,
                                payload: {
                                    message: '<i class="icon--check-light-circle"></i>' + this.favoriteAdding.runnerName + ' added to <span class="bold-text">stable</span>.',
                                    duration: 3000
                                }
                            });

                            this._changeDetector.markForCheck();
                        },
                        error  => {
                            // Get the maximum number of records out of the message:
                            const errorsArray: string[] = CduxObjectUtil.deepGet(error, 'data.error.errors');
                            const errorMessage: string = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].toLowerCase() : null;
                            // Because we don't want to use custom error codes, many potential errors are returned as 400. This means I
                            // need to parse the string and search for specific terms in order to determine which error we have encountered
                            if (errorMessage.search(this.errorMessageKeys.MAX_NUMBER) !== -1) {
                                // Show maximum number error
                                const maxRecords: any[] = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].match(/(\d+)/g) : null;
                                if (error.data.error.code === 400 && Array.isArray(maxRecords) && maxRecords.length) {
                                    // Display the error message
                                    this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%d', maxRecords[0]));
                                }
                            } else if (errorMessage.search(this.errorMessageKeys.ALREADY_EXISTS) !== -1) {
                                // Show already exists error message
                                this._toastService.cduxWarning(this.errorMessages.ALREADY_EXISTS.replace('%s', this.favoriteAdding.runnerName));
                            } else {
                                // Show default error message
                                this._toastService.cduxWarning(this.errorMessages.DEFAULT);
                            }
                            this._changeDetector.markForCheck();
                        }
                    ).finally(() => {
                        this.closeDrawer();
                    });
                    break;
                case 'trainer':
                case 'jockey':
                    this.adding = true;
                    this.favPerson.add(this.favoriteAdding).then(
                        (result: IFavoritePerson) => {
                            this._logClickEvent(EventClickType.STABLE_ALERTS_ADD);
                            this.favoriteAdding.personId = result.personId;
                            this._accountBubbleNotificationService.dispatch({
                                type: FavEventType.FAVORITE_RUNNER_ADD,
                                payload: {
                                    message: '<i class="icon--check-light-circle"></i>' + this.favoriteAdding.firstName + ' ' + (this.favoriteAdding.middleName ? this.favoriteAdding.middleName : '') + ' ' + this.favoriteAdding.lastName + ' added to <span class="bold-text">stable</span>.',
                                    duration: 3000
                                }
                            });
                            this._changeDetector.markForCheck();
                        },
                        error  => {
                            // Get the maximum number of records out of the message:
                            const errorsArray: string[] = CduxObjectUtil.deepGet(error, 'data.error.errors');
                            const errorMessage: string = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].toLowerCase() : null;
                            // Because we don't want to use custom error codes, many potential errors are returned as 400. This means I
                            // need to parse the string and search for specific terms in order to determine which error we have encountered
                            if (errorMessage.search(this.errorMessageKeys.MAX_NUMBER) !== -1) {
                                // Show maximum number error
                                const maxRecords: any[] = Array.isArray(errorsArray) && errorsArray.length ? errorsArray[0].match(/(\d+)/g) : null;
                                if (error.data.error.code === 400 && Array.isArray(maxRecords) && maxRecords.length) {
                                    // Display the error message
                                    this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%d', maxRecords[0]));
                                    this._toastService.cduxWarning(this.errorMessages.MAX_NUMBER.replace('%p', this.favoriteAdding.personType + 's'));
                                }
                            } else if (errorMessage.search(this.errorMessageKeys.ALREADY_EXISTS) !== -1) {
                                // Show already exists error message
                                this._toastService.cduxWarning(this.errorMessages.ALREADY_EXISTS.replace('%s', this.favoriteAdding.firstName + ' ' + (this.favoriteAdding.middleName ? this.favoriteAdding.middleName : '') + ' ' + this.favoriteAdding.lastName));
                            } else {
                                // Show default error message
                                this._toastService.cduxWarning(this.errorMessages.DEFAULT);
                            }
                            this._changeDetector.markForCheck();
                        }
                    ).finally(() => {
                        this.closeDrawer();
                    });
                    break;
            }
        }
    }

    private _setTrainersResults(trainers: FavoriteSearchPerson[]) {
        this._setPersonResults(trainers);
    }

    private _setJockeysResults(jockeys: FavoriteSearchPerson[]) {
        this._setPersonResults(jockeys);
    }

    private _setRunnersResults(runners: FavoriteSearchRunner[]) {
        if (runners?.length > 0) {
            for (let i = 0; i < runners.length; i++) {
                this.results.push({
                    name: runners[i]?.name || 'Unknown',
                    runnerId: runners[i]?.brisId || '',
                    breedType: runners[i]?.breedType || '',
                    whereBred: runners[i]?.whereBred || '',
                    yearOfBirth: runners[i]?.yob || 0,
                    comment: '',
                    trackName: runners[i]?.upcomingStarts[0]?.trackName || '',
                    raceNumber: runners[i]?.upcomingStarts[0]?.raceNumber?.toString() || '',
                    silkUrl:  runners[i]?.upcomingStarts[0]?.silkUrl || '',
                    isCurrentFavorite: runners[i]?.isCurrentFavorite || false
                });
            }
        }
    }

    public clearSearchString() {
        this.modelChanged.next('');
        this.searchString = '';
        this.isLoading = true;
        this.results = [];
    }

    private _logClickEvent(clickType: EventClickType) {
        const ts = Date.now();

        let favType = EventClickAttributeType.STABLE_ALERTS_ADD_FAVORITE_TYPE;
        let favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_LOCATION;

        if (clickType === EventClickType.STABLE_ALERTS_ADD_TO_STABLE) {
            favType = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_FAVORITE_TYPE;
            favLoc = EventClickAttributeType.STABLE_ALERTS_ADD_TO_STABLE_LOCATION;
        }

        this._eventTrackingService.logClickEvent(
            clickType,
            [
                {
                    attrId: favType,
                    data: this._favoriteType,
                    timestamp: ts
                },
                {
                    attrId: favLoc,
                    data: FavoriteLocationEnum.STABLE_ALERTS,
                    timestamp: ts
                },
            ]
        );
    }
}
