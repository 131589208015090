import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactory,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { VideoAngles } from '@cdux/ng-common';
import { LiveVideoFeedFactory } from '../../video-feeds/live-video-feed.factory';
import { IVideoFeed, VideoFeedError } from '../../video-feeds/video-feed.interface';

@Component({
    selector: 'cdux-video-player',
    templateUrl: 'video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerComponent implements OnDestroy {
    @Input()
    public set feedFactory(factory: ComponentFactory<IVideoFeed>) {
        if (!factory) { return; }
        this.resetDisplay();
        try {
            this.feed = this.container.createComponent(factory).instance;
            // setAngle calls handleStreamData, but nothing will happen since there is no data present
            if (this.feedAngle) {
                this.feed.setAngle(this.feedAngle, false);
            }
            this.feedSubs = this.feed && [
                this.feed.onError.pipe(
                    skipWhile(() => this.videoFeedFactory.failover())
                ).subscribe(
                    e => this.handleFeedError(e || VideoFeedError.UNKNOWN_ERROR),
                    e => this.handleFeedError(e || VideoFeedError.UNKNOWN_ERROR)
                ),
                this.feed.getAngles().subscribe(angles => {
                    this.feedAnglesChange.emit(angles);
                    this.feedAngleChange.emit(this.feed.getAngle());
                })
            ];
            this.changeDetectorRef.detectChanges();
        } catch (e) {
            this.handleFeedError(VideoFeedError.UNKNOWN_ERROR);
        }
    }

    @Input()
    public set feedAngle(angle: VideoAngles) {
        if (this._feedAngle !== angle) {
            this._feedAngle = angle;

            if (this.feed && this.feed.getAngle() !== this._feedAngle) {
                this.feed.setAngle(angle, true);
            }

            this.feedAngleChange.emit(angle);
        }
    }
    public get feedAngle(): VideoAngles {
        return this._feedAngle;
    }
    private _feedAngle: VideoAngles = null;

    @Output()
    public error = new EventEmitter<VideoFeedError>();

    @Output()
    public feedAngleChange = new EventEmitter<VideoAngles>();

    @Output()
    public feedAnglesChange = new EventEmitter<VideoAngles[]>();

    @ViewChild('videoContainer', { read: ViewContainerRef })
    private container: ViewContainerRef;
    private feed: IVideoFeed;
    private feedSubs: Subscription[];

    @Input()
    public onTV = false;

    public isFlashAvailable = true;
    public isVideoAvailable = true;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private videoFeedFactory: LiveVideoFeedFactory
    ) { }

    public ngOnDestroy() {
        this.resetDisplay();
    }

    public handleFeedError(type: VideoFeedError) {
        this.resetDisplay();
        this.error.emit(type);

        // If we are switching from a feed that has angles and this current feed fails for whatever reason, make sure
        // we clear out the angles so the buttons don't linger
        this.feedAnglesChange.emit([]);
        this.feedAngleChange.emit(null);

        switch (type) {
            case VideoFeedError.FLASH_UNAVAILABLE:
                this.displayFlashUnavailable();
                break;
            case VideoFeedError.VIDEO_UNAVAILABLE:
                this.displayVideoUnavailable();
                break;
            default:
                this.displayVideoUnavailable();
                break;
        }

        // In some cases, the error event is called from a non-Angular function.
        // We need to force Angular to go through its change detection process
        // to ensure the UI updates.
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    public displayFlashUnavailable() {
        this.isFlashAvailable = false;
    }

    public displayVideoUnavailable() {
        this.isVideoAvailable = false;
    }

    public resetDisplay() {
        this.isFlashAvailable = true;
        this.isVideoAvailable = true;
        this.container.clear();

        if (this.feedSubs) {
            this.feedSubs.forEach(sub => sub.unsubscribe());
            this.feedSubs.length = 0;
            this.feed = null;
        }
    }

    public resize() {
        this.feed.resize();
    }
}
