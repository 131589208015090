<section class="my-bets-by-track-container">

    <div class="header">
        <div class="title">MY BETS</div>
        <div class="view-all-btn smaller" *ngIf="isLoggedIn" (click)="viewAllMyBets()">VIEW ALL</div>
    </div>

    <div class="my-bets-by-track-rows">
        <div class="my-bets-by-track-row">
            <div class="bet">
                <div class="track-race-container">
                    <div class="track-name">{{ wagerState?.basicTrack | formatTrackName }}</div>
                    <div class="race-number">Race {{ wagerState?.basicTrack?.RaceNum }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-bets-by-track-rows">
        <ng-container *ngIf="bets?.length > 0; else noBets">
            <div class="my-bets-by-track-row" *ngFor="let bet of bets; trackBy: trackBySerialNumber; first as firstBet">
                <div class="bet" [class.active]="activeBet === bet" [class.hide]="cancelingBet === bet"
                    [class.highlight-bet]="bet.serialNumber === highlightedBet">
                    <div class="bettype-ellipses-container">
                        <div class="bet-type">{{ bet.poolType | formatWager : bet.runners : bet.delimiter : undefined : bet.quickPick}}</div>
                        <div class="bet-amount-ellipses" [class.profit-loss]="isRaceOfficial(bet.raceStatus)">
                            <div class="bet-amount">{{ bet.wagerAmount | formatBetAmount }}</div>
                            <div class="ellipsis" [class.profit-loss]="isRaceOfficial(bet.raceStatus)">
                                <div *ngIf="isRaceOfficial(bet.raceStatus)" [ngClass]="bet.wagerTotal > 0 ? 'profit' : 'loss'">
                                    <span class="trophy" *ngIf="bet.status !== wagerDisplayStatus.REFUNDED"></span>{{ bet.wagerTotal | currency }}
                                </div>
                                <span class="icon" *ngIf="canCopyBet(bet) || canCancelBet(bet)"
                                    (click)="toggleCopyCancel(bet)"></span>
                            </div>
                        </div>
                    </div>
                    <cdux-runner-list class="bet-container place-leg-pick-container"
                        [runnerList]="bet.runners"
                        [runnerListDelimiter]="bet.delimiter"
                        [collapseBoxedRunners]="false"
                        [poolType]="bet.poolType"
                        [trackType]="bet.trackType"
                    ></cdux-runner-list>
                    <ng-template #NoSaddleCloths>
                        <div class="bet-container">
                            <div class="bet-pick">{{ bet.runners | formatRunners:bet.delimiter }}</div>
                        </div>
                    </ng-template>
                </div>

                <div class="copy-cancel-row" *ngIf="cancelingBet !== bet; else notice">
                    <div class="copy-btn" *ngIf="canCopyBet(bet)" (click)="copyBet(bet); toggleCopyCancel(bet)">
                        <div class="copy-icon"></div>
                        <div class="copy-label">COPY</div>
                    </div>
                    <div class="delete-btn" *ngIf="canCancelBet(bet)" (click)="toggleCancelConfirm(bet); toggleCopyCancel(bet)">
                        <div class="delete-icon"></div>
                        <div class="delete-label">DELETE</div>
                    </div>
                </div>

                <ng-template #notice>
                    <ng-container *ngIf="!cancelError; else errorNotice">
                        <div class="copy-cancel-row notice">
                            <div class="description">Are you sure you want to cancel this bet?</div>
                            <div class="options">
                                <div class="option" (click)="cancelBet(bet)">YES</div>
                                <div class="option" (click)="toggleCancelConfirm(bet)">NO</div>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>

                <ng-template #errorNotice>
                    <div class="copy-cancel-row notice error">
                        <div class="description">
                            We were unable to delete your bet.<br>
                            If you have any questions, please<br>
                            <ng-container *ngIf="phoneSupportEnabled; else chatLink">
                                call {{ 'primary-phone-number' | cduxTranslate:affiliateId }}.
                            </ng-container>
                            <ng-template #chatLink>
                                <a [cduxLogClick]="EventClickType.SUPPORT_CHAT_REQUEST" (click)="launchZeMessenger()">CHAT</a> with Player Services.
                            </ng-template>
                        </div>
                        <div class="options">
                            <div class="dismiss" (click)="toggleCancelConfirm(bet)"></div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>

    <ng-template #noBets>
        <div class="no-bets" [ngSwitch]="true">
            <div class="text" *ngSwitchCase="!isLoggedIn">You must be logged in to see your bets.</div>
            <div class="text" *ngSwitchCase="!isLoadingBets && isLoggedIn">You have no bets for this race.</div>
        </div>
    </ng-template>
</section>
