<div class="phone-update-container open" [formGroup]="formGroup">
    <div>
        <h4>Update Phone</h4>
    </div>
    <div class="form-label-hover">
        <input formControlName="phone" type="tel" autocomplete="user-tel" [ngClass]="{'has-value': !!newControl.value, 'input-error': showErrorMessage(inputField.newField)}"
            id="phone" [cduxTextMask]="phoneMask" (blur)="fieldVisited(inputField.newField)">
        <label class="ts-label" for="phone">Cell Phone</label>
        <i [ngClass]="{'icon--valid-check': isPhoneValid() || showValidIcon(newControl), 'icon--remove--amber': showRemoveIcon(newControl)}" (click)="showRemoveIcon(newControl) && resetInput(newControl)"></i>
        <div *ngIf="showErrorMessage(inputField.newField)" class="ts-validation error">Please enter a valid phone number</div>
    </div>

    <div class="update-cancel-button-container">
        <div id="cancelBtn"
            class="update-cancel-button active"
            (click)="onCancel()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_PHONE_CANCEL"
        >CANCEL</div>
        <div id="updateBtn"
            class="update-cancel-button"
            [ngClass]="formGroup.valid ? 'active' : 'inactive'"
            (click)="formGroup.valid && onUpdate()"
            [cduxLogClick]="eventClickType.MY_ACCOUNT_SETTINGS_PHONE"
        >UPDATE</div>
    </div>
</div>
