<div class="tile">
  <div class="header-row">
    <div class="saddle-silk">
      <div *ngIf="favoriteData.todaysEntryDetails[0].silkUrl" class="silk-saddle-cloth-container">
        <div id="silk-container-{{favoriteData.runnerId}}" class="silk"><img [src]="favoriteData.todaysEntryDetails[0].silkUrl"/></div>
      </div>
    </div>
    <div id="fav-tile-runner-{{ favoriteData.runnerId }}-name" class="runner-name">{{ favoriteData.runnerName }}
      <div class="button-container">
        <div aria-label="view stats" class="link" role="link" tabindex="0" (click)="replays()">PPs & Replays</div>
        <button *ngIf="favoriteData.todaysEntryDetails.length > 1" class="button-primary-outline-white smallest" id="fav-tile-runner-{{ favoriteData.runnerId }}-expand"  (click)="expand()">All Entries</button>
      </div>
    </div>
  </div>
  <div class="race-row" *ngIf="isRunningToday">
    <div class="race-row_track-col">
      <span class="title">Track</span>
      <span id="fav-tile-runner-{{ favoriteData.runnerId }}-trackDisplayName">{{ favoriteData.todaysEntryDetails[0].trackName || favoriteData.todaysEntryDetails[0].brisCode }}</span>
    </div>
    <div class="race-row_race-col">
      <span class="title">Race</span>
      R<span id="fav-tile-runner-{{ favoriteData.runnerId }}-raceNumber">{{ favoriteData.todaysEntryDetails[0].raceNumber }}</span>
    </div>
    <div class="race-row_time">
      <span class="title">Time</span>
      <div class="mtp-badge minutes-to-post">
        <cdux-mtp-badge
          id="fav-tile-runner-{{ favoriteData.runnerId }}-mtpBadge"
          [source]="setMtpDisplaySrc(favoriteData.todaysEntryDetails[0])" [showMinutes]="true"></cdux-mtp-badge>
      </div>
    </div>
  </div>
  <div class="notes-row">
    <span class="title">Notes</span>
    <span id="fav-tile-runner-{{ favoriteData.runnerId }}-notes" class="notes">{{ favoriteData.comment }}</span>
  </div>
  <div class="action-row">
    <div class="action-row_col"><span id="fav-tile-runner-{{ favoriteData.runnerId }}-edit"   class="action-item" (click)="edit()">Edit</span></div>
    <div class="action-row_col"><span id="fav-tile-runner-{{ favoriteData.runnerId }}-remove" class="action-item" (click)="remove()">Remove</span></div>
    <div class="action-row_col" style="margin-left: 14px;" *ngIf="isRunningToday">
      <button class="smaller" (click)="bet(favoriteData.todaysEntryDetails[0], false)"><span id="fav-tile-runner-{{ favoriteData.runnerId }}-bet">Bet</span></button>
    </div>
    <!-- <div class="action-row_col" *ngIf="isRunningToday"><span id="fav-tile-runner-{{ favoriteData.runnerId }}-bet"   class="action-item" (click)="bet()">Bet</span></div> -->
  </div>
</div>

<cdux-simple-modal
  *ngIf="modalContent"
  [modalContent]="modalContent"
  (backdropClicked)="dismissModal()"
  (modalInitialized)="assignOverlay($event)">
</cdux-simple-modal>

<ng-template #replaysModalTemplate>
  <div class="stable-alerts__trainer__runner">
    <header class="header--stable-alerts__trainer__runner replays">
      <div class="col1">
      </div>
      <div class="col2">
      </div>
      <div class="col3">
        <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>
    <div class="scrollable">
      <cdux-favorite-stats-runner
        [detailOptions]="options"
        isStable="true"
      ></cdux-favorite-stats-runner>
    </div>
  </div>
</ng-template>

<!-- TODO: make this a component reusable in favorite-toggle-*.component.ts -->
<ng-template #updateModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <!-- TODO: don't use "runner", keep type-agnostic -->
    <h2>
      <span id="favorite-add-modal-description" class="runner">{{ favoriteData.runnerName }}</span>
    </h2>
    <textarea id="favorite-add-modal-note"
              cols="50" [maxlength]="COUNT_MAX" name="note"
              placeholder="ADD A NOTE..." rows="13"
              [(ngModel)]="note"
    ></textarea>
    <div class="char-count"></div>
    <div class="btn-update" (click)="confirmUpdate()">Update</div>
  </div>
</ng-template>

<ng-template #removeModalTemplate>
  <div class="favorite-modal">
    <i class="icon--close" (click)="dismissModal()"></i>
    <!-- TODO: don't use "runner", keep type-agnostic -->
    <h2>
      <span id="favorite-add-modal-description" class="runner">
        Are you sure you want to remove<br>{{ favoriteData.runnerName }}?
      </span>
    </h2>
    <div class="message">You will no longer receive alerts and all notes will be lost.</div>
    <div class="button-row">
      <div class="btn-cancel" (click)="dismissModal()">Cancel</div>
      <div class="btn-remove" (click)="confirmRemove()">Remove</div>
    </div>

  </div>
</ng-template>

<ng-template #expandedModalTemplate>
  <div class="stable-alerts__runners__all-entries">
    <header class="header--stable-alerts__runners__all-entries">
      <div class="col1">
        <h1 class="stable-alerts__runners__all-entries__title">{{ favoriteData.runnerName }}</h1>
      </div>

      <div class="col2">
          <div class="stable-alerts__runners__all-entries__notes-title">Notes</div>
          <div id="expanded_view_notes" class="stable-alerts__runners__all-entries__notes">{{ favoriteData.comment }}</div>
      </div>

      <div class="col3">
          <button class="icon-button icon--close--standard" (click)="dismissModal()"></button>
      </div>
    </header>

    <div class="stable-alerts__runners__all-entries__title-bar" *ngIf="!isPhone">
      <span class="col1">Track</span>
      <span class="col2">Time</span>
      <span class="col3">Race</span>
      <span class="col4">Race Details</span>
      <span class="col6">Bet</span>
    </div>

    <div class="stable-alerts__runners__all-entries__title-bar" *ngIf="isPhone">
      <span class="col1">Runners</span>
    </div>

    <div class="stable-alerts__runners__all-entries__scroll-area">
        <ul id="expanded_view_runner_list" class="stable-alerts__runners__all-entries__list">
          <li *ngFor="let track of favoriteData.todaysEntryDetails; index as i">
            <div class="col1">
              <span class="runner-name" id="expanded_view_track_name_{{i}}">{{ track.trackName }}</span>
            </div>
            <div class="col2">
                <div class="mtp-badge near-minutes-to-post">
                  <cdux-mtp-badge
                    id="expanded_view_mtp_{{i}}-mtpBadge"
                    [source]="setMtpDisplaySrc(track)"
                    [showMinutes]="true">
                  </cdux-mtp-badge>
                </div>
            </div>
            <div class="col3">
                <span id="expanded_view_race_number_{{i}}">R{{ track.raceNumber }}</span>
            </div>
            <div class="col4">
                <div id="expanded_view_race_type_{{i}}" class="race-details-1">
                  {{ getRaceTypeText(track) }}
                </div>
                <div id="expanded_view_purse_{{i}}" class="race-details-2">{{ getRaceDetails(track) }}</div>
            </div>
            <div class="col6">
              <button [class.smaller]="!isPhone" (click)="bet(track, true)">BET</button>
            </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
