<nav class="bet-pad-bet-nav" *ngIf="isWagerable">
    <div class="bet-nav" [class.has-subtypes]="betModifierList?.length > 0">
        <cdux-betpad-bet-type
            [list]="poolTypes"
            [(ngModel)]="selectedPoolType"
            (ngModelChange)="onUpdateBetType($event)">
        </cdux-betpad-bet-type>
        <cdux-bet-nav-amount
            [list]="betAmountList"
            [selectedPoolType]="selectedPoolType"
            [selectedBetModifier]="selectedBetModifier"
            [selectedTrackRace]="selectedTrackRace"
            [(ngModel)]="selectedBetAmount"
            (ngModelChange)="onUpdateBetAmount($event)">
        </cdux-bet-nav-amount>
        <cdux-betpad-sub-type *ngIf="betModifierList?.length > 0"
                              [list]="betModifierList"
                              [(ngModel)]="selectedBetModifier"
                              (ngModelChange)="onUpdateBetModifier($event)">
        </cdux-betpad-sub-type>
    </div>
</nav>
