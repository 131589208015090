import { formatDate } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import {
    CduxDateUtil,
    EventClickType,
    FavoriteTracksService,
    IReplayDates,
    IReplayTracks,
    ITrack,
    ITrackBasic,
    ToteDataService,
    TrackService,
    UserEventEnum,
} from '@cdux/ng-common';
import { DropupService, ICalendarDay } from '@cdux/ng-fragments'
import { VideoFeedStates } from '../../enums/video-feed-states';
import { VideoService } from '../../services/video.service';
import { ITrackGroupLists } from 'app/shared/program/interfaces/track-group-lists.interface';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';

@Component({
    selector: 'cdux-video-select',
    templateUrl: './video-select.component.html',
    styleUrls: ['./video-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoSelectComponent implements OnDestroy, AfterViewInit, OnChanges {

    @Output()
    public selected = new EventEmitter<{
        track: ITrack | IReplayTracks,
        race?: number,
        raceDate?: string
    }>();

    /**
     * Date Picker Calendar Components
    */
    @ViewChild('datepickerLitePortal')
    private _datepickerPortalTplRef;
    public selectedDate = new Date();
    public isDatepickerVisible: boolean;

    private _trackGroups: ITrackGroupLists;
    public set trackGroups(value: ITrackGroupLists) {
        this._trackGroups = value;
    }
    public get trackGroups(): ITrackGroupLists {
        return this._trackGroups;
    }

    /**
     * List of tracks for Replay video
     */
    private _trackList: IReplayTracks[] = [];
    public set trackList(value: IReplayTracks[]) {
        if (!!value) {
            // TODO: set FormattedTrackName for IReplayTracks at the data service level
            (value as ITrack[]).map((track) => this._formatTrackName(track));
        }
        this._trackList = value;
        this._changeDetectorRef.detectChanges();
    }
    public get trackList(): IReplayTracks[] {
        return this._trackList;
    }
    private _liveTracksListObs: Subscription;
    private _replayTracksListObs: Subscription;

    /**
     * List of races for a replay track
     */
    private _raceList: number[] = [];
    public set raceList(value: number[]) {
        this._raceList = value;
        this._changeDetectorRef.detectChanges();
    }
    public get raceList(): number[] {
        return this._raceList;
    }

    /**
     * List of dates with replays available
     */
    public availableReplayDates: ICalendarDay[] = [];
    private _availableReplayDate: string;
    private _availableReplayTrack: ITrackBasic;
    public isReplay: boolean;

    private _feedState: VideoFeedStates;
    @Input()
    public set feedState(feedState) {
        this._feedState = feedState;
        this.isReplay = (feedState === VideoFeedStates.REPLAY);
        if (this.isDatepickerVisible && this._feedState === VideoFeedStates.LIVE) {
            this._dropupService.closeDropup();
        }
        this._feedStateUpdates.next(this._feedState);
    }
    public get feedState() {
        return this._feedState;
    }
    private _feedStateUpdates: Subject<VideoFeedStates> = new Subject();

    private _track: ITrack | IReplayTracks;
    @Input()
    public set track(track) {
        // TODO: set FormattedTrackName for IReplayTracks at the data service level
        this._track = this._formatTrackName(track);
        this._changeDetectorRef.detectChanges();
    }
    public get track(): ITrack | IReplayTracks {
        return this._track;
    }

    private _race: number;
    @Input()
    public set race(race) {
        this._race = race;
    }
    public get race(): number {
        return this._race;
    }

    private _raceDate: string;
    @Input()
    public set raceDate(raceDate) {
        this._raceDate = raceDate;
    }
    public get raceDate(): string {
        return this._raceDate;
    }

    @Input()
    public onStandalone: boolean = false;

    public videoFeedStates = VideoFeedStates;
    public eventClickType = EventClickType;

    private _subscriptions: Subscription[] = [];

    constructor(private _videoService: VideoService,
        private _dropupService: DropupService,
        private _toteDataService: ToteDataService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _favoriteTracksService: FavoriteTracksService,
        private _eventService: EventTrackingService
    ) { }

    ngAfterViewInit () {
        this._subscriptions.push(
            this._dropupService.initDropup(this._datepickerPortalTplRef),
            this._dropupService.toggleStateSubject.subscribe(
                toggleTo => (this.isDatepickerVisible = toggleTo) &&
                    this._initializeReplayDates(this.selectedDate, this.track)
            )
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        // if the feed state is replay and we have all inputs, otherwise, we're on live
        if (this.isReplay) {

            // if race date is set, set selected date
            if (this.raceDate) {
                const date = CduxDateUtil.parseLocalDateString(this.raceDate);
                this._initializeReplayDates(date, this.track);
                this.selectedDate = date;
            }

            // if we have no date selected, use the tote date and then set all the dropdowns
            if (!this.selectedDate) {
                this._toteDataService.currentRaceDate().pipe(take(1), map((date) => {
                    // if we have no date set, use tote date
                    this.selectedDate = CduxDateUtil.parseLocalDateString(date);
                    this._initializeReplayDates(this.selectedDate, this.track);
                    if (this.race && this.track) {
                        this._initializeReplayTrackList(this.selectedDate, this.track);
                    } else {
                        this._initializeReplayTrackList(this.selectedDate);
                    }
                }));
            } else {
                // we have a good date, set all the dropdowns
                if (this.race && this.track) {
                    this._initializeReplayTrackList(this.selectedDate, this.track);
                } else {
                    this._initializeReplayTrackList(this.selectedDate);
                }
            }
        } else {
            this._initializeLiveTrackList();
        }
        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy() {
        this._subscriptions.forEach(sub => sub.unsubscribe());
        this._liveTracksListObs.unsubscribe();
        if (this._replayTracksListObs) {
            this._replayTracksListObs.unsubscribe();
        }
        this._dropupService.closeDropup();
    }

    private _clearDropdowns() {
        this.raceList = [];
        this.trackList = [];
    }

    private _setTrackList(tracks: ITrack[] | IReplayTracks[]) {
        // if we already have a track set and we the trackList was cleared out
        if (this.track && this.trackList.length === 0) {
            // if the track we have selected matches in the new track list, maintain it, otherwise it gets set to null;
            if (VideoService.findTrackInList(this.track.BrisCode, this.track.TrackType, tracks as ITrack[])) {
                // our track matched one in the list, we need to immediately grab the new race list.
                this._videoService.getReplaySingleTrackObservable(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en-US'), this.track as ITrackBasic).pipe(take(1)).subscribe(
                    (track) => {
                        // the returned track will contain the array of races, preserve it
                        this.track['races'] = track.races;
                        // reset the race list
                        this.race = 0;
                        // set the list of races
                        this.raceList = track.races;
                        this._changeDetectorRef.detectChanges();
                    }
                );
            } else {
                // our track isn't in the list, clear it out
                this.track = null;
            }
        }
        this.trackList = tracks;
    }

    private _setRaceList() {
        // if we have a list of races, set it
        if (this.track && this.track.hasOwnProperty('races')) {
            this.raceList = this.track['races'];
            // if the race isn't set, or the race list doesn't include our race, clear it out.
            if (!this.race || !this.raceList.includes(this.race)) {
                this.race = 0;
            }
        }
    }

    private _setDropDowns() {
        this._setTrackList(this.trackList);
        this._setRaceList();
    }

    private _initializeReplayTrackList(date: Date, track?: ITrack | IReplayTracks) {
        if (!this._replayTracksListObs || this._replayTracksListObs.closed) {
            if (this.track) {
                this._replayTracksListObs = this._videoService
                    .getReplayTracksListObservable(formatDate(date, 'yyyy-MM-dd', 'en-US'), <ITrack>{BrisCode: this.track.BrisCode, TrackType: this.track.TrackType, DisplayName: '' })
                    .pipe(
                        takeUntil(this._feedStateUpdates)
                    )
                    .subscribe((replayTracks: IReplayTracks[]) => {
                        if (replayTracks) {
                            this.trackList = replayTracks;
                            this.track = VideoService.findTrackInList(this.track.BrisCode, this.track.TrackType, this.trackList as ITrack[]);
                            this._setDropDowns();
                        }
                        this._changeDetectorRef.detectChanges();
                    });
            } else {
                this._replayTracksListObs = this._videoService
                    .getReplayTracksListObservable(formatDate(date, 'yyyy-MM-dd', 'en-US'))
                    .pipe(
                        takeUntil(this._feedStateUpdates)
                    )
                    .subscribe((replayTracks: IReplayTracks[]) => {
                        if (replayTracks) {
                            this.trackList = replayTracks;
                        }
                        this._changeDetectorRef.detectChanges();
                    });
            }
        }
    }

    private _initializeLiveTrackList() {
        if (!this._liveTracksListObs || this._liveTracksListObs.closed) {
            this._liveTracksListObs = this._videoService
                .getLiveTrackListObservable()
                .pipe(
                    takeUntil(this._feedStateUpdates)
                )
                .subscribe((tracks: ITrackGroupLists) => {
                    this.trackGroups = tracks;
                    this._changeDetectorRef.detectChanges();
                });
        }
    }

    private _initializeReplayDates(date: Date, track: ITrack | IReplayTracks) {
        if (!date || !track) { return; }

        date = new Date(date.getTime());
        date.setDate(1); // rewind month
        const fromDate = CduxDateUtil.convertDateToWSFormat(date);

        if (TrackService.isSameTrack(track, this._availableReplayTrack) &&
            fromDate === this._availableReplayDate) { return; } // no need

        date = new Date(date.getTime());
        date.setMonth(date.getMonth() + 1);
        date.setDate(0); // find months end
        const toDate = CduxDateUtil.convertDateToWSFormat(date);

        this._videoService.getReplayDates(track, fromDate, toDate)
            .pipe(take(1)).subscribe((dates: IReplayDates) => {
                this._availableReplayTrack = track;
                this._availableReplayDate = fromDate;
                this.availableReplayDates.length = 0;
                for (const d in dates) {
                    if (!(dates[d] && dates[d].videoReplayAvailable)) { continue; }
                    const [ year, month, day ] = d.split('-');
                    this.availableReplayDates.push({ day: +day, month: +month - 1, year: +year });
                }
                this._changeDetectorRef.detectChanges();
            });
    }

    private _emitSelection() {
        if (this.isReplay) {
            this._eventService.logUserEvent(UserEventEnum.REPLAY_VIEWED, {
                trackName: this.track.BrisCode,
                raceNumber: this._race
            })
            this.selected.emit({ track: this.track, race: this.race, raceDate: this.raceDate });
        } else {
            this.selected.emit({ track: this.track });
        }
    }

    private _formatTrackName(track: ITrack | IReplayTracks): ITrack | IReplayTracks {
        if (!!track && !track.FormattedTrackName) {
            track.FormattedTrackName = TrackService.formatTrackName(track);
        }
        return track;
    }

    /**
     * Generates an ID on the track dropdown
     *
     * @param track
     */
    public generateTrackIdTag(track: ITrack | IReplayTracks): string {
        let baseId = '';

        if (this._favoriteTracksService.isFavoriteTrack(track)) {
            baseId += '_favorite';
        }

        return baseId + '_' + track.BrisCode;
    }

    public onDateSelect(date: Date) {
        if (date !== this.selectedDate) {
            this._initializeReplayDates(date, this.track);
            this.selectedDate = date;

            this._videoService.getReplayTracksListObservable(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en-US'))
                .pipe(take(1))
                .subscribe((trackList) => {
                    this.race = 0;
                    this._clearDropdowns();
                    this._setTrackList(trackList);
                    this._changeDetectorRef.detectChanges();
                });
        }
        this.raceDate = formatDate(this.selectedDate, 'yyyy-MM-dd', 'en-US');
        this._dropupService.closeDropup();
        this._changeDetectorRef.detectChanges();
    }

    public onMonthSelect(month: number) {
        const date = new Date(this.selectedDate.getTime());
        date.setMonth(month);
        this._initializeReplayDates(date, this.track);
    }

    public onYearSelect(year: number) {
        const date = new Date(this.selectedDate.getTime());
        date.setFullYear(year);
        this._initializeReplayDates(date, this.track);
    }

    public onTrackSelect(track: ITrack) {
        if (TrackService.isSameTrack(this.track, track)) { return; }

        // if we're on a replay, we have to retrieve the raceList
        if (this.isReplay) {
            // clear out the race selection and race list
            this.race = 0;
            this.raceList = [];

            this._initializeReplayDates(this.selectedDate, track);
            this._videoService.getReplaySingleTrackObservable(formatDate(this.selectedDate, 'yyyy-MM-dd', 'en-US'), track as ITrackBasic)
                .pipe(take(1))
                .subscribe(
                    (replayTrack) => {
                        this.track = replayTrack;
                        this._setRaceList();
                        this._changeDetectorRef.detectChanges();
                    });
        } else {
            this.track = track;
            this._emitSelection();
            this._changeDetectorRef.detectChanges();
        }
    }

    public onRaceSelect(race: number) {
        if (this.race === race) { return; }

        this.race = race;
        this._emitSelection();
    }

    public toggleDatepicker() {
        this._dropupService.toggleState();
    }

    public trackByTrackID(index: number, track: ITrack | ITrackBasic ): (string | null) {
        return track ? track.BrisCode + track.TrackType : null;
    }

}
